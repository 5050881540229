import { Component, OnInit, Inject } from '@angular/core';

import * as moment from 'moment';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';

import { RecordService } from '../../../../../services/record.service';

@Component({
  selector: 'app-annual-condition-report',
  templateUrl: './annual-condition-report.component.html',
  styleUrls: ['./annual-condition-report.component.scss']
})
export class AnnualConditionReportComponent implements OnInit {

  public objFormValue: any;
  public bShowLoader: boolean = false;
  public objForm: FormGroup = new FormGroup({
    start_date: new FormControl(moment().subtract(12, 'months').format('YYYY-MM-01')),
    end_date: new FormControl(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
  });
  public objFormMetadata: any = {
    start_date: {
      label: 'start',
      key: 'start_date',
      controlType: 'date',
      default_value: ''
    },
    end_date: {
      label: 'end',
      key: 'end_date',
      controlType: 'date',
      default_value: ''
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AnnualConditionReportComponent>,
    private recordService: RecordService
  ) {
    // Initialize moment
    let objStartDate = moment();
    let objEndDate = moment();
    // Set start and end date default value
    this.objFormMetadata = {
      start_date: {
        label: 'start',
        key: 'start_date',
        controlType: 'date',
        default_value: objStartDate.subtract(1, 'years').format('YYYY-MM-01'),
        readonly: false
      },
      end_date: {
        label: 'end',
        key: 'end_date',
        controlType: 'date',
        default_value: objEndDate.subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
        readonly: false
      }
    };
  }

  ngOnInit() {
  }

  /**
   * Select the start and end date configuration
   * Request the pdf data in api based on the selected dates.
   *
   * @returns {void}
   */
  onSubmit(): void {

    this.bShowLoader = true;
    let objData = this.objForm.getRawValue();
    // Do we have start and end date?
    if (objData.start_date && objData.end_date) {
      // Get current datetime
      let strCurrentTime = moment().format('HH:mm:ss');
      let strFormatStartDate = moment(objData.start_date).format('YYYY-MM-DD');
      let strFormatEndDate = moment(objData.end_date).format('YYYY-MM-DD');
      // Prepare for the where caluse
      let objWhereClause = {
        start_date: moment(strFormatStartDate+ ' ' +strCurrentTime).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(strFormatEndDate+ ' ' +strCurrentTime).utc().format('YYYY-MM-DD HH:mm:ss'),
        site_id: this.data.params.filter.site_id
      }
      // Get pdf record for the given date
      this.recordService.getPDFRecordData('recurring_jobs', null, objWhereClause).subscribe( response => {
        this.bShowLoader = false;
        this.dialogRef.close(response.body);
      });
    }
  }

  /**
   * Close the dialog
   *
   * @returns {void}
   */
  cancelDialog(): void {
    this.dialogRef.close(null);
  }

  /**
   * Updates the dates
   * Start and end date must have 1 year difference
   *
   * @param strField
   * @param objEvent
   *
   * @returns {void}
   */
  updateDate(strField, objValue): void {

    // Instatiate dates
    let objStartDate = moment();
    let objEndDate = moment();
    if (strField == 'start_date') {

      // If we got here, it means that user changed the value of start date, we need to add 1 year on end date
      objStartDate = moment(objValue);
      objEndDate = moment(objValue).add(12, 'months')
    } else {

      // If we got here, it means that user changed the value of end date, we need to subtract 1 year on start_date
      objStartDate = moment(objValue).subtract(12, 'months');
      objEndDate = moment(objValue);
    }

    // Set the value for start and end date
    this.objForm.patchValue({
      start_date: objStartDate.format('YYYY-MM-DD'),
      end_date: objEndDate.format('YYYY-MM-DD')
    });
  }
}
