import { from, throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { concatMap, switchMap, tap, toArray } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';

import { PresignedUrl } from '../../../../objects/presigned-url';
import { BrowserService } from '../../../../services/browser/browser.service';
import { Specifications } from '../../../../contracts/importer/specifications';
import { NotificationService } from '../../../../services/notification.service';
import { ImporterService } from '../../../../services/importer/importer.service';
import { MimeTypeValidator } from '../../../../shared/validators/files/mime-type-validator/mime-type-validator';
import { FileService } from '../../../../services/file/file.service';
import { Relate } from '../../../../objects/relate';
import { RecordService } from '../../../../services/record.service';
import { LooseObject } from '../../../../objects/loose-object';
import { isEmpty, get, set } from 'lodash-es';

export type ImportingStageComponentData = {
  /**
   * The name of the module that we're currently importing
   *
   * @type {String}
   */
  moduleName: string;

  /**
   * The presigned URL that we've got after uploading the import file to our
   * temporary S3 bucket.
   *
   * @type {PresignedUrl}
   */
  importFilePresignedUrl: PresignedUrl;

  /**
   * The importing specifications. The specifications object passed to each importing
   * stage component will be the return value of the previous importing stage.
   *
   * @type {Specifications}
   */
  specifications: Specifications;
}
@Component({
  selector: 'new-import-queue-form',
  templateUrl: './new-import-queue-form.component.html',
  styleUrls: ['./new-import-queue-form.component.scss']
})
export class NewImportQueueFormComponent implements OnInit {

  /**
   * The import queueing form metadata including inputs.
   *
   * @type {FormGroup}
   */
  importQueueForm: FormGroup = this.formBuilder.group({
    moduleName: new FormControl('', [Validators.required]),
    assetType: new FormControl(''),
    importFile: new FormControl('', [Validators.required, this.mimeTypeValidator.csv]),
  });

  /**
   * A list of modules that can be imported by the user.
   *
   * @type {string[]}
   */
  importable_modules: { label: string, model: string }[] = [
    { label: 'assets', model: 'Asset'},
    { label: 'contact_roles', model: 'ContactRole'},
    { label: 'customers', model: 'Customer'},
    { label: 'items', model: 'Item'},
    { label: 'jobs', model: 'Job'},
    { label: 'leads', model: 'Lead'},
    { label: 'opportunities', model: 'Opportunities'},
    { label: 'sites', model: 'Site'},
    { label: 'contacts', model: 'Contact'},
    { label: 'pricebooks', model: 'Pricebook'},
    { label: 'pricebook_items', model: 'PricebookItem'},
    { label: 'recurring_jobs', model: 'RecurringJob'},
    { label: 'stock_levels', model: 'StockLevel', },
    { label: 'supplier_pricing', model: 'Supplier Pricing', }
  ];

  /**
   * Can the user submit the queueing form? This should be set
   * to false if an import request is currently being processed.
   *
   * @type {Boolean}
   */
  canQueue: boolean = true;

  strModule: string = '';

  strCurrentStep: string = 'choose_file';

  strFieldMappingModule: string;

  obvFieldMapping: BehaviorSubject<Specifications> = new BehaviorSubject({
    paired_data: null,
    additional_paired_data: null,
    csv_path: ''
  });

  objFieldMappingUploadUrl: {
    filename: string,
    url: string
  };

  /**
   * determine if the transition between two step are still loading
   */
  bStepsLoading: boolean = false;

  objSteps = {
    choose_file: true,
    field_mapping: false,
    importing: false,
  };

  bIsAsset: boolean = false;

  objAssetTypeRelate: Relate<any> = new Relate<any>();

  /**
   * determine if the download template has filter
   */
  get hasAdditionalTemplateFilter(): boolean {
    return this.importQueueForm.get('moduleName').value === 'assets' || this.moduleSource === 'assets';
  }

  /**
   * The importing specifications for the importing request that we're about to queue
   *
   * @type {Specifications}
   */
  protected specifications: Specifications = {
    paired_data:[],
    additional_paired_data: [],
    csv_path: ''
  };

  /**
   * As of the writting, import wizard can be opened on supplier pricing and pricebooks modules
   * thus, we need to determine the source to remove the selection of modules
   * @type { string | null }
   */
  public moduleSource: string | null = null;

  /**
   * Here, we'll store the ID of the opened record from the module source
   * @type { string | null }
   */
  protected recordId: string | null = null;

  /**
   * The return value of each importing stages, combined into one.
   *
   * @type {Specifications}
   */
  protected importingStagesData: Specifications = this.specifications;

  constructor(
    protected router: Router,
    protected formBuilder: FormBuilder,
    protected browserService: BrowserService,
    protected importerService: ImporterService,
    protected translateService: TranslateService,
    protected fileService: FileService,
    protected notificationService: NotificationService,
    protected mimeTypeValidator: MimeTypeValidator,
    protected recordService: RecordService,
    protected dialogRef: MatDialogRef<NewImportQueueFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewImportQueueFormComponentDialogData,
  ) { }

  ngOnInit() {
    this.moduleSource = this.data.module;
    this.recordId = this.data.recordId;

    if (this.moduleSource === null || this.moduleSource === 'assets') {
      this.objAssetTypeRelate.buildRelates(
        switchMap( term => this.recordService.getRecordRelate('asset_types', term, '')),
        null
      );
    }

    this.importQueueForm.get('assetType').valueChanges.subscribe((selected) => {
      this.data = set(this.data, 'additional_mappings.asset_type_id', selected);
    });
  }

  /**
   * Uploads the submitted CSV file to S3 as a temporary file, then makes a request
   * to the import queueing API containing the filename of the import file that the
   * user has uploaded. After completion, the dialog box is closed and the user is
   * redirected to the import record that will be created.
   *
   * A sidenote on the disabling of the submit button: When triggering this method,
   * the submit button will be disabled and will not be re-enabled again since we're
   * redirecting the user to the import record that was created. However, an error
   * with HTTP requests (either with the temporary file upload API or queueing API)
   * will cause the submit button to be re-enabled again to allow the user to retry.
   *
   * @returns {void}
   */
  submitImportQueueForm(): void {
    if (this.importQueueForm.valid === false && this.moduleSource === null) {
      this.notificationService.notifyWarning('invalid_form');
    }

    if (this.importQueueForm.get('moduleName').value === 'assets' && isEmpty(this.importQueueForm.get('assetType').value)) {
      this.notificationService.notifyWarning('Asset Type is required for Assets module.');
    }
    this.bStepsLoading = true;
    this.canQueue = false;

    let importFile = this.importQueueForm.get('importFile').value;
    this.strModule = ! isEmpty(this.importQueueForm.get('moduleName').value) ? this.importQueueForm.get('moduleName').value : this.moduleSource;

    this
      .fileService
      .upload(importFile)
      .pipe(
        switchMap(
          tmpFile => tmpFile.url === null || tmpFile.filename === null
            ? throwError('There was an error uploading the CSV file. Please try again')
            : from([tmpFile]).pipe(concatMap(
                ({ filename, url }) => this.onLoadFieldMapping(this.strModule, filename, url)
                .filter(data => data.paired_data != null)
                .pipe(
                  tap(data => {
                    this.bStepsLoading = true;
                    this.importingStagesData = data;
                  }),
                )
              ),
              // By calling toArray() here, we wait for all the importing stages to
              // complete, compile its responses as an array, then call the queueing API.
              toArray(),
              switchMap(() => this.importerService.import(this.strModule, tmpFile.filename, this.importingStagesData, this.data.recordId))
            )
        )
      )
      .subscribe(
        response => {
          if (get(response, 'missing_required_fields', null) !== null) {
            const requiredFields = get(response, 'missing_required_fields')
            .map((field) => this.translateService.instant(field));
            this.notificationService.notifyWarning('Please update your csv file or re-map your fields. Required field/s missing: ' + requiredFields.join(', ')), { duration: 10000 };
            this.dialogRef.close();
          } else {
            this.notificationService.notifySuccess('csv_queueing_success_message');
            this.dialogRef.close();
            this.router.navigate([`admin/import/${response['id']}`]);
          }
        },
        error => {
          this.importQueueForm.patchValue({ importFile: null });
          this.canQueue = true;
          console.error(error);
        }
      );

  }

  /**
   * Makes a request to the template download API then returns the file
   * to the user for download.
   *
   * @see {@link https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api}
   *
   * @param {string} moduleName
   *
   * @returns {void}
   */
  downloadTemplate(moduleName: string): void {
    this.importerService.downloadImportFileTemplate(moduleName, this.getAdditionalTemplateFilter()).subscribe((response) => {
      this.browserService.forceDownload(
        response.body,
        this.browserService.getFilenameFromResponseHeaders(response)
      );
    });
  }

  /**
   * load field mapping component
   *
   * @param {string} strModule
   * @param {string} strFilename
   * @param {string} strUrl
   */
  onLoadFieldMapping(strModule: string, strFilename: string, strUrl: string) {
    this.bStepsLoading = false;

    this.strCurrentStep = 'field_mapping';
    this.objSteps.field_mapping = true;

    this.strFieldMappingModule = strModule;
    this.objFieldMappingUploadUrl = {
      filename: strFilename,
      url: strUrl
    };

    return this.obvFieldMapping;
  }

  /**
   * saves and process the import
   *
   * @param {Specifications} objSpecifications
   *
   * @returns {void}
   */
  onSubmitFieldMapping(objSpecifications: Specifications): void {
      this.objSteps.importing = true;
      this.strCurrentStep = 'importing';
      this.obvFieldMapping.next(objSpecifications);
      this.obvFieldMapping.complete();
  }

  /**
   * close dialog
   *
   * @returns {void}
   */
  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * get additional filter when download template
   *
   * @returns {LooseObject}
   */
  getAdditionalTemplateFilter(): LooseObject {
    if ((this.importQueueForm.get('moduleName').value === 'assets' || this.moduleSource === 'assets')) {
      const assetTypeId = this.importQueueForm.get('assetType').value;
      return { asset_type_id: isEmpty(assetTypeId) ? null: assetTypeId };
    } else {
      if (this.recordId !== null && this.moduleSource !== null) {
        return {
          module_source: this.moduleSource
        }
      }
    }

    return {};
  }

  /**
   * When the import wizard is opened from mega menu, provide an option to go to import's listing
   */
  public goToImportListing() {
    if (this.moduleSource) {
      this.dialogRef.close();
      this.router.navigate([`admin/import`]);
    }
  }
}

export type NewImportQueueFormComponentDialogData = {
  module: string;
  recordId?: string;
  additional_mappings?: Record<string, any>;
}