<header>
  <div class="header-container">
    <div class="col-12">
      <span class="record-title">
        <span class="icon-figure-primary pr-1">
          <fa-icon [icon]="['fa', 'sync-alt']"></fa-icon>&nbsp;
          <span class="pl-1">{{ title | translate }} </span>
        </span>
      </span>
      <div class="buttons-mobile-view">
        <ul class="list-inline float-right">
          <li
            *ngIf="displayUpdateAccountingButton"
            class="list-inline-item"
          >
            <fieldmagic-primary-button
              [isInProgress]="flaggingInProgress$ | async"
              [icon]="(strDisplayOption == 'unsynced_invoices') ? 'times' : 'check'"
              [disabled]="isObjListBlank || bSubmitted || objList?.total_records === 0"
              label="{{ (strDisplayOption == 'unsynced_invoices') ? 'disable_accounting' : 'enable_accounting' }}"
              (click)="toggleAccountingFlag()"
            ></fieldmagic-primary-button>
          </li>
          <li class="list-inline-item">
            <button
              class="btn ml-1 btn-primary"
              [disabled]="bSubmitted || objList?.total_records === 0 || strDisplayOption === 'synced_invoices' || isObjListBlank || (flaggingInProgress$ | async)"
              (click)="onSubmit()">
                <fa-icon *ngIf="!bSubmitted" [icon]="['fas', 'sync-alt']"></fa-icon>
                <fa-icon *ngIf="bSubmitted" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                <span class="pl-1">{{ 'sync' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <fieldmagic-primary-button
              variant="error"
              [disabled]="bSubmitted"
              icon="times"
              (click)="closeDialog()"
              label="close"
            ></fieldmagic-primary-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>

<hr class="separator" />

<mat-dialog-content>
  <div class="row">
    <div class="col-9">
      <p>{{ tooltip | translate }}</p>
    </div>

    <div class="col-3">
      <div class="d-flex d-flex-gap w-100 align-items-center">
        <fieldmagic-icon
          icon="sync-alt"
          class="text-link"
          (click)="onRefresh()"
        ></fieldmagic-icon>
        <ng-select
          [disabled]="isObjListBlank"
          [items]="arDisplayOptions"
          [clearable]="false"
          class="value-text w-100"
          (change)="switchView()"
          [(ngModel)]="strDisplayOption">
            <ng-template ng-label-tmp let-item="item">{{ item | translate }}</ng-template>
            <ng-template ng-option-tmp let-item="item">
              <span>{{ item | translate }}</span>
            </ng-template>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <span *ngIf="!isObjListBlank">
        <small *ngIf="objList?.total_records">
          {{ 'list_view_summary' | translate: {fromRecords: objList.from_records, toRecords: objList.to_records, totalRecords: objList.total_records} }}
        </small>
      </span>
    </div>

    <div class="col-6">
      <div *ngIf="!isObjListBlank" class="pagination-section">
        <ul *ngIf="objList?.total_records" class="pagination float-right">
          <li class="page-item" [class.disabled]="!objList.hasPreviousToken">
            <a id="firstPage" class="page-link" [ngClass]="{'pagination-color': objList.hasPreviousToken}" aria-label="first" (click)="initItems(1)">
              <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
            </a>
          </li>
          <li class="page-item" [class.disabled]="!objList.hasPreviousToken">
            <a id="prevPage" class="page-link" [ngClass]="{'pagination-color': objList.hasPreviousToken}" aria-label="previous" (click)="initItems(objList.current_page - 1)">
              <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
            </a>
          </li>
          <li class="page-item" [class.disabled]="!objList.hasNextToken">
            <a id="nextPage" class="page-link" [ngClass]="{'pagination-color': objList.hasNextToken}" aria-label="next" (click)="initItems(objList.current_page + 1)">
              <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
            </a>
          </li>
          <li class="page-item" [class.disabled]="!objList.hasNextToken">
            <a id="lastPage" class="page-link" [ngClass]="{'pagination-color': objList.hasNextToken}" aria-label="last" (click)="initItems(objList.last_page)">
              <fa-icon [icon]="['far', 'chevron-double-right']" aria-hidden="true"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <table class="table table-bordered mt-2">
        <thead>
          <tr>
            <th width="5%" class="text-center align-middle">
              <input
                type="checkbox"
                [disabled]="objList?.total_records === 0 || strDisplayOption === 'synced_invoices'"
                [(ngModel)]="bSelectAllChecked"
                [ngModelOptions]="{standalone: true}"
                (change)="selectAllChanged()">
            </th>
            <th width="20%">
              <div class="d-flex w-100 justify-content-center d-flex-gap">
                {{ 'invoice_number' | translate }}
                <fieldmagic-sorting-icon
                  [current]="sorting$ | async"
                  field="invoice_number"
                  (onSort)="onSort($event)"
                ></fieldmagic-sorting-icon>
              </div>
            </th>
            <th width="25%" class="text-center">
              <div class="d-flex w-100 justify-content-center d-flex-gap">
                {{ customerOrSupplier | translate }}
                <fieldmagic-sorting-icon
                [current]="sorting$ | async"
                field="customer_text"
                (onSort)="onSort($event)"
                ></fieldmagic-sorting-icon>
              </div>
            </th>
            <th width="5%">
              <div class="d-flex w-100 justify-content-center d-flex-gap">
                {{ 'amount' | translate }}
                <fieldmagic-sorting-icon
                  [current]="sorting$ | async"
                  field="amount"
                  (onSort)="onSort($event)"
                ></fieldmagic-sorting-icon>
              </div>
            </th>
            <th width="15%">
              <div class="d-flex w-100 justify-content-center d-flex-gap">
                {{ 'invoice_date' | translate }}
                <fieldmagic-sorting-icon
                  [current]="sorting$ | async"
                  field="invoice_date"
                  (onSort)="onSort($event)"
                ></fieldmagic-sorting-icon>
              </div>
            </th>
            <th width="10%" class="text-center">{{ 'job_number' | translate }}</th>
            <th *ngIf="isSyncErrorAndRemoteLinkColDisplayed" width="11%" class="text-center">
              {{ ((disableSyncErrorIcon) ? 'sync_error' : 'remote_link') | translate }}
            </th>
          </tr>
        </thead>

        <tbody>
          <ng-template #noData>
            <tr>
              <td
                colspan="7"
                class="text-center"
              >
                {{ 'no_record_found' | translate }}
              </td>
            </tr>
          </ng-template>
          <ng-container *ngIf="!isObjListBlank; else loading">
            <ng-container *ngIf="objList?.data | filled; else noData">
              <tr
                *ngFor="let item of objList?.data"
                [class.row-disabled]="item.row_is_disabled"
                [matTooltip]="item.row_is_disabled ? ('updates_are_in_progress' | translate) : undefined"
              >
                <td class="text-center align-middle">
                  <input
                    type="checkbox"
                    [disabled]="(strDisplayOption === 'synced_invoices') || item.row_is_disabled"
                    [(ngModel)]="item.is_selected"
                    [ngModelOptions]="{standalone: true}"
                    (change)="itemCheckboxToggled(item)">
                </td>
                <td>
                  <a target="_blank" [routerLink]="['/' + data.strModule + '/' + item.id]">{{ item.invoice_number }}</a>
                </td>
                <td>
                  <a *ngIf="isFieldFilled(item.customer_id); else noCustomer" target="_blank" [routerLink]="['/customers/' + item.customer_id]">{{ item.customer_text }}</a>
                  <ng-template #noCustomer>{{ item.customer_text }}</ng-template>
                </td>
                <td>
                  <span *ngIf="data.strModule == 'customer_invoices'">{{ item | data_get: "amount_tax_inc" | currency: {decimal_places: 2} }}</span>
                  <span *ngIf="data.strModule == 'supplier_invoices'">{{ item | data_get: "amount_inc_tax" | currency: {decimal_places: 2} }}</span>
                </td>
                <td>
                  <span *ngIf="data.strModule == 'customer_invoices'">{{ item | data_get: "date_invoice" | format: 'lll' }}</span>
                  <span *ngIf="data.strModule == 'supplier_invoices'">{{ item | data_get: "invoice_date" | format: 'lll' }}</span>
                </td>
                <td>
                  <a *ngIf="isFieldFilled(item.job_id); else noJob" target="_blank" [routerLink]="['/jobs/' + item.job_id]">{{ formatJobNumberText(item.job_text) }}</a>
                  <ng-template #noJob>{{ formatJobNumberText(item.job_text) }}</ng-template>
                </td>
                <td *ngIf="isSyncErrorAndRemoteLinkColDisplayed" class="text-center">
                  <ng-container *ngIf="disableSyncErrorIcon; else displayRemoteLink">
                    <span
                      *ngIf="item.accounting_sync_error === true; else noSyncError"
                      ngxClipboard
                      [cbContent]="item.accounting_sync_detail"
                      (cbOnSuccess)="notifyCopy()"
                      class="text-primary pointer"
                      matTooltip="{{ item.accounting_sync_detail }}">
                      <fa-icon [icon]="['fas', 'info-circle']" aria-hidden="true" size="lg"></fa-icon>
                    </span>
                    <ng-template #noSyncError>--</ng-template>
                  </ng-container>

                  <ng-template #displayRemoteLink>
                    <a href="{{ xeroRecordBaseLink + item.accounting_id }}" target="_blank">
                      <fa-icon [icon]="['fas', 'external-link-alt']" aria-hidden="true" size="lg"></fa-icon>
                    </a>
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</mat-dialog-content>

<ng-template #loading>
  <tr>
    <td colspan="7" class="text-center">
      <inline-view-loader [isLoading]="true"></inline-view-loader>
    </td>
  </tr>
</ng-template>


