import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ListingService } from '../../../services/listing.service';
import { NotificationService } from '../../../services/notification.service';
import { RecordService } from '../../../services/record.service';
import { ViewService } from '../../../services/view.service';
import { EditPurchaseOrderComponent } from './edit-purchase-order/edit-purchase-order.component';
import { RelateIds } from '../../../lists/relate-ids';
import * as moment from 'moment';
import { WizardPurchaseOrderComponent } from './wizard-purchase-order/wizard-purchase-order.component';
import { Router } from '@angular/router';
import { filled } from '../../../shared/utils/common';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.scss'],
  providers: [ListingService]
})
export class PurchaseOrdersComponent implements OnInit {
    @Input() strRecordId : string;
    @Input() strModule : string;
    public arPurchaseOrders = [];
    public arPreviousPages = {};
    public bPageLoaded: boolean = false;
    public objJobData: any;
    public objCustomerData: any;
    public objModuleConfig: any = {};
    public bDialogLoaded: boolean = true;

  constructor(
    private dialog: MatDialog,
    public listService: ListingService,
    private notifService: NotificationService,
    public viewService: ViewService,
    private recordService: RecordService,
    private router: Router,
  ) {}

  ngOnInit() {
    // Get the list on page initialization.
    this.fetchList('default');
  }

  /**
  * Open the create purchase order dialog.
  */
  createPurchaseOrder() {
    // Create the object to be passed inside the dialog.
    let objData = {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        padding: '1%',
        disableClose: true,
        // The id of the jobs, the purchase order's "parent".
        data: {
          module: this.strModule,
          record_id : this.strRecordId,
          view_type : 'add'
        }
      };

      // Initialize the dialog.
      let tabDialogRef = this.dialog.open(EditPurchaseOrderComponent, objData);

      // When the dialog closes, reload the list.
      tabDialogRef.afterClosed().first().subscribe(item => {
        if (item != undefined) {
          // Show notification that activity has been created.
          this.notifService.notifySuccess('header_notification.success_added');
          setTimeout(() => {
            this.onRefresh();
          }, 3500);
        }
      });
  }

  /**
   * Opens the selected PurchaseOrder from the list.
   * @param objPurchaseOrder - the PurchaseOrder data.
   */
  openPurchaseOrder(objPurchaseOrder) {
    // Object to be passed in the dialog.
    let objData = {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
      padding: '1%',
      disableClose: true,
      // Data to be passed on
      data: {
        module: this.strModule,
        record_id : this.strRecordId,
        purchase_order: [],
        purchase_order_id: objPurchaseOrder['id'],
        view_type : 'edit'
      }
    };

    // Instantiate dialog.
    let tabDialogRef = this.dialog.open(EditPurchaseOrderComponent, objData);

    // Reload invoice list once the dialog is closed.
    tabDialogRef.afterClosed().first().subscribe(item => {
      // Only when the user hits save will we reload the list.
      if (item != undefined && item == 'record_update_success') {
        // Show notification that activity has been created.
        this.notifService.notifySuccess('header_notification.success_update');
        this.fetchList('default');
      }
    });
  }

  /**
   * Fetch the list of purchase order.
   * @param strPage - what page is currently to be viewed.
   */
  fetchList(strPage, bNotifyRedirect: boolean = false) {

    let objPagination = this.listService.beforeFetching(strPage);
    let strModuleId = RelateIds[this.strModule];

    this.bPageLoaded = false;

    // Get the list from API.
    this.listService.fetchDataAdvanceSearch(
      objPagination['objPage'],
      'purchase_orders',
      {[strModuleId]: this.strRecordId},
      { 'id': 'created_at', 'sort': 'desc' },
    ).subscribe(response => {
      this.arPurchaseOrders = response['data'];
      this.listService.afterFetching(response, strPage);
      this.bPageLoaded = true;

      if (bNotifyRedirect && this.arPurchaseOrders.length) {
        this.notifService.sendConfirmation('redirect_to_new_purchase_order')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.router.navigate([`/purchase_orders/${this.arPurchaseOrders[0].id}`]);
        });
      }
    });
  }
  /**
   *
   * @param strId - record id
   */
  deletePurchaseOrder(strId) {
    //We tell the app component to open the confirmation.
    this.notifService.sendConfirmation().subscribe(
      confirmation => {
        //If the user confirmed, delete the record by field
        if (confirmation.answer) {
          this.recordService.deleteRecord('purchase_orders', strId).first().subscribe(
            data => {
              if (data) {
                this.fetchList('default');
                this.notifService.notifySuccess('record_delete_success')
              } else {
                this.notifService.notifyError('record_delete_failed');
              }
            });
        }
      }
    );
  }
  /**
   * Get record module config field
   */
  getModuleConfig(strModule) {
    this.recordService.getRecordConfig(strModule).first().subscribe(
      result => {
      this.objModuleConfig = result;
      }
    );
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate, arCustomConfig = []) {
    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('ll');
  }

  /**
   * Compiled all related data to be shown in tooltip
   * @param relatedData - related data to be shown
   */
  compiledRelatedData(relatedData) {
    let invoiceNumber = [];
    relatedData.forEach( data => {
      invoiceNumber.push(data['text']);
    });

    return invoiceNumber.join(", ");
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(bNotifyRedirect: boolean = false): void {
    this.arPurchaseOrders = []; // clear list
    this.fetchList('default', bNotifyRedirect);
  }

  /**
  * Open the create purchase order dialog.
  */
  openPurchaseOrderWizard(): void {
    // Create the object to be passed inside the dialog.
    let objData = {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        padding: '1%',
        disableClose: true,
        // The id of the jobs, the purchase order's "parent".
        data: {
          module: this.strModule,
          record_id : this.strRecordId,
          view_type : 'add'
        }
      };

      // Initialize the dialog.
      let tabDialogRef = this.dialog.open(WizardPurchaseOrderComponent, objData);

      // When the dialog closes, reload the list.
      tabDialogRef.afterClosed().first().subscribe( response => {
        if (response.status === 'success') {
          setTimeout(() => {
            this.onRefresh(response.redirect);
          }, 1500);
        }
      });
  }

  getRedirectQueryParams = (record: PurchaseOrder): Record<string, string> => ({
    ... (filled(record.job_id) && {
      go_back_to: this.viewService.makeRedirectUrl('jobs', record.job_id, {
        openWidgetTab: 'purchase-orders',
      }),
    }),
  });
}

type PurchaseOrder = {
  job_id?: string | null;
}
