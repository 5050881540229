import { Component, OnInit } from '@angular/core';
import { ViewService } from '../../../../services/view.service';
import { ListingService } from '../../../../services/listing.service';
import { LooseObject } from '../../../../objects/loose-object';
@Component({
  selector: 'stock-adjustments',
  templateUrl: './stock-adjustments.component.html',
  styleUrls: ['./stock-adjustments.component.scss']
})
export class StockAdjustmentsComponent implements OnInit {

  recordList: LooseObject[] = [];

  constructor(
    private list: ListingService,
    private view: ViewService,
  ) {}

  ngOnInit() {
    this.getList();
  }

  getList(page: string = 'default'): void {
    let objPagination = this.list.beforeFetching(page);
    this.list.fetchData(JSON.stringify(objPagination.objPage), 'stock_adjustments', JSON.stringify({
      order_by: {
        id: 'created_at',
        sort: 'desc'
      },
      item_id: this.view.arRecord['item_id'],
    })).subscribe( response => {
      if (response && response['data']) {

        this.recordList = response['data'];
        this.list.afterFetching(response, page);
      }
    });
  }

  /**
   * make a redirect url to preview the the related record
   *
   * @param module
   * @param id
   * @returns
   */
  getLink(module: string, id: string): string {
    return '/' + module + '/' + id;
  }

  /**
   * check if record has empty relate field
   *
   * @param {LooseObject} data
   * @returns {boolean}
   */
  hasNoRelateField(data: LooseObject): boolean {
    return !data.purchase_order_id
      && !data.job_id
      && !data.customer_invoice_id
      && !data.stock_take_id
      && !data.source_warehouse_id
      && !data.supplier_invoice_id;
  }
}
