import { Component, OnInit, Inject, HostListener, ElementRef } from '@angular/core';

import { UUID } from 'angular2-uuid';
import { Subscription, Observable, Subject, concat, of, throwError } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { transform as _transform, isEmpty as _isEmpty, values as _values } from 'lodash-es';
import { debounceTime, distinctUntilChanged, tap, switchMap, concatMap, map } from 'rxjs/operators';

import { FormService } from '../../../../../../services/form.service';
import { RecordService } from '../../../../../../services/record.service';
import { NotificationService } from '../../../../../../services/notification.service';

import { LooseObject } from '../../../../../../objects/loose-object';
import { Select } from '../../../../../../objects/select';

@Component({
  selector: 'app-stock-transfer-quantities',
  templateUrl: './stock-transfer-quantities.component.html',
  styleUrls: ['./stock-transfer-quantities.component.scss']
})
export class StockTransferQuantitiesComponent implements OnInit {

  /**
   * to determine if component still loading
   */
  loading: boolean = true;

  /**
   * store the stock transfer quantity
   */
  stockTransferQuantityForm: Array<StockTransferQuantityConfig> = [];

  /**
   * store all selected items
   */
  selecteItems: LooseObject = {};

  /**
   * determine if we need to change the class
   */
  matDialogContentClass: string = 'overflow-visible'

  /**
   * determine if the form is update or create
   */
  isUpdate: boolean = false;

  /**
   * List of observable subscription that would be cleaned up after this component is unmounted/destroy
   *
   * @var {Subscription[]}
   */
  protected subscriptions: Subscription[] = [];

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  constructor(
    private elementRef: ElementRef,
    private formService: FormService,
    private recordService: RecordService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<StockTransferQuantitiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<object>,
  ) { }

  /**
   * @inheritDoc
   */
  ngOnInit() {

    if (this.data.length) {

      this.data.forEach( data => {

        this.createFormConfig(data['item_id'], data['quantity'], [data['item']]);
      });
      this.isUpdate = true;
    } else {

      this.createFormConfig();
    }

    this.updateMatDialogClass();
  }

  /**
   * create stock transfer quantity config
   *
   * @returns {void}
   */
  createFormConfig(item_id: string = null, quantity: number = null, itemOption: Array<object> = []): void {
    let formId = UUID.UUID();
    let config = {
      id: formId,
      form: new FormGroup({
        'item_id':  new FormControl(item_id, Validators.required),
        'quantity':  new FormControl(quantity, Validators.required),
      }),
      item_loader: false,
      item_obv: new Observable<Select[]>(),
      item_typeahead: new Subject<string>(),
    }

    if (itemOption.length) {

      this.selecteItems[formId] = itemOption[0];
    }
    config.item_obv = concat(
      of(itemOption),
      config.item_typeahead.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => config.item_loader = true),
        switchMap(term => {
          return this.recordService.getRecordRelate('items', term, '', false, false).pipe(
            tap( (data) => {
              if (data.length === 1) {

                this.selecteItems[formId] = data[0]['id'];
                config.form.patchValue({ item_id: data[0]['id'] });
                config.form.markAsDirty();

                this.onChangeItem(formId, config.form, data[0]);

                this.elementRef.nativeElement.querySelector(`.item-${formId}`).blur();
              }
              config.item_loader = false;
            }),
          )
        })
      ),
    );

    this.stockTransferQuantityForm.push(config);

    this.loading = false;
  }

  /**
   * auto focus in quantity after selecting the item
   *
   * @param formId
   * @param formGroup
   * @param event
   *
   * @returns {void}
   */
  onChangeItem(formId: string, formGroup: FormGroup, event = null): void {
    let itemElement = this.elementRef.nativeElement.querySelector(`.item-${formId}`);
    let quantityElement = this.elementRef.nativeElement.querySelector(`.quantity-${formId}`);
    if (event) {

      this.selecteItems[formId] = event;
    }
    if (formGroup.controls.item_id.value && quantityElement) {

      quantityElement.focus();
    }
  }

  /**
   * add stock transfer quantity
   *
   * @returns {void}
   */
  addStockTransferQuantity(): void {
    let validForm = this.stockTransferQuantityForm.map( config => config.form.valid );
    let invalidForm = validForm.find(validate => validate == false);
    // check if there is a invalid form
    if (invalidForm === undefined) {
      let itemList = this.stockTransferQuantityForm.map( config => {

        let data = config.form.getRawValue();
        data['item'] = this.selecteItems[config.id];
        return data;
      });

      this.dialogRef.close(itemList);
    } else {

      this.notificationService.notifyWarning('invalid_requested_parameters');
      this.stockTransferQuantityForm.map( config => {
        config.form.controls.quantity.markAsDirty();
        config.form.controls.item_id.markAsDirty();
      } );
    }
  }

  /**
   * add stock transfer quantity
   *
   * @returns {void}
   */
  addProduct(): void {
    this.createFormConfig();
    this.updateMatDialogClass()
  }

  /**
   * remove stock transfer quantity
   */
  removeStockTransferQuantity(form): void {
    let formIndex = this.stockTransferQuantityForm.findIndex( config => config.id == form.id);
    this.stockTransferQuantityForm.splice(formIndex)
    // delete this.stockTransferQuantityForm[formIndex];
  }

  /**
   * cancel dialog
   *
   * @returns {void}
   */
  cancelDialog(): void {
    let isDirty = this.stockTransferQuantityForm.map( config => config.form.dirty );
    let hasDirtyForm = isDirty.find( dirtyForm => dirtyForm == true);
    if (hasDirtyForm) {
      this.notificationService.sendConfirmation('confirm_cancel')
      .filter(confirmation => confirmation.answer === true)
      .subscribe(() => { this.dialogRef.close(); });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * update the css to apply a scrollbar if it reaches the maximum height
   *
   * @returns {void}
   */
  updateMatDialogClass() {
    setTimeout(() => {
      if (this.elementRef.nativeElement.querySelector('.mat-dialog-content').offsetHeight > 750) {
        this.matDialogContentClass = 'overflow-overlay';
      }
    }, 2)
  }

  /**
   * check if the given form is invalid
   *
   * @param form
   * @param id
   *
   * @returns {boolean}
   */
  isFormInvalid(form: FormGroup, id: string): boolean {
    if (form.controls[id]) {

      return form.controls[id].dirty && !form.controls[id].valid;
    }

    return true;
  }
}

export interface StockTransferQuantityConfig {
  id: string
  form: FormGroup,
  item_loader: boolean,
  item_obv: Observable<Select[]>,
  item_typeahead: Subject<string>,
};
