import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgDragDropModule } from 'ng-drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Modules
import { SharedModule } from './shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditModule } from './edit.module';
import { SharedModule as FormTemplateSharedModule } from '../../module/form-templates/shared/shared.module';
import { SharedModule as AccountingSystemSharedModule } from '../../integrations/accounting_systems/shared/shared.module';
import { ClipboardModule } from 'ngx-clipboard';
import { ActivityModule } from './activity.module';
import { SharedCalendarModule } from './shared-calendar.module';

// Components
import { ViewComponent } from '../components/view/view.component';
import { StatuswidgetComponent } from '../components/view/statuswidget/statuswidget.component';
import { ConvertdialogComponent } from '../components/view/convertdialog/convertdialog.component';
import { ConvertOpportunityComponent } from '../components/view/convert-opportunity/convert-opportunity.component';
import { ConvertOpportunityFilesComponent } from '../components/view/convert-opportunity-files/convert-opportunity-files.component';
import { ConvertedDataComponent } from '../components/view/converted-data/converted-data.component';
import { LineItemsComponent } from '../components/view/line-items/line-items.component';
import { OptionComponent } from '../components/view/pdf/option/option.component';
import { AnnualConditionReportComponent } from '../components/view/pdf/annual-condition-report/annual-condition-report.component';
import { ReasonDialogComponent } from '../components/view/reason-dialog/reason-dialog.component';
import { AssetHierarchicalViewComponent } from '../../module/assets/view/components/asset-hierarchical-view/asset-hierarchical-view.component';
import { SchedulesComponent } from '../components/schedules/schedules.component';

import { MatTreeModule } from "@angular/material/tree";
import { MatIconModule } from "@angular/material/icon";

// Services
import { ViewService } from '../../services/view.service';
import { LambdaService } from '../../services/lambda.service';
import { AssetService } from '../../services/asset.service';
import { PreferredSupplierDialogComponent } from '../components/view/preferred-supplier-dialog/preferred-supplier-dialog.component';

import { SharedModule as SafetyManagementSharedModule } from "../../module/safety-management/shared/shared.module";
import { BackToListComponent } from '../components/view/back-to-list/back-to-list.component';
import { ViewActionsButtonComponent } from '../components/view/actions-buttons/actions-button.component';
import { WorkOrderItemsModule } from './work-order-items.module';
import { ViewCustomFieldComponent } from '../components/view/custom-field/custom-field.component';
import { ViewItemCodesComponent } from '../../admin/items/view/components/item-codes.component';
import { ViewReorderConfigComponent } from '../../admin/items/view/components/reorder-config.component';
import { ViewRelatedProductsComponent } from '../../admin/items/view/components/related-products.component';
import { ViewItemInventoryLevelsComponent } from '../../admin/items/view/components/item-inventory-levels.component';
import { AddMaterialsToJobComponent } from '../../module/purchase-orders/widgets/materials/add-materials-to-job/add-materials-to-job.component';
import { ViewItemUsageHistoryComponent } from '../../admin/items/view/components/item-usage-history';
import { ItemListPaginationComponent } from '../../admin/items/view/components/item-list-pagination.component';
import { CommonPaginationModule } from '../components/listing/common/common-pagiation.module';
import { SharedInputModule } from './shared-input.module';
import { ViewSupplierInventoryComponent } from '../../admin/items/view-supplier-inventory/view-supplier-inventory.component';
import { CreateFromWorkOrderComponent } from '../../module/purchase-orders/widgets/materials/create-from-work-order/create-from-work-order.component';
import { TagListModule } from '../../features/tags/components/tag-list/tag-list.module';
import { StockReceiptsComponent } from '../../module/purchase-orders/widgets/stock-receipts/stock-receipts.component';
import { MaterialsComponent as JobMaterials } from '../../module/jobs/materials/materials.component';
import { MaterialsComponent as PurchaseOrderMaterials } from '../../module/purchase-orders/widgets/materials/materials.component';
import { IsAuditingModuleSupportedDirective, PreviewRecordHistoryDialogComponent, ViewRecordChangeHistoryButtonComponent, ViewRecordChangeHistoryDialogComponent } from '../../features/audit-trail/shared';
import { DateModule } from '../components/forms/input/date/date.module';
import { SharedAuditTrailModule } from '../../features/audit-trail/shared-audit-trail.module';
import { ViewWarehouseStockLevelsComponent } from '../../admin/warehouse/view/warehouse-stock-levels/view-warehouse-stock-levels.component';
import { StickyNotesComponent } from '../components/widget/activities/sticky-notes/sticky-notes.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    EditModule,
    SharedModule.forRoot(),
    MatExpansionModule,
    FormTemplateSharedModule,
    AccountingSystemSharedModule,
    NgDragDropModule.forRoot(),
    DragDropModule,
    SafetyManagementSharedModule,
    WorkOrderItemsModule,
    ClipboardModule,
    CommonPaginationModule,
    SharedInputModule,
    TagListModule,
    MatTreeModule,
    MatIconModule,
    DateModule,
    SharedAuditTrailModule.forChild(),
    ActivityModule,
    SharedCalendarModule,
  ],
  declarations: [
    ViewComponent,
    ConvertdialogComponent,
    ConvertOpportunityComponent,
    ConvertOpportunityFilesComponent,
    ConvertedDataComponent,
    StatuswidgetComponent,
    LineItemsComponent,
    OptionComponent,
    AnnualConditionReportComponent,
    OptionComponent,
    ReasonDialogComponent,
    PreferredSupplierDialogComponent,
    BackToListComponent,
    ViewActionsButtonComponent,
    ViewCustomFieldComponent,
    ViewItemCodesComponent,
    ViewReorderConfigComponent,
    ViewRelatedProductsComponent,
    ViewItemInventoryLevelsComponent,
    AddMaterialsToJobComponent,
    ViewItemUsageHistoryComponent,
    ItemListPaginationComponent,
    ViewSupplierInventoryComponent,
    CreateFromWorkOrderComponent,
    AssetHierarchicalViewComponent,
    SchedulesComponent,
    JobMaterials,
    PurchaseOrderMaterials,
    StockReceiptsComponent,
    IsAuditingModuleSupportedDirective,
    ViewRecordChangeHistoryButtonComponent,
    ViewRecordChangeHistoryDialogComponent,
    PreviewRecordHistoryDialogComponent,
    ViewWarehouseStockLevelsComponent,
    StickyNotesComponent,
  ],
  entryComponents: [
    ConvertOpportunityComponent,
    ConvertOpportunityFilesComponent,
    ConvertdialogComponent,
    ConvertedDataComponent,
    LineItemsComponent,
    OptionComponent,
    AnnualConditionReportComponent,
    ReasonDialogComponent,
    PreferredSupplierDialogComponent,
    ViewCustomFieldComponent,
    ViewItemCodesComponent,
    ViewReorderConfigComponent,
    ViewRelatedProductsComponent,
    ViewItemInventoryLevelsComponent,
    AddMaterialsToJobComponent,
    ViewItemUsageHistoryComponent,
    ItemListPaginationComponent,
    ViewSupplierInventoryComponent,
    CreateFromWorkOrderComponent,
    AssetHierarchicalViewComponent,
    SchedulesComponent,
    JobMaterials,
    PurchaseOrderMaterials,
    StockReceiptsComponent,
    ViewRecordChangeHistoryDialogComponent,
    PreviewRecordHistoryDialogComponent,
    ViewWarehouseStockLevelsComponent
  ],
  exports: [
    ViewComponent,
    ConvertOpportunityComponent,
    ConvertOpportunityFilesComponent,
    ConvertdialogComponent,
    ConvertedDataComponent,
    StatuswidgetComponent,
    LineItemsComponent,
    OptionComponent,
    AnnualConditionReportComponent,
    ReasonDialogComponent,
    PreferredSupplierDialogComponent,
    DragDropModule,
    JobMaterials,
    PurchaseOrderMaterials,
    StockReceiptsComponent,
  ],
  providers: [
    ViewService,
    LambdaService,
    AssetService,
  ]
})
export class ViewModule { }
