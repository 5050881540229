import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PaginatedData } from '../../../module/acs-support/acs-support';
import { CalendarApiService } from '../../../module/calendar/services/calendar-api.service';
import { DateService } from '../../../services/helpers/date.service';
import { NotificationService } from '../../../services/notification.service';
import { RecordService } from '../../../services/record.service';
import { ActivitiesService } from '../../../services/activities.service';
import { WidgetService } from '../../../services/widget.service';
import { SchedulerTaskTabbedData } from '../objects/scheduler-task-tabbed-data';
import { cloneDeep, get } from 'lodash-es';
import { filter, switchMap } from 'rxjs/operators';
import { Users } from '../../../objects/users';
import { TaskComponent } from '../../../shared/components/widget/activities/task/task.component';
import { LooseObject } from '../../../objects/loose-object';
import * as moment from 'moment';
import { filled, isId } from '../../../shared/utils/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-schedules-tab',
  templateUrl: './schedules-tab.component.html',
  styleUrls: ['./schedules-tab.component.scss'],
})
export class SchedulesTabComponent implements OnInit, OnDestroy {

  /**
   * The default assigned user when creating a schedule. Only has value in create mode.
   *
   * @type {ScheduleDefaultAssignedUser}
   */
  @Input('default-user-on-create') defaultUser: Users;

  @Input('activity-id') strActivityId: string|null = null;

  @Input('job-id') strJobId: string|null = null;

  @Input('opportunity-id') strOpportunityId: string|null = null;

  @Input('has-padding') bHasPadding: boolean = false;

  @Input('metadata-type') strMetadataType: string;

  @Input('viewing-from-task-dialog') bViewingFromTaskDialog: boolean = false;

  @Input('viewing-from-record-view') bViewingFromRecordView: boolean = false;

  @Output() reloadRecordView: EventEmitter<boolean> = new EventEmitter();

  bRelatedSchedulesLoading: boolean = false;

  bScheduleFormOpen: boolean = false;

  objRelatedSchedulesData: PaginatedData;

  objSelectedSchedule: SchedulerTaskTabbedData = null;

  private _arSubscriptions: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    protected calendarServiceApi: CalendarApiService,
    protected dateService: DateService,
    protected notifService: NotificationService,
    protected recordService: RecordService,
    protected activitiesService: ActivitiesService,
    protected widgetService: WidgetService,
  ) { }

  ngOnInit() {
    this.getSchedules();
  }

  ngOnDestroy(): void {
    this._arSubscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }

  getSchedules(numPage: number = 1): void {
    this.bRelatedSchedulesLoading = true;

    const strJobParam: string|null = this.strMetadataType === 'task'
      ? null
      : this.strJobId;

    const strOppParam: string|null = this.strMetadataType === 'task'
      ? null
      : this.strOpportunityId;

    this.calendarServiceApi.getRelatedSchedules(
      this.strActivityId,
      strJobParam,
      strOppParam,
      numPage
    ).subscribe((res) => {
      this.objRelatedSchedulesData = res;
      this.objRelatedSchedulesData['data'] = cloneDeep(this.objRelatedSchedulesData['data']).map(item => {
        return new SchedulerTaskTabbedData(item);
      });

      this.bRelatedSchedulesLoading = false;
    })
  }

  deleteSchedule(schedule: SchedulerTaskTabbedData): void {
    this.notifService.sendConfirmation('confirm_delete').pipe(
      filter(confirmation => confirmation.answer === true),
      switchMap(() => this.recordService.deleteRecord('scheduler_tasks', schedule.id)),
    ).subscribe(
      res => {
        this.notifService.notifySuccess('deleted_successful');

        this._reloadSchedules();
      },
      err => {
        this.notifService.notifyError('header_notification.generic_fail');
      }
    );
  }

  openScheduleForm(schedule: SchedulerTaskTabbedData): void {
    this.objSelectedSchedule = schedule;
    this.bScheduleFormOpen = true;
  }

  closeScheduleForm(bDidSaveForm: boolean): void {
    this.objSelectedSchedule = null;
    this.bScheduleFormOpen = false;

    if (bDidSaveForm) {
      this._reloadSchedules();
    }
  }

  formatDate(strDate: string): string {
    const utcTime = moment.utc(strDate);
    const local = this.dateService.convertUtcToLocal(utcTime);

    return local.format('lll');
  }

  openTaskDialog(schedule: SchedulerTaskTabbedData): void {
    const dialogData: LooseObject = {
      width: '700px',
      height: 'auto',
      // Data to be passed on
      data: {
        module : this._getModule(),
        record_id : this._getModuleId(),
        record_data: this._getRecordData(schedule),
        view_type : 'edit',
        activity_id: schedule.activity_id
      },
      disableClose: true
    };

    const sub: Subscription = this.dialog
      .open(TaskComponent, dialogData)
      .afterClosed()
      .pipe(
        filter(data => filled(data) && data !== 'cancel' && get(data, 'mode') === 'edit'),
        switchMap(data => this.activitiesService.updateActivity(JSON.stringify(data['data'])))
      )
      .subscribe(data => {
        this.notifService.notifySuccess('activity_updated');
        this._reloadSchedules();
      });

    this._arSubscriptions.push(sub);
  }

  private _reloadSchedules(): void {
    if (this.bViewingFromRecordView) {
      setTimeout(() => {
        this.widgetService.reloadWidgetList.next(true);
        this.getSchedules();
      }, 2000);
    } else {
      setTimeout(() => {
        this.getSchedules();
      }, 2000);
    }
  }

  private _getRecordData(schedule: SchedulerTaskTabbedData): LooseObject {
    if (filled(this.strJobId)) {
      return schedule.job;
    }

    if (filled(this.strOpportunityId)) {
      return schedule.opportunity;
    }

    return {};
  }

  private _getModule(): string|null {
    if (filled(this.strJobId)) {
      return 'jobs';
    }

    if (filled(this.strOpportunityId)) {
      return 'opportunities';
    }

    return null;
  }

  private _getModuleId(): string|null {
    if (isId(this.strJobId)) {
      return this.strJobId;
    }

    if (isId(this.strOpportunityId)) {
      return this.strOpportunityId;
    }

    return null;
  }
}