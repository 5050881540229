import { isError, attempt } from "lodash";

/**
 * Parses the given json string into native type
 *
 * @deprecated use safely_parse_json from ./common.ts
 *
 * @param {string} value
 * @param {any} fallback
 *
 * @returns {any}
 */
export const parse = <T>(json: string, fallback: any = undefined): object | any[] | any | T => {
  const parsed = attempt((value) => JSON.parse(value), json);

  return ! isError(parsed) ? parsed : fallback;
}
