<div ngSwitch [ngSwitch]="true">
  <div *ngSwitchCase="strModule == 'customer_invoices'">
    <!-- Time and Materials Table-->
    <div *ngIf="strInvoicingType == 'time_and_materials'">

      <table *ngIf="bHasEnabledGroupBy" class="table table-bordered" width="100%" id="materials">
          <thead>
            <tr>
              <th class="text-center" colspan="6" scope="col" style="font-size: 12px;">{{ 'time_entries' | translate }}</th>
            </tr>
          </thead>
          <tbody *ngIf="arGroupTimeEntries.length > 0">
            <ng-container *ngFor="let objGroupedEntries of arGroupTimeEntries">
              <tr class="bg-primary text-white">
                <td colspan="3">
                  <label matTooltip="{{ 'technician' | translate }}"> {{ 'technician' | translate }}</label>
                  <div class="row">
                    <div class="col-12">
                      {{ objGroupedEntries.user_text }}
                    </div>
                  </div>
                </td>
                <td colspan="3">
                  <label matTooltip="{{ 'summary' | translate }}"> {{ 'summary' | translate }}</label>
                  <div class="row">
                    <div class="col-12">
                      {{ objGroupedEntries.summary }}
                    </div>
                  </div>
                </td>
              </tr>
                <ng-container *ngFor="let lineItem of objGroupedEntries.time_entries">
                    <tr *ngIf="checkRecord(lineItem, 'time_entries')">
                      <td colspan="2" width="25%">
                        <label matTooltip="{{ 'work_date' | translate }}"> {{ 'work_date' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldWorkDate">
                                <span
                                    *ngIf="lineItem['relate_data']['time_entries']['start_time'] || lineItem['relate_data']['time_entries']['end_time']"
                                    matTooltip="{{ formatDate(lineItem['relate_data']['time_entries']['start_time']) }} ~ {{ formatDate(lineItem['relate_data']['time_entries']['end_time']) }}"
                                >
                                    {{ formatDate(lineItem['relate_data']['time_entries']['start_time']) }} ~ {{ formatDate(lineItem['relate_data']['time_entries']['end_time']) }}
                                </span>
                                <span *ngIf="!lineItem['relate_data']['time_entries']['start_time'] && !lineItem['relate_data']['time_entries']['end_time']"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td width="15%">
                          <label matTooltip="{{ 'actual_hours' | translate }}"> {{ 'actual_hours' | translate }}</label>
                          <div class="row">
                                <div class="col-12 truncate" id="viewFieldActualHours">
                                    <span *ngIf="lineItem['relate_data']['time_entries']['actual_duration']" matTooltip="{{ lineItem['relate_data']['time_entries']['actual_duration'] }}">
                                        {{ lineItem['relate_data']['time_entries']['actual_duration'] }}
                                    </span>
                                    <span *ngIf="!lineItem['relate_data']['time_entries']['actual_duration']"> -- </span>
                                </div>
                            </div>
                      </td>
                      <td width="15%">
                        <label matTooltip="{{ 'billable_hours' | translate }}"> {{ 'billable_hours' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldBillableHours">
                                <span *ngIf="lineItem['relate_data']['time_entries']['billed_duration']" matTooltip="{{ lineItem['relate_data']['time_entries']['billed_duration'] }}">
                                    {{ lineItem['relate_data']['time_entries']['billed_duration'] }}
                                </span>
                                <span *ngIf="!lineItem['relate_data']['time_entries']['billed_duration']"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td width="15%">
                        <label matTooltip="{{ 'unit_price' | translate }}"> {{ 'unit_price' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldUnitPrice">
                                <span *ngIf="checkRecord(lineItem['unit_price'], '')" matTooltip="{{ lineItem['unit_price'] | currency: {decimal_places: 4} }}">
                                  {{ lineItem['unit_price'] | currency: {decimal_places: 4} }}
                                </span>
                                <span *ngIf="checkRecord(lineItem['unit_price'], '') == false"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td width="15%">
                          <label matTooltip="{{ 'line_total' | translate }}"> {{ 'line_total' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldLineTotal">
                                    <span *ngIf="lineItem['relate_data']['time_entries']['billed_duration']" matTooltip="{{ (lineItem['relate_data']['time_entries']['billed_duration'] * lineItem['unit_price']) | currency: {decimal_places: 4} }}">
                                      {{ (lineItem['relate_data']['time_entries']['billed_duration'] * lineItem['unit_price']) | currency }}
                                    </span>
                                    <span *ngIf="!lineItem['relate_data']['time_entries']['billed_duration']"> -- </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="checkRecord(lineItem, 'time_entries')" >
                      <td >
                        <label matTooltip="{{ 'description' | translate }}"> {{ 'description' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldDescription">
                                <span *ngIf="lineItem['relate_data']['time_entries']['description']" matTooltip="{{ lineItem['relate_data']['time_entries']['description'] }}">
                                    {{ lineItem['relate_data']['time_entries']['description'] }}
                                </span>
                                <span *ngIf="!lineItem['relate_data']['time_entries']['description']"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td colspan="2">
                        <label matTooltip="{{ 'actual_billing_category' | translate }}"> {{ 'actual_billing_category' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldActualBillingCategory">
                                <span *ngIf="checkRecord(lineItem['item_name'], '')" matTooltip="{{ lineItem['item_name'] }}">
                                    {{ lineItem['item_name'] }}
                                </span>
                                <span *ngIf="!checkRecord(lineItem['item_name'], '')"> -- </span>
                            </div>
                        </div>
                      </td>


                          <td>
                            <label matTooltip="{{ 'tax_code' | translate }}"> {{ 'tax_code' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldTaxCode">
                                    <span *ngIf="checkRecord(lineItem['tax_code_name'], '')" matTooltip="{{ lineItem['tax_code_name'] }}">
                                        {{ lineItem['tax_code_name'] }}
                                    </span>
                                    <span *ngIf="!checkRecord(lineItem['tax_code_name'])"> -- </span>
                                </div>
                            </div>
                          </td>

                          <td>
                            <label matTooltip="{{ 'account_code' | translate }}"> {{ 'account_code' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldAccountCode">
                                    <span *ngIf="checkRecord(lineItem['account_code_name'])" matTooltip="{{ lineItem['account_code_name'] }}">
                                        {{ lineItem['account_code_name'] }}
                                    </span>
                                    <span *ngIf="!checkRecord(lineItem['account_code_name'])"> -- </span>
                                </div>
                            </div>
                          </td>

                          <td>
                            <label matTooltip="{{ 'exclude_from_invoice' | translate }}"> {{ 'exclude_from_invoice' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldExcludeFromInvoice">
                                  <span *ngIf="lineItem['relate_data']['time_entries']['exclude_from_invoice'] != undefined" matTooltip="{{ (lineItem['relate_data']['time_entries']['exclude_from_invoice']) ? 'Yes' : 'No' }}">
                                    {{ (lineItem['relate_data']['time_entries']['exclude_from_invoice']) ? 'Yes' : 'No' }}
                                </span>
                                </div>
                            </div>
                          </td>
                    </tr>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!bHasTimeEntryLineItem">
                <tr>
                    <td colspan="6" align="center">{{ 'no_invoice_line' | translate }}</td>
                </tr>
            </ng-container>
          </tbody>
          <tbody *ngIf="arGroupTimeEntries.length < 1">
                <tr>
                    <td colspan="6" align="center">{{ 'no_invoice_line' | translate }}</td>
                </tr>
          </tbody>
      </table>

     <!-- Time Entries Table-->
      <table *ngIf="!bHasEnabledGroupBy" class="table table-bordered main_content" width="100%">
          <thead>
            <tr>
              <th class="text-center" colspan="6" scope="col" style="font-size: 12px;">{{ 'time_entries' | translate }}</th>
            </tr>
          </thead>
          <tbody *ngIf="currentFieldData.length > 0">
            <ng-container *ngFor="let lineItem of currentFieldData">
                <ng-container *ngIf="checkRecord(lineItem, 'time_entries')">
                    <tr>
                      <td width="16%">
                        <label matTooltip="{{ 'technician' | translate }}"> {{ 'technician' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldTechnician">
                                <span *ngIf="lineItem['relate_data']['time_entries']['user_text']" matTooltip="{{ lineItem['relate_data']['time_entries']['user_text'] }}"> {{ lineItem['relate_data']['time_entries']['user_text'] }} </span>
                                <span *ngIf="!lineItem['relate_data']['time_entries']['user_text']"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td width="20%">
                        <label matTooltip="{{ 'work_date' | translate }}"> {{ 'work_date' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldWorkDate">
                                <span
                                    *ngIf="lineItem['relate_data']['time_entries']['start_time'] || lineItem['relate_data']['time_entries']['end_time']"
                                    matTooltip="{{ formatDate(lineItem['relate_data']['time_entries']['start_time']) }} ~ {{ formatDate(lineItem['relate_data']['time_entries']['end_time']) }}"
                                >
                                    {{ formatDate(lineItem['relate_data']['time_entries']['start_time']) }} ~ {{ formatDate(lineItem['relate_data']['time_entries']['end_time']) }}
                                </span>
                                <span *ngIf="!lineItem['relate_data']['time_entries']['start_time'] && !lineItem['relate_data']['time_entries']['end_time']"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td width="15%">
                          <label matTooltip="{{ 'actual_hours' | translate }}"> {{ 'actual_hours' | translate }}</label>
                          <div class="row">
                                <div class="col-12 truncate" id="viewFieldActualHours">
                                    <span *ngIf="lineItem['relate_data']['time_entries']['actual_duration']" matTooltip="{{ lineItem['relate_data']['time_entries']['actual_duration'] }}">
                                        {{ lineItem['relate_data']['time_entries']['actual_duration'] }}
                                    </span>
                                    <span *ngIf="!lineItem['relate_data']['time_entries']['actual_duration']"> -- </span>
                                </div>
                            </div>
                      </td>
                      <td width="15%">
                        <label matTooltip="{{ 'billable_hours' | translate }}"> {{ 'billable_hours' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldBillableHours">
                                <span *ngIf="lineItem['relate_data']['time_entries']['billed_duration']" matTooltip="{{ lineItem['relate_data']['time_entries']['billed_duration'] }}">
                                    {{ lineItem['relate_data']['time_entries']['billed_duration'] }}
                                </span>
                                <span *ngIf="!lineItem['relate_data']['time_entries']['billed_duration']"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td width="17%">
                        <label matTooltip="{{ 'unit_price' | translate }}"> {{ 'unit_price' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldUnitPrice">
                                <span *ngIf="checkRecord(lineItem['unit_price'])" matTooltip="{{ lineItem['unit_price'] | currency: {decimal_places: 4} }}">
                                  {{ lineItem['unit_price'] | currency: {decimal_places: 4} }}
                                </span>
                                <span *ngIf="checkRecord(lineItem['unit_price']) == false"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td width="17%">
                          <label matTooltip="{{ 'line_total' | translate }}"> {{ 'line_total' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldLineTotal">
                                    <span *ngIf="lineItem['relate_data']['time_entries']['billed_duration']" matTooltip="{{ (lineItem['relate_data']['time_entries']['billed_duration'] * lineItem['unit_price']) | currency: {decimal_places: 4} }}">
                                      {{ (lineItem['relate_data']['time_entries']['billed_duration'] * lineItem['unit_price']) | currency }}
                                    </span>
                                    <span *ngIf="!lineItem['relate_data']['time_entries']['billed_duration']"> -- </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label matTooltip="{{ 'description' | translate }}"> {{ 'description' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldDescription">
                                <span *ngIf="lineItem['relate_data']['time_entries']['description']" matTooltip="{{ lineItem['relate_data']['time_entries']['description'] }}">
                                    {{ lineItem['relate_data']['time_entries']['description'] }}
                                </span>
                                <span *ngIf="!lineItem['relate_data']['time_entries']['description']"> -- </span>
                            </div>
                        </div>
                      </td>

                      <td>
                        <label matTooltip="{{ 'actual_billing_category' | translate }}"> {{ 'actual_billing_category' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldActualBillingCategory">
                                <span *ngIf="checkRecord(lineItem['item_name'])" matTooltip="{{ lineItem['item_name'] }}">
                                    {{ lineItem['item_name'] }}
                                </span>
                                <span *ngIf="!checkRecord(lineItem['item_name'])"> -- </span>
                            </div>
                        </div>
                      </td>

                          <td>
                            <label matTooltip="{{ 'tax_code' | translate }}"> {{ 'tax_code' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldTaxCode">
                                    <span *ngIf="checkRecord(lineItem['tax_code_name'])" matTooltip="{{ lineItem['tax_code_name'] }}">
                                        {{ lineItem['tax_code_name'] }}
                                    </span>
                                    <span *ngIf="!checkRecord(lineItem['tax_code_name'])"> -- </span>
                                </div>
                            </div>
                          </td>

                          <td>
                            <label matTooltip="{{ 'account_code' | translate }}"> {{ 'account_code' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldAccountCode">
                                    <span *ngIf="checkRecord(lineItem['account_code_name'])" matTooltip="{{ lineItem['account_code_name'] }}">
                                        {{ lineItem['account_code_name'] }}
                                    </span>
                                    <span *ngIf="!checkRecord(lineItem['account_code_name'])"> -- </span>
                                </div>
                            </div>
                          </td>

                          <td>
                            <label matTooltip="{{ 'exclude_from_invoice' | translate }}"> {{ 'exclude_from_invoice' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldExcludeFromInvoice">
                                  <span *ngIf="lineItem['relate_data']['time_entries']['exclude_from_invoice'] != undefined" matTooltip="{{ (lineItem['relate_data']['time_entries']['exclude_from_invoice']) ? 'Yes' : 'No' }}">
                                    {{ (lineItem['relate_data']['time_entries']['exclude_from_invoice']) ? 'Yes' : 'No' }}
                                </span>
                                </div>
                            </div>
                          </td>
                    </tr>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!bHasTimeEntryLineItem">
                <tr>
                    <td colspan="6" align="center">{{ 'no_invoice_line' | translate }}</td>
                </tr>
            </ng-container>
          </tbody>
          <tbody *ngIf="currentFieldData.length < 1">
                <tr>
                    <td colspan="6" align="center">{{ 'no_invoice_line' | translate }}</td>
                </tr>
          </tbody>
      </table>

      <!-- Materials Table-->
      <table class="table table-bordered main_content">
            <thead>
              <tr>
                <th class="text-center" colspan="7" scope="col" style="font-size: 12px;">{{ 'materials' | translate }}</th>
              </tr>
            </thead>
            <tbody *ngIf="currentFieldData.length > 0">
              <ng-container *ngFor="let lineItem of currentFieldData">
                <ng-container *ngIf="checkRecord(lineItem, 'materials')">
                    <tr>
                      <td width="16%">
                        <label matTooltip="{{ 'type' | translate }}"> {{ 'type' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldType">
                                <span *ngIf="lineItem['relate_data']['materials']['type']" matTooltip="{{ lineItem['relate_data']['materials']['type'] | translate }}"> {{ lineItem['relate_data']['materials']['type'] | translate }} </span>
                                <span *ngIf="!lineItem['relate_data']['materials']['type']"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td width="8%">
                        <label matTooltip="{{ 'quantity' | translate }}"> {{ 'quantity' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldQuantity">
                                <span *ngIf="lineItem['relate_data']['materials']['quantity']" matTooltip="{{ lineItem['relate_data']['materials']['quantity'] }}">
                                    {{ lineItem['relate_data']['materials']['quantity'] }}
                                </span>
                                <span *ngIf="!lineItem['relate_data']['materials']['quantity']"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td width="12%">
                          <label matTooltip="{{ 'unit_cost' | translate }}"> {{ 'unit_cost' | translate }}</label>
                          <div class="row">
                                <div class="col-12 truncate" id="viewFieldUnitCost">
                                    <span *ngIf="lineItem['relate_data']['materials']['unit_cost']" matTooltip="{{ lineItem['relate_data']['materials']['unit_cost'] | currency: {decimal_places: 4} }}">
                                      {{ lineItem['relate_data']['materials']['unit_cost'] | currency: {decimal_places: 4} }}
                                    </span>
                                </div>
                            </div>
                      </td>
                      <td width="15%">
                        <label matTooltip="{{ 'unit_price' | translate }}"> {{ 'unit_price' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldUnitPrice">
                                <span *ngIf="lineItem['relate_data']['materials']['unit_price']" matTooltip="{{ lineItem['relate_data']['materials']['unit_price'] | currency: {decimal_places: 4} }}">
                                  {{ lineItem['relate_data']['materials']['unit_price'] | currency: {decimal_places: 4} }}
                                </span>
                                <span *ngIf="!lineItem['relate_data']['materials']['unit_price']"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td width="15%">
                        <label matTooltip="{{ 'markup' | translate }}"> {{ 'markup' | translate }}</label>
                        <div class="row">
                            <div class="col-12 truncate" id="viewFieldMarkup">
                                <span *ngIf="lineItem['relate_data']['materials']['markup']" matTooltip="{{ lineItem['relate_data']['materials']['markup'] }}">
                                    {{ lineItem['relate_data']['materials']['markup'] }}
                                </span>
                                <span *ngIf="!lineItem['relate_data']['materials']['markup']"> -- </span>
                            </div>
                        </div>
                      </td>
                      <td width="17%">
                          <label matTooltip="{{ 'total_cost' | translate }}"> {{ 'total_cost' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldTotalCost">
                                    <span *ngIf="lineItem['relate_data']['materials']['total_cost']" matTooltip="{{ lineItem['relate_data']['materials']['total_cost'] | currency: {decimal_places: 4} }}">
                                      {{ lineItem['relate_data']['materials']['total_cost'] | currency }}
                                    </span>
                                    <span *ngIf="!lineItem['relate_data']['materials']['total_cost']"> -- </span>
                                </div>
                            </div>
                        </td>
                        <td width="17%">
                            <label matTooltip="{{ 'total_price' | translate }}"> {{ 'total_price' | translate }}</label>
                              <div class="row">
                                  <div class="col-12 truncate" id="viewFieldTotalPrice">
                                      <span *ngIf="lineItem['relate_data']['materials']['total_price']" matTooltip="{{ lineItem['relate_data']['materials']['total_price'] | currency: {decimal_places: 4} }}">
                                        {{ lineItem['relate_data']['materials']['total_price'] | currency }}
                                      </span>
                                      <span *ngIf="!lineItem['relate_data']['materials']['total_price']"> -- </span>
                                  </div>
                              </div>
                          </td>
                    </tr>
                    <tr>
                        <td>
                            <label matTooltip="{{ 'item' | translate }}"> {{ 'item' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldItem">

                                    <ng-container *ngIf="checkRecord(lineItem['item_name']) && lineItem['relate_data']['materials']['type'] == 'product_catalog'">
                                      <span>
                                          {{ lineItem['item_name'] }}
                                      </span>
                                      <a *ngIf="lineItem.item_id"
                                        href="/#/items/{{ lineItem.item_id }}"
                                        class="pl-1 pr-1 pb-2 pt-2"
                                        target="_blank">
                                        <fa-icon
                                          matTooltipClass="tooltip-space"
                                          matTooltip="
                                          {{ 'click_to_view' | translate }}&#13;&#13;
                                          {{ 'product' | translate }}: {{ lineItem.item_name }}&#13;
                                          {{ 'code' | translate }}: {{ lineItem.item_code }}"
                                          class="text-primary"
                                          [icon]="['fas', 'info-circle']">
                                        </fa-icon>
                                      </a>
                                      <fa-icon *ngIf="lineItem.item_id"
                                          [icon]="['fas', 'chart-line']"
                                          class="text-primary desktop-container pointer pl-1 pr-1 pb-2 pt-2"
                                          matTooltip="{{ 'show_stock_levels_and_supplier_pricing' | translate }}"
                                          (click)="openSupplierInventoryDialog(lineItem.item_id)"
                                        >
                                        </fa-icon>
                                    </ng-container>

                                    <span *ngIf="lineItem['relate_data']['materials']['product'] && lineItem['relate_data']['materials']['type'] == 'once_off_purchase'" matTooltip="{{ lineItem['relate_data']['materials']['product'] }}">
                                        {{ lineItem['relate_data']['materials']['product'] }}
                                    </span>
                                    <span *ngIf="!checkRecord(lineItem['item_name']) && lineItem['relate_data']['materials']['type'] == 'product_catalog'"> -- </span>
                                    <span *ngIf="!lineItem['relate_data']['materials']['product'] && lineItem['relate_data']['materials']['type'] == 'once_off_purchase'"> -- </span>
                                </div>
                            </div>
                          </td>

                          <td colspan="2">
                            <label matTooltip="{{ 'notes' | translate }}"> {{ 'notes' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldNotes">
                                    <span *ngIf="lineItem['relate_data']['materials']['notes']" matTooltip="{{ lineItem['relate_data']['materials']['notes'] }}">
                                        {{ lineItem['relate_data']['materials']['notes'] }}
                                    </span>
                                    <span *ngIf="!lineItem['relate_data']['materials']['notes']"> -- </span>
                                </div>
                            </div>
                          </td>

                          <td>
                            <label matTooltip="{{ 'tax_code' | translate }}"> {{ 'tax_code' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldTaxCode">
                                    <span *ngIf="checkRecord(lineItem['tax_code_name'])" matTooltip="{{ lineItem['tax_code_name'] }}">
                                        {{ lineItem['tax_code_name'] }}
                                    </span>
                                    <span *ngIf="!checkRecord(lineItem['tax_code_name'])"> -- </span>
                                </div>
                            </div>
                          </td>

                          <td colspan="2">
                            <label matTooltip="{{ 'account_code' | translate }}"> {{ 'account_code' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldAccountCode">
                                    <span *ngIf="checkRecord(lineItem['account_code_name'])" matTooltip="{{ lineItem['account_code_name'] }}">
                                        {{ lineItem['account_code_name'] }}
                                    </span>
                                    <span *ngIf="!checkRecord(lineItem['account_code_name'])"> -- </span>
                                </div>
                            </div>
                          </td>

                          <td>
                            <label matTooltip="{{ 'exclude_from_invoice' | translate }}"> {{ 'exclude_from_invoice' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldExcludeFromInvoice">
                                    <span *ngIf="lineItem['relate_data']['materials']['exclude_from_invoice'] != undefined" matTooltip="{{ (lineItem['relate_data']['materials']['exclude_from_invoice']) ? 'Yes' : 'No' }}">
                                        {{ (lineItem['relate_data']['materials']['exclude_from_invoice']) ? 'Yes' : 'No' }}
                                    </span>
                                </div>
                            </div>
                          </td>
                    </tr>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!bHasMaterialLineItem">
                    <tr>
                        <td colspan="6" align="center">{{ 'no_invoice_line' | translate }}</td>
                    </tr>
                </ng-container>
            </tbody>
            <tbody *ngIf="currentFieldData.length < 1">
                <tr>
                    <td colspan="7" align="center">{{ 'no_invoice_line' | translate }}</td>
                </tr>
            </tbody>
      </table>
    </div>
    <!-- Flexible, Fixed Price and Milestone Invoicing Table-->
    <div *ngIf="strInvoicingType != 'time_and_materials'">
        <table class="table table-bordered main_content">
                <thead>
                    <tr>
                    <th class="text-center" colspan="5" scope="col" style="font-size: 12px;">{{ 'invoice_line_items' | translate }}</th>
                    </tr>
                </thead>
                <tbody *ngIf="currentFieldData.length > 0">
                    <ng-container *ngFor="let lineItem of currentFieldData">
                        <ng-container *ngIf="checkRecord(lineItem)">
                            <tr>
                                <td>
                                    <label matTooltip="{{ 'item' | translate }}"> {{ 'item' | translate }}</label>
                                    <div class="row">
                                        <div class="col-12 truncate" id="viewFieldItem">
                                            <ng-container *ngIf="checkRecord(lineItem['item_name'])">
                                              <span>
                                                  {{ lineItem['item_name'] }}
                                              </span>
                                              <a *ngIf="lineItem.item_id"
                                                href="/#/items/{{ lineItem.item_id }}"
                                                class="pl-1 pr-1 pb-2 pt-2"
                                                target="_blank">
                                                <fa-icon
                                                  matTooltipClass="tooltip-space"
                                                  matTooltip="
                                                  {{ 'click_to_view' | translate }}&#13;&#13;
                                                  {{ 'product' | translate }}: {{ lineItem.item_name }}&#13;
                                                  {{ 'code' | translate }}: {{ lineItem.item_code }}"
                                                  class="text-primary"
                                                  [icon]="['fas', 'info-circle']">
                                                </fa-icon>
                                              </a>
                                              <fa-icon *ngIf="lineItem.item_id"
                                                  [icon]="['fas', 'chart-line']"
                                                  class="text-primary desktop-container pointer pl-1 pr-1 pb-2 pt-2"
                                                  matTooltip="{{ 'show_stock_levels_and_supplier_pricing' | translate }}"
                                                  (click)="openSupplierInventoryDialog(lineItem.item_id)"
                                                >
                                                </fa-icon>
                                            </ng-container>
                                            <span *ngIf="!checkRecord(lineItem['item_name'])"> -- </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <label matTooltip="{{ 'description' | translate }}"> {{ 'description' | translate }}</label>
                                    <div class="row">
                                        <div class="col-12 truncate" id="viewFieldDescription">
                                            <span *ngIf="checkRecord(lineItem['description'])" matTooltip="{{ lineItem['description'] }}">
                                                {{ lineItem['description'] }}
                                            </span>
                                            <span *ngIf="checkRecord(lineItem['description']) == false"> -- </span>
                                        </div>
                                    </div>
                                </td>
                                <td width="17%">
                                    <label matTooltip="{{ 'quantity' | translate }}"> {{ 'quantity' | translate }}</label>
                                    <div class="row">
                                        <div class="col-12 truncate" id="viewFieldQuantity">
                                            <span *ngIf="checkRecord(lineItem['quantity'])" matTooltip="{{ lineItem['quantity'] }}">
                                                {{ lineItem['quantity'] }}
                                            </span>
                                            <span *ngIf="checkRecord(lineItem['quantity']) == false"> -- </span>
                                        </div>
                                    </div>
                                </td>
                                <td width="17%">
                                    <label matTooltip="{{ 'unit_price' | translate }}"> {{ 'unit_price' | translate }}</label>
                                    <div class="row">
                                        <div class="col-12 truncate" id="viewFieldUnitPrice">
                                            <span *ngIf="checkRecord(lineItem['unit_price'], '')" matTooltip="{{ lineItem['unit_price'] | currency: {decimal_places: 4} }}">
                                              {{ lineItem['unit_price'] | currency: {decimal_places: 4} }}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td width="17%">
                                    <label matTooltip="{{ 'amount' | translate }}"> {{ 'amount' | translate }}</label>
                                    <div class="row">
                                        <div class="col-12 truncate" id="viewFieldAmount">
                                            <span matTooltip="{{ lineItem | computeAmount | currency: {decimal_places: 4} }}">
                                              {{ lineItem | computeAmount | currency }}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td *ngIf="bHasDepartmentTracking" colspan="2">
                                    <label matTooltip="{{ 'department' | translate }}"> {{ 'department' | translate }}</label>
                                    <div class="row">
                                    <div class="col-12 truncate" id="viewFieldDepartment">
                                        <span *ngIf="checkRecord(lineItem['department_name'])" matTooltip="{{ lineItem['department_name'] }}">
                                            {{ lineItem['department_name'] }}
                                        </span>
                                        <span *ngIf="checkRecord(lineItem['department_name']) == false"> -- </span>
                                    </div>
                                    </div>
                                </td>
                                <td colspan="2">
                                    <label matTooltip="{{ 'tax_code' | translate }}"> {{ 'tax_code' | translate }}</label>
                                    <div class="row">
                                        <div class="col-12 truncate" id="viewFieldTaxCode">
                                            <span *ngIf="checkRecord(lineItem['tax_code_name'])" matTooltip="{{ lineItem['tax_code_name'] }}">
                                                {{ lineItem['tax_code_name'] }}
                                            </span>
                                            <span *ngIf="checkRecord(lineItem['tax_code_name']) == false"> -- </span>
                                        </div>
                                    </div>
                                </td>
                                <td [attr.colspan]="bHasDepartmentTracking ? 1 : 3">
                                    <label matTooltip="{{ 'account_code' | translate }}"> {{ 'account_code' | translate }}</label>
                                    <div class="row">
                                        <div class="col-12 truncate" id="viewFieldAccountCode">
                                            <span *ngIf="checkRecord(lineItem['account_code_name'])" matTooltip="{{ lineItem['account_code_name'] }}">
                                                {{ lineItem['account_code_name'] }}
                                            </span>
                                            <span *ngIf="!checkRecord(lineItem['account_code_name'])"> -- </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!bHasInvoiceLineItem">
                        <tr>
                            <td colspan="6" align="center">{{ 'no_invoice_line' | translate }}</td>
                        </tr>
                    </ng-container>
                </tbody>
                <tbody *ngIf="currentFieldData.length < 1">
                    <tr>
                        <td colspan="5" align="center">{{ 'no_invoice_line' | translate }}</td>
                    </tr>
                </tbody>
        </table>
    </div>

    <!-- Total Computation -->
    <div class="mt-1">
        <table class="table table-bordered">
            <tbody>
            <tr>
                <th scope="row" width="66%" class="invisible"></th>
                <td scope="row" width="17%" ><span class="p-2 float-right">{{ 'total_ex_tax' | translate }}</span></td>
                <td scope="row" width="17%" class="text-center" id="viewFieldTotalExTax">
                    <span *ngIf="bHasTimeEntryLineItem || bHasMaterialLineItem || bHasInvoiceLineItem; else noLineItems">
                      {{ recordData['amount_tax_ex'] | currency }}
                    </span>
                    <ng-template #noLineItems>
                            0.00
                    </ng-template>
                </td>
            </tr>
            <tr>
                <th scope="row" class="invisible"></th>
                <td scope="row"><span class="p-2  float-right">{{ 'tax' | translate }}</span> </td>
                <td scope="row" class="text-center" id="viewFieldTax">
                    <span *ngIf="bHasTimeEntryLineItem || bHasMaterialLineItem || bHasInvoiceLineItem; else noLineItems">
                      {{ recordData['amount_tax'] | currency }}
                    </span>
                    <ng-template #noLineItems>
                            0.00
                    </ng-template>
                </td>
            </tr>
            <tr>
                <th scope="row" width="66%" class="invisible"></th>
                <td scope="row" width="19%" ><span class="p-2 float-right">{{ 'tax_adjustment_amount' | translate }}</span></td>
                <td scope="row" width="17%" class="text-center" id="viewFieldTaxAdjustment">
                  {{ recordData['tax_adjustment_amount'] | currency }}
                </td>
            </tr>
            <tr >
                <th scope="row" class="invisible"></th>
                <td scope="row"><span class="p-2  float-right">{{ 'total_inc_tax' | translate }}</span> </td>
                <td scope="row" class="text-center" id="viewFieldTotalIncTax">
                    <span *ngIf="bHasTimeEntryLineItem || bHasMaterialLineItem || bHasInvoiceLineItem; else noLineItems">
                      {{ recordData['amount_tax_inc'] | currency }}
                    </span>
                    <ng-template #noLineItems>
                            0.00
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
  </div>
  <div *ngSwitchCase="strModule == 'recurring_jobs'">
    <table *ngIf="strLabel === 'assets_items'" class="table table-bordered table-striped ">
      <thead>
      <tr>
        <th scope="col" width="15%" style="font-size: 12px;">{{ 'asset_type' | translate }}</th>
        <th scope="col" width="14%" style="font-size: 12px;">{{ 'asset_tag' | translate }}</th>
        <th scope="col" width="20%" style="font-size: 12px;">{{ 'checklist' | translate }}</th>
        <th *ngIf="bHasDepartmentTracking" scope="col" width="15%" style="font-size: 12px;">{{ 'department' | translate }}</th>
        <th scope="col" width="15%" style="font-size: 12px;">{{ 'price_per_asset' | translate }}</th>
        <th scope="col" width="20%" style="font-size: 12px;">{{ 'time_per_asset' | translate }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="recordData.assets_items.length < 1">
        <td colspan="7" class="text-center">
          <span>{{ 'no_available_items' | translate }}</span>
        </td>
      </tr>
      <ng-container *ngFor="let attr of recordData.assets_items; index as i">
        <tr>
          <td>
            <div id="viewFieldTechnician">
              <div class="form-group mb-0">
                <label>{{ 'technician' | translate }}</label>
                <ng-container *ngIf="attr.user.length; else noValue">
                  <span *ngFor="let user of attr.user" matTooltip="{{ user.text }}">{{ user.text }}</span>
                </ng-container>
              </div>
            </div>
          </td>
          <td>
            <div class="p-2" id="viewFieldAssetGroup">
              <span *ngFor="let assetGroup of attr.asset_tags" matTooltip="{{ assetGroup.name }}">
                {{ assetGroup.name}}
              </span>
            </div>
          </td>
          <td scope="row">
            <div id="viewFieldChecklist">
              <span *ngFor="let checklist of attr.checklist" matTooltip="{{ checklist.name }}">
                {{ checklist.name }}
              </span>
            </div>
          </td>
          <td *ngIf="bHasDepartmentTracking">
              <div id="viewFieldDepartment">
                <div class="form-group mb-0">
                  <label>{{ 'department' | translate }}</label>
                  <ng-container *ngIf="attr.department.length && attr.department_id; else noValue">
                    <ng-template ngFor="let department of attr.department">
                      <span *ngIf="department.id == attr.department_id" matTooltip="{{ department.department_name }}">
                        {{ department.department_name }}
                      </span>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
          </td>
          <td>
            <div class="p-2" id="viewFieldPricePerAsset">
                <span>{{ attr.price_per_asset }}</span>
            </div>
          </td>
          <td>
            <div class="p-2" id="viewFieldTimePerAsset">
                <span>{{ attr.time_per_asset }}</span>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>

    <ng-template #noValue>
      <span>--</span>
    </ng-template>

    <table *ngIf="strLabel === 'material'" class="table table-bordered table-striped ">
      <thead>
      <tr>
        <th scope="col" width="20%" style="font-size: 12px;">{{ 'type' | translate }}</th>
        <th scope="col" width="20%" style="font-size: 12px;">{{ 'product' | translate }}</th>
        <th scope="col" width="5%" style="font-size: 12px;">{{ 'quantity' | translate }}</th>
        <th scope="col" width="12%" style="font-size: 12px;">{{ 'unit_cost' | translate }}</th>
        <th scope="col" width="12%" style="font-size: 12px;">{{ 'unit_price' | translate }}</th>
        <th scope="col" width="11%" style="font-size: 12px;">{{ 'markup' | translate }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="recordData.material_line_items.length < 1">
        <td colspan="8" class="text-center">
          <span>{{ 'no_available_items' | translate }}</span>
        </td>
      </tr>
      <ng-container *ngFor="let arLineItem of recordData.material_line_items; index as i">
        <tr>
          <td>
            <div class="p-2" id="viewFieldType">
              <span matTooltip="{{ arLineItem.type | translate }}">{{ arLineItem.type | translate }}</span>
            </div>
          </td>
          <td>
            <div class="p-2" id="viewFieldProduct">
              <ng-container *ngIf="arLineItem.type === 'product_catalog'">
                <span *ngFor="let arItem of arLineItem.item" matTooltip="{{ arItem['name'] }}">
                  {{ arItem['name'] }}
                </span>
              </ng-container>
              <ng-container *ngIf="arLineItem.type === 'once_off_purchase'">
                <span matTooltip="{{ arLineItem.product }}">
                  {{ arLineItem.product }}
                </span>
              </ng-container>
            </div>
          </td>
          <td>
            <div class="p-2" id="viewFieldQuantity">
              <span matTooltip="{{ arLineItem.quantity }}">
                {{ arLineItem.quantity }}
              </span>
            </div>
          </td>
          <td>
            <div class="p-2" id="viewFieldUnitCost">
              <span matTooltip="{{ arLineItem.unit_cost | currency: {decimal_places: 4} }}">{{ arLineItem.unit_cost | currency: {decimal_places: 4} }}</span>
            </div>
          </td>
          <td>
            <div class="p-2" id="viewFieldUnitPrice">
              <span matTooltip="{{ arLineItem.unit_price | currency: {decimal_places: 4} }}">{{ arLineItem.unit_price | currency: {decimal_places: 4} }}</span>
            </div>
          </td>
          <td>
            <div class="p-2" id="viewFieldMarkup">
              <span matTooltip="{{ arLineItem.markup }}">{{ arLineItem.markup }}</span>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>

    <table *ngIf="strLabel === 'work_order'" class="table table-bordered table-striped ">
        <thead>
        <tr>
          <th scope="col" width="14%" style="font-size: 12px;">{{ 'item' | translate }}</th>
          <th scope="col" width="18%" style="font-size: 12px;">{{ 'description' | translate }}</th>
          <th *ngIf="bHasDepartmentTracking" scope="col" width="14%" style="font-size: 12px;">{{ 'department' | translate }}</th>
          <th scope="col" width="8%" style="font-size: 12px;">{{ 'quantity' | translate }}</th>
          <th scope="col" width="11%" style="font-size: 12px;">{{ 'unit_price' | translate }}</th>
          <th scope="col" width="12%" style="font-size: 12px;">{{ 'discounted_price' | translate }}</th>
          <th scope="col" width="12%" style="font-size: 12px;">{{ 'tax_code' | translate }}</th>
          <th scope="col" width="12%" style="font-size: 12px;">{{ 'line_total' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="recordData.work_order_items.length < 1">
          <td colspan="8" class="text-center">
            <span>{{ 'no_available_items' | translate }}</span>
          </td>
        </tr>
        <ng-container *ngFor="let workOrder of recordData.work_order_items; index as i">
          <tr>
            <td>
              <div class="p-2" id="viewFieldItem">
                <span class="value-text" matTooltip="{{ workOrder.item_name | translate | placeholdWithString }}">
                    {{ workOrder.item_name | translate | placeholdWithString }}
                </span>
              </div>
            </td>
            <td>
              <div class="p-2" id="viewFieldDescription">
                <span class="value-text" matTooltip="{{ workOrder.description }}">
                    {{ workOrder.description }}
                </span>
              </div>
            </td>
            <td *ngIf="bHasDepartmentTracking">
              <div class="p-2" id="viewFieldDepartment">
                  <span class="value-text" matTooltip="{{ workOrder.department_name }}">
                    {{ workOrder.department_name }}
                  </span>
                </div>
              </td>
            <td scope="row">
              <div id="viewFieldQuantity">
                <span class="value-text" matTooltip="{{ workOrder.quantity }}">
                    {{ workOrder.quantity }}
                </span>
              </div>
            </td>
            <td>
              <div class="p-2" id="viewFieldUnitPrice">
                <span class="value-text" matTooltip="{{ workOrder.unit_price | currency: {decimal_places: 4} }}">
                  {{ workOrder.unit_price | currency: {decimal_places: 4} }}
                </span>
              </div>
            </td>
            <td>
              <div class="p-2" id="viewFieldDiscountedPrice">
                <span class="value-text" matTooltip="{{ workOrder.discounted_price | currency: {decimal_places: 4} }}">
                  {{ workOrder.discounted_price | currency: {decimal_places: 4} }}
                </span>
              </div>
            </td>
            <td>
              <div class="p-2" id="viewFieldTaxCode">
                <span class="value-text" matTooltip="{{ workOrder.tax_code_name }}">
                  {{ workOrder.tax_code_name }}
                </span>
              </div>
            </td>
            <td>
              <div class="p-2" id="viewFieldLineTotal">
                <span class="value-text" matTooltip="{{ workOrder.line_item | currency: {decimal_places: 4} }}">
                  {{ workOrder.line_item | currency }}
                </span>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
    </table>

    <!-- Total Computation -->
    <div *ngIf="strLabel === 'work_order'" class="mt-1">
      <table class="table table-bordered">
        <tbody>
          <tr>
              <th scope="row" width="66%" class="invisible"></th>
              <td scope="row" width="17%" ><span class="p-2 float-right">{{ 'total_ex_tax' | translate }}</span></td>
              <td scope="row" width="17%" class="text-center" id="viewFieldTotalExTax">
              <span>{{ intAmountWithoutTax | currency }}</span>
              </td>
          </tr>
          <tr>
              <th scope="row" class="invisible"></th>
              <td scope="row"><span class="p-2  float-right">{{ 'tax' | translate }}</span> </td>
              <td scope="row" class="text-center" id="viewFieldTax">
              <span>{{ intTax | currency }}</span>
              </td>
          </tr>
          <tr >
              <th scope="row" class="invisible"></th>
              <td scope="row"><span class="p-2  float-right">{{ 'total_inc_tax' | translate }}</span> </td>
              <td scope="row" class="text-center" id="viewFieldTotalIncTax">
              <span>{{ intAmountWithTax | currency }}</span>
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngSwitchCase="strModule == 'recurring_invoices'">
    <table class="table table-bordered main_content">
      <thead>
        <tr>
          <th class="text-center" colspan="8" scope="col" style="font-size: 12px;">{{ 'invoice_line_items' | translate }}</th>
        </tr>
      </thead>

      <!-- content -->
      <tbody>
        <!-- per line item -->
        <ng-container *ngFor="let lineItem of recordData.line_items">
          <tr>
            <td>
              <div class="p-2 form-group">
                <label>{{ 'item' | translate }}</label>

                <ng-container *ngIf="checkRecord(lineItem.item_name)">
                  <span id="viewFieldItem" class="value-text">
                    {{ lineItem.item_name | translate | placeholdWithString }}
                  </span>

                  <a *ngIf="lineItem.item_id"
                    href="/#/items/{{ lineItem.item_id }}"
                    class="pl-1 pr-1 pb-2 pt-2"
                    target="_blank">
                    <fa-icon
                      matTooltipClass="tooltip-space"
                      matTooltip="
                      {{ 'click_to_view' | translate }}&#13;&#13;
                      {{ 'product' | translate }}: {{ lineItem.item_name }}&#13;
                      {{ 'code' | translate }}: {{ lineItem.item_code }}"
                      class="text-primary"
                      [icon]="['fas', 'info-circle']">
                    </fa-icon>
                  </a>
                  <fa-icon *ngIf="lineItem.item_id"
                      [icon]="['fas', 'chart-line']"
                      class="text-primary desktop-container pointer pl-1 pr-1 pb-2 pt-2"
                      matTooltip="{{ 'show_stock_levels_and_supplier_pricing' | translate }}"
                      (click)="openSupplierInventoryDialog(lineItem.item_id)"
                    >
                  </fa-icon>
                </ng-container>

                <span *ngIf="!checkRecord(lineItem.item_name)"> -- </span>

              </div>
            </td>
            <td>
              <div class="p-2 form-group">
                <label>{{ 'description' | translate }}</label>
                <span id="viewFieldDescription" class="value-text" matTooltip="{{ lineItem.description | translate | placeholdWithString }}">
                  {{ lineItem.description | translate | placeholdWithString }}</span>
              </div>
            </td>
            <td>
              <div class="p-2 form-group">
                <label>{{ 'quantity' | translate }}</label>
                <span id="viewFieldQuantity" class="value-text" matTooltip="{{ lineItem.quantity }}"> {{ lineItem.quantity }}</span>
              </div>
            </td>
            <td>
              <div class="p-2 form-group">
                <label>{{ 'unit_price' | translate }}</label>
                <span id="viewFieldUnitPrice" class="value-text" matTooltip="{{ lineItem.unit_price | currency: {decimal_places: 4} }}">{{ lineItem.unit_price | currency: {decimal_places: 4} }}</span>
              </div>
            </td>
            <td>
              <div class="p-2 form-group">
                <label>{{ 'amount' | translate }}</label>
                <span id="viewFieldAmount" class="value-text" matTooltip="{{ lineItem.unit_price * lineItem.quantity | currency: {decimal_places: 4} }}">{{ lineItem.unit_price * lineItem.quantity | currency }}</span>
              </div>
            </td>
          </tr>

          <tr>
            <td *ngIf="bHasDepartmentTracking" colspan="2">
              <div class="p-2 form-group">
                <label>{{ 'department' | translate }}</label>
                <span id="viewFieldDepartment" class="value-text" matTooltip="{{ lineItem.department_name | translate | placeholdWithString }}">
                  {{ lineItem.department_name | translate | placeholdWithString }}</span>
              </div>
            </td>
            <td [attr.colspan]="bHasDepartmentTracking ? 1 : 3">
              <div class="p-2 form-group">
                <label>{{ 'tax_code' | translate }}</label>
                <span id="viewFieldTaxCode" class="value-text" matTooltip="{{ lineItem.tax_code_name | translate | placeholdWithString }}">
                  {{ lineItem.tax_code_name | translate | placeholdWithString }}</span>
              </div>
            </td>
            <td colspan="2">
              <div class="p-2 form-group">
                <label>{{ 'account_code' | translate }}</label>
                <span id="viewFieldAccountCode" class="value-text" matTooltip="{{ lineItem.account_code_name | translate | placeholdWithString }}">
                  {{ lineItem.account_code_name | translate | placeholdWithString }}</span>
              </div>
            </td>
          </tr>
        </ng-container>
        <!-- /end per line item -->

        <!-- no invoice content -->
        <tr *ngIf="recordData.line_items.length < 1">
          <td class="text-center">
            <span>{{ 'no_invoice_line' | translate }}</span>
          </td>
        </tr>
      </tbody>
      <!-- /end content -->
    </table>
    <table class="table table-bordered mt-1">
      <tbody>
      <tr>
        <th scope="row" width="60%" class="invisible"></th>
        <td scope="row" width="20%" ><span class="p-2 float-right">{{ 'total_ex_tax' | translate }}</span></td>
          <td scope="row" width="20%" class="text-center" id="viewFieldTotalExTax">
            <span class="p-2 form-group" matTooltip="{{ recordData['amount_tax_excluded'] | currency: {decimal_places: 4} }}">{{ recordData['amount_tax_excluded'] | currency }}</span>
        </td>
      </tr>
      <tr>
        <th scope="row" class="invisible"></th>
        <td scope="row"><span class="p-2  float-right">{{ 'tax' | translate }}</span> </td>
        <td scope="row" class="text-center" id="viewFieldAmount_tax">
            <span class="p-2 form-group" matTooltip="{{ recordData['tax'] | currency : {decimal_places: 4} }}">{{ recordData['tax'] | currency }}</span>
        </td>
      </tr>
      <tr>
        <th scope="row" width="60%" class="invisible"></th>
        <td scope="row" width="20%" ><span class="p-2 float-right">{{ 'tax_adjustment_amount' | translate }}</span></td>
        <td scope="row" width="20%" class="text-center" id="viewFieldTotalTaxAdjustment">
          <span class="p-2 form-group" matTooltip="{{ recordData['tax_adjustment_amount'] | currency: {decimal_places: 4} }}">{{ recordData['tax_adjustment_amount'] | currency }}</span>
        </td>
      </tr>
      <tr >
        <th scope="row" class="invisible"></th>
        <td scope="row"><span class="p-2  float-right">{{ 'total_inc_tax' | translate }}</span> </td>
        <td scope="row" class="text-center" id="viewFieldTotalIncTax">
            <span class="p-2 form-group" matTooltip="{{ recordData['amount_tax_included'] | currency: {decimal_places: 4} }}">{{ recordData['amount_tax_included'] | currency }} </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div *ngSwitchCase="strModule == 'purchase_orders' || strModule == 'supplier_invoices' || strModule == 'credit_notes'">
    <table class="table table-bordered main_content">
        <thead>
            <tr><th class="text-center" colspan="5" scope="col" style="font-size: 12px;">{{ 'line_items' | translate }}</th></tr>
        </thead>
        <tbody *ngIf="currentFieldData.length > 0">
            <ng-container *ngFor="let lineItem of currentFieldData">
                <ng-container *ngIf="checkRecord(lineItem)">
                    <tr>
                        <td>
                            <label matTooltip="{{ 'item' | translate }}"> {{ 'item' | translate }}</label>
                            <div class="row">
                              <div class="col-12 truncate" id="viewFieldItem">
                                <a *ngIf="checkRecord(lineItem['item_name'])" href="/#/items/{{ lineItem['item_id'] }}" target="_blank">
                                  <span
                                    matTooltipClass="tooltip-space"
                                    matTooltip="
                                    {{ 'click_to_view' | translate }}&#13;&#13;
                                    {{ 'product' | translate }}: {{ lineItem.item_name }}&#13;
                                    {{ 'code' | translate }}: {{ lineItem.item_code }}">
                                      <ng-container *ngIf="lineItem['item_code']; else noItemCode">
                                        [{{ lineItem['item_code'] }}] {{ lineItem['item_name'] }}
                                      </ng-container>
                                      <ng-template #noItemCode>
                                        {{ lineItem['item_name'] }}
                                      </ng-template>
                                  </span>
                                </a>
                                <span *ngIf="checkRecord(lineItem['item_name']) == false"> -- </span>
                                <fa-icon *ngIf="lineItem.item_id"
                                      [icon]="['fas', 'chart-line']"
                                      class="text-primary desktop-container pointer pl-1 pr-1 pb-2 pt-2"
                                      matTooltip="{{ 'show_stock_levels_and_supplier_pricing' | translate }}"
                                      (click)="openSupplierInventoryDialog(lineItem.item_id)"
                                    >
                                </fa-icon>
                              </div>
                              <div *ngIf="lineItem['job_id']" class="col-12" >
                                <a class="text-success" matTooltip="{{ 'job_number' | translate }}: {{ lineItem['job_number'] }}" [routerLink]="['/jobs/'+lineItem['job_id']]" target="_blank">
                                  <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>
                                </a>
                                <a *ngIf="lineItem['material_id']" class="text-primary pe-none pl-2 ">
                                  <fa-icon [icon]="['fas', 'box-full']"></fa-icon>
                                </a>
                              </div>
                            </div>
                        </td>
                        <td>
                            <label matTooltip="{{ 'description' | translate }}"> {{ 'description' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldDescription">
                                    <span *ngIf="checkRecord(lineItem['description'])" matTooltip="{{ lineItem['description'] }}">
                                        {{ lineItem['description'] }}
                                    </span>
                                    <span *ngIf="checkRecord(lineItem['description']) == false"> -- </span>
                                </div>
                            </div>
                        </td>
                        <td width="17%">
                            <label matTooltip="{{ 'quantity' | translate }}"> {{ 'quantity' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldQuantity">
                                    <span *ngIf="checkRecord(lineItem['quantity'])" matTooltip="{{ lineItem['quantity'] }}">
                                        {{ lineItem['quantity'] }}
                                    </span>
                                    <span *ngIf="checkRecord(lineItem['quantity']) == false"> -- </span>
                                </div>
                            </div>
                        </td>
                        <td width="17%">
                            <label matTooltip="{{ 'unit_cost' | translate }}"> {{ 'unit_cost' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldUnitCost">
                                    <span *ngIf="checkRecord(lineItem['unit_cost'])" matTooltip="{{ lineItem['unit_cost'] | currency: {decimal_places: 4} }}">
                                      {{ lineItem['unit_cost'] | currency: {decimal_places: 4} }}
                                    </span>
                                    <span *ngIf="checkRecord(lineItem['unit_cost']) == false"> -- </span>
                                </div>
                            </div>
                        </td>
                        <td width="17%">
                            <label matTooltip="{{ 'amount' | translate }}"> {{ 'amount' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldAmount">
                                    <span *ngIf="checkRecord(lineItem['total_price'])" matTooltip="{{ lineItem['total_price'] | currency: {decimal_places: 4} }}">
                                      {{ lineItem['total_price'] | currency }}
                                    </span>
                                    <span *ngIf="checkRecord(lineItem['total_price']) == false"> -- </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="bHasDepartmentTracking" [attr.colspan]="hasStockReceipt ? 1 : 2">
                            <label matTooltip="{{ 'department' | translate }}"> {{ 'department' | translate }}</label>
                            <div class="row">
                            <div class="col-12 truncate" id="viewFieldDepartment">
                                <span *ngIf="checkRecord(lineItem['department_name'])" matTooltip="{{ lineItem['department_name'] }}">
                                    {{ lineItem['department_name'] }}
                                </span>
                                <span *ngIf="checkRecord(lineItem['department_name']) == false"> -- </span>
                            </div>
                            </div>
                        </td>
                        <td [attr.colspan]="hasStockReceipt ? 1 : 2">
                            <label matTooltip="{{ 'tax_code' | translate }}"> {{ 'tax_code' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldTaxCode">
                                    <span *ngIf="checkRecord(lineItem['tax_code_name'])" matTooltip="{{ lineItem['tax_code_name'] }}">
                                        {{ lineItem['tax_code_name'] }}
                                    </span>
                                    <span *ngIf="checkRecord(lineItem['tax_code_name']) == false"> -- </span>
                                </div>
                            </div>
                        </td>
                        <td [attr.colspan]="hasStockReceipt ? 2 : bHasDepartmentTracking ? 1 : 3">
                            <label matTooltip="{{ 'account_code' | translate }}"> {{ 'account_code' | translate }}</label>
                            <div class="row">
                                <div class="col-12 truncate" id="viewFieldAccountCode">
                                    <span *ngIf="checkRecord(lineItem['account_code_name'])" matTooltip="{{ lineItem['account_code_name'] }}">
                                        {{ lineItem['account_code_name'] }}
                                    </span>
                                    <span *ngIf="!checkRecord(lineItem['account_code_name'])"> -- </span>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="hasStockReceipt" colspan="1">
                          <label matTooltip="{{ 'received' | translate }}"> {{ 'received' | translate }}</label>
                          <div class="row">
                              <div class="col-12 truncate">
                                  <span matTooltipClass="tooltip-space" matTooltip="{{ getStockReceiptToolTip(lineItem, 'stock_receipt_received') }}">
                                    {{ lineItem['stock_receipt_total_received'] || '0' }}
                                  </span>
                              </div>
                          </div>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
        <tbody *ngIf="currentFieldData.length < 1">
            <tr>
                <td colspan="5" align="center">{{ 'no_invoice_line' | translate }}</td>
            </tr>
        </tbody>
    </table>
    <!-- Total Computation -->
    <div class="mt-1">
        <table class="table table-bordered">
            <tbody>
            <tr>
                <th scope="row" width="66%" class="invisible"></th>
                <td scope="row" width="17%" ><span class="p-2 float-right">{{ 'total_ex_tax' | translate }}</span></td>
                <td scope="row" width="17%" class="text-center" id="viewFieldTotalExTax">
                  <span matTooltip="{{ recordData['amount'] | currency }}">{{ recordData['amount'] | currency }}</span>
                </td>
            </tr>
            <tr>
                <th scope="row" class="invisible"></th>
                <td scope="row"><span class="p-2  float-right">{{ 'tax' | translate }}</span> </td>
                <td scope="row" class="text-center" id="viewFieldTax">
                  <span matTooltip="{{ recordData['tax'] | currency }}">{{ recordData['tax'] | currency }}</span>
                </td>
            </tr>
            <tr>
                <th scope="row" width="66%" class="invisible"></th>
                <td scope="row" width="17%" ><span class="p-2 float-right">{{ 'tax_adjustment_amount' | translate }}</span></td>
                <td scope="row" width="17%" class="text-center" id="viewFieldTaxAdjustment">
                  <span matTooltip="{{ recordData['tax_adjustment_amount'] | currency }}">{{ recordData['tax_adjustment_amount'] | currency }}</span>
                </td>
            </tr>
            <tr >
                <th scope="row" class="invisible"></th>
                <td scope="row"><span class="p-2  float-right">{{ 'total_inc_tax' | translate }}</span> </td>
                <td scope="row" class="text-center" id="viewFieldTotalIncTax">
                  <span matTooltip="{{ recordData['amount_inc_tax'] | currency }}">{{ recordData['amount_inc_tax'] | currency }}</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
  </div>
  <div *ngSwitchCase="strModule == 'jobs'">
    <div class="mt-1">
      <table *ngIf="strLabel === 'work_order'" class="table table-bordered table-striped ">
        <thead>
        <tr>
          <th scope="col" width="14%" style="font-size: 12px;">{{ 'item' | translate }}</th>
          <th scope="col" width="18%" style="font-size: 12px;">{{ 'description' | translate }}</th>
          <th *ngIf="bHasDepartmentTracking" scope="col" width="14%" style="font-size: 12px;">{{ 'department' | translate }}</th>
          <th scope="col" width="8%" style="font-size: 12px;">{{ 'quantity' | translate }}</th>
          <th scope="col" width="11%" style="font-size: 12px;">{{ 'unit_price' | translate }}</th>
          <th scope="col" width="12%" style="font-size: 12px;">{{ 'discounted_price' | translate }}</th>
          <th scope="col" width="12%" style="font-size: 12px;">{{ 'tax_code' | translate }}</th>
          <th scope="col" width="12%" style="font-size: 12px;">{{ 'line_total' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="recordData.work_order_items.length < 1">
          <td colspan="8" class="text-center">
            <span>{{ 'no_available_items' | translate }}</span>
          </td>
        </tr>
        <ng-container *ngFor="let workOrder of recordData.work_order_items; index as i">
          <tr>
            <td>
              <div class="p-2" id="viewFieldItem">
                <span class="value-text" matTooltip="{{ workOrder.item_name | translate | placeholdWithString }}">
                    {{ workOrder.item_name | translate | placeholdWithString }}
                </span>
              </div>
            </td>
            <td>
              <div class="p-2" id="viewFieldDescription">
                <span class="value-text" matTooltip="{{ workOrder.description }}">
                    {{ workOrder.description }}
                </span>
              </div>
            </td>
            <td *ngIf="bHasDepartmentTracking">
              <div class="p-2" id="viewFieldDepartment">
                  <span class="value-text" matTooltip="{{ workOrder.department_name }}">
                    {{ workOrder.department_name }}
                  </span>
                </div>
              </td>
            <td scope="row">
              <div id="viewFieldQuantity">
                <span class="value-text" matTooltip="{{ workOrder.quantity }}">
                    {{ workOrder.quantity }}
                </span>
              </div>
            </td>
            <td>
              <div class="p-2" id="viewFieldUnitPrice">
                <span class="value-text" matTooltip="{{ workOrder.unit_price | currency: {decimal_places: 4} }}">
                  {{ workOrder.unit_price | currency: {decimal_places: 4} }}
                </span>
              </div>
            </td>
            <td>
              <div class="p-2" id="viewFieldDiscountedPrice">
                <span class="value-text" matTooltip="{{ workOrder.discounted_price | currency: {decimal_places: 4} }}">
                  {{ workOrder.discounted_price | currency: {decimal_places: 4} }}
                </span>
              </div>
            </td>
            <td>
              <div class="p-2" id="viewFieldTaxCode">
                <span class="value-text" matTooltip="{{ workOrder.tax_code_name }}">
                  {{ workOrder.tax_code_name }}
                </span>
              </div>
            </td>
            <td>
              <div class="p-2" id="viewFieldLineTotal">
                <span class="value-text" matTooltip="{{ workOrder.line_item | currency: {decimal_places: 4} }}">
                  {{ workOrder.line_item | currency }}
                </span>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
    </table>

    <!-- Total Computation -->
    <div *ngIf="strLabel === 'work_order'" class="mt-1">
      <table class="table table-bordered">
        <tbody>
          <tr>
              <th scope="row" width="66%" class="invisible"></th>
              <td scope="row" width="17%" ><span class="p-2 float-right">{{ 'total_ex_tax' | translate }}</span></td>
              <td scope="row" width="17%" class="text-center" id="viewFieldTotalExTax">
              <span>{{ intAmountWithoutTax | currency }}</span>
              </td>
          </tr>
          <tr>
              <th scope="row" class="invisible"></th>
              <td scope="row"><span class="p-2  float-right">{{ 'tax' | translate }}</span> </td>
              <td scope="row" class="text-center" id="viewFieldTax">
              <span>{{ intTax | currency }}</span>
              </td>
          </tr>
          <tr >
              <th scope="row" class="invisible"></th>
              <td scope="row"><span class="p-2  float-right">{{ 'total_inc_tax' | translate }}</span> </td>
              <td scope="row" class="text-center" id="viewFieldTotalIncTax">
              <span>{{ intAmountWithTax | currency }}</span>
              </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</div>
