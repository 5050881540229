import { Observable } from "rxjs/Observable";
import { Select } from "./select";
import { Subject } from "rxjs/Subject";

export class RecurringJobLineItem {

  /**
   * Asset type for the current
   * line item
   */
  asset_type_id: string;

  /**
   * Asset group for the current
   * line item
   */
  asset_group_id: string;

  /**
   * Set of id and name of the selected
   * checklist
   */
  checklist_ids: string[];

  /**
   * Department for the current
   * line item.
   */
  department_id: string;

  /**
   * User for the current
   * line item.
   */
  user_id: string;

  /**
   * Description for the task.
   */
  task_description: string;

  id: string;

  /**
   * Estimated Duration (Hour)
   */
  expected_hours: number;

  /**
   * estimated labour cost
   */
  estimated_hours: number;

  /**
   * estimated material cost
   */
  estimated_materials: number;

  /**
   * Team for the current
   * line item.
   */
  team_id: string;

  /**
   * Team for the current
   * line item.
   */
  item_id?: string;

  /**
   * Cost Estimation method
   */
  cost_estimation?: string;

  constructor(properties: {
    asset_type_id?: string
    asset_group_id?: string
    department_id?: string
    user_id?: string
    checklist_ids?: string[]
    task_description?: string,
    expected_hours?: number,
    estimated_hours?: number
    estimated_materials?: number
    team_id?: string,
    item_id?: string,
    cost_estimation?: string,
  } = {}){
    this.asset_type_id = properties.asset_type_id;
    this.asset_group_id = properties.asset_group_id;
    this.department_id = properties.department_id;
    this.user_id = properties.user_id;
    this.checklist_ids = properties.checklist_ids;
    this.task_description = properties.task_description;
    this.expected_hours = properties.expected_hours;
    this.estimated_hours = properties.estimated_hours;
    this.estimated_materials = properties.estimated_materials;
    this.team_id = properties.team_id;
    this.item_id = properties.item_id;
    this.cost_estimation = properties.cost_estimation;
  }
}