import { Component, OnInit, Inject } from '@angular/core';

import * as moment from 'moment';
import { isFunction } from 'lodash-es';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FieldTransformed } from "./../../../../../../../objects/field-transformed";
import { DialogButtonConfig } from "./../../../../../../../objects/dialog-button-config";

@Component({
  selector: 'app-annual-condition-report',
  templateUrl: './annual-condition-report.component.html',
  styleUrls: ['./annual-condition-report.component.scss']
})
export class AnnualConditionReportComponent implements OnInit {

  public bCustomField: boolean = false;
  public objFormValue: any;
  public bShowLoader: boolean = false;
  public objForm: FormGroup = new FormGroup({
    start_date: new FormControl(moment().subtract(12, 'months').format('YYYY-MM-01')),
    end_date: new FormControl(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
  });
  public objFormMetadata: {
      [key: string]: FieldTransformed
  } = {
    start_date: {
      label: 'start',
      key: 'start_date',
      controlType: 'date',
      default_value: null
    },
  }

  public arButtons: DialogButtonConfig[] = [
    {
      icon: ['fas', 'times'],
      text: 'close',
      callback: 'closeDialog'
    }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AnnualConditionReportComponent>
  ) { }

  ngOnInit() {
    this.arButtons.push({
      icon: (this.data.has_custom_field) ? null : ['fas', 'file-pdf'],
      text: (this.data.has_custom_field) ? 'continue' : 'preview',
      callback: 'onSubmit'
    });
    // Initialize moment
    let objStartDate = moment();
    let objEndDate = moment();
    // Set start and end date default value
    this.objFormMetadata = {
      start_date: {
        label: 'start',
        key: 'start_date',
        controlType: 'date',
        default_value: objStartDate.subtract(1, 'years').format('YYYY-MM-01'),
        readonly: false
      },
      end_date: {
        label: 'end',
        key: 'end_date',
        controlType: 'date',
        default_value: objEndDate.subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
        readonly: false
      }
    };
  }

  /**
   * Select the start and end date configuration
   * Request the pdf data in api based on the selected dates.
   *
   * @returns {void}
   */
  onSubmit(): void {
    this.bShowLoader = true;
    let objData = this.objForm.getRawValue();
    // Do we have start and end date?
    if (objData.start_date && objData.end_date) {
      // Get current datetime
      let strCurrentTime = moment().format('HH:mm:ss');
      let strFormatStartDate = moment(objData.start_date).format('YYYY-MM-DD');
      // Prepare for the where caluse
      this.dialogRef.close({
        start_date: moment(strFormatStartDate+ ' ' +strCurrentTime).utc().format('YYYY-MM-DD HH:mm:ss'),
      });
    }
  }

  /**
   * Close the dialog
   *
   * @returns {void}
   */
  closeDialog(): void {
    this.dialogRef.close(null);
  }

  /**
   * Updates the dates
   * Start and end date must have 1 year difference
   *
   * @param strField
   * @param objEvent
   *
   * @returns {void}
   */
  updateDate(strField, objValue): void {
    // Instatiate dates
    let objStartDate = moment();

    // If we got here, it means that user changed the value of start date, we need to add 1 year on end date
    objStartDate = moment(objValue);

    // Set the value for start and end date
    this.objForm.patchValue({
      start_date: objStartDate.format('YYYY-MM-DD'),
    });
  }

  /**
   * Wait until the child component emits the data
   *
   * @param event
   *
   * @returns {void}
   */
  ngOnHeaderChange(event): void {
    if (isFunction(this[event.callback])) {
        this[event.callback]();
    } else {
        this.closeDialog();
    }
  }
}
