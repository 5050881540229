import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
import {blank} from '../utils/common';
import {TranslateService} from '@ngx-translate/core';

@Directive({
  selector: '[fieldmagicTabContent]',
})
export class TabPaneDirective implements AfterViewInit {
  @Input('fieldmagicTabContent') id: string;

  constructor(readonly el: ElementRef) {}

  ngAfterViewInit(): void {
    this.el.nativeElement.id = this.id;
  }
}

@Directive({
  selector: '[fieldmagicGridColumn]',
})
export class GridColumnDirective implements AfterViewInit {
  @Input('fieldmagicGridColumn') column: number;

  constructor(readonly el: ElementRef) {
    if (this.el.nativeElement.tagName !== 'DIV') {
      throw new Error(
        'fieldmagicGridColumn directive can only be used on div elements',
      );
    }
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.classList.add(`col-lg-${this.column}`);
    this.el.nativeElement.classList.add(`col-md-${this.column}`);
    this.el.nativeElement.classList.add(`col-sm-12`);
    this.el.nativeElement.classList.add(`col-xs-12`);
  }
}

@Directive({
  selector: '[fieldmagicBox]',
})
export class BoxDirective implements AfterViewInit {
  @Input()
  border: 'dotted' | 'solid' = 'solid';

  @Input()
  minHeight: 'auto' | 'md' | 'sm' | 'lg' = 'auto';

  @Input()
  alignItemsVertically: 'start' | 'center' | 'end' = 'start';

  @Input()
  alignItemsHorizontally: 'start' | 'center' | 'end' = 'start';

  @Input()
  label: string | null = null;

  constructor(
    private readonly _el: ElementRef,
    private readonly _translator: TranslateService,
  ) {
    if (this._el.nativeElement.tagName !== 'DIV') {
      throw new Error(
        'fieldmagicBox directive can only be used on div elements',
      );
    }
  }

  ngAfterViewInit(): void {
    const native = this._el.nativeElement;

    native.classList.add('fieldmagic-box');
    native.classList.add('p-2');
    native.classList.add('d-flex');
    native.classList.add('flex-column');
    native.classList.add('d-flex-gap');

    this._applyBorder(native);
    this._applyMinHeight(native);
    this._applyLabel(native);
  }

  private _applyBorder(native: HTMLElement): void {
    if (this.border === 'dotted') {
      native.classList.add('fieldmagic-box-dotted');
    } else {
      native.classList.add('fieldmagic-box-solid');
    }
  }

  private _applyMinHeight(native: HTMLElement): void {
    if (this.minHeight === 'md') {
      native.classList.add('fieldmagic-box-md');
    } else if (this.minHeight === 'sm') {
      native.classList.add('fieldmagic-box-sm');
    } else if (this.minHeight === 'lg') {
      native.classList.add('fieldmagic-box-lg');
    }
  }

  private _applyLabel(native: HTMLElement): void {
    if (blank(this.label)) {
      return;
    }

    native.classList.add('fieldmagic-box-with-label');

    const label = document.createElement('span');

    label.classList.add('fieldmagic-box-label');
    label.textContent = this._translator.instant(this.label);

    native.prepend(label);
  }
}

@Directive({
  selector: '[fieldmagicTableRow]'
})
export class TableRowDirective {}

@Directive({
  selector: '[fieldmagicTableRowColumn]'
})
export class TableRowColumnDirective implements AfterViewInit {
  constructor(
    private readonly element: ElementRef<HTMLElement>,
  ) {}

  ngAfterViewInit(): void {
    this.element.nativeElement.classList.add('fieldmagic-table-row-column');
  }
}

@Directive({
  selector: '[fieldmagicGridRow]',
})
export class GridRowDirective implements AfterViewInit {
  constructor(private readonly _el: ElementRef) {
    if (this._el.nativeElement.tagName !== 'DIV') {
      throw new Error(
        'fieldmagicGridRow directive row can only be used in div elements',
      );
    }
  }

  ngAfterViewInit(): void {
    this._el.nativeElement.classList.add('row');
  }
}

@Directive({
  selector: '[fieldmagicPaginationLinksTopContent]',
})
export class PaginationLinksTopContentDirective {}

@Directive({
  selector: '[fieldmagicAsyncLoadedContent]'
})
export class AsyncLoadedContentComponentDirective {}

@Directive({
  selector: '[fieldmagicDialogHeaderTitleTmp]'
})
export class DialogHeaderTitleTemplateDirective {}

@Directive({
  selector: '[fieldmagicDialogButtonsTmp]',
})
export class DialogButtonsTemplateDirective {}

@Directive({
  selector: '[fieldmagicDialogContentTmp]'
})
export class DialogContenTemplatetDirective {}

@Directive({
  selector: '[fieldmagicPaginationListItem]'
})
export class PaginationListItemDirective {}

@Directive({
  selector: '[fieldmagicWyswygFormat]',
})
export class WYSWYGFormatDirective {}

@Directive({
  selector: '[fieldmagicDropdownButtonActionItem]'
})
export class DropdownButtonActionItemDirective {}
