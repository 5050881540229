import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { concat } from 'rxjs/observable/concat';
import { Observable } from 'rxjs/Observable';
import { Select } from '../../../../../objects/select';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { RelateIds } from '../../../../../lists/relate-ids';
import { NotificationService } from '../../../../../services/notification.service';
import { Notification } from '../../../../../objects/notification';
import { ActivitiesService } from '../../../../../services/activities.service';
import * as moment from 'moment';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { ActivityType } from '../../../../../lists/activity';
import { RecordService } from '../../../../../services/record.service';
@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  public strModule: string;
  public bSubmitted: boolean = false;
  public bShowLoader: boolean = false;
  public bPageLoaded: boolean = false;

  public strRelatedId: any = RelateIds;
  public strViewType: string = this.data.view_type;

  public arRecordDetails: any = [];
  public objEditDetails = {};
  public objInitialRawData = {};

  public eventForm: FormGroup;

  public objEventName = [
    'event', 'description', 'assigned_to', 'event_date'
  ];

  public objEventField = {
    'event': {
      key: 'event',
      label: 'event',
      controlType: 'dropdown',
      default_value: '',
      options: [
        {
          id: 'phone',
          text: 'events.phone'
        },
        {
          id: 'in_person',
          text: 'events.in_person'
        },
        {
          id: 'web',
          text: 'events.web'
        }
      ],
      max_length: 128
    },
    'description': {
      key: 'description',
      label: 'notes',
      controlType: 'textarea',
      rows: 5,
      default_value: '',
      max_length: 10000
    },
    'assigned_to': {
      key: 'assigned_to',
      label: 'assigned_to',
      controlType: 'relate',
      module: 'users',
      default_value: this.localStorageService.getItem('user_id')
    },
    'event_date': {
      key: 'event_date',
      label: 'event_date',
      controlType: 'datetime',
      custom_class: 'schedule-event',
      default_value: '',
      readonly: false
    }
  };

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  constructor(
    public dialogRef: MatDialogRef<EventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activitiesService: ActivitiesService,
    private notifService: NotificationService,
    protected localStorageService: LocalStorageService,
    private recordService: RecordService
  ) {
    // Store record details
    this.arRecordDetails = data;
    // Get current user id and name
    this.objEventField['assigned_to']['default_text'] = this.localStorageService.getItem('user_name');
    this.objEventField['assigned_to']['default_value'] = this.localStorageService.getItem('user_id');

    if (this.strViewType == 'edit') {
      this.recordService.getRecordRelateJoined('activities', false, { 'activities.id': this.data['activity_id'] }).subscribe( response => {
        // Get updated record data of currently updating.
        this.objEditDetails = (response != null && response.length > 0) ? response[0] : [];
        // Convert datetime to utc
        let utcTime = moment.utc(this.objEditDetails['activity_date']).toDate();

        this.objEventField['event_date']['default_value'] = moment(utcTime).format('YYYY-MM-DD HH:mm:ss');
        this.objEventField['event']['default_value'] = this.objEditDetails['activity_name'];
        this.objEventField['description']['default_value'] = this.objEditDetails['notes'];
        this.objEventField['assigned_to']['default_value'] = this.objEditDetails['user_id'];
        this.objEventField['assigned_to']['default_text'] = this.objEditDetails['user_text'];
        this.initForm();
        this.objInitialRawData = {...this.eventForm.getRawValue()};
        this.bPageLoaded = true;
      });
    } else {
      this.initForm();
      this.bPageLoaded = true;
    }

    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

  ngOnInit() {}
  /**
   * Initialize Form group
   */
  initForm() {
    this.eventForm = new FormGroup({
      event: new FormControl(this.objEventField['event']['default_value'], Validators.required),
      description: new FormControl(this.objEventField['description']['default_value'], Validators.required),
      assigned_to: new FormControl(this.objEventField['assigned_to']['default_value'], Validators.required),
      event_date: new FormControl(this.objEventField['event_date']['default_value'], Validators.required)
    });
  }
  /**
   * For Cancel button
   */
  cancelDialog(): void {
    if (this.eventForm.dirty) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel').filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close('cancel');
        });
    } else {
      this.dialogRef.close('cancel');
    }
  }

  /**
   * Create event data in activities table
   */
  onSubmit() {
    // Set submitted to true
    this.bSubmitted = true;
    // Do we have valid form?
    if (this.eventForm.valid) {

      this.bShowLoader = true;
      if (this.strViewType == 'edit') {

        let arProperKeys = {
          'event': 'activity_name',
          'description': 'notes',
          'assigned_to': 'user_id',
          'event_date': 'activity_date'
        }

        let objDiffTemp = {
          id: this.arRecordDetails['activity_id'],
          record_id: this.arRecordDetails['record_id'],
          module: this.arRecordDetails['module']
        };

        Object.keys(this.objInitialRawData).forEach(key => {
          let strKey = arProperKeys[key] != undefined ? arProperKeys[key] : key;
          objDiffTemp[strKey] = this.eventForm.getRawValue()[key];
        });

        this.bShowLoader = false;
        this.dialogRef.close({'mode': 'edit', 'data': objDiffTemp});

      } else {
        // Setup request data
        let arRequestData = {
          module_id: this.arRecordDetails.record_id,
          module_field: this.strRelatedId[this.arRecordDetails.module],
          assigned_to: this.eventForm.controls['assigned_to'].value,
          subject: this.eventForm.get('event').value,
          note: this.eventForm.controls['description'].value,
          module: this.data.module,
          type: ActivityType['event'],
          activity_date: moment(this.eventForm.controls['event_date'].value).format('YYYY-MM-DD HH:mm:ss')
        };

        this.bShowLoader = false;
        this.dialogRef.close({'mode': 'create', 'data':  arRequestData});
      }
    }
  }

}
