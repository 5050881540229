import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomCurrencyPipe } from '../custom-currency-pipe';
import * as moment from 'moment';
import { filled } from '../../shared/utils/common';

@Pipe({
  name: 'normalizeValue'
})
export class NormalizeValuePipe implements PipeTransform {

  constructor(
    private translate: TranslateService,
    private currencyPipe: CustomCurrencyPipe,
  ) { }

  // The types on this pipe is based on the AdvanceSearch data types
  transform(value: any, type: string): any {
    if (filled(value) === false) {
      return value;
    }

    switch (type) {
      case 'text':
      case 'textarea':
        return String(value);

      case 'dropdown':
        return this.translate.instant(value);

      case 'checkbox':
        return this.translate.instant(value ? 'yes' : 'no');

      case 'number':
      case 'decimal':
        return Number(value);

      case 'currency':
        return this.currencyPipe.transform(value);

      case 'date':
        return this.formatDate(value, false);

      case 'datetime':
        return this.formatDate(value, true);

      default:
        return value;
    }
  }

  /**
   * We update the datetime value.
   * @param date
   */
  formatDate(date: string, bDateTime = true) {

    if (!date) {
      return '';
    }

    let convertedDate = moment(date);

    if (!convertedDate.isValid()) {
      return '';
    }

    if (bDateTime) {
      let convertedUTCDateTime = moment.utc(date).toDate();
      return moment(convertedUTCDateTime).format('lll');
    }

    return convertedDate.format('ll');
  }
}
