
    <table
      class="fieldmagic-table table table-bordered w-100 table-striped table-sm"
    >
      <thead>
        <tr>
          <ng-container *ngFor="let header of headers">
            <th
              class="fieldmagic-table-header fieldmagic-column-{{ header.size || 'auto' }}"
            >
              <fieldmagic-text
                [alignment]="header.alignment"
                content="{{ header.label | translate }}"
              >
              </fieldmagic-text>
            </th>
          </ng-container>
        </tr>
      </thead>

      <tbody>
        <ng-container
          *ngIf="fetching$ | async; else displayRows"
        >
          <tr>
            <td
              class="fieldmagic-row-column w-100"
              [attr.colspan]="headers.length"
            >
              <fieldmagic-icon
                icon="spinner"
                [spin]="true"
                position="center"
              >
              </fieldmagic-icon>
            </td>
          </tr>
        </ng-container>

        <ng-template #displayRows>
          <ng-container *ngIf="items$ | async as items">
            <ng-container *ngIf="items | filled; else noItems">
              <ng-container
                *ngFor="let item of items; index as index; trackBy: rowTrackBy;"
                [ngTemplateOutlet]="rowTemplate || defaultRowTemplate"
                [ngTemplateOutletContext]="{
                  item: item,
                  index: index
                }"
              >
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-template>

        <ng-template #noItems>
          <tr>
            <td
              [attr.colspan]="headers.length"
              class="fieldmagic-row-column w-100"
            >
              <fieldmagic-text
                alignment="center"
                content="{{ 'No records found' | translate }}"
              >
              </fieldmagic-text>
            </td>
          </tr>
        </ng-template>

        <ng-template
          let-item="item"
          let-index="index"
          #defaultRowTemplate
        >
          <tr>
            <td
              *ngFor="let header of headers"
              class="fieldmagic-row-column"
            >
              <fieldmagic-text
                *ngIf="header.key | filled"
                content="{{ item | data_get: header.key }}"
                [alignment]="header.alignment"
              >
              </fieldmagic-text>
              <fieldmagic-text
                *ngIf="header.key | blank"
                content="{{ 'Missing Header Key' | translate }}"
                [alignment]="header.alignment"
              >
              </fieldmagic-text>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  