import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import * as moment from 'moment';
import { ViewService } from '../../../../../services/view.service';
import { AssetService } from '../../../../../services/asset.service';
import { RecordService } from '../../../../../services/record.service';
import { ListingService } from '../../../../../services/listing.service';
import { EditformComponent } from './../../../editform/editform.component'
import { CreateJobWizardComponent } from '../../../../../features/wizard/components/create-job-wizard/create-job-wizard.component';
import { PopupConfig } from '../../../../../objects/dialogs/popup-config';
import { data_get } from '../../../../utils/common';

@Component({
  selector: 'app-jobs-asset-jobs',
  templateUrl: './asset-jobs.component.html',
  styleUrls: ['./asset-jobs.component.scss'],
  providers: [ListingService, AssetService]
})
export class AssetJobsComponent implements OnInit {

  @Input() strRecordId: string;

  public bLoading: boolean = false;
  public arRecordData: Array<object> = [];
  public objFilter: object = {};
  public objStatus: Array<object> = [];
  public objStatusClass = {
    pass: 'text-success',
    fail: 'text-danger',
    awaiting_completion: 'text-warning'
  }

  constructor(
    public activatedRoute: ActivatedRoute,
    public listingService: ListingService,
    public assetService: AssetService,
    public viewService: ViewService,
    public recordService: RecordService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.getRecordData();
  }

  /**
   *  Set's the listing filter
   *
   * @param {object} event
   * @param {string} field
   *
   * @returns {void}
   */
  setRecordFilter(event: object = {}, field: string = null): void {
    this.objFilter = {
      asset_id: this.strRecordId
    }
    if (event && event["id"]) {
      this.objFilter[field] = event["id"];
    }
    this.getRecordData();
  }

  /**
   * Return listing filter
   *
   * @returns {object}
   */
  getRecordFilter(): object {
    return this.objFilter;
  }

  /**
   * Set's the option values for status filter
   *
   * @param {array} objConfig
   *
   * @returns {void}
   */
  setStatusOption(objConfig: Array<object> = []): void {
    this.objStatus = objConfig;
  }

  /**
   * Returns the list of records
   *
   * @param {string} strNavigation
   *
   * @returns {void}
   */
  getRecordData(strNavigation: string = 'default'): void {
    this.bLoading = true;
    this.arRecordData = [];
    let objFilter = this.getRecordFilter();
    let objPagination = this.listingService.beforeFetching(strNavigation);
    this.listingService.fetchData(JSON.stringify(objPagination.objPage), 'assets_jobs', JSON.stringify(objFilter)).subscribe( response => {

      this.setStatusOption(response['option']['status']['config']);
      if (response['data'] && response['data'].length != 0) {

        this.arRecordData = response['data'];
      }
			this.listingService.afterFetching(response, strNavigation);
      this.bLoading = false;
    });
  }

  /**
   * Format to readable date
   *
   * @param strDate
   *
   * @returns {string}
   */
  formatDate(strDate: string): string {
    return moment(strDate).utcOffset(new Date().getTimezoneOffset()).format('lll');
  }

  /**
   * Edit job record
   *
   * @param {string} strJobId
   *
   * @returns {void}
   */
  editJobRecord(strJobId: string): void {
    let objData = {
      strMode : 'edit',
      strModule : 'jobs',
      strId: strJobId,
    };

    this.openEditFormComponent(objData);
  }

  /**
   * Open edit form component
   *
   * @param {object} objData
   *
   * @returns {void}
   */
  openEditFormComponent(objData: object): void {

    let dialogConfig : {[k: string]: any} = {
      data: objData,
      disableClose: true,
    };

    // IF MOBILE
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      // display as pop up if not mobile
      dialogConfig.width = '80%';
      dialogConfig.height = 'auto';
    }

    this.dialog.open(EditformComponent, dialogConfig);
  }

  /**
   * Opens the job creation wizard
   *
   * @returns {void}
   */
  openCreateJobWizard(): void {
    let strSiteId: string = this.viewService.getRecord().record_details.site_id;
    let strCustomerId: string = this.viewService.getRecord().record_details.customer_id;

    let jobWizardConfig = {
      record_type: 'jobs',
      site_id: strSiteId,
      creating_from_widget: true,
      customer_id: strCustomerId,
      asset_data: {
        asset_id: this.viewService.getRecord().record_details.id,
        asset_type_text: this.viewService.getRecord().record_details.asset_type_text,
        serial_number: this.viewService.getRecord().record_details.serial_number,
        asset_name: data_get(this.viewService.getRecord(), 'record_details.name'),
        asset_type_id: data_get(this.viewService.getRecord(), 'record_details.asset_type_id'),
      },
    };

    let otherConfig = {
      panelClass: ['mat-dialog-container-overflow-auto', 'job-wizard-step'],
      data: jobWizardConfig
    }

    let popup = new PopupConfig(
      CreateJobWizardComponent,
      { width: '60%', height: 'auto' },
      { width: '70%', height: 'auto' },
      otherConfig
    );

    this.dialog
      .open(popup.component, popup.config)
      .afterClosed()
      .first()
      .subscribe(_ => {
        setTimeout(() => {
          this.getRecordData();
        }, 3500);
      });
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arRecordData = []; // clear list
    this.getRecordData();
  }

  ngOnChanges(): void {
    this.setRecordFilter();
  }
}