import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { FormService } from '../../../../services/form.service';
import { RecordService } from '../../../../services/record.service'
import { cloneDeep } from 'lodash-es';
import { NotificationService } from '../../../../services/notification.service';
import { Notification } from '../../../../objects/notification';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-converted-data',
  templateUrl: './converted-data.component.html',
  styleUrls: ['./converted-data.component.scss']
})
export class ConvertedDataComponent implements OnInit {
  public bLoading: boolean = true;
  public arConvertedData: any = [];
  public arConvertedDataKey: any = [];
  public objConvertedModule: any = {
    'converted_customer_id' : { 'module': 'customers'},
    'converted_site_id' : { 'module': 'sites'},
    'converted_contact_id' : { 'module': 'contacts'},
    'converted_job_id' : { 'module': 'jobs'},
    'converted_opportunity_id' : { 'module': 'opportunities'},
    'converted_quote_id' : { 'module': 'quotes'}
  }
  constructor(
    public recordService: RecordService,
    public notifService: NotificationService,
    public dialog: MatDialogRef<ConvertedDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    let strModules = [];
    let objFilters = {};
    let objConvertedModuleKeys = Object.keys(this.objConvertedModule);
    // If has converted data
    // Set each data for request to api to get record
    objConvertedModuleKeys.forEach( convertedModuleKey => {
      // Set Conveted Module
      let strConvertedModule = this.objConvertedModule[convertedModuleKey]['module'];
      // Check if converted module key is set in record data
      if (!isEmpty(this.data['record_data'][convertedModuleKey])) {
        // Push module that is converted
        strModules.push(strConvertedModule);
        let strModuleId = strConvertedModule + '.id';
        objFilters[strConvertedModule] = { [strModuleId]: this.data['record_data'][convertedModuleKey] }
      }
    });

    if (strModules.length > 0) {
      /**
       * Get multiple module relate record
       * Get Record Joined Relate
       * @param strModule - request module to get config
       * @param strRecordIds - request record id of main table
       * @param arFilter - request filter
       * @param arOrderClause - request order clause   Format: ['id' => 'column', 'sort' => 'desc']
       * @param intLimit - request limit
       * @param arWhereIn - request to find in array
       * @param arWhereNotIn - request to find in array
       */
      this.recordService.getMultipleModuleRelateRecord(strModules.join('|'), false, objFilters, false).subscribe(response => {
        // Set converted data
        this.arConvertedData = response;
        // Set key of converted data
        this.arConvertedDataKey = Object.keys(response);
        // Hide loader icon.
        this.bLoading = false;
      });
    } else {
      // Hide loader icon.
      this.bLoading = false;
    }
  }

  /**
   * Close the dialog.
   */
  public cancelDialog() {
    this.dialog.close();
  }

}
