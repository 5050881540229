import 'rxjs/add/operator/do';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import { Router } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import 'rxjs/add/operator/distinctUntilChanged';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Component, DoCheck, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { Address } from '../../../objects/address';
import { Location } from '../../../objects/location';
import { AddressService } from '../../../services/address.service';
import { ClientStoreService } from '../../../services/client-store.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ClientsListStoreService } from '../../../services/clients-list-store/clients-list-store.service';
import { StrService } from '../../../services/helpers/str.service';
import { get, has, isEmpty, isEqual } from 'lodash-es';
import { GeolocationComponent } from '../forms/input/geolocation/geolocation.component';
import { PlacesService } from './places.service';
import { Country } from '../../../lists/country';
import { LooseObject } from '../../../objects/loose-object';
import { filter, first, switchMap, take, takeUntil, takeWhile } from 'rxjs/operators';
import { filled } from '../../utils/common';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [ AddressService ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AddressComponent implements OnInit {

  @ViewChild('searchInput') searchElementRef: ElementRef;
  @ViewChild(GeolocationComponent)
  private geolocationComponent: GeolocationComponent;
  @Input() parentHasSubmitted: boolean = false;
  @Input() addressForm: FormGroup;
  @Input() strModule: string;

  public arrAddress: Observable<Address[]>;
  public arrCountryList: Location[] = [];
  public arrStateList: Location[] = [];
  public strStreet: string = "";
  public strAlternateState: string = "";
  public strCurrentUrl: any;
  public bGeolocationOnProcess: boolean = false;
  public geolocationData: GeolocationData = {
    'address_name': null,
    'latitude': null,
    'longitude': null,
  };

  //We use this to check if the parent component has hit the submit button.
  @Output() addressChange = new EventEmitter<any>();
  @Output() addressDirty = new EventEmitter<any>();

  constructor(
    public clientStoreService: ClientStoreService,
    public clientsListStoreService: ClientsListStoreService,
    protected localStorageService: LocalStorageService,
    public addressFormBuilder: FormBuilder,
    private addressService: AddressService,
    public placeService: PlacesService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private stringService: StrService) {
      this.strCurrentUrl = this.router.url;
    }

  /**
   * After initializing of the component.
   * We need to call markForCheck to
   * be able to reflect all changes.
   */
  ngDoCheck() {
    this.changeDetectorRef.markForCheck();
  }

  buildForm(objAddress, strMode, strModule = 'registration') {
    // Check if object lacks necessary children, assign the child if yes.
    Object.keys(new Address()).forEach(item => {
      if (has(undefined, objAddress[item]) || has(null,objAddress)) {
        objAddress[item] = "";
      }
    });

    // Fix 'This page can't load Google Maps manually' error in address autocomplete
    // This happens if the country is not in code format, ie. 'New Zealand' instead of 'NZ',
    // or if the country is nonexistent, like 'abcd'
    if (!isEmpty(objAddress['country'])) {
      // force country data to be uppercase, so that 'ph' or 'au' becomes 'PH', 'AU'
      objAddress['country'] = objAddress['country'].toString().trim().toUpperCase();
      const countryInstance = new Country();
      const strRawCountry: string = objAddress['country'];
      const arCountryCodes: string[] = countryInstance.list.map(country => country.code);
      // check first if country matches a country code ex, "PH", "AU"
      if (!arCountryCodes.includes(strRawCountry)) {
        // if address is not in code form, ie. it is "NEW ZEALAND" instead of "NZ", let's search by the whole name
        const matchedCountry: LooseObject = countryInstance.list.find(country => country.name.toUpperCase() === strRawCountry);
        // if there is a match, use the matched country's country code
        if (matchedCountry) {
          objAddress['country'] = matchedCountry.code;
        } else {
          // else, just put the country code of the active client
          const activeClient = this.clientStoreService.getActiveClient();
          objAddress['country'] = get(activeClient, 'country', '');
        }
      }
    }

    //Build form
    this.addressForm.reset();
    this.addressForm = this.addressFormBuilder.group(objAddress);

    this.geolocationComponent.latitude = objAddress.latitude;
    this.geolocationComponent.longitude = objAddress.longitude;

    this.addressForm.controls.country.valueChanges.subscribe(() => {
      this.onCountryChange();
    });

    // Loop address field and set valuechanges for each field
    this.addressForm.valueChanges
      .debounceTime(500)
      .distinctUntilChanged((prev, curr) => {
        return isEqual(prev, curr);
      })
      .subscribe(result => {
        this.addressChange.emit(this.addressForm.getRawValue());
        if (this.addressForm.dirty) {
          this.addressDirty.emit(this.addressForm.dirty);
        }
    });

    this.setCountriesToAddressForm();
  }

  setCountriesToAddressForm() {
    //Get all countries in the local json file.
    this.addressService.getAllCountries()
      .subscribe(res => {
        //Populate the country selection.
        this.arrCountryList = res;

        if (this.addressForm.controls['country'].value) {
          this.addressForm.controls['state'].enable();
          this.onCountryChange();
        } else {
          // Get current client data
          let arCurrentClient = this.clientStoreService.getActiveClient();
          let arClientList = this.clientsListStoreService.getClientList();

          // Check client country to set as default country for address
          if (arCurrentClient != undefined && arCurrentClient != null) {

            let strCurrentClientId = arCurrentClient['client_id'];
            let strClientCountry = get(arClientList, [strCurrentClientId, 'country'], null);
            let strClientState = get(arCurrentClient, ['address', 'state'], null);

            // If found client country. Get the current country location
            if (strClientCountry !== null) {
              this.addressForm.patchValue({country: strClientCountry});

              if (isEmpty(this.addressForm.controls['state'].value)) {
                this.addressForm.patchValue({state: strClientState});
              }

              this.onCountryChange();
            }
          }
        }

        this.initializeAutoComplete();
    });
  }

  ngOnInit() {
    if (this.addressForm === undefined) {
      //Create a new form group using form builder.
      //We used the Address object as it's structure.
      this.addressForm = this.addressFormBuilder.group(new Address());

      if (this.strModule === 'workflows') {
        this.setCountriesToAddressForm();
      }
    } else {
      this.setCountriesToAddressForm();
    }

    //Disabled state while still loading.
    this.addressForm.controls['state'].disable();
  }

  //Call this method to show/hide a visual error if required fields are valid/invalid.
  isInvalid(strFieldName): boolean {
    //If the field is invalid and the parent submit button is triggered.
    if (!this.addressForm.get(strFieldName).valid && this.parentHasSubmitted) {
      return true;
    } else {
      return false;
    }
  }

  //Re-populate state selection list whenever the selected country changes.
  onCountryChange() {

    let selectedState = this.addressForm.get('state').value;

    this.addressService.getStatesByCountry(this.addressForm.get('country').value)
    .subscribe(
      res => {
        //Populate the state selection.
        this.arrStateList = res;

        /*
         * Get the state code if selectedState returns name because
         * MapQuest API sometimes returns state name instead of state code.
         */

        let numStateCodeIndex: number = this.arrStateList.findIndex(item => (
          item.code == selectedState ||
          item.name == selectedState ||
          item.code == this.strAlternateState ||
          item.name == this.strAlternateState)
        );

        let objState: any = {};

        if (numStateCodeIndex > -1) {
          objState = this.arrStateList[numStateCodeIndex];
        } else {
          objState = this.arrStateList[0];
        }

        if (objState){
          //If there is a state code, set it as selected.
          this.addressForm.patchValue({
            state: objState.code
          })
        }
        this.addressForm.controls['state'].enable();

        if (this.placeService.places) {
          let activeClient = this.clientStoreService.getActiveClient();
          let isAllConfigCountriesForGoogleSearch = get(activeClient, 'config.all_countries_for_location_search', false);

          if (!isAllConfigCountriesForGoogleSearch) {
            let currentClientCountry = get(activeClient, 'country', '');
            let configCountriesForGoogleSearch = get(activeClient, 'config.countries_for_location_search', []);
            let componentRestrictions = [currentClientCountry];
            if ((new Country()).hasValidCode(this.addressForm.get('country').value)) {
              componentRestrictions.push(this.addressForm.get('country').value);
            }
            if (filled(configCountriesForGoogleSearch)) {
              componentRestrictions = [...componentRestrictions, ...configCountriesForGoogleSearch];
            }
            // Array.from will filter the duplicated value
            let filterRestrictions = Array.from(new Set(componentRestrictions));
            // filterRestrictions.filter(Boolean) will filter the undefined and null values
            this.placeService.places.setComponentRestrictions({ country: filterRestrictions.filter(Boolean) });
          } else {
            this.placeService.places.setComponentRestrictions({ country: [] });
          }
        }
      }
    );
  }

  //Hide the autocomplete when the results are all blank.
  hideOption(searchedAddress): Boolean {
    if (
      searchedAddress.street == "" && searchedAddress.level == "" &&
      searchedAddress.city == "" && searchedAddress.state == ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Display street properly.
  displayStreet(address): any {
    if (typeof address == 'object') {
      return (address) ? address['street'] : undefined;
    } else {
      return address
    }
  }

  /**
   * Get the string between two string
   * @param htmlStr - Full string to be find
   * @param firstStr - First string in between
   * @param secondStr  - Second string in between
   */
  getFromBetween(htmlStr, firstStr, secondStr) {
    if(htmlStr.indexOf(firstStr) < 0 || htmlStr.indexOf(secondStr) < 0) return false;
    var SP = htmlStr.indexOf(firstStr)+firstStr.length;
    var string1 = htmlStr.substr(0,SP);
    var string2 = htmlStr.substr(SP);
    var TP = string1.length + string2.indexOf(secondStr);
    return htmlStr.substring(SP,TP);
  }

  /**
   * Sets the data based on the provided place.
   *
   * @param {google.maps.places.PlaceResult} objPlace
   *
   * @returns {void}
   */
  setFromAutoComplete(objPlace: google.maps.places.PlaceResult): void {

    this.bGeolocationOnProcess = false;

    //set address
    let fltLatitude = objPlace.geometry.location.lat();
    let fltLongitude = objPlace.geometry.location.lng();
    let strUnit = '';
    let strBuildingName = '';
    let strStreet = '';
    let strCity = '';
    let strState = '';
    let strPostalCode = '';
    let strCountry = '';
    let explodedAddress = objPlace['adr_address'].split('>,');

    // Set address components per address field
    objPlace['address_components'].forEach( address => {
      if (address['types'].indexOf('subpremise') > -1 || address['types'].indexOf('room') > -1) {
        strUnit = address.long_name + ' ';
      }
      if (address['types'].indexOf('premise') > -1 || address['types'].indexOf('establishment') > -1) {
        strBuildingName += address.long_name + ' ';
      }
      if (address['types'].indexOf('floor') > -1 ||
                  address['types'].indexOf('establishment') > -1 ||
                  address['types'].indexOf('street_number') > -1 ||
                  address['types'].indexOf('route') > -1 ||
                  address['types'].indexOf('sublocality') > -1) {
        strStreet += address.long_name + ' ';
      }
      if (address['types'].indexOf('locality') > -1 ||
          address['types'].indexOf('postal_town') > -1) {
        strCity = address.short_name;
      }
      if (address['types'].indexOf('administrative_area_level_1') > -1) {
        strState = address.short_name;
      }
      if (address['types'].indexOf('administrative_area_level_2') > -1) {
        this.strAlternateState = address.short_name;
      }
      if (address['types'].indexOf('postal_code') > -1) {
        strPostalCode = address.short_name;
      }
      if (address['types'].indexOf('country') > -1) {
        strCountry = address.short_name;
      }
    });

    // If street is empty try to check on adr_address if street-address exist
    if (strStreet === '') {
      explodedAddress.forEach( data => {
        if (data.indexOf('street-address') > -1) {
          let strCurrentAddress = this.getFromBetween(data, '>', '<');
          strStreet = strCurrentAddress;
        }
      });
    }

    // If the street is still empty. We need to set the street by building name
    // as it is required and set the building name to empty that can be manually
    // set by the user. If the building name is empty. We need to use the formatted address
    if (strStreet === '') {
      strStreet = (strBuildingName !== '') ? strBuildingName : objPlace['formatted_address'];
      strBuildingName = '';
    }

    let objCurrentValue = this.addressForm.getRawValue();

    // Patchvalue address form
    this.addressForm.patchValue({
      latitude: (fltLatitude === null) ? objCurrentValue.latitude : fltLatitude,
      longitude: (fltLongitude === null) ? objCurrentValue.longitude : fltLongitude,
      unit: this.stringService.fallsBackTo(strUnit, objCurrentValue.unit),
      building_name: this.stringService.fallsBackTo(strBuildingName, objCurrentValue.building_name),
      city: this.stringService.fallsBackTo(strCity, objCurrentValue.city),
      zip: this.stringService.fallsBackTo(strPostalCode, objCurrentValue.zip),
      state: this.stringService.fallsBackTo(strState, objCurrentValue.state),
      country: this.stringService.fallsBackTo(strCountry, objCurrentValue.country),
      street: this.stringService.fallsBackTo(strStreet, objCurrentValue.street).trim()
    });

    this.geolocationComponent.latitude = (fltLatitude === null) ? objCurrentValue.latitude : fltLatitude;
    this.geolocationComponent.longitude = (fltLongitude === null) ? objCurrentValue.longitude : fltLongitude;

    // If strcountry is not empty chnage country by response
    if (strCountry != '') this.onCountryChange();

  }

  /**
   * Initialize Autocomplete search for street field
   *
   * @returns {void}
   */
  initializeAutoComplete(): void {

    this.placeService.initializePlaces(this.searchElementRef, this.addressForm.get('country').value)
    .subscribe(autocomplete => {

      autocomplete.addListener("place_changed", () => {
        this.placeService.ngZone.run(() => {

          let objPlace: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (objPlace === undefined || objPlace.geometry === undefined || objPlace.geometry === null) {
            return;
          }

          this.setFromAutoComplete(objPlace);

          // Value emitted here if module is workflows because buildForm is not called in workflows
          if (this.strModule === 'workflows') {
            //This will only patch once it selects in autocomplete but doesnt affect update data
            this.addressForm.patchValue({
              unit: '',
              building_name: '',
              level: '',
              lot_number: '',
              zip: '',
            });

            //This detect value changes so that it can emit updated address value
            this.addressForm.valueChanges.subscribe( addressValues => {
              this.addressChange.emit(addressValues);
            });

          }
        });
      });

    });

  }

  /**
   * This event emit from the geolocationComponent
   * @param event
   */
  geolocationComponentEmitter(event): void {
    this.addressForm.patchValue({
      latitude: event.latitude,
      longitude: event.longitude,
    })
  }

  setGeolocationComponentLatLng(value: { latitude: number, longitude: number }): void {
    this.geolocationComponent.latitude = value.latitude;
    this.geolocationComponent.longitude = value.longitude;
  }
}

export interface GeolocationData {
  address_name: string,
  latitude: number,
  longitude: number,
}
