import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListingPagination } from '../../objects/listing-pagination';
import { ListingElementsEvent } from '../../objects/listing-elements-event';

@Component({
  selector: 'fm-list-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  /**
   * Flag to show summary.
   *
   * @var {boolean}
   */
  @Input('bPageShowSummary') bPageShowSummary: boolean = false;

  /**
   * Flag to show the page buttons.
   *
   * @var {boolean}
   */
  @Input('bPageShowButtons') bPageShowButtons: boolean = false;

  /**
   * Flag to show the list loading.
   *
   * @var {boolean}
   */
  @Input('bListLoading') bListLoading: boolean = false;

  /**
   * Contains the pagination data.
   *
   * @var {ListingPagination}
   */
  @Input('objPagination') objPagination: ListingPagination;

  /**
   * contains page list
   *
   * @var {number[]}
   */
  @Input('arPageConfig') arPageConfig: {
    current_page: number,
    last_page: number,
    pages: number[]
  } = {
    current_page: 0,
    last_page: 0,
    pages: []
  } ;

  /**
   * Inform events outside the component.
   *
   * @var {EventEmitter<ListingElementsEvent>}
   */
  @Input('eventPageConfig') eventPageConfig = new EventEmitter<number[]>();

  arNavigationList: number[] = [];

  intLastPage: number = 0

  intCurrentPage: number = 0;

  /**
   * Inform events outside the component.
   *
   * @var {EventEmitter<ListingElementsEvent>}
   */
  @Output() pageEvent = new EventEmitter<ListingElementsEvent>();

  constructor() {}

  /**
   * Go to the next page.
   *
   * @returns {void}
   */
  next(): void{
    if (this.objPagination.next_page) {
      this.pageEvent.emit({type: 'paginate', data: 'next'});
    }
  }

  /**
   * Go to the previous page.
   *
   * @returns {void}
   */
  previous(): void {
    if (this.objPagination.previous_page) {
      this.pageEvent.emit({type: 'paginate', data: 'prev'});
    }
  }

  /**
   * Reset to first page.
   *
   * @returns {void}
   */
  reset(): void {
    if (this.objPagination.previous_page) {
      this.pageEvent.emit({type: 'paginate', data: 'default'});
    }
  }

  /**
   * change current page
   *
   * @returns {void}
   */
  changePage(page: number): void {
    this.pageEvent.emit({type: 'paginate', data: 'with_filter', page: page});
  }

  /**
   * wait for the emit on parent parent to change the pagination details
   */
  ngOnChanges(): void {
    if (this.eventPageConfig) {
      this.eventPageConfig.subscribe( response => {
        this.intLastPage = response.last_page;
        this.arNavigationList = response.pages;
        this.intCurrentPage = response.current_page;
      })
    }
  }
}
