import { Component, Inject, OnInit } from "@angular/core";
import { DIALOG_DATA, DialogService } from '../../../../services/dialog.service';
import { FormControl, FormGroup } from '@angular/forms';
import { toString } from 'lodash-es';

@Component({
  selector: 'app-task-calendar-custom-notification-message',
  template: `
    <fieldmagic-dialog>
      <div class="dialog-header-content">
        <fieldmagic-dialog-title
          icon="envelope"
          label="{{ 'Message' | translate }}"
        >
        </fieldmagic-dialog-title>
      </div>
      <div class="dialog-header-buttons">
        <button class="btn btn-primary"
          (click)="onSave()"
        >
          {{ 'save' | translate }}</button>
        <button
          class="btn btn-danger"
          (click)="onClose()"
        >
          {{ 'cancel' | translate }}
        </button>
      </div>
      <div class="dialog-content" [formGroup]="form">
        <fieldmagic-textarea-input [max]="200" formControlName="message"></fieldmagic-textarea-input>
      </div>
    </fieldmagic-dialog>
  `,
  providers: [
    DialogService,
  ],
})
export class CustomNotificationMessageDialog implements OnInit {
  readonly form = new FormGroup({
    message: new FormControl()
  });

  constructor(
    @Inject(DIALOG_DATA) private data: CustomNotificationMessageDialogProps,
    private dialog: DialogService,
  ) { }

  ngOnInit(): void {
    this.form.patchValue({
      message: this.data.message,
    });
  }

  onClose(): void {
    this.dialog.close({
      instance: this,
    });
  }

  onSave(): void {
    const message = toString(this.form.value['message']).trim();

    this.dialog.close({
      instance: this,
      result: message,
    });
  }
}

type CustomNotificationMessageDialogProps = {
  message?: string;
}
