import { Component, OnInit, Input } from '@angular/core';
import { ListingService } from '../../../services/listing.service';
import * as moment from 'moment';
import { RelateIds } from '../../../lists/relate-ids';
import { EditformComponent } from '../../../shared/components/editform/editform.component';
import { ViewService } from '../../../services/view.service';
import { MatDialog } from '@angular/material';
import { NotificationService } from '../../../services/notification.service';
import { RecordService } from '../../../services/record.service';
import { CreateJobWizardComponent } from '../../../features/wizard/components/create-job-wizard/create-job-wizard.component';
import { PopupConfig } from '../../../objects/dialogs/popup-config';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  providers: [ListingService]
})
export class JobsComponent implements OnInit {
  @Input() strRecordId : string;
  @Input() strModule : string;
  public arJobs = [];
  public bLoading = false;
  public bPageLoaded: boolean = false;
  public arJobStatus = {
		'awaiting_scheduling': 'text-secondary',
		'scheduled': 'text-primary',
		'pending': 'text-warning',
		'completed': 'text-invoiced',
		'awaiting_invoicing': 'text-success',
		'cancelled': 'text-danger'
  };
  public strSelectStatus: string = null;

  /**
   * list of job status filter
   */
  public arStatusFilter: string[] = [
    'awaiting_scheduling', 'scheduled', 'pending',
    'awaiting_invoicing', 'completed', 'cancelled'
  ];

  constructor(
      public listService: ListingService,
      public viewService: ViewService,
      private dialog: MatDialog,
      private notifService: NotificationService,
      private recordService: RecordService
    ) {

    }

  ngOnInit() {
    // Get the list on page initialization.
		this.fetchList('default');
  }
  /**
   * Filter to be search
   */
  filter() {
    let objFilter = {};

    if (this.strSelectStatus != '' && this.strSelectStatus != null) {
      objFilter['status'] = this.strSelectStatus;
    }

    this.arJobs = [];
    this.fetchList('default', objFilter);
  }

  /**
   * Fetch the list of jobs.
   * @param strPage - what page is currently to be viewed.
   * @param objFilter - Filter of data.
   */
  fetchList(strPage, objFilter = {}) {

      let objPagination = this.listService.beforeFetching(strPage);
      this.bLoading = true;
      let strModuleId = RelateIds[this.strModule]
      objFilter[strModuleId] = this.strRecordId;

      // Get the list from API.
      this.listService.fetchDataAdvanceSearch(
        objPagination['objPage'],
        'jobs',
        objFilter,
        { 'id': 'created_at', 'sort': 'desc' },
      ).subscribe(response => {
        this.arJobs = response['data'];

        this.listService.afterFetching(response, strPage);
        this.bLoading = false;
        this.bPageLoaded = true;
      });
    }

  /**
   * Open dialog to edit record.
   * @param - the id of record if edit.
   */
  recordDialog(strId = '') {
    let arRecordView = this.viewService.arRecord;
    let arData = {
      module : this.strModule,
    }

    if (this.strModule == 'sites') {
      arData['address'] = arRecordView['address'];
      arData['site_text'] = arRecordView['site_text'];
      arData['site_id'] = arRecordView['id'];
      arData['customer_text'] = arRecordView['customer_text'];
      arData['customer_id'] = arRecordView['customer_id'];
    } else if (this.strModule == 'customers') {
      arData['address'] = arRecordView['address'];
      arData['customer_text'] = arRecordView['customer_text'];
      arData['customer_id'] = arRecordView['id'];
    }

    let popupConfig : {[k: string]: any} = {
        //Here, we pass all the data we need inside the dialog.
        data: {
            "arData": arData,
            "strModule": 'jobs',
            "strId": strId,
            "strMode": (strId) ? 'edit' : 'add'
        },
        disableClose: true
      };

    // IF MOBILE
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      popupConfig.width = '100%';
      popupConfig.height = '100%';
      popupConfig.maxHeight = '100vh';
      popupConfig.maxWidth = '100vw';
    } else {
      // display as pop up
      popupConfig.width = '80%';
      popupConfig.height = 'auto';
    }

    // This line initializes and opens dialog.
    let editRecordDialog = this.dialog.open(EditformComponent, popupConfig);

    editRecordDialog.afterClosed().first().subscribe(editRecordData => {
      if (editRecordData !== undefined && editRecordData.status == 'save') {
        this.filter();
      }
    });

  }

  /**
   * Opens the job creation wizard
   *
   * @returns {void}
   */
  openCreateJobWizard(): void {
    let arRecordView = this.viewService.arRecord;

    let jobWizardConfig = {
      record_type: 'jobs',
      creating_from_widget: true,
    };

    if (this.strModule === 'sites') {
      jobWizardConfig['site_id'] = arRecordView['id'];
      jobWizardConfig['customer_id'] = arRecordView['customer_id'];
    } else if (this.strModule === 'customers') {
      jobWizardConfig['customer_id'] = arRecordView['id'];
    } else if (this.strModule === 'opportunities') {
      jobWizardConfig['customer_id'] = arRecordView['customer_id'];
      jobWizardConfig['site_id'] = arRecordView['site_id'];
      jobWizardConfig['opportunity_id'] = arRecordView['id'];
      jobWizardConfig['contact_id'] = arRecordView['contact_id'];
    }

    let otherConfig = {
      panelClass: ['mat-dialog-container-overflow-auto', 'job-wizard-step'],
      data: jobWizardConfig
    };

    let popup = new PopupConfig(
      CreateJobWizardComponent,
      { width: '60%', height: 'auto' },
      { width: '70%', height: 'auto' },
      otherConfig
    );

    this.dialog
      .open(popup.component, popup.config)
      .afterClosed()
      .first()
      .subscribe(_ => {
        setTimeout(() => {
          this.onRefresh();
        }, 3500);
      });
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate, arCustomConfig = []) {

    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('lll');
  }

  /**
   *
   * @param strId - record id
   */
  deleteJob(strId) {
    //We tell the app component to open the confirmation.
    this.notifService.sendConfirmation() .subscribe(
      confirmation => {
        //If the user confirmed, delete the record by field
        if (confirmation.answer) {
          this.recordService.deleteRecord('jobs', strId).first().subscribe(
            data => {
              if(data) {
                this.fetchList('default');
                this.notifService.notifySuccess('record_delete_success')
              } else {
                this.notifService.notifyError('record_delete_failed');
              }
            });
        }
      }
    );
  }

  /**
   * Refreshes current list
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.filter();
  }

  ngOnChanges(): void {
    this.fetchList('default');
  }
}
