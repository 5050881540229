
    <fieldmagic-async-content
      [loading]="fetching$ | async"
    >
      <ng-template fieldmagicAsyncLoadedContent>
        <div
           *ngIf="notes$ | async | filled"
          class="w-100 d-flex flex-row sticky-notes"
        >
          <div
            *ngFor="let note of notes$ | async"
            class="alert alert-warning sticky-note pointer"
            (click)="onEditNote(note)"
          >
            <div class="w-100 d-flex justify-content-between">
              <small>
                <fieldmagic-icon
                  customClass="sticky-note-icon"
                  icon="thumbtack"
                >
                </fieldmagic-icon>
              </small>

              <p style="font-size:0.7rem">{{ note.created_at | format: 'll' }}</p>
            </div>
            <div class="w-100 d-flex">
              <p>{{ note.notes | truncate: 25 }}</p>
            </div>
          </div>
        </div>
      </ng-template>
    </fieldmagic-async-content>
  