import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvanceSearchboxTemplate, StaticEnum } from '../../../objects/advance-searchbox';
import { ListingService } from '../../../services/listing.service';
import { AsConfigService } from '../../../shared/external-libraries/ngx-advanced-searchbox/src/public_api';
import { SearchService } from '../../../services/search.service';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, pairwise, scan, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { NotificationService } from '../../../services/notification.service';
import { LooseObject } from '../../../objects/loose-object';
import { AdvanceReportService } from '../../../services/advance-report.service';
import { HttpErrorResponse } from '@angular/common/http';
import { clone, cloneDeep, isEmpty, pick, range, reduce } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfig } from '../../../objects/advance-reports';
import { filled } from '../../../shared/utils/common';
import { ClientStoreService } from '../../../services/client-store.service';

@Component({
  selector: 'app-advance-reports-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {
  unsubscribeSubscriptions: Subject<void> = new Subject<void>();

  moduleList$: Observable<AdvanceSearchboxTemplate>;
  assetTypeList$: Observable<object[]>;

  moduleFieldList: AdvanceSearchboxTemplate[] = [];
  fieldList: AdvanceSearchboxTemplate[] = [];
  fieldDetails: LooseObject;
  selectedFields = [];

  globalFilter: object = {};

  chartTypes = ['bar', 'line'];
  groupingTypes = ['date_histogram', 'terms', 'histogram'];

  // For date_histogram
  dateTimeFields = [];
  calendarInterval = ['week', 'month', 'quarter', 'year'];

  // For histogram
  numericFields = [];
  minGranularity = 1;
  maxGranularity = 1000;

  // For Top Values
  dropdownFields = [];
  minTopValueSize = 1;
  maxTopValueSize = 50;

  // For datasets
  aggregationType = ['sum', 'min', 'max', 'value_count'];

  generalSetupForm: FormGroup;
  chartSetupForm: FormGroup;

  // Setup Preview
  dashboardData$: Observable<LooseObject[]>;
  resultSet: LooseObject[];

  multiselectMessages = {
    'minlength': (params: { requiredLength: number }) => this.translateService.instant('validation_error_messages.min_select', { min: params.requiredLength }),
    'maxlength': (params: { requiredLength: number }) => this.translateService.instant('validation_error_messages.max_select', { max: params.requiredLength })
  };

  departmentRestriction = false;

  private searchTerm$ = new BehaviorSubject<string>('');
  private page$ = new BehaviorSubject<number>(1);
  private hasMorePages = true;

  constructor(
    public dialogRef: MatDialogRef<WizardComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public formBuilder: FormBuilder,
    public listService: ListingService,
    public asConfig: AsConfigService,
    public searchService: SearchService,
    public advanceReportService: AdvanceReportService,
    public notifService: NotificationService,
    public translateService: TranslateService,
    private client: ClientStoreService,
  ) {
    this.departmentRestriction = this.client.isDepartmentTracking() && this.client.isDepartmentRestricted();
  }

  ngOnInit() {
    this.generalSetupForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(128)]],
      module: ['', Validators.required],
      asset_type_id: [null],
      fields: [[], [Validators.required, Validators.minLength(1)]],
      // TODO: Find a way to validate advanced searchbox
    });
    this.chartSetupForm = this.formBuilder.group({
      chart_configs: this.formBuilder.array([], [Validators.min(1), Validators.max(4)])
    });

    this.listService.setModule('advance_reports');
    this.moduleList$ = this.listService.getAdvancedSearchboxTemplate().pipe(
      takeUntil(this.unsubscribeSubscriptions),
      map((response) => response.find(x => x.model === 'module')),
      map((response) => {
        return {
          ...response,
          domains: (response.domains as StaticEnum[]).map(x => {
            return {
              ...x,
              label: this.translateService.instant(x.label),
            }
          })
        }
      })
    );

    this.assetTypeList$ = this.searchTerm$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        this.page$.pipe(
          switchMap((page) => {
            return this.advanceReportService.searchAssetTypes(term, page).pipe(
              map((response) => {
                return response.map(x => {
                  return {
                    label: x.name,
                    value: x.id,
                    attributes: x.attributes,
                  }
                })
              }),
              tap((response) => this.hasMorePages = response.length > 0)
            )
          }),
          scan((acc, items) => (this.page$.value === 1 ? items : [...acc, ...items]), []),
        )
      )
    );

    this.generalSetupForm.get('module').valueChanges
      .pipe(startWith(''), pairwise(), takeUntil(this.unsubscribeSubscriptions))
      .subscribe(([prevValue, newValue]) => {
        if (prevValue) {
          while (this.chartConfigForms.length > 0) {
            this.chartConfigForms.removeAt(0);
          }
          this.addChart();
          this.generalSetupForm.get('fields').reset();
        }
        this.generalSetupForm.get('asset_type_id').reset();

        this.listService.setModule(newValue);
        const metadata$ = this.listService.getAdvancedSearchboxTemplate();
        metadata$.pipe(takeUntil(this.unsubscribeSubscriptions)).subscribe((template) => {
          this.setupAdvanceSearchbox(template);
          this.moduleFieldList = clone(this.fieldList);
        });
      });
    if (this.dialogData.isNew === false) {
      this.generalSetupForm.patchValue(pick(this.dialogData.recordDetails, ['name', 'module', 'asset_type_id']));
      if (filled(this.dialogData.recordDetails.asset_type_id)) {
        this.searchTerm$.next(this.dialogData.recordDetails.asset_type_name);
        this.assetTypeList$.subscribe((assetTypeList) => {
          const selectedOption = assetTypeList.find(x => x['value'] === this.dialogData.recordDetails.asset_type_id);
          this.loadAssetTypeFields(selectedOption, false);
          this.generalSetupForm.get('fields').setValue(this.dialogData.recordDetails.fields);
        });
      } else {
        this.generalSetupForm.get('fields').setValue(this.dialogData.recordDetails.fields);
      }

      // PHP return this value as [] if not defined. And when object is instatiated using array
      // It will return an empty json when stringify it
      this.globalFilter = isEmpty(this.dialogData.recordDetails.filters) ? {} : cloneDeep(this.dialogData.recordDetails.filters);

      let chartConfigs = this.dialogData.recordDetails.chart_config as LooseObject[];
      chartConfigs.forEach((config, index) => {
        this.addChart(config.datasets.length);

        this.chartConfigForms.controls[index].patchValue({
          "date_histogram": { "field": null, "calendar_interval": null },
          "histogram": { field: null, interval: null },
          "terms": { field: null, size: null },
          ...config
        });
      });
    } else {
      this.addChart();
    }
  }

  loadAssetTypeFields(selectedOption, resetFields: boolean = true) {
    if (filled(selectedOption)) {
      this.fieldList = this.moduleFieldList.concat(selectedOption.attributes.map(x => {
        return {
          ...x,
          translated_label: x.label,
        }
      }));
      this.fieldDetails = reduce(this.fieldList, (obj, param) => {
        obj[param.model] = {
          type: param.dataType,
          label: param.label,
        };
        return obj;
      }, {});

      /// rebuild the dropdown fields based on selected asset type
      this.dropdownFields = this.fieldList.filter(x => FIELDS_AVAILABLE_FOR_GROUPING.includes(x.dataType));
    }

    if (resetFields) {
      this.generalSetupForm.get('fields').reset();
    }
  }

  get singleChartForm(): FormGroup {
    const form = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(128)]],
      grouping_type: [null, Validators.required],
      date_histogram: this.formBuilder.group({
        field: [null, Validators.required],
        calendar_interval: ['month', Validators.required],
      }),
      histogram: this.formBuilder.group({
        field: [null, Validators.required],
        interval: [null, [Validators.required, Validators.min(1), Validators.max(100000000)]],
      }),
      terms: this.formBuilder.group({
        field: [null, Validators.required],
        size: [5, [Validators.required, Validators.min(1), Validators.max(10)]],
      }),
      datasets: this.formBuilder.array([], [Validators.min(1), Validators.max(2)])
    });

    form.get('date_histogram').disable();
    form.get('histogram').disable();
    form.get('terms').disable();

    return form;
  }

  get singleDatasetForm(): FormGroup {
    const form = this.formBuilder.group({
      name: [this.translateService.instant('advance_report_lang.record_count'), [Validators.required, Validators.minLength(5), Validators.maxLength(128)]],
      chart_type: ['bar', Validators.required],
      type: ['value_count', Validators.required],
      field: [null],
      color: ['#ff6347', Validators.required],
    });
    form.get('type').valueChanges.pipe(takeUntil(this.unsubscribeSubscriptions)).subscribe((value) => {
      if (value && value !== 'value_count') {
        form.get('field').setValidators([Validators.required]);
      } else {
        form.get('field').clearValidators();
        form.get('field').setValue(null);
        form.get('field').updateValueAndValidity();
      }
    });
    return form;
  }

  get chartConfigForms(): FormArray {
    return this.chartSetupForm.get('chart_configs') as FormArray;
  }

  datasetForm(chartIndex: number): FormArray {
    return this.chartConfigForms.controls[chartIndex].get('datasets') as FormArray;
  }

  removeChart(index: number) {
    if (this.chartConfigForms.length === 1) {
      this.notifService.notifyError('advance_report_lang.min_charts');
      return;
    }
    this.chartConfigForms.removeAt(index);
  }

  addChart(datasetCount: number = 1) {
    if (this.chartConfigForms.length >= 4) {
      this.notifService.notifyError('advance_report_lang.max_charts');
      return;
    }
    const singleChartForm = cloneDeep(this.singleChartForm);

    singleChartForm.get('grouping_type').valueChanges.pipe(takeUntil(this.unsubscribeSubscriptions)).subscribe((value) => {
      this.groupingTypes.forEach((type) => {
        singleChartForm.get(type).disable();
      });
      singleChartForm.get(value).enable();
    });

    for (let x = 0; x < datasetCount; x++) {
      (singleChartForm.get('datasets') as FormArray).push(this.singleDatasetForm);
    }
    this.chartConfigForms.push(singleChartForm);
  }

  removeDataset(chartIndex: number, datasetIndex: number) {
    if (this.datasetForm(chartIndex).length === 1) {
      this.notifService.notifyError('advance_report_lang.min_datasets');
      return;
    }
    this.datasetForm(chartIndex).removeAt(datasetIndex);
  }

  addDataset(chartIndex: number) {
    // Default color for every new dataset
    const colorIndex = [
      '#ff6347', // Tomato Red,
      '#4caf50', // Leaf Green,
      '#2196f3', // Sky Blue,
      '#ff69b4', // Hot Pink,
      '#ffd700', // Gold,
      '#00ced1', // Dark Turquoise,
      '#ff4500', // Orange Red,
      '#8a2be2', // Blue Violet,
    ];

    if (this.datasetForm(chartIndex).length >= 2) {
      this.notifService.notifyError('advance_report_lang.max_datasets');
      return;
    }
    const singleDatasetForm = this.singleDatasetForm;
    singleDatasetForm.patchValue({
      color: colorIndex[this.datasetForm(chartIndex).length]
    });
    this.datasetForm(chartIndex).push(singleDatasetForm);
  }

  setupAdvanceSearchbox(template: AdvanceSearchboxTemplate[]): void {
    // Setup Template
    this.fieldList = template.map(x => {
      const extraLabel = filled(x.extraLabel) ? ` (${this.translateService.instant(x.extraLabel)})` : '';
      return {
        ...x,
        translated_label: this.translateService.instant(x.label) + extraLabel,
      }
    });
    this.fieldDetails = reduce(template, (obj, param) => {
      obj[param.model] = {
        type: param.dataType,
        label: param.label,
      };
      return obj;
    }, {});
    this.dateTimeFields = this.fieldList.filter(x => x.dataType === 'datetime');
    this.numericFields = this.fieldList.filter(x => ['currency', 'number'].includes(x.dataType));
    this.dropdownFields = this.fieldList.filter(x => FIELDS_AVAILABLE_FOR_GROUPING.includes(x.dataType));

    // Get filter with remote related data
    this.fieldList.forEach((asTemplate: AdvanceSearchboxTemplate) => {
      if (asTemplate.domains && asTemplate.domains === 'remote') {
        this.asConfig.customDomainsAsyncFn[asTemplate.model] = (observable, viewModel, model) => {
          return observable.pipe(
            debounceTime(400),
          ).switchMap((term) => {
            return this.searchService.getRemoteData(asTemplate, term);
          });
        };
      }
    });
  }

  /**
   * When the user clicks next.
   *
   * @param {MatStepper} stepper
   */
  next(stepper: MatStepper, form: FormGroup): void {
    this.touchAllFields(form);

    if (form.invalid) {
      this.notifService.notifyError('please_complete_the_form');
    } else {
      stepper.next();
      if (stepper.selectedIndex === 2) {
        this.generateDashboard();
      }
    }
  }

  touchAllFields(form: FormGroup) {
    for (let fieldName in form.controls) {
      if (form.controls[fieldName] instanceof FormArray) {
        let formGroups = form.controls[fieldName] as FormArray;
        formGroups.controls.forEach((formGroup: FormGroup) => {
          this.touchAllFields(formGroup);
        });
      } else if (form.controls[fieldName] instanceof FormGroup) {
        this.touchAllFields(form.controls[fieldName] as FormGroup);
      } else {
        form.controls[fieldName].markAsTouched();
      }
    }
  }

  closeDialog(action: string, data: LooseObject = {}) {
    this.unsubscribeSubscriptions.next();
    this.unsubscribeSubscriptions.complete();
    this.dialogRef.close({
      action: action,
      data: data
    });
  }

  generateDashboard() {
    this.dashboardData$ = this.advanceReportService.getAdvanceReportData({
      filters: this.globalFilter,
      fields: this.generalSetupForm.get('fields').value,
      chart_config: this.chartConfigForms.value as ChartConfig,
      asset_type_id: filled(this.generalSetupForm.get('asset_type_id').value) ? this.generalSetupForm.get('asset_type_id').value : null,
    }, this.generalSetupForm.get('module').value).pipe(
      shareReplay(),
      tap((response) => {
        this.resultSet = response.resultset;
      }),
      map((response) => {
        return Object.keys(response.aggregations).map(chartId => {
          return this.advanceReportService.aggregationResultToChart(response.aggregations[chartId], response.config[chartId]);
        });
      })
    );
  }

  saveDashboard() {
    this.advanceReportService.saveAdvanceReportData({
      name: this.generalSetupForm.get('name').value,
      filters: this.globalFilter,
      fields: this.generalSetupForm.get('fields').value,
      chart_config: this.chartConfigForms.value,
      asset_type_id: filled(this.generalSetupForm.get('asset_type_id').value) ? this.generalSetupForm.get('asset_type_id').value : null,
    }, this.generalSetupForm.get('module').value, this.dialogData.id || null).pipe(takeUntil(this.unsubscribeSubscriptions)).subscribe((response) => {
      this.closeDialog('save', response);
    }, (error: HttpErrorResponse) => {
      if (error.status == 400 || error.status == 422) {
        this.notifService.notifyWarning('record_invalid_parameters');
      }
    });
  }

  // Asset type infinit scroll functions

  onSearch(event): void {
    this.searchTerm$.next(event.term);
    this.page$.next(1);
  }

  onScrollToEnd(): void {
    if (this.hasMorePages) {
      this.page$.next(this.page$.value + 1);
    }
  }
}

const FIELDS_AVAILABLE_FOR_GROUPING = [
  'dropdown',
  'multiselect',
  'uuid',
  'checkbox',
  'text',
  'number',
];