import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AUDIT_TRAIL_AVAILABLE_MODULES } from '../constant';
import { isNil } from 'lodash-es';

@Directive({
  selector: '[isAuditSupported]'
})
export class IsAuditingModuleSupportedDirective implements OnInit, OnDestroy {
  @Input('isAuditSupported')
  set moduleName(value: string) {
    this._isSupported$.next(
      AUDIT_TRAIL_AVAILABLE_MODULES.includes(value),
    );
  }

  private readonly _isSupported$ = new BehaviorSubject<boolean>(false);

  private _isSupportedSubscription: Subscription;

  constructor(
    private readonly _template: TemplateRef<any>,
    private readonly _container: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this._isSupportedSubscription = this._isSupported$.subscribe((isSupported) => {
      if (isSupported) {
        this._container.createEmbeddedView(this._template);
      } else {
        this._container.clear();
      }
    });
  }

  ngOnDestroy(): void {
    if (! isNil(this._isSupportedSubscription)) {
      this._isSupportedSubscription.unsubscribe();
    }
  }
}