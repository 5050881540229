import { Component, ElementRef, OnInit, Inject, ViewChild, OnDestroy, HostListener, ErrorHandler } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { concat } from 'rxjs/observable/concat';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, tap, finalize, filter, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { FormGroup, FormControl } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { RelateIds } from '../../../../lists/relate-ids';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../../services/notification.service';
import { ActivitiesService } from '../../../../services/activities.service';
import { RecordService } from '../../../../services/record.service';
import { FormService } from '../../../../services/form.service';
import { EmailAddressOption } from '../../../../objects/email-address-option';
import { ViewService } from '../../../../services/view.service';
import { ModuleInitial } from '../../../../lists/module-initial';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { ActivityType } from '../../../../lists/activity';
import { ClientStoreService } from '../../../../services/client-store.service';
import { FileService } from '../../../../services/file/file.service';
import { Liquid } from 'liquidjs';
import { LooseObject } from '../../../../objects/loose-object';
import { EmailTemplate } from '../../../../objects/email-template';
import { LocalPipe } from '../../../../pipes/moment/local/local.pipe';
import {
  SaveEmailTemplateDialogComponent,
  SaveEmailTemplateDialogComponentProps,
  SaveEmailTemplateDialogComponentState,
  SaveEmailTemplateDialogComponentStates
} from './email-templates/save-template/save-template.component';
import { SignaturesComponent } from './signatures/signatures.component';
import { Select } from '../../../../objects/select';
import { environment } from '../../../../../environments/environment';
import { sprintf } from 'sprintf-js';
import { Guid } from 'guid-typescript';
import { blank, data_get, either, filled, fallback, when, whenFilled } from '../../../utils/common';
import { spf } from '../../../utils/str';
import { trim, get, each, set, uniqBy, replace, padStart, isEmpty, toString, concat as _concat, map as _map, clone } from 'lodash-es';
import { Client } from '../../../../objects/client';

const engine = new Liquid();

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit, OnDestroy {

  public objEmailConfigData: EmailConfigData|null = null;

  /**
   * Whitelist of allowed formats
   * inside the quill editor. We won't
   * allow images and video to be pasted.
   *
   * @var {string[]}
   */
  public arFormats: string[] = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula'
  ];

  public bShowLoader: any = {
    'draft': false,
    'send': false,
    'template': false,
    'delete_draft': false,
    'uploading_attachment': false,
  };
  public strRelatedId: any = RelateIds;
  public bHasCc: boolean = false;
  public bHasBcc: boolean = false;
  public bPageLoaded: boolean = false;
  public arFiles : any = [];
  public strModuleInitial: string;
  public uploader:FileUploader = new FileUploader({
    disableMultipart:true,
    url: ''
  });

  public fromSystemUser = 'Fieldmagic <system@mail.fieldmagic.co>'
  public addTag: (email) => void;

  @ViewChild('fileDrop') fileInput: ElementRef;

  public hasBaseDropZoneOver:boolean = false;
  public isFileUploaded: boolean = false;

  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  public emailForm: FormGroup

  public objEmailField = {
    to: {
      key: 'to',
      label: 'to',
      type: 'relate',
      formControlName: 'relate',
      module: 'leads|contacts',
      multiple: true,
      required: true,
      readonly: false,
      default_value: '',
      maxSelectedItems: 20
    },
    cc: {
      key: 'cc',
      label: 'cc',
      type: 'relate',
      formControlName: 'relate',
      module: 'leads|contacts',
      multiple: true,
      required: false,
      readonly: false,
      default_value: '',
      maxSelectedItems: 20
    },
    bcc: {
      key: 'bcc',
      label: 'bcc',
      type: 'relate',
      formControlName: 'relate',
      module: 'leads|contacts',
      multiple: true,
      required: false,
      readonly: false,
      default_value: '',
      maxSelectedItems: 20
    },
    subject: {
      key: 'subject',
      type: 'text',
      label: 'subject',
      required: true
    },
    body: {
      key: 'body',
      type: 'textarea',
      label: 'body',
      required: false
    },
    from: {
      key: 'from',
      type: 'text',
      label: 'from',
      required: false
    },
  };

  public is_Empty = isEmpty;
  public bSubmitted: boolean = false;
  public objEmailValidation = {
    to: true,
    cc: true,
    bcc: true,
    subject: true,
    attachment: true,
    body: true
  };

  public strEmailAddress: string = '';
  public strTemplateId: string = '';
  public strTemplateName: string = '';
  public arTemplates: any = [];
  public modalRef: BsModalRef;
  public isDraft: boolean = false;
  public strActivityId: string = '';
  public bEmailStructure = false;
  public bIsArchivedMail: boolean = false;

  public isFetchingEmailTemplates: boolean = false;
  public strDefaultSignature: string = null;
  public currentUserEmailAddress: string = '';
  public fromAddresses: Array<Select> = [];
  public currentUserEmail: string = '';
  /**
   * Wysiwig Config
   */
  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['code-block'],
      //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      //[{ 'direction': 'rtl' }],                         // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'],                                         // remove formatting button
      ['link']
    ]
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  /**
   * Internal Use: contains list of subscription that is used throughout the component that would be
   * cleaned up when this component is destroyed.
   *
   * @var {Subscription[]}
   */
  protected subscriptions: Subscription[] = [];

  public objReadablePipe = new LocalPipe;

  public objModuleFields: any;

  public arSuggested: LooseObject[] = [

  ];

  private _bSignatureAppended = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public clientStoreService: ClientStoreService,
    public dialogRef: MatDialogRef<EmailComponent>,
    private activitiesService: ActivitiesService,
    private notifService: NotificationService,
    private translateService: TranslateService,
    private recordService: RecordService,
    private formService: FormService,
    private modalService: BsModalService,
    private viewService: ViewService,
    protected localStorageService: LocalStorageService,
    private fileService: FileService,
    protected dialogFactory: MatDialog,
    private readonly _auditor: ErrorHandler,
  ) {
    dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

  ngOnInit() {
    this.activitiesService.getEmailConfig({
      contact_roles_data: this._getContactRolesData(),
      email_templates_module: get(this.data, 'module'),
      email_signature_id: this.localStorageService.getJsonItem('current_client')['default_signature'],
      customer_id: either(this.data, ['record_data.customer_id', 'record_data.customer.id']),
      site_id: either(this.data, ['record_data.site_id', 'record_data.site.id']),
      contact_id: get(this.data, 'record_data.contact_id')
    }).subscribe(response => {
      this.objEmailConfigData = get(response, 'data', null) || null;
      this._preInitializeComponentActions();
    });
  }

  /**
   * Add the contact role to the "to" field.
   *
   * @param {LooseObject} objRole
   */
  addTo(objRole: LooseObject) {

    if (objRole['email_address'] && objRole['email_address'].length > 0) {
      let arTo = this.emailForm.controls.to.value || [];
      let primaryEmailAddress = objRole['email_address'].find(email => toString(get(email, 'primary')) == '1');

      if (blank(primaryEmailAddress)) {
        this.notifService.notifyWarning('email_address_primary_not_found');
        return;
      }

      arTo.push({
        id: objRole['contact_id'],
        text: objRole['contact_text'],
        email_address: primaryEmailAddress
      });

      this.patchEmailAddressValue(arTo);
    }

  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  initializeComponent() {
    // Set draft flag
    if (typeof(this.data['activity']) != 'undefined') {
      this.isDraft = this.data['activity']['is_draft'];
      this.strActivityId = this.data['activity']['id'];
    }

    let defaultFromOption = get(this.objEmailConfigData, 'client_data.email_domain_verified', false)
      ? 'current_user'
      : 'system_user';

    // initialize form
    this.emailForm = new FormGroup({
      to:  new FormControl(''),
      cc:  new FormControl(''),
      bcc:  new FormControl(''),
      subject:  new FormControl(''),
      body: new FormControl(''),
      from: new FormControl(defaultFromOption),
    });

    // Loop each relate fields
    Object.keys(this.objEmailField).forEach( item => {
      // Transform field to object
      this.objEmailField[item] = this.formService.tranformFieldObject(this.objEmailField[item]);
      // Do we have relate type? Initiate relate fields
      if (this.objEmailField[item]['controlType'] == 'relate') {
        // If activity is an email draft.
        if (this.data['activity'] != undefined && this.data['activity']['is_draft']) {
          let bIsJsonString = this.IsJsonString(this.data['activity'][item]);
          let initialValue = (bIsJsonString) ? JSON.parse(this.data['activity'][item]) : [];
          this.onSetRelate(item, initialValue);
        } else if (this.data['quote_data'] != undefined && item == 'to') {
          this.onSetRelate(item, this.data['quote_data']['send_to'])
        } else {
          this.onSetRelate(item);
        }
      }
    });

    this.getEmailRecord();

    this.addTag = this.addTagRef.bind(this);

    // If the activity is an email draft.
    if (this.data['activity'] != undefined && this.data['activity']['is_draft']) {

      // Set the subject of the email draft.
      if (this.data['activity']['activity_name'] != null) {
        this.emailForm.patchValue({
          subject: this.data['activity']['activity_name']
        })
      }

      // Set the body of the email draft.
      if (this.data['activity']['notes'] != null) {
        this.emailForm.patchValue({
          body: this.data['activity']['notes']
        })
      }

      // populate to address
      if (this.data['activity']['to'] != null) {
        let previousToEmailAddresses = this.data['activity']['to'] || null;
        this.populateEmailAddressFieldFromRecordValues('to', previousToEmailAddresses);
      }

      // If there are emails under cc.
      if (this.data['activity']['cc'] != null) {
        let previousToEmailAddresses = this.data['activity']['cc'] || null;
        this.populateEmailAddressFieldFromRecordValues('cc', previousToEmailAddresses);
      }

      // Same process in cc.
      if (this.data['activity']['bcc'] != null) {
        let previousToEmailAddresses = this.data['activity']['bcc'] || null;
        this.populateEmailAddressFieldFromRecordValues('bcc', previousToEmailAddresses);
      }

    }

    // If the email view is from the quote preview.
    if (this.data['email_type'] == 'pdf') {

      let arEmail = [];
      // Loop through the email.
      this.data['quote_data']['send_to'].forEach( objEmail => {
        if (objEmail['email_address']['primary'] == '1') {
          // Set the email as the selected email on the dropdown.
          arEmail.push(objEmail);
        }
      })

      this.onSetRelate('to', this.data['quote_data']['send_to']);

      // Put the attachment on the list of files attached.
      this.arFiles = this.data['quote_data']['attachment'];

      // Set subject and the email addressee.
      this.emailForm.patchValue({
        subject: this.data['quote_data']['subject'],
      });

      if (filled(arEmail)) {
        this.patchEmailAddressValue(arEmail);
      }

      // Indicator that this is from quote preview.
      this.bEmailStructure = true;
    }

    // FC-852: hide delete button when sending new mail from archived
    this.bIsArchivedMail = (this.data['is_archived']) ? true : false;
    this.strModuleInitial =  ModuleInitial[this.data.module];

    // FC-1261: autofill recipients of the new email when it is from an archived form
    if (this.bIsArchivedMail && this.data['activity']['email_participants']) {
      let participantsList = this.data['activity']['email_participants'] || [];
      let currentEmailAddress = this.localStorageService.getItem('user_email');
      let filteredParticipantsList = participantsList
        .filter((participant) => {
          return participant.email !== currentEmailAddress
        });

      this.populateEmailAddressFieldFromRecordValues('to', filteredParticipantsList);

      // FC-1261: auto populate subject field from the current email thread
      this.emailForm.patchValue({
        subject: this.data['activity']['activity_name']
      });
    }

    if (get(this.data, ['activity', 'from'])) {
      this.emailForm.patchValue({
        from: this.data['activity']['from']
      })
    }

    // FC-3943: get module fields to be used in email template variables
    this.objModuleFields = {
      ...get(this.objEmailConfigData, 'record_module_fields.available_fields', {}),
      ...get(this.objEmailConfigData, 'record_module_fields.used_fields', {}),
    }

    if (filled(get(this.objEmailConfigData, 'default_signature'))) {
      this.setSignature(get(this.objEmailConfigData.default_signature, 'signature'));
    }
  }

  emailFormValidator(strType) {

    // Do we have value?
    if (!isEmpty(strType)) {

      // Set bsubmitted to true
      this.bSubmitted = true;
      // Reset validation every time will call this method
      this.objEmailValidation = {
        to: true,
        cc: true,
        bcc: true,
        subject: true,
        attachment: true,
        body: true
      };
      // What type of saving do we have?
      switch (strType) {
        case 'template':
          // Do we have these fields?
          if (!isEmpty(this.emailForm.get('subject').value) ||
              !isEmpty(this.arFiles) ||
              !isEmpty(this.emailForm.controls['body'].value)) {

              // Mark these fields as true
              this.objEmailValidation.subject = true;
              this.objEmailValidation.attachment = true;
              this.objEmailValidation.body = true;
              // Return true
              return true;
          } else {

            // Mark thse fields as false
            this.objEmailValidation.subject = false;
            this.objEmailValidation.attachment = false;
            this.objEmailValidation.body = false;
          }
        break;
        case 'draft':
          // Make sure that we have on of these fields?
          if (!isEmpty(this.emailForm.get('to').value) ||
              !isEmpty(this.emailForm.get('cc').value) ||
              !isEmpty(this.emailForm.get('bcc').value) ||
              !isEmpty(this.emailForm.get('subject').value) ||
              !isEmpty(this.arFiles) ||
              !isEmpty(this.emailForm.get('body').value)) {

              // Return true
              return true;
          }
        break;
        case 'send':
          // Do we have these fields?
          if (!isEmpty(this.emailForm.get('to').value) &&
              !isEmpty(this.emailForm.get('subject').value)) {
              // Do we have attechment or body?
              if (!isEmpty(this.arFiles) || !isEmpty(this.emailForm.controls['body'].value)) {
                // Mark these fields as true
                this.objEmailValidation.to = true;
                this.objEmailValidation.subject = true;
                this.objEmailValidation.attachment = true;
                this.objEmailValidation.body = true;
                // Return true
                return true;
              }
          }

          // Mark thse fields as false
          this.objEmailValidation.to = false;
          this.objEmailValidation.subject = false;
          this.objEmailValidation.body = false;

          // FC-1261: if we have empty body lets marked the attachments as invalid (required)
          if (! this.emailForm.get('body').value) {
            this.objEmailValidation.attachment = false;
          }
        break;
      }
    }

    // Return false
    return false;
  }


  /**
   * For Cancel button
   */
  cancelDialog(): void {
    if (this.isInProgress) {
      this.notifService.notifyWarning('form_in_progress');
    } else if(this.emailForm.dirty) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close('cancel');
        });
    } else {
      this.dialogRef.close('cancel');
    }
  }

  /**
   * Open template name modal
   */
   onSaveEmailTemplate() {
    // Validate data
    if (this.emailFormValidator('template')) {
      const dialog = this.dialogFactory.open<
        SaveEmailTemplateDialogComponent,
        SaveEmailTemplateDialogComponentProps,
        SaveEmailTemplateDialogComponentStates
      >(SaveEmailTemplateDialogComponent, {
        minWidth: '50vw',
        data: {
          subject: this.emailForm.get('subject').value,
          attachments: this.arFiles,
          body: this.emailForm.controls['body'].value,
          share_template: false
        }
      });

      dialog.afterOpened()
        .subscribe(() => this.bShowLoader['template'] = true);

      dialog.afterClosed()
        .pipe(
          filter((state) => state !== SaveEmailTemplateDialogComponentState.CANCELLED),
          finalize(() => {
            this.bShowLoader['template'] = false;
          })
        ).subscribe(() => {
          this.arTemplates = [];
        });
    }
  }

  /**
   * Select / Rendering Template
   */
  onSelectTemplate(strId) {
    // Do we have id?
    if (strId) {

      // Store template id
      this.strTemplateId = strId;
      let arSelectedTemplate: any = [];
      Object.keys(this.arTemplates).forEach( item => {
        // Do this item matches the id?
        if (this.arTemplates[item]['id'] == strId) arSelectedTemplate = this.arTemplates[item];
      });

      // Do we have selected template?
      if (arSelectedTemplate) {
        // if template body is blank, retain whatever value we already have in the body
        const regex = /(<([^>]+)>)/ig;
        const strSelectedTemplateBody: string = arSelectedTemplate.body.replace(regex, '');

        // Set value in form group
        this.emailForm.patchValue({
          ...arSelectedTemplate,
          // if subject from email template is blank, just retain whatever value we already have in the subject field
          subject: fallback(
            trim(arSelectedTemplate.subject),
            {
              fallback: () => get(this.emailForm.get('subject'), 'value'),
            }
          ),
          body: whenFilled(
            strSelectedTemplateBody,
            {
              then: () => arSelectedTemplate.body,
              else: () => get(this.emailForm.get('body'), 'value', '')
            }
          )
        });

        if (filled(strSelectedTemplateBody) && this.strDefaultSignature) {
          this._bSignatureAppended = false;
        }

        if (this.strDefaultSignature && !this._bSignatureAppended) {
          this.emailForm.controls.body.setValue(this.emailForm.controls.body.value + this.strDefaultSignature);
          this._bSignatureAppended = true;
        }

        // Do we have 'attachment' value? Set it to the field
        if (arSelectedTemplate['attachment']) {
          this.arFiles = _concat(this.arFiles, _map(arSelectedTemplate['attachment'], (attachment) => ({
            ...attachment,
            from_template: true,
          })));
        }
        // FC-3943: If email template has a module, transform any variables in the body into readable text
        if (arSelectedTemplate['module'] && this.objModuleFields) {
          this.loadTemplateModuleVariablesData(arSelectedTemplate);
        }
      }
    }
  }

  /**
   * Replace variables in string with values.
   *
   * Content taken from the 'useTemplate' method in 'SmsComponent'.
   * client/src/app/shared/components/widget/activities/messages/sms/sms.component.ts
   *
   * @param {EmailTemplate} objEmailTemplate
   *
   * @returns {void}
   */
  loadTemplateModuleVariablesData(objEmailTemplate: EmailTemplate): void {
    let objVariableSource: LooseObject = clone(this.viewService.getRecord()['record_details']);

    Object.keys(this.viewService.getRecord()['record_details']).forEach(field => {
      if (objVariableSource[field] && typeof objVariableSource[field] === 'string') {
        objVariableSource[field] = this.translateService.instant(objVariableSource[field]);
      }
      if (typeof objVariableSource == 'object' && objVariableSource[field + '_text'] != undefined) {
        objVariableSource[field] = objVariableSource[field + '_text'];
      }
      if (field.match(/_id/g) && objVariableSource[field.replace('_id', '') + '_text'] != undefined) {
        objVariableSource[field] = objVariableSource[field.replace('_id', '') + '_text'];

        if (this.data.module === 'sites' && field === 'primary_contact_id') {
          objVariableSource['primary_tenant_contact_id'] = objVariableSource[field];
        }
      }
      if (field == 'email_address' && Array.isArray(objVariableSource[field]) && objVariableSource[field].length > 0) {
        objVariableSource[field] = objVariableSource[field].find(email => email.primary === "1").email;
      }
      if (this.objModuleFields[field] !== undefined){
        if (objVariableSource[field] && this.objModuleFields[field].type == 'date') {
          objVariableSource[field] = this.objReadablePipe.transform(objVariableSource[field]).format('ll');
        }
        if (objVariableSource[field] && this.objModuleFields[field].type == 'datetime') {
          objVariableSource[field] = this.objReadablePipe.transform(objVariableSource[field]).format('lll');
        }
      }
    });

    engine
      .parseAndRender(objEmailTemplate.body, objVariableSource)
      .then(strTemplateText => {
        let bodyVal: string = strTemplateText;

        if (this.strDefaultSignature) {
          bodyVal = strTemplateText + this.strDefaultSignature;
          this._bSignatureAppended = true;
        }

        this.emailForm.patchValue({
          'body': bodyVal
        });
      });
  }

  /*
   * Submit the form's data to the Activities table
   * and send the email id not draft
   */
  onSubmit(isDraft = false) {

    let strLoadingId = (isDraft) ? 'draft' : 'send';
    let strRecordId = this.viewService.getRecord()['record_details']['id'];
    let strCompanyAlias = this.clientStoreService.getActiveClient().alias;
    let strReplyTo: any = "";
    let arTempEmail = {};

    if (!this.bEmailStructure) {
      strReplyTo = this.strModuleInitial + '.' + strRecordId + '.' + strCompanyAlias + '@archive.fieldmagic.co';
    } else {
      strReplyTo = this.strModuleInitial + '.' + this.viewService.getRecordNumber() + '@' + strCompanyAlias + '.mail.fieldmagic.co';
    }

    // Loop each relate fields
    Object.keys(this.objEmailField).forEach(item => {
      // Declare as object.
      arTempEmail[item] = {};
      // If not string (not an array of email.)
      if (typeof this.emailForm.controls[item].value != 'string') {
        // Initiate a variable, this is where we'll put the final list.
        arTempEmail[item]['final'] = [];
        // If the email list has 1 or more items.
        if (this.emailForm.controls[item].value != null && this.emailForm.controls[item].value.length != 0) {
          // Loop through email.
          this.emailForm.controls[item].value.forEach(email => {

            let objEmail = this.arRelateValues[item]['list'].find(address => {
              let selection = (typeof address == 'string') ? address : address['email_address']['email'];
              return (selection == email);
            });

            // If in the list, put in the variable for saving in API.
            if (objEmail != undefined) {
              arTempEmail[item]['final'].push(objEmail);
            } else {
              // If email has no relationship, add it as plain string in list of email
              arTempEmail[item]['final'].push(email)
            }
          })
        }
      }
    });


    // Validate data
    if (this.emailFormValidator((isDraft) ? 'draft' : 'send')) {

      let arRequestData = {
        alias: strCompanyAlias,
        module_id: this.data.record_id,
        module_field: this.strRelatedId[this.data.module],
        to: (arTempEmail['to']['final'] != undefined) ? arTempEmail['to']['final'] : [],
        cc: (arTempEmail['cc']['final'] != undefined) ? arTempEmail['cc']['final'] : [],
        bcc: (arTempEmail['bcc']['final'] != undefined) ? arTempEmail['bcc']['final'] : [],
        subject: this.emailForm.get('subject').value,
        note: this.emailForm.controls['body'].value,
        module: this.data.module,
        type: ActivityType['email'],
        attachment: this.arFiles,
        reply_to : strReplyTo,
        activity_id : this.data['activity'] !== undefined ? this.data['activity']['id'] : '',
        from: this.emailForm.controls['from'].value,
        formatted_from_name: this.formatFromNameByModule(),
      }

      // FC-2406: added validation to check if files and the content exceeds 10MB
      const MAX_CONTENT_SIZE = 10000;
      let totalContentSize = 0;
      let hasInProgressAttachment = false;

      arRequestData.attachment.forEach((attachment) => {
        totalContentSize += attachment.size;

        // check if we have an inprogress attachment
        if (! attachment.upload_name) hasInProgressAttachment = true;
      });
      // @see http://extraconversion.com/data-storage/characters/characters-to-bytes.html
      // @see https://aws.amazon.com/ses/faqs/#:~:text=Q%3A%20Is%20there%20a%20limit,are%20part%20of%20the%20message.
      // 1 byte = 0.001 KB, a character represents a 1 byte in size.
      const bodySize = (arRequestData.note) ? arRequestData.note.length : 0;
      totalContentSize += (bodySize / 1000);

      if (totalContentSize > MAX_CONTENT_SIZE) {
        this.notifService.notifyWarning('file_size_error');
        return;
      }

      if (hasInProgressAttachment) {
        this.notifService.notifyWarning('mail_attachment_inprogress');
        return;
      }

      // are all email forms valid and is not draft?
      // Or is email intended to be draft
      if (((this.emailForm.controls['to'].value &&
          this.emailForm.controls['subject'].value) &&
          (this.emailForm.controls['body'].value || this.arFiles.length > 0) &&
          !isDraft)) {

        // Show the loader in the right button.
        this.bShowLoader[strLoadingId] = true;

        // add draft flag to array data
        arRequestData['isDraft'] = false;

        // Call api for creating record in activity table
        this.activitiesService.createActivity(JSON.stringify(arRequestData))
        .pipe(
          finalize(() => this.bShowLoader[strLoadingId] = false),
          catchError((response) => {
            if (response.status === 400 && response.error === 'exceeds_maximum_content_size') {
              this.notifService.notifyWarning('file_size_error');
            } else if (response.error === 'reached_email_credits') {
              this.notifService.notifyWarning('you_have_reached_the_limit_for_sending_email');
            } else {
              this._auditor.handleError(response);
              this.notifService.notifyError('mail_sending_failed');
            }

            return of(null);
          }),
          filter((data) => ! isEmpty(data))
        )
        .subscribe((data) => {
          this.dialogRef.close(data);

          this.updateWidgetStatus(arRequestData);
        });
        // Is draft button clicked?
        // Check if any on body, subject cc bcc and is not empty
      } else if (isDraft && (
          this.emailForm.controls['to'].value ||
          this.emailForm.controls['subject'].value ||
          this.emailForm.controls['body'].value ||
          this.emailForm.controls['cc'].value ||
          this.emailForm.controls['bcc'].value)
        ) {

        // add draft flag to array data
        arRequestData['isDraft'] = true;

        // Show the loader in the right button.
        this.bShowLoader[strLoadingId] = true;

        // Check if draft has attachments.
        if (arRequestData.attachment.length != 0) {

          // Inform user that attachments will be lost if saved as draft.
          this.notifService.sendConfirmation('confirm_draft', 'saving_draft_attachments').subscribe(confirmation => {
            if (confirmation.answer) {
              this.bShowLoader[strLoadingId] = true;
              this.saveEmail(arRequestData, isDraft, strLoadingId);
            } else {
              this.bShowLoader[strLoadingId] = false;
            }
          });

        } else {
          this.saveEmail(arRequestData, isDraft, strLoadingId);
        }
      }
    } else {
      // If it's a draft, inform them they cant create an empty draft.
      if (isDraft) {
        this.notifService.notifyWarning('empty_draft');
      }
    }
  }

  /**
   * Saving an email.
   * @param arRequestData - the final data of the draft.
   * @param isDraft - flag if draft or send.
   * @param strLoadingId - which button to show the loader.
   */
  saveEmail(arRequestData, isDraft, strLoadingId) {
    // If the draft is already in the database and just needs update.
    if (this.data['activity'] != undefined && this.data['activity']['id'] != undefined) {
      // Set the id.
      arRequestData['id'] = this.data['activity']['id'];
      // Update draft in API.
      this.activitiesService.updateActivity(JSON.stringify(arRequestData))
        .pipe(
          finalize(() => this.bShowLoader[strLoadingId] = false)
        )
        .subscribe(
          data => {
            data['isDraft'] = isDraft;
            this.dialogRef.close(data);
            this.bShowLoader[strLoadingId] = false;
          }
        );
    } else {
      // Create the draft in the database.
      this.activitiesService.createActivity(JSON.stringify(arRequestData))
        .pipe(
          finalize(() => this.bShowLoader[strLoadingId] = false)
        )
        .subscribe(
          data => {
            data['isDraft'] = isDraft;
            this.dialogRef.close(data);
          }
        );
    }
  }

  /*
   * Reset form
   */
  onResetForm() {
    this.emailForm.reset();
    this.arFiles = [];
    this.strTemplateId = '';
    this.strTemplateName = '';
    this.emailFormValidator('clear');
  }

  onFileSelected(files: FileList): void {
    // if we don't have files dont continue
    if (files.length < 1) return;

    // const config = new UploadConfig;
    const regex = new RegExp(['image/*', 'application/*', 'text/*'].join('|'), 'i');

    each(files, (file: File) => {
      const { name, type, size } = file;

      // notify that the current selected file is invalid in size or in accepted file type
      if ((size / 1024 / 1024) > 8) {
        this.notifService.notifyWarning('mail_attachment_size_exceed');
        return;
      } else if (type.search(regex) === -1) {
        this.notifService.notifyWarning('mail_attachment_file_type_not_allowed');
        return;
      }

      this.bShowLoader.uploading_attachment = true;

      // push the current file to the stack and acquire the index of that pushed row in the stack
      const index = this.arFiles.push({
        name,
        size: size / 1024,
        type
      });

      let subscription = this.fileService.upload(file)
        .pipe(
          finalize(() => {
            const isUploadingAttachmentsCompleted = this.arFiles.length === this.arFiles.filter((file) => !!file.upload_name).length;

            // if all of the attachments are uploaded successfully we then
            // remove the disable flag on the component action buttons
            if (isUploadingAttachmentsCompleted) {
              this.bShowLoader.uploading_attachment = false;
            }
          })
        )
        .subscribe((response) => {
          this.arFiles[index - 1] = set(this.arFiles[index - 1], 'upload_name', response['filename']);
        });

      // push to subscriptions stack for later cleaning
      this.subscriptions.push(subscription);
      this.emailForm.markAsDirty();
    });
  }

  // Remove an attachment
  removeAttachement(itemName) {

    // Get index of attachment you want to delete
    var strSpliceIndex = this.arFiles.findIndex(
      item => (item.name == itemName)
    );

    // Delete the attachment from the array
    this.arFiles.splice(strSpliceIndex, 1);

  }

  public arRelateValues: any = [];
  public bRelateLoading: boolean = false;

  /**
   * Initiate or update the relate field
   */
  onSetRelate(strField, arInitial = []) {

    if (arInitial != null) {
      // If there are emails under cc, show the cc field.
      if (strField == 'cc' && arInitial.length > 0) {
        this.bHasCc = true;
      }
      // If there are emails under bcc, show the bcc field.
      if (strField == 'bcc' && arInitial.length > 0) {
        this.bHasBcc = true;
      }

      // If a new email, set the email in the record as the default email.
      if (arInitial.length == 0 && strField == 'to') {
        if (this.viewService.getRecord() !== undefined) {
          let objRecordDetails = this.viewService.getRecord()['record_details'];
          let objUsedFields = this.viewService.getRecord()['used_fields'];
          let strCurrentModule = this.data.module;
          let arEmailAddressesOptions = [];

          if (strCurrentModule == 'leads'
            || strCurrentModule == 'contacts'
            || strCurrentModule == 'customers'
            || strCurrentModule == 'sites'
          ) {
            // set the addresses and name
            var arRawEmailAddressesOptions = objRecordDetails['email_address'] || [];
            arEmailAddressesOptions = this.createEmailAddressOptionsFromEmailList(arRawEmailAddressesOptions, objRecordDetails['text']);

          } else if (strCurrentModule == 'opportunities' && objUsedFields['contact_id']) {
            // get the options and look for the current contact selected
            let arContactOptions = objUsedFields['contact_id']['options'] || [];
            let objFoundContact = arContactOptions.find((objOption) => objOption['id'] === objRecordDetails['contact_id']);

            // set the addresses and name
            var arRawEmailAddressesOptions = (objFoundContact) ? objFoundContact['email_address'] : [];

            // if we have addresses map each values
            if (arRawEmailAddressesOptions.length > 0) {
              arEmailAddressesOptions = this.createEmailAddressOptionsFromEmailList(arRawEmailAddressesOptions, objFoundContact['contact_text']);
            }
          }

          arInitial = arEmailAddressesOptions;
          let objPrimaryAddress = arInitial.find(objOption => (objOption.isPrimary == true)) || null;

          // if we found that address set the current `to` address
          if (objPrimaryAddress) {
            this.patchEmailAddressValue([ objPrimaryAddress ]);
          }
        }
      }
    }

    // Create field config
    this.arRelateValues[strField] = {
      item: null,
      input: new Subject<string>(),
      loading: false,
      display: false,
      list: [],
    };

    // Default value is false,
    this.arRelateValues[strField]['item'] = concat(
      of(arInitial), // We set the initial values to the ng-select.
      this.arRelateValues[strField]['input'].pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.arRelateValues[strField]['loading'] = true),
        // We search the inserted text in the ng-select, this time by name.
        switchMap(term => this.recordService.getRecordRelate(this.objEmailField[strField]['module'], term, '', true).pipe(
          catchError(() => of([])), // Empty the list of dropdown on error.
          tap((data) => {
            this.arRelateValues[strField]['list'] = [...this.arRelateValues[strField]['list'], ...data];
            this.objEmailField[strField]['options'] = this.arRelateValues[strField]['item'];
            this.arRelateValues[strField]['loading'] = false;
          })
        ))
      )
    );

  }

  /**
   * If the tag added a new value.
   * @param event
   */
  onMultiSelectChange(event){

    //First we properly map the tag fields.
    let strNew = event[event.length - 1]['id'];

    event.splice(event.length - 1, 1);

    let arItemList: any = [];

    if (event.length > 0) {
      event.forEach(item => {
        arItemList.push(item['id']);
      });
    }

  }

  setRelateCounter(arRelateRecord) {
    // this.item.option_count = arRelateRecord.length;
    return arRelateRecord;
  }

  getEmailRecord() {

    // Get current user full name
    let strFullName = this.localStorageService.getItem('user_name');
    // Get current user full name
    let strEmail = this.localStorageService.getItem('user_email');
    // Store email address with full name if exist
    this.strEmailAddress = (strFullName) ? strFullName + ' <' + strEmail + '>' : strEmail;
    this.currentUserEmail = strEmail;
    this.currentUserEmailAddress = this.strEmailAddress;
    this.fromAddresses = [
      new Select('current_user', sprintf('%s (%s)', this.translateService.instant('current_user'), this.currentUserEmailAddress)),
      new Select('system_user', sprintf('%s (%s)', this.translateService.instant('system_user'), this.currentUserEmailAddress)),
    ];
  }

  /**
   * If a new email was created.
   *
   * @param email
   */
  addTagRef(email) {
    let regexEmailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Check if a valid email
    if (regexEmailValidation.test(String(email).toLowerCase())) {
      // if valid email add to list of email
      return { 'text' : '', 'email_address' : { email: email } }
    } else {
      // If invalid email send notification
      this.notifService.sendNotification('oops', 'email_format_incorrect_added', 'warning');
    }
  }

  deleteDraft() {

    let arRequestData = {
      id : this.strActivityId,
      record_id: this.data.record_id,
      module: this.data.module
    }

    this.bShowLoader['delete_draft'] = true;

    // Remove draft
    this.activitiesService.deleteActivity(JSON.stringify(arRequestData))
    .pipe(
      finalize(() => this.bShowLoader['delete_draft'] = false)
    )
    .subscribe( data => {
      this.dialogRef.close({'mode': 'delete', 'data':  data});
    });
  }

  IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  toggleCc() {
    this.bHasCc = (! this.bHasCc);
    this.emailForm.patchValue({
      cc: [],
    });
  }

  toggleBcc() {
    this.bHasBcc = (! this.bHasBcc);
    this.emailForm.patchValue({
      bcc: [],
    });
  }

  loadEmailTemplates(): void {
    if (this.arTemplates.length < 1) {
      this.isFetchingEmailTemplates = true;
      let payload = { module: get(this.data, 'module') }
      // Get templates
      this.activitiesService.getEmailRecord(JSON.stringify(payload)).subscribe( data => {
        // Store template
        this.arTemplates = data['template'];
        this.isFetchingEmailTemplates = false;
      });
    }
  }


  protected createEmailAddressOptionsFromEmailList(emailList: Array<string|object>, defaultText: string = '') {
    let options = [];

    for (let email of emailList) {
        let text = email['text'] || email['name'] || defaultText;
        let emailAddress = email['email'] || email;
        let isPrimary = (email['primary'] ? true : false);

        options.push(new EmailAddressOption(text, emailAddress, isPrimary));
    }

    return options;
  }

  protected populateEmailAddressFieldFromRecordValues(field: string, values: any = null) {
    var values = values || [];

    // if we have a json parse it
    if (typeof values == 'string') {
      try {
        values = JSON.parse(values);
      } catch (err) {
        values = []
      }
    }

    let arToEmailAddressOptions = this.createEmailAddressOptionsFromEmailList(values);

    this.emailForm.patchValue({
      [field]: arToEmailAddressOptions
    });

    this.arRelateValues[field].item = of(arToEmailAddressOptions);
  }

  /**
   * Check if the actions is in progress
   *
   * @returns {boolean}
   */
  get isInProgress(): boolean {
    return this.bShowLoader.send || this.bShowLoader.draft
      || this.bShowLoader.template || this.bShowLoader.delete_draft
      || this.bShowLoader.uploading_attachment;
  }

  /**
   * Opens the dialog where we can manage signatures.
   *
   * @return {void}
   */
  onManageSignature() {

    const objDialog = this.dialogFactory.open(SignaturesComponent, {width: '70%', minWidth: '60%'});

    objDialog.afterClosed().subscribe(response => {
      if (response && response != 'cancel') {
        this.setSignature(response);
      }
    });

  }

  /**
   * Retrieves and sets all the contact roles related to the
   * record being viewed in the send email.
   *
   * @return {void}
   */
  setRoles() {
    if (filled(this.objEmailConfigData)) {
      this.objEmailConfigData.contact_roles_data.forEach(role => {
        const objExists = this.arSuggested.find(look => look.contact_id == role.contact_id);

        let filteredEmailAddresses = role.email_address.filter(email => email.email != this.currentUserEmail);
        let hasPrimaryEmailAddress = filled(filteredEmailAddresses.find(email => toString(email.primary) == '1'));

        if (! hasPrimaryEmailAddress && filled(filteredEmailAddresses)) {
          filteredEmailAddresses[0].primary = '1';
          role.email_address = filteredEmailAddresses;
        }

        if (!objExists
            && role.email_address
            && role.email_address.length > 0
            && filled(filteredEmailAddresses)
          ) {
          this.arSuggested.push(role);
        }
      });
    }

    this.initializeComponent();
    this.setDefaultSettings();
    this.bPageLoaded = true;
  }

  /**
   * Set the default roles based on the
   * current module or email view type.
   *
   * @returns {void}
   */
  setDefaultSettings() {

    this.arSuggested.forEach(objRole => {
      if (this.data['module'] == 'opportunities' &&
          this.data['email_type'] &&
          this.data['email_type'] == 'pdf' &&
          objRole['set_contact_quotes']
      ) {
        this.addTo(objRole);
      }

      if (this.data['module'] == 'purchase_orders' &&
        this.data['email_type'] &&
        this.data['email_type'] == 'pdf' &&
        objRole['set_contact_po']
      ) {
        this.addTo(objRole);
      }

      if (this.data['module'] == 'jobs' &&
        this.data['email_type'] &&
        this.data['email_type'] == 'pdf' &&
        objRole['set_contact_job_report']
      ) {
        this.addTo(objRole);
      }
    });

    const modulesWithCustomer = [
      'customer_invoices',
      'supplier_invoices',
      'purchase_orders',
      'opportunities',
    ];

    if (modulesWithCustomer.includes(this.data['module'])
      && get(this.data, 'email_type') == 'pdf'
      && filled(get(this.data, 'record_data'))
    ) {

      let email = this.emailForm.controls.to.value || [];

      if (this.data['record_data']['supplier'] &&
          this.data['record_data']['supplier']['id'] &&
          this.data['record_data']['supplier']['email_addresses'].length > 0
        ) {
        email.push({
          id: this.data['record_data']['supplier']['id'],
          text: this.data['record_data']['supplier']['name'],
          email_address: {
            email: this.data['record_data']['supplier']['email_addresses'][0],
            primary: "1"
          }
        });
      }

      if (this.data['record_data']['customer'] &&
        this.data['record_data']['customer']['id']  &&
        this.data['record_data']['customer']['email_addresses'].length > 0) {
          email.push({
            id: this.data['record_data']['customer']['id'],
            text: this.data['record_data']['customer']['name'],
            email_address: {
              email: this.data['record_data']['customer']['email_addresses'][0],
              primary: "1"
            }
          });
      }

      this.patchEmailAddressValue(email);
    }

    const emails = this.emailForm.controls.to.value || [];

    if (filled(get(this.objEmailConfigData, 'customer_data'))) {
      const customerEmailAddresses = get(this.objEmailConfigData.customer_data, 'email_address', []);
      const customerPrimaryEmailAddress = customerEmailAddresses.find(email => toString(email.primary) === '1');

      if (filled(customerPrimaryEmailAddress)) {
        emails.push({
          id: get(this.objEmailConfigData.customer_data, 'id'),
          text: get(this.objEmailConfigData.customer_data, 'text'),
          email_address: customerPrimaryEmailAddress,
        });
      }
    }

    if (filled(get(this.objEmailConfigData, 'site_data'))) {
      const siteEmailAddresses = get(this.objEmailConfigData.site_data, 'email_address', []);
      const sitePrimaryEmailAddress = siteEmailAddresses.find(email => toString(email.primary) === '1');

      if (filled(sitePrimaryEmailAddress) ) {
        emails.push({
          id: get(this.objEmailConfigData.site_data, 'id'),
          text: spf('[%s] - %s', {
            args: [
              get(this.objEmailConfigData.site_data, 'site_number'),
              either(this.objEmailConfigData.site_data, ['customer_name', 'customer_text'])
            ],
          }),
          email_address: sitePrimaryEmailAddress,
        });
      }
    }

    if (filled(get(this.objEmailConfigData, 'contact_data'))) {
      const contactEmailAddresses = get(this.objEmailConfigData.contact_data, 'email_address', []);
      const contactPrimaryEmailAddress = contactEmailAddresses.find(email => toString(email.primary) === '1');

      if (filled(contactPrimaryEmailAddress)) {
        emails.push({
          id: get(this.objEmailConfigData.contact_data, 'id'),
          text: get(this.objEmailConfigData.contact_data, 'text'),
          email_address: contactPrimaryEmailAddress,
        });
      }
    }

    if (filled(emails)) {
      const deduplicated = uniqBy(emails, (email) => get(email, 'email_address.email'));

      /// perform deduplication
      this.patchEmailAddressValue(deduplicated);
    }
   }

  /**
   * Set the signature.
   *
   * @param {string} strSignature
   * @param {number} numNewLines
   *
   * @returns {void}
   */
  setSignature(strSignature: string, numNewLines: number = 2) {
    if (strSignature) {
      let strExisting = this.emailForm.controls['body'].value;
      let strNewLines = '';
      if (this.emailForm.controls['body'].value == null) {
        strExisting = '';
      }
      for (let index = 0; index < numNewLines; index++) {
        strNewLines = strNewLines + '<br>'
      }
      this.emailForm.patchValue({'body': `${strExisting} ${strNewLines} ${strSignature}`});
      this.strDefaultSignature = `${strNewLines} ${strSignature}`;
      this._bSignatureAppended = true;
    }
  }

  /**
   * This will format the From Name per module
   * @returns
   */
  formatFromNameByModule(): string {
    let singularModule = {
      'leads' : 'lead',
      'customers' : 'customer',
      'sites' : 'site',
      'contacts' : 'contact',
      'jobs' : 'job',
      'opportunities' : 'opportunity',
      'assets' : 'asset',
      'customer_invoices' : 'customer_invoice',
      'supplier_invoices' : 'supplier_invoice',
      'purchase_orders' : 'purchase_order',
    };

    let moduleName = this.translateService.instant(singularModule[this.data['module']]);
    let clientName = this.clientStoreService.getActiveClient().name;
    let recordName = this._identifyRecordNameFromData({
      data: get(this.data, 'record_data', {}),
      module_name: this.data['module'],
    });

    /// fallback to text field if record name is not found
    if (blank(recordName)) {
      recordName = get(this.data, 'record_data.text');
    }

    if (['jobs', 'opportunities'].includes(this.data['module'])) {
      /// remove prefix # if present making sure we are not duplicating the # prefix
      recordName = replace(recordName, '#', '')

      return spf('%s: %s #%s', {
        args: [clientName, moduleName, recordName],
      });
    }

    if (this.data['module'] == 'customers') {
      return clientName;
    }

    if (this.data['module'] === 'supplier_invoices' && /^[0-9]+$/.test(recordName)) {
      recordName = spf('#%s', {
        args: [padStart(recordName, 6, '0')],
      });
    }

    return spf('%s: %s - %s', {
      args: [clientName, moduleName, recordName],
    });
  }

  /**
   * remove the current user email address if exist in "to" form
   *
   * @param emailAddress
   */
  patchEmailAddressValue(emailAddress: LooseObject[]): void {
    this.emailForm.patchValue({
      to: emailAddress.filter(emailTo => emailTo.email_address.email != this.currentUserEmail)
    })
  }

  onChangeFromAddress(event) {
    let activeClient = this.clientStoreService.getActiveClient();

    if (get(activeClient, 'email_domain_verified') == false && get(event, 'id') == 'current_user') {
      let domainRoute = window.location.origin + '/#/admin/email_domains';
      let message = this.translateService.instant('we_recommend_connection_to_email_domain');
      let infoMessage = sprintf('%s <br><br><a href="%s" target="_blank" class="info-a-href"><b>%s</b></a>', message, domainRoute, domainRoute);

      this.notifService.notifyInfo(infoMessage)
    }
  }

  /**
   * update widget status
   * @param requestData
   */
  updateWidgetStatus(requestData: LooseObject): void {
    const parentData = this.viewService.getRecord()['record_details'];

    if (requestData.module == 'customer_invoices') {
      this.viewService.updateStatusWidgetValue('sent_to_client');
    }

    if (requestData.module == 'purchase_orders' && parentData.status == 'draft') {
      this.viewService.updateStatusWidgetValue('sent');
    }
  }

  private _identifyRecordNameFromData(opts: {
    data: Record<string, any>,
    module_name: string,
  }): string|null {
    const mappings = {
      'leads': ['first_name', 'last_name'],
      'jobs': ['job_number'],
      'sites': ['site_number'],
      'opportunities': ['quote_number|opportunity_number'],
      'assets': ['serial_number'],
      'customer_invoices': ['invoice_number'],
      'supplier_invoices': ['invoice_number'],
      'purchase_orders': ['po_number'],
    };

    const fields = mappings[opts.module_name];

    if (blank(fields)) {
      return null;
    }

    const parts = [];

    for (const field of fields) {
      const alternatives = field.split('|');

      for (const alternative of alternatives) {
        if (blank(opts.data[alternative])) {
          continue;
        }

        parts.push(opts.data[alternative]);
      }
    }

    return parts.join(' ');
  }

  private _getContactRolesData(): EmailConfigContactRoleData[] {
    const arRelatedModules: EmailConfigContactRoleData[] = [];

    const arModulesWithEmail = [
      { key: 'sites', id: 'site_id', module: 'site' },
    ];

    if (this.data['module'] == 'purchase_orders' || this.data['module'] == 'supplier_invoices') {
      arModulesWithEmail.push({
        key: 'customers',
        id: 'customer_id',
        module: 'supplier'
      });
    } else {
      arModulesWithEmail.push({
        key: 'customers',
        id: 'customer_id',
        module: 'customer'
      });
    }

    arModulesWithEmail.forEach(roles => {
        if (this.data['record_data']) {
          if (this.data['record_data'][roles.id] || this.data['module'] == roles.key) {
            arRelatedModules.push({
              module: roles.key,
              module_id: this.data['module'] == roles.key ? this.data['record_data']['id'] : this.data['record_data'][roles.id]
            })
          }
          if (this.data['record_data'] && this.data['record_data'][roles.module] && this.data['record_data'][roles.module]['id']) {
            arRelatedModules.push({
              module: roles.key,
              module_id: this.data['record_data'][roles.module]['id']
            });
          }
        }
    })

    if (this.data['module'] == 'opportunities') {
      arRelatedModules.push({
        module: 'opportunities',
        module_id: this.data['record_id']
      });
    }

    return arRelatedModules;
  }

  private _preInitializeComponentActions(): void {
    if (this.data['activity_id'] != undefined && this.data['activity_id'] != '') {
      this.recordService.getRecordRelateJoined('activities', false, { 'activities.id': this.data['activity_id'] }).subscribe( response => {
        // Get updated record data of currently updating.
        this.data['activity'] = (response != null && response.length > 0) ? response[0] : [];
        this.initializeComponent();
        this.bPageLoaded = true;
      });
    } else {
      this.currentUserEmail = this.localStorageService.getItem('user_email');
      this.setRoles();
    }
  }

  onAttachmentPreview(attachment: {
    upload_name: string;
  }): void {
    if (blank(attachment.upload_name)) {
      return;
    }

    if (blank(window)) {
      return;
    }

    this.fileService.getObjectSignedUrl(attachment.upload_name, environment.temp_bucket)
      .pipe(
        filter((response) => filled(data_get(response, 'url'))),
        map((response) => data_get(response, 'url'))
      )
      .subscribe((url) => window.open(url, '_blank'));
  }
}

export interface EmailConfigRequestData {
  contact_roles_data?: EmailConfigContactRoleData[];
  email_templates_module?: string;
  customer_id?: string;
  site_id?: string;
  contact_id?: string;
  email_signature_id?: string;
}

export interface EmailConfigContactRoleData {
  module: string;
  module_id: string;
}

export interface EmailConfigData {
  client_data: Client;
  contact_data: LooseObject;
  customer_data: LooseObject;
  site_data: LooseObject;
  default_signature: LooseObject;
  record_module_fields: LooseObject;
  contact_roles_data: LooseObject[];
}