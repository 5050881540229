export class ListingPagination {

  next_page: boolean = false;
  previous_page: boolean = false;
  from_records: number = 0;
  to_records: number = 0;
  total_records: number = 0;
  page: number = 0;

  constructor(properties?: ListingPagination) {
    if (properties) {
      this.next_page = properties.next_page;
      this.previous_page = properties.previous_page;
      this.from_records = properties.from_records;
      this.to_records = properties.to_records;
      this.total_records = properties.total_records;
      this.page = properties.page;
    }
  }

}
