
    <div
      class="d-flex align-items-center"
      [class.justify-content-center]="position == 'center'"
      [class.justify-content-start]="position == 'left'"
      [class.justify-content-end]="position == 'right'"
      [class.flex-row-reverse]="position == 'right'"
      [class.d-flex-gap]="! (stacked$ | async)"
      [class.pointer]="purpose == 'clicking'"
      [class.grabbing]="purpose == 'dragging'"
    >
      <ng-container
        *ngIf="spin"
        [ngTemplateOutlet]="iconTemplate"
        [ngTemplateOutletContext]="{
          icon: ['fas', 'spinner'],
          spin: spin,
          klass: customClass,
          size: size,
          stacked: false
        }"
      >
      </ng-container>
      <span
        *ngIf="!spin"
        [class.fa-sm]="size == 'xs'"
        [class.fa-lg]="size == 'sm'"
        [class.fa-2x]="size == 'lg'"
        [class.fa-4x]="size == 'xl'"
        [class.fa-stack]="stacked$ | async"
      >
        <ng-container
          *ngFor="let icon of icons$ | async"
          [ngTemplateOutlet]="iconTemplate"
          [ngTemplateOutletContext]="{
            spin: false,
            icon: icon.icon,
            klass: icon.klass,
            size: icon.size,
            stacked: stacked$ | async
          }"
        >
        </ng-container>
      </span>
      <span *ngIf="label | filled; else content">
        {{ label | translate }}
      </span>
      <ng-template #content>
        <ng-content></ng-content>
      </ng-template>
      <ng-template
        #iconTemplate
        let-icon="icon"
        let-spin="spin"
        let-klass="klass"
        let-size="size"
        let-stacked="stacked"
      >
        <fa-icon
          [icon]="icon"
          [spin]="spin"
          [class]="klass"
          [matTooltip]="tooltip"
          [class.fa-stack-1x]="size != 'xl' && stacked"
          [class.fa-stack-2x]="size == 'xl' && stacked"
          [class.fa-xs]="size == 'xs'"
          [class.fa-sm]="size == 'sm'"
          [class.fa-1x]="size == 'lg'"
          [class.fa-2x]="size == 'xl'"
        ></fa-icon>
      </ng-template>
    </div>
  