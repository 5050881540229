import { Component, OnInit, Inject, HostListener, Renderer2  } from '@angular/core';
import { cloneDeep, get, isEmpty } from 'lodash';
import { finalize, filter } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Select } from '../../../../../../objects/select';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormService } from '../../../../../../services/form.service';
import { StudioService } from '../../../../../../services/studio.service';
import { RecordService } from '../../../../../../services/record.service';
import { ArrService } from '../../../../../../services/helpers/arr.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { CustomTranslateService } from '../../../../../../services/custom-translate.service';
import { LooseObject } from '../../../../../../objects/loose-object';
import { TranslateService } from '@ngx-translate/core';
import { filled } from '../../../../../utils/common';
import { sprintf } from 'sprintf-js';

type Option = {
  id: string,
  text: string,
  disabled: boolean
};

@Component({
  selector: 'app-update-dialog',
  templateUrl: './bulk-update-dialog.component.html',
  styleUrls: ['./bulk-update-dialog.component.scss'],
  providers: [CustomTranslateService]
})
export class BulkUpdateDialogComponent implements OnInit {

  public moduleSelected: string = null;
  public modulePreviousSelected: string = null;
  public module: Array<string> = [];
  public fields = [];
  public arAttributes: Array<object> = [];
  public searchForm: FormGroup;
  public isLoading: boolean = false;
  public bSubmitted: boolean = false;
  public bIsAddfieldDisabled: boolean = false;
  public metadata: object = {};
  public fieldOptions: BehaviorSubject<Array<Option>> = new BehaviorSubject([]);
  public fieldOptions$: Observable<Array<Option>> = this.fieldOptions.asObservable();

  public excludedModules: string[] = [
    'contact_sites',
    'contact_opportunities',
    'contact_customers',
    'pricebook_items',
    'pricebooks',
    'quotes',
    'assets_jobs',
    'tax_codes',
    'account_codes',
    'asset_groups',
    'files',
    'asset_types'
  ];

  @HostListener('window:resize', ['$event']) sizeChange(event) {
    this.setDialogStyle();
  }

  /**
   * this component field is based on the module's metadata
   *
   * @param formService
   * @param recordService
   * @param notificationService
   * @param matDialogRef
   * @param data
   */
  constructor(
    public formService: FormService,
    public studioService: StudioService,
    public recordService: RecordService,
    public notificationService: NotificationService,
    public arrService: ArrService,
    public customTranslate: CustomTranslateService,
    public matDialogRef: MatDialogRef<BulkUpdateDialogComponent>,
    private renderer: Renderer2,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.customTranslate.initializeTransalateables([
      'of', 'here', 'click', 'bulk_update_success', 'to_download_report', 'bulk_update_failed'
    ]);

    if (this.data['module']) {
      this.moduleSelected = this.data['module'];
      this.getModuleConfig(this.data['module'], this.data['records']);
    }
  }

  ngOnInit() {}

  /**
   * if there is existing selected module, prompt the user that there is existing field
   *
   * @param event
   */
  onChangeModule(module: string): void {
    if (module) {
      this.getModuleConfig(module);
    }
  }

  /**
   * get the modules metadata
   */
  getModuleConfig(module: string, records: object = {}): void {
    this.isLoading = true;
    this.fields = [];
    this.recordService.getRecordConfig(module).first().subscribe(objResponse => {
      var objMetadata = this.overrideDefaultMetadata(objResponse['used_fields']);
      this.metadata = this.transformMetadata(cloneDeep(objMetadata))
      this.initializeOption(cloneDeep(objMetadata));
      this.isLoading = false
      this.modulePreviousSelected = module;
      this.addField();
    });
  }

  /**
   * add field
   *
   * @param field
   * @param value
   */
  addField(): void {
    if (this.moduleSelected && !this.bIsAddfieldDisabled) {
      this.arAttributes.push({
        field_options: this.fieldOptions$,
        metadata: null,
        form: null,
        selected_field: null,
        previous_field: null,
      });
    } else {
      this.notificationService.sendNotification('please_select_module', 'warning', 'warning');
    }

    this.setDialogStyle();
  }

  /**
   * remove field
   *
   * @param field
   * @param value
   */
  removeField(index: number): void {
    this.setEnabledDisabledField(this.arAttributes[index]['selected_field'], false);
    this.arAttributes.splice(index, 1);
    this.disableAddField();
    this.setDialogStyle();
  }

  /**
   * This will set to disable adding new field
   * if all field options are selected.
   */
  async disableAddField() {

    const fieldOptionsSubs = await this.fieldOptions$.subscribe(objOptions => {
      let intFieldOptionsCount = objOptions.length;
      let arSelectedFields = this.arAttributes.map(arAttribute => {
        return !isEmpty(arAttribute['selected_field']) ? arAttribute['selected_field'] : null;
      }).filter(arAttribute => arAttribute !== null);

      this.bIsAddfieldDisabled = intFieldOptionsCount === arSelectedFields.length;
    });

    // Need to unsbuscribe after the process
    fieldOptionsSubs.unsubscribe();
  }

  /**
   * transform the metadata so that we can use the app-edit component
   * We only allow allowed_bulk fields
   *
   * @param metadata
   */
  transformMetadata(metadata: object): object {
    var transform_metadata: object = {};
    Object.keys(metadata).forEach(field_key => {
      if (metadata[field_key]['allowed_bulk'] || (metadata[field_key]['deletable'] && metadata[field_key]['type'] != 'address')) {
        metadata[field_key]['key'] = field_key;
        metadata[field_key]['label'] = null;
        metadata[field_key]['default_value'] = '';
        if (metadata[field_key]['type'] == 'dropdown' && metadata[field_key]['options']) {
          metadata[field_key]['options'] = metadata[field_key]['options'].map( option => {
            return new Select(option.id, option.text)
          });
        }
        transform_metadata[field_key] = this.formService.tranformFieldObject(metadata[field_key], {});
      }
    });
    return transform_metadata;
  }

  /**
   * get the option for date fields
   */
  getDateOption(): Array<Select> {
    return [
      new Select('last_seven_days', 'last_seven_days'),
      new Select('last_one_month', 'last_one_month'),
      new Select('this_day', 'this_day'),
      new Select('this_month', 'this_month')
    ];
  }

  /**
   * create option values
   *
   * @param metadata
   */
  initializeOption(metadata: object): void {
    var options: Array<Option> = [];

    Object.keys(metadata).forEach( field_key => {
      if (metadata[field_key]['allowed_bulk'] || (metadata[field_key]['deletable'] && metadata[field_key]['type'] != 'address')) {
        options.push(
          {
            id: field_key,
            text: this.translateService.instant(metadata[field_key]['label']),
            disabled: false
          }
        );
      }
    })

    this.fieldOptions.next(options);
  }

  /**
   * Submit the request to update
   * selected record
   */
  onSubmit(): void {
      this.bSubmitted = true;
      let arPayload: Array<object> = [];
      let arfieldsWithoutValue: Array<string> = [];

      let records = Object.entries(this.data.records).map(([key, value]) => {
        return value;
      });

      let hasInvalidData = false;

      this.arAttributes
      .filter(attribute => attribute['form'] !== null)
      .map((attribute, index) => {
        let selectedField = attribute['selected_field'];
        //Check if there are still invalid fields.
        if (!attribute['form'].valid) {
          hasInvalidData = true;
        }

        let fieldValue = attribute['form'].value[selectedField];

        if (fieldValue !== undefined && fieldValue !== null) {
          arPayload.push(attribute['form'].value);
        }

        if(fieldValue === '') {
          arfieldsWithoutValue.push(selectedField);
        }

        attribute['form']['controls'][selectedField].markAsTouched();
      });

      if (!hasInvalidData) {
        let objPayload: object = Object.assign({}, ...arPayload);

        if (arfieldsWithoutValue.length > 0) {
          let arTranslatedMessage = [];

          this.customTranslate.initializeTransalateables(arfieldsWithoutValue);
          arfieldsWithoutValue.forEach(field => {
            arTranslatedMessage.push(this.customTranslate.getTranslation(field));
          });

          this.customTranslate.initializeTransalateables(['field_empty_value']);
          let warningMessage = arTranslatedMessage.join(', ') + this.customTranslate.getTranslation('field_empty_value');

          this.notificationService.sendConfirmation(warningMessage, 'warning')
          .pipe(
            finalize(() => this.bSubmitted = false),
            filter(confirmation => confirmation.answer == true)
          )
          .subscribe(() => {
            this.onSaveRecord(records, objPayload)
          });
        } else {
          this.onSaveRecord(records, objPayload);
        }

      } else {
        this.bSubmitted = false;
        this.notificationService.sendNotification('not_allowed', 'required_notice', 'danger');
      }
  }

  onSaveRecord(recordsSelected, objPayload) {
    this.customTranslate.initializeTransalateables([
      'of', 'here', 'click', 'bulk_update_success', 'to_download_report', 'bulk_update_failed'
    ]);

    let recordIds = [];

    recordsSelected.forEach( record => {
      recordIds.push({id: record['id']});
    });

    let payload = {
      record_ids: recordIds,
      update_payload: objPayload,
      additional_data: {
        filter: this.data['current_filter'],
        is_total_selected: this.data['is_total_selected'],
      }
    };

    this.recordService.updateMultipleRecord(this.moduleSelected, payload).subscribe( response => {
      this.bSubmitted = false;
      if (response.body.success) {
            var totalLength = this.data['is_total_selected'] ? this.data['total_records'] : recordsSelected.length;
            var notificationMessage = this.customTranslate.getTranslation('bulk_update_success') +
          ` ${response.body.success} ` + this.customTranslate.getTranslation('of') +
          ` ${totalLength} `;

        this.notificationService.sendNotification('updated', notificationMessage, 'success', 5000);
        this.matDialogRef.close({
          module: this.moduleSelected,
          field: objPayload
        });
      }
    }, error => {
      this.bSubmitted = false;
      if (error.status == 422) {
        var notificationMessage = this.customTranslate.getTranslation('bulk_update_failed') + "\n";

        if (filled(error.error.error)) {
          error.error.error.forEach(errorResponse => {
            let errorName = filled(get(errorResponse, 'name', '')) ? this.translateService.instant(get(errorResponse, 'name', '')) : '';
            let errorMessage = filled(get(errorResponse, 'message', '')) ? this.translateService.instant(get(errorResponse, 'message', '')) : '';
            let errorNote = filled(get(errorResponse, 'note', '')) ? this.translateService.instant(get(errorResponse, 'note', '')) : '';
            notificationMessage += sprintf("%s %s %s \n", errorName, errorMessage, errorNote);
          });
        }

        this.notificationService.sendNotification('warning', notificationMessage, 'warning', 10000);
      }
    });
  }


  /**
   * cancel the dialog
   */
  cancelDialog(): void {
    this.matDialogRef.close();
  }

  /**
   * we need to set the value to null everytime the user change the selected field
   *
   * @param form
   */
  ngOnFormChange(index, field) {

    if (field) {
      this.disableAddField();
      this.setEnabledDisabledField(field, true);

      let previousField = this.arAttributes[index]['previous_field'];
      if (previousField) {
        this.setEnabledDisabledField(previousField, false);
      }

      // If previous field not match on the current field.
      // We should set previous field by current field.
      if (previousField !== field) {
        this.arAttributes[index]['previous_field'] = field;
      }

      let defaultValue = this.metadata[field]['default_value'];
      this.arAttributes[index]['metadata'] = this.metadata[field];

      if (this.metadata[field]['required']) {
        this.arAttributes[index]['form'] = new FormGroup({[field]: new FormControl(defaultValue, Validators.required)});
      } else {
        this.arAttributes[index]['form'] = new FormGroup({[field]: new FormControl(defaultValue)});
      }
    }
  }

  /**
   * This will set the field option if its
   * selected or not. So we avoid duplicate
   * field selected
   *
   * @param field
   * @param isDisabled
   */
  setEnabledDisabledField(field: string, isDisabled: boolean) {
    let currentFieldOption = this.fieldOptions.getValue();
    let newFieldOptions = [];

    currentFieldOption.forEach( fieldOption => {
      if (isDisabled && fieldOption.id === field) {
        fieldOption.disabled = true;
      }

      if (!isDisabled && fieldOption.id === field) {
        fieldOption.disabled = false;
      }

      newFieldOptions.push(fieldOption);
    });

    this.fieldOptions.next(newFieldOptions);
  }


  /**
   * Set style for dialog to avoid
   * the dropdown panel to be behind
   * in the dialog content
   */
  setDialogStyle(): void {
    let dialogParentElement = document.getElementsByClassName('cdk-global-overlay-wrapper');
    let dialogElement = document.getElementsByClassName('mat-dialog-container');
    let currentWindowHeight = window.innerHeight;

    if (dialogParentElement.length > 0 && dialogElement.length > 0) {
      // We need to set overflow on dialog parent element. So
      // when dialog is more than the dialog parent it will
      // be scrollable
      this.renderer.setStyle(dialogParentElement[0], 'overflow', 'auto');

      let additionalHeightFromPadding = 24;
      // Check the dialog height to current window height.
      // to set the correct overflow in the dialog
      // Note: We get the percentage of max height of the dialog
      // to be computed in the window height and also we need to
      // subtract the dialog padding
      if (dialogElement[0].getBoundingClientRect().height > (currentWindowHeight * 0.80) - additionalHeightFromPadding) {
        dialogElement[0].setAttribute("style", "overflow: auto !important");
      } else {
        dialogElement[0].setAttribute("style", "overflow: visible !important");
      }
    }
  }

  /**
   * capitalized first letter
   *
   * @param string
   */
  capitalizeFirstLetter(string): string {
    return string[0].toUpperCase() + string.slice(1);
  }

  /**
   * You can change default metadata here.
   * @param objMetadata
   * @returns
   */
  overrideDefaultMetadata(objMetadata: LooseObject): LooseObject  {
    switch(this.moduleSelected) {
      case 'purchase_orders':
        Object.keys(objMetadata).map(strCurrentField => {
          if (strCurrentField === 'delivery_notes') {
            objMetadata['delivery_notes']['is_hidden'] = false;
          }

          if (strCurrentField === 'site_id') {
            objMetadata['site_id']['is_hidden'] = false;
          }
        });
      break;
    }

    return objMetadata;
  }

  doSomethingInParent(event) {
    if (event['field'] == 'pricing_method') {
      if (event['value'] == 'fixed_markup') {
        this.setEnabledDisabledField('unit_price', true);
        this.setEnabledDisabledField('markup', false);
      } else if (event['value'] == 'default_markup') {
        this.setEnabledDisabledField('markup', true);
        this.setEnabledDisabledField('unit_price', true);
      } else if (event['value'] == 'fixed_sell_price') {
        this.setEnabledDisabledField('markup', true);
        this.setEnabledDisabledField('unit_price', false);
      }
    }
  }
}