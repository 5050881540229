import { Injectable, Output } from '@angular/core';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';
import { Config } from 'protractor';
import { Select } from '../objects/select';
import { BehaviorSubject } from 'rxjs';

const kBaseUrl: string = environment.url + "/widgets/";

@Injectable()
export class WidgetService {
  public strPage = '';
  public objToken = {
    activities: {},
    files: {},
    contacts: {},
    customers: {},
    sites: {},
    opportunities: {},
    assets: {},
    document_library: {}
  };

  public objNav = {
    activities: {},
    files: {},
    contacts: {},
    customers: {},
    sites: {},
    opportunities: {},
    assets: {},
    document_library: {}
  };

  reloadWidgetList = new BehaviorSubject<boolean>(false);

  constructor (private http: HttpClient, private router: Router) {}

  setNavFirst(strNavFirst, widget) {
    this.objNav[widget]['first'] = strNavFirst;
  }

  beforePageLoad(strNav, widget, strFilterColumn = 'updated_at') {

    let objResources = {...this.objToken[widget], ...this.objNav[widget]};
    let arFilter = {};
    if (strNav == 'current') {
      this.strPage = 'current';
      arFilter = {
        'direction': strNav,
        'date': objResources['current']
      };
    } else if(strNav != 'default') {
      this.strPage = '';
      arFilter = {
        'direction': strNav,
        'date': (strNav == 'next') ? objResources['next'] : objResources['prev']
      };
    } else {
      this.strPage = 'default';
    }

		var arRequestData = {
        'filter_clause' : { [strFilterColumn] : btoa(JSON.stringify(arFilter)) },
      }

    return arRequestData;
  }

  afterPageLoad(arRecordList, widget, strSortBy = 'updated_at') {
    // If default reset all token and nav to go on first page
    if (this.strPage == 'default'){
      this.objToken[widget] = {};
      this.objNav[widget] = {};
    }
    // Do we have next token?
		this.objToken[widget]['next'] = (arRecordList['hasNextToken']) ? arRecordList['hasNextToken'] : false;

		// Do we have next token?
		if (arRecordList['data'].length != 0) {

      // Store the data in current listing
      this.objToken[widget]['current'] = arRecordList['data'][0][strSortBy];
      this.objToken[widget]['prev'] = arRecordList['data'][0][strSortBy];

      if(this.objToken[widget]['next']) {
        this.objToken[widget]['next'] = arRecordList['data'][arRecordList['data'].length - 1][strSortBy];
      }

      // Store the first data in the listing
      if (typeof(this.objNav[widget]['first']) == 'undefined') {
        // Store the first in current listing
        this.objNav[widget]['first'] = arRecordList['data'][0][strSortBy];
      }
      // If previous value is greater than the first value
      if (this.objNav[widget]['prev'] > this.objNav[widget]['first']) {
        // If we got here, it means user created a new record and we need to store the first record
        this.objNav[widget]['first'] = arRecordList['data'][0][strSortBy];
      }
      // Check if first and first record in listing has same value
      this.objToken[widget]['prev'] = (this.objNav[widget]['first'] ==  arRecordList['data'][0][strSortBy]) ? false : arRecordList['data'][0][strSortBy];
    }
  }

  getContactRole(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "get_contact_role", body.toString());
  }

  getAllContactRoles(module: string, module_id?: string, filter?: string) {
    let body = new URLSearchParams();
    body.append('module', module);
    if (module_id) {
      body.append('module_id', module_id);
    }
    if (filter) {
        body.append('filter', filter);
    }
    return this.http.post<Response>(kBaseUrl + "get_all_contact_role", body.toString());
  }

  getFiles(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "get_files", body.toString());
  }


  addLibraryDocument(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "add_library_document", body.toString());
  }

  getAllOpportunitiesDocument(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "fetch_all_documents", body.toString());
  }

  removeQuotesDocument(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "remove_quotes_document", body.toString());
  }

  getAssets(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + 'get_assets', body.toString());
  }

	fetchData(objPage, strModule, jsonFilterData) {

		let body = new URLSearchParams();
		if (jsonFilterData != '') { body.append('filter', jsonFilterData); }
		if (objPage != '') { body.append('page', objPage); }
		return this.http.post(kBaseUrl + strModule, body.toString());
  }

  /**
   * Get list of contact role related by given module
   *
   * @param string strModule
   * @param mixed objFilter
   */
  getContactRolesRelatedByGivenModule(strModule: string, strRecordId: string, objFilter: [] | {} = {}): Observable<Response> {
    let body = new URLSearchParams();

    body.append('module', strModule);
    body.append('record_id', strRecordId);
    body.append('filter', JSON.stringify(objFilter));

    return this.http.post<Response>(kBaseUrl + "get_contact_role_by_module", body.toString());
  }

  /**
   * Sends a request to update the file and mark
   * it as public.
   *
   * @param {string}
   * @param {boolean}
   *
   * @returns
   */
   update(strId: string, bIsPublic: boolean): Observable<{message: string}> {

    let body = new URLSearchParams({
      id: strId,
      public: bIsPublic as any
    });

    return this.http.post<{message: string}>(kBaseUrl + "update_file", body.toString());
  }

}
