import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RecordService } from '../../../../services/record.service';
import { AddMaterialComponent } from '../add-material/add-material.component';
import { filter, finalize } from 'rxjs/operators';
import { Job } from '../../../../objects/job';
import { NotificationService } from '../../../../services/notification.service';
import { MaterialLineItem } from './material-line-item';
import { StrService } from '../../../../services/helpers/str.service';
import { LooseObject } from '../../../../objects/loose-object';
import { get, isEmpty, isUndefined, first, filter as _filter, map as _map } from 'lodash-es';
import { toFormattedNumber } from '../../../../shared/utils/numbers';
import { BehaviorSubject, defer, forkJoin, iif, of, Subscription } from 'rxjs';
import { blank, either, filled } from '../../../../shared/utils/common';
import { DefaultMaterialJob, DefaultMaterialPricebook, DefaultMaterialTask, MaterialLineData, OnMaterialsUpdate } from './components/material-lines/material-lines.component';

@Component({
  selector: 'log-materials',
  templateUrl: './log-materials.component.html',
  styleUrls: ['./log-materials.component.scss'],
})
export class LogMaterialsComponent implements OnInit, OnDestroy {
  /**
   * Will contain the list of line items
   * to be logged as materials.
   *
   * @var {MaterialLineItem[]}
   */
  public arMaterialLineItem: MaterialLineItem[] = [];

  /**
   * Is the entire form touched.
   *
   * @var {boolean}
   */
  public bIsTouched: boolean = false;

  /**
   * Is the save api still loading?
   *
   * @var {boolean}
   */
  public bIsLoading: boolean = false;

  /**
   * Is the list still loading?
   *
   * @var {boolean}
   */
  public bListLoading: boolean = false;

  arDeletedLineItems: LooseObject[] = [];

  readonly pricebookId$ = new BehaviorSubject<string|null>(null);

  readonly defaultPricebook$ = new BehaviorSubject<DefaultMaterialPricebook|null>(null);

  readonly defaultJob$ = new BehaviorSubject<DefaultMaterialJob|null>(null);

  readonly defaultTask$ = new BehaviorSubject<DefaultMaterialTask|null>(null);

  readonly initialValue$ = new BehaviorSubject<MaterialLineData[]>([]);

  readonly _subscriptions: Subscription[] = [];

  /**
   * If the material list is invalid, meaning
   * the user has not selected a product or inputted
   * any name if its a once off.
   *
   * @return {boolean}
   */
  get bIsMaterialsInValid(): boolean {
    return this.arMaterialLineItem.findIndex(item => {
      return item.type == 'once_off_purchase' ? blank(item.notes) : blank(item.product.value);
    }) > -1;
  }

  /**
   * get the deleted line items
   */
  get deletedLineItems(): LooseObject {
    return this.arDeletedLineItems.filter(lineItems => {
      if (!isUndefined(lineItems) || !isEmpty(lineItems)) {
        if (!isEmpty(lineItems.id)) {
          return true;
        }
      }
      return false;
    });
  }

  constructor(
    public objDialog: MatDialogRef<AddMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) public objJobData: Job,
    private record: RecordService,
    private notificationService: NotificationService,
    private strService: StrService
  ) {}

  /// see: OnInit::ngOnInit
  ngOnInit(): void {
    if (filled(get(this.objJobData, 'pricebook_id'))) {
      this.defaultPricebook$.next({
        id: get(this.objJobData, 'pricebook_id'),
        text: get(this.objJobData, 'pricebook_text'),
      });
    }

    this._subscriptions.push(
      defer(() => {
        this.bListLoading = true;

        return this.record.getRecord('jobs', this.objJobData.id);
      }).pipe(
        finalize(() => this.bListLoading = false),
      ).subscribe((response) => {
        /// set default job
        if (filled(get(response, 'record_details.id'))) {
          this.defaultJob$.next({
            id: get(response, 'record_details.id'),
          });
        }

        const tasks = _filter(
          get(response , 'activities.data', []), (activity) => get(activity, 'activity_type') == 'task'
        );

        // FC-4212: If job has only 1 task, default the task selection to that one task.
        if (tasks.length == 1) {
          this.defaultTask$.next({
            id: get(first(tasks), 'id'),
            name: get(first(tasks), 'activity_name'),
          });
        }

        if (filled(get(response, 'related_data.materials', []))) {
          this.initialValue$.next(_map(
            get(response, 'related_data.materials', []),
            (line) => ({
              id: line.id,
              task_id: line.activity_id,
              task_name: line.activity_name,
              quantity: line.quantity,
              unit_cost: line.unit_cost || 0,
              unit_price: line.unit_price || 0,
              item_id: line.item_id,
              item_name: line.product_name,
              item_code: either(line, ['code', 'item_code']),
              description: line.notes,
              once_off_product_name: line.product,
              markup: toFormattedNumber(line.markup),
              customer_invoice_id: line.customer_invoice_id,
              customer_invoice_number: line.invoice_number,
              supplier_pricing: get(line, 'supplier_pricing', []),
              related_products: get(line, 'related_products', []),
            })
          ));
        }
      }),
    );
  }

  /// see: OnDestroy::ngOnDestroy
  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Send the materials to the API,
   * only send those who have changes.
   *
   * @returns {void}
   */
  logMaterials(): void {
    if (!isEmpty(this.arMaterialLineItem) || !isEmpty(this.deletedLineItems)) {
      this.bIsTouched = true;
      // notify the user that we will permanently delete the materials when there is removed line item
      if (!isEmpty(this.deletedLineItems)) {
        this.notificationService.sendConfirmation('confirm_delete_materials').pipe(
          filter(confirmation => confirmation.answer === true),
        ).subscribe(() => {
          this.saveMaterials()
        })
      } else {
        this.saveMaterials()
      }
    } else {
      this.notificationService.notifyWarning('required_materials')
    }
  }

  /**
   * save materials
   *
   * @returns
   */
  saveMaterials(): void {
    if (!this.bIsMaterialsInValid) {

      this.bIsLoading = true;

      let arMaterialsToSave = this.arMaterialLineItem.map((item, index) => {
        let itemData = item.getMaterialData(this.objJobData.id);
        itemData.sort_seq = index;
        return itemData;
      });

      // if the user removed existing line item, also need to dlete the materials record
      forkJoin([
        iif(() => !isEmpty(arMaterialsToSave),
          this.record.saveMultipleRecord('materials', arMaterialsToSave),
          of({})
        ),
        iif(() => !isEmpty(this.deletedLineItems),
          this.record.deleteMultipleRecord('materials', this.deletedLineItems.map( lineItems => {
            return { id: lineItems.id }
          } )),
          of({})
        )
      ]).subscribe(() => {
        this.bIsTouched = false;
        this.bIsLoading = false;
        this.objDialog.close('save');
      });
    } else {
       this.notificationService.notifyError('please_complete_the_form');
    }
  }

  /**
   * check if the material item name has invalid characters
   *
   * @param materials
   * @returns
   */
  hasInvalidItem(materials): boolean {
    let hasSpecialCharacters = materials.filter( material =>
      // FC-4245: we should check the product_name from "once_off_purchase" and not the related text
      this.strService.hasInvalidCharacters(material.product)
    );
    return hasSpecialCharacters.length !== 0;
  }

  onUpdatedMaterials(event: OnMaterialsUpdate): void {
    if (event.mode == 'remove') {
      this.arDeletedLineItems.push(event.material);
    } else {
      this.arMaterialLineItem = event.materials;
    }
  }
}
