import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ContentChild, SimpleChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TasksComponent } from '../../../module/calendar/tasks/tasks.component';
import { ClientStoreService } from '../../../services/client-store.service';
import { WidgetTab } from '../../../objects/widget-tab';
import { RoleService } from '../../../services/role.service';
import { isPlural, plural } from 'pluralize';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivitiesComponent } from './activities/activities.component';
import { data_get, filled } from '../../utils/common';
import { find, isString } from 'lodash-es';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit, OnDestroy {

  /**
   * An event emitter for when a child component that we instantiate
   * emits something. This event will be available when this element
   * is added to your component:
   * <app-widget (eventEmission)="yourCallback($event)"></app-widget>
   */
  @Output() eventEmission: EventEmitter<string | undefined> = new EventEmitter();

  /**
   * Where will this module be placed? Data fetched for the
   * widget will be related to the given record (module + record id)
   * so this should always be set correctly.
   */
  @Input() strModule: string;

  /**
   * A list of tab configuration that is used in rendering
   * each tab in the widget. Each tab config usually contain
   * metadata about its icon, tooltip text, active state, etc.
   *
   * @type {WidgetTab[]}
   * @readonly
   */
  @Input()
  protected readonly arModuleTabs: WidgetTab[] = [];

  /**
   * Returns a reference to the tasks component. This is so that
   * the calendar (which uses this component) may still be able
   * to access the tasks metadata from there.
   */
  @ViewChild(TasksComponent) tasksComponent: TasksComponent;

  /**
   * Returns a reference to the activities component.
   */
  @ViewChild(ActivitiesComponent) activitiesComponent: ActivitiesComponent;

  /**
   * Contains any custom widgets.
   */
  @ContentChild('custom_widgets') custom_widgets: any;

  /**
   * Widgets are placed within records so for widget data related to
   * that record be fetched correctly, a record id must be set.
   */
  @Input() recordId: string;


  @Input() objFetchedRecordDetails: object;

  /**
   * A container for module names, this is just used for determining
   * whether a module's widget data should be fetched.
   * (Basically used for if statements)
   */
  protected objTriggerFetch = {};

  /**
   * By default, all widgets have an "Activity" tab set.
   * We can hide it by overriding this property.
   */
  public showActivityTab: boolean = true;

  /**
   * An observable that emits list of available widgets that are possibly permitted for the user
   *
   * @type {Observable<WidgetTab[]>}
   *
   */
  public widgets$: Observable<WidgetTab[]> = this.getWidgets();

  private _requestedWidget: string;

  private _routeQueryParamsSubs: Subscription;

  constructor(
		public clientStoreService: ClientStoreService,
    private route: ActivatedRoute,
    private roles: RoleService
  ) {
    // Get user id
    this.route.params.subscribe( params => {
      // Set up record config
      this.recordId =  params['id']
    });
  }

  ngOnInit() {
    this._routeQueryParamsSubs = this.route.queryParams.subscribe((queries) => {
      const widgetTab = data_get(queries, 'widget_tab');
      const isValidWidgetTab = filled(find(this.arModuleTabs, (tab) => tab.type === widgetTab));

      if (filled(widgetTab) && isString(widgetTab) && isValidWidgetTab) {
        this._requestedWidget = widgetTab;
        this.prepareWidgetDataForDisplay(widgetTab);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('arModuleTabs' in changes) {
      this.widgets$ = this.getWidgets();
    }
  }

  ngOnDestroy(): void {
    if (filled(this._routeQueryParamsSubs)) {
      this._routeQueryParamsSubs.unsubscribe();
    }
  }

  /**
   * Gets the widget icons to be displayed
   *
   * @returns {Observable<WidgetTab[]>}
   */
  getWidgets(): Observable<WidgetTab[]> {
    return this.clientStoreService.getWhenClientIsSetEvent().pipe(
      map((objClient) => this.arModuleTabs.filter((objWidget) => {
        const strRawModule = (objWidget.for_module || objWidget.singular).replace('-', '_');
        const strModule = isPlural(strRawModule) ? strRawModule : plural(strRawModule);
        return this.roles.hasPermission(objWidget.is_list === false ? objWidget.for_module : `${strModule}:list`, {
          client: objClient,
        });
      })),
    );
  }

  /**
   * Determines if the given widget tab should be displayed by
   * looking for its metadata in our this.arModuleTabs property.
   *
   * @param {string} strWidget - widget to display
   *
   * @see this.arModuleTabs
   *
   * @returns {boolean}
   */
  shouldWidgetBeDisplayed(strWidget: string, arModuleTabs: any = []): boolean {
    return ((arModuleTabs.length == 0) ? this.arModuleTabs : arModuleTabs)
      .find(widget => (widget['singular'] == strWidget)) !== undefined;
  }

  /**
   * Indicates that a specific widget's data should be fetched later.
   *
   * @param {string} strWidgetType
   *
   * @returns {void}
   */
  prepareWidgetDataForDisplay(strWidgetType: string): void {
    this.objTriggerFetch[strWidgetType] = true;
  }

  /**
   * Determines whether a given tab type is set to active
   * initially on configuration. This means that when it
   * was added to our this.arModuleTabs property, it already
   * has its `default_active` attribute set to "active"
   *
   * @param {string} type
   *
   * @returns {boolean}
   */
  isTabActiveByDefault(type: string, arModuleTabs: [] = []): boolean {
    if (filled(this._requestedWidget)) {
      return this._requestedWidget === type;
    }

    let tabConfig = ((arModuleTabs.length == 0) ? this.arModuleTabs : arModuleTabs)
      .find(function (arModuleTab) {
        return arModuleTab['type'] === type;
      });

    return tabConfig !== undefined && tabConfig.default_active === 'active';
  }

  /**
   * Refresh list of other widgets.
   */
  refreshWidget(objData) {
    if (objData['widget'] != undefined) {
      this.objTriggerFetch[objData['widget']] = false;
    }
  }

  /**
   * Default callback for whenever a child component emits an event.
   * This will then emit another event, containing the event data
   * from the original emission.
   *
   * @param {Any} $event
   *
   * @returns {void}
   */
  onEventEmit($event): void {
    this.eventEmission.emit($event);
  }

}