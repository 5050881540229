import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';

import { NoteComponent } from './note/note.component';
import { EventComponent } from './event/event.component';
import { TaskComponent } from './task/task.component';
import { EmailComponent } from '../email/email.component';
import { RenderComponent } from '../email/render/render.component';

import { ActivitiesService, GetActivitiesResult } from '../../../../services/activities.service';
import { NotificationService } from '../../../../services/notification.service';
import { ViewService } from '../../../../services/view.service';
import { WidgetService } from '../../../../services/widget.service';

import * as moment from 'moment';
import { RelateIds } from '../../../../lists/relate-ids';
import { ActivityType, ActivityIcon } from '../../../../lists/activity'
import { FMError } from '../../../../objects/fm-error';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { ChatComponent } from './messages/chat/chat.component';
import { LooseObject } from '../../../../objects/loose-object';
import { SmsComponent } from './messages/sms/sms.component';
import { ActivatedRoute } from '@angular/router';
import { iif, of, Subject, Subscription } from 'rxjs';
import { RecordService } from '../../../../services/record.service';
import { DateService } from '../../../../services/helpers/date.service';
import { TaskCalendarComponent } from '../../../../features/task-calendar/task-calendar.component';
import { blank, data_get, filled, when, observe } from '../../../utils/common';
import { ListingService } from '../../../../services/listing.service';
import { Activity, AuditedActivity } from '../../../../objects/activity';
import { PaginatedResult, Pagination, PaginationStore } from '../../../store/pagination.store';
import { TaskCalendarDialogComponent } from '../../../../features/task-calendar/components/dialog/task-calendar-dialog.component';
import { isObject, last } from 'lodash-es';
import { EventBusService } from '../../../../services/event-bus.service';
import { spf } from '../../../utils/str';
import { ReadableAddressPipe } from '../../../../pipes/readable-address.pipe';
import { TaskMetadataC } from '../../../../module/calendar/full-calendar/classes/metadata/task-metadata';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
  providers: [
    PaginationStore,
  ],
})

export class ActivitiesComponent implements OnInit, OnDestroy {

  @Input() strRecordId: string;
  @Input() strModule: string;
  @Input() bFromJobScheduler = false;
  @Output() triggerActivitiesRefresh: EventEmitter<any> = new EventEmitter();

  public selectDate: string;
  public selectType: string;

  public arActivityIcon: any = ActivityIcon;
  public bLoading = false;
  public arRecordData:any = [];

  public intInstanceID: number;
  public objStatusWidgetConfig: object;
  public arSubscriptions: Subscription[] = [];

  /**
   * list of activity timeline
   */
  public arTimelineFilter: string[] = [
    'all', 'this_month', 'this_year'
  ];

  /**
   * list of activity type
   */
  public arActivityTypeFilter: string[] = [
    'all', 'event', 'note', 'task', 'email', 'message'
  ];

  readonly activities$: Subject<AuditedActivity[]> = this._pagination.list$;

  private _onEditActivity?: Subscription;

  constructor(
    private dialog: MatDialog,
    public widgetService: WidgetService,
    private notifService: NotificationService,
    private viewService: ViewService,
    private recordService: RecordService,
    private router: ActivatedRoute,
    protected date: DateService,
    private readonly _pagination: PaginationStore<AuditedActivity, string>,
    private readonly _activities: ActivitiesService,
    private readonly _events: EventBusService,
  ) {}

  ngOnInit() {
    this.arSubscriptions.push(
      this.widgetService.reloadWidgetList.subscribe((bReload: boolean) => {
        if (bReload) {
          this.onRefresh();
        }
      })
    );

    // Get activity list from get Record api
    this.viewService.obvViewResult$.take(1).subscribe(() => {
      this.widgetService.beforePageLoad('default', 'activities', 'created_at');
      this.detectAutoOpen();
    });

    // Set default filter date and type
    this.selectDate = 'all';
    this.selectType = 'all';

    this.initStatusWidgetConfig();

    this.arSubscriptions.push(
      this.router.queryParams.pipe(
        tap((params) => {
          if (filled(params['activity_id'])) {
            return;
          }

          this.bLoading = false;
        }),
        filter(params => (params['activity_id'])),
        switchMap(params => this.recordService.getRecord('activities', params['activity_id'])),
        filter(response => (response['record_details'])),
        finalize(() => this.bLoading = false),
      ).subscribe(response => {
        this.updateActivity(response['record_details'], response['record_details']['id']);
      })
    );

    this._pagination.onNextPage$
      .subscribe((page) => this._loadList(page));

    this._onEditActivity = this._events.getEventFor<AuditedActivity>('activities:edit')
      .subscribe((data) => this.updateActivity(data, data.id));
  }

  /**
   * Destroy any subcriptions when this component is
   * destroyed.
   *
   * @returns {void}
   */
  ngOnDestroy(): void {
    this.arSubscriptions.forEach(subs => {
      subs.unsubscribe();
    })

    if (this._onEditActivity) {
      this._onEditActivity.unsubscribe();
    }
  }

  /**
   * Detect send via chat from invoices or any preview filter.
   *
   * @returns {void}
   */
  detectAutoOpen(): void {
    this.router.queryParams.take(1).subscribe((params: {
      chat_type: string,
      upload_name: string,
      file_name: string,
      url: string
    }) => {
      if (params.chat_type === 'file' && params.upload_name !== null) {
        this.dialog.afterAllClosed.take(1).subscribe(() => {
          this.addActivity('message', {
            upload_name: params.upload_name,
            file_name: params.file_name,
            url: params.url,
          });
        });
      }
    });
  }

  /**
   * Method that opens an add dialog of either a
   * task, email, etc.
   *
   * @param {string} strComponent
   * @param {LooseObject} objAdditionalData
   *
   * @returns {void}
   */
  addActivity(strComponent: string, objAdditionalData: {
    upload_name?: string,
    file_name?: string,
    url?: string
  } = {}): void {
    iif(() => this.strModule == 'jobs' && strComponent !== 'email', this.recordService.getRecord('jobs', this.strRecordId), of({ record_details: {}})).subscribe( response => {
      this.arRecordData = response['record_details'];
      let objData: LooseObject = {
        width: '700px',
        height: 'auto',
        // Data to be passed on
        data: {
          "module" : this.strModule,
          "record_id" : this.strRecordId,
          "record_data": this.arRecordData,
          "view_type" : "add"
        },
        disableClose: true
      };

      let objEmailData = {
        width: '70%',
        height: 'auto',
        // Data to be passed on
        data: {
          "module" : this.strModule,
          "record_id" : this.strRecordId,
          "record_data": this.viewService.arRecord,
          "view_type" : "add"
        },
        disableClose: true
      };

      let tabDialogRef;

      // Detect what widget was clicked
      switch (strComponent) {
        case 'note':
          tabDialogRef = this.dialog.open(NoteComponent, objData);
          break;
        case 'event':
          tabDialogRef = this.dialog.open(EventComponent, objData);
          break;
        case 'task':
          tabDialogRef = this.dialog.open(TaskComponent, objData);
          break;
        case 'email':
          tabDialogRef = this.dialog.open(EmailComponent, objEmailData);
          break;
        case 'message':
          objData.panelClass = ['custom-dialog-container'];
          objData.disableClose = true;
          objData.data.record_data = (this.bFromJobScheduler) ? this.viewService.getRecord() : this.viewService.arRecord;
          objData.data.record_config = {...this.viewService.objRecord.used_fields, ...this.viewService.objRecord.available_fields};
          objData.data.additional_data = objAdditionalData;
          tabDialogRef = this.dialog.open(SmsComponent, objData);
          break;
      }

      // Get response after closing or submitting notes dialog
      tabDialogRef
        .afterClosed()
        .pipe(
          filter(data => data !== undefined)
        )
        .subscribe(
          (data) => {
            // Check if button clicked is not cancel button
            if (data !== 'cancel') {
              this.saveDraft(data);

              if (data['mode'] === 'create') {
                // If not an email, create the activity.
                this._activities.createActivity(JSON.stringify(data['data'])).subscribe( data => {
                  // Set data to be displayed in Activity List widget
                  this.onRefresh();
                  this.updateView('activity_created', strComponent);
                  // FC-962: reload status widget component
                  // NOTE: this should not be here since this component should not be aware of who is module currently using
                  // it must be done in the higher level module or main module. improved me later
                  if (this.isJobTaskActivity(strComponent) || this.isQuoteTaskActivity(strComponent)) {
                    this.reloadStatusWidget(
                      this.objStatusWidgetConfig['module'],
                      this.objStatusWidgetConfig['id']
                    );
                  }
                  if (this.bFromJobScheduler) {
                    this.triggerActivitiesRefresh.emit();
                  }
                }, err => {
                  if (err['error'] && err['error']['contact_id']) {
                    this.notifService.sendNotification('not_allowed', err['error']['contact_id'][0], 'warning');
                  }
                });
              }
            }

            if (data['data'] !== undefined && data['data'].length > 0) {
              this.onRefresh();
              this.updateView('email_sent');

              if (this.bFromJobScheduler) {
                // If created activity is email, get latest email from data and emit.
                this.triggerActivitiesRefresh.emit();
              }
            }
          }
        );
    });
  }

  /**
   * Update the current activity view using the latest fetched data.
   * @param strMessage
   */
  updateView (strMessage: string, strActivityType?: string): void {
    if (strActivityType == 'message') {
      this.notifService.notifySuccess('message_sent');
    } else {
      this.notifService.notifySuccess(strMessage);
    }
  }

  /**
   * Remove html code in the email draft for preview.
   * @param strHtml
   */
  cutHtml(strHtml) {
     return strHtml ? String(strHtml).replace(/<[^>]+>/gm, '') : '';
  }

  /**
   * This triggers when a checkbox is being toggled.
   * @param objActivity
   */
  toggleFinished(objActivity) {

    // Create the needed request object to udpate an activity.
    let objRequest = {
      id: objActivity.id,
      module: this.strModule,
      record_id: this.strRecordId,
      module_id: RelateIds[this.strModule],
      is_completed: objActivity.is_completed
    };

    // Send the request to the API.
    this._activities.updateActivity(JSON.stringify(objRequest)).subscribe(result => {
      // Inform user that update was success.
      if (result['data'] != undefined) {
        this.notifService.notifySuccess('task_updated');
        // Reload new list that comes with the update.
        this.onRefresh();

        // FC-962: reload status widget component
        // NOTE: this should not be here since this component should not be aware of who is module currently using
        // it must be done in the higher level module or main module. improved me later
        // since toggleFinished is only applicable to the task
        if (this.isJobTaskActivity('task') || this.isQuoteTaskActivity('task')) {
          this.reloadStatusWidget(
            this.objStatusWidgetConfig['module'],
            this.objStatusWidgetConfig['id']
          );
        }
}
    })
  }


  // Function to add an activities
  updateActivity(objComponent, strActivityId) {
    this.arRecordData = (this.strModule == 'jobs') ? this.viewService.arRecord : [];
    let objData: LooseObject = {
      width: '700px',
      height: 'auto',
      // Data to be passed on
      data: {
        "module" : this.strModule,
        "record_id" : this.strRecordId,
        "view_type" : "edit",
        "activity": [],
        "record_data": this.arRecordData,
        "activity_id": strActivityId
      },
      disableClose: true
    };

    let objEmailData = {
      width: '70%',
      height: 'auto',
      // Data to be passed on
      data: {
        "module" : this.strModule,
        "record_id" : this.strRecordId,
        "activity_id" : strActivityId,
        "is_draft" : false,
        "activity": [],
        "record_data": this.arRecordData,
      },
      disableClose: true
    };

    let tabDialogRef;
    // Detect what widget was clicked
    switch (objComponent['activity_type']) {
      case ActivityType['note']:
        tabDialogRef = this.dialog.open(NoteComponent, objData);
        break;
      case ActivityType['event']:
        tabDialogRef = this.dialog.open(EventComponent, objData);
        break;
      case ActivityType['task']:
        if (objComponent['task_progress'] !== 'cancelled') {
          tabDialogRef = this.dialog.open(TaskComponent, objData);
        }
        break;
      case ActivityType.message:
        objData.data = objComponent;
        objData.data.module = this.strModule;
        objData.data.record_id = this.strRecordId;
        objData.panelClass = ['custom-dialog-container'];
        tabDialogRef = this.dialog.open(ChatComponent, objData);
        break;
      case ActivityType['email']:
        // If draft, send the whole activity record to the component. Else, just send the IDs and module.
        if (objComponent['is_draft']) {
          tabDialogRef = this.dialog.open(EmailComponent, objData);
        } else {
          tabDialogRef = this.dialog.open(RenderComponent, objEmailData);
        }

      break;
    }

    if (tabDialogRef) {
          // Get response after closing or submitting notes dialog
    tabDialogRef.afterClosed().subscribe(
      data => {
        // Check if button clicked is not cancel button
        if (data != 'cancel' && data) {
          this.saveDraft(data);

          if (data['mode'] == 'edit') {

            this._activities.updateActivity(JSON.stringify(data['data'])).subscribe( response => {
              // Get the data with default filters
              // Set data to be displayed in Activity List widget
              this.onRefresh();
              this.notifService.notifySuccess('activity_updated');

              // FC-962: reload status widget component
              // NOTE: this should not be here since this component should not be aware of who is module currently using
              // it must be done in the higher level module or main module. improved me later
              if (this.isJobTaskActivity(objComponent['activity_type']) || this.isQuoteTaskActivity(objComponent['activity_type'])) {
                this.reloadStatusWidget(
                  this.objStatusWidgetConfig['module'],
                  this.objStatusWidgetConfig['id']
                );
              }

              this._events.dispatch({
                name: spf('activities:edited:%s', {
                  args: [objComponent['activity_type']],
                }),
                args: {
                  previous: objComponent,
                  updates: data['data'],
                },
              });
            }, error => {

              // Catch thrown exception errors from the api
              // Eg. when a job should always have at least one non-cancelled task on it
              if (error.status === 422) {
                let objError: FMError = new FMError(error.error.errors[0]);
                this.notifService.sendNotification('not_allowed', objError.detail, 'warning');
              } else {
                this.notifService.sendNotification('not_allowed', 'unable_to_update_activity', 'warning');
              }

            });

          } else {
            if (data['mode'] == 'delete') {
              // Refresh data after deletion of draft
              this.onRefresh();
            } else {
              // Refresh data after sending draft successfully
              this.onRefresh();
            }
          }

        }
      }
    );
    }

  }

  /**
   * Save the email as draft and reload the activity list.
   * @param data
   */
  saveDraft(data) {
    // Check if there is a isDraft object.
    if (data['isDraft'] != undefined) {

      // Inform if draft is saved or if email is sent.
      if (data['isDraft']) {
        this.notifService.notifySuccess('draft_saved');
      } else {
        this.notifService.notifySuccess('email_sent');
      }

      // Get the data with default filters
      this.onRefresh();
    }
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate, strType) {
    // Convert datetime to utc
    let utcTime = moment.utc(strDate);
    let strLocalTime = this.date.convertUtcToLocal(utcTime);

    // Convert to local time zone and display
    return strLocalTime.format('lll');
  }

  // Filter activity by timeline and by activity type
  filterActivity(): void {
    // this trigger the filter by refreshing the pagination
    this.onRefresh();
  }

  activityColor(activity: Activity): string {
    switch (activity.activity_type) {
      case 'message':
        return 'icon-circle-chat';
      case 'event':
        return 'icon-circle-event';
      case 'note':
        return 'icon-circle-note';
      case 'email':
        return 'icon-circle-email';
      case 'task':
        return this._getTaskColor(activity);
      default:
        return '';
    }
  }

  private _getTaskColor(task: Activity): string {
    if (this.isCancelledTask({
      activity_type: task.activity_type,
      task_progress: task.task_progress,
    })) {
      return 'task-cancelled';
    }

    switch (task.task_progress) {
      case 'awaiting_scheduling':
        return 'task-awaiting-scheduling';
      case 'scheduled':
        return 'task-scheduled';
      case 'in_transit':
        return 'task-in-transit';
      case 'in_progress':
        return 'task-in-progress';
      case 'complete':
        return 'task-complete';
      default:
        return '';
    }
  }

  /**
   * initializes requirements for the status widget config used by this component instance
   *
   * @returns {void}
   */
  protected initStatusWidgetConfig(): void {
    // set view service status widget id to new a new random number
    this.viewService.updateStatusWidget({
      instance_id: this.intInstanceID = Math.floor(Math.random() * 100000) + 1
    });

    // set current status widget config
    this.objStatusWidgetConfig = this.viewService.arRecordConfig;
  }

  /**
   * relates the status widget component
   *
   * @param {string} strModule
   * @param {string} strModuleID
   */
  protected reloadStatusWidget(strModule: string, strModuleID: string): void {
    this.viewService.updateStatusWidget({
      type: 'update',
      data: {
        id: strModuleID,
        module: strModule
      },
      instance_id: this.intInstanceID
    });
  }

  /**
   * checks if the given component is a task for a jobs module
   *
   * @param {string} strComponent
   */
  protected isJobTaskActivity(strComponent: string): boolean {
    return (this.strModule === 'jobs' && strComponent === ActivityType.task);
  }

  /**
   * checks if the given component is a task for the opportunities module
   *
   * @param {string} strComponent
   */
  protected isQuoteTaskActivity(strComponent: string): boolean {
    return (this.strModule === 'opportunities' && strComponent === ActivityType.task);
  }

  /**
   * Retry sending the SMS. This will only be visible
   * if the status of the SMS is failed.
   *
   * @param objActivity
   *
   * @returns {void}
   */
  public retrySms(objActivity: {id: string}): void {
    this._activities.updateActivity(JSON.stringify({
      id: objActivity.id,
      module: this.strModule,
      record_id: this.strRecordId,
      module_id: RelateIds[this.strModule],
      sms_status: 'queued'
    })).subscribe(result => {
      if (result['data'] != undefined) {
        this.notifService.notifySuccess('sms_retry_sent');
      }
    });
  }

  /**
   * Checks if the activity is a failed sms activity.
   *
   * @param objActivity
   *
   * @returns {boolean}
   */
  public isFailedSms(objActivity: {activity_type: string, sms_status: string}): boolean {
    return (objActivity.activity_type === 'message' && objActivity.sms_status === 'failed')
  }

  /**
 * Checks if the activity is a cancelled task.
 *
 * @param objActivity
 *
 * @returns {boolean}
 */
  public isCancelledTask(objActivity: { activity_type: string, task_progress: string }): boolean {
    return (objActivity.activity_type === 'task' && objActivity.task_progress === 'cancelled')
  }

  /**
   * Opens a popup where there is a list of
   * user on the side and they can schedule that
   * user in the calendar.
   *
   * @returns {void}
   */
  public openSchedulerList(activity: Activity): void {

    this.recordService.getRecord('activities', activity['id'], true).subscribe(response => {
      let objTask = response['record_details'];
      let objRelatedRecord = this.viewService.objRecord['record_details'];
      if (isObject(objRelatedRecord['address'])) {
        objRelatedRecord['address'] = (new ReadableAddressPipe).transform(objRelatedRecord['address'] as any);
      }

      if (this.viewService.strRecordModule == 'jobs') {
        objTask['job'] = objRelatedRecord;
      } else if (this.viewService.strRecordModule == 'opportunities') {
        objTask['opportunity'] = objRelatedRecord;
      }

      objTask['activity_id'] = objTask['id'];
      let objSchedulerDialog = this.dialog.open(TaskCalendarDialogComponent, {
        width: '95%',
        height: '90%',
        data: new TaskMetadataC(objTask),
        panelClass: 'custom-dialog'
      });

      objSchedulerDialog.afterClosed().subscribe(() => {
        if (objSchedulerDialog.componentInstance.bRefresh) {
          this.reloadStatusWidget('jobs', objTask['job'].id)
          this.onRefresh();
        }
      })
    });

  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this._pagination.refresh();
  }

  ngOnChanges() {
    if (!this.bFromJobScheduler) {
      this.onRefresh();
    }
  }

  private _loadList = (page?: string) => observe({
    before: () => this.bLoading = true,
    after: () => this.bLoading = false,
    observable: () => this._activities.getActivities(JSON.stringify({
      'module': this.strModule,
      'record_id': this.strRecordId,
      'widget': 'activities',
      'filter_clause': {
        'activity_type': this.selectType,
        'activity_date': this.selectDate,
        ... (this.selectType != 'note' && {
          'is_sticky_note': false,
        }),
        ... (filled(page) && {
          'created_at': page,
        }),
      },
    }))
  }).subscribe((response) => this._pagination.setCurrent(
    this._computeCurrentPageFromResponse(response),
  ));

  private _computeCurrentPageFromResponse(response: GetActivitiesResult): PaginatedResult<AuditedActivity, string> {
    let nextPage = null;

    if (response.has_next_token) {
      nextPage = btoa(JSON.stringify({
        'direction': 'next',
        'date': last(response.data).created_at,
      }))
    }

    return new PaginatedResult({
      items: response.data,
      pagination: new Pagination({
        next_page: nextPage,
      })
    });
  }
}
