import { Injectable } from '@angular/core';
import { AUDIT_TRAIL_AVAILABLE_MODULES } from '../constant';
import { PaginatedResult } from '../../../shared/view-models';
import { Observable } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { format_as_api_accepted_date } from '../../../shared/utils/api';
import { RecordHistory } from '../types';
import { NotificationService } from '../../../services/notification.service';
import { data_get, fallback } from '../../../shared/utils/common';

@Injectable()
export class SharedAuditTrailService {
  constructor(
    private _api: ApiService,
    private _notifications: NotificationService,
  ) {}

  fetchList = (
    module: string, id: string, opts: FetchListOptions
  ): Observable<PaginatedResult<RecordHistory, number>> => this._api.callPrivate$({
    action: 'post',
    path:  `/audit/${module}`,
    body: (new Map())
      .set('id', id)
      .set('range', {
        from: format_as_api_accepted_date(opts.from),
        to: format_as_api_accepted_date(opts.to),
      })
      .set('page', fallback(data_get(opts, 'page'), {
        fallback: () => 0,
      })),
    onSuccessResponse: (response) => new PaginatedResult({
      items: response.get('items'),
      pagination: response.get('pagination'),
    }),
  });

  preview = (
    module: string, history: RecordHistory,
  ): Observable<PreviewResponse> => this._api.callPrivate$({
    action: 'post',
    path: `/audit/${module}/preview`,
    body: (new Map())
      .set('id', history.id)
      .set('history', history),
    onSuccessResponse: (response) => response.get('item'),
  });

  restore = (
    module: string, history: RecordHistory,
  ): Observable<void> => this._api.callPrivate$({
    action: 'post',
    path: `/audit/${module}/restore`,
    body: (new Map())
      .set('id', history.id)
      .set('history', history),
    onSuccessResponse: (_) => this._notifications.notifySuccess('Record successfully restored.'),
    onErrorResponse: (_, __) => this._notifications.notifyError('Unable to restore record. Please contact support'),
  });
}

export type AuditTrailModuleName = typeof AUDIT_TRAIL_AVAILABLE_MODULES[number];

type FetchListOptions = {
  /// The date where the history should start for the given module
  from: Date,
  /// The date where the history should end for the given module
  to: Date,
  /// The page to which the list should point to
  page?: number;
}

type PreviewResponse = {
  /// contains the parsed data information
  data: Record<string, any>;
  /// contains the all fields in the module
  fields: Record<string, any>;
  /// contains the view configuration
  view: Record<string, any>[];
  /// contains the available fields in the module
  available_fields: Record<string, any>;
  /// contains the available fields in the module
  used_fields: Record<string, any>;
}