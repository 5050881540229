import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListingFilter } from '../../objects/listing-filter';
import { NotificationService } from '../../../../../services/notification.service';
import { RecordService } from '../../../../../services/record.service';
import { LooseObject } from '../../../../../objects/loose-object';

@Component({
  selector: 'fm-listing-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {

  /**
   * TODO: Filters should be assembled in this component.
   *
   * Filters from the main parents.
   *
   * @var {any}
   */
  @Input('arSavedFilters') arSavedFilters: any

  /**
   * The models oer48f the filter to be passed to the API.
   *
   * @var {ListingFilter}
   */
  @Input('arModel') arModel: ListingFilter;

  /**
   * Which module uses this component.
   *
   * @var {string}
   */
  @Input('strModule') strModule: string;

  /**
   * Event listeners to inform parent of changes of this component.
   *
   * @var {EventEmitter<FilterAction>}
   */
  @Output() onFilterEvent = new EventEmitter<FilterAction>();

  constructor(
    public notify: NotificationService,
    public record: RecordService
  ) { }

  /**
   * Trigger set filter list.
   *
   * @param {string} strKey
   * @param {string} strAction
   * @param {string} bReset
   */
  setFilter(strKey: string, strAction: string, bReset: boolean) {
    this.onFilterEvent.next({key: strKey, action: strAction, reset: bReset});
  }

  /**
   * Remove a filter item.
   *
   * @param {id: string, name: string} objFilter
   *
   * @returns {void}
   */
  deleteFilter(objFilter: {id: string, name: string}) {
    this.notify.sendConfirmation('filter_delete_confirm').subscribe(confirmation => {
      if (confirmation.answer) {
        this.record.deleteFilter(this.strModule, objFilter.id).subscribe(response => {
          const strResponse: any = response['body'];
          this.notify.notifySuccess(strResponse);
          this.onFilterEvent.next({key: objFilter.name, action: 'clear_filter', reset: false});
        });
      }
    });
  }

}

export interface FilterAction {
  key: string;
  action: string;
  reset: boolean;
}
