export class ListingFilter implements ListingFilterCommon, ListingFilterCommonInfo {

  default_filter: string;
  share: boolean;
  filter_structure: FilterStructure;
  order_by: OrderBy;
  filter_name: string;
  has_filter: boolean;

  constructor(properties?: ListingFilter) {
    if (properties) {
      this.default_filter = properties.default_filter;
      this.share = properties.share;
      this.filter_structure = properties.filter_structure;
      this.order_by = properties.order_by;
      this.filter_name = properties.filter_name;
      this.has_filter = properties.has_filter;
    }
  }

}

export interface ListingFilterCommon {
  filter_structure: FilterStructure;
  order_by: OrderBy;
}

export interface ListingFilterCommonInfo {
  filter_name: string;
  has_filter: boolean;
}

export interface ListingFilterStructure {
  label: string;
  value: string | ListingFilterStructure[];
  op: 'eq' | 'lt' | 'le' | 'ne' | 'gt' | 'ge' | 'startsWith' | 'endsWith' | 'contains';
}

export interface FilterStructure {
  [key: string]: ListingFilterStructure[]
}

export interface OrderBy {
  id: string;
  sort: 'asc' | 'desc';
}

export interface SavedListingFilter {
  default: {[key: string]: {config: ListingFilterCommon}[]}
  saved: {
    config: ListingFilterCommon & ListingFilterCommonInfo,
    created_at: string,
    id: string,
    is_removable: boolean,
    is_shared: boolean,
    module: string
    name: string
    updated_at: string,
    user_id: string
  };
}

