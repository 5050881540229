import { entries } from 'lodash-es';
import { Field } from '../../../services/form.service';
import { spf } from '../../../shared/utils/str';
import { PromptFieldEntry } from './types';

/// list of field labels that are exempted as checklist field entry
const CHECKLISTS_EXCLUDED_FIELD_ENTRY_FIELD_LABELS = [
  'phone',
  'address_geolocation',
  'attributes',
  'custom_attributes',
  'custom_field',
  'job_number',
  'status',
  'email_address',
  'invoicing_type',
  'priority',
  'job_type',
  'created_at',
  'created_by',
  'updated_at',
  'modified_by',
  'last_inspection_date',
  'is_custom_location',
  'is_invoiced',
  'amount_to_invoice',
  'amount_actually_invoiced',
  'external_id',
  'tags',
  'work_order',
  'hierarchical_view',
  'geolocation_point',
  'geolocation',
  'checklist_report_format',
];

/// List of field types that are exempted as checklist field entry
const CHECKLISTS_EXCLUDED_FIELD_ENTRY_FIELD_TYPES = [
  'address',
  'relate',
];

export const identify_module_group_name = (moduleName: string): PromptFieldEntry['group_name'] => {
  let groupName: PromptFieldEntry['group_name'] = 'ungrouped';

  if (moduleName == 'assets') {
    groupName = 'asset_fields';
  } else if (moduleName == 'sites') {
    groupName = 'site_fields';
  } else if (moduleName == 'opportunities') {
    groupName = 'opportunity_fields';
  } else if (moduleName == 'custom_asset_types') {
    groupName = 'custom_asset_fields';
  } else if (moduleName == 'jobs') {
    groupName = 'job_fields';
  }

  return groupName;
}

export const format_module_field_entries = (fields: Record<string, Field>, moduleName: string): PromptFieldEntry[] => {
  let output: PromptFieldEntry[] = [];

  for (const [name, metadata] of entries(fields)) {
    /// exclude if the the given field name or its type belongs to the list
    /// of types/names that are not supported
    if (CHECKLISTS_EXCLUDED_FIELD_ENTRY_FIELD_LABELS.includes(metadata.label)
      || CHECKLISTS_EXCLUDED_FIELD_ENTRY_FIELD_TYPES.includes(metadata.type)
    ) {
      continue;
    }

    output.push({
      id: spf('%s-%s', {
        args: [
          moduleName,
          name,
        ]
      }),
      name: metadata.label,
      group_name: identify_module_group_name(moduleName),
    });
  }

  return output;
}