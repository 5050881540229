import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { blank, filled } from '../../../utils/common';
import { isString } from 'lodash-es';
import { DEFAULT_ICON_SIZE, FieldmagicCustomizedIconProp, FieldmagicIconProp, FieldmagicIconSizeProp } from '../../icons/icon.component';

export type ClickEvent = Event;

@Component({
  selector: 'fieldmagic-primary-button',
  template: `
    <button
      class="fieldmagic-btn btn {{ variant$ | async }}"
      [ngClass]="klass"
      [disabled]="isInProgress || disabled || denied"
      [class.fieldmagic-btn-lg]="size == 'lg'"
      [class.fieldmagic-btn-icon]="purpose == 'icon' || purpose == 'delete-icon'"
      [matTooltip]="'forbidden_action_error' | translate | when: denied:{
        otherwise: tooltip
      }"
    >
      <fieldmagic-icon
        *ngIf="(icon | filled) || isInProgress; else content;"
        [icon]="icon || 'spinner'"
        [spin]="isInProgress"
        [size]="iconSize"
      >
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </fieldmagic-icon>
      <ng-template #content>
        <ng-content *ngIf="label | blank; else asLabel"></ng-content>
      </ng-template>
      <ng-template #asLabel>
        <span>{{ label | translate }}</span>
      </ng-template>
    </button>
  `,
  styleUrls: [
    './primary-button.component.scss',
  ],
})
export class PrimaryButtonComponent {
  /// indicates that the button is work in progress
  @Input() isInProgress: boolean = false;

  /// indicates that the button should be disabled. Difference with the isInProgress, when disabled
  /// it doesn't show any progress indicator
  @Input() disabled: boolean = false;

  /// the icon to display at the left side of the text. This does not affect
  /// the loading indicator
  @Input() icon: FieldmagicIconProp;

  /// a custom classes that will style the button
  @Input() klass: string | {
    [className: string]: boolean;
  };

  /// A simple string that labels the button. If you wish to have complex label
  /// you can use the template slot
  @Input() label: string;

  @Input() variant: FieldmagicPrimaryButtonVariant = 'primary';

  @Input() purpose: FieldmagicPrimaryButtonPurpose = 'generic';

  @Input() iconSize: FieldmagicIconSizeProp = DEFAULT_ICON_SIZE;

  @Input() size: 'lg' | 'md' = 'md';

  @Input() denied: boolean = false;

  @Input() tooltip?: string;

  readonly variant$ = new BehaviorSubject<string>('btn-primary');

  ngOnInit(): void {
    if (this.variant === 'error') {
      this.variant$.next('btn-danger');
    } else if (this.variant == 'secondary') {
      this.variant$.next('btn-secondary');
    } else if (this.variant == 'success') {
      this.variant$.next('btn-success');
    } else if (this.variant == 'warning' || this.purpose == 'delete') {
      this.variant$.next('btn-warning text-white');
    } else if (
        this.variant == 'link'
        || this.purpose == 'icon'
        || this.purpose == 'widget-action'
        || this.purpose == 'delete-icon'
    ) {
      this.variant$.next('btn-link');
    } else {
      this.variant$.next('btn-primary');
    }

    if (this.purpose == 'delete' && blank(this.icon)) {
      this.icon = 'trash-alt';
    }

    if (this.purpose == 'delete-icon' && blank(this.icon)) {
      this.icon = {
        icon: 'trash-alt',
        klass: 'text-warning',
      };
    }

    if (this.purpose == 'delete' && blank(this.label)) {
      this.label = 'delete';
    }

    if (this.purpose == 'save' && blank(this.icon)) {
      this.icon = 'save';
    }

    if (this.purpose == 'save' && blank(this.label)) {
      this.label = 'save';
    }

    if (this.purpose == 'widget-action' && isString(this.icon)) {
      this.icon = [{
        icon: this.icon,
        klass: 'text-success',
      }];
    }
  }
}

type FieldmagicPrimaryButtonPurpose = 'delete'
  | 'generic'
  | 'save'
  | 'widget-action'
  | 'icon'
  | 'delete-icon';

type FieldmagicPrimaryButtonVariant = 'error'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'link';