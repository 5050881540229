import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ListingService } from '../../../../services/listing.service';
import { ArrService } from '../../../../services/helpers/arr.service';
import { NotificationService } from '../../../../services/notification.service';
import { WidgetService } from '../../../../services/widget.service';
import { DialogStockReceiptComponent } from './dialog/dialog-stock-receipt.component';
import { StockReceipt } from '../../../../objects/stock-management/stock-receipt';
import { get as _get, isEmpty as _isEmpty, cloneDeep, first, get} from 'lodash-es';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RecordService } from '../../../../services/record.service';
import { EditSupplierInvoiceComponent } from '../../../jobs/supplier-invoices/edit-supplier-invoice/edit-supplier-invoice.component';
import { EditformComponent } from '../../../../shared/components/editform/editform.component';
import { FormPopup } from '../../../../objects/centralized-forms/form-popup';
import { LooseObject } from '../../../../objects/loose-object';
import { ViewService } from '../../../../services/view.service';
import { filter } from 'rxjs/operators';
import { filled } from '../../../../shared/utils/common';
import { StatusCode } from '../../../../lists/status-code';

@Component({
  selector: 'stock-receipts',
  templateUrl: './stock-receipts.component.html',
  styleUrls: ['./stock-receipts.component.scss']
})

export class StockReceiptsComponent implements OnInit {

  /**
   * Simple loading flag.
   *
   * @var {boolean | null}
   */
  public bLoading: boolean | null = null;

  /**
   * Holds the stock receipt record.
   *
   * @var {StockReceipt | null}
   */
  public objStockReceiptData: StockReceipt | null = null;

  /**
   * Holds the purchase order id.
   *
   * @var {string}
   */
  public strRecordId: string;

  private arSubscriptions: Subscription[] = [];

  /**
   * stock receipt data
   *
   * @var {StockReceipt[]}
   */
  public arStockReceipt: StockReceipt[] = [];

  public objModuleId: LooseObject = {
    purchase_orders: 'purchase_order_id',
    supplier_invoices: 'supplier_invoice_id'
  };

  public objParentData: {
    id: string,
    module: string,
  };

  get stockCreditCreate(): StockReceipt {
    let arReceipt = this.arStockReceipt.filter( objStockReceipt => objStockReceipt.type === 'receipt' && objStockReceipt.status == 'received');
    let objStocKReceiptData = null

    if (arReceipt.length) {
      objStocKReceiptData = cloneDeep(arReceipt[arReceipt.length - 1]);
      objStocKReceiptData.type = 'credit';
    }

    return objStocKReceiptData;
  }

  get stockReceiptCreate(): StockReceipt {
    let arReceipt = this.arStockReceipt.filter( objStockReceipt => objStockReceipt.type === 'receipt');
    let objStocKReceiptData = null

    if (arReceipt.length) {
      objStocKReceiptData = cloneDeep(arReceipt[arReceipt.length - 1]);
      objStocKReceiptData.line_items.map( objLineItem => {
        objLineItem.received = 0;
        objLineItem.expected_receipt_date = null;

        return objLineItem;
      })
    }

    return objStocKReceiptData;
  }

  get hasRecievedStockReceipt(): boolean {
    let arReceipt = this.arStockReceipt.filter( objStockReceipt =>
      objStockReceipt.type === 'receipt' && objStockReceipt.status === 'received'
    );
    return arReceipt.length != 0;
  }

  constructor(
    public widget: WidgetService,
    public arr: ArrService,
    private list: ListingService,
    private dialog: MatDialog,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private record: RecordService,
    private view: ViewService,
  ) { }

  ngOnInit() {
    this.arSubscriptions.push(this.route.params.subscribe( params => {
      this.strRecordId = params['id'];

      this.fetchList();
    }));

    this.objParentData = this.record.getParentData();
  }

  /**
   * Destroy any subcriptions when this component is destroyed.
   *
   * @returns {void}
   */
  ngOnDestroy(): void {
    this.arSubscriptions.forEach(subs => {
      subs.unsubscribe();
    })
  }

  /**
   * Checks and gets if there is stock receipt in parallel to purchase order id
   *
   * @returns {void}
   */
  fetchList(): void {
    this.bLoading = true;
    const parentData = this.record.getParentData();

    if (filled(this.objModuleId[parentData.module])) {
      this.record.getRecordRelateJoined('stock_receipts', false, {[this.objModuleId[parentData.module]]: parentData.id})
      .subscribe( (response: StockReceipt[]) => {

        this.bLoading = false;
        this.arStockReceipt = response;
      });
    } else {

      this.bLoading = false;
      // this.arStockReceipt = [];
    }
  }

  /**
   * Opens the stock receipt dialog where we can
   * receive ordered items.
   *
   * @returns {void}
   */
  openUpdateStockReceiptDialog(
    objStockReceipt: StockReceipt = null,
    strStockType: 'credit' | 'receipt' = 'receipt',
    strMode: string = 'view',
    buttonType: string = ''): void {

    let toOpen: boolean = true;
    if (buttonType == 'create_button' && strStockType == 'credit' && !this.hasRecievedStockReceipt) {
      toOpen = false;
      this.notification.notifyWarning('draft_return_stock_receipt_message');
    }

    if (toOpen) {
      this.dialog.open(DialogStockReceiptComponent, {
        panelClass: ['w-75'],
        disableClose: true,
        data: {
          stock_receipt: (objStockReceipt) ? objStockReceipt : this.getStockReceiptFilter(),
          stock_type: strStockType,
          mode: strMode,
        }
      })
      .afterClosed()
      .subscribe(objDialogResponse => {
        const kStrAction = _get(objDialogResponse, 'action', null);
        const objStockReceipt = _get(objDialogResponse, 'data', null);
        const objStockReceiptItems = _get(objStockReceipt, 'line_items', null);
        const strType = _get(objDialogResponse, 'type', null);
        const bConfirmSupplierInvoice = _get(objDialogResponse, 'create_supplier_invoice', false);

        if (! _isEmpty(objStockReceiptItems)) {
          this.objStockReceiptData = objStockReceipt;
        }

        if (kStrAction === 'save') {
          this.notification.notifySuccess('stock_receipt_updated');
          this.fetchList()
        }

        if (bConfirmSupplierInvoice && this.objParentData.module == 'purhase_orders') {
          this.notification.sendConfirmation('confirm_create_supplier_invoice')
            .filter(confirmation => confirmation.answer == true)
            .subscribe(() => this.openSupplierInvoice());
        }

        if (strType === 'credit') {
          this.notification.sendConfirmation('confirm_create_supplier_credit_notes')
            .filter(confirmation => confirmation.answer == true)
            .subscribe( () => this.openCreditNotes(objStockReceipt) );
        }
      });
    }
  }

  /**
   * open supplier invoice form to create record
   */
  openSupplierInvoice(): void {
    this.record.getRecordBasedOnParent(true).subscribe( response => {
      let objParentRecord = response['record_details'];
      let dialogRef = this.dialog.open(EditSupplierInvoiceComponent, {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        disableClose: true,
        data: {
          module: 'purchase_orders',
          view_type : 'add',
          purchase_order_id: objParentRecord['id'],
          purchase_order_text: objParentRecord['text'],
          from_convert_po: true,
        }
      });
      dialogRef.afterClosed().first().subscribe(saveRecordData => {
        if (saveRecordData.message == 'save') {
          this.notification.notifySuccess('header_notification.success_added');
        }
      });
    });
  }

  /**
   * open supplier invoice form to create record
   */
  openCreditNotes(creditStock: LooseObject): void {
    this.record.getRecordBasedOnParent(true).subscribe( response => {
      let objParentRecord = response['record_details'];
      let purchaseOrderLineItems = objParentRecord.line_items;
      let lineItems = creditStock.line_items;

      let creditNoteLineItems = lineItems.map( lineItem => {
        let purchaseOrderLineItem = purchaseOrderLineItems.find( poLineItem => poLineItem.id == lineItem.id );
        let totalAmount = lineItem.credit * purchaseOrderLineItem.unit_cost;
        let totalTax = (purchaseOrderLineItem.tax_rate / 100) * totalAmount;

        return {
          item_id: purchaseOrderLineItem.item_id,
          item_name: purchaseOrderLineItem.item_name,
          item_code: purchaseOrderLineItem.item_code,
          description: lineItem.description,
          tax_code_id: purchaseOrderLineItem.tax_code_id,
          tax_code_name: purchaseOrderLineItem.tax_code_name,
          tax_rate: purchaseOrderLineItem.tax_rate,
          account_code_id: purchaseOrderLineItem.account_code_id,
          account_code_name: purchaseOrderLineItem.account_code_name,
          quantity: lineItem.credit,
          unit_cost: purchaseOrderLineItem.unit_cost,
          total_price: totalAmount,
          amount_tax: totalTax,
          purchase_order_item_id: purchaseOrderLineItem.id
        }
      });

      this.dialog.open(EditformComponent, new FormPopup('credit_notes', {}, {
        customer_id: objParentRecord.customer_id,
        customer_text: objParentRecord.customer_text,
        purchase_order_id: objParentRecord.id,
        purchase_order_text: objParentRecord.text,
        type: 'supplier',
        invoice_type: 'supplier',
        line_items: creditNoteLineItems,
        disable_line_items: true
      }));
    });
  }

  deleteStockReceipt(stockReceipt: LooseObject): void {
    if (filled(stockReceipt.id)) {
      this.notification.sendConfirmation('confirm_delete').pipe(
        filter(confirmation => confirmation.answer == true),
      ).subscribe(() => {
        this.record.deleteRecord('stock_receipts', stockReceipt.id).subscribe( response => {
          if (response.status === StatusCode.kResponseSuccess) {
            this.notification.notifySuccess('record_delete_success')
          } else {
            this.notification.notifyWarning('record_delete_failed')
          }
          this.fetchList();
          this.view.reloadRecordView(true);
        });
      })
    }
  }

  getStockReceiptFilter(): LooseObject {
    if (this.view.strRecordModule == 'purchase_orders') {
      return { purchase_order_id: this.strRecordId };
    }

    if (this.view.strRecordModule == 'supplier_invoices') {
      return { supplier_invoice_id: this.strRecordId };
    }

    return {};
  }
}