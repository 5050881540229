import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import moment from 'moment';
import { DocumentService } from '../../../services/document.service';
import { FileService } from '../../../services/file/file.service';
import { ActivitiesService } from '../../../services/activities.service';
import { LambdaService } from '../../../services/lambda.service';
import { DocumentLibrary } from '../../../lists/document-libray';
import { NotificationService } from '../../../services/notification.service';
import { RecordService } from '../../../services/record.service';
import { get } from 'lodash-es';
import { tap, map, filter, switchMap,  } from 'rxjs/operators';
import { blank, isId, filled } from '../../../shared/utils/common';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormControl , Validators } from '@angular/forms';
import { LooseObject } from '../../../objects/loose-object';
import { Document } from '../upload-document-dialog/upload-document-dialog.component';
import { environment } from '../../../../environments/environment';
import { PreviewComponent } from '../../../shared/components/widget/file/preview/preview.component';
import { Select } from '../../../objects/select';

@Component({
  selector: 'app-update-document-dialog',
  templateUrl: './update-document-dialog.component.html',
  styleUrls: ['./update-document-dialog.component.scss']
})
export class UpdateDocumentDialogComponent implements OnInit {
    public form: FormGroup;
    public arCurrentDocument;
    public arVersionList : any = [];
    public arFormatList = new DocumentLibrary;
    public bIsSaving: boolean = false;
    public isFileDropped: boolean = false;
    public isFileUploaded: boolean = false;

    private _bPreviewBtnLoading: boolean = false;
    private _bDownloadBtnLoading: boolean = false;
    private _bLoadingInit: boolean = false;

    objFields: LooseObject = {
        document_name: {
            controlType: 'text',
            key: 'document_name',
            label: 'document_name',
            required: true,
            default_value: null,
            default_text: '',
            max_length: 128,
        },
        linked_asset_types: {
            controlType: "relate",
            key: "linked_asset_types",
            label: "linked_asset_types",
            module: "asset_types",
            multiple: true,
            hide_label: false,
            default_value: [],
            default_text: '',
        },
    };

    @HostListener('window:keyup.esc') onKeyUp() {
      this.cancelDialog();
    }

    get previewButtonDisabled(): boolean {
        return this._bPreviewBtnLoading;
    }

    get downloadButtonDisabled(): boolean {
        return this._bDownloadBtnLoading;
    }

    get arePreviewAndDownloadBtnsDisabled(): boolean {
        return this.bIsSaving || this._bLoadingInit || this.isFileUploaded;
    }

    get isSaveDisabled(): boolean {
        const strDocumentName = get(this.form.get('document_name'), 'value');
        const objFile = get(this.form.get('document'), 'value');

        return blank(strDocumentName) || blank(objFile) || this.bIsSaving || this._bLoadingInit || this.isFileDropped;
    }

    get hasFile(): boolean {
        const objFile = get(this.form.get('document'), 'value')

        return filled(objFile);
    }

    get uploadedFilename(): string {
        return get(this.form.get('document'), 'value.name', '');
    }


    constructor(
      private dialog: MatDialog,
      public dialogRef: MatDialogRef<UpdateDocumentDialogComponent>,
      public activitiesService: ActivitiesService,
      public fileService: FileService,
      public documentService: DocumentService,
      public lambdaService: LambdaService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private notifService: NotificationService,
      private recordService: RecordService,
      @Inject(DOCUMENT) private document: any
    ) { }

    ngOnInit() {
        this.form = new FormGroup({
            document_name: new FormControl(get(this.data, 'document_name'), Validators.required),
            document: new FormControl(null, Validators.required),
            linked_asset_types: new FormControl([]),
        });

        const strRecordId: string = get(this.data, 'id');
        this.arCurrentDocument = this.data

        if (filled(strRecordId) && isId(strRecordId)) {
            this._bLoadingInit = true;

            this.recordService.getRecord('document_library', strRecordId).pipe(
                finalize(() => this._bLoadingInit = false),
                tap(res => {
                    const arLinkedAssetTypes: Select[] = get(res, 'record_details.linked_asset_types', []).map(item => {
                        return new Select(
                            get(item, 'id'),
                            get(item, 'name', '')
                        );
                    });

                    this.form.patchValue({
                        linked_asset_types: arLinkedAssetTypes,
                        document: {
                            name: get(res, 'record_details.document_name'),
                            size: get(res, 'record_details.document_size', 0),
                            type: 'application/pdf',
                            upload_name : get(res, 'record_details.upload_name')
                        }
                    });

                    this.arCurrentDocument['upload_name'] = get(res, 'record_details.upload_name');
                }),
                map(res => get(res, 'record_details.upload_name')),
                filter(uploadName => filled(uploadName)),
                switchMap(uploadName => this.fileService.getObjectHistory(uploadName)),
            ).subscribe(data =>
                this.arVersionList = data
            );
        }

        this.dialogRef.backdropClick().subscribe(_ => {
            this.cancelDialog();
        });
    }

    /**
     * Let's format the datetime value.
     * @param date
     */
    formatDate(strDate) {
      return moment(strDate).utcOffset(new Date().getTimezoneOffset()).format('lll');
    }

    /**
     * Closes the dialog
     */
    cancelDialog() {
        if (this.form.touched || this.form.dirty || this.isFileDropped || this.isFileUploaded) {
            // Pop-up modal for confirmation
            this.notifService.sendConfirmation('confirm_cancel')
                .filter(confirmation => confirmation.answer === true)
                .subscribe(() => {
                    this.dialogRef.close({ status: 'cancel' });
                });
        } else {
            this.dialogRef.close({status : 'cancel'});
        }
    }

    /**
     *
     * Triggers when saving a document, passes value of the document to the main component for saving
     *
     * @param objData - the document that is dropped
     */
    onSubmit() {
        this.form.markAsDirty();
        this.form.markAsTouched();

        // Check if form is valid
        if (this.form.valid) {

            let arInitialRequest = {
                metadata : this.arFormatList.metadata,
                document_id : get(this.form.value, 'document.upload_name', '').split('/').pop(),
                field_checker : true
            };

            this.bIsSaving = true;

            // Check if uploaded file has form fields available
           this.lambdaService.mergeFormFields(JSON.stringify(arInitialRequest))
           .subscribe(
                data => {
                    const document: Document = this._getFormattedDocument(get(data, 'available_fields', {}));
                    document.current_data = this.arCurrentDocument

                    // Call api for adding the document
                    this.documentService.updateDocument(JSON.stringify(document)).pipe(
                        finalize(() => this.bIsSaving = false)
                    ).subscribe(
                        data => {
                            this.notifService.notifySuccess('file_update_success');
                            this.dialogRef.close({ status: 'save' });
                        }
                    );
                },
                error => this.bIsSaving = false
           );
        } else {
            this.notifService.notifyError('required_notice');
        }
    }

     /**
     *
     * Triggered when a document is dropped from the file input
     *
     * @param objData - the document that is dropped
     */
    onFileChange(objData) {
        let reader = new FileReader();

        if(objData.target.files && objData.target.files.length) {
            const [file] = objData.target.files;
            reader.readAsDataURL(file);
            let strFileExtension = file.name.split('.').pop();

            if(strFileExtension === 'pdf') {
                if(file.size/1024/1024 < 30) {
                    reader.onload = () => {
                        this.isFileDropped = true;

                        this.fileService.upload(file).subscribe((response) => {
                            let objFile = this.fileService.objFile;
                            this.isFileDropped = false;
                            this.isFileUploaded = true;

                            this.form.patchValue({
                                document: {
                                    name: objFile.name,
                                    size: objFile.size / 1024,
                                    type: objFile.type,
                                    upload_name : response['filename']
                                }
                            });
                        });
                    };
                } else {
                    this.notifService.notifyWarning('file_size_limit');
                }
            } else {
                this.notifService.notifyWarning('pdf_only');
            }
        }
    }

    /**
     * Preview the uploaded file
     *
     * @returns {void}
     */
    previewFile(): void {
        if (!this._bPreviewBtnLoading) {
            this._bPreviewBtnLoading = true;

            this.fileService.previewFile(this.arCurrentDocument['upload_name'], this.arCurrentDocument['document_name']).pipe(
                finalize(() => this._bPreviewBtnLoading = false)
            ).subscribe(_ => {});
        } else {
            this.notifService.notifyError('preview_loading_wait');
        }
    }

    /**
     * Triggers when the user clicks download icon
     * Gets presigned url for downloading a file from s3 and open page to download
     *
     * @returns {void}
     */
    downloadFile(): void {
        if (!this._bDownloadBtnLoading) {
            this._bDownloadBtnLoading = true;

            let strFilename = get(this.arCurrentDocument, 'document_name', '');

            if (strFilename.slice(-4) !== '.pdf') {
                strFilename = `${strFilename}.pdf`;
            }

            this.fileService.download(this.arCurrentDocument['upload_name'], 'application/pdf', strFilename).pipe(
                finalize(() => this._bDownloadBtnLoading = false)
            ).subscribe(data => {
                if (data) {
                    const tabOpen = window.open(data.toString(), '_blank');

                    if (!tabOpen) {
                        this.document.location.href = data.toString();
                    }
                } else {
                    this.notifService.sendNotification('header_notification.generic_fail', 'content_notification.fail_download', 'danger');
                }
            });
        }
    }

     /**
     * Gets the formatted data for submission.
     *
     * @param {any} availableFields
     *
     * @returns {Document}
     */
    private _getFormattedDocument(availableFields: any): Document {
        const formValue: LooseObject = this.form.value;
        const arLinkedAssetTypes: string[] = get(formValue, 'linked_asset_types', [])
            .map(item => get(item, 'id'))
            .filter(item => isId(item));

        return {
            document_name: formValue.document_name,
            document: formValue.document,
            available_fields: availableFields,
            is_library_document: true,
            linked_asset_types: arLinkedAssetTypes
        };
    }
}
