import {Component, Input} from '@angular/core';

@Component({
  selector: 'fieldmagic-grid',
  template: `
    <div
      fieldmagicGridRow
      [attr.id]="id"
    >
      <ng-content></ng-content>
    </div>
  `,
})
export class LayoutGridComponent {
  @Input() id: string;
}
