import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { ListingService } from '../../../services/listing.service';
import { NotificationService } from '../../../services/notification.service';
import { RecordService } from '../../../services/record.service';
import { ViewService } from '../../../services/view.service';
import { RelateIds } from '../../../lists/relate-ids';
import * as moment from 'moment';
import { isEmpty } from 'lodash-es';

import { StatusColors } from '../../../lists/listing-fields';
import { filter } from 'rxjs/operators';
import { StockAllocationComponent } from '../materials/stock-allocation/stock-allocation.component';
import { MaterialsService } from '../../../services/materials.service';
import { iif, of } from 'rxjs';
import { filled } from '../../../shared/utils/common';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  providers: [ListingService]
})

export class InvoicesComponent implements OnInit {
  @Input() strRecordId : string;
  @Input() strModule : string;
  @Output() refreshRelatePanel = new EventEmitter<any>();
  public arInvoices = [];
  public arPreviousPages = {};
  public bPageLoaded: boolean = false;
  public objJobData: any;
  public objCustomerData: any;
  public numInstanceId = Math.floor(Math.random() * 100000) + 1;
  public objStatusWidgetRecordDetails: any = [];
  public bDialogLoaded: boolean = true;
  public objModuleConfig: any = {};
  public strSelectStatus: string = null;
  public objColors = new StatusColors;

  /**
   * list of status invoices filter
   */
  public arStatusFilter: string[] = [
    'created', 'sent_to_client', 'paid', 'voided'
  ];

  constructor(
      private dialog: MatDialog,
      public listService: ListingService,
      private notifService: NotificationService,
      public viewService: ViewService,
      private recordService: RecordService,
      protected materialsService: MaterialsService,
  ) {
    // Get and store request record config
    this.objStatusWidgetRecordDetails = this.viewService.arRecordConfig;
  }

  ngOnInit() {
    this.viewService.updateStatusWidget({
      instance_id: this.numInstanceId
    });
    // Get the list on page initialization.
    this.fetchList('default');
  }

  /**
  * Open the create invoice dialog.
  */
  createInvoice() {
    this.bDialogLoaded = false;
    // To set the invoice type and invoice amount when dialog is open
      this.bDialogLoaded = true;
      // Get Record view
      let recordViewDetail = this.viewService.objRecord;
      this.objJobData = (this.strModule == 'jobs') ? this.viewService.arRecord : [];
      // Create the object to be passed inside the dialog.
      let objData = {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        padding: '1%',
        disableClose: true,
        // The id of the jobs, the invoice's "parent".
        data: {
          record_view_detail: recordViewDetail,
          module: this.strModule,
          record_id : this.strRecordId,
          view_type : 'add'
        }
      };

      // Initialize the dialog.
      let tabDialogRef = this.dialog.open(EditInvoiceComponent, objData);
      // When the dialog closes, reload the list.
      tabDialogRef.afterClosed().first().subscribe(item => {
        if (item != undefined && item.action== 'save') {
          // Show notification that activity has been created.
          this.notifService.notifySuccess('header_notification.success_added');
          setTimeout(() => {
            this.filter();
          }, 3500);
          // Refresh relate panel time and materials
          this.refreshRelatePanel.emit({ widget: 'materials' });
          this.refreshRelatePanel.emit({ widget: 'time-entries' });
          // FC-3803: valid invoicing types when updating job status, see FC-962 for details
          let arValidInvoicingTypes: string[] = ['fixed_price_invoices', 'time_and_materials'];
          // FC-962: moved logic checking to API
          if (
              item.response['transition_job_to_completed'] === true &&
              !isEmpty(this.objJobData['invoicing_type']) &&
              arValidInvoicingTypes.includes(this.objJobData['invoicing_type'])
          ) {
            // We tell the app component to open the confirmation.
            // FC-962: show message prior to the invoicing type
            this.notifService.sendConfirmation(`update_job_status_${this.objJobData['invoicing_type']}`)
              .subscribe(confirmation => {
                // If the user confirm
                if (confirmation.answer) {
                  let arRequestData = { status: 'completed' };
                  this.recordService.saveRecord('jobs', arRequestData, this.strRecordId).first().subscribe(
                    data => {
                      this.fetchList('reload');
                      // Pass the status widget value, so we can update the record.
                      this.getRecordViewData();
                      // Show notification that activity has been created.
                      this.notifService.notifySuccess('success_job_update');
                    }
                  );
                } else {
                  this.getRecordViewData();
                }
                this.notifyUnusedMaterial(item.invoice, item.response);
              });
          } else {
            this.notifyUnusedMaterial(item.invoice, item.response);
          }
        }
      });
  }

  /**
   * Opens the selected invoice from the list.
   * @param objInvoices - the invoice data.
   */
  openInvoice(objInvoices) {
      // Object to be passed in the dialog.
      let objData = {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        padding: '1%',
        disableClose: true,
        // Data to be passed on
        data: {
          record_id : this.strRecordId,
          invoice: [],
          module: this.strModule,
          view_type : 'edit',
          customer_invoice_id: objInvoices['id']
        }
      };

      // Instantiate dialog.
      let tabDialogRef = this.dialog.open(EditInvoiceComponent, objData);

      // Reload invoice list once the dialog is closed.
      tabDialogRef.afterClosed().first().subscribe(item => {
        // Only when the user hits save will we reload the list.
        if (item != undefined && item.action == 'save') {
          // Show notification that activity has been created.
          this.notifService.notifySuccess('header_notification.success_update');
          this.filter();
          this.getRecordViewData();
          // Refresh relate panel time and materials
          this.refreshRelatePanel.emit({ widget: 'materials' });
          this.refreshRelatePanel.emit({ widget: 'time-entries' });
          this.notifyUnusedMaterial(item.invoice, item.response);
        }
      });
  }
  /**
   * Fetch with filter
   */
  filter() {
    let objFilter = {};

    if (this.strSelectStatus != '' && this.strSelectStatus != null) {
      objFilter['status'] = this.strSelectStatus;
    }

    this.fetchList('default', objFilter);
  }

  /**
   * Fetch the list of invoice.
   * @param strPage - what page is currently to be viewed.
   */
  fetchList(strPage, objFilter = {}) {

    let objPagination = this.listService.beforeFetching(strPage);
    let strModuleId = RelateIds[this.strModule];
    objFilter[strModuleId] = this.strRecordId;
    this.bPageLoaded = false;
    // Get the list from API.
    this.listService.fetchDataAdvanceSearch(
      objPagination['objPage'],
      'customer_invoices',
      objFilter,
      { 'id': 'created_at', 'sort': 'desc' },
    ).subscribe(response => {
      this.arInvoices = response['data'];
      this.listService.afterFetching(response, strPage);
      this.bPageLoaded = true;
    });
  }
  /**
   *
   * @param strId - record id
   */
  deleteInvoice(strId) {
    //We tell the app component to open the confirmation.
    this.notifService.sendConfirmation()
      .subscribe(
        confirmation => {
          //If the user confirmed, delete the record by field
          if (confirmation.answer) {
            this.recordService.deleteRecord('customer_invoices', strId).first().subscribe(
              data => {
                if (data) {
                  this.fetchList('reload');
                  this.getRecordViewData();
                  // Refresh relate panel time and materials
                  this.refreshRelatePanel.emit({ widget: 'materials' });
                  this.refreshRelatePanel.emit({ widget: 'time-entries' });
                  this.notifService.notifySuccess('record_delete_success');
                } else {
                  this.notifService.notifyError('record_delete_failed');
                }
              });
          }
        }
      );
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate, arCustomConfig = []) {
    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('ll');
  }
  /**
   * Get record view data
   */
  getRecordViewData() {
    // Pass the status widget value, so we can update the record.
    this.viewService.updateStatusWidget({
      type: 'update',
      data: { module: this.objStatusWidgetRecordDetails['module'], id: this.objStatusWidgetRecordDetails['id']},
      instance_id: this.numInstanceId,
      customer_invoices: true
      });
  }

  /**
   * Get record module config field
   */
  getModuleConfig(strModule) {
    this.recordService.getRecordConfig(strModule).first().subscribe(
      result => {
        this.objModuleConfig = result;
      }
    );
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arInvoices = []; // clear list
    this.filter();
  }

  /**
   * Refresh widget
   */
  refreshWidget(){
    this.fetchList('reload');
    this.getRecordViewData();
    // Refresh relate panel time and materials
    this.refreshRelatePanel.emit({ widget: 'materials' });
    this.refreshRelatePanel.emit({ widget: 'time-entries' });
  }

  ngOnChanges() {
    this.onRefresh();
  }

  /**
   * Notify the user if there is still unused material
   */
  notifyUnusedMaterial(customerInvoice, saveResponse) {
    let materialIds = customerInvoice['line_items'].map( lineItem => {
      return lineItem.relate_id
    });
    this.recordService.getRecordRelateJoined('materials', false, { 'items.is_inventoried': true }, false, false, {
      'materials.id': materialIds,
      'materials.status': ['pending', 'allocated'],
    }).subscribe( response => {
      if (response.length) {
        this.notifService.sendConfirmation('do_you_want_to_redirect_in_materials_to_allocate')
          .pipe(
            filter(confirmation => confirmation.answer == true)
          )
          .subscribe(() => {
            let currentUrl = this.recordService.getParentData();
            if (currentUrl.module === 'jobs') {
              this.dialog.open(StockAllocationComponent, {
                width: '80%',
                height: 'auto',
                data: {
                  job_data :  this.objJobData,
                  record_id : this.objJobData['id'],
                  material_ids: customerInvoice['line_items'].map( lineItem => {
                    return lineItem.relate_id
                  })
                },
                panelClass: 'custom-dialog'
              });
            }
        });
      }
    });
  }

  /**
   * confirmation before opening the customer invoice dialog
   */
  openCreateInvoiceDialog(): void {
    this.materialsService.getMaterialsWithoutSupplierInvoice(this.strRecordId).subscribe(response => {
      if (filled(response)) {
        this.notifService.sendConfirmation('unallocated_line_item_to_supplier_invoice')
          .pipe(
            filter(confirmation => confirmation.answer == true)
          )
          .subscribe(() => this.createInvoice());
      } else {
        this.createInvoice();
      }
    });
  }
}
