import { LooseObject } from '../../../../../objects/loose-object';
import { filled } from '../../../../../shared/utils/common';
import { JobMetadata, JobMetadataC } from './job-metadata';
import { OpportunityMetadata, OpportunityMetadataC } from './opportunity-metadata';
import { RelateData } from './relate-data';
import { Users } from '../../../../../objects/users';

export interface TaskMetadata extends RelateData {

  id: string;

  name: string;

  department: RelateData;

  job?: JobMetadata;

  opportunity?: OpportunityMetadata;

  activity_date: string;

  assigned_user: RelateData | null;

  description: string;

  due_date: string;

  estimated_duration: number;

  priority: string;

  status: string;

  parent_id?: string | null;

  schedule_assigned_user?: Users|null;

  /**
   * Indicates the task can be allowed for viewing or not.
   *
   * Note: This is only available in the calendar task
   *
   * @type {boolean}
   */
  viewable?: boolean;

  /**
   * Indicates the task can be rescheduled to other user or update its schedule
   *
   * Note: This is only available in the calendar task
   *
   * @type {boolean}
   */
  reschedulable?: boolean;
}

export class TaskMetadataC implements RelateData {

  id: string;

  name: string;

  department: RelateData | null;

  job?: JobMetadata;

  opportunity?: OpportunityMetadata;

  activity_date: string;

  assigned_user: RelateData | null;

  description: string;

  due_date: string | null;

  estimated_duration: number;

  priority: string;

  status: string;

  parent_id?: string | null;

  metadata_type: string = 'task';

  task_progress: string;

  schedule_assigned_user: Users|null;

  /**
   * Indicates the task can be allowed for viewing or not.
   *
   * Note: This is only available in the calendar task
   *
   * @type {boolean}
   */
  viewable?: boolean;

  /**
   * Indicates the task can be rescheduled to other user or update its schedule
   *
   * Note: This is only available in the calendar task
   *
   * @type {boolean}
   */
  reschedulable?: boolean;

  task?: LooseObject = {};

  customer?: LooseObject = {};

  site?: LooseObject = {};

  activity_name: string;

  is_deleted: boolean;

  constructor(properties: TaskMetadataC) {

    this.assigned_user = properties.assigned_user || null;
    this.department = properties.department || null;
    this.description = properties.description;
    this.due_date = properties.due_date || null;
    this.estimated_duration = parseFloat(properties.estimated_duration as any) || 0;
    this.id = properties.id;
    this.job = properties.job ? new JobMetadataC(properties.job) : null;
    this.opportunity = properties.opportunity ? new OpportunityMetadataC(properties.opportunity) : null;
    this.name = properties.name;
    this.priority = properties.priority;
    this.status = properties.status;
    this.parent_id = properties.parent_id || null;
    this.task_progress = properties.task_progress || null;
    this.activity_name = properties.activity_name;
    this.is_deleted = properties.is_deleted || false;
    this.schedule_assigned_user = properties.schedule_assigned_user || null;

    this.viewable = false;
    this.reschedulable = properties.reschedulable;

    if (filled(this.job)) {
      this.site = properties.job.site
      this.customer = properties.job.customer
    }
    if (filled(this.opportunity)) {
      this.site = properties.opportunity.site
      this.customer = properties.opportunity.customer
    }

    this.task = JSON.parse(JSON.stringify(properties));
  }

}