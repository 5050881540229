import { Component, OnInit, Inject, Input, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { concat } from 'rxjs/observable/concat';
import { Observable } from 'rxjs/Observable';
import { Select } from '../../../../objects/select';
import { Subject, throwError } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { FormGroup, FormControl } from '@angular/forms';
import { RelateIds } from '../../../../lists/relate-ids';
import { NotificationService } from '../../../../services/notification.service';
import { RecordService } from '../../../../services/record.service';
import { HttpEvent } from '@angular/common/http/http';
import { ActivityType } from '../../../../lists/activity'
import { FileService } from '../../../../services/file/file.service';
import { ArrService } from '../../../../services/helpers/arr.service';
import { toFormattedNumber } from '../../../../shared/utils/numbers';
import { toNumber, toString } from 'lodash-es';

@Component({
  selector: 'app-add-material',
  templateUrl: './add-material.component.html',
  styleUrls: ['./add-material.component.scss']
})
export class AddMaterialComponent implements OnInit {
  public strModule: string;
  public productType: string = 'product_catalog';
  public bSubmitted: boolean = false;
  public bShowLoader: boolean = false;
  public isTouched: boolean = false;
  public strRelatedId: any = RelateIds;
  public objJobData: any = this.data.job_data;
  public strViewType: string = this.data.view_type;
  public arRecordDetails: any = [];
  public arNgSelectFieldKey: any = [];
  public arMaterialFieldKey: any = [];
  public objEditDetails = {};
  public objInitialRawData = {};
  public isFileDropped: boolean = false;
  public isFileUploaded: boolean = true;
  public bDialogLoaded: boolean = false;
  public arFiles : any = [];
  public strImageUrl: string = '';
  public arMaterialFile : any = [];

  public MaterialForm: FormGroup;
  public getDateDiff: any = [];

  public unitCost: any = 0.000;
  public unitPrice: any = 0.000;
  public markUp: any = 0.000;
  public totalCost: any = 0.0000;
  public totalPrice: any = 0.0000;
  public objMaterialFields = {
    'markup': {
      key: 'markup',
      label: 'markup',
      controlType: 'decimal',
      default_value: '',
      readonly: false
    },
    'quantity': {
      key: 'quantity',
      label: 'quantity',
      controlType: 'number',
      default_value: '',
      readonly: false
    },
    'unit_cost': {
      key: 'unit_cost',
      label: 'unit_cost',
      controlType: 'currency',
      default_value: '',
      readonly: false,
      decimal_places: 4,
    },
    'unit_price': {
      key: 'unit_price',
      label: 'unit_price',
      controlType: 'currency',
      default_value: '',
      readonly: false,
      decimal_places: 4,
    },
    'total_cost': {
      key: 'total_cost',
      label: 'total_cost',
      controlType: 'currency',
      default_value: '',
      readonly: true
    },
    'total_price': {
      key: 'total_price',
      label: 'total_price',
      controlType: 'currency',
      default_value: false,
      readonly: true
    },
    'exclude_from_invoice': {
      key: 'exclude_from_invoice',
      label: 'exclude_from_invoice',
      controlType: 'checkbox',
      default_value: '',
      readonly: false
    },
    'notes': {
      key: 'notes',
      label: 'notes',
      controlType: 'textarea',
      rows: 5,
      default_value: '',
    }
  };

  /**
   * Custom ng-select fields.
   */
  public arNgSelectFields =
    { items : {
        obv: new Observable<Select[]>(),
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'product',
        name: 'item_id',
        module: 'items',
        value: null,
        filter: {
          labor: false,
          active: true
        },
      },
      activities: {
        obv: new Observable<Select[]>(),
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'select_task',
        name: 'activity_id',
        module: 'activities',
        value: null,
        filter: {
          'activities.job_id': this.data.record_id,
          activity_type: ActivityType['task']
        }
      }
    };

  public bFormDirty: boolean = false;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  constructor(
    public dialogRef: MatDialogRef<AddMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifService: NotificationService,
    private recordService: RecordService,
    private fileService: FileService,
    private arrService: ArrService,
    ) {
      let MaterialForm = {
        product: new FormControl(null),
        image: new FormControl([null]),
        type: new FormControl(null),
        quantity: new FormControl(1),
        unit_cost: new FormControl('0.00'),
        total_cost: new FormControl('0.00'),
        unit_price: new FormControl('0.00'),
        total_price: new FormControl('0.00'),
        markup: new FormControl('0.00'),
        exclude_from_invoice: new FormControl(false),
        notes: new FormControl(null)
      };
      // Initialize form control fields.
      this.arNgSelectFieldKey = Object.keys(this.arNgSelectFields);
      this.arMaterialFieldKey = Object.keys(this.objMaterialFields);
      this.MaterialForm = new FormGroup(MaterialForm);
      if (this.data['material_id'] != undefined && this.data['material_id'] != '' && this.data['material_id'] != null) {
        let arFilter = { 'materials.id' : this.data['material_id'] };
        this.recordService.getRecordRelateJoined('materials', false, arFilter).subscribe( response => {
          // Get updated record data of currently updating.
          this.data['material'] = (response != null && response.length > 0) ? response[0] : [];
          this.setMaterial(data);
          this.bDialogLoaded = true;
          this.onChanges();
        });
      } else {
        this.setMaterial(data);
        this.bDialogLoaded = true;
        this.onChanges();
      }

      this.dialogRef.backdropClick().subscribe(_ => {
        this.cancelDialog();
      });
    }

  ngOnInit() {
  }

  /**
   * detect changes on materials form
   */
  onChanges(): void {
    // Calculate the total cost and total price if quantity is change and validate quantity if less than 1
    this.MaterialForm.get('quantity').valueChanges.subscribe(val => {
      if (val < 1) {
        this.notifService.notifyWarning('quantity_warning');
        this.MaterialForm.patchValue({
          quantity: 1
        });
      } else if (val > 0) {
        this.MaterialForm.patchValue({
          total_cost: this.computeTotal('unit_cost', val),
          total_price: this.computeTotal('unit_price', val),
        });
      }
    });

    this.MaterialForm.get('unit_cost').valueChanges.subscribe( () =>
      this.MaterialForm.patchValue({
        total_cost: this.computeTotal('unit_cost', this.MaterialForm.value.quantity),
        total_price: this.computeTotal('unit_price', this.MaterialForm.value.quantity),
      })
    );

    this.MaterialForm.get('unit_price').valueChanges.subscribe( () =>
      this.MaterialForm.patchValue({
        total_cost: this.computeTotal('unit_cost', this.MaterialForm.value.quantity),
        total_price: this.computeTotal('unit_price', this.MaterialForm.value.quantity),
      })
    );

    this.MaterialForm.controls['image'].valueChanges
      .distinctUntilChanged()
      .subscribe(changes => this.onFileChange(changes));
  }

  // onChange of relate field
  relateChange(relateValue, relateFieldKey) {
    // if relate field is items calculate and append the price of items
    if (relateValue) {
      let strPricebookUnitPrice = this.arrService.keyFallsBackTo(relateValue, 'pricebook_unit_price');

      if (relateFieldKey == 'items') {
        let strCustomerId = this.arrService.keyFallsBackTo(this.objJobData, 'customer_id');

        if (strCustomerId !== null) {
          relateValue['unit_price'] = (strPricebookUnitPrice) ? strPricebookUnitPrice : relateValue['unit_price'];
        }

        this.unitCost = parseFloat(relateValue['unit_cost']);
        this.unitPrice = parseFloat(relateValue['unit_price']);
        this.markUp = (parseFloat(this.unitCost) !== 0) ? toString(toFormattedNumber((((this.unitPrice - this.unitCost) / this.unitCost) * 100))) : '0.000';

        this.MaterialForm.patchValue({
          unit_cost: toString(toFormattedNumber(this.unitCost, {
            currency: true,
            maxDecimalPlaces: 4,
          })),
          unit_price: toString(toFormattedNumber(this.unitPrice, {
            currency: true,
            maxDecimalPlaces: 4,
          })),
          markup: this.markUp,
          total_cost: toString(toFormattedNumber(this.unitCost * this.MaterialForm.value.quantity, {
            currency: true,
          })),
          total_price: toString(toFormattedNumber(this.unitPrice * this.MaterialForm.value.quantity, {
            currency: true,
          })),
        });
      }
    }
  }

  /**
   * Close the current dialog.
   */
  cancelDialog() {
    if(this.checkFormGroupDirty()) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   *
   * @param data - Set data of each field
   *
   */
  setMaterial(data) {

    if (data['material'] != undefined) {
      this.unitCost = parseFloat(data['material']['unit_cost']);
      this.unitPrice = parseFloat(data['material']['unit_price']);
      this.markUp = parseFloat(data['material']['markup']);
      this.totalCost = parseFloat(data['material']['total_cost']);
      this.totalPrice = parseFloat(data['material']['total_price']);

      // Set the values of the form control.
      this.MaterialForm.patchValue({
        unit_cost: data['material']['unit_cost'],
        unit_price: data['material']['unit_price'],
        quantity: data['material']['quantity'],
        markup: data['material']['markup'],
        exclude_from_invoice: data['material']['exclude_from_invoice'],
        notes: data['material']['notes'],
        product: data['material']['product'],
        image: data['material']['material_image'] ? [data['material']['material_image']] : [],
        total_cost: data['material']['total_cost'],
        total_price: data['material']['total_price'],
      });

      this.productType = data['material']['type'];
      // Set the value of the contact id manually.
      this.arNgSelectFields['items']['value'] = (data['material']['item_id'] != undefined && data['material']['item_id'] != null && data['material']['item_id'] != '') ? { id: data['material']['item_id'], text: data['material']['name'] } : {};
      this.arNgSelectFields['activities']['value'] = (data['material']['activity_id'] != undefined && data['material']['activity_id'] != null && data['material']['activity_id'] != '') ? { id: data['material']['activity_id'], text: data['material']['activity_name'] } : {};
      this.arFiles['name'] = (data['material']['material_image']) ? data['material']['material_image']['name'] : '';
      this.arMaterialFile = data['material']['material_image'];
      this.strImageUrl = data['material']['material_image_url'];
    }
  }

  /**
   * Initialize related field
   * @param fieldKey - field key of selected field
   */
  initRelateField(fieldKey) {
    this.arNgSelectFields[fieldKey]['loader'] = true
    // Initialize a variable for filters.
    let arFilter: any = false;
    // If the field has filter, place them in the arFilter.
    if (this.arNgSelectFields[fieldKey]['filter'] != undefined) {
      arFilter = this.arNgSelectFields[fieldKey]['filter'];
    }
    if (fieldKey == 'items') {
      let strCustomerId = (this.objJobData['customer_id'] != undefined && this.objJobData['customer_id'] != '' && this.objJobData['customer_id'] != null) ? this.objJobData['customer_id'] : null;
      this.recordService.getProductRecordData('', arFilter, strCustomerId).subscribe(response => {
        this.arNgSelectFields[fieldKey]['loader'] = false;
        // Initialize the list observable.
        this.arNgSelectFields[fieldKey]['obv'] = concat(
          of(response),
          this.arNgSelectFields[fieldKey]['typehead'].pipe(
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => this.arNgSelectFields[fieldKey]['loader'] = true),
            switchMap(term => this.recordService.getProductRecordData(term, arFilter, strCustomerId).pipe(
              tap(() => {
                this.arNgSelectFields[fieldKey]['loader'] = false;
              })
            ))
          )
        )
      });
    } else {
      this.recordService.getRecordRelate(fieldKey, false, false, false, arFilter).subscribe( response => {
        this.arNgSelectFields[fieldKey]['loader'] = false;
        // Initialize the list observable.
        this.arNgSelectFields[fieldKey]['obv'] = concat(
          of(response),
          this.arNgSelectFields[fieldKey]['typehead'].pipe(
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => this.arNgSelectFields[fieldKey]['loader'] = true),
            switchMap(term => this.recordService.getRecordRelate(this.arNgSelectFields[fieldKey]['module'], term, '', false, arFilter).pipe(
              tap(() => {
                this.arNgSelectFields[fieldKey]['loader'] = false;
              })
            ))
          )
        )
      });
    }
  }

  // Create time entry data
  saveMaterial() {
      this.bSubmitted = true;
      this.isTouched = true;
      this.bShowLoader = true;
      let arErrors = [];

      // Validation of item relate fields - validate if type is product catalog
      if((this.arNgSelectFields['items']['value'] === undefined || this.arNgSelectFields['items']['value'] === null) && this.productType == 'product_catalog') {
          arErrors.push(true);
      }
      // Validation of product fields - validate only if type is once off purchase
      if(this.MaterialForm.value.product == '' && this.productType == 'once_off_purchase') {
        arErrors.push(true);
      }

      // Validation of quantity - notify when quantity is less than 1
      if (this.MaterialForm.value.quantity < 1) {
        this.notifService.notifyWarning('quantity_warning');
        arErrors.push(true);
      }

      if (arErrors.length < 1) {
        // Loop through the relate field and remove all observables.
        // Note: If we dont remove observables, a circular object error will be thrown.
        // Remove also the loader since we just use if for viewing purposes.
        this.arNgSelectFieldKey.forEach(key => {
          delete this.arNgSelectFields[key]['obv'];
          delete this.arNgSelectFields[key]['typehead'];
          delete this.arNgSelectFields[key]['loader'];
        });

        // Get the raw value of the form.
        let objMaterial = this.MaterialForm.getRawValue();
        this.arNgSelectFieldKey.forEach(key => {
          let fieldId = this.arNgSelectFields[key]['name'];

          // FC-947: skips item_id when current product_type is once_off_purchase
          // and should not included in the request since item_id should be a product input rather than dropdown
          if (fieldId == 'item_id' && this.productType == 'once_off_purchase') {
            return;
          }

          objMaterial[fieldId] = (this.arNgSelectFields[key]['value'] != null && this.arNgSelectFields[key]['value'] != '') ? this.arNgSelectFields[key]['value']['id'] : null;
        });

        // Additional request data not in form raw value
        objMaterial['job_id'] = this.data.record_id;
        objMaterial['image'] = this.arMaterialFile;
        objMaterial['type'] = this.productType;

        // FC-947: fixes markup data when it is left on saving as blank data
        if (isNaN(objMaterial['markup'])) {
          objMaterial['markup'] = 0.00;
        }

        // Check product type then delete to avoid multiple product
        if (this.productType == 'product_catalog') {
          objMaterial['product'] = '';
        } else if (this.productType == 'once_off_purchase') {
          objMaterial['item_id'] = null;
        }

        // If there is a time entry data passed (means its an update and not add).
          if (this.data['material'] != undefined) {
            // Submit to API together with the ID for updating.
            this.recordService.saveRecord('materials', objMaterial, this.data['material']['id']).subscribe(response => {
              let strResponse: any = response;
              this.bSubmitted = false;
              this.bShowLoader = false;

              if (strResponse['status'] == 202) {
                if (strResponse.body.error !== undefined) {
                  this.notifService.promptError(strResponse.body.error);
                } else {
                  this.notifService.sendNotification('header_error', 'header_notification.generic_fail', 'warning');
                  this.dialogRef.close();
                }
              } else {
                this.dialogRef.close('save');
              }
            })
          } else {
            // Submit to API for adding.
              this.recordService.saveRecord('materials', objMaterial, '').subscribe(response => {
                let strResponse: any = response;
                this.bSubmitted = false;
                this.bShowLoader = false;

                if (strResponse['status'] == 202) {
                  if (strResponse.body.error !== undefined) {
                    this.notifService.promptError(strResponse.body.error);
                  } else {
                    this.notifService.sendNotification('header_error', 'header_notification.generic_fail', 'warning');
                    this.dialogRef.close();
                  }
                } else {
                  this.dialogRef.close('save');
                }
              })
          }
      } else {
        this.bSubmitted = false;
        this.bShowLoader = false;
        this.notifService.sendNotification('not_allowed', 'required_notice', 'danger');
      }
  }

  onFileChange(file) {
    // if file size is less than 30mb
    let strFileType = file.type;
    if (file.size / 1024 / 1024 < 30 && strFileType.includes('image')) {
      this.fileService.upload(file).pipe(
        concatMap(tempfile => {
          if (tempfile.url === null || tempfile.filename === null) {
            return throwError(
              'An error occured while uploading the file. Please try again'
            );
          }

          let objFile = this.fileService.objFile;
          this.arFiles = {
            'name': objFile.name,
            'size': objFile.size / 1024,
            'type': objFile.type,
            'upload_name': tempfile['filename']
          };

          return this.fileService.getObjectSignedUrl(tempfile.filename);
        })
      )
        .subscribe(response => {
          this.strImageUrl = response['url'];
          this.isFileDropped = false;
          this.isFileUploaded = true;
          this.arMaterialFile = this.arFiles;
        });
    }
  }
  // when product type is change and once_off_purchase is selected set unit cost and unit price to 0
  typeChange() {
    if (this.productType == 'once_off_purchase') {
      this.unitCost = 0.00;
      this.unitPrice = 0.00;
      this.MaterialForm.patchValue({
        unit_cost: 0.00,
        unit_price: 0.00,
        total_cost: 0.00,
        total_price: 0.00
      });
    }
  }


  // Add leading zeros
  pad(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  /**
   * Check if the form is changed
   *
   * @returns {boolean}
   */
  checkFormGroupDirty(): boolean {
    return this.MaterialForm.dirty || this.bFormDirty;
  }

  /**
   * Mark as dirty
   *
   * @returns {void}
   */
  markAsDirty(): void {
    this.bFormDirty = true;
  }

  protected computeTotal(field: string, quantity: number)
  {
    return toString(
      toFormattedNumber(
        parseFloat(this.MaterialForm.value[field]) * quantity, { currency: true }
      )
    );
  }
}
