import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { toNumber } from 'lodash-es';
import { blank, filled } from '../../../../utils/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fieldmagic-number-input',
  template: `
    <ng-container *ngIf="! standalone; else input;">
      <div class="form-group">
        <div class="d-flex d-flex-gap">
          <fieldmagic-text
            purpose="input"
            [content]="label"
            [withRequiredMarker]="withRequiredMarker"
          >
          </fieldmagic-text>

          <fieldmagic-icon
            *ngIf="tooltip | filled"
            icon="info-circle"
            [tooltip]="tooltip"
          >
          </fieldmagic-icon>
        </div>

        <ng-container *ngTemplateOutlet="input"></ng-container>
      </div>
    </ng-container>

    <ng-template #input>
      <input
        [disabled]="isDisabled$ | async"
        [(ngModel)]="value"
        type="number"
        step="1"
        [min]="min"
        [max]="max"
        class="form-control input-height-40 font-size-12"
        [class.fieldmagic-input-has-error]="errors | filled"
        [attr.placeholder]="placeholder"
        (change)="onChange($event.target.value)"
      />

      <fieldmagic-input-errors
        *ngIf="errors | filled"
        [errors]="errors"
      >
      </fieldmagic-input-errors>
    </ng-template>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent implements ControlValueAccessor {
  @Input() standalone: boolean = false;

  @Input() label: string;

  @Input() placeholder?: string;

  @Input() withRequiredMarker: boolean = false;

  @Input() errors: string[] = [];

  @Input() tooltip?: string;

  @Input() min?: number;

  @Input() max?: number;

  @Output('change')
  $onChange = new EventEmitter<FieldmagicNumberInputComponentOnChangeEvent>();

  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  _onChangeFn: (value?: number) => void;
  _onToucheFn: () => void;

  value?: number;

  writeValue(value: any) {
    if (blank(value)) {
      this.value = undefined;
    }

    value = toNumber(value);

    if (isNaN(value)) {
      value = 0;
    }

    this.value = Math.floor(value);
  }

  registerOnChange(fn: any): void {
    if (blank(fn)) {
      return;
    }

    this._onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    if (blank(fn)) {
      return;
    }

    this._onToucheFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled$.next(isDisabled);
  }

  onChange(value: string): void {
    let parsed: number | null;

    if (blank(value)) {
      parsed = null;
    } else {
      parsed = toNumber(value);
    }

    if (isNaN(parsed)) {
      value = null;
    }

    this.value = parsed;
    this.$onChange.next(parsed);

    if (filled(this._onChangeFn)) {
      this._onChangeFn(parsed);
    }
  }
}

export type FieldmagicNumberInputComponentOnChangeEvent = number | null;
