import { TranslateService } from '@ngx-translate/core';
import { JobMetadataC } from '../../../module/calendar/full-calendar/classes/metadata/job-metadata';
import { OpportunityMetadataC } from '../../../module/calendar/full-calendar/classes/metadata/opportunity-metadata';
import { TaskMetadataC } from '../../../module/calendar/full-calendar/classes/metadata/task-metadata';
import { get } from 'lodash-es';
import { filled } from '../../../shared/utils/common';
import { LooseObject } from '../../../objects/loose-object';
import { UUID } from 'angular2-uuid';

export class SchedulerItem {

  task_name: string;
  task_priority: string;
  task_description: string;
  due_date: string;
  status: string;
  job_number: string;
  job_address: string;
  customer: string;
  department: string;
  opportunity_number: string;
  address: string;
  expected_close_date: string;
  job_due_date: string;

  metadata: TaskMetadataC | JobMetadataC | OpportunityMetadataC;
  module: string;

  // scheduler id if null, it means that it is new id
  id?: string;

  // for every plotted event id
  scheduler_id: string

  is_deleted: boolean

  get hasCustomer(): boolean {
    return filled(get(this, 'customer'));
  }

  get hasDepartment(): boolean {
    return filled(get(this, 'department'));
  }

  get hasJobAddress(): boolean {
    return filled(get(this, 'job_address'));
  }

  get hasQuoteAddress(): boolean {
    return filled(get(this, 'address'));
  }

  get hasDueDate(): boolean {
    return filled(get(this, 'job_due_date'));
  }

  get hasJobTasks(): boolean {
    return filled(get(this.metadata, 'tasks'));
  }

  constructor(properties: TaskMetadataC | JobMetadataC | OpportunityMetadataC, translate?: TranslateService, scheduler_tasks_id: string = null) {
    this.id = scheduler_tasks_id;
    this.scheduler_id = UUID.UUID();
    this.is_deleted = properties.is_deleted;
    this.metadata = properties;

    if (properties instanceof TaskMetadataC) {

      this.module = 'tasks';
      if (translate) {

        this.task_priority = translate.instant(get(properties, 'priority', ' ') || ' ');
        this.status = translate.instant(get(properties, 'status', ' ') || ' ');
      } else {

        this.task_priority = properties.priority;
        this.status = properties.status;
      }

      this.task_name = properties.activity_name;
      this.task_description = properties.description;
      this.due_date = properties.due_date || '--';

      if (properties.job) {
        this.job_number = properties.job.job_number;
        this.job_address = properties.job.full_address;
        this.job_due_date = properties.job.due_date || '--';
        if (properties.job.customer) {

          this.customer = properties.job.customer.name;
        }
      }

      if (properties.opportunity) {
        this.opportunity_number = properties.opportunity.opportunity_number;
        this.address = properties.opportunity.full_address;
        this.expected_close_date = properties.opportunity.forecast_close_date;
        if (properties.opportunity.customer) {

          this.customer = properties.opportunity.customer.name;
        }
      }
    }

    if (properties instanceof JobMetadataC) {

      this.module = 'jobs';
      this.job_number = properties.job_number;
      this.job_address = properties.full_address;
      this.job_due_date = properties.due_date;
      if (properties.customer) {

        this.customer = properties.customer.name;
      }
    }

    if (properties instanceof OpportunityMetadataC) {

      this.module = 'opportunities';
      this.opportunity_number = properties.opportunity_number;
      this.address = properties.full_address;
      this.expected_close_date = properties.forecast_close_date;
      if (properties.customer) {

        this.customer = properties.customer.name;
      }

    }

    if (properties.department) {
      this.department = properties.department.name;
    }

    properties[this.module] = properties;
    this.metadata = properties
  }

  /**
   * generate new scheduler id
   */
  generateSchedulerId(): void {
    this.scheduler_id = UUID.UUID();
  }

  /**
   * set the current scheduler id
   *
   * @param strSchedulerId
   */
  setSchedulerId(strSchedulerId: UUID): void {
    this.scheduler_id = strSchedulerId as string;
  }
}