import { Component, OnInit, Inject, ViewChild, ElementRef, EventEmitter, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { isEmpty } from 'lodash-es';
import { RelateIds } from '../../../../../lists/relate-ids';
import { FileService } from '../../../../../services/file/file.service';
import { WidgetService } from '../../../../../services/widget.service';
import { NotificationService } from '../../../../../services/notification.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {


  public bShowLoader: any = {
    'submit': false
  };

  public is_Empty = isEmpty;
  public bSubmitted: boolean = false;
  public arFiles : any = [];
  public strRelatedId: any = RelateIds;
  public arRecordDetails: any = [];
  public arRequestData: any = [];
  public isFileDropped: boolean = false;
  public isFileUploaded: boolean = false;
  public strDescription: string = '';
  public arFilesList: any = [];
  public bEdited: boolean = false;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  constructor(
    public dialogRef: MatDialogRef<FilesComponent>,
    private fileService: FileService,
    public widgetService: WidgetService,
    private notifService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {    // Store record details
    this.arRecordDetails = data;
  }

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

  onFileChange(objData) {
    let reader = new FileReader();

    // If file is valid
    if (objData.target.files && objData.target.files.length) {
      this.isFileDropped = true;

      const [file] = objData.target.files;
      const sanitizedFile: File = this.fileService.sanitizeFilename(file);

      reader.readAsDataURL(sanitizedFile);

      // check if the uploaded file is valid
      if (!this.fileService.hasValidUploadFile(sanitizedFile)) {
        this.notifService.notifyWarning('invalid_file_type');
        this.isFileDropped = false;
        this.isFileUploaded = true;
        this.arFiles = {};

        return;
      }

      // if file size is less than 30mb
      if (sanitizedFile.size/1024/1024 < 30) {
        reader.onload = () => {
          this.fileService.upload(sanitizedFile).subscribe((response) => {
              let objFile = this.fileService.objFile;
              this.isFileDropped = false;
              this.isFileUploaded = true;
              this.arFiles = {
                  'name': objFile.name,
                  'size': objFile.size / 1024,
                  'type': objFile.type,
                  'upload_name' : response['filename']
              };
          });
        };
      }
    }
  }

  /**
   * For Cancel button
   */
  cancelDialog(): void {
    if (this.bEdited) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
      .filter(confirmatation => (confirmatation.answer === true))
      .subscribe(() => {
        this.dialogRef.close({ status: 'cancel' });
      });
    } else {
      this.dialogRef.close({ status: 'cancel' });
    }
  }

   /*
   * Submit the form's data to the Activities table
   * and send the email id not draft
   */
  onSubmit() {
    // Set submitted to true
    this.bSubmitted = true;

    // Check if file is set and is uploaded
    if (this.isFileUploaded == false) {
      this.notifService.sendNotification('upload_failed', 'file_invalid', 'warning');
    // Check character count of description
    } else if (this.strDescription.length > 1024) {
      this.notifService.sendNotification('upload_failed', 'max_character', 'warning');
    } else {
      // Setup request data
      this.arRequestData = {
        module_id: this.arRecordDetails.record_id,
        module_field: this.strRelatedId[this.arRecordDetails.module],
        description: this.strDescription,
        file: this.arFiles,
        module: this.arRecordDetails.module,
      };

      this.bSubmitted = false;
      this.dialogRef.close({ status: 'save', request: this.arRequestData });
    }
  }

  /**
   * Mark as edited
   */
  markAsEdited(): void {
    this.bEdited = true;
  }

}
