import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from '../../../../services/dialog.service';
import { filled } from '../../../utils/common';

@Component({
  selector: 'fieldmagic-dialog-close-button',
  template: `
    <fieldmagic-primary-button
      label="Close"
      icon="times"
      variant="error"
      (click)="onClick()"
      [disabled]="disabled"
    >
    </fieldmagic-primary-button>
  `,
  providers: [
    DialogService,
  ],
})
export class DialogCloseButtonComponent<C = any> {
  @Input() disabled: boolean = false;

  @Input() instance?: C;

  @Output('click') $onClick = new EventEmitter<void>();

  constructor(
    private readonly _dialog: DialogService,
  ) {}

  onClick(): void {
    if (filled(this.instance)) {
      this._dialog.close({
        instance: this.instance,
      });
    } else {
      this.$onClick.emit();
    }
  }
}