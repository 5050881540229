import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

// Modules
import { UsersRoutingModule } from './users-routing.module';
import { ViewModule } from '../../shared/modules/view.module';
import { EditModule } from '../../shared/modules/edit.module';
import { InformationModule } from '../../shared/modules/information.module';
import { SharedModule } from '../../shared/modules/shared.module';

// Components
import { InvitesComponent } from './invites/invites.component';
import { UsersComponent } from './users.component';
import { ViewComponent } from './view/view.component';

// Services
import { UsersService } from '../../services/users.service';
import { ListingService } from '../../services/listing.service';
import { ViewService } from '../../services/view.service';
import { RecordService } from '../../services/record.service';
import { FormService } from '../../services/form.service';
import { ListingNewModule } from '../../shared/components/listing-new/listing-new.module';
import { ListingDialogModule } from '../../shared/components/listing-new/components/dialog/llisting-dialog.module';
import { AddUsersDialogComponent } from './components/dialog/add-users-dialog.component';
import { SharedInputModule } from "../../shared/modules/shared-input.module";

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    UsersRoutingModule,
    ViewModule,
    EditModule,
    InformationModule,
    SharedModule.forRoot(),
    ListingNewModule,
    ListingDialogModule,
    SharedInputModule,
],
  declarations:[
    InvitesComponent,
    UsersComponent,
    ViewComponent,
    AddUsersDialogComponent,
  ],
  exports: [
    EditModule,
    AddUsersDialogComponent,
  ],
  providers: [
    UsersService,
    ListingService,
    ViewService,
    RecordService,
    FormService
  ],
  entryComponents: [
    AddUsersDialogComponent,
  ],
})

export class UsersModule { }
