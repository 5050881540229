import { BehaviorSubject, Subject } from 'rxjs';
import { PaginatedResult } from '../view-models';
import { isNull, first, isEqual, merge } from 'lodash-es';
import { Injectable } from '@angular/core';
import { blank } from '../utils/common';

export { PaginatedResult, Pagination } from '../view-models';

export class PaginationStore<R extends Object, P = string> {
  readonly onNextPage$ = new BehaviorSubject<P | null>(null);

  readonly list$ = new Subject<R[] | null>();

  readonly hasNextPage$ = new BehaviorSubject<boolean>(false);

  readonly hasPreviousPage$ = new BehaviorSubject<boolean>(false);

  readonly isFirstPage$ = new BehaviorSubject<boolean>(true);

  private _pointer: number = 0;

  get hasNextPage(): boolean {
    if (isNull(this._current)) {
      return false;
    }

    return this._current.pagination.hasNextPage;
  }

  private _current: PaginatedResult<R, P> | null = null;

  private _cache: PaginatedResult<R, P>[] = [];

  setCurrent(paginated: PaginatedResult<R, P>) {
    this._setCurrent(paginated);
  }

  goToFirstPage(): void {
    if (this._cache.length < 1) {
      return;
    }

    this.hasPreviousPage$.next(false);
    this.isFirstPage$.next(true);

    this._setCurrent(
      first(this._cache), {
        is_history: true,
      }
    );

    this._pointer = 0;
    this._cache = [];
  }

  goToPreviousPage(): void {
    if (this._cache.length < 1) {
      return;
    }

    let pointer = this._pointer - 1;

    /// make sure that we are not out of range
    // and also drop all the cache
    if (pointer < 1) {
      pointer = 0;
    }

    const previous = this._cache[pointer];
    const isFirstPage = isEqual(previous, first(this._cache));

    // if we are in first page reset the cache
    // then tell the pagination links that we are in the first page which disables
    // the go to first page link
    if (isFirstPage) {
      this.isFirstPage$.next(true);
      this.hasPreviousPage$.next(false);
      this._cache = [];
    }

    this._setCurrent(previous, {
      is_history: true,
    });

    this._pointer -= 1;
  }

  goToNextPage(): void {
    const { pagination } = this._current;

    let pointer = this._pointer + 1;

    /// this guarantees that a pointer cannot be higher
    /// that the currently cached list
    if (this._cache.length < 1 || pointer > this._cache.length) {
      this.list$.next(null);
      this._cache.push(this._current);
      this.onNextPage$.next(pagination.nextPage);
      this.hasPreviousPage$.next(true);
      this.isFirstPage$.next(false);
      this._pointer += 1;

      return;
    }

    this._setCurrent(this._cache[pointer - 1], {
      is_history: true,
    });

    this.hasPreviousPage$.next(true);
    this.isFirstPage$.next(false);
    this._pointer += 1;
  }

  refresh(): void {
    this.reset();
    this.list$.next(null);
    this.onNextPage$.next(null);
  }

  reset(): void {
    this._cache = [];
    this._pointer = 0;
    this.hasNextPage$.next(false);
    this.hasPreviousPage$.next(false);
    this.isFirstPage$.next(true);
  }

  loading(): void {
    this.list$.next(null);
  }

  _setCurrent(paginated: PaginatedResult<R, P>, options: SetCurrentOptions = {}): void {
    options = merge(options, {
      is_history: false,
    });

    this._current = paginated;

    /// notify observables
    this.list$.next(paginated.items);
    this.hasNextPage$.next(paginated.pagination.hasNextPage);
  }
}

type SetCurrentOptions = {
  is_history?: boolean;
}

@Injectable()
export class HorizontalPaginationStore<R extends Object, P = any> {
  readonly onNextPage$ = new BehaviorSubject<P | null>(null);

  readonly hasNextPage$ = new BehaviorSubject<boolean>(false);

  readonly list$ = new BehaviorSubject<R[]>([]);

  readonly fetching$ = new BehaviorSubject<boolean>(false);

  private _nextPage?: P;

  setCurrent(paginated: PaginatedResult<R, P>): void {
    this.hasNextPage$.next(paginated.pagination.hasNextPage);
    this.fetching$.next(false);

    this._nextPage = paginated.pagination.nextPage;

    if (blank(paginated.items)) {
      return;
    }

    this.list$.next([
      ... this.list$.value,
      ... paginated.items,
    ]);
  }

  goToNextPage(): void {
    this.fetching$.next(true);
    this.onNextPage$.next(this._nextPage);
  }

  refresh(): void {
    this._nextPage = null;

    this.fetching$.next(true);
    this.onNextPage$.next(null);
  }
}