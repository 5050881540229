<div *ngIf="bViewLoaded" class="list-view pb-5">
  <table class="table" style="border-top: 0px">
    <!-- To control width per td -->
    <colgroup *ngIf="innerWidth > 1324 && (arContent || []).length > 0">
      <col style="width: 3%">
      <col [class.col_field]="strModule != 'leads' && strModule != 'customers'" *ngFor="let strRowDisplay of arRowDisplay"
        [ngClass]="{'status_field' : checkStatusField(strRowDisplay), 'phone_field' : strRowDisplay == 'phone'}">
      <col style="width: 7%">
    </colgroup>
    <tbody>
      <tr>
        <td class="header-table pr-rem-8" [attr.colspan]="arRowDisplay.length + 3"
          [ngClass]="{'no-pagination': arPageNumber.length <= 1}">
          <div class="container-fluid">
            <div class="row">

              <div class="col-md-12 col-lg-3">

                <!-- dropdown menu -->
                <span class="header-buttons pt-0 mt-2">
                  <!-- add button -->
                  <ng-container *ngIf="bAdd">
                    <ng-container *fcHasPermission="strModule + ':create'; else disabledCreateButton">
                      <span class="dropdown nowrap filter-button">
                        <a
                          id="btnAddRecord"
                          (click)="(bAddButton) ? goToAddLink() : ''"
                          [routerLink]=""
                          href=""
                          class="btn btn-with-icon header-button-size"
                        >
                          <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
                          <span class="filter-icon" translate>{{ strModule == 'users' ? 'invite' : 'add' }}</span>
                        </a>
                      </span>
                    </ng-container>
                    <ng-template #disabledCreateButton>
                      <span class="dropdown nowrap filter-button" matTooltip="{{ 'forbidden_action_error' | translate }}">
                        <a
                          id="btnAddRecord"
                          href="#"
                          class="btn btn-with-icon header-button-size disabled"
                        >
                          <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
                          <span class="filter-icon">{{ 'add' | translate }}</span>
                        </a>
                      </span>
                    </ng-template>
                  </ng-container>

                  <!-- export button -->
                  <span *ngIf="!bAdd && strModule === 'exports'" class="dropdown nowrap filter-button">
                    <a id="btnAddRecord" (click)="goToAddLink()" [routerLink]="" href=""  class="btn btn-with-icon header-button-size">
                      <fa-icon [icon]="['fas', 'file-download']"></fa-icon>
                      <span class="filter-icon" translate>{{ 'exports' }}</span>
                    </a>
                  </span>

                  <!-- dropdown sort -->
                  <span class="dropdown nowrap filter-button">
                    <a class="btn btn-with-icon header-button-size" href="#" role="button" id="btnSortMenu"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <fa-layers class="s14">
                        <fa-icon [icon]="['fal', 'sort']"></fa-icon>
                        <fa-icon *ngIf="arModels['order_by'].sort"
                          [icon]="['fas', (arModels['order_by'].sort == 'asc') ? 'sort-up' : 'sort-down']"
                          class="navigation-border"></fa-icon>
                      </fa-layers>
                      <span id="btnSort" class="filter-icon">{{ 'sort' | translate }}</span>
                    </a>
                    <div *ngIf="arSortOption" class="dropdown-menu dropdown-content center-align sort-dropdown"
                      aria-labelledby="btnSortMenu">
                      <div *ngFor="let sortOption of arSortOption">
                        <span class="m-0 dropdown-item-filter pointer"
                          (click)="generateFilter(sortOption.value)">
                          <fa-icon *ngIf="arModels['order_by'].id == sortOption.value"
                            [icon]="['fas', (arModels['order_by'].sort == 'asc') ? 'caret-up' : 'caret-down']"
                            aria-hidden="true" size="lg" class="text-center"></fa-icon>
                          <fa-icon *ngIf="arModels['order_by'].id != sortOption.value" [icon]="['far', 'minus']"
                            aria-hidden="true"></fa-icon>
                            <span id="sort_{{sortOption.value}}" class="pl-1">{{ sortOption.id | translate }}</span>
                        </span>
                      </div>
                    </div>
                    <div *ngIf="!arSortOption" class="dropdown-menu dropdown-content center-align"
                      aria-labelledby="dropdownSortButton">
                      <span class="dropdown-item-filter">{{ 'no_sort_available' | translate }}</span>
                    </div>
                  </span>

                  <!-- dropdown filter -->
                  <span class="dropdown nowrap filter-button">
                    <a class="btn btn-with-icon header-button-size" href="#" role="button" id="btnFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <fa-icon [icon]="[(arModels['filter_name']) ? 'fas' : 'fal', 'filter']" aria-hidden="true"></fa-icon>
                      <span class="filter-icon">{{ 'filter' | translate }}</span>
                    </a>
                    <div class="dropdown-menu dropdown-content center-align filter-content" aria-labelledby="btnFilter">
                      <div class="table mb-0 w-100">
                        <div class="row m-0">
                          <div class="cell">
                            <span class="filter-labels">{{ 'default_filters' | translate }}</span>
                          </div>
                        </div>
                        <div *ngFor="let filter of arSavedFilters.default | keyvalue" class="row m-0 mt-1">
                          <div class="col-xs-12 col-md-12 filter-items">
                            <a id="filter_{{filter.key}}" (click)="setFilter(filter.key, 'saved_filter', true)" matTooltip="{{ filter.key | translate }}" class="pointer">
                              {{ filter.key | translate }}
                            </a>
                            <fa-icon *ngIf="arModels['filter_name'] == filter.key" class="text-success ml-2" [icon]="['fas', 'check']"></fa-icon>
                          </div>
                        </div>
                      </div>
                      <!-- saved filters -->
                      <div *ngIf="(arSavedFilters.saved | json) != '[]'">
                        <hr />
                        <div class="table mb-0 w-100">
                          <div class="row m-0">
                            <div class="cell">
                              <span class="filter-labels">{{ 'saved_filters' | translate }}</span>
                            </div>
                          </div>
                          <div *ngFor="let filter of arSavedFilters.saved | keyvalue" class="row m-0 mt-1">
                            <div class="col-xs-12 col-md-12 filter-items">
                              <a id="saved_filter_{{filter.key}}" (click)="setFilter(filter.key, 'saved_filter', true)" matTooltip="{{ filter.key | translate }}" class="pointer">
                                {{ filter.key | translate }} <span *ngIf="filter.value['is_shared'] && !filter.value['is_removable']"><b>({{ 'shared_filter' | translate }})</b></span>
                              </a>
                              <fa-icon *ngIf="arModels['filter_name'] == filter.key" class="text-success ml-2" [icon]="['fas', 'check']"></fa-icon>
                              <fa-icon *ngIf="filter.value['is_removable']" class="text-danger ml-2 pointer" (click)="deleteFilter(filter.value)" [icon]="['fas', 'times']"></fa-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="(arSavedFilters.saved | json) == '[]'">
                        <hr />
                        <div class="table mb-0 w-100">
                          <div class="row m-0">
                            <div class="cell">
                              <span class="filter-labels">{{ 'saved_filters' | translate }}</span>
                            </div>
                          </div>
                          <div class="row m-0 mt-1">
                            <div class="col-xs-12 col-md-12 filter-items">
                              <span class="dropdown-item-filter">{{ 'no_available_filters' | translate }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </span>
                <span class="header-buttons align-bottom listing-summary" *ngIf="!listService.bLoading && totalRecords">{{ 'list_view_summary' | translate:  { fromRecords: fromRecords, toRecords: toRecords, totalRecords: totalRecords } }}</span>
              </div>

              <!-- Advanced Searchbox -->
              <div class="col-md-12 col-lg-6" >
                <advanced-searchbox
                  (onChange)="triggerFilter($event)"
                  *ngIf='templateAs'
                  [placeholder]="'search'"
                  [openOnLoad]="false"
                  [template]="templateAs"
                  [model]="modelAs"
                  [form]="form">
                </advanced-searchbox>
              </div>

              <!-- pagination header -->
              <div class="pagination-section col-md-12 col-lg-3 " align="right">
                <ul class="pagination float-right pr-rem-8 pt-1">
                  <li>

                    <button matTooltip="{{ 'search' | translate }}" class="btn btn-primary btn-sm fs-11 lh-01 pl-2 pr-2 h-034-mt1-mb1 ml-06 as-buttons" id="btnGoSearch" (click)="filterRecord('go')">
                      <fa-icon [icon]="['far', 'search']" [fixedWidth]="true" aria-hidden="true"></fa-icon>
                    </button>

                    <button [disabled]="!((modelAs | json) != '{}')" matTooltip="{{ 'save_current_filter' | translate }}" class="btn btn-success btn-sm fs-11 lh-01 pl-2 pr-2 h-034-mt1-mb1 ml-06 as-buttons" id="btnSaveCurrentFilter" (click)="saveCurrentFilter()">
                      <fa-icon [icon]="['fas', 'save']" [fixedWidth]="true" aria-hidden="true"></fa-icon>
                    </button>

                    <button matTooltip="{{ 'clear_filters' | translate }}" class="btn btn-danger btn-sm text-white fs-11 lh-01 pl-2 pr-2 h-034-mt1-mb1 ml-06 as-buttons" id="btnClearAllFilters" (click)="clearAllFilters()">
                      <fa-icon [icon]="['fas', 'times']" [fixedWidth]="true" aria-hidden="true"></fa-icon>
                    </button>

                    <tag-options [module]="strModule" [records]="arSelectedRecords" (onTaggedSuccess)="refreshList()"></tag-options>

                    <ng-container *ngIf="list_actions || hasMergingOption">
                      <button
                        matTooltip="{{ 'more_actions' | translate }}"
                        matTooltipPosition="above"
                        class="btn btn-secondary btn-sm fs-11 lh-01 pl-2 pr-2 h-034-mt1-mb1 ml-06 as-buttons"
                        id="btnMoreAction"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <fa-icon [icon]="['fas', 'caret-down']" [fixedWidth]="true" aria-hidden="true"></fa-icon>
                      </button>

                      <div class="dropdown-menu dropdown-content" aria-labelledby="btnMoreAction">

                        <ng-container *ngIf="list_actions"
                          [ngTemplateOutlet]="list_actions">
                        </ng-container>

                        <div *ngIf="hasMergingOption" class="dropdown-item">
                          <fieldmagic-icon
                            id="btnMergeRecord"
                            icon="compress-alt"
                            (click)="openMergeDialog()"
                            matTooltip="{{ 'merge_info' | translate }}"
                          >
                            <span>{{ 'merge' | translate }}</span>
                          </fieldmagic-icon>
                        </div>

                      </div>
                    </ng-container>
                  </li>
                </ul>
                <ul class="pagination float-right pr-1 pt-2">
                  <li
                    [ngClass]="hasPreviousPage ? 'active' : 'disabled'"
                    class="page-item ml-2">
                    <a id="navigateFirstPageTop" class="page-link pr-2 pl-2" aria-label="first" (click)="filterRecord('default')">
                      <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <li
                    [ngClass]="hasPreviousPage ? 'active' : 'disabled'"
                    class="page-item">
                    <a id="navigatePreviousTop" class="page-link pr-2 pl-2" aria-label="first" (click)="filterRecord('prev')">
                      <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <ng-container *ngFor="let numPage of arNavigationPage">
                    <li class="page-item" [ngClass]="{'active': currentPage == numPage}">
                      <a id="nagvigateToPage_{{numPage}}" class="page-link pr-1 pl-1" (click)="setPageNumber(numPage)" aria-label="first">
                        {{ numPage }}
                      </a>
                    </li>
                  </ng-container>
                  <li [ngClass]="hasNextPage && lastPage > currentPage ? 'active' : 'disabled'" class="page-item">
                    <a id="navigateNextTop" class="page-link pr-2 pl-2" aria-label="first" (click)="filterRecord('next')">
                      <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <li [ngClass]="hasNextPage && lastPage > currentPage ? 'active' : 'disabled'" class="page-item">
                    <a id="navigateNextTop" class="page-link pr-2 pl-2" aria-label="first" (click)="setPageNumber(lastPage)">
                      <fa-icon [icon]="['far', 'chevron-double-right']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </td>
      </tr>

      <!-- record spinner -->
      <tr *ngIf="listService.bLoading" align='center'>
        <td [attr.colspan]="arRowDisplay.length + 3">
          <fa-icon [icon]="['fas', 'spinner']" class="loading-spinner" [spin]="true"></fa-icon>
        </td>
      </tr>

      <!-- record display -->
      <tr class="datarow" *ngFor="let rowData of arContent; let recordIndex = index" [id]="'recordDetails' + recordIndex">

        <ng-container *ngIf="!listService.bLoading">
          <td class="dataColumn" [class.col_field]="strModule != 'leads' && strModule != 'customers'">
            <input *ngIf="hasMergingOption" [attr.id]="'recordCheckbox' + recordIndex" class="chkboxSelectRecord" type="checkbox" [checked]="isRecordSelected(rowData)" name="rowData['is_checked']" (click)="selectRecordChange($event, rowData)">
          </td>
          <ng-container *ngIf="!bViewLink">
            <td
              (click)="gotoRecordView(rowData.id, recordIndex)"
              *ngFor="let strRowDisplay of arRowDisplay"
              class="dataColumn" [class.col_field]="strModule != 'leads' && strModule != 'customers'"
              [ngClass]="{'status_field' : checkStatusField(strRowDisplay), 'phone_field' : strRowDisplay == 'phone'}">

              <app-list
                id="record{{recordIndex}}"
                [strRecordUrl]="generateRecordUrl(rowData.id, recordIndex, rowData)"
                [rowType]="arContentType"
                [rowData]="rowData"
                [rowToDisplay]="strRowDisplay"
                [currentModule]="strModule">
              </app-list>
            </td>
          </ng-container>

          <ng-container *ngIf="bViewLink">
            <td (click)="goToEdit(rowData.id, rowData)" *ngFor="let strRowDisplay of arRowDisplay"
              [class.col_field]="strModule != 'leads' && strModule != 'customers'"
              class="dataColumn"
              [ngClass]="{
                'status_field' : checkStatusField(strRowDisplay),
                'phone_field' : strRowDisplay == 'phone',
                'disabled-click-event': loadingRecord
              }">
              <app-list
                [rowType]="arContentType"
                [rowData]="rowData"
                [rowToDisplay]="strRowDisplay"
                [strRecordUrl]="generateRecordUrl(rowData.id, recordIndex, rowData)"
                [currentModule]="strModule">
              </app-list>
            </td>
          </ng-container>

          <td class="pl-0 pr-0">
            <tag-list *ngIf="objTagConfig.show(strModule)" [record]="rowData"></tag-list>
          </td>


          <!-- record buttons -->
          <td class="text-right">
            <div *ngIf="!bNoAction">
              <div class="buttons-container">
                <ng-container *fcHasPermission="strModule + ':edit'; else disabledEditButton">
                  <button *ngIf="rowData.editable == true || rowData.editable == undefined" type="button"
                    class="btn btn-success" (click)="(bEditButton) ? goToEdit(rowData.id, rowData) : ''"
                    [disabled]="loadingRecord">
                    <fa-icon [spin]="loadingRecord" [icon]="['fas', loadingRecord ? 'spinner' : 'pencil']" class="white"></fa-icon> &nbsp; {{ 'edit' | translate }}
                  </button>
                </ng-container>
                <ng-template #disabledEditButton>
                  <button
                    *ngIf="rowData.editable == true || rowData.editable == undefined"
                    type="button"
                    class="btn btn-success"
                    disabled
                  >
                    <fa-icon [icon]="['fas', 'pencil']" class="white"></fa-icon>
                    <span class="pl-1" matTooltip="{{ 'forbidden_action_error' | translate }}">{{ 'edit' | translate }}</span>
                  </button>
                </ng-template>
                <ng-container *fcHasPermission="strModule + ':delete'; else disabledDeleteButton">
                  <ng-template
                    [ngTemplateOutlet]="rowDeleteButtonTemplate || defaultDeleteButton"
                    [ngTemplateOutletContext]="{ asIcon: false, item: rowData, fns: { refreshList: refreshList } }"
                  >
                  </ng-template>
                  <ng-template #defaultDeleteButton>
                    <button
                      *ngIf="bAllowedDelete
                        && (rowData.related_record == undefined || rowData.related_record.length < 1)
                        && (rowData.deletable === true || rowData.deletable === undefined)"
                      type="button" class="btn btn-warning" (click)="deleteLink(rowData.id)">
                      <fa-icon [icon]="['fas', 'trash-alt']" class="white"></fa-icon> &nbsp; {{ 'delete' | translate }}
                    </button>
                    <button
                      *ngIf="bAllowedDelete
                        && (rowData.related_record == undefined || rowData.related_record.length < 1)
                        && (rowData.deletable === true || rowData.deletable === undefined) && strModule === 'customers'"
                      type="button" class="btn btn-primary" (click)="archiveLink(rowData.id)">
                      <fa-icon [icon]="['fas', 'archive']" class="white"></fa-icon> &nbsp; {{ 'archive' | translate }}
                    </button>
                    <ng-container *ngIf="rowData.related_record != undefined && rowData.related_record.length > 0">
                      <ng-container *ngIf="compiledRelatedData(rowData.related_record); let relatedData">
                        <span
                          matTooltip="{{ strModule + '_warning_disabled' | translate }} {{ relatedData }} {{ strModule + '_warning_info' | translate }}">
                          <button
                            *ngIf="bAllowedDelete && (rowData.deletable === true || rowData.deletable === undefined)"
                            type="button" class="btn buttons ml-2 btn-secondary"
                            (click)="deleteLink(rowData.id)" [disabled]="true">
                            <fa-icon [icon]="['fas', 'trash-alt']" class="white"></fa-icon> &nbsp;
                            {{ 'delete' | translate }}
                          </button>
                        </span>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </ng-container>
                <ng-template #disabledDeleteButton>
                  <button
                    *ngIf="bAllowedDelete"
                    type="button"
                    class="btn btn-warning"
                    disabled
                  >
                    <fa-icon [icon]="['fas', 'trash-alt']" class="white"></fa-icon>
                    <span class="pl-1" matTooltip="{{ 'forbidden_action_error' | translate }}">{{ 'delete' | translate }}</span>
                  </button>
                </ng-template>
              </div>

              <!-- Additional buttons for import -->
              <span *ngIf="strModule === 'imports'" id="iconDownloadImportedRows{{recordIndex}}" class="dropdown nowrap filter-button">
                <listing-download-button [record]="rowData"> </listing-download-button>
              </span>

              <div class="pr-2 w-100 icons-container">
                <div>
                  <ng-container *fcHasPermission="strModule + ':edit'; else editIconDisabledDueToPermission">
                    <a style="cursor: pointer;" *ngIf="(rowData.editable == true || rowData.editable == undefined); else editIconDisabled" class="actions text-success"
                      (click)="(bEditButton) ? goToEdit(rowData.id, rowData) : ''"
                      [ngClass]="loadingRecord ? 'disabled-click-event' : ''">
                      <fa-icon [spin]="loadingRecord" id="iconEditRecord{{recordIndex}}" [icon]="['fas', loadingRecord ? 'spinner' : 'pencil']"></fa-icon>
                    </a>
                    <ng-template #editIconDisabled>
                      <span matTooltip="{{ strTooltipDisabled | translate }}" class="actions text-secondary">
                        <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
                      </span>
                    </ng-template>
                  </ng-container>
                  <ng-template #editIconDisabledDueToPermission>
                    <span matTooltip="{{ 'forbidden_action_error' | translate }}" class="actions text-secondary">
                      <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
                    </span>
                  </ng-template>
                </div>
                <div *ngIf="bAllowedDelete" class="actions">
                  <ng-container *fcHasPermission="strModule + ':delete'; else deleteIconForbidden">
                    <ng-template
                      [ngTemplateOutlet]="rowDeleteButtonTemplate || defaultIconDeleteButton"
                      [ngTemplateOutletContext]="{ asIcon: true, item: rowData, fns: { refreshList: refreshList } }"
                    >
                    </ng-template>
                    <ng-template #defaultIconDeleteButton>
                      <ng-container *ngIf="(rowData.related_record == undefined || rowData.related_record.length < 1)
                      && (rowData.deletable === true || rowData.deletable === undefined); else deleteIconDisabled">
                        <a
                          id="iconDeleteRecord{{recordIndex}}"
                          class="text-warning"
                          style="cursor: pointer;"
                          (click)="deleteLink(rowData.id)"
                        >
                          <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                        </a>
                      </ng-container>
                      <ng-template #deleteIconDisabled>
                        <ng-container *ngIf="rowData.related_record != undefined && rowData.related_record.length > 0; else commonDeleteButton">
                          <ng-container *ngIf="compiledRelatedData(rowData.related_record); let relatedData">
                            <a *ngIf="bAllowedDelete" class="text-secondary" [routerLink]="" href=""
                              matTooltip="{{ strModule + '_warning_disabled' | translate }} {{ relatedData }} {{ strModule + '_warning_info' | translate }}">
                              <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                            </a>
                          </ng-container>
                        </ng-container>
                        <ng-template #commonDeleteButton>
                          <span class="text-secondary">
                            <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                          </span>
                        </ng-template>
                      </ng-template>
                      <br />
                      <a *ngIf="(rowData.related_record == undefined || rowData.related_record.length < 1)
                        && (rowData.deletable === true || rowData.deletable === undefined) && strModule === 'customers';"
                        id="iconDeleteRecord{{recordIndex}}"
                        class="text-primary"
                        style="cursor: pointer;"
                        (click)="archiveLink(rowData.id)"
                      >
                        <fa-icon [icon]="['fas', 'archive']"></fa-icon>
                      </a>
                    </ng-template>
                  </ng-container>
                  <ng-template #deleteIconForbidden>
                    <span matTooltip="{{ 'forbidden_action_error' | translate }}" class="text-secondary">
                      <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                    </span>
                  </ng-template>
                </div>
              </div>
            </div>

            <!--
              This so we can separate the custom logic for specific modules
              and move it outside the listing-component to prevent it from flooding any further
            -->
            <ng-container *ngIf="list_item_actions"
              [ngTemplateOutlet]="list_item_actions">
            </ng-container>
            <ng-container *ngIf="rowTemplate" [ngTemplateOutlet]="rowTemplate"
              [ngTemplateOutletContext]="{ item: rowData, fns: { refreshList: this.refreshList } }">
            </ng-container>

          </td>
        </ng-container>

      </tr>

      <!-- record empty -->
      <tr *ngIf="listService.bNoResult && !listService.bLoading" align='center'>
        <td [attr.colspan]="arRowDisplay.length + 3">
          <span class="no-results-text">
            <fa-icon [icon]="['far', 'exclamation-circle']"></fa-icon>{{ 'result_empty' | translate }}
          </span>
        </td>
      </tr>

      <!-- pagination footer -->
      <tr>
        <td [attr.colspan]="arRowDisplay.length + 3" class="footer-table">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 col-lg-6 vertical-center">
                <span class="header-buttons pt-0" *ngIf="!listService.bLoading && totalRecords">{{ 'list_view_summary' | translate:  { fromRecords: fromRecords, toRecords: toRecords, totalRecords: totalRecords } }}</span>
              </div>
              <div class="pagination-section col-md-12 col-lg-3 offset-lg-3" align="right">
                <ul class="pagination float-right">
                  <li
                    [ngClass]="hasPreviousPage ? 'active' : 'disabled'"
                    class="page-item ml-2">
                    <a id="navigateFirstPageTop" class="page-link pr-2 pl-2" aria-label="first" (click)="filterRecord('default')">
                      <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <li
                    [ngClass]="hasPreviousPage ? 'active' : 'disabled'"
                    class="page-item">
                    <a id="navigatePreviousTop" class="page-link pr-2 pl-2" aria-label="first" (click)="filterRecord('prev')">
                      <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <ng-container *ngFor="let numPage of arNavigationPage">
                    <li class="page-item" [ngClass]="{'active': currentPage == numPage}">
                      <a id="nagvigateToPage_{{numPage}}" class="page-link pr-1 pl-1" (click)="setPageNumber(numPage)" aria-label="first">
                        {{ numPage }}
                      </a>
                    </li>
                  </ng-container>
                  <li [ngClass]="hasNextPage && lastPage > currentPage ? 'active' : 'disabled'" class="page-item">
                    <a id="navigateNextTop" class="page-link pr-2 pl-2" aria-label="first" (click)="filterRecord('next')">
                      <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <li [ngClass]="hasNextPage && lastPage > currentPage ? 'active' : 'disabled'" class="page-item">
                    <a id="navigateNextTop" class="page-link pr-2 pl-2" aria-label="first" (click)="setPageNumber(lastPage)">
                      <fa-icon [icon]="['far', 'chevron-double-right']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </td>
      </tr>

    </tbody>
  </table>
</div>