import { ApplicationRef, ChangeDetectorRef, Component, ComponentFactoryResolver, Injector, Input, OnInit, OnDestroy, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalendarApiService } from '../services/calendar-api.service';
import { CalendarService } from '../services/calendar.service';
import { CalendarPageDirection, CalendarPagination } from '../full-calendar/classes/calendar-pagination';
import { forkJoin, of, Subscription, Observable, interval } from 'rxjs';
import { delay, delayWhen, finalize, switchMap, take, tap } from 'rxjs/operators';
import { TASK_PROGRESS_COLORS } from '../full-calendar/full-calendar.component';
import { Draggable } from '@fullcalendar/interaction';
import { LooseObject } from '../../../objects/loose-object';
import { MatDialog } from '@angular/material';
import { TaskDetailsDialogComponent } from '../dialogs/task-details-dialog/task-details-dialog.component';
import moment from 'moment';
import { TaskMetadataC } from '../full-calendar/classes/metadata/task-metadata';
import { JobMetadataC } from '../full-calendar/classes/metadata/job-metadata';
import { OpportunityMetadataC } from '../full-calendar/classes/metadata/opportunity-metadata';
import { AuthorizedClient } from '../../../features/data-sharing/objects/authorized-client';
import { InputSanitizerService } from '../../../services/input-sanitizer.service';
import { FilterField, FilterTypes } from '../view/filter/task-filter.component';
import { isEmpty, isEqual, isNil, get } from 'lodash-es';
import { ListingService } from '../../../services/listing.service';
import { AdvanceSearchboxTemplate } from '../../../objects/advance-searchbox';
import { TranslateService } from '@ngx-translate/core';
import { ClientStoreService } from '../../../services/client-store.service';
import { ActivitiesService } from '../../../services/activities.service';
import { RecordService } from '../../../services/record.service';
import { blank } from '../../../shared/utils/common';

@Component({
  selector: 'calendar-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  providers: [
    CalendarApiService
  ],
  encapsulation: ViewEncapsulation.None
})

export class TasksComponent implements OnInit, OnDestroy {
  /**
   * Contains the selected authorized client/contractor
   */
  @Input('child-client') childClient?: AuthorizedClient;

  /**
   * Which view is being shown at the moment.
   */
  @Input('team-view') teamView: boolean = false;

  /**
   * emit when we need to reset filter
   *
   * @type {EventEmitter<boolean>}
   */
  @Output() objResetFilter = new EventEmitter<boolean>();

  /**
   * Which view we are currently viewing.
   *
   * @type {CommonFilter}
   */
  objFiltersView: CommonFilter = this.filterView[0];

  /**
   * List of tasks or jobs.
   */
  items: (TaskMetadataC | JobMetadataC | OpportunityMetadataC)[] = [];

  /**
   * If the user is viewing the calendar coming from a job, this means
   * that we should only be viewing tasks under that job. This job id
   * is received via query string.
   *
   * @type {string|undefined|}
   */
  strViewingFromJobId: string|undefined;

  /**
   * If the user is viewing the calendar coming from an opportunity, this means
   * that we should only be viewing tasks under that opportunity. This opportunity id
   * is received via query string.
   *
   * @type {string|undefined}
   */
  strViewingFromOpportunityId: string|undefined;

  /**
   * Holds the current filter and reuse it when changing the pagination
   *
   * @type {object}
   */
  currentFilter: object = {};

  /**
   * Subscribes to actions in task details dialog
   * (schedule/unschedule/edit) and updates the
   * tasks for scheduling list.
   *
   * @type {Subscription}
   */
  updateForSchedulingListSub: Subscription;

  /**
   * Flag that controls if the tasks list spinner should be shown
   *
   * @type {boolean}
   */
  bShowTasksListSpinner: boolean = true;

  /**
   * Flag that indicates if the task list is being loaded for the first time.
   * Gets set to false after the initial load of the task list.
   *
   * @type {boolean}
   */
  private bTaskListInitialLoad: boolean = true;

  /**
   * Flag to check auto open to only happen once.
   *
   * @type {boolean}
   */
  private bOpenedOnce: boolean = false;

  /**
   * Access the constant list of filter view periods.
   *
   * @return {CommonFilter[]}
   */
  get filterView(): CommonFilter[] {
    if (!this.client.isDepartmentTracking()) {
      return FILTER_VIEW.map(item => {
        item.filters = item.filters.filter(filter => {
          return filter.label != 'department_id';
        });
        return item;
      })
    } else {
      return FILTER_VIEW;
    }
  }

  /**
   * Check if the task/job list has
   * a next page.
   *
   * @type {boolean}
   */
  get hasNextPage(): boolean {
    return this.calendarServiceApi.objPagination.next_page === null;
  }

  /**
   * Check if the task/job list has
   * a previous page.
   *
   * @type {boolean}
   */
  get hasPrevPage(): boolean {
    return this.calendarServiceApi.objPagination.prev_page === null;
  }

  /**
   * The x in "Showing x to y of z entries." label.
   *
   * @type {number}
   */
  get showingFrom(): number {
    return this.calendarServiceApi.numShowStart
  }

  /**
   * The y in "Showing x to y of z entries." label.
   *
   * @type {number}
   */
  get showingTo(): number {
    if (this.items.length === 10) {
      return this.calendarServiceApi.numShowEnd;
    } else {
      return this.calendarServiceApi.numShowEnd + this.calendarService.showingToChange;
    }
  }

  /**
   * The z in "Showing x to y of z entries." label.
   *
   * @type {number}
   */
  get showingTotal(): number {
    return this.calendarServiceApi.objPagination.total + this.calendarService.totalChange;
  }

  /**
   * Event emitter when the task widget changes view
   * between job or task.
   *
   * @type {EventEmitter<FilterField[]>}
   */
  @Output() onChangeView = new EventEmitter<FilterField[]>();

  /**
   * Event emitter when each time the task/job list is reloaded.
   *
   * @type {EventEmitter<boolean>}
   */
  @Output() onListReload: EventEmitter<boolean> = new EventEmitter();


  constructor(
    private calendarServiceApi: CalendarApiService,
    public calendarService: CalendarService,
    public ref: ChangeDetectorRef,
    public dialog: MatDialog,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected appRef: ApplicationRef,
    protected injector: Injector,
    protected is: InputSanitizerService,
    protected route: ActivatedRoute,
    private client: ClientStoreService,
    protected listService: ListingService,
    protected translateService: TranslateService,
    private activitiesService: ActivitiesService,
    private recordService: RecordService
  ) {
    this.strViewingFromJobId = this.route.snapshot.queryParamMap.get('job_id');
    this.strViewingFromOpportunityId = this.route.snapshot.queryParamMap.get('opportunity_id');

    // Updates tasks for scheduling list after actions in task details dialog (schedule/unschedule/edit)
    this.updateForSchedulingListSub = this.calendarService.forSchedulingListUpdate$.subscribe((task: object) => {
      switch (task['action']) {
        case 'reschedule':
        case 'duplicate':
        case 'show_on_calendar':
          break;
        case 'schedule':
          if (this.objFiltersView.value !== 'task_view' && !task['dragged_from_list']) {
            let strTaskModule: 'job' | 'opportunity' = !isNil(task['data']['job']) ? 'job' : 'opportunity';
            // When manually scheduling tasks in job/quote view (not dragging from list),
            // only remove job/quote from job/quote list if there are no more tasks left with status = 'awaiting_scheduling'
            let objAwaitingSchedulingTask = task['data'][strTaskModule]['tasks'].find(task => task.status === 'awaiting_scheduling');

            if (!objAwaitingSchedulingTask) {
              this.calendarService.decrementListingOffsets();
              this.removeFromTaskList(task['data'][strTaskModule]['id']);
            } else {
              // If there are still tasks left with status = 'awaiting_scheduling',
              // we just update the task metadata
              this.items = this.items.map(objJobMetadata => {
                if (objJobMetadata.id !== task['data'][strTaskModule]['id']) {
                  return objJobMetadata;
                } else {
                  return { ...objJobMetadata, tasks: task['data'][strTaskModule]['tasks'] };
                }
              });

              this.resetDraggableItems();
            }
          } else {
            this.calendarService.decrementListingOffsets();
            this.removeFromTaskList(task['data']['id']);
          }

          this.onListReload.emit(true);
          break;
        case 'unschedule':
          if (this.objFiltersView.value === 'task_view') {
            this.calendarService.incrementListingOffsets(this.items.length);
            this.handleTaskUnscheduled(task['data']);
            this.onListReload.emit(true);
          } else {
            setTimeout(() => {
              this.setSchedulableTasks();
            }, 500);
          }
          break;
        default:
          setTimeout(() => {
            this.setSchedulableTasks();
          }, 500);
      }
    });
  }

  ngOnInit(): void {

    this.bOpenedOnce = false;

    // If viewing job scheduler from opportunity (quote) record, and no task yet exists for said record, automatically create task against it.
    if (this.strViewingFromOpportunityId) {
      this.createQuoteTask();
    } else {
      this.initActions();
    }
  }

  ngOnDestroy(): void {
    this.updateForSchedulingListSub.unsubscribe();
  }

  initActions(): void {
    this.calendarService.reloadSchedulableTasks.subscribe(filter => {
      if (isEqual(filter, this.currentFilter) === false) {
        this.calendarServiceApi.resetPage();
      }

      // During initial load of task list, if viewing from job or opportunity, immediately
      // exit this function as the filtering of tasks by job/opportunity will rerun this.
      if (this.bTaskListInitialLoad && (this.strViewingFromJobId || this.strViewingFromOpportunityId)) {
        this.bTaskListInitialLoad = false;
        return;
      }

      // During initial load of task list, if not viewing from job or opportunity,
      // set filter to empty to remove any remaining filters.
      if (this.bTaskListInitialLoad && !this.strViewingFromJobId && !this.strViewingFromOpportunityId) {
        filter = {};
        this.bTaskListInitialLoad = false;
      }

      this.setSchedulableTasks({
        ...filter,
      });
      this.currentFilter = filter;
    });

    // Adding set timeout because of errors in change detection.
    setTimeout(() => {
      this.emitFilterNewInstance(this.objFiltersView);
    }, 1);
  }

  /**
   * If viewing job scheduler from opportunity (quote) record, and no task yet exists for said record, automatically create task against it.
   *
   * @returns {void}
   */
  createQuoteTask(): void {
    forkJoin([
      // get opportunity record details
      this.recordService.getRecord('opportunities', this.strViewingFromOpportunityId),
      // get tasks under the opportunity
      this.listService.fetchDataAdvanceSearch(
        {},
        'activities',
        {
          activity_type: [{ op: "eq", value: [{ label: "task", value: "task" }] }],
          opportunity_id: this.strViewingFromOpportunityId
        },
        {},
        null,
        1,
      )
    ]).pipe(
      switchMap(([oppRes, taskRes]) => {
        // if opportunity has existing tasks, don't do anything and proceed as normal
        if (get(taskRes, 'data', []).length > 0) {
          return of([]);
        } else {
          // if opportunity has no existing tasks, create a quote task with status 'awaiting_scheduling'
          let objOpportunityDetails: object = get(oppRes, 'record_details', {});
          // create an unscheduled quote task
          let objUnscheduledQuoteTask: object = {
            module_id: this.strViewingFromOpportunityId,
            module_field: "opportunity_id",
            task_progress: "awaiting_scheduling",
            team_id: null,
            // for task subject (activity_name), get value for summary trimmed to 128 characters, if blank, use the sample format of 'Quote 000001'
            subject: objOpportunityDetails['summary'].slice(0, 128) || `${this.translateService.instant('opportunity')} ${objOpportunityDetails['opportunity_number']}`,
            assigned_to: null,
            priority: "normal",
            estimated_duration: "1",
            due_date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            // for task notes (description), get value for summary, if blank, use the sample format of 'Quote 000001'
            note: objOpportunityDetails['summary'] || `${this.translateService.instant('opportunity')} ${objOpportunityDetails['opportunity_number']}`,
            module: "opportunities",
            type: "task"
          };

          return this.activitiesService.createActivity(JSON.stringify(objUnscheduledQuoteTask));
        }
      }),
    ).subscribe(res => {
      // if new quote task is created, res should not be empty and we add delay so that elasticsearch will display it on the list
      let numDelay: number = isEmpty(res) ? 0 : 500;

      setTimeout(() => {
        this.initActions();
      }, numDelay);
    });
  }

  /**
   * Removes a task from the list.
   *
   * @param {string} strId
   *
   * @returns {void}
   */
  removeFromTaskList(strId: string): void{
    this.items = this.items.filter(task => {
      return task.id !== strId;
    });
  }

  /**
   * Makes every item in the list draggable.
   * Should be called every time this.items is updated.
   *
   * @returns {void}
   */
  resetDraggableItems(): void {
    setTimeout(() => {
      this.ref.detectChanges();
      this.fcMakeExternalEvents(document.getElementsByClassName('draggable-task'));
    }, 500);
  }

  /**
   * Access the next or previous page.
   *
   * @param {CalendarPageDirection} strDirection
   *
   * @returns {void}
   */
  accessPage(strDirection: CalendarPageDirection): void {
    this.calendarServiceApi.setPage(strDirection);
    this.calendarService.reloadSchedulableTasks.next(
      this.currentFilter
    );
  }

  /**
   * Set the list of schedulable tasks.
   *
   * @param {LooseObject} objFilter
   *
   * @returns {void}
   */
  setSchedulableTasks(objFilter?: LooseObject): void {
    this.items = [];
    this.calendarService.schedulableTasksAreLoading.next(true);

    of(this.objFiltersView).pipe(
      switchMap(result => this.getTasksListSource(result.value, objFilter)),
      delayWhen( () => !isNil(this.strViewingFromJobId) || !isNil(this.strViewingFromOpportunityId) ? interval(1000) : interval(0) ),
      tap(() => this.calendarService.schedulableTasksAreLoading.next(false) ),
      finalize( () => this.bShowTasksListSpinner = false ),
    ).subscribe(response => {
        this.items = response.items;

        this.calendarService.resetListingOffsets();
        this.resetDraggableItems();
        this.onListReload.emit(true);

        if (!this.bOpenedOnce && this.route.snapshot.queryParamMap.get('popup') == 'open' && this.items) {
          this.bOpenedOnce = true;
          this.handleTaskClick(this.items[0]);
        }

      });
  }

  /**
   * Gets the observable of the for scheduling list.
   *
   * @param {string} strView
   * @param {LooseObject} objFilter
   *
   * @returns {Observable<{items: JobMetadataC[] | OpportunityMetadataC[] | TaskMetadataC[], pagination: CalendarPagination}>}
   */
  getTasksListSource(strView: string, objFilter: LooseObject): Observable<{items: JobMetadataC[] | OpportunityMetadataC[] | TaskMetadataC[], pagination: CalendarPagination}> {
    switch (strView) {
      case 'task_view':
        return this.calendarServiceApi.tasksForScheduling(objFilter);
      case 'job_view':
        return this.calendarServiceApi.jobsForScheduling(objFilter);
      case 'quote_view':
        return this.calendarServiceApi.opportunitiesForScheduling(objFilter);
      default:
        return of({ items: [], pagination: new CalendarPagination() });
    }
  }

  /**
   * Set the filter view.
   *
   * @param {CommonFilter} objFilter
   *
   * @returns {void}
   */
  setFilterView(objFilter: CommonFilter): void {
    this.objFiltersView = objFilter;
    this.strViewingFromJobId = undefined;
    this.strViewingFromOpportunityId = undefined;

    this.calendarServiceApi.resetPage();
    this.setSchedulableTasks({});
    this.emitFilterNewInstance(objFilter);
    this.objResetFilter.emit(true)
  }

  /**
   * Send the new set filters. Should always be
   * new instances of the object to allow change
   * detection in ng-select.
   *
   * @param {FilterField} arFilters
   *
   * @returns {void}
   */
  emitFilterNewInstance(objFilter: CommonFilter): void {

    let modules = ['activities', 'customers', 'sites', 'jobs', 'opportunities'];

    let templates$ = [];

    modules.forEach(module => {
      templates$.push(this.listService.getAdvancedSearchboxTemplate(module));
    });

    forkJoin(templates$).pipe(
      take(1),
    ).subscribe((templates: Array<AdvanceSearchboxTemplate[]>) => {
      let filterFields = [];
      modules.forEach((module, index) => {
        // Remove fixed filter
        if (module === 'activities') {
          templates[index] = templates[index].filter(x => {
            return ['activity_type', 'task_progress'].includes(x.model) === false;
          }).map(activityField => {
            if (activityField.dataType === 'uuid' && activityField.remoteModule === 'jobs') {
              activityField.bindLabel = 'job_number';
            }

            return activityField;
          })
        }

        // update searching of relate field
        if (module === 'opportunities') {
          templates[index] = templates[index].map(activityField => {
            if (activityField.dataType === 'uuid' && activityField.remoteModule === 'jobs') {
              activityField.bindLabel = 'job_number';
            }

            return activityField;
          })
        }

        // Exclude special filters as they have special function that may not work as task-filters
        templates[index] = templates[index].filter(x => {
          return x.filterFieldType !== 'special';
        });

        filterFields = filterFields.concat(templates[index].map((asTemplate: AdvanceSearchboxTemplate) => {
          return new FilterField(
            (asTemplate.dataType as FilterTypes),
            asTemplate.model,
            `${this.translateService.instant(asTemplate.label)}`,
            module,
            asTemplate,
            asTemplate.placeholder ? asTemplate.placeholder : null,
            asTemplate.domains === 'remote' ? asTemplate.remoteModule : (asTemplate.domains || null),
          );
        }));
      });

      filterFields = filterFields.concat(this.generateTaskTypeFilterField());
      this.onChangeView.emit(filterFields);
    });
  }

  /**
   * Generates a 'Task Type' filter field for the 'Tasks' section of the filter.
   *
   * @returns {FilterField}
   */
  generateTaskTypeFilterField(): FilterField {
    return new FilterField(
      'checkbox',
      'task_type',
      'task_type',
      'activities',
      {
        bindLabel: 'label',
        dataType: 'dropdown',
        type: "INPUT",
        domains: [
          { label: 'jobs', value: 'job_id' },
          { label: 'opportunities', value: 'opportunity_id' }
        ],
        placeholder: 'task_type',
        model: 'task_type',
        label: 'task_type'
      },
      'task_type',
      [
        { label: 'jobs', value: 'job_id' },
        { label: 'opportunities', value: 'opportunity_id' }
      ],
    );
  }

  /**
   * Loops through the provided set of HTML elements then declares
   * them as calendar-droppable events that FullCalendar can recognize.
   *
   * @param {HTMLCollection} elements
   *
   * @todo Attempt to use cdkDrag instead of FullCalendar's Draggable class.
   * @see https://stackoverflow.com/questions/22754315/for-loop-for-htmlcollection-elements
   *
   * @returns {void | false} Returns false when no task metadata has been found
   */
  fcMakeExternalEvents(elements: HTMLCollection): void | false {

    for (let i = 0; i < elements.length; i++) {

      let element: HTMLElement = elements[i] as HTMLElement;

      // Skip those elements that FullCalendar already recognizes
      // as an external event so that we don't initialize it twice on
      // the same element.
      if (element.classList.contains('fc-initialized')) { continue; }

      let strTaskId: string = element.getAttribute('task');

      let objTaskMetadata = this.items.find((task) => {
        return task.id === strTaskId;
      });

      if (!objTaskMetadata) {

        return false;

      } else {

        let strEventColor = this.getColor(objTaskMetadata);

        let numEstimatedDuration = '01:00';

        if(objTaskMetadata['estimated_duration'] && objTaskMetadata['estimated_duration'] != 0.000) {
          // Sanitize (using is: InputSanitizerService) the estimated_duration float value to be accepted by moment.duration()
          let placeToFloatVar = moment.duration(this.is.toFloat(objTaskMetadata['estimated_duration']), 'h')['_data'];
          numEstimatedDuration = moment(placeToFloatVar).format("HH:mm");
        }

        // This is what makes the element draggable. Setup by FullCalendar,
        // there's not much room for customization in this.
        new Draggable(element, {
          eventData: {
            textColor: strEventColor,
            borderColor: strEventColor,
            id: objTaskMetadata['id'],
            duration: numEstimatedDuration,
            color: strEventColor,
            recordMetadata: {
              ...objTaskMetadata
            }
          }
        });

        element.classList.add('fc-initialized');
      }
    }
  }

  /**
   * Triggered when the user clicks an task. When a task
   * is clicked, we'll show the following task information
   * in a popup fashion:
   *
   * 1.) Site details
   * 2.) Job details
   * 3.) Task details
   *
   * @param taskMetadata
   */
  handleTaskClick(taskMetadata): void {
    if (!taskMetadata.job && taskMetadata['metadata_type'] === 'job') {
      taskMetadata.job = {
        id: taskMetadata.id,
        job_number: taskMetadata.job_number,
        full_address: taskMetadata.full_address
      };
    }

    if (!taskMetadata.opportunity && taskMetadata['metadata_type'] === 'opportunity') {
      taskMetadata.opportunity = {
        id: taskMetadata.id,
        opportunity_number: taskMetadata.opportunity_number,
        full_address: taskMetadata.full_address
      };
    }

    // The user wasn't clicking on one of the event actions so that means he/she
    // wants to view the task info so let's whip up the dialog box.
    this.dialog.open(TaskDetailsDialogComponent, {
      maxWidth: '84vw',
      width: '1280px',
      data: {
        ...this.calendarService.formatTaskForDialogDisplay(taskMetadata),
        scheduled_task: false,
        ... (this.childClient && {
          for_child_client_id: this.childClient.client_id,
        }),
        view: this.teamView ? 'teams' : 'users'
      }
    })
    .afterClosed()
    .subscribe(objDialogResult => {
      if (blank(objDialogResult)) {
        return;
      }

      if (!objDialogResult.action && !objDialogResult.editedTas) {
        return;
      }

      if (objDialogResult.action !== 'show_on_calendar') {
        this.calendarService.updateForSchedulingList.next(objDialogResult);
      }

      this.calendarService.updateCalendar.next(objDialogResult);
    });
  }

  /**
   * A user has unscheduled a task. Meaning, we need to remove it from the calendar
   * AND add it again to the list of schedulable tasks if the task is visible
   * in the list.
   *
   * @param unscheduledTask
   *
   * @returns {void}
   */
  handleTaskUnscheduled(unscheduledTask): void {
    if (! isEmpty(unscheduledTask.parent_id) && ! unscheduledTask.was_replaced) {
      return;
    }

    this.items = [unscheduledTask].concat(this.items.slice(0, 9));
    // We'll be modifying tasksComponent's `tasks` and we need to be able to
    // reference it later so we need to clone it. Otherwise, we'll be working on
    // data will be modified everywhere.
    let _calendarSchedulableTasks = JSON.parse(JSON.stringify(this.items));

    // After scheduling the task and having the list schedulable tasks updated,
    // we must of course update our paginationLog. This is very important. The
    // first and eleventh task of the list have been updated and so should this.
    this.items = _calendarSchedulableTasks;

    this.resetDraggableItems();
  }

  /**
   * Get the color of the tasks.
   *
   * @param {TaskMetadataC | JobMetadataC | OpportunityMetadataC} objItem
   *
   * @returns {string}
   */
  private getColor(objItem: TaskMetadataC | JobMetadataC | OpportunityMetadataC): string {

    let strStatus = 'scheduled';

    if (objItem instanceof TaskMetadataC && objItem.status !== '' && objItem.status !== 'awaiting_scheduling') {
      strStatus = objItem.status;
    }

    return TASK_PROGRESS_COLORS[strStatus];
  }

}

export interface CommonFilter {
  label: string;
  value: string;
  filters?: FilterField[]
}

const FILTER_VIEW = [
  { label: 'task_view', value: 'task_view', filters: []},
  { label: 'job_view', value: 'job_view', filters: []},
  { label: 'quote_view', value: 'quote_view', filters: []}
];
