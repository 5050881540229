import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule as AngularFontawesome } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

const light = require('@fortawesome/pro-light-svg-icons');
const solid = require('@fortawesome/pro-solid-svg-icons');
const regular = require('@fortawesome/pro-regular-svg-icons');

library.add(
  solid.faBars,
  solid.faSyncAlt,
  solid.faPlay,
  solid.faEye,
  solid.faSpinner,
  light.faSpinner,
  solid.faLanguage,
  solid.faBell,
  light.faBell,
  regular.faBell,
  solid.faExchange,
  solid.faQuestion,
  solid.faCog,
  regular.faCog,
  light.faCog,
  solid.faSignOutAlt,
  solid.faCircle,
  light.faCircle,
  regular.faSms,
  light.faSms,
  solid.faSms,
  light.faChevronLeft,
  light.faChevronRight,
  light.faChevronUp,
  regular.faChevronLeft,
  regular.faFolderOpen,
  regular.faChevronSquareDown,
  regular.faChevronRight,
  solid.faFilter,
  regular.faFilter,
  light.faFilter,
  solid.faCaretLeft,
  solid.faCaretUp,
  solid.faCaretRight,
  regular.faChevronDoubleLeft,
  regular.faChevronDoubleRight,
  solid.faPlusCircle,
  regular.faPlusCircle,
  light.faPlusCircle,
  solid.faEdit,
  solid.faTrashAlt,
  light.faTrashAlt,
  regular.faTrashAlt,
  light.faMobileAndroidAlt,
  solid.faMobileAndroidAlt,
  regular.faMobileAndroidAlt,
  light.faExclamationCircle,
  regular.faExclamationCircle,
  solid.faExclamationCircle,
  light.faPencil,
  solid.faPencil,
  regular.faPencil,
  light.faExchange,
  solid.faExchange,
  regular.faExchange,
  solid.faSave,
  solid.faTimes,
  regular.faTimes,
  solid.faMinus,
  regular.faMinus,
  solid.faPlus,
  light.faPlus,
  solid.faCheck,
  regular.faCheck,
  solid.faExclamation,
  solid.faArrowLeft,
  solid.faArrowRight,
  solid.faTimesCircle,
  solid.faCheckCircle,
  solid.faCogs,
  regular.faCogs,
  solid.faArrowsAlt,
  solid.faRepeat,
  solid.faLifeRing,
  solid.faFileCheck,
  solid.faFileUpload,
  solid.faSearch,
  light.faSearch,
  regular.faSearch,
  light.faBuilding,
  solid.faBuilding,
  regular.faBuilding,
  light.faEnvelope,
  solid.faEnvelope,
  regular.faEnvelope,
  light.faHashtag,
  solid.faHashtag,
  regular.faHashtag,
  light.faAlignJustify,
  solid.faAlignJustify,
  regular.faAlignJustify,
  light.faClipboardList,
  solid.faClipboardList,
  regular.faClipboardList,
  light.faToolbox,
  solid.faToolbox,
  regular.faToolbox,
  light.faPortrait,
  solid.faPortrait,
  regular.faPortrait,
  light.faBook,
  solid.faBook,
  regular.faBook,
  light.faAddressCard,
  solid.faAddressCard,
  regular.faAddressCard,
  light.faChartPie,
  solid.faChartPie,
  regular.faChartPie,
  light.faPhonePlus,
  solid.faPhonePlus,
  regular.faPhonePlus,
  light.faHome,
  solid.faHome,
  regular.faHome,
  light.faProjectDiagram,
  solid.faProjectDiagram,
  regular.faProjectDiagram,
  regular.faVideo,
  solid.faSortUp,
  solid.faSortDown,
  light.faSort,
  regular.faSync,
  regular.faPercentage,
  light.faIdCard,
  solid.faIdCard,
  regular.faIdCard,
  light.faUsers,
  solid.faUsers,
  regular.faUsers,
  light.faUser,
  solid.faUser,
  regular.faUser,
  light.faUserCrown,
  solid.faUserCrown,
  regular.faUserCrown,
  light.faPhoneOffice,
  solid.faPhoneOffice,
  regular.faPhoneOffice,
  light.faSitemap,
  solid.faSitemap,
  regular.faSitemap,
  light.faDollarSign,
  solid.faDollarSign,
  regular.faDollarSign,
  light.faWrench,
  solid.faWrench,
  regular.faWrench,
  light.faMapMarkerAlt,
  solid.faMapMarkerAlt,
  regular.faMapMarkerAlt,
  light.faAddressBook,
  solid.faAddressBook,
  regular.faAddressBook,
  light.faListUl,
  solid.faListUl,
  regular.faListUl,
  light.faListAlt,
  solid.faListAlt,
  regular.faListAlt,
  light.faKey,
  solid.faKey,
  regular.faKey,
  light.faCalendar,
  solid.faCalendar,
  regular.faCalendar,
  light.faShoppingBag,
  solid.faShoppingBag,
  regular.faShoppingBag,
  solid.faFileAlt,
  regular.faFileAlt,
  light.faRoad,
  solid.faRoad,
  regular.faRoad,
  light.faPaste,
  solid.faPaste,
  regular.faPaste,
  light.faEye,
  solid.faEye,
  regular.faEye,
  solid.faClock,
  regular.faClock,
  light.faClock,
  solid.faDownload,
  regular.faDownload,
  light.faDownload,
  solid.faClipboard,
  regular.faClipboard,
  light.faClipboard,
  solid.faFingerprint,
  regular.faFingerprint,
  light.faFingerprint,
  light.faChevronSquareDown,
  solid.faDesktop,
  solid.faCalendarAlt,
  light.faPhone,
  light.faTasks,
  light.faStickyNote,
  regular.faTasks,
  solid.faPhone,
  solid.faTasks,
  solid.faStickyNote,
  solid.faPaperPlane,
  solid.faFilePlus,
  light.faPlusSquare,
  regular.faArrowAltCircleRight,
  regular.faAngleUp,
  regular.faAngleDown,
  solid.faCircle,
  light.faInfoCircle,
  solid.faInfoCircle,
  regular.faInfoCircle,
  light.faInfo,
  solid.faExclamationCircle,
  solid.faAsterisk,
  light.faClipboardListCheck,
  solid.faClipboardListCheck,
  regular.faClipboardListCheck,
  light.faFileUpload,
  solid.faFileUpload,
  regular.faFileUpload,
  light.faFilePdf,
  regular.faFilePdf,
  solid.faFilePdf,
  light.faFileVideo,
  light.faFileImage,
  light.faFile,
  light.faFileSpreadsheet,
  light.faFilePowerpoint,
  light.faFileWord,
  light.faFileCsv,
  light.faFileAudio,
  light.faFileAlt,
  light.faFileArchive,
  light.faCloudDownload,
  light.faBoxOpen,
  solid.faBoxOpen,
  regular.faBoxOpen,
  light.faChartLine,
  solid.faChartLine,
  regular.faChartLine,
  light.faMinusCircle,
  solid.faMinusCircle,
  regular.faMinusCircle,
  light.faFastForward,
  solid.faFastForward,
  regular.faFastForward,
  regular.faMoneyCheck,
  regular.faFileImport,
  light.faBoxFull,
  solid.faBoxFull,
  regular.faBoxFull,
  light.faCartPlus,
  solid.faCartPlus,
  regular.faCartPlus,
  light.faMoneyBillAlt,
  solid.faMoneyBillAlt,
  regular.faMoneyBillAlt,
  light.faBarcode,
  solid.faBarcode,
  regular.faBarcode,
  light.faHourglassStart,
  solid.faHourglassStart,
  regular.faHourglassStart,
  light.faHourglassEnd,
  solid.faHourglassEnd,
  regular.faHourglassEnd,
  light.faGripHorizontal,
  solid.faGripHorizontal,
  regular.faGripHorizontal,
  light.faArrowToBottom,
  solid.faArrowToBottom,
  regular.faArrowToBottom,
  light.faArrowToTop,
  solid.faArrowToTop,
  regular.faArrowToTop,
  light.faUserHardHat,
  solid.faUserHardHat,
  regular.faUserHardHat,
  regular.faStickyNote,
  regular.faCommentAlt,
  light.faLink,
  solid.faLink,
  regular.faLink,
  light.faFileInvoice,
  solid.faFileInvoice,
  regular.faFileInvoice,
  light.faCopy,
  solid.faCopy,
  regular.faCopy,
  light.faMarker,
  solid.faMarker,
  regular.faMarker,
  light.faCode,
  solid.faCode,
  regular.faCode,
  light.faTextWidth,
  solid.faTextWidth,
  regular.faTextWidth,
  light.faBalanceScale,
  solid.faBalanceScale,
  regular.faBalanceScale,
  light.faReceipt,
  solid.faReceipt,
  regular.faReceipt,
  solid.faFileImage,
  solid.faCheckDouble,
  solid.faSignature,
  light.faBriefcase,
  solid.faBriefcase,
  regular.faBriefcase,
  solid.faHistory,
  solid.faCalendarWeek,
  solid.faFileDownload,
  light.faTools,
  solid.faTools,
  regular.faTools,
  light.faBan,
  solid.faBan,
  regular.faBan,
  light.faUndoAlt,
  solid.faUndoAlt,
  regular.faUndoAlt,
  light.faFileInvoiceDollar,
  solid.faFileInvoiceDollar,
  regular.faFileInvoiceDollar,
  solid.faEnvelopeOpenText,
  regular.faCabinetFiling,
  solid.faBookReader,
  solid.faFile,
  regular.faFile,
  regular.faFileCsv,
  solid.faUnlink,
  solid.faClone,
  regular.faClone,
  solid.faCut,
  light.faUpload,
  solid.faUpload,
  regular.faUpload,
  light.faCrown,
  solid.faCrown,
  regular.faCrown,
  light.faComments,
  solid.faComments,
  regular.faComments,
  light.faLocationArrow,
  solid.faLocationArrow,
  regular.faLocationArrow,
  light.faPaperclip,
  solid.faPaperclip,
  regular.faPaperclip,
  light.faLineColumns,
  solid.faLineColumns,
  regular.faLineColumns,
  light.faFileChartLine,
  solid.faFileChartLine,
  regular.faFileChartLine,
  light.faCommentsDollar,
  solid.faCommentsDollar,
  regular.faCommentsDollar,
  light.faShoppingCart,
  solid.faShoppingCart,
  regular.faShoppingCart,
  solid.faHardHat,
  regular.faHardHat,
  light.faMapSigns,
  solid.faMapSigns,
  regular.faMapSigns,
  light.faCheckSquare,
  light.faSquare,
  solid.faRecycle,
  solid.faTrashRestore,
  regular.faExclamation,
  regular.faCalendarAlt,
  regular.faFileExport,
  solid.faFileExport,
  light.faFileExport,
  solid.faExchangeAlt,
  light.faInventory,
  solid.faInventory,
  regular.faInventory,
  light.faWarehouseAlt,
  solid.faWarehouseAlt,
  regular.faWarehouseAlt,
  light.faHandHoldingBox,
  solid.faHandHoldingBox,
  regular.faHandHoldingBox,
  light.faPeopleCarry,
  solid.faPeopleCarry,
  regular.faPeopleCarry,
  light.faTruckLoading,
  solid.faTruckLoading,
  regular.faTruckLoading,
  light.faClipboardCheck,
  solid.faClipboardCheck,
  regular.faClipboardCheck,
  light.faCartArrowDown,
  solid.faCartArrowDown,
  regular.faCartArrowDown,
  light.faCaretDown,
  solid.faCaretDown,
  regular.faCaretDown,
  light.faArrowRight,
  solid.faArrowRight,
  regular.faArrowRight,
  light.faArrowLeft,
  solid.faArrowLeft,
  regular.faArrowLeft,
  light.faSlidersH,
  solid.faSlidersH,
  regular.faSlidersH,
  light.faCompressAlt,
  solid.faCompressAlt,
  regular.faCompressAlt,
  light.faAbacus,
  solid.faAbacus,
  regular.faAbacus,
  regular.faEquals,
  light.faStore,
  solid.faStore,
  regular.faStore,
  light.faWallet,
  solid.faWallet,
  regular.faWallet,
  light.faChevronDown,
  solid.faChevronDown,
  regular.faChevronDown,
  solid.faChevronUp,
  regular.faChevronUp,
  solid.faObjectUngroup,
  solid.faArrowAltDown,
  solid.faArrowAltUp,
  solid.faUserCog,
  solid.faShare,
  solid.faEllipsisV,
  light.faLandmark,
  solid.faLandmark,
  regular.faLandmark,
  light.faDatabase,
  solid.faDatabase,
  regular.faDatabase,
  light.faForward,
  solid.faForward,
  regular.faForward,
  light.faSpinnerThird,
  solid.faSpinnerThird,
  regular.faSpinnerThird,
  light.faMagic,
  solid.faMagic,
  regular.faMagic,
  light.faFolder,
  solid.faFolder,
  regular.faFolder,
  regular.faMobile,
  solid.faMobile,
  light.faStar,
  solid.faStar,
  regular.faStar,
  light.faPlug,
  solid.faPlug,
  regular.faPlug,
  light.faGripVertical,
  solid.faGripVertical,
  regular.faGripVertical,
  light.faPlusSquare,
  solid.faPlusSquare,
  regular.faPlusSquare,
  light.faMinusSquare,
  solid.faMinusSquare,
  regular.faMinusSquare,
  light.faArchive,
  solid.faArchive,
  regular.faArchive,
  light.faInboxOut,
  solid.faInboxOut,
  regular.faInboxOut,
  light.faShippingFast,
  solid.faShippingFast,
  regular.faShippingFast,
  light.faBusinessTime,
  solid.faBusinessTime,
  regular.faBusinessTime,
  light.faMapMarkerExclamation,
  solid.faMapMarkerExclamation,
  regular.faMapMarkerExclamation,
  light.faCaretCircleDown,
  solid.faCaretCircleDown,
  regular.faCaretCircleDown,
  light.faCaretCircleUp,
  solid.faCaretCircleUp,
  regular.faCaretCircleUp,
  light.faCaretCircleRight,
  solid.faCaretCircleRight,
  regular.faCaretCircleRight,
  regular.faSyncAlt,
  light.faExternalLinkAlt,
  solid.faExternalLinkAlt,
  regular.faExternalLinkAlt,
  light.faAt,
  solid.faAt,
  regular.faAt,
  light.faCalculator,
  solid.faCalculator,
  regular.faCalculator,
  light.faTag,
  solid.faTag,
  regular.faTag,
  light.faStickyNote,
  solid.faStickyNote,
  regular.faStickyNote,
  solid.faShareAlt,
  regular.faShareAlt,
  light.faShareAlt,
  solid.faFileSpreadsheet,
  regular.faSparkles,
  light.faSparkles,
  solid.faSparkles,
  solid.faWindowClose,
  regular.faWindowClose,
  light.faWindowClose,
  solid.faSort,
  regular.faThumbtack,
  light.faThumbtack,
  solid.faThumbtack,
  regular.faCheckCircle,
  regular.faPrint,
  light.faPrint,
  solid.faPrint,
  solid.faFileCsv,
  light.faEllipsisH,
  solid.faEllipsisH,
  regular.faEllipsisH,
  solid.faInfo,
  light.faEyeSlash,
  solid.faEyeSlash,
  regular.faEyeSlash,
  regular.faGripHorizontal,
  regular.faGripVertical,
);

@NgModule({
  imports: [
    CommonModule,
    AngularFontawesome
  ],
  declarations: [],
  exports: [
    AngularFontawesome
  ]
})
export class FontAwesomeModule { }
