import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNull, isUndefined, round, toString } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { ClientStoreService } from '../../../../../services/client-store.service';
import { CurrencyPipe } from '@angular/common';
import { toFormattedNumber } from '../../../../utils/numbers';
import { filled } from '../../../../utils/common';

type OnChangeHandler = (value?: string) => void;
type OnTouchedHandler = () => void;
type OnKeyPressHandler = (event: KeyboardEvent) => void;

const _kDefaultDecimalPlaces = 2;

@Component({
  selector: 'fc-currency-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyInputComponent),
      multi: true,
    },
    CurrencyPipe
  ],
  template: `
    <div class="input-icon">
      <input
        type="number"
        [attr.min]="min"
        [attr.max]="max"
        [attr.step]="step"
        class="{{ customClass }}"
        [placeholder]="placeholder"
        [disabled]="isDisabled$ | async"
        [(ngModel)]="value"
        (change)="onChange($event.target.value)"
        (focus)="onFocused()"
        (keypress)="onKeyPress($event)"
        [readonly]="isReadonly"
        [class.with-controls]="withControls"
      />
      <i>{{ strCurrencySymbol }}</i>
    </div>
  `,
  styleUrls: ['./currency.component.scss']
})
export class CurrencyInputComponent implements ControlValueAccessor {

  /**
   * store's the currency symbol
   */
  strCurrencySymbol: string = '$';

  @Input() placeholder: string = '';

  @Input() step: number = 0.1;

  @Input() min: number;

  @Input() max: number;

  @Input() withControls: boolean = false;

  @Input() places: number = _kDefaultDecimalPlaces;

  /**
   * Dictates if the field should be disabled
   */
  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  /**
   * Contains the value inputted
   */
  value: string;

  /**
   * determine if field allows negative value
   */
  @Input() nonNegative: boolean = false;

  /**
   * determine if field allows negative value
   */
  @Input() customClass: string = 'form-control font-size-11';

  /**
   * determine if field allows negative value
   */
  @Input() isReadonly: boolean = false;

  @Output('focus') $onFocused = new EventEmitter<void>();

  constructor(
    private clientStoreService: ClientStoreService,
    private currencyPipe: CurrencyPipe
  ) {
    let currentClient = this.clientStoreService.getActiveClient();
    let objConfig = currentClient ? currentClient.config : {};
    let strCurrentCurrency = (objConfig && objConfig['currency']) ? objConfig['currency'] : 'USD';
    let strCurrencySymbol = this.currencyPipe.transform('0', strCurrentCurrency, 'symbol-narrow');

    this.strCurrencySymbol = strCurrencySymbol.replace('0.00', '');
  }

  /**
   * Callback when input is touched
   */
  onTouched: OnTouchedHandler = () => { };

  /**
   * Callback when input value was changed
   */
  onChange: OnChangeHandler = () => { };

  /**
   * Callback when on key press
   */
  onKeyPress: OnKeyPressHandler = (event: KeyboardEvent) => this.onKeyPressInput(event);

  /**
   * @inheritdoc
   */
  registerOnChange(fn: OnChangeHandler): void {
    this.onChange = (value?: string) => {
      fn(
        this.value = this.formatValue(value)
      );
    };
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: OnTouchedHandler): void {
    this.onTouched = fn;
  }

  /**
   * {@inheritdoc}
   */
  setDisabledState(disabled: boolean): void {
    this.isDisabled$.next(disabled);
  }

  /**
   * @inheritdoc
   */
  writeValue(value?: string): void {
    this.value = this.formatValue(value);
  }

  onFocused(): void {
    if (filled(this.onTouched)) {
      this.onTouched();
    }

    this.$onFocused.emit();
  }

  /**
   * Formats the given value
   */
  protected formatValue(value?: string): string | undefined {
    if (isUndefined(value) || isNull(value)) {
      return undefined;
    }

    const places = this.places || _kDefaultDecimalPlaces;
    const normalized = toFormattedNumber(value, {
      maxDecimalPlaces: places,
      currency: true,
    });

    /// this checks if the normalized value has 1-2 decimal places
    /// if it passes then we forced it to atleast 2 decimal places
    const regex = new RegExp('^(?:-?(?:\\d*)?(?:\\.\\d{1,2})?)$', 'i');

    if (regex.test(toString(normalized))) {
      return round(normalized, 2).toFixed(2);
    }

    return toString(normalized);
  }

  /**
   * on key press event
   * if non negative is true, we should allow the user to input numeric values only
     */
  protected onKeyPressInput(event: KeyboardEvent) {
    if (!this.nonNegative) {
      return;
    }

    if (event.which != 8 && event.which != 0 && event.which !== 46 && event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  }
}
