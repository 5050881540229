import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { format_module_field_entries } from '../module/checklists/shared/utils';
import { AvailablePeriodOption, Checklist, ChecklistQuestion, ChecklistType, PromptFieldEntry, ChecklistResponse, ChecklistPromptGroupConfiguration, ChecklistRequiredBefore } from '../module/checklists/shared/types';
import { data_get, fallback, filled, safely_parse_json, transform } from '../shared/utils/common';
import { NotificationService } from './notification.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ModuleMetadata } from './form.service';
import { ConfigurableDropdownOption } from '../objects/studio';

const kBaseUrl: string = environment.url + "/checklists/";

@Injectable()
export class ChecklistsService {

  protected checklistResponseData: any = {};
  protected checklistResponseError: string = null;

  constructor (
    private http: HttpClient,
    private _api: ApiService,
    private _notifications: NotificationService,
  ) {}

  getFieldEntries = (): Observable<Record<string, PromptFieldEntry[]>> => this._api.callPrivate$({
    path: '/checklists/field_entries',
    action: 'post',
    expectsNewFormat: true,
    onSuccessResponse: (data) => ({
      assets: format_module_field_entries(data.get('assets'), 'assets'),
      jobs: format_module_field_entries(data.get('jobs'), 'jobs'),
      opportunities: format_module_field_entries(data.get('opportunities'), 'opportunities'),
      sites: format_module_field_entries(data.get('sites'), 'sites'),
    }),
  });

  get = (id: string): Observable<Checklist> => this._api.callPrivate$({
    path: '/record/get',
    action: 'post',
    body: {
      module: 'checklists',
      id,
      has_related_data: false,
      is_data_only: false,
    },
    onSuccessResponse: (response) => ({
      ... response.get('record_details'),
      questions: safely_parse_json(data_get(response.get('record_details'), 'questions'), {
        fallback: () => [],
      }),
      available_periods: safely_parse_json(data_get(response.get('record_details'), 'available_periods'), {
        fallback: () => [],
      }),
      asset_type_attributes: safely_parse_json(data_get(response.get('record_details'), 'asset_type_attributes'), {
        fallback: () => {},
      }),
      required_before: fallback(data_get(response.get('record_details'), 'required_before'), {
        fallback: () => null,
      }),
      group_prompts_configuration: safely_parse_json(data_get(response.get('record_details'), 'group_prompts_configuration'), {
        fallback: () => {},
      }),
      auto_attached_on: safely_parse_json(data_get(response.get('record_details'), 'auto_attached_on'), {
        fallback: () => [],
      }),
    }),
  });

  delete = (id: string): Observable<boolean> => this._notifications.confirmDelete().pipe(
    switchMap(() => this._api.callPrivate$({
      path: '/record/delete',
      action: 'post',
      body: {
        module: 'checklists',
        id,
      },
      onSuccessResponse: (_) => {
        this._notifications.notifySuccess('record_delete_success', {
          header: 'deleted_successful',
        });

        return true;
      },
    })),
    catchError((err) => {
      if (err !instanceof HttpErrorResponse) {
        return throwError(err);
      }

      return of(false);
    }),
  );

  create = (data: CreateChecklistData): Observable<string> => this._api.callPrivate$({
    action: 'post',
    path: '/record/save',
    body: {
      module: 'checklists',
      data: {
        name: data.name,
        type: 'unified',
        is_checklist_enabled: true,
      }
    },
    onSuccessResponse: (response) => response.get('id'),
  });

  update = (id: string, data: UpdateChecklistData): Observable<boolean> => this._api.callPrivate$({
    path: '/record/save',
    action: 'post',
    body: {
      module: 'checklists',
      id: id,
      data: {
        ... data,
        questions: JSON.stringify([data.questions]),
        available_periods: JSON.stringify(data.available_periods),
        is_comment_enabled: fallback(data.is_comment_enabled, {
          fallback: () => false,
        }),
        required_before: data.required_before,
        document_ordering: fallback(data.document_ordering, {
          fallback: () => 0,
        }),
      }
    },
    onSuccessResponse: (_) => true,
  }).pipe(
    tap((_) => this._notifications.notifySuccess('Checklists successfully saved.')),
  );

  linkTo = (checklist: Checklist, data: {
    job_id?: string,
    opportunity_id?: string,
    available_periods?: AvailablePeriodOption[],
  } = {}): Observable<ChecklistResponse> => this._api.callPrivate$<ChecklistResponse>({
    action: 'post',
    path: '/checklists/link',
    body: {
      checklist_id: checklist.id,
      job_id: data.job_id,
      opportunity_id: data.opportunity_id,
      available_periods: fallback(data.available_periods, {
        fallback: () => [],
      }),
    },
    expectsNewFormat: true,
    onSuccessResponse: (data) => {
      this._notifications.notifySuccess('Checklist successfully linked.');

      return {
        id: data.get('id'),
        name: data.get('name'),
        questions: data.get('questions'),
        type: data.get('type'),
      };
    },
    onErrorResponse: (status, data) => {
      if (status == 422 && filled(data.get('message'))) {
        this._notifications.notifyError(data.get('message'));
      } else if (status == 422) {
        this._notifications.notifyError('Payload validation error. Please contact support');
      }
    },
  });

  getLinked = (opts: {
    asset_job_id: string
    job_id?: string;
    opportunity_id?: string;
    asset_type_id?: string;
  }): Observable<GetLinkedChecklistsResponseData> => this._api.callPrivate$({
    action: 'post',
    path: '/checklists/get_linked',
    expectsNewFormat: true,
    body: {
      asset_job_id: opts.asset_job_id,
      job_id: opts.job_id,
      opportunity_id: opts.opportunity_id,
      asset_type_id: opts.asset_type_id,
    },
    onSuccessResponse: (response) => ({
      links: fallback(response.get('links'), {
        fallback: () => [],
      }),
      available: fallback(response.get('available'), {
        fallback: () => [],
      }),
      linked: transform(response.get('linked'), {
        transformer: (data) => ({
          ... data,
          questions: safely_parse_json(data_get(data, 'questions'), {
            fallback: () => [],
          }),
        }),
      }),
    }),
  });

  linkInspection = (data: {
    asset_job_id: string;
    checklist_response_id: string;
    asset_type_id: string;
  }): Observable<boolean> => this._api.callPrivate$({
    action: 'post',
    path: '/checklists/link_inspection',
    expectsNewFormat: true,
    body: {
      checklist_response_id: data.checklist_response_id,
      asset_job_id: data.asset_job_id,
      asset_type_id: data.asset_type_id,
    },
    onSuccessResponse: (_) => {
      this._notifications.notifySuccess('Inspection attached successfully');

      return true;
    },
    onErrorResponse: (status, data) => {
      if (status == 422 && filled(data.get('message'))) {
        this._notifications.notifyError(data.get('message'));
      } else if (status == 422) {
        this._notifications.notifyError('Payload validation error. Please contact support.');
      }
    },
  });

  getLinkDetails = (data: {
    id: string;
    asset_id?: string;
    asset_type_id?: string;
    asset_job_id?: string;
  }): Observable<GetLinkDetailsData> => this._api.callPrivate$({
    action: 'post',
    path: '/checklists/link_details',
    expectsNewFormat: true,
    body: {
      id: data.id,
      asset_id: data.asset_id,
      asset_type_id: data.asset_type_id,
      asset_job_id: data.asset_job_id,
    },
    onSuccessResponse: (data) => ({
      data: data.get('data'),
      related_data: data.get('related_data'),
      modules_metadatum: data.get('modules_metadatum')
    }),
  });

  saveBasicInspection = (data: {
    asset_job_id: string;
    status: InspectionStatus,
    fault_details?: string;
  }) => this._api.callPrivate$({
    path: '/checklists/save_basic_inspection',
    action: 'post',
    body: {
      asset_job_id: data.asset_job_id,
      status: data.status,
      fault_details: data.fault_details,
    },
    expectsNewFormat: true,
    onSuccessResponse: (_) => {
      this._notifications.notifySuccess('Inspection details saved successfully.');

      return true;
    },
    onErrorResponse: (status, data) => {
      if (status == 422 && filled(data.get('message'))) {
        this._notifications.notifyError(data.get('message'));
      } else if (status == 422) {
        this._notifications.notifyError('Payload validation error. Please contact support.');
      }
    },
  });

  search(term: string, opts: {
    types?: string[],
    asset_type_ids?: string[],
    size?: number,
  } = {}): Observable<any[]> {
    const payload = new URLSearchParams;

    if (filled(term)) {
      payload.set('term', term);
    }

    if (filled(opts.types)) {
      payload.set('types', JSON.stringify(opts.types));
    }

    if (filled(opts.asset_type_ids)) {
      payload.set('asset_type_ids', JSON.stringify(opts.asset_type_ids));
    }

    if (filled(opts.size)) {
      payload.set('size', JSON.stringify(opts.size));
    }

    return this.http.post(kBaseUrl + 'search', payload.toString(), {
      observe: 'body',
    }).pipe(
      map((content) => data_get(content, 'items', {
        default_value: [],
      })),
    );
  }

  getModulesDropdownOptions = (): Observable<ModulesDropdownOptions> => this._api.callPrivate$({
    action: 'post',
    path: '/checklists/module_dropdown_options',
    expectsNewFormat: true,
    onSuccessResponse: (data) => ({
      jobs: {
        type: fallback(data_get(data.get('jobs'), 'type'), {
          fallback: () => [],
        }),
      },
      checklists: {
        required_before: fallback(data_get(data.get('checklists'), 'required_before'), {
          fallback: () => [],
        })
      }
    }),
  });

  saveResponse = (opts: {
    checklist_response_id: string,
    questions?: ChecklistQuestion[];
    date_completed?: string;
    fault_details?: string;
    faulty_product_ids?: string[],
    asset_id?: string;
    completed_by?: string;
    review_status?: string;
  }): Observable<boolean> => this._api.callPrivate$({
    action: 'post',
    path: '/checklists/save_response',
    expectsNewFormat: true,
    body: {
      'id': opts.checklist_response_id,
      'asset_id': opts.asset_id,
      'fault_details': opts.fault_details,
      'product_id': opts.faulty_product_ids,
      'response': opts.questions,
      'completed_by': opts.completed_by,
      'date_completed': opts.date_completed,
      'review_status': opts.review_status,
    },
    onSuccessResponse: (_) => {
      this._notifications.notifySuccess('Response successfully saved.');

      return true;
    },
    onErrorResponse: (status, data) => {
      if (status == 422) {
        this._notifications.notifyError(data.get('message'));
      }
    },
  });
}

type UpdateChecklistData = {
  name: string;
  type: ChecklistType;
  questions: ChecklistQuestion;
  is_enabled?: boolean;
  is_comment_enabled?: boolean;
  document_ordering?: number;
  required_before?: ChecklistRequiredBefore;
  group_prompts_configuration?: {
    [prompt_id: string]: ChecklistPromptGroupConfiguration;
  };

  /// deprecated props as of FC-4542
  asset_type_id?: string;
  available_periods?: AvailablePeriodOption[];
  auto_attached_on?: string[];
}

type CreateChecklistData = {
  name: string;
}

type ChecklistResponseFaultyData = {
  details?: string;
  product_id?: any;
}

export type DisplayableChecklistResponseData = {
  id: string;
  type: ChecklistType;
  questions: ChecklistQuestion[];
  response?: ChecklistQuestion[];
  asset_type_id?: string;
  job_id?: string;
  opportunity_id?: string;
  faulty?: ChecklistResponseFaultyData;
}

export type GetLinkedChecklistsResponseData = {
  linked?: ChecklistResponse;
  links: ChecklistResponse[];
  available: Checklist[];
}

export type GetLinkDetailsData = {
  data: ChecklistResponse;
  related_data: {
    jobs?: Record<string, any>;
    opportunities?: Record<string, any>;
    sites?: Record<string, any>;
    assets?: Record<string, any>;
    items?: Record<string, any>[];
  };
  modules_metadatum: {
    jobs: ModuleMetadata;
    opportunities: ModuleMetadata;
    sites: ModuleMetadata;
    assets: ModuleMetadata;
  };
}

export type InspectionStatus = 'pass' | 'fail' | 'awaiting_completion';

export type ModulesDropdownOptions = {
  jobs: {
    type: ConfigurableDropdownOption[],
  };
  checklists: {
    required_before: ConfigurableDropdownOption[],
  };
};