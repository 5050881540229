
    <button
      class="fieldmagic-btn btn {{ variant$ | async }}"
      [ngClass]="klass"
      [disabled]="isInProgress || disabled || denied"
      [class.fieldmagic-btn-lg]="size == 'lg'"
      [class.fieldmagic-btn-icon]="purpose == 'icon' || purpose == 'delete-icon'"
      [matTooltip]="'forbidden_action_error' | translate | when: denied:{
        otherwise: tooltip
      }"
    >
      <fieldmagic-icon
        *ngIf="(icon | filled) || isInProgress; else content;"
        [icon]="icon || 'spinner'"
        [spin]="isInProgress"
        [size]="iconSize"
      >
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </fieldmagic-icon>
      <ng-template #content>
        <ng-content *ngIf="label | blank; else asLabel"></ng-content>
      </ng-template>
      <ng-template #asLabel>
        <span>{{ label | translate }}</span>
      </ng-template>
    </button>
  