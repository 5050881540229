
    <div
      *ngIf="label | filled; else input;"
      class="form-group"
    >
      <fieldmagic-text
        purpose="input"
        [content]="label"
        [withRequiredMarker]="withRequiredMarker"
      >
      </fieldmagic-text>

      <ng-container *ngTemplateOutlet="input"></ng-container>
    </div>

    <ng-template #input>
      <ng-select
        [(ngModel)]="model"
        (change)="onChanged($event)"
        [loading]="fetching$ | async"
        [items]="options$ | async"
        [typeahead]="typeahead$"
        [multiple]="multiple"
        [groupBy]="makeGroupNameFactory()"
        [placeholder]="placeholder"
        appendTo="body"
      >
        <ng-template
          ng-label-tmp
          let-item="item"
          let-clear="clear"
        >
          <span class="ng-value-label">
            {{ item.text | translate }}
          </span>

          <span
            *ngIf="multiple"
            class="ng-value-icon right selected-close"
            (click)="clear(item)"
            aria-hidden="true"
          >
            ×
          </span>
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
        >
          {{ item.text | translate }}
        </ng-template>

        <ng-template
          ng-optgroup-tmp
          let-item="item"
        >
          {{ item | data_get: 'label' | translate }}
        </ng-template>
      </ng-select>
      <fieldmagic-input-errors
        *ngIf="errors | filled"
        [errors]="errors"
      >
      </fieldmagic-input-errors>
    </ng-template>
  