import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomEditFieldComponent, Form, FormMode } from '../../../../base/form';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { Select } from '../../../../objects/select';
import { Client } from '../../../../objects/client';
import { tap, switchMap } from 'rxjs/operators';
import { filled, blank, fallback } from '../../../../shared/utils/common';
import { get } from 'lodash-es';
import { LooseObject } from '../../../../objects/loose-object';
import { ListingService } from '../../../../services/listing.service';
import { LocalStorageService } from '../../../../services/local-storage.service';

@Component({
  selector: 'fc-warehouse-stock-levels',
  templateUrl: './warehouse-stock-levels.component.html',
  styles: ['.pagination-color { color: #0070B9 !important; }']
})

export class WarehouseStockLevelsComponent implements CustomEditFieldComponent, OnInit, OnDestroy {
  /**
   * The parent form group where the field belongs to
   *
   * @type {FormGroup}
   */
  parentForm: FormGroup;

  /**
   * Contains the data for the items in the list, as well as the pagination metadata
   *
   * @type {LooseObject}
   */
  objList: LooseObject = {};

  /**
   * The mode of the opened form, either 'add' or 'edit'.
   *
   * @type {FormMode}
   */
  strMode: FormMode;

  /**
   * Goes to the desired page in the listing.
   *
   * @type {BehaviorSubject<number>}
   */
  goToListingPage: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  /**
   * Observable for goToListingPage.
   *
   * @type {Observable<number>}
   */
  private listingUpdate$: Observable<number> = this.goToListingPage.asObservable();

  /**
   * The id of the selected warehouse.
   *
   * @type {string}
   */
  private strWarehouseId: string;

  /**
   * The id of the selected item.
   *
   * @type {string|null}
   */
  private strItemId: string|null = null;

  /**
   * Stores all the subscriptions
   *
   * @type {Subscription}
   */
  private _subscriptions: Subscription[] = [];

  /**
   * Checks if objList is blank
   *
   * @returns {string}
   */
  get isObjListBlank(): boolean {
    return blank(this.objList);
  }

  get itemRelateFilter(): LooseObject {
    return { labor: false };
  }

  constructor(
    private listingService: ListingService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    if (this.strMode !== 'add') {
      const itemsSub: Subscription = this.listingUpdate$
        .pipe(
          tap(_ => this.objList = {}),
          switchMap(numPage => this.listingService.fetchDataAdvanceSearch(
            { pageNum: numPage },
            'stock_levels',
            this._getListingFilter(),
            { id: 'quantity', sort: 'desc' },
            null,
            this._getListingPerPage()
          ))
        )
        .subscribe(response => {
              this.objList = response;
        });

      this._subscriptions.push(itemsSub);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  /**
   * @inheritdoc
   */
  setFormMode(formMode: FormMode): void {
    this.strMode = formMode;
  }

  /**
   * @inheritdoc
   */
  setParentForm(parentForm: FormGroup): void {
    this.parentForm = parentForm;
  }

  /**
   * @inheritdoc
   */
  setField(field: Form<any>): void {
    // no need at the moment
  }

  /**
   * @inheritdoc
   */
  setAdditionalData(data: LooseObject): void {
    this.strWarehouseId = data['warehouse_id'];
  }

  /**
   * Performs actions whenever the selected item changes.
   *
   * @param {Select|undefined} value
   *
   * @returns {void}
   */
  onItemChange(value?: Select): void {
    this.strItemId = get(value, 'id');

    this.goToListingPage.next(1);
  }

  /**
   * Gets the listing filters. Warehouse id is required.
   * If there is a selected product, adds the 'product_id' to the filter.
   *
   * @returns {number}
   */
  private _getListingFilter(): LooseObject {
    const filter: LooseObject = { warehouse_id: this.strWarehouseId };

    if (filled(this.strItemId)) {
      filter['item_id'] = this.strItemId;
    } else {
      filter['quantity'] = {op: "gt", value: "0"};
    }

    return filter;
  }

  /**
   * Gets the listing records per page config from local storage. Defaults to 25.
   *
   * @returns {number}
   */
  private _getListingPerPage(): number {
    const currentClient: Client = this.localStorageService.getJsonItem('current_client');
    const numListingPerPage: number|null = get(currentClient, 'config.listing_records_per_page', 25);

    return fallback(numListingPerPage, {
      fallback: () => 25,
    });
  }
}