import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';

import { Form } from '../base/form';
import { Dropdown } from '../base/dropdown';
import { Multiselect } from '../base/multiselect';
import { Textarea } from '../base/textarea';
import { Datetime } from '../base/datetime';
import { Json } from '../base/json';

import { Select } from '../objects/select';
import { Subject } from 'rxjs';
import { Relate } from '../base/relate';
import { NotificationService} from '../services/notification.service';
import { Notification } from '../objects/notification';

import { cloneDeep, get, isEmpty, isEqual, isUndefined } from 'lodash-es';
import { Locale } from '../lists/locale';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { addressValidator } from '../shared/validators/address-validator';
import { phoneValidator } from '../shared/validators/phone-validator';
import { amountValidator } from '../shared/validators/currency-validator';
import * as moment from 'moment';
import { Phone } from '../objects/phone';
import { ArrService } from '../services/helpers/arr.service';
import { ClientStoreService } from '../services/client-store.service';
import { LooseObject } from '../objects/loose-object';
import { DateSelectList } from '../lists/date-select-list';
import { LocalStorageService } from './local-storage.service';
import { MODULE_RELATE_TEXT_FIELDS } from '../module/form-templates/shared/constants';
import { filled } from '../shared/utils/common';
import { sprintf } from 'sprintf-js';
import { SetUnsavedChangesData } from '../objects/auto-save';

@Injectable()
export class FormService {

  public strModule: string;
  public strMode: string;
  public arRelateValues: any[] = [];
  public arOldData: any = [];
  public bAddTag: boolean = false;
  public bAppendToBody: boolean = false;
  public objSaveData: any = {};
  public objData: any = {};
  public arOldRawData: object = {};

  public obvAddItem = new Subject<any>();
  strAddItem$ = this.obvAddItem.asObservable();
  public arDataType: any[] = [];
  public overrideData: LooseObject = null;

  private clientDetails: LooseObject = {}

  /**
   * metadata list
   */
  protected objFields: LooseObject = {};

  constructor (
    private notifService: NotificationService,
    private translate: TranslateService,
    public arrService: ArrService,
    public clientStorage: ClientStoreService,
    public localStorageService: LocalStorageService,
  ) {
  }

  /**
   * This maps the data properly so the edit form
   * can be generated.
   * @param anyDataToEdit
   */
  setData(anyDataToEdit: any, arDetails: any) {
    //Typecast so we can loop.
    let data: any = anyDataToEdit;

    data = data.map(
      item => {
        let arFields: any = [];
        //Loops all the fields listed.
        item['fields'].forEach(
          fieldNames => {
            //Modifies the data inside the fields.
            fieldNames.forEach(
              fieldProperties => {

                let arField: any = {};

                //Make sure there is a key and value set.
                if(Object.values(fieldProperties)[0] && Object.keys(fieldProperties)[0]) {

                  //Get the value.
                  arField = Object.values(fieldProperties)[0];
                  //Get the key of the field.
                  arField['key'] = Object.keys(fieldProperties)[0];

                  //Checks if the fields consumes 1 column or 2 (maximum of 2)
                  if (fieldNames.length < 2) {
                    arField['space'] = 2;
                  }

                  //Add the new field, changing to the proper field object.
                  arFields.push(this.tranformFieldObject(arField, arDetails));

                } else {
                  //If it's an empty space.
                  arField['controlType'] = 'blank';
                  arFields.push(new Form(arField));
                }
              }
            );
          }
        );

        //Add the modified fields.
        item['fields'] = arFields;

        return item;
      }
    );
    return data;
  }

  tranformFieldObject(objField, arDetails = null){
    let objNewField: any = {};

    // If there is no readonly (usually custom fields emits this)
    // Set readonly as false since we have no option to set custom fields
    // as readonly fields.
    if (objField['readonly'] == undefined) {
      objField['readonly'] = false;
    }

    // FC-3084: when is_admin and user is not admin, make the field readonly true
    if (objField['is_admin'] == true && !this.clientStorage.isAdmin()) {
      objField['readonly'] = true;
    }

    //We check what type of field it is.
    switch (objField['type']) {
      case 'address':
        objField['controlType'] = 'address';
        objNewField = new Form(objField);
        break;
      //If it's a text, we add text as it's input type.
      case 'text':
        objField['controlType'] = 'text';
        objNewField = new Form(objField);
        break;
      //If it's a number, we add number as it's input type.
      //Allows negative numbers.
      case 'number':
        objField['controlType'] = 'number';
        objNewField = new Form(objField);
        break;
      //If it's a checkbox, we add checkbox as it's input type.
      case 'checkbox':
        objField['controlType'] = 'checkbox';
        if (typeof objField['default_value'] != 'boolean') { objField['default_value'] = false; }
        objNewField = new Form(objField);
        break;
      //We use a separate input for currency.
      //Does not allow negatives.
      case 'currency':
        objField['controlType'] = 'currency';
        objNewField = new Form(objField);
        break;
      case 'decimal':
        objField['controlType'] = 'decimal';
        objNewField = new Form(objField);
        break;
      //Also a separate input for email.
      case 'email':
        objField['controlType'] = 'email';
        objNewField = new Form(objField);
        break;
      //Also a separate input for email.
      case 'picker':
        objField['controlType'] = 'picker';
        objNewField = new Form(objField);
        break;
      // Also a separate input for image.
      case 'image':
        objField['controlType'] = 'image';
        objNewField = new Form(objField);
        break;
      //We recreate the feild as a textarea object.
      case 'textarea':
        objNewField = new Textarea(objField);
        break;
      //Display the input as a toggle instead of the usual checkbox.
      case 'toggle':
        objField['controlType'] = 'toggle';
        objNewField = new Form(objField);
        break;
      //If the field is date.
      case 'date':
        objField['controlType'] = 'date';
        //Check if the string is inside the arDateList.
        if (objField['default_value'] != undefined && JSON.stringify(new DateSelectList().list).includes(objField['default_value'])) {
          let customDefaultValue = {
            date: objField['default_value'],
          }
          objField['default_value'] = this.getDateTimePreset(customDefaultValue);
        }
        objNewField = new Form(objField);
        break;
      //If the field required a dialog to add it's data.
      case 'dialog':
        objField['controlType'] = 'dialog';
        if (objField['default_value'] && objField['label'] == 'phone') {
          objField['default_value'] = objField['default_value'].map(phone => {
            return new Phone(phone)
          });
        }
        objNewField = new Form(objField);
        break;
      //We recreate the field as a multiselect object.
      case 'multiselect':
        // If fields is deletable it means it is custom field.
        if (objField['deletable']) {
          let arDropdownValues = objField['options'];
          //Create a dropdown array.
          arDropdownValues = arDropdownValues.map(
            item => {
              item = new Select(item['id'], item['text']);
              return item;
            }
          );
          //Put list as options.
          objField['options'] = arDropdownValues;
        } else {
          //If the multiselect doesn't have any options, we make the default value as it's options.
          if (isEmpty(objField['options']) && objField['default_value']) {
            let arDropdownValues = objField['default_value'];
            // Do we have multiselect email_address field?
            if (objField.key == 'email_address' && Array.isArray(arDropdownValues)) {

              //Create a dropdown array.
              arDropdownValues = arDropdownValues.map(
                item => {
                  item = new Select(item.email, item.email, item.primary);
                  return item;
                }
              );

              // Update default values.
              let arDropdownDefaultValues = objField['default_value'];
              // Map default values, so that it will be compatible in our ng select.
              arDropdownDefaultValues = arDropdownDefaultValues.map(
                item => {
                  // Do we have email address and in string format?
                  if (item.email && typeof(item.email) == 'string') {
                    return item.email;
                  } else {
                    // If we got there check if item is a string format and has value.
                    if (typeof(item) == 'string' && item.length != 0) { return item; }
                  }
                }
              );
              objField['default_value'] = arDropdownDefaultValues;
            } else {

              //Create a dropdown array.
              arDropdownValues = arDropdownValues.map(
                item => {
                  item = new Select(item, item);
                  return item;
                }
              );
            }
            //Put list as options.
            objField['options'] = arDropdownValues;
          }
        }

        if (objField['key'] !== 'email_address' && typeof objField['default_value'] == 'object') {
          let parsedValues = [];

          if (objField['default_value']) {
            objField['default_value'].forEach((defaultValue) => {
              let value = null;

              if (typeof defaultValue == 'object') {
                value = Object.values(defaultValue)[0];
              } else {
                value = defaultValue;
              }

              parsedValues.push(value);
            });
          }

          objField['default_value'] = parsedValues;
        }

        objNewField = new Multiselect(objField);
        break;
      //We recreate the field as a multiselect object.
      case 'dropdown':
        if (objField['list']) {
          objField['options'] = this.getList(objField['list']);
        }
        objNewField = new Dropdown(objField);
        break;
      //If its a datetime, we breakdown the data.
      case 'datetime':
        //If datetime is object (Studio uses this.)
        if (typeof objField['default_value'] == 'object' && objField['default_value'] != null) {
          objField['date'] = objField['default_value']['date'];
          objField['hour'] = objField['default_value']['hour'];
          objField['minute'] = objField['default_value']['minute'];
          objField['meridiem'] = objField['default_value']['meridiem'];
        }
        //Check if the string is inside the arDateList.
        if (
          objField['default_value'] != undefined &&
          objField['default_value']['date'] != undefined &&
          JSON.stringify(new DateSelectList().list).includes(objField['default_value']['date'])
        ) {
          objField['default_value'] = this.getDateTimePreset(objField['default_value']);
        }
        objNewField = new Datetime(objField);
        break;
      //If the data is relate, we recreate it as an relate object.
      case 'relate':
        if (objField['default_value']) {
          let regExr = /_id/g;
          let strTextField = (objField['key'].includes('id')) ? objField['key'].replace(regExr, '_text') : `${objField['key']}_text`;

          objField['default_text'] = arDetails[strTextField];
        }

        objNewField = new Relate(objField);
        break;
      case 'datetimerange':
        objField['controlType'] = 'datetimerange';
        objNewField = new Form(objField);
      break;
      case 'geocoding':
        objField['controlType'] = 'geocoding';
        objNewField = new Form(objField);
      break;
      //If the type is not supported by the centralized form, simply display it in an input field.
      default:
        objField['controlType'] = 'notset';
        objNewField = new Form(objField);
        break;
      //If the field is json.
      case 'json':
        objField['controlType'] = 'json';
        if (objField['default_value'] != undefined && objField['default_value'].toString().length == 19) {
          objField['default_value'] = objField['default_value'].slice(0, -9);
        }
        objNewField = new Json(objField);
        break;
      case 'risk_rating':
        objField['controlType'] = 'risk_rating';
        objNewField = new Form(objField);
        break;
      case 'custom_form_component':
        objField['controlType'] = 'custom_form_component';
        objNewField = new Form(objField);
        break;
      case 'email_address':
        objField['controlType'] = 'email_address';
        objNewField = new Form(objField);
        break;
      case 'multitext':
        objField['controlType'] = 'multitext';
        objNewField = new Form(objField);
        break;
    }

    return objNewField;
  }

  /**
   * Combines the record view and field properties
   * so that it can be easily mapped and generated.
   * @param arView
   * @param arFields
   */
  formData(arView: any, arFields :any, arDetails: any){
    arView = cloneDeep(arView);
    arFields = cloneDeep(arFields);
    this.objFields = cloneDeep(arFields)

    arView = arView.map(
      data => {
        let fields = data['fields'] || [];
        data['fields'] = fields.map(
          fieldsData => {
            fieldsData = fieldsData.map(
              fieldNames => {
                return {[fieldNames]: arFields[fieldNames]};
              }
            );
            return fieldsData;
          }
        );
        return data;
      }
    );
    return this.setData(arView, arDetails);
  }

  /**
   * Arrange the data from the API to be compatible with the dynamic form.
   * @param objData
   */
  arrangeData(objData){
    let items: any = {};

    if (objData) {

      let arData: any = objData;
      //Modify the form groups to a reactive form group.
        items[this.strModule] = arData.map(
        formItems => {
          formItems['id'] = formItems['label'].toLowerCase().replace(/ /g,'_');
          formItems['groups'] = this.toFormGroup(formItems['fields'])
          return formItems;
        }
      );
      //Set the old data for comparison when changes are made.
      this.setOldData(items[this.strModule]);
    }

    return items;
  }


  /**
   * Returns the data back to
   * a format that the API accepts when updating.
   * @param objSaveData - the data to be saved in the API.
   */
  mapDataValues(objSaveData){

    if (objSaveData == undefined) {
      return {};
    }

    let objSave: any = {};
    let arFieldType: any[] = [];

    //Loops the values.
    objSaveData.forEach(
      item => {
        //Merge all form group values into a single object.
        objSave = {...objSave, ...item['groups'].getRawValue()};
      }
    );

    // Loops the field types.
    objSaveData.forEach(
      item => {
        Object.keys(item['fields']).forEach( strKey => {
          arFieldType[item['fields'][strKey]['key']] = item['fields'][strKey]['controlType'];

          // Special case for checkbox. Must explicitly assign to false if value is null or undefined.
          if (item['fields'][strKey]['controlType'] === 'checkbox') {
            if (objSave[item['fields'][strKey]['key']] != undefined && objSave[item['fields'][strKey]['key']] == "") {
              objSave[item['fields'][strKey]['key']] = false;
            }
          // If current field is json. We need to check the format of each field
          } else if (item['fields'][strKey]['controlType'] === 'json') {
            let arFormat = this.arrService.keyFallsBackTo(item['fields'][strKey], 'format', '');
            if (arFormat) {
              let arFormatField = Object.keys(arFormat);
              // Loop the format field
              if (arFormatField.length > 0) {
                arFormatField.forEach( strField => {
                  // Check the format field if there's a readonly metadata. If none, ofcourse its not readonly.
                  let bReadonly = this.arrService.keyFallsBackTo(item['fields'][strKey]['format'][strField], 'readonly', false);
                  // If its readonly
                  if (bReadonly) {
                    // We need to check if the attributes metadata has values
                    if (objSave[item['fields'][strKey]['key']].length > 0) {
                      // If attributes have values. We need to loop it to check if readonly field has value
                      objSave[item['fields'][strKey]['key']].forEach( (arValue, strIndex) => {
                        // If there's a value. We need to empty it.
                        if (arValue[strField] !== '') {
                          objSave[item['fields'][strKey]['key']][strIndex][strField] = '';
                        }
                      });
                    }
                  }
                });
              }
            }
          }
        });
      }
    );

    // Store field type
    this.arDataType = arFieldType;

    //Returned the mapped values.
    return objSave;
  }

  /**
   * Used to send and update only the data that had changes.
   * @param objSaveData
   * @param strButtonTrigger // checks if button pressed is cancel or save
   */
  setSaveData(objSaveData, strButtonTrigger = '') {
    //Where we will save the changed data.
    let objDifference: any = {};
    this.objSaveData = {};
    //Get the previous data.
    let objOldData: any [] = this.mapDataValues(this.arOldData[this.strModule]);
    //Get the latest updates on the form.
    let objNewData: any [] = this.mapDataValues(objSaveData);
    objNewData = {...objNewData, ...this.overrideData};

    // Instantiate email option variable
    let arEmailOption = [];
    // Map values to get email_address option
    let objMetadata = objSaveData.map( form => {
      form.fields.map( field => {
        // If the value is a preset date (usually for custom fields), save the actual date and not the preset date.
        // Ex. If value is "next_year", save the actual next year date.
        if ((field['controlType'] == 'date' || field['controlType'] == 'datetime') && field['default_value'] != "" && field['default_value'] != undefined) {
          if (!moment(field['default_value'], "YYYY-MM-DD HH:mm:ss").isValid()) {
            objDifference[field['key']] = this.getDateTimePreset((field['controlType'] == 'date') ? {date: field['default_value']} : field['default_value']).format((field['controlType'] == 'date') ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss');
          }
        }
        // Do we have multiselect email?
        if (field.key == 'email_address' && field.controlType == 'multiselect') {
          arEmailOption = field.options;
          return arEmailOption;
        }
      })
    });
    // Do we have 'add' mode?
    if (this.strMode == 'add') {
      // Remove all keys with empty and undefined values
      Object.keys(objNewData).forEach( item => {
        let objFieldMetadata = this.objFields[item];
        switch (item) {
          case 'email_address':
            if (objNewData['email_address'] && objFieldMetadata.type === 'multiselect') {
              let arEmails = [];
              let arSelected = objNewData['email_address'];
              // Loop each current email option to get the data
              arEmailOption.forEach( email => {
                // Check if current item exists on the selected email
                if (arSelected.findIndex(findEmail => (findEmail['id'] === email['id'])) !== -1) {
                  // Create multiselect format
                  arEmails.push({ email: email.text, primary: (email.primary == '1') ? '1' : '0' });
                }
              });
              objNewData[item] = arEmails;
            }
          break;
          case 'attributes':
            // FC-2419: Format the asset attributes
            if (this.strModule === "assets" && objNewData["attributes"]) {
              var objAssetAttribute: object = {};
              objNewData["attributes"].forEach( field => {
                objAssetAttribute[field["key"]] = field["default_value"];
              });
              objNewData["attributes"] = objAssetAttribute;
            }
          break;
          default:
            // If not boolean, removed the object from its parent.
            if (typeof objNewData[item] != 'boolean' && (objNewData[item] === '' || objNewData[item] === undefined)) {
              delete objNewData[item];
            }

          break;
        }
      });
      // Set objSaveData value
      this.objSaveData = objNewData;
      // return true, we don't need to compare data because it is add record.
      return true;
    }

    //Loop through the data.
    Object.keys(objOldData).forEach(
      item => {

        //If the values are a simple string, we compare if they are different.
        if (typeof objOldData[item] == 'string' || typeof objNewData[item] == 'string') {
          //If they are different, add them to the objDifference.
          // Check field type
          switch(this.arDataType[item]) {
            case 'date':
            case 'datetime':
              objDifference[item] = objNewData[item];
              if (objOldData[item] && typeof(objOldData[item]) == 'string') {
                if (objOldData[item].match(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/)) {
                  let pipe = new DatePipe('en-US');
                  let strNewDate = (objNewData[item] instanceof moment) ? objNewData[item].format('YYYY-MM-DD'): objNewData[item];

                  // FC-2367: Transforming a date '2019-12-09 00:00:00' is a bug in safari, see link below. Added replace before transforming the data
                  // @link https://github.com/angular/angular/issues/12334
                  if (strNewDate && pipe.transform(objOldData[item].replace(/\s/g, "T"), 'short') != pipe.transform(strNewDate.replace(/\s/g, "T"), 'short')) {
                    objDifference[item] = strNewDate;
                  }
                }
              }
            break;
            default:
              objDifference[item] = objNewData[item];
            break;
          }
        } else {
          //If it isnt a simple string, we turn it into a string and compare if they are different.
          objDifference[item] = objNewData[item];
        }
      }
    );

    // FC-1788: Fix when transitioning the stage to Close Lost for the first time, the Reason for Lost will be blank.
    // We need to make sure that all the record from objNewData should add on the request
    Object.keys(objNewData).forEach( item => {
      // If the key from the objNewData is not existing on objOldData, add that key on objDifference
      if(typeof(objOldData[item]) === 'undefined') {
        objDifference[item] = objNewData[item];
      } else if (this.strModule == 'users' && item === 'department_id' && ! isEmpty(objDifference[item]) && objNewData[item][0]['primary'] == undefined) {
        // The primary key gets removed whenever we toggle the department restriction so we need to retain the current data
        objDifference[item] = objOldData[item];
      }
    });

    if (this.objFields.email_address && this.objFields.email_address.type === 'multiselect') {
      // For formatting multiselect email address
      // Do we have email options?
      objDifference['email_address'] = arEmailOption
        .filter(email => {
          return objNewData['email_address']
            .findIndex(new_email => {
              // Not sure why, but saving the form without modifying the email address
              // results in objNewData['email_address'] to have an array of strings but
              // in normal cases, will have an array of Select fields.
              let _new_email = new_email['id'] || new_email;

              return email['id'] === _new_email;
            }) !== -1
        })
        .map(email => {
          return { email: email.text, primary: email.primary === '1' };
        });
    }

      // FC-2419: Format the asset attributes
      if (this.strModule === "assets" && objDifference["attributes"]) {
        var objAssetAttribute: object = {};
        objDifference["attributes"].forEach( field => {
            objAssetAttribute[field["key"]] = field["default_value"];
        });
        objDifference["attributes"] = objAssetAttribute;
      }

    if (strButtonTrigger === 'cancel') {
      // if user clicks cancel and changes were made
      if (isEmpty(objDifference)) {
          return false;
      }

      return true;

    // When save button is clicked
    } else {
      this.objSaveData = objDifference;

      return true;
    }
 }

  /**
   * If an input field is a tag, we use this to add a new data
   * to the tag list.
   * @param strItem - the item to add.
   * @param strModule - the module (only if relate).
   * @param event - the previous values of the items.
   */
  addItem(strItem, strModule, event){


    let objAddItem: any = {
      strItem,
      strModule,
      'arItemValues': event,
    };

    this.obvAddItem.next(objAddItem);
  }

  /**
   * Tranforms the metadata into a reactive form group.
   * @param arformItems
   */
  toFormGroup(arformItems: Form<any>[] ) {
    let group: any = {};
    //Loop and change it to a FormControl object.
    arformItems.forEach(item => {
      group[item.key] = this.toFormControl(item);
    });
    return new FormGroup(group);
  }

  /**
   * Transforms a field object to a form control.
   * @param objField
   */
  toFormControl(objField) {


    let objFormControl: any = {};

    if (objField['controlType'] == 'address' && objField.required) {
      objFormControl = new FormControl(objField.default_value || '', addressValidator());
    } else if (objField.required) {
      // FC-1822: Convert the utc time to local timezone
      if (objField.controlType == 'datetime' && objField.default_value) {
        let toUtc = moment.utc(objField.default_value).toDate();
        objField.default_value = moment(toUtc).format('YYYY-MM-DD HH:mm:ss');
      }
      objFormControl = new FormControl(objField.default_value || '', Validators.required);
    } else if (objField.controlType == 'currency' && objField.precision) {
      objFormControl = new FormControl(objField.default_value || '', amountValidator(objField.precision));
    } else if (objField.controlType == 'dialog') {
      let arPhone: Phone[] = (typeof objField.default_value == 'string') ? [] : objField.default_value;
      objFormControl = new FormControl(arPhone, phoneValidator());
    } else {
      // Do we have date or datetime and has value?
      if ((objField.controlType == 'date' || objField.controlType == 'datetime') && objField.default_value) {

        if (objField.controlType == 'datetime') {
          let toUtc = moment.utc(objField.default_value).toDate();
          objField.default_value = moment(toUtc).format('YYYY-MM-DD HH:mm:ss');
        } else {
          objField.default_value = moment(objField.default_value).format('YYYY-MM-DD');
        }
      }
      if (typeof objField.default_value != 'boolean' && typeof objField.default_value != 'number') {
        // Set default value to form control
        objFormControl = new FormControl(objField.default_value || '');
      } else {
        objFormControl = new FormControl(objField.default_value);
      }
    }

    if (objField.controlType === 'risk_rating') {
      const [likelihood, consequences] = objField.default_value || [null, null];

      // [0] = likelihood [1] = consequences
      objFormControl = new FormArray([
        new FormControl(likelihood, [Validators.required]),
        new FormControl(consequences, [Validators.required]),
      ]);
    }

    if (objField.controlType === 'custom_form_component') {
      if (isUndefined(objField.custom_form_options)) {
        throw `field "${objField.key}" is missing the "controls" property which is necessary to build the appropriate controls`;
      }

      if (objField.multiple) {
        return new FormArray([], [
          ... (objField.required ? [Validators.required] : [])
        ]);
      }

      if (objField.custom_form_options.is_object) {
        return new FormGroup({}, [
          ... (objField.required ? [Validators.required] : [])
        ]);
      }

      return new FormControl(objField.default_value, [
        ... (objField.required ? [Validators.required] : [])
      ]);
    }

    return objFormControl;
  }

  /**
   * We save the initial data so we can check
   * for changes if the form is indeed changed.
   * @param objSaveData
   */
  setOldData(objSaveData){
    //We use deep cloning to create a new instance
    //instead of retaining the form instance.
    this.arOldData[this.strModule] = cloneDeep(objSaveData);
    this.arOldRawData[this.strModule] = cloneDeep(this.getRawData(objSaveData))
  }

  /**
   * Set the values that relate fields would use.
   * @param arRelateValues
   */
  setRelateValues(arRelateValues){
    this.arRelateValues = arRelateValues;
  }

  /**
   * Set the module that will use that centralized edit.
   */
  setModule(strModule){
    this.strModule = strModule;
  }

  /**
   * Set the mode of transaction.
   */
  setMode(strMode){
    this.strMode = strMode;
  }

  /**
   * Get the list the dropdown belongs to.
   * NOTE: This is temporary. All dropdown list should be either
   * in API or local constant lists.
   */
  getList(strListCategory){
    switch (strListCategory){
      case 'locale':
        let arLocale: Select[] = [];
        Object.keys(Locale).forEach(
          item => {
              arLocale.push(new Select(Locale[item],'language.' + Locale[item]))
          }
        );
        return arLocale;
      case 'travel':
        //TEST DATA FOR TRAVEL
        return [
          new Select('731d3aeb-15fc-4c1e-8560-f98f5715c123', 'Travel 1'),
          new Select('6543ba1e-7624-49d1-a924-bd133edfff8e', 'Travel 2'),
          new Select('16e7282d-e31f-471b-beac-ae8ffc366d98', 'Travel 3'),
        ];
      case 'billing':
        //TEST DATA FOR TRAVEL
        return [
          new Select('3e859030-4f45-40de-8b13-f07379a09b4e', 'Billing 1'),
          new Select('257363ab-53d8-4a6e-9f43-cedd89fe9d25', 'Billing 2'),
          new Select('1d745d2b-0d2c-4583-abb6-4c6769453663', 'Billing 3'),
        ];
      case 'job_title':
        //TEST DATA FOR TRAVEL
        return [
          new Select('englv1', 'Engineer Level 1'),
          new Select('archlv3', 'Architect Level 3'),
          new Select('rmlv5', 'Repair Man Level 5'),
        ];
      case 'phone':
        //TEST DATA FOR TRAVEL
        return [
          new Select('home', 'lists.home'),
          new Select('work', 'lists.work'),
          new Select('mobile', 'lists.mobile'),
          new Select('others', 'lists.others'),
        ];
      case 'email':
        //TEST DATA FOR TRAVEL
        return [
          new Select('work', 'lists.work'),
          new Select('personal', 'lists.personal'),
          new Select('others', 'lists.others'),
        ];
      default:
        return [];

    }
  }

    /**
   * This functions gets the date/datetime based on
   * the given preset.
   * @param objDateTime
   */
  getDateTimePreset(objDateTime){
    // Check if not empty
    if (objDateTime['date']) {
      //Temporary holder for the final formed datetime.
      let strTempDateTime: any;

      switch(objDateTime['date']) {
        case 'today':
          strTempDateTime = moment();
          break;
        case 'yesterday':
          strTempDateTime = moment().subtract(1, 'days');
          break;
        case 'tomorrow':
          strTempDateTime = moment().add(1, 'days');
          break;
        case 'next_week':
          strTempDateTime = moment().add(7, 'days');
          break;
        case 'next_monday':
          strTempDateTime = moment().add(1, 'weeks').startOf('isoWeek');
          break;
        case 'next_friday':
          strTempDateTime = moment().add(1, 'weeks').startOf('isoWeek').add(4, 'days');
          break;
        case 'two_weeks':
          strTempDateTime = moment().add(2, 'weeks');
          break;
        case 'next_month':
          strTempDateTime = moment().add(1, 'months');
          break;
        case 'first_day_month':
          strTempDateTime = moment().add(1, 'months').startOf('month');
          break;
        case 'three_months':
          strTempDateTime = moment().add(3, 'months');
          break;
        case 'six_months':
          strTempDateTime = moment().add(6, 'months');
          break;
        case 'next_year':
          strTempDateTime = moment().add(1, 'years');
          break;
      }

      //Initialize the time, set to 0 if none given.
      let intHour = objDateTime['hour'] ? Number(objDateTime['hour']) : 0;
      let intMin = objDateTime['minute'] ? Number(objDateTime['minute']) : 0;

      //If the meridiem is a pm, we add 12 to the hrs since we need a 24-hr format
      //for the momentjs to read.
      if (objDateTime['meridiem'] && objDateTime['meridiem'] == "pm") {
        intHour = ((intHour + 12) == 24) ? 0 : intHour + 12;
      }

      //Return the final time as a Date object.
      return strTempDateTime.set({h: intHour, m:intMin});
    }

    return '';
  }

  getRawData(arData): object {
    var objOldRawData: object = {};
    arData.forEach(
      item => {
        //Merge all form group values into a single object.
        objOldRawData = {...objOldRawData, ...item['groups'].getRawValue()};
      }
    );
    return objOldRawData;
  }

  /**
   * Check if the any of the given attributes where changed
   */
  wasChanged(attributes: string[]): boolean {
    const oldData = this.getRawData(this.arOldData[this.strModule]);
    const newData = this.objSaveData;

    for (let attribute of attributes) {
      if (! isEqual(get(oldData, attribute), get(newData, attribute))) {
        return true;
      }
    }

    return false;
  }

  /**
   * This will override/merge original data by given data.
   * This can be used if you used the shared form and set
   * data from other component.
   *
   * @param data
   */
  overrideDataBeforeSave(data: LooseObject) {
    this.overrideData = data;
  }

  /**
   * Get the unsaved changes from local storage and
   * compare to given data to get the difference
   * @param autoSaveKey
   * @param dataToCompare
   * @returns
   */
  getUnsavedChangesData(recordId:string, module: string, dataToCompare: any, parentRecordId: string = '') {
    let localStorageData = JSON.parse(this.localStorageService.getItem(this.formatAutoSaveKey(module, recordId, parentRecordId)));
    let dataChanges = [];
    const now = new Date();

    if (filled(get(localStorageData, 'ttl')) && now > new Date(localStorageData['ttl'])) {
      this.localStorageService.removeItem(this.formatAutoSaveKey(module, recordId, parentRecordId));
    } else if (!isEmpty(localStorageData)) {
        dataChanges = this.arrService.findDifference(localStorageData, dataToCompare);
    }

    return dataChanges;
  }

  setUnsavedChangesDataToLocalStorage(unsavedChangesData: SetUnsavedChangesData) {
    if (!isEmpty(unsavedChangesData.data_to_save)) {
      if (filled(unsavedChangesData.related_fields) && filled(unsavedChangesData.related_changes)) {
        unsavedChangesData.related_fields.forEach(fieldName => {
          if (!isEmpty(unsavedChangesData.related_changes[fieldName])) {
            let fieldText = MODULE_RELATE_TEXT_FIELDS[fieldName];
            unsavedChangesData.data_to_save[fieldText] = unsavedChangesData.related_changes[fieldName];
          }
        });
      }

      const now = new Date();
      // Add 1 day (24 hours) to the current time
      const expirationDate = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      unsavedChangesData.data_to_save['ttl'] = expirationDate;

      this.localStorageService.setItem(this.formatAutoSaveKey(unsavedChangesData.module, unsavedChangesData.record_id, unsavedChangesData.parent_record_id), unsavedChangesData.data_to_save);
    }
  }

  removeUnsavedChangesDataToLocalStorage(recordId: string, module: string, parentRecordId: string = '') {
    let unsavedChangesFromLocalStorage = this.localStorageService.getItem(this.formatAutoSaveKey(module, recordId, parentRecordId));

    if (!isEmpty(unsavedChangesFromLocalStorage)) {
      this.localStorageService.removeItem(this.formatAutoSaveKey(module, recordId, parentRecordId));
    }
  }

  removeAutoSaveInterval(autoSaveIntervalId: number) {
    if(filled(autoSaveIntervalId)) {
      clearInterval(autoSaveIntervalId);
    }
  }

  formatAutoSaveKey(module: string, recordId: string = '', parentRecordId: string = '') {
    let activeClient = this.clientStorage.getActiveClient();
    let clientId = get(activeClient, 'client_id', '');

    return filled(recordId) ? sprintf('auto_save_%s', recordId) : sprintf('auto_save_%s_new_%s_%s', clientId, module, parentRecordId);
  }
}

export interface Field {
  type: 'relate' | 'datetime' | 'date' | 'dropdown' | 'currency' | 'text' | 'textarea' | 'number' | 'address' | 'checkbox' | 'multiselect' | 'increment' | 'dialog' | 'json';
  default_value: any;
  label: string;
  key?: string;
}

export interface RelateField extends Field {
  type: 'relate';
  module: string;
}

export interface DropdownField extends Field {
  type: 'dropdown';
  options: Select[];
}

export type ModuleMetadata = {
  [key: string]: Field;
}