<div *ngIf="bLoading" class="align-center">
  <inline-view-loader [isLoading]="bLoading"></inline-view-loader>
</div>
<div *ngIf="!bLoading">
  <div class="d-flex justify-content-around activities-option tabs-content cursor-default" >
    <span id="widgetStockReceiptsReceiveButton" class="span-links pointer"
      (click)="openUpdateStockReceiptDialog(null, 'receipt', 'create')">
      <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
      {{ 'receive_stock' | translate }}
    </span>
    <span id="widgetStockCreditButton" class="pointer"
      (click)="openUpdateStockReceiptDialog(stockCreditCreate, 'credit', 'create', 'create_button')"
      [ngClass]="hasRecievedStockReceipt ? 'span-links' : ''">
      <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
      {{ 'return_stock' | translate }}
    </span>
  </div>
  <div class="d-flex justify-content-around activities-option tabs-content border-top cursor-default">
    <div class="col-sm-6 pl-0">
      <span class="module-title">
        <fa-icon [icon]="['fa', 'receipt']" aria-hidden="true"></fa-icon>
        {{ 'stock_receipts' | translate }}
      </span>
    </div>
    <div class="col-sm-6 pr-0">
      <nav class="pagination-section">
        <ul class="pagination">
          <refresh-pagination-btn id="widgetChecklistsRefreshButton" (refresh)="fetchList()"></refresh-pagination-btn>
        </ul>
      </nav>
    </div>
  </div>
  <ng-container *ngIf="arStockReceipt.length">
    <div *ngFor="let objStockReceipt of arStockReceipt;" class="files-body">
      <div class="container">
        <div class="row border-top default-widget-container">
          <div class="col-sm-1"></div>
          <div class="col-sm-6">
            <div class="row" >
              <div class="col-12" matTooltip="{{ 'warehouse_name' | translate }}: {{ objStockReceipt.warehouse_name | translate }} ( {{ objStockReceipt.warehouse_type | translate }} )">
                <fa-icon class="pr-1" [icon]="['fas', 'warehouse-alt']"></fa-icon>
                <span >{{ objStockReceipt.warehouse_name }} ( {{ objStockReceipt.warehouse_type | translate }} )</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-12 truncate" matTooltip="{{ 'created_at' | translate }}: {{ objStockReceipt.created_at | local | format:'lll' }}">
                <fa-icon class="pr-1" [icon]="['fas', 'calendar']"></fa-icon>
                <span >{{ objStockReceipt.created_at | local | format:'lll' }}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-1">
            <div class="row">
              <div class="col-12">
                <fa-icon id="widgetStockReceiptsViewButton" *ngIf="objStockReceipt.status == 'received'" class="text-primary pointer float-right" [icon]="['fas', 'eye']" (click)="openUpdateStockReceiptDialog(objStockReceipt, objStockReceipt.type, 'view', 'updated_button')"></fa-icon>
                <fa-icon id="widgetStockReceiptsEditButton" *ngIf="objStockReceipt.status == 'draft'" class="text-success pointer float-right" [icon]="['fas', 'pencil']" (click)="openUpdateStockReceiptDialog(objStockReceipt, objStockReceipt.type, 'edit', 'updated_button')"></fa-icon>
              </div>
            </div>
          </div>
          <div class="col-sm-1">
            <fa-icon
              matTooltip="{{ objStockReceipt.type | translate }}"
              [icon]="['fas', 'receipt']"
              [ngClass]="{'text-success': objStockReceipt.type == 'receipt', 'text-danger': objStockReceipt.type == 'credit'}"></fa-icon>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-12" matTooltip="{{ 'reference' | translate }}: {{ objStockReceipt.reference | placeholdWithString }}">
                <span >{{ objStockReceipt.reference | placeholdWithString }}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-12 truncate" matTooltip="{{ (objStockReceipt.type == 'receipt' ? 'received_at' : 'returned_at') | translate }}: {{ (objStockReceipt.status == 'received' ? (objStockReceipt.updated_at | local | format:'lll') : '--') }}">
                <fa-icon class="pr-1" [icon]="['fas', 'calendar']"></fa-icon>
                <span >{{ (objStockReceipt.status == 'received' ? (objStockReceipt.updated_at | local | format:'lll') : '--') }}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-1">
            <div class="row">
              <div class="col-12">
                <fa-icon id="widgetStockReceiptsDeleteButton" class="text-warning pointer float-right pr-2px" [icon]="['fas', 'trash-alt']" (click)="deleteStockReceipt(objStockReceipt)"></fa-icon>
              </div>
            </div>
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-6">
            <div class="row" >
              <div class="col-12" matTooltip="{{ 'status' | translate }}: {{ (objStockReceipt.type == 'receipt' ? objStockReceipt.status : 'returned') | translate }}">
                <span >{{ (objStockReceipt.type == 'receipt' ? objStockReceipt.status : 'returned') | translate }}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-5"></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
