<header>
  <div class="d-flex dialog-header">
    <h3 class="title">
      <fieldmagic-icon icon='barcode'>
        <span>{{ 'barcode' | translate }}</span>
      </fieldmagic-icon>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="printBarcode()" class="btn ml-1 btn-primary"
            [disabled]="!this.selectedRecord.length || loadingButton.csv || loadingButton.print">
            <fieldmagic-icon [icon]="loadingButton.print ? 'spinner' : 'file-csv'" [spin]="loadingButton.print">
              <span>{{ 'print' | translate }}</span>
            </fieldmagic-icon>
          </button>
        </li>
        <li class="list-inline-item">
          <button type="button" (click)="dialogClose()" class="btn ml-1 btn-danger">
            <fieldmagic-icon icon='times'>
              <span>{{ 'cancel' | translate }}</span>
            </fieldmagic-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr>
<mat-dialog-content>
  <div class="col-12">
    <div class="row d-flex flex-row">
      <div class="header-label" style="width: 7%"></div>
      <div class="header-label" style="width: 70%">
        <b>{{ 'item' | translate }}</b>
      </div>
      <div class="header-label" style="width: 23%">
        <b>{{ 'no_of_copies' | translate }}</b>
      </div>
    </div>
    <ng-container *ngIf="barcodeForm.length; else noSelectedRecord">
      <ng-container *ngFor="let data of barcodeForm">
        <div class="row d-flex flex-row">
          <div class="header-label align-content-center pl-2" style="width: 7%">
            <span class="selected-item">
              <fieldmagic-icon
                class="pointer text-danger"
                matTooltip="{{ 'remove' | translate }}"
                icon='minus-circle'
                (click)="onRemoveItem(data)">
              </fieldmagic-icon>
            </span>
          </div>
          <div class="header-label align-content-center" style="width: 70%">
            <span class="selected-item">
              [{{ data.item.code }}] {{ data.item.text }}
            </span>
          </div>
          <div class="header-label" style="width: 23%">
            <fieldmagic-number-input
              [(ngModel)]="data.copy"
              min="1"
              max="100">
            </fieldmagic-number-input>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #noSelectedRecord>
      <div class="row d-flex flex-row border">
        <div class="header-label align-content-center" align="center" style="width: 100%">
          <span class="selected-item">
            {{ 'no_selected_item' | translate }}
          </span>
        </div>
      </div>
    </ng-template>
  </div>
</mat-dialog-content>