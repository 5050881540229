import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { blank } from '../../../../utils/common';
import { isString } from 'lodash-es';

@Component({
  selector: 'fieldmagic-page-go-back-button',
  template: `
    <fieldmagic-primary-button
      icon="arrow-left"
      variant="link"
      label="{{ label$ | async }}"
      (click)="onClick()"
      [disabled]="disabled"
    >
    </fieldmagic-primary-button>
  `
})
export class PageGoBackButtonComponent {
  @Input() link: string | string[];

  @Input()
  set target(value: 'list' | 'generic') {
    if (value == 'list') {
      this.label$.next('Back To List');
    } else {
      this.label$.next('Back');
    }
  }

  @Input() disabled: boolean = false;

  readonly label$ = new BehaviorSubject<string>(null);

  constructor(
    private readonly _router: Router,
  ) {}

  onClick(): void {
    if (blank(this.link)) {
      throw 'Missing [link] property';
    }

    if (isString(this.link)) {
      this.link = [this.link];
    }

    this._router.navigate(this.link, {
      replaceUrl: true,
    });
  }
}