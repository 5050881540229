import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material';
import { isNil } from 'lodash-es';

type OnChangeHandler = (value?: boolean) => void;
type OnTouchedHandler = () => void;

@Component({
  selector: 'fieldmagic-checkbox-input',
  template: `
    <mat-slide-toggle
      *ngIf="!useNative; else native"
      [id]="id"
      [disabled]="isDisabled$ | async"
      class="font-size-11"
      [(ngModel)]="value"
      (change)="onSwitchChange($event)"
      (blur)="onTouched()"
      [matTooltip]="tooltip | translate"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </mat-slide-toggle>

    <ng-template #native>
      <div class="d-flex d-flex-gap">
        <input
          type="checkbox"
          [(ngModel)]="value"
          [id]="id"
          [disabled]="isDisabled$ | async"
          (change)="onNativeChange($event.target)"
        />
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </ng-template>

    <ng-template #content>
      <span
        *ngIf="label | filled"
        class="text-label"
      >
        {{ label | translate }}
      </span>
      <ng-content *ngIf="label | blank"></ng-content>
    </ng-template>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxInputComponent),
      multi: true,
    },
  ],
})
export class CheckboxInputComponent implements ControlValueAccessor {
  @Input() id: string;

  @Input() label: string;

  @Input() useNative: boolean = false;

  @Input() tooltip?: string

  @Output('change') $onChange = new EventEmitter<boolean>();

  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  value: boolean;

  /**
   * Callback when input is touched
   */
  onTouched: OnTouchedHandler = () => { };

  private _onChange: OnChangeHandler;

  /**
   * @inheritdoc
   */
  registerOnChange(fn: OnChangeHandler): void {
    /// since we have a custom onChanged handler this
    /// will be an internal thing provided to update form accessor
    /// which will be notified in our onChange method
    this._onChange = fn;
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: OnTouchedHandler): void {
    this.onTouched = fn;
  }

  /**
   * {@inheritdoc}
   */
  setDisabledState(disabled: boolean): void {
    this.isDisabled$.next(disabled);
  }

  /**
   * @inheritdoc
   */
  writeValue(value?: boolean): void {
    this.value = value || false;
  }

  onSwitchChange = (event: MatSlideToggleChange): void =>
    this._triggerChangeEvent(event.checked);

  onNativeChange = (event: HTMLInputElement): void =>
    this._triggerChangeEvent(event.checked);

  private _triggerChangeEvent(checked: boolean): void {
    this.$onChange.emit(checked);

    if (!isNil(this._onChange)) {
      this._onChange(checked);
    }
  }
}
