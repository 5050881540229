import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { DocumentService } from '../../../services/document.service';
import { FileService } from '../../../services/file/file.service';
import { LambdaService } from '../../../services/lambda.service';
import { NotificationService } from '../../../services/notification.service';
import { blank, isId, filled } from '../../../shared/utils/common';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormControl , Validators } from '@angular/forms';
import { FileUploadInterface } from '../../../objects/file-upload';
import { LooseObject } from '../../../objects/loose-object';
import { get } from 'lodash-es';

@Component({
  selector: 'app-upload-document-dialog',
  templateUrl: './upload-document-dialog.component.html',
  styleUrls: ['./upload-document-dialog.component.scss']
})
export class UploadDocumentDialogComponent implements OnInit {

    public isFileDropped: boolean = false;
    public isFileUploaded: boolean = false;
    public form: FormGroup;
    public bIsSaving: boolean = false;

    objFields: LooseObject = {
        document_name: {
            controlType: 'text',
            key: 'document_name',
            label: 'document_name',
            required: true,
            default_value: null,
            default_text: '',
            max_length: 128,
        },
        linked_asset_types: {
            controlType: "relate",
            key: "linked_asset_types",
            label: "linked_asset_types",
            module: "asset_types",
            multiple: true,
            hide_label: false,
            default_value: [],
            default_text: '',
        },
    };

    @HostListener('window:keyup.esc') onKeyUp() {
      this.cancelDialog();
    }

    get isSaveDisabled(): boolean {
        const strDocumentName = get(this.form.get('document_name'), 'value');
        const objFile = get(this.form.get('document'), 'value');

        return blank(strDocumentName) || blank(objFile) || this.bIsSaving || this.isFileDropped;
    }

    get hasFile(): boolean {
        const objFile = get(this.form.get('document'), 'value')

        return filled(objFile);
    }

    get uploadedFilename(): string {
        return get(this.form.get('document'), 'value.name', '');
    }

    constructor(
      public dialogRef: MatDialogRef<UploadDocumentDialogComponent>,
      public fileService: FileService,
      public documentService: DocumentService,
      public lambdaService: LambdaService,
      private notifService: NotificationService,
    ) { }

    ngOnInit() {
        this.form = new FormGroup({
            document_name: new FormControl(null, Validators.required),
            document: new FormControl(null, Validators.required),
            linked_asset_types: new FormControl([]),
        });

        this.dialogRef.backdropClick().subscribe(_ => {
            this.cancelDialog();
        });
    }

    /**
     * Closes the dialog
     */
    cancelDialog() {
        if (this.form.touched || this.form.dirty || this.isFileDropped || this.isFileUploaded) {
            // Pop-up modal for confirmation
            this.notifService.sendConfirmation('confirm_cancel')
                .filter(confirmation => confirmation.answer === true)
                .subscribe(() => {
                    this.dialogRef.close();
                });
        } else {
            this.dialogRef.close();
        }
    }

    /**
     * Triggers when saving a document, passes value of the document to the main component for saving
     *
     * @returns {void}
     */
    onSubmit(): void {
        this.form.markAsDirty();
        this.form.markAsTouched();

        // Check if form is valid
        if (this.form.valid) {

            // Flag saving to true.
            this.bIsSaving = true;

            // Prepare initial Lambda request
            let arInitialRequest = {
                document_id : get(this.form.value, 'document.upload_name', '').split('/').pop(),
                field_checker : true
            };

            // Check if uploaded file has form fields available
            this.lambdaService.mergeFormFields(JSON.stringify(arInitialRequest))
            .subscribe(
                data => {
                    const document: Document = this._getFormattedDocument(get(data, 'available_fields', {}));

                    // Add the document
                    this.documentService.addDocument(JSON.stringify([document])).pipe(
                        finalize(() => this.bIsSaving = false)
                    ).subscribe(
                        data => {
                            this.notifService.notifySuccess('file_create_success');
                            this.dialogRef.close({data : data});
                        }
                    );

                },
                error => this.bIsSaving = false
            );
        } else {
            this.notifService.notifyError('required_notice');
        }
    }

     /**
     *
     * Triggered when a document is dropped from the file input
     *
     * @param objData - the document that is dropped
     */
    onFileChange(objData) {

        let reader = new FileReader();
        // If file is valid
        if(objData.target.files && objData.target.files.length) {

            const [file] = objData.target.files;
            reader.readAsDataURL(file);

            let strFileExtension = file.name.split('.').pop();

            // Check if file is pdf
            if(strFileExtension === "pdf") {
                // if file size is less than 30mb
                if(file.size/1024/1024 < 30) {

                    reader.onload = () => {
                        this.isFileDropped = true;

                        this.fileService.upload(file).subscribe((response) => {
                            let objFile = this.fileService.objFile;
                            this.isFileDropped = false;
                            this.isFileUploaded = true;

                            this.form.patchValue({
                                document: {
                                    name: objFile.name,
                                    size: objFile.size / 1024,
                                    type: objFile.type,
                                    upload_name : response['filename']
                                }
                            });
                        });
                    };
                } else {
                    this.notifService.notifyWarning('file_size_limit');
                }
            } else {

                this.notifService.notifyWarning('pdf_only');
            }
        }
    }

     /**
     * Gets the formatted data for submission.
     *
     * @param {any} availableFields
     *
     * @returns {Document}
     */
    private _getFormattedDocument(availableFields: any): Document {
        const formValue: LooseObject = this.form.value;
        const arLinkedAssetTypes: string[] = get(formValue, 'linked_asset_types', [])
            .map(item => get(item, 'id'))
            .filter(item => isId(item));

        return {
            document_name: formValue.document_name,
            document: formValue.document,
            available_fields: availableFields,
            is_library_document: true,
            linked_asset_types: arLinkedAssetTypes
        };
    }
}

export interface Document {
    document_name: string,
    document: FileUploadInterface,
    available_fields: any;
    is_library_document: boolean,
    linked_asset_types: string[],
    version?: number;
    current_data?: Document;
}