<ng-container *ngIf="quoteForm && bDialogLoaded">
  <div [formGroup]="quoteForm">
    <header>
      <div class="d-flex dialog-header">
        <h3 class="dialog-title" translate>
          <div *ngIf="!isTemplate">
            <fa-icon [icon]="['fas', 'plus-circle']" transform="shrink-3" class="dialog-title"></fa-icon>
            {{ strViewType + '_quote' | translate }} {{ strQuoteNumber }}
          </div>

          <div *ngIf="isTemplate" class="mb-1">
            <input *ngIf="editText"  #quotename (focusout)="focusInput(false)" class="form-control" id="quote_name" name="quote_name" formControlName="quote_name"/>
            <div *ngIf="!editText" class="dialog-title">
              <span (click)="focusInput(true)">&nbsp;{{ quoteForm.controls['quote_name'].value }}</span>
              <fa-icon [icon]="['fas', 'pencil']" transform="shrink-3" class="ml-2"></fa-icon>
            </div>

          </div>
        </h3>
        <div class="ml-auto">

          <ul class="list-inline pull-right">
            <li class="list-inline-item">
              <div [ngClass]="(increasedProfit) ? 'profit-increase' : 'profit-decrease'">
                <span class="pl-1">{{ totalProfit | currency }} ({{ profitPercentage }}%)</span>
              </div>
            </li>

            <ng-container *ngIf="strViewType === 'edit' && this.strModule !== 'quote_templates'">
              <li class="list-inline-item">
                <form-templates-generator module-name="quotes" [module-id]="quoteId"></form-templates-generator>
              </li>

              <li *ngIf="data['quote']['is_primary'] === false; else removePrimary" class="list-inline-item">
                <button type="button" (click)="setAsPrimary(true)" class="btn btn-primary">
                  <fa-icon [icon]="['fas', 'crown']"></fa-icon>
                  <span class="pl-1">{{ 'set_primary' | translate }}</span>
                </button>
              </li>

              <ng-template #removePrimary>
                  <li class="list-inline-item">
                    <button type="button" (click)="setAsPrimary(false)" class="btn btn-primary">
                      <fa-icon [icon]="['fas', 'crown']"></fa-icon>
                      <span class="pl-1">{{ 'unset_primary' | translate }}</span>
                    </button>
                  </li>
              </ng-template>

            </ng-container>


            <li *ngIf="!isTemplate" class="list-inline-item">
              <form-real-time-pdf-preview
                [disabled]="bSubmitted || (whenInProgress$ | async)"
                (click)="onPreview()"
                [trigger-event]="realTimePreviewTemplate.asObservable()"
                (onComplete)="whenInProgress$.next(false)">
              </form-real-time-pdf-preview>
            </li>

            <li class="list-inline-item" *ngIf="! isTemplate">
              <button  (click)="saveTemplateName()" class="btn btn-primary" [disabled]="bSubmitted">
                <ng-container *ngIf="bHideTemplateButton == null || bHideTemplateButton === true">
                  <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'file-plus']"></fa-icon>
                  <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                </ng-container>
                <span class="pl-1">{{ 'save_template' | translate }}</span>
              </button>
            </li>

            <li *ngIf="!isTemplate" class="list-inline-item">
              <form-templates-save-and-preview-btn
                [disabled]="bSubmitted"
                (click)="saveAndPreview()"
                [trigger-event]="previewTemplate.asObservable()">
              </form-templates-save-and-preview-btn>
            </li>

            <ng-container *ngIf="!isTemplate">
              <li class="list-inline-item">
                <button *ngIf="!bSubmitted && bHideTemplateButton == null || bHideTemplateButton === false"
                  (click)="save()" class="btn btn-primary" [disabled]="bSubmitted">
                  <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'save']"></fa-icon>
                  <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                  <span class="pl-1">{{ 'save' | translate }}</span>
                </button>
              </li>
            </ng-container>

            <li *ngIf="isTemplate" class="list-inline-item">
              <button *ngIf="isTemplate" (click)="save()" class="btn btn-primary" [disabled]="bSubmitted">
                <ng-container *ngIf="bHideTemplateButton == null || bHideTemplateButton === true">
                  <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'file-plus']"></fa-icon>
                  <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                </ng-container>
                <span  class="pl-1">{{ 'save' | translate }}</span>
              </button>
            </li>

            <li class="list-inline-item">
              <button type="button" (click)="cancelDialog()" class="btn btn-danger">
                <fa-icon [icon]="['fas', 'times']"></fa-icon>
                <span class="pl-1">{{ 'close' | translate }}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <hr />
    </header>


    <ul class="nav nav-tabs" id="quoteTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="quote-editor-tab" data-toggle="tab" href="#quote-editor" role="tab" aria-controls="quote-editor" aria-selected="false">{{ 'quote' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="cover-letter-tab" data-toggle="tab" href="#cover-letter" role="tab" aria-controls="cover-letter" aria-selected="false">{{ 'cover_letter' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="documents-tab" data-toggle="tab" href="#documents" role="tab" aria-controls="documents" aria-selected="true">{{ 'documents' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="system-tab" data-toggle="tab" href="#system" role="tab" aria-controls="system" aria-selected="true">{{ 'system' | translate }}</a>
      </li>
    </ul>
    <div class="tab-content" id="quoteTabContent" style="max-height: 70vh; overflow-y: auto; overflow-x: hidden;">

      <div class="tab-pane fade" id="system" role="tabpanel" aria-labelledby="system-tab">
        <div class="row">
          <div class="col-lg-3 mt-3">
            <app-edit-field [item]="objQuotesField['format']" [form]="quoteForm" [bSubmitted]="bSubmitted" strMode="add" strModule="quotes"></app-edit-field>
          </div>
        </div>
      </div>

      <div class="tab-pane fade show active" id="quote-editor" role="tabpanel" aria-labelledby="quote-editor-tab">
        <div class="row">
          <div *ngFor="let fields of arNgSelectFields | keyvalue" class="col-lg-3 mt-3">
            <label style="font-size: 14px" for="{{ fields.value['name'] }}">{{ fields.value['placeholder'] | translate }}</label>
            <ng-select
              [items]="fields.value['obv'] | async"
              [typeahead]="fields.value['typehead']"
              [loading]="fields.value['loader']"
              [hideSelected]="true"
              [clearable]="true"
              [ngModelOptions]="{standalone: true}"
              bindLabel="text"
              bindValue="id"
              (open)="triggerSubject(fields.value['typehead'])"
              (change)="onValueChange($event, fields.value)"
              [(ngModel)]="fields.value['value']"
              [id]="fields.value['name']"
              [name]="fields.value['name']"
              (ngModelChange)="markAsDirty()"
              [groupBy]="(fields.key == 'contacts') ? 'group_name' : ''">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label">{{ item.text | translate }}</span>
                </ng-template>
                <ng-template ng-optgroup-tmp let-item="item">
                  {{ ( item.group_name | translate ) }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <span *ngIf="!item.role" class="value-text"> {{ item.text | translate }}</span>
                  <span *ngIf="item.role" class="value-text"> {{ item.text | translate }} - {{ item.role | translate }}</span>
                </ng-template>
            </ng-select>
          </div>

          <div class="col-lg-6 mt-3">
            <app-edit-field [item]="objQuotesField['quote_summary']" [form]="quoteForm" [bSubmitted]="bSubmitted" strMode="add" strModule="quotes"></app-edit-field>
          </div>

          <div class="col-lg-3 mt-3">
            <app-edit-field [item]="objQuotesField['quote_date']" [form]="quoteForm" [bSubmitted]="bSubmitted" strMode="add" strModule="quotes"></app-edit-field>
          </div>

          <div class="col-lg-3 mt-3">
            <app-edit-field [item]="objQuotesField['expiry_date']" [form]="quoteForm" [bSubmitted]="bSubmitted" strMode="add" strModule="quotes"></app-edit-field>
          </div>

          <div class="col-12 mt-4">
            <div class="d-flex">
              <div class="flex-grow-1">
                <browse-products
                  [strPricebookId]="strPricebookId"
                  (objProductSelected)="addAttribute($event, false)"
                  (objGroupSelected)="addGroup($event)"
                  [overrideUnitPriceWithDiscountPrice]="false"
                >
                </browse-products>
              </div>
              <div class="ml-2">
                <button class="btn btn-primary h-40 mr-2" (click)="addGroup()">
                  <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>&nbsp;{{ 'add_group' | translate }}
                </button>
                <button class="btn btn-info h-40" (click)="getRelatedProducts(true)">
                  <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>&nbsp;{{ 'reset_pricing' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="col-lg-12 mt-2">
            <table class="table border-left border-right border-bottom" style="table-layout: fixed;">
              <thead>
                <tr>
                  <th scope="col" width="7%"></th>
                  <th scope="col" >{{ 'description' | translate }}<required-tag></required-tag></th>
                  <th *ngIf="bDepartmentTracking" scope="col" width="12%">{{ 'department' | translate }}</th>
                  <th scope="col" width="7%">{{ 'quantity' | translate }}</th>
                  <th scope="col" width="7%">{{ 'cost' | translate }}</th>
                  <th scope="col" width="7%">{{ 'unit_price' | translate }}</th>
                  <th
                    *fieldmagicIsConfigured="'enable_discounts'"
                    scope="col" width="7%"
                  >
                    {{ 'discounted_price' | translate }}
                  </th>
                  <th scope="col" width="7%">{{ 'markup' | translate }}</th>
                  <th scope="col" width="12%">{{ 'tax_code' | translate }}<required-tag></required-tag></th>
                  <th scope="col" width="13%" class="text-center">{{ 'line_total' | translate }}</th>
                </tr>
              </thead>
              <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                <tr *ngIf="arLineItems.length === 0" [shAttachMenu]="contextMenuComponent?.menu" [shMenuData]="contextMenuService.formatMenuData([], -1)">
                  <td [attr.colspan]="groupRowColspan" align="center">
                    <div class="row p-2 m-2">
                      <div class="col-12 text-center">{{ 'empty_quote_label' | translate }}</div>
                    </div>
                  </td>
                </tr>
                <ng-container *ngFor="let attr of arLineItems; index as numLineItemIndex">

                  <ng-container
                    *ngIf="checkIfGroup(attr); then isGroupItem; else isLineItem">
                  </ng-container>

                  <ng-template #isGroupItem>
                    <tr
                      cdkDrag
                      [ngClass]="{
                        'multiple-selected': contextMenuService.isLineItemSelected(attr, selectedLineItems),
                        'row-focused': (numLastFocusedIndex - 1) == numLineItemIndex
                      }"
                    >
                      <td
                        [shAttachMenu]="contextMenuComponent?.menu"
                        [shMenuData]="contextMenuService.formatMenuData(attr, numLineItemIndex)"
                        (click)="onClickedLineItem($event, attr)"
                        matTooltip="{{ 'copy_paste_info' | translate }}"
                        matTooltipPosition="right"
                      >
                        <div class="d-flex flex-row">
                          <fa-icon cdkDragHandle
                            style="cursor: grab;"
                            class="p-2 pl-3"
                            [icon]="['fas', 'grip-vertical']">
                          </fa-icon>
                          <fa-icon (click)="removeAttribute(numLineItemIndex)"
                            class="pl-1 pr-1 pb-2 pt-2 text-danger pointer"
                            [icon]="['fal', 'minus-circle']">
                          </fa-icon>
                          <fa-icon
                            *ngIf="attr.item_id"
                            class="pl-1 pr-1 pb-2 pt-2 pointer"
                            [icon]="['fas', 'info-circle']">
                          </fa-icon>
                        </div>
                      </td>
                      <td [attr.colspan]="groupRowColspan">
                        <div class="p-2">
                          <input
                            type="text"
                            [ngClass]="{'is-invalid': (!attr['label']) && isTouched}"
                            [(ngModel)]="attr.label"
                            (ngModelChange)="markAsDirty()"
                            [ngModelOptions]="{standalone: true}"
                            placeholder="{{ 'enter_group_title' | translate }}"
                            [class]="'form-control font-size-12'"
                            (focus)="setLastIndex(numLineItemIndex)"
                          />
                        </div>
                      </td>

                      <div *cdkDragPreview class="container-fluid bg-light p-0 m-0" style="width: 90%;">
                        <table class="table border-left border-right border-bottom m-0">
                          <tbody>
                            <tr>
                              <td scope="col" width="4%">
                                <fa-icon class="p-2 pl-3" cdkDragHandle [icon]="['fas', 'grip-vertical']"></fa-icon>
                              </td>
                              <td scope="col">
                                <div class="p-3">
                                  {{ attr.label || 'No Group Label' }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          </table>
                      </div>
                    </tr>
                  </ng-template>

                  <ng-template #isLineItem>
                    <tr *ngIf="arLineItems.length == 0">{{ 'no_results' | translate }}</tr>
                    <tr
                      cdkDrag
                      [ngClass]="{
                        'multiple-selected': contextMenuService.isLineItemSelected(attr, selectedLineItems),
                        'row-focused': (numLastFocusedIndex - 1) == numLineItemIndex
                      }"
                    >
                      <td scope="col"
                        [shAttachMenu]="contextMenuComponent?.menu"
                        [shMenuData]="contextMenuService.formatMenuData(attr, numLineItemIndex)"
                        (click)="onClickedLineItem($event, attr)"
                        matTooltip="{{ 'copy_paste_info' | translate }}"
                        matTooltipPosition="right"
                      >
                        <div class="d-flex flex-row">
                          <fa-icon cdkDragHandle
                            style="cursor: grab;"
                            class="p-2  pl-3"
                            [icon]="['fas', 'grip-vertical']">
                          </fa-icon>
                          <a *ngIf="attr.item_id"
                            href="/#/items/{{ attr.item_id }}"
                            class="pl-1 pr-1 pb-2 pt-2  "
                            target="_blank">
                            <fa-icon
                              matTooltipClass="tooltip-space"
                              matTooltip="
                              {{ 'click_to_view' | translate }}&#13;&#13;
                              {{ 'product' | translate }}: {{ attr['item_name'] }}&#13;
                              {{ 'code' | translate }}: {{ attr['item_code'] | placeholdWithString }}&#13;
                              {{ 'preferred_supplier' | translate }}: {{ attr['preferred_supplier_text'] | placeholdWithString }}&#13;
                              {{ 'current_stock_level' | translate }}: {{ attr['current_stock_level'] }}"
                              class="text-primary"
                              [icon]="['fas', 'info-circle']">
                          </fa-icon>
                          </a>
                          <fa-icon *ngIf="attr.item_id"
                            [icon]="['fas', 'chart-line']"
                            class="text-primary desktop-container pointer pl-1 pr-1 pb-2 pt-2"
                            matTooltip="{{ 'show_stock_levels_and_supplier_pricing' | translate }}"
                            (click)="openSupplierInventoryDialog(attr.item_id)"
                          >
                          </fa-icon>
                          <fa-icon
                            *ngIf="!attr.item_id"
                            class="pl-1 pr-1 pb-2 pt-2 text-success pointer"
                            matTooltip="{{ 'create_item' | translate }}"
                            [icon]="['fas', 'plus-circle']"
                            (click)="createItem(attr)">
                          </fa-icon>
                          <fa-icon (click)="removeAttribute(numLineItemIndex)"
                            class="pl-1 pr-1 pb-2 pt-2 text-danger pointer"
                            [icon]="['fal', 'minus-circle']">
                          </fa-icon>
                          <fa-icon
                            *ngIf="attr.related_products.length > 0"
                            matTooltip="{{ 'add_related_products' | translate }}"
                            class="pl-1 pr-1 pb-2 pt-2 pointer text-success"
                            [icon]="['fas', 'plus-circle']"
                            (click)="addRelated(attr, numLineItemIndex)">
                          </fa-icon>
                        </div>
                      </td>
                      <td scope="col">
                        <div class="p-2">
                          <textarea
                            class="form-control h-40"
                            rows="1"
                            style="font-size: 12px;"
                            placeholder="{{ 'description' | translate }}"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="attr['description']"
                            [ngClass]="{'is-invalid': !attr['description'] && isTouched}"
                            (ngModelChange)="markAsDirty()"
                            (focus)="expandInput($event.target); setLastIndex(numLineItemIndex);"
                            (focusout)="shrinkInput($event.target);"></textarea>
                        </div>
                      </td>
                      <td *ngIf="bDepartmentTracking" scope="col">
                        <div class="p-2">
                          <ng-select
                            (focus)="setLastIndex(numLineItemIndex)"
                            [items]="arDepartments"
                            [hideSelected]="true"
                            [clearable]="true"
                            (ngModelChange)="setDepartmentName($event, attr)"
                            [ngModelOptions]="{standalone: true}"
                            bindLabel="text"
                            bindValue="id"
                            class="h-40"
                            placeholder="{{ 'department' | translate }}"
                            [(ngModel)]="attr.department_id"
                            [id]="attr['id'] + 'department'"
                            [name]="attr['id'] + 'department'">
                          </ng-select>
                        </div>
                      </td>
                      <td scope="col">
                        <div class="p-2">
                          <fieldmagic-decimal-input
                            (focus)="setLastIndex(numLineItemIndex)"
                            customClass="form-control input-height-40 font-size-12"
                            placeholder="{{ 'quantity' | translate }}"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="attr['quantity']"
                            [ngClass]="{'is-invalid': !checkQuantity(attr['quantity']) && isTouched}"
                            (ngModelChange)="markAsDirty()"
                            (change)="isProfitIncreased()"
                          ></fieldmagic-decimal-input>
                        </div>
                      </td>
                      <td scope="col">
                        <div class="p-2">
                          <fc-currency-input
                            (focus)="setLastIndex(numLineItemIndex)"
                            [places]="4"
                            customClass="form-control input-height-40 font-size-12"
                            placeholder="{{ 'hourly_cost' | translate }}"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="(attr['labor']) ? attr['hourly_cost'] : attr['unit_cost']"
                            (ngModelChange)="(attr['labor']) ? (attr['hourly_cost'] = $event) : (attr['unit_cost'] = $event)"
                            (change)="onUnitCostChange($event, numLineItemIndex)"
                          ></fc-currency-input>
                        </div>
                      </td>
                      <td scope="col"  *ngIf="attr['label'] === undefined">
                        <div class="p-2">
                          <fc-currency-input
                            [places]="4"
                            id="unit_price_{{ numLineItemIndex }}"
                            [(ngModel)]="attr['unit_price']"
                            [ngModelOptions]="{standalone: true}"
                            [customClass]="'form-control font-size-12 input-height-40'"
                            (focusout)="onUnitPriceChange(attr);"
                            (focus)="setLastIndex(numLineItemIndex)"
                            (ngModelChange)="markAsDirty()"
                            (change)="isProfitIncreased(); adjustMarkup(numLineItemIndex)"
                          >
                          </fc-currency-input>
                        </div>
                      </td>
                      <td
                        *fieldmagicIsConfigured="'enable_discounts'"
                        scope="col"
                      >
                        <div class="p-2">
                          <fc-currency-input
                            [places]="4"
                            id="discounted_price_{{ numLineItemIndex }}"
                            [(ngModel)]="attr['discounted_price']"
                            [ngModelOptions]="{standalone: true}"
                            [customClass]="'form-control font-size-12 input-height-40'"
                            (focus)="setLastIndex(numLineItemIndex)"
                            (ngModelChange)="markAsDirty()"
                            (change)="isProfitIncreased();"
                          >
                          </fc-currency-input>
                        </div>
                      </td>
                      <td scope="col" *ngIf="attr['label'] === undefined || attr['label'] === null">
                        <div class="p-2">
                          <fieldmagic-decimal-input
                            (focus)="setLastIndex(numLineItemIndex)"
                            (change)="markupChange($event, numLineItemIndex)"
                            customClass="form-control input-height-40 font-size-12"
                            placeholder="{{ 'markup' | translate }}"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="attr['markup']">
                          </fieldmagic-decimal-input>
                        </div>
                      </td>
                      <td scope="col">
                        <div class="p-2">
                          <ng-select
                            (focus)="setLastIndex(numLineItemIndex)"
                            [items]="arTaxCodes"
                            [hideSelected]="true"
                            [clearable]="true"
                            (change)="selectATaxcode($event, numLineItemIndex)"
                            [ngModelOptions]="{standalone: true}"
                            bindLabel="text"
                            bindValue="id"
                            appendTo="body"
                            placeholder="{{ 'tax_code' | translate }}"
                            [(ngModel)]="attr.tax_code_id"
                            [id]="attr['id'] + 'tax'"
                            [name]="attr['id'] + 'tax'"
                            class="h-40"
                            [ngClass]="{'is-invalid': (!attr['tax_code_id']) && isTouched}">
                          </ng-select>
                        </div>
                      </td>

                      <td scope="col"
                        [shAttachMenu]="contextMenuComponent?.menu"
                        [shMenuData]="contextMenuService.formatMenuData(attr, numLineItemIndex)"
                        (click)="onClickedLineItem($event, attr)"
                        matTooltip="{{ 'copy_paste_info' | translate }}"
                        matTooltipPosition="right"
                      >
                        <div class="text-center"
                        matTooltip="{{ 'line_total' | translate }} - {{ 'cost' | translate }} = {{ 'profit' | translate }} &#13;
                        {{ attr.computeLineItem | currency }} - {{ computeCostPerLineItem(attr) | currency }} = {{ computeProfitPerLineItem(attr) | currency }}"
                        matTooltipClass="profit-tooltip">
                          {{ attr.computeLineItem | currency }}
                        </div>
                      </td>

                      <div *cdkDragPreview class="container-fluid bg-light p-0 m-0" style="width: 90%;">
                        <table class="table border-left border-right border-bottom m-0">
                          <tbody>
                            <tr>
                              <td scope="col" width="4%"><fa-icon class="p-2 pl-3" cdkDragHandle [icon]="['fas', 'grip-vertical']"></fa-icon></td>
                              <td scope="col" width="20%"><div class="p-2">{{ attr['description'] | slice:0:75 }}</div></td>
                              <td scope="col" width="13%"><div class="p-2">{{ attr['department_name'] }}</div></td>
                              <td scope="col" width="10%"><div class="p-2">Qty. {{ attr['quantity'] }}</div></td>
                              <td scope="col" width="10%"><div class="p-2">{{ attr['unit_price'] | currency }}</div></td>
                              <td
                                *fieldmagicIsConfigured="'enable_discounts'"
                                scope="col"
                                width="10%"
                              >
                                <div class="p-2">
                                  {{ attr['discounted_price'] | currency }}
                                </div>
                              </td>
                              <td scope="col" width="13%"><div class="p-2">{{ attr['tax_code_name'] }}</div></td>
                              <td scope="col" width="10%"><div class="p-2">{{ ((attr['labor']) ? attr['hourly_cost'] : attr['unit_cost']) | currency }}</div></td>
                              <td scope="col" width="10%"><div class="p-2">{{ attr.computeLineItem | currency }}</div></td>
                            </tr>
                          </tbody>
                          </table>
                      </div>

                    </tr>
                  </ng-template>

                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="col-lg-12 mt-1">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th scope="row" width="70%" class="invisible"></th>
                  <td scope="row" width="12%" ><span class="p-3 float-right">{{ 'total_ex_tax' | translate }}</span></td>
                  <td scope="row" width="12%" class="text-center"><span class="p-2">{{ totalWithoutTax | currency }}</span></td>
                </tr>
                <tr>
                  <th scope="row" class="invisible"></th>
                  <td scope="row"><span class="p-3 float-right">{{ 'profit_margin' | translate }}</span> </td>
                  <td scope="row" class="text-center"><span class="p-2" [ngClass]="(increasedProfit) ? 'text-success' : 'text-danger'">{{ totalProfit | currency }} ({{ profitPercentage }}%)</span></td>
                </tr>
                <tr>
                  <th scope="row" class="invisible"></th>
                  <td scope="row"><span class="p-3  float-right">{{ 'tax' | translate }}</span> </td>
                  <td scope="row" class="text-center"><span class="p-2">{{ totalTax | currency }}</span></td>
                </tr>
                <tr >
                  <th scope="row" class="invisible"></th>
                  <td scope="row"><span class="p-3  float-right">{{ 'total_inc_tax' | translate }}</span> </td>
                  <td scope="row" class="text-center"><span class="p-2">{{ totalWithTax | currency }}</span> </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="cover-letter" role="tabpanel" aria-labelledby="cover-letter-tab">
        <div class="mt-2">
          <quill-editor
            formControlName="cover_letter"
            placeholder="Enter Text"
            [modules]="quillConfig"
            [style]="{'height':'220px'}">
          </quill-editor>
        </div>
        <span><small>{{ 'allowed_variables' | translate }}: contact, customer, quote_number, quote_summary, quote_date, expiry_date, assigned_user,
          contact_first_name, contact_last_name, assigned_first_name, assigned_last_name, assigned_email_address, contact_primary_email, contact_email_#,
          contact_home_number_#, contact_work_number_#, contact_mobile_number_#, contact_others_number_#, assigned_home_number_#, assigned_work_number_#,
          assigned_mobile_number_#, assigned_others_number_# (ex. assigned_mobile_number_1, contact_email_2)</small></span>
      </div>

      <div class="tab-pane fade" id="documents" role="tabpanel" aria-labelledby="documents-tab">

        <div class="add-item-container" *fcMinSubscriptionPlanRequired="'fieldmagic-advanced'">
          <span class="add-item" (click)="addDocument()" >
            <fa-icon [icon]="['fal', 'plus-circle']" ></fa-icon>
            {{ 'add_document_from_library' | translate }}
          </span>
        </div>

        <div class="order-contents" cdkDropList (cdkDropListDropped)="dropDocument($event)">
          <div  *ngFor="let document of arDocumentList index as i" class="drag-item" cdkDrag>
            <input *ngIf="document['document_name'] != 'quote_content'" type="checkbox" class="checkbox-order" [(ngModel)]="document['is_included']" [ngModelOptions]="{standalone: true}" [disabled]="document['disabled']">
            {{ document.document_name | translate }}
            <fa-icon class="drag-icon" [icon]="['fas', 'arrows-alt']" rotate="45"></fa-icon>
            <fa-icon *ngIf="document.document_name != 'quote_content'" class="remove-icon" [icon]="['fal', 'minus-circle']" (click)="removeDocument(document.id)"></fa-icon>
          </div>

          <div class="file-drop-container" *fcMinSubscriptionPlanRequired="'fieldmagic-advanced'">

            <input class="file-input" type="file" (change)="onFileChange($event)" accept="application/pdf"/>

            <div *ngIf="!isFileDropped && arFiles.name == undefined" class="file-input-overlay">
              {{ 'drop_pdf_file' | translate }}
            </div>

            <div *ngIf="isFileDropped" class="file-input-overlay-uploading">
              <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon> {{ 'uploading' | translate }}
            </div>

            <div *ngIf="!isFileDropped && arFiles.name !== undefined" class="file-input-overlay-uploaded">
              {{ 'drop_pdf_file' | translate }}
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>
</ng-container>
<fc-context-menu (isClicked)="doSomethingFromContextMenu($event)"></fc-context-menu>

<ng-container *ngIf="!bDialogLoaded">
  <div class="col-sm-12" align="center">
    <h4><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></h4>
  </div>
</ng-container>
