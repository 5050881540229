import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AvailabilityComponent } from './availability.component';
import { MatTooltipModule } from '@angular/material';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    NgSelectModule,
    MatTooltipModule,
    FormsModule
  ],
  declarations: [
    AvailabilityComponent
  ],
  exports: [
    AvailabilityComponent
  ],
})
export class AvailabilityModule { }
