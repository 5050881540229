<mat-chip
                #fmTagPopover="ngbPopover"
                [ngbPopover]="popContent"
                [autoClose]="'outside'"
                container="body"
                [style.background]="tag.color"
                [style.color]="tag.textColor()"
                [removable]="removable"
                (removed)="remove(tag)">
                <input *ngIf="selectable" type="checkbox" class="mr-2" [(ngModel)]="tag.include" (click)="$event.stopPropagation()">
                {{ tag.name }}
                <fa-icon *ngIf="removable"
                  [style.margin-top]="'3px'"
                  [style.color]="tag.textColor()"
                  [icon]="['fas', 'times-circle']"
                  matChipRemove
                  (click)="$event.stopPropagation()">
                </fa-icon>
             </mat-chip>
             <ng-template #popContent>
              <input [(ngModel)]="objTag.name" class="form-control mb-1" [ngClass]="{'is-invalid': bMarkAsRequired}"/>
              <small *ngIf="bMarkAsRequired" class="text-danger">{{ 'required' | translate }}</small>
              <span
                cpAlphaChannel="disabled"
                [style.background]="tag.color"
                [cpToggle]="true"
                [cpDialogDisplay]="'inline'"
                [(colorPicker)]="objTag.color">
              </span>
              <button class="btn btn-primary btn-block mt-2" (click)="select(fmTagPopover)">{{ 'save' | translate }}</button>
             </ng-template>
             