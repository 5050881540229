import { CreateChecklistComponent } from '../../../../../admin/checklists/create-checklist/create-checklist.component';
import { UploadDocumentDialogComponent } from '../../../../../admin/document-library/upload-document-dialog/upload-document-dialog.component';
import { SaveComponent } from '../../../../../admin/sms-templates/save/save.component';
import { EditInvoiceComponent } from '../../../../../module/jobs/customer-invoices/edit-invoice/edit-invoice.component';
import { EditPurchaseOrderComponent } from '../../../../../module/jobs/purchase-orders/edit-purchase-order/edit-purchase-order.component';
import { EditSupplierInvoiceComponent } from '../../../../../module/jobs/supplier-invoices/edit-supplier-invoice/edit-supplier-invoice.component';
import { EditRecurringJobsComponent } from '../../../widget/recurring-jobs/edit-recurring-jobs/edit-recurring-jobs.component';
import { EditRecurringInvoiceFormComponent } from '../../../widget/recurring_invoices/form/edit-recurring_invoice-form.component';
import { EditComponent as EditJobTemplatesComponent } from '../../../../../admin/job-templates/edit/edit.component';
import { EditComponent as EditEmailTemplateComponent } from '../../../../../admin/email-template/edit/edit.component';
import { AddUsersDialogComponent } from '../../../../../admin/users/components/dialog/add-users-dialog.component';

/**
 * This is for modules who have their own custom
 * way doing create such as having its custom way
 * of handling after-dialog logic and custom components.
 */
export const MODULES_PARENT_CREATE = [];

/**
 * For modules that only has a custom component but uses
 * pretty much the same logic as our EditformComponent.
 */
export const MODULES_CUSTOM_COMPONENT = [
  {module: 'document_library', component: UploadDocumentDialogComponent},
  {module: 'sms_templates', component: SaveComponent},
  {module: 'checklists', component: CreateChecklistComponent},
  {module: 'recurring_jobs', component: EditRecurringJobsComponent,
    config: {
      maxWidth: '100%',
      width: '80%',
      height: 'auto',
      disableClose: true,
      data: {
        record_id: null,
        module: null,
        recurring_job: {},
        view_type: 'add',
      }
    }
  },
  {module: 'customer_invoices', component: EditInvoiceComponent,
    config: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
      padding: '1%',
      disableClose: true,
      data: {
        record_id : '',
        view_type : 'add'
      }
    }
  },
  {module: 'purchase_orders', component: EditPurchaseOrderComponent,
    config: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
      padding: '1%',
      disableClose: true,
      data: {
        record_id : '',
        view_type : 'add'
      }
    }
  },
  {module: 'customer_invoices', component: EditInvoiceComponent,
    config: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
      padding: '1%',
      disableClose: true,
      data: {
        record_id : '',
        view_type : 'add'
      }
    }
  },
  {module: 'supplier_invoices', component: EditSupplierInvoiceComponent,
    config: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
      padding: '1%',
      disableClose: true,
      data: {
        record_id : '',
        view_type : 'add'
      }
    }
  },
  {module: 'recurring_invoices', component: EditRecurringInvoiceFormComponent,
    config: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
      disableClose: true,
      data: {
        isNew: true,
        moduleID: null,
        moduleName: 'recurring_invoices',
        recordID: null,
      }
    }
  },
  {module: 'job_templates', component: EditJobTemplatesComponent,
    config: {
      panelClass: 'height-auto-mat-dialog',
      maxWidth: '98%',
      maxHeight: '98%',
      width: '98%',
      height: 'auto',
      disableClose: true,
      data: {
        record_id: null,
        module: null,
        job_template: {},
        view_type: 'add',
      },
    }
  },
  {module: 'email_templates', component: EditEmailTemplateComponent,
    config: {
      maxWidth: '100%',
      width: '40%',
      height: 'auto',
      disableClose: true,
      data: {}
    }
  },
  {
    module: 'users',
    component: AddUsersDialogComponent,
    config: {
      maxWidth: '100%',
      disableClose: true,
      width: '60%',
    },
  }
];

// For modules that redirects to its own page to create.
export const MODULES_CUSTOM_REDIRECT = [
  {module: 'users', link: 'admin/users/invites'},
  {module: 'project_templates', link: 'admin/project_templates/create'},
  {module: 'workflows', link: 'admin/workflow/form'},
  {module: 'roles', link: 'admin/roles/add'},
];

// For modules that redirects to its own page to create.
export const MODULES_NO_REDIRECT = [
  'item_folders', 'recurring_invoices', 'job_templates', 'teams', 'subcontractors'
];
