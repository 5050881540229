import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { LooseObject } from '../../../../../../objects/loose-object';
import { FormService } from '../../../../../../services/form.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { RecordService } from '../../../../../../services/record.service';
import { ListingService } from '../../../../../../services/listing.service';

@Component({
  selector: 'task-details-quote-edit',
  templateUrl: './quote-edit.component.html',
})
export class QuoteEditComponent implements OnInit {
  @Input() quoteDetails: LooseObject;
  @Output() quoteFormSave = new EventEmitter<boolean>();

  form: FormGroup;
  bSubmitted = false;
  bSaving = false;
  strMode = 'edit';
  strModule = 'opportunities';
  fields: any = [
    {
      required: false,
      readonly: false,
      is_admin: false,
      default_value: "",
      key: "opportunity_name",
      label: "opportunity_name",
      controlType: "text",
      max_length: 64,
      space: 6,
      has_primary: false
    },
    {
      required: true,
      readonly: false,
      is_admin: false,
      default_value: "",
      key: "customer_id",
      label: "customer",
      controlType: "relate",
      space: 6,
      is_hidden: false,
      has_primary: false,
      note: "",
      maxSelectedItems: 100,
      option_count: 0,
      default_text: "",
      module: "customers",
      multiple: false,
      options: [],
      filter: {},
      add_tag: false
    },
    {
      required: false,
      readonly: false,
      is_admin: false,
      key: "site_id",
      label: "site",
      controlType: "relate",
      space: 6,
      is_hidden: false,
      has_primary: false,
      note: "",
      maxSelectedItems: 100,
      option_count: 0,
      default_text: "",
      module: "sites",
      multiple: false,
      options: [],
      filter: {},
      add_tag: false
    },
    {
      required: false,
      readonly: false,
      is_admin: false,
      key: "contact_id",
      label: "contact",
      controlType: "relate",
      space: 6,
      is_hidden: false,
      has_primary: false,
      note: "",
      maxSelectedItems: 100,
      option_count: 0,
      default_text: "",
      module: "contacts",
      multiple: false,
      options: [],
      filter: {},
      add_tag: false
    },
    {
      required: false,
      readonly: false,
      is_admin: false,
      default_value: 0.00,
      key: "amount",
      label: "amount",
      controlType: "currency",
      space: 6,
      has_primary: false
    },
    {
      required: true,
      readonly: false,
      is_admin: false,
      key: "stage",
      label: "stage",
      controlType: "dropdown",
      space: 6,
      is_hidden: false,
      has_primary: false,
      options: [
        {
          id: "prospecting",
          text: "prospecting"
        },
        {
          id: "awaiting_quote",
          text: "awaiting_quote"
        },
        {
          id: "quote_sent",
          text: "quote_sent"
        },
        {
          id: "negotiation",
          text: "negotiation"
        },
        {
          id: "closed_won",
          text: "closed_won"
        },
        {
          id: "closed_lost",
          text: "closed_lost"
        }
      ],
      tag: false,
      hideSelected: true,
      closeOnSelect: false,
      maxSelectedItems: 100,
      clearable: false,
      list: "",
      default_value: null,
    },
    {
      required: false,
      readonly: false,
      is_admin: false,
      default_value: "",
      key: "forecast_close_date",
      label: "forecast_close_date",
      controlType: "date",
      space: 6,
      has_primary: false
    },
    {
      required: false,
      readonly: false,
      is_admin: false,
      default_value: "",
      key: "date_follow_up",
      label: "date_follow_up",
      controlType: "datetime",
      space: 6,
      has_primary: false
    },
    {
      required: true,
      readonly: false,
      is_admin: false,
      default_value: "",
      key: "summary",
      label: "summary",
      controlType: "textarea",
      space: 12,
      is_hidden: false,
      max_length: 10000,
      has_primary: false,
      rows: 4
    },
  ];

  constructor(
    private notifService: NotificationService,
    private formService: FormService,
    private recordService: RecordService,
    public listingService: ListingService,
  ) {
    this.form = this.formService.toFormGroup(this.fields);
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if ('quoteDetails' in changes && this.form !== undefined) {
      this.form.patchValue(this.quoteDetails);

      // Prefill values on some fields where the patchValue doesn;t work
      let customerIdIndex = this.fields.findIndex(field => field.key === 'customer_id');
      let siteIdIndex = this.fields.findIndex(field => field.key === 'site_id');
      let contactIdIndex = this.fields.findIndex(field => field.key === 'contact_id');
      let forecastCloseDateIndex = this.fields.findIndex(field => field.key === 'forecast_close_date');
      let dateFollowUpIndex = this.fields.findIndex(field => field.key === 'date_follow_up');

      this.fields[customerIdIndex].default_text = this.quoteDetails.customer_text;
      this.fields[customerIdIndex].options = [
        {
          id: this.quoteDetails.customer_id,
          text: this.quoteDetails.customer_text,
          disabled: false
        }
      ];

      this.fields[siteIdIndex].default_text = this.quoteDetails.site_text;
      this.fields[siteIdIndex].options = [
        {
          id: this.quoteDetails.site_id,
          text: this.quoteDetails.site_text,
          disabled: false
        }
      ];

      this.fields[contactIdIndex].default_text = this.quoteDetails.contact_text;
      this.fields[contactIdIndex].options = [
        {
          id: this.quoteDetails.contact_id,
          text: this.quoteDetails.contact_text,
          disabled: false
        }
      ];

      this.fields[forecastCloseDateIndex].default_value = this.quoteDetails.forecast_close_date;
      this.fields[dateFollowUpIndex].default_value = this.quoteDetails.date_follow_up;

      this.listingService.fetchDataAdvanceSearch(
        {},
        'quotes',
        { opportunity_id: [{ id: this.quoteDetails.id, name: this.quoteDetails.opportunity_number }] },
        {},
        null,
        10,
      ).subscribe(res => {
        // if quote has quote version, make 'amount' field read only
        if (res['data'].length > 0) {
          let numAmountIndex = this.fields.findIndex(field => field.key === 'amount');
          this.fields[numAmountIndex].readonly = true;
        }
      });
    }
  }

  save() {
    this.form.markAsDirty();
    this.form.markAsTouched();
    this.bSubmitted = true;

    if (this.form.invalid) {
      this.notifService.notifyError('please_complete_the_form');
    } else {
      this.bSaving = true;
      this.recordService.saveRecord(this.strModule, this.form.value, this.quoteDetails.id)
        .pipe(
          finalize(() => this.bSaving = false)
        )
        .subscribe(arResponse => {
          this.notifService.notifySuccess("record_update_success");
          this.quoteFormSave.emit(true);
        }, (error: HttpErrorResponse) => {
          if (error.status == 400 || error.status == 422) {
            this.notifService.notifyWarning('record_invalid_parameters');
          }
        });
    }
  }

  cancel() {
    this.quoteFormSave.emit(false);
  }
}
