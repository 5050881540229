import { Component, Inject, OnInit } from "@angular/core";
import { DIALOG_DATA } from '../../../../services/dialog.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'task-calendar-dialog',
  template: `<task-calendar [data]="data" (closeParentDialog)="closeDialog()" (onSetRefreshActivities)="markAsRefresh($event)" appendTo="body"></task-calendar>`,
})
export class TaskCalendarDialogComponent {

  public bRefresh: boolean = false;

  constructor(
    protected dialogRef: MatDialogRef<TaskCalendarDialogComponent>,
    @Inject(DIALOG_DATA) public data
  ) { }

  markAsRefresh(event: boolean) {
    this.bRefresh = event;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

