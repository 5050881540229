import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AddMaterialComponent } from './add-material/add-material.component';
import { ListingService } from '../../../services/listing.service';
import { NotificationService } from '../../../services/notification.service';
import { RecordService } from '../../../services/record.service';
import { ViewService } from '../../../services/view.service';
import * as moment from 'moment';
import { LogMaterialsComponent } from './log-materials/log-materials.component';
import { filter } from 'rxjs/operators';
import { StockAllocationComponent } from './stock-allocation/stock-allocation.component';
import { ImageViewComponent } from '../../../shared/components/image-view/image-view.component';
import { get, isEmpty } from 'lodash-es';
import { LooseObject } from '../../../objects/loose-object';
import { EditInvoiceComponent } from '../customer-invoices/edit-invoice/edit-invoice.component';
import { MaterialsService } from '../../../services/materials.service';
import { AddMaterialsToJobComponent } from '../../purchase-orders/widgets/materials/add-materials-to-job/add-materials-to-job.component';
import { StockReceipt } from '../../../objects/stock-management/stock-receipt';
import { CreateFromWorkOrderComponent } from '../../purchase-orders/widgets/materials/create-from-work-order/create-from-work-order.component';

@Component({
  selector: 'app-jobs-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit {

  @Input() strRecordId : string;

  public arMaterials = [];
  public arPreviousPages = {};
  public bPageLoaded: boolean = false;
  public objJobData: any;
  public objStockReceiptData: StockReceipt | null = null;

  constructor(
    private dialog: MatDialog,
    public listService: ListingService,
    private notifService: NotificationService,
    private recordService: RecordService,
    public viewService: ViewService,
    private materialService: MaterialsService,
  ) { }

  ngOnInit() {
    this.fetchList('default');
  }

  /**
   * Calls the default image viewer component
   *
   * @param strImageUrl
   */
  viewThumbnail(strImageUrl: string) {
    this.dialog.open(ImageViewComponent, {
      width: '720px',
      height: 'auto',
      data: {
          image_url : strImageUrl
      }
    });
  }

  /**
   * Check if the job customer has a pricebook
   * and collects the data
   *
   * @returns {void}
   */
  logMaterialsPricebookData(): void {
    if (isEmpty(this.viewService.arRecord.customer_id)) {
      this.logMaterials();
    } else {
      this.recordService.getRecord('customers', this.viewService.arRecord.customer_id).subscribe(data => {
        const strPricebookId = data['record_details'].pricebook_id;
        this.viewService.arRecord['pricebook_id'] = strPricebookId;
        this.viewService.arRecord['pricebook_text'] = get(data, 'record_details.pricebook_text');

        if (strPricebookId) {
          this.recordService.getRecordRelateJoined('pricebook_items', false, { 'pricebook_id': strPricebookId }).subscribe(objPricebookItems => {
            this.viewService.arRecord["pricebook_items"] = objPricebookItems;
            this.logMaterials();
          });
        } else {
          this.logMaterials();
        }
      });
    }
  }

  /**
   * Open the dialog to log multiple materials
   * against a job.
   *
   * @returns {void}
   */
  logMaterials(): void {
    this.dialog.open(LogMaterialsComponent, {
      maxWidth: '98%',
      width: '98%',
      height: 'auto',
      disableClose: false,
      data: this.viewService.arRecord,
    }).afterClosed()
      .pipe(
        filter((objDialogResponse) => (
          objDialogResponse != undefined && objDialogResponse == 'save'
        ))
      )
      .subscribe(objDialogResponse => {
        this.notifService.notifySuccess('updated');
        this.fetchList('default');
        this.viewService.reloadRecordView(objDialogResponse);
      });
  }

  /**
   * Opens the selected material from the list.
   * @param objMaterial - the material data.
   */
  openMaterial(objMaterial) {
      // Object to be passed in the dialog.
      let objData = {
        width: '1200px',
        height: 'auto',
        // Data to be passed on
        data: {
          // Put the job id and material data to the dialog.
          job_data :  this.viewService.arRecord,
          record_id : this.strRecordId,
          material: [],
          material_id: objMaterial['id'],
          view_type : 'edit'
        },
        disableClose: true
      };

      // Instantiate dialog.
      let tabDialogRef = this.dialog.open(AddMaterialComponent, objData);

      // Reload material list once the dialog is closed.
      tabDialogRef.afterClosed().subscribe(item => {
        // Only when the user hits save will we reload the list.
        if (item != undefined && item == 'save') {
          // Show notification that activity has been created.
          this.notifService.notifySuccess('header_notification.success_update');
          this.fetchList('default');
          this.viewService.reloadRecordView(item);
        }
      });
  }

  /**
   * Fetch the list of material.
   * @param strPage - what page is currently to be viewed.
   */
  fetchList(strPage) {

    let objPagination = this.listService.beforeFetching(strPage);

    this.bPageLoaded = false;

    // Get the list from API.
    this.listService.fetchData(JSON.stringify(objPagination['objPage']), 'materials', JSON.stringify({'job_id': this.strRecordId})).subscribe(response => {

      this.arMaterials = response['data'];
      this.listService.afterFetching(response, strPage);

      this.bPageLoaded = true;

    });
  }

  /**
   *
   * @param strId - record id
   */
  deleteMaterial(strId) {
    //We tell the app component to open the confirmation.
    this.notifService.sendConfirmation().subscribe(
      confirmation => {
        //If the user confirmed, delete the record by field
        if (confirmation.answer) {
          this.recordService.deleteRecord('materials', strId).first().subscribe(
            data => {
              if (data) {
                this.fetchList('reload');
                this.viewService.reloadRecordView(data);
                this.notifService.notifySuccess('record_delete_success')
              } else {
                this.notifService.notifyError('record_delete_failed');
              }
            },
            error => {
              if (error.error.id) {
                this.notifService.notifyWarning(error.error.id[0]);
              }
            }
          );
        }
      }
    );
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate, arCustomConfig = []) {
    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('lll');
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arMaterials = []; // clear list
    this.fetchList('default');
  }

  /**
   * Refreshes records whenever there are changes
   */
  ngOnChanges(): void {
    this.onRefresh();
  }

  /**
   * Opens the dialog screen where we can
   * allocate a stock or item from a warehouse.
   *
   * @returns {void}
   */
  allocateFromWarehouse(): void {

    let objAllocateDialog = this.dialog.open(StockAllocationComponent, {
      width: '80%',
      height: 'auto',
      data: {
        job_data :  this.viewService.arRecord,
        record_id : this.strRecordId
      },
      disableClose: false
    });

    objAllocateDialog.afterClosed().subscribe(strDialogResponse => {
      if (strDialogResponse != undefined && strDialogResponse == 'save') {
        this.notifService.notifySuccess('header_notification.success_update');
        this.fetchList('default');
        this.viewService.reloadRecordView(strDialogResponse);
      }

      if (strDialogResponse === 'reload') {

        this.fetchList('default');
        this.viewService.reloadRecordView(strDialogResponse);
      }
    });

  }

  /**
   * Open customer invoice so they can edit materials.
   *
   * @param objMaterial
   */
  openCI(objMaterial: LooseObject) {

    if (objMaterial['customer_invoice_id'] && objMaterial['customer_invoice_id'] != null) {
      let objData = {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        padding: '1%',
        disableClose: true,
        data: {
          record_id : this.strRecordId,
          invoice: [],
          module: 'customer_invoices',
          view_type : 'edit',
          customer_invoice_id: objMaterial['customer_invoice_id']
        }
      };

      let tabDialogRef = this.dialog.open(EditInvoiceComponent, objData);

      tabDialogRef.afterClosed().first().subscribe(item => {
        if (item != undefined && item.action == 'save') {
          this.onRefresh();
        }
      });
    }
  }

  /**
   * Opens dialog for adding materials from
   * receive ordered items.
   *
   * @returns {void}
   */
  openAddMaterialsToJob(): void {
    this.materialService.getPurchaseOrderLineItemsByJob(this.strRecordId).subscribe(response => {

      this.dialog.open(AddMaterialsToJobComponent, {
        width: '95%',
        height: 'auto',
        maxWidth: '100%',
        disableClose: false,
        data: {
          stock_receipt_data: this.objStockReceiptData,
          po_line_items: response,
          job_id: this.strRecordId,
        }
      })
      .afterClosed()
      .subscribe(objDialogResponse => {
        this.onRefresh();
        if (objDialogResponse == 'materials_created') {
          this.viewService.reloadRecordView(true);
        }
      });
    });

  }

  /**
   * open dialog to create materials from work order
   *
   * @returns {void}
   */
  openCreateMaterialsFromWo(): void {
    this.dialog.open(CreateFromWorkOrderComponent, {
      width: '95%',
      height: 'auto',
      maxWidth: '100%',
      disableClose: false,
      data: {
        job_id: this.strRecordId,
      }
    })
    .afterClosed()
    .subscribe( response => {
      if (response.action == 'saved') {
        if (response.is_created) {

          this.notifService.notifySuccess(response.message);
          this.viewService.reloadRecordView(true);
          this.onRefresh();
        } else {

          this.notifService.notifyInfo(response.message);
        }
      }
    });
  }

  /**
   * get material display name based on type
   * @param material
   * @returns
   */
  getDisplayText(material: LooseObject): string {
    if (material.type == 'product_catalog') {
      return material.product_name;
    }
    if (material.type == 'once_off_purchase') {
      return material.product;
    }
    return '';
  }
}
