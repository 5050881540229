import { Component, OnInit, ViewChild, ViewContainerRef, OnDestroy, Inject, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { concatMap, filter, finalize, switchMap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { CustomTranslateService } from '../../../../../../services/custom-translate.service';
import { RelateIds } from '../../../../../../lists/relate-ids';
import { Relate } from '../../../../../../objects/relate';
import { RecordService } from '../../../../../../services/record.service';
import { Phone } from '../../../../../../objects/phone';
import { SmsService } from '../../../../../../services/sms.service';
import { Select } from '../../../../../../objects/select';
import { SmsTemplate } from '../../../../../../objects/sms-template';
import { NotificationService } from '../../../../../../services/notification.service';
import { LocalStorageService } from '../../../../../../services/local-storage.service';
import { SearchService } from '../../../../../../services/search.service';
import { get } from 'lodash-es';
import { AccountService } from '../../../../../../services/account.service';
import { ChatService } from '../../../../../../services/chat.service';
import { MentionsService } from '../../../../../../services/mentions.service';
import { LooseObject } from '../../../../../../objects/loose-object';
import { LocalPipe } from '../../../../../../pipes/moment/local/local.pipe';
import * as moment from 'moment';
import { Liquid } from 'liquidjs';
import { clone } from 'lodash-es';
import { ListingService } from '../../../../../../services/listing.service';

const engine = new Liquid();
@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss'],
  providers: [
    CustomTranslateService,
    SmsService,
    MentionsService
  ]
})
export class SmsComponent implements OnInit, OnDestroy {

  /**
   * The native element for either the textarea with the
   * formControl of message_chat or message_sms. See FormGroup below.
   *
   * @var {ElementRef}
   */
  @ViewChild('textarea', { read: ElementRef }) objMessageTextarea: ElementRef;

  /**
   * The form group of the
   * sms activity.
   *
   * @var {FormGroup}
   */
  public chatForm: FormGroup = new FormGroup({
    is_internal: new FormControl(true),
    to: new FormControl(null),
    message_sms: new FormControl(null),
    message_chat: new FormControl(null),
    number: new FormControl(null)
  });

  /**
   * This is the object that handles that sms templates
   * data including the flags for the loader.
   *
   * @var {template: SmsTemplate[], loaded: boolean}
   */
  public objTemplates: {
    templates: SmsTemplate[],
    loaded: boolean
  } = {
    templates: [],
    loaded: false
  };

  /**
   * Relate object containing the typeheads and other
   * necessary properties to use the Ng-Select field
   * properly.
   *
   * @var {Relate}
   */
  public objContactsRelate: Relate<Select> = new Relate<Select>();

  /**
   * List of subscriptions. We put them here so we can
   * destroy them when this component is closed.
   *
   * @var {Subscription}
   */
  private arSubscriptions: Subscription[] = [];

  /**
   * List of Phones of a single Contact.
   *
   * @var {Phone[]}
   */
  public arContactPhones: Phone[] = [];

  /**
   * Name of the attachment.
   *
   * @var {upload_name: string,file_name: string, url: string}
   */
  public objAttachment: {
    upload_name: string,
    file_name: string,
    url: string
  };

  public objReadablePipe = new LocalPipe;

  /**
   * Show user that sending of message
   * is still loading.
   *
   * @var {boolean}
   */
  public bSendLoader: boolean = false;

  /**
   * flag for primary contact role
   *
   * @var {boolean}
   */
  protected bHasTenantPrimaryContactRole: boolean = false;

  /**
   * Checks if the message field is valid.
   *
   * @returns {boolean}
   */
  get bIsMessageValid(): boolean {
    return !this.chatForm.controls['message_sms'].valid;
  }

  /**
   * Checks if the current form is an internal chat form.
   *
   * @return {boolean}
   */
  get bIsInternalChat(): boolean {
    return this.chatForm.controls['is_internal'].value;
  }

  /**
   * Retrieves which key to use.
   *
   * @returns {string}
   */
  get strMessageValue(): string {
    if (this.bIsInternalChat) {
      return this.chatForm.controls['message_chat'].value
    } else {
      return this.chatForm.controls['message_sms'].value
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public objParentData: any,
    public selfDialog: MatDialogRef<SmsComponent>,
    private customTranslateService: CustomTranslateService,
    private recordService: RecordService,
    private smsService: SmsService,
    private notifService: NotificationService,
    private localStorageService: LocalStorageService,
    private globalService: SearchService,
    private accountService: AccountService,
    private chatService: ChatService,
    public mentionsService: MentionsService,
    private listingService: ListingService,
  ) {
    this.customTranslateService.addTranslationKey(objParentData['module'].slice(0, -1));
    if (objParentData['module'] === 'sites') {

      this.getPrimaryContactRole();
    }
  }

  ngOnInit(): void {
    this.buildRelates();
    this.getSubscriptions();
    this.translateFields();
    this.getAttachment();
  }

  ngOnDestroy(): void {
    this.arSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Retrieves attachment name from the url.
   *
   * @returns {void}
   */
  getAttachment(): void {
    if (this.objParentData.additional_data.file_name) {
      this.objAttachment = this.objParentData.additional_data;
      this.objAttachment.url = atob(this.objAttachment.url);
    }
  }

  /**
   * Calls the API that loads all the templates.
   *
   * @returns {void}
   */
  loadTemplate(): void {
    if (!this.objTemplates.loaded) {
      this.smsService.getSmsTemplates(this.objParentData.module).subscribe(item => {
        let arTemplates: any = item;
        this.objTemplates.templates = arTemplates;
        this.objTemplates.loaded = true;
      });
    }
  }

  /**
   * Replace variables in string with values.
   *
   * @param {SmsTemplate} objSmsTemplate
   *
   * @returns {void}
   */
  useTemplate(objSmsTemplate: SmsTemplate): void {

    let objVariableSource: LooseObject = {...this.objParentData.record_data};

    Object.keys(this.objParentData.record_data).forEach(field => {
      if (typeof objVariableSource == 'object' && objVariableSource[field + '_text'] != undefined) {
        objVariableSource[field] = objVariableSource[field + '_text'];
      }
      if (field.match(/_id/g) && objVariableSource[field.replace('_id', '') + '_text'] != undefined) {
        objVariableSource[field] = objVariableSource[field.replace('_id', '') + '_text'];
      }
      if (field == 'email_address' && Array.isArray(objVariableSource[field]) && objVariableSource[field].length > 0) {
        objVariableSource[field] = objVariableSource[field].find(email => email.primary === "1").email;
      }
      if (this.objParentData.record_config[field] !== undefined){
        if (this.objParentData.record_config[field].type == 'date') {
          objVariableSource[field] = this.objReadablePipe.transform(objVariableSource[field]).format('ll');
        }
        if (this.objParentData.record_config[field].type == 'datetime') {
          objVariableSource[field] = this.objReadablePipe.transform(objVariableSource[field]).format('lll');
        }
      }
    });

    engine
      .parseAndRender(objSmsTemplate.message, objVariableSource)
      .then(strTemplateText => {
        this.chatForm.patchValue({
          'message_sms': strTemplateText
        });
      });
  }

  /**
   * When the user hits the send button we map the data properly and close the dialog.
   *
   * @returns {void}
   */
  onSubmit(): void {
    let  objRequestData: ActivityMessage = this.getActivityMessage();

    if (this.chatForm.valid && this.hasCompleteData(objRequestData)) {
      this.bSendLoader = true;

      if (!this.bIsInternalChat) {
        this.accountService.getSmsBalance().pipe(
          concatMap(balance => {
            if (balance.credits > 0) {
              return of(true);
            } else {
              return this.notifService.sendConfirmation(
                'no_sms_credits_message',
                'no_sms_credits',
                'ok'
              ).pipe(
                filter(confirmation => confirmation.answer === false),
                finalize(() => {
                  this.bSendLoader = false;
                })
              );
            }
          }),
          concatMap(() => this.getAttachmentObservable())
        ).subscribe(() => {
          this.setLoaderAndClose(objRequestData);
        });
      } else {
        this.getAttachmentObservable().subscribe(() => {
          this.setLoaderAndClose(objRequestData);
        })
      }

    } else {
      this.notifService.sendNotification('invalid_form', 'valid_forms', 'warning');
    }
  }

  /**
   * Builds the activity message data to be passed to the server.
   *
   * @returns {ActivityMessage}
   */
  private getActivityMessage(): ActivityMessage {

    let objActivityMessage: ActivityMessage = {
      module_id: this.objParentData.record_id,
      module_field: RelateIds[this.objParentData.module],
      module: this.objParentData.module,
      type: 'message',
      is_internal: this.chatForm.controls['is_internal'].value,
      thread_participants: this.mentionsService.getAllMentionedPeopleId()
    };

    if (this.objAttachment) {
      objActivityMessage.file_key = this.objAttachment.upload_name;
    }

    if (objActivityMessage.is_internal === true) {

      objActivityMessage.subject = this.strMessageValue;
      objActivityMessage.note = this.strMessageValue;
    } else {
      let numPhoneNumber: Phone = get(this.chatForm, ['controls', 'number', 'value'], null);
      objActivityMessage.subject = get(this.chatForm, ['controls', 'to', 'value', 'text'], null);
      objActivityMessage.note = this.strMessageValue;
      objActivityMessage.contact_id = get(this.chatForm, ['controls', 'to', 'value', 'id'], null);

      if (numPhoneNumber !== null) {
        // FC-3773: changed data type of .to from string to object because SMS messages
        // do not appear in the recent activities list of job scheduler's task details dialog.
        objActivityMessage.to = { phone: (numPhoneNumber.code + numPhoneNumber.number).toString() };
      } else {
        // Manually get the value of phone number if contact has just ONE
        // phone number registered Because I cant get the value in form control
        if (this.arContactPhones.length === 1) {
          let phoneCode = get(this.arContactPhones, [0, 'code'], null);
          let phoneNumber = get(this.arContactPhones, [0, 'number'], null);

          if (phoneCode && phoneNumber) {
            objActivityMessage.to = { phone: phoneCode + phoneNumber };
          }
        }
      }
    }


    return objActivityMessage;
  }

  /**
   * Since there is 2 types of sending message (external and internal), we cannot set required
   * validation in form data. We have to manually check if sending is external and all fields
   * (message, to and number) are present
   *
   * @param {ActivityMessage} objRequestData
   */
  hasCompleteData(objRequestData: ActivityMessage) {
    if (! objRequestData.is_internal && (! objRequestData.contact_id || ! objRequestData.note || ! objRequestData.to)) {
      return false;
    }

    return true;
  }

  /**
   * Builds the relate fields.
   *
   * @returns {void}
   */
  private buildRelates(): void {
    let arContacts: Select[] = [];

    if (this.objParentData['module'] === 'contacts') {
      arContacts.push(this.objParentData.record_data);
    }

    this.objContactsRelate.buildRelates(
      switchMap(term => this.recordService.getRecordRelate('contacts', term, '', false)
        .map(contacts => {
          return contacts.filter(contact => Array.isArray(contact['phone']) && contact['phone'].length > 0);
        })
      ),
      arContacts
    );

    this.mentionsService.objUsersRelate.buildRelates(
      switchMap(term => this.globalService.global(term, 'users')
        .map(user => {
          return user.filter(user => user.id != this.localStorageService.getItem('user_id'))
        })
      )
    );
  }

  /**
   * Retrieves all subscriptions so we can easily
   * destroy all when the onDestroy lifecycle
   * happens.
   *
   * @returns {void}
   */
  private getSubscriptions(): void {

    this.arSubscriptions = [
      this.chatForm.controls['to'].valueChanges.subscribe(contact => {
        if (!this.bIsInternalChat) {
          this.arContactPhones = (contact) ? contact.phone.map(phone => new Phone(phone)) : [];
          this.chatForm.patchValue({})
        }
      }),
      this.chatForm.controls['is_internal'].valueChanges.subscribe(response => {
        if (this.objParentData['module'] === 'contacts' &&
            this.chatForm.controls['to'].value === null &&
            response === false
        ) {
          this.chatForm.patchValue({
            to: this.objParentData.record_data
          });
        } else {
          this.chatForm.patchValue({ to: null, message: null, number: null });
        }
      }),
      this.chatForm.controls['message_chat'].valueChanges.subscribe(() => {
        this.checkTags();
      }),
      this.chatForm.controls['message_sms'].valueChanges.subscribe(() => {
        this.checkTags();
      })
    ];
  }

  /**
   * Check if tags are still mapping correctly.
   *
   * @returns {void}
   */
  checkTags(): void {

    let objTextareaUpdates: {
      textarea_value: string,
      textarea_cursor: number
    } | null = this.mentionsService.checkTags(this.strMessageValue);

    if (objTextareaUpdates != null) {
      this.chatForm.patchValue({ message_chat: objTextareaUpdates.textarea_value });
      this.objMessageTextarea.nativeElement.selectionEnd = objTextareaUpdates.textarea_cursor;
    }

  }

  /**
   * Translates all data needed for values in the
   * sms template.
   *
   * @returns {void}
   */
  private translateFields(): void {
    // Clone the parent data to avoid mutating it
    const objRecordData = clone(this.objParentData.record_data);

    Object.keys(objRecordData).forEach(strField => {
      if (strField.includes('_id')) {
        let strTextKey = strField.replace('_id', '_text');
        if (objRecordData[strTextKey]) {
          objRecordData[strField] = objRecordData[strTextKey]
        }
      }

      if (typeof objRecordData[strField] === 'string') {
        this.customTranslateService.addTranslationKey(objRecordData[strField]);
      }
    });

    this.customTranslateService.getAllTranslation();

    Object.keys(objRecordData).forEach(strField => {
      objRecordData[strField] = this.customTranslateService.getTranslation(objRecordData[strField]);
    });
  }

  /**
   * Set loader and close dialog.
   *
   * @param {LooseObject} objRequestData
   *
   * @returns {void}
   */
  private setLoaderAndClose(objRequestData: LooseObject): void {
    this.bSendLoader = false;
    this.selfDialog.close({ mode: 'create', data: objRequestData });
  }

  /**
   * Get the attachment observable there should be any.
   *
   * @returns {Observable<string|null>}
   */
  private getAttachmentObservable(): Observable<string | null> {
    if (this.objAttachment) {
      return this.chatService.copyMessageAttachment(this.objAttachment.upload_name);
    }
    return of(null)
  }

  /**
   * for contact role with tenant primary role
   * if the contact role is not tenant,
   * remove the primary contact role data
   */
  private getPrimaryContactRole(): void {
    let objRecord = this.objParentData.record_data;
    if (objRecord.primary_contact_id) {

      this.listingService.fetchData('', 'contact_roles', JSON.stringify({
        module: 'sites',
        module_id: objRecord.id,
        contact_id: objRecord.primary_contact_id
      })).subscribe(response => {
        if (response['data'][0]) {

          let hasTenantPrimary = response['data'][0].role.filter( role =>
            role.id == 'tenant' && role.primary == true
          ).length !== 0;

          if (hasTenantPrimary === true) {

            this.objParentData.record_data.primary_tenant_contact_id = objRecord.primary_contact_id;
            this.objParentData.record_data.primary_tenant_contact_text = objRecord.primary_contact_text;
          }
        }
      })
    }
  }
}

export interface ActivityMessage {

  /**
   * The id of the record where this
   * widget is located.
   *
   * @var {string}
   */
  module_id: string;

  /**
   * The name of the primary_key for this module.
   * Ex. Lead = lead_id, Job = job_id, and so on.
   *
   * @var {string}
   */
  module_field: string;

  /**
   * Which module does this record belong.
   *
   * @var {string}
   */
  module: string;

  /**
   * What type of activity is this. For this
   * component, it always "message".
   *
   * @var {string}
   */
  type: string;

  /**
   * Is this chat an internal or external chat?
   *
   * @var {boolean}
   */
  is_internal: boolean;

  /**
   * Who are the users that we will notify
   * when there is a new message in this chat.
   *
   * @var {string[]}
   */
  thread_participants: string[];

  /**
   * The subject of the activity, or for activity messages,
   * the title of the activity as seen in the widget.
   *
   * @var {string}
   */
  subject?: string;

  /**
   * Contents of the activity message, usually contains
   * the first chat of the activity message.
   *
   * @var {string}
   */
  note?: string;

  /**
   * The contact id of this chat. Only used for external users.
   *
   * @var {string}
   */
  contact_id ?: string,

  /**
   * The phone number if an external chat.
   *
   * @var {object}
   */
  to? : object,

  /**
   * Upload name of a file if it's a file.
   *
   * @var {string}
   */
  file_key?: string,

}
