<div>
  <div class="col-12 mb-3">
    <h3 id="customerSummaryTitle">({{ (summary.customer?.customer_id ? 'update' : 'create') | translate}}) {{ 'customer' | translate }}</h3>
    <div class="row ml-3 mb-3">
      <div class="col-6">
        <label class="record-labels" for="summaryCustomerName">{{ 'name' | translate }}</label>
        <span id="summaryCustomerName"
          class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
          {{ summary.customer?.type === 'organization' ? summary.customer?.name : summary.customer?.first_name +
          ' ' + summary.customer?.last_name }}
        </span>
      </div>
      <div class="col-6">
        <label class="record-labels" for="summaryCustomerType">{{ 'type' | translate }}</label>
        <span id="summaryCustomerType"
          class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
          {{ (summary.customer?.type | translate) }}
        </span>
      </div>
    </div>
    <div class="row ml-3 mb-3">
      <div class="col-12">
        <label class="record-labels" for="summaryCustomerSiteAddress">
          ({{ (summary.customer?.site_id ? 'existing' : 'create') | translate}}) {{ 'site' | translate }}
        </label>
        <span id="summaryCustomerSiteAddress"
          class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
          <ng-container *ngIf="summary.customer?.site_id; else showAddressField">
            {{ summary.customer?.existing_site_address_text }}
          </ng-container>

          <ng-template #showAddressField>
            {{ summary.customer?.address | readableAddress }}
          </ng-template>
        </span>
      </div>
    </div>
  </div>

  <div class="col-12 mb-3">
    <h3 id="contactSummaryTitle">({{ (summary.contact?.contact_id ? 'update' : 'create') | translate}}) {{ 'contact' | translate }}</h3>
    <div class="row ml-3 mb-3">
      <div class="col-6">
        <label class="record-labels" for="summaryContactFirstName">{{ 'first_name' | translate }}</label>
        <span id="summaryContactFirstName"
          class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
          {{ summary.contact?.first_name | placeholdWithString }}
        </span>
      </div>
      <div class="col-6">
        <label class="record-labels" for="summaryContactLastName">{{ 'last_name' | translate }}</label>
        <span id="summaryContactLastName"
          class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
          {{ summary.contact?.last_name | placeholdWithString }}
        </span>
      </div>
    </div>
    <div class="row ml-3 mb-3">
      <div class="col-6">
        <label class="record-labels" for="summaryContactEmailAddress">{{ 'email_address' | translate }}</label>
        <div id="summaryContactEmailAddress">
          <div *ngIf="summary.contact?.email_address; else noData" >
            <div *ngFor="let item of summary.contact?.email_address">
              <div class="icon-container">
                <fa-icon [fixedWidth]="true" [icon]="['far', 'envelope']" class="icon-style"></fa-icon>
                {{ item.email }} <span *ngIf="item.primary == '1'" class="badge badge-success">Primary</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <label class="record-labels" for="summaryContactPhone">{{ 'phone' | translate }}</label>
        <div id="summaryContactPhone">
          <div *ngIf="summary.contact?.phone; else noData">
            <div *ngFor="let item of summary.contact?.phone">
              <div class="icon-container">
                <fa-icon [fixedWidth]="true" [icon]="['far', item.getPhoneIcon]" class="icon-style"></fa-icon>
                <span *ngIf="item.number; else illegalNumber">{{ item.code }} {{ (item.number) ? (item.number |
                  phone) :
                  '' }}</span>
              </div>
              <ng-template #illegalNumber><span>{{ 'fix_number' | translate }}</span></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 mb-3" *ngIf="summary.quote">
    <h3 id="quoteSummaryTitle">{{ 'create' | translate }} {{ 'opportunity' | translate }}</h3>
    <div class="row ml-3 mb-3">
      <div class="col-12">
        <label class="record-labels" for="summaryQuoteOpportunityName">{{ 'opportunity_name' | translate }}</label>
        <span id="summaryQuoteOpportunityName"
          class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
          {{ summary.quote?.opportunity_name }}
        </span>
      </div>
    </div>
    <div class="row ml-3 mb-3">
      <div class="col-6">
        <label class="record-labels" for="summaryQuoteSummary">{{ 'summary' | translate }}</label>
        <span id="summaryQuoteSummary"
          class="form-control-plaintext form-control-sm pl-2 record-texts border-bottom long-text"
          [innerHTML]="summary.quote?.summary | nl2br | placeholdWithString"></span>
      </div>
      <div class="col-6">
        <label class="record-labels" for="summaryQuoteNotes">{{ 'notes' | translate }}</label>
        <span id="summaryQuoteNotes"
          class="form-control-plaintext form-control-sm pl-2 record-texts border-bottom long-text"
          [innerHTML]="summary.quote?.notes | nl2br | placeholdWithString"></span>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <span>--</span>
</ng-template>