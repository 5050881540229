import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';
import { LooseObject } from '../objects/loose-object';
import { NotificationService } from './notification.service';
import { ClientStoreService } from './client-store.service';
import { tap } from 'rxjs/operators';

const kBaseUrl: string = environment.url + "/dropdown/";

@Injectable()
export class DropdownService {

  constructor (
    private http: HttpClient,
    protected notifications: NotificationService,
    protected clientStoreService: ClientStoreService,
  ) {}

  getConfig(): Observable<LooseObject> {
    return this.http.post<any>(kBaseUrl+ 'config', {});
  }

  saveConfig(module: string, field: string, options: LooseObject[]): Observable<HttpResponse<any>> {
    let body = new URLSearchParams();
    body.append('module', module);
    body.append('field', field);
    body.append('options', JSON.stringify(options));
    return this.http.post<any>(kBaseUrl+ 'save', body.toString()).pipe(
      tap(() => sessionStorage.removeItem(`config-${module}`)),
    );
  }

  getDropdownOption(module: string, field: string) {
    let body = new URLSearchParams();
    body.append('module', module);
    body.append('field', field);
    return this.http.post<any>(kBaseUrl+ 'field_options', body.toString());
  }

  restoreConfig(module: string, field: string): Observable<HttpResponse<any>> {
    let body = new URLSearchParams();
    body.append('module', module);
    body.append('field', field);
    return this.http.post<any>(kBaseUrl+ 'restore', body.toString()).pipe(
      tap(() => sessionStorage.removeItem(`config-${module}`)),
    );;
  }

  deleteDropdownOption(module: string, field: string, ids: string[]): Observable<HttpResponse<any>> {
    let body = new URLSearchParams();
    body.append('module', module);
    body.append('field', field);
    body.append('ids', JSON.stringify(ids));
    return this.http.post<any>(kBaseUrl+ 'delete_options', body.toString()).pipe(
      tap(() => sessionStorage.removeItem(`config-${module}`)),
    );;
  }

  getListingLegends(module: string, field: string) {
    let body = new URLSearchParams();
    body.append('module', module);
    body.append('field', field);
    return this.http.post<any>(kBaseUrl+ 'get_listing_legends', body.toString());
  }

}