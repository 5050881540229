
    <div
      class="page-content-header row"
      data-testid="page_content_header"
    >
      <div
        class="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-title"
        data-testid="content_title"
      >
        <ng-container *ngIf="title | filled">
          <fieldmagic-icon
            *ngIf="icon | filled; else titleFallback"
            [icon]="icon[0]"
            [variant]="icon[1]"
          >
            <ng-container *ngTemplateOutlet="titleFallback"></ng-container>
          </fieldmagic-icon>

          <ng-template #titleFallback>
            <span>{{ title | translate }}</span>
          </ng-template>
        </ng-container>

        <ng-content select=".page-content-header-title > *"></ng-content>
      </div>
      <div
        class="col-lg-6 col-md-6 col-sm-12 col-xs-12 page-content-header-actions-container d-flex align-items-center"
        data-testid="page_content_header_actions"
      >
        <ng-content select=".page-content-header-actions > *"></ng-content>
      </div>
    </div>
    <hr class="clearfix">
    <div
      class="page-content-body-container w-100"
      data-testid="page_content_body"
    >
      <ng-content select=".page-content-body > *"></ng-content>
    </div>
  