import 'rxjs/add/operator/map';
import { Router, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Client } from '../objects/client';
import { ClientStoreService } from './client-store.service';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { ClientsListStoreService } from './clients-list-store/clients-list-store.service';
import { ClientInvitationsStoreService } from './client-invitations-store/client-invitations-store.service';
import { map, filter, first } from 'rxjs/operators';
import { LooseObject } from '../objects/loose-object';
import { currency_codes } from '../../assets/api/currency_codes.json';
import * as moment from 'moment';
import { PdfConfig } from '../objects/pdf-config';
import { CustomerNotification } from '../objects/config';
import { isNil, toString, trim } from 'lodash-es';
import { fallback } from '../shared/utils/common';

const kBaseUrl: string = `${environment.url}/account/`;

@Injectable()
export class AccountService {

  // FC-1643: Add EventEmitter on check user, to fix the redirection in client registration after login
  @Output() checkUserReady = new EventEmitter<boolean>();
  public fromSwitchAccount: boolean = false;

  constructor(
    public translate: TranslateService,
    public localStorageService: LocalStorageService,
    public clientStoreService: ClientStoreService,
    public clientsListStoreService: ClientsListStoreService,
    public clientInvitationsStoreService: ClientInvitationsStoreService,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  // Function for wrapping json request to 'data'
  wrappJsonData(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return body.toString();
  }

  /*
   * Get the response from /account/whoami API
   * to check if user is connected to a
   * client or not.
   */
  checkUser() {

    let objCampaignOrigin = this.getCampaignOriginData();
    let requestBody = Object.keys(objCampaignOrigin).length !== 0 ? this.wrappJsonData(JSON.stringify(objCampaignOrigin)) : null;

    return this.http.post(kBaseUrl + 'whoami', requestBody).pipe(
      map(data => {
        //Remove the client_list from local storage to make sure its clean.
        this.clientsListStoreService.removeClientList();
        this.clientStoreService.removeActiveClient();
        this.localStorageService.removeItem('local_notifications');
        //Set the user's id
        this.localStorageService.setItem("user_id", data['user_id']);
        //Set the user's full name
        this.localStorageService.setItem("user_name", data['user_name']);
        //Set the user eamail.
        this.localStorageService.setItem("user_email", data['email_address']);
        //Set the user locale.
        this.localStorageService.setItem("user_locale", data['user_locale']);
        //Set a flag if the current user is affiliated to crmonline
        this.localStorageService.setItem("is_internal", data['is_fieldmagic_internal']);
        //Set the hubspot token connected to the user.
        this.localStorageService.setItem("hubspot_token", data['hubspot_token']);

        if (data['user_image'] != null && data['user_image'] != '') {
          this.localStorageService.removeItem("temp_image");
        }
        // Set image.
        // FC-1112: as per https://stackoverflow.com/questions/47150795/why-is-null-stored-as-a-string-in-localstorage/47150845
        // local storage stores values as DOMString storing null inside the local storage results in unexpected behaviour
        if (data['user_image']) {
          this.localStorageService.setItem("image", data['user_image']);
        }

        // Sets app current language
        this.translate.use(data['user_locale']);

        if (data['requires_registration']) {
          // Navigate to the registration page.
          this.router.navigate(['/account/register']);
        } else {

          // Store client list to local storage.
          this.clientsListStoreService.setClientList(data['clients']);
          this.checkUserReady.emit(true);

          let previousClient = this.localStorageService.getJsonItem('previous_client');
          if (previousClient !== null && this.fromSwitchAccount !== true) {
            // Store the previous client and redirect to job
            this.clientStoreService.setActiveClient(previousClient);
            this.clientStoreService.setPreviousClient(previousClient);
            this._redirect();
          } else {
            // If user has more than one company/client or has an invitation
            if (this.clientsObjectToArray(data['clients']).length > 1 || data['invitations'].length > 0) {
              // Redirect to selection page.
              this.goToSelection(data);
            } else {
              if (this.fromSwitchAccount === true) {
                // Redirect to selection page.
                this.goToSelection(data);
              } else {

                let objClient = data['clients'][Object.keys(data['clients'])[0]];

                if (objClient.status == 'inactive') {
                  this.goToSelection(data);
                } else {
                  // Store the single client object to local storage
                  this.clientStoreService.setActiveClient(objClient);
                  this.clientStoreService.setPreviousClient(objClient);
                  // If user has one client, go straight to main page.
                  this.router.navigate(['/account/steps']);
                }

              }
            }
          }
        }
      })
    );
  }

  /**
   * Sets invitation and redirects to selection.
   * @param data
   */
  goToSelection(data) {
    // If user has invitation/s set it in the local storage
    if (data['invitations'].length > 0) {
      this.clientInvitationsStoreService.setClientInvitations(data['invitations']);
    }
    // Redirect to selection page.
    this.router.navigate(['/account/selection']);
  }

  /*
   * Pass client data from the signup form
   * to create a client in the database then
   * get response status to check if creation
   * was successful.
   */
  createClient(clientJson): Observable<Client> {
    return this.http.post<Client>(`${kBaseUrl}client_registration`, this.wrappJsonData(clientJson), {
      headers: {
        'X-Captcha': 'register_client',
      },
    });
  }

  /**
   * Converts an object of clients into an array. The object is already an
   * array but with named keys. This method just converts it to an array
   * for us to be able to perform array operations on it.
   *
   * @param {Object} clientsObject
   *
   * @returns {Client[]}
   */
  clientsObjectToArray(clientsObject): Client[] {
    return Object.values(clientsObject).map((client: Client) => { return client; });
  }

  /*
   * Updates the client list in the local storage.
   * We use this after registering a new client.
   */
  updateClientList(jsonRegistrationResponse) {
    //Get the client list in local storage and merge it with the response in registration.
    const mergedClients = {...this.clientsListStoreService.getClientList(), ...jsonRegistrationResponse}
    //Overwrite the client list with the new list in the local storage.
    this.clientsListStoreService.setClientList(mergedClients);
  }

  /*
   * Get the response from /account/user_invitations API
   */
  getUserInvivation() {
    // Call user_invitations API
    return this.http.post(kBaseUrl + "user_invitations", '');
  }

  /*
   * Get the response from /account/invites_reminder API
   * to check if client has users to remind
   * them to invite users
   */
  checkInvivationReminder() {
    return this.http.post(kBaseUrl + "invites_reminder", '');
  }

   /*
   * Updates Invivation Reminder Flag of client's record
   *
   * @param strNewMessage
   */
  updateInvitesReminderFlag(strNewMessage): Observable<Response> {
    return this.http.post<Response>(kBaseUrl + "update_reminder_flag", this.wrappJsonData(strNewMessage));
  }

    /*
   * Pass client data from the signup form
   * to create a client in the database then
   * get response status to check if creation
   * was successful.
   */
  sendUserInvitations(jsonEmails): Observable<Response> {
    return this.http.post<Response>(kBaseUrl + "send_invitations", this.wrappJsonData(jsonEmails), {
      headers: {
        'X-Captcha': 'send_user_invitations',
      },
    });
  }

  resendUserInvitations(jsonData): Observable<Response> {
    return this.http.post<Response>(kBaseUrl + "resend_user_invitations", this.wrappJsonData(JSON.stringify(jsonData)), {
      headers: {
        'X-Captcha': 'resend_user_invitations',
      },
    });
  }

  /**
   * Accepts an invitation from a client.
   *
   * @param clientId
   * @param userId
   * @param level
   *
   * @returns {Observable<Response>}
   */
  acceptInvitation(clientId: string, userId: string, level: string): Observable<Response> {
    let body = new URLSearchParams();
    body.append('client_id', clientId);
    body.append('level', level);
    body.append('user_id', userId);

    return this.http.post<Response>(`${kBaseUrl}invitation/accept`, body.toString());
  }

  /**
   * Rejects an invitation from a client.
   *
   * @param clientId
   * @param userId
   *
   * @returns {Observable<Response>}
   */
  rejectInvitation(clientId: string, userId: string): Observable<Response> {
    let body = new URLSearchParams();
    body.append('client_id', clientId);
    body.append('user_id', userId);

    return this.http.post<Response>(`${kBaseUrl}invitation/reject`, body.toString());
  }

  /**
   * Call alias API checker.
   * @param jsonData
   */
  checkAlias(jsonData) {
    return this.http.post<Response>(kBaseUrl + "check_alias", this.wrappJsonData(jsonData));
  }

  removeDemoData(strTitle) {
    return this.http.post<Response>(kBaseUrl + "remove_demo_data", this.wrappJsonData(strTitle));
  }

  getLaunchUrl() {
    return this.http.post<Response>(kBaseUrl + "get_launch_url", '');
  }

  /**
   * Updates the clients pdf config
   *
   * @param {object} objConfig
   */
  updateClientPDFConfig(objConfig): Observable<Response> {
    return this.http.post<Response>(kBaseUrl + 'update_client_pdf_config', this.wrappJsonData(objConfig))
  }

  /**
   * Updates the client's chat appliccation config
   *
   * @param {object} objConfig
   */
  updateClientChatApplicationConfig(objConfig): Observable<Response> {
    return this.http.post<Response>(kBaseUrl + 'update_branding_config', this.wrappJsonData(JSON.stringify(objConfig)))
  }

  /**
   * Get client config
   */
  getClientConfig(): Observable<Response> {
    return this.http.post<Response>(kBaseUrl + 'get_client_config', {});
  }

  /**
   * API Call to generate demo data.
   */
  generateDemoData(): Observable<Response> {
    return this.http.post<Response>(kBaseUrl + 'populate_demo_data', "").pipe(
      filter(data => {
        if (data['message'] == 'demo_data_populated') {
          return true;
        }
        return false;
      })
    );
  }

  /**
   * FC-1643: Fix switching account
   * Create a flag to identify that the user is switching account
   *
   * @returns {void}
   */
  triggerSwitchAccount() {
    this.fromSwitchAccount = true;
  }

  /**
   * Gets the value of the cookie given the key
   *
   * @param key
   */
  getCookieValue(key: string) {

    const value = '; ' + document.cookie;
    const parts = value.split('; ' + key + '=');

    if (parts.length == 2) {
      return parts.pop().split(';').shift();
    }

    return null;
  }

  /**
   * Prepares the campaign_origin value by getting the cookies and initializing them to objCampaignOrigin
   *
   * @returns object objCampaignOrigin
   */
  getCampaignOriginData() : object {

    let objCampaignOrigin = {};
    let strSourceKey = this.getCookieValue('utm_source_key');
    let strCampaign = this.getCookieValue('utm_campaign');
    let strLandingPage = this.getCookieValue('utm_landing_page');

    if (strSourceKey !== null) {
      objCampaignOrigin['utm_source_key'] = strSourceKey;
    }

    if (strCampaign !== null) {
      objCampaignOrigin['utm_campaign'] = strCampaign;
    }

    if (strLandingPage !== null) {
      objCampaignOrigin['utm_landing_page'] = strLandingPage;
    }

    return objCampaignOrigin;

  }

  /**
   * Retrieves the client config and forms the
   * resulting data for pdf generation purposes.
   *
   * @return {Observable<PdfConfig>}
   */
  getClientConfigForPdfPreviews(): Observable<PdfConfig> {
    return this.getClientConfig().map(response => {

      let objPdfData: PdfConfig = {} as PdfConfig;
      let objPDFConfig: LooseObject = (response && response['pdf']) ? response['pdf'] : {};
      let strCurrency: string = (response && response['currency']) ? response['currency'] : null;

      objPdfData.currency = currency_codes.find(currencies => (currencies.code == strCurrency)) || currency_codes[0];
      objPdfData.language = this.localStorage.getItem('user_locale');
      objPdfData.config = objPDFConfig;
      objPdfData.request_type = 'pdf';
      objPdfData.timezone = moment().format('Z');
      objPdfData.generated_date = moment().local().format('YYYY-MM-DD HH:mm:ss');

      if (!objPdfData.generated_by) {
        objPdfData.generated_by = this.localStorage.getItem('user_name');
      }
      return objPdfData;
    });
  }

  /**
   * Retrieves the SMS balance from the clients table.
   *
   * Couldn't use the one in local storage since we'd have to
   * reset the client list cache everytime we update the sms balance,
   * hence making a separate API.
   *
   * @returns {Observable<{credits: number,config: {sms_config?: LooseObject}}>}
   */
  getSmsBalance(): Observable<{
    credits: number,
    config: {
      sms_config?: LooseObject
    }
  }> {
    return this.http.post<{
      credits: number,
      config: {
        sms_config?: LooseObject
      }
    }>(kBaseUrl + "sms_balance", null);
  }

  /**
   * Add additional values to the client config json.
   *
   * @param {Config} objRequestData
   *
   * @returns { Observable<{ message: string} }
   */
   updateClientConfig(objRequestData: {

    steps?: boolean,

    sms_config?: {

      autocharge: boolean,

      threshold: number

    },

    notification?: {
      customer: CustomerNotification
    }

  }): Observable<{ message: string}> {
    return this.http.post<{message: string}>(`${environment.url}/admin_configure/update_client_config`, this.wrappJsonData(JSON.stringify(objRequestData)))
  }

  setActiveClientThenNavigate(client: Client): Observable<boolean> {
    // We're going to remove this attribute that we added because it isn't really
    // a part of the "Client" object.
    delete client['subscription'];

    this.clientStoreService.setActiveClient(client)
    this.clientStoreService.setPreviousClient(client);

    let redirectTo = '/jobs';

    if (! client.config.steps) {
      redirectTo = '/account/steps';
    }

    return this._redirect({
      redirect_to: redirectTo,
    }).pipe(
      first(),
    );
  }

  protected _redirect(opts: {
    redirect_to?: string;
  } = {}): Observable<boolean> {
    const stored = this.localStorageService.getItem('redirect_path');

    const defaultRedirectTo = fallback(opts.redirect_to, {
      fallback: () => '/jobs',
    });

    if (isNil(stored) || toString(stored).trim().length < 1) {
      return Observable.fromPromise(this.router.navigate([
        defaultRedirectTo,
      ]));
    }

    const parsed = this.router.parseUrl(trim(stored, '#'));
    const path = parsed.root.children[PRIMARY_OUTLET].toString();

    /// remove redirect path
    this.localStorageService.removeItem('redirect_path');

    if (path != '/') {
      return Observable.fromPromise(this.router.navigate([path], {
        queryParams: parsed.queryParams,
      }));
    }

    return Observable.fromPromise(this.router.navigate([
      defaultRedirectTo,
    ]));
  }
}
