

<span *ngIf="bLogoEditOnly; else sup">
  <a *ngIf="bShowView"
    id="iconDeleteRecord{{recordIndex}}"
    class="text-primary pointer icon-size mr-1"
    (click)="viewData(rowData.id)">
    <fa-icon [icon]="['fas', 'eye']"></fa-icon>
  </a>
  <a
    id="iconEditRecord{{rowData.id}}"
    class="text-success pointer icon-size mr-1"
    (click)="goToEdit(rowData.id)">
    <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
  </a>
  <a
    id="iconDeleteRecord{{recordIndex}}"
    class="text-warning pointer icon-size mr-1"
    (click)="deleteLink(rowData.id)">
    <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
  </a>
</span>

<ng-template #sup>

  <div class="d-flex buttons-container">

    <ng-container *fcHasPermission="strModule + ':edit'; else disabledEditButton">

      <!-- Edit button for mobile. -->
      <button *ngIf="rowData.editable == true || rowData.editable == undefined"
        type="button"
        class="btn btn-success mobile-container"
        [disabled]="loadingRecord"
        (click)="goToEdit(rowData.id, rowData)">
          <fa-icon [spin]="loadingRecord" [icon]="['fas', loadingRecord ? 'spinner' : 'pencil']" class="white"></fa-icon> &nbsp; {{ 'edit' | translate }}
      </button>

      <!-- Edit button for desktop. -->
      <a *ngIf="(rowData.editable == true || rowData.editable == undefined); else editIconDisabled"
        class="text-success pointer desktop-container icon-size"
        [ngClass]="loadingRecord ? 'disabled-click-event' : ''"
        (click)="goToEdit(rowData.id, rowData)">
        <fa-icon [spin]="loadingRecord" id="iconEditRecord{{recordIndex}}" [icon]="['fas', loadingRecord ? 'spinner' : 'pencil']"></fa-icon>
      </a>

      <!-- Disabled individual edit button for desktop. -->
      <ng-template #editIconDisabled>
        <span matTooltip="{{ strTooltipDisabled | translate }}" class="actions text-secondary desktop-container icon-size">
          <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
        </span>
      </ng-template>

    </ng-container>

    <ng-template #disabledEditButton>

      <!-- Disabled edit button for mobile. -->
      <button
        *ngIf="rowData.editable == true || rowData.editable == undefined"
        type="button"
        class="btn btn-success mobile-container"
        disabled>
        <fa-icon [icon]="['fas', 'pencil']" class="white"></fa-icon>
        <span class="pl-1" matTooltip="{{ 'forbidden_action_error' | translate }}">{{ 'edit' | translate }}</span>
      </button>

      <!-- Disabled edit button for desktop. -->
      <span matTooltip="{{ 'forbidden_action_error' | translate }}" class="text-secondary desktop-container icon-size">
        <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
      </span>

    </ng-template>

    <ng-container *fcHasPermission="strModule + ':delete'; else disabledDeleteButton">

      <button *ngIf="bAllowedDelete
        && (rowData.related_record == undefined || rowData.related_record.length < 1)
        && (rowData.deletable === true || rowData.deletable === undefined)"
        type="button"
        class="btn btn-warning mobile-container text-light"
        (click)="deleteLink(rowData.id)">
        <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon> &nbsp; {{ 'delete' | translate }}
      </button>

      <ng-container *ngIf="rowData.related_record != undefined && rowData.related_record.length > 0">
        <ng-container *ngIf="compiledRelatedData(rowData.related_record); let relatedData">
          <span matTooltip="{{ strModule + '_warning_disabled' | translate }} {{ relatedData }} {{ strModule + '_warning_info' | translate }}">
            <button *ngIf="bAllowedDelete && (rowData.deletable === true || rowData.deletable === undefined)"
              type="button"
              class="btn buttons ml-2 btn-secondary mobile-container"
              (click)="deleteLink(rowData.id)"
              disabled="true">
                <fa-icon [icon]="['fas', 'trash-alt']" class="white"></fa-icon> &nbsp;
                {{ 'delete' | translate }}
            </button>
          </span>
        </ng-container>
      </ng-container>

      <a *ngIf="bAllowedDelete && (rowData.related_record == undefined || rowData.related_record.length < 1)
        && (rowData.deletable === true || rowData.deletable === undefined); else deleteIconDisabled"
        id="iconDeleteRecord{{recordIndex}}"
        class="text-warning desktop-container pointer icon-size"
        (click)="deleteLink(rowData.id)">
        <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
      </a>

      <ng-container *ngIf="rowData.related_record != undefined && rowData.related_record.length > 0">
        <ng-container *ngIf="compiledRelatedData(rowData.related_record); let relatedData">
          <a *ngIf="bAllowedDelete"
            class="text-secondary desktop-container icon-size"
            [routerLink]=""
            href=""
            matTooltip="{{ strModule + '_warning_disabled' | translate }} {{ relatedData }} {{ strModule + '_warning_info' | translate }}">
              <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
          </a>
        </ng-container>
      </ng-container>

    </ng-container>

    <ng-template #disabledDeleteButton>

      <button *ngIf="bAllowedDelete"
        type="button"
        class="btn btn-warning mobile-container"
        disabled="true">
        <fa-icon [icon]="['fas', 'trash-alt']" class="white"></fa-icon>
        <span class="pl-1" matTooltip="{{ 'forbidden_action_error' | translate }}">{{ 'delete' | translate }}</span>
      </button>

      <span matTooltip="{{ 'forbidden_action_error' | translate }}" class="text-secondary desktop-container icon-size">
        <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
      </span>

    </ng-template>

  </div>

</ng-template>

