import { Component, Input, OnInit } from '@angular/core';
import { ListingService } from '../../../../services/listing.service';
import { ViewService } from '../../../../services/view.service';
import { MatDialog } from '@angular/material';
import { StockReceipt } from '../../../../objects/stock-management/stock-receipt';
import { AddMaterialsToJobComponent } from './add-materials-to-job/add-materials-to-job.component';
import { RecordService } from '../../../../services/record.service';

@Component({
  selector: 'app-purchase-orders-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit {
  @Input() strRecordId : string;
  public bPageLoaded: boolean = true;

  public objStockReceiptData: StockReceipt | null = null;
  public arMaterials = [];

  constructor(
    public listService: ListingService,
    private view: ViewService,
    private dialog: MatDialog,
    private record: RecordService,
  ) { }

  ngOnInit() {
    this.fetchList('default');
    this.listService.fetchData(null, 'stock_receipts', JSON.stringify({'purchase_order_id': this.strRecordId}))
      .map(response => {return response['data'].length > 0 ? response['data'][0] : null})
      .subscribe(response => {
        this.objStockReceiptData = (response !== null) ? new StockReceipt(response) : response;
      });
  }

  /**
   * Fetch the list of material.
   * @param strPage - what page is currently to be viewed.
   */
  fetchList(strPage) {

    let objPagination = this.listService.beforeFetching(strPage);

    this.bPageLoaded = false;

    // Get the list from API.
    this.listService.fetchData(JSON.stringify(objPagination['objPage']), 'materials', JSON.stringify({'purchase_order_id': this.view.arRecord['id']})).subscribe(response => {

      this.arMaterials = response['data'];
      this.listService.afterFetching(response, strPage);

      this.bPageLoaded = true;

    });
  }


  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arMaterials = []; // clear list
    this.fetchList('default');
  }

  /**
   * Opens dialog for adding materials from
   * receive ordered items.
   *
   * @returns {void}
   */
  openAddMaterialsToJob(): void {
    this.record.getRecordBasedOnParent(true).subscribe(response => {
      let details = response['record_details'];

      this.dialog.open(AddMaterialsToJobComponent, {
        width: '95%',
        height: 'auto',
        maxWidth: '100%',
        disableClose: false,
        data: {
          purchase_order_id: this.strRecordId,
          stock_receipt_data: this.objStockReceiptData,
          po_line_items: details['line_items'],
          job_id: '',
        }
      })
      .afterClosed()
      .subscribe(objDialogResponse => {
        this.onRefresh();
        if (objDialogResponse == 'materials_created') {
          this.view.reloadRecordView(true);
        }
      });
    });

  }

}
