
    <div class="dropdown-submenu">
      <div class="dropdown-item">
        <fieldmagic-icon
          id='btnRebuildSearchDatabase'
          (click)='viewBarcode()'
          icon='barcode'
          class="pointer"
        >
          <span>{{ 'barcode' | translate }}</span>
        </fieldmagic-icon>
      </div>
      <ul class="dropdown-menu">
        <div class="dropdown-item">
          <fieldmagic-icon
            id='btnRebuildSearchDatabase'
            (click)='viewBarcode()'
            icon='barcode'
            class="pointer"
          >
            <span>{{ 'view_barcode_list' | translate }}</span>
          </fieldmagic-icon>
        </div>

        <div class="dropdown-item">
          <fieldmagic-icon
            id='btnRebuildSearchDatabase'
            (click)='addBarcode()'
            icon='plus'
            class="pointer"
          >
            <span>{{ 'add_to_barcode_list' | translate }}</span>
          </fieldmagic-icon>
        </div>
        <div class="dropdown-item">
          <fieldmagic-icon
            id='btnRebuildSearchDatabase'
            (click)='clearBarcode()'
            icon='times'
          >
            <span>{{ 'clear_barcode_list' | translate }}</span>
          </fieldmagic-icon>
        </div>
      </ul>
    </div>
  