import { Subject, of, concat, Observable, BehaviorSubject } from 'rxjs';
import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap, switchMap, finalize, shareReplay } from 'rxjs/operators';
import * as _moment from 'moment';

import { cloneDeep as _cloneDeep, merge as _merge, isEmpty, get, toNumber, isNaN, toString, isNil, cloneDeep, isArray, set } from 'lodash-es';
import { FormService } from '../../../../services/form.service';
import { RecordService } from '../../../../services/record.service';
import { Select } from '../../../../objects/select';
import { Department } from '../../../../objects/department';
import { NotificationService } from '../../../../services/notification.service';
import { CustomTranslateService } from '../../../../services/custom-translate.service';
import { RelateIds } from '../../../../lists/relate-ids';
import { ViewService } from '../../../../services/view.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { ClientStoreService } from '../../../../services/client-store.service';
import { NumberService } from '../../../../services/helpers/number.service';
import { ArrService } from '../../../../services/helpers/arr.service';
import { StrService } from '../../../../services/helpers/str.service';
import { InputSanitizerService } from '../../../../services/input-sanitizer.service';
import { SelectTemplate } from '../../../../objects/select-template';
import { LooseObject } from '../../../../objects/loose-object';
import { toFormattedNumber } from '../../../../shared/utils/numbers';
import { ReadableAddressPipe } from '../../../../pipes/readable-address.pipe';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { blank, fallback, filled, isId } from '../../../../shared/utils/common';
import { sprintf } from 'sprintf-js';
import { MODULE_RELATE_TEXT_FIELDS } from '../../../form-templates/shared/constants';
import { ContextMenuComponent } from '../../../../shared/components/context-menu/context-menu.component';
import { ContextMenuService } from '../../../../services/context-menu.service';
import { UUID } from 'angular2-uuid';
import { SetUnsavedChangesData } from '../../../../objects/auto-save';
import { SupplierInvoiceLine } from '../../../../objects/line-items/supplier-invoice-line'
import { ItemsService } from '../../../../services/items.service';
import { EditformComponent } from '../../../../shared/components/editform/editform.component';
import { FormPopup } from '../../../../objects/centralized-forms/form-popup';
import { MaterialsService } from '../../../../services/materials.service';
import { ViewSupplierInventoryComponent } from '../../../../admin/items/view-supplier-inventory/view-supplier-inventory.component';
import { Relate } from '../../../../objects/relate';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-edit-supplier-invoice',
  templateUrl: './edit-supplier-invoice.component.html',
  styleUrls: ['./edit-supplier-invoice.component.scss'],
  providers: [CustomTranslateService]
})
export class EditSupplierInvoiceComponent implements OnInit {
  @ViewChild("invoiceLineDesc") textAreaInvoiceLineElement: ElementRef;
  @ViewChild(ContextMenuComponent) contextMenuComponent: ContextMenuComponent;

  public supplierInvoiceForm: any = [];
  public strRecordId: string = (this.data.record_id != undefined && this.data.record_id != null && this.data.record_id != '') ? this.data.record_id : null;
  public strViewType: string = this.data.view_type;
  public strRecordModule: string = (this.data.module != undefined) ? this.data.module : '';
  public objJobData: {} = {};
  public supplierInvoiceRecord: {} = {};
  public objCustomerData: {} = {};
  public objModuleConfig: {} = {};
  public arSupplierInvoiceFieldKey: any = [];
  public objSupplierInvoiceField = {};
  public bSubmitted: boolean = false;
  public bHasChanged: boolean = false;
  public bInvoiceLineLoaded: boolean = false;
  public hasError: boolean = false;
  public isTouched: boolean = false;
  public itemType: any = '';
  public arLineAttributes: any = [];
  public arTaxCodeFilter: any = { is_purchase: true };
  public arAccountCodeFilter: any = { is_purchase: true };
  public arItemFilter: any = { active: true, labor: false };
  public arReadOnlyId: any = [];
  public arRelateEmptyId: any = [];
  public arRequiredField: any = [];

  public initialRelateAccountCodeData: any = [];
  public initialRelateTaxCodeData: any = [];
  public initialRelateItemData: any = [];
  public initialRelateDepartmentData: any = [];
  public objRelateText: any = {};
  public objPurchaseOrderData: {} = {};

  public arTranslateables = [
    'exceed_supplier_invoice_amount',
    'total_purchase_order_amount',
    'total_supplier_invoice',
    'total_remaining_amount',
  ];

  public objDefaultTaxCode = {};
  public objDefaultAccountCode = {};
  public objCacheData: {} = {};
  public objClientRecord: {} = {};
  public pdfPreview: boolean = false;
  public objPurchaseOrderOnchange: {} = {};
  public bFormDirty: boolean = false;
  public previewTemplate: Subject<SelectTemplate> = new Subject<SelectTemplate>();
  public isPreviewTemplate: boolean = false;
  public realTimePreviewTemplate: Subject<SelectTemplate> = new Subject<SelectTemplate>();
  public strSupplierPaymentTermType: string;
  public strSupplierPaymentTermDays: string;
  public strPurchasingNotes: string;

  /**
   * If customer field is readonly
   *
   * @var {boolean}
   */
  public bIsCustomerFieldReadOnly: boolean = false;

  /**
   * If department tracking is enabled.
   *
   * @var {boolean}
   */
  public bDepartmentTracking: boolean = false;

  /**
   * When the supplier invoice is loading.
   *
   * @var {boolean}
   */
  public bLoading: boolean = false;
  public isAutoSave: boolean = false;
  public isFromUnsavedChanges: boolean = false;
  public autoSaveIntervalId;
  public relateChanges: LooseObject = {};
  public relateFields: Array<string> = [];
  public selectedLineItems: Array<LooseObject> = [];

  /// this contains the tax adjustment amount for the whole invoice
  totalTaxAdjustment: number = 0;

  whenInProgress$ = new BehaviorSubject<boolean>(false);

  /**
   * To determine that we checked all update on unit cost
   */
  bUpdateAllUnitCost: boolean = false;

  /**
   * To determine that we checked all update on supplier
   */
  bUpdateAllSupplier: boolean = false;

  /**
   * To determine that we checked all update on product unit cost
   */
  bUpdateAllProductUnitCost: boolean = false;

  /**
   * holds the record that we need to update
   */
  arItemsToUpsert: LooseObject[] = [];

  iconDisplay = {
    supplier_pricing: true,
    product_supplier: true,
    product_unit_cost: true,
  };

  isRelatedToJob: boolean = false;

  upsertMaterial: boolean = false;

  /**
   * determine if the SI is related to PO
   */
  isRelatedToPO: boolean = false

  /**
   * determine if the SI is related to PO
   */
  markAsInvoiced: boolean = false;

  focusedLineIndex: number = -1;

  get invoiceLineIncludedTax(): number {
    return this.computeInvoiceLineIncludedTax();
  }

  set invoiceLineIncludedTax(value: number) {
    const adjustment = toFormattedNumber(value, {
      currency: true,
    });

    const totalPrice = this.computeInvoiceLineExcludedTax();
    const totalTax = this.computeInvoiceLineTax();

    this.totalTaxAdjustment = toFormattedNumber(adjustment - (totalPrice + totalTax), {
      currency: true,
    });

    this.setAmountDue();
  }

  /**
   * FC-4265: Used when we update the SI data and we changed the Purchase Order id.
   *
   * @var {{ id: string, si_amount_to_add_to_po_remaining_amount: number }}
   */
  private objOriginalPurchaseOrderData: { id: string, si_amount_to_add_to_po_remaining_amount: number } = {
    id: '',
    si_amount_to_add_to_po_remaining_amount: 0
  }

  /**
   * hold's the original line item
   */
  originalLineItem: LooseObject[] = [];

  /**
   * to determine if we have update on unit cost
   */
  hasUpdateOnUnitCost: boolean = false;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  constructor(
    public clientStoreService: ClientStoreService,
    public dialogRef: MatDialogRef<EditSupplierInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public formService: FormService,
    public recordService: RecordService,
    public notifService: NotificationService,
    public customTranslate: CustomTranslateService,
    public viewService: ViewService,
    public numberService: NumberService,
    public arrService: ArrService,
    public strService: StrService,
    public contextMenuService: ContextMenuService,
    protected is: InputSanitizerService,
    protected localStorageService: LocalStorageService,
    private readableAddressPipe: ReadableAddressPipe,
    private translateService: TranslateService,
    private itemsService: ItemsService,
    private materialsService: MaterialsService,
  ) {}

  ngOnInit() {
    this.objClientRecord = this.clientStoreService.getActiveClient();
    this.bDepartmentTracking = this.clientStoreService.isDepartmentTracking();
    // Declare tax code and account code variable
    let defaultTaxCodePurchase = [];
    let defaultAccountCodePurchase = [];
    let dataChanges = this.formService.getUnsavedChangesData(get(this.data, 'supplier_invoice_id', ''), 'supplier_invoices', get(this.data, 'supplier_invoice', []), this.strRecordId);

    if (this.strViewType == 'edit') {
      this.recordService.getRecordAndParentRecord(
        'supplier_invoices', this.data['supplier_invoice_id'], !isEmpty(this.data.module) && !isEmpty(this.data.record_id)
      )
      .subscribe(([supplierInvoice, moduleRecord]) => {
        const numOriginalSIAmount: number = toNumber(get(supplierInvoice, 'record_details.amount', 0));
        this.objOriginalPurchaseOrderData = {
          id: get(supplierInvoice, 'record_details.purchase_order_id'),
          si_amount_to_add_to_po_remaining_amount: isNaN(numOriginalSIAmount) ? 0 : numOriginalSIAmount
        };
        // Set Job Data if record view is job get the record in view service
        this.objJobData = (this.strRecordModule == 'jobs') ? moduleRecord['record_details'] : [];
        // Set Customer Data if record view is customer get the record in view service
        this.objCustomerData = (this.strRecordModule == 'customers') ? moduleRecord['record_details'] : [];
        // Set Purchase Order Data if record view is purchase_orders get the record in view service
        this.objPurchaseOrderData = (this.strRecordModule == 'purchase_orders') ? moduleRecord['record_details'] : {};
        // Set supplier invoice record
        this.supplierInvoiceRecord = supplierInvoice;
        // Set supplier record details
        this.data['supplier_invoice'] = supplierInvoice['record_details'];
        // Set config of supplier invoice
        this.objModuleConfig['record_details'] = this.supplierInvoiceRecord['record_details'];
        this.objModuleConfig['record_view'] = this.supplierInvoiceRecord['record_view'];
        this.objModuleConfig['used_fields'] = this.supplierInvoiceRecord['used_fields'];
        this.totalTaxAdjustment = toFormattedNumber(get(this.supplierInvoiceRecord, 'record_details.tax_adjustment_amount'), {
          currency: true,
        });
        // Check if has related data
        if (this.supplierInvoiceRecord['related_data'] != undefined) {
          // Set all related data to objCacheData
          this.objCacheData = this.supplierInvoiceRecord['related_data'];
          // Check if default tax code exist
          if (this.supplierInvoiceRecord['related_data']['default_tax_code_purchase'] != undefined) {
            // Set default tax code
            defaultTaxCodePurchase = this.supplierInvoiceRecord['related_data']['default_tax_code_purchase'];
          }

          // Check if default account code exist
          if (this.supplierInvoiceRecord['related_data']['default_account_code_purchase'] != undefined) {
            // Set default account code
            defaultAccountCodePurchase = this.supplierInvoiceRecord['related_data']['default_account_code_purchase'];
          }

          // If job data is empty. Check the related job data in supplier invoice record. What does this mean the dialog is not from widget.
          if (this.objJobData['id'] == undefined && this.supplierInvoiceRecord['record_details']['job_id'] !== '' && this.supplierInvoiceRecord['record_details']['job_id'] !== null) {
            this.objJobData = {
              id: this.supplierInvoiceRecord['record_details']['job_id'],
              text: this.supplierInvoiceRecord['record_details']['job_text']
            }
          }
        }
        // If the record view is customers. Set customer record id
        if (this.strRecordModule == 'customers') {
          this.strRecordId = (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '';
        // If the record view is jobs. Set job record id
        } else if (this.strRecordModule == 'jobs') {
          this.strRecordId = (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '';
        }

        // Set default tax code
        this.objDefaultTaxCode = defaultTaxCodePurchase;
        // Set default account code
        this.objDefaultAccountCode = defaultAccountCodePurchase;

        if (dataChanges.length > 0) {
          this.notifService.sendConfirmation('do_you_want_to_apply_unsaved_changes', 'unsaved_changes')
          .subscribe((confirmation) => {
            if (confirmation.answer) {
              this.isFromUnsavedChanges = true;
              dataChanges.forEach(data => {
                let path = data['path'];
                let value = data['value1'];
                let relatedTextField = MODULE_RELATE_TEXT_FIELDS[path];
                // we need to change the customer text to supplier text
                // This is to set the previous related field change
                if (!isEmpty(relatedTextField)) {
                  let indexRelateTextField = dataChanges.findIndex(changes => changes['path'] == relatedTextField);
                  if (indexRelateTextField > -1) {
                    this.relateChanges[path] = dataChanges[indexRelateTextField]['value1'];
                  }
                }

                set(supplierInvoice['record_details'], path, value);
              });

              this.initializeComponent();
            } else {
              this.initializeComponent();
              this.formService.removeUnsavedChangesDataToLocalStorage(get(this.data, 'supplier_invoice_id', ''), 'supplier_invoices', this.strRecordId);
            }
          });
        } else {
          this.initializeComponent()
        }

        setTimeout( () => {
          this.onChanges();
        }, 2000);
      });

    } else {
      this.data['supplier_invoice'] = {};
      this.recordService.getConfigAndParentRecord(
        'supplier_invoices', !isEmpty(this.data.module) && !isEmpty(this.data.record_id)
      )
      .subscribe(([supplierInvoice, moduleRecord]) => {
        // Set Job Data if record view is job get the record in view service
        this.objJobData = (this.strRecordModule == 'jobs') ? moduleRecord['record_details'] : [];
        // Set Customer Data if record view is customer get the record in view service
        this.objCustomerData = (this.strRecordModule == 'customers') ? moduleRecord['record_details'] : [];
      // Set Purchase Order Data if record view is purchase order,  get the record in view service
        this.objPurchaseOrderData = (this.strRecordModule == 'purchase_orders') ? moduleRecord['record_details'] : [];
        // Set config of supplier invoice
        this.objModuleConfig = supplierInvoice;
        // Check if has related data
        if (this.objModuleConfig['related_data'] != undefined) {
          // Set all related data to objCacheData
          this.objCacheData = this.objModuleConfig['related_data'];
          // Check if default tax code exist
          if (this.objModuleConfig['related_data']['default_tax_code_purchase'] != undefined) {
            // Set default tax code
            defaultTaxCodePurchase = this.objModuleConfig['related_data']['default_tax_code_purchase'];
          }

          // Check if account code exist
          if (this.objModuleConfig['related_data']['default_account_code_purchase'] != undefined) {
            // Set account code
            defaultAccountCodePurchase = this.objModuleConfig['related_data']['default_account_code_purchase'];
          }
        }
        // If the record view is customers. Set customer record id
        if (this.strRecordModule == 'customers') {
          this.strRecordId = (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '';
        // If the record view is jobs. Set job record id
        } else if (this.strRecordModule == 'jobs') {
          this.strRecordId = (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '';
        } else if ( this.strRecordModule == 'purchase_orders' ){
          this.strRecordId = isId(this.objPurchaseOrderData['id']) ? this.objPurchaseOrderData['id'] : '';
        }

        // Set default tax code
        this.objDefaultTaxCode = defaultTaxCodePurchase;
        // Set default account code
        this.objDefaultAccountCode = defaultAccountCodePurchase;

        if (dataChanges.length > 0) {
          this.notifService.sendConfirmation('do_you_want_to_apply_unsaved_changes', 'unsaved_changes')
          .subscribe((confirmation) => {
            if (confirmation.answer) {
              this.isFromUnsavedChanges = true;
              dataChanges.forEach(data => {
                let path = data['path'];
                let value = data['value1'];
                let relatedTextField = MODULE_RELATE_TEXT_FIELDS[path];
                // we need to change the customer text to supplier text
                // relatedTextField = relatedTextField == 'customer_text' ? 'supplier_text' : relatedTextField;

                // This is to set the previous related field change
                if (!isEmpty(relatedTextField)) {
                  let indexRelateTextField = dataChanges.findIndex(changes => changes['path'] == relatedTextField);
                  if (indexRelateTextField > -1) {
                    this.relateChanges[path] = dataChanges[indexRelateTextField]['value1'];
                  }
                }

                if (path == 'tax_adjustment_amount') {
                  this.totalTaxAdjustment = toFormattedNumber(value, {
                    currency: true,
                  });
                }

                set(this.data['supplier_invoice'], path, value);
              });

              this.initializeComponent();
              this.onCreatePostInitializeComponent();
            } else {
              this.initializeComponent();
              this.onCreatePostInitializeComponent();
              this.formService.removeUnsavedChangesDataToLocalStorage(get(this.data, 'supplier_invoice_id', ''), 'supplier_invoices', this.strRecordId);
            }
          });
        } else {
          this.initializeComponent();
          this.onCreatePostInitializeComponent();
        }

        if (get(this.data, 'create_from') == 'stock_receipt') {
          let stockReceipt = get(this.data, 'stock_receipt');
          if (filled(stockReceipt) && filled(stockReceipt.purchase_order_id)) {
            this.setLineItemFromPurchaseOrder(stockReceipt.purchase_order_id);
          }
        }
      });
    }

    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

  initializeComponent() {
    this.customTranslate.initializeTransalateables(this.arTranslateables);

    if (filled(this.objPurchaseOrderData['id'])) {
      this.isRelatedToPO = true;
      this.markAsInvoiced = this.objPurchaseOrderData['status'] == 'sent';
    }

      this.initField();

      if (this.data.supplier_invoice != undefined && (this.strViewType == 'edit' || this.isFromUnsavedChanges)) {
        this.supplierInvoiceForm.map( form => {
          let objField = Object.keys(form['groups']['controls']);
          objField.forEach( strField => {
            let indexField = this.getFieldIndex(strField, form);
            if (indexField > -1) {
              if (form['fields'][indexField]['controlType'] == 'relate') {
                let textField = strField.replace('_id', '_text');
                let strText = this.data.supplier_invoice[textField];
                // If not found with customer text, we try to get via supplier_text
                if (blank(strText) && textField == 'customer_text') {
                  strText = this.data.supplier_invoice['supplier_text'];
                }

                form['fields'][indexField]['default_value'] = this.data.supplier_invoice[strField];
                form['fields'][indexField]['default_text'] = strText;
              }

              if (strField == 'customer_id' && this.strRecordModule == 'customers') {
                form['fields'][indexField]['readonly'] = true;
              }
              // Set default value for date and datetime
              if (form['fields'][indexField]['controlType'] == 'date' || form['fields'][indexField]['controlType'] == 'datetime') {
                form['fields'][indexField]['default_value'] = this.data.supplier_invoice[strField];
              }

              if (this.data.supplier_invoice[strField] != undefined) {
                this.fieldPatchValue(strField, this.data.supplier_invoice[strField]);
              }
            }
          });

          form['fields'].forEach(fieldMetadata => {
            if (fieldMetadata['controlType'] == 'relate') {
              this.relateFields.push(fieldMetadata['key']);
            }
          });
        });

        // Get index of accounting sync error and accounting sync detail
        let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
        let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
        let indexindexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.supplierInvoiceForm[indexOfAccountingSyncDetail]);
        // If field are exist
        if (indexOfAccountingSyncError > -1 && indexindexOfAccountingSyncDetailField > -1) {
          // Get the value of sync error
          let bAccountingSyncError = this.data.supplier_invoice['accounting_sync_error'];
          // If true show accounting sync detail field else hide it.
          if (bAccountingSyncError) {
            this.supplierInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = false;
          } else {
            this.supplierInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = true;
          }
        }

        this.triggerAutoSave();
      } else {
        let indexJobForm = this.getFormFieldIndex('job_id');
        let indexJobField = this.getFieldIndex('job_id', this.supplierInvoiceForm[indexJobForm]);

        let indexCustomerForm = this.getFormFieldIndex('customer_id');
        let indexCustomerField = this.getFieldIndex('customer_id', this.supplierInvoiceForm[indexCustomerForm]);
        // Set default customer data if exist
        if (this.objCustomerData['id'] != undefined) {
          if (indexCustomerForm > -1 && indexCustomerField > -1) {
            this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['default_value'] = this.objCustomerData['id'];
            this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['default_text'] = this.objCustomerData['customer_text'];
            this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['readonly'] = true;
            this.relateChanges['customer_id'] = get(this.objCustomerData, ['customer_text']);
            this.bIsCustomerFieldReadOnly = true;
          }
          this.fieldPatchValue('customer_id', (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '');

          // set the default payment term if customer data and supplier payment terms exists
          if (filled(this.objCustomerData['supplier_invoice_terms'])){
            this.setDefaultInvoiceDue(this.objCustomerData['supplier_payment_term_type'], this.objCustomerData['supplier_payment_term_days']);
          }
          else {
            if (filled(this.objModuleConfig['related_data']['default_supplier_payment_term'])){
              this.setDefaultInvoiceDue(this.objModuleConfig['related_data']['default_supplier_payment_term']['term_type'], this.objModuleConfig['related_data']['default_supplier_payment_term']['term_days'] );
            }
          }
        }
        else if (filled(this.objPurchaseOrderData['id'])){
          if (filled(this.objPurchaseOrderData['supplier_payment_term_type'] && filled(this.objPurchaseOrderData['supplier_payment_term_days']))){
            this.setDefaultInvoiceDue(this.objPurchaseOrderData['supplier_payment_term_type'], this.objPurchaseOrderData['supplier_payment_term_days']);
          }
        } else {
          if (filled(this.objModuleConfig['related_data']['default_supplier_payment_term'])){
            this.setDefaultInvoiceDue(this.objModuleConfig['related_data']['default_supplier_payment_term']['term_type'], this.objModuleConfig['related_data']['default_supplier_payment_term']['term_days'] );
          }
        }
        // Set default job data if exist
        if (this.objJobData['id'] != undefined) {
          if (indexJobForm > -1 && indexJobField > -1) {
            this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField]['default_value'] = this.objJobData['id'];
            this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField]['default_text'] = this.objJobData['job_number'];
            this.relateChanges['job_id'] = get(this.objJobData, ['job_number']);
          }
          this.fieldPatchValue('job_id', (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '');
        }
        // Get index of accounting sync error and accounting sync detail
        let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
        let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
        // If mode is create. Hide accounting sync detail as default
        let indexindexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.supplierInvoiceForm[indexOfAccountingSyncDetail]);
        if (indexOfAccountingSyncError > -1 && indexindexOfAccountingSyncDetailField > -1) {
          this.supplierInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = true;
        }
      }

      this.setLineItems();
      this.triggerAutoSave();
      this._enableCustomFlag();
  }

  /**
   * Initialize relate field
   * @param {string} strModule - module to get related data
   * @param strAttributeName  - attribute name i.e. arTimeEntryAttributes
   * @param strObservableName - Observable name i.e department
   * @param strIndex - Index of attribute
   * @param objFilter - Desired Filter
   */
  initRelateData(strModule, strAttributeName, strObservableName, strIndex, objFilter: any = false) {
    let strLoader = strObservableName + '_loader';
    if (strAttributeName != undefined && strAttributeName != '') this[strAttributeName][strIndex][strLoader] = true;
    if (strModule == 'items') {
      this.recordService.getProductRecordData('', objFilter, null, false).subscribe(result => {
        if (strAttributeName != undefined && strAttributeName != '') {
          this.initRelateRecords(strAttributeName, strModule, strObservableName, objFilter, result, strIndex);
          this[strAttributeName][strIndex][strLoader] = false;
        }
      });
    } else {
      this.recordService.getRecordRelate(strModule, '', false, false, objFilter).subscribe( result => {
        if (strAttributeName != undefined && strAttributeName != '') {
          this.initRelateRecords(strAttributeName, strModule, strObservableName, objFilter, result, strIndex);
          this[strAttributeName][strIndex][strLoader] = false;
        }
      });
    }
  }

  /**
   * Initialize Main Field
   */
  initField() {
    // To set data of custom attributes as default value
    if (this.objModuleConfig['record_details']['custom_attributes'] != undefined && this.objModuleConfig['record_details']['custom_attributes'] != null && this.objModuleConfig['record_details']['custom_attributes'] != '') {
      let customAttributeKeys = Object.keys(this.objModuleConfig['record_details']['custom_attributes']);
      customAttributeKeys.forEach( strKey => {
        // Append each custom attributes to record details
        this.objModuleConfig['record_details'][strKey] = this.objModuleConfig['record_details']['custom_attributes'][strKey];
      });
    }

    // Add filter to customer relate
    if (this.objModuleConfig['used_fields']['customer_id'] !== undefined) {
      this.objModuleConfig['used_fields']['customer_id']['filter'] = {'is_supplier': true};
    }

    let arFormData = this.formService.formData(this.objModuleConfig['record_view'], this.objModuleConfig['used_fields'], this.objModuleConfig['record_details']);
    this.supplierInvoiceForm  = arFormData.map(
      formItems => {
        formItems['id'] = formItems['label'].toLowerCase().replace(/ /g,'_');
        formItems['groups'] = this.formService.toFormGroup(formItems['fields'])
        return formItems;
      }
    );
  }

  /**
   * On changes of form
   */
  onChanges(): void {
    // Get index of this field
    let indexOfPurchaseOrderId = this.getFormFieldIndex('purchase_order_id');
    let indexOfJob = this.getFormFieldIndex('job_id');
    let indexOfSyncToAccounting = this.getFormFieldIndex('sync_to_accounting');
    let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
    let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
    let indexindexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.supplierInvoiceForm[indexOfAccountingSyncDetail]);
    let indexOfAmountPaid = this.getFormFieldIndex('amount_paid');
    let indexOfAmountDue = this.getFormFieldIndex('amount_due');

    // When purchase_order_id field is exist. Trigger when value change
    if (indexOfPurchaseOrderId > -1) {
        this.supplierInvoiceForm[indexOfPurchaseOrderId]['groups'].get('purchase_order_id').valueChanges.subscribe(val => {
          // Check if val has value
          let strPurchaserOrderId = (val != null && val != undefined && val != '') ? val : '';
          if (strPurchaserOrderId) {
            this.bInvoiceLineLoaded = false;
            // If has purchase order id
            this.setLineItemFromPurchaseOrder(strPurchaserOrderId);
          }
        });
    }
    // When job id field is exist. Trigger when value change
    if (indexOfJob > -1) {
      this.supplierInvoiceForm[indexOfJob]['groups'].get('job_id').valueChanges.subscribe(val => {
        this.objJobData = [];
        // Check if val has value then assigned it to strRecordId(job_id)
        this.strRecordId = this.strService.fallsBackTo(val);
        if (filled(this.strRecordId) && isId(this.strRecordId)) {
          // If has job id get the data
          this.recordService.getRecordRelateJoined('jobs', this.strRecordId).subscribe( result => {
            // Set data
            this.objJobData = (!isEmpty(result[0])) ? result[0] : [];
          });
        }
      });
    }
    // Onchange of accounting sync error. If true show accounting sync detail else hide it.
    if (indexOfAccountingSyncError > -1 && indexindexOfAccountingSyncDetailField > -1) {
      this.supplierInvoiceForm[indexOfAccountingSyncError]['groups'].get('accounting_sync_error').valueChanges.subscribe(val => {
        if (val) {
          this.supplierInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = false;
        } else {
          this.supplierInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = true;
        }
      });
    }
    // Onchange of sync to accounting. Clear the value of accounting sync detail
    if (indexOfSyncToAccounting > -1) {
      this.supplierInvoiceForm[indexOfSyncToAccounting]['groups'].get('sync_to_accounting').valueChanges.subscribe(val => {
        this.supplierInvoiceForm[indexOfSyncToAccounting]['groups'].patchValue({
          accounting_sync_detail: ''
        });
      });
    }

    // If amount paid field exist
    if (indexOfAmountPaid > -1) {
      this.supplierInvoiceForm[indexOfAmountPaid]['groups'].get('amount_paid').valueChanges.subscribe(val => {
        let totalIncludedTax: number = this.computeInvoiceLineIncludedTax();
        // Clear accounting sync detail when sync to accounting is change
        let strAmountDue: number = totalIncludedTax - parseFloat(val);
        this.supplierInvoiceForm[indexOfAmountDue]['groups'].patchValue({
          amount_due: !isNaN(strAmountDue) ? toFormattedNumber(strAmountDue, {
            currency: true,
          }) : 0
        });
      });
    }
  }

  /**
   * Removes the line item.
   * @param attr - the object to be removed.
   */
  removeAttribute(attr) {
    this.arLineAttributes.splice(this.arLineAttributes.indexOf(attr), 1);
    this.focusedLineIndex = -1;
    this.setAmountDue();
    this.markAsDirty();
  }

  addLineAttribute(objData: any = [], bRecompute: boolean = true, bFromUi: boolean = false, lineItemIndex: number = -1) {

    // Generate a unique name for the lineitem.
    let newId = 'nameField' + this.arLineAttributes.length + Math.floor((Math.random() * 10) + 1);
    let selectLineId = UUID.UUID();

    // If the generated name is indeed unique (no existing item of a similar name found.).
    if (this.arLineAttributes.findIndex(attr => (attr['id_type'] == newId)) < 0) {

      if (Object.keys(objData).length > 0) {
        let strTaxCodeId = '';
        let strTaxCode = '';
        let strTaxCodeName = '';
        let strTaxRate: number = 0;
        let objTaxCode = null;

        let strAccountCodeId = '';
        let strAccountCode = '';
        let strAccountCodeName = '';
        let objAccountCode = null;

        if (objData['tax_code_id'] != undefined && objData['tax_code_id'] != null && objData['tax_code_id'] != '') {
          strTaxCodeId = objData['tax_code_id'];
          strTaxCode = objData['tax_code'];
          strTaxCodeName = objData['tax_code_name'];
          strTaxRate = parseFloat(objData['tax_rate']) * 0.01;
          objTaxCode = new Select(objData['tax_code_id'], objData['tax_code_name']);
        } else if (this.objDefaultTaxCode['id'] != undefined && this.objDefaultTaxCode['id'] != null && this.objDefaultTaxCode['id'] != '') {
          strTaxCodeId = this.objDefaultTaxCode['id'];
          strTaxCode = this.objDefaultTaxCode['code'];
          strTaxCodeName = this.objDefaultTaxCode['name'];
          strTaxRate = parseFloat(this.objDefaultTaxCode['rate']) * 0.01;
          objTaxCode = new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']);
        }

        if (objData['account_code_id'] != undefined && objData['account_code_id'] != null && objData['account_code_id'] != '') {
          strAccountCodeId = objData['account_code_id'];
          strAccountCode = objData['account_code'];
          strAccountCodeName = objData['account_code_name'];
          objAccountCode = { id: objData['account_code_id'], name: objData['account_code_name'] };
        } else if (this.objDefaultAccountCode['id'] != undefined && this.objDefaultAccountCode['id'] != null && this.objDefaultAccountCode['id'] != '') {
          strAccountCodeId = this.objDefaultAccountCode['id'];
          strAccountCode = this.objDefaultAccountCode['code'];
          strAccountCodeName = this.objDefaultAccountCode['name'];
          objAccountCode = { id: this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name'] };
        }

        // Set value of relate field
        let strItemId = bFromUi ? objData['id'] : objData['item_id']
        let strItemName = objData.name || objData.item_name;
        let strItemCode = objData.code || objData.item_code;
        let strDescription = objData.description || objData.name;
        let strQuantity: number = objData.quantity || 1;
        let strUnitCost: number = toFormattedNumber(objData.unit_cost, {
          currency: true,
          maxDecimalPlaces: 4,
        });
        let strDepartmentName = objData['department_name'] || null;
        let strDepartmentId = objData['department_id'] || null;
        let strItemAccountingId = objData['item_accounting_id'];
        let objDepartment = (strDepartmentId) ? {id: strDepartmentId, text: strDepartmentName, department_name: strDepartmentName} : null;
        let objItem = (strItemId) ? new Select(strItemId, strItemName) : {};

        const invoicedQuantity = toFormattedNumber(get(objData, 'invoiced_quantity', 0));

        strQuantity = toFormattedNumber(strQuantity) - invoicedQuantity;

        let indexOfSupplierField = this.getFormFieldIndex('customer_id');

        if (indexOfSupplierField > -1 && get(objData, 'supplier_pricing') && bFromUi) {
          let customerId = this.supplierInvoiceForm[indexOfSupplierField]['groups'].controls.customer_id.value;
          let itemSupplierIndex = objData['supplier_pricing'].findIndex(supplier => (supplier.customer_id == customerId));

          if (itemSupplierIndex > -1) {
            strUnitCost = objData['supplier_pricing'][itemSupplierIndex]['unit_cost'];
          }
        }

        let arDefaultJob = get(objData, 'job_id') ? [{
          job_id: get(objData, 'job_id'),
          job_number: get(objData, 'job_number'),
        }] : [];

        let lineItemsData = {
          id : "",
          supplier_invoice: {
            item_id: strItemId,
            item_name: strItemName,
            item_code: strItemCode,
            tax_code: strTaxCode,
            account_code: strAccountCode,
            description: strDescription,
            quantity: strQuantity,
            unit_cost: strUnitCost,
            tax_code_id: strTaxCodeId,
            tax_code_name: strTaxCodeName,
            tax_rate: strTaxRate * 100, // To convert again the tax rate to percentage Ex. 10%
            account_code_name: strAccountCodeName,
            account_code_id: strAccountCodeId,
            department_id: strDepartmentId,
            department_name: strDepartmentName,
            item_accounting_id: strItemAccountingId,
            current_stock_level: objData['current_stock_level'] || 0,
            purchase_order_line_item_id: get(objData, 'purchase_order_line_item_id'),
            preferred_supplier_text: get(objData, 'preferred_supplier_text', null),
            labor: objData['labor'],
            material_id: get(objData, 'material_id'),
            job_id: get(objData, 'job_id'),
            job_number: get(objData, 'job_number'),
          },
          id_type: newId,
          item: objItem,
          tax: objTaxCode,
          account_code: objAccountCode,
          department: objDepartment,
          item_obv: new Observable<Select[]>(),
          item_typehead: new Subject<string>(),
          item_loader: false,
          taxcode_obv: new Observable<Select[]>(),
          taxcode_typehead: new Subject<string>(),
          taxcode_loader: false,
          account_code_obv: new Observable<Select[]>(),
          account_code_typehead: new Subject<string>(),
          account_code_loader: false,
          department_obv: new Observable<Select[]>(),
          department_typehead: new Subject<string>(),
          department_loader: false,
          amount: toFormattedNumber(strUnitCost * strQuantity, {
            currency: true,
          }),
          tax_rate: strTaxRate,
          select_line_id: selectLineId,
          related_products: get(objData, 'related_products'),
          supplier_pricing: get(objData, 'supplier_pricing'),
          update_supplier_pricing: false,
          update_product_supplier: false,
          update_product_buy_price: false,
          job: new Relate<any>().buildRelates(
            switchMap(term => this.recordService.getRecordRelate('jobs', term,'' )),
            arDefaultJob,
            filled(arDefaultJob)
          ),
          job_data: arDefaultJob[0],
        }

        if(lineItemIndex != -1) {
          this.arLineAttributes.splice(lineItemIndex, 0, lineItemsData);
        } else if (this.focusedLineIndex > -1) {
          this.arLineAttributes.splice(this.focusedLineIndex, 0, lineItemsData);
        } else {
          this.arLineAttributes.push(lineItemsData);
        }
      } else {
        let arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.objModuleConfig['related_data'], 'default_item_accounting', []);
        let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';
        let objDepartmentJob = new Department().getJobDepartment(this.objJobData);

        const objSupplierInvoiceData = {
            id : "",
            supplier_invoice: {
              item_id: null,
              item_code: null,
              item_name: null,
              account_code: filled(this.objDefaultAccountCode['code']) ? this.objDefaultAccountCode['code'] : null,
              tax_code: filled(this.objDefaultTaxCode['code']) ? this.objDefaultTaxCode['code'] : null,
              department_id: this.strService.fallsBackTo(objDepartmentJob.department_id),
              department_name: this.strService.fallsBackTo(objDepartmentJob.department_text),
              description: null,
              quantity: 1,
              unit_cost: '0.00',
              tax_code_id: filled(this.objDefaultTaxCode['id']) ? this.objDefaultTaxCode['id'] : null,
              tax_code_id_name: filled(this.objDefaultTaxCode['name']) ? this.objDefaultTaxCode['name'] : null,
              account_code_name: filled(this.objDefaultAccountCode['name']) ? this.objDefaultAccountCode['name'] : null,
              account_code_id: filled(this.objDefaultAccountCode['id']) ? this.objDefaultTaxCode['id'] : null,
              item_accounting_id: strDefaultItemAccountingId,
              preferred_supplier_text: get(objData, 'preferred_supplier_text', null),
            },
            id_type: newId,
            item: null,
            tax: filled(this.objDefaultTaxCode['id']) ? new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']) : null,
            account_code: filled(this.objDefaultAccountCode['id']) ? {id : this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name']} : null,
            department: !isEmpty(objDepartmentJob.department) ? objDepartmentJob.department : null,
            item_obv: new Observable<Select[]>(),
            item_typehead: new Subject<string>(),
            item_loader: false,
            taxcode_obv: new Observable<Select[]>(),
            taxcode_typehead: new Subject<string>(),
            taxcode_loader: false,
            account_code_obv: new Observable<Select[]>(),
            account_code_typehead: new Subject<string>(),
            account_code_loader: false,
            department_obv: new Observable<Select[]>(),
            department_typehead: new Subject<string>(),
            department_loader: false,
            amount: 0,
            tax_rate: filled(this.objDefaultTaxCode['id']) ? parseFloat(this.objDefaultTaxCode['rate']) * 0.01 : 0,
            select_line_id: selectLineId,
            labor: null,
            related_products: [],
            supplier_pricing: [],
            update_supplier_pricing: false,
            update_product_supplier: false,
            update_product_buy_price: false,
        };

        if (this.focusedLineIndex > -1) {
          this.arLineAttributes.splice(this.focusedLineIndex, 0, objSupplierInvoiceData);
        } else {
          this.arLineAttributes.push(objSupplierInvoiceData);
        }
      }

      // For Tax Code Relate
      this.initRelateRecords('arLineAttributes', 'tax_codes', 'taxcode', this.arTaxCodeFilter, this.initialRelateTaxCodeData);
      // For Items Relate
      this.initRelateRecords('arLineAttributes', 'items', 'item', this.arItemFilter, this.initialRelateItemData);
      // For Account Code Relate
      this.initRelateRecords('arLineAttributes', 'account_codes', 'account_code', this.arAccountCodeFilter, this.initialRelateAccountCodeData);
      // For Department Relate
      this.initRelateRecords('arLineAttributes', 'departments', 'department', false, this.initialRelateDepartmentData);
      this.bInvoiceLineLoaded = true;

      this.originalLineItem = cloneDeep(this.arLineAttributes);

      if (bRecompute) {
        this.setAmountDue();
      }

      if (bFromUi) {
        this.markAsDirty();
      }
    }
  }

  /**
   * Compute total cost, price and amount when quantity is change
   * @param event
   * @param attr
   * @param strModule
   */
  quantityChange(event, attr) {
    let numLineItem = this.arLineAttributes.findIndex(line => (line.id_type == attr.id_type));
    if (numLineItem > -1) {
      let quantity: number = (event.target.value != '' && event.target.value > 0) ? event.target.value : 1;
      let totalCost: number = parseFloat(this.arLineAttributes[numLineItem]['supplier_invoice']['unit_cost']) * quantity;
      this.arLineAttributes[numLineItem]['supplier_invoice']['quantity'] = quantity;
      this.arLineAttributes[numLineItem]['amount'] = toFormattedNumber(totalCost, {
        currency: true,
      })
      this.setAmountDue();
    }
    this.markAsDirty()
  }

  /**
   * Compute data when unit price is change
   * @param event
   * @param attr
   * @param strModule
   */
  unitCostChange(event, attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arLineAttributes.findIndex(line => (line.id_type == attr.id_type));
    if (numLineItem > -1) {
      let unitCost: number = (event.target.value != '') ? event.target.value : 0;
      let totalAmount: number = (unitCost * this.arLineAttributes[numLineItem]['supplier_invoice']['quantity']);
      this.arLineAttributes[numLineItem]['amount'] = toFormattedNumber(totalAmount, {
        currency: true,
      });
      this.arLineAttributes[numLineItem]['supplier_invoice']['unit_cost'] = toFormattedNumber(unitCost, {
        currency: true,
        maxDecimalPlaces: 4,
      });
      this.setAmountDue();
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectATaxcode(event, attr) {
    let strTaxCodeId = (event == undefined || event == null) ? null : event['id'];
    let strTaxName = (event == undefined || event == null) ? null : event['name'];
    let strTaxCode = (event == undefined || event == null) ? null : event['code'];
    let strTaxRate = (event == undefined || event == null) ? 0 : parseFloat(event['rate']) * 0.01;

    let numLineItem = this.arLineAttributes.findIndex(line => (line.id_type == attr.id_type));

      // If the item is found.
      if (numLineItem > -1) {
          // Set the values of the line item.
          this.arLineAttributes[numLineItem]['supplier_invoice']['tax_code_id'] = strTaxCodeId;
          this.arLineAttributes[numLineItem]['supplier_invoice']['tax_code'] = strTaxCode;
          this.arLineAttributes[numLineItem]['supplier_invoice']['tax_code_name'] = strTaxName;
          this.arLineAttributes[numLineItem]['supplier_invoice']['tax_rate'] = parseFloat(fallback(get(event, 'rate'), {
            fallback: () => '0',
          }));
          this.arLineAttributes[numLineItem]['tax_rate'] = strTaxRate;
          this.setAmountDue();
      }
    this.markAsDirty();
  }
  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectAccountCode(event, attr) {
    let strAccountCodeId = (event == undefined || event == null) ? null : event['id'];
    let strAccountName = (event == undefined || event == null) ? null : event['name'];
    let strAccountCode = (event == undefined || event == null) ? null : event['code'];

    let numLineItem = this.arLineAttributes.findIndex(line => (line.id_type == attr.id_type));
    // If the item is found.
    if (numLineItem > -1) {
        this.arLineAttributes[numLineItem]['supplier_invoice']['account_code_id'] = strAccountCodeId;
        this.arLineAttributes[numLineItem]['supplier_invoice']['account_code'] = strAccountCode;
        this.arLineAttributes[numLineItem]['supplier_invoice']['account_code_name'] = strAccountName;
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectDepartment(event, attr) {
    let strDepartmentCodeId = (event == undefined || event == null) ? null : event['id'];
    let strDepartmentName = (event == undefined || event == null) ? null : event['department_name'];

    // Find the index of the line item that was selected.
    let numLineItem = this.arLineAttributes.findIndex(line => (line.id_type == attr.id_type));

    // If the item is found.
    if (numLineItem > -1) {
      // Set the values of the line item.
      this.arLineAttributes[numLineItem]['supplier_invoice']['department_id'] = strDepartmentCodeId;
      this.arLineAttributes[numLineItem]['supplier_invoice']['department_name'] = strDepartmentName;
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectProduct(event, attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arLineAttributes.findIndex(line => (line.id_type == attr.id_type));
    let arDefaultItemAccounting;
    if (this.strViewType === 'edit') {
      arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.supplierInvoiceRecord['related_data'], 'default_item_accounting', []);
    } else {
      arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.objModuleConfig['related_data'], 'default_item_accounting', []);
    }

    let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';

    if (numLineItem > -1) {
      if (event != undefined && event != '' && event != null) {
        event['unit_cost'] = (event.unit_cost != undefined && event.unit_cost != '') ? event.unit_cost : 0;
        let arTaxCode: [] | {} = [];
        let arAccountCode: [] | {} = [];

        event['description'] = (event['description'] != "" && event['description'] != undefined) ? event['description'] : "";

        if (event['default_purchase_tax_code_id'] != null && event['default_purchase_tax_code_id'] != '') {
          arTaxCode = {
            'id' : event['tax_code_id'],
            'rate' : event['tax_rate'],
            'code' : event['tax_code'],
            'name' : event['tax_code_name'],
            'text' : event['tax_code_name']
          }
        } else {
          if (this.strViewType == 'edit') {
            if (this.supplierInvoiceRecord['related_data'] != undefined && this.supplierInvoiceRecord['related_data']['default_tax_code_purchase'] != undefined) {
              arTaxCode = this.supplierInvoiceRecord['related_data']['default_tax_code_purchase'];
            }
          } else {
            if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_tax_code_purchase'] != undefined) {
              arTaxCode = this.objModuleConfig['related_data']['default_tax_code_purchase'];
            }
          }
        }

        if (event['default_purchase_account_code_id'] != null && event['default_purchase_account_code_id'] != '') {
          arAccountCode = {
            'id' : event['account_code_id'],
            'code' : event['account_code'],
            'name' : event['account_code_name']
          }
        } else {
          if (this.strViewType == 'edit') {
            if (this.supplierInvoiceRecord['related_data'] != undefined && this.supplierInvoiceRecord['related_data']['default_account_code_purchase'] != undefined) {
              arAccountCode = this.supplierInvoiceRecord['related_data']['default_account_code_purchase'];
            }
          } else {
            if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_account_code_purchase'] != undefined) {
              arAccountCode = this.objModuleConfig['related_data']['default_account_code_purchase'];
            }
          }
        }
        // Set the values of the line item.
        let total_cost: number = (parseFloat(this.arLineAttributes[numLineItem]['supplier_invoice']['quantity']) * event['unit_cost']);

        this.arLineAttributes[numLineItem]['amount'] = this.numberService.roundToTwo(total_cost);
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_id'] = event['id'];
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_name'] = event['name'];
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_code'] = event['code'];
        this.arLineAttributes[numLineItem]['supplier_invoice']['unit_cost'] = event['unit_cost'];
        this.arLineAttributes[numLineItem]['supplier_invoice']['description'] = event['description'];
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_accounting_id'] = (event['accounting_id'] !== null && event['accounting_id'] !== '') ? event['accounting_id'] : strDefaultItemAccountingId;

        // Tax code relate data
        let strTaxRate = (arTaxCode && arTaxCode['rate'] != undefined && arTaxCode['rate'] != null && arTaxCode['rate'] != '') ? parseFloat(arTaxCode['rate']) * 0.01 : 0;
        this.arLineAttributes[numLineItem]['supplier_invoice']['tax_code_id'] = (arTaxCode && arTaxCode['id'] != undefined && arTaxCode['id'] != null) ? arTaxCode['id'] : '';
        this.arLineAttributes[numLineItem]['supplier_invoice']['tax_code'] = (arTaxCode && arTaxCode['code'] != undefined && arTaxCode['code'] != null) ? arTaxCode['code'] : '';
        this.arLineAttributes[numLineItem]['tax'] = (arTaxCode && arTaxCode['id'] != undefined) ? arTaxCode : null;
        this.arLineAttributes[numLineItem]['tax_rate'] = strTaxRate;
        // Account code relate data
        this.arLineAttributes[numLineItem]['supplier_invoice']['account_code_id'] = (arAccountCode && arAccountCode['id'] != undefined && arAccountCode['id'] != null) ? arAccountCode['id'] : '';
        this.arLineAttributes[numLineItem]['supplier_invoice']['account_code'] = (arAccountCode && arAccountCode['code'] != undefined && arAccountCode['code'] != null) ? arAccountCode['code'] : '';
        this.arLineAttributes[numLineItem]['account_code'] = (arAccountCode && arAccountCode['id'] != undefined) ? arAccountCode : null;
        this.setAmountDue();
      } else {
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_id'] = '';
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_name'] = '';
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_code'] = '';
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_accounting_id'] = strDefaultItemAccountingId;
      }
    }
    this.markAsDirty();
  }

  /**
   * Compute Total Invoice Line Tax
   */
  computeInvoiceLineTax() {
    let computedTax: number = 0;

    this.arLineAttributes.forEach( data => {
      let amount: number = (data.amount != undefined && data.amount != '' && data.amount != null && data.amount != 'NaN') ? parseFloat(data.amount) : 0;
      let tax_rate = (data.tax_rate != undefined && data.tax_rate != '' && data.tax_rate != null && data.amount != 'NaN') ? parseFloat(data.tax_rate) : 0;
      computedTax += amount * tax_rate;
    });

    return toFormattedNumber(computedTax, {
      currency: true,
    });
  }

  /**
   * Compute Total Excluded Tax (Invoice Line Items Layout)
   */
  computeInvoiceLineExcludedTax() {
    let computedExcludedTax: number = 0;

    this.arLineAttributes.forEach( data => {
      let amount: number = (data.amount != undefined && data.amount != '' && data.amount != null && data.amount != 'NaN') ? parseFloat(data.amount) : 0;
      computedExcludedTax += amount;
    });

    return toFormattedNumber(computedExcludedTax, {
      currency: true,
    });
  }

  /**
   * Compute Total Included Tax (Invoice Line Items Layout)
   */
  computeInvoiceLineIncludedTax() {
    let computedIncludedTax: number = this.computeInvoiceLineExcludedTax() + this.computeInvoiceLineTax();

    return toFormattedNumber(computedIncludedTax + toNumber(this.totalTaxAdjustment), {
      currency: true,
    });
  }
  /**
   * Get index of form
   * @param strField
   */
  getFormFieldIndex(strField) {

    return this.supplierInvoiceForm.findIndex(attr => (attr.groups.get(strField) != undefined && attr.groups.get(strField) != null));
  }
  /**
   * Get index of field
   * @param strField
   * @param form
   */
  getFieldIndex(strField, form) {
    if (form != undefined && form['fields'] != undefined) {
      return form['fields'].findIndex( attr => (attr['key'] == strField));
    } else {
      return -1;
    }
  }
  /**
   * Set value of field
   * @param strField
   * @param strValue
   */
  fieldPatchValue(strField, strValue) {
    let indexOfFormField = this.getFormFieldIndex(strField);
    if (indexOfFormField > -1) {
      this.supplierInvoiceForm[indexOfFormField]['groups'].patchValue({
        [strField] : strValue,
      }, {emitEvent: false, onlySelf: true});
    }
  }

  /**
   * Validate Request
   * @param objRequest
   */
  validateRequest(objRequest, bLineItem = true) {
    for (let key in objRequest) {
      if (this.arRequiredField.indexOf(key) > -1 && key != 'line_items') {
        if((objRequest[key] == undefined || objRequest[key] == '' || objRequest[key] == null || objRequest[key].length < 1)) {
          let indexOfFormField = this.getFormFieldIndex(key);
          if (indexOfFormField > -1) {
            this.supplierInvoiceForm[indexOfFormField]['groups']['controls'][key].markAsTouched();
            this.supplierInvoiceForm[indexOfFormField]['groups']['controls'][key].setErrors({'incorrect' : true});
          }
          this.hasError = true;
        }
      }
      switch(key) {
        case 'tax_code_id':
        case 'tax_code':
        case 'tax_code_name':
        case 'account_code_id':
        case 'account_code':
        case 'account_code_name':
          if(objRequest[key] == '' || objRequest[key] == null || objRequest[key] == undefined) {
            this.hasError = true;
          }
        break;
        case 'department_id':
        if (
          this.bDepartmentTracking &&
          (objRequest[key] == '' || objRequest[key] == null || objRequest[key] == undefined) &&
          bLineItem
        ) {
          this.hasError = true;
        }
        break;
      }

    }
  }

  /**
   * Validate Field
   * @param strValue
   */
  validateField(strValue) {
    if (strValue == '' || strValue == null || strValue == undefined) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Set line items
   */
  setLineItems() {
    if (this.data.supplier_invoice != undefined && (this.strViewType == 'edit' || this.isFromUnsavedChanges)) {
      if (this.data.supplier_invoice.line_items != undefined && this.data.supplier_invoice.line_items != null && this.data.supplier_invoice.line_items.length > 0) {
        this.data.supplier_invoice.line_items.forEach( data => {
          this.addLineAttribute(data, false);
        });
      } else {
        this.bInvoiceLineLoaded = true;
      }
    } else {
      this.bInvoiceLineLoaded = true;
    }
  }

  /**
   * Initialize relate fields
   * @param strAttributeName - object attribute
   * @param strModule - module for relate
   * @param strObservableName - observable that is set
   * @param objMainFilter - Main filter for record (onChange of the fields)
   * @param arRelateInitialValue - Initial relate value
   */
  initRelateRecords(strAttributeName, strModule, strObservableName, objMainFilter, arRelateInitialValue, curentIndex: number | string = '') {
    // Get the lastest index.
    let newIndex = (curentIndex === '') ? this[strAttributeName].length - 1 : curentIndex;
    let strObservable = strObservableName + '_obv';
    let strTypehead = strObservableName + '_typehead';
    let strLoader = strObservableName + '_loader';
    if (strModule == 'items') {
      // Set a default dropdown value for this line item.
      this[strAttributeName][newIndex][strObservable] = concat(
        of(arRelateInitialValue),
        this[strAttributeName][newIndex][strTypehead].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this[strAttributeName][newIndex][strLoader] = true),
          switchMap((term: string) => this.recordService.getProducts({
            term: term,
            filter: objMainFilter,
          }).pipe(
            shareReplay(),
            tap(() => {
              this[strAttributeName][newIndex][strLoader] = false;
            })
          ))
        )
      )
    } else if(['tax_codes', 'account_codes', 'departments'].includes(strModule)) {
      // Set a default dropdown value for this line item.
      this[strAttributeName][newIndex][strObservable] = concat(
        of(arRelateInitialValue),
        this[strAttributeName][newIndex][strTypehead].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this[strAttributeName][newIndex][strLoader] = true),
          switchMap(term =>
            this.recordService.getRecordRelate(strModule, term, false, false, objMainFilter).pipe(
              shareReplay(),
              tap(() => {
                this[strAttributeName][newIndex][strLoader] = false;
              })
            )
          )
        )
      );
    } else {
      // Set a default dropdown value for this line item.
      this[strAttributeName][newIndex][strObservable] = concat(
        of(arRelateInitialValue),
        this[strAttributeName][newIndex][strTypehead].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this[strAttributeName][newIndex][strLoader] = true),
          switchMap(term => this.recordService.getRecordRelate(strModule, term, '', false, objMainFilter).pipe(
            shareReplay(),
            tap(() => {
              this[strAttributeName][newIndex][strLoader] = false;
            })
          ))
        )
      )
    }
  }

  /**
   * Close the current dialog.
   */
  cancelDialog() {
    if(this.checkFormGroupDirty()) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close({message: 'cancel'});
        });
    } else {
      this.dialogRef.close({message: 'cancel'});
    }
  }

  /**
   * Compile the data form including the relate values that we need to save.
   *
   * @return {object}
   */
  getRecordToSave(isPreview: boolean = false, isFromAutoSave: boolean = false): object {
    // Get the raw value of the form.
    this.hasError = false;

    if (! isPreview) {
      this.bSubmitted = true;
      this.isTouched = true;
    }

    let objSupplierInvoice = [];
    this.arReadOnlyId = [];
    this.arRelateEmptyId = [];
    this.arRequiredField = [];
    let strRecordModuleId = RelateIds[this.strRecordModule];
    this.supplierInvoiceForm.map(
      form => {
        Object.keys(form['groups'].value).forEach(
          item => {
            let strFieldMetadata = form['fields'].find( x => x.key == item);
            if (strFieldMetadata != undefined) {
              if (strFieldMetadata.readonly) this.arReadOnlyId.push(item);
              if (strFieldMetadata.controlType == 'relate') this.arRelateEmptyId.push(item);
              if (strFieldMetadata.required) this.arRequiredField.push(item);
            }
        })
    });

    //Loops the values.
    this.supplierInvoiceForm.forEach(
      item => {
        //Merge all form group values into a single object.
        objSupplierInvoice = {...objSupplierInvoice, ...item['groups'].getRawValue()};
    });

    objSupplierInvoice['tax_adjustment_amount'] = this.totalTaxAdjustment;

    // Remove readonly fields
    this.arReadOnlyId.forEach( strId => {
      if (strId != 'accounting_sync_detail') {
        delete objSupplierInvoice[strId];
      }
    });

    let lineItems = [];
    this.arItemsToUpsert = [];

    if (! isPreview) {
      this.validateRequest(objSupplierInvoice, false);
    }

    // Validate each line itmes of time entry
    this.arLineAttributes.forEach( data => {
      data.supplier_invoice.total_price = data.amount;
      data.supplier_invoice.quantity = data.supplier_invoice.quantity;
      if (data['item'] && data['item'].text) {
        data.supplier_invoice.item = data['item'].text;
        data.supplier_invoice.item_name = data['item'].text;
      }

      this.validateRequest(data.supplier_invoice);
      lineItems.push(data.supplier_invoice);

      this._updateItemUnitCostAndSupplierPayload(data);
    });

    if (this.strRecordModule === 'customers' && isEmpty(this.strRecordId) && !isEmpty(objSupplierInvoice['customer_id'])) {
      this.strRecordId = objSupplierInvoice['customer_id'];
    }

    if (this.strRecordModule === 'purchase_orders' && !isEmpty(objSupplierInvoice['purchase_order_id'])) {
      this.strRecordId = objSupplierInvoice['purchase_order_id'];
    }

    if (this.strRecordModule != undefined && this.strRecordModule != '') {
      if(this.strRecordId != null && this.strRecordId != undefined && this.strRecordId != '') {
        objSupplierInvoice[strRecordModuleId] = this.strRecordId;
      } else {
        objSupplierInvoice[strRecordModuleId] = null;
      }
    }

    if (lineItems.length > 0) {
      objSupplierInvoice['line_items'] = lineItems;
    } else {
      if (!isPreview) {
        this.bSubmitted = false;
        this.pdfPreview = false;
        this.hasError = true;
        if (!isFromAutoSave) {
          this.notifService.sendNotification('not_allowed', 'invoice_line_items_required', 'warning');
        }
      }
    }

    if (objSupplierInvoice['contact_id'] == '') {
      objSupplierInvoice['contact_id'] = null;
    }

    if (objSupplierInvoice['department_id'] == '') {
      objSupplierInvoice['department_id'] = null;
    }

    this.arRelateEmptyId.forEach( strField => {
      if (objSupplierInvoice[strField] == '') {
        objSupplierInvoice[strField] = null;
      }
    });

    return objSupplierInvoice;
  }

  /**
   * Save the supplier invoice
   *
   * @returns {void}
   */
  saveSupplierInvoice(): void {

    let objSupplierInvoice = this.getRecordToSave();

    const lines = get(objSupplierInvoice, 'line_items', []);

    if (isArray(lines)) {
      lines.forEach(lineItem => {
        if (isEmpty(get(lineItem, 'description'))) {
          this.hasError = true;
          return;
        }
      });
    }

    if (!this.hasError && objSupplierInvoice) {
      if (this.strViewType == 'edit' && this.data['supplier_invoice'] != undefined) {
        // Save customer invoice
        this.beforeSaveSupplierInvoice(objSupplierInvoice, 'edit', this.data['supplier_invoice']['id']);
      } else {
        // Status default to created
        objSupplierInvoice['status'] = 'created';
        this.beforeSaveSupplierInvoice(objSupplierInvoice, 'add', '');
      }
    } else {
      this.notifService.sendNotification('not_allowed', 'required_notice', 'danger');
      this.bSubmitted = false;
      this.isPreviewTemplate = false;
      this.whenInProgress$.next(false);
    }
  }
  beforeSaveSupplierInvoice(objSupplierInvoice, strViewType, requestRecordId) {

    this.hasChangesInLineItem(objSupplierInvoice);

    this.whenInProgress$.next(true);

    // Save supplier invoice
    this.saveSupplierInvoiceRecordData(objSupplierInvoice, requestRecordId).pipe(
      finalize(() => this.whenInProgress$.next(false))
    ).subscribe(res => {

      if (strViewType == 'add') {
        if (res.status === 202) {
          if (res.body.error !== undefined) {
            this.notifService.promptError(res.body.error);
          } else {
            this.notifService.notifyWarning('invalid_next_supplier_number');
          }
        } else if (res.body.id != undefined) {
          this._upsertProduct(objSupplierInvoice['customer_id']);
          this.formService.removeUnsavedChangesDataToLocalStorage(get(this.data, 'supplier_invoice_id', ''), 'supplier_invoices', this.strRecordId);
          this.displayTemplate(res.body.id);
          if (this.hasUpdateOnUnitCost) {
            this.notifService.notifySuccess('materials_linked_on_line_item_update')
          }
          this.updateStatusWidget();
          this.dialogRef.close({id: res.body.id, message: 'save'});
        } else {
          this.dialogRef.close({message: 'fail'});
        }
      } else {
        if (res.status == 200) {
          this._upsertProduct(objSupplierInvoice['customer_id']);
          this.formService.removeUnsavedChangesDataToLocalStorage(get(this.data, 'supplier_invoice_id', ''), 'supplier_invoices', this.strRecordId);
          this.displayTemplate(this.data['supplier_invoice_id']);
          if (this.hasUpdateOnUnitCost) {
            this.notifService.notifySuccess('materials_linked_on_line_item_update')
          }
          this.updateStatusWidget();
          this.dialogRef.close({id: this.data['supplier_invoice_id'], message: 'save'});
        } else if (res.status === 202 && res.body.error !== undefined) {
          this.notifService.promptError(res.body.error);
        } else {
            this.dialogRef.close({message: 'fail'});
        }
      }
    });
  }

  /**
   * Compare the old data to the requested data
   * @param objSupplierInvoice - Requested data
   * @param relateData = Related Data
   */
  compareOldData(objSupplierInvoice) {
    let objSupplierInvoiceFields = Object.keys(objSupplierInvoice);
    objSupplierInvoiceFields.forEach( strField => {
      if (strField != 'line_items') {
        let strOriginalDate = '';

        if (strField == 'invoice_date' || strField == 'invoice_due' || strField == 'po_date') {
          strOriginalDate = objSupplierInvoice[strField];
          this.data.supplier_invoice[strField] = this.formatDate(this.data.supplier_invoice[strField]);
          objSupplierInvoice[strField] = this.formatDate(objSupplierInvoice[strField]);
        }
        // Due to in objSupplierInvoice relate id set as null if its empty. We set the old data also as null
        if (this.arRelateEmptyId.indexOf(strField) > -1) {
          if (this.data.supplier_invoice[strField] == '') {
            this.data.supplier_invoice[strField] = null;
          }
        }

        if (strField == 'job_id' && this.data.supplier_invoice[strField] == '') {
          this.data.supplier_invoice[strField] = null;
        }

        if (this.data.supplier_invoice[strField] != objSupplierInvoice[strField]) {
          this.bHasChanged = true;
        }

        if (strField == 'invoice_date' || strField == 'invoice_due' || strField == 'po_date') {
          objSupplierInvoice[strField] = strOriginalDate;
        }
      } else {
        objSupplierInvoice[strField].forEach( (data, index) => {
          let objLineItemsField = Object.keys(data);
          objLineItemsField.forEach( strLineItemField => {
            // If data purchase order with index given is undefined. Set has changed to true.
            if (this.data.supplier_invoice[strField][index] == undefined) {
              this.bHasChanged = true;
            } else {
              if (this.data.supplier_invoice[strField][index][strLineItemField] != data[strLineItemField]) {
                this.bHasChanged = true;
              }
            }
          });
        });
      }
    });
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate) {

    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('ll');
  }

  /**
   * Get the default tax or account code from the local storage setting.
   * @param strKey tax | account
   */
  getDefaultCodes(strKey) {
    let objCOnfig = this.clientStoreService.getActiveClient();

    let strResult: string | boolean = false;

    if (objCOnfig['default_' + strKey + '_code_purchase'] != null) {
      strResult = objCOnfig['default_' + strKey + '_code_purchase'];
    }

    return strResult;
  }

  /**
   * Forms the ng-select object if the default tax/account code exists.
   * Also retrieves the name from the list of tax/account lists.
   * @param strKey tax | account
   * @param anyDefaultValue
   */
  getDefaultObject(strKey, anyDefaultValue = {}) {

    let objResult: any = anyDefaultValue;
    let strId = this.getDefaultCodes(strKey);
    let arRelate = [];

    if (strKey == 'tax') {
      arRelate = this.initialRelateTaxCodeData;
    }

    if (strKey == 'account') {
      arRelate = this.initialRelateAccountCodeData;
    }

    if (strId) {
      let numIndex = arRelate.findIndex(element => (element['id'] == strId));
      if (numIndex > -1) {
        objResult = {
          id: strId,
          name: arRelate[numIndex]['name']
        }
      }
    }

    return objResult;
  }

  /**
   * set amount due with computation of included tax
   */
  setAmountDue() {
    let indexOfAmountDue = this.getFormFieldIndex('amount_due');
    let indexOfAmountPaid = this.getFormFieldIndex('amount_paid');
    let strAmountPaid: number = 0;

    if (indexOfAmountPaid > -1) {
      let strAmountPaidValue = this.supplierInvoiceForm[indexOfAmountDue]['groups'].controls.amount_paid.value;
      strAmountPaid = this.is.toFloat(strAmountPaidValue);
    }

    if (indexOfAmountDue > -1) {
      this.supplierInvoiceForm[indexOfAmountDue]['groups'].patchValue({
        amount_due: this.computeInvoiceLineIncludedTax() - strAmountPaid
      });
    }
  }

  /**
   * Get and store the relate text on change in other component
   *
   * @param {object} objField
   * @param {string} strValue
   *
   * @returns {void}
   */
  getRelateText(objField, strValue): void {
    if (objField.controlType == 'relate') {
      this.objRelateText[objField.key.replace('_id', '_text')] = strValue;
    }
  }

  /**
   * This is to increase the row
   * when textarea is focus
   */
  increaseRow(i) {
    let currentElem = document.querySelector('.invoice-desc-'+ i);
    currentElem.setAttribute("style", "height: 100px");
  }

  /**
   * This is to decrease the row
   * when textarea is focus out
   */
  decreaseRow(i) {
    let currentElem = document.querySelector('.invoice-desc-'+ i);
    currentElem.setAttribute("style", "height: 40px");
  }

  /**
   * Check if the form group is changed
   *
   * @returns {boolean}
   */
  checkFormGroupDirty(): boolean {
    let bDirty = false;
    this.supplierInvoiceForm.forEach( objForm => {
      var formGroup = objForm.groups;
      if (formGroup.dirty === true) {
        bDirty = true;
      }
    });
    return bDirty || this.bFormDirty;
  }

  /**
   * Mark as dirty
   *
   * @returns {void}
   */
  markAsDirty(): void {
    this.bFormDirty = true;
  }

  /**
   * This will auto populate the related field
   * of purchase order to supplier invoice
   * @param purchaseOrderDetail
   */
  populateRelatedFieldFromPurchaseOrder(purchaseOrderDetail)
  {
    if (purchaseOrderDetail !== null) {
      let indexContactForm = this.getFormFieldIndex('contact_id');
      let indexContactField = this.getFieldIndex('contact_id', this.supplierInvoiceForm[indexContactForm]);
      let indexCustomerForm = this.getFormFieldIndex('customer_id');
      let indexCustomerField = this.getFieldIndex('customer_id', this.supplierInvoiceForm[indexCustomerForm]);

      // FC-4255: fix issue in supplier invoice form where changing purchase order value does not update the supplier value
      if (indexCustomerForm > -1 && indexCustomerField > -1) {
        const customerField = _cloneDeep(this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField])
        customerField['options'] = [new Select(purchaseOrderDetail['customer_id'], purchaseOrderDetail['customer_text'])];
        // if customer field is readonly, we temporarily set it to false to update the supplier value
        if (this.bIsCustomerFieldReadOnly) {
          customerField['readonly'] = false;
        }

        this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField] = customerField;
      }

      this.fieldPatchValue('contact_id', purchaseOrderDetail['contact_id']);
      this.fieldPatchValue('customer_id', purchaseOrderDetail['customer_id']);
      this.fieldPatchValue('amount_paid', purchaseOrderDetail['amount_paid']);

      if (this.strRecordModule !== 'jobs') {
        let indexJobForm = this.getFormFieldIndex('job_id');
        let indexJobField = this.getFieldIndex('job_id', this.supplierInvoiceForm[indexJobForm]);

        if (indexJobForm > -1 && indexJobField > -1) {
          const jobField = _cloneDeep(this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField])
          jobField['options'] = [new Select(purchaseOrderDetail['job_id'], purchaseOrderDetail['job_text'])];
          this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField] = jobField;

          this.fieldPatchValue('job_id', purchaseOrderDetail['job_id']);
          this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField]['default_value'] = purchaseOrderDetail['job_id'];
          this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField]['default_text'] = purchaseOrderDetail['job_text'];
          this.relateChanges['job_id'] = get(purchaseOrderDetail, ['job_text']);
        }
      }

      if (indexContactForm > -1 && indexContactField > -1) {
        this.supplierInvoiceForm[indexContactForm]['fields'][indexContactField]['default_value'] = purchaseOrderDetail['contact_id'];
        this.supplierInvoiceForm[indexContactForm]['fields'][indexContactField]['default_text'] = purchaseOrderDetail['contact_text'];
        this.relateChanges['contact_id'] = get(purchaseOrderDetail, ['contact_text']);
      }

      if (indexCustomerForm > -1 && indexCustomerField > -1) {
        this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['default_value'] = purchaseOrderDetail['customer_id'];
        this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['default_text'] = purchaseOrderDetail['customer_text'];
        this.relateChanges['customer_id'] = get(purchaseOrderDetail, ['customer_text']);

        if (this.bIsCustomerFieldReadOnly) {
          this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['readonly'] = true;
        }
      }
    }
  }

  /**
   * Set line items from purchase order
   *
   * @param strPurchaserOrderId
   */
  setLineItemFromPurchaseOrder(strPurchaserOrderId) {
    this.recordService.getRelatedModuleRecord('purchase_orders', strPurchaserOrderId, {
      has_related_data: true
    }).subscribe( result => {
      // Set data
      let purchaseOrderDetail = this.arrService.keyFallsBackTo(result, 'record_details');
      // We need to set this type to any. So it can use this in foreach
      // Note: It has condition for this variable so it sure that the value is an array.
      let lineItems: Record<string, any>[] = this.arrService.keyFallsBackTo(purchaseOrderDetail, 'line_items');

      if (lineItems.length > 0) {
        /// filter line items that where already invoiced
        lineItems = lineItems.filter((line) => {
          const invoiceQuantity = toFormattedNumber(get(line, 'invoiced_quantity', 0));
          const expectedQuantity = toFormattedNumber(get(line, 'quantity', 0));

          return expectedQuantity > invoiceQuantity;
        });
      }

      this.objPurchaseOrderOnchange = (purchaseOrderDetail !== null) ? purchaseOrderDetail : {};
      // This is to auto populate the related field from purchase order to supplier invoice.
      this.populateRelatedFieldFromPurchaseOrder(purchaseOrderDetail);

      this.arLineAttributes = [];
      if (filled(lineItems)) {
        /// remove focus
        this.focusedLineIndex = -1;

        lineItems.forEach( data => {
          // copy the id from purchase_order_line_item data to purchase_order_line_item_id key
          // to identify that it is a relate field
          data.purchase_order_line_item_id = data.id;
          this.addLineAttribute(data)
        });
        this.setAmountDue();
      } else {
        this.bInvoiceLineLoaded = true;
        this.setAmountDue();
      }

      this.setDropdownFieldDefaultValue('purchase_order_id', new Select(purchaseOrderDetail['id'], purchaseOrderDetail['text']));
    });
  }

  /**
   * triggers the save and preview
   */
  onSubmitAndPreview(): void {
    this.isPreviewTemplate = true;
    if (!this.bSubmitted) {
        this.saveSupplierInvoice();
    }
  }

  /**
   * trigger event to disaplay template
   *
   * @param id
   */
  displayTemplate(id: string): void {
    if (this.isPreviewTemplate && id) {
      this.previewTemplate.next({
        id: id,
        module: 'supplier_invoices',
        document_type: 'supplier_invoice_report'
      });
    }
    this.isPreviewTemplate = false;
  }

  onLineTotalChange(input: HTMLInputElement, opts: {
    attr: Record<string, any>,
    index: number,
  }): void {
    if (this.arLineAttributes.length < opts.index) {
      return;
    }

    const adjustment = toFormattedNumber(input.value, {
      currency: true,
    });

    const quantity = toFormattedNumber(get(opts.attr, 'supplier_invoice.quantity'));
    const cost = adjustment / quantity;

    this.arLineAttributes[opts.index]['supplier_invoice']['unit_cost'] = toFormattedNumber(cost, {
      currency: true,
      maxDecimalPlaces: 4,
    });

    this.setAmountDue();
    this.markAsDirty();
  }

  onTaxAdjustment(): void {
    this.setAmountDue();
    this.markAsDirty();
  }

  /**
   * for viewing the current record
   *
   * @returns void
   */
  onPreview(): void {
    let objSupplierInvoice = this.getRecordToSave(true);
    let arModule = [];
    let objFilter = {};
    let arRelateFields = [
      { id: 'customer_id', module: 'customers', key: 'supplier' },
      { id: 'contact_id', module: 'sites', key: 'contact' },
      { id: 'user_id', module: 'users', key: 'assigned_user' },
      { id: 'purchase_order_id', module: 'purchase_orders', key: 'purchase_order' },
      { id: 'job_id', module: 'jobs', key: 'job'},
    ];

    arRelateFields.forEach( relateField => {
      if (objSupplierInvoice[relateField.id]) {
        arModule.push(relateField.module);
        objFilter[relateField.module] = {
          id: objSupplierInvoice[relateField.id]
        };
      }
    });

    this.whenInProgress$.next(true);

    this.recordService.getMultipleModuleRelateRecord(arModule.join('|'), false, objFilter).subscribe( response => {
      arRelateFields.forEach( relateField => {
        if (response[relateField.module] && response[relateField.module][0]) {
          objSupplierInvoice[relateField.key] = response[relateField.module][0];
          if (objSupplierInvoice[relateField.key]['address']) {
            objSupplierInvoice[relateField.key]['address'] =
              this.readableAddressPipe.transform(objSupplierInvoice[relateField.key]['address'])
          }
        } else {
          objSupplierInvoice[relateField.key] = {}
        }
      });

      objSupplierInvoice = this.setDropdownValues(objSupplierInvoice);
      objSupplierInvoice['amount'] = this.computeInvoiceLineExcludedTax();
      objSupplierInvoice['tax'] = this.computeInvoiceLineTax();
      objSupplierInvoice['amount_inc_tax'] = this.totalTaxAdjustment;

      this.realTimePreviewTemplate.next({
        id: this.data['supplier_invoice_id'],
        module: 'supplier_invoices',
        document_type: 'supplier_invoice_report',
        data: objSupplierInvoice
      });

      this.bSubmitted = false;
      this.isPreviewTemplate = false;
    });
  }

  /*
   * NOTE: This is a method from Angular CDK itself and is not a custom one.
   *
   * Triggered when an item is being dropped.
   * This is used by the quote line editor.
   * @param event - the item being dragged.
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arLineAttributes, event.previousIndex, event.currentIndex);
  }

  setDropdownValues(purchaseOrder): LooseObject {
    let arDropdownKeys = Object.keys(this.objModuleConfig['used_fields']).filter(
      strKey => this.objModuleConfig['used_fields'][strKey].type === 'dropdown'
    );
    arDropdownKeys.forEach( dropdownKeys => {
      if (purchaseOrder[dropdownKeys]) {
        let dropdownValue = cloneDeep(purchaseOrder[dropdownKeys]);

        purchaseOrder[dropdownKeys+ '_raw'] = dropdownValue;
        purchaseOrder[dropdownKeys] = this.translateService.instant(dropdownValue)
      }
    });

    return purchaseOrder;
  }

  triggerAutoSave() {
    if (blank(this.autoSaveIntervalId)) {
      this.autoSaveIntervalId = setInterval(() => {
        if (this.checkFormGroupDirty()) {
          this.saveSupplierInvoiceViaAutoSave();
        }
      }, 5000);
    }
  }

  saveSupplierInvoiceViaAutoSave() {
    this.arReadOnlyId = [];
    this.arRelateEmptyId = [];
    this.arRequiredField = [];
    let objSupplierInvoice = this.getRecordToSave(false, true);
    this.bSubmitted = false;

    if (!isEmpty(objSupplierInvoice)) {
      objSupplierInvoice['id'] = this.data['supplier_invoice_id'];
      let unsavedChangesData: SetUnsavedChangesData = {
        record_id: get(this.data, 'supplier_invoice_id', ''),
        module: 'supplier_invoices',
        data_to_save: objSupplierInvoice,
        parent_record_id: this.strRecordId,
        related_fields: this.relateFields,
        related_changes: this.relateChanges,
      };

      this.formService.setUnsavedChangesDataToLocalStorage(unsavedChangesData);
    }
  }

  doSomethingInParent(event) {
    let numFormIndex = this.getFormFieldIndex(event['field']);
    let numFieldIndex = this.getFieldIndex(event['field'], this.supplierInvoiceForm[numFormIndex]);

    if (numFormIndex > -1 && numFieldIndex > -1 && this.supplierInvoiceForm[numFormIndex]['fields'][numFieldIndex]['controlType'] == 'relate') {
      this.relateChanges[event['field']] = get(event, 'value.text');
    }
  }

  onCreatePostInitializeComponent() {
    this.onChanges();
    // We need to set related purchase order to
    // supplier invoice when it from the convert PO
    let strPurchaseOrderId = this.arrService.keyFallsBackTo(this.data, 'purchase_order_id');
    if (this.data['from_convert_po'] !== undefined && this.data['from_convert_po'] === true && strPurchaseOrderId) {
      this.bInvoiceLineLoaded = false;
      let indexPoForm = this.getFormFieldIndex('purchase_order_id');
      let indexPoField = this.getFieldIndex('purchase_order_id', this.supplierInvoiceForm[indexPoForm]);

      if (indexPoForm > -1 && indexPoField > -1) {
        this.supplierInvoiceForm[indexPoForm]['fields'][indexPoField]['default_value'] = this.data['purchase_order_id'];
        this.supplierInvoiceForm[indexPoForm]['fields'][indexPoField]['default_text'] = this.data['purchase_order_text'];
      }

      this.fieldPatchValue('purchase_order_id', strPurchaseOrderId);
      this.setLineItemFromPurchaseOrder(strPurchaseOrderId);
    }

    const numSyncToAccountingIndex: number = this.getFormFieldIndex('sync_to_accounting');

    if (numSyncToAccountingIndex > -1 && !this.isFromUnsavedChanges) {
      this.supplierInvoiceForm[numSyncToAccountingIndex]['groups'].patchValue({
        // FC-4359: when creating supplier invoices, the default value of sync_to_accounting flag will follow the value of 'sync_supplier_invoice_on_creation'.
        sync_to_accounting: get(this.objClientRecord, 'sync_supplier_invoice_on_creation', true)
      });
    }
  }

  ngOnDestroy(): void {
    this.formService.removeAutoSaveInterval(this.autoSaveIntervalId);
    this.data['supplier_invoice'] = {};
  }

  doSomethingFromContextMenu(event) {
    let copiedLineItemFromLocalStorage = this.contextMenuService.getCopiedLineItem();

    if (event.action == 'paste' && filled(copiedLineItemFromLocalStorage)) {
      let indexCounter = get(event, ['data', 'index']) + 1;
      copiedLineItemFromLocalStorage.forEach(lineItem => {
        if (!lineItem['labor']) {
          lineItem['id'] = lineItem['item_id'];
          this.addLineAttribute(lineItem, false, true, indexCounter);
          indexCounter++;
        }
      });
      this.setAmountDue();
    } else if (event.action == 'copy') {
      let copiedLineItems = [];
      let eventDataLineItem = get(event, ['data', 'line_item'], []);

      if (this.selectedLineItems.length > 0) {
        copiedLineItems = this.selectedLineItems.map(lineItem => {
          let newLineItem = this.formatCopiedLineItem(lineItem);

          return new SupplierInvoiceLine(newLineItem);
        });
      } else if (filled(eventDataLineItem)) {
        let newLineItem = this.formatCopiedLineItem(eventDataLineItem);

        copiedLineItems = [new SupplierInvoiceLine(newLineItem)];
      }

      if (filled(copiedLineItems)) {
        this.contextMenuService.setCopiedLineItem(copiedLineItems);
      }
    }
  }

  formatCopiedLineItem(lineItem: LooseObject) {
    let currentLineItem = cloneDeep(lineItem);
    currentLineItem['tax_code_name'] = get(currentLineItem, ['tax', 'text']);
    currentLineItem['account_code_name'] = get(currentLineItem, ['supplier_invoice', 'account_code_name']);
    currentLineItem['account_code'] = get(currentLineItem, ['supplier_invoice', 'account_code']);
    currentLineItem['item_name'] = get(currentLineItem, ['item', 'text']);
    currentLineItem['tax_rate'] = parseFloat(currentLineItem['tax_rate']) > 0 ? parseFloat(currentLineItem['tax_rate']) * 100 : 0;

   return {...get(currentLineItem, ['supplier_invoice'], []), ...currentLineItem};
  }

  onClickedLineItem($event, attr) {
    this.selectedLineItems = this.contextMenuService.selectLineItem($event, attr, this.selectedLineItems);
  }

  /**
   * set dropdown default value
   *
   * @param fieldKey
   * @param option
   */
  setDropdownFieldDefaultValue(fieldKey: string, option: Select): void {
    let indexPoForm = this.getFormFieldIndex(fieldKey);
    let indexPoField = this.getFieldIndex(fieldKey, this.supplierInvoiceForm[indexPoForm]);

    if (indexPoForm > -1 && indexPoField > -1) {
      this.supplierInvoiceForm[indexPoForm]['fields'][indexPoField]['default_value'] = option.id;
      this.supplierInvoiceForm[indexPoForm]['fields'][indexPoField]['default_text'] = option.text;
    }

    this.fieldPatchValue(fieldKey, option.id);
  }

  /**
   * Save supplier invoice record data
   *
   * @param {LooseObject} objData
   * @param {strRecordId} strRecordId
   *
   * @see {RecordService['saveRecord']}
   */
  protected saveSupplierInvoiceRecordData(objData: LooseObject, strRecordId: string = '') {
    // FC-4265: If we are updating the SI record, and we changed Purchase Order record id, we must update the
    // 'remaining_amount' of the previous purchase order data, otherwise, if PO 'remaining_amount' field remains 0,
    // it will not be visible in the options of the relate field of a purchase order in a form.
    // Refer to server/app/Models/PurchaseOrder.php getRecordsAdditionalFilter method.
    if (
      this.strViewType === 'edit' &&
      (this.objOriginalPurchaseOrderData.id !== objData.purchase_order_id)
    ) {
      objData['previous_po_to_update'] = this.objOriginalPurchaseOrderData;
    }
    objData['update_materials_unit_cost'] = this.upsertMaterial;

    if (this.isRelatedToPO) {
      objData['update_purchase_order_to_invoiced'] = this.markAsInvoiced;
    }

    return this.recordService.saveRecord('supplier_invoices', objData, strRecordId).pipe(
      finalize(() => {
        this.bSubmitted = false;
        this.isPreviewTemplate = false;
      })
    );
  }

  /**
   * Set the due date based on payment term type
   * @param strSupplierPaymentTermType
   * @param strSupplierPaymentTermDays
   * @param strInvoiceDueDate
   */
  dueDateComputeByPaymentTerms(strSupplierPaymentTermType, strSupplierPaymentTermDays, strInvoiceDateValue, indexInvoiceDue){
    switch (strSupplierPaymentTermType){
      case 'past_invoice_date':
        this.supplierInvoiceForm[indexInvoiceDue].groups.patchValue({'invoice_due': moment(strInvoiceDateValue).add(strSupplierPaymentTermDays,'days').format('YYYY-MM-DD')});
      break;
      case 'past_end_of_the_month':
        this.supplierInvoiceForm[indexInvoiceDue].groups.patchValue({'invoice_due': moment(strInvoiceDateValue).endOf('month').add(strSupplierPaymentTermDays,'days').format('YYYY-MM-DD')});
      break;
      case 'cash_on_delivery':
        this.supplierInvoiceForm[indexInvoiceDue].groups.patchValue({'invoice_due': moment(strInvoiceDateValue).format('YYYY-MM-DD')});
    }
  }

  /**
   * Get and store the relate object on change in other component
   *
   * @param {object} objField
   * @param {object} objValue
   *
   * @returns {void}
   */
  getRelateObject(objField, objValue): void {
    let defaultSupplierPaymentTerm = '';
    let strSupplierPaymentTermType = '';
    let strSupplierPaymentTermDays = '';
    let indexInvoiceDue = this.getFormFieldIndex('invoice_due');
    let indexOfPurchasingNotes = this.getFormFieldIndex('purchasing_notes');
    let objCurrentValue = objValue;

    if(filled(objValue.extras)){
      objCurrentValue = objValue.extras;
    }

    // if value of invoice date changes, adjust the invoice_due based on payment terms
    if (objField.key === 'invoice_date' ){
      if (indexInvoiceDue > -1) {
        const strInvoiceDateValue = this.supplierInvoiceForm[indexInvoiceDue].groups.get('invoice_date').value;

        // Check if Current Supplier has no existing payment terms configuration. use default, else, use customer's selected term
        if(blank(this.strSupplierPaymentTermType)){
          defaultSupplierPaymentTerm = get(this.objModuleConfig, 'related_data.default_supplier_payment_term', null);

          if (!blank(defaultSupplierPaymentTerm)) {
            strSupplierPaymentTermType = defaultSupplierPaymentTerm['term_type'] || '';
            strSupplierPaymentTermDays = defaultSupplierPaymentTerm['term_days'] || '';
            this.dueDateComputeByPaymentTerms(strSupplierPaymentTermType, strSupplierPaymentTermDays, strInvoiceDateValue, indexInvoiceDue);
          }

        }
        else {
          this.dueDateComputeByPaymentTerms(this.strSupplierPaymentTermType, this.strSupplierPaymentTermDays, strInvoiceDateValue, indexInvoiceDue);
        }
      }
    }

    // if the value of supplier name changes, adjust the invoice due based on payment terms selected for the supplier
    if (objField.key === 'customer_id' || objField.key == 'purchase_order_id'){
      this.strPurchasingNotes = get(objCurrentValue, 'purchasing_notes', null);

      // Get the payment terms of the Supplier selected
      this.strSupplierPaymentTermType = get(objCurrentValue, 'supplier_payment_term_type', null);
      this.strSupplierPaymentTermDays = get(objCurrentValue, 'supplier_payment_term_days', null);

      if(blank(this.strSupplierPaymentTermType)){
        defaultSupplierPaymentTerm = get(this.objModuleConfig, 'related_data.default_supplier_payment_term', null);

          if (!blank(defaultSupplierPaymentTerm)) {
            strSupplierPaymentTermType = defaultSupplierPaymentTerm['term_type'] || '';
            strSupplierPaymentTermDays = defaultSupplierPaymentTerm['term_days'] || '';
            const strInvoiceDateValue = this.supplierInvoiceForm[indexInvoiceDue].groups.get('invoice_date').value;
            this.dueDateComputeByPaymentTerms(strSupplierPaymentTermType, strSupplierPaymentTermDays, strInvoiceDateValue, indexInvoiceDue);
          }
      }
      else {
        const strInvoiceDateValue = this.supplierInvoiceForm[indexInvoiceDue].groups.get('invoice_date').value;
        this.dueDateComputeByPaymentTerms(this.strSupplierPaymentTermType, this.strSupplierPaymentTermDays, strInvoiceDateValue, indexInvoiceDue);
      }
    }
  }

  /**
   * Set the due date based on payment term type
   * @param strSupplierPaymentTermType
   * @param strSupplierPaymentTermDays
   *
   */
  setDefaultInvoiceDue(strSupplierPaymentTermType, strSupplierPaymentTermDays){
    let indexInvoiceDue = this.getFormFieldIndex('invoice_due');
    let indexInvoiceField = this.getFieldIndex('invoice_due', this.supplierInvoiceForm[indexInvoiceDue]);

    switch (strSupplierPaymentTermType){
      case 'past_invoice_date':
        this.supplierInvoiceForm[indexInvoiceDue]['fields'][indexInvoiceField]['default_value'] = moment().add(strSupplierPaymentTermDays, 'days').format('YYYY-MM-DD');
      break;
      case 'past_end_of_the_month':
        this.supplierInvoiceForm[indexInvoiceDue]['fields'][indexInvoiceField]['default_value'] = moment().endOf('month').add(strSupplierPaymentTermDays, 'days').format('YYYY-MM-DD');
      break;
      case 'cash_on_delivery':
        this.supplierInvoiceForm[indexInvoiceDue].groups.patchValue({'invoice_due': moment()});
    }
  }

  /**
   * Open suuplier inventory dialog.
   *
   * @param recordData
   */
  openSupplierInventoryDialog(itemId: string) {
    this.dialog.open(ViewSupplierInventoryComponent, {
      width: '80%',
      height: '98%',
      data: {
        record: {id: itemId},
      }
    });
  }

  /**
   * to determine if we have changes on line item unit_cost that is linked to a materials
   * @param objRecordToSave
   */
  hasChangesInLineItem(objRecordToSave: LooseObject): void {
    let lineItemToSave = get(objRecordToSave, 'line_items', []);
    let originalLineItem = this.originalLineItem.map(data => data.supplier_invoice);
    lineItemToSave.forEach( lineItem => {

      let poLineItemId = get(lineItem, 'purchase_order_line_item_id', null);
      if (filled(poLineItemId)) {
        // find the matching line item based on purchase_order_line_item_id
        let data = originalLineItem.find(currentLineItem =>
          currentLineItem.purchase_order_line_item_id == poLineItemId
        );
        if (filled(data) && data.unit_cost != lineItem.unit_cost) {
          this.hasUpdateOnUnitCost = true;
        }
      }
    });
  }

  onChangeCheckbox(event, strType: string, objLineItem: LooseObject, bAll: boolean = true) {
    if (bAll) {
      this.arLineAttributes.map( item => {
        if (filled(item.supplier_invoice.item_id)) {
          item[strType] = event.currentTarget['checked'];
        }
      });
      if (strType == 'update_supplier_pricing') {

        this.bUpdateAllUnitCost = event.currentTarget['checked'];
      }
      if (strType == 'update_product_supplier') {

        this.bUpdateAllSupplier = event.currentTarget['checked'];
      }
      if (strType == 'update_product_buy_price') {

        this.bUpdateAllProductUnitCost = event.currentTarget['checked'];
      }
    } else {

      objLineItem[strType] = event.currentTarget['checked'];
      if (event.currentTarget['checked'] == false) {
        if (strType == 'update_supplier_pricing') {

          this.bUpdateAllUnitCost = event.currentTarget['checked'];
        }
        if (strType == 'update_product_supplier') {

          this.bUpdateAllSupplier = event.currentTarget['checked'];
        }
        if (strType == 'update_product_buy_price') {

          this.bUpdateAllProductUnitCost = event.currentTarget['checked'];
        }
      }
    }
  }

  onChangeSupplierCheckbox(event, objLineItem: LooseObject, bAll: boolean = true) {
    if (event.currentTarget['checked']) {
      if (bAll) {
        this.arLineAttributes.map( item => {
          if (filled(item.supplier_invoice.item_id)) {
            item.update_product_supplier = event.currentTarget['checked'];
            item.update_product_buy_price = event.currentTarget['checked'];
          }
        });
        this.bUpdateAllSupplier = event.currentTarget['checked'];
        this.bUpdateAllProductUnitCost = event.currentTarget['checked'];
      } else {

        objLineItem.update_product_supplier = event.currentTarget['checked'];
        objLineItem.update_product_buy_price = event.currentTarget['checked'];
      }
    }
  }

  /**
   * To open create item dialog form
   */
  createItem(lineItem: LooseObject): void {
    let itemData = this._compileItemDefaultValue(lineItem);
    this.dialog.open(EditformComponent, new FormPopup('items', {}, itemData))
      .afterClosed()
      .subscribe((response) => {
        if (filled(response.data)) {
          lineItem.item = response.data;
          lineItem.supplier_invoice.item_id = response.data.id;
          lineItem.supplier_invoice.item_name = response.data.name;
          lineItem.supplier_invoice.item_code = response.data.code;
          lineItem.supplier_invoice.unit_cost = toFormattedNumber(response.data.unit_cost, {
            currency: true,
            maxDecimalPlaces: 4,
          });
          lineItem.supplier_invoice.preferred_supplier_text = response.data.preferred_supplier_text;
          lineItem.amount = toFormattedNumber(
            lineItem.supplier_invoice.unit_cost * lineItem.supplier_invoice.quantity, {currency: true}
          );
        }
      });
  }

  updateStatusWidget(): void {
    if (this.markAsInvoiced
      && filled(this.objPurchaseOrderData['id'])
      && this.objPurchaseOrderData['status'] != 'invoiced') {
      this.viewService.updateStatusWidgetValue('invoiced');
    }
  }

  onLineFocus(index: number): void {
    this.focusedLineIndex = index + 1;
  }

  /**
   * get tool tip for relate job line item
   * @param lineItem
   * @returns
   */
  getJobLineItemToolTip(lineItem: LooseObject): string {
    if (lineItem.job_id) {
      return sprintf('%s #%s', this.translateService.instant('selected_job_number'), lineItem.job_number);
    }
    return this.translateService.instant('select_job_to_relate_on_line_item');
  }

  /**
   * on changet job line item
   * @param event
   * @param lineItem
   */
  onChangeJobLineItem(event, lineItem: LooseObject) {
    lineItem.supplier_invoice.job_id = get(event, 'id');
    lineItem.supplier_invoice.job_number = get(event, 'job_number');
    lineItem.job_data = {
      id: get(event, 'id'),
      job_number: get(event, 'job_number'),
    };
    this.markAsDirty();
  }

  /**
   * create default value for item dialog create
   *
   * @param lineItem
   * @returns
   */
  protected _compileItemDefaultValue(lineItem: LooseObject): LooseObject {
    let supplierInvoice: LooseObject = {};
    this.supplierInvoiceForm.forEach( item => {
        supplierInvoice = {...supplierInvoice, ...item['groups'].getRawValue()};
    });

    let itemsDefaultValue: LooseObject = {
      name: get(lineItem, 'supplier_invoice.description'),
      code: get(lineItem, 'supplier_invoice.description'),
      pricing_method: 'default_markup',
      unit_cost: get(lineItem, 'supplier_invoice.unit_cost'),
    };

    if (filled(supplierInvoice.customer_id)) {
      itemsDefaultValue.preferred_supplier = supplierInvoice.customer_id;
      itemsDefaultValue.preferred_supplier_text = this.relateChanges.customer_id;
    }

    if (filled(lineItem.tax)) {
      itemsDefaultValue.default_purchase_tax_code_id = get(lineItem, 'tax.id');
      itemsDefaultValue.default_purchase_tax_code_text = get(lineItem, 'tax.text');
    }

    if (filled(lineItem.account_code)) {
      itemsDefaultValue.default_purchase_account_code_id = get(lineItem, 'account_code.id');
      itemsDefaultValue.default_purchase_account_code_text = get(lineItem, 'account_code.name');
    }

    return itemsDefaultValue
  }

  /**
   * upsert item's unit_cost or supplier pricing
   *
   * @param strCustomerId
   */
  protected _upsertProduct(strCustomerId: string): void {
    if (filled(this.arItemsToUpsert)) {
      this.itemsService
        .upsertProductAndSupplierPricing(strCustomerId, this.arItemsToUpsert)
        .subscribe( (response) => {
          this.notifService.notifySuccess('product_supplier_pricing_updated')
        });
    }
  }

  /**
   * create payload to upser item's unit_cost or supplier pricing
   *
   * @param objLineItem
   */
  protected _updateItemUnitCostAndSupplierPayload(objLineItem: LooseObject): void {
    if (objLineItem.item
      && objLineItem.update_supplier_pricing || objLineItem.update_product_supplier || objLineItem.update_product_buy_price
    ) {
      this.arItemsToUpsert.push(
        {
          item_id: objLineItem.item.id,
          unit_cost: objLineItem.supplier_invoice.unit_cost,
          update_supplier_pricing: objLineItem.update_supplier_pricing,
          update_product_supplier: objLineItem.update_product_supplier,
          update_product_buy_price: objLineItem.update_product_buy_price,
        }
      );
    }
  }

  /**
   * show or hide the custom fields
   * add delay to get the supplier invoice form value
   */
  protected _enableCustomFlag(): void {
    setInterval(() => {
      this._enableMarkPOAsInvoiced();
      this._enableUpsertMaterial();
    }, 1000);
  }

  /**
   * to determine if we need to show or hide the upsert material custom flag
   */
  protected _enableUpsertMaterial(): void {
    const formIndex = this.getFormFieldIndex('job_id');
    if (formIndex > -1) {
      this.isRelatedToJob = filled(this.supplierInvoiceForm[formIndex].groups.controls['job_id'].value);
    }
    if (this.isRelatedToJob == false) {
      // if we got here it means that we have no supplier invoice has no related job
      // we need to check if line item has related job
      let lineItemWithJobId = this.arLineAttributes.filter( lineItem => filled(get(lineItem, 'supplier_invoice.job_id')));
      if (filled(lineItemWithJobId)) {
        this.isRelatedToJob = true;
      }
    }
  }

  /**
   * to determine if we need to show or hide the mark purchase order as invoice flag
   */
  protected _enableMarkPOAsInvoiced(): void {
    let intPurchaseOrderIndex = this.getFormFieldIndex('purchase_order_id');
    if (intPurchaseOrderIndex > -1) {
      let objSupplierForm = this.supplierInvoiceForm[intPurchaseOrderIndex]['groups'];
      this.isRelatedToPO = filled(objSupplierForm.controls['purchase_order_id'].value);
    }
  }
}
