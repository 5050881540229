import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RecordService } from '../../../../services/record.service';
import { concat } from 'rxjs/observable/concat';
import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs/Observable';
import { Select } from '../../../../objects/select';
import { Subject } from 'rxjs';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators/distinctUntilChanged';
import { NotificationService } from '../../../../services/notification.service';
import { get, union, groupBy, isEmpty } from 'lodash-es';
import { CustomTranslateService } from '../../../../services/custom-translate.service';

@Component({
  selector: 'app-preferred-supplier-dialog',
  templateUrl: './preferred-supplier-dialog.component.html',
  styleUrls: ['./preferred-supplier-dialog.component.scss'],
  providers: [CustomTranslateService]
})
export class PreferredSupplierDialogComponent implements OnInit {

  public bDisabled = false;
  public objItemsWithSupplier = [];
  public objItemsWithoutSupplier = [];
  public arSelectedItemsWithSupplier = [];
  public arSelectedItemsWithoutSupplier = [];
  public objPreferredSupplierConfig: any = {
    obv: new Observable<Select[]>(),
    typehead: new Subject<string>(),
    loader: false,
    placeholder: 'customer',
    name: 'customer_id',
    module: 'customers',
    value: {},
    filter: {
      is_supplier: true
    }
  };
  public arTranslateables = ['multiple_po_created'];

  constructor(
    public recordService: RecordService,
    public dialog: MatDialogRef<PreferredSupplierDialogComponent>,
    public customTranslate: CustomTranslateService,
    public notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public objRecords: any) { }

  ngOnInit() {
    this.objItemsWithSupplier = this.objRecords['items_with_supplier'];
    this.objItemsWithoutSupplier = this.objRecords['items_without_supplier'];
  }

  /**
   * Triggers when 'select all' checkbox is checked
   *
   * @param event
   * @param hasSupplier
   *
   * @returns {void}
   */
  selectAllItems(event, hasSupplier = true) : void {

    let bChecked = (event.target.checked) ?  true : false;
    let intItemsLength = 0;

    if (hasSupplier) {
      this.arSelectedItemsWithSupplier = [];
      intItemsLength = Object.keys(this.objItemsWithSupplier).length;
    } else {
      this.arSelectedItemsWithoutSupplier = [];
      intItemsLength = Object.keys(this.objItemsWithoutSupplier).length;
    }

    for (let intCounter = 0; intCounter < intItemsLength; intCounter ++) {

      if (bChecked) {
        if (hasSupplier) {
          this.arSelectedItemsWithSupplier.push(this.objItemsWithSupplier[intCounter]);
          this.objItemsWithSupplier[intCounter]['checked'] = true;
        } else {
          this.arSelectedItemsWithoutSupplier.push(this.objItemsWithoutSupplier[intCounter]);
          this.objItemsWithoutSupplier[intCounter]['checked'] = true;
        }
      } else {
        if (hasSupplier) {
          this.objItemsWithSupplier[intCounter]['checked'] = false;
        } else {
          this.objItemsWithoutSupplier[intCounter]['checked'] = false;
        }
      }

    }

    // If unchecked, reset the selected items array
    if (! bChecked) {
      if (hasSupplier) {
        this.arSelectedItemsWithSupplier = [];
      } else {
        this.arSelectedItemsWithoutSupplier = [];
      }
    }

  }

  /**
   * Triggers when a checkbox is checked
   * Add or remove it to an array of selected items
   *
   * @param event
   * @param item
   * @param index
   * @param hasSupplier
   *
   * @returns {void}
   */
  selectItem(event, item, index, hasSupplier = true) : void {

    let bChecked = (event.target.checked) ?  true : false;

    if (bChecked) {
      if (hasSupplier) {
        if (! this.arSelectedItemsWithSupplier.includes(item)) {
          this.arSelectedItemsWithSupplier.push(item);
          this.objItemsWithSupplier[index]['checked'] = true;
        }
      } else {
        if (! this.arSelectedItemsWithoutSupplier.includes(item)) {
          this.arSelectedItemsWithoutSupplier.push(item);
          this.objItemsWithoutSupplier[index]['checked'] = true;
        }
      }
    } else {
      if (hasSupplier) {
        this.objItemsWithSupplier[index]['checked'] = false;
        let intItemWithSupplierToBeRemoved = this.arSelectedItemsWithSupplier.indexOf(item);

        if (intItemWithSupplierToBeRemoved > -1) {
          this.arSelectedItemsWithSupplier.splice(intItemWithSupplierToBeRemoved, 1);
        }
      } else {
        this.objItemsWithoutSupplier[index]['checked'] = false;
        let intItemWithoutSupplierToBeRemoved = this.arSelectedItemsWithoutSupplier.indexOf(item);

        if (intItemWithoutSupplierToBeRemoved > -1) {
          this.arSelectedItemsWithoutSupplier.splice(intItemWithoutSupplierToBeRemoved, 1);
        }
      }
    }

  }

  /**
  * Creates multiple purchase orders that are grouped by the preferred supplier
  *
  * @returns {void}
  */
  createPurchaseOrders() : void {

    this.bDisabled = true;

    // If there is atleas 1 selected items without supplier and
    // the supplier dropdown is empty, prompt an error message
    if (this.arSelectedItemsWithoutSupplier.length !== 0 && isEmpty(this.objPreferredSupplierConfig['value'])) {

      this.notificationService.sendNotification('cannot_create_po', 'no_preferred_supplier_error', 'warning');
      this.bDisabled = false;

    // If there's no selected item at all
    } else if (this.arSelectedItemsWithoutSupplier.length === 0 && this.arSelectedItemsWithSupplier.length === 0) {

      this.notificationService.sendNotification('cannot_create_po', 'no_items_selected', 'warning');
      this.bDisabled = false;

    } else {

      // Grouped selected items by preferred supplier
      let objGroupedItemsWithSupplier = groupBy(this.arSelectedItemsWithSupplier, selected_item => selected_item.item_record.preferred_supplier);
      let objCombinedGroupedItems = {};
      let strPreferredSupplierId = this.objPreferredSupplierConfig['value']['id'];

      if (objGroupedItemsWithSupplier.hasOwnProperty(strPreferredSupplierId)) {
        // @ts-ignore
        // please fix my types :(
        objGroupedItemsWithSupplier[strPreferredSupplierId] = union(objGroupedItemsWithSupplier[strPreferredSupplierId], this.arSelectedItemsWithoutSupplier);
        objCombinedGroupedItems = objGroupedItemsWithSupplier;
      } else {
        objCombinedGroupedItems = Object.assign(objGroupedItemsWithSupplier, { [strPreferredSupplierId]: this.arSelectedItemsWithoutSupplier });
      }

      let arPurchaseOrdersList = [];

      for (var supplierId in objCombinedGroupedItems) {

        let arLineItems = [];
        let objItemRecord = objCombinedGroupedItems[supplierId];

        objItemRecord.forEach( item => {
          item['line_item']['unit_cost'] = item['item_record']['unit_cost'];
          item['line_item']['quantity'] = item['line_item']['quantity'];
          item['line_item']['total_price']= item['line_item']['unit_cost'] * item['line_item']['quantity'];
          item['line_item']['account_code_id'] = get(item, ['item_record', 'account_code_id'], null);
          item['line_item']['account_code'] = get(item, ['item_record', 'account_code'], null);
          item['line_item']['tax_code_id'] = get(item, ['item_record', 'tax_code_id'], null);
          item['line_item']['tax_code'] = get(item, ['item_record', 'tax_code'], null);

          if (item['line_item']['account_code_id'] === null) {
            item['line_item']['account_code_id'] = this.objRecords['related_data']['default_account_code_purchase']['id'];
            item['line_item']['account_code'] = this.objRecords['related_data']['default_account_code_purchase']['code'];
          }

          if (item['line_item']['tax_code_id'] === null) {
            item['line_item']['tax_code_id'] = this.objRecords['related_data']['default_tax_code_purchase']['id'];
            item['line_item']['tax_code'] = this.objRecords['related_data']['default_tax_code_purchase']['code'];
          }

          if (get(this.objRecords, ['opportunity_record', 'department_id'], null)) {
            item['line_item']['department_id'] = get(this.objRecords, ['opportunity_record', 'department_id'], null);
          }

          arLineItems.push(item['line_item']);
        });

        let objPOData = {
          customer_id : supplierId,
          contact_id: get(this.objRecords, ['opportunity_record', 'contact_id'], null),
          job_id: this.objRecords['job_id'],
          site_id: get(this.objRecords, ['opportunity_record', 'site_id'], null),
          line_items: arLineItems,
          status: 'draft'
        };

        if (objPOData.site_id !== null) {
          objPOData['delivery_location'] = 'site';
        }

        arPurchaseOrdersList.push(objPOData);
      }

      this.recordService.saveMultipleRecord('purchase_orders', arPurchaseOrdersList).subscribe( response => {

        if (response.status === 200) {
          let strPOList = Object.values(response.body.success).join(', ');
          this.customTranslate.initializeTransalateables(this.arTranslateables);
          this.notificationService.sendNotification('success', this.customTranslate.getTranslation('multiple_po_created') + strPOList, 'success');
          this.cancelDialog();
        } else {
          this.notificationService.sendNotification('error_in_saving', 'error_in_creating_po', 'warning');
        }

        this.bDisabled = false;
      });

    }
  }

  /**
   * Initialize related field for preferred supplier
   *
   * @returns {void}
   */
  initRelateSupplier() {
    this.recordService.getRecordRelate('customers', false, false, false, this.objPreferredSupplierConfig['filter']).subscribe( response => {
      this.objPreferredSupplierConfig['loader'] = false;
      this.objPreferredSupplierConfig['obv'] = concat(
        of(response),
        this.objPreferredSupplierConfig['typehead'].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this.objPreferredSupplierConfig['loader'] = true),
          switchMap(term => this.recordService.getRecordRelate(this.objPreferredSupplierConfig['module'], term, '', false, this.objPreferredSupplierConfig['filter']).pipe(
            tap(() => {
              this.objPreferredSupplierConfig['loader'] = false;
            })
          ))
        )
      )
    });
  }

  /**
   * Closes this dialog
   *
   * @returns {void}
   */
  cancelDialog(): void {
    this.dialog.close();
  }

}
