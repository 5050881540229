import { LooseObject } from '../../../../../objects/loose-object';
import { RelateData } from './relate-data';
import { TaskMetadata } from './task-metadata';
import { Users } from '../../../../../objects/users';

export interface JobMetadata {

  assigned_user: RelateData | null;

  department: RelateData | null;

  customer: LooseObject | null;

  site?: LooseObject | null;

  due_date: string

  full_address: string;

  id: string;

  job_number: string;

  tasks: Array<TaskMetadata> | null;

  status: string;

  priority?: string;

  job_summary?: string;

  address?: string;

  type?: string;

  invoicing_type?: string;

  amount_to_invoice?: string;

  po_number?: string;

  substatus?: string;

  estimated_timeframe?: string;

  date_completed?: string;

  asset_status?: string;

  internal_notes?: string;

  created_at?: string;

  updated_at?: string;

  job?: LooseObject;

  is_deleted: boolean;

  schedule_assigned_user?: Users|null;

  reschedulable?: boolean;
}

export class JobMetadataC {

  assigned_user: RelateData | null;

  department: RelateData | null;

  customer: LooseObject | null;

  site?: LooseObject | null;

  due_date: string

  full_address: string;

  id: string;

  job_number: string;

  tasks: Array<TaskMetadata> | null;

  metadata_type: string = 'job';

  status: string;

  priority?: string;

  job_summary?: string;

  address?: string;

  type?: string;

  invoicing_type?: string;

  amount_to_invoice?: string;

  po_number?: string;

  substatus?: string;

  estimated_timeframe?: string;

  date_completed?: string;

  asset_status?: string;

  internal_notes?: string;

  created_at?: string;

  updated_at?: string;

  job?: LooseObject = {};

  is_deleted: boolean;

  schedule_assigned_user: Users|null;

  reschedulable?: boolean;

  constructor(properties: JobMetadata) {
    this.id = properties.id;
    this.job_number = properties.job_number;
    this.full_address = properties.full_address;
    this.due_date = properties.due_date;

    this.assigned_user = properties.assigned_user || null;
    this.department = properties.department || null;
    this.customer = properties.customer || null;
    this.site = properties.site || null;
    this.tasks = properties.tasks || null;
    this.status = properties.status || null;

    this.priority = properties.priority || null;
    this.job_summary = properties.job_summary || null;
    this.address = properties.address || null;
    this.type = properties.type || null;
    this.invoicing_type = properties.invoicing_type || null;
    this.amount_to_invoice = properties.amount_to_invoice || null;
    this.po_number = properties.po_number || null;
    this.substatus = properties.substatus || null;
    this.estimated_timeframe = properties.estimated_timeframe || null;
    this.date_completed = properties.date_completed || null;
    this.asset_status = properties.asset_status || null;
    this.internal_notes = properties.internal_notes || null;
    this.created_at = properties.created_at || null;
    this.updated_at = properties.updated_at || null;
    this.is_deleted = properties.is_deleted || false;
    this.schedule_assigned_user = properties.schedule_assigned_user || null;
    this.reschedulable = properties.reschedulable

    this.job = JSON.parse(JSON.stringify(properties));
  }
}