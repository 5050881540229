<div *ngIf="bPageShowSummary" class="d-flex justify-content-start">
  <span class="header-buttons" *ngIf="!bListLoading && objPagination">
    {{ 'list_view_summary' | translate:  { fromRecords: objPagination.from_records, toRecords: objPagination.to_records, totalRecords: objPagination.total_records } }}
  </span>
</div>

<div *ngIf="bPageShowButtons" class="d-flex justify-content-end">

  <span (click)="reset()"
    class="pr-2 pl-2"
    [ngClass]="objPagination.previous_page ? 'span-link pointer' : 'text-secondary'">
    <fa-icon [icon]="['far', 'chevron-double-left']"></fa-icon>
  </span>

  <span (click)="previous()"
    class="pr-2 pl-2"
    [ngClass]="objPagination.previous_page ? 'span-link pointer' : 'text-secondary'">
    <fa-icon [icon]="['far', 'chevron-left']"></fa-icon>
  </span>

  <ng-container *ngFor="let numPage of arNavigationList">
    <span (click)="changePage(numPage)"
      class="pr-1 pl-1"
      [ngClass]="intCurrentPage === numPage ? 'span-link' : 'text-secondary pointer'">
      {{ numPage }}
    </span>
  </ng-container>

  <span (click)="next()"
    class="pr-2 pl-2"
    [ngClass]="objPagination.next_page ? 'span-link pointer' : 'text-secondary'">
    <fa-icon [icon]="['far', 'chevron-right']"></fa-icon>
  </span>

  <span (click)="changePage(intLastPage)"
    class="pr-2 pl-2"
    [ngClass]="objPagination.next_page ? 'span-link pointer' : 'text-secondary'">
    <fa-icon [icon]="['far', 'chevron-double-right']"></fa-icon>
  </span>

</div>