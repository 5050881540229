export class TagDisplay {
  id?: string;
  name: string;
  color: string;
  module: string;

  constructor(props: TagDisplayInterface) {
    this.name = props.name;
    this.id = props.id;
    this.color = props.color || '#e0e0e0';
    this.module = props.module;
  }

  textColor?() {

    let hex = this.color.replace('#', '');

    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b);

    return luminance > 128 ? '#191919' : '#fff';
  }

}

export class TagRecordDisplay extends TagDisplay {
  include: boolean;

  constructor(tag: TagDisplayInterface) {
    super(tag);

    this.include = tag.include || false;
  }
}

export interface TagDisplayInterface {
  id?: string;
  name: string;
  color?: string;
  module: string;
  include?: boolean;
}