<h5 class="ml-3">{{ headerText | translate }}</h5>

<div *ngIf="form; else loading">
  <div class="container-fluid">
      <div class="row">
        <div *ngFor="let data of fields" class="col-{{data.space}} mb-3">
          <ng-container>
            <app-edit-field [item]="data" [form]="form" [bSubmitted]="bSubmitted" [strMode]="strMode"
              [strModule]="strModule"></app-edit-field>
          </ng-container>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <button id="scheduleFormCancel" type="button" class="btn btn-warning btn-block btn-danger btn-lg" (click)="cancel()" [disabled]="bSaving">
            <strong>{{ 'cancel' | translate }}</strong>
          </button>
        </div>
        <div class="col-6">
          <button id="taskUpsertSave" type="button" class="btn btn-primary btn-block btn-lg" (click)="save()" [disabled]="bSaving">
            <strong><fa-icon *ngIf="bSaving" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon> {{ 'save' | translate }}</strong>
          </button>
        </div>
      </div>
  </div>
</div>

<ng-template #loading>
  <div class="no-content text-center mt-2">
    <inline-view-loader [isLoading]="true"></inline-view-loader>
  </div>
</ng-template>