
    <fieldmagic-dialog
      icon="cog"
      label="Group Configuration"
      [instance]="self"
    >
      <ng-template fieldmagicDialogButtonsTmp>
        <fieldmagic-primary-button
          purpose="save"
          (click)="onSave()"
        >
        </fieldmagic-primary-button>
      </ng-template>

      <ng-template fieldmagicDialogContentTmp>
        <fieldmagic-number-input
          label="Cells per row"
          [(ngModel)]="promptPerColumn"
          [errors]="errors | data_get: 'prompt_per_column'"
          tooltip="Allows the prompts under this group to be displayed as column."
        >
        </fieldmagic-number-input>
      </ng-template>
    </fieldmagic-dialog>
  