<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'sticky-note']" ></fa-icon>&nbsp;
        <span *ngIf="strViewType == 'edit'" >{{ 'edit_note'| translate }} </span>
        <span *ngIf="strViewType == 'add'" >{{ 'create_note'| translate }} </span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="onSubmit()"
            icon="save"
            label="save"
            [isInProgress]="bShowLoader"
          ></fieldmagic-primary-button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            variant="error"
            (click)="cancelDialog()"
            icon="times"
            label="close"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr class="separator"/>
<div class="row footer">
  <div class="col-sm-12" *ngIf="notesForm && bPageLoaded">
    <form [formGroup]="notesForm">
      <textarea [required] class="form-control input-textarea" [ngClass]="{'is-invalid': !notesForm.valid && bSubmitted }" maxLength="10000" rows="5" formControlName="note"></textarea>

      <div *ngIf="notesForm.controls['note'].invalid && notesForm.controls['note'].dirty " class="text-danger">
        <small *ngIf="notesForm.controls['note'].errors.maxlength" class="align-self-center"> {{ 'max_character' | translate}} </small>
      </div>

      <fieldmagic-grid>
        <ng-container *ngIf="strModule == 'jobs' || strModule == 'opportunities'">
          <div
            *ngIf="strModule == 'jobs'"
            fieldmagicGridColumn="4"
          >
            <fieldmagic-checkbox-input
              formControlName="include_in_report"
              label="Include in job report"
            >
            </fieldmagic-checkbox-input>
          </div>
          <div fieldmagicGridColumn="4">
            <fieldmagic-checkbox-input
              formControlName="share_in_mobile"
              label="Share in mobile"
            >
            </fieldmagic-checkbox-input>
          </div>
        </ng-container>
        <div fieldmagicGridColumn="4">
          <fieldmagic-checkbox-input
            formControlName="is_sticky_note"
            label="Sticky Note"
          >
          </fieldmagic-checkbox-input>
        </div>
      </fieldmagic-grid>
    </form>
  </div>
  <ng-container *ngIf="!bPageLoaded">
    <div class="col-sm-12" align="center">
      <h4><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></h4>
    </div>
  </ng-container>
</div>