
<header >
  <div class="d-flex dialog-header">
    <h3 class="dialog-title">{{ "update" | translate }}</h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" id="btnSubmit" [disabled]="bSubmitted || arAttributes.length < 1" (click)="onSubmit()" class="btn ml-1 btn-primary">
            <inline-view-loader [isLoading]="bSubmitted" [hasText]="false"></inline-view-loader>
            <span class="pl-1">{{ "update" | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button type="button" id="btnCancel" (click)="cancelDialog()" class="btn ml-1 btn-danger">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>
<ng-container class="dialog-container">
  <div *ngIf="!isLoading" class="row">
    <div class="col-5"><label>{{ "field" | translate }}</label></div>
    <div class="col-6"><label>{{ "value" | translate }}</label></div>
    <div class="col-1"></div>
  </div>
  <div *ngIf="isLoading" class="row">
    <div class="col-12" align="center">
      <inline-view-loader [isLoading]="isLoading"></inline-view-loader>
    </div>
  </div>
  <div *ngIf="!arAttributes.length && !isLoading" class="row">
    <div class="col-5">--</div>
    <div class="col-6">--</div>
    <div class="col-1"><fa-icon [icon]="['fas', 'plus-circle']" class="text-success pointer" (click)="addField()"></fa-icon></div>
  </div>
  <ng-container *ngFor="let attr of arAttributes; let i = index">
      <div class="row pb-3">
        <div class="col-5">
          <ng-select
            [items]="attr['field_options'] | async"
            bindValue="id"
            bindLabel="text"
            [(ngModel)]="attr['selected_field']"
            [clearable]="false"
            (ngModelChange)="ngOnFormChange(i, $event)">
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span>{{ item["text"] | translate }}</span>
            </ng-template>
            <ng-template  ng-option-tmp let-item="item">
              <span> {{ item["text"] | translate }} </span>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-6">
          <ng-container *ngIf="attr['selected_field']">
            <form [formGroup]="attr['form']">
              <app-edit-field [item]="attr['metadata']" [form]="attr['form']" [strModule]="moduleSelected" hasLabel="false" bFilter="true" (parentEvent)="doSomethingInParent($event)"></app-edit-field>
            </form>
          </ng-container>
        </div>
        <div class="col-1" align="center">
          <fa-icon [icon]="['far', 'minus-circle']" class="filter-remove pointer text-danger" (click)="removeField(i)"></fa-icon>&nbsp;
          <fa-icon [icon]="['fas', 'plus-circle']" [ngClass]="{'text-success': !bIsAddfieldDisabled, 'text-secondary': bIsAddfieldDisabled}" class="pointer" (click)="bIsAddfieldDisabled ? '' : addField()"></fa-icon>
        </div>
      </div>
  </ng-container>
</ng-container>