// Core
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';

// Third Party
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// Service
import { ViewService } from '../../../services/view.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { RecordService } from '../../../services/record.service';
import { NotificationService } from '../../../services/notification.service';
import { FileService } from '../../../services/file/file.service';

// Objects
import { Common } from '../../../objects/common';
import { Phone } from '../../../objects/phone';
import { LooseObject } from '../../../objects/loose-object';

// Component
import { OptionComponent } from './pdf/option/option.component';
import { EditformComponent } from '../editform/editform.component';
import { ConvertdialogComponent } from './convertdialog/convertdialog.component';
import { ConvertOpportunityComponent } from './convert-opportunity/convert-opportunity.component';
import { WorkOrderItemsComponent } from './jobs/work-order-items/work-order-items.component';
import { ConvertedDataComponent } from './converted-data/converted-data.component';
import { ReasonDialogComponent } from './reason-dialog/reason-dialog.component';
import { AnnualConditionReportComponent } from './pdf/annual-condition-report/annual-condition-report.component';
import { EditInvoiceComponent } from '../../../module/jobs/customer-invoices/edit-invoice/edit-invoice.component';
import { EditPurchaseOrderComponent } from '../../../module/jobs/purchase-orders/edit-purchase-order/edit-purchase-order.component';
import { EditSupplierInvoiceComponent } from '../../../module/jobs/supplier-invoices/edit-supplier-invoice/edit-supplier-invoice.component';

// Others
import { ModuleLogo } from '../../../lists/module-logo';
import { LooseModuleData } from '../../../module/form-templates/shared/contracts/loose-module-data';
import { ReadableAddressPipe } from '../../../pipes/readable-address.pipe';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { EditRecurringJobsComponent } from '../widget/recurring-jobs/edit-recurring-jobs/edit-recurring-jobs.component';
import { RecordViewNavigation, SearchAfter } from '../../../objects/elasticsearch';
import { EditRecurringInvoiceFormComponent } from '../widget/recurring_invoices/form/edit-recurring_invoice-form.component';
import { SubscriptionRestrictionService } from '../../../services/subscription-restriction/subscription-restriction.service';
import { ENTERPRISE_PLAN } from '../../../objects/subscription-plans';
import { AccountingSyncAction } from '../../../integrations/accounting_systems/shared/components/buttons/sync/sync.component';
import { DataSharingService } from '../../../services/data-sharing/data-sharing.service';
import { ClientStoreService } from '../../../services/client-store.service';
import { LinkData } from '../../../objects/link-data';
import { sprintf } from 'sprintf-js';
import { LayoutDashboardService } from '../../layouts/layout-dashboard/layout-dashboard.service';
import { NewImportQueueFormComponent } from '../../../admin/import/forms/new-import-queue-form/new-import-queue-form.component';
import { ProfitabilityAnalysisDialogComponent, ProfitabilityAnalysisDialogData } from '../../../module/jobs/profitability-analysis/dialog/dialog.component';
import { WizardComponent as AdvanceReportWizardComponent } from '../../../module/advance-reports/wizard/wizard.component';
import { cloneDeep, get, isNil, has, join, split, isEmpty } from 'lodash-es';
import { blank, data_get, filled } from '../../utils/common';
import { PresenceDetails, PusherService } from '../../../services/pusher.service';
import { Client } from '../../../objects/client';
import { MODULE_ID_MAPPING as ACTIVITY_MODULE_ID_MAPPING } from '../../../lists/activity';
@Component({
  selector: 'app-viewform',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  providers: [
    ReadableAddressPipe
  ]
})

export class ViewComponent implements OnInit, OnDestroy {

  // Should be casted as Record, but for now let's do any.
  @Output() arRecordValue = new EventEmitter();
  @Output() objRecordRaw = new EventEmitter();

  @Input('form-template-data') formTemplateData: LooseModuleData|undefined;
  @Input('form-template-label') formTemplateLabel: string;
  @Input() objRecordDetails: object;
  @Input() fieldLinkData: LinkData;

  public bConverting: boolean = false;
  public bEditable: boolean;
  public deletable: boolean = true;
  public arIcon: any;
  public strRelatedLink : string;
  public strModule: string;

  public arRecordHeader: string[] = [];
  public arRecordConfig: string[] = [];
  public arRecordDetails: string[] = [];
  public arRelatedData: Object[] = [];
  public arRecordView: Array<{
    label: string,
    fields: [{
        [key: string]: any
    }]
  }> = [];

  public arRecordFields: string[] = [];
  public arRecordViewData: [] | {} = [];

  public strCurrentLabel: string;
  public arCurrentView: string[] = [];

  public bConvertButton: boolean = true;
  public bEditButton: boolean = true;
  public openingEditModal: boolean = false;

  public arNoRelateModule: string[] = [
    'departments',
    'tax_codes',
    'account_codes',
    'asset_groups',
    'asset_types',
    'roles',
    'job_templates',
    'warehouses',
    'payment_terms',
  ];
  public arNoRecordViewLineItems: string[] = ['customer_invoices', 'purchase_orders', 'supplier_invoices'];
  public arModuleWithRelatedData: string[] = ['jobs', 'customer_invoices', 'opportunities', 'recurring_jobs'];
  public arCustomerInvoicesData  = [];
  public arRecordViewList = [];
  public numInstanceId: any = false;
  public arAdminModules: Array<string> = ['asset_types', 'users'];

  public bViewDocument: boolean = false;

  protected objStatusWidget$: Subscription;
  protected objReloadRecordView$: Subscription;

  /**
   * Stores the data needed to navigate to the next page
   * based on the list pagination.
   *
   * @var {RecordViewNavigation}
   */
  public strNextData: RecordViewNavigation;

  /**
   * Stores the data needed to navigate to the previous page
   * based on the list pagination.
   *
   * @var {RecordViewNavigation}
   */
  public strPreviousData: RecordViewNavigation;

  public objBadgeModules = {
    'contacts': 'customer_text'
  };

  /**
   * Retrieve url of current page.
   *
   * @returns {string}
   */
  get strUrl(): string {

    let arUrl = this.router.url.split("/");

    arUrl.splice(0, 1);
    arUrl.splice(arUrl.length - 1, 1);

    return '/' + arUrl.join('/') + '/';
  }

  /**
   * FC-3930: check if active client is on an enterprise plan.
   * This is needed to display the info icon on the 'is_customer' and 'is_supplier' fields in the customers module.
   *
   * @return {boolean}
   */
  get isActiveClientOnEnterprisePlan(): boolean {
    return this.subscriptionService.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ENTERPRISE_PLAN)
  }

    /**
   * Used when controlType is 'custom_form_component'. Put here all additional data you want to send to the component.
   *
   * @return {boolean}
   */
    get additionalCustomFormComponent(): LooseObject {
      return {
        warehouse_id: get(this.arRecordDetails, 'id')
      };
    }

  get doesModuleSupportActivities(): boolean {
    return ACTIVITY_MODULE_ID_MAPPING[this.strModule] != undefined;
  }

  protected searchAfter: SearchAfter;

  routeParams$: Subscription;

  activeClient: Client|null;
  currentRecordViewers: PresenceDetails[] = [];

  constructor(
    private viewService: ViewService,
		private route: ActivatedRoute,
    private recordService: RecordService,
    private fileService: FileService,
    private notifService: NotificationService,
    private dialog: MatDialog,
    private router: Router,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private addressPipe: ReadableAddressPipe,
    private subscriptionService: SubscriptionRestrictionService,
    private dataSharingService: DataSharingService,
    private clients: ClientStoreService,
    private layoutDashboardService: LayoutDashboardService,
    private pusherService: PusherService,
  ) {
    // Fix for record view that uses the default routeReuseStrategy
    this.routeParams$ = this.route.params.subscribe(params => {
      this.searchAfter = window.history.state.searchAfter || null;

    });
    this.activeClient = this.clients.getActiveClient();
  }

  ngOnInit() {
    this.pusherService.initRecordViewPresence(this.objRecordDetails['module'], this.objRecordDetails['id']);
    this.pusherService.recordViewPresence.bind("pusher:subscription_succeeded", () => {
      const members = this.pusherService.recordViewPresence['members'].members;
      for (let userId in members) {
        if (userId === this.pusherService.recordViewPresence['members'].myID) {
          continue;
        }
        this.currentRecordViewers.push(this.pusherService.prepareMemberDetails(userId, members[userId]));
      }
    });
    this.pusherService.recordViewPresence.bind("pusher:member_added", (member) => {
      this.currentRecordViewers.push(this.pusherService.prepareMemberDetails(member.id, member.info));
    });
    this.pusherService.recordViewPresence.bind("pusher:member_removed", (member) => {
      this.currentRecordViewers = this.currentRecordViewers.filter(x => x.id !== member.id);
    });
  }

  ngOnChanges() {
    // Get and store request record config
    this.strModule = this.objRecordDetails['module'];
    this.viewService.setRecordModule(this.strModule);
    // Check if id has value
    if (this.objRecordDetails['id']) {
      // Send data to get record
      this.getRecord(this.objRecordDetails);
    }
    // Set module icon and current module
    this.arIcon = ModuleLogo[this.objRecordDetails['module']];

    // Waiting if we have changes outside this component
    this.objReloadRecordView$ = this.viewService.reloadRecordDetail.debounceTime(400).subscribe(data => {
      // If change has data. Reload record view
      if (data) {
        this.getRecord(this.objRecordDetails);
      }
    });
    this.objStatusWidget$ = this.viewService.arUpdateStatusWidget.distinctUntilChanged().debounceTime(400).subscribe(arData => {
      if (!this.numInstanceId) {
        this.numInstanceId = arData['instance_id'];
      }
      switch (arData['type']) {
        case 'update':
          this.getRecord(this.objRecordDetails);
          break;
        case 'converted':
          this.convertDialog('widget');
          break;
        case 'reason_for_lost':
          this.setReasonForLost();
          break;
      }
    });
  }

  /**
   * Retrieve currently viewed record based on ID.
   *
   * @param arRecordConfig
   *
   * @returns {void}
   */
  getRecord(arRecordConfig): void {

    let bHasModule: boolean = this.arModuleWithRelatedData.indexOf(arRecordConfig['module']) > -1;
    let strModule: string = arRecordConfig['module'];
    let objListData = this.getListingFilters();

    // Add the search_after
    if (objListData) {
      objListData['search_after'] = this.searchAfter;
    }

    if (filled(data_get(this.objRecordDetails, 'metadata'))) {
      this._initData({
        data: data_get(this.objRecordDetails, 'metadata'),
        config: arRecordConfig,
        moduleName: strModule,
      });
    } else {
      this.recordService.getRecord(
        strModule,
        arRecordConfig['id'],
        bHasModule,
        objListData,
        'view',
        'get',
        {
          is_view: true,
        }
      ).subscribe((data) => this._initData({
        data,
        config: arRecordConfig,
        moduleName: strModule,
      }));
    }
  }

  /**
   * Get Class for position of the field
   *
   * @param objField
   */
  getCustomClass(objField) {
    // Divide length by 12
    return 'col-' +12/objField.length;
  }

  /**
   * Switch from different tab details
   *
   * @param strTabName current tab
   */
  switchTabView(strTabName: string) {
    this.arRecordView.map(data => {
      // Get label
      if (data['label'] == strTabName) {
        // Set current record label
        this.strCurrentLabel = strTabName;
        // Set current record label details
        this.setCurrentView(data['fields']);
      }
    });
  }

  /**
   * Get field type and store in global variable
   *
   * @param strFieldId field id
   * @return input data type
   */
  getField(strFieldId) {

    if (strFieldId) {
      let arField: any = []
      arField = this.arRecordFields[strFieldId];

      if (arField) {

        let strCurrentFieldValue: any;
        let strFieldValue: any = this.arRecordDetails[strFieldId];
        // Check type
        switch(arField['type']) {

          case 'text':
          case 'checkbox':
          case 'date':
          case 'datetime':
            strCurrentFieldValue = (strFieldValue) ? strFieldValue : arField['default_value'];
          break;

          case 'textarea':
            let strTextAreaValue = (strFieldValue) ? strFieldValue : arField['default_value'];
            strCurrentFieldValue = this.nl2br(strTextAreaValue, false);
          break;

          case 'number':
            let intNumberValue = (strFieldValue) ? strFieldValue : arField['default_value'];
            strCurrentFieldValue = (intNumberValue) ? intNumberValue : 0;
          break;

          case 'currency':
            let intCurrencyValue = (strFieldValue) ? strFieldValue : arField['default_value'];
            strCurrentFieldValue = (intCurrencyValue) ? intCurrencyValue : 0.00;
          break;

          case 'address':
            strCurrentFieldValue = this.addressPipe.transform(strFieldValue || arField['default_value']);

            if (!has(arField, 'google_maps_url')) {
              arField['google_maps_url'] = '#';
              arField['value'] = strCurrentFieldValue;
              this.generateGoogleMapsUrl(arField)
            }
          break;

          case 'relate':
            let regExr = /_id/g;
            let strTextId = (strFieldId.includes('id')) ? strFieldId.replace(regExr, '_text') : `${strFieldId}_text`;

            strCurrentFieldValue = this.arRecordDetails[strTextId];

            if (get(arField, 'module') === 'customers') {
              arField['customer_notes'] = this.arRecordDetails['customer_notes'];
              arField['purchasing_notes'] = this.arRecordDetails['purchasing_notes'];
            }

            if (get(arField, 'module') === 'sites') {
              arField['google_maps_url'] = '#';
              arField['value'] = strCurrentFieldValue;
              this.generateGoogleMapsUrl(arField)
            }
          break;

          case 'multiselect':
            // Declare list
            let arMultiSelectList: Common[] = [];
            // Check if field has value
            if (strFieldValue) {
              // Loop object
              Object.keys(strFieldValue).forEach( item => {
                let strKey: any;
                // Get object key and has value
                if (strFieldValue[item]) {
                  // Create object
                  arMultiSelectList.push(strFieldValue[item]);
                }
              });
            }
            // Join array values to make it string
            strCurrentFieldValue = arMultiSelectList.join(', ');
          break;

          case 'dropdown':
            // Get the current value
            strCurrentFieldValue = (strFieldValue) ? strFieldValue : arField['default_value'];
            // Check if there is a options in the current field
            if ((strCurrentFieldValue != "" || typeof strCurrentFieldValue == 'number') && arField['options']) {
              // Loop and get the option text based on id
              arField['options'].forEach( arOption => {
                if (arOption['id'] == strCurrentFieldValue) {
                  strCurrentFieldValue = arOption['text'];
                }
              });
            }
          break;

          case 'dialog':
            if (strFieldValue) {
              strCurrentFieldValue = strFieldValue.map(element => {
                return new Phone(element);
              })
            } else {
              strCurrentFieldValue = [];
            }
          break;

          case 'json':
            if (this.strModule === "assets" && strFieldId === "attributes") {
              strCurrentFieldValue = this.arRecordFields["attributes"]["default_value"]
            } else {
              strCurrentFieldValue = strFieldValue;
            }
          break;

          case 'image':
            // Set this as blank first because we need to get the actual presigned url.
            strCurrentFieldValue = '';
            arField['loading'] = true;
          break;

          default:
            strCurrentFieldValue = (strFieldValue) ? strFieldValue.toString() : arField['default_value'].toString();
          break;
        }

        arField['value'] = strCurrentFieldValue;
        return arField;
      }
    }
  }

  goToEdit() {
    this.openingEditModal = true;
    this.pusherService.getRecordEditors(this.objRecordDetails['module'], this.objRecordDetails['id']).pipe(
      finalize(() => this.openingEditModal = false)
    ).subscribe((recordEditors) => {
      if (recordEditors.length > 0) {
        this.notifService.notifyError(
          this.translate.instant('record_presence.editing_message', {editor_name: recordEditors[0].name})
        );
      } else {
        this.goToEditDialog();
        this.dialog.afterAllClosed.subscribe(() => {
          this.pusherService.unsubscribePresenceChannel(this.pusherService.recordEditPresence, true);
        });
      }
    });
  }

  /**
   * To tell if edit will use dialog or link.
   *
   * @param - the id of record if edit.
   */
  goToEditDialog() {
    const dialogSpec = {
      maxWidth: '100%',
      width: '80%',
      height: 'auto',
      padding: '1%',
      disableClose: true,
    };

    if (this.strModule == 'pricebooks') {
      this.router.navigate(['admin/pricebooks/edit/' +this.arRecordHeader['id']]);
    } else if(this.strModule == 'customer_invoices') {
      // Append record details to module config to get the latest data
      // Create the object to be passed inside the dialog.
      let objData = {
        ...dialogSpec,
        // The id of the jobs, the invoice's "parent".
        data: {
          record_id : [],
          invoice: [],
          view_type : 'edit',
          customer_invoice_id: this.arRecordDetails['id']
        }
      };
      // Initialize the dialog.
      let dialogRef = this.dialog.open(EditInvoiceComponent, objData);
      dialogRef.afterClosed().first().subscribe(saveRecordData => {
        if (saveRecordData != undefined && saveRecordData.response == 'record_update_success') {
          this.notifService.notifySuccess('header_notification.success_update');
          this.getRecord(this.arRecordHeader);
        }
      });

    } else if(this.strModule == 'purchase_orders') {
      // Create the object to be passed inside the dialog.
      let objData = {
        ...dialogSpec,
        // The id of the jobs, the invoice's "parent".
        data: {
          record_id : '',
          purchase_order: [],
          purchase_order_id: this.arRecordDetails['id'],
          view_type : 'edit'
        }
      };
      // Initialize the dialog.
      let dialogRef = this.dialog.open(EditPurchaseOrderComponent, objData);
      dialogRef.afterClosed().first().subscribe(saveRecordData => {
        if (saveRecordData && saveRecordData != 'fail') {
          this.notifService.notifySuccess('header_notification.success_update');
          this.getRecord(this.arRecordHeader);
        }
      });
    } else if(this.strModule == 'supplier_invoices') {
        // Create the object to be passed inside the dialog.
        let objData = {
          ...dialogSpec,
          // The id of the jobs, the invoice's "parent".
          data: {
            record_id : '',
            supplier_invoice: [],
            supplier_invoice_id: this.arRecordDetails['id'],
            view_type : 'edit'
          }
        };
        // Initialize the dialog.
        let dialogRef = this.dialog.open(EditSupplierInvoiceComponent, objData);
        dialogRef.afterClosed().first().subscribe(saveRecordData => {
          if (saveRecordData.message == 'save') {
            this.notifService.notifySuccess('header_notification.success_update');
            this.getRecord(this.arRecordHeader);
          }
        });
    } else if (this.strModule === 'recurring_jobs') {
      dialogSpec.width = '80%';

      this
        .dialog
        .open(EditRecurringJobsComponent, {
          ...dialogSpec,
          data: {
            record_id: null,
            module: null,
            recurring_job: this.arRecordDetails,
            recurring_job_id: this.arRecordDetails['id'],
            view_type: 'edit',
          },
        })
        .afterClosed()
        .pipe(
          first(),
          filter(response => response !== undefined && response !== false),
          tap(() => this.getRecord(this.arRecordHeader)),
          tap(() => this.notifService.notifySuccess('header_notification.success_update')),
        )
        .subscribe();
    } else if (this.strModule === 'recurring_invoices') {
      this
        .dialog
        .open(EditRecurringInvoiceFormComponent, {
          ...dialogSpec,
          data: {
            isNew: false,
            moduleID: this.arRecordDetails['id'],
            moduleName: this.strModule,
            recordID: this.arRecordDetails['id'],
          },
        })
        .afterClosed()
        .pipe(
          tap(() => this.getRecord(this.arRecordHeader))
        )
        .subscribe();
    } else if (this.strModule === 'advance_reports') {
      this
        .dialog
        .open(AdvanceReportWizardComponent, {
          maxWidth: '100%',
          width: '80%',
          height: 'auto',
          disableClose: true,
          data: {
            isNew: false,
            recordDetails: this.arRecordDetails,
            id: this.arRecordDetails['id'],
          },
        })
        .afterClosed()
        .pipe(
          first(),
          filter(response => !isNil(response) && response !== false),
        )
        .subscribe(editRecordData => {
          if (editRecordData.action === 'save') {
            this.notifService.notifySuccess('header_notification.success_update');
            this.getRecord(this.arRecordHeader);
          }
        });
    } else {
      // Use dialog
      this.recordDialog();
    }
  }


  /**
   * Open dialog to edit record.
   */
  recordDialog(arData = []) {

    let dialogConfig : {[k: string]: any} = {
      //Here, we pass all the data we need inside the dialog.
      data: {
        "arData": arData,
        "strModule": this.arRecordHeader['module'],
        "strId": this.arRecordHeader['id'],
        "strMode": (this.arRecordHeader['id']) ? 'edit' : 'add'
      },
      disableClose: true
    };

    // IF MOBILE
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      // display as pop up if not mobile
      dialogConfig.width = '80%';
      dialogConfig.height = 'auto';
    }

    //This line initializes the dialog/modal.
    let editRecordDialog = this.dialog.open(EditformComponent, dialogConfig);

    editRecordDialog.afterClosed().first().subscribe(editRecordData => {
      if (editRecordData !== undefined &&  editRecordData.status !== undefined && editRecordData.status === 'save') {
        this.getRecord(this.arRecordHeader);
      }
    });
  }

  nl2br (str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }

  formatNumbers(data) {

    // Check if view label is numeric
    if (data && !isNaN(Number(data))) {

      // if number's length is less than 6
      if (data.toString().length <= 6) {
      // Loop until number is 6 digits
        while (data.toString().length < 6) {
          // add leading 0
          data = "0" + data;
        }
      }

      return '#' + data;
    }

    return data;
  }

  /**
   * Create link for relate
   * @param date
   */
  getRelateLink(strModule, strId) {
    let strResult = null;
    // Do we have module that is not on the list?
    if (strModule && strId && this.arNoRelateModule.indexOf(strModule) == -1) {
      // Do we have module that is in admin section? Alter module
      switch (strModule) {
        case 'users':
          strModule = 'admin/users';
        break;
        case 'teams':
          strModule = 'admin/teams';
          break;
        case 'items':
          strModule = 'items';
        break;
        case 'asset_types':
          strModule = 'admin/asset_types';
        break;
        case 'warehouses':
          strModule = 'admin/warehouse';
        case 'pricebooks':
          strModule = 'admin/pricebooks/edit'
        break;
      }
      // Alter result
      strResult = '/' + strModule + '/' + strId;
    }
    // Return link
    return strResult;
  }

  /**
   * We update the datetime value.
   * @param date
   */
  formatDate(date, bDateTime = true) {

    if (! date) {
      return '';
    }

    let convertedDate = moment(date);

    if (! convertedDate.isValid()) {
      return '';
    }

    if (bDateTime) {
      let convertedUTCDateTime = moment.utc(date).toDate();
      return moment(convertedUTCDateTime).format('lll');
    }

    return convertedDate.format('ll');
  }
  /**
   * To view converted data linked to this record
   */
  convertedLinkDialog() {
    let dialogConfig : {[k: string]: any} = {
      //Here, we pass all the data we need inside the dialog.
      data: {
        'module': this.strModule,
        'record_data': this.arRecordDetails
      }
    };

    // IF MOBILE
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      // display as pop up if not mobile
      dialogConfig.width = '80%';
      dialogConfig.height = 'auto';
    }

      //This line initializes the convert dialog/modal.
      let dialogReference = this.dialog.open(ConvertedDataComponent, dialogConfig);

      dialogReference.afterClosed().first().subscribe( data => {});
  }
  /**
   * Convert dialog to convert record.
   */
  convertDialog(reference) {
    let dialogConfig : {[k: string]: any} = {
      //Here, we pass all the data we need inside the dialog.
      data: {
        'module': this.strModule,
        'reference': reference,
        'recordDetails': this.arRecordDetails
      }
    };

    // IF MOBILE
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      // display as pop up if not mobile
      dialogConfig.width = '80%';
      dialogConfig.height = 'auto';
    }

    let convertDialog;

    if (this.strModule == 'leads') {
      //This line initializes the convert dialog/modal.
      convertDialog = this.dialog.open(ConvertdialogComponent, dialogConfig);

      convertDialog.afterClosed().first().subscribe( editRecordData => {
        if (editRecordData) {
          this.getRecord(this.arRecordHeader);
        }
      });
    }

    if (this.strModule == 'opportunities') {

      dialogConfig.data.relatedData = this.arRelatedData;

      //This line initializes the opportunity conversion dialog/modal.
      convertDialog = this.dialog.open(ConvertOpportunityComponent, dialogConfig);

      // When the conversion dialog is closed.
      convertDialog.afterClosed().first().subscribe( editRecordData => {
        // If conversion was a success, recall the same record from the API to get the new values.
        if (editRecordData && editRecordData['message'] == 'success') {
          this.router.navigate(['jobs/' + editRecordData['job_id']]);
          // Success but skip conversion
        } else if (editRecordData == 'success') {
          this.recordService.saveRecord(this.strModule, {'stage': 'closed_won'}, this.arRecordDetails['id'])
          .subscribe( data => {
            // Inform the status widget component of the change.
            this.viewService.updateStatusWidget({
              type: 'update',
              data: { module: this.strModule, id: this.arRecordDetails['id']},
              instance_id: this.numInstanceId
            });
            // Show notification that the status has been updated.
            this.notifService.notifySuccess('status_update_success');
            //Get the new record.
            this.getRecord(this.arRecordHeader);
          });
        }
      });
    }

    if (this.strModule === 'purchase_orders') {
      let arData = {
        record_id: '',
        view_type: 'add',
        purchase_order_id: this.arRecordDetails['id'],
        purchase_order_text: this.arRecordDetails['text'],
        from_convert_po: true,
      };

      // Create the object to be passed inside the dialog.
      let objData = {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        padding: '1%',
        disableClose: true,
        data: arData
      };
      // Initialize the dialog.
      let dialogRef = this.dialog.open(EditSupplierInvoiceComponent, objData);

      dialogRef.afterClosed().first().subscribe(saveRecordData => {
        if (saveRecordData.message == 'save') {
          this.notifService.sendNotification('success', 'convert_success', 'success');
          this.router.navigate(['supplier_invoices/' + saveRecordData.id]);
        }
      });
    }
  }

  /**
   * Get the default value from metadata
   *
   * @param {object} objMetadata
   *
   * @returns {string}
   */
  getFieldValue(objMetadata): string {
    let strValue = objMetadata.value;
    let strResponse = '';

    switch (objMetadata.type) {
      case 'dropdown':
        if (objMetadata.options) {

          let objOption = objMetadata.options.find( option => option.id === objMetadata.default_value );
          strResponse = (objOption && objOption.text) ? objOption.text : '';
        }
        break;
      case 'geocoding':
        let latitudeValue = objMetadata['default_value'] && !isEmpty(String(objMetadata['default_value']['latitude'])) ? objMetadata['default_value']['latitude'] : '--';
        let longitudeValue = objMetadata['default_value'] && !isEmpty(String(objMetadata['default_value']['longitude'])) ? objMetadata['default_value']['longitude'] : '--';

        strResponse = sprintf('%s: %s\n%s: %s', this.translate.instant('latitude'), latitudeValue, this.translate.instant('longitude'), longitudeValue);
      break;
      case 'multitext':
        strResponse = join(split(strValue, ','), ', ');
        break;
      default:
        strResponse = (strValue !== '' && strValue !== undefined && strValue !== null) ? strValue.toString() : '';
        break;
    }

    if (this.strModule == 'customers') {
      if (objMetadata.label == 'customer_number' && !this.arRecordDetails['is_customer']) {
        strResponse = null;
      }
      if (objMetadata.label == 'supplier_number' && !this.arRecordDetails['is_supplier']) {
        strResponse = null;
      }
      if (
        (objMetadata.label === 'create_job_email_address' || objMetadata.label === 'create_supplier_invoice_email_address') &&
        this.isActiveClientOnEnterprisePlan && isEmpty(strResponse)
      ) {
        strResponse = this.generateCreateJobOrSupplierInvoiceEmail(objMetadata.label);
      }
      // If client plan is not enterprise, display blank values for 'create_job_email_address' and 'create_supplier_invoice_email_address'
      if (
        (objMetadata.label === 'create_job_email_address' || objMetadata.label === 'create_supplier_invoice_email_address') &&
        !this.isActiveClientOnEnterprisePlan
      ) {
        strResponse = '--';
      }
    }

    return strResponse
  }

  /**
   * Updates only the amount_to_invoice field after computing profitability analysis.
   * Only available on job record with 'time_and_materials' invoicing type
   *
   * @param {number} numUpdatedAmountToInvoice
   *
   * @returns {void}
   */
  updateAmountToInvoiceField(numUpdatedAmountToInvoice: number): void {
    if (this.strModule === 'jobs') {
      this.arRecordViewList.forEach(arItem => {
        const amountToInvoiceField = arItem.find(field => field['label'] === 'amount_to_invoice');

        if (amountToInvoiceField) {
          amountToInvoiceField['value'] = numUpdatedAmountToInvoice;
          return;
        }
      });
    }
  }

  /**
   * Generate a 'create job' or 'create supplier' invoice address
   *
   * @param {'is_customer' | 'is_supplier'} strKey
   *
   * @returns {string}
   */
  generateCreateJobOrSupplierInvoiceEmail(strLabel: 'create_job_email_address' | 'create_supplier_invoice_email_address'): string {
    let objCurrentClient = JSON.parse(this.localStorageService.getItem('current_client'));
    let strModule: string = strLabel === 'create_job_email_address' ? 'createjob' : 'createsupplierinvoice';

    return `${this.arRecordDetails['id']}.${strModule}.${objCurrentClient.alias}@${environment.archive_domain_name}`;
  }

  ngOnDestroy() {
    // FC-1069: unsubscribe to the observable subscription upon destroyed of this component
    // to disable redundant subscription to the event emitted by the status widget component
    // as subscription retains the in the application states even if the component is not being rendered
    this.objStatusWidget$.unsubscribe();
    this.objReloadRecordView$.unsubscribe();
    this.routeParams$.unsubscribe();
    this.layoutDashboardService.objCurrentViewedRecord = null;
    this.pusherService.unsubscribePresenceChannel(this.pusherService.recordViewPresence, true);
  }

  /**
   * Show dialog when changing reason for lost.
   */
  setReasonForLost() {
    let dialogData = {
      width: '800px',
      height: '170px',
      panelClass: 'custom-dialog-container',
      data: {}
    };

    if (this.arRecordViewData['used_fields'] != undefined && this.arRecordViewData['used_fields']['reason_for_lost'] != undefined) {
      dialogData['data']['options'] = this.arRecordViewData['used_fields']['reason_for_lost']['options'];
      dialogData['data']['default_value'] = this.arRecordViewData['used_fields']['reason_for_lost']['default_value'];
    }

    //This line initializes the convert dialog/modal.
    let setReasonDialog = this.dialog.open(ReasonDialogComponent, dialogData);

    setReasonDialog.afterClosed().first().subscribe( editRecordData => {
      if (editRecordData && editRecordData != "") {
        this.recordService.saveRecord(this.strModule, {'stage': 'closed_lost', 'reason_for_lost': editRecordData}, this.arRecordDetails['id'])
          .subscribe( data => {
            // Inform the status widget component of the change.
            this.viewService.updateStatusWidget({
              type: 'update',
              data: { module: this.strModule, id: this.arRecordDetails['id']},
              instance_id: this.numInstanceId
            });
            // Show notification that the status has been updated.
            this.notifService.notifySuccess('status_update_success');
            //Get the new record.
            this.getRecord(this.arRecordHeader);
          });
      }
    });
  }

  formatMultiselectValues(values, options): string|null {

    if (typeof values !== 'object') {
      return null;
    }

    return Object.assign(values).map((value) => {

      if ((typeof value == 'object')) {

        return Object.values(value)[0];
      } else {
        let option = options.find( ({ id }) => id === value )
        let strValue = (option !== undefined && option !== null) ? option.text : '';
        // FC-1825: If strValue is not empty string, translate the value
        return (strValue) ? this.translate.instant(strValue) : strValue;
      }
    }).join(', ');
  }

  /**
   * Prepare data before viewing.
   * @param arResult
   */
  setCurrentView(arResult) {

    let arFinalResult = [];

    if (typeof arResult == 'object' && arResult.length > 0) {
      arFinalResult = arResult.map(element1 => {
        return element1.map(element2 => {
          let arDisplayField = this.getField(element2);
          if (this.strModule == "jobs" || this.strModule == "opportunities") {
            // FC-2216: if custom location is false and in element of address return undefined
            if (!this.arRecordDetails["is_custom_location"] && element2 == "address") {
              return;
            }
          }

          if (this.strModule == 'contacts') {
            if (!this.subscriptionService.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ENTERPRISE_PLAN) && element2 == 'portal_active') {
              return;
            }
          }

          if (arDisplayField != undefined) {
            return this.getField(element2);
          }
        // Make sure we remove any undefined values.
        }).filter(element3 => {
          if (element3 == undefined) {
            return false;
          } else {
            return true;
          }
        })

      })
    }

    this.arRecordViewList = arFinalResult;
    this.setOtherDelayedValues();
  }

  /**
   * Set other values that we need but is expected to send another request.
   */
  setOtherDelayedValues() {
    this.arRecordViewList.forEach((element1, index1) => {
      element1.forEach((element2, index2) => {
        if (element2['type'] != undefined && element2['type'] == 'image') {
          if (element2['default_value'] != undefined &&
            element2['default_value'] != '' &&
            element2['default_value']['upload_name'] != undefined &&
            element2['default_value']['upload_name'] != '') {
              this.fileService.getObjectSignedUrl(element2['default_value']['upload_name'], environment.client_bucket).subscribe(object => {
                this.arRecordViewList[index1][index2]['loading'] = false;
                this.arRecordViewList[index1][index2]['value'] = object['url'];
              });
          } else {
            this.arRecordViewList[index1][index2]['loading'] = false;
          }
        }
      })
    })
  }

  /**
   * Check if the user type is admin.
   * Check if the module is allowed to pthe current user type
   *
   * @param strModule
   *
   * @returns {boolean}
   */
  validateRelateModule(strModule): boolean {
    if (this.arNoRelateModule.indexOf(strModule) == -1) {
      if (this.arAdminModules.indexOf(strModule) === -1) {
        return true;
      } else {
        return JSON.parse(this.localStorageService.getItem("current_client")).level == 'admin';
      }
    }

    return false;
  }

  /**
   * Update's the current record view.
   *
   * @param {Array<object>}
   *
   * @returns {Array<{label: string, fields: [{[key: string]: any}]}>}
   */
  updateRecordView(arRecordView): Array<{
    label: string,
    fields: [{
        [key: string]: any
    }]
  }> {
    let arNewRecordView: Array<{
      label: string,
      fields: [{
          [key: string]: any
      }]
    }> = arRecordView;

    let objFieldToRemove = {
      // FC-1788: if status is not closed_lost, remove the reason_for_lost
      opportunities: {
        is_passed: this.arRecordDetails['status'] && this.arRecordDetails['status'] !== 'closed_lost',
        field: 'reason_for_lost'
      },
      // FC-3676: when is_inventoried is true, hide the current stock level
      items: {
        is_passed: this.arRecordDetails['is_inventoried'] && this.arRecordDetails['is_inventoried'] === true,
        field: 'current_stock_level'
      }
    }

    if (objFieldToRemove[this.strModule] && objFieldToRemove[this.strModule].is_passed) {
      arNewRecordView = [];
      arRecordView.forEach( tab => {
        var newFields = tab['fields'].map( fields => {
          return fields.filter( field => {
            return field !== objFieldToRemove[this.strModule].field;
          })
        }).filter( fields => fields.length !== 0);

        // Create set of new record view
        arNewRecordView.push({
          label: tab['label'],
          fields: newFields
        });
      });
    }

    return arNewRecordView;
  }

  /**
   * Format the metadata's default value
   *
   * @param objUsedFields
   */
  formatMetadataDefaultValue(objUsedFields) {
    if (this.strModule === "assets") {
      Object.keys(objUsedFields).forEach( field_key => {
        if (field_key === 'attributes') {
          var currentValue = cloneDeep(this.arRecordDetails[field_key]);
          var assetTypeAttributes = this.arRecordDetails["asset_type_attributes"];
          if (assetTypeAttributes) {
            var assetAttributes = assetTypeAttributes.map( item => {
              item.default_value = currentValue[item.key];
              return item;
            })
            objUsedFields[field_key].default_value = assetAttributes;
          }
        }
      });
    }
    return objUsedFields;
  };

  /**
   * Display the work order items dialog
   *
   * @returns {void}
   */
  jobWorkOrderItemsDialog(): void {
    let dialogConfig : {[k: string]: any} = {
      data: {...this.arRecordViewData, auto_save: true},
      disableClose: true
    };
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      dialogConfig.width = '80%';
      dialogConfig.height = 'auto';
    }

    let workOrderItemsDialog = this.dialog.open(WorkOrderItemsComponent, dialogConfig);
    workOrderItemsDialog.afterClosed().subscribe( response => {
      if (response) {
        this.notifService.notifySuccess('header_notification.success_update');
        let arRecordConfig = this.viewService.arRecordConfig;
        if (arRecordConfig['id']) {
          this.getRecord(arRecordConfig);
        }
      }
    });
  }

  /*
   * Checks if url given has proper protocol (http/s),
   * If there's not add https before redirecting
   *
   * @param {string} url
   * @returns {void}
   */
  openExternalLink(url: string): void {
    var protocolPattern = new RegExp('^(https?)://');

    if (! protocolPattern.test(url)) {
      url = "https://" + url;
    }

    window.open(url, '_blank');
  }

  /**
   * Retrieves filters based on given module.
   *
   * @param {string} strModule
   */
  private getListFilters(strModule: string): LooseObject {

    let arListingStorage: LooseObject = this.localStorageService.getJsonItem('listing');

    if (arListingStorage) {
      return arListingStorage[strModule];
    }
    return {};
  }

  /**
   * Format the data from the listing filters
   * to a readeable state by the API.
   *
   * @returns {LooseObject}
   */
  private getListingFilters(): LooseObject {
    const objFilters: LooseObject = this.getListFilters(this.strModule);
    if (objFilters) {
      return objFilters;
    } else {
      return {
        filter: {},
        filter_name: null,
        page: {},
        sort: []
      }
    }
  }

  /**
   * delete record
   */
  deleteRecord(): void {
    let recordId = this.arRecordHeader['id'] || null;
    if (recordId) {
      this.notifService.sendConfirmation('confirm_delete')
      .filter(confirmation => confirmation.answer == true)
      .subscribe(() => {
        this.recordService.deleteRecord(this.strModule, recordId).first().subscribe(
          data => {
            let arResponse = data.body;
            if (data.status == 200) {
              this.notifService.sendNotification('deleted_successful', arResponse.toString(), 'success');
              this.router.navigate([(this.isAdmin) ? '/admin/' +this.strModule : this.strModule]);
            } else {
              var warningMessage = (typeof arResponse === 'object' && arResponse['message']) ? arResponse['message'] : arResponse.toString();
              this.notifService.sendNotification('warning', this.getCustomDeleteMessage(warningMessage), 'warning');
            }
          },
          error => {
            if (error.error.id) {
              this.notifService.sendNotification('not_allowed', error.error.id[0], 'warning');
            }
          }
        );
      });
    } else {
      this.notifService.sendNotification('not_allowed', 'content_notification.fail_delete_resource', 'warning');
    }
  }

  /**
   * get delete message
   *
   * @param message
   */
  getCustomDeleteMessage(message: string): string {
    if (this.strModule === 'purchase_orders') {
      return this.translate.instant('purchase_orders_warning_disabled')+ ' ' +this.translate.instant('purchase_orders_warning_info')
    }
    return message;
  }

  /**
   * check if is in admin
   */
  get isAdmin(): boolean {
    return this.router.url.indexOf('/admin/') != -1;
  }

  /**
   * Returns a recurring job's required skills, concatenated by a comma.
   *
   * @returns {string}
   */
  get recurringJobsRequiredSkills(): string {
    return (this.arRelatedData['job_skills'] || [])
      .map(r => r.name)
      .join(', ');
  }

  /**
   * Returns a recurring job's checklist names, concatenated by a comma.
   *
   * @returns {string}
   */
  get recurringJobsChecklists(): string {
    return (this.arRelatedData['checklists'] || [])
      .map(r => r.name)
      .join(', ');
  }

  /**
   * Handles the accounting sync successful action event
   *
   * @param {AccountingSyncAction} action
   *
   * @returns {void}
   */
  handleAccountingSyncSuccessfulAction(action: AccountingSyncAction): void {
    if (
      action === AccountingSyncAction.ACTION_UPDATE_AMOUNT_DUE
      || action === AccountingSyncAction.ACTION_DEBUG_SYNC
    ) {
      this.getRecord(this.arRecordHeader);
    }
  }

  /**
   * Copy the record
   *
   * @return {void}
   */
  copyRecord(): void {
    let paramClientId = this.route.snapshot.queryParamMap.get('on_behalf_of');
    let recordId = this.arRecordDetails['id'];
    this.dataSharingService.copyMasterCustomer(recordId, paramClientId).subscribe(response => {
      this.notifService.notifySuccess('header_notification.success_copy');
    });
  }

  /**
   * Displays a notification showing user has copied text to clipboard
   *
   * @returns {void}
   */
  notifyCopy(): void {
    this.notifService.notifyInfo('copied_to_clipboard');
  }

  /**
   * FC-3930: Displays an information box telling the user that
   * to avail the feature of creating jobs or supplier invoices by email,
   * they have to upgrade to the enterprise plan.
   *
   * Only accessible to non-enterprise clients.
   *
   * @returns {void}
   */
  displayUpgradeInfoBoxForNonEnterpriseClients(): void {
    if (!this.isActiveClientOnEnterprisePlan && this.strModule === 'customers') {
      this.notifService.sendConfirmation(
        'create_job_or_supplier_invoice_from_email_upgrade',
        'additional_features',
        'default',
        { trueVal: 'upgrade', falseVal: 'decline' }
      ).filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.router.navigate(['/admin'], { queryParams: { open: 'manage_subscription' } });
      });
    }
  }

  /**
   * Retrieve relate link query params
   */
  getQueryParams(moduleName: string): Record<string, string> {
    switch (moduleName) {
      case 'customers':
        return this.makeRelateCustomerQueryParams();
    }

    return {};
  }

  /**
   * Create a query params for related customer module
   */
  protected makeRelateCustomerQueryParams(): Record<string, string> {
    const onBehalfOfClientId = get(this.arRecordDetails, 'customer_owner_client_id');
    const currentClientId = this.clients.getActiveClient().client_id;

    if (onBehalfOfClientId === currentClientId) {
      return {};
    }

    return {
      on_behalf_of: onBehalfOfClientId,
    };
  }

  /**
   * Opens Supplier Pricing Import Wizard
   *
   * @returns {void}
   */
  public importSupplierPricing(): void {
    this.dialog.open(NewImportQueueFormComponent, {
      disableClose: true,
      panelClass: ['w-100'],
      data: {
        module: 'supplier_pricing',
        recordId: this.arRecordDetails['id']
      }
    });
  }

  /**
   * Opens the Google Maps popup
   *
   * @param {LooseObject} field
   *
   * @returns {void}
   */
  generateGoogleMapsUrl(field: LooseObject): void {
    const strAddressText: string = get(field, 'value', '');

    if (get(field, 'module') === 'sites' && filled(strAddressText)) {
      const cleaned = strAddressText.replace(/^\[.*\][\s]?/, '');

      this.setMapsLinkUrl(field, {
        address: cleaned,
      });
    } else {
      this.setMapsLinkUrl(field, {
        address: strAddressText,
        lat: get(field, 'default_value.latitude', ''),
        lng: get(field, 'default_value.longitude', ''),
      });
    }
  }

  private setMapsLinkUrl(field: Record<string, any>, opts: {
    lat?: string | number,
    lng?: string | number,
    address?: string,
  } = {}): void {
    let gmapUrl: string;

    if (filled(opts.lat) && filled(opts.lng)) {
      gmapUrl = `https://www.google.com/maps/place/${opts.lat},${opts.lng}`;
    } else if (filled(opts.address)) {
      gmapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(opts.address)}`;
    }

    if (blank(gmapUrl)) {
      return;
    }

    field['google_maps_url'] = gmapUrl;
  }

  /*
   * Opens a job profitability dialog
   *
   * @param {JobRecord} record
   *
   * @returns {void}
   */
  protected showProfitabilityAnalysis(): void {
    this.dialog.open<ProfitabilityAnalysisDialogComponent, ProfitabilityAnalysisDialogData>(ProfitabilityAnalysisDialogComponent, {
      data: {
        jobId: this.arRecordDetails['id'],
      },
      minWidth: '95vw',
      disableClose: true,
    });
  }

  /**
   * Ignores the default sorting for keyvalue
   * iterations in html
   *
   * @returns {number}
   */
  ignoreSort(): number {
    return 0;
  }

  /**
   * Format the time.
   *
   * @params {LooseObject}
   */
  public formatTimeAvailability(objAvalability: LooseObject) {
    let strTimeRange = 'no_schedule';
    ['start_time', '-', 'end_time'].forEach(strKey => {
      if (strKey == '-' && strTimeRange != 'no_schedule') { strTimeRange = strTimeRange + ' - '; }
      if (objAvalability[strKey] != null) {
        if (strTimeRange == 'no_schedule') { strTimeRange = ''; }
        let strTime = objAvalability[strKey].split(':');
        let num = parseInt(strTime[0]);
        let mins = strTime[1];
        strTimeRange = strTimeRange +  ((num > 11) ? ((num - 12) || 12).toString().padStart(2, '0') :
          (num || 12).toString().padStart(2, '0')) + `:${(mins)} ${(num > 11) ?
          'PM' : 'AM'}`
      }
    });
    return strTimeRange;
  }

  private _initData(opts: {
    data: Record<string, any>,
    moduleName: string,
    config: string[],
  }): void {
    const { data, moduleName, config } = opts;

    // Save module config here.
    this.arRecordViewData = data;
    this.layoutDashboardService.objCurrentViewedRecord = {...{module: moduleName}, ...data};
    this.objRecordRaw.emit(data);

    // Check if the form has a labor field.
    if (
      data['used_fields']['labor'] != undefined &&
      data['used_fields']['labor']['default_value'] != undefined &&
      data['used_fields']['labor']['default_value']) {

      const EXCLUDED_ITEM_FIELDS_IN_LABOR_TYPE = ['current_stock_level', 'unit_cost', 'preferred_supplier'];

      // If yes, modify the form.
      // FC-2375: refactored removing unit_cost and current_stock_level when it is a labor
      data['record_view'] = data['record_view'].map(({label, fields}) => ({
        label,
        fields: fields.map((group) => group.filter((field) => ! EXCLUDED_ITEM_FIELDS_IN_LABOR_TYPE.includes(field)))
      }));
    }

    // Store request data
    this.arRecordDetails = data['record_details'];
    this.arRecordView = this.updateRecordView(data['record_view']);
    this.arRecordFields = cloneDeep(this.formatMetadataDefaultValue(data['used_fields']));

    if (get(data, 'related_data', null)) {
      this.arRelatedData = cloneDeep(data['related_data']);
    }

    if (! data_get(this.objRecordDetails, 'data_only')) {
      // Gets and saves data from view record
      this.viewService.setViewRecord(this.arRecordDetails);
      // Let's emit to the parent.
      this.arRecordValue.emit(this.arRecordDetails);
      // Recall module record view
      this.viewService.callRecordView();
    }

    // Special case if you want to change the value before display
    switch(config['module']) {
      case 'items':
        // Set image value to image url
        this.arRecordDetails['image'] = this.arRecordDetails['image_url'];
      break;
      case 'jobs':
        if (this.arRecordDetails['billable'] != true) {
          this.arRecordDetails['invoicing_type'] = "";
          delete this.arRecordFields['invoicing_type'];

          this.arRecordDetails['amount_to_invoice'] = "0.00";
          delete this.arRecordFields['amount_to_invoice'];
        }
      break;
      case 'purchase_orders':
        if (this.arRecordDetails['delivery_location'] == 'site') {
          this.arRecordFields['site_id']['is_hidden'] = false;
        } else if (
          this.arRecordDetails['delivery_location'] == 'refer_to_delivery_notes' ||
          this.arRecordDetails['delivery_location']
        ) {
          this.arRecordFields['delivery_notes']['is_hidden'] = false;
        } else if (this.arRecordDetails['delivery_location'] == 'main_warehouse') {
          this.arRecordFields['warehouse_id']['is_hidden'] = false;
        }

        if (this.arRecordDetails['accounting_sync_error']) {
          this.arRecordFields['accounting_sync_detail']['is_hidden'] = false;
        } else {
          this.arRecordFields['accounting_sync_detail']['is_hidden'] = true;
        }
      break;
      case 'customers':
        if (!isNil(this.arRecordFields['is_customer']) && !isNil(this.arRecordFields['create_job_email_address'])) {
          this.arRecordFields['create_job_email_address']['is_hidden'] = false;
        }

        if (!isNil(this.arRecordFields['is_supplier']) && !isNil(this.arRecordFields['create_supplier_invoice_email_address'])) {
          this.arRecordFields['create_supplier_invoice_email_address']['is_hidden'] = false;
        }
        break;
      case 'customer_invoices':
      case 'supplier_invoices':
        if (this.arRecordDetails['accounting_sync_error']) {
          this.arRecordFields['accounting_sync_detail']['is_hidden'] = false;
        } else {
          this.arRecordFields['accounting_sync_detail']['is_hidden'] = true;
        }
        break;
      case 'activities':
        if (this.arRecordDetails['activity_type'] == 'event') {
          // This is to override the text to get the right translation
          switch(this.arRecordDetails['activity_name']) {
            case 'phone':
              this.arRecordDetails['activity_name'] = 'events.phone';
            break;
            case 'in_person':
              this.arRecordDetails['activity_name'] = 'events.in_person';
            break;
            case 'web':
              this.arRecordDetails['activity_name'] = 'events.web';
            break;
          }
        }
      break;

      case 'assets':
        this.fieldLinkData['geolocation']['data'] = {
          'id': this.arRecordDetails['id'],
          'longitude' : this.arRecordDetails['geolocation']['longitude'],
          'latitude' : this.arRecordDetails['geolocation']['latitude'],
        };

      break;

      // FC-3970: Added subcontractor on dropdown level, so that it will visible to view record
      // we need to add it manually, because updating level to subcontractor is not allowed
      case 'users':
        this.arRecordFields['level'].options.push({
          id: 'subcontractor',
          text: this.translate.instant('subcontractor')
        });
      break;
    }
    // Store record data
    if ((typeof(config['label_id']) == 'string')) {
      config['label'] = this.arRecordDetails[config['label_id']];
    } else {
      // Get the value of each id
      let arLabel = config['label_id'].map( item => { return this.arRecordDetails[item]; });
      // Join the value
      config['label'] = arLabel.join(' ');
    }
    this.bEditable = get(this.arRecordDetails, 'editable', true);
    this.deletable = get(this.arRecordDetails, 'deletable', true);
    this.arRecordHeader = config;

    this.arRecordHeader['label'] = this.formatNumbers(this.arRecordHeader['label']);
    this.strCurrentLabel = this.arRecordView[0]['label'];

    this.setCurrentView(this.arRecordView[0]['fields']);

    if (! data_get(this.objRecordDetails, 'data_only')) {
      this.viewService.setViewResult(data);
    }

    // Pass the option in status widget
    let arWidgetValue = {};
    switch(config['module']) {
      case 'leads':
        // Change the convert button display and create widget values
        let strLeadCurrentStatus = this.arRecordDetails['status'];

        this.bConvertButton = (strLeadCurrentStatus == 'converted' || strLeadCurrentStatus == 'disqualified') ? false : true;

        arWidgetValue = {
          'id': strLeadCurrentStatus,
          'option': this.arRecordFields['status']['options'],
        }
      break;
      case 'jobs':
        // Change the convert button display and create widget values
        let strJobCurrentStatus = this.arRecordDetails['status'];
        this.bConvertButton = false;
        arWidgetValue = {
          'id': strJobCurrentStatus,
          'option': this.arRecordFields['status']['options'],
        }
      break;
      case 'opportunities':
        // Change the convert button display and create widget values
        let strOpportunitiesCurrentStatus = this.arRecordDetails['stage'];

        this.bConvertButton = (strOpportunitiesCurrentStatus == 'closed_won' || strOpportunitiesCurrentStatus == 'closed_lost' ) ? false : true;

        arWidgetValue = {
          'id': strOpportunitiesCurrentStatus,
          'option': this.arRecordFields['stage']['options'],
          'field': 'stage'
        }
      break;
      case 'customer_invoices':
          let strCustomerInvoicesCurrentStatus = this.arRecordDetails['status'];
          arWidgetValue = {
            'id': strCustomerInvoicesCurrentStatus,
            'option': this.arRecordFields['status']['options'],
          }
      break;
      case 'supplier_invoices':
          let strSupplierInvoicesCurrentStatus = this.arRecordDetails['status'];
          arWidgetValue = {
            'id': strSupplierInvoicesCurrentStatus,
            'option': this.arRecordFields['status']['options'],
          }
      break;
      case 'purchase_orders':
          let strPurchaseOrdersCurrentStatus = this.arRecordDetails['status'];
          arWidgetValue = {
            'id': strPurchaseOrdersCurrentStatus,
            'option': this.arRecordFields['status']['options'],
          }
      break;
    }

    if (! data_get(this.objRecordDetails, 'data_only')) {
      // Do we have widget value?
      if (arWidgetValue) {
        this.viewService.setStatusWidget(arWidgetValue);
      }

      if (this.strModule == 'opportunities') {
        this.viewService.setRecordNumber(this.arRecordDetails['opportunity_number']);
      }

      this.strNextData = data['next'] || null;
      this.strPreviousData = data['previous'] || null;
    }
  }
}
export interface ViewRecord {
  id: string;
}
