import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Response } from '@angular/http';

import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';
import { ErrorEventType, ErrorPublishingService } from './error-publishing.service';
import { ApiService } from './api.service';
import { fallback, filled } from '../shared/utils/common';
import { NotificationService } from './notification.service';

const kBaseUrl: string = environment.url + "/users/";
let headers = new HttpHeaders();
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  /**
   * Create instance of the users service
   *
   * @param {HttpClient} http
   */
  constructor (
    private http: HttpClient,
    private errors: ErrorPublishingService,
    private _api: ApiService,
    private _notifications: NotificationService,
  ) {}

  /**
   * Get all users from this client.
   */
  getUsers() {
    return this.http.post<Response>(kBaseUrl.slice(0, -1), "");
  }

  /*
   * Get User Details
   */
  getUserDetails(strUserId, bConfig): Observable<Response>{
    let body = new URLSearchParams();
    body.append('module', 'users');

    return this.http.post<Response>(kBaseUrl + strUserId, body);
  }

  /*
   * Update User Details
   */
  updateUserDetails(strUserId, objUserDetails): Observable<Response>{
    let body = new URLSearchParams();
    body.append('data', JSON.stringify(objUserDetails));
    body.append('module', 'users');

    return this.http.post<Response>(kBaseUrl + 'update/' + strUserId, body.toString());
  }

  /*
   * Get department list
   */
  getDepartmentList(): Observable<Response>{
    let body = new URLSearchParams();
    body.append('module', 'departments');
    return this.http.post<Response>(kBaseUrl + 'department', body);
  }

  /*
   * Get department details
   */
  getDepartmentById(strDepartmentId): Observable<Response>{
    let body = new URLSearchParams();
    body.append('module', 'departments');

    return this.http.post<Response>(kBaseUrl + 'department/' + strDepartmentId + "?config", body.toString());
  }

   /*
   * Get department details
   */
  getDepartmentMetadata(): Observable<Response>{
    let body = new URLSearchParams();
    body.append('module', 'departments');
    return this.http.post<Response>(kBaseUrl + 'metadata_department', body.toString());
  }

  /*
   * Create department
   */
  createDepartment(objDetails): Observable<Response>{
    let body = new URLSearchParams();
    body.append('data', JSON.stringify(objDetails));
    body.append('module', 'departments');

    return this.http.post<Response>(kBaseUrl+ 'create_department', body.toString());
  }

  /*
   * Update department
   */
  updateDepartment(objDetails): Observable<Response>{

    let body = new URLSearchParams();
    body.append('data', JSON.stringify(objDetails));
    body.append('module', 'departments');

    return this.http.post<Response>(kBaseUrl+ 'update_department/' + objDetails.id, body.toString());
  }

  /*
   * Delete department
   */
  deleteDepartment(strDepartmentId): Observable<Response>{

    let body = new URLSearchParams();
    body.append('id', strDepartmentId);
    body.append('module', 'departments');

    return this.http.post<Response>(kBaseUrl+ 'delete_department/' +strDepartmentId, body.toString());
  }

  getUserSharedLocationWithClient(strDepartmentId: string = null, strUserId: string = null): Observable<Response>{
    let body = new URLSearchParams();
    body.append('department_id', (strDepartmentId === null) ? '' : strDepartmentId);
    body.append('user_id', (strUserId === null) ? '' : strUserId);

    return this.http.post<Response>(kBaseUrl+ 'get_users_shared_location_with_client/', body.toString()).pipe(
      tap({
        error: (objError: HttpErrorResponse) => {
          if (objError.status === 403) {
            this.errors.publish({
              type: ErrorEventType.HTTP_ERROR,
              status: 403,
              for: 'technician_live_map',
            });
          }
        },
      })
    );
  }

  invite = (users: BasicUserDetails[], opts: {
    automatically_generate_password?: boolean,
    send_welcome_email?: boolean,
    send_welcome_email_to_inviter?: boolean,
  } = {}): Observable<boolean> => this._api.callPrivate$({
    path: '/users/invite',
    action: 'post',
    expectsNewFormat: true,
    notifyOnFailure: true,
    body: {
      users: users,
      generate_password_automatically: fallback(opts.automatically_generate_password, {
        fallback: () => false,
      }),
      send_welcome_email: fallback(opts.send_welcome_email, {
        fallback: () => true,
      }),
      send_welcome_email_to_inviter: fallback(opts.send_welcome_email_to_inviter, {
        fallback: () => true,
      }),
    },
    onSuccessResponse: () => {
      this._notifications.notifySuccess('User/s successfully invited.');
      return true;
    },
  });

  updatePassword = (password: string) => this._api.callPrivate$({
    path: '/users/update_password',
    action: 'post',
    body: {
      password,
    },
    expectsNewFormat: true,
    notifyOnFailure: true,
    onSuccessResponse: (_) => {
      this._notifications.notifySuccess('Password updated successfully.');

      return true;
    },
  })
}

export type BasicUserDetails = {
  email_address: string;
  password?: string;
  level: 'admin' | 'desktop' | 'mobile-premium';
}
