import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskCalendarComponent } from './task-calendar.component';
import { ListingService } from '../../services/listing.service';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before pluginsß
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '../../shared/modules/font-awesome.module';
import { CalendarApiService } from '../../module/calendar/services/calendar-api.service';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchService } from '../../services/search.service';
import { RecordService } from '../../services/record.service';
import { CalendarService } from '../../module/calendar/services/calendar.service';
import { ReadableAddressPipe } from '../../pipes/readable-address.pipe';
import { TaskDetailsNoActivitiesModule } from '../../module/calendar/dialogs/task-details-dialog/components/task-details-no-activities.module';
import { SharedInputModule } from '../../shared/modules/shared-input.module';
import { CustomNotificationMessageDialog } from './components/dialog/custom-notification-message.component';
import { SharedModule } from '../../shared/modules/shared.module';
import { NotifyViaPushService } from './components/services/notify-via-push-service';
import { DialogService } from '../../services/dialog.service';
import { TaskCalendarDialogComponent } from './components/dialog/task-calendar-dialog.component';
import { DropdownService } from '../../services/dropdown.service';

@NgModule({
  declarations: [
    TaskCalendarComponent,
    TaskCalendarDialogComponent,
    CustomNotificationMessageDialog,
  ],
  entryComponents: [
    TaskCalendarDialogComponent,
    CustomNotificationMessageDialog,
  ],
  exports: [
    TaskCalendarComponent,
    TaskCalendarDialogComponent,
    CustomNotificationMessageDialog,
  ],
  imports: [
    CommonModule,
    FullCalendarModule,
    TranslateModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    TaskDetailsNoActivitiesModule,
    SharedInputModule,
    SharedModule.forChild(),
  ],
  providers: [
    ListingService,
    CalendarApiService,
    CalendarService,
    SearchService,
    RecordService,
    ReadableAddressPipe,
    DialogService,
    NotifyViaPushService,
    DropdownService,
  ],
})
export class TaskCalendarModule { }
