import { Component, Input } from '@angular/core';
import { isArray } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fieldmagic-text',
  template: `
    <ng-container *ngIf="alignment === 'left'; else centerOrRight">
      <ng-container *ngTemplateOutlet="contentElem"></ng-container>
    </ng-container>
    <ng-template #centerOrRight>
      <div class="d-flex justify-content-{{ alignment }}">
        <ng-container *ngTemplateOutlet="contentElem"></ng-container>
      </div>
    </ng-template>
    <ng-template #contentElem>
      <span
        [class.fieldmagic-text-title]="purpose == 'title'"
        [class.fieldmagic-text-link]="purpose == 'link'"
        [class.fieldmagic-text-input-label]="purpose == 'input'"
        [class.fieldmagic-text-input-label-with-required-mark]="purpose == 'input' && withRequiredMarker"
        [class.fieldmagic-text-error]="purpose == 'error'"
      >
        {{ content | translate }}
      </span>
    </ng-template>
  `,
  styleUrls: ['./text.component.scss'],
})
export class TextComponent {
  @Input() content: string;

  @Input() alignment: TextAlignment = 'left';

  @Input() purpose: 'title' | 'link' | 'body' | 'input' | 'error' = 'body';

  @Input() withRequiredMarker: boolean = false;
}

@Component({
  selector: 'fieldmagic-input-errors',
  template: `
    <div class="fieldmagic-error-text-containers">
      <ng-container *ngFor="let error of (errors$ | async)">
        <fieldmagic-text
          class="fieldmagic-input-error-text"
          [content]="error"
          purpose="error"
        >
        </fieldmagic-text>
      </ng-container>
    </div>
  `,
  styleUrls: [
    './text.component.scss',
  ]
})
export class InputErrorTextComponent {
  @Input()
  set errors(value: string | string[]) {
    if (! isArray(value)) {
      value = [value];
    }

    this.errors$.next(value);
  }

  readonly errors$ = new BehaviorSubject<string[]>([]);
}

export type TextAlignment = 'left' | 'center' | 'right';
