import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, Subscription, of } from 'rxjs';
import { debounceTime, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ListingService } from '../../../services/listing.service';
import { RecordService } from '../../../services/record.service';
import { FolderTreeSource, DynamicFlatNode } from '../_class/folders-tree-source';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AddItemComponent } from './add-item/add-item.component';
import { isEmpty } from 'lodash-es';
import { ItemFoldersService } from '../_services/item-folders.service';
import { resolve } from 'dns';
import { NotificationService } from '../../../services/notification.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { FormControl } from '@angular/forms';
import { SearchService } from '../../../services/search.service';

@Component({
  selector: 'draggable-folders',
  templateUrl: './draggable-folders.component.html',
  styleUrls: ['./draggable-folders.component.scss']
})
export class DraggableFoldersComponent implements OnInit {

  /**
   * Listens to any events supplied by the parent component.
   *
   * @var {Observable<string>}
   */
  @Input('events') objEvents: Observable<string>;

  /**
   * Where we put the subscription for the events.
   *
   * @var {Subscription}
   */
  private arSubscriptions: Subscription[] = [];

  /**
   * Variable that will hold the tree's controls.
   *
   * @var {FlatTreeControl<DynamicFlatNode>}
   */
  treeControl: FlatTreeControl<DynamicFlatNode>;

  /**
   * Datasource for the tree.
   *
   * @var {FolderTreeSource}
   */
  dataSource: FolderTreeSource;

  /**
   * Checks if the current node selected has a child.
   *
   * @param {number} level
   * @param {DynamicFlatNode} node
   *
   * @returns {boolean}
   */
  hasChild = (level: number, node: DynamicFlatNode) => node.is_expandable;

  /**
   * Holds the ListingService instance.
   *
   * @var {ListingService}
   */
  list: ListingService;

  /**
   * Holds the MatDialog instance.
   *
   * @var {MatDialog}
   */
  dialog: MatDialog;

  /**
   * Holds the RecordService instance.
   *
   * @var {RecordService}
   */
  record: RecordService;

  /**
   * Holds the ItemFoldersService instance.
   *
   * @var {ItemFoldersService}
   */
  folder: ItemFoldersService;

  /**
   * Holds the NotificationService instance.
   *
   * @var {NotificationService}
   */
  notif: NotificationService;

  /**
   * The search folder input control.
   *
   * @var {string}
   */
  objSearchFolder: FormControl = new FormControl(null);

  search: SearchService

  constructor(
    list: ListingService,
    dialog: MatDialog,
    record: RecordService,
    folder: ItemFoldersService,
    notif: NotificationService,
    local: LocalStorageService,
    search: SearchService
  ) {

    this.list = list;
    this.dialog = dialog;
    this.record = record;
    this.folder = folder;
    this.notif = notif;
    this.search = search;
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(
      (node: DynamicFlatNode) => node.level,
      (node: DynamicFlatNode) => node.is_expandable
    );

    this.dataSource = new FolderTreeSource(this.treeControl, folder, local, null);
    this.refreshList();

  }

  ngOnInit(): void {

    this.arSubscriptions.push(
      this.objEvents.subscribe(() => {
        this.refreshList();
      })
    );

    this.arSubscriptions.push(this.objSearchFolder.valueChanges
      .pipe(
        debounceTime(500),
        tap(term => {
          if (term) {
            this.dataSource.bHideUnallocated = true;
          } else {
            this.dataSource.bHideUnallocated = false;
          }
        }),
        switchMap(term => {
          if (term) {
            return this.search.global(term, ['items', 'item_folders'])
          } else {
            return of(null);
          }
        })
      )
      .subscribe(results => {

        this.dataSource.data = [];
        this.dataSource.numPage = null;

        if (results == null) {
          this.dataSource.loadPages();
        } else {
          let arFolderIds = results.map(item => {
            if (item.module == 'item_folders') {
              return item.id;
            }
          }).filter(item => { return item != null});

          let arItemIds = results.map(item => {
            if (item.module == 'items') {
              return item.id;
            }
          }).filter(item => { return item != null});

          this.dataSource.loadPages(arFolderIds, arItemIds);
        }

    }));
  }

  ngOnDestroy(): void {
    this.arSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  /**
   * Refresh the list and revert
   * the page back to the first page.
   *
   * @returns {void}
   */
  refreshList(): void {
    this.dataSource.numPage = null;
    this.dataSource.dataChange.next([]);
    this.dataSource.loadPages();
  }

  /**
   * Make sure to collapse node when dragging.
   *
   * @param {DynamicFlatNode} objEvent
   */
  drag(objEvent: DynamicFlatNode): void {
    this.treeControl.collapse(objEvent);
  }

  /**
   * Drop event when a folder or product is dropped to
   * another folder.
   *
   * @param {CdkDragDrop} objEvent
   *
   * @returns {void}
   */
  async drop(objEvent: CdkDragDrop<DynamicFlatNode>): Promise<void> {

    let numDraggedItemIndex = this.dataSource.data.indexOf(objEvent.item.data);
    let numPreviousItemIndex = this.dataSource.data[objEvent.currentIndex];

    if (objEvent.currentIndex != numDraggedItemIndex) {

      if (objEvent.currentIndex < numDraggedItemIndex) {
        numPreviousItemIndex = this.dataSource.data[objEvent.currentIndex - 1];
      }

      let strId: string = null;
      let numLevel: number = null;

      if (objEvent.currentIndex == 0 || objEvent.currentIndex == this.dataSource.data.length) {
        numLevel = 0;
      } else if (numPreviousItemIndex.is_expandable) {
        numLevel = numPreviousItemIndex.level + 1;
        strId = numPreviousItemIndex['id'];
      } else {
        numLevel = numPreviousItemIndex.level;
        strId = numPreviousItemIndex['id'];
      }

      if (numLevel > 4) {
        return;
      }

      if (numLevel != 0 && numPreviousItemIndex.metadata['code']) {
        strId = numPreviousItemIndex.metadata['item_folder_id'];
      }

      if (numPreviousItemIndex) {
        let obJParent = this.dataSource.getParent(numPreviousItemIndex);
        if (obJParent && obJParent.id == 'unallocated' && objEvent.item.data.is_folder) {
          this.notif.notifyWarning('cannot_drag_folder_to_unallocated');
          return;
        }
      }

      if ((strId == 'unallocated' && objEvent.item.data.is_folder)) {
        if (!this.dataSource.bHideUnallocated) {
          this.notif.notifyWarning('cannot_drag_folder_to_unallocated');
          return;
        } else {
          if (objEvent.currentIndex > 1) {
            let objEventNew: CdkDragDrop<DynamicFlatNode> = objEvent;
            objEventNew.currentIndex = objEventNew.currentIndex - 1;
            this.drop(objEventNew);
          }
          return;
        }
      }

      this.dataSource.data[numDraggedItemIndex].level = numLevel;
      await this.updateProductOrFolder(objEvent, strId);
    }

    moveItemInArray(
      this.dataSource.data,
      numDraggedItemIndex,
      objEvent.currentIndex
    );

    this.dataSource.dataChange.next(this.dataSource.data);

    if (numPreviousItemIndex && !this.treeControl.isExpanded(numPreviousItemIndex)) {
      this.treeControl.expand(numPreviousItemIndex);

      if (this.dataSource.getParent(numPreviousItemIndex) != this.dataSource.getParent(this.dataSource.data[objEvent.currentIndex])) {
        this.dataSource.data.splice(objEvent.currentIndex, 1);
      }
      this.dataSource.dataChange.next(this.dataSource.data);
    }

  }

  /**
   * Refresh this node (if expandable only.)
   *
   * @param {objNode} DynamicFlatNode
   *
   * @returns {void}
   */
  refreshNode(objNode: DynamicFlatNode): void {

    objNode.is_loading = true;

    if (this.treeControl.isExpanded(objNode)) {
      this.treeControl.collapse(objNode);
    }

    setTimeout(() => {
      this.treeControl.expand(objNode);
    }, 1000);

  }

  /**
   * Refresh the parent node of item.
   *
   * @param objNode
   */
  refreshItem(objNode: DynamicFlatNode): void {
    this.refreshNode(this.dataSource.getParent(objNode));
  }

  /**
   * Add products to the current folder.
   *
   * @param {DynamicFlatNode} objNode
   *
   * @returns {void}
   */
  addProducts(objNode: DynamicFlatNode): void {

    this.dialog.open(AddItemComponent, {data: objNode.metadata})
      .afterClosed()
      .pipe(
        filter(arProductResponse => !isEmpty(arProductResponse)),
        switchMap(arProductResponseNotEmpty => this.folder.addItemsToFolder(arProductResponseNotEmpty, objNode.id).pipe(
          tap(() => {
            if (arProductResponseNotEmpty.length > 0) {
              arProductResponseNotEmpty.forEach(item => {
                let numNodeIndex = this.dataSource.data.findIndex(node => (node.id == item));
                if (numNodeIndex > -1) {
                  this.dataSource.data.splice(numNodeIndex, 1);
                }
              });
              this.dataSource.dataChange.next(this.dataSource.data);
            }
          })
        ))
      )
      .subscribe(() => { this.refreshNode(objNode); })

  }

  /**
   * Update the product folder id or the folders' parent id.
   *
   * @param {CdkDragDrop<DynamicFlatNode>} objNode
   * @param {string} strId
   *
   * @returns {Promise<any>}
   */
  async updateProductOrFolder(objNode: CdkDragDrop<DynamicFlatNode>, strId: string): Promise<any> {

    let objItem = objNode['item']['data']['metadata'];
    let objParent = this.dataSource.data.find(folders => folders.id == strId);
    if (objItem['code']) {

      let numIndex = this.dataSource.data.findIndex(item => { return item.id == objItem['id']});
      this.dataSource.data[numIndex].metadata = {
        ...this.dataSource.data[numIndex].metadata,
        ...{item_folder_id: strId}
      };

      return await this.record.saveRecord('items', {item_folder_id: strId}, objItem['id']).toPromise();
    } else {
      if (objItem['parent_folder_id'] != strId) {

        let objData = {
          parent_folder_id: strId,
          level: strId ? objParent['metadata']['level'] + 1 : 0,
          full_path: strId ? objParent['metadata']['full_path'] + objItem['full_path'] : '/' + objItem['name'],
        };

        let numIndex = this.dataSource.data.findIndex(item => { return item.id == objItem['id']});
        this.dataSource.data[numIndex].metadata = {
          ...this.dataSource.data[numIndex].metadata,
          ...objData
        };

        return await this.record.saveRecord('item_folders', {parent_folder_id: strId}, objItem['id'])
        .toPromise();
      }
    }

    return new Promise((resolve) => {
      resolve(null);
    });
  }
}