<div *ngIf="!bScheduleFormOpen">
  <div class="row">
    <div class="col-9">
      <h5 [ngClass]="{'ml-3': bHasPadding}">{{ 'schedule' | translate }}</h5>
    </div>

    <div class="col-3">
      <button class="btn btn-primary pull-right" type="button" id="createScheduleButton" (click)="openScheduleForm(null)">
        <fa-icon [icon]="['fas', 'plus']" aria-hidden="true"></fa-icon>
        <span class="ml-1 font-weight-bold">{{ 'create' | translate }}</span>
      </button>
    </div>
  </div>

  <div *ngIf="objRelatedSchedulesData; else loading">
    <div *ngIf="objRelatedSchedulesData['data'].length > 0; else noSchedules">
      <div class="row" [ngClass]="{'pl-3': bHasPadding, 'pr-3': bHasPadding}">
        <div class="col-6">
          <span>
            <small *ngIf="!bRelatedSchedulesLoading" class="small-font">
              {{ 'list_view_summary' | translate:{fromRecords: objRelatedSchedulesData.from, toRecords:
              objRelatedSchedulesData.to, totalRecords: objRelatedSchedulesData.total} }}
            </small>
          </span>
        </div>

        <div class="col-6 no-right-padding">
          <div class="pagination-section">
            <ul class="pagination float-right">
              <li class="page-item" [ngClass]="objRelatedSchedulesData['current_page'] === 1 || bRelatedSchedulesLoading ? 'disabled' : ''">
                <a id="relatedSchedulesFirstPage" class="page-link" aria-label="first" (click)="getSchedules(1)">
                  <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
                </a>
              </li>
              <li class="page-item" [ngClass]="objRelatedSchedulesData['current_page'] === 1 || bRelatedSchedulesLoading ? 'disabled' : ''">
                <a id="relatedSchedulesPrevPage" class="page-link" aria-label="previous" (click)="getSchedules(objRelatedSchedulesData['current_page'] - 1)">
                  <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                </a>
              </li>
              <li class="page-item" [ngClass]="(objRelatedSchedulesData['current_page'] === objRelatedSchedulesData['last_page']) || bRelatedSchedulesLoading ? 'disabled' : ''">
                <a id="relatedSchedulesNextPage" class="page-link" aria-label="next" (click)="getSchedules(objRelatedSchedulesData['current_page'] + 1)">
                  <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                </a>
              </li>
              <li class="page-item" [ngClass]="(objRelatedSchedulesData['current_page'] === objRelatedSchedulesData['last_page']) || bRelatedSchedulesLoading ? 'disabled' : ''">
                <a id="relatedSchedulesLastPage" class="page-link" aria-label="last" (click)="getSchedules(objRelatedSchedulesData['last_page'])">
                  <fa-icon [icon]="['far', 'chevron-double-right']" aria-hidden="true"></fa-icon>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div *ngIf="!bRelatedSchedulesLoading; else loading" class="row" [ngClass]="{'pl-3': bHasPadding, 'pr-3': bHasPadding}">
        <div class="col-12 border ml-1 mb-2 mt-2 p-3 small-font" *ngFor="let schedule of objRelatedSchedulesData?.data">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <div *ngIf="schedule.is_primary">
                <span class="badge badge-success font-size-12">{{ 'primary_task_schedule' | translate }}</span>
              </div>

              <div *ngIf="!bViewingFromTaskDialog && schedule.task">
                <div class="pointer light-blue" (click)="openTaskDialog(schedule)">
                  <b>{{ 'task' | translate }}</b>: {{ schedule.task.activity_name | truncate }}
                </div>
              </div>

              <div>
                <b>{{ 'due_date' | translate }}</b>:
                {{ formatDate(schedule.due_date) }}
              </div>

              <div><b>{{ 'assigned_user' | translate }}</b>: {{ schedule.user_text }}</div>
            </div>

            <div>
              <button class="btn btn-primary mr-1" type="button" id="editButton" (click)="openScheduleForm(schedule)">
                <span>{{ 'edit' | translate }}</span>
              </button>

              <button class="btn btn-danger" type="button" id="deleteButton" (click)="deleteSchedule(schedule)">
                <span>{{ 'delete' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noSchedules>
      <div class="row pt-3" [ngClass]="{'pl-3': bHasPadding, 'pr-3': bHasPadding}">
        <div class="col-12">
          <span class="text-center">{{ 'no_related_schedules_found' | translate }}</span>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="bScheduleFormOpen">
  <app-schedule-form
    [schedule]="objSelectedSchedule"
    [activity-id]="strActivityId"
    [job-id]="strJobId"
    [opportunity-id]="strOpportunityId"
    [default-user-on-create]="defaultUser"
    (scheduleFormSave)="closeScheduleForm($event)">
  </app-schedule-form>
</div>

<ng-template #loading>
  <div class="no-content text-center mt-2">
    <inline-view-loader [isLoading]="true"></inline-view-loader>
  </div>
</ng-template>