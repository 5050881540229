
    <ng-container *ngIf="! standalone; else input;">
      <div class="form-group">
        <div class="d-flex d-flex-gap">
          <fieldmagic-text
            purpose="input"
            [content]="label"
            [withRequiredMarker]="withRequiredMarker"
          >
          </fieldmagic-text>

          <fieldmagic-icon
            *ngIf="tooltip | filled"
            icon="info-circle"
            [tooltip]="tooltip"
          >
          </fieldmagic-icon>
        </div>

        <ng-container *ngTemplateOutlet="input"></ng-container>
      </div>
    </ng-container>

    <ng-template #input>
      <input
        [disabled]="isDisabled$ | async"
        [(ngModel)]="value"
        type="number"
        step="1"
        [min]="min"
        [max]="max"
        class="form-control input-height-40 font-size-12"
        [class.fieldmagic-input-has-error]="errors | filled"
        [attr.placeholder]="placeholder"
        (change)="onChange($event.target.value)"
      />

      <fieldmagic-input-errors
        *ngIf="errors | filled"
        [errors]="errors"
      >
      </fieldmagic-input-errors>
    </ng-template>
  