import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { tap, delay, map } from 'rxjs/operators';
import { forkJoin, iif, Observable, of } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';
import { Config } from 'protractor';
import { Select } from '../objects/select';
import { LooseObject } from '../objects/loose-object';
import { NotificationService } from './notification.service';
import { ErrorEventType, ErrorPublishingService } from './error-publishing.service';
import { ClientStoreService } from './client-store.service';
import { get, isEmpty, isNil, isNull, includes } from 'lodash-es';
import { blank, filled, isId } from '../shared/utils/common';
import { TaxCodeInterface } from '../objects/tax-code';

const kBaseUrl: string = environment.url + "/record/";

@Injectable()
export class RecordService {

  constructor (
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected notifications: NotificationService,
    protected errors: ErrorPublishingService,
    protected clientStoreService: ClientStoreService,
  ) {}

  /**
   * save record
   *
   * @param strModule - request module
   * @param arData - record data
   * @param strId - default null, for update record
   */
  saveRecord(strModule, arData, strId = null): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('data', JSON.stringify(arData));
    if (strId) body.append('id', strId);
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'save', body.toString(), { observe: 'response' }).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.notifications.notifyNotAllowed();
          }
        }
      })
    );
  }

  /**
   * save multiple record
   *
   * @param strModule - request module
   * @param arData - record data
   * @param opts - additional metadata
   */
  saveMultipleRecord(strModule, arData, opts: {
    additional_data?: Record<string, any>,
    id?: string;
  } = {}): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('data', JSON.stringify(arData));

    /// include additional data
    if (! isEmpty(opts.additional_data)) {
      for (const key in opts.additional_data) {
        body.append(key, get(opts.additional_data, key));
      }
    }

    if (! isNil(opts.id)) {
      body.append('id', opts.id);
    }

    // Post request
    return this.http.post<Response>(kBaseUrl+ 'save_multiple', body.toString(), { observe: 'response' });
  }

  /**
   * Get record depending on requested module
   *
   * @param {string} strModule - The record's module.
   * @param {string} strId - The record's unique ID.
   * @param {boolean} bHasRelatedData - We should get the record along with the records related to it.
   * @param {LooseObject} objPageData - The filters/sort settings from the list view.
   */
  getRecord(
    strModule: string,
    strId: string,
    bHasRelatedData: boolean = true,
    objPageData: LooseObject = {},
    strOrigin: string = 'view',
    strUrl: string = 'get',
    options: GetRecordOptions = {}
  ): Observable<Response> {
    let body = new URLSearchParams();

    body.append('module', strModule);
    body.append('id', strId);
    body.append('has_related_data', bHasRelatedData.toString());

    if (!isNil(options.is_view)) {
      body.append('is_view', '1');
    }

    if (! isNil(options.is_data_only)) {
      body.append('is_data_only', '1');
    }

    if (objPageData) {
      body.append('page', JSON.stringify(objPageData));
    }

    const otherClientId = options.on_behalf_of_client || this.activatedRoute.snapshot.queryParamMap.get('on_behalf_of');

    if (filled(otherClientId) && isId(otherClientId)) {
      body.append('other_client_id', otherClientId);
    }

    return this.http.post<Response>(kBaseUrl + strUrl, body.toString()).pipe(
      tap({
        error: (objErr: HttpErrorResponse) => {
          if (objErr.status === 403) {
            this.errors.publish({
              type: ErrorEventType.HTTP_ERROR,
              status: 403,
              for: strOrigin,
            });
          }
        }
      })
    );
  }

  /**
   * Delete record
   *
   * @param strModule - request module
   * @param strId - record id to delete
   */
  deleteRecord(strModule, strId): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('id', strId);
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'delete', body.toString(), { observe: 'response' }).pipe(
      tap({
        error: (objErr: HttpErrorResponse) => {
          if (objErr.status === 403) {
            this.notifications.notifyNotAllowed();
          }
        }
      })
    );
  }

  /**
   * delete multiple record
   *
   * @param strModule - request module
   * @param arData - record data
   */
  deleteMultipleRecord(strModule, arData): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('data', JSON.stringify(arData));
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'delete_multiple', body.toString(), { observe: 'response' });
  }

  /**
   * update multiple record
   *
   * @param strModule - request module
   * @param arData - record data
   */
  updateMultipleRecord(strModule, arData): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('data', JSON.stringify(arData));
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'update_multiple', body.toString(), { observe: 'response' });
  }

  /**
   * Get record config
   *
   * @param strModule - The module of the requested record config.
   * @param objDefaultValue - Default values to provide along with the config.
   * @param withRelatedData - If the config comes with related data.
   */
  getRecordConfig(
    strModule: string,
    objDefaultValue: LooseObject = null,
    bHasRelatedData: boolean = false,
    objRelatedDataReference: LooseObject = null
  ): Observable<Response> {

    let body = new URLSearchParams();

    body.append('module', strModule);
    body.append('has_related_data', bHasRelatedData.toString());

    if (objRelatedDataReference) {
      body.append('related_data_reference', JSON.stringify(objRelatedDataReference));
    }

    if (objDefaultValue) {
      body.append('data', JSON.stringify(objDefaultValue));
    }

    return this.http.post<Response>(kBaseUrl+ 'config', body.toString());
  }

  /**
   * Get relate joined record
   *
   * @param strModule - request module to get config
   * @param strRecordId - request record id of main table
   * @param arFilter - request filter
   * @param arOrderClause - request order clause   Format: ['id' => 'column', 'sort' => 'desc']
   * @param intLimit - request limit
   * @param arWhereIn - request to find in array
   * @param arWhereNotIn - request to find in array
   */
  getRecordRelateJoined(
    strModule: string,
    strRecordId: any = false,
    arFilter: [] | boolean | object = false,
    arOrderClause: [] | boolean | object = false,
    intLimit: number | boolean = false,
    arWhereIn: [] | {} = [],
    arWhereNotIn: [] | {} = []): Observable<Select[]> {

    // Request parameter
    let arRequest = {};

    // If the relate has filters.
    if (arFilter) arRequest['filter'] = arFilter;
    if (arWhereIn) arRequest['where_in'] = arWhereIn;
    if (arWhereNotIn) arRequest['where_not_in'] = arWhereNotIn;
    if (arOrderClause) arRequest['order_clause'] = arOrderClause;
    if (intLimit) arRequest['limit'] = intLimit;
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    if (strRecordId) body.append('id', strRecordId);
    body.append('module', strModule);
    body.append('data', JSON.stringify(arRequest));

    // Post request
    return this.http.post<Select[]>(kBaseUrl+ 'relate_joined', body.toString());
  }

  /**
   * Get multiple module relate record
   *
   * @param strModule - request module to get config
   * @param strRecordIds - request record id of main table
   * @param arFilter - request filter
   * @param arOrderClause - request order clause   Format: ['id' => 'column', 'sort' => 'desc']
   * @param intLimit - request limit
   * @param arWhereIn - request to find in array
   * @param arWhereNotIn - request to find in array
   */
  getMultipleModuleRelateRecord(strModule, strRecordIds: any = false, arFilter: any = false, arOrderClause: any = false, intLimit: any = false, arWhereIn: {} | [] = [], arWhereNotIn: {} | [] = []): Observable<Select[]> {

    // Request parameter
    let arRequest = {};

    // If the relate has filters.
    if (arFilter) arRequest['filter'] = arFilter;
    if (arWhereIn) arRequest['where_in'] = arWhereIn;
    if (arWhereNotIn) arRequest['where_not_in'] = arWhereNotIn;
    if (arOrderClause) arRequest['order_clause'] = arOrderClause;
    if (intLimit) arRequest['limit'] = intLimit;
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    if (strRecordIds) body.append('ids', JSON.stringify(strRecordIds));
    body.append('module', strModule);
    body.append('data', JSON.stringify(arRequest));

    // Post request
    return this.http.post<Select[]>(kBaseUrl+ 'multiple_module_relate_record', body.toString());
  }

  /**
   * Get relate record
   *
   * @param strModule - request module to get config
   */
  getRecordRelate(
    strModule: string,
    strTerm: string | {},
    arIds: string | string[] | boolean,
    bEmail: boolean = false,
    arFilter: {} | boolean = false ,
    limit: number = 10,
    bHasAdditionalFilter: boolean = true,
    options: GetRecordRelateOptions = {}
  ): Observable<Select[]> {
    if (blank(arIds) || arIds === false) {
      arIds = [];
    }

    //Check if the type if string, then place it in an array for API to accept.
    if (typeof arIds == 'string' && filled(arIds)) {
      arIds = [arIds];
    }

    // Request parameter
    let arRequest = {
      term: strTerm,
      ids: arIds,
      limit: limit
    };
    // This request is for emails?
    if (bEmail) arRequest['is_email'] = true;

    // If the relate has filters.
    if (arFilter) arRequest['filter'] = arFilter;

    arRequest['additional_filter'] = bHasAdditionalFilter;

    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('data', JSON.stringify(arRequest));

    const otherClientId = options.on_behalf_of_client;

    if (filled(otherClientId) && isId(otherClientId)) {
      body.append('other_client_id', otherClientId);
    }

    // Post request
    return this.http.post<Select[]>(kBaseUrl+ 'relate', body.toString());
  }

  /**
   * Get relate record
   *
   * @param strModule - request module to get config
   */
  getRecordMetric(strModule): Observable<Select[]> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    // Post request
    return this.http.post<Select[]>(kBaseUrl+ 'metric', body.toString());
  }

  createActivity(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "create_activity", body.toString());
  }

  createEmailTemplate(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "create_email_template", body.toString());
  }

  /**
   * convert record
   *
   * @param strModule - request module
   * @param strData - modules that we need to convert
   */
  convertRecord(strModule, strData): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // FC-3657: when converting opportunity to job, migrate notes of opportunities to job internal notes
    if (strModule === 'opportunities') {
      strData.config[0].data['internal_notes'] = strData.record['notes'] || '';
    }
    // Append post parameter
    body.append('module', strModule);
    body.append('data', JSON.stringify(strData));
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'convert', body.toString(), { observe: 'response' });
  }

  /**
   * get dropdown record by module
   *
   * @param strModule - request module
   */
  getDropdownRecord(strModule): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'get_dropdown', body.toString(), { observe: 'response' });
  }

  /**
   * get dropdown record by module
   *
   */
  getAllDropdownRecord(): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'get_all_dropdown', body.toString(), { observe: 'response' });
  }

  /**
   * get duplicate record of related module of lead
   *
   */
  getPossibleLeadDuplicates(strLeadId: string = ''): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Request parameter
    body.append('id', strLeadId);
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'get_possible_lead_duplicates', body.toString(), { observe: 'response' });
  }

  /**
   * save dropdown record
   *
   * @param strModule - request module
   * @param arData - record data
   */
  saveDropdownRecord(strModule, arData): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('data', JSON.stringify(arData));
    // Post request

    // FC-4604: Cache is removed to retrieve the config with custom dropdown in the respective module
    const cacheKey = `config-${strModule}`;

    return this.http.post<Response>(kBaseUrl+ 'save_dropdown', body.toString(), { observe: 'response' }).pipe(
      tap(() => {
        sessionStorage.removeItem(cacheKey);
      }
    ));
  }

  /**
   * Restore default dropdown config
   *
   */
  restoreDropdownConfig(strModule: string, selectedField: string): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    body.append('field', selectedField);
    body.append('module', strModule);

    // Post request
    return this.http.post<Response>(kBaseUrl+ 'restore_dropdown', body.toString(), { observe: 'response' });
  }

  /**
   * Delete record
   *
   * @param strModule - request module
   * @param strId - record id to delete
   */
  deleteFilter(strModule, strId): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('id', strId);
    // Post request
    return this.http.post<Response>(environment.url + '/list/' + strModule + '/delete_filter', body.toString(), { observe: 'response' });
  }

  /**
   * Get all the unique asset types that is linked on sites
   *
   * @param strModule
   * @param strId
   */
  getAssetUniqueAssetTypes(strModule, arFilter): Observable<HttpResponse<Config>> {

    let body = new URLSearchParams();
    body.append('module', strModule);
    body.append('data', JSON.stringify(arFilter));

    return this.http.post<Response>(kBaseUrl+ 'get_unique_asset_types_by', body.toString(), { observe: 'response' });
  }

  /**
   * Get the record needed to generate pdf report
   *
   * @param strModule
   * @param arWhereFilter
   * @param arWhereInFilter
   */
  getPDFRecordData(strModule, strRecordId = null, arWhereFilter = {}): Observable<HttpResponse<Config>> {

    let objData = {};
    if (strRecordId) { objData['id'] = strRecordId; }
    if (arWhereFilter) { objData['where'] = arWhereFilter; }

    let body = new URLSearchParams();
    body.append('module', strModule);
    body.append('data', JSON.stringify(objData));

    return this.http.post<Response>(kBaseUrl+ 'get_pdf_data', body.toString(), { observe: 'response' });
  };

  /**
   * Get product records
   *
   * @param strTerm
   * @param arFilter
   * @param strCustomerId
   *
   * @deprecated use getProducts instead
   */
  getProductRecordData(
    strTerm: string | {} = '',
    arFilter: any = [],
    strCustomerId: string = '',
    bIsSales: boolean = true,
    strPricebookId = null,
    strIds: any = [],
    isPreferredSupplierIncluded : boolean = false,
    overrideUnitPriceWithDiscountPrice: boolean = true,
  ): Observable<Select[]> {

    let objData = {};

    if (strCustomerId) { objData['customer_id'] = strCustomerId; }
    if (strTerm) { objData['term'] = strTerm; }
    if (arFilter) { objData['filter'] = arFilter; }
    if (strPricebookId != undefined || strPricebookId != null || strPricebookId != '') { objData['pricebook_id'] = strPricebookId;}
    if (strIds.length > 0) { objData['products'] = strIds;}

    objData['is_preferred_supplier_included'] = isPreferredSupplierIncluded;
    objData['is_sales'] = bIsSales;
    objData['override_unit_price_with_discount_price'] = overrideUnitPriceWithDiscountPrice;

    let body = new URLSearchParams();
    body.append('data', JSON.stringify(objData));
    return this.http.post<Select[]>(kBaseUrl+ 'get_product_data', body.toString());
  };

  /**
   * Get data by keyword search
   *
   * @param strTerm - term to be compare
   * @param objData - data to be filtered
   */
  getFilterData(strTerm, objData): Observable<Select[]> {
    let pattern = new RegExp(`^${strTerm}\\D*`, 'i');

    // Get all data that only matched to the keyword
    objData = objData.map(
      data => {
        if (data['text'].match(pattern) !== null) {
          return data;
        }
      }
    );

    // Need to filter matched data. To make sure there's no undefined data
    let objFinalData = objData.filter( value => value !== undefined);

    return of(objFinalData).pipe(delay(500));
  }

  /**
   * Merge Record
   * @param module
   * @param primaryRecordId
   * @param dataToMerge
   * @param dataToDelete
   *
   * @returns
   */
   mergeRecord(module: string, primaryRecordId: string, dataToMerge: object = {}, dataToDelete: Array<string> = []): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', module);
    body.append('data', JSON.stringify(dataToMerge));
    body.append('delete_record_ids', JSON.stringify(dataToDelete));
    body.append('id', primaryRecordId);
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'merge', body.toString(), { observe: 'response' });
  }

  /**
   * restore multiple record
   *
   * @param strModule - request module
   * @param arData - record data
   */
  restoreMultipleRecord(strModule, arData): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('data', JSON.stringify(arData));
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'restore_multiple', body.toString(), { observe: 'response' });
  }

   /**
   * Wraps the `saveMultiple` action for the recurring jobs
   *
   * @param   {LooseObject} objData
   *
   * @returns {Observable<Object>}
   */
  generateJobs(objData: LooseObject): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();

    // Append post parameter
    body.append('data', JSON.stringify(objData));

    // Post request
    return this.http.post(`${environment.url}/recurring_jobs/generate_job`, body.toString(), { observe: 'response' }).pipe(
      tap({
        error: (objError: HttpErrorResponse) => {
          if (objError.status === 403) {
            this.notifications.notifyNotAllowed();
          }
        }
      })
    );
  }

  /**
   * Retrieved the record information of the related module
   *
   * @param {string} strModule
   * @param {string} strRecordId
   * @param {string} objOptions
   *
   * @returns {Observable<Response>}
   */
  getRelatedModuleRecord(
    strModule: string,
    strRecordId: string,
    objOptions: GetRelatedModuleRecordOptions = {}
  ): Observable<Response> {
    let objBody = new URLSearchParams();

    const bHasRelatedData = objOptions.has_related_data || false;
    const objPageData = objOptions.page_data || {};

    objBody.append('module', strModule);
    objBody.append('has_related_data', bHasRelatedData.toString());

    if (objPageData) {
      objBody.append('page', JSON.stringify(objPageData));
    }

    const otherClientId = objOptions.on_behalf_of_client;

    if (filled(otherClientId) && isId(otherClientId)) {
      objBody.append('other_client_id', otherClientId);
    }

    return this.http.post<Response>(`${environment.url}/record/related_record_data/${strRecordId}`, objBody);
  }

  /**
   * get workflow module fields
   *
   * @returns {Observable<Response>}
   */
  getFields(strModule: string): Observable<Response> {
    let body = new URLSearchParams();
    body.append('module', strModule);

    return this.http.post<Response>(environment.url + "/workflow/fields", body.toString());
  }

  /**
   * Get product records
   *
   * @param strTerm
   * @param arFilter
   * @param strCustomerId
   */
  getReorderItems(
    strTerm: string | {} = '',
    arFilter: any = []
  ): Observable<Select[]> {

    let objData = {};
    if (strTerm) { objData['term'] = strTerm; }
    if (arFilter) { objData['filter'] = arFilter; }

    let body = new URLSearchParams();
    body.append('data', JSON.stringify(objData));
    return this.http.post<Select[]>(kBaseUrl+ 'get_product_data', body.toString());
  };

  /**
   * Delete record
   *
   * @param strModule - request module
   * @param strId - record id to delete
   */
  archiveRecord(strModule, strId, relatedModule: string[] = []): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('id', strId);
    body.append('related_module', JSON.stringify(relatedModule))
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'archive', body.toString(), { observe: 'response' }).pipe(
      tap({
        error: (objErr: HttpErrorResponse) => {
          if (objErr.status === 403) {
            this.notifications.notifyNotAllowed();
          }
        }
      })
    );
  }

  /**
   * restore multiple record
   *
   * @param strModule - request module
   * @param arData - record data
   */
  unarchiveMultipleRecord(strModule, arData): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('data', JSON.stringify(arData));
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'unarchive_multiple', body.toString(), { observe: 'response' });
  }

  /**
   * get the current record data and parent record data
   */
  getRecordAndParentRecord(strModule: string, strId: string, fromParentRecord: boolean = false): Observable<[any, any]> {
    let objParentData = this.getParentData();
    return forkJoin([
      this.getRecord(strModule, strId, true, {}, 'edit_form'),
      (fromParentRecord)
        ? this.getRecord(objParentData.module, objParentData.id, true, {}, 'edit_form')
        : of ({ record_details: {} })
    ])
  }

  /**
   * get the module config for create and parent record
   */
  getConfigAndParentRecord(strModule: string, fromParentRecord: boolean = false): Observable<[any, any]> {
    let objParentData = this.getParentData();
    return forkJoin([
      this.getRecordConfig(strModule, null, true).first(),
      (fromParentRecord)
        ? this.getRecord(objParentData.module, objParentData.id, true, {}, 'edit_form')
        : of ({ record_details: {} })
    ])
  }

  /**
   * get current record url module and id
   *
   * @return {string}
   */
  getParentData(): { module: string, id: string } {
    let arUrl = this.router.url.split('/')
    arUrl.splice(0, 1);
    return {
      module: arUrl[0],
      id: (arUrl[1]) ? arUrl[1].split('?')[0] : ''
    };
  }

  /**
   * retrieve record with condition
   *
   * @param module
   * @param id
   * @param condition
   * @returns
   */
  getRecordBasedOnParent(condition: boolean = false): Observable<any> {
    let parentData = this.getParentData();
    let pattern = "[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}";
    let matched = parentData.id.match(pattern);

    return iif(() => condition && !isEmpty(parentData.id) && !isNull(matched),
      this.getRecord(parentData.module, parentData.id),
      of({ record_details: {} })
    );
  }

  getProducts(opts: {
    term?: string,
    filter?: Record<string, any>,
    customer_id?: string;
    pricebook_id?: string;
    ids?: string[];
    sales_only?: boolean
    include_preferred_supplier?: boolean;
    override_unit_price_with_discount_price?: boolean;
  }): Observable<Record<string, any>[]> {
    opts = Object.assign({
      include_preferred_supplier: false,
      override_unit_price_with_discount_price: true,
      sales_only: true,
    }, opts);

    let data: Record<string, any> = {};

    if (filled(opts.customer_id)) {
      data['customer_id'] = opts.customer_id;
    }

    if (filled(opts.term)) {
      data['term'] = opts.term;
    }

    if (filled(opts.filter)) {
      data['filter'] = opts.filter;
    }

    if (filled(opts.pricebook_id)) {
      data['pricebook_id'] = opts.pricebook_id;
    }

    if (filled(opts.ids)) {
      data['products'] = opts.ids;
    }

    data['is_preferred_supplier_included'] = opts.include_preferred_supplier;
    data['is_sales'] = opts.sales_only;
    data['override_unit_price_with_discount_price'] = opts.override_unit_price_with_discount_price;

    const params = new URLSearchParams();

    params.append('data', JSON.stringify(data));

    return this.http.post<Record<string, any>[]>(kBaseUrl + 'get_product_data', params.toString());
  }

  /**
   * Retrieve the configured default relates for the current client. Relates are only available when
   * requested via opts.includes.
   */
  getConfiguredDefaultRelates<T extends ConfiguredDefaultRelatesModules>(opts: {
    includes: T[];
  }): Observable<GetConfiguredDefaultRelatesResult<T>> {

    const payload = new URLSearchParams();

    payload.set('includes', opts.includes.join('|'));

    return this.http.post(kBaseUrl + 'configured_default_relates', payload.toString(), {
      observe: 'response',
    }).pipe(
      map((response) => get(response.body, 'item', {}) as GetConfiguredDefaultRelatesResult<T>),
    );
  }

  getAssetTypeCount(siteId: string): Observable<HttpResponse<Config>> {
    let body = new URLSearchParams();
    body.append('site_id', siteId);

    return this.http.post(`${environment.url}/recurring_jobs/get_asset_type_count`, body.toString(), { observe: 'response' });
  }
}

type ContainsOnBehalfOfClientOption = {
  /**
   * Contains the client id of the client that the current
   * authenticated client wished to work on behalf with
   */
  on_behalf_of_client?: string;
}

type GetRelatedModuleRecordOptions = {
  has_related_data?: boolean;
  page_data?: LooseObject;
} & ContainsOnBehalfOfClientOption;

type GetRecordOptions = ContainsOnBehalfOfClientOption & {
  /// tells if the current requested record details is meant for views
  /// useful where metadata are only available for views
  is_view?: boolean;

  /// tells if the current requested record details is to retrieved
  /// only the record details without the related data such as activities
  /// and other informations not relevant to the usage
  is_data_only?: boolean;
};

type GetRecordRelateOptions = ContainsOnBehalfOfClientOption;


type ConfiguredDefaultRelates = {
  default_sales_tax: TaxCodeInterface;
  default_sales_account: Record<string, any>;
  default_purchase_account: Record<string, any>;
  default_purchase_tax: TaxCodeInterface;
}

type ConfiguredDefaultRelatesModules = keyof ConfiguredDefaultRelates;

type GetConfiguredDefaultRelatesResult<T extends ConfiguredDefaultRelatesModules> = {
  [key in T]: ConfiguredDefaultRelates[T];
};