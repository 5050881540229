import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LooseObject } from '../objects/loose-object';
import { sprintf } from 'sprintf-js';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { filled } from '../shared/utils/common';
import { get } from 'lodash-es';

const APIBaseUrl: string = environment.url + "/barcode/";

@Injectable()
export class BarcodeService {

  constructor (
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
  ) {}

  /**
   * check if there is a valid default barcode
   *
   * @returns
   */
  hasBarcodeConfig(): boolean {
    let barcodeConfig = this.getCachedBarcode();
    return filled(barcodeConfig.port) && filled(barcodeConfig.design) && filled(barcodeConfig.printer);
  }

  /**
   * generate barcode
   *
   * @param variables
   * @param copy
   * @returns
   */
  generateItemImage(variables: LooseObject, copy: number = 1): Observable<LooseObject> {
    let barcodeConfig = this.getCachedBarcode();
    return this.httpClient.post<Response>(sprintf(
      'http://localhost:%s/api/v1/print?design=%s&variables=%s&printer=%sPreview&window=hide&copies=%s',
      barcodeConfig.port, barcodeConfig.design, JSON.stringify(variables), barcodeConfig.printer, copy
    ), {});
  }

  /**
   * generate barcode payload
   *
   * @param item
   * @param fields
   * @returns
   */
  generateBarcodePayload(item: LooseObject): LooseObject {
    let compiledFields: LooseObject = {};
    let barcodeConfig = this.getCachedBarcode();

    barcodeConfig.fields.forEach( field => {
      compiledFields[field.barcode_field] = get(item, field.item_field, '');
    });

    return compiledFields;
  }

  /**
   * generate multiple barcode
   *
   * @param payload
   * @returns
   */
  generateMultipleItemImage(payload: LooseObject[]) {
    let barcodeConfig = this.getCachedBarcode();
    return this.httpClient.get<any>(
      sprintf('http://localhost:%s/api/v1/batch?payload=%s', barcodeConfig.port, btoa(JSON.stringify(payload)))
    );
  }

  /**
   * generate multiple barcode payload
   *
   * @param items
   * @returns
   */
  generateMultipleBarcodePayload(selectedItems: LooseObject[], items: LooseObject[]): LooseObject[] {
    let compiledFields: LooseObject[] = [];
    let barcodeConfig = this.getCachedBarcode();
    selectedItems.forEach( selectedItem => {
      let barcodeVariable: LooseObject = {};
      let item = items.find(data => data.id == selectedItem.item.id);
      barcodeConfig.fields.forEach( field => {
        barcodeVariable[field.barcode_field] = get(item, field.item_field, '');
      });

      compiledFields.push({
        design: barcodeConfig.design,
        variables: barcodeVariable,
        window: 'hide',
        copy: selectedItem.copy,
      });
    });

    return compiledFields;
  }

  /**
   * save default barcode
   *
   * @param data
   */
  saveDefaultBarcode(data: LooseObject): Observable<HttpResponse<any>> {
    let body = new URLSearchParams();
    body.append('printer', data.printer);
    body.append('design', data.design);
    body.append('port', data.port);
    body.append('fields', JSON.stringify(data.fields));

    return this.httpClient.post<Response>(APIBaseUrl+ 'save', body.toString(), { observe: 'response' });
  }

  /**
   * retrieve default barcode
   */
  getDefaultBarcode(): Observable<any> {
    return this.httpClient.post<Response>(APIBaseUrl + 'get', {});
  }

  /**
   * retrieve cached barcode
   * @returns
   */
  getCachedBarcode(): LooseObject {
    let currentClient = this.localStorageService.getJsonItem('current_client');
    if (filled(currentClient)) {
      return typeof currentClient.default_barcode == 'string'
        ? JSON.parse(currentClient.default_barcode)
        : currentClient.default_barcode;
    }
    return {};
  }

  ping(port): Observable<HttpResponse<any>> {
    return this.httpClient.get<any>(
      sprintf('http://localhost:%s/api/v1/batch?payload=', port)
    );
  }
}