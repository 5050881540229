
    <div class="fieldmagic-error-text-containers">
      <ng-container *ngFor="let error of (errors$ | async)">
        <fieldmagic-text
          class="fieldmagic-input-error-text"
          [content]="error"
          purpose="error"
        >
        </fieldmagic-text>
      </ng-container>
    </div>
  