
<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'wrench']" ></fa-icon>&nbsp;
        <span *ngIf="strViewType == 'edit'" >{{ 'edit_job_template' | translate }} </span>
        <span *ngIf="strViewType == 'add'">{{ 'add_job_template' | translate }} </span>
    </h3>
    <ng-container *fcHasPermission="'jobs' + ((strViewType === 'add') ? ':create' : ':edit')">
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <button (click)="onSubmit()" class="btn ml-1 btn-primary" [disabled]="bSubmitted || (disabledSaveButton$ | async)">
              <fa-icon *ngIf="!bSubmitted" [icon]="['fas', 'save']"></fa-icon>
              <fa-icon *ngIf="bSubmitted" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              <span class="pl-1">{{ 'save' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <fieldmagic-primary-button
              (click)="cancelDialog()"
              variant="error"
              id="btn-close"
              label="close"
              icon="times"
              [disabled]="bSubmitted"
            ></fieldmagic-primary-button>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</header>

<hr class="separator"/>

<mat-dialog-content *ngIf="objJobTemplateForm" class="mobile-view-dialog">
  <app-error-handler onlyFor="edit_form" [isAction]="true" alignment="center" (onError)="onFormError()">
    <div *ngIf="objJobTemplateForm.length != 0" class="mt-3">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li *ngFor="let item of objJobTemplateForm" class="nav-item text-size">
          <a class="nav-link" [ngClass]="{'active': objJobTemplateForm[0]['label'] === item.label}" id="{{item.label}}-tab" data-toggle="tab" href="#{{item.id}}" role="tab" aria-selected="true" translate>{{ item.label }}</a>
        </li>
      </ul>
      <div class="tab-content">
        <div *ngFor="let item of objJobTemplateForm" [ngClass]="{'active': objJobTemplateForm[0]['label'] === item.label, 'show': objJobTemplateForm[0]['label'] === item.label}" class="tab-pane row p-4" [id]="item.id" role="tabpanel">
          <form [formGroup]="item.groups">
            <div class="row">
              <ng-container *ngFor="let data of item.fields">
                <div [ngClass]="
                {
                'col-6': data.space === 6,
                'col-12': data.space === 12
                }"
                class="mb-3">
                  <ng-container>
                    <app-edit-field [item]="data" [form]="item.groups" [bSubmitted]="bSubmitted" [strMode]="strViewType" strModule="job_templates" (parentEvent)="doSomethingInParent($event)" [strRecordId]=""></app-edit-field>
                    <ng-template *ngIf="data.key === 'task_line_items' then taskLineItems"></ng-template>
                    <ng-template *ngIf="data.key === 'material_line_items' then materialLineItems"></ng-template>
                    <ng-template *ngIf="data.key === 'work_order_line_items' then workOrderLineItems"></ng-template>
                  </ng-container>
                </div>
                <ng-template #taskLineItems>
                  <ng-container>
                    <div class="row">
                      <table class="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th scope="col" width="20%" class="font-12">{{ 'task_description' | translate }}</th>
                            <th scope="col" width="20%" class="font-12">{{ 'estimated_duration' | translate }}</th>
                            <th *ngIf="bDepartmentTracking" scope="col" width="20%" class="font-12">{{ 'department' | translate }}</th>
                            <th scope="col" width="20%" class="font-12">{{ 'technician' | translate }}</th>
                            <th scope="col" width="19%" class="font-12">{{ 'checklist' | translate }}</th>
                            <th scope="col" width="1%"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="arTaskLineAttributes.length < 1">
                            <td colspan="7" class="text-center">
                              <span>{{ 'no_available_items' | translate }}</span>
                            </td>
                          </tr>
                          <ng-container *ngFor="let objTaskAttr of arTaskLineAttributes; index as i">
                            <tr>
                              <td>
                                <div class="p-2">
                                  <textarea
                                  class="form-control font-12 task-desc-{{i}} h38"
                                  [ngModelOptions]="{standalone: true}"
                                  [(ngModel)]="objTaskAttr.task_description"
                                  (focus)="expandInput($event.target)"
                                  (focusout)="shrinkInput($event.target)"
                                  #taskDesc>
                                  </textarea>
                                </div>
                              </td>
                                <td scope="row">
                                  <div class="p-2">
                                    <fieldmagic-decimal-input
                                      [ngModelOptions]="{standalone: true}"
                                      [(ngModel)]="objTaskAttr.estimated_duration"
                                      customClass="form-control font-size-12 input-height-40"
                                    ></fieldmagic-decimal-input>
                                  </div>
                                </td>
                                <td *ngIf="bDepartmentTracking">
                                  <div class="p-2">
                                    <ng-select
                                      [items]="objTaskAttr.department.source | async"
                                      [typeahead]="objTaskAttr.department.typehead"
                                      [loading]="objTaskAttr.department.loader"
                                      [clearable]="true"
                                      [ngModelOptions]="{standalone: true}"
                                      bindLabel="department_name"
                                      [(ngModel)]="objTaskAttr.department.value"
                                      appendTo="body"
                                      (open)="objTaskAttr.department.loadDataOnClick('')">
                                    </ng-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="p-2">
                                    <ng-select
                                      [items]="objTaskAttr.user.source | async"
                                      [typeahead]="objTaskAttr.user.typehead"
                                      [loading]="objTaskAttr.user.loader"
                                      [clearable]="true"
                                      [ngModelOptions]="{standalone: true}"
                                      bindLabel="name"
                                      [(ngModel)]="objTaskAttr.user.value"
                                      appendTo="body"
                                      (open)="objTaskAttr.user.loadDataOnClick('')">
                                    </ng-select>
                                  </div>
                                </td>
                                <td scope="row">
                                  <div>
                                    <ng-select
                                      [items]="objTaskAttr.checklist.source | async"
                                      [typeahead]="objTaskAttr.checklist.typehead"
                                      [loading]="objTaskAttr.checklist.loader"
                                      [clearable]="false"
                                      [closeOnSelect]="false"
                                      bindLabel="name"
                                      [ngModelOptions]="{standalone: true}"
                                      [(ngModel)]="objTaskAttr.checklist.value"
                                      [maxSelectedItems]="10"
                                      [multiple]="true"
                                      appendTo="body"
                                      (open)="objTaskAttr.checklist.loadDataOnClick('')">
                                    </ng-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="p-2 text-center">
                                    <fa-icon [ngClass]="{'text-secondary': arTaskLineAttributes.length < 2, 'text-danger': arTaskLineAttributes.length > 1}" class="cursor-pointer font-12" (click)="(arTaskLineAttributes.length > 1) ? removeTaskLineItem(i) : ''" [icon]="['fal', 'minus-circle']"></fa-icon>
                                    <fa-icon class="text-success cursor-pointer font-12" (click)="addTaskLineItem(i)" [icon]="['fas', 'plus-circle']"></fa-icon>
                                  </div>
                                </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template #materialLineItems>
                  <material-lines
                    [initialValue]="materialsInitialValue$ | async"
                    (updated)="onMaterialsUpdate($event)"
                  >
                  </material-lines>
                </ng-template>
                <ng-template #workOrderLineItems>
                  <app-jobs-work-order-items
                    class="row"
                    [asChild]="true"
                    [defaultTaxCode]="defaultTaxCode$ | async"
                    [initialValue]="workOrderInitialValue$ | async"
                    [defaultPricebook]="defaultPricebook$ | async"
                    (updated)="onWorkOrdersUpdate($event)"
                    [parentWasValidated]="wasValidated$ | async"
                  >
                  </app-jobs-work-order-items>
                </ng-template>
              </ng-container>
            </div>
          </form>
        </div>
      </div>
    </div>
  </app-error-handler>
</mat-dialog-content>

