import { PipeTransform, Pipe } from '@angular/core';
import { filled } from '../shared/utils/common';

@Pipe({
  name: 'filled',
  pure: false,
})
export class FilledPipe implements PipeTransform {
  transform = <T = any>(value: T): boolean => filled<T>(value);
}
