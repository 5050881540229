
    <ng-container
      *ngIf="! standalone; else input;"
    >
      <div class="form-group">
        <div class="d-flex d-flex-gap">
          <fieldmagic-text
            purpose="input"
            [content]="label"
            [withRequiredMarker]="withRequiredMarker"
          >
          </fieldmagic-text>

          <fieldmagic-icon
            *ngIf="tooltip | filled"
            icon="info-circle"
            [tooltip]="tooltip"
          >
          </fieldmagic-icon>
        </div>

        <ng-container *ngTemplateOutlet="input"></ng-container>
      </div>

    </ng-container>

    <ng-template #input>
      <div class="input-group">
        <input
          [attr.type]="inputType"
          class="form-control input-height-40 font-size-12"
          [class.fieldmagic-input-has-error]="errors | filled"
          [ngClass]="customClass"
          maxlength="{{ maxlength }}"
          placeholder="{{ placeholder | translate }}"
          [(ngModel)]="value"
          [class.isinvalid]="! isValid"
          (change)="onChange($event.target.value)"
          (keypress)="onKeyPressInput($event)"
          [disabled]="isDisabled$ | async"
        />
        <div
          *ngIf="obsecure"
          class="input-group-append"
          (click)="onShowObsecure()"
          [matTooltip]="(showObsecure$ | async) ? 'Hide' : 'Show'"
        >
          <fieldmagic-icon
            class="input-group-text"
            [icon]="(showObsecure$ | async) ? 'eye-slash' : 'eye'"
            purpose="clicking"
          >
          </fieldmagic-icon>
        </div>
      </div>

      <fieldmagic-input-errors
        *ngIf="errors | filled"
        [errors]="errors"
      >
      </fieldmagic-input-errors>
    </ng-template>
  