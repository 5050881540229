import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {isArray, isString} from 'lodash-es';
import { fallback } from '../../utils/common';

@Component({
  selector: 'fieldmagic-icon',
  template: `
    <div
      class="d-flex align-items-center"
      [class.justify-content-center]="position == 'center'"
      [class.justify-content-start]="position == 'left'"
      [class.justify-content-end]="position == 'right'"
      [class.flex-row-reverse]="position == 'right'"
      [class.d-flex-gap]="! (stacked$ | async)"
      [class.pointer]="purpose == 'clicking'"
      [class.grabbing]="purpose == 'dragging'"
    >
      <ng-container
        *ngIf="spin"
        [ngTemplateOutlet]="iconTemplate"
        [ngTemplateOutletContext]="{
          icon: ['fas', 'spinner'],
          spin: spin,
          klass: customClass,
          size: size,
          stacked: false
        }"
      >
      </ng-container>
      <span
        *ngIf="!spin"
        [class.fa-sm]="size == 'xs'"
        [class.fa-lg]="size == 'sm'"
        [class.fa-2x]="size == 'lg'"
        [class.fa-4x]="size == 'xl'"
        [class.fa-stack]="stacked$ | async"
      >
        <ng-container
          *ngFor="let icon of icons$ | async"
          [ngTemplateOutlet]="iconTemplate"
          [ngTemplateOutletContext]="{
            spin: false,
            icon: icon.icon,
            klass: icon.klass,
            size: icon.size,
            stacked: stacked$ | async
          }"
        >
        </ng-container>
      </span>
      <span *ngIf="label | filled; else content">
        {{ label | translate }}
      </span>
      <ng-template #content>
        <ng-content></ng-content>
      </ng-template>
      <ng-template
        #iconTemplate
        let-icon="icon"
        let-spin="spin"
        let-klass="klass"
        let-size="size"
        let-stacked="stacked"
      >
        <fa-icon
          [icon]="icon"
          [spin]="spin"
          [class]="klass"
          [matTooltip]="tooltip"
          [class.fa-stack-1x]="size != 'xl' && stacked"
          [class.fa-stack-2x]="size == 'xl' && stacked"
          [class.fa-xs]="size == 'xs'"
          [class.fa-sm]="size == 'sm'"
          [class.fa-1x]="size == 'lg'"
          [class.fa-2x]="size == 'xl'"
        ></fa-icon>
      </ng-template>
    </div>
  `,
})
export class IconComponent {
  @Input()
  set icon(value: FieldmagicIconProp) {
    value = fallback(value, {
      fallback: () => [],
    });

    let icons: IconProp[] = [];

    if (!isArray(value)) {
      icons.push(value);
    } else {
      icons = value;
    }

    const compiled: CompiledIcon[] = [];

    for (const icon of icons) {
      /// backward compatibility
      if (isString(icon)) {
        compiled.push({
          icon: [this._transformKnownVariant(this.variant), icon],
          size: this.size,
          klass: this.customClass,
        });

        continue;
      }

      compiled.push({
        icon: [
          this._transformKnownVariant(icon.variant || DEFAULT_ICON_VARIANT),
          icon.icon,
        ],
        klass: icon.klass,
        size: icon.size || this.size || DEFAULT_ICON_SIZE,
      });
    }

    /// reversing the stack to make sure that first icon would be in the top
    /// if the the icons where stacked
    this.icons$.next(compiled.reverse());
    this.stacked$.next(compiled.length > 1);
  }

  @Input() variant: IconVariant = DEFAULT_ICON_VARIANT;

  @Input() spin: boolean = false;

  @Input() position: 'left' | 'right' | 'center' = 'left';

  @Input() tooltip: string;

  @Input() customClass: string = '';

  @Input() label: string;

  @Input() size: IconSize = DEFAULT_ICON_SIZE;

  @Input() purpose: IconPurpose;

  readonly icons$ = new BehaviorSubject<CompiledIcon[]>([]);

  readonly stacked$ = new BehaviorSubject<boolean>(false);

  private _transformKnownVariant(variant: IconVariant): string {
    if (variant == 'regular') {
      return 'far';
    }

    if (variant == 'light') {
      return 'fal';
    }

    return 'fas';
  }
}

const DEFAULT_ICON_VARIANT = 'solid';
export const DEFAULT_ICON_SIZE = 'md';

type IconVariant = 'solid' | 'light' | 'regular';
type IconSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs';
type SimpleIcon = string;
type CustomizedIcon = {
  icon: string;
  variant?: IconVariant;
  /// custom class will be similar how angular class accepts the class attribute
  /// it can be a string, an array or anything that follows the angular class attribute type
  klass?: any;
  size?: IconSize;
};
type IconProp = SimpleIcon | CustomizedIcon;
type CompiledIcon = {
  icon: [string, string];
  size: IconSize;
  klass?: any;
};
type IconPurpose = 'clicking' | 'dragging';

export type FieldmagicCustomizedIconProp = CustomizedIcon;
export type FieldmagicIconProp = IconProp | IconProp[];
export type FieldmagicIconSizeProp = IconSize;
