<table class="table table-bordered main-table">
  <tbody>
    <tr>
      <td scope="row" style="padding-bottom: 0px; padding-top: 0px;">
        <div class="row pl-3 pr-3 pt-1 pb-1" [ngClass]="{'p-3': bShowFilter}">
          <div class="col-lg-3 pt-1 pb-3">
            <div class="row">
              <div class="col-12">
                <div class="d-flex justify-content-start listing-font-size mt-2">
                  <ng-container *ngIf="strModule == 'item_folders'">
                    <fm-listing-add
                      strModule="items"
                      strAddLabel="add_item"
                      (afterCreateEvent)="childEvent($event)">
                    </fm-listing-add>
                  </ng-container>

                  <fm-listing-add
                    [hidden]="!bShowAdd"
                    [strModule]="strAddModule ?  strAddModule : strModule"
                    [strAddLabel]="strAddLabel"
                    (afterCreateEvent)="childEvent($event)">
                  </fm-listing-add>

                  <fm-listing-sort
                    *ngIf="bShowSort"
                    [arSortOption]="arSortOption"
                    [arModel]="arModel"
                    (sortEvent)="childEvent($event)">
                  </fm-listing-sort>

                  <fm-listing-filter
                    *ngIf="bShowFilter"
                    [strModule]="strModule"
                    [arSavedFilters]="arSavedFilters"
                    (onFilterEvent)="filterEvents($event)"
                    [arModel]="arModel">
                  </fm-listing-filter>

                  <ng-container *ngIf="customListActionTemplate"
                    [ngTemplateOutlet]="customListActionTemplate">
                  </ng-container>

                  <ng-container>
                    <span class="nowrap">
                      <span class="span-link select-all" (click)="ngOnChangeCheckbox($event, {}, true)">
                        <fa-icon class="pr-1" [icon]="['fal', (isSelectAll) ? 'check-square' : 'square']" aria-hidden="true"></fa-icon>
                        <span class="filter-icon">{{ 'select_all' | translate }}</span>
                      </span>
                    </span>
                  </ng-container>

                </div>
              </div>
              <div *ngIf="!bShowCustomList" class="col-12 pt-3">
                <fm-list-pagination
                  bPageShowSummary="true"
                  [objPagination]="objPagination">
                </fm-list-pagination>
              </div>
            </div>
          </div>

          <div class="col-lg mt-2">
            <advanced-searchbox
              style="margin-top: -7px;"
              (onChange)="triggerFilter($event)"
              *ngIf='bShowFilter && objAdvancedSearchbox.template'
              [placeholder]="'search'"
              [openOnLoad]="false"
              [template]="objAdvancedSearchbox.template"
              [model]="objAdvancedSearchbox.model"
              [form]="objAdvancedSearchbox.form">
            </advanced-searchbox>

            <span class="select-all-records" *ngIf="!listService.bLoading && isSelectAll">All records on this page are selected. <a class="primary-field" (click)="triggerSelectTotalRecords($event, isTotalRecordSelected)"> {{ isTotalRecordSelected ? 'Clear Selection' : "Select all " + objPagination.total_records + " records"}}</a></span>
          </div>
          <div class="col-xl-3 mt-2">
            <div class="row">
              <div class="col-12">
                <div class="d-flex justify-content-end">

                  <ng-container *ngIf="bShowFilter">
                    <button (click)="filterRecord('go')" class="btn btn-primary mr-2 extras-button-padding">
                      <fa-icon [icon]="['fas', 'search']"></fa-icon>
                    </button>
                    <button (click)="saveCurrentFilter()" class="btn btn-success mr-2 extras-button-padding">
                      <fa-icon [icon]="['fas', 'save']"></fa-icon>
                    </button>
                    <button (click)="clearAllFilters()" class="btn btn-danger mr-2 extras-button-padding">
                      <fa-icon [icon]="['fas', 'times']"></fa-icon>
                    </button>
                    <tag-options [module]="strModule" [records]="arSelectedRecords" (onTaggedSuccess)="triggerFilter()"></tag-options>
                  </ng-container>

                  <div class="dropdown">
                    <button class="btn btn-secondary extras-button-padding"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                    </button>

                    <div class="dropdown-menu dropdown-menu-right extra-action-dropdown" aria-labelledby="dropdownMenuButton">
                      <span *ngIf="bAllowedMerge" matTooltip="{{ 'merge_info' | translate }}"
                        class="dropdown-item pointer"
                        (click)="openMergeDialog()">
                          <fa-icon [icon]="['fas', 'compress-alt']"></fa-icon> {{ 'merge' | translate }}
                      </span>

                      <ng-container *ngIf="bOptionBarcode">
                        <div *fcAccessLevelRequired="'admin'">
                          <fieldmagic-barcode-option
                            [selectedRecords]="arSelectedRecords"
                          ></fieldmagic-barcode-option>
                        </div>
                      </ng-container>

                      <div *fcAccessLevelRequired="'admin'" class="dropdown-item">
                        <fieldmagic-rebuild-search-database-option
                          [moduleName]="strModule"
                        ></fieldmagic-rebuild-search-database-option>
                      </div>

                      <ng-container *ngIf="isModuleAllowedBulk(strModule, excludedBulkUpdateModule)">
                        <div *fcAccessLevelRequired="'admin'" class="dropdown-item">
                          <fieldmagic-update-button
                            [moduleName]="strModule"
                            [selectedRecords]="arSelectedRecords"
                            [isTotalRecordSelected]="isTotalRecordSelected"
                            [filterModel]="objAdvancedSearchbox.model"
                            [totalRecords]="objPagination.total_records"
                            (bulkUpdateEvent)="bulkUpdateEvent($event)"
                          ></fieldmagic-update-button>
                       </div>
                      </ng-container>

                      <ng-container *ngIf="isModuleAllowedBulk(strModule, excludedBulkDeleteModule)">
                        <div *fcAccessLevelRequired="'admin'" class="dropdown-item">
                          <fieldmagic-icon
                            id="btnMultipleDelete"
                            icon="trash-alt"
                            (click)="deleteMultipleRecord()"
                          >
                            <span>{{ 'bulk_delete' | translate }}</span>
                          </fieldmagic-icon>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="customListExtraActionDropdownTemplate"
                        [ngTemplateOutlet]="customListExtraActionDropdownTemplate"
                        [ngTemplateOutletContext]="{ list: this }">
                      </ng-container>
                    </div>
                  </div>

                  <ng-container *ngIf="customListExtraActionTemplate" [ngTemplateOutlet]="customListExtraActionTemplate"></ng-container>
                </div>
              </div>
              <div *ngIf="!bShowCustomList" class="col-12 mt-3 mb-1 pr-2">
                <fm-list-pagination
                  (pageEvent)="childEvent($event)"
                  bPageShowButtons="true"
                  [bListLoading]="listService.bLoading"
                  [objPagination]="objPagination"
                  [eventPageConfig]="eventPageConfig">
                </fm-list-pagination>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>

    <ng-container *ngIf="bShowCustomList && customListTemplate; else defaultListTemplate"
      [ngTemplateOutlet]="customListTemplate">
    </ng-container>

    <ng-template #defaultListTemplate>

      <tr *ngIf="listService.bLoading">
        <td scope="row">
          <div class="d-flex justify-content-center">
            <fa-icon [icon]="['fas', 'spinner']" class="loading-spinner" [spin]="true"></fa-icon>
          </div>
        </td>
      </tr>

      <tr *ngIf="listService.bNoResult && !listService.bLoading">
        <td scope="row">
          <div class="d-flex justify-content-center text-secondary">
            <fa-icon [icon]="['far', 'exclamation-circle']"></fa-icon>&nbsp;{{ 'result_empty' | translate }}
          </div>
        </td>
      </tr>

      <table class="table table-borderless child-table" style="margin-bottom: 0px;">
        <tr class="datarow" *ngFor="let rowData of arContent; let recordIndex = index" [id]="'recordDetails' + recordIndex">

          <ng-container *ngIf="!listService.bLoading">
            <td *ngIf="bAllowedMerge || bAllowCheckboxSelection" class="list-item-outline {{ useFieldWidth }}" style="width: 5%">
              <input [attr.id]="'recordCheckbox' + recordIndex"
                type="checkbox"
                [checked]="isRecordSelected(rowData)"
                [name]="rowData['is_checked']"
                [disabled]="isDisabled(rowData)"
                (change)="ngOnChangeCheckbox($event, rowData)">
            </td>

            <ng-container *ngIf="!bViewLink">
              <td *ngFor="let strRowDisplay of arRowDisplay"
                class="list-item-outline {{ useFieldWidth }}"
                (click)="redirectToRecordView(rowData.id, recordIndex)"
                [ngClass]="{
                  'status-field-style' : checkStatusField(strRowDisplay),
                  'short-field-style' : checkShortDisplayField(strRowDisplay),
                  'long-field-style' : checkLongDisplayField(strRowDisplay),
                  'phone-field-style' : strRowDisplay == 'phone'
                }">

                <app-list id="record{{recordIndex}}"
                  [rowType]="arContentType"
                  [rowData]="rowData"
                  [rowToDisplay]="strRowDisplay"
                  [strRecordUrl]="generateRecordUrl(rowData.id, recordIndex)"
                  [currentModule]="strModule"
                  [legends]="objLegends">
                </app-list>
              </td>
            </ng-container>

            <ng-container *ngIf="bViewLink">
              <td (click)="goToEdit(rowData.id, rowData)" *ngFor="let strRowDisplay of arRowDisplay"
                class="list-item-outline {{ useFieldWidth }}"
                [ngClass]="{
                  'status-field-style' : checkStatusField(strRowDisplay),
                  'phone-field-style' : strRowDisplay == 'phone'
                  }">

                <app-list
                  [rowType]="arContentType"
                  [rowData]="rowData"
                  [rowToDisplay]="strRowDisplay"
                  [currentModule]="strModule"
                  [legends]="objLegends">
                </app-list>
              </td>
            </ng-container>

            <td class="pl-0 pr-0" style="width: 9%;">
              <tag-list *ngIf="objTagConfig.show(strModule)" [record]="rowData"></tag-list>
            </td>

            <td class="text-right">

              <div class="d-flex justify-content-end buttons-container-view">
                <list-actions
                  [hidden]="bNoAction"
                  [rowData]="rowData"
                  [bAllowedDelete]="bAllowedDelete"
                  [strModule]="strModule"
                  (actionsEvent)="childEvent($event)">
                </list-actions>
                <ng-container *ngIf="customListItemActionTemplate"
                  [ngTemplateOutlet]="customListItemActionTemplate"
                  [ngTemplateOutletContext]="{ item: rowData, fns: { refreshList: this.forceComponentChanges } }">
                </ng-container>
              </div>
            </td>

          </ng-container>
        </tr>
      </table>
    </ng-template>

    <tr *ngIf="!bShowCustomList">
      <td scope="row" style="border-top: 0px;">
        <div class="row pl-3 pr-3 pt-2 pb-2">
          <div class="col-6 p-1 pl-2">
            <fm-list-pagination
              bPageShowSummary="true"
              [objPagination]="objPagination">
            </fm-list-pagination>
          </div>
          <div class="col-6 p-1">
            <fm-list-pagination
              (pageEvent)="childEvent($event)"
              bPageShowButtons="true"
              [bListLoading]="listService.bLoading"
              [objPagination]="objPagination"
              [eventPageConfig]="eventPageConfig">
            </fm-list-pagination>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>