import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '../../../../../services/dialog.service';
import { AuditTrailModuleName, SharedAuditTrailService } from '../../../services/shared-audit-trail.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject, defer, Subscription } from 'rxjs';
import { PaginationStore } from '../../../../../shared/store/pagination.store';
import { distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { FieldmagicTableHeader } from '../../../../../shared/components/table/table.component';
import moment from 'moment';
import { RecordHistory } from '../../../types';
import { NotificationService } from '../../../../../services/notification.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Form } from '../../../../../base/form';
import { isNil, isNull } from 'lodash-es';
import { data_get, fallback, transform } from '../../../../../shared/utils/common';

@Component({
  selector: 'audit-trail-record-history-button',
  template: `
    <fieldmagic-primary-button
      icon="history"
      label="View History"
      (click)="onClick()"
    >
    </fieldmagic-primary-button>
  `
})
export class ViewRecordChangeHistoryButtonComponent {
  @Input() moduleId: string;

  @Input() moduleName: AuditTrailModuleName;

  constructor(
    private readonly _dialog: DialogService,
  ) {}

  onClick = (): void => this._dialog.show<ViewRecordChangeHistoryDialogComponent, ViewRecordChangeHistoryDialogComponentProps>({
    component: ViewRecordChangeHistoryDialogComponent,
    data: {
      id: this.moduleId,
      moduleName: this.moduleName
    },
    size: 'md',
  });
}

@Component({
  selector: 'audit-trail-record-history-dialog',
  template: `
    <fieldmagic-dialog>
      <div class="dialog-header-content">
        <fieldmagic-dialog-title
          icon="history"
          label="Change History"
        >
        </fieldmagic-dialog-title>
      </div>

      <div class="dialog-header-buttons">
        <fieldmagic-dialog-close-button
          (click)="onDismiss()"
        ></fieldmagic-dialog-close-button>
      </div>

      <div class="dialog-content">
        <fieldmagic-pagination-links>
          <ng-template fieldmagicPaginationLinksTopContent>
            <div class="d-flex w-100 d-flex-gap align-items-center">
              <fieldmagic-text
                content="{{ 'Range' | translate }}"
              >
              </fieldmagic-text>

              <input-date
                class="w-100"
                [objForm]="form"
                [objItem]="inputDateField"
                [strMode]="add"
                strModule="audit"
                (sendToParent)="onFilter()"
              >
              </input-date>
            </div>
          </ng-template>

          <fieldmagic-table
            [headers]="headers$ | async"
            [loading]="fetching$ | async"
            [data]="items$ | async"
          >
            <ng-template
              fieldmagicTableRow
              let-item="item"
            >
              <tr>
                <td
                  fieldmagicTableRowColumn
                >
                  <fieldmagic-text
                    alignment="center"
                    *ngIf="item.modified_by | filled"
                    content="{{ 'Update' | translate }}"
                  >
                  </fieldmagic-text>
                  <fieldmagic-text
                    alignment="center"
                    *ngIf="(item.created_by | filled) && (item.modified_by | blank)"
                    content="{{ 'Create' | translate }}"
                  >
                  </fieldmagic-text>
                </td>
                <td
                  fieldmagicTableRowColumn
                >
                  <fieldmagic-text
                    alignment="center"
                    content="{{ item.logged_at | format: 'lll' }}"
                  >
                  </fieldmagic-text>
                </td>
                <td
                  fieldmagicTableRowColumn
                >
                  <fieldmagic-text
                    *ngIf="item.author_name | filled; else unknownUser"
                    alignment="center"
                    content="{{ item.author_name }}"
                  >
                  </fieldmagic-text>
                  <ng-template #unknownUser>
                   <fieldmagic-text
                      alignment="center"
                      content="{{ 'Unknown Author' | translate }}"
                      matTooltip="{{ 'Author might have been removed from the system' | translate }}"
                    >
                    </fieldmagic-text>
                  </ng-template>
                </td>
                <td
                  fieldmagicTableRowColumn
                >
                  <fieldmagic-icon
                    icon="eye"
                    position="center"
                    purpose="clicking"
                    matTooltip="View"
                    (click)="onPreview(item)"
                  >
                  </fieldmagic-icon>
                </td>
              </tr>
            </ng-template>
          </fieldmagic-table>
        </fieldmagic-pagination-links>
      </div>
    </fieldmagic-dialog>
  `,
  providers: [
    PaginationStore,
  ]
})
export class ViewRecordChangeHistoryDialogComponent implements OnInit, OnDestroy {
  readonly form = new FormGroup({
    range: new FormControl({
      start_date:  DEFAULT_AUDIT_TRAIL_START_DATE,
      end_date:  DEFAULT_AUDIT_TRAIL_END_DATE,
    }),
  });

  readonly inputDateField = new Form({
    controlType: 'daterange',
    key: 'range',
  });

  readonly headers$ = new BehaviorSubject<FieldmagicTableHeader[]>([
    {
      label: 'Type',
      size: 'md',
      alignment: 'center',
    },
    {
      label: 'Happened On',
      size: 'lg',
      alignment: 'center',
    },
    {
      label: 'Author',
      size: 'lg',
      alignment: 'center',
    },
    {
      size: 'xs',
    }
  ]);

  readonly items$ = this._pagination.list$;

  readonly fetching$ = this._pagination.list$.pipe(
    map((data) => isNull(data)),
    startWith(() => false),
  );

  private _listSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly _props: ViewRecordChangeHistoryDialogComponentProps,
    private readonly _sharedAudit: SharedAuditTrailService,
    private readonly _pagination: PaginationStore<RecordHistory, number>,
    private readonly _dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this._listSubscription = this._pagination.onNextPage$.pipe(
      tap(() => this._pagination.loading()),
      switchMap((page) => this._sharedAudit.fetchList(this._props.moduleName, this._props.id, {
        from: fallback(
          transform(data_get(this.form.get('range').value, 'start_date'), {
            transformer: (value: string) => moment(value).toDate(),
          }),
          {
            fallback: () => DEFAULT_AUDIT_TRAIL_START_DATE,
          }
        ),
        to: fallback(
          transform(data_get(this.form.get('range').value, 'end_date'), {
            transformer: (value: string) => moment(value).toDate(),
          }),
          {
            fallback: () => DEFAULT_AUDIT_TRAIL_END_DATE,
          }
        ),
        page: page,
      })),
    ).subscribe((response) => this._pagination.setCurrent(response));
  }

  ngOnDestroy(): void {
    if (! isNil(this._listSubscription)) {
      this._listSubscription.unsubscribe();
    }
  }

  onDismiss = (): void => this._dialog.close({
    instance: this,
  });

  onPreview = (record: RecordHistory): void => this._dialog.show<PreviewRecordHistoryDialogComponent, PreviewRecordHistoryDialogComponentProps>({
    component: PreviewRecordHistoryDialogComponent,
    size: 'lg',
    data: {
      id: this._props.id,
      moduleName: this._props.moduleName,
      history: record,
    },
    dismissable: false,
  });

  onFilter = () => this._pagination.refresh();
}

@Component({
  template: `
    <fieldmagic-dialog>
      <div class="dialog-header-content">
        <fieldmagic-dialog-title
          icon="history"
          label="History ({{ loggedAt$ | async | format: 'lll' }})"
        >
        </fieldmagic-dialog-title>
      </div>

      <div class="dialog-header-buttons">
        <fieldmagic-primary-button
          label="Restore"
          icon="history"
          (click)="onRestore()"
          [disabled]="(fetching$ | async) || (restoring$ | async)"
        >
        </fieldmagic-primary-button>
        <fieldmagic-dialog-close-button
          (click)="onDismiss()"
          [disabled]="restoring$ | async"
        >
        </fieldmagic-dialog-close-button>
      </div>

      <div class="dialog-content">
        <fieldmagic-async-content
          [loading]="fetching$ | async"
        >
          <ng-template
            fieldmagicAsyncLoadedContent
          >
            <app-viewform [objRecordDetails]="details"></app-viewform>
          </ng-template>
        </fieldmagic-async-content>
      </div>
    </fieldmagic-dialog>
  `
})
export class PreviewRecordHistoryDialogComponent implements OnInit {
  readonly loggedAt$ = new BehaviorSubject<string>(null);

  readonly fetching$ = new BehaviorSubject<boolean>(false);

  readonly restoring$ = new BehaviorSubject<boolean>(false);

  details: Record<string, any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly _props: PreviewRecordHistoryDialogComponentProps,
    private readonly _dialog: DialogService,
    private readonly _sharedAudit: SharedAuditTrailService,
    private readonly _notifications: NotificationService,
  ) {}

  ngOnInit(): void {
    this.loggedAt$.next(this._props.history.logged_at);

    defer(() => {
      this.fetching$.next(true);

      return this._sharedAudit.preview(
        this._props.moduleName,
        this._props.history,
      );
    }).pipe(
      finalize(() => this.fetching$.next(false))
    ).subscribe((response) => this.details = {
      data_only: true,
      module: this._props.moduleName,
      id: this._props.id,
      label_id: 'name',
      metadata: {
        available_fields: response.available_fields,
        used_fields: response.used_fields,
        record_details: response.data,
        record_view: response.view,
      },
    });
  }

  onDismiss = (): void => this._dialog.close({
    instance: this,
  });

  onRestore(): void {
    this._notifications.sendConfirmation('Are you sure you want to restore the record from this point?')
      .pipe(
        filter(({ answer }) => !! answer),
        tap(() => this.restoring$.next(true)),
        switchMap((_) => this._sharedAudit.restore(this._props.moduleName, this._props.history).pipe(
          finalize(() => this.restoring$.next(false)),
        ))
      )
      .subscribe((_) => this.onDismiss());
  }
}

type ViewRecordChangeHistoryDialogComponentProps = {
  /// the module record unique id
  id: string;
  /// the name of the current module
  moduleName: AuditTrailModuleName;
}

type PreviewRecordHistoryDialogComponentProps = {
  /// the module record unique id
  id: string;
  /// the name of the current module
  moduleName: string;
  /// the current selected record history
  history: RecordHistory;
}

const DEFAULT_AUDIT_TRAIL_START_DATE = moment(new Date).startOf('M').endOf('D').toDate();
const DEFAULT_AUDIT_TRAIL_END_DATE = moment(new Date).endOf('M').endOf('D').toDate();