import Pusher from 'pusher-js';
import { Channel } from 'pusher-js';

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { LooseObject } from '../objects/loose-object';
import { filled } from '../shared/utils/common';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { isNil } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class PusherService {

  recordViewPresence: Channel = null;
  recordEditPresence: Channel = null;

  /**
   * The current user's private pusher channel
   * where we subscribe to notifications event.
   *
   * @var {any}
   */
  private privatePusher: Channel;

  private presencePusher: Pusher;

  constructor(
    private localStorage: LocalStorageService,
    private readonly _notifications: NotificationService,
  ) { }

  initPresencePusher(): void {
    let strUserId = this.localStorage.getItem('user_id');
    let strUserName = this.localStorage.getItem('user_name');
    let strAccessToken = this.localStorage.getItem('access_token');

    this.presencePusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      authEndpoint: environment.pusher.auth_endpoint + 'presence',
      auth: {
        params: {
          token: strAccessToken,
          user_id: strUserId,
          display_name: strUserName
        },
      }
    });
  }

  disconnectPresencePusher(): void {
    if (filled(this.presencePusher)) {
      this.presencePusher.disconnect();
      this.presencePusher = null;
    }
  }

  ngOnDestroy() {
    this.disconnectPresencePusher();
  }

  /**
   * Retrieves the pusher instance of this service.
   *
   * @returns {any}
   */
  getPrivatePusherInstance(): Channel {
    return this.privatePusher;
  }

  /**
   * Create a pusher subscription to listen
   * for events.
   *
   * @param {string} strThreadId
   *
   * @return {any}
   *  - Can't typecast it cause Pusher's subscription is
   *    a tad bit different compared to RxJS's subscription.
   */
  getPusherPublic(strThreadId: string): Channel {
    let pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster
    });
    return pusher.subscribe(strThreadId + '-channel');
  }

  /**
   * Create a pusher subscription to listen
   * for events.
   *
   * @param {string} strThreadId
   *
   * @return {Channel}
   *  - Can't typecast it cause Pusher's subscription is
   *    a tad bit different compared to RxJS's subscription.
   */
  getPusherPresence(strThreadId: string): Channel {
    if (filled(this.presencePusher) === false) {
      this.initPresencePusher();
    }
    return this.presencePusher.subscribe('presence-' + strThreadId + '-channel');
  }

  /**
   * Create a pusher subscription to listen
   * for events.
   *
   * @param {string} strThreadId
   *
   * @return {Channel}
   */
  getPusherPrivate(strUserId: string): Channel {
    let strAccessToken = this.localStorage.getItem('access_token');

    let pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      authEndpoint: environment.pusher.auth_endpoint + 'private',
      auth: {
        params: {
          token: strAccessToken
        }
      }
    });
    return pusher.subscribe('private-' + strUserId + '-channel');
  }

  /**
   * Preparing attributes for displaying presence on record view
   *
   * @param   {string}           userId
   * @param   {LooseObject}      userDetails
   *
   * @return  {PresenceDetails}
   */
  prepareMemberDetails(userId: string, userDetails: LooseObject): PresenceDetails {
    return {
      id: userId,
      name: userDetails['name'],
      color: this.stringToColor(userDetails['name']),
      initials: userDetails['name']
        .split(' ')
        .map(part => part.charAt(0))
        .join('')
        .substring(0, 2)
        .toUpperCase(),
      icon: 'eye',
    };
  }

  /**
   * Useful for making a unique color based on the provided string
   *
   * @param   {string}  str
   *
   * @return  {string}
   */
  stringToColor(str: string): string {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = '#' + ((hash >> 24) & 0xFF).toString(16).padStart(2, '0') +
      ((hash >> 16) & 0xFF).toString(16).padStart(2, '0') +
      ((hash >> 8) & 0xFF).toString(16).padStart(2, '0');
    return color.slice(0, 7);
  }

  /**
   * Get all the members joined on a specific channel (Excluding the current user)
   *
   * @param   {Channel[]}          pusherChannel
   *
   * @return  {PresenceDetails[]}
   */
  getMembersExcludingCurrentUser(pusherChannel: Channel): PresenceDetails[] {
    const members = pusherChannel['members'].members;
    let membersExcludingYou: PresenceDetails[] = [];
    for (let userId in members) {
      if (userId === pusherChannel['members'].myID) {
        continue;
      }
      membersExcludingYou.push(this.prepareMemberDetails(userId, members[userId]));
    }
    return membersExcludingYou;
  }

  /**
   * Unsubscribe to channel and remove all binded events. Can disconnect the pusher instance if necessary
   *
   * @param   {Channel}  channel
   * @param   {boolean}  disconnect
   *
   * @return  {void}
   */
  unsubscribePresenceChannel(channel: Channel, disconnect: boolean = false): void {
    channel.unbind();
    channel.unsubscribe();
    if (disconnect) {
      this.disconnectPresencePusher();
    }
  }

  /**
   * Prepare the record view presence for a specific record
   *
   * @param   {string}  module
   * @param   {string}  recordId
   *
   * @return  {void}
   */
  initRecordViewPresence(module: string, recordId: string): void {
    this.recordViewPresence = this.getPusherPresence(`${module}-${recordId}-record-view`);
  }

  /**
   * Get all record editors on a record-edit presence channel
   *
   * @param   {string}            module
   * @param   {string}            recordId
   * @param   {boolean}           restrictMultipleEditor
   *
   * @return  {Observable<PresenceDetails[]>}
   */
  getRecordEditors(module: string, recordId: string, restrictMultipleEditor: boolean = true): Observable<PresenceDetails[]> {
    return new Observable((observer) => {
      // Check for other editors presence
      this.recordEditPresence = this.getPusherPresence(`${module}-${recordId}-record-edit`);
      this.recordEditPresence.bind("pusher:subscription_succeeded", () => {
        const currentRecordEditors = this.getMembersExcludingCurrentUser(this.recordEditPresence);

        // Unsubscribe to make sure only a single editor exists on the channel
        if (restrictMultipleEditor && currentRecordEditors.length > 0) {
          this.unsubscribePresenceChannel(this.recordEditPresence, true);
        }

        // Emit the result to the observer
        observer.next(currentRecordEditors);
        observer.complete();
      });
    });
  }

  isRecordHasPresence(moduleName: string, id: string, opts: {
    allow_multiple_editors?: boolean
  } = {}): Observable<boolean> {
    opts = Object.assign({
      allow_multiple_editors: false,
    }, opts);

    return this.getRecordEditors(moduleName, id, ! opts.allow_multiple_editors)
      .pipe(
        tap((presence) => (filled(presence) && this._notifications.notifyError('record_presence.editing_message', {
          replacements: {
            editor_name: presence[0].name,
          }
        }))),
        map((presence) => filled(presence)),
      );
  }

  disconnectFromEditorsPresence(opts: {
    disconnect?: boolean,
  } = {}): void {
    opts = Object.assign({
      disconnect: true,
    }, opts);

    if (isNil(this.recordEditPresence)) {
      return;
    }

    this.unsubscribePresenceChannel(this.recordEditPresence, opts.disconnect);
  }
}

export interface PresenceDetails {
  id: string;
  name: string;
  color: string;
  initials: string;
  icon: string;
}
