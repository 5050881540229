export const MODULES_NO_ACTION = ['activities', 'exports', 'stock_levels'];
export const MODULES_NO_ADD = ['activities', 'exports', 'stock_levels'];
export const MODULES_WITH_DOWNLOAD = ['exports'];
export const MODULES_WITH_CREATE_DIALOG = ['checklists', 'document_library', 'sms_templates', 'exports'];
export const MODULES_NO_DELETE = ['users', 'subcontractors'];

// For modules that don't have record views.
export const MODULES_NO_RECORD_VIEW = [
  'departments',
  'resources',
  'teams',
  'tax_codes',
  'account_codes',
  'document_library',
  'pricebooks',
  'project_templates',
  'job_skills',
  'checklists',
  'email_templates',
  'sms_templates',
  'job_safety_sub_task_templates',
  'stock_levels',
  'job_templates',
  'activity_log_types',
  'workflows',
  'subcontractors',
  'payment_terms',
];

export const MODULES_WITHOUT_MERGE = [
  'customer_invoices',
  'supplier_invoices',
  'purchase_orders',
  'users',
  'asset_types',
  'checklists',
  'document_library',
  'project_templates',
  'job_safety_sub_task_templates',
  'items',
  'account_codes',
  'tax_codes',
  'pricebooks',
  'recurring_invoices',
  'stock_levels',
  'warehouses',
  'job_templates',
  'item_folders',
  'payment_terms',
  'roles',
  'advance_reports'
];

// For the advanced filters sorting.
export const SORT_TYPES = [
  'smallint',
  'integer',
  'float',
  'double precision',
  'numeric',
  'date',
  'timestamp without time zone',
  'datetime',
  'number',
  'currency',
  'checkbox',
];

export const STATUS_FIELDS = [
  'status',
  'sync_to_accounting',
  'on_hold',
  'disable_maintenance',
  'is_active',
  'is_productive',
]

export const NO_EDIT_MODAL_MODULES = [
  'pricebooks',
  'teams',
  'project_templates',
  'checklists',
  'stock_levels',
  'roles',
  'workflows',
];

export const MODULES_WITH_BARCODE = [
  'items',
];

export const SHORT_DISPLAY_FIELDS = {
  items: [
    'unit_price',
    'current_stock_level',
  ]
};

export const LONG_DISPLAY_FIELDS = {
  items: [
    'name',
  ]
};
