
    <fieldmagic-primary-button
      [isInProgress]="isInProgress"
      [disabled]="disabled"
      [icon]="icon"
      [klass]="klass"
      [variant]="variant"
      (click)="onOpen()"
      data-toggle="dropdown"
    >
      <span class="dropdown-toggle">{{ label | translate }}</span>
    </fieldmagic-primary-button>
    <div class="dropdown-menu dropdown-content fieldmagic-dropdown-button-content">
      <ng-container *ngFor="let actionItemTmp of actionItemTmps; let index = index;">
        <div
          class="dropdown-item fieldmagic-dropdown-button-item"
          (click)="onActionItemClick(index)"
        >
          <ng-container *ngTemplateOutlet="actionItemTmp"></ng-container>
        </div>
      </ng-container>
    </div>
  