import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule, MomentDateTimeAdapter, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime-moment';
import { QuillModule } from 'ngx-quill';
import { FileUploadModule } from 'ng2-file-upload';

// Modules
import { EditModule } from './edit.module';
import { SharedModule } from './shared.module';
import { SharedModule as FormTemplateSharedModule } from '../../module/form-templates/shared/shared.module';
import { AddressModule } from '../../shared/modules/address.module';

// Components
import { ListingComponent } from '../components/listing/listing.component';
import { FilterdialogComponent } from '../components/listing/filterdialog/filterdialog.component';
import { EditInvoiceComponent } from '../../module/jobs/customer-invoices/edit-invoice/edit-invoice.component';
import { EditPurchaseOrderComponent } from '../../module/jobs/purchase-orders/edit-purchase-order/edit-purchase-order.component';
import { EditSupplierInvoiceComponent } from '../../module/jobs/supplier-invoices/edit-supplier-invoice/edit-supplier-invoice.component';
import { QuickfilterComponent } from '../components/listing/quickfilter/quickfilter.component';
import { EditComponent as EditEmailTemplate } from "../../admin/email-template/edit/edit.component";
import { SaveComponent } from '../../admin/sms-templates/save/save.component';
import { MergeRecordComponent } from '../../shared/components/listing/merge-dialog/merge-record/merge-record.component';
import { EditComponent as EditJobTemplatesComponent } from '../../admin/job-templates/edit/edit.component';
import { SharedInputModule } from './../../shared/modules/shared-input.module';
import { WizardPurchaseOrderComponent } from '../../module/jobs/purchase-orders/wizard-purchase-order/wizard-purchase-order.component';

// Services
import { ActivitiesService } from '../../services/activities.service';
import { DocumentService } from '../../services/document.service';
import { WidgetService } from '../../services/widget.service';
import { ProjectTemplatesService } from '../../services/project-templates.service';
import { JobSkillsService } from '../../services/job-skills.service';

import { AsBoxModule } from '../../shared/external-libraries/ngx-advanced-searchbox/src/public_api'
import { ErrorHandlerModule } from './error-handler.module';
import { ListModule } from '../components/listing/list/list.module';
import { ProductFoldersModule } from '../../features/product-folders/product-folders.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ReadableAddressPipe } from '../../pipes/readable-address.pipe';
import { PopoverModule } from 'ngx-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HumanReadableDatePipe } from '../../pipes/human-readable-date/human-readable-date.pipe';
import { TagsModule } from '../../features/tags/tags.module';
import { MaterialsModule } from '../../module/jobs/materials/materials.module';
import { WorkOrderItemsModule } from './work-order-items.module';

import { AdvanceReportService } from '../../services/advance-report.service';
import { WizardComponent as AdvanceReportWizardComponent } from '../../module/advance-reports/wizard/wizard.component';
import { SelectWorkOrderItemsComponent } from '../../module/jobs/purchase-orders/select-work-order-items/select-work-order-items.component';
import { ShContextMenuModule } from 'ng2-right-click-menu'
import { PageSpinnerComponent } from '../components/page-spinner/page-spinner.component';
import { StudioService } from '../../services/studio.service';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_MOMENT_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    EditModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    SharedModule.forRoot(),
    QuillModule,
    FileUploadModule,
    AsBoxModule.forRoot(),
    FormTemplateSharedModule,
    AddressModule,
    ErrorHandlerModule,
    SharedInputModule,
    ListModule,
    ProductFoldersModule,
    NgbTooltipModule,
    PopoverModule,
    DragDropModule,
    TagsModule,
    MaterialsModule,
    WorkOrderItemsModule,
    ShContextMenuModule,
  ],
  declarations: [
    ListingComponent,
    FilterdialogComponent,
    EditInvoiceComponent,
    EditPurchaseOrderComponent,
    EditSupplierInvoiceComponent,
    QuickfilterComponent,
    // FilterMetadataComponent,
    EditEmailTemplate,
    SaveComponent,
    MergeRecordComponent,
    EditJobTemplatesComponent,
    WizardPurchaseOrderComponent,
    AdvanceReportWizardComponent,
    SelectWorkOrderItemsComponent,
  ],
  entryComponents: [
    FilterdialogComponent,
    EditInvoiceComponent,
    EditPurchaseOrderComponent,
    EditSupplierInvoiceComponent,
    // FilterMetadataComponent,
    EditEmailTemplate,
    SaveComponent,
    MergeRecordComponent,
    EditJobTemplatesComponent,
    WizardPurchaseOrderComponent,
    AdvanceReportWizardComponent,
    SelectWorkOrderItemsComponent,
    PageSpinnerComponent,
  ],
  exports: [
    ListingComponent,
    FilterdialogComponent,
    QuickfilterComponent,
    // FilterMetadataComponent,
    ListModule,
  ],
  providers: [
    ActivitiesService,
    DocumentService,
    ProjectTemplatesService,
    JobSkillsService,
    WidgetService,
    AdvanceReportService,
    StudioService,
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
    ReadableAddressPipe,
    HumanReadableDatePipe
  ]
})

export class ListingModule { }
