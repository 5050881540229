import { Component } from '@angular/core';
import { blank, observe, str_len } from '../../../../shared/utils/common';
import { BehaviorSubject, defer, of } from 'rxjs';
import { DialogService } from '../../../../services/dialog.service';
import { filter } from 'rxjs/operators';
import { UsersService } from '../../../../services/users.service';

@Component({
  selector: 'change-password-dialog',
  template: `
    <fieldmagic-dialog
      title="Change Password"
      icon="key"
      [instance]="self"
    >
      <ng-template fieldmagicDialogButtonsTmp>
        <fieldmagic-primary-button
          purpose="save"
          (click)="onSave()"
          [isInProgress]="saving$ | async"
        >
        </fieldmagic-primary-button>
      </ng-template>

      <ng-template fieldmagicDialogContentTmp>
        <div class="d-flex flex-column d-flex-gap-2x">
          <fieldmagic-alert>
            <fieldmagic-text content="Secure password rules"></fieldmagic-text>
            <div class="d-flex d-flex-gap">
              <ul>
                <li><fieldmagic-text content="Password must be atleast 10 characters."></fieldmagic-text></li>
                <li><fieldmagic-text content="Password must contain atleast 1 uppercase character."></fieldmagic-text></li>
                <li><fieldmagic-text content="Password must contain atleast 1 number character."></fieldmagic-text></li>
                <li><fieldmagic-text content="Password must contain atleast 1 lowercase character."></fieldmagic-text></li>
              </ul>
            </div>
          </fieldmagic-alert>

          <div class="d-flex flex-column">
            <fieldmagic-text-input
              label="New Password"
              [(ngModel)]="newPassword"
              [standalone]="false"
              [obsecure]="true"
              [withRequiredMarker]="true"
              [errors]="errors | data_get: 'new_password'"
            >
            </fieldmagic-text-input>

            <fieldmagic-text-input
              label="Confirm Password"
              [standalone]="false"
              [(ngModel)]="confirmPassword"
              [obsecure]="true"
              [withRequiredMarker]="true"
              [errors]="errors | data_get: 'confirm_password'"
            >
            </fieldmagic-text-input>
          </div>
        </div>
      </ng-template>
    </fieldmagic-dialog>
  `
})
export class ChangePasswordDialogComponent {
  get self(): ChangePasswordDialogComponent {
    return this;
  }

  errors: Record<string, string[]> = {};

  newPassword?: string;
  confirmPassword?: string;

  readonly saving$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _users: UsersService,
    private readonly _dialog: DialogService,
  ) {}

  onSave = () => observe({
    before: () => this.saving$.next(true),
    after: () => this.saving$.next(false),
    observable: () => defer(() => {
      if (! this._isValid()) {
        return of(false);
      }

      return this._users.updatePassword(this.newPassword);
    }),
  }).pipe(
    filter((successful) => successful),
  ).subscribe(() => this._dialog.close({
    instance: this,
  }));

  private _isValid(): boolean {
    let errors: Record<string, string[]> = {};

    if (blank(this.newPassword)) {
      errors['new_password'] = ['This field is required.'];
    } else if (str_len(this.newPassword) < 10) {
      errors['new_password'] = ['The password must be atleast 10 characters long.'];
    }

    if (blank(this.confirmPassword)) {
      errors['confirm_password'] = ['This field is required.'];
    } else if (this.confirmPassword !== this.newPassword) {
      errors['confirm_password'] = ['The passwords don\'t match.'];
    }

    this.errors = errors;

    return blank(errors);
  }
}