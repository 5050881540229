import { Component, Input, OnInit } from '@angular/core';
import { TagDisplay } from '../../objects/tag-display';
import { LooseObject } from '../../../../objects/loose-object';

@Component({
  selector: 'tag-list',
  templateUrl: './tag-list.component.html'
})
export class TagListComponent implements OnInit {

  /**
   * The record that has the tag field.
   *
   * @var {LooseObject}
   */
  @Input('record') record: LooseObject;

  /**
   * Whether to use the design for the view record
   * or for the list record.
   *
   * True means it uses to the view record.
   *
   * @var {boolean}
   */
  @Input('view') view: boolean = true;

  /**
   * The tag to display in the view/list.
   *
   * @var {TagDisplay}
   */
  tags: TagDisplay[];

  ngOnInit() {
    if (this.record && this.record['tags']) {
      this.tags = this.record['tags'].map(items => { return new TagDisplay(items); });
    }
  }

}
