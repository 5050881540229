import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FormPopup } from '../../../../../objects/centralized-forms/form-popup';
import { EditformComponent } from '../../../editform/editform.component';
import { Router } from '@angular/router';
import { ListingElementsEvent } from '../../objects/listing-elements-event';
import { MODULES_CUSTOM_COMPONENT, MODULES_CUSTOM_REDIRECT, MODULES_NO_REDIRECT, MODULES_PARENT_CREATE } from './add-config';
import { CustomCreate } from '../../objects/custom-create';
import { ProjectTemplatesService } from '../../../../../services/project-templates.service';

@Component({
  selector: 'fm-listing-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent {

  /**
   * Custom create components to use.
   *
   * @var {objCustomCreate}
   */
  public objCustomCreate?: CustomCreate = {};

  /**
   * The current module this component is declared.
   *
   * @var {string}
   */
  @Input('strModule') strModule: string;

  /**
   * The label for the add button.
   *
   * @var {string}
   */
  @Input('strAddLabel') strAddLabel: string = 'add';

  /**
   * Event listeners to inform parent of changes of this component.
   *
   * @var {EventEmitter<ListingElementsEvent>}
   */
  @Output() afterCreateEvent = new EventEmitter<ListingElementsEvent>();

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private projectTemplateService: ProjectTemplatesService
  ) { }

  /**
   * Prepare in opening the create dialog.
   *
   * @param {string} strModule
   *
   * @returns {void}
   */
  create(strModule?: string): void {

    if (!strModule) {
      strModule = this.strModule
    }

    if (MODULES_PARENT_CREATE.includes(strModule)) {
      this.afterCreateEvent.emit(
        new ListingElementsEvent({
          type: 'custom_create',
          data: null
        }
      ));
    } else if (MODULES_CUSTOM_COMPONENT.map(item => { return item.module }).includes(strModule)) {

      let objCustomAdd = MODULES_CUSTOM_COMPONENT.find(item => item.module == strModule);

      if (objCustomAdd) {
        this.objCustomCreate.component = objCustomAdd.component;
        this.objCustomCreate.config = objCustomAdd.config || {
          width: '600px',
          height: 'auto',
          disableClose: true
        };

        this.objCustomCreate.config.data = {...this.objCustomCreate.config.data, ...this.objCustomCreate.params};

        this.defaultCreate(strModule, this.objCustomCreate.config, this.objCustomCreate.component);
      }

    } else if (MODULES_CUSTOM_REDIRECT.map(item => { return item.module }).includes(strModule)) {

      let objCustomAdd = MODULES_CUSTOM_REDIRECT.find(item => item.module == strModule);

      if (strModule == 'project_templates') {
        this.projectTemplateService.setMode('create');
      }

      this.objCustomCreate.redirect = [objCustomAdd.link];
      this.router.navigate(this.objCustomCreate.redirect, {queryParams: this.objCustomCreate.params});
    } else {
      this.defaultCreate(strModule, new FormPopup(strModule, {}, this.objCustomCreate.params));
    }

  }

  /**
   * Method to actually call the create dialog.
   *
   * @param {string} strModule
   * @param {MatDialogConfig} objConfig
   * @param {any} component
   *
   * @returns {void}
   */
  defaultCreate(
    strModule: string,
    objConfig: MatDialogConfig,
    component: any = EditformComponent
  ): void {

    let createDialog = this.dialog.open(
      component,
      objConfig
    );

    createDialog.afterClosed().first().subscribe(data => {

      if (!MODULES_NO_REDIRECT.includes(strModule)) {
        if (data !== undefined) {
          if (data.status && data.status == 'save' || data.action && data.action == 'save'){

            let strId: string;

            if (data['data'] && data['data']['id']) {
              strId = data['data']['id'];
            }

            if (data['response'] && data['response']['id']) {
              strId = data['response']['id'];
            }

            this.afterCreateEvent.emit(
              new ListingElementsEvent({
                type: 'redirect_view',
                data: strId
              })
            );

          } else {
            this.afterCreateEvent.emit(
              new ListingElementsEvent({
                type: 'create_closed',
                data: data
              })
            );
          }
        }
      } else {
        if (
          (data && data.status && data.status != 'cancel') ||
          (data && data.message && data.message == 'save') ||
          (data && data == 'save')
        ) {
          this.afterCreateEvent.emit(
            new ListingElementsEvent({
              type: 'paginate',
              data: 'go'
            })
          );
        }
      }
    });
  }

}
