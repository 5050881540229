import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { map, switchMap, tap } from 'rxjs/operators';
import { RecordService } from '../../../../../services/record.service';
import { ItemReorder } from '../../../../../objects/stock-management/item-reorder';
import { ListingService } from '../../../../../services/listing.service';
import { Relate } from '../../../../../objects/relate';
import { EditformComponent } from '../../../../../shared/components/editform/editform.component';
import { FormPopup } from '../../../../../objects/centralized-forms/form-popup';
import { StockManagementService } from '../../../../../services/stock-management.service';
import { NotificationService } from '../../../../../services/notification.service';
import { Pagination } from '../../../../../shared/components/pagination/pagination';
import { LooseObject } from '../../../../../objects/loose-object';
import { cloneDeep, isEmpty } from 'lodash-es';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'reorder-list',
  templateUrl: './reorder-list.component.html',
  styleUrls: ['./reorder-list.component.scss'],
  providers: [StockManagementService]
})
export class ReorderListComponent implements OnInit {

  /**
   * Holds the pagination for list of
   * items to reorder.
   *
   * @var {Pagination<ItemReorder>}
   */
  public objPagination: Pagination<ItemReorder> = new Pagination;

  /**
   * A flag to dictate if all the line items
   * is checked.
   *
   * @var {boolean}
   */
  public bIsAllChecked: boolean = false;

  /**
   * Flag to indicate if the reorder is being generated.
   *
   * @var {boolean}
   */
  public bIsSaving: boolean = false;

  /**
   * Flag to indicate that submit button is clicked.
   *
   * @var {boolean}
   */
  public bSubmitted: boolean = false;

  public arReorderList: ItemReorder[] = [];

  public bEmailToSuppliers: boolean = false;

  protected rawData: LooseObject[];

  /**
   * Disable button if it's either saving or
   * no item is selected/checked.
   *
   * @returns {boolean}
   */
  get bDisabledReorder(): boolean {
    return this.arReorderList.filter(item => item.is_included).length < 1 || this.bIsSaving;
  }

  constructor(
    private dialog: MatDialog,
    private recordService: RecordService,
    private stock: StockManagementService,
    private notif: NotificationService
  ) {}

  ngOnInit() {
    this.getReorderRecord();
  }

  /**
   * Create customers if the searched item does not exist.
   *
   * @returns {Promise<{id: string, name: string}>}
   */
  addTagPromise(element): Promise<{id: string, name: string}> {
    // FC-3928: close ng select options when the user select's "Create New Supplier"
    if (element) {
      element.close();
    }

    return this.dialog.open(EditformComponent, new FormPopup('customers')).afterClosed().first().map(objResult => {
      if (objResult.data == null) {
        return null;
      } else {
        if (objResult.data['is_supplier'] === true) {
          return {
            id: objResult.data.id, name: objResult.data.name
          }
        } else {
          this.notif.notifyWarning('created_is_not_supplier');
          return null;
        }

      }
    }).toPromise();
  }

  /**
   * Generate reorder items.
   *
   * @returns {void}
   */
  reorderProducts(): void {
    this.bIsSaving = true;
    this.bSubmitted = true;
    let arReorderData = this.arReorderList.filter( objItemReorder =>
      objItemReorder.is_included == true
    );
    if (this.hasValidReorderItems(arReorderData)) {

      let arSelectedProducts: {item_id: string, supplier_id: string, reorder_quantity: number, unit_cost: number}[] =
      arReorderData.map(objItemReorder => {
        return {
          item_id: objItemReorder.item_id,
          supplier_id: objItemReorder.preferred_supplier.value.id,
          reorder_quantity: objItemReorder.min_reorder_level,
          lead_time: objItemReorder.lead_time,
          unit_cost: objItemReorder.unit_cost,
          is_supplier_new_pricing: objItemReorder.is_supplier_new_pricing,
          warehouse_id: objItemReorder.warehouse_id,
        }
      });
      this.stock.generateReorderItems({
        email_to_supplier: this.bEmailToSuppliers,
        line_items: arSelectedProducts
      }).pipe(
        tap({
          error: (err: HttpErrorResponse) => {
            if (err.status === 422) {

              this.notif.notifyWarning('invalid_requested_parameters');
            }
            this.bIsSaving = false;
          },
        })
      ).subscribe(() => {
        this.bSubmitted = false;
        this.bIsSaving = false;
        this.bEmailToSuppliers = false;
        this.notif.notifySuccess('purchase_order_generated');

        this.getReorderRecord();
      });
    } else {

      this.bIsSaving = false;
      this.notif.notifyWarning('required_notice');
    }
  }

  /**
   * Simply marks all items as checked.
   *
   * @returns {void}
   */
  checkAll(): void {
    this.arReorderList.map( objItemReorder => {
      objItemReorder.is_included = this.bIsAllChecked;

      return objItemReorder;
    });
  }

  /**
   * add custom item for reorder
   */
  addItem(objItem: any = {}): void {
    let objWarehouse = {};
    let objLastWarehouseData = this.arReorderList.filter(obj => obj.is_additional == true);

    if (!isEmpty(objLastWarehouseData)) {
      objWarehouse = objLastWarehouseData.pop().warehouse_relate.value;
      objItem.warehouse_id = objWarehouse['id'];
      objItem.warehouse_text = objWarehouse['text'];
    }

    this.arReorderList.push(
      new ItemReorder(
        objItem,
        new Relate<any>().buildRelates(
          switchMap(strTerm => this.recordService.getRecordRelate('customers', strTerm, false, false, {is_supplier: true})),
          (objItem.customer_id) ? [{ id: objItem.customer_id, name: objItem.customer_text }] : null,
          true
        ),
        true,
        new Relate<any>().buildRelates(
          switchMap(strTerm => this.recordService.getProductRecordData(strTerm, {
            labor: false,
            active: true
          }, '', true, null, [], true))
        ),
        new Relate<any>().buildRelates(
          switchMap(strTerm => this.recordService.getRecordRelate('warehouses', strTerm, false, false, { status: 'active'})),
          objWarehouse ? [ objWarehouse ] : [],
          !isEmpty(objWarehouse)
        )
      )
    );
  }


  /**
   * trigger's when changing supplier
   *
   * @param item
   */
  onChangeSupplier(item: ItemReorder): void {
    item.is_supplier_new_pricing = false;
    item.customer_id = item.preferred_supplier.value ? item.preferred_supplier.value.id : null;

    this.updateUnitCost(item);
    this.updateLeadTime(item);
  }

  /**
   * update buy price
   *
   * @param item
   */
  updateUnitCost(item: ItemReorder): void {
    if (item.preferred_supplier.value && item.preferred_supplier.value.id) {

      let objReorderCost = item.original_data.supplier_pricing || [];
      let objSupplierReorderCost = objReorderCost.find( reorderCost =>
        reorderCost.customer_id == item.preferred_supplier.value.id
      );

      if (item.preferred_supplier.value.unit_cost) {
        item.unit_cost = item.preferred_supplier.value.unit_cost
      }

      if (objSupplierReorderCost) {
        item.unit_cost = objSupplierReorderCost.unit_cost;
      }
    }
  }

  /**
   * update lead time based on selected supplier
   *
   * @param item
   */
  updateLeadTime(item: ItemReorder): void {
    item.lead_time = 0;
    if (item.preferred_supplier.value && item.preferred_supplier.value.lead_time) {
      item.lead_time = item.preferred_supplier.value.lead_time;
    }
  }

  /**
   * trigger's when changing the warehouse relate field
   * update the reorder item value
   *
   * @param item
   */
  onChangeWarehouse(item: ItemReorder): void {
    item.warehouse_id = item.warehouse_relate.value.id;
    item.warehouse_text = item.warehouse_relate.value.name;

    this.getStocklevel(item);
  }

  /**
   * trigger's when changing the item relate field
   * update the reorder item value
   *
   * @param item
   */
  onChangeItem(item: ItemReorder): void {
    item.item_id = item.item_relate.value.id;
    item.item_code = item.item_relate.value.code;
    item.item_name = item.item_relate.value.name;
    item.unit_cost = item.item_relate.value.unit_cost;
    item.original_data = item.item_relate.value;
    item.use_supplier_pricing = false;
    item.min_reorder_level = 0;

    this.getStocklevel(item);
    this.updateCustomerRelate(item, item.item_relate.value);
  }

  /**
   *
   * @param item
   */
  getStocklevel(item: ItemReorder): void {
    if (item.is_additional && item.warehouse_id && item.item_id) {
      this.recordService.getRecordRelate('stock_levels', '', false, false, {
        item_id: item.item_id,
        warehouse_id: item.warehouse_id,
      }).subscribe( objResponse => {

        let objStocklevelData = (objResponse) ? objResponse[0] : {};
        if (objStocklevelData) {

          item.stock_level_quantity = objStocklevelData['quantity'];
          item.purchase_orders = objStocklevelData['purchase_orders'];
        }

        item.min_stock_level = item.item_relate.value.minimum_quantity || 0;
        item.min_reorder_level = item.item_relate.value.reorder_quantity || 0;
        item.customer_id = item.item_relate.value.preferred_supplier;
        item.customer_text = item.item_relate.value.preferred_supplier_text;

        // update minimum stock level when there is a reorder config linked to the client
        if (item.item_relate.value.reorder_config) {
          let reorderConfig = item.item_relate.value.reorder_config
            .find(reorderConfig => reorderConfig.warehouse_id == item.warehouse_id);
          if (reorderConfig) {
            item.min_stock_level = reorderConfig.min_stock_level
            item.min_reorder_level = reorderConfig.min_reorder_level
          }
        }
      })
    }
  }

  /**
   * check if the selected items are valid
   *
   * @param arItems
   *
   * @returns {boolean}
   */
  hasValidReorderItems(arItems: ItemReorder[]): boolean {
    return cloneDeep(arItems).map( objItems => {

      let formValidator = new FormGroup({
        warehouse_id: new FormControl('', [Validators.required]),
        item_id: new FormControl('', [Validators.required]),
        customer_id: new FormControl('', [Validators.required]),
        unit_cost: new FormControl(0, [Validators.required, Validators.min(.01)]),
        min_reorder_level: new FormControl(0, [Validators.required, Validators.min(1)]),
      });
      formValidator.patchValue(objItems);

      return formValidator.valid;
    }).filter( bIsValid => bIsValid === false).length === 0;
  }

  /**
   * get all record record
   */
  getReorderRecord(): void {
    this.stock.listReorderItems(JSON.stringify({}), JSON.stringify({})).map( (objResponse: LooseObject[]) => {
      return objResponse.map( (objItem: any) => {

        let objItemReoder = new ItemReorder(objItem, null);
        this.updateCustomerRelate(objItemReoder, objItem);

        return objItemReoder;
      })
    }).subscribe( objResponse => {
      this.bIsAllChecked = false;
      this.arReorderList = objResponse;
    });
  }

  /**
   * when unit cost is changed, add a flag that it was change
   * so we can enable the checkbox to save the reorder cost for selected supplier
   *
   * @param item ItemReorder
   */
  onChangeUnitCost(item: ItemReorder): void {
    item.is_unit_cost_changed = true;
  }

  /**
   * remove item in item reorder
   */
  removeItemReorder(index: number): void {
    this.arReorderList.splice(index, 1);
  }

  /**
   * parse json string
   *
   * @param strJson
   */
  parseJSON(strJson) {
    try {
      return JSON.parse(strJson);
    } catch(ex){
      return null;
    }
  }

  /**
   * adding default value or default option on customer relate field
   *
   * @param objLineItem
   * @param objItem
   */
  updateCustomerRelate(objItemReorder: ItemReorder, objItem: LooseObject = null): void {
    if (
      (objItem.customer_id && objItem.customer_text)
      || (objItem.preferred_supplier && objItem.preferred_supplier_text)
    ) {
      objItemReorder.item_preferred_supplier = [
        {
          id: objItem.customer_id || objItem.preferred_supplier,
          name: objItem.customer_text || objItem.preferred_supplier_text,
          unit_cost: objItem.unit_cost,
          lead_time: objItem.lead_time,
          primary: true
        }
      ];
    }

    objItemReorder.supplier_pricing_list = objItem.supplier_pricing
      ? objItem.supplier_pricing.map( supplierPricing => {
        return {
          id: supplierPricing['customer_id'],
          name: supplierPricing['customer_text'],
          unit_cost: supplierPricing['unit_cost'],
          lead_time: supplierPricing['lead_time'],
        };
      })
      : [];

    if (objItemReorder.preferred_supplier) {
      objItemReorder.preferred_supplier.destroyTypehead();
    }

    objItemReorder.preferred_supplier = new Relate<any>().buildRelates(
      switchMap(strTerm => this.customerBuildRelate(objItemReorder, strTerm)),
      !isEmpty(objItemReorder.item_preferred_supplier) ? objItemReorder.item_preferred_supplier.concat(objItemReorder.supplier_pricing_list) : objItemReorder.supplier_pricing_list,
      !isEmpty(objItemReorder.item_preferred_supplier)
    );
  }

  /**
   * initialize relate request for customer relate field
   *
   * @param objLineItem
   * @param strTerm
   */
  customerBuildRelate(objItemReorder: ItemReorder, strTerm: string): Observable<any> {
    return this.recordService.getRecordRelate('customers', strTerm, false, false, { is_supplier: true })
      .pipe(
        map( response => {
          let arItemDefaultSupplier = objItemReorder.item_preferred_supplier ? objItemReorder.item_preferred_supplier : [];
          if (isEmpty(strTerm) && !isEmpty(objItemReorder.supplier_pricing_list)) {

            let arSupplierPricing = cloneDeep(objItemReorder.supplier_pricing_list);
            if (!isEmpty(arItemDefaultSupplier)) {
              arSupplierPricing.unshift(arItemDefaultSupplier[0])
            }

            return arSupplierPricing;
          }

          return isEmpty(strTerm) && !isEmpty(arItemDefaultSupplier) ? arItemDefaultSupplier : response;
        })
      );
  }

  computeOnOrdered(arPurchaseOrder: LooseObject[]) {

    let totalQuantity = 0;
    arPurchaseOrder.forEach( item => {
      totalQuantity += parseFloat(item.quantity_remaining);
    });

    return totalQuantity;
  }

  /**
   * disable negative values when inputing on number field
   *
   * @param event
   */
  negateNegative(event: KeyboardEvent) {
    if (event.which != 8 && event.which != 0 && event.which !== 46 && event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  }
}