import { Observable } from "rxjs/Observable";
import { Select } from "./select";
import { Subject } from "rxjs/Subject";
import { TaxCodeInterface } from "./tax-code";
import { UUID } from 'angular2-uuid';
import { Relate } from './relate';
import { Department } from './department';
import { Item } from './item';
import { LooseObject } from './loose-object';
import { toFormattedNumber } from "../shared/utils/numbers";
import { isNil } from 'lodash-es';
import { blank, filled, when } from '../shared/utils/common';

export class QuoteLineItem {

  /**
   * Description of the item/labor
   */
  description: string;

  /**
   * Discounted price of the item/labor.
   * Discount price usually comes from a pricebook.
   */
  discounted_price: number;

  /**
   * Code of the product/service
   */
  item_code: string;

  /**
   * The ID of the item/labor in our DB.
   */
  item_id: string;

  /**
   * Name of the product/labor.
   */
  item_name: string;

  /**
   * Item for the current
   * line item.
   */
  item: Relate<Item>

  /**
   * Flag that identifies if the item is a product
   * or labor.
   */
  labor: boolean;

  /**
   * Total price of the line item without the tax.
   */
  line_item: number;

  /**
   * The amout of product per line. If labor, this is the
   * hours to rendered for the labor.
   */
  quantity: number;

  /**
   * Code of the tax that is either
   * set with the product or uses
   * the global tax code set in the admin.
   */
  tax_code: string;

  /**
   * Id of the tax code.
   */
  tax_code_id: string;

  /**
   * A more elaborate name of the tax.
   */
  tax_code_name: string;

  /**
   * Rate of the tax.
   */
  tax_rate: number;

  /**
   * Original price of the products
   * without discount from pricebook.
   */
  unit_price: number;

  /**
   * Unit cost of the non labor products.
   */
  unit_cost: number;

  /**
   * Horly cost of the labor products.
   */
  hourly_cost: number;

  /**
   * Department for the current
   * line item.
   */
  department_id: string;

  /**
   * The name of the selected department.
   */
  department_name: string;

  /**
   * Department for the current
   * line item.
   */
  department: Relate<Department>

  /**
   * Reference for identifier when we
   * auto populate the work order
   * to material and task.
   */
  work_order_reference: string;

  /**
   * Identifier if the work order
   * line item is existing
   */
  is_created: boolean;

  /**
   * Identifier if the work order
   * line item is existing
   */
  current_stock_level: number;

  /**
   * Contains related products
   */
  related_products: LooseObject[];

  /**
   * markup percentage
   */
  markup: number;

  /**
   * Identifier id for multiple select
   */
  select_line_id: string;

  /**
   * Contains products supplier pricing
   */
  supplier_pricing: LooseObject[];

  /**
   * Value to compute when markup is manually adjusted
   */
  master_unit_price: number;

  /**
   * Temporary values only needed for client side.
   *
   * When saving the quote line item to the API, we use the function
   * forSaving() to delete these properties in a cloned
   * version of the object.
   */
  obv:Observable<Select[]>;
  typehead: Subject<string>;
  loader: boolean;
  id: string;

  preferred_supplier_text: string;

  enable_discounts?: boolean;

  constructor(properties: {
    id?: string
    description?: string
    discounted_price?: number
    item_code?: string
    item_id?: string
    item_name?: string
    item?: Relate<Item>
    labor?: boolean
    line_item?: number
    quantity?: number
    tax_code?: string
    tax_code_id?: string
    tax_code_name?: string
    tax_rate?: number
    unit_price?: number
    unit_cost?: number
    hourly_cost?: number
    department_id?: string
    department_name?: string
    department?: Relate<Department>
    work_order_reference?: string
    is_created?: boolean
    current_stock_level?: number
    related_products?: LooseObject[],
    markup?: number,
    preferred_supplier_text?: string,
    supplier_pricing?: LooseObject[],
    select_line_id?: string,
    enable_discounts?: boolean;
  } = {}){
    this.id = properties.id;
    this.description = properties.description || '';
    this.discounted_price = properties.discounted_price || properties.unit_price || 0;
    this.item_code = properties.item_code;
    this.item_id = (this.isUuid(properties)) ? properties.item_id : properties.item_name;
    this.item_name = properties.item_name;
    this.item = properties.item;
    this.labor = properties.labor || false;
    this.line_item = properties.line_item || 0;
    this.quantity = properties.quantity || 1;
    this.tax_code = properties.tax_code;
    this.tax_code_id = properties.tax_code_id;
    this.tax_code_name = properties.tax_code_name;
    this.tax_rate = properties.tax_rate || 0;
    this.unit_price = properties.unit_price || 0;
    this.unit_cost = properties.unit_cost || 0;
    this.hourly_cost = properties.hourly_cost || 0;
    this.department_id = properties.department_id;
    this.department_name = properties.department_name;
    this.department = properties.department;
    this.work_order_reference = properties.work_order_reference || UUID.UUID()
    this.is_created = properties.is_created || false
    this.current_stock_level = properties.current_stock_level;
    this.related_products = properties.related_products || [];
    this.preferred_supplier_text = properties.preferred_supplier_text;
    this.supplier_pricing = properties.supplier_pricing || [];
    this.markup = properties.markup || 0;
    this.select_line_id = properties.select_line_id || UUID.UUID();
    this.master_unit_price = properties.unit_price;
    this.enable_discounts = properties.enable_discounts;
  }

  /**
   * This function computes for the line item's total
   * without the tax computation.
   *
   * - Total computation of the line item without the tax.
   * - The formula is Quantity * Discounted Price.
   */
  get computeLineItem(): number {
    let price = this.unit_price;

    if (this.enable_discounts) {
      price = this.discounted_price;
    }

    this.line_item = toFormattedNumber(this.quantity * price, {
      currency: true,
    });

    return this.line_item;
  }

  set computeLineItem(value: number) {
    const adjustment = toFormattedNumber(value, {
      currency: true,
    });

    const quantity = this.quantity;
    const computed = toFormattedNumber(adjustment / quantity, {
      currency: true,
      maxDecimalPlaces: 4,
    });

    if (this.enable_discounts) {
      this.discounted_price = computed;
    } else {
      this.unit_price = computed;
    }
  }


  /**
   * Initializes the current object
   * with the necessary Observables and
   * generates a temporary ID used for client side.
   *
   * - Note: Returns the current object.
   * - Usage: new QuoteLineItem().withObservablesAndId()
   */
  public withObservablesAndId(): QuoteLineItem {
    this.id = this.generateRandomId();
    this.obv = new Observable<Select[]>();
    this.typehead = new Subject<string>(),
    this.loader = false;
    return this;
  }

  /**
   * Checks if the given item_id is in
   * the UUID format.
   *
   * Note: Only accepts an object with an item_id property!
   */
  public isUuid(properties: {item_id?: string} = {}): boolean {
    let strPattern = "[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}";
    return (properties.item_id) ? (properties.item_id.match(strPattern) == null) ? false : true : false;
  }

  /**
   * Returns the a cloned version of the
   * current object without the
   * observables and other values
   * that the API will not need.
   *
   * Note: This returns a new object and not the current instance!
   */
  public forSaving(): QuoteLineItem {

    let objQuoteLineItemClone: QuoteLineItem = {...this};

    delete objQuoteLineItemClone.id;
    delete objQuoteLineItemClone.obv;
    delete objQuoteLineItemClone.loader;
    delete objQuoteLineItemClone.typehead;

    // If the format of the item_id is not a UUID, let's exclude from the cloned object.
    if (!this.isUuid(objQuoteLineItemClone)) {
      delete objQuoteLineItemClone.item_id;
    }

    delete objQuoteLineItemClone.enable_discounts;

    return objQuoteLineItemClone;
  }

  /**
   * This function updates the current object's product values
   * as well as the tax codes and other properties that can come
   * from the product.
   */
  public updateProduct(properties: {
    code?: string
    unit_price?: number
    unit_cost?: number
    hourly_cost?: number
    description?: string
    line_item?: number
    labor?: boolean,
    id?: string,
    name?: string,
    text?: string,
    tax_code?: string
    tax_code_id?: string
    tax_code_name?: string
    tax_rate?: number
    pricebook_id?: string
    pricebook_unit_price?: number
    current_stock_level?: number
    related_products?: LooseObject[],
    supplier_pricing?: LooseObject[],
    markup?: number
    quantity?: number
    preferred_supplier_text?: string
    discounted_price?: number
  } = {}): void {

    this.item_code = properties.code;
    this.unit_price = properties.unit_price || 0;
    this.unit_cost = properties.unit_cost || 0;
    this.hourly_cost = properties.hourly_cost || 0;
    this.discounted_price = properties.discounted_price || properties.unit_price || 0;
    this.item_id = properties.id;
    this.item_name = properties.name || properties.text;
    this.labor = properties.labor || false;
    this.current_stock_level = properties.current_stock_level;
    this.related_products = properties.related_products || [];
    this.supplier_pricing = properties.supplier_pricing || [];
    this.markup = properties.markup || 0.00;
    this.description = properties.description || this.item_name || '';
    this.quantity = properties.quantity || 1;
    this.preferred_supplier_text = properties.preferred_supplier_text;
    this.master_unit_price = properties.unit_price;

    // When the product given belongs to a pricebook.
    if (properties.pricebook_id) {
      this.discounted_price = properties.pricebook_unit_price;
      this.unit_price = when(this.enable_discounts, {
        then: () => properties.unit_price,
        else: () => properties.pricebook_unit_price,
      });

      this.computeMarkup();
    }

    if (
        blank(properties.markup)
        && (filled(properties.unit_price)
        && (filled(properties.unit_cost) || filled(properties.hourly_cost)))) {
          this.computeMarkup();
    }

  }

  /**
   * Updates the current object's tax code values.
   *
   * Note: This does not return anything, just updates the current object.
   */
  public updateTaxCode(properties: TaxCodeInterface): void {
    this.tax_code = (properties && properties.code) ? properties.code : null;
    this.tax_code_id = (properties && properties.id) ? properties.id : null;
    this.tax_code_name = (properties && properties.name) ? properties.name : null;
    this.tax_rate = (properties && properties.rate) ? properties.rate : 0;
  }

  /**
   * Generates a random id needed for
   * the draggable line items in the quote.
   */
  private generateRandomId(): string {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

  /**
   * Updates the current line item's pricess
   *
   * @param {number} cost
   * @param {number} price
   * @param {number} discounted_price
   */
  public updatePrices(numCost: number, numPrice: number, numDiscPrice: number): void {

    if (this.labor) {
      this.hourly_cost = numCost;
    } else {
      this.unit_cost = numCost;
    }

    this.unit_price = numPrice;
    this.discounted_price = numDiscPrice;
    this.markup = 0;
  }

  /**
   * Computes the mark up depending on the cost and price.
   *
   * @return {void}
   */
  public computeMarkup(): void {

    let numUnitPrice = (this.unit_price > 0) ? this.unit_price : this.discounted_price;
    let numUnitCost = (!this.labor) ? this.unit_cost : this.hourly_cost;

    let intTotalComputation = 100 * (numUnitPrice - numUnitCost) / numUnitCost;

    this.markup = toFormattedNumber((intTotalComputation !== Infinity ? intTotalComputation : 0));
  }
}
