import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { observable, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StockLevel } from '../objects/stock-management/stock-level';
import { Stocktake, StocktakeItemInterface } from '../objects/stock-management/stocktake';
import { map, tap } from 'rxjs/operators';
import { ErrorEventType, ErrorPublishingService } from './error-publishing.service';
import { isEmpty } from 'lodash-es';
import { MaterialInterface } from '../objects/material';
import { Router } from '@angular/router';

@Injectable()
export class StockManagementService {

  /**
  * The base url for all requests in this service.
  *
  * @returns {string}
  */
  get strBaseUrl() {
    return environment.url + '/stock_management/';
  }

  constructor (
    private http: HttpClient,
    private errors: ErrorPublishingService,
    private router: Router,
  ) {}

  /**
   * Save the stocktake in the API.
   *
   * @param {'final' | 'draft'} strStatus
   * @param {string} strWarehouseId
   * @param {StocktakeItemInterface[]} arItems
   * @param {string} strId
   *
   * @returns {Observable<{message: string, data: Stocktake}>}
   */
  saveStocktake(
    strStatus: 'final' | 'draft',
    strWarehouseId: string,
    arItems: StocktakeItemInterface[],
    strId: string = null
  ): Observable<{message: string, data: Stocktake}> | null {

    if (! isEmpty(arItems)) {
      let objParams = new URLSearchParams({
        status: strStatus,
        items: JSON.stringify(arItems),
        warehouse_id: strWarehouseId
      });

      if (strId) {
        objParams.append('id', strId);
      }

      return this.http.post<{message: string, data: Stocktake}>(this.strBaseUrl + 'stocktake/save', objParams.toString()).pipe(
        map( response => {
          return {
            message: response['message'],
            data: new Stocktake(response['data'])
          }
        })
      );
    } else {
      return null;
    }
  }

  /**
   * Get the stocktake record.
   *
   * @param {string} strId
   * @param {string} strWarehouseId
   *
   * @returns {Observable<Stocktake|null>}
   */
  getStocktake(strId: string = null, strWarehouseId: string = null): Observable<Stocktake|null> {

    let objParams = new URLSearchParams();

    if (strId) {
      objParams.append('id', strId);
    } else {
      objParams.append('warehouse_id', strWarehouseId);
    }

    return this.http.post<{data: Stocktake}>(this.strBaseUrl + 'stocktake/get', objParams.toString())
      .map(response => {
        return (response.data) ? new Stocktake(response.data) : null;
      });

  }

  /**
   * Retrieves the stock levels based on the given ids.
   *
   * @param {string[]} arIds
   * @param {string} strWarehouseId
   *
   * @returns {Observable<StockLevel[]>}
   */
  getStockLevels(arIds: string[], strWarehouseId: string): Observable<StockLevel[]> {
    return this.http.post<{data: StockLevel[]}>(this.strBaseUrl + 'stock_levels/get', new URLSearchParams({
      ids: JSON.stringify(arIds),
      warehouse_id: strWarehouseId
  }).toString())
      .map(response => {
        return response.data.map(item => new StockLevel(item));
      });
  }

  /**
   * Generate the purchase orders from the selected
   * items in the reorder screen.
   *
   * @param {email_to_supplier: boolean, line_items: {item_id: string, supplier_id: string, reorder_quantity: number}[]} arData
   *
   * @returns {Observable<{message: string}>}
   */
  generateReorderItems(
    arData: {
      email_to_supplier: boolean,
      line_items: {item_id: string, supplier_id: string, reorder_quantity: number}[]
    }
  ): Observable<{message: string}> {
    return this.http.post<any>(this.strBaseUrl + 'reorder/generate', new URLSearchParams({
      data: JSON.stringify(arData)
    }).toString()).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.errors.publish({
              type: ErrorEventType.HTTP_ERROR,
              status: 403,
              for: `stock_management`,
            });
          }
        },
      })
    );
  }

  /**
   * Generate the purchase orders from the selected
   * items in the reorder screen.
   *
   * @param {string} strPageData
   * @param {string} strFilterData
   *
   * @returns {Observable<{data: any[], hasNextToken: boolean}>}
   */
   listReorderItems(strPageData: string, strFilterData: string): Observable<any> {
    return this.http.post<any>(this.strBaseUrl + 'reorder/list', new URLSearchParams({
      filter: strFilterData,
      page: strPageData
    })).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.errors.publish({
              type: ErrorEventType.HTTP_ERROR,
              status: 403,
              for: `stock_management`,
            });
          }
        },
      })
    );
  }

  /**
   * Retrieves the stock levels based on the given ITEM ids.
   *
   * @param {string[]} arIds
   * @param {string} strWarehouseId
   *
   * @returns {Observable<StockLevel[]>}
   */
   getStockLevelsByItem(arIds: string[], strWarehouseId: string): Observable<StockLevel[]> {
    return this.http.post<{data: any}>(this.strBaseUrl + 'stock_levels/get_by_item', new URLSearchParams({
      ids: JSON.stringify(arIds),
      warehouse_id: strWarehouseId
  }).toString())
      .map(response => {
        return response.data.map(item => new StockLevel({
          current_stock_level: item.current_stock_level,
          description: item.description,
          id: item.stock_level_id,
          item_id: item.id,
          item_name: item.name,
          preferred_supplier: item.preferred_supplier,
          product_code: item.code,
          product_name: item.name,
          location: item.location,
          quantity: item.stock_level_quantity || 0,
          unit: item.unit,
          unit_price: item.unit_price
        } as any));
      });
  }

  /**
   * Retrieves the stock levels based on the given ITEM ids.
   *
   * @param {string[]} arIds
   * @param {string} strWarehouseId
   *
   * @returns {Observable<StockLevel[]>}
   */
  getStockLevelsRelate(strTerm: string, strWarehouseId: string): Observable<StockLevel[]> {
    return this.http.post<{data: any}>(this.strBaseUrl + 'stock_levels/relate', new URLSearchParams({
      term: strTerm,
      warehouse_id: strWarehouseId
  }).toString())
      .map(response => {
        return response.data.map(item => new StockLevel({
          current_stock_level: item.current_stock_level,
          description: item.description,
          id: item.id,
          item_id: item.item_id,
          item_name: item.name,
          preferred_supplier: item.preferred_supplier,
          product_code: item.code,
          product_name: item.name,
          location: item.location,
          quantity: item.quantity || 0,
          unit: item.unit,
          unit_price: item.unit_price
        } as any));
      });
  }

  /**
   * Get the stocktake record.
   *
   * @param {string} strId
   * @param {string} strWarehouseId
   *
   * @returns {Observable<Stocktake|null>}
   */
   listStocktake(strStocktakeDate: string = null): Observable<Stocktake[]> {

    let objParams = new URLSearchParams();

    if (strStocktakeDate) {
      objParams.append('stocktake_date', strStocktakeDate);
    }

    return this.http.post<{data: Stocktake[]}>(this.strBaseUrl + 'stocktake/list', objParams.toString())
      .map(response => {
        return response.data.map(item => {
          return new Stocktake(item);
        });
      });

  }

  /**
   * Allocate the stock and add validation to avoid
   * allocating more than the available stock.
   *
   * @param {MaterialInterface[]} arStockToAllocate
   *
   * @returns {Observable<{message: string}>}
   */
  allocateStock(arStockToAllocate: MaterialInterface[]): Observable<{message: string}> {
    return this.http.post<{message: string}>(this.strBaseUrl + 'stock_levels/allocate', new URLSearchParams({
      data: JSON.stringify(arStockToAllocate)
    }).toString());
  }

  getStockReceiptConfig(): Observable<any> {
    const moduleData = this.getParentData();
    return this.http.post<{message: string}>(this.strBaseUrl + 'stock_receipt/config', new URLSearchParams({
      module: moduleData.module,
      module_id: moduleData.id,
    }).toString());
  }


  /**
   * get current record url module and id
   *
   * @return {string}
   */
  getParentData(): { module: string, id: string } {
    let arUrl = this.router.url.split('/')
    arUrl.splice(0, 1);
    return {
      module: arUrl[0],
      id: (arUrl[1]) ? arUrl[1].split('?')[0] : ''
    };
  }
}
