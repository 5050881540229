
    <fieldmagic-dialog
      label="Select Inspection Checklist"
      icon="link"
      [instance]="self"
    >
      <ng-template fieldmagicDialogButtonsTmp>
        <fieldmagic-primary-button
          label="Attach"
          icon="link"
          [isInProgress]="linking$ | async"
          (click)="onAttach()"
        >
        </fieldmagic-primary-button>
      </ng-template>

      <ng-template fieldmagicDialogContentTmp>
        <div class="d-flex flex-column">
          <fieldmagic-alert
            content="Multiple inspection checklist has been detected for the current asset. Please click the edit button to edit the response."
          >
          </fieldmagic-alert>

          <div class="w-100 d-flex justify-content-center">
            <div class="d-flex flex-column d-flex-gap-2x w-75">
              <div
                *ngFor="let checklist of (checklists$ | async)"
                fieldmagicGridRow
              >
                <div fieldmagicGridColumn="6">
                  <fieldmagic-text
                    [content]="checklist.name"
                  >
                  </fieldmagic-text>
                </div>
                <div
                  class="d-flex justify-content-center"
                  fieldmagicGridColumn="6"
                >
                  <fieldmagic-primary-button
                    label="Edit"
                    icon="pencil"
                    (click)="onEdit(checklist)"
                  >
                  </fieldmagic-primary-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </fieldmagic-dialog>
  