<span class="dropdown nowrap filter-button">

  <span class="pr-3 span-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <fa-layers class="pr-1">
      <fa-icon [icon]="['fal', 'sort']"></fa-icon>
      <fa-icon [icon]="['fas', 'sort-down']"></fa-icon>
    </fa-layers>
    {{ 'sort' | translate }}
  </span>
  <div class="dropdown-menu center-align sort-dropdown">
    <ng-container *ngIf="!arSortOption; else defaultSortOptions">
      <span class="dropdown-item-filter">{{ 'no_sort_available' | translate }}</span>
    </ng-container>
    <ng-template #defaultSortOptions>
      <div *ngFor="let sortOption of arSortOption">
        <span class="m-0 dropdown-item-filter pointer"
          (click)="generateFilter(sortOption.value)">
          <fa-icon *ngIf="arModel['order_by'].id == sortOption.value"
            [icon]="['fas', (arModel['order_by'].sort == 'asc') ? 'caret-up' : 'caret-down']"
            aria-hidden="true" size="lg" class="text-center"></fa-icon>
          <fa-icon *ngIf="arModel['order_by'].id != sortOption.value" [icon]="['far', 'minus']"
            aria-hidden="true"></fa-icon>
            <span id="sort_{{sortOption.value}}" class="pl-1">{{ sortOption.id | translate }}</span>
        </span>
      </div>

    </ng-template>
  </div>

</span>