<app-error-handler onlyFor="stock_levels:list">
  <section>
    <div class="row">
      <div class="col-sm-12">
        <app-information strModule="stock_levels"
          [isPanel1Visible]= "true"
          [isPanel2Visible]= "false"
          [isPanel3Visible]= "false" >
        </app-information>
      </div>
      <div class="col-sm-12">


        <fm-listing
          [bShowAdd]="false"
          strModule="stock_levels"
          [bShowCustomList]="false"
          [objHeaderData]="objTableHeaderData"
          [bAllowCheckboxSelection]="true">

          <ng-template #customListExtraActionDropdown>

            <div class="dropdown-item">
              <fieldmagic-icon
                icon="box-open"
                [routerLink]="['reorder']"
                matTooltip="{{ 'reorder_info' | translate }}"
                id="hrefDialog"
              >
                <span>{{'reorder' | translate }}</span>
              </fieldmagic-icon>
            </div>

            <div class="dropdown-item">
              <fieldmagic-icon
                icon="people-carry"
                [routerLink]="['stocktake']"
                matTooltip="{{ 'stocktake_info' | translate }}"
                id="hrefDialog"
              >
                {{ 'create_stocktake' | translate }}
              </fieldmagic-icon>
            </div>

          </ng-template>

        </fm-listing>
      </div>
    </div>
  </section>
</app-error-handler>
