import { NgModule } from '@angular/core';
import { LinkChecklistButtonComponent, LinkChecklistDialogComponent } from './components/dialogs/link-checklist/link-checklist-dialog.component';
import { SharedModule } from '../../shared/modules/shared.module';
import { SharedInputModule } from '../../shared/modules/shared-input.module';
import { CommonModule } from '@angular/common';
import { DialogService } from '../../services/dialog.service';
import { ChecklistInputComponent } from './components/input/checklist-input.component';
import { ChecklistsService } from '../../services/checklist.service';
import { ChecklistResponseComponent } from '../../shared/components/widget/checklists/checklist-response/checklist-response.component';
import { ChecklistResponsePromptComponent } from '../../shared/components/widget/checklists/checklist-response/checklist-response-prompt/checklist-response-prompt.component';
import { OwlDateTimeModule } from 'ng-pick-datetime';
import { SelectSpecificChecklistDialogComponent, BasicInspectionChecklistDialogComponent, RenderPromptsButtonComponent } from './components/dialogs/render-prompts/render-prompts.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule.forChild(),
    SharedInputModule,
    OwlDateTimeModule,
  ],
  declarations: [
    LinkChecklistButtonComponent,
    LinkChecklistDialogComponent,
    ChecklistInputComponent,
    ChecklistResponsePromptComponent,
    ChecklistResponseComponent,
    RenderPromptsButtonComponent,
    SelectSpecificChecklistDialogComponent,
    BasicInspectionChecklistDialogComponent,
  ],
  entryComponents: [
    LinkChecklistDialogComponent,
    ChecklistResponseComponent,
    SelectSpecificChecklistDialogComponent,
    BasicInspectionChecklistDialogComponent,
  ],
  exports: [
    LinkChecklistButtonComponent,
    ChecklistInputComponent,
    ChecklistResponseComponent,
    RenderPromptsButtonComponent,
  ],
  providers: [
    DialogService,
    ChecklistsService,
  ],
})
export class ChecklistsModule {}