import { Component, Input } from '@angular/core';

@Component({
  selector: 'fieldmagic-alert',
  template: `
    <div
      class="alert"
      [class.alert-danger]="variant == 'error'"
      [class.alert-warning]="variant == 'warning'"
      [class.alert-success]="variant == 'success'"
      [class.alert-info]="variant == 'info'"
    >
      <small>
          <fa-icon [icon]="['fas', iconName]" class="mr-2"></fa-icon>

          <fieldmagic-text
            *ngIf="content | filled"
            purpose="body"
            [content]="content"
          >
          </fieldmagic-text>

          <ng-content
            *ngIf="content | blank"
          >
          </ng-content>
      </small>
    </div>
  `
})
export class AlertComponent {
  @Input() variant: AlertComponentVariant = 'info';

  @Input() content?: string;

  get iconName(): string {
    if (this.variant == 'warning') {
      return 'exclamation-triangle';
    }

    if (this.variant == 'error') {
      return 'exclamation-circle';
    }

    if (this.variant == 'success') {
      return 'check-circle';
    }

    return 'info-circle';
  }
}

type AlertComponentVariant = 'warning' | 'error' | 'info' | 'success';
