<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'hashtag']" ></fa-icon>
        <span> {{ arAssetData.serial_number }} - {{ arAssetData.asset_type_text }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="saveStatus()" class="btn btn-primary">
            <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
            <span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="closeDialog()"
            icon="times"
            variant="error"
            label="close"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr class="separator"/>
<div class="form-container">
  <div class="d-flex flex-column d-flex-gap">
    <div class="row">
      <div fieldmagicGridColumn="3" class="label">
        {{ 'status' | translate }}
      </div>
      <div fieldmagicGridColumn="9">
          <ng-select
            class="input-select"
            [items]="arStatus"
            [clearable]="true"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="strStatus"
            appendTo="body"
          >
          <!-- DISPLAY IN SELECTED -->
          <ng-template ng-label-tmp let-item="item">
            <span class="ng-value-label">{{ item | translate }}</span>
          </ng-template>
          <!-- DISPLAY IN OPTION -->
          <ng-template  ng-option-tmp let-item="item">
            <span class="value-text"> {{ item | translate }} </span>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <ng-container *ngIf="strStatus == 'fail'">
      <div class="row">
        <div fieldmagicGridColumn="3" class="label">
          {{ 'fault_details' | translate }}
        </div>
        <div fieldmagicGridColumn="9">
          <fieldmagic-textarea-input [(ngModel)]="faultDetails"></fieldmagic-textarea-input>
        </div>
      </div>
    </ng-container>
  </div>
</div>