import { LooseObject } from "./loose-object";
import { get, isArray, isEmpty } from 'lodash-es';
import { data_get, filled } from '../shared/utils/common';

export class GlobalRecord {

  /**
   * The ID of the record
   *
   * @var {string}
   */
  id: string;

  /**
   * Usually the identifying name of the record
   * such as job number for job, first name and last
   * name for lead, and so on.
   *
   * @var {string}
   */
  name: string;

  /**
   * Which module this record belongs to.
   *
   * @var {string}
   */
  module: string;

  /**
   * The match score converted to percentage
   * based on the search term.
   *
   * @var {number}
   */
  match_percentage: number;

  /**
   * Other properties of this global record.
   *
   * @var {LooseObject}
   */
  other_properties: LooseObject;

  constructor(
    properties: any,
    score?: number,
    max_score?: number
  ) {

    let names: string | string[] = data_get(properties, 'display_names');

    if (isArray(names)) {
      names = names.filter((display) => filled(display) && display != '[]').join(' ');
    }

    this.id = properties.id;
    this.name = names;
    this.module = properties.module;
    this.other_properties = properties.other_properties !== undefined ? properties.other_properties : {};

    if (score && max_score) {
      this.match_percentage = (score / max_score) * 100;
    } else {
      this.match_percentage = properties.match_percentage || 0;
    }

    Object.keys(properties).forEach(index => {
      if (!['id', 'name', 'module'].includes(index)) {
        this.other_properties[index] = properties[index];
      }
    });

    if (this.module == 'users' && this.other_properties['user_id'] !== undefined) {
      this.id = this.other_properties['user_id'];
    }

    if (this.module === 'sites') {
      // FC-4360: if site record has 'location_nickname', make it visible in the result
      if (get(properties, 'display_names.length', 0) === 2) {
        const strLocationNickname: string = properties.display_names[1] || '';
        const strAddressString: string = properties.display_names[0] || '';

        if (filled(strLocationNickname.trim()) && strLocationNickname !== '[]') {
          this.name = `${strLocationNickname}: ${strAddressString}`;
        } else {
          this.name = strAddressString;
        }
      }

      // Reduces multiple commas to a single comma. Sample cases below.
      // "242 Sample Road,,,Sample City" -> "242 Sample Road,Sample City"
      // "123 Tram Road, ,  ,,, ,    , Perth, WA" -> "123 Tram Road, Perth, WA"
      if (this.name) {
        this.name = this.name.replace(/,[,\s]+,/g, ',');
      }
    }
  }
}
