<div class="d-flex justify-content-around contacts-option tabs-content">
    <span id="widgetMaterialsCreateButton" (click)="logMaterialsPricebookData()" class="span-links">
        <fa-icon [icon]="['fas', 'abacus']" class="text-success"></fa-icon>
        {{ 'log_materials' | translate }}
    </span>
    <span id="widgetMaterialsCreateButton" (click)="openCreateMaterialsFromWo()" class="span-links">
        <fa-icon [icon]="['fas', 'box-full']" class="text-success"></fa-icon>
        {{ 'create_materials_from_wo' | translate }}
    </span>

    <span id="widgetMaterialsCreateButtonFromPO" (click)="openAddMaterialsToJob()" class="span-links">
        <fa-icon [icon]="['fas', 'box-full']" class="text-success"></fa-icon>
        {{ 'create_from_po' | translate }}
    </span>
    <ng-container *fcHasPermission="'stock_management'">
        <ng-container *fcMinSubscriptionPlanRequired="'fieldmagic-enterprise'">
            <span id="widgetMaterialsAllocateStockButton" (click)="allocateFromWarehouse()" class="span-links">
                <fa-icon [icon]="['fas', 'warehouse-alt']" class="text-success mr-1"></fa-icon>
                {{ 'allocate_stock' | translate }}
            </span>
        </ng-container>
    </ng-container>
</div>

<div class="contact-roles-body">
    <div class="container">
        <div class="row pt-2 pb-2">
            <div class="col-sm-6 pt-1">
                <span class="module-title">
                    <fa-icon [icon]="['fa', 'box-full']" aria-hidden="true"></fa-icon>
                    &nbsp;
                    {{ 'materials' | translate }}
                </span>
            </div>
            <div class="col-sm-6 pr-2">
                <nav class="pagination-section">
                    <ul class="pagination">
                        <refresh-pagination-btn id="widgetMaterialsRefreshButton" (refresh)="onRefresh()" [isDisabled]="! bPageLoaded"></refresh-pagination-btn>
                        <li [ngClass]="(listService.strFirstValue != listService.strCurrentValue && listService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetMaterialsPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev')">
                                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                        <li [ngClass]="(listService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetMaterialsNextButton" class="page-link" aria-label="first" (click)="fetchList('next')">
                                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div *ngIf="arMaterials.length < 1">
            <div class="row border-top" *ngIf="bPageLoaded">
                <span class="no-content">
                    {{ 'no_materials' | translate }}
                </span>
            </div>
            <div class="row border-top" *ngIf="!bPageLoaded">
                <span class="no-content">
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                    {{ 'loading' | translate }}
                </span>
            </div>
        </div>

        <div class="row border-top p-2"  *ngFor="let material of arMaterials">
            <div class="col-sm-4">
                <div class="row">
                    <div class="col-12">
                        <span *ngIf="material.item_id && material['type'] != 'once_off_purchase'; else onceOffLabel" matTooltip="{{ 'product_code' | translate }}: {{ material.code | translate }}">
                            {{ material.code }}
                        </span>
                        <ng-template #onceOffLabel><span>({{ 'once_off_purchase' | translate }})</span></ng-template>
                    </div>
                </div>
                <div class="row" matTooltip="{{ 'product_name' | translate }}: {{ getDisplayText(material) | placeholdWithString }}">
                    <div class="col-12">
                        <fa-icon class="icon-spacer" [icon]="['fas', 'box-open']"></fa-icon>&nbsp;
                        <span>{{ getDisplayText(material) | placeholdWithString }}</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="row" matTooltip="{{ 'unit_cost' | translate }}: {{ material.unit_cost | currency }}">
                    <div class="col-12">
                        <span>{{ material.unit_cost | currency }}</span>
                    </div>
                </div>
                <div class="row" matTooltip="{{ 'quantity' | translate }}: {{ material.quantity }}">
                    <div class="col-12">
                        <fa-icon class="icon-quanity-spacer" [icon]="['fas', 'cart-plus']"></fa-icon>&nbsp;
                        <span>{{ material.quantity }}</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div *ngIf="!material.purchase_order_line_item_id" class="row" matTooltip="{{ 'notes' | translate }}: {{  (material.notes) ? material.notes : '--' }}">
                    <div class="col-12">
                        <span *ngIf="material.notes">
                            {{ material.notes | slice:0:75 }}
                            <span *ngIf="material.notes.length > 75">...</span>
                        </span>
                        <span *ngIf="!material.notes">--</span>
                    </div>
                </div>

                <div *ngIf="material.purchase_order_line_item_id" class="row" matTooltip="{{ 'supplier_name' | translate }}: {{  (material.customer_text) ? material.customer_text : '--' }}">
                    <div class="col-12">
                        <a id="customerLabel" [routerLink]="['/customers/'+material.customer_id]" target="_blank">
                            <span> {{ material.customer_text }}</span>
                        </a>
                    </div>
                </div>

                <div *ngIf="material.purchase_order_line_item_id" class="row" matTooltip="{{ 'po_number' | translate }}: {{ material.po_number }}">
                    <div class="col-12">
                        <a id="poNumberLabel" [routerLink]="['/purchase_orders/'+material.purchase_order_id]" target="_blank">
                            <span> #{{ material.po_number }}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-1 d-table text-role less-padding">
                <div id="widgetMaterialsViewButton" class="row" *ngIf="material.material_image && material.material_image_url" (click)="viewThumbnail(material.material_image_url)">
                    <div class="col-12" matTooltip="{{ 'view_image' | translate }}">
                        <fa-icon class="text-primary pointer float-right" [icon]="['fas', 'eye']"></fa-icon>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <fa-icon id="widgetMaterialsEditButton" *ngIf="material.customer_invoice_id == '' || material.customer_invoice_id == null" class="text-success pointer float-right" [icon]="['fas', 'pencil']" (click)="openMaterial(material)"></fa-icon>
                        <a *ngIf="material.customer_invoice_id != '' && material.customer_invoice_id != null" href="" (click)="openCI(material)" [routerLink]="">
                          <fa-icon id="widgetMaterialsEditButton" class="text-secondary pointer float-right" [icon]="['fas', 'pencil']" (click)="''" matTooltip="{{ 'material_linked' | translate }}{{ material.invoice_number }} {{ 'cannot_be_updated' | translate }}"></fa-icon>
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <fa-icon id="widgetMaterialsDeleteButton" *ngIf="material.customer_invoice_id == '' || material.customer_invoice_id == null" class="text-warning pointer float-right" [icon]="['fas', 'trash-alt']" (click)="deleteMaterial(material.id)"></fa-icon>
                        <a *ngIf="material.customer_invoice_id != '' && material.customer_invoice_id != null" href="" (click)="openCI(material)" [routerLink]="">
                          <fa-icon id="widgetMaterialsDeleteButton" class="text-secondary pointer float-right" [icon]="['fas', 'trash-alt']" (click)="''" matTooltip="{{ 'material_linked' | translate }}{{ material.invoice_number }} {{ 'cannot_be_deleted' | translate }}"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>