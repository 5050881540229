export enum ActivityType {
    event = 'event',
    note = 'note',
    task = 'task',
    email = 'email',
    message = 'message'
};

export enum Priority {
    high = 'high',
    normal = 'normal',
    low = 'low'
};

export enum ActivityIcon {
    event = 'calendar-alt',
    note = 'sticky-note',
    task = 'tasks',
    email = 'envelope',
    message = 'comments'
};

export const MODULE_ID_MAPPING = {
  'leads': 'lead_id',
  'customers': 'customer_id',
  'sites': 'site_id',
  'contacts': 'contact_id',
  'opportunities': 'opportunity_id',
  'quotes': 'quote_id',
  'jobs': 'job_id',
  'assets': 'asset_id',
  'customer_invoices': 'customer_invoice_id',
  'purchase_orders': 'purchase_order_id',
  'supplier_invoices': 'supplier_invoice_id',
}
