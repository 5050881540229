import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Response } from '@angular/http';
import { Observable, Subject } from 'rxjs';
//Get the base url from the environment file.
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { TranslateService } from '@ngx-translate/core';
import { Config } from 'protractor';
import { Select } from '../objects/select';
import { isEmpty } from 'lodash-es';
const kBaseUrl: string = environment.url + "/contact_roles/";

@Injectable()

export class ContactRolesService {

  constructor(private http: HttpClient, private router: Router, public translate: TranslateService) { }

  /**
   * Update primary contact roles.
   *
   * @param strWidgetName
   * @param strModule
   * @param arData
   * @param strId
   */
  updatePrimaryContact(strWidgetName, strModule, arData, strId = null): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('module', strModule);
    body.append('widget_name', strWidgetName);
    body.append('data', JSON.stringify(arData));
    body.append('id', strId);
    // Post request
    return this.http.post<Response>(kBaseUrl+ 'update_primary_contact', body.toString(), { observe: 'response' });
  }

  /**
   * translate role and merge values
   * unshift the primary role so it will be visible in the display
   *
   * @param arRoles
   */
  transformRole(arRoles: Select[]): string {
    var arCompiledText = [];
    let strPrimaryText = this.translate.instant("primary");
    let strNoRoleAssigned = this.translate.instant('no_role_assigned');
    if (Array.isArray(arRoles)) {
      arRoles.forEach( option => {
        if (option.id) {
          let optionText = this.translate.instant(option.id);
          if (option.primary) {
            arCompiledText.unshift(`${optionText} (${strPrimaryText})`)
          } else {
            arCompiledText.push(optionText);
          }
        }
      });
      return !isEmpty(arCompiledText) ? arCompiledText.join(", ") : strNoRoleAssigned;
    }
    return strNoRoleAssigned;
  }
}
