<fieldmagic-dialog>
  <div class="dialog-header-content">
    <fieldmagic-dialog-title
      icon="plus-circle"
      label="add_checklist"
    >
    </fieldmagic-dialog-title>
  </div>

  <div class="dialog-header-buttons">
    <fieldmagic-primary-button
      icon="save"
      label="save"
      [isInProgress]="saving$ | async"
      (click)="onSave()"
    >
    </fieldmagic-primary-button>

    <fieldmagic-dialog-close-button
      [disabled]="saving$ | async"
      (click)="onClose()"
    >
    </fieldmagic-dialog-close-button>
  </div>

  <div class="dialog-content">
    <div
      fieldmagicGridRow
    >
      <div
        fieldmagicGridColumn="12"
      >
        <fieldmagic-text-input
          [(ngModel)]="name"
          [errors]="errors | data_get: 'name'"
          label="Name"
          [standalone]="false"
          [withRequiredMarker]="true"
        >
        </fieldmagic-text-input>
      </div>
    </div>
  </div>
</fieldmagic-dialog>