import { Component, Inject, OnInit } from '@angular/core';
import { LooseObject } from '../../../../objects/loose-object';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { get, isEmpty, toString } from 'lodash-es';
import { toFormattedNumber } from '../../../../shared/utils/numbers';

@Component({
  selector: 'app-select-work-order-items',
  templateUrl: './select-work-order-items.component.html',
  styleUrls: ['./select-work-order-items.component.scss']
})
export class SelectWorkOrderItemsComponent implements OnInit {

  public isFromJob: boolean = false;
  public isSelectAll: boolean = false;
  public lineItems: LooseObject;
  public selectedLineItems: Array<LooseObject> = [];
  public lineItemsFromParent: Array<LooseObject> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private dialog: MatDialogRef<SelectWorkOrderItemsComponent>,
  ) {}

  ngOnInit() {
    this.isFromJob = !isEmpty(get(this.dialogData, 'job_id', ''));
    this.lineItems = get(this.dialogData, 'work_order_items', []);
    this.lineItems.map(lineItem => {
      lineItem.lineTotal = get(lineItem, 'computeLineItem', get(lineItem, 'line_item', 0));

      return lineItem;
    });

    this.lineItemsFromParent = get(this.dialogData, 'current_line_items', []);
  }

  /**
   * Simply closes the dialog.
   *
   * @returns {void}
   */
  cancelDialog(): void {

    this.dialog.close({
      action: 'close'
    });
  }

  /**
   * Adds all the line items of the purchase order to job's materials
   *
   * @returns {void}
   */
  addWorkOrderItems(): void {
    this.dialog.close({
      'work_order_items': this.selectedLineItems,
    });
  }

  onChangeCheckbox(event, lineItem = [], isAll) {
    if (isAll && event.currentTarget['checked'] == true) {
      this.selectedLineItems = [];

      this.lineItems.forEach(item => {
        if (!this.isSelectedFromParentLineItems(item)) {
          this.selectedLineItems.push(item);
        }
      });
      this.isSelectAll = true;
    } else if (isAll && event.currentTarget['checked'] == false) {
      this.selectedLineItems = [];
      this.isSelectAll = false;
    } else {
      if (event.currentTarget['checked'] == true) {
        this.selectedLineItems.push(lineItem);
      } else {
        let findIndex = this.selectedLineItems.findIndex(item => item['work_order_reference'] == lineItem['work_order_reference']);
        this.selectedLineItems.splice(findIndex, 1);
        this.isSelectAll = false;
      }

      this.isSelectAll = this.isSelectedAllLineItem();
    }
  }

  /**
   * This will check if the given line item is already selected
   * @param currentLineItem
   * @returns
   */
  isLineItemSelected(currentLineItem): boolean {
    return this.selectedLineItems.findIndex(item => item['work_order_reference'] == currentLineItem['work_order_reference']) >= 0;
  }

  /**
   * This will check if the given line item is already
   * selected from parent component to disabled selecting
   * @param lineItem
   * @returns
   */
  isSelectedFromParentLineItems(lineItem): boolean {
    let findIndex = this.lineItemsFromParent.findIndex(item => get(item, ['purchase_order', 'work_order_reference']) == lineItem['work_order_reference']);

    return findIndex > -1;
  }

  /**
   * This will check if all line items are already selected
   * @returns
   */
  isSelectedAllLineItem(): boolean {
    let isAllLineItemSelected = true;

    for (let i = 0; i < this.lineItems.length; i++) {
      let isLineItemSelected = this.isLineItemSelected(this.lineItems[i]);
      let isLineItemSelectedFromParent = this.isSelectedFromParentLineItems(this.lineItems[i]);

      if (!isLineItemSelected && !isLineItemSelectedFromParent) {
        isAllLineItemSelected = false;
       break;
      }
    }

    return isAllLineItemSelected;
  }

  formatNumber(value) {
    return toString(toFormattedNumber(value));
  }
}
