
    <mat-slide-toggle
      *ngIf="!useNative; else native"
      [id]="id"
      [disabled]="isDisabled$ | async"
      class="font-size-11"
      [(ngModel)]="value"
      (change)="onSwitchChange($event)"
      (blur)="onTouched()"
      [matTooltip]="tooltip | translate"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </mat-slide-toggle>

    <ng-template #native>
      <div class="d-flex d-flex-gap">
        <input
          type="checkbox"
          [(ngModel)]="value"
          [id]="id"
          [disabled]="isDisabled$ | async"
          (change)="onNativeChange($event.target)"
        />
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </ng-template>

    <ng-template #content>
      <span
        *ngIf="label | filled"
        class="text-label"
      >
        {{ label | translate }}
      </span>
      <ng-content *ngIf="label | blank"></ng-content>
    </ng-template>
  