
<fieldmagic-async-content
  [loading]="fetching$ | async"
>
  <ng-template
    fieldmagicAsyncLoadedContent
  >
    <fieldmagic-page-content-layout
      [title]="displayName"
      icon="clipboard-list"
    >
      <!-- HEADER -->
      <div class="page-content-header-actions">
        <fieldmagic-page-go-back-button
          [disabled]
          target="list"
          link="checklists"
          [disabled]="(deleting$ | async) || (saving$ | async)"
        >
        </fieldmagic-page-go-back-button>
        <fieldmagic-primary-button
          [isInProgress]="deleting$ | async"
          [disabled]="saving$ | async"
          purpose="delete"
          (click)="onDelete()"
        ></fieldmagic-primary-button>
        <fieldmagic-primary-button
          [isInProgress]="saving$ | async"
          [disabled]="saving$ | async"
          purpose="save"
          (click)="onSave()"
        ></fieldmagic-primary-button>
      </div>
      <!-- END HEADER -->

      <!-- CONTENT -->
      <div class="page-content-body">
        <!-- Configurations -->
        <div fieldmagicGridRow class="mb-4">
          <div
            fieldmagicGridColumn="6"
          >
            <fieldmagic-text-input
              [standalone]="false"
              [(ngModel)]="name"
              label="Name"
              [withRequiredMarker]="true"
              [errors]="errors | data_get: 'name'"
            >
            </fieldmagic-text-input>
          </div>

          <div
            *ngIf="type == 'asset'"
            fieldmagicGridColumn="6"
          >
            <fieldmagic-dropdown-input
              label="Available Periods"
              [(ngModel)]="availablePeriods"
              [multi]="true"
              [options]="periods$ | async"
              [withRequiredMarker]="true"
              placeholder="Please select period(s)"
              [errors]="errors | data_get: 'available_periods'"
            >
            </fieldmagic-dropdown-input>
          </div>

          <div
            fieldmagicGridColumn="3"
            class="d-flex align-items-center"
          >
            <fieldmagic-checkbox-input
              [(ngModel)]="isEnabled"
              label="Checklist Enabled"
            >
            </fieldmagic-checkbox-input>
          </div>

          <div
            fieldmagicGridColumn="3"
            class="d-flex align-items-center"
          >
            <fieldmagic-checkbox-input
              [(ngModel)]="isCommentEnabled"
              label="Enable comment per prompt"
            ></fieldmagic-checkbox-input>
          </div>
          <div
            fieldmagicGridColumn="3"
          >
            <fieldmagic-number-input
              [(ngModel)]="documentOrdering"
              label="Document Order"
              tooltip="This controls how this checklist will be ordered in the documents. The higher the value the least it will be prioritized."
              [errors]="errors | data_get: 'document_ordering'"
            >
            </fieldmagic-number-input>
          </div>

          <div
            fieldmagicGridColumn="3"
          >
            <fieldmagic-dropdown-input
              label="Required before"
              [options]="requierdBeforeOptions$ | async"
              [ngModel]="requiredBefore"
              bindValue="id"
              (change)="onRequiredBeforeChange($event)"
              tooltip="Make this checklist required to be completed when performing the given action in the mobile app."
            ></fieldmagic-dropdown-input>
          </div>

          <div
            fieldmagicGridColumn="3"
          >
            <fieldmagic-dropdown-input
              label="Automatically attached on"
              [options]="attachedOnOptions$ | async"
              [ngModel]="autoAttachedOn"
              bindValue="id"
              tooltip="Make this checklist automatically attached based on a given condition."
              (change)="onAttachedOnChange($event)"
              [grouped]="true"
              [multi]="true"
            ></fieldmagic-dropdown-input>
          </div>
        </div>
        <!-- END configurations -->

        <!-- Actions -->
        <div fieldmagicGridRow class="mb-4">
          <div
            class="d-flex align-items-center"
            fieldmagicGridColumn="6"
          >
            <fieldmagic-text
              purpose="title"
              content="Prompts"
            >
            </fieldmagic-text>
          </div>

          <div
            class="d-flex d-flex-gap justify-content-end"
            fieldmagicGridColumn="6"
          >
            <fieldmagic-primary-button
              variant="link"
              [icon]="[{
                icon: 'plus-circle',
                klass: 'text-success'
              }]"
              (click)="onAddPrompt()"
              matTooltip="{{ 'add_prompt' | translate }}"
              matTooltipPosition="above"
              label="Add prompt"
              size="lg"
            >
            </fieldmagic-primary-button>
            <fieldmagic-primary-button
              variant="link"
              [icon]="[{
                icon: 'plus-circle',
                klass: 'text-success'
              }]"
              (click)="onGroupPromptAdd()"
              matTooltip="{{ 'add_repeating_group_tooltip' | translate }}"
              matTooltipPosition="above"
              label="Add repeating group"
              size="lg"
            >
            </fieldmagic-primary-button>
          </div>
        </div>
        <!-- END Actions -->

        <!-- Prompts -->
        <div fieldmagicGridRow>
          <div fieldmagicGridColumn="12">
            <table
              class="table table-striped w-100"
            >
              <thead>
                <tr>
                  <th class="theader header-empty"></th>
                  <th class="theader header-prompt">
                    {{ 'prompt' | translate }}
                  </th>
                  <th class="theader header-type">
                    {{ 'type' | translate }}
                  </th>
                  <th class="theader header-value">
                    {{ 'value' | translate }}
                  </th>
                  <th
                    *ngIf="type == 'asset'"
                    class="theader header-value"
                  >
                    {{ 'schedule_type' | translate }}
                  </th>
                  <th class="theader header-icons">
                    <fieldmagic-icon
                      position="center"
                      icon="asterisk"
                      class="text-danger"
                      matTooltip="{{ 'tooltip_required' | translate }}"
                    >
                    </fieldmagic-icon>
                  </th>
                  <th class="theader header-icons">
                    <fieldmagic-icon
                      position="center"
                      icon="desktop"
                      matTooltip="{{ 'tooltip_report_visibility' | translate }}"
                    ></fieldmagic-icon>
                  </th>
                  <th class="theader header-icons">
                    <fieldmagic-icon
                      position="center"
                      icon="file-image"
                      matTooltip="{{ 'add_image_to_prompt' | translate }}"
                    ></fieldmagic-icon>
                  </th>
                  <th class="theader header-prompt-actions"></th>
                </tr>
              </thead>

              <tbody
                *ngIf="prompts$ | async as prompts"
                class="prompt-container outer"
                [cdkDropListEnterPredicate]="isDroppable"
                [cdkDropListConnectedTo]="getRepeatingGroupsClassNames(prompts)"
                (cdkDropListDropped)="onPromptDragDropped($event, {
                  prompts: prompts
                })"
                cdkDropList
              >
                <ng-container *ngIf="prompts | filled; else noPrompts;">
                  <ng-container *ngFor="let prompt of prompts; index as prompt_index">
                    <tr
                      *ngIf="! prompt.is_group"
                      class="prompt-row"
                      app-checklist-prompt
                      [index]="prompt_index"
                      [prompt]="prompt"
                      (removed)="onPromptRemove($event, prompts)"
                      [cdkDragData]="prompt"
                      cdkDrag
                      [fieldEntries]="fieldEntries$ | async"
                      [assetType]="assetType"
                      [forType]="type"
                      [errors]="errors"
                      [scheduleTypes]="availablePeriods"
                    >
                      <td class="text-center">
                        <fieldmagic-icon
                          purpose="dragging"
                          icon="grip-vertical"
                          matTooltip="{{ 'drag_to_change_position' | translate }}"
                          cdkDragHandle
                          position="center"
                        ></fieldmagic-icon>
                      </td>
                    </tr>
                    <tr
                      *ngIf="prompt.is_group"
                      class="prompt-row"
                      [cdkDragData]="prompt"
                      (mouseout)="onHover(true)"
                      (mouseover)="onHover(false)"
                      cdkDrag
                    >
                      <td
                        class="p-0"
                        [attr.colspan]="(type == 'asset') ? 9 : 8"
                      >
                        <table
                          class="w-100 group-prompt-background rounded-top"
                        >
                          <tbody
                            id="repeating-group-{{ prompt_index }}-header"
                            [cdkDropListConnectedTo]="getConnectedDroppableLists(prompt_index, prompts)"
                            (cdkDropListDropped)="onPromptDragDropped($event, {
                              prompts: prompts,
                              group: prompt
                            })"
                            cdkDropList
                          >
                            <tr class="group-name-row">
                              <td
                                class="border-top-0 header-icons"
                              >
                                <fieldmagic-icon
                                  purpose="dragging"
                                  icon="grip-vertical"
                                  class="text-white"
                                  position="center"
                                  cdkDragHandle
                                  matTooltip="{{ 'drag_to_change_position' | translate }}"
                                ></fieldmagic-icon>
                              </td>
                              <td
                                class="text-center border-top-0 pl-0 pr-0 pb-4 pt-4"
                                [attr.colspan]="(type == 'asset') ? 9 : 8"
                              >
                                <div class="d-flex align-items-center d-flex-gap-2x">
                                  <fieldmagic-text-input
                                    class="w-50"
                                    placeholder="Group name"
                                    [(ngModel)]="prompt.group_name"
                                    [errors]="errors | data_get: ('%s:prompt' | sprintf: [prompt.id])"
                                  >
                                  </fieldmagic-text-input>
                                  <ng-container *ngIf="type == 'unified'">
                                    <div
                                      class="w-30 d-flex d-flex-gap-2x align-items-center text-left"
                                    >
                                      <fieldmagic-relate-input
                                        [(ngModel)]="prompt.asset_type_id"
                                        [standalone]="true"
                                        class="w-100"
                                        placeholder="Select asset type"
                                        moduleName="asset_types"
                                        (change)="onAssetTypeChange($event, prompt)"
                                      ></fieldmagic-relate-input>
                                      <fieldmagic-dropdown-input
                                        *ngIf="prompt.asset_type_id | filled"
                                        [(ngModel)]="prompt.available_period"
                                        [standalone]="true"
                                        placeholder="Select available period(s)"
                                        class="w-100"
                                        [options]="periods$ | async"
                                        [clearable]="false"
                                      ></fieldmagic-dropdown-input>
                                    </div>

                                    <fieldmagic-primary-button
                                      purpose="icon"
                                      [icon]="[{
                                        icon: 'ellipsis-h',
                                        klass: 'text-white'
                                      }]"
                                      matTooltip="More group confguration"
                                      (click)="onOpenPerGroupPromptConfiguration(prompt)"
                                    ></fieldmagic-primary-button>
                                  </ng-container>
                                  <fieldmagic-primary-button
                                      purpose="icon"
                                      iconSize="lg"
                                      [icon]="[
                                      {
                                        icon: 'plus-circle',
                                        klass: 'text-success',
                                        size: 'sm'
                                      },
                                      {
                                        icon: 'circle',
                                        klass: 'text-white',
                                        size: 'sm'
                                      }
                                    ]"
                                    (click)="onAddPrompt({
                                      prompts: prompts,
                                      group: prompt
                                    })"
                                    matTooltip="{{ 'add_prompt_under_group' | translate }}"
                                  ></fieldmagic-primary-button>
                                </div>
                              </td>
                              <td
                                class="border-top-0 pr-4"
                              >
                                <div class="d-flex justify-content-end">
                                  <fieldmagic-primary-button
                                    purpose="delete-icon"
                                    (click)="onPromptRemove(prompt_index, prompts)"
                                  >
                                  </fieldmagic-primary-button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          class="table table-striped mb-0 group-prompt-border"
                        >
                          <tbody
                            id="repeating-group-{{ prompt_index }}"
                            class="prompt-container"
                            [cdkDropListConnectedTo]="getConnectedDroppableLists(prompt_index, prompts)"
                            (cdkDropListDropped)="onPromptDragDropped($event, {
                              prompts: prompts,
                              group: prompt
                            })"
                            cdkDropList
                          >
                            <tr
                              *ngFor="let sub_prompt of prompt.prompts; index as sub_index"
                              app-checklist-prompt
                              cdkDrag
                              class="prompt-row"
                              [parent]="prompt"
                              [prompt]="sub_prompt"
                              [index]="sub_index"
                              [parentIndex]="prompt_index"
                              [cdkDragData]="sub_prompt"
                              [fieldEntries]="fieldEntries$ | async"
                              (removed)="onPromptRemove(sub_index, prompts, {
                                group: prompt
                              })"
                              [assetType]="assetType || (prompt | data_get: 'asset_type_id')"
                              [availableOnPeriod]="prompt | data_get: 'available_period'"
                              [forType]="type"
                              [errors]="errors"
                              [scheduleTypes]="availablePeriods"
                            >
                              <td
                                class="indent-and-drag-asset"
                              >
                                <fieldmagic-icon
                                  purpose="dragging"
                                  icon="grip-vertical"
                                  cdkDragHandle
                                  matTooltip="{{ 'drag_to_change_position' | translate }}"
                                ></fieldmagic-icon>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
                <ng-template #noPrompts>
                  <tr class="text-center">
                    <td
                      [attr.colspan]="(type == 'asset') ? 9 : 8"
                    >
                      No prompts
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
        <!--End Prompts-->
      </div>
      <!-- END CONTENT -->
    </fieldmagic-page-content-layout>
  </ng-template>
</fieldmagic-async-content>