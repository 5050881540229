import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/modules/shared.module';
import { SharedInputModule } from '../../shared/modules/shared-input.module';
import { ChangePasswordDialogComponent } from './dialog/change-password/change-password-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule.forChild(),
    SharedInputModule,
  ],
  declarations: [
    ChangePasswordDialogComponent,
  ],
  entryComponents: [
    ChangePasswordDialogComponent,
  ],
  exports: [
    ChangePasswordDialogComponent,
  ],
})
export class SharedUsersModule {

}