import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountingSystemService } from '../../../../services/accounting_system.service';
import { ConfigurationService as AdminService } from '../../../../../../../services/configuration.service';
import { FormService, RelateField, DropdownField } from '../../../../../../../services/form.service';
import { Select } from '../../../../../../../objects/select';
import { get, assign } from 'lodash-es';
import { PROGRESS_SKIP } from '../../../connect/connect.component';

const createLaborOption = new Select('create_labor', 'create_labor');
@Component({
  selector: 'accounting-set-default-configuration',
  templateUrl: './tax-account-codes.component.html',
  providers: [
    FormService,
  ],
  styleUrls: [
    './tax-account-codes.component.scss',
  ]
})
export class AccountingSetDefaultConfiguration implements OnInit, OnDestroy {
  /**
   * Emitted event when updates are submitted to the server
   *
   * @var {EventEmitter<boolean>}
   */
  @Output('event-progress') progress: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  /**
   * Flag that will determine if this componetn would allow users to create a labor item
   *
   * @type {boolean}
   */
  @Input('allowCreateLabor') allowCreateLabor: boolean = false;

  /// a flag to use if this form can be skipped
  /// useful for scenarios where this for is part of a wizard
  @Input() canBeSkipped: boolean = false;

  /**
   * Flag if the current form is being processed
   *
   * @var {boolean}
   */
  isProcessing: boolean = false;

  /**
   * Instance of the form
   *
   * @var {FormGroup}
   */
  form: FormGroup = new FormGroup({
    purchase_tax_code_id: new FormControl(undefined, [Validators.required]),
    purchase_account_code_id: new FormControl(undefined, [Validators.required]),
    sales_tax_code_id: new FormControl(undefined, [Validators.required]),
    sales_account_code_id: new FormControl(undefined, [Validators.required]),
    adjustment_purchase_account_code_id: new FormControl(undefined, [Validators.required]),
    adjustment_purchase_tax_code_id: new FormControl(undefined, [Validators.required]),
    adjustment_sales_account_code_id: new FormControl(undefined, [Validators.required]),
    adjustment_sales_tax_code_id: new FormControl(undefined, [Validators.required]),
    item_id: new FormControl(undefined, [Validators.required]),
    item: new FormGroup({
      name: new FormControl(undefined),
      code: new FormControl('LABOUR')
    }),
  });

  /**
   * @type {Fields}
   */
  fields: Fields;

  /**
   * Flag that dictates whether the item custom form should be displayed
   *
   * @type {boolean}
   */
  shouldDisplayCustomItemForm: boolean = false;

  /**
   * Internal: List of subscriptions that should be cleaned up after component has been destroyed
   *
   * @private
   */
  private subscriptions: Subscription[] = [];

  /**
   * @param {AccountingSystemService} accounting
   * @param {AdminService} admin
   * @param {FormService} fb
   */
  constructor(
    private accounting: AccountingSystemService,
    private admin: AdminService,
    private fb: FormService,
  ) { }

  /**
   * {@inheritdoc}
   */
  ngOnInit() {
    this.subscriptions.push(
      this.admin.getAdminConfig().subscribe((response: unknown) => {
        let data = response as SavedConfiguration;

        this.buildFields(data);
      })
    );

    this.subscriptions.push(
      this.form.get('item_id').valueChanges.subscribe((value) => {
        this.onItemIsSelected(value);
      })
    );
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.accounting.unsetFlagForNoImportedItems();
  }

  /**
   * Handle form submission
   *
   * @returns {void}
   */
  onSubmit(): void {
    if (this.form.valid) {
      this.isProcessing = true;

      const data = this.form.value;

      if (data.item_id === 'create_labor') {
        data.item_id = null;
        data.should_create_item = true;
      }

      this.subscriptions.push(
        this.accounting.updateSystemDefaults$(data)
          .pipe(finalize(() => this.isProcessing = false))
          .subscribe((result) => this.progress.emit(result))
      )
    }
  }

  onSkip(): void {
    this.progress.emit(true);
  }

  /**
   * Handles item input changed action
   *
   * @param {string} selected
   *
   * @returns {void}
   */
  protected onItemIsSelected(selected?: string): void {
    this.shouldDisplayCustomItemForm = !!selected && selected === 'create_labor';

    let item: FormGroup = this.form.get('item') as FormGroup;
    let name: FormControl = item.get('name') as FormControl;
    let code: FormControl = item.get('name') as FormControl;

    if (this.shouldDisplayCustomItemForm) {
      name.setValidators([Validators.required]);
      code.setValidators([Validators.required]);
    } else {
      name.setValidators([]);
      code.setValidators([]);
    }

    // reinitialize validation
    // @see https://angular.io/api/forms/AbstractControl#setValidators
    name.updateValueAndValidity({
      emitEvent: false
    });
    code.updateValueAndValidity({
      emitEvent: false,
    });
  }

  /**
   * Flag for checking if labor creation would be needed that would show an item form that contaisn the labor information
   *
   * @type {boolean}
   */
  protected get isLaborCreationNeeded(): boolean {
    return this.allowCreateLabor && this.accounting.noImportedItems;
  }

  /**
   * Build the fields configuration
   *
   * @param   {SavedConfiguration} configuration
   *
   * @returns {void}
   */
  protected buildFields(configuration: SavedConfiguration): void {
    const purchaseFilter = { is_purchase: true, external_id: 'not_null' };
    const salesFilter = { is_sales: true, external_id: 'not_null' };
    const fields = [
      {
        form_key: 'item_id',
        config_key: 'default_item_id',
        module_name: 'items',
        filters: { active: true, accounting_id: 'not_null' }
      },
      {
        form_key: 'purchase_account_code_id',
        config_key: 'default_account_code_purchase',
        module_name: 'account_codes',
        filters: purchaseFilter,
      },
      {
        form_key: 'purchase_tax_code_id',
        config_key: 'default_tax_code_purchase',
        module_name: 'tax_codes',
        filters: purchaseFilter,
      },
      {
        form_key: 'sales_account_code_id',
        config_key: 'default_account_code_sale',
        module_name: 'account_codes',
        filters: salesFilter,
      },
      {
        form_key: 'sales_tax_code_id',
        config_key: 'default_tax_code_sale',
        module_name: 'tax_codes',
        filters: salesFilter,
      },
      {
        form_key: 'adjustment_purchase_account_code_id',
        config_key: 'adjustment_purchase_account_code',
        module_name: 'account_codes',
        filters: purchaseFilter,
      },
      {
        form_key: 'adjustment_purchase_tax_code_id',
        config_key: 'adjustment_purchase_tax_code',
        module_name: 'tax_codes',
        filters: purchaseFilter,
      },
      {
        form_key: 'adjustment_sales_account_code_id',
        config_key: 'adjustment_sales_account_code',
        module_name: 'account_codes',
        filters: salesFilter,
      },
      {
        form_key: 'adjustment_sales_tax_code_id',
        config_key: 'adjustment_sales_tax_code',
        module_name: 'tax_codes',
        filters: salesFilter,
      },
    ];

    fields.forEach((field) => {
      const isItemField = field.form_key === 'item_id';
      const shouldItemFieldIsCreateLabor = isItemField && this.isLaborCreationNeeded;
      const configValue = (shouldItemFieldIsCreateLabor) ? createLaborOption.id : get(configuration, field.config_key);
      const configValueText = get(configuration, (isItemField) ? 'default_item_text' : `${field.config_key}_text`);
      const metadata = this.fb.tranformFieldObject({
        key: field.form_key,
        type: (shouldItemFieldIsCreateLabor) ? 'dropdown' : 'relate',
        module: field.module_name,
        filter: field.filters,
        default_value: configValue,
        options: shouldItemFieldIsCreateLabor && [createLaborOption]
      }, {
        [field.form_key]: configValue,
        [`${field.form_key.replace(/_id/i, '')}_text`]: configValueText
      });

      this.form.get(field.form_key).patchValue(metadata.default_value);

      this.fields = assign(this.fields, {
        [field.form_key]: metadata
      });
    });
  }
}

export interface SavedConfiguration {
  default_item_id?: string;
  default_item_text?: string;
  default_account_code_purchase?: string;
  default_account_code_purchase_text?: string;
  default_account_code_sale?: string;
  default_account_code_sale_text?: string;
  default_tax_code_purchase?: string;
  default_tax_code_purchase_text?: string;
  default_tax_code_sale?: string;
  default_tax_code_sale_text?: string;
  adjustment_account_code_purchase?: string;
  adjustment_account_code_purchase_text?: string;
  adjustment_tax_code_purchase?: string;
  adjustment_tax_code_purchase_tax?: string;
  adjustment_account_code_sale?: string;
  adjustment_account_code_sale_text?: string;
  adjustment_tax_code_sale?: string;
  adjustment_tax_code_sale_text?: string;
}

type FieldKey = 'item_id'
  | 'purchase_tax_code_id'
  | 'purchase_account_code_id'
  | 'sales_tax_code_id'
  | 'sales_account_code_id'
  | 'adjustment_purchase_account_code_id'
  | 'adjustment_purchase_tax_code_id'
  | 'adjustment_sales_account_code_id'
  | 'adjustment_sales_tax_code_id';
type Fields = {
  [key in FieldKey]: DropdownField | RelateField
}
