
    <fieldmagic-icon
      *ngIf="(target$ | async) != field"
      icon="sort"
      class="pointer"
      (click)="onClick()"
    ></fieldmagic-icon>
    <fieldmagic-icon
      *ngIf="(target$ | async) == field && (direction$ | async) == 'asc'"
      icon="sort-up"
      class="pointer"
      (click)="onClick()"
    ></fieldmagic-icon>
    <fieldmagic-icon
      *ngIf="(target$ | async) == field && (direction$ | async) == 'desc'"
      icon="sort-down"
      class="pointer"
      (click)="onClick()"
    ></fieldmagic-icon>
  