<ng-container *fcHasPermission="strModule + ':create'; else disabledCreateButton">
  <span class="pr-3 span-link" (click)="create()">
    <fa-icon
      [icon]="['fas', 'plus-circle']"
      class="text-success pr-1">
    </fa-icon>{{ strAddLabel | translate }}
  </span>
</ng-container>
<ng-template #disabledCreateButton>
  <span class="pr-3 span-link appear-disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
    <fa-icon
      [icon]="['fas', 'plus-circle']"
      class="text-success pr-1">
    </fa-icon>{{ strAddLabel | translate }}
  </span>
</ng-template>