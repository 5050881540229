<div class="d-flex justify-content-around contacts-option tabs-content">
  <span id="widgetMaterialsCreateButton" class="span-links" (click)="openAddMaterialsToJob()">
      <fa-icon [icon]="['fas', 'box-full']" class="text-success"></fa-icon>
      {{ 'create_materials_to_job' | translate }}
  </span>
</div>

<div class="contact-roles-body">
  <div class="container">
    <div class="row pt-2 pb-2">
      <div class="col-sm-6 pt-1">
        <span class="module-title">
            <fa-icon [icon]="['fa', 'box-full']" aria-hidden="true"></fa-icon>
            &nbsp;
            {{ 'materials' | translate }}
        </span>
      </div>
      <div class="col-sm-6 pr-2">
        <nav class="pagination-section">
          <ul class="pagination">
            <refresh-pagination-btn id="widgetMaterialsRefreshButton" (refresh)="onRefresh()" [isDisabled]="! bPageLoaded"></refresh-pagination-btn>
            <li [ngClass]="(listService.strFirstValue != listService.strCurrentValue && listService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                <a id="widgetMaterialsPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev')">
                    <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                </a>
            </li>
            <li [ngClass]="(listService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                <a id="widgetMaterialsNextButton" class="page-link" aria-label="first" (click)="fetchList('next')">
                    <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div *ngIf="arMaterials.length < 1">
      <div class="row border-top" *ngIf="bPageLoaded">
        <span class="no-content">
            {{ 'no_materials' | translate }}
        </span>
      </div>
      <div class="row border-top" *ngIf="!bPageLoaded">
        <span class="no-content">
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            {{ 'loading' | translate }}
        </span>
      </div>
    </div>

    <div class="row border-top p-2"  *ngFor="let material of arMaterials">
      <div class="col-sm-4">
        <div class="row">
          <div class="col-12">
            <span *ngIf="material.item_id && material['type'] != 'once_off_purchase'; else onceOffLabel" matTooltip="{{ 'product_code' | translate }}: {{ material.code | translate }}">
                {{ material.code }}
            </span>
            <ng-template #onceOffLabel><span>({{ 'once_off_purchase' | translate }})</span></ng-template>
          </div>
        </div>
        <div class="row" matTooltip="{{ 'product_name' | translate }}: {{ material.name | translate }}">
          <div class="col-12">
            <fa-icon class="icon-spacer" [icon]="['fas', 'box-open']"></fa-icon>&nbsp;
            <span *ngIf="material['type'] == 'product_catalog'">{{ material.name }}</span>
            <span *ngIf="material['type'] == 'once_off_purchase'">{{ material.product }}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="row" matTooltip="{{ 'unit_cost' | translate }}: {{ material.unit_cost | currency }}">
          <div class="col-12">
              <span>{{ material.unit_cost | currency }}</span>
          </div>
        </div>
        <div class="row" matTooltip="{{ 'quantity' | translate }}: {{ material.quantity }}">
          <div class="col-12">
              <fa-icon class="icon-quanity-spacer" [icon]="['fas', 'cart-plus']"></fa-icon>&nbsp;
              <span>{{ material.quantity }}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="row" matTooltip="{{ 'notes' | translate }}: {{  (material.notes) ? material.notes : '--' }}">
          <div class="col-12">
            <span *ngIf="material.notes">
                {{ material.notes | slice:0:75 }}
                <span *ngIf="material.notes.length > 75">...</span>
            </span>
            <span *ngIf="!material.notes">--</span>
          </div>
        </div>

        <div class="row" matTooltip="{{ 'job_number' | translate }}: {{  (material.job_text) ? material.job_text : '--' }}">
          <div class="col-12">
            <a id="jobNumberLabel" [routerLink]="['/jobs/'+material.job_id]" target="_blank">
                <span> #{{ material.job_text }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>