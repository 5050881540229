import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as moment from 'moment';
import { isEmpty } from 'lodash-es';
import { Select } from "../../../../../../objects/select";
import { ContactRolesService } from '../../../../../../services/contact-roles.service';

@Component({
  selector: 'app-role-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss']
})
export class OpportunitiesComponent implements OnInit {

  @Input() arOpportunityList: Array<object>;
  @Output() objAction: EventEmitter<{
    action: string,
    data: object
  }> = new EventEmitter();

  public arOpportunityStatus = {
    'open': 'text-success',
    'closed_won': 'text-primary',
    'closed_lost': 'text-danger',
  };

  constructor(
    private contactRolesService: ContactRolesService
  ) { }

  ngOnInit() {
  }

  /**
   * add leading zeroes to opportunity number
   * should always be a 6 digit
   */
  formatOpportunityNumber(opportunity_number): string {
    let numCharCount = opportunity_number.toString().length;
    let numAdditionalZeroes = (6 - numCharCount);
    let strFinalName = '';
    for (let index = 0; index < numAdditionalZeroes; index++) {
        strFinalName = strFinalName + '0';
    }
    return strFinalName + opportunity_number;
  }

  /**
   * format datetime value
   */
  formatDate(date: string): string {
    if (!isEmpty(date)) {
      let utcTime = moment.utc(date).toDate();
      return moment(utcTime).local().format('LL');
    }
    return null;
  }

  /**
   * emit objAction data
   */
  emitAction(action: string, record: object): void {
    this.objAction.emit({
        action: action,
        data: record
    })
  }

  /**
   * translates the selected roles
   * unshift the primary role so will be visible in the display
   *
   * @param arRoles
   */
  translateRole(arRoles: Array<Select>): string {
    return this.contactRolesService.transformRole(arRoles);
  }
}
