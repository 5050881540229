import { Component, ContentChild, TemplateRef, Input } from '@angular/core';
import { PaginationStore } from '../../store/pagination.store';
import { PaginationLinksTopContentDirective } from '../../directives/component.directive';

@Component({
  selector: 'fieldmagic-pagination-links',
  templateUrl: './pagination-links.component.html',
})
export class PaginationLinksComponent {
  @Input()
  topOnly: boolean = false;

  @ContentChild(
    PaginationLinksTopContentDirective,
    {
      read: TemplateRef,
    }
  )
  topContent: TemplateRef<any>;

  @Input() hideRefresh: boolean = false;

  get hasNextPage$() {
    return this.pagination.hasNextPage$;
  }

  get hasPreviousPage$() {
    return this.pagination.hasPreviousPage$;
  }

  get isFirstPage$() {
    return this.pagination.isFirstPage$;
  }

  constructor(
    private readonly pagination: PaginationStore<any, any>,
  ) { }

  onRefresh(): void {
    this.pagination.refresh();
  }

  onNextPage(): void {
    this.pagination.goToNextPage();
  }

  onPreviousPage(): void {
    this.pagination.goToPreviousPage();
  }

  onFirstPage(): void {
    this.pagination.goToFirstPage();
  }
}
