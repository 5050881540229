
    <fieldmagic-dialog>
      <div class="dialog-header-content">
        <fieldmagic-dialog-title
          icon="history"
          label="History ({{ loggedAt$ | async | format: 'lll' }})"
        >
        </fieldmagic-dialog-title>
      </div>

      <div class="dialog-header-buttons">
        <fieldmagic-primary-button
          label="Restore"
          icon="history"
          (click)="onRestore()"
          [disabled]="(fetching$ | async) || (restoring$ | async)"
        >
        </fieldmagic-primary-button>
        <fieldmagic-dialog-close-button
          (click)="onDismiss()"
          [disabled]="restoring$ | async"
        >
        </fieldmagic-dialog-close-button>
      </div>

      <div class="dialog-content">
        <fieldmagic-async-content
          [loading]="fetching$ | async"
        >
          <ng-template
            fieldmagicAsyncLoadedContent
          >
            <app-viewform [objRecordDetails]="details"></app-viewform>
          </ng-template>
        </fieldmagic-async-content>
      </div>
    </fieldmagic-dialog>
  