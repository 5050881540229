import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ListingService } from '../../../services/listing.service';
import { NotificationService } from '../../../services/notification.service';
import { RecordService } from '../../../services/record.service';
import { ViewService } from '../../../services/view.service';
import * as moment from 'moment';
import { toNumber, isNaN, get } from 'lodash-es';
import { EditSupplierInvoiceComponent } from './edit-supplier-invoice/edit-supplier-invoice.component';
import { RelateIds } from '../../../lists/relate-ids';

@Component({
  selector: 'app-supplier-invoices',
  templateUrl: './supplier-invoices.component.html',
  styleUrls: ['./supplier-invoices.component.scss'],
  providers: [ListingService]
})
export class SupplierInvoicesComponent implements OnInit {
  @Output() refreshRelatePanel = new EventEmitter<any>();
  @Input() strRecordId : string;
  @Input() strModule : string;
  public arSupplierInvoices = [];
  public arPreviousPages = {};
  public bPageLoaded: boolean = false;
  public objJobData: any;
  public objCustomerData: any;
  public bDialogLoaded: boolean = true;
  public objModuleConfig: any = {};

  /**
   * Can be false if module is purchase orders, if the PO is not convertible to SI
   *
   * @var {boolean}
   */
  public bDisplayAddBtn: boolean = true;

  constructor(
      private dialog: MatDialog,
      public listService: ListingService,
      private notifService: NotificationService,
      public viewService: ViewService,
      private recordService: RecordService
  ) {}

  ngOnInit() {
    // Get the list on page initialization.
    this.fetchList('default');
  }

  /**
  * Open the create supplier invoice dialog.
  */
  createSupplierInvoice() {

      // Create the object to be passed inside the dialog.
      let objData = {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        padding: '1%',
        disableClose: true,
        // The id of the jobs, the supplier invoice's "parent".
        data: {
          module: this.strModule,
          record_id : this.strRecordId,
          view_type : 'add',
          ...(this.strModule === 'purchase_orders') && {
            purchase_order_id: this.strRecordId,
            purchase_order_text: get(this.viewService.arRecord, 'text', ''),
            from_convert_po: true,
          }
        }
      };

      // Initialize the dialog.
      let tabDialogRef = this.dialog.open(EditSupplierInvoiceComponent, objData);

      // When the dialog closes, reload the list.
      tabDialogRef.afterClosed().first().subscribe(item => {
        if (item != undefined && item.message == 'save') {
          // Show notification that activity has been created.
          this.notifService.notifySuccess('header_notification.success_added');
          setTimeout(() => {
            this.onRefresh();
          }, 3500);
          this.refreshRelatePanel.emit({ widget: 'purchase-orders' });
        }
      });
  }

  /**
   * Opens the selected SupplierInvoice from the list.
   * @param objSupplierInvoice - the SupplierInvoice data.
   */
  openSupplierInvoice(objSupplierInvoice) {
      // Object to be passed in the dialog.
      let objData = {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        padding: '1%',
        disableClose: true,
        // Data to be passed on
        data: {
          module: this.strModule,
          record_id : this.strRecordId,
          supplier_invoice: [],
          supplier_invoice_id: objSupplierInvoice['id'],
          view_type : 'edit'
        }
      };

      // Instantiate dialog.
      let tabDialogRef = this.dialog.open(EditSupplierInvoiceComponent, objData);

      // Reload invoice list once the dialog is closed.
      tabDialogRef.afterClosed().first().subscribe(item => {
        // Only when the user hits save will we reload the list.
        if (item != undefined && item.message == 'save') {
          // Show notification that activity has been created.
          this.notifService.notifySuccess('header_notification.success_update');
          this.fetchList('default');
          this.refreshRelatePanel.emit({ widget: 'purchase-orders' });
        }
      });
  }

  /**
   * Fetch the list of supplier invoice.
   * @param strPage - what page is currently to be viewed.
   */
  fetchList(strPage) {

    let objPagination = this.listService.beforeFetching(strPage);
    let strModuleId = RelateIds[this.strModule];

    this.bPageLoaded = false;

    // Get the list from API.
    this.listService.fetchDataAdvanceSearch(
      objPagination['objPage'],
      'supplier_invoices',
      {[strModuleId]: this.strRecordId},
      { 'id': 'created_at', 'sort': 'desc' },
    ).subscribe(response => {
      this.arSupplierInvoices = response['data'];
      this.listService.afterFetching(response, strPage);
      this.bPageLoaded = true;
    });
  }
  /**
   *
   * @param strId - record id
   */
  deleteSupplierInvoice(strId) {
    //We tell the app component to open the confirmation.
    this.notifService.sendConfirmation()
      .subscribe(
        confirmation => {
          //If the user confirmed, delete the record by field
          if (confirmation.answer) {
            this.recordService.deleteRecord('supplier_invoices', strId).first().subscribe(
              data => {
                if (data) {
                  this.fetchList('default');
                  this.refreshRelatePanel.emit({ widget: 'purchase-orders' });
                  this.notifService.notifySuccess('record_delete_success');
                } else {
                  this.notifService.notifyError('record_delete_failed');
                }
              });
          }
        }
      );
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate, arCustomConfig = []) {
    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('ll');
  }

  /**
   * Get record module config field
   */
  getModuleConfig(strModule) {
    this.recordService.getRecordConfig(strModule).first().subscribe(
      result => {
        this.objModuleConfig = result;
      }
    );
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arSupplierInvoices = []; // clear list
    this.fetchList('default');
  }
}
