import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LooseObject } from '../../../../objects/loose-object';
import { blank, filled } from '../../../../shared/utils/common';
import { Relate } from '../../../../objects/relate';
import { RecordService } from '../../../../services/record.service';
import { switchMap } from 'rxjs/operators';
import { cloneDeep, get, toNumber } from 'lodash-es';
import { toFormattedNumber } from '../../../../shared/utils/numbers';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormService } from '../../../../services/form.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EditformComponent } from '../../../../shared/components/editform/editform.component';
import { FormPopup } from '../../../../objects/centralized-forms/form-popup';
import { MatDialog } from '@angular/material';
import { ViewSupplierInventoryComponent } from '../../../../admin/items/view-supplier-inventory/view-supplier-inventory.component';

@Component({
  selector: 'credit-notes-line-items',
  templateUrl: './line-items.component.html',
  styleUrls: ['./line-items.component.scss'],
})
export class LineItemsComponent implements OnInit {

  @Input() metadata: LooseObject;

  @Output() updateLineItemValue = new EventEmitter<LooseObject>();

  public lineItems: CreditNoteLineItem[] = [];

  public amountTotal: number = 0;

  public amountTax: number = 0;

  public amountTaxEx: number = 0;

  public amountTaxAdjustment: number = 0;

  public amountTaxInc: number = 0;

  private formRawData: LooseObject;

  get totalTax(): number {
    let computedTax = 0;
    this.lineItems.forEach( data => {
      let amount: any = filled(data.total_price) ? data.total_price : 0;
      let tax_rate: any = filled(data.tax_rate) ? data.tax_rate : 0;
      computedTax += parseFloat(amount) * parseFloat(tax_rate);
    });
    return toFormattedNumber(computedTax, { currency: true });
  }

  constructor(
    private recordService: RecordService,
    private formService: FormService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.formRawData = this.formService.arOldRawData['credit_notes'];
    if (filled(this.metadata['default_value'])) {
      this.metadata['default_value'].forEach( lineItem => {
        this.addLineItem(lineItem);
      });
      this.calculateAmount();
    } else {
      this.addLineItem();
    }

    this.amountTaxAdjustment = get(this.formRawData, 'amount_tax_adjustment');
  }

  /**
   * add line item
   *
   * @param lineItem
   */
  addLineItem(lineItem: LooseObject = {}, isNewItem: boolean = false): void {
    if (isNewItem) {
      lineItem = {
        ...lineItem,
        ...{
          item_id: lineItem.id,
          item_name: lineItem.name,
          item_code: lineItem.code,
        },
      }
    }

    let creditNoteLineItem = new CreditNoteLineItem(
      lineItem,
      new Relate<any>().buildRelates(
        switchMap(strTerm => this.recordService.getRecordRelate('tax_codes', strTerm, [])),
        (lineItem.tax_code_id) ? [{ id: lineItem.tax_code_id, name: lineItem.tax_code_name }] : null,
        filled(lineItem.tax_code_id)
      ),
      new Relate<any>().buildRelates(
        switchMap(strTerm => this.recordService.getRecordRelate('account_codes', strTerm, [])),
        (lineItem.account_code_id) ? [{ id: lineItem.account_code_id, name: lineItem.account_code_name }] : null,
        filled(lineItem.account_code_id)
      ),
    );

    if (this.lineItems.length == 1 && blank(this.lineItems[0].description)) {
      this.lineItems[0] = creditNoteLineItem;
    } else {
      this.lineItems.push(creditNoteLineItem);
    }

    this.calculateLineItem(creditNoteLineItem);
    this.calculateAmount();
  }

  /**
   * calculate all amount using all line item
   */
  calculateAmount(): void {
    let numAmountTax = 0;
    let numAmountTaxEx = 0;

    this.lineItems.forEach( data => {
      let amount: any = filled(data.total_price) ? toNumber(data.total_price) : 0;
      let tax_rate: any = filled(data.tax_rate) ? toNumber(data.tax_rate) * 0.01 : 0;
      numAmountTax += amount * tax_rate;
      numAmountTaxEx += amount;
    });

    this.amountTax = toFormattedNumber(numAmountTax, { currency: true });
    this.amountTaxEx = toFormattedNumber(numAmountTaxEx, { currency: true });
    this.amountTaxInc = toFormattedNumber((numAmountTax + numAmountTaxEx) + toNumber(this.amountTaxAdjustment) , { currency: true });

    let lineItemValue = cloneDeep(this.lineItems);
    this.updateLineItemValue.emit({
      line_items: this.validateLineItem(),
      amount_tax: this.amountTax,
      amount_tax_ex: this.amountTaxEx,
      amount_tax_inc: this.amountTaxInc,
      amount_tax_adjustment: this.amountTaxAdjustment
    });
  }

  /**
   * calculate total amount per line item
   * @param lineItem
   */
  calculateLineItem(lineItem: CreditNoteLineItem): void {
    lineItem.unit_cost = toFormattedNumber(lineItem.unit_cost, { currency: true });
    lineItem.total_price = toFormattedNumber(lineItem.quantity * lineItem.unit_cost, {
      currency: true,
    });
    this.calculateAmount();
  }

  /**
   * on change tax code
   *
   * @param event
   * @param lineItem
   */
  onChangeTaxCode(event, lineItem: CreditNoteLineItem): void {
    if (filled(event)) {
      lineItem.tax_code_id = event.id;
      lineItem.tax_code_name = event.name;
      lineItem.tax_rate = event.rate;

      this.calculateAmount();
    }
  }

  /**
   * on change account code
   *
   * @param event
   * @param lineItem
   */
  onChangeAccountCode(event, lineItem: CreditNoteLineItem): void {
    if (filled(event)) {
      lineItem.account_code_id = event.id;
      lineItem.account_code_name = event.name;

      this.calculateAmount();
    }
  }

  /**
   * on change amount, update unit cost value based on the inputted
   * amount divided by quantity
   *
   * @param event
   * @param lineItem
   */
  onChangeAmount(event, lineItem: CreditNoteLineItem): void {
    if (filled(event.target.value)) {
      lineItem.unit_cost = toFormattedNumber(event.target.value / lineItem.quantity, { currency: true });
      this.calculateAmount();
    }
  }

  /**
   * remove line item
   *
   * @param lineItem
   */
  onRemoveLineItem(lineItem: CreditNoteLineItem): void {
    this.lineItems.splice(this.lineItems.indexOf(lineItem), 1);
    this.calculateAmount();
  }

  /**
   * to rearange the line item
   * @param event
   */
  onDropLineItem(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.lineItems, event.previousIndex, event.currentIndex);
  }

  validateLineItem(): string|null {
    let lineItemValue = cloneDeep(this.lineItems);
    let form = new FormGroup({
      description:  new FormControl('', [Validators.required]),
      quantity:  new FormControl('', [Validators.required]),
      unit_cost:  new FormControl('', [Validators.required]),
      tax_code_id:  new FormControl('', [Validators.required]),
      account_code_id:  new FormControl('', [Validators.required]),
    })
    let validateLineItems = lineItemValue.map( lineItem => {
      form.patchValue({
        description: lineItem.description,
        quantity: lineItem.quantity,
        unit_cost: lineItem.unit_cost,
        tax_code_id: lineItem.tax_code_id,
        account_code_id: lineItem.account_code_id,
      });
      return form.valid;
    });

    if (validateLineItems.filter(valid => !valid).length) {
      return null;
    } else {
      return JSON.stringify(lineItemValue.map( lineItem => {
        delete lineItem.tax_code_relate
        delete lineItem.account_code_relate
        return lineItem;
      }));
    }
  }

  /**
   * To open create item dialog form
   */
  createItem(lineItem: LooseObject): void {
    let itemData = this._compileItemDefaultValue(lineItem);
    this.dialog.open(EditformComponent, new FormPopup('items', {}, itemData))
      .afterClosed()
      .subscribe((response) => {
        if (filled(response.data)) {
          lineItem.item_id = response.data.id;
          lineItem.item_name = response.data.name;
          lineItem.item_code = response.data.code;
        }
      });
  }

  /**
   * create default value for item dialog create
   *
   * @param lineItem
   * @returns
   */
  protected _compileItemDefaultValue(lineItem: LooseObject): LooseObject {

    let itemsDefaultValue: LooseObject = {
      unit_cost: get(lineItem, 'unit_cost'),
      name: get(lineItem, 'description'),
      code: get(lineItem, 'description'),
    };

    if (filled(lineItem.tax_code_id)) {
      itemsDefaultValue.default_purchase_tax_code_id = lineItem.tax_code_id;
    itemsDefaultValue.default_purchase_tax_code_text = lineItem.tax_code_name;
    }

    if (filled(lineItem.account_code_id)) {
      itemsDefaultValue.default_purchase_account_code_id = lineItem.account_code_id;
      itemsDefaultValue.default_purchase_account_code_text = lineItem.account_code_name;
    }

    return itemsDefaultValue
  }

  /**
   * Open suuplier inventory dialog.
   *
   * @param recordData
   */
  openSupplierInventoryDialog(itemId: string) {
    this.dialog.open(ViewSupplierInventoryComponent, {
      width: '80%',
      height: '98%',
      data: {
        record: {id: itemId},
      }
    });
  }
}

export class CreditNoteLineItem {
  item_id?: string;
  item_name?: string;
  item_code?: string;
  description?: string;
  tax_code_id?: string;
  tax_code_name?: string;
  tax_rate?: number;
  account_code_id?: string;
  account_code_name?: string;
  quantity?: number;
  unit_cost?: number;
  total_price?: number;
  amount_tax?: number;
  purchase_order_item_id?: string;
  tax_code_relate?: Relate<any>;
  account_code_relate?: Relate<any>;

  constructor(properties, taxCodeRelate: Relate<any>, accountCodeRelate: Relate<any>) {
    this.item_id = properties.item_id;
    this.item_name = properties.item_name;
    this.item_code = properties.item_code;
    this.description = filled(properties.description) ? properties.description : properties.item_name;
    this.tax_code_id = properties.tax_code_id;
    this.tax_code_name = properties.tax_code_text;
    this.tax_rate = properties.tax_rate;
    this.account_code_id = properties.account_code_id;
    this.account_code_name = properties.account_code_text;
    this.quantity = get(properties, 'quantity', 1);
    this.unit_cost = get(properties, 'unit_cost', 1);
    this.total_price = properties.total_price;
    this.amount_tax = properties.amount_tax;
    this.tax_code_relate = taxCodeRelate;
    this.account_code_relate = accountCodeRelate;
  }

}