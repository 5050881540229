
    <fieldmagic-dialog
      icon="users"
      title="Add User(s)"
      [instance]="self"
    >
      <ng-template
        fieldmagicDialogButtonsTmp
      >
        <fieldmagic-primary-button
          purpose="save"
          [isInProgress]="saving$ | async"
          (click)="onSave()"
        >
        </fieldmagic-primary-button>
      </ng-template>

      <ng-template
        fieldmagicDialogContentTmp
      >
        <div class="d-flex d-flex-gap-2x flex-column">
          <div class="d-flex d-flex-gap">
            <fieldmagic-checkbox-input
              [(ngModel)]="generatePassword"
              label="Automatically generate password"
            >
            </fieldmagic-checkbox-input>

            <fieldmagic-checkbox-input
              [(ngModel)]="sendWelcomeEmail"
              label="Send welcome email"
            >
            </fieldmagic-checkbox-input>

            <fieldmagic-checkbox-input
              [(ngModel)]="sendWelcomeEmailToInviter"
              label="Send welcome email to me"
            >
            </fieldmagic-checkbox-input>
          </div>

          <table class="table table-bordered">
            <thead>
              <tr>
                <th width="40%">{{ 'email_address' | translate }}</th>
                <th width="33%">{{ 'password' | translate }}</th>
                <th width="23%">{{ 'access_level' | translate }}</th>
                <th width="4%"></th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let user of users; trackBy: userByEmail; let index = index"
              >
                <td>
                  <fieldmagic-text-input
                    [(ngModel)]="user.email_address"
                    [errors]="errors | data_get: ('%s:email_address' | sprintf: [user.id])"
                  >
                  </fieldmagic-text-input>
                </td>
                <td>
                  <fieldmagic-text-input
                    [disabled]="generatePassword"
                    [(ngModel)]="user.password"
                    [errors]="errors | data_get: ('%s:password' | sprintf: [user.id])"
                  >
                  </fieldmagic-text-input>
                </td>
                <td>
                  <fieldmagic-dropdown-input
                    [(ngModel)]="user.level"
                    [options]="levels$ | async"
                    [clearable]="false"
                  >
                  </fieldmagic-dropdown-input>
                </td>
                <td>
                  <div class="d-flex d-flex-gap">
                    <fieldmagic-primary-button
                      [icon]="[{
                        icon: 'plus-circle',
                        klass: 'text-success'
                      }]"
                      purpose="icon"
                      tooltip="Click to add line"
                      (click)="add(index)"
                    >
                    </fieldmagic-primary-button>
                    <fieldmagic-primary-button
                      [icon]="[{
                        icon: 'minus-circle',
                        klass: 'text-danger'
                      }]"
                      purpose="icon"
                      tooltip="Click to remove line"
                      (click)="remove(index)"
                    >
                    </fieldmagic-primary-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </ng-template>

    </fieldmagic-dialog>
  