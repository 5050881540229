export interface StockReceiptLineItemInterface {
    id: string;
    item_name: string;
    item_id: string;
    job_id?: string;
    item_code: string;
    quantity: number;
    received: number;
    description: string;
    expected_receipt_date: string;
    credit: number;
    credited: number;
    material_id?: string;
    customer_invoice_id?: string;
    purchase_order_line_item_id?: string;
  }

  export class StockReceiptLineItem {
    id: string;
    item_name: string;
    item_id: string;
    job_id?: string;
    item_code: string;
    quantity: number;
    received: number;
    description: string;
    expected_receipt_date: string;
    credit: number;
    credited: number;
    material_id?: string;
    customer_invoice_id?: string;
    purchase_order_line_item_id?: string;

    constructor(properties: StockReceiptLineItemInterface | any) {
      this.id = properties.id;
      this.item_name = properties.item_name;
      this.item_id = properties.item_id;
      this.job_id = properties.job_id || null;
      this.item_code = properties.item_code;
      this.quantity = parseFloat(properties.quantity);
      this.received = parseFloat(properties.received) || 0;
      this.description = properties.description;
      this.expected_receipt_date = properties.expected_receipt_date
      this.credit = properties.credit || 0;
      this.credited = properties.credited || 0;
      this.material_id = properties.material_id || null;
      this.customer_invoice_id = properties.customer_invoice_id || null;
      this.purchase_order_line_item_id = properties.purchase_order_line_item_id || null;
    }
  }

  export interface StockReceiptInterface {

    status: 'draft' | 'received';
    purchase_order_id?: string;
    purchase_order_number?: string;
    line_items: StockReceiptLineItemInterface[];
    id: string;
    warehouse_name: string;
    warehouse_id: string;
    type: 'receipt' | 'credit';
    reference: string;
    supplier_invoice_id?: string;
  }

  export class StockReceipt implements StockReceiptInterface {

    status: 'draft' | 'received';

    purchase_order_id?: string;
    purchase_order_number?: string;
    line_items: StockReceiptLineItemInterface[];
    id: string;
    warehouse_name: string;
    warehouse_id: string;
    type: 'receipt' | 'credit';
    reference: string;
    supplier_invoice_id?: string;

    constructor(properties:StockReceiptInterface) {
      this.status = properties.status || 'draft';
      this.purchase_order_id = properties.purchase_order_id || null;
      this.purchase_order_number = properties.purchase_order_number;
      this.id = properties.id;
      this.warehouse_name = properties.warehouse_name;
      this.warehouse_id = properties.warehouse_id;
      this.type = properties.type;
      this.line_items = properties.line_items ? properties.line_items.map(item => {
        return new StockReceiptLineItem(item);
      }) : [];
      this.reference = properties.reference;
      this.supplier_invoice_id = properties.supplier_invoice_id || null;
    }
  }