import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { EditModule } from '../../shared/modules/edit.module';
import { InformationModule } from '../../shared/modules/information.module';
import { ListingModule } from '../../shared/modules/listing.module';
import { SharedModule } from '../../shared/modules/shared.module';
import { FontAwesomeModule } from '../../shared/modules/font-awesome.module';
import { ActivityModule } from '../../shared/modules/activity.module';
import { StockLevelRoutingModule } from './stock-level-routing.module';
import { ViewModule } from '../../shared/modules/view.module';

// Components
import { StockLevelComponent } from './list/stock-level.component';
import { ReorderListComponent } from './actions/reorder/list/reorder-list.component';
import { StocktakeListComponent } from './actions/stocktake/list/stocktake-list.component';
import { ListComponent as StockTransferList } from './widgets/stock-transfer/list/list.component';
import { FormComponent as StockTransferForm } from './widgets/stock-transfer/dialog/form/form.component';
import { ViewComponent as StockTransferView } from './widgets/stock-transfer/dialog/view/view.component';
import { StockTransferQuantitiesComponent as StockTransferQuantityForm } from './widgets/stock-transfer/dialog/stock-transfer-quantities/stock-transfer-quantities.component';
import { WarehouseListComponent } from './widgets/warehouse/list/warehouse-list.component';
import { StocktakeHistoryComponent } from './actions/stocktake/history/stocktake-history.component';
import { ViewComponent as StocktakeViewComponent } from './actions/stocktake/view/view.component';

// Services
import { RecordService } from '../../services/record.service';
import { FormService } from '../../services/form.service';
import { WidgetService } from '../../services/widget.service';
import { ViewComponent } from './view/view.component';
import { ViewService } from '../../services/view.service';
import { StockAdjustmentsComponent } from './widgets/stock-adjustments/stock-adjustments.component';
import { PaginationButtonsModule } from '../../shared/components/pagination/pagination-buttons.module';
import { ErrorHandlerModule } from '../../shared/modules/error-handler.module';
import { DateModule } from '../../shared/components/forms/input/date/date.module';
import { UpdateStockLevelComponent } from './widgets/warehouse/list/update-stock-level/update-stock-level.component';
import { SharedInputModule } from '../../shared/modules/shared-input.module';
import { CommonPaginationModule } from '../../shared/components/listing/common/common-pagiation.module';
import { ListingNewModule } from '../../shared/components/listing-new/listing-new.module';
import { ListingDialogModule } from '../../shared/components/listing-new/components/dialog/llisting-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    StockLevelRoutingModule,
    EditModule,
    ViewModule,
    InformationModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    SharedModule,
    ActivityModule,
    PaginationButtonsModule,
    ErrorHandlerModule,
    DateModule,
    SharedInputModule,
    CommonPaginationModule,
    ListingNewModule,
    ListingModule,
    ListingDialogModule,
  ],
  declarations: [
    StockLevelComponent,
    ViewComponent,
    ReorderListComponent,
    StocktakeListComponent,
    StockTransferList,
    StockTransferForm,
    StockTransferView,
    StockTransferQuantityForm,
    WarehouseListComponent,
    StockAdjustmentsComponent,
    UpdateStockLevelComponent,
    UpdateStockLevelComponent,
    StocktakeHistoryComponent,
    StocktakeViewComponent,
  ],
  providers: [
    RecordService,
    FormService,
    WidgetService,
    ViewService
  ],
  entryComponents: [
    StockTransferForm,
    StockTransferView,
    StockTransferQuantityForm,
    UpdateStockLevelComponent,
  ],
  exports: [
    EditModule,
  ]
})
export class StockLevelModule { }
