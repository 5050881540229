<!-- header -->
<header>
  <div class="d-flex">
    <!-- title -->
    <h3 class="title">
      <fa-icon [icon]="['fas', 'file-invoice-dollar']" ></fa-icon>&nbsp;
      <span *ngIf="! isCreateForm()" >{{ 'edit_recurring_invoice'| translate }} </span>
      <span *ngIf="isCreateForm()">{{ 'create_recurring_invoice'| translate }} </span>
    </h3>
    <ng-container *fcHasPermission="'recurring_invoices' + (isCreateForm() ? ':create' : ':edit'); else createRecurringInvoiceDisabled">
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <!-- submit btn -->
        <li class="list-inline-item">
          <button type="button" class="btn ml-1 btn-primary" [disabled]="isSubmitting || (disabledSaveButton$ | async)" id="save" (click)="triggerFormSubmit()">
              <fa-icon *ngIf="!isSubmitting" [icon]="['fas', 'save']"></fa-icon>
              <fa-icon *ngIf="isSubmitting" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              <span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
        <!-- /submit btn -->

        <!-- cancel btn -->
        <li class="list-inline-item">
          <fieldmagic-primary-button
            (click)="closeDialog()"
            icon="times"
            variant="error"
            label="close"
            [disabled]="isSubmitting"
          ></fieldmagic-primary-button>
        </li>
        <!-- /cancel btn -->
      </ul>
    </div>
    </ng-container>
  </div>
</header>
<!-- /end header -->

<!-- seperator -->
<hr class="seperator"/>
<!-- /seperator -->

<!-- dialog content -->
<mat-dialog-content class="mobile-view-dialog">
<ng-container *fcHasPermission="'recurring_invoices' + (isCreateForm() ? ':create' : ':edit'); else createRecurringInvoiceDisabled">
  <app-error-handler onlyFor="edit_form" [isAction]="true" alignment="center" (onError)="onFormError()">

  <div class="mt-3">
    <!-- tabs -->
    <ul class="nav nav-tabs" id="recurring_invoice_tabs" role="tablist">
      <!-- information -->
      <li class="nav-item text-size">
        <a href="#information_content" id="information-tab" data-toggle="tab" class="nav-link active" role="tab" aria-selected="true" translate>{{ 'information' | translate }}</a>
      </li>
      <!-- end information -->

      <!-- system -->
      <li class="nav-item text-size">
        <a href="#system_content" id="system-tab" data-toggle="tab" class="nav-link" role="tab" aria-selected="true" translate>{{ 'system' | translate }}</a>
      </li>
      <!-- end system -->
    </ul>
    <!-- /end tabs -->

    <!-- tab-content -->
    <form (ngSubmit)="save(recurringInvoiceForm)" #recurringInvoiceForm="ngForm">
      <div class="tab-content">
          <!-- information -->
          <div class="tab-pane row p-4 active" id="information_content" role="tabpanel">
            <small class="form-text text-muted mb-3">
              {{ 'fields_marked_are_required1' | translate }}
              <span class="text-danger"> * </span>
              {{ 'fields_marked_are_required2' | translate }}</small>
              <!-- row:1 -->
              <div class="row">
                <!-- active/inactive -->
                <div class="col-12 mb-3">
                    <label>{{ 'is_active' | translate }}</label>
                    <br/>
                    <mat-slide-toggle
                      [ngModelOptions]="{ name: 'isActive' }"
                      [(ngModel)]="objRecurringInvoice.isActive"
                      id="isActive"
                      color="primary"
                      class="value-text"
                      ngDefaultControl
                    >
                    </mat-slide-toggle>
                </div>
                <!-- /end active/inactive -->

                <!-- automatic invoicing? -->
                <div class="col-6 mb-3">
                    <label>{{ 'is_automatic_invoice' | translate }}</label>
                    <br/>
                    <mat-slide-toggle
                      [ngModelOptions]="{ name: 'isAutomatic' }"
                      [(ngModel)]="objRecurringInvoice.isAutomatic"
                      id="isAutomatic"
                      color="primary"
                      class="value-text"
                      ngDefaultControl
                    >
                    </mat-slide-toggle>
                </div>
                <!-- /end automatic invoicing -->

              <!-- email to client? -->
              <div class="col-6 mb-3">
                <label matTooltip="{{ 'help_email_to_client' | translate }}">{{ 'email_to_client'| translate }}</label>
                <br/>
                <mat-slide-toggle
                  [ngModelOptions]="{ name: 'emailToClient' }"
                  [(ngModel)]="objRecurringInvoice.emailToClient"
                  color="primary"
                  class="value-text"
                  ngDefaultControl
                >
                </mat-slide-toggle>
              </div>

                <!-- period -->
                <div class="col-6 mb-3">
                  <label>{{ 'period' | translate }}<required-tag></required-tag></label>
                  <ng-select
                    [(ngModel)]="objRecurringInvoice.objPeriod"
                    [items]="arSelectablePeriods$ | async"
                    [clearable]="false"
                    [ngModelOptions]="{ name: 'objPeriod' }"
                    [bindValue]
                    [class.is-invalid]="objPeriod.touched && ! objPeriod.valid"
                    #objPeriod="ngModel"
                    class="value-text"
                    required
                    ngDefaultControl
                  >
                    <!-- DISPLAY IN SELECTED -->
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label">{{ item.text | translate }}</span>
                    </ng-template>
                    <!-- DISPLAY IN OPTION -->
                    <ng-template  ng-option-tmp let-item="item">
                      <span class="value-text"> {{ item.text | translate }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <!-- /end period -->

                <!-- next invoice date -->
                <div class="col-6 mb-3">
                  <label>{{ 'next_invoice_date' | translate }}<required-tag></required-tag></label>
                  <div class="input-group">
                    <input
                      [(ngModel)]="objRecurringInvoice.objNextInvoiceDate"
                      [owlDateTime]="objFieldNextInvoiceDate"
                      [ngModelOptions]="{ name: 'objNextInvoiceDate' }"
                      [required]="true"
                      [readonly]="true"
                      type="text"
                      id="next_invoice_date_txt"
                      class="form-control date-input"
                    />
                    <button class="calendar-button" type="button" [owlDateTimeTrigger]="objFieldNextInvoiceDate">
                      <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
                    </button>
                  </div>
                  <div><owl-date-time #objFieldNextInvoiceDate pickerType="calendar"></owl-date-time></div>
                </div>
                <!-- /end next invoice date -->

                <!-- customer selection -->
                <div class="col-6 mb-3">
                  <label>{{ 'customer' | translate }}<required-tag></required-tag></label>
                  <ng-select
                    [items]="arSelectableRelatedCustomers$ | async"
                    [typeahead]="objSearchCustomerInput$"
                    [loading]="isLoadingRelated.customers"
                    [ngModelOptions]="{ name: 'objCustomer' }"
                    [clearable]="true"
                    [bindValue]
                    [(ngModel)]="objRecurringInvoice.objCustomer"
                    [class.is-invalid]="objCustomer.touched && !objCustomer.valid"
                    #objCustomer="ngModel"
                    required
                    class="value-text"
                    bindLabel="text"
                    (change)="onCustomerChanged($event)"
                    (open)="initRelatedSelectableFieldValues('customers')"
                    ngDefaultControl
                  >
                    <!-- DISPLAY IN SELECTED -->
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label">{{ item.text | translate }}</span>
                    </ng-template>
                    <!-- DISPLAY IN OPTION -->
                    <ng-template  ng-option-tmp let-item="item">
                      <span class="value-text"> {{ item.text | translate }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <!-- /end customer selection -->

                <!-- contact -->
                <div class="col-6 mb-3">
                    <label>{{ 'contact' | translate }}<required-tag *ngIf="objRecurringInvoice.emailToClient"></required-tag></label>
                    <ng-select
                      [items]="arSelectableRelatedContacts$ | async"
                      [typeahead]="objRelatedContactInput$"
                      [loading]="isLoadingRelatedContacts"
                      [ngModelOptions]="{ name: 'objContact' }"
                      [clearable]="true"
                      [bindValue]
                      [(ngModel)]="objRecurringInvoice.objContact"
                      [class.is-invalid]="(objContact.touched && !objContact.valid)"
                      #objContact="ngModel"
                      class="value-text"
                      bindLabel="text"
                      groupBy="groupname"
                      (open)="initRelatedSelectableFieldValues('contacts')"
                      [required] = "objRecurringInvoice.emailToClient"
                      ngDefaultControl
                    >
                      <!-- DISPLAY IN SELECTED -->
                      <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span class="ng-value-label">{{ item.text | translate }}</span>
                      </ng-template>
                      <!-- DISPLAY GROUP NAME -->
                      <ng-template ng-optgroup-tmp let-item="item">
                        {{ (item.groupname | translate) }}
                      </ng-template>
                      <!-- DISPLAY IN OPTION -->
                      <ng-template  ng-option-tmp let-item="item">
                        <span *ngIf="!item.role" class="value-text"> {{ item.text | translate }}</span>
                        <span *ngIf="item.role" class="value-text"> {{ item.text | translate }} - {{ item.role | translate }}</span>
                      </ng-template>
                    </ng-select>
                </div>
                <!-- /end contact -->

                <!-- site selection -->
                <div class="col-12 mb-3">
                  <label>{{ 'site' | translate }}</label>
                  <ng-select
                    [items]="arSelectableRelatedSites$ | async"
                    [loading]="isLoadingRelated['sites']"
                    [ngModelOptions]="{ name: 'objSite' }"
                    [typeahead]="arSelectableRelatedSitesInput$"
                    [clearable]="true"
                    [bindValue]
                    [(ngModel)]="objRecurringInvoice.objSite"
                    class="value-text"
                    bindLabel="text"
                    (change)="onSiteChanged($event)"
                    (open)="initRelatedSelectableFieldValues('sites')"
                    ngDefaultControl
                  >
                    <!-- DISPLAY IN SELECTED -->
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label">{{ item.text | translate }}</span>
                    </ng-template>
                    <!-- DISPLAY IN OPTION -->
                    <ng-template  ng-option-tmp let-item="item">
                      <span class="value-text"> {{ item.text | translate }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <!-- /end site selection -->

                <!-- expiry date -->
                <div class="col-6 mb-3">
                  <label>{{ 'expiry_date' | translate }}</label>
                  <div class="input-group">
                    <input
                      [(ngModel)]="objRecurringInvoice.objExpiryDate"
                      [owlDateTime]="objFieldExpiryDate"
                      [readonly]="true"
                      [ngModelOptions]="{ name: 'objExpiryDate' }"
                      type="text"
                      id="next_invoice_date_txt"
                      class="form-control date-input"
                    />

                    <!-- clear button -->
                    <span *ngIf="(objRecurringInvoice.objExpiryDate !== null)"><fa-icon [icon]="['fas', 'times']" class="date-clear" (click)="clearExpirationDate(recurringInvoiceForm)"></fa-icon></span>
                    <!-- /end -->

                    <button class="calendar-button" type="button" [owlDateTimeTrigger]="objFieldExpiryDate">
                      <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
                    </button>
                  </div>
                  <div><owl-date-time #objFieldExpiryDate pickerType="calendar"></owl-date-time></div>
                </div>
                <!-- /end expiry date -->

                <!-- purchase order number -->
                <div class="col-6 mb-3">
                  <label>{{ 'po_number' | translate }}</label>
                  <fieldmagic-text-input
                    [(ngModel)]="objRecurringInvoice.strPurchaseOrderNumber"
                    [ngModelOptions]="{ name: 'strPurchaseOrderNumber' }"
                    id="purchaseOrderNumber"
                    customClass="form-control font-size-12 input-height-40"
                  ></fieldmagic-text-input>
                </div>
                <!-- /end purchase order number -->

                <!-- invoice date -->
                <div class="col-6 mb-3">
                  <label>{{ 'invoice_date' | translate }}<required-tag></required-tag></label>
                  <ng-select
                    [items]="arSelectableDatePeriods$ | async"
                    [clearable]="false"
                    [(ngModel)]="objRecurringInvoice.objInvoiceDate"
                    [ngModelOptions]="{ name: 'objInvoiceDate' }"
                    [bindValue]
                    [class.is-invalid]="objInvoiceDate.touched && !objInvoiceDate.valid"
                    #objInvoiceDate="ngModel"
                    required
                    class="value-text"
                  >
                    <!-- DISPLAY IN SELECTED -->
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label">{{ item.text | translate }}</span>
                    </ng-template>
                    <!-- DISPLAY IN OPTION -->
                    <ng-template  ng-option-tmp let-item="item">
                      <span class="value-text"> {{ item.text | translate }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <!-- /invoice date -->

                <!-- due date payment terns -->
                <div class="col-6 mb-3">
                  <label>{{ 'due_date' | translate }}<required-tag></required-tag></label>
                  <ng-select
                    [items]="arSelectableRelatedPaymentTerms$ | async"
                    [loading]="isLoadingRelated['payment_terms']"
                    [ngModelOptions]="{ name: 'objPaymentTerm' }"
                    [typeahead]="arSelectableRelatedPaymentTermsInput$"
                    [clearable]="true"
                    [bindValue]
                    [(ngModel)]="objRecurringInvoice.objPaymentTerm"
                    class="value-text"
                    bindLabel="text"
                    (open)="initRelatedSelectableFieldValues('payment_terms')"
                    ngDefaultControl
                  >
                    <!-- DISPLAY IN SELECTED -->
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label">{{ item.text | translate }}</span>
                    </ng-template>
                    <!-- DISPLAY IN OPTION -->
                    <ng-template  ng-option-tmp let-item="item">
                      <span class="value-text"> {{ item.text | translate }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <!-- /due date -->

                <!-- invoice summary -->
                <div class="col-12 mb-3">
                  <label>{{ 'invoice_summary' | translate }}</label>
                  <textarea
                    [ngModelOptions]="{ name: 'strInvoiceSummary' }"
                    [(ngModel)]="objRecurringInvoice.strInvoiceSummary"
                    class="form-control"
                    maxlength="10000"
                    rows="4"
                  ></textarea>
                </div>
                <!-- /end invoice summary -->

                <!-- reference -->
                <div class="col-6 mb-3">
                  <label>{{ 'reference' | translate }}</label>
                  <fieldmagic-text-input
                    [(ngModel)]="objRecurringInvoice.strReference"
                    [ngModelOptions]="{ name: 'strReference' }"
                    id="reference"
                    customClass="form-control font-size-12 input-height-40"
                  ></fieldmagic-text-input>
                </div>
                <!-- /end reference -->

                <div class="col-12">
                  <label class="labels">
                    {{ 'line_items' | translate }}
                  </label>
                </div>
                <div class="col-12">
                  <browse-products
                    (objProductSelected)="addEmptyLineItem($event)"
                    [customerId]="objRecurringInvoice.objCustomer | data_get: 'id'"
                  >
                  </browse-products>
                </div>

                <!-- line items -->
                <div class="col-12 mb-3">
                  <!-- Flexible and Milestone Invoicing Field -->
                  <div class="mt-3">
                    <table class="table border-left border-right border-bottom" id="line_items">
                      <thead>
                        <tr>
                          <th scope="col" width="6%"></th>
                          <th scope="col" style="font-size: 12px;">{{ 'description' | translate }}</th>
                          <th scope="col" *ngIf="bDepartmentTracking" width="12%" style="font-size: 12px;">{{ 'department' | translate }}<required-tag></required-tag></th>
                          <th scope="col" width="10%" style="font-size: 12px;">{{ 'quantity' | translate }}</th>
                          <th scope="col" width="10%" style="font-size: 12px;">{{ 'unit_price' | translate }}</th>
                          <th scope="col" width="14%" style="font-size: 12px;">{{ 'tax_code' | translate }}<required-tag></required-tag></th>
                          <th scope="col" width="14%" style="font-size: 12px;">{{ 'account_code' | translate }}<required-tag></required-tag></th>
                          <th scope="col" width="10%" style="font-size: 12px;">{{ 'amount' | translate }}</th>
                        </tr>
                      </thead>

                      <!-- content -->
                      <tbody *ngIf="isLineItemsLoaded">
                        <!-- per line item -->
                        <ng-container
                          *ngFor="let objLineItem of arLineItems; let intIndex = index"
                        >
                          <tr
                            [ngClass]="{
                              'multiple-selected': contextMenuService.isLineItemSelected(objLineItem, selectedLineItems),
                              'row-focused': (focusedLineIndex - 1) == intIndex
                            }"
                            (click)="onLineFocus(intIndex)"
                          >
                            <td
                              [shAttachMenu]="contextMenuComponent?.menu"
                              [shMenuData]="contextMenuService.formatMenuData(objLineItem, intIndex)"
                              (click)="onClickedLineItem($event, objLineItem)"
                              matTooltip="{{ 'copy_paste_info' | translate }}"
                              matTooltipPosition="right"
                            >
                              <div class="d-flex justify-content-center">
                                <fa-icon *ngIf="arLineItems.length > 1" (click)="removeLineItem(intIndex)"
                                  class="pl-1 pr-1 pb-2 pt-2 text-danger pointer"
                                  [icon]="['fal', 'minus-circle']">
                                </fa-icon>

                                <a *ngIf="objLineItem.objItem && objLineItem.objItem['id']"
                                  href="/#/items/{{ objLineItem.objItem['id'] }}"
                                  class="pl-1 pr-1 pb-2 pt-2"
                                  target="_blank">
                                  <fa-icon
                                    matTooltipClass="tooltip-space"
                                    matTooltip="
                                    {{ 'click_to_view' | translate }}&#13;&#13;
                                    {{ 'product' | translate }}: {{ objLineItem.objItem['text'] }}&#13;
                                    {{ 'code' | translate }}: {{ objLineItem.objItem['code'] | placeholdWithString }}&#13;
                                    "
                                    class="pointer text-primary"
                                    [icon]="['fas', 'info-circle']">
                                  </fa-icon>
                                </a>
                                <fa-icon
                                  *ngIf="!objLineItem.objItem || objLineItem.objItem && !objLineItem.objItem.id"
                                  class="pl-1 pr-1 pb-2 pt-2 text-success pointer"
                                  matTooltip="{{ 'create_item' | translate }}"
                                  [icon]="['fas', 'plus-circle']"
                                  (click)="createItem(objLineItem)">
                                </fa-icon>
                              </div>
                            </td>
                            <td>
                              <textarea
                                [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="objLineItem.description"
                                class="form-control description"
                                style="font-size: 12px;"
                                placeholder="{{ 'description' | translate }}"
                              ></textarea>
                            </td>
                            <td *ngIf="bDepartmentTracking">
                              <ng-select
                                [items]="objLineItem._arSelectableDepartments$ | async"
                                [loading]="objLineItem._bIsLoadingDepartments"
                                [typeahead]="objLineItem._objDepartmentTypeahead$"
                                [hideSelected]="true"
                                [clearable]="true"
                                [ngModelOptions]="{standalone: true}"
                                [bindValue]
                                [(ngModel)]="objLineItem.objDepartment"
                                [class.is-invalid]="objDepartment.touched && ! objDepartment.valid"
                                #objDepartment="ngModel"
                                [required]="bDepartmentTracking"
                                bindLabel="text"
                                placeholder="{{ 'select_department' | translate }}"
                                (open)="onOpenDepartmentField(objLineItem)">
                                <!-- DISPLAY IN SELECTED -->
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                  <span class="ng-value-label">{{ item.text | translate }}</span>
                                </ng-template>
                                <!-- DISPLAY IN OPTION -->
                                <ng-template  ng-option-tmp let-item="item">
                                  <span class="value-text"> {{ item.text | translate }}</span>
                                </ng-template>
                              </ng-select>
                            </td>
                            <td>
                              <fieldmagic-decimal-input
                                [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="objLineItem.quantity"
                                (change)="calculateAmount(objLineItem)"
                                customClass="form-control font-size-12"
                                placeholder="{{ 'quantity' | translate }}">
                              </fieldmagic-decimal-input>
                            </td>
                            <td>
                              <fc-currency-input
                                [places]="4"
                                id="unit_price_{{ intIndex }}"
                                [(ngModel)]="objLineItem.unitPrice"
                                [ngModelOptions]="{standalone: true}"
                                [nonNegative]="false"
                                [customClass]="'form-control font-size-12'"
                                (change)="calculateAmount(objLineItem)"
                              >
                              </fc-currency-input>
                            </td>
                            <td>
                              <ng-select
                                [items]="objLineItem._arSelectableTaxCodes$ | async"
                                [loading]="objLineItem._bIsLoadingTaxCodes"
                                [typeahead]="objLineItem._objTaxCodeTypeahead$"
                                [hideSelected]="true"
                                [clearable]="true"
                                [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="objLineItem.objTaxCode"
                                [bindValue]
                                [class.is-invalid]="objTaxCode.touched && ! objTaxCode.valid"
                                (change)="calculateAmount(objLineItem)"
                                #objTaxCode="ngModel"
                                required
                                bindLabel="text"
                                placeholder="{{ 'tax_code' | translate }}"
                                (open)="onOpenTaxCodeField(objLineItem)"
                              >
                                <!-- DISPLAY IN SELECTED -->
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                  <span class="ng-value-label">{{ item.text | translate }}</span>
                                </ng-template>
                                <!-- DISPLAY IN OPTION -->
                                <ng-template  ng-option-tmp let-item="item">
                                  <span class="value-text"> {{ item.text | translate }}</span>
                                </ng-template>
                              </ng-select>
                            </td>
                            <td>
                              <ng-select
                                [items]="objLineItem._arSelectableAccountCodes$ | async"
                                [loading]="objLineItem._bIsLoadingAccountCodes"
                                [typeahead]="objLineItem._objAccountCodeTypeahead$"
                                [hideSelected]="true"
                                [clearable]="true"
                                [ngModelOptions]="{standalone: true}"
                                [bindValue]
                                [class.is-invalid]="objAccountCode.touched && ! objAccountCode.valid"
                                #objAccountCode="ngModel"
                                required
                                [(ngModel)]="objLineItem.objAccountCode"
                                bindLabel="text"
                                placeholder="{{ 'account_code' | translate }}"
                                (open)="onOpenAccountCodeField(objLineItem)"
                              >
                                <!-- DISPLAY IN SELECTED -->
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                  <span class="ng-value-label">{{ item.text | translate }}</span>
                                </ng-template>
                                <!-- DISPLAY IN OPTION -->
                                <ng-template  ng-option-tmp let-item="item">
                                  <span class="value-text"> {{ item.text | translate }}</span>
                                </ng-template>
                              </ng-select>
                            </td>
                            <td>
                              <fc-currency-input
                                customClass="form-control font-size-12 input-height-40"
                                [(ngModel)]="objLineItem.amount"
                                [ngModelOptions]="{standalone: true}"
                                (change)="onLineTotalChange($event.target, {line: objLineItem})"
                              ></fc-currency-input>
                            </td>
                            <!-- /end remove line -->
                          </tr>
                        </ng-container>
                        <!-- /end per line item -->

                        <!-- no invoice content -->
                        <tr *ngIf="arLineItems.length < 1" [shAttachMenu]="contextMenuComponent?.menu" [shMenuData]="contextMenuService.formatMenuData([], -1)">
                          <td class="text-center" colspan="9">
                            <span>{{ 'no_invoice_line' | translate }}</span>
                          </td>
                        </tr>
                        <!-- /end no invoice content -->

                      </tbody>
                      <!-- /end content -->

                      <!-- loader -->
                      <tbody *ngIf="! isLineItemsLoaded">
                        <tr><td class="text-center" style="font-size: 16px;"><fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon></td></tr>
                      </tbody>
                      <!-- /end loader -->
                    </table>
                  </div>

                  <!-- Total Computation -->
                  <div *ngIf="isLineItemsLoaded" class="mt-1">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <th scope="row" width="80%" class="invisible"></th>
                          <td scope="row" width="10%" ><span class="p-2 float-right">{{ 'total_ex_tax' | translate }}</span></td>
                          <td scope="row" width="10%" class="text-center">
                            <span class="p-2 form-group" matTooltip="{{ fltTotalExcludedTaxAmount | currency }}">{{ fltTotalExcludedTaxAmount | currency: {decimal_places: 2} }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" class="invisible"></th>
                          <td scope="row"><span class="p-2  float-right">{{ 'tax' | translate }}</span> </td>
                          <td scope="row" class="text-center">
                            <span class="p-2 form-group" matTooltip="{{ fltTotalTaxAmount | currency }}">{{ fltTotalTaxAmount | currency: {decimal_places: 2} }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" class="invisible"></th>
                          <td scope="row"><span class="p-2  float-right">{{ 'tax_adjustment_amount' | translate }}</span> </td>
                          <td scope="row" class="text-center">
                            <fc-currency-input
                              step="0.01"
                              [withControls]="true"
                              customClass="form-control font-size-12 input-height-40"
                              [(ngModel)]="totalTaxAdjustment"
                              [ngModelOptions]="{standalone: true}"
                              (change)="onTaxAdjustment($event.target)"
                            ></fc-currency-input>
                          </td>
                        </tr>
                        <tr >
                          <th scope="row" class="invisible"></th>
                          <td scope="row"><span class="p-2  float-right">{{ 'total_inc_tax' | translate }}</span> </td>
                          <td scope="row" class="text-center">
                            <span class="p-2 form-group">
                              <fc-currency-input
                                id="invoiceLineIncludedTaxField"
                                [(ngModel)]="fltTotalIncludedTaxAmount"
                                [ngModelOptions]="{standalone: true}"
                                customClass="form-control input-height-40 font-size-12"
                                (change)="onGrandTotalChange($event.target)"
                              >
                              </fc-currency-input>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- /end line items -->
              </div>
              <!-- /end row:1 -->
          </div>
          <!-- end information -->

          <!-- system -->
          <div class="tab-pane row p-4" id="system_content" role="tabpanel">
            <div class="row">
              <!-- assigned user selection -->
              <div class="col-6 mb-3">
                <label>{{ 'assigned_user' | translate }}<required-tag></required-tag></label>
                <ng-select
                  [items]="arSelectableAssignedUser$ | async"
                  [loading]="isLoadingRelated['users']"
                  [typeahead]="objRelatedAssignedUserInput$"
                  [ngModelOptions]="{ name: 'objAssignedUser' }"
                  [clearable]="true"
                  [bindValue]
                  [(ngModel)]="objRecurringInvoice.objAssignedUser"
                  [class.is-invalid]="objAssignedUser.touched && !objAssignedUser.valid"
                  #objAssignedUser="ngModel"
                  required
                  class="value-text"
                  bindLabel="text"
                  (open)="initRelatedSelectableFieldValues('users')"
                  ngDefaultControl
                  appendTo="body"
                >
                  <!-- DISPLAY IN SELECTED -->
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label">{{ item.text | translate }}</span>
                  </ng-template>
                  <!-- DISPLAY IN OPTION -->
                  <ng-template  ng-option-tmp let-item="item">
                    <span class="value-text"> {{ item.text | translate }}</span>
                  </ng-template>
                </ng-select>
              </div>
              <!-- /end assigned user selection -->
            </div>
          </div>
          <!-- end system -->
      </div>
    </form>
    <!-- /end tab-content -->
  </div>

  </app-error-handler>
</ng-container>
</mat-dialog-content>

<fc-context-menu (isClicked)="doSomethingFromContextMenu($event)"></fc-context-menu>

<ng-template #createRecurringInvoiceDisabled>
  <span id="btnCreateRecurringInvoiceDisabled" class="disabled"
    matTooltip="{{ 'forbidden_action_error' | translate }}">
    {{ 'forbidden_action_error' | translate }}
  </span>
</ng-template>
