<div class="d-flex border rounded mb-2">
  <div class="rounded-left p-2 flex-shrink-1 bg-primary text-light pl-4 pr-4 pt-3 pb-3">
    <div class="d-flex align-items-center h-100">
      <fa-icon [icon]="['fas', 'info-circle']" class="fa-lg" aria-hidden="true"></fa-icon>
    </div>
  </div>
  <div class="w-100 p-2 pl-4">
    <div class="d-flex align-items-center h-100">
      <div class="row">
        <div class="col-sx-12 col-md-12">
          <div id="sidePanelInformationDetails" class="info-size" [innerHTML]="information_content | translate"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="bLoading; else legendsContent">
  <div style="width: 100%; text-align: center;">
    <inline-view-loader [isLoading]="bLoading" [hasText]="false"></inline-view-loader>
  </div>
</ng-container>
<ng-template #legendsContent>
  <div *ngIf="arModuleLegend.length > 0" class="container-fluid mb-2">
    <div class="row">
      <ng-container *ngFor="let legend of arModuleLegend">
        <div [ngClass]="arModuleLegend.length > 12  ? 'col-3' : 'col-2'" class="legend-container">
          <div class="w-100 text-center rounded h-30" [style.background-color]="legend.color ? legend.color : null">
            <div class="row h-100">
              <div class="col-3 pr-0 legend-count text-center border-right border-white">
                {{ legend.count }}
              </div>
              <div class="col-9 pl-0 legend-text text-center cursor-pointer" (click)="onChangeFilter(legend)">
                {{ legend.text | translate }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
