import { get } from 'lodash-es';
import { LooseObject } from '../loose-object';

export class PoWizardLine {
  item_id?: string;
  item_code?: string;
  item_name?: string;
  quantity?: number;
  unit_cost?: number;
  labor?: boolean;
  use_supplier_pricing?: boolean;
  is_supplier_new_pricing?: boolean;
  is_unit_cost_changed?: boolean;
  related_products?: Array<LooseObject>;
  supplier_pricing?: Array<LooseObject>;
  description?: string;
  preferred_supplier?: string;
  preferred_supplier_text?: string;

  constructor(data: LooseObject){
    this.item_id = get(data, 'item_id');
    this.item_code = get(data, 'item_code');
    this.item_name = get(data, 'item_name');
    this.quantity = get(data, 'quantity');
    this.unit_cost = get(data, 'unit_cost');
    this.labor = get(data, 'labor');
    this.related_products = get(data, 'related_products');
    this.supplier_pricing = get(data, 'supplier_pricing');
    this.use_supplier_pricing = get(data, 'use_supplier_pricing');
    this.is_unit_cost_changed = get(data, 'is_unit_cost_changed');
    this.is_supplier_new_pricing = get(data, 'is_supplier_new_pricing');
    this.description = get(data, 'description');
    this.preferred_supplier = get(data, 'preferred_supplier');
    this.preferred_supplier_text = get(data, 'preferred_supplier_text');
  }
}