import { Pipe, PipeTransform } from '@angular/core';
import { get, isArray, isObject, map } from 'lodash-es';

@Pipe({
  name: 'dotNotation'
})
export class DotNotationPipe implements PipeTransform {

  transform(value: any, path: string): any {
    if (!path) return value;

    // Split the path into parts
    const [parentPath, ...typePath] = path.split('.');

    // Get the parent property (email_addresses)
    const parentData = get(value, parentPath);

    if (isArray(parentData)) {
      // If parentData is an array, map over it to get the type boolean value for each item
      return map(parentData, item => get(item, typePath.join('.'))).join(', ');
    } else if (isObject(parentData)) {
      // If parentData is a single object, return the type boolean value
      return get(parentData, typePath.join('.'));
    } else {
      // If parentData is neither an array nor an object
      return parentData;
    }
  }
}
