import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, defer, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { NotificationService } from '../../../services/notification.service';
import { blank, filled, observe } from '../../../shared/utils/common';
import { ChecklistsService } from '../../../services/checklist.service';
import { DialogService } from '../../../services/dialog.service';

@Component({
  selector: 'app-create-checklist',
  templateUrl: './create-checklist.component.html',
  styleUrls: ['./create-checklist.component.scss'],
  providers: [
    DialogService,
  ],
})
export class CreateChecklistComponent {
  name: string;

  errors?: Record<string, string[]>;

  readonly saving$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _checklists: ChecklistsService,
    private readonly _notifications: NotificationService,
    private readonly _router: Router,
    private readonly _dialog: DialogService,
  ) { }

  onSave = () => observe({
    before: () => this.saving$.next(true),
    after: () => this.saving$.next(false),
    observable: () => defer(() => {
      if (! this._isValid()) {
        return of(null);
      }

      return this._checklists.create({
        name: this.name,
      });
    }),
  }).pipe(
    filter((id) => filled(id)),
    tap(() => this._notifications.notifySuccess('checklist_created_success')),
  ).subscribe((id) => {
    this._dialog.close({
      instance: this,
    });

    this._router.navigate(['/checklists/edit', id]);
  });

  onClose = () => this._dialog.close({
    instance: this,
  });

  private _isValid(): boolean {
    this.errors = {};

    if (blank(this.name)) {
      this.errors['name'] = ['field_is_required'];
    }

    return blank(this.errors);
  }
}