<div class="float-left w-25">
  <fieldmagic-relate-input
    moduleName="warehouses"
    placeholder="select_warehouse"
    (change)="onWarehouseChange($event)"
    [bDisplayLabel]="false">
  </fieldmagic-relate-input>
</div>

<fieldmagic-pagination-links>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th colspan="6" class="text-center">{{ "inventory_levels" | translate }}</th>
      </tr>
      <tr>
        <th><small><strong>{{"warehouse_name" | translate}}</strong></small></th>
        <th><small><strong>{{"location" | translate}}</strong></small></th>
        <th><small><strong>{{"Min Stock Level" | translate}}</strong></small></th>
        <th><small><strong>{{"Min Reorder Qty" | translate}}</strong></small></th>
        <th><small><strong>{{"current_stock_level" | translate}}</strong></small></th>
        <th><small><strong>{{"On Order" | translate}}</strong></small></th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngIf="list$ | async as list; else loader">
        <tr *ngIf="list.length < 1">
          <td colspan="6" class="text-center">{{ "no_record_found" | translate }}</td>
        </tr>
        <tr
          *ngFor="let row of list"
          [attr.data-row]="index"
        >
          <td>
            <span class="col-value">{{ row.warehouse_name }}</span>
          </td>
          <td>
            <span class="col-value">{{
              row.location | placeholdWithString
            }}</span>
          </td>
          <td>
            <span class="col-value" decimalPipe>{{ row.min_stock_level }}</span>
          </td>
          <td>
            <span class="col-value" decimalPipe>{{
              row.min_reorder_level
            }}</span>
          </td>
          <td>
            <span class="col-value" decimalPipe>{{
              row.current_stock_level
            }}</span>
          </td>
          <td>
            <span class="col-value" decimalPipe>{{ row.on_order }}</span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</fieldmagic-pagination-links>
<ng-template #loader>
  <tr class="text-center">
    <td colspan="6">
      <inline-view-loader
        [isLoading]="true"
        [hasText]="false"
      ></inline-view-loader>
    </td>
  </tr>
</ng-template>
