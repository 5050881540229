import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DIALOG_DATA, DialogService } from '../../../../../services/dialog.service';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, defer, of, Subscription } from 'rxjs';
import { AvailablePeriodOption, ChecklistResponse, ChecklistType } from '../../../shared/types';
import { blank, data_get, fallback, filled, observe } from '../../../../../shared/utils/common';
import { filter, tap } from 'rxjs/operators';
import { ChecklistsService } from '../../../../../services/checklist.service';

@Component({
  selector: 'link-checklist-button',
  template: `
    <fieldmagic-primary-button
      variant="link"
      purpose="widget-action"
      label="link_checklist"
      icon="link"
      (click)="showDialog()"
    >
    </fieldmagic-primary-button>
  `,
})
export class LinkChecklistButtonComponent {
  @Input() moduleName: LinkChecklistButtonModuleNameProp;

  @Input() moduleId: string;

  @Output('completed') $onComplete = new EventEmitter<void>();

  constructor(
    private readonly _dialog: DialogService,
  ) {}

  showDialog = () => this._dialog.show<LinkChecklistDialogComponent, LinkChecklistDialogComponentProps>({
    component: LinkChecklistDialogComponent,
    dismissable: false,
    data: {
      module_id: this.moduleId,
      module_name: this.moduleName,
    },
    afterClosing: (_) => this.$onComplete.next(),
  });
}

@Component({
  selector: 'link-checklist-dialog',
  template: `
    <fieldmagic-dialog>
      <div class="dialog-header-content">
        <fieldmagic-dialog-title
          label="choose_checklist"
          icon="link"
        >
        </fieldmagic-dialog-title>
      </div>

      <div class="dialog-header-buttons">
        <fieldmagic-primary-button
          label="link"
          icon="link"
          (click)="onLink()"
          [isInProgress]="linking$ | async"
        >
        </fieldmagic-primary-button>

        <fieldmagic-dialog-close-button
          (click)="onClose()"
          [disabled]="linking$ | async"
        ></fieldmagic-dialog-close-button>
      </div>

      <div class="dialog-content">
        <div
          [formGroup]="form"
          fieldmagicGridRow
        >
          <div
            fieldmagicGridColumn="12"
          >
            <select-checklist-input
              formControlName="checklist"
              [types]="availableChecklistTypes$ | async"
              label="Available Checklists"
              placeholder="Please select a checklist"
              [withRequiredMarker]="true"
              [errors]="errors | data_get: 'checklist'"
              [assetTypeIds]="assetTypeIds"
            >
            </select-checklist-input>
          </div>

          <div
            *ngIf="isAvailablePeriodSelectable$ | async"
            fieldmagicGridColumn="12"
          >
            <fieldmagic-dropdown-input
              formControlName="available_periods"
              [multi]="true"
              label="Available Inspection Period/s"
              placeholder="Please inspection select period/s"
              [options]="availablePeriodOptions$ | async"
              [withRequiredMarker]="isAvailablePeriodRequired$ | async"
              [errors]="errors | data_get: 'available_periods'"
            >
            </fieldmagic-dropdown-input>
          </div>
        </div>
      </div>
    </fieldmagic-dialog>
  `
})
export class LinkChecklistDialogComponent implements OnInit, OnDestroy {
  errors: Record<string, string[]> = {};

  readonly form = new FormGroup({
    checklist: new FormControl(null),
    available_periods: new FormControl([]),
  });

  readonly availableChecklistTypes$ = new BehaviorSubject<ChecklistType[]>([
    'job',
    'asset',
  ]);

  readonly linking$ = new BehaviorSubject<boolean>(false);

  readonly isAvailablePeriodSelectable$ = new BehaviorSubject<boolean>(false);
  readonly availablePeriodOptions$ = new BehaviorSubject<AvailablePeriodOption[]>([]);
  readonly isAvailablePeriodRequired$ = new BehaviorSubject<boolean>(false);

  readonly assetTypeIds = this._props.asset_type_id;

  private _formChangesSubscription?: Subscription;
  private _checklistInputChangesSubscription?: Subscription;

  constructor(
    @Inject(DIALOG_DATA) private readonly _props: LinkChecklistDialogComponentProps,
    private readonly _dialog: DialogService,
    private readonly _checklists: ChecklistsService,
  ) {}

  ngOnInit(): void {
    if (filled(this._props.types)) {
      this.availableChecklistTypes$.next(this._props.types);
    }

    this._formChangesSubscription = this.form.valueChanges.pipe(
      tap((values) => this.isAvailablePeriodSelectable$.next(
        (data_get(values, 'checklist.type') == 'unified' && filled(data_get(values, 'checklist.available_periods')))
        || data_get(values, 'checklist.type') == 'asset'
      )),
      tap((values) => this.isAvailablePeriodRequired$.next(
        data_get(values, 'checklist.type') == 'asset'
      )),
      tap((values) => this.availablePeriodOptions$.next(
        fallback(data_get(values, 'checklist.available_periods'), {
          fallback: () => [],
        })
      )),
    ).subscribe((_) => {});

    this._checklistInputChangesSubscription = this.form.get('checklist').valueChanges
      .subscribe((_) => this.form.patchValue({
        available_periods: [],
      }));

    if (this._props.module_name == 'opportunities') {
      this.availableChecklistTypes$.next(['opportunity']);
    }
  }

  ngOnDestroy(): void {
    if (filled(this._formChangesSubscription)) {
      this._formChangesSubscription.unsubscribe();
    }

    if (filled(this._checklistInputChangesSubscription)) {
      this._checklistInputChangesSubscription.unsubscribe();
    }
  }

  onLink = () => observe({
      before: () => this.linking$.next(true),
      after: () => this.linking$.next(false),
      observable: () => defer(() => {
        if (! this._isValid()) {
          return of(null);
        }

        const checklist = this.form.value['checklist'];
        const availablePeriods = this.form.value['available_periods'];

        return this._checklists.linkTo(checklist, {
          available_periods: availablePeriods,
          ... (this._props.module_name == 'jobs' && {
            job_id: this._props.module_id,
          }),
          ... (this._props.module_name == 'opportunities' && {
            opportunity_id: this._props.module_id,
          }),
        })
      }),
    }).pipe(
      filter((linked) => filled(linked)),
    ).subscribe((linked) => this._dialog.close({
      instance: this,
      result: {
        linked,
      },
    }));

  onClose = () => this._dialog.close({
    instance: this,
  });

  private _isValid(): boolean {
    let errors: Record<string, string[]> = {};

    const checklist = this.form.value['checklist'];
    const availablePeriods = this.form.value['available_periods'];

    if (blank(checklist)) {
      errors['checklist'] = ['field_is_required'];
    }

    if (data_get(checklist, 'type') == 'asset' && blank(availablePeriods)) {
      errors['available_periods'] = ['field_is_required'];
    }

    this.errors = errors;

    return blank(errors);
  }
}

export type LinkChecklistButtonModuleNameProp = 'opportunities' | 'jobs';

export type LinkChecklistDialogComponentProps = {
  module_name: LinkChecklistButtonModuleNameProp;
  module_id: string;
  asset_type_id?: string;
  types?: ChecklistType[];
}

export type LinkChecklistDialogComponentResult = {
  linked: ChecklistResponse;
}