import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

    public objRecordDetails = {};
    constructor(
      private route: ActivatedRoute,
    ) {
      // Get user id
      this.route.params.subscribe( params => {
          // Set up record config
          this.objRecordDetails = {
            id: params['user'],
            module: 'users',
            label_id: ['first_name', 'last_name'],
            icon: 'user',
            button: [
              { type: 'dialog_edit', link: params['user'], class: 'btn-primary', label: 'edit', icon: 'edit' },
              { type: 'back', link: '/admin/users', class: 'btn-link mr-2', label: 'back_to_list', icon: 'arrow-left' },
            ]
          };
      });
    }

    ngOnInit() {
    }

}
