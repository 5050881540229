import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClientStoreService } from '../../services/client-store.service';
import { data_get, is_value_true } from '../utils/common';

@Directive({
  selector: '[fieldmagicIsConfigured]'
})
export class IsConfiguredDirective implements OnInit, OnDestroy {
  @Input('fieldmagicIsConfigured') configName: string;

  private _onClientChangeSubscription?: Subscription;

  constructor(
    private readonly _template: TemplateRef<any>,
    private readonly _container: ViewContainerRef,
    private readonly _clients: ClientStoreService,
  ) {}

  ngOnInit(): void {
    this._onClientChangeSubscription = this._clients.getWhenClientIsSetEvent()
      .subscribe((client) => {
        const isEnabled = is_value_true(
          data_get(client, ['config', this.configName])
        );

        if (isEnabled) {
          this._container.createEmbeddedView(this._template);
        } else {
          this._container.clear();
        }
      })
  }

  ngOnDestroy(): void {
    if (this._onClientChangeSubscription) {
      this._onClientChangeSubscription.unsubscribe();
    }
  }
}