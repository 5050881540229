import { Component, ContentChildren, forwardRef, Input, TemplateRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { isNil, toString } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { WYSWYGFormatDirective } from '../../../../directives/component.directive';

type OnChangeHandler = (value?: string) => void;
type OnTouchedHandler = () => void;

@Component({
  selector: 'fieldmagic-textarea-input',
  template: `
    <!-- Non-Standalone -->
    <div
      *ngIf="! standalone"
      class="form-group"
    >
      <div class="d-flex d-flex-gap">
        <fieldmagic-text
          *ngIf="displayLabel || (label | filled)"
          purpose="input"
          [content]="label"
          [withRequiredMarker]="withRequiredMarker"
        >
        </fieldmagic-text>
      </div>
      <ng-container *ngTemplateOutlet="input"></ng-container>
    </div>

    <!-- Standalone -->
    <ng-container
      *ngIf="standalone"
      [ngTemplateOutlet]="input"
    >
    </ng-container>

    <ng-template #input>
      <div class="w-100 d-flex flex-column d-flex-gap">
        <ng-container
          *ngIf="wyswyg; else textarea;"
          [ngTemplateOutlet]="quill"
        >
        </ng-container>
        <div
          class="d-flex w-100"
          [class.justify-content-between]="errors | filled"
          [class.justify-content-end]="errors | blank"
        >
          <fieldmagic-input-errors
            *ngIf="errors | filled"
            [errors]="errors"
          >
          </fieldmagic-input-errors>

          <small *ngIf="max != undefined" class="text-help">{{ remaining$ | async }}/{{max}}</small>
        </div>
      </div>
    </ng-template>

    <ng-template #textarea>
      <textarea
        class="form-control font-size-11 fieldmagic-textarea-input"
        [class.input-height-40]="expandable"
        [disabled]="isDisabled$ | async"
        (change)="onChange($event.target.value)"
        (keyup)="onKeyUp($event.target.value)"
        (blur)="onTouched()"
        [attr.maxLength]="max"
        [attr.rows]="rows"
        [value]="value"
        (focus)="onFocusIn($event.target)"
        (focusout)="onFocusOut($event.target)"
        [class.fieldmagic-input-has-error]="errors | filled"
      ></textarea>
    </ng-template>

    <ng-template #quill>
      <quill-editor
        #quill
        [ngModel]="value"
        [disabled]="isDisabled$ | async"
        [class.fieldmagic-input-has-error]="errors | filled"
        (onContentChanged)="onChange($event.html)"
        (blur)="onTouched()"
        (onFocus)="onFocusIn(quill.elementRef.nativeElement)"
        (focusout)="onFocusOut(quill.elementRef.nativeElement)"
        class="fieldmagic-wyswig-input"
        [class.hidden-toolbar]="isDisabled$ | async"
      >
        <div quill-editor-toolbar>
          <span class="ql-formats">
            <select class="ql-font">
              <option selected></option>
              <option value="serif"></option>
              <option value="monospace"></option>
            </select>
            <select class="ql-size">
              <option value="small"></option>
              <option selected></option>
              <option value="large"></option>
              <option value="huge"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>
          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <select class="ql-align">
              <option selected></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-link"></button>
          </span>

          <span
            *ngFor="let wyswygFormatTmp of wyswygFormatTmps"
            class="ql-formats"
          >
            <ng-container *ngTemplateOutlet="wyswygFormatTmp"></ng-container>
          </span>
        </div>
      </quill-editor>
    </ng-template>
  `,
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaInputComponent),
      multi: true,
    },
  ],
})
export class TextareaInputComponent implements ControlValueAccessor {
  @Input() id: string;

  @Input() max: number;

  @Input() rows: number = 5;

  @Input() expandable: boolean = false;

  @Input() errors: string | string[] = [];

  @Input() wyswyg: boolean = false;

  @Input() label?: string;

  @Input() standalone: boolean = true;

  @Input() withRequiredMarker: boolean = false;

  @ContentChildren(
    WYSWYGFormatDirective, {
      read: TemplateRef,
    }
  )
  wyswygFormatTmps: TemplateRef<WYSWYGFormatDirective>[] = [];

  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  readonly remaining$ = new BehaviorSubject<number>(0);

  _value: string;
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = toString(value).trim();
  }

  /**
   * Callback when input is touched
   */
  onTouched: OnTouchedHandler = () => { };

  onChange: OnChangeHandler = (_) => { };

  /**
   * @inheritdoc
   */
  registerOnChange(fn: OnChangeHandler): void {
    this.onChange = (value: string) => {
      this._computeRemaining(value);
      fn(toString(value).trim());
    };
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: OnTouchedHandler): void {
    this.onTouched = fn;
  }

  /**
   * {@inheritdoc}
   */
  setDisabledState(disabled: boolean): void {
    this.isDisabled$.next(disabled);
  }

  /**
   * @inheritdoc
   */
  writeValue(value?: string): void {
    this._computeRemaining(value);
    this.value = value;
  }

  onKeyUp(value: string): void {
    this._computeRemaining(value);
  }

  private _computeRemaining(value: string | undefined): void {
    value = toString(value);

    const max = this.max;

    if (value.length < 1 || isNil(max) || max < 1) {
      return this.remaining$.next(0);
    }

    const remaining = max - value.length;

    this.remaining$.next(remaining);
  }

  // handles on focus in event
  onFocusIn(target: HTMLElement): void {
    if (! this.expandable) {
      return;
    }


    target.classList.add('expanded');
  }

  onQuillFocusIn(event) {
    console.info(event);
  }

  // handles on focus out event
  onFocusOut(target: HTMLElement): void {
    if (! this.expandable) {
      return;
    }

    target.classList.remove('expanded');
  }
}
