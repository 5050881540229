// Core
import { ActivatedRoute, ActivatedRouteSnapshot, Navigation, NavigationExtras, Params, PRIMARY_OUTLET, Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// Third Party
import { LocalStorageService } from '../../../../services/local-storage.service';
import { BehaviorSubject } from 'rxjs';
import { blank, data_get, filled, transform } from '../../../utils/common';
import { trimEnd, trimStart } from 'lodash-es';

@Component({
  selector: 'back-to-list',
  template: `
    <a
      id="navigateBackToList"
      [routerLink]="redirectTo$ | async"
      [queryParams]="queryParams$  | async"
      [ngClass]="['btn',cstmClass]"
    >
      <fa-icon *ngIf="icon" [icon]="['fas', icon]" class="mr-1"></fa-icon> {{ label | translate }}
    </a>
  `,
  styleUrls: []
})
export class BackToListComponent implements OnInit, OnChanges {

  @Input() cstmClass: string;
  @Input() icon: string;
  @Input() label: string;
  @Input() link: string;
  @Input() module: string;

  readonly redirectTo$ = new BehaviorSubject<string>('');
  readonly queryParams$ = new BehaviorSubject<Params>({});

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((queries) => {
      const goBackToURL = data_get(queries, 'go_back_to');

      if (filled(goBackToURL)) {
        return this._buildLinkFromParams(goBackToURL);
      }

      // Get current page from local storage
      const arListingStorage = (this.localStorageService.getItem('listing')) ? JSON.parse(this.localStorageService.getItem('listing')) : {};

      if (arListingStorage[this.module] && arListingStorage[this.module]['page'] !== undefined) {
        const pageNum = arListingStorage[this.module]['page']['pageNum'];
        this.queryParams$.next({
          page: pageNum,
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (filled(changes.link) && changes.link.currentValue != changes.link.previousValue) {
      this.redirectTo$.next(changes.link.currentValue);
    }
  }

   _buildLinkFromParams(goBackToURL: string): void {
    const tree = this.router.parseUrl(goBackToURL);
      const link = transform(
        data_get(tree, ['root', 'children', PRIMARY_OUTLET]), {
          transformer: (value: UrlSegmentGroup) => value.toString(),
        }
      );

      if (blank(link)) {
        return;
      }

      this.redirectTo$.next(`/${trimStart(link, '/')}`);
      this.queryParams$.next(tree.queryParams);
  }
}
