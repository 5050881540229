import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { RelateIds } from '../../../../../lists/relate-ids';
import { NotificationService } from '../../../../../services/notification.service';
import * as moment from 'moment';
import { ActivityType } from '../../../../../lists/activity';
import { RecordService } from '../../../../../services/record.service';
import { ActivityRequest } from '../../../../../objects/activity';
import { DateService } from '../../../../../services/helpers/date.service';
import { isEmpty, get } from 'lodash-es';
import { ClientStoreService } from '../../../../../services/client-store.service';
import { Users } from '../../../../../objects/users';
import { Select } from '../../../../../objects/select';
import { filled, blank } from '../../../../utils/common';

const kTaskDatesFormat = 'YYYY-MM-DD HH:mm:ss';
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit {


  public strModule: string = this.data.module;
  public bSubmitted: boolean = false;
  public bShowLoader: boolean = false;
  public bPageLoaded: boolean = false;
  public strRelatedId: any = RelateIds;
  public strViewType: string = this.data.view_type;
  public arRecordData: any = this.data.record_data;
  public arRecordDetails: any = [];
  public objEditDetails: any = {};
  public objInitialRawData = {};
  public taskForm: FormGroup;
  public bCompleted: boolean = true;

  public activeTab: 'information' | 'schedules' = 'information';

  public objTaskLayout = [
    ['task'],
    ['assign_user', 'assign_team'],
    ['department_id', 'priority'],
    ['estimated_duration', 'original_estimated_duration'],
    ['due_date', 'event_date'],
    ['description'],
    ['review_complete', 'review_required']
  ];

  public objTaskField = {
    task: {
      key: 'task',
      label: 'task',
      controlType: 'text',
      required: true,
      default_value: '',
      max_length: 128,
      validator: Validators.required
    },
    department_id: {
      key: 'department_id',
      label: 'department',
      controlType: 'relate',
      default_value: '',
      required: true,
      module: 'departments',
      validator: Validators.required
    },
    description: {
      key: 'description',
      label: 'description',
      controlType: 'textarea',
      required: false,
      default_value: '',
      rows: 5,
      max_length: 10000
    },
    assign_user: {
      key: 'assign_user',
      label: 'assign_user',
      controlType: 'relate',
      default_value: null,
      required: false,
      module: 'users',
      validator: []
    },
    assign_team: {
      key: 'assign_team',
      label: 'assign_team',
      controlType: 'relate',
      default_value: null,
      required: false,
      module: 'teams',
      validator: []
    },
    priority: {
      key: 'priority',
      label: 'priority',
      controlType: 'dropdown',
      required: true,
      options: [
        {
          id: 'high',
          text: 'high'
        },
        {
          id: 'normal',
          text: 'normal'
        },
        {
          id: 'low',
          text: 'low'
        },
        {
          id: 'urgent',
          text: 'urgent',
        },
      ],
      default_value: 'normal',
      validator: Validators.required
    },
    estimated_duration: {
      key: 'estimated_duration',
      label: 'estimated_duration',
      controlType: 'number',
      required: false,
      readonly: false,
      default_value: '1',
      min_length: 0,
      validator: []
    },
    event_date: {
      key: 'event_date',
      label: 'date_completed',
      controlType: 'datetime',
      default_value: '',
      required: false,
      readonly: true,
      is_hidden: true,
      validator: []
    },
    due_date: {
      key: 'due_date',
      label: 'due_date',
      controlType: 'datetime',
      default_value: '',
      required: false,
      validator: []
    },
    review_complete: {
      key: 'review_complete',
      label: 'review_complete',
      controlType: 'checkbox',
      required: false,
      default_value: false,
    },
    review_required: {
      key: 'review_required',
      label: 'review_required',
      controlType: 'checkbox',
      required: false,
      default_value: false,
    },
    original_estimated_duration: {
      key: 'original_estimated_duration',
      label: 'original_estimated_duration',
      controlType: 'number',
      required: false,
      readonly: true,
      default_value: 0,
      min_length: 0,
      validator: []
    },
  };

  private _assignedUser: Users;

  get assignedUser(): Users {
    return this._assignedUser;
  }

  get jobId(): string|null {
    if (get(this.data, 'module') === 'jobs') {
      return get(this.data, 'record_id', null) || null;
    }

    return null;
  }

  get opportunityId(): string|null {
    if (get(this.data, 'module') === 'opportunities') {
      return get(this.data, 'record_id', null) || null;
    }

    return null;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  constructor(public dialogRef: MatDialogRef<TaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifService: NotificationService,
    private recordService: RecordService,
    protected date: DateService,
    protected client: ClientStoreService,
  ) {
    if ((this.strModule != 'jobs' && this.strModule != 'opportunities') || !this.client.isDepartmentTracking()) {
      let departmentLayoutIndex = -1;
      this.objTaskLayout.forEach((strField, index) => {
        departmentLayoutIndex = strField.findIndex(field => field == 'department_id');
        if (departmentLayoutIndex > -1) this.objTaskLayout[index].splice(departmentLayoutIndex, 1);
      });
      delete this.objTaskField['department_id'];
    }

    // Store record details
    this.arRecordDetails = data;

    if (this.strViewType == 'edit') {
      this.recordService.getRecordRelateJoined('activities', false, { 'activities.id': this.data['activity_id'] }).subscribe(response => {
        // Get updated record data of currently updating.
        this.objEditDetails = (response != null && response.length > 0) ? response[0] : [];
        this.objTaskField['task']['default_value'] = this.objEditDetails['activity_name'];
        this.objTaskField['description']['default_value'] = this.objEditDetails['notes'];
        this.objTaskField['estimated_duration']['default_value'] = this.objEditDetails['estimated_duration'];
        this.objTaskField['original_estimated_duration']['default_value'] = this.objEditDetails['original_estimated_duration'];

        if (!isEmpty(this.objEditDetails['team_text'])) {
          this.objTaskField['assign_team']['default_value'] = this.objEditDetails['team_id'];
          this.objTaskField['assign_team']['default_text'] = this.objEditDetails['team_text'];
        }

        if (!isEmpty(this.objEditDetails['user_text'])) {
          this.objTaskField['assign_user']['default_value'] = this.objEditDetails['user_id'];
          this.objTaskField['assign_user']['default_text'] = this.objEditDetails['user_text'];
          this._assignedUser = new Users({
            id: this.objEditDetails['user_id'],
            name: this.objEditDetails['user_text']
          });
        }

        if ((this.strModule === 'jobs' || this.strModule === 'opportunities') && this.objTaskField['department_id']) {
          this.objTaskField['department_id']['default_value'] = this.objEditDetails['department_id'];
          this.objTaskField['department_id']['default_text'] = this.objEditDetails['department_name'];
        }

        this.objTaskField['priority']['default_value'] = this.objEditDetails['priority'].toString();
        this.objTaskField['review_complete']['default_value'] = this.objEditDetails['review_complete'];
        this.objTaskField['review_required']['default_value'] = this.objEditDetails['review_required'];

        if (this.objEditDetails['is_completed']) {
          this.objTaskField['event_date']['is_hidden'] = false;

          let utcTime1 = moment.utc(this.objEditDetails['date_completed']).toDate();
          this.objTaskField['event_date']['default_value'] = moment(utcTime1).format(kTaskDatesFormat);

          // FC-962: disable changes to assign to if tasks is already completed
          this.objTaskField['assign_user']['readonly'] = true;
          this.objTaskField['assign_team']['readonly'] = true;
        }

        this.bCompleted = (this.objEditDetails['is_completed'] === true && this.objEditDetails['task_progress'] === "complete") ? true : false;

        let utcTime2 = moment.utc(this.objEditDetails['due_date'])

        this.objTaskField['due_date']['default_value'] = moment(utcTime2.toDate()).format(kTaskDatesFormat);
        this.initForm();
        this.objInitialRawData = { ...this.taskForm.getRawValue() };
        this.bPageLoaded = true;
      });

    } else {
      if (this.arRecordData && this.objTaskField['department_id']) {
        this.objTaskField['department_id']['default_value'] = this.arRecordData['department_id'];
        this.objTaskField['department_id']['default_text'] = this.arRecordData['department_text'];
      }
      this.initForm();
      this.bPageLoaded = true;
    }

    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

  ngOnInit() { }

  /**
   * Initialize Form
   */
  initForm() {
    // Create form control
    let arFormControl: any = {};
    // Loop each field
    Object.keys(this.objTaskField).forEach(item => {
      // Get field config
      let arFieldConfig = this.objTaskField[item];

      let objFormControl: FormControl = new FormControl(arFieldConfig['default_value'], arFieldConfig['validator']);

      arFormControl[item] = objFormControl;
    });
    // Do we have created form control?
    if (arFormControl) this.taskForm = new FormGroup(arFormControl);

  }

  /**
   * For Cancel button
   */
  cancelDialog(): void {
    if (this.taskForm.dirty) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.saveAndClose('cancel');
    }
  }

  /*
   * Create event data in activities table
   */
  onSubmit() {
    // Set submitted to true
    this.bSubmitted = true;
    // Do we have valid form?
    if (this.taskForm.valid) {

      if (this.strViewType == 'edit') {

        let arProperKeys = {
          'task': 'activity_name',
          'description': 'notes',
          'assign_user': 'user_id',
          'assign_team': 'team_id',
          'event_date': 'activity_date'
        }

        let objDiffTemp = {
          id: this.arRecordDetails['activity_id'],
          module: this.arRecordDetails['module'],
          record_id: this.arRecordDetails.record_id,
          task_progress: (this.arRecordDetails.activity.task_progress == '') ? 'awaiting_scheduling' : this.arRecordDetails.activity.task_progress
        };

        Object.keys(this.objInitialRawData).forEach(key => {
          let strKey = arProperKeys[key] != undefined ? arProperKeys[key] : key;
          let strValue = this.taskForm.getRawValue()[key] || null;

          // FC-967: validate due date
          if (strKey == 'due_date') {
            strValue = (moment(strValue).isValid()) ? strValue : null;
          }

          objDiffTemp[strKey] = strValue;

        });

        this.saveAndClose({ 'mode': 'edit', 'data': objDiffTemp });

      } else {
        let due_date = this.taskForm.controls['due_date'].value;

        // Setup request data
        let arRequestData = {
          module_id: this.arRecordDetails.record_id,
          module_field: this.strRelatedId[this.arRecordDetails.module],

          // All new tasks should have their task progress set to awaiting scheduling.
          // And since this field is hidden from the form (when trying to create or
          // edit a task), we must manually supply a value before sending the request.
          task_progress: 'awaiting_scheduling',
          team_id: this.taskForm.controls['assign_team'].value,
          subject: this.taskForm.get('task').value,
          assigned_to: this.taskForm.controls['assign_user'].value,
          priority: this.taskForm.controls['priority'].value,
          estimated_duration: (this.taskForm.controls['estimated_duration'].value == "") ? 0 : this.taskForm.controls['estimated_duration'].value,
          // Only include the `due_date` in the request if it is not blank.
          ...(moment(due_date).isValid() && { due_date: moment(due_date).format(kTaskDatesFormat) }),
          note: this.taskForm.controls['description'].value,
          module: this.data.module,
          type: ActivityType['task'],
        };

        if (this.strModule == 'jobs' && this.objTaskField['department_id'] && this.client.isDepartmentTracking()) arRequestData['department_id'] = this.taskForm.controls['department_id'].value;

        this.bSubmitted = false;
        this.saveAndClose({ 'mode': 'create', 'data': arRequestData });
      }

    }
  }

  /**
   * Marks the current task as cancelled.
   *
   * @return {void}
   */
  markTaskAsCancelled(): void {
    this.notifService.sendConfirmation("confirm_task_cancellation")
      .subscribe(
        confirmation => {
          if (confirmation.answer) {
            // Create the needed request object to udpate an activity.
            let objRequest = new ActivityRequest({
              id: this.arRecordDetails['activity_id'],
              module: this.arRecordDetails['module'],
              record_id: this.arRecordDetails.record_id,
            });

            this.saveAndClose({ 'mode': 'edit', 'data': { ...objRequest, task_progress: 'cancelled' } });
          }
        }
      );
  }

  /**
   * Method to call the close dialog to
   * pass the final actitivit value.
   *
   * @param objToSave
   */
  saveAndClose(objToSave: any) {

    // Initially we would want this at the edit level
    // But when we add 1 hr to the forms, it also
    // changes the datefield in the UI which is something
    // we dont want since we dont want the user to see
    // that we are adding a plus 1 to their hour when its DST

    this.dialogRef.close(objToSave);
  }


  /**
   * Changes the currently displayed tab.
   *
   * @param {'information' | 'schedules'} strTabName
   *
   * @returns {void}
   */
  switchTab(strTabName: 'information' | 'schedules'): void {
    this.activeTab = strTabName;
  }

  getRelateValueChange(change: { field: string, value: any }): void {
    if (filled(change)) {
      if (change.field === 'assign_user') {
        this._assignedUser = new Users({
          id: get(change, 'value.id'),
          name: get(change, 'value.text')
        })
      }
    } else {
      this._assignedUser = new Users({});
    }
  }
}
