import { Component, forwardRef, Input, EventEmitter, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { RecordService } from "../../../../../services/record.service";
import { Select } from "../../../../../objects/select";
import { debounceTime, distinctUntilChanged, finalize, shareReplay, switchMap } from "rxjs/operators";
import { isNil } from 'lodash-es';

type OnChangeHandler = (selected?: Select) => void;
type OnTouchedHandler = () => void;

export type RelateOption<T = any> = {
  id: string;
  text: string;
} & T;

export type Filter = {[key: string]: any};

@Component({
  selector: 'fieldmagic-relate-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RelateInputComponent),
      multi: true,
    }
  ],
  templateUrl: './relate-input.component.html',
  styleUrls: [
    './relate-input.component.scss',
  ]
})
export class RelateInputComponent implements ControlValueAccessor {
  /// inline replacement for the placeholder. This is used as a the label for the dropdown if you wish
  /// to have different placeholder text and label text.
  @Input() label: string;

  /**
   * Filter to be used for fetching remote data
   *
   * @type {Filter}
   */
  @Input('filter') filter: Filter;

  /**
   * Dropdown placeholder
   *
   * @type {string}
   */
  @Input('placeholder') placeholder: string;

  /**
   * Dropdown module name
   */
  @Input() moduleName: string;

  /**
   * Flags whether to display the label field. Set to true by default.
   *
   * @type {boolean}
   */
  @Input() bDisplayLabel: boolean = true;

  /**
   * Indicates that the input is standalone this means that the input
   * will not be enclosed to a form group and the label will not be displayed.
   * It will only display the provided `placeholder` value
   */
  @Input() standalone: boolean = false;

  @Input() withRequiredMarker: boolean = false;

  @Input() errors: string[] | string = [];

  /**
   * Emits whenever the value is changed.
   *
   * @type {EventEmitter<Select>}
   */
  @Output('change') $onChange = new EventEmitter<FieldmagicRelateInputChangedEvent>();

  /**
   * Flag if current options are being fetched remotely
   *
   * @type {boolean}
   */
  isLoading: boolean = false;

  /**
   * Flag if input is disabled
   *
   * @type {boolean}
   */
  isDisabled: boolean = false;

  /**
   * Search subject
   *
   * @type {Subject<string>}
   */
  search$: Subject<string> = new Subject;

  /**
   * List of available options
   *
   * @type {Observable<RelateOption[]>}
   */
  options$: Observable<RelateOption[]> = Observable.from([]);

  /**
   * Callback when input is touched
   *
   * @type {OnTouchedHandler}
   */
  onTouched: OnTouchedHandler = () => {};

  /**
   * Callback when input value was changed
   *
   * @type {OnChangeHandler}
   */
  onChange: OnChangeHandler = (_?: RelateOption) => {};

  /**
   * Contains the current selected option
   *
   * @type {RelateOption}
   */
  selected: RelateOption;

  /**
   * Cache storage
   *
   * @type {Observable<RelateOption[]>}
   */
  protected cache$: Observable<RelateOption[]>;

  /**
   * @param {RecordService} records
   */
  constructor(
    protected records: RecordService
  ) {}

  /**
   * {@inheritdoc}
   */
  writeValue(selected?: RelateOption): void {
    this.selected = selected;
  }

  /**
   * {@inheritdoc}
   */
  registerOnChange(fn: OnChangeHandler): void {
    this.onChange = (selected) => {
      if (! isNil(fn)) {
        fn(selected);
      }

      this.$onChange.emit(selected);
    };
  }

  /**
   * {@inheritdoc}
   */
  registerOnTouched(fn: OnTouchedHandler): void {
    this.onTouched = fn;
  }

  /**
   * Handles event emitted by ng-select when input is open for selection
   *
   * @returns {void}
   */
  onOpen() {
    this.options$ = Observable.concat(
      this.cache$ || this.getOptions(),
      this.search$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term: string) => this.getOptions(term))
      )
    );
  }

  /**
   * {@inheritdoc}
   */
  setDisabledState(disabled: boolean): void {
    this.isDisabled = disabled;
  }

  /**
   * Fetch remote data of sites
   *
   * @param {string|undefined} term
   *
   * @returns {Observable<RelateOption[]>}
   */
  protected getOptions(term?: string): Observable<RelateOption[]> {
    this.enableLoader();

    this.cache$ = this.records.getRecordRelate(this.moduleName, term, undefined, false, this.filter)
      .pipe(
        shareReplay(),
        finalize(() => this.disableLoader())
      );

    return this.cache$;
  }

  /**
   * Enables loader indicator
   *
   * @returns {void}
   */
  protected enableLoader(): void {
    Promise.resolve(null).then(() => this.isLoading = true);
  }

  /**
   * Disables loader indicator
   *
   * @returns {void}
   */
  protected disableLoader(): void {
    Promise.resolve(null).then(() => this.isLoading = false);
  }
}

export type FieldmagicRelateInputChangedEvent<T = any> = RelateOption<T>;
