import { get } from 'lodash-es';
import { LooseObject } from '../loose-object';

export class PurchaseOrderLine {
  item_id?: string;
  item_code?: string;
  item_name?: string;
  account_code?: string;
  account_code_id?: string;
  account_code_name?: string;
  tax_code?: string;
  tax_code_id?: string;
  tax_code_name?: string;
  tax_rate?: number;
  department_id?: string;
  department_name?: string;
  description?: string;
  quantity?: number;
  unit_cost?: number;
  item_accounting_id?: string;
  job_id?: string;
  job_number?: string;
  current_stock_level?: number;
  supplier_pricing?: Array<LooseObject>;
  related_products?: Array<LooseObject>;
  supplier_pricing_note?: string;
  show_related_job_field?: boolean;
  labor?: boolean;

  constructor(data: LooseObject){
    this.item_id = get(data, 'item_id');
    this.item_code = get(data, 'item_code');
    this.item_name = get(data, 'item_name');
    this.account_code = get(data, 'account_code');
    this.account_code_id = get(data, 'account_code_id');
    this.account_code_name = get(data, 'account_code_name');
    this.tax_code = get(data, 'tax_code');
    this.tax_code_id = get(data, 'tax_code_id');
    this.tax_code_name = get(data, 'tax_code_name');
    this.tax_rate = get(data, 'tax_rate');
    this.department_id = get(data, 'department_id');
    this.department_name = get(data, 'department_name');
    this.description = get(data, 'description');
    this.quantity = get(data, 'quantity');
    this.unit_cost = get(data, 'unit_cost');
    this.item_accounting_id = get(data, 'item_accounting_id');
    this.job_id = get(data, 'job_id');
    this.job_number = get(data, 'job_number');
    this.current_stock_level = get(data, 'current_stock_level');
    this.supplier_pricing = get(data, 'supplier_info');
    this.related_products = get(data, 'related_products');
    this.supplier_pricing_note = get(data, 'supplier_pricing_note');
    this.show_related_job_field = get(data, 'show_related_job_field');
    this.labor = get(data, 'labor');
  }
}