import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotifyViaPushService } from '../features/task-calendar/components/services/notify-via-push-service';
import { LooseObject } from '../objects/loose-object';
import { get } from 'lodash-es';
import { Observable } from 'rxjs';
import { EmailConfigData, EmailConfigRequestData } from '../shared/components/widget/email/email.component';
import { AuditedActivity } from '../objects/activity';
import { map, tap } from 'rxjs/operators';
import { blank, data_get, fallback } from '../shared/utils/common';
import { EventBusService } from './event-bus.service';
import { spf } from '../shared/utils/str';

const kBaseUrl: string = environment.url + "/activities/";

@Injectable()
export class ActivitiesService {

  constructor (
    private http: HttpClient,
    private notifyPush: NotifyViaPushService,
    private readonly _events: EventBusService,
  ) {}

  /**
   * Fetches activity records
   * @param module
   * @param record_id
   * @param filter_clause
   */
  getActivities(jsonData): Observable<GetActivitiesResult> {
    let body = new URLSearchParams();

    body.append('data', jsonData);

    return this.http.post<Response>(kBaseUrl + "get_activities", body.toString()).pipe(
      map((response) => ({
        data: fallback(data_get(response, 'data'), {
          fallback: () => [],
        }),
        has_next_token: fallback(data_get(response, 'hasNextToken'), {
          fallback: () => false,
        })
      }))
    );
  }

  /**
   * Inserts a record in activities table
   */
  createActivity(jsonData) {
    let body = new URLSearchParams({
      data: jsonData,
    });

    body = this.sendPushNotifOnDueDate(JSON.parse(jsonData), body);

    return this.http.post<Response>(kBaseUrl + "create_activity", body, {
      headers: {
        'X-Captcha': 'create_activity',
      },
    }).pipe(
      tap((response) => {
        const data = {
          ... response,
        } as AuditedActivity;

        if (blank(data_get(data, 'activity_type'))) {
          return;
        }

        this._events.dispatch<AuditedActivity>({
          name: spf('activities:created:%s', {
            args: [data_get(data, 'activity_type')],
          }),
          args: data,
        });
      })
    );
  }

  updateActivity(jsonData) {
    let body = new URLSearchParams();

    body = this.sendPushNotifOnDueDate(JSON.parse(jsonData), body);

    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "update_activity", body.toString());
  }

  getEmailRecord(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + 'get_email_record', body.toString());
  }

  deleteActivity(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "delete_activity", body.toString());
  }

  /**
   * Checks if there is due date present to send push notification.
   *
   * @param arRequestData
   * @param objURLParams
   *
   * @returns
   */
  private sendPushNotifOnDueDate(arRequestData: LooseObject, objURLParams: URLSearchParams) {

    if (arRequestData['due_date']) {
      objURLParams.append('notify_via_push', this.notifyPush.shouldNotify(arRequestData['due_date']) ? '1' : '0');

      if (arRequestData['id']) {
        objURLParams.append('push_notification_body', this.notifyPush.makePushNotificationBody({[arRequestData['id']]: {due_date: arRequestData['due_date']}}));
      } else {
        objURLParams.append('push_notification_body', this.notifyPush.getNotificationBodyMessage(arRequestData['due_date']));
      }

    }

    return objURLParams;
  }

  public getEmailConfig(data: EmailConfigRequestData): Observable<{ data: EmailConfigData }> {
    return this.http.post<{ data: EmailConfigData }>(
      `${kBaseUrl}email_form_config`,
      new URLSearchParams({
        contact_roles_data: JSON.stringify(get(data, 'contact_roles_data')),
        email_templates_module: get(data, 'email_templates_module'),
        customer_id: get(data, 'customer_id'),
        site_id: get(data, 'site_id'),
        contact_id: get(data, 'contact_id'),
        email_signature_id: get(data, 'email_signature_id'),
      })
    );
  }
}

export type GetActivitiesResult = {
  data: AuditedActivity[],
  has_next_token?: boolean;
}