import { Component, OnInit } from '@angular/core';
import { CustomFieldViewComponent, Form } from '../../../base/form';
import { ViewService } from '../../../services/view.service';
import { get } from 'lodash-es';
import { Users } from '../../../objects/users';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
})

export class SchedulesComponent implements CustomFieldViewComponent, OnInit {
  /**
   * Contains the ID of the record.
   *
   * @type {string}
   */
  private _strRecordId: string;

  /**
   * Contains the record module.
   *
   * @type {string}
   *
   */
  private _strModule: string;

  get jobId(): string|null {
    if (this._strModule === 'jobs') {
      return this._strRecordId;
    }

    return null;
  }

  get opportunityId(): string|null {
    if (this._strModule === 'opportunities') {
      return this._strRecordId;
    }

    return null;
  }

  private _assignedUser: Users;

  get assignedUser(): Users {
    return this._assignedUser;
  }

  get metadataType(): string|null {
    switch (this._strModule) {
      case 'jobs':
          return 'job';
      case 'opportunities':
          return 'opportunity';
      default:
        return null;
    }
  }

  constructor(
    private viewService: ViewService,
  ) { }

  ngOnInit(): void {
    this._strRecordId = get(this.viewService.getViewRecord(), 'id', '');
    this._strModule = this.viewService.strRecordModule;

    this._assignedUser = new Users({
      id: get(this.viewService.getViewRecord(), 'user_id', ''),
      name: get(this.viewService.getViewRecord(), 'user_text', ''),
    })
  }

  /**
   * @inheritdoc
   */
  setField(field: Form<any>): void {
    // no need at the moment
  }
}
