
    <div
      *ngIf="! standalone"
      class="form-group"
    >
      <div class="d-flex d-flex-gap">
        <fieldmagic-text
          *ngIf="displayLabel || (label | filled)"
          purpose="input"
          [content]="label"
          [withRequiredMarker]="withRequiredMarker"
        >
        </fieldmagic-text>

        <fieldmagic-icon
          *ngIf="tooltip | filled"
          icon="info-circle"
          [tooltip]="tooltip"
        >
        </fieldmagic-icon>
      </div>
      <ng-container *ngTemplateOutlet="input"></ng-container>
    </div>
    <ng-container
      *ngIf="standalone"
      [ngTemplateOutlet]="input"
    >
    </ng-container>
    <ng-template #input>
      <ng-select
        placeholder="{{ placeholder | translate }}"
        class="fieldmagic-dropdown"
        [class.fieldmagic-input-has-error]="errors | filled"
        [disabled]="isDisabled$ | async"
        [items]="options$ | async"
        [clearable]="clearable"
        [typeahead]="typeahead$"
        [loading]="searching$ | async"
        [ngModel]="value$ | async"
        (change)="onChanged($event)"
        (blur)="onTouched()"
        (click)="onTouched()"
        [multiple]="multi"
        [groupBy]="makeGroupNameFactory()"
        bindLabel="text"
        [bindValue]="bindValue"
        appendTo="body"
      >
        <!-- displayed for the selected dropdown option -->
        <ng-template
          ng-label-tmp
          let-item="item"
          let-clear="clear"
        >
          <span class="ng-value-label">{{ computeDisplay(item) | translate }}</span>
          <span
            *ngIf="multi"
            class="ng-value-icon right selected-close"
            (click)="clear(item)"
          >
            x
          </span>
        </ng-template>

        <!-- displayed for the dropdown options -->
        <ng-template
          ng-option-tmp
          let-item="item"
        >
          {{ computeDisplay(item) | translate }}
        </ng-template>

        <ng-template
          ng-optgroup-tmp
          let-item="item"
        >
          {{ item | data_get: 'text' | translate }}
        </ng-template>
      </ng-select>
      <fieldmagic-input-errors
        *ngIf="errors | filled"
        [errors]="errors"
      ></fieldmagic-input-errors>
    </ng-template>
  