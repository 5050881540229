import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, forkJoin, defer, of } from 'rxjs';
import { NotificationService } from '../../../services/notification.service';
import { ChecklistsService, ModulesDropdownOptions } from '../../../services/checklist.service';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { includes, isEqual, isNil, last, reduce } from 'lodash-es';
import { AnswerableChecklistPrompt, AvailablePeriodOption, Checklist, ChecklistPrompt, ChecklistPromptGroup, ChecklistPromptGroupConfiguration, ChecklistRequiredBefore, ChecklistType, PromptFieldEntry } from '../../../module/checklists/shared/types';
import { TranslateService } from '@ngx-translate/core';
import { PusherService } from '../../../services/pusher.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { FieldmagicDropdownOption } from '../../../shared/components/forms/input/dropdown/dropdown.component';
import { filled, blank, generateId, data_get, observe, filter_array, spf, fallback, str_snake_case, arr_map } from '../../../shared/utils/common';
import { FieldmagicRelateInputChangedEvent } from '../../../shared/components/forms/input/relate/relate-input.component';
import { AssetType } from '../../asset-types/shared/types';
import { DIALOG_DATA, DialogService } from '../../../services/dialog.service';
import { ConfigurableDropdownOption } from '../../../objects/studio';

const CHECKLIST_AVAILABLE_PERIODS = [
  'annual',
  'bi_annual',
  'every_2_years',
  'every_3_years',
  'every_4_years',
  'every_5_years',
  'monthly',
  'quarterly',
  'weekly',
  'one_day',
  'non_recurring',
  'every_10_years',
];

@Component({
  selector: 'app-update-checklist',
  templateUrl: './update-checklist.component.html',
  styleUrls: [
    './update-checklist.component.scss',
  ]
})
export class UpdateChecklistComponent implements OnInit {
  get displayName() : string {
    if (blank(this.name)) {
      return 'Unnamed';
    }

    if (this.type != 'asset') {
      return this.name;
    }

    return spf('%s (%s)', {
      args: [this.name, fallback(data_get(this.assetType, 'name'), {
        fallback: () => 'No Asset Type',
      })]
    });
  }

  id: string;

  type: ChecklistType = 'unified';

  name: string;

  isEnabled: boolean = true;

  isCommentEnabled: boolean = false;

  requiredBefore?: ChecklistRequiredBefore = 'starting_every_travel';

  autoAttachedOn: string[] = [];

  perPromptGroupConfiguration?: Checklist['group_prompts_configuration'];

  documentOrdering?: number = 0;

  /// backward compatibility props
  assetType?: AssetType;
  availablePeriods: AvailablePeriodOption[] = [];

  errors: Record<string, string[]> = {};

  readonly prompts$ = new BehaviorSubject<ChecklistPrompt[]>([]);

  readonly periods$ = new BehaviorSubject<FieldmagicDropdownOption<string>[]>(CHECKLIST_AVAILABLE_PERIODS);

  readonly fieldEntries$ = new BehaviorSubject<Record<string, PromptFieldEntry[]>>({
    assets: [],
    jobs: [],
    opportunities: [],
    asset_types: [],
    sites: [],
  });

  readonly fetching$ = new BehaviorSubject<boolean>(false);

  readonly deleting$ = new BehaviorSubject<boolean>(false);

  readonly saving$ = new BehaviorSubject<boolean>(false);

  readonly requierdBeforeOptions$ = new BehaviorSubject<FieldmagicDropdownOption<ConfigurableDropdownOption>[]>([]);

  readonly attachedOnOptions$ = new BehaviorSubject<FieldmagicDropdownOption<ConfigurableDropdownOption>[]>([]);

  private _hovered: boolean = false;

  constructor(
    private readonly _translator: TranslateService,
    private readonly _checklists: ChecklistsService,
    private readonly _pusher: PusherService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _notifications: NotificationService,
    private readonly _dialog: DialogService,
  ) {}

  ngOnInit(): void {
    observe({
      before: () => this.fetching$.next(true),
      after: () => this.fetching$.next(false),
      observable: () => this._route.params.pipe(
        switchMap((params) => this._pusher.isRecordHasPresence('checklists', params.id).pipe(
          map((hasPresence) => ({
            hasPresence,
            id: params.id,
          })),
        )),
        tap(({ hasPresence }) => hasPresence && this._router.navigate(['checklists'], {
          replaceUrl: true
        })),
        filter(({ hasPresence }) => ! hasPresence),
        switchMap(({id}) => forkJoin({
          field_entries: this._checklists.getFieldEntries(),
          data: this._checklists.get(id),
          dropdown: this._checklists.getModulesDropdownOptions(),
        })),
      ),
    }).subscribe((results) => this._initializeChecklist(results.data, results.field_entries, {
      dropdown: results.dropdown,
    }));
  }

  ngOnDestroy(): void {
    this._pusher.disconnectFromEditorsPresence();
  }

  onSave = () => observe({
    before: () => this.saving$.next(true),
    after: () => this.saving$.next(false),
    observable: () => defer(() => {
      if (! this._isValid()) {
        return of(false);
      }

      return this._checklists.update(this.id, {
        type: this.type,
        name: this.name,
        is_enabled: this.isEnabled,
        is_comment_enabled: this.isCommentEnabled,
        questions: {
          name: this.type == 'asset' ? 'per_asset_prompts' : 'main_prompts',
          prompts: this.prompts$.getValue(),
        },
        asset_type_id: data_get(this.assetType, 'id'),
        available_periods: this.availablePeriods,
        document_ordering: this.documentOrdering,
        required_before: this.requiredBefore,
        group_prompts_configuration: this.perPromptGroupConfiguration,
        auto_attached_on: this.autoAttachedOn,
      })
    }),
  }).pipe(
    filter((successful) => successful),
  ).subscribe((_) => {});

  onDelete = () => observe({
    before: () => this.deleting$.next(true),
    after: () => this.deleting$.next(false),
    observable: () => this._checklists.delete(this.id),
  }).subscribe((deleted) => {
    if (! deleted) {
      return;
    }

    this._router.navigate(['checklists']);
  });

  onAddPrompt(opts: {
    prompts?: ChecklistPrompt<AnswerableChecklistPrompt>[],
    group?: ChecklistPrompt<ChecklistPromptGroup>,
  } = {}): void {
    opts = Object.assign({
      prompts: this.prompts$.getValue(),
    }, opts);

    let destination = opts.prompts;

    if (filled(opts.group)) {
      destination = opts.group.prompts;
    }

    destination.push(this._makeInitialPrompt());

    this.prompts$.next(opts.prompts);
  }

  onGroupPromptAdd(): void {
    const prompts = this.prompts$.getValue();

    prompts.push(
      this._makeInitialGroupPrompt()
    );
  }

  onPromptRemove(pos: number, prompts: ChecklistPrompt[], opts: {
    group?: ChecklistPromptGroup,
  } = {}): void {
    let source = prompts;

    if (blank(prompts)) {
      return;
    }

    if (filled(opts.group)) {
      source = opts.group.prompts;
    }

    /// remove from source
    source.splice(pos, 1);

    /// update the prompts
    this.prompts$.next(prompts);
  }

  isDroppable = (): boolean => this._hovered;

  onHover = (value: boolean) => this._hovered = value;

  getRepeatingGroupsClassNames(prompts: ChecklistPrompt[]): string[] {
    let classNames: string[] = [];

    for (let index = 0; index < prompts.length; ++index) {
      if (! prompts[index].is_group) {
        continue;
      }

      classNames.push(
        spf('repeating-group-%d', {
          args: [index],
        }),
        spf('repeating-group-%d-header', {
          args: [index],
        }),
      );
    }

    return classNames;
  }

  getConnectedDroppableLists = (pos: number, source: ChecklistPrompt[]): string[] => ['outer'].concat(
    filter_array(
      this.getRepeatingGroupsClassNames(source),
      (className) => className != spf('repeating-group-%d', {
        args: [pos],
      })
    )
  );

  onPromptDragDropped(event: CdkDragDrop<string[]>, opts: {
    prompts: ChecklistPrompt<AnswerableChecklistPrompt | ChecklistPromptGroup>[],
    group?: ChecklistPromptGroup,
  }): void {
    const group = opts.group;
    const prompts = opts.prompts;
    const dropped: ChecklistPrompt = event.item.data;
    const container = prompts.find((prompt) => {
      if (! prompt.is_group) {
        return false;
      }

      const children = (prompt as ChecklistPromptGroup).prompts;

      if (blank(children)) {
        return false;
      }

      return children.findIndex((child) => child.id == dropped.id) !== -1;
    }) as ChecklistPromptGroup;

    /// dropping a prompt to a grouped prompt
    if (blank(container)
      && ! includes(data_get(group, 'prompts'), dropped)
      && ! dropped.is_group
      && ! isNil(group)
    ) {
      return this._dropPromptTo(prompts, {
        source: prompts,
        destination: group.prompts,
        fromIndex: event.previousIndex,
        toIndex: event.currentIndex,
      });
    }
    /// end

    /// dropping a prompt from a group to outside of the group (not to another group)
    const destination = prompts[event.currentIndex];

    if (filled(destination)
      && blank(group)
      && filled(container)
    ) {
      return this._dropPromptTo(prompts, {
        source: container.prompts,
        destination: prompts,
        fromIndex: event.previousIndex,
        toIndex: event.currentIndex,
      });
    }
    /// end

    /// dropping a prompt from a group to another group
    /// but shouldn't be on the same group as it source
    if (filled(container)
      && filled(group)
      && ! isEqual(container, group)
    ) {
      return this._dropPromptTo(prompts, {
        source: container.prompts,
        destination: group.prompts,
        fromIndex: event.previousIndex,
        toIndex: event.currentIndex,
      });
    }
    /// end

    /// dropping a prompt from a last group prompt outside
    if (! event.isPointerOverContainer
      && filled(dropped)
      && isEqual(container, last(prompts))
    ) {
      return this._dropPromptTo(prompts, {
        source: container.prompts,
        destination: prompts,
        fromIndex: event.previousIndex,
        toIndex: event.currentIndex,
      });
    }
    /// end

    /// dropping a prompt within the same group
    if (filled(group)) {
      return this._dropPromptTo(prompts, {
        source: group.prompts,
        destination: group.prompts,
        fromIndex: event.previousIndex,
        toIndex: event.currentIndex,
      });
    }
    /// end

    /// dropping a prompt within the same context
    /// a good example is re-positioning prompts one after another
    return this._dropPromptTo(prompts, {
      source: prompts,
      destination: prompts,
      fromIndex: event.previousIndex,
      toIndex: event.currentIndex,
    });
  }

  onAssetTypeChange(event: FieldmagicRelateInputChangedEvent, group: ChecklistPromptGroup): void {
    /// set the available period to always when asset type was removed
    if (blank(event)) {
      group.available_period = 'always';
    }
  }

  onOpenPerGroupPromptConfiguration = (prompt: ChecklistPrompt<ChecklistPromptGroup>) => this._dialog.show<
    ChecklistPromptGroupConfigDialogComponent, ChecklistPromptGroupConfigDialogProps, ChecklistPromptGroupConfigDialogResult
  >({
    component: ChecklistPromptGroupConfigDialogComponent,
    data: {
      configuration: fallback(data_get(this.perPromptGroupConfiguration, prompt.id), {
        fallback: () => ({
          prompt_per_column: 1,
        })
      }),
    },
    size: 'sm',
    afterClosing: (result) => {
      if (blank(result)) {
        return;
      }

      this.perPromptGroupConfiguration = {
        ... this.perPromptGroupConfiguration,
        ... {
          [prompt.id]: result.configuration,
        }
      };
    },
  });

  onRequiredBeforeChange = (selected: FieldmagicDropdownOption<ConfigurableDropdownOption>) =>
      this.requiredBefore = data_get(selected, 'id');

  onAttachedOnChange = (selected: FieldmagicDropdownOption<ConfigurableDropdownOption>[]) =>
    this.autoAttachedOn = arr_map(selected, {
      transformer: (option) => data_get(option, 'id'),
    });

  private _initializeFieldEntries = (modules: Record<string, PromptFieldEntry[]>): void => this.fieldEntries$.next(modules);

  private _initializeChecklist(data: Checklist, fieldEntries: Record<string, PromptFieldEntry[]>, opts: {
    dropdown?: ModulesDropdownOptions,
  } = {}): void {
    this.id = data.id;
    this.type = data.type;
    this.name = data.name;
    this.isEnabled = data.is_checklist_enabled;
    this.isCommentEnabled = data.is_comment_enabled;
    this.availablePeriods = data.available_periods;
    this.requiredBefore = data.required_before;
    this.documentOrdering = data.document_ordering;
    this.perPromptGroupConfiguration = fallback(data.group_prompts_configuration, {
      fallback: () => ({}),
    });

    if (filled(data.auto_attached_on)) {
      this.autoAttachedOn = data.auto_attached_on;
    }

    if (data.type == 'unified') {
      this.periods$.next([
        'always',
        ... CHECKLIST_AVAILABLE_PERIODS,
      ]);
    }

    if (data.type == 'asset') {
      this.assetType = {
        id: data.asset_type_id,
        name: data.asset_type_text,
        attributes: data.asset_type_attributes,
      };
    }

    /// render prompts
    const family = data.questions.find((question) => (question.name == 'per_asset_prompts' && data.type == 'asset')
      || (data.type != 'asset' && question.name == 'main_prompts')
    );

    if (filled(family)) {
      this.prompts$.next(family.prompts);
    }

    this.requierdBeforeOptions$.next(fallback(data_get(opts, 'dropdown.checklists.required_before'), {
      fallback: () => [],
    }));

    /// initialize field entries
    this._initializeFieldEntries(fieldEntries);

    /// initialize dropdown options
    const attachedOnOptions: FieldmagicDropdownOption<ConfigurableDropdownOption>[] = [
      {
        id: 'on_jobs_create',
        text: this._translator.instant('Every new job'),
        groupName: 'job',
      },
    ];

    if (filled(data_get(opts, 'dropdown.jobs.type'))) {
      const types: ConfigurableDropdownOption[] = data_get(opts, 'dropdown.jobs.type');

      for (const type of types) {
        attachedOnOptions.push({
          id: spf('on_jobs_create_with_type_%s', {
            args: [str_snake_case(type.id, {
              lowercase: true,
            })],
          }),
          text: this._translator.instant('on_job_create_with_type', {
            job_type: this._translator.instant(type.text),
          }),
          groupName: 'job',
        });
      }
    }

    attachedOnOptions.push({
      id: 'on_opportunities_create',
      text: this._translator.instant('on_quote_create'),
      groupName: 'quote',
    });

    this.attachedOnOptions$.next(attachedOnOptions);
  }

  /**
   * Make an initial prompt that will be used during creation of prompts
   *
   * FC-3781: set default prompt as pass/fail
   */
  private _makeInitialPrompt = (): ChecklistPrompt<AnswerableChecklistPrompt> => ({
    id: generateId(),
    prompt: '',
    type: 'pass/fail',
    value : {
      pass: [this._translator.instant('pass')],
      fail: [this._translator.instant('fail')],
      is_passed: 'default',
    },
    schedule_type: {
      ... (this.type != 'asset' && {
        always: true,
      }),
      ... (this.type == 'asset' && reduce(this.availablePeriods, (acc, period) => ({
        ... acc,
        [period]: true,
      }), {})),
    },
    is_required: true,
    is_visible_in_reports: true,
    is_image_enabled: false,
    is_group: false,
  });

  private _makeInitialGroupPrompt = (): ChecklistPrompt<ChecklistPromptGroup> => ({
    id: generateId(),
    group_name: '',
    type: 'group',
    prompts: [
      this._makeInitialPrompt(),
    ],
    is_group: true,
    available_period: 'always',
  });

  private _dropPromptTo(prompts: ChecklistPrompt[], opts: {
    source: ChecklistPrompt[],
    destination: ChecklistPrompt[],
    fromIndex: number,
    toIndex: number,
  }): void {
    transferArrayItem(opts.source, opts.destination, opts.fromIndex, opts.toIndex);

    /// attempt rebuild?
    this.prompts$.next(prompts);
  }

  private _isValid(): boolean {
    this.errors = {};

    if (blank(this.name)) {
      this.errors['name'] = ['field_is_required'];
    }

    if (this.type == 'asset' && blank(this.availablePeriods)) {
      this.errors['available_periods'] = ['field_is_required'];
    }

    if (this.documentOrdering > 999 || this.documentOrdering < -999) {
      this.errors['document_ordering'] = ['This field must not exceed 999 and must not be less than -999.'];
    }

    const prompts = this.prompts$.getValue();

    for (const prompt of prompts) {
      /// validates sub/child prompts
      if (prompt.type == 'group' && prompt.is_group) {
        for (const subPrompt of data_get(prompt, 'prompts', { default_value: [] })) {
          this._validatePrompt(subPrompt);
        }
      }

      this._validatePrompt(prompt);
    }

    const isValid = blank(this.errors);

    if (! isValid) {
      this._notifications.notifyError('Please make sure that the checklist information are correct.');
    }

    return isValid;
  }

  private _validatePrompt(prompt: ChecklistPrompt): void {
    if (blank(data_get(prompt, 'prompt')) && blank(data_get(prompt, 'group_name'))) {
      this.errors[spf('%s:prompt', {
        args: [prompt.id],
      })] = ['The prompt must not be empty'];
    }

    if (prompt.type == 'group' && blank(data_get(prompt, 'prompts'))) {
      this.errors[spf('%s:prompt', {
        args: [prompt.id]
      })] = ['Missing child prompts'];
    }

    if (blank(data_get(prompt, 'type'))) {
      this.errors[spf('%s:type', {
        args: [prompt.id],
      })] = ['A type must be specified.'];
    }

    const valueErrorKey = spf('%s:value', {
      args: [prompt.id]
    });

    if (prompt.type == 'pass/fail' && blank(data_get(prompt, 'value.pass'))) {
      this.errors[spf('%s:pass', {args: [valueErrorKey]})] = ['A pass option must be provided.'];
    }

    if (prompt.type == 'pass/fail' && blank(data_get(prompt, 'value.fail'))) {
      this.errors[spf('%s:fail', {args: [valueErrorKey]})] = ['A fail option must be provided.'];
    }

    if (prompt.type == 'instructions' && blank(data_get(prompt, 'value.instructions_text'))) {
      this.errors[valueErrorKey] = ['field_is_required'];
    }

    if (prompt.type == 'field_entry' && blank(data_get(prompt, 'value.field_entry'))) {
      this.errors[valueErrorKey] = ['field_is_required'];
    }

    if (prompt.type == 'dropdown' && blank(data_get(prompt, 'value.dropdown'))) {
      this.errors[valueErrorKey] = ['field_is_required'];
    }
  }
}

@Component({
  selector: 'checklist-group-prompt-configuration',
  template: `
    <fieldmagic-dialog
      icon="cog"
      label="Group Configuration"
      [instance]="self"
    >
      <ng-template fieldmagicDialogButtonsTmp>
        <fieldmagic-primary-button
          purpose="save"
          (click)="onSave()"
        >
        </fieldmagic-primary-button>
      </ng-template>

      <ng-template fieldmagicDialogContentTmp>
        <fieldmagic-number-input
          label="Cells per row"
          [(ngModel)]="promptPerColumn"
          [errors]="errors | data_get: 'prompt_per_column'"
          tooltip="Allows the prompts under this group to be displayed as column."
        >
        </fieldmagic-number-input>
      </ng-template>
    </fieldmagic-dialog>
  `,
})
export class ChecklistPromptGroupConfigDialogComponent {
  promptPerColumn: number = fallback(this._props.configuration.prompt_per_column, {
    fallback: () => 1,
  });

  errors: Record<string, string[]> = {};

  get self(): ChecklistPromptGroupConfigDialogComponent {
    return this
  }

  constructor(
   @Inject(DIALOG_DATA) private readonly _props: ChecklistPromptGroupConfigDialogProps,
   private readonly _dialog: DialogService,
  ) {}

  onSave(): void {
    if (! this._isValid()) {
      return;
    }

    this._dialog.close<this, ChecklistPromptGroupConfigDialogResult>({
      instance: this,
      result: {
        configuration: {
          ... this._props.configuration,
          prompt_per_column: this.promptPerColumn,
        }
      }
    });
  }

  private _isValid(): boolean {
    const errors: Record<string, string[]> = {};

    if (this.promptPerColumn < 1) {
      errors['prompt_per_column'] = ['Must be atleast 1 column.'];
    } else if (this.promptPerColumn > 4) {
      errors['prompt_per_column'] = ['Must not be greater than 4 columns.'];
    }

    this.errors = errors;

    return blank(errors);
  }
}

type ChecklistPromptGroupConfigDialogProps = {
  configuration: ChecklistPromptGroupConfiguration;

}

type ChecklistPromptGroupConfigDialogResult = {
  configuration: ChecklistPromptGroupConfiguration;
}