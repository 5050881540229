import { Component, ContentChild, ElementRef, Input, TemplateRef } from '@angular/core';
import { DialogButtonsTemplateDirective, DialogContenTemplatetDirective, DialogHeaderTitleTemplateDirective } from '../../directives/component.directive';

@Component({
  selector: 'fieldmagic-dialog',
  templateUrl: './dialog.component.html',
})
export class DialogComponent<C = any> {
  @Input('label') title?: string;

  @Input() icon?: string;

  @Input() instance?: C;

  @Input() disableClose: boolean = false;

  @ContentChild(DialogHeaderTitleTemplateDirective, {
    read: TemplateRef,
  })
  titleTmp?: TemplateRef<ElementRef>;

  @ContentChild(DialogButtonsTemplateDirective, {
    read: TemplateRef,
  })
  buttonsTmp?: TemplateRef<ElementRef>;

  @ContentChild(DialogContenTemplatetDirective, {
    read: TemplateRef
  })
  contentTmp?: TemplateRef<ElementRef>;
}
