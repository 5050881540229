import { Activity } from "../objects/activity";
import { Item } from "../objects/item";
import { Relate } from "../objects/relate";
import { toString } from 'lodash-es';
import { toFormattedNumber } from '../shared/utils/numbers';

export class MaterialLineItem {

    quantity: string;
    markup: string;
    unit_cost: string;
    unit_price: string;
    type: string;
    product: Relate<Item>;
    task: Relate<Activity>;
    exclude_from_invoice: boolean ;
    notes: string;
    from_work_order: boolean;
    work_order_reference: string;
    status: string;
    item_id: string;

    get total_cost() {
      return toString(
        toFormattedNumber(this._quantity * this._unit_cost, {
          currency: true,
        })
      );
    }

    get total_price() {
      return toString(toFormattedNumber(this._quantity * this._unit_price, {
        currency: true,
      }));
    }

    get _total_cost() {
      return parseFloat(this.total_cost);
    }

    get _total_price() {
      return parseFloat(this.total_price);
    }

    get _quantity() {
      return parseFloat(this.quantity);
    }

    set _quantity(value: number) {
      this.quantity = toString(
        toFormattedNumber(value)
      );
    }

    get _markup() {
      return parseFloat(this.markup);
    }

    set _markup(value: number) {
      this.markup = toString(toFormattedNumber(value));
    }

    get _unit_cost() {
      return parseFloat(this.unit_cost);
    }

    set _unit_cost(value: number) {
      this.unit_cost = toString(toFormattedNumber(value, {
        currency: true,
        maxDecimalPlaces: 4,
      }));
    }

    get _unit_price() {
      return parseFloat(this.unit_price);
    }

    set _unit_price(value: number) {
      this.unit_price = toString(
        toFormattedNumber(value, {
          currency: true,
          maxDecimalPlaces: 4,
        })
      );
    }

    markUpComputation(): number {
      let intTotalComputation = 100 * (this._unit_price - this._unit_cost) / this._unit_cost;

      return toFormattedNumber(intTotalComputation !== Infinity ? intTotalComputation : 0);
    }

    getMaterialData(strJobId: string) {
      return {
        'type': this.type,
        'quantity': this._quantity,
        'unit_cost': this._unit_cost,
        'unit_price': this._unit_price,
        'total_price': this._total_price,
        'total_cost': this._total_cost,
        'markup': this._markup,
        'product': (this.type == 'once_off_purchase') ? this.product.value : '',
        'item_id': this.product.value ? this.product.value.id : '',
        'activity_id': this.task.value ? this.task.value.id : '',
        'job_id': strJobId,
        'from_work_order': this.from_work_order,
        'work_order_reference': this.work_order_reference,
        'status': this.status
      }
    }

    constructor(properties: any = {}) {
      this.type = properties.type || 'product_catalog';
      this.product = properties.product;
      this.task = properties.task;
      this.markup =  properties.markup || '0.000';
      this.quantity = properties.quantity || '0.000';
      this.unit_cost = properties.unit_cost || '0.0000';
      this.unit_price = properties.unit_price || '0.0000';
      this.from_work_order = properties.from_work_order || false;
      this.work_order_reference = properties.work_order_reference;
      this.status = properties.status || 'pending';
      this.item_id = properties.item_id || null;
      this.from_work_order = properties.from_work_order || false;
    }
  }
