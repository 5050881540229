import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AsyncLoadedContentComponentDirective } from '../../directives/component.directive';

@Component({
  selector: 'fieldmagic-async-content',
  template: `
    <fieldmagic-grid>
      <div fieldmagicGridColumn="12">
        <div
          *ngIf="loading$ | async; else content;"
          class="d-flex justify-content-center"
        >
          <fieldmagic-icon
            icon="spinner"
            [spin]="true"
          >
          </fieldmagic-icon>
        </div>
      </div>
    </fieldmagic-grid>
  `
})
export class AsyncContentComponent {
  @Input()
  set loading(value: boolean) {
    this.loading$.next(value);
  }

  @ContentChild(
    AsyncLoadedContentComponentDirective,
    {
      read: TemplateRef,
    }
  )
  content: TemplateRef<any>;

  readonly loading$ = new BehaviorSubject<boolean>(false);
}