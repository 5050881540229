import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isArray, isNil, isString } from 'lodash-es';
import {
  BehaviorSubject,
  concat,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { blank, data_get, fallback } from '../../../../utils/common';

@Component({
  selector: 'fieldmagic-dropdown-input',
  template: `
    <div
      *ngIf="! standalone"
      class="form-group"
    >
      <div class="d-flex d-flex-gap">
        <fieldmagic-text
          *ngIf="displayLabel || (label | filled)"
          purpose="input"
          [content]="label"
          [withRequiredMarker]="withRequiredMarker"
        >
        </fieldmagic-text>

        <fieldmagic-icon
          *ngIf="tooltip | filled"
          icon="info-circle"
          [tooltip]="tooltip"
        >
        </fieldmagic-icon>
      </div>
      <ng-container *ngTemplateOutlet="input"></ng-container>
    </div>
    <ng-container
      *ngIf="standalone"
      [ngTemplateOutlet]="input"
    >
    </ng-container>
    <ng-template #input>
      <ng-select
        placeholder="{{ placeholder | translate }}"
        class="fieldmagic-dropdown"
        [class.fieldmagic-input-has-error]="errors | filled"
        [disabled]="isDisabled$ | async"
        [items]="options$ | async"
        [clearable]="clearable"
        [typeahead]="typeahead$"
        [loading]="searching$ | async"
        [ngModel]="value$ | async"
        (change)="onChanged($event)"
        (blur)="onTouched()"
        (click)="onTouched()"
        [multiple]="multi"
        [groupBy]="makeGroupNameFactory()"
        bindLabel="text"
        [bindValue]="bindValue"
        appendTo="body"
      >
        <!-- displayed for the selected dropdown option -->
        <ng-template
          ng-label-tmp
          let-item="item"
          let-clear="clear"
        >
          <span class="ng-value-label">{{ computeDisplay(item) | translate }}</span>
          <span
            *ngIf="multi"
            class="ng-value-icon right selected-close"
            (click)="clear(item)"
          >
            x
          </span>
        </ng-template>

        <!-- displayed for the dropdown options -->
        <ng-template
          ng-option-tmp
          let-item="item"
        >
          {{ computeDisplay(item) | translate }}
        </ng-template>

        <ng-template
          ng-optgroup-tmp
          let-item="item"
        >
          {{ item | data_get: 'text' | translate }}
        </ng-template>
      </ng-select>
      <fieldmagic-input-errors
        *ngIf="errors | filled"
        [errors]="errors"
      ></fieldmagic-input-errors>
    </ng-template>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownInputComponent),
      multi: true,
    },
  ],
  styleUrls: [
    './dropdown.component.scss',
  ],
})
export class DropdownInputComponent<O extends FieldmagicDropdownOption>
  implements ControlValueAccessor, OnDestroy {
  /// the current input label
  @Input() label: string;

  /// the options for the dropdown
  @Input()
  set options(value: O[] | Observable<O[]>) {
    if (isArray(value)) {
      this.options$ = of(value);
    } else {
      this.options$ = value;
    }

    if (! isNil(this.remote)) {
      this.options$ = concat(
        this.options$,
        this.typeahead$.pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => this.searching$.next(true)),
          switchMap((term) => this.remote(term)),
          map((options) => this._filterOptions(options)),
          finalize(() => this.searching$.next(false)),
        ),
      );
    } else {
      this.options$ = this.options$.pipe(
        map((options) => this._filterOptions(options)),
      );
    }
  }

  /// indicates that the value should be cleared
  @Input() clearable: boolean = true;

  /// the remote search factory that will be called when search
  /// is perform in this input
  @Input() remote: RemoteSearchFactory<O>;

  /// the placeholder for the dropdown
  @Input() placeholder: string = '';

  @Input() displayLabel: boolean = true;

  /**
   * Indicates that the input is standalone this means that the input
   * will not be enclosed to a form group and the label will not be displayed.
   * It will only display the provided `placeholder` value
   */
  @Input() standalone: boolean = false;

  /**
   * Indicates the dropdown will omit multiple values
   * or users can select multiple values
   */
  @Input() multi: boolean = false;

  @Input() filter: FieldmagicDropdownOptionFilter<O>;

  @Input() grouped: boolean = false;

  @Input() bindValue?: string;

  @Input() withRequiredMarker: boolean = false;

  @Input() errors: string[] | string = [];

  @Input() tooltip?: string;

  @Output('change')
  $onChanged = new EventEmitter<FieldmagicDropdownInputValue<O> | FieldmagicDropdownInputValue<O>[]>();

  /// the options resolver
  options$: Observable<O[]>;

  /// the observable that emits the term that typed by the user
  readonly typeahead$ = new Subject<string>();

  /// the observable that emits the searching state
  readonly searching$ = new BehaviorSubject<boolean>(false);

  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  readonly value$ = new BehaviorSubject<FieldmagicDropdownInputValue<O> | FieldmagicDropdownInputValue<O>[]>(null);

  _termSubscription: Subscription;

  _onChangeFn: FieldmagicDropdownInputOnChangeHandler<O>;

  _onTouchFn: FieldmagicDropdownInputOnTouchHandler;

  /// @see OnDestroy::ngOnDestroy
  ngOnDestroy(): void {
    if (this._termSubscription) {
      this._termSubscription.unsubscribe();
    }
  }

  onChanged(value: FieldmagicDropdownInputValue<O> | FieldmagicDropdownInputValue<O>[]) {
    this.$onChanged.emit(value);

    if (blank(this._onChangeFn)) {
      return;
    }

    this._onChangeFn(value);
  }

  onTouched() {
    if (blank(this._onTouchFn)) {
      return;
    }

    this._onTouchFn();
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled$.next(isDisabled);
  }

  writeValue(value: FieldmagicDropdownInputValue<O>): void {
    this.value$.next(value);
  }

  registerOnChange(fn: FieldmagicDropdownInputOnChangeHandler<O>): void {
    this._onChangeFn = fn;
  }

  registerOnTouched(fn: FieldmagicDropdownInputOnTouchHandler): void {
    this._onTouchFn = fn;
  }

  computeDisplay(item: O): string {
    if (isString(item)) {
      return item;
    }

    return (item as CustomizableDropdownOption).text;
  }

  makeGroupNameFactory(): (option: O) => string | undefined {
    if (! this.grouped) {
      return undefined;
    }

    const fn = (option: O): string => {
      if (isString(option)) {
        return 'ungrouped';
      }

      return fallback(data_get(option as object, 'groupName'), {
        fallback: () => 'ungrouped',
      });
    };

    return fn.bind(this);
  }

  private _filterOptions(options: O[]): O[] {
    if (isNil(this.filter)) {
      return options;
    }

    return options.filter((option) => this.filter(option));
  }
}

type CustomizableDropdownOption = {
  /// the displayed information
  text: string;
  /// the group name for this option. This works when input is flagged as grouped
  groupName?: string;
};

export type FieldmagicDropdownOption<T = Record<string, any>> = T extends Record<string, any>
  ? CustomizableDropdownOption & T
  : string;
export type FieldmagicDropdownInputValue<O extends FieldmagicDropdownOption> =
  | O
  | null
  | undefined;
export type FieldmagicDropdownInputOnChangeHandler<O extends FieldmagicDropdownOption> = (
  value: FieldmagicDropdownInputValue<O> | FieldmagicDropdownInputValue<O>[],
) => void;
export type FieldmagicDropdownInputOnTouchHandler = () => void;
export type RemoteSearchFactory<O extends FieldmagicDropdownOption> = (
  term: string,
) => Observable<O[]>;
export type DropdownOption = FieldmagicDropdownOption;
export type FieldmagicDropdownOptionFilter<O extends FieldmagicDropdownOption>  = (option: O) => boolean;
