// angular modules
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

// moment
import * as _moment from 'moment';

// fieldmagic services
import { ListingService } from '../../../../services/listing.service';
import { RecordService } from '../../../../services/record.service';
import { NotificationService } from '../../../../services/notification.service';
import { CustomTranslateService } from '../../../../services/custom-translate.service';

// sub components
import { EditRecurringInvoiceFormComponent } from './form/edit-recurring_invoice-form.component';
import { HistoricalInvoicesComponent } from './historical-invoices/historical-invoices.component';
import { get, values } from 'lodash-es';
import { filter, first } from 'rxjs/operators';
import { filled } from '../../../utils/common';
import { forkJoin } from 'rxjs';

// create moment instance
const moment = (_moment as any).default || _moment;

@Component({
  selector: 'app-recurring-invoices',
  templateUrl: './recurring_invoices.component.html',
  styleUrls: ['./recurring_invoices.component.scss'],
  providers: [ CustomTranslateService, ListingService ],
})
export class RecurringInvoicesComponent implements OnInit {
  /**
   * contains the current customer identifier
   *
   * @param {string}
   */
  @Input() strRecordID: string;
  @Input() strModule: string;

  /**
   * contains the module id mapping filter
   * Note: this needs improvement for more flexibility
   *
   * @var {object}
   */
  arModuleIDMapping: object = {
    customers: 'customer_id',
    sites: 'site_id'
  };

  /**
   * contains the list of recurring invoices
   *
   * @param {Array<any>}
   */
  arRecurringInvoices: Array<any> = [];

  /**
   * tells if the current state should show loading icon
   *
   * @param {boolean}
   */
  bLoading: boolean = false;

  /**
   * contains the list of items from the previous pages
   *
   * @param {object}
   */
  arPreviousPages: object = [];

  /**
   * contais the list of filters and its values
   *
   * @param {object}
   */
  arFilters: object = {};

  /**
   * list of status filter
   */
  public arStatusFilter: Array<{
    id: string, text: string
  }> = [
    { id: 'true', text: 'active' },
    { id: 'false', text: 'inactive' },
  ]
  /**
   * class constructor
   *
   * @param {ListingService}         listingService
   * @param {RecordService}          recordService
   * @param {NotificationService}    notificationService
   * @param {CustomTranslateService} translationService
   * @param {MatDialog}              dialog
   */
  constructor(
    protected listingService: ListingService,
    protected recordService: RecordService,
    protected notificationService: NotificationService,
    protected translationService: CustomTranslateService,
    protected dialog: MatDialog
  ) {}

  /**
   * {@inheritDoc}
   */
  ngOnInit() {
    // fetch the list
    this.fetchList('default');
  }

  /**
   * retrieves the current instance of attached dependency of listing service
   *
   * @returns {ListingService}
   */
  getListingService(): ListingService {
    return this.listingService;
  }

  /**
   * retrieves the current isntance of the attached dependency
   * of record service
   *
   * @returns {RecordService}
   */
  getRecordService(): RecordService {
    return this.recordService;
  }

  /**
   * retrieves the translation service instance
   *
   * @returns {CustomTranslateService}
   */
  getTranslationService(): CustomTranslateService {
    return this.translationService;
  }

  /**
   * retrieves the notification service instance
   *
   * @return {NotificationService}
   */
  getNotificationService(): NotificationService {
    return this.notificationService;
  }

  /**
   * retrieves the angular material dialog instance from attached dependency
   *
   * @returns {MatDialog}
   */
  getDialog(): MatDialog {
    return this.dialog;
  }

  /**
   * retrieves the related module id property value
   *
   * @returns {string}
   */
  getModuleRecordID(): string {
    return this.strRecordID;
  }

  /**
   * retrieves the module name of the component
   *
   * @returns {string}
   */
  getModuleName(): string {
    return this.strModule;
  }

  /**
   * retrieves the list of recurring invoices property value
   *
   * @returns {object}
   */
  getRecurringInvoicesList(): object {
    return this.arRecurringInvoices;
  }

  /**
   * retrieves the current array of filters
   *
   * @returns {object}
   */
  getFilters(): object {
    return this.arFilters || [];
  }

  /**
   * sets the current loading state to true or false
   *
   * @param {boolean} bState
   */
  setLoadingState(bState: boolean = false): void {
    this.bLoading = bState;
  }

  /**
   * sets the list of recurring invoices
   *
   * @param {Array<any>} arRecurringInvoices
   * @param {Array<any>} arRecurringInvoicesOption
   */
  setRecurringInvoiceList(arRecurringInvoices: Array<any>, arRecurringInvoicesOption: Array<any>): void {
    this.arRecurringInvoices = arRecurringInvoices.map((objRecurringInvoice) => {
      return Object.assign(objRecurringInvoice, {
        amount_tax_included: parseFloat(objRecurringInvoice['amount_tax_included']),
        amount_tax_excluded: parseFloat(objRecurringInvoice['amount_tax_excluded']),
        period: this.getDropdownText('period', objRecurringInvoice['period'], arRecurringInvoicesOption)
      });
    });
  }

  /**
   * add a filter name and value to the list of filters
   *
   * @param {string} field
   * @param {any}    value
   */
  addFilter(strField: string, value: any, bRefreshList: boolean = false): void {
    let fieldName = strField;
    // append to object if possible
    this.arFilters[fieldName] = value;

    // if adding filter should also refresh the list
    if (bRefreshList === true) {
      this.fetchList('default');
    }
  }

  /**
   * retrieves the list of data from the listing service
   *
   * @param {string} strCursor
   */
  fetchList(strCursor: string = 'default') {
    // get the pagination object
    let objPagination = this.getListingService().beforeFetching(strCursor);

    // add the customer_id to the filter
    this.addFilter(this.getModuleRecordIDFilter(), this.getModuleRecordID());

    // set loading state to true
    this.setLoadingState(true);

    // call API
   forkJoin({
    list: this.getListingService()
    .fetchDataAdvanceSearch(
      objPagination['objPage'],
      'recurring_invoices',
      this.getFilters(),
      { 'id': 'created_at', 'sort': 'desc' },
    ),
    config: this.getListingService().getConfig('recurring_invoices'),
   })
      .subscribe(({list, config}) => {
        // set our current list
        this.setRecurringInvoiceList(list['data'], values(config['option']));

        // set the current loading state to false and call listing service hook
        this.getListingService().afterFetching(list, strCursor);
        this.setLoadingState(false);
      });
  }

  /**
   * opens up the dialog form
   *
   * @returns {void}
   */
  openDialogForm(strRecurringInvoiceID?: string): void {
    // open the dialog
    this.getDialog().open(EditRecurringInvoiceFormComponent, {
      maxWidth: '100%',
      width: '90%',
      height: 'auto',
      data: {
        isNew: (! strRecurringInvoiceID) ? true : false,
        moduleID: this.getModuleRecordID(),
        moduleName: this.getModuleName(),
        recordID: strRecurringInvoiceID,
      },
      disableClose: true
    }).afterClosed().pipe(
      first(),
      filter((response) => filled(response)),
    ).subscribe((response) => {
      // if current dialog was closed and needs refresh via save status from the
      // dialog callback
      if (get(response, 'message') === 'save') {
        setTimeout(() => {
          this.onRefresh();
        }, 500);
      }
    });
  }

  /**
   * event called by the delete button which deletes the current selected
   * record data
   *
   * @param {string} strRecurringInvoiceID
   */
  deleteRecord(strRecurringInvoiceID: string): void {
    this.getNotificationService()
      .sendConfirmation('confirm_delete').subscribe(confirmation => {
        if (confirmation.answer) {
          // call the api
          this.getRecordService().deleteRecord('recurring_invoices', strRecurringInvoiceID).first().subscribe((objData) => {
            if (!objData) {
              this.getNotificationService().notifyError('record_delete_failed');
            } else {
              let cursor = (this.listingService.getFirstPageValue() == this.listingService.getPreviousPageValue()) ? 'default' : 'reload';
              // reload list
              this.fetchList(cursor);
              this.getNotificationService().notifySuccess('record_delete_success');
            }
          });
        }
      });
  }

  /**
   * formats the current date into a client timezone date
   *
   * @param {string} strRawDate
   */
  formatDate(strRawDate: string): string {
    return moment(
      moment.utc(strRawDate).toDate()
    ).local().format('LL');
  }

  showHistoricalInvoicesDialog(strRecurringInvoiceID: string): void {
    this.getDialog().open(HistoricalInvoicesComponent, {
      width: '60%',
      height: 'auto',
      data: {
        recurringInvoiceID: strRecurringInvoiceID
      }
    });
  }

  /**
   * Get the dropdown text based on options
   *
   * @param {string} strField
   * @param {string} strDropdownId
   * @param {Array<any>} arOption
   *
   * @returns {string}
   */
  getDropdownText(strField: string, strDropdownId: string, arOption: Array<any>): string {
    var strDropdownText = strDropdownId;
    if (arOption[strField] && arOption[strField]['config']) {
      var arMatchOption = arOption[strField]['config'].find(option => option.id === strDropdownId);
      if (arMatchOption) {
        return arMatchOption.text;
      }
    }
    return strDropdownText;
  }

  /**
   * retrieves the current module id filter
   *
   * @param   {string|null} strModuleName
   *
   * @returns {string|null}
   */
  protected getModuleRecordIDFilter(strModuleName?: string): string|null {
    var strModuleName = strModuleName || this.getModuleName();
    return this.arModuleIDMapping[strModuleName] || null;
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arRecurringInvoices = []; // clear list
    this.fetchList('default');
  }

  ngOnChanges(): void {
    this.onRefresh();
  }
}
