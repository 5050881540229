import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatStepper, MatDialog, MatDialogConfig } from '@angular/material';
import { cloneDeep, get, isEmpty } from 'lodash-es';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { pairwise, startWith, switchMap, filter, first, distinctUntilChanged } from 'rxjs/operators';
import { FormService } from '../../../../../services/form.service';
import { RecordService } from '../../../../../services/record.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { NotificationService } from '../../../../../services/notification.service';
import { SharedService, WizardSharedSite } from '../../../services/shared.service';
import { WizardJobData, WizardJobDataFieldText } from '../../../objects/wizard';
import { Select } from '../../../../../objects/select';
import { ChooseInspectionPeriodComponent } from './choose-inspection-period/choose-inspection-period.component';
import { AssetJobsComponent } from '../../../../../shared/components/widget/asset/asset-jobs/asset-jobs.component';
import { EditformComponent } from '../../../../../shared/components/editform/editform.component';
import { ClientStoreService } from '../../../../../services/client-store.service';
import { blank, data_get, fallback, filled, ids_only } from '../../../../../shared/utils/common';
import { LooseObject } from '../../../../../objects/loose-object';
import { SelectedAsset } from '../../../../../shared/components/widget/asset/asset-jobs/asset-jobs.component';
import { AssetData } from '../../../objects/asset-data';
import { spf } from '../../../../../shared/utils/str';

@Component({
  selector: 'job-step',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit, OnDestroy {
  @Input() stepper: MatStepper;
  @Input() bHasNoCustomer: boolean;
  @Input() bIsCustomLocation: boolean;

  /**
   * Contains asset data when creating job from asset.
   *
   * @var {AssetData}
   */
  @Input() objAssetData: AssetData;

  /**
   * ID of the quote, when creating a job from the opportunity view
   *
   * @type {string}
   */
  @Input() opportunityId: string;

  @Output() objJobData = new EventEmitter<WizardJobData>();

  form: FormGroup;
  bSubmitted = false;
  strMode = 'add';
  strModule = 'jobs';
  fields: any = [
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": null,
      "key": "job_template_id",
      "label": "job_template",
      "controlType": "relate",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "job_templates",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false
    },
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "normal",
      "key": "priority",
      "label": "priority",
      "controlType": "dropdown",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "options": [
        {
          "id": "low",
          "text": "low"
        },
        {
          "id": "normal",
          "text": "normal"
        },
        {
          "id": "high",
          "text": "high"
        },
        {
          "id": "urgent",
          "text": "urgent"
        }
      ],
      "tag": false,
      "hideSelected": true,
      "closeOnSelect": false,
      "maxSelectedItems": 100,
      "clearable": false,
      "list": ""
    },
    // Show project template when value is project. Add milestone invoicing to billing type
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "simple_job",
      "key": "type",
      "label": "job_type",
      "controlType": "dropdown",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "options": [
        {
          "id": "simple_job",
          "text": "simple_job"
        },
        {
          "id": "project",
          "text": "project"
        }
      ],
      "tag": false,
      "hideSelected": true,
      "closeOnSelect": false,
      "maxSelectedItems": 100,
      "clearable": false,
      "list": ""
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "due_date",
      "label": "due_date",
      "controlType": "date",
      "space": 6,
      "has_primary": false
    },
    // Show billing type when checked
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": true,
      "key": "billable",
      "label": "billable",
      "controlType": "checkbox",
      "space": 6,
      "has_primary": false
    },
    // Show amount_to_invoice when value = fixed_price_invoices
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": null,
      "key": "invoicing_type",
      "label": "invoicing_type",
      "controlType": "dropdown",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "options": [
        {
          "id": "time_and_materials",
          "text": "time_and_materials"
        },
        {
          "id": "fixed_price_invoices",
          "text": "fixed_price_invoices"
        },
        {
          "id": "flexible_invoicing",
          "text": "flexible_invoicing"
        },
      ],
      "tag": false,
      "hideSelected": true,
      "closeOnSelect": false,
      "maxSelectedItems": 100,
      "clearable": false,
      "list": ""
    },
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "job_summary",
      "label": "job_summary",
      "controlType": "textarea",
      "space": 6,
      "is_hidden": false,
      "max_length": 10000,
      "has_primary": false,
      "rows": 4
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "internal_notes",
      "label": "internal_notes",
      "controlType": "textarea",
      "space": 6,
      "is_hidden": false,
      "max_length": 10000,
      "has_primary": false,
      "rows": 4
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": null,
      "key": "project_template_id",
      "label": "project_template",
      "controlType": "relate",
      "space": 6,
      "is_hidden": true,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "project_templates",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false
    },
    {
      "required": false,
      "readonly": true,
      "is_admin": false,
      "default_value": "0.00",
      "key": "amount_to_invoice",
      "label": "amount_to_invoice",
      "controlType": "currency",
      "space": 6,
      "is_hidden": true,
      "precision": [
        12,
        3
      ],
      "has_primary": false
    },
  ];

  objOtherFields = [
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": null,
      "key": "department_id",
      "label": "department",
      "controlType": "relate",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "departments",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false
    },
  ]

  additionalOptionsFields: LooseObject = {
    assets: {
      required: false,
      readonly: false,
      is_admin: false,
      default_value: [],
      key: 'assets',
      label: 'assets',
      controlType: 'relate',
      space: 12,
      is_hidden: false,
      has_primary: false,
      multiple: true,
      hide_label: false,
      default_text: '',
      module: 'assets',
      options: [],
      add_tag: false,
      closeOnSelect: true,
      note: 'only_display_assets_related_to_site',
      always_init_relate: true,
    }
  }

  fieldRecordText: WizardJobDataFieldText = {
    job_template_text: "",
    project_template_text: "",
    department_text: "",
  };

  arSelectedInspectionPeriods: SelectedInspectionPeriod[] = [];

  readonly assetTypeIds$ = new BehaviorSubject<string[]>([]);

  /**
   * Internal Use: contains list of subscription that is used throughout the component that would be
   * cleaned up when this component is destroyed.
   *
   * @type {Subscription[]}
   */
  protected arSubscriptions: Subscription[] = [];

  /**
   * determine if the requirements are initialized
   */
  bLoaded: boolean = false;

  constructor(
    private notifService: NotificationService,
    private formService: FormService,
    private recordService: RecordService,
    private localStorageService: LocalStorageService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private clientStore: ClientStoreService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    const sub = this.sharedService.customerDataUpdate$.subscribe(_ => {
      const objSiteData: WizardSharedSite|null = this.sharedService.getSite();

      if (filled(objSiteData)) {
        this.additionalOptionsFields['assets']['filter'] = {
          site_id: get(objSiteData, 'site_id', null) || null
        };
      }
    });

    this.arSubscriptions.push(sub);

    this.initializeComponent();
    this.recordService.getRecordConfig('jobs').subscribe( response => {
      let fields = {
        ...filled(response['available_fields']) ? response['available_fields'] : [],
        ...filled(response['used_fields']) ? response['used_fields'] : []
      }

      if (filled(fields['type'])) {
        let fieldIndex = this.fields.findIndex(data => data.key == 'type');
        this.fields[fieldIndex].options = fields['type']['options'];
        this.form.controls.type.setValue(fields['type']['default_value']);
      }

      this.bLoaded = true;
    });

    if (filled(this.objAssetData)) {
      const objDefaultLinkedAsset: Select = new Select(
        get(this.objAssetData, 'asset_id'),
        spf('[%s] %s', {
          args: [
            data_get(this.objAssetData, 'asset_type_text'),
            fallback(data_get(this.objAssetData, 'asset_name'), {
              fallback: () => data_get(this.objAssetData, 'serial_number'),
            })
          ]
        }),
      );

      this.form.patchValue({
        assets: get(this.form.value, 'assets', []).concat([objDefaultLinkedAsset])
      });

      if (filled(data_get(this.objAssetData, 'asset_type_id'))) {
        this.assetTypeIds$.next([data_get(this.objAssetData, 'asset_type_id')]);
      }
    }
  }

  initializeComponent(): void {
    if (this.clientStore.isDepartmentTracking()) {
      this.fields.splice(8, 0, this.objOtherFields[0]);
    }

    this.form = this.formService.toFormGroup(this.fields);

    this.form.addControl('checklists', new FormControl([]));
    this.form.addControl('assets', new FormControl([]));

    const invoicingTypeField = this.fields.findIndex(x => x.key === 'invoicing_type');
    const jobTemplateId = this.fields.findIndex(x => x.key === 'job_template_id');
    const projectTemplateId = this.fields.findIndex(x => x.key === 'project_template_id');
    const departmentId = this.fields.findIndex(x => x.key === 'department_id');

    let typeChangeSub: Subscription = this.form.controls.type.valueChanges.subscribe((change) => {
      let oldOptions = cloneDeep(this.fields[invoicingTypeField].options);
      if (change === 'project') {
        this.fields[projectTemplateId].is_hidden = false;
        oldOptions.push({
          "id": "milestone_invoicing",
          "text": "milestone_invoicing"
        });
      } else {
        this.fields[projectTemplateId].is_hidden = true;
        this.form.controls.project_template_id.setValue(null);
        oldOptions.splice(3, 1);
      }
      this.fields[invoicingTypeField].options = oldOptions;
      this.changeDetectorRef.markForCheck();
    });

    let billableChangeSub: Subscription = this.form.controls.billable.valueChanges.subscribe((show) => {
      this.fields[invoicingTypeField].is_hidden = !show;
      this.fields[invoicingTypeField].required = show;
      if (!show) {
        this.form.controls.invoicing_type.setValue(null);
        this.form.controls.invoicing_type.clearValidators();
      } else {
        this.form.controls.invoicing_type.setValidators([Validators.required]);
      }
      this.form.controls.invoicing_type.updateValueAndValidity();
      this.changeDetectorRef.markForCheck();
    });

    let jobTemplateIdChangeSub: Subscription = this.form.controls.job_template_id.valueChanges.subscribe((change) => {
      if (!change) {
        this.fieldRecordText.job_template_text = '';
        return;
      }
      let selectedJobTemplate = this.fields[jobTemplateId].options.find(x => x.id === change);

      // FC-4349: if we have instance of select use the extras
      if (selectedJobTemplate instanceof Select) {
        selectedJobTemplate = selectedJobTemplate.extras;
      }

      this.fieldRecordText.job_template_text = selectedJobTemplate.text;

      // Remove this values as this is not save on the job template module
      delete selectedJobTemplate.due_date;
      delete selectedJobTemplate.department_id;

      this.form.patchValue(selectedJobTemplate);
      this.changeDetectorRef.markForCheck();
    });

    let projTemplateIdChangeSub: Subscription = this.form.controls.project_template_id.valueChanges.subscribe((change) => {
      if (!change) {
        this.fieldRecordText.project_template_text = '';
        return;
      }
      const selectedRecord = this.fields[projectTemplateId].options.find(x => x.id === change);
      this.fieldRecordText.project_template_text = selectedRecord.text;
    })

    let checklistsChangeSub: Subscription = this.form.controls.checklists.valueChanges.pipe(
      startWith([]),
      pairwise(),
      switchMap(([prev, curr]) => {
        let latestSelected: Select = curr.slice(-1)[0];
        let strLatestSelectedChklistType: string = data_get(latestSelected, 'extras.type');

        if ((curr.length > prev.length) && (strLatestSelectedChklistType === 'asset')) {
          let successDialog = this.dialog.open(ChooseInspectionPeriodComponent, {
            width: '40%',
            disableClose: true,
            data: latestSelected
          });

          return successDialog.afterClosed();
        }

        return of(null);
      }),
      filter(response => !!response)
    ).subscribe(response => {
      if (!response.success) {
        // If inspection period is invalid, remove previously selected asset  checklist
        this.form.patchValue({
          checklists: this.form.get('checklists').value.filter(item => item.id !== response.data.id)
        });
      } else {
        this.arSelectedInspectionPeriods.push(response.data);
      }
    });

    if (departmentId > -1) {
      let departmentIdChangeSub: Subscription = this.form.controls.department_id.valueChanges.subscribe((change) => {
        if (!change) {
          this.fieldRecordText.department_text = '';
          return;
        }
        const selectedRecord = this.fields[departmentId].options.find(x => x.id === change);
        this.fieldRecordText.department_text = selectedRecord.text;
      });

      this.arSubscriptions.push(departmentIdChangeSub);

      // Set optional values to null because the dropdown default_value key is not working
      this.form.controls.department_id.setValue(null);
    }

    this.arSubscriptions.push(typeChangeSub);
    this.arSubscriptions.push(billableChangeSub);
    this.arSubscriptions.push(jobTemplateIdChangeSub);
    this.arSubscriptions.push(projTemplateIdChangeSub);

    this.arSubscriptions.push(checklistsChangeSub);

    this.form.get('assets').valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe((values: Select[]) => {
      this.assetTypeIds$.next(ids_only(values, 'extras.asset_type_id'));
    });
  }

  ngOnDestroy(): void {
    this.arSubscriptions.forEach(sub => sub.unsubscribe());
  }

  goForward() {
    this.form.markAsDirty();
    this.form.markAsTouched();
    this.bSubmitted = true;
    if (this.form.invalid) {
      this.notifService.notifyError('please_complete_the_form');
    } else {
      // On to the next form
      this.stepper.next();

      let arSelectedChecklists: Select[] = this.form.get('checklists').value;

      if (!isEmpty(arSelectedChecklists)) {
        let arSelectedChecklistsIds: string[] = arSelectedChecklists.map(item => item.id);

        this.recordService.getRecordRelate('checklists', '', arSelectedChecklistsIds).subscribe(results => {
          let arUpdatedSelectedChecklists: Select[] = results.map(item => {
            let objInspectionPeriod: SelectedInspectionPeriod = this.arSelectedInspectionPeriods.find(inspectionPeriod => inspectionPeriod.id === item.id);

            if (objInspectionPeriod) {
              item['selected_inspection_periods'] = objInspectionPeriod.periods;
              return { ...item, selected_inspection_periods: objInspectionPeriod.periods };
            }

            return item;
          });

          this.form.patchValue({
            checklists: arUpdatedSelectedChecklists
          });

          this.objJobData.emit({
            ...this.form.value,
            no_customer: this.bHasNoCustomer,
            is_custom_location: this.bIsCustomLocation,
            user_id: this.localStorageService.getItem('user_id'),
            field_text: this.fieldRecordText,
            opportunity_id: this.opportunityId
          });
        });
      } else {
        this.objJobData.emit({
          ...this.form.value,
          no_customer: this.bHasNoCustomer,
          is_custom_location: this.bIsCustomLocation,
          user_id: this.localStorageService.getItem('user_id'),
          field_text: this.fieldRecordText,
          opportunity_id: this.opportunityId
        });
      }
    }
  }

  goBack() {
    this.stepper.previous();
  }

  openSearchAssetsDialog(): void {
    const selectedSite: WizardSharedSite|null = this.sharedService.getSite();

    const dialogConfig: MatDialogConfig = {
      width: '80%',
      disableClose: true,
      data: {
        job_record: {
          id: null,
          customer_id: this.sharedService.customerId,
          customer_text: this.sharedService.customerText,
          site_id: get(selectedSite, 'site_id'),
          site_text: get(selectedSite, 'address_text'),
        },
        from_job_wizard: true
      }
    }

    const sub: Subscription = this.dialog
      .open(AssetJobsComponent, dialogConfig)
      .afterClosed()
      .pipe(
        first()
      ).subscribe(res => {
        const arLinkedAssets: SelectedAsset[] = get(res, 'linked_assets', []);

        if (filled(arLinkedAssets)) {
          const arSelect: Select[] = arLinkedAssets.map((asset: SelectedAsset) => new Select(
            asset.id,
            asset.text,
            null,
            { asset_type_id: asset.asset_type_id }
          ));

          this.form.patchValue({
            assets: get(this.form.value, 'assets', []).concat(arSelect)
          });
        }
      });

    this.arSubscriptions.push(sub);
  }

  createAndLinkAsset(): void {
    const selectedSite: WizardSharedSite|null = this.sharedService.getSite();

    const dialogConfig : MatDialogConfig = {
      width: '80%',
      data: {
        arData: {
          site_id: get(selectedSite, 'site_id'),
          site_text: get(selectedSite, 'address_text'),
          customer_id: this.sharedService.customerId,
          customer_text: this.sharedService.customerText,
          asset_type_id: null,
          is_active: true,
        },
        strModule: 'assets',
        strMode: 'add'
      },
      disableClose: true
    };

    const sub: Subscription = this.dialog
      .open(EditformComponent, dialogConfig)
      .afterClosed()
      .pipe(
        first()
      ).subscribe(res => {
        if (filled(get(res, 'data'))) {
          const objItem: Select = new Select(
            get(res, 'data.id'),
            spf('[%s] %s', {
              args: [
                data_get(res, 'data.serial_number'),
                data_get(res, 'data.asset_type_text'),
              ]
            }),
            '0',
            {
              'asset_type_id': data_get(res, 'data.asset_type_id'),
            },
          );

          this.form.patchValue({
            assets: data_get(
              this.form.value,
              'assets',
              {
                default_value: [],
              })
              .concat([objItem])
          });
        }
      });

    this.arSubscriptions.push(sub);
  }
}

interface SelectedInspectionPeriod {
  id: string,
  periods: string[]
}
