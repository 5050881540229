import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Common } from '../../../../../objects/common';
import { ListingFilter } from '../../objects/listing-filter';
import { ListingService } from '../../../../../services/listing.service';
import { ListingElementsEvent } from '../../objects/listing-elements-event';

@Component({
  selector: 'fm-listing-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent implements OnInit {

  /**
   * Sort options from the parent, usually retrieved via the API.
   *
   * @var {Common[]}
   */
  @Input('arSortOption') arSortOption: Common[] = [];

  /**
   * The model for the filters.
   *
   * @var {ListingFilter}
   */
  @Input('arModel') arModel: ListingFilter;

  /**
   * Inform events outside the component.
   *
   * @var {EventEmitter<ListingElementsEvent>}
   */
  @Output() sortEvent = new EventEmitter<ListingElementsEvent>();

  constructor(private listService: ListingService) { }

  ngOnInit() {}

  /**
   * TODO: Redo function, part of this already exists somewhere else.
   *
   * Generates the filter needed for the sorting.
   *
   * @param {string} strId
   */
  generateFilter(strId: string = null): void {

    let strSort: string = 'asc';

    if (this.arModel['order_by'] !== undefined && this.arModel['order_by']['id'] === strId) {
      strSort = this.arModel['order_by']['sort'] === 'asc' ? 'desc' : 'asc';
    }

    const sortParams = {
      id: strId,
      sort: strSort,
    };

    this.listService.setOrderBy(sortParams);
    this.listService.setFilter('');

    this.arModel['order_by'] = sortParams as any;

    this.sortEvent.next({
      type: 'sort',
      data: this.listService.getFilter()
    });
  }

}
