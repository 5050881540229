
<div id="widgetActivitiesActionButton" class="d-flex justify-content-around activities-option tabs-content">
    <span id="widgetActivitiesEmailButton" (click)="bLoading ? '' : addActivity('email')" class="span-links ml-2 mr-2">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        {{ (bFromJobScheduler ? 'email' : 'new_email') | translate }}
    </span>
    <span id="widgetActivitiesEventButton" (click)="bLoading ? '' : addActivity('event')" class="span-links ml-2 mr-2">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        {{ (bFromJobScheduler ? 'event' : 'schedule_event') | translate }}
    </span>
    <span *ngIf="!bFromJobScheduler" id="widgetActivitiesTaskButton" (click)="bLoading ? '' : addActivity('task')" class="span-links ml-2 mr-2">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        {{ (bFromJobScheduler ? 'task' : 'new_task') | translate }}
    </span>
    <span id="widgetActivitiesNoteButton" (click)="bLoading ? '' : addActivity('note')" class="span-links ml-2 mr-2">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        {{ (bFromJobScheduler ? 'note' : 'new_note') | translate }}
    </span>
    <span id="widgetActivitiesMessageButton" (click)="bLoading ? '' : addActivity('message')" class="span-links ml-2 mr-2">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        {{ (bFromJobScheduler ? 'message' : 'new_message') | translate }}
    </span>
</div>

<div *ngIf="!bFromJobScheduler" class="activities-body">
    <div class="container">
        <div class="col-lg-12 pt-4 pb-4">
            <div class="row">
                <div class="col-lg-12 no-padding">
                    <div class="form-inline">
                        <div class="form-group flex-nowrap w-100">
                            <div class="col-lg-4">
                                <label class="jc-l">{{ 'filter_timeline' | translate }}</label>
                            </div>
                            <div class="col-lg-8">
                                    <ng-select
                                    [items]="arTimelineFilter"
                                    [clearable]="false"
                                    class="value-text"
                                    (change)="filterActivity()"
                                    [(ngModel)]="selectDate"
                                    id="widgetActivitiesTimelineFilter">
                                    <ng-template ng-label-tmp let-item="item">{{ item | translate }}</ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                      <span id="widgetActivitiesTimelineFilter{{ item }}">{{ item | translate }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="form-inline mt-12">
                        <div class="form-group flex-nowrap w-100">
                            <div class="col-lg-4">
                                <label class="jc-l">{{ 'filter_by_activities' | translate }}</label>
                            </div>
                            <div class="col-lg-8">
                                <ng-select
                                    [items]="arActivityTypeFilter"
                                    [clearable]="false"
                                    class="value-text"
                                    (change)="filterActivity()"
                                    [(ngModel)]="selectType"
                                    id="widgetActivitiesActivityFilter">
                                    <ng-template ng-label-tmp let-item="item">{{ item | translate }}</ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                      <span id="widgetActivitiesActivityFilter{{ item }}">{{ item | translate }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row border-top pt-2 pb-2">
            <div class="col-sm-6 pt-1">
                <span class="module-title">
                    <fa-icon [icon]="['fa', 'calendar-alt']" aria-hidden="true"></fa-icon>
                    &nbsp;
                    {{ 'recent_activity' | translate }}
                </span>
            </div>
            <div class="col-sm-6 pt-1">
                <fieldmagic-pagination-links
                  [topOnly]="true"
                ></fieldmagic-pagination-links>
            </div>
        </div>

        <div *ngIf="activities$ | async | blank">
            <div class="row border-top" *ngIf="!bLoading">
                <span class="no-content">
                    {{ 'no_activities' | translate }}
                </span>
            </div>
            <div class="row border-top" *ngIf="bLoading">
                <span class="no-content">
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                    {{ 'loading' | translate }}
                </span>
            </div>
        </div>

        <div class="row border-top" *ngFor="let activity of (activities$ | async); let i = index"
            [popover]="isCancelledTask(activity) || isFailedSms(activity) ? false : popTemplate"
            [popoverContext]="context"
            triggers="mouseenter:mouseleave"
            placement="left"
            [attr.id]="'widgetActivitiesrecord_' + activity.id">

            <ng-template #popTemplate let-message="message" >
                <div>
                    <span class="detail-label">
                        {{ 'name' | translate  }}
                    </span>

                    <!-- handle seperate event translation -->
                    <span *ngIf="activity.activity_type === 'event'" class="detail-value">
                        {{ 'events.' + activity.activity_name | translate }}
                    </span>

                    <!-- common translation for activity name -->
                    <span *ngIf="activity.activity_type !== 'event'" class="detail-value">
                      {{ activity.activity_name }}
                    </span>
                </div>
                <div *ngIf="activity.activity_type != 'email'" class="detail-label">
                        {{ 'activity_detail' | translate }}
                    <span class="detail-value">
                        {{ activity.notes }}
                    </span>
                </div>

                <div *ngIf="activity.activity_type === 'task' && activity.user_text !== ''" class="detail-label">
                    {{ 'assigned_user' | translate }}
                    <span class="detail-value">
                        {{ activity.user_text }}
                    </span>
                </div>

                <div *ngIf="activity.activity_type === 'task' && activity.team_text !== ''" class="detail-label">
                    {{ 'assigned_team' | translate }}
                    <span class="detail-value">
                        {{ activity.team_text }}
                    </span>
                </div>

                <div *ngIf="activity.activity_type == 'email' && !activity.is_draft">
                    <span class="detail-label">
                        {{ 'from' | translate  }}
                    </span>
                    <span class="detail-value">
                        {{ activity.from }}
                    </span>
                </div>
                <div *ngIf="activity.activity_type === 'message' && (activity.user_text | filled)">
                    <span class="detail-label">
                          {{ 'from' | translate}}
                    </span>

                    <span class="detail-value">
                        {{ activity.user_text }}
                    </span>
                </div>
                <div>
                    <span class="detail-label">
                        {{ 'created_at' | translate  }}
                    </span>
                    <span class="detail-value">
                        {{ activity.created_at | date:'MM/d/yyyy' }}
                    </span>
                </div>
            </ng-template>

            <div class="col-sm-1">
                <span class="task-icon {{ activityColor(activity) }}">
                    <fa-icon [icon]="['fas', 'circle']"></fa-icon>
                </span>
                <div class="ml-checkbox">
                    <mat-checkbox *ngIf="activity.activity_type === 'task' && activity.task_progress !== 'cancelled'"
                        id="ActivityMarkAsComplete{{ i }}"
                        class="m-0"
                        [(ngModel)]="activity.is_completed"
                        [ngModelOptions]="{standalone: true}"
                        color="primary"
                        (click)="$event.stopPropagation()"
                        (change)="toggleFinished(activity)">
                    </mat-checkbox>
                    <fa-icon *ngIf="isCancelledTask(activity)"
                        matTooltip="{{ 'remove_from_cancelled' | translate }}" matTooltipPosition="above"
                        [icon]="['fas', 'undo-alt']"
                        class="icon-undo-retry"
                        (click)="toggleFinished(activity)">
                    </fa-icon>
                    <fa-icon *ngIf="isFailedSms(activity)"
                        matTooltip="{{ 'sending_failed' | translate }}" matTooltipPosition="above" [icon]="['fas', 'exclamation-circle']"
                        class="icon-undo-retry" (click)="retrySms(activity)">
                    </fa-icon>
                </div>
            </div>

            <div class="col-sm-6" (click)="updateActivity(activity, activity.id)">

                <div *ngIf="activity.activity_type == 'task'" [ngClass]="isCancelledTask(activity) ? 'text-cancelled-task' : 'text-activity-type'">
                    <span id="ActivityTaskLabel" class="mr-2 cut-text">
                        <del *ngIf="activity.is_completed">{{ activity.activity_name | slice:0:100 }}</del>
                        <span *ngIf="!activity.is_completed">{{ activity.activity_name | slice:0:100 }}</span>
                        <span *ngIf="activity.activity_name.length > 100">...</span>
                    </span>
                </div>

                <div *ngIf="activity.activity_type == 'event'" class="text-activity-type">
                    <span id="ActivityEventLabel_{{ activity.activity_name }}" class="cut-text">{{ 'events.' + activity.activity_name | translate }}</span>
                </div>

                <div *ngIf="
                    activity.activity_type != 'task' &&
                    activity.activity_type != 'event' &&
                    activity.activity_type != 'message' &&
                    activity.activity_type != 'note'" class="text-activity-type">
                    <span id="ActivityEmailLabel" class="cut-text">
                        {{ activity.activity_name | slice:0:100 }}
                    </span>
                    <span *ngIf="activity.activity_name.length > 100">...</span>
                </div>

                <span *ngIf="activity.activity_type == 'task'" class="text-activity-content">
                    <span><strong>{{ activity.task_progress | translate }}</strong></span>
                </span>
                <span *ngIf="activity.activity_type == 'task'" class="text-activity-content">
                    <span *ngIf="activity.user_text !== ''">{{ activity.user_text | translate }}</span>
                    <span *ngIf="activity.user_text !== '' && activity.team_text !== ''">&nbsp;|&nbsp;</span>
                    <span *ngIf="activity.team_text !== ''">{{ activity.team_text | translate }}</span>
                    <span *ngIf="activity.user_text === '' || activity.team_text === ''">{{ not_yet_assigned | translate }}</span>
                    <small *ngIf="activity.assigned_user_client_name" class="ml-1 font-weight-bold" style="font-size: 95%;">
                        ({{ activity.assigned_user_client_name }})
                    </small>
                </span>

                <span class="text-activity-content d-flex d-flex-gap" *ngIf="activity.activity_name != ''">
                    <span *ngIf="activity.activity_type == 'email'">{{ cutHtml(activity.notes) | slice:0:75}}</span>
                    <span *ngIf="activity.activity_type != 'email'">
                        <ng-container *ngIf="activity.activity_type == 'message'; else defaultContent">
                            <span *ngIf="activity.latest_message_type == 'text'; else defaultPreview" id="MessageLabel" class="pointer">
                                {{ activity.latest_message | slice:0:75 }}
                            </span>
                            <ng-template #defaultPreview>
                                <span class="pointer">{{ activity.message_file_title | slice:0:75}}</span>
                            </ng-template>
                        </ng-container>
                        <ng-template #defaultContent>
                            <ng-container *ngIf="activity.activity_type == 'note'; else nonNoteContent">
                                <span id="ActivityNoteLabel" class="pointer">{{ activity.notes | slice:0:75}}</span>
                            </ng-container>
                            <ng-template #nonNoteContent>
                                <ng-container *ngIf="activity.activity_type !== 'task'">
                                {{ activity.notes | slice:0:75}}
                                </ng-container>
                            </ng-template>
                        </ng-template>
                    </span>
                    <span *ngIf="activity.notes.length > 75 && activity.activity_type !== 'task'">...</span>
                </span>

                <span class="text-activity-content float-right" *ngIf="activity.activity_name == ''">

                    {{ 'no_subject' | translate }}
                </span>

            </div>

            <div class="col-sm-2">
                <div class="row">
                    <div class="col-12">
                        <span>
                            <fa-icon [icon]="['fas', arActivityIcon[activity.activity_type]]"></fa-icon>
                        </span>
                        &nbsp;
                        <span>{{ activity.activity_type | translate }}</span>
                        <span *ngIf="activity.activity_type == 'email' && activity.is_draft"> - Draft</span>

                        <!-- counter -->
                        <ng-container *ngIf="activity.activity_type == 'email' && ! activity.is_draft" >
                            &nbsp;<span class="badge badge-pill badge-primary">{{ (activity.activity_count >= 1000) ? 999 : activity.activity_count }}</span>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-3">
                <div class="row p-0">
                    <div class="col-12">
                        <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>&nbsp;
                        <span matTooltip="{{ 'activity_date' | translate }} {{ formatDate(activity.activity_date, activity.activity_type) }}">{{ formatDate(activity.activity_date, activity.activity_type) }}</span>
                    </div>
                    <div *ngIf="activity.activity_type == 'task' && activity.job_id" class="col-12 pt-1 pr-3">
                        <button *ngIf="activity.task_progress != 'completed' && activity.is_completed === false" type="button" class="btn btn-primary" (click)="openSchedulerList(activity)">
                            <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                            <span class="pl-1">{{ 'schedule' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
