export class QuoteLineGroup {

  /**
   * Name of the group.
   */
  label: string;

  /**
   * Auto generated line id
   */
  select_line_id: string;

  /**
   * Just a boolean to check if this indeed a group.
   */
  is_group: boolean;

  constructor(properties: {
    select_line_id?: string
    label?: string
    is_group?: boolean
  } = {}){
    this.select_line_id = properties.select_line_id || '';
    this.label = properties.label || '';
    this.is_group = properties.is_group || true;
  }

}