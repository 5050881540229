
import { RelateIds } from "../lists/relate-ids";

/**
 * This is still incomplete, feel free add
 * the missings field when needed.
 */
export class Activity {

    id: string
    external_id: string
    user_id: string
    activity_name: string
    activity_date: string
    activity_type: string
    is_draft: boolean
    is_completed: boolean
    lead_id: string
    customer_id: string
    site_id: string
    contact_id: string
    opportunity_id: string
    quote_id: string
    job_id: string
    asset_id: string
    customer_invoice_id: string
    purchase_order_id: string
    supplier_invoice_id: string
    department_id: string
    task_progress: string
    is_sticky_note: boolean;

    constructor(properties: ActivityInterface) {
        this.id = properties.id
        this.external_id = properties.external_id
        this.user_id = properties.user_id
        this.activity_name = properties.activity_name
        this.activity_date = properties.activity_date
        this.activity_type = properties.activity_type
        this.is_draft = properties.is_draft
        this.is_completed = properties.is_completed || false
        this.lead_id = properties.lead_id
        this.customer_id = properties.customer_id
        this.site_id = properties.site_id
        this.contact_id = properties.contact_id
        this.opportunity_id = properties.opportunity_id
        this.quote_id = properties.quote_id
        this.job_id = properties.job_id
        this.asset_id = properties.asset_id
        this.customer_invoice_id = properties.customer_invoice_id
        this.purchase_order_id = properties.purchase_order_id
        this.supplier_invoice_id = properties.supplier_invoice_id
        this.department_id = properties.department_id
        this.task_progress = properties.task_progress
        this.is_sticky_note = properties.is_sticky_note || false;
    }
}

export class ActivityRequest implements ActivityRequestInterface {

    id: string
    module: string
    record_id: string
    module_id: string

    constructor(properties: ActivityRequestInterface) {
        this.id = properties.id;
        this.module = properties.module || null;
        this.record_id = properties.record_id || null;
        this.module_id = properties.module_id || null;
    }

    /**
     * If the module, record_id, and module_id can't be
     * provided, look in the objActivity.
     *
     * @param objActivity
     */
    setPropertiesFromActivity(objActivity: Activity): ActivityRequest {
        if (objActivity) {
            for (var [strModule, strModuleId] of Object.entries(RelateIds)) {
                if (objActivity[strModuleId]) {
                  // Update request object
                  this.module = strModule;
                  this.record_id = objActivity[strModuleId];
                  this.module_id = strModuleId;
                  break;
                }
            }
        }

        return this;
    }

    /**
     * Creates a request combining this object
     * and some optional properties of Activity.
     *
     * @param properties
     */
    buildRequest(properties: ActivityInterface): string {
        return JSON.stringify({...this, ...properties});
    }

}

export interface ActivityInterface {

    id?: string
    external_id?: string
    user_id?: string
    activity_name?: string
    activity_date?: string
    activity_type?: string
    is_draft?: boolean
    is_completed?:boolean
    lead_id?: string
    customer_id?: string
    site_id?: string
    contact_id?: string
    opportunity_id?: string
    quote_id?: string
    job_id?: string
    asset_id?: string
    customer_invoice_id?: string
    purchase_order_id?: string
    supplier_invoice_id?: string
    department_id?: string
    task_progress?: string
    is_sticky_note?: boolean;

}

export interface ActivityRequestInterface {

    id: string;
    module?: string;
    record_id?: string;
    module_id?: string;

}

export type AuditedActivity = ActivityInterface & {
    created_at?: string;
    updated_at?: string;
}

export type NoteActivity = AuditedActivity & {
    notes: string;
}