import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { ErrorEvent, ErrorEventType, ErrorPublishingService } from '../../../services/error-publishing.service';

type TextAlignmentProp = 'center' | 'left' | 'right';
type DisplayAs = 'default' | 'widget';

@Component({
  selector: 'app-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: [
    './error-handler.component.scss',
  ],
})
export class ErrorHandlerComponent implements OnInit, OnDestroy {

  /**
   * The error that has been emitted or occurred
   *
   * @type {ErrorEvent}
   */
  error: ErrorEvent;

  /**
   * @type {boolean}
   */
  get hasError(): boolean {
    return !! this.error;
  }

  /**
   * An error identity that which this component will listens to
   *
   * @type {string}
   */
  @Input() onlyFor: string = '*';

  /**
   * A flag use to identify when the error is coming from the action state
   *
   * @type {boolean}
   */
  @Input() isAction: boolean = false;

  /**
   * Text display alignment
   *
   * @type {TextAlignmentProp}
   */
  @Input() alignment: TextAlignmentProp = 'left';

  /**
   * Determine how text error will be display
   *
   * @type {DisplayAs}
   */
  @Input() displayAs: DisplayAs = 'default';

  @Input() message?: string;

  /**
   * Emitted event when error occured
   *
   * @type {EventEmitter<ErrorEvent>}
   */
  @Output() onError = new EventEmitter<ErrorEvent>();

  /**
   * @param {ErrorPublishingService} errors
   */
  constructor(
    protected readonly publisher: ErrorPublishingService,
  ) {}

  /**
   * @type {Subscription[]}
   */
  protected subscriptions: Subscription[] = [];

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.publisher.onError().pipe(
        take(1),
        filter((objError) => this.onlyFor === '*' || objError.for === this.onlyFor),
        tap((objError) => this.onError.emit(objError))
      )
      .subscribe((objError) => this.error = objError)
    );
  }

  /**
   * @inheritdoc
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((objSubscription) => objSubscription.unsubscribe());
  }

  /**
   * Checks if the current error is a forbiden response from a http request
   *
   * @returns {boolean}
   */
  get isForbiddenHttpError(): boolean {
    return this.error.type === ErrorEventType.HTTP_ERROR && this.error.status === 403;
  }
}