<section class="list-view">
    <div class="row">
        <div class="col-sm-12 mb-3">
            <app-information strModule="invite_users"
                [isPanel1Visible]= "true"
                [isPanel2Visible]= "true"
                [isPanel3Visible]= "true" >
            </app-information>
        </div>
        <div class="col-sm-12">

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sx-9">
                    <h4 class="font-weight-light title-header">{{ 'invite_users' | translate}}</h4>
                </div>

                <div class="col-lg-6 col-md-6 col-sx-6 title-button" align="right">
                    <a [routerLink]="['/admin/']" class="btn-cancel">{{ 'cancel' | translate}}</a>
                    <button *ngIf="arInvitations" (click)="showResendInvitationDialog()" class="btn btn-primary invite-btn">
                        <fa-icon [icon]="['fas','envelope-open-text']" class="pr-2"></fa-icon>{{ 'see_pending_user' | translate}}
                    </button>
                    <button (click)="sendUserInvitations()" class="btn btn-primary invite-btn" [disabled]="bIsInvitationSent">
                        <span *ngIf="! bIsInvitationSent">
                            <fa-icon [icon]="['fas','envelope']" class="pr-2"></fa-icon>
                            {{ 'invite' | translate}}
                        </span>
                        <inline-view-loader [isLoading]="bIsInvitationSent" [hasText]="true"></inline-view-loader>
                    </button>
                </div>
            </div>
            <hr />
            <div class="body-container">
                <ng-container *ngFor="let objData of objUserInvitationData | keyvalue">
                    <div class="row">
                        <div class="col">
                            <div class="position-bottom">
                                <label>{{ objData['value']['label'] | translate }}</label>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label class="locale-label">{{ 'locale' | translate }}</label>
                                <ng-select [(ngModel)]="objData['value']['locale']" [clearable]="false">
                                    <ng-option *ngFor="let locale of arAvailableLocale" [value]="locale">{{ locale | translate }}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <ng-select
                        class="email-input"
                        (paste)="onPaste($event, objData['key'])"
                        [items]="objData['value']['email_addresses']"
                        [addTag]="addTag"
                        [multiple]= true
                        [clearable]="true"
                        [isOpen]="false"
                        (change)="addEmailChip($event, objData['key'])"
                        [(ngModel)]="objData['value']['email_addresses']"
                        (blur)="onBlur($event, objData['key'])">
                    </ng-select>

                </ng-container>
            </div>

        </div>
    </div>
</section>