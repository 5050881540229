import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { RecordService } from '../../../../../services/record.service';
import { Subject, Observable, concat, of } from 'rxjs';
import { Select } from '../../../../../objects/select';
import { debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { ListingService } from '../../../../../services/listing.service';
import { NotificationService } from '../../../../../services/notification.service';
import { AssetService } from '../../../../../services/asset.service';
import { StatusCode } from '../../../../../lists/status-code';
import { isId, filled } from '../../../../utils/common';
import { get } from 'lodash-es';
import { LooseObject } from '../../../../../objects/loose-object';

@Component({
  selector: 'app-asset-jobs',
  templateUrl: './asset-jobs.component.html',
  styleUrls: ['./asset-jobs.component.scss'],
  providers: [ListingService, AssetService]
})
export class AssetJobsComponent implements OnInit {

  public bShowLoader: boolean = false;
  public bSubmitted: boolean = false;
  public objData: any;
  public bLoading: boolean = false;
  public arPagination = {
    current_value: '',
    current_page: '',
    next_page: '',
    prev_page: '',
    initial_value: ''
  };
  public arPaginationPrevious: any = {};
  public arSearchFilters = [];

  public strSite;
  public strSerialNumber;
  public strAssetGroups;
  public strAssetType;

  public arAssets = [];
  public arLinkedAssetIds: any = [];

  public isResultEmpty = true;
  public arSelectedItems: SelectedAsset[] = [];


  public objSelections = {
    'sites' : {
      typehead: new Subject<string>(),
      loader: false,
      placeholder: 'site',
      name: 'name',
      module: 'sites',
      value: 'strSite',
      readonly: false
    },
    'asset_types' : {
      typehead: new Subject<string>(),
      loader: false,
      placeholder: 'asset_types',
      name: 'name',
      module: 'asset_types',
      value: 'strAssetType',
      readonly: false
    },
    'asset_groups' : {
      typehead: new Subject<string>(),
      loader: false,
      placeholder: 'asset_groups',
      name: 'name',
      module: 'asset_groups',
      value: 'strAssetGroups',
      readonly: false
    }
  }

  constructor(
    private dialogRef: MatDialogRef<AssetJobsComponent>,
    public recordService : RecordService,
    public listingService : ListingService,
    private notifService: NotificationService,
    public assetService : AssetService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

      this.objData = data;

      if (isId(get(this.objData, 'job_record.id'))) {
        this.assetService.getAllLinkedAssets(this.objData['job_record']['id'], 'jobs').subscribe( result => {
          if (result) {
            this.arLinkedAssetIds = result;
          }
        });
      }
    }

  ngOnInit() {

    // et related record for sites and tet the site to be the same as the site in job record
    this.strSite = get(this.objData, 'job_record.site_id');
    this.getRelatedRecordOptions('sites');

    // Get related record for asset types
    this.getRelatedRecordOptions('asset_types');

    // Get related record for asset groups
    this.getRelatedRecordOptions('asset_groups');

  }

  // Get options for related modules
  getRelatedRecordOptions(strModule) {
      this.objSelections[strModule]['obv'] = new Observable<Select[]>();

      let arDefaultRecord = [];
      let bHasAdditionalFilter: boolean = strModule !== 'asset_types';

      // If getting the sites related record, set the current job's site as default value
      if (strModule === 'sites' && isId(get(this.objData, 'job_record.site_id'))) {
        arDefaultRecord = [{"name": this.objData['job_record']['site_text'], "id": this.objData['job_record']['site_id']}];
      }

      // Place an initial value to the asynchronus ng-select.
      this.objSelections[strModule]['obv'] = concat(
      // Make sure the initial value is an array.
      of(arDefaultRecord),
      // Set the input observable to trigger this API call.
      this.objSelections[strModule]['typehead'].pipe(
          // Trigger only ever 400 milisecond.
          debounceTime(400),
          // Trigger only when the value is different from the previous.
          distinctUntilChanged(),
          // Show the loader.
          tap(() => {
              this.objSelections[strModule]['loader'] = true;
          }),
          // Get the response from the API.
          switchMap(strTerm => this.recordService.getRecordRelate(strModule, strTerm, '', false, false, 10, bHasAdditionalFilter).pipe(
              // Hide the loader once done.
              tap(() => {
              this.objSelections[strModule]['loader'] = false;
              })
          ))
      ));
  }

  /**
   * Close the dialog and return the response on asset component
   *
   * @return void
   */
  searchAsset(): void {
    this.getItem();
  }

  // Gets all asset records that matche the search criteria and links them to job
  linkAllRelatedAsset() {

    let objFilter = this.getFilters();

    if (get(this.data, 'from_job_wizard', false) === true) {
      if (isId(objFilter['site_id']) && isId(objFilter['asset_type_id'])) {
        this.listingService.fetchData(null, 'assets', JSON.stringify(objFilter)).subscribe(result => {
          const arData: LooseObject[] = get(result, 'data', []) || [];
          const arMatchingAssets: SelectedAsset[] = arData.map(item => {
            return {
              id: item.id,
              text: this._formatSelectedAssetText(
                get(item, 'asset_type_text', ''),
                get(item, 'serial_number', '')
              ),
              asset_type_id: get(item, 'asset_type_id', '')
            }
          });

          if (filled(arMatchingAssets)) {
            this.notifService.notifySuccess('assets_will_be_linked_to_job');
            this.dialogRef.close({ linked_assets: arMatchingAssets });
          } else {
            this.notifService.notifyWarning('no_matching_asset_found');
          }
        });
      } else {
        this.notifService.sendNotification('link_asset_unsucessful_header', 'site_and_asset_type_required', 'warning');
      }

      return;
    }

    if (isId(objFilter['site_id']) && isId(objFilter['asset_type_id']) && isId(get(this.objData, 'job_record.id'))) {
      // Link ALL assets to the job that matches the filter
      this.assetService.linkAllRelatedAssets(objFilter, this.objData['job_record']['id']).subscribe( result => {

        if (result.status === StatusCode.kResponseSuccess) {
          this.notifService.sendNotification('header_notification.generic_success', 'link_successful_header', 'success');
          this.dialogRef.close({ status: 'save' });
        } else if (result.status === StatusCode.kResponseAccepted && result.body['error'] !== undefined) {
          this.notifService.promptError(result.body['error']);
        } else {
          this.notifService.notifyWarning('something_went_wrong')
        }
      });
    } else {
      this.notifService.sendNotification('link_asset_unsucessful_header', 'site_and_asset_type_required', 'warning');
    }
  }

   /**
   * Set page
   *
   * @param page
   *
   * @return void
   */
  getItem(strPage = null) {

    this.bLoading = true;
    let objPage = {};
    switch (strPage) {
      case 'next':
        objPage['direction'] = 'next';
        objPage['page'] = btoa(this.arPagination.next_page);
      break;
      case 'prev':
        objPage['direction'] = 'prev';
        objPage['page'] = btoa(this.arPagination.prev_page);
      break;
    }

    let objFilter = this.getFilters();

    // Search asset
    this.listingService.fetchData(JSON.stringify(objPage), 'assets', JSON.stringify(objFilter)).subscribe( result => {

      // Do we have data?
      if (result['data'].length != 0) {

        this.isResultEmpty = false;

        // Store data
        this.arAssets = result['data'];

        // Refresh selected items
        this.arSelectedItems = [];
        for (let intCounter = 0; intCounter < this.arAssets.length; intCounter ++) {

          // Check if asset is not yet linked in the job
          if (! this.isAssetLinked(this.arAssets[intCounter]['id'])) {
              this.arAssets[intCounter]['checked'] = false;
              this.arAssets[intCounter]['is_linked'] = false;
          } else {
            this.arAssets[intCounter]['is_linked'] = true;
          }
        }

        // check if order by id or sort is empty
        if (!result['order_by']['id'] || !result['order_by']['sort']) {

          // Set default order by
          result['order_by']['id'] = 'updated_at';
          result['order_by']['sort'] = 'desc';
        }

        let strOrderBy = result['order_by']['id'];
        switch (strPage) {

          case 'next':
            if (this.arPagination.current_page != this.arAssets[0][strOrderBy]) {
              this.arPagination.prev_page = this.arPagination.current_page;
              this.arPagination.current_page = this.arAssets[0][strOrderBy];
            }
          break;

          case 'prev':
            this.arPagination.current_page = this.arAssets[0][strOrderBy];
            this.arPagination.prev_page = this.arPaginationPrevious[this.arAssets[0][strOrderBy]];
          break;

          default:
            this.arPagination.initial_value = this.arAssets[0][strOrderBy]
            this.arPagination.current_page = this.arAssets[0][strOrderBy];
            this.arPagination.prev_page = this.arAssets[0][strOrderBy];
            this.arPaginationPrevious = {};
          break;
        }

        this.arPaginationPrevious[this.arPagination.current_page.toString()] = this.arPagination.prev_page;
        this.arPagination.next_page = (result['hasNextToken']) ? this.arAssets[result['data'].length -1][strOrderBy] : '';
      } else {
        this.arAssets = [];
        this.isResultEmpty = true;
        this.arPagination = {
          current_value: '',
          current_page: '',
          next_page: '',
          prev_page: '',
          initial_value: ''
        };
        this.arPaginationPrevious = {};
      }
      this.bLoading = false;
    });
  }

  // To close the current dialog
  closeDialog(): void {
    this.dialogRef.close({ status: 'cancel' });
  }

  // Check if asset is already linked
  isAssetLinked(strAssetId) {
    return this.arLinkedAssetIds.includes(strAssetId);
  }

  // When select all checkbox is checked, have all items in current page be selected
  selectAllItem(event) {

    let bChecked = (event.target.checked) ?  true : false;

    for (let intCounter = 0; intCounter < this.arAssets.length; intCounter ++) {
      // Check if asset is not yet linked in the job
      if (! this.isAssetLinked(this.arAssets[intCounter]['id'])) {
        if (bChecked) {
          this.arSelectedItems.push({
            id: this.arAssets[intCounter]['id'],
            text: this._formatSelectedAssetText(
              get(this.arAssets[intCounter], 'asset_type_text', ''),
              get(this.arAssets[intCounter], 'serial_number', '')
            ),
            asset_type_id: get(this.arAssets[intCounter], 'asset_type_id', '')
          })
          this.arAssets[intCounter]['checked'] = true;
        } else {
          this.arAssets[intCounter]['checked'] = false;
        }
      }
    }

    // If unchecked, reset the selected items array
    if (! bChecked) {
      this.arSelectedItems = [];
    }

  }

  // When a checkbox for an asset is un/selected, add or remove it to an array of selected items
  selectItem(event: any, strAssetId: string, assetIndex: number, strAssetTypeText: string, strSerialNumber: string, strAssetTypeId: string) {
    let bChecked = (event.target.checked) ?  true : false;
    const numExistingItemIdx: number = this.arSelectedItems.findIndex(item => item.id === strAssetId);

    if (bChecked) {
      // Check if id is not yet in selected items
      if (numExistingItemIdx === -1) {
        this.arSelectedItems.push({
          id: strAssetId,
          text: this._formatSelectedAssetText(strAssetTypeText, strSerialNumber),
          asset_type_id: strAssetTypeId
        });
        this.arAssets[assetIndex]['checked'] = true;
      }

    } else {
      // Set checked flag to false and remove the item from the selected items array
      this.arAssets[assetIndex]['checked'] = false;

      if (numExistingItemIdx > -1) {
        this.arSelectedItems.splice(numExistingItemIdx, 1);
      }
    }

  }

  // Link selected assets to job and save to asset jobs table
  linkSelectedAssets() {
    if (get(this.data, 'from_job_wizard', false) === true) {
      if (this.arSelectedItems.length > 0) {
        this.notifService.notifySuccess('assets_will_be_linked_to_job');
        this.dialogRef.close({ linked_assets: this.arSelectedItems });
      } else {
        this.notifService.sendNotification('link_asset_unsucessful_header', 'link_asset_unsucessful', 'warning');
      }

      return;
    }

    if (this.arSelectedItems.length > 0 && this.objData['job_record']['id'] != undefined) {
      const arSelectedItemIds: string[] = this.arSelectedItems.map(item => item.id);

      this.assetService.linkAssets(arSelectedItemIds, this.objData['job_record']['id']).subscribe( result => {
        if (result) {
          this.notifService.sendNotification('header_notification.generic_success', 'link_successful_header', 'success');
          this.dialogRef.close({ status: 'save' });
        }
      });

    } else {
      this.notifService.sendNotification('link_asset_unsucessful_header', 'link_asset_unsucessful', 'warning');
    }
  }

  // Gets and removes filters for asset searching
  // Asset to be linked should always be active
  getFilters() {
    let objFilter = {
      'is_active' : true
    };

    if (this.strSite !== undefined && this.strSite != null) {
      objFilter['site_id'] = this.strSite;
    } else {
      delete objFilter['site_id'];
    }

    if (this.strSerialNumber !== undefined && this.strSerialNumber != null) {
      objFilter['serial_number'] = this.strSerialNumber;
    } else {
      delete objFilter['serial_number'];
    }

    if (this.strAssetType !== undefined && this.strAssetType != null) {
      objFilter['asset_type_id'] = this.strAssetType;
    } else {
      delete objFilter['asset_type_id'];
    }

    if (this.strAssetGroups !== undefined && this.strAssetGroups != null) {
      objFilter['asset_group_id'] = this.strAssetGroups;
    } else {
      delete objFilter['asset_group_id'];
    }

    return objFilter;
  }

  // Return array of Asset Ids that are linked to the current record
  getAllLinkedAssets() {
    this.assetService.getAllLinkedAssets(this.objData['job_record']['id'], 'jobs').subscribe( result => {
      if (result) {
        this.arLinkedAssetIds = result;
      }
    });
  }

  /**
   * When the ng-select dropdown is clicked/opened, we fake a user input
   * a user input with no characters.
   * @param typehead - the ng-select typehead observable.
   */
  public triggerSubject(typehead: Subject<string>) {
    // We trigger the typehead to execute a search.
    typehead.next("");
  }

  private _formatSelectedAssetText(strAssetTypeText: string, strSerialNumber: string): string {
    return `[${strAssetTypeText}] ${strSerialNumber}`;
  }
}

export interface SelectedAsset {
  id: string;
  text: string;
  asset_type_id: string;
}