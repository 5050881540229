import { BehaviorSubject } from 'rxjs';
import { Component, Input, NgModule, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BulkUpdateDialogComponent } from './bulk-update-dialog/bulk-update-dialog.component';

@Component({
  selector: 'fieldmagic-update-button',
  template: `
    <fieldmagic-icon
      id="btnMultipleUpdate"
      icon="pencil"
      (click)="openUpdateDialog()"
    >
      <span>{{ 'bulk_update' | translate }}</span>
    </fieldmagic-icon>
  `,
})

export class BulkUpdateButtonComponent {
  @Output() bulkUpdateEvent = new EventEmitter();
  @Input() moduleName: string;
  @Input() selectedRecords: [];
  @Input() isTotalRecordSelected;
  @Input() filterModel;
  @Input() totalRecords;

  readonly isInProgress$ = new BehaviorSubject<boolean>(false);

  constructor(
    public dialog: MatDialog,
  ) {}

  /**
   * Open dialog to set field and value to update
   */
  openUpdateDialog(): void {
    if (Object.keys(this.selectedRecords).length) {

      let updateDialog = this.dialog.open(BulkUpdateDialogComponent, {
        data: {
          module: this.moduleName,
          records: this.selectedRecords,
          is_total_selected: this.isTotalRecordSelected,
          current_filter: this.filterModel,
          total_records: this.totalRecords,
        },
        disableClose: true,
        maxHeight: '80vh',
        width: '70%',
        height: 'auto',
      });

      updateDialog.afterClosed().subscribe( response => {
        if (response && response['module']) {
          this.bulkUpdateEvent.emit('success');
        }
      });
    }
  }
}
