export const AUDIT_TRAIL_AVAILABLE_MODULES = [
  'leads',
  'jobs',
  'customers',
  'sites',
  'contacts',
  'opportunities',
  'recurring_jobs',
  'recurring_invoices',
  'items',
  'customer_invoices',
  'supplier_invoices',
  'purchase_orders',
  'assets',
];