<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'users']" ></fa-icon>&nbsp;
        <span *ngIf="strViewType == 'edit'" >{{ 'edit_task'| translate }} </span>
        <span *ngIf="strViewType == 'add'" >{{ 'create_task'| translate }} </span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li *ngIf="strViewType == 'edit' && !bCompleted" class="list-inline-item">
          <button type="button" (click)="markTaskAsCancelled()" class="btn ml-1 btn-danger" [disabled]="! taskForm">
            <fa-icon [icon]="['fas', 'ban']"></fa-icon>
            <span class="pl-1">{{ 'cancel_task' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item" *ngIf="(strViewType !== 'edit' || (strViewType === 'edit' && objEditDetails.editable))">
          <fieldmagic-primary-button
            (click)="onSubmit()"
            icon="save"
            label="save"
            [isInProgress]="bShowLoader"
          ></fieldmagic-primary-button>
        </li>
        <li class="list-inline-item">
          <fieldmagic-primary-button
            variant="error"
            (click)="cancelDialog()"
            icon="times"
            label="close"
          ></fieldmagic-primary-button>
        </li>
      </ul>
    </div>
  </div>
</header>

<hr class="separator"/>

<div class="col-12">
  <nav class="pb-3">
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <a
        [ngClass]="{'active': activeTab === 'information'}"
        class="nav-item nav-link noradius text-primary pointer record-labels" id="infoTab"
        (click)="switchTab('information')">
        {{ 'information' | translate }}
      </a>
      <a *ngIf="(objEditDetails['job_id'] || objEditDetails['opportunity_id']) && strViewType === 'edit'"
        [ngClass]="{'active': activeTab === 'schedules'}"
        class="nav-item nav-link noradius text-primary pointer record-labels" id="schedulesTab"
        (click)="switchTab('schedules')">
        {{ 'schedule' | translate }}
      </a>
    </div>
  </nav>
</div>

<div *ngIf="activeTab === 'information'">
  <div *ngIf="objTaskLayout && objTaskField && taskForm && bPageLoaded" class="col-sm-12">
    <form [formGroup]="taskForm">
      <div *ngFor="let objTaskColumn of objTaskLayout" class="row">
        <div *ngFor="let strFieldId of objTaskColumn" class="form-group" [ngClass]="(objTaskColumn.length == 1) ? 'col-sm-12' : 'col-sm-6'">
          <app-edit-field
            strModule="activities"
            [strParentModule]="strModule"
            [item]="objTaskField[strFieldId]"
            [form]="taskForm"
            strMode="add"
            [bSubmitted]="bSubmitted"
            (parentEvent)="getRelateValueChange($event)">
          </app-edit-field>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="activeTab === 'schedules'">
  <app-schedules-tab
    [activity-id]="data['activity_id']"
    [job-id]="jobId"
    [opportunity-id]="opportunityId"
    [default-user-on-create]="assignedUser"
    [metadata-type]="'task'"
    [viewing-from-task-dialog]="true"
    [has-padding]="true">
  </app-schedules-tab>
</div>

<ng-container *ngIf="!bPageLoaded">
  <div class="col-sm-12" align="center">
    <h4><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></h4>
  </div>
</ng-container>