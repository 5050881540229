<div data-testid="view-mode-display">
  <div class="row">
    <div class="col-6">
      <fieldmagic-relate-input
        moduleName="items"
        placeholder="filter_by_product"
        ($onChange)="onItemChange($event)"
        [filter]="itemRelateFilter"
        [bDisplayLabel]="false">
      </fieldmagic-relate-input>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <span *ngIf="!isObjListBlank">
        <small *ngIf="objList?.total_records; else noRecordFound">
          {{ 'list_view_summary' | translate: {fromRecords: objList.from_records, toRecords: objList.to_records, totalRecords: objList.total_records} }}
        </small>
      </span>
    </div>

    <div class="col-6">
      <div *ngIf="!isObjListBlank" class="pagination-section">
        <ul *ngIf="objList?.total_records" class="pagination float-right">
          <li class="page-item" [class.disabled]="!objList.hasPreviousToken">
            <a id="firstPage" class="page-link" [ngClass]="{'pagination-color': objList.hasPreviousToken}" aria-label="first" (click)="goToListingPage.next(1)">
              <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
            </a>
          </li>
          <li class="page-item" [class.disabled]="!objList.hasPreviousToken">
            <a id="prevPage" class="page-link" [ngClass]="{'pagination-color': objList.hasPreviousToken}" aria-label="previous" (click)="goToListingPage.next(objList.current_page - 1)">
              <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
            </a>
          </li>
          <li class="page-item" [class.disabled]="!objList.hasNextToken">
            <a id="nextPage" class="page-link" [ngClass]="{'pagination-color': objList.hasNextToken}" aria-label="next" (click)="goToListingPage.next(objList.current_page + 1)">
              <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
            </a>
          </li>
          <li class="page-item" [class.disabled]="!objList.hasNextToken">
            <a id="lastPage" class="page-link" [ngClass]="{'pagination-color': objList.hasNextToken}" aria-label="last" (click)="goToListingPage.next(objList.last_page)">
              <fa-icon [icon]="['far', 'chevron-double-right']" aria-hidden="true"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <table class="table table-bordered mt-2">
        <thead>
          <tr>
            <th class="text-center font-size-12" width="25%">{{ 'name' | translate }}</th>
            <th class="text-center font-size-12" width="25%">{{ 'code' | translate }}</th>
            <th class="text-center font-size-12" width="25%">{{ 'quantity' | translate }}</th>
            <th class="text-center font-size-12" width="25%">{{ 'On Order' | translate }}</th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngIf="!isObjListBlank; else loading">
            <tr *ngFor="let stock_level of objList?.data">
              <td>
                <a target="_blank" [routerLink]="['/items/' + stock_level.item_id]">{{ stock_level.product_name }}</a>
              </td>

              <td>{{ stock_level.product_code }}</td>

              <td>{{ stock_level.quantity }}</td>

              <td>{{ stock_level.on_order }}</td>

            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #loading>
  <tr>
    <td colspan="3" class="text-center">
      <inline-view-loader [isLoading]="true"></inline-view-loader>
    </td>
  </tr>
</ng-template>

<ng-template #noRecordFound>
  <small>{{ 'no_record_found' | translate }}</small>
</ng-template>