import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './shared/modules/material.module';
import { FontAwesomeModule } from './shared/modules/font-awesome.module';
import { SharedModule } from './shared/modules/shared.module';
import { EditModule } from './shared/modules/edit.module';
import { WizardModule } from './features/wizard/wizard.module';
import { AgmCoreModule } from '@agm/core';
import { SharedModule as AccountingSystemModule } from './integrations/accounting_systems/shared/shared.module';
import { SharedModule as FormTemplateSharedModule } from './module/form-templates/shared/shared.module';
import { QuillModule } from 'ngx-quill';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { LayoutAccountComponent } from './shared/layouts/layout-account/layout-account.component';
import { LayoutDashboardComponent } from './shared/layouts/layout-dashboard/layout-dashboard.component';
import { ForceupdateComponent } from './shared/components/forceupdate/forceupdate.component';
import { PopupComponent as NotificationPopupComponent } from './shared/components/notifications/popup/popup.component';
import { HeaderComponent as NotificationHeaderComponent } from './shared/components/notifications/header/header.component';
import { ConfirmationComponent } from './shared/components/notifications/confirmation/confirmation.component';
import { AddQuoteComponent } from './module/opportunities/quotes/add-quote/add-quote.component';
import { NameQuoteComponent } from './module/opportunities/quotes/name-quote/name-quote.component';
import { PreviewQuoteComponent } from './module/opportunities/quotes/preview-quote/preview-quote.component';

// Services
import { AuthService } from './auth/auth.service';
import { AccountService } from './services/account.service';
import { CustomTranslateService } from './services/custom-translate.service';
import { LayoutDashboardService } from './shared/layouts/layout-dashboard/layout-dashboard.service';
import { NotificationService } from './services/notification.service';
import { ChecklistsService } from './services/checklist.service';

import { Title } from '@angular/platform-browser';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ErrorComponent } from './error/error.component';
import { AtatusErrorHandler } from './handlers/atatus.handler';
import { ChargebeeModule } from './admin/chargebee/chargebee.module';
import { WebpackTranslateLoader } from './shared/classes/webpack-translate-loader';
import { SearchService } from './services/search.service';
import { AdvanceSearchboxService } from './services/advance-searchbox.service';
import { SharedInputModule } from './shared/modules/shared-input.module';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './services/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';

import { PushNotificationsModule } from 'ng-push';
import { AcsSupportService } from './module/acs-support/acs-support.service';
import { DialogComponent } from './account/client-info/dialog/dialog.component';
import { DataSharingService } from './services/data-sharing/data-sharing.service';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { DashboardModule } from './shared/layouts/layout-dashboard/dashboard/dashboard.module';
import { ProductFoldersModule } from './features/product-folders/product-folders.module';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ShContextMenuModule } from 'ng2-right-click-menu';
import { ContextMenuService } from './services/context-menu.service';
import { SharedUsersModule } from './module/user/shared-users.module';
import { EventBusService } from './services/event-bus.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LayoutAccountComponent,
    LayoutDashboardComponent,
    ForceupdateComponent,
    ErrorComponent,
    NotificationPopupComponent,
    NotificationHeaderComponent,
    ConfirmationComponent,
    AddQuoteComponent,
    NameQuoteComponent,
    PreviewQuoteComponent,
    DialogComponent
  ],
  exports: [
    QuillModule,
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDOMAL3H37-L_Mg5VjLSSFRbGNT5SvSSUk',
      libraries: ['places']
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    AppRoutingModule,
    MaterialModule,
    FontAwesomeModule,
    SharedModule.forRoot(),
    EditModule,
    WizardModule,
    PopoverModule.forRoot(),
    DragDropModule,
    MatCardModule,
    ChargebeeModule,
    MatSnackBarModule,
    AccountingSystemModule,
    FormTemplateSharedModule,
    QuillModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    PushNotificationsModule,
    RecaptchaV3Module,
    SharedInputModule,
    DashboardModule,
    ProductFoldersModule,
    CarouselModule.forRoot(),
    ShContextMenuModule,
    SharedUsersModule,
  ],
  entryComponents: [
    ConfirmationComponent,
    ForceupdateComponent,
    AddQuoteComponent,
    NameQuoteComponent,
    PreviewQuoteComponent,
    DialogComponent,
  ],
  providers: [
    SearchService,
    AdvanceSearchboxService,
    AcsSupportService,
    AuthService,
    AccountService,
    ChecklistsService,
    CustomTranslateService,
    LayoutDashboardService,
    NotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AtatusErrorHandler
    },
    Title,
    MessagingService,
    AsyncPipe,
    DataSharingService,
    ContextMenuService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha_site_key,
    },
    EventBusService,
  ],
  bootstrap: [ AppComponent ],
})

export class AppModule { }
