import { PipeTransform, Pipe } from '@angular/core';
import { isString } from 'lodash-es';
import { data_get, fallback } from '../shared/utils/common';

@Pipe({
  name: 'data_get',
  pure: false,
})
export class DataGetPipe implements PipeTransform {
  transform = <T extends Object, R = any>(value: T, args: DataGetPipeOptions<R> | string): R | undefined => {
    let opts: DataGetPipeOptions<R>;

    if (isString(args)) {
      opts =  {
        key: args,
      }
    } else {
      opts = args as DataGetPipeOptions<R>;
    }

    return fallback(data_get(value, opts.key), {
      fallback: () => opts.default_value,
    });
  }
}

type DataGetPipeOptions<R> = {
  key: string | string[],
  default_value?: R
}
