import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';

//Get the base url from the environment file.
import { environment } from '../../../../environments/environment';

import { NotificationService } from '../../../services/notification.service';
import { ErrorPublishingService } from '../../../services/error-publishing.service';
import { Observable } from 'rxjs/Observable';
import { Config } from 'protractor';
import { CustomerWizardData, JobWizardData, QuoteWizardData } from '../objects/wizard';
import { Subject } from 'rxjs';
import { LayoutDashboardService } from '../../../shared/layouts/layout-dashboard/layout-dashboard.service';
import { tap } from 'rxjs/operators';
import { isEmpty, get } from 'lodash-es';

const kBaseUrl: string = environment.url + "/record/";

@Injectable()
export class WizardService {

  /**
   * Listens if we will close the wizard.
   *
   * @var {Subject}
   */
  public onDialogClose = new Subject<boolean>();
  $onDialogClose = this.onDialogClose.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    protected notifications: NotificationService,
    protected errors: ErrorPublishingService
  ) { }

  saveJobDetails(objData: JobWizardData ): Observable<HttpResponse<Config>> {
    let body = new URLSearchParams();
    body.append('data', JSON.stringify(objData));
    return this.http.post<Response>(kBaseUrl + 'wizard/job', body.toString(), { observe: 'response' }).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.notifications.notifyNotAllowed();
          }
        }
      })
    );
  }

  saveQuoteDetails(objData: QuoteWizardData ): Observable<HttpResponse<Config>> {
    let body = new URLSearchParams();
    body.append('data', JSON.stringify(objData));
    return this.http.post<Response>(kBaseUrl + 'wizard/opportunity', body.toString(), { observe: 'response' }).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.notifications.notifyNotAllowed();
          }
        }
      })
    );
  }

  /**
   * Saves the customer wizard details to the API.
   *
   * @param {CustomerWizardData} objData
   *
   * @returns {Observable<HttpResponse<Config>>}
   */
  saveCustomerDetails(objData: CustomerWizardData): Observable<HttpResponse<Config>> {
    let body = new URLSearchParams();
    body.append('data', JSON.stringify(objData));
    return this.http.post<Response>(kBaseUrl + 'wizard/customer', body.toString(), { observe: 'response' }).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.notifications.notifyNotAllowed();
          }
        }
      })
    );
  }

  savePurchaseOrderDetails(objData): Observable<HttpResponse<Config>> {
    let body = new URLSearchParams();
    body.append('data', JSON.stringify(objData));
    return this.http.post<Response>(kBaseUrl + 'wizard/purchase_order', body.toString(), { observe: 'response' }).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.notifications.notifyNotAllowed();
          }
        }
      })
    );
  }

  /**
   * Checking preselected data.
   *
   * @param {LayoutDashboardService} layout
   * @param {any} dialog
   *
   * @returns {any}
   */
  checkPreselectedData(layout: LayoutDashboardService, dialog: any): any{
    if (
      layout.objCurrentViewedRecord !== null &&
      !isEmpty(layout.objCurrentViewedRecord['record_details']) &&
      !isEmpty(layout.objCurrentViewedRecord['module']) &&
      // Only preselect data on the customer form of the wizard if it was not opened from the mega menu
      !get(dialog, 'opened_from_mega_menu', false)
    ) {

      let objRecord = layout.objCurrentViewedRecord['record_details'];

      // FC-4005: if customer id was found, add customer_id in dialog response
      if (objRecord['customer_id']) {
        dialog.customer_id = objRecord['customer_id'];
      }

      // FC-4005: if current view record is customer, use customer id instead relate customer_id
      if (layout.objCurrentViewedRecord['module'] == 'customers' && objRecord['id']) {
        dialog.customer_id = objRecord['id'];
      }

      if (
        objRecord['site_id'] ||
        (layout.objCurrentViewedRecord['module'] == 'sites' && objRecord['id'])
      ) {
        dialog.site_id = objRecord['site_id'] || objRecord['id'];
      }
    }

    return dialog;
  }

}