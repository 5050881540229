import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Response } from '@angular/http';
import { Observable, Subject } from 'rxjs';

import { catchError } from 'rxjs/operators';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

import 'rxjs/add/operator/map';
import { TranslateService } from '@ngx-translate/core';

import { Field } from '../objects/field';
import { Config } from 'protractor';
import { DEFAULT_PLATFORM_TYPE, LayoutPlatformType } from '../objects/studio';

const kBaseUrl: string = environment.url + "/studio/";

@Injectable()
export class StudioService {

  constructor (private http: HttpClient, private router: Router, public translate: TranslateService) {}

  /*
   * Get the list of modules for this client.
   */
  getModules(): Observable<Response>{
    return this.http.post<Response>(kBaseUrl + "modules", {});
  }

  /**
   * Get the record view and available fields of the selected module.
   * @param strModule - the selected module.
   */
  getModuleView(strModule: string, platform: LayoutPlatformType = DEFAULT_PLATFORM_TYPE): Observable<Response>{
    let arFormEncode = new URLSearchParams();

    arFormEncode.append('module', strModule);
    arFormEncode.append('platform', platform);

    return this.http.post<Response>(kBaseUrl + 'layout', arFormEncode);
  }

  /**
   * Creates a new custom field
   * for a given module.
   *
   * @param objNewField - See objects/field.ts
   */
  createField(objNewField): Observable<HttpResponse<Config>>{
    let arFormEncode = new URLSearchParams();
    arFormEncode.append('body', JSON.stringify(objNewField));
    return this.http.post<HttpResponse<Config>>(kBaseUrl + "create_field", arFormEncode, { observe: 'response' });
  }

  /**
   * Updates the custom field
   * from a module.
   *
   * @param objNewField
   */
  updateField(objUpdateField): Observable<HttpResponse<Config>>{
    //We remove deletable and readonly from the final JSON since API does not accept it when updating fields.
    delete objUpdateField['custom_field'][0]['deletable'];
    delete objUpdateField['custom_field'][0]['readonly'];
    let arFormEncode = new URLSearchParams();
    arFormEncode.append('body', JSON.stringify(objUpdateField));
    return this.http.post<HttpResponse<Config>>(kBaseUrl + "update_field", arFormEncode, { observe: 'response' });
  }

  /**
   * Updates the custom field
   * from a module.
   *
   * @param objNewField
   */
  deleteField(objDeleteField, objRecordView){
    let arFormEncode = new URLSearchParams();
    arFormEncode.append('field', JSON.stringify(objDeleteField));
    arFormEncode.append('view', JSON.stringify(objRecordView));
    return this.http.post<Response>(kBaseUrl + "delete_field", arFormEncode);
  }

  /**
   * Saves the record view
   * for a given module.
   *
   * @param objUpdateRecord - See objects/layout.ts
   */
  saveRecordView(objUpdateRecord, platform: LayoutPlatformType = DEFAULT_PLATFORM_TYPE) {
    let arFormEncode = new URLSearchParams();
    arFormEncode.append('body', JSON.stringify(objUpdateRecord));
    arFormEncode.append('platform', platform);
    return this.http.post<Response>(kBaseUrl + "create_record", arFormEncode);
  }

  /**
   * Saves the record view
   * for a given module.
   *
   * @param objDeleteRecord - See objects/layout.ts
   */
  deleteRecordView(objDeleteRecord, platform: LayoutPlatformType = DEFAULT_PLATFORM_TYPE) {
    let arFormEncode = new URLSearchParams();
    arFormEncode.append('body', JSON.stringify(objDeleteRecord));
    arFormEncode.append('platform', platform);
    return this.http.post<Response>(kBaseUrl + "delete_record", arFormEncode);
  }
}
