import { UUID } from 'angular2-uuid';

export class ActivityLogTypeMetadata {
  /**
   * activity log type id
   *
   * @type {string}
   */
  id: string;

  /**
   * name of the activity log type
   *
   * @type {string}
   */
  name: string;

  /**
   * determine if the activity is payable
   *
   * @type {boolean}
   */
  is_payable: boolean;

  /**
   * determine if the activity is productive
   *
   * @type {boolean}
   */
  is_productive: boolean;

  /**
   * for every plotted event id
   */
  scheduler_id: string;

  reschedulable?: boolean;

  constructor(properties) {
    this.id = properties.id
    this.name = properties.name
    this.is_payable = properties.is_payable
    this.is_productive = properties.is_productive
    this.reschedulable = properties.reschedulable;

    this.scheduler_id = UUID.UUID();
  }
}