import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';
const kBaseUrl: string = environment.url + "/document_library/";

@Injectable()
export class DocumentService {

  constructor (private http: HttpClient, private router: Router) {}

  // Upload a new document in s3 and save data in table
  addDocument(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "create", body.toString());
  }

  // Reupload a new version of pdf file
  updateDocument(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "update", body.toString());
  }

  // Get all documents in library
  getDocuments(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "get", body.toString());
  }

	/**
	 * Unlinks the document from the asset type.
	 *
   * @param {string} strLinkId
	 *
	 * @returns {Observable<{ data: UnlinkDocumentResponseData }>}
	 */
  unlinkDocumentFromAssetType(strLinkId: string): Observable<{ data: UnlinkDocumentResponseData }> {
    return this.http.post<{ data: UnlinkDocumentResponseData }>(
      `${environment.url}/asset_types/unlink_document`,
        new URLSearchParams({
          document_library_asset_type_id: strLinkId,
        })
    );
  }
}

export interface UnlinkDocumentResponseData {
  unlinking_successful: boolean;
}

export interface LinkedDocument {
  id: string;
	document_library_id: string;
	document_name: string;
	upload_name: string;
	document_size: number;
	created_at: string;
	updated_at: string;
}

export interface LinkDocumentResponse {
  data: LinkedDocument[];
  hasNextToken: boolean;
}