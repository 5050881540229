import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { PickerType, SelectMode } from 'ng-pick-datetime/date-time/date-time.class';
import { Form } from '../../../../../base/form';
import { DateSelectList } from '../../../../../lists/date-select-list';

import * as _moment from 'moment';
import { I } from '@angular/cdk/keycodes';
import { Subscription } from 'rxjs';
import { get } from 'lodash-es';
import { filled } from '../../../../utils/common';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'input-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})

export class DateComponent implements OnInit, OnDestroy {

  /**
   * The form group that holds the FormControl
   * of this input field.
   *
   * @var { FormGroup }
   */
  @Input() objForm: FormGroup;

  /**
   * Since we have custom fields in the system, sometimes
   * we want additional settings that comes with the
   * form input, and this will contain it.
   *
   * @var { Form | any }
   */
  @Input() objItem: Form<string> | any;

  /**
   * Usually, forms have 2 modes, either add or edit.
   * So we put it here.
   *
   * @var { string }
   */
  @Input() strMode: string;

   /**
   * Sometimes there are special cases for dates
   * under certain module, so let's put it here.
   *
   * @var { string }
   */
  @Input() strModule: string;

  /**
   * In order to support the centralized form, sometimes after a process in the
   *
   */
  @Output() sendToParent = new EventEmitter<any>();

  /**
   * For displaying the owl value in the
   * input.
   *
   * @var {string}
   */
  public strDateDisplay: string;

  /**
   * The date form object.
   *
   * @var {DateForm}
   */
  public objDate: DateForm;

  /**
   * Values for date ranges.
   *
   * @var {any[]}
   */
  public arDateRangeField: any[] = new moment();

  /**
   * List of subscriptions to destroy
   * once component is closed.
   *
   * @var {Subscription[]}
   */
  public arSubscriptions: Subscription[] = [];

  /**
   * Hide or show the owl component.
   *
   * @var {boolean}
   */
  public bIsOwlComponentShown: boolean = false;

  /**
   * The minimum date range, dates before this will be disabled.
   *
   * @var {Date}
   */
  public dateRangeMin: Date = null;

  /**
   * The maximum date range, dates after this will be disabled.
   *
   * @var {Date}
   */
  public dateRangeMax: Date = null;

  /**
   * Retrieves the key of the field in the form.
   *
   * @var {boolean}
   */
  get key(): string { return this.objItem['key']; }

  /**
   * Retrieves the FormControl in the
   * form using the key.
   *
   * @var {FormControl}
   */
  get control(): AbstractControl { return this.objForm.controls[this.key]; };

  /**
   * If the character left after removing the field type
   * is just a number, it means that the field is a custom field.
   *
   * @var {boolean}
   */
  get touched(): boolean { return this.control.touched || false; }

  /**
   * If the field is required.
   *
   * @var {boolean}
   */
  get required(): boolean { return this.objItem.required || false; }

   /**
   * If the character left after removing the field type
   * is just a number, it means that the field is a custom field.
   *
   * @var {boolean}
   */
  get readonly(): boolean { return this.objItem.readonly || false; }

   /**
   * Sets the placeholder if there is any.
   *
   * @var {string}
   */
  get placeholder(): string {
    return get(this.objItem, 'placeholder', '');
  }

  /**
   * Dynamically switch model if the date is ranged or not.
   *
   * @var {any}
   */
  get model(): any {
    if (this.objDate.is_ranged) {
      return this.arDateRangeField;
    } else {
      return this.strDateDisplay;
    }
  }

  /**
   * Dynamically switch model if the date is ranged or not.
   *
   * @var {any}
   */
   set model(objValue: any) {
    if (this.objDate.is_ranged) {
      this.arDateRangeField = objValue;
    } else {
      this.strDateDisplay = objValue;
    }
  }

  /**
   * Validate the form.
   *
   * @var {boolean}
   */
  get valid(): boolean {
    if (this.objDate.is_ranged) {
      return !this.validateDateRange();
    } else {
      return (this.control.disabled) ? true : this.control.valid || false;
    }
  }

  /**
   * If the character left after removing the field type
   * is just a number, it means that the field is a custom field.
   *
   * @var {boolean}
   */
  get isCustomField(): boolean { return isNaN(parseInt(this.key.replace(this.objDate.type, ''))) ; }

  constructor() { }

  ngOnInit() {
    this.objDate = new DateForm(this.objItem.controlType);

    // If you wanna use this component outside the centralize
    // and you did not provide a form group, it will make one for you.
    if (!this.objForm) {
      this.objForm = new FormGroup({
        [this.key]: new FormControl(null, this.objItem.required ? Validators.required : null)
      });
      this.arSubscriptions.push(this.objForm.valueChanges.subscribe(objValue => {
        this.sendToParent.emit({field_value: objValue});
      }));
    }

    if (this.objDate.is_ranged) {
      this.generateDateRanges();
    } else {
      this.initializeDateField();
    }

    this.arSubscriptions.push(
      this.objForm.controls[this.key].valueChanges.subscribe((value) => {
        if (!this.objDate.is_ranged) {
          this.model = moment
            .utc(value)
            .local()
            .format(this.objDate.format);
        }
      })
    );
  }

  ngOnDestroy() {
    this.arSubscriptions.forEach(item => {
      item.unsubscribe();
    });
  }

  /**
   * Generates the date ranges on initalization.
   *
   * @returns {void}
   */
  generateDateRanges(): void {

    let strTempStart = moment.utc(this.control.value.start_date)._d;;
    let strTempEnd = moment.utc(this.control.value.end_date)._d;

    this._initDateMinMax();

    this.arDateRangeField = [moment(strTempStart).toDate()];

    let objNewPatchValue = {
      [this.key]: {
        start_date : moment.utc(strTempStart).format(this.objDate.format)
      }
    };

    if (this.control.value.end_date != undefined) {
      this.arDateRangeField.push(moment(strTempEnd).toDate());
      objNewPatchValue[this.key]['end_date'] = moment.utc(strTempEnd).format(this.objDate.format);
    }

    this.objForm.patchValue(objNewPatchValue);

  }

  /**
   * Initialize date fields, update the form and display values
   * for the datetime component.
   *
   * @return {void}
   */
  initializeDateField(): void {
    let strTempDate = this.getTempDate();

    this.strDateDisplay = '';
    let strPatchValue: string = '';

    // FC-1263: Fix .toDate is not a function by adding instanceof moment
    if (strTempDate != undefined && strTempDate != "" && strTempDate instanceof moment) {
      let objDisplay = moment(strTempDate).format(this.objDate.format);
      strPatchValue = (this.objDate.type == FieldmagicDateEnum.Date) ?
        objDisplay :
        moment.utc(strTempDate._d.toUTCString()).format(this.objDate.format)
      ;
      this.strDateDisplay = objDisplay;
    }

    this._initDateMinMax();

    this.objForm.patchValue({[this.key]: strPatchValue});
  }

  /**
   * Can't keep track of possible returns
   * for this method so lets go with any.
   *
   * @returns {any}
   */
  getTempDate(): any {

    if (this.objItem.default_value && typeof(this.objItem.default_value) == 'object') {
      return this.getDateTimePreset(this.objItem.default_value);
    } else {

      if (this.objItem.default_value) {
        if (this.objItem.date != undefined && this.objItem.date != "" && JSON.stringify(new DateSelectList().list).includes(this.objItem.date)) {
          return this.getDateTimePreset({
            date: this.objItem.date,
            meridiem: (this.objItem.meridiem != undefined) ? this.objItem.meridiem : '',
            hour: (this.objItem.hour != undefined) ? this.objItem.hour : 0,
            minute: (this.objItem.minute != undefined) ? this.objItem.minute : 0
          });
        } else {
          return moment(this.objItem.default_value);
        }
      } else if (this.objItem.default_value == '' && this.strMode == 'add') {
        if (this.objDate.select_mode == 'range') {
          return this.checkDateDefaultFieldValue();
        } else {
          return moment();
        }
      }
    }

  }


  /**
   * We update the datetime value.
   *
   * @param {value: string[] | string } objEvent
   */
   updateDateTime(objEvent: {value: any }): void {

    if (this.bIsOwlComponentShown || this.strMode == 'add') {

      if (this.objDate.is_ranged) {
        this.updateRangedTime(objEvent.value[0], objEvent.value[1]);
      } else {
        this.updateNonRangedTime(objEvent.value);
      }

      this.bIsOwlComponentShown = false;

    } else {
      this.objForm.patchValue({
        [this.key]: objEvent.value,
      });
    }

    this.objForm.controls[this.key].markAsDirty();
    this.sendToParent.emit({
      field_type: this.objDate.type
    });
  }

  /**
   * Just toggles the owl component
   * if were gonna hide or show.
   *
   * @returns {void}
   */
  calendarToggle(): void {
    this.bIsOwlComponentShown = !this.bIsOwlComponentShown;
  }

  /**
   * Set the time either as default or UTC.
   *
   * @param {string} strTime
   *
   * @returns {any}
   */
  setTimeValueOnUpdate(strTime: string): any {
    return moment(
      !this.objDate.has_time ?
      moment(strTime) :
      moment.utc(moment(strTime)._d.toUTCString())
    ).format(this.objDate.format);
  }

  /**
   * Updates the list of date range time and also emit a
   * get diff event.
   *
   * @param {string} strStartValue
   * @param {string} strEndValue
   *
   * @returns {void}
   */
  updateRangedTime(strStartValue: string, strEndValue: string): void {

    let strFormValueStart: any = '';
    let strFormValueEnd: any = '';

    // Check if event value of datetime is not defined
    if (strStartValue != undefined && strEndValue != undefined) {

      strFormValueStart = this.setTimeValueOnUpdate(strStartValue);
      strFormValueEnd = this.setTimeValueOnUpdate(strEndValue);

      if (strFormValueStart != 'Invalid date' && strFormValueEnd != 'Invalid date') {
        // Calculate date difference then pass the result to your component
        this.sendToParent.emit({
          date_difference: this.calculateDateDiff(strFormValueStart,strFormValueEnd) as any
        });
      }

    } else {
      this.sendToParent.emit({
        date_difference: 'invalid'
      });
    }

    // Patch the field
    this.objForm.patchValue({
      [this.key]: {
        start_date : strFormValueStart,
        end_date : strFormValueEnd
      }
    });

  }

  /**
   * Update the non ranged time and
   * patch the new value.
   *
   * @param {string} objDateValue
   *
   * @returns {void}
   */
  updateNonRangedTime(objDateValue: string): void {

    let strFormValue = this.setTimeValueOnUpdate(objDateValue);

    // Verify first if we actually have a valid formatted data.
    if (moment(strFormValue).isValid()) {

      this.strDateDisplay = moment(objDateValue).format(this.objDate.format);
      this.objForm.patchValue({[this.key]: strFormValue});

    } else {
      // If we don't have one, lets patch the value to null to trigger other constraints.
      this.objForm.patchValue({[this.key]: null});
    }

  }

  /**
   * Clear the fields.
   *
   * @param strField
   *
   * @returns {void}
   */
  clearField(): void {
    this.objForm.patchValue({[this.key]: ''});
    this.strDateDisplay = '';
    this.arDateRangeField = [];
  }

  /**
   * Validation for Start and End Datetime
   */
  validateDateRange(): boolean {
    if (
      this.arDateRangeField[0] == undefined ||
      this.arDateRangeField[0] == null ||
      this.arDateRangeField[0] == '' ||
      this.arDateRangeField[1] == undefined ||
      this.arDateRangeField[1] == null ||
      this.arDateRangeField[1] == ''
    ) {
      return true;
    }
    return false;
  }

  /**
   * This functions gets the date/datetime based on
   * the given preset.
   *
   * @param objDateTime
   */
  getDateTimePreset(objDateTime: {
    date: string,
    hour: string,
    minute: string,
    meridiem: string
  }): any {
    // Check if not empty
    if (objDateTime.date) {
      //Temporary holder for the final formed datetime.
      let strTempDateTime: any;

      switch(objDateTime.date) {
        case 'today':
          strTempDateTime = moment();
          break;
        case 'yesterday':
          strTempDateTime = moment().subtract(1, 'days');
          break;
        case 'tomorrow':
          strTempDateTime = moment().add(1, 'days');
          break;
        case 'next_week':
          strTempDateTime = moment().add(7, 'days');
          break;
        case 'next_monday':
          strTempDateTime = moment().add(1, 'weeks').startOf('isoWeek');
          break;
        case 'next_friday':
          strTempDateTime = moment().add(1, 'weeks').startOf('isoWeek').add(4, 'days');
          break;
        case 'two_weeks':
          strTempDateTime = moment().add(2, 'weeks');
          break;
        case 'next_month':
          strTempDateTime = moment().add(1, 'months');
          break;
        case 'first_day_month':
          strTempDateTime = moment().add(1, 'months').startOf('month');
          break;
        case 'three_months':
          strTempDateTime = moment().add(3, 'months');
          break;
        case 'six_months':
          strTempDateTime = moment().add(6, 'months');
          break;
        case 'next_year':
          strTempDateTime = moment().add(1, 'years');
          break;
      }

      //Initialize the time, set to 0 if none given.
      let intHour = objDateTime.hour ? Number(objDateTime.hour) : 0;
      let intMin = objDateTime.minute ? Number(objDateTime.minute) : 0;

      //If the meridiem is a pm, we add 12 to the hrs since we need a 24-hr format
      //for the momentjs to read.
      if (objDateTime.meridiem && objDateTime.meridiem == "pm") {
        intHour = ((intHour + 12) == 24) ? 0 : intHour + 12;
      }

      //Return the final time as a Date object.
      return strTempDateTime.set({h: intHour, m:intMin});
    }

    return '';
  }

  /**
   * Check the date default field, usually this is when the form is edit
   * or add but custom fields.
   *
   * @returns {void}
   */
  checkDateDefaultFieldValue(): void {

    if (this.isCustomField) {
      // All date fields shall have a default value of today's date.
      if (
        (this.strModule === 'jobs' && this.key.includes('date_completed')) ||
        (this.strModule === 'recurring_jobs' && this.key.includes('expiry_date'))
      ) {
        // But a special case for jobs' date_completed field and
        // 'recurring jobs' expiry_date, it should be blank.
        return null;
      }
    } else {
      // but if it's a custom field, the default value will be used if configured,
      // and null if otherwise
      return this.objItem.default_value || null;
    }

  }

  /**
   * Calculate date differenct.
   *
   * @param {string} strStartDate
   * @param {string} strEndDate
   *
   * @returns {years: any,  months: any, days: any, hours: any, minutes: any, seconds: any }}
   */
  calculateDateDiff(strStartDate: string, strEndDate: string): {
    years: any,
    months: any,
    days: any,
    hours: any,
    minutes: any,
    seconds: any
  } {

    //define moments for the startdate and enddate
    var strStartDateMoment = moment(strStartDate);
    var strEndDateMoment = moment(strEndDate);

    if (strStartDateMoment.isValid() === true && strEndDateMoment.isValid() === true) {
      //getting the difference in years
      var years = strEndDateMoment.diff(strStartDateMoment, 'years');

      //to calculate the months, first get the previous year and then subtract it
      strStartDateMoment.add(years, 'years')
      var months = strEndDateMoment.diff(strStartDateMoment, 'months');

      //to calculate the days, first get the previous month and then subtract it
      strStartDateMoment.add(months, 'months');
      var days = strEndDateMoment.diff(strStartDateMoment, 'days');

      //Similar to days go the previous day
      // startdateMoment.add(days, 'days')
      var hours = strEndDateMoment.diff(strStartDateMoment, 'hours');

      //Getting minutes
      strStartDateMoment.add(hours, 'hours')
      var minutes = strEndDateMoment.diff(strStartDateMoment, 'minutes');

      //Similar to days go the previous day
      strStartDateMoment.add(minutes, 'minutes')
      var seconds = strEndDateMoment.diff(strStartDateMoment, 'seconds');

      return {
        years: years,
        months: months,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      };

    }
    else {
      return undefined;
    }
  }

  /**
   * Sets the min and max range fields
   *
   * @returns {void}
   */
  private _initDateMinMax(): void {
    if (filled(get(this.objItem, 'date_range_restriction.from'))) {
      const strRangeMin: string = moment.utc(this.objItem.date_range_restriction.from)._d;
      this.dateRangeMin = new Date(strRangeMin);
    }

    if (filled(get(this.objItem, 'date_range_restriction.to'))) {
      const strRangeMax: string  = moment.utc(this.objItem.date_range_restriction.to)._d;
      this.dateRangeMax = new Date(strRangeMax);
    }
  }
}

export enum FieldmagicDateEnum {
  Date = 'date',
  Datetime = 'datetime',
  DatetimeRange = 'datetimerange',
  DateRange = 'daterange',
};

export interface DateForm {

  /**
   * Available date field type of FieldmagicCloud.
   *
   * @var { FieldmagicDateEnum }
   */
  type: FieldmagicDateEnum;

  /**
   * The picker type for the date.
   *
   * @var { PickerType }
   */

  picker_type: PickerType;

  /**
   * The mode of select of the owl date time.
   *
   * @var { SelectMode }
   */
  select_mode: SelectMode

}

export class DateForm implements DateForm {

  type: FieldmagicDateEnum;
  picker_type: PickerType;
  select_mode: SelectMode;
  format: string;

  /**
   * Check if the date field is a range.
   *
   * @var {boolean}
   */
  get is_ranged() {return this.select_mode == 'range'};

  /**
   * If the date has time.
   *
   * @var {boolean}
   */
  get has_time() {return this.picker_type == 'both'};

  constructor(type: FieldmagicDateEnum) {
    this.type = type;

    if (this.type.includes('datetime')) {
      this.format = 'YYYY-MM-DD HH:mm:ss'
      this.picker_type = 'both';
    } else {
      this.format = 'YYYY-MM-DD'
      this.picker_type = 'calendar';
    }

    if (this.type.includes('range')) {
      this.select_mode = 'range';
    } else {
      this.select_mode = 'single';
    }
  }

}
