<div class="row">
    <div class="col-sm-12">
      <span class="title" translate>
        <fa-icon [icon]="['fas', 'envelope']" ></fa-icon>
        &nbsp; {{ 'send_an_email'| translate }}
      </span>
      <span class="buttons">

        <button type="button" *ngIf="isDraft === true && ! bIsArchivedMail" (click)="deleteDraft()" class="btn ml-1 btn-primary" [disabled]="isInProgress">
          <button-icon-loader [isLoading]="bShowLoader['delete_draft']" [defaultIcon]="['fas', 'trash-alt']"></button-icon-loader>
          <span class="pl-1">{{ 'delete' | translate }}</span>
        </button>
        <div class="btn-group ml-1">
          <button type="button" *ngIf="! bIsArchivedMail" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="loadEmailTemplates()" [disabled]="isInProgress">
            <fa-icon [icon]="['far', 'file-alt']"></fa-icon><span class="pl-1">{{ 'email_template' | translate }}</span>
          </button>
          <div class="dropdown-menu">
            <span class="dropdown-item pointer small" (click)="onManageSignature()">
              <fa-icon [icon]="['fas', 'signature']" class="pr-1"></fa-icon>&nbsp;{{ 'manage_signatures' | translate }}
            </span>
            <div class="dropdown-divider"></div>
            <span class="dropdown-item pointer small" (click)="onSaveEmailTemplate()">
              <fa-icon [icon]="['fas', 'file-plus']" class="pr-1"></fa-icon>&nbsp;&nbsp;{{ 'save_template' | translate }}
            </span>
            <div class="dropdown-divider"></div>
            <div *ngFor="let template of arTemplates" class="dropdown-item pointer small w280 d-flex pl-3 pr-3" (click)="onSelectTemplate(template.id)">
              <div class="d-inline"><div class="template-text w200" matTooltip="{{ template.name }}">{{ template.name }}</div></div>
              <div class="d-inline w-25">
                <fa-icon [ngClass]="{'d-none': template.id != strTemplateId}" [icon]="['fas', 'check']" class="text-success float-right"></fa-icon>
              </div>
            </div>
            <ng-container *ngIf="! isFetchingEmailTemplates">
              <span *ngIf="arTemplates.length == 0" class="dropdown-item small disabled">{{ 'no_available_template' | translate }}</span>
              <div *ngIf="arTemplates.length != 0 && !is_Empty(strTemplateId)" class="dropdown-divider"></div>
              <span *ngIf="arTemplates.length != 0 && !is_Empty(strTemplateId)" class="dropdown-item small pointer small" (click)="onResetForm()">{{ 'clear_template' | translate }}</span>
            </ng-container>
            <fa-icon *ngIf="isFetchingEmailTemplates" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true" class="dropdown-item small"></fa-icon>
          </div>
        </div>
        <button (click)="onSubmit(true)" *ngIf="! bIsArchivedMail" class="btn ml-1 btn-primary" [ngClass]="(bShowLoader['draft'])? 'btn-spinner' : ''" [disabled]="isInProgress">
          <button-icon-loader [isLoading]="bShowLoader['draft']" [defaultIcon]="['fas', 'save']"></button-icon-loader>
          <span class="pl-1">{{ 'save_draft' | translate }}</span>
        </button>
        <button (click)="onSubmit()" class="btn ml-1 btn-primary" [ngClass]="(bShowLoader['send'])? 'btn-spinner' : ''" [disabled]="isInProgress">
          <button-icon-loader [isLoading]="bShowLoader['send']" [defaultIcon]="['fas', 'paper-plane']"></button-icon-loader>
          <span class="pl-1">{{ 'send' | translate }}</span>
        </button>
        <fieldmagic-primary-button
          class="ml-1"
          (click)="cancelDialog()"
          [disabled]="isInProgress"
          variant="error"
          icon="times"
          label="close"
        ></fieldmagic-primary-button>
      </span>
    </div>
  </div>

  <hr class="separator"/>

  <ng-container *ngIf="emailForm && bPageLoaded">
  <form [formGroup]="emailForm">
    <div *ngIf="strEmailAddress" class="form-group row">
      <label for="from" class="col-sm-2 col-form-label">{{ 'from' | translate }}</label>
      <div class="col-sm-10">
        <ng-select
          class="select-input"
          [items]="fromAddresses"
          bindLabel="text"
          bindValue="id"
          formControlName="from"
          (change)="onChangeFromAddress($event)"
          >
        </ng-select>
      </div>
    </div>
    <div class="form-group row">
      <label for="to" class="col-sm-2 col-form-label">{{ 'to' | translate }}<required-tag></required-tag></label>
      <div class="col-sm-8">
        <ng-select *ngIf="arRelateValues['to']"
          [items]="arRelateValues['to'].item | async"
          [loading]="arRelateValues['to'].loading"
          [typeahead]="arRelateValues['to'].input"
          [clearable]="objEmailField['to'].clearable"
          [formControlName]="objEmailField['to'].key"
          [multiple]="objEmailField['to'].multiple"
          [hideSelected]="objEmailField['to'].hideSelected"
          [closeOnSelect]="true"
          [maxSelectedItems]="objEmailField['to'].maxSelectedItems"
          [hideSelected]="false"
          [ngClass]="{'select-invalid': !objEmailValidation.to  && bSubmitted && is_Empty(emailForm.get('to').value)}"
          [addTag]="addTag"
          [selectOnTab]="true"
          class="value-text"
          name="objEmailField['to'].key"
          bindLabel="text">
          <!-- DISPLAY IN SELECTED -->
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label">{{ item.text }} <{{ item.email_address.email }}></span>
            <span class="ng-value-icon right email-selected" (click)="clear(item)" aria-hidden="true">×</span>
          </ng-template>
          <!-- DISPLAY IN OPTION -->
          <ng-template  ng-option-tmp let-item="item">
            {{ item.text }}<br />{{ item.email_address.email }}
          </ng-template>
        </ng-select>
      </div>
      <div class="col-sm-2" class="pt005">
        <span (click)="toggleCc()" [ngClass]="{ 'text-warning': bHasCc, 'text-secondary': !bHasCc }" class="fs011 pointer pl-2">{{ 'cc' | translate }}</span>
        <span (click)="toggleBcc()" [ngClass]="{ 'text-warning': bHasBcc, 'text-secondary': !bHasBcc }" class="fs011 pointer pl-3">{{ 'bcc' | translate }}</span>
      </div>
    </div>
    <div *ngIf="arSuggested.length > 0" class="form-group row" style="margin-top: -10px;">
      <div class="col-sm-2"></div>
      <div class="col-sm-10">
        <small><i>{{ 'suggested_contacts' | translate }}</i></small>
        <div *ngFor="let suggest of arSuggested" class="row">
          <div class="col-12">
            <small>
              <fa-icon (click)="addTo(suggest)" [icon]="['fas', 'plus-circle']" class="text-success pointer"></fa-icon>
              <small class="ml-1">
                <fa-icon *ngIf="suggest['module'] == 'sites'" [icon]="['fas', 'map-marker-alt']"></fa-icon>
                <fa-icon *ngIf="suggest['module'] == 'customers'" [icon]="['fas', 'users']"></fa-icon>
                <fa-icon *ngIf="suggest['module'] == 'opportunities'" [icon]="['fas', 'list-ul']"></fa-icon>
              </small>
              {{suggest.contact_text}}
            </small>
            <small *ngFor="let email of suggest['email_address']">
              <span *ngIf="email.primary == '1'"><{{ email.email }}></span>
            </small>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="bHasCc" class="form-group row">
      <label for="cc" class="col-sm-2 col-form-label">{{ 'cc' | translate }}</label>
      <div class="col-sm-10">
        <ng-select *ngIf="arRelateValues['cc']"
          [items]="arRelateValues['cc'].item | async"
          [loading]="arRelateValues['cc'].loading"
          [typeahead]="arRelateValues['cc'].input"
          [clearable]="objEmailField['cc'].clearable"
          [formControlName]="objEmailField['cc'].key"
          [multiple]="objEmailField['cc'].multiple"
          [hideSelected]="objEmailField['cc'].hideSelected"
          [closeOnSelect]="true"
          [maxSelectedItems]="objEmailField['cc'].maxSelectedItems"
          [hideSelected]="false"
          class="value-text"
          [addTag]="addTag"
          [selectOnTab]="true"
          [ngClass]="{'is-invalid': !objEmailValidation.cc  && bSubmitted && !emailForm.get('cc').value && is_Empty(emailForm.get('cc').value)}"
          name="objEmailField['cc'].key"
          bindLabel="text">
          <!-- DISPLAY IN SELECTED -->
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label">{{ item.text }} <{{ item.email_address.email }}></span>
            <span class="ng-value-icon right email-selected" (click)="clear(item)" aria-hidden="true">×</span>
          </ng-template>
          <!-- DISPLAY IN OPTION -->
          <ng-template ng-option-tmp let-item="item">
            {{ item.text }}<br />{{ item.email_address.email }}
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div *ngIf="bHasBcc" class="form-group row">
      <label for="bcc" class="col-sm-2 col-form-label">{{ 'bcc' | translate }}</label>
      <div class="col-sm-10">
        <ng-select *ngIf="arRelateValues['bcc']"
          [items]="arRelateValues['bcc'].item | async"
          [loading]="arRelateValues['bcc'].loading"
          [typeahead]="arRelateValues['bcc'].input"
          [clearable]="objEmailField['bcc'].clearable"
          [formControlName]="objEmailField['bcc'].key"
          [multiple]="objEmailField['bcc'].multiple"
          [hideSelected]="objEmailField['bcc'].hideSelected"
          [closeOnSelect]="true"
          [maxSelectedItems]="objEmailField['bcc'].maxSelectedItems"
          [hideSelected]="false"
          class="value-text"
          [addTag]="addTag"
          [ngClass]="{'is-invalid': !objEmailValidation.bcc  && bSubmitted && !emailForm.get('bcc').value && is_Empty(emailForm.get('bcc').value)}"
          name="objEmailField['bcc'].key"
          bindLabel="text"
          [selectOnTab]="true">
          <!-- DISPLAY IN SELECTED -->
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label">{{ item.text }} <{{ item.email_address.email }}></span>
            <span class="ng-value-icon right email-selected" (click)="clear(item)" aria-hidden="true">×</span>
          </ng-template>
          <!-- DISPLAY IN OPTION -->
          <ng-template ng-option-tmp let-item="item">
            {{ item.text }}<br />{{ item.email_address.email }}
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="form-group row">
      <label for="subject" class="col-sm-2 col-form-label">{{ 'subject' | translate }}<required-tag></required-tag></label>
      <div class="col-sm-10">
        <fieldmagic-text-input
          id="subject"
          formControlName="subject"
          [customClass]="'form-control input-text'"
          [isValid]="!objEmailValidation.subject  && bSubmitted && is_Empty(emailForm.get('subject').value)">
        </fieldmagic-text-input>
      </div>
    </div>
    <div class="form-group row">
      <label for="attachment" class="col-sm-2 col-form-label">{{ 'attachment' | translate }}</label>
      <div class="col-sm-10">
        <div class="files-container">
          <div *ngFor="let item of arFiles" class="files">
            <fa-icon [icon]="['fas', 'times-circle']" class="bullet" (click)="removeAttachement(item.name)" *ngIf="item.upload_name"></fa-icon>
            <inline-view-loader [isLoading]="! item.upload_name" [hasText]="false"></inline-view-loader>
            <span class="pointer" (click)="onAttachmentPreview(item)" matTooltip="Click to preview">
              {{ item.name }} - {{ item.size | number:'.2' }} kb
            </span>
          </div>
        </div>
        <div ng2FileDrop
          id="fileDrop"
          [ngClass]="{'nv-file-over': hasBaseDropZoneOver, 'attachment-invalid': !objEmailValidation.attachment  && bSubmitted && is_Empty(arFiles)}"
          (fileOver)="fileOverBase($event)"
          [uploader]="uploader"
          (onFileDrop)="onFileSelected($event)"
          class="well my-drop-zone "
          #fileDrop>
          {{ 'drop_files_here' | translate }}
        </div>
      </div>
    </div>
    <quill-editor
      formControlName="body"
      placeholder="Enter Text"
      [modules]="quillConfig"
      [style]="{'height':'220px'}"
      [formats]="arFormats"
      [ngClass]="{'validate-body': !objEmailValidation.body  && bSubmitted && is_Empty(emailForm.get('body').value)}">
    </quill-editor>
  </form>

</ng-container>

<ng-container *ngIf="!bPageLoaded">
    <div class="col-sm-12" align="center">
      <h4><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></h4>
    </div>
</ng-container>
