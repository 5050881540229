import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { trim, toString } from 'lodash-es';
import { blank, filled } from '../../../../utils/common';

@Component({
  selector: 'fieldmagic-multitext-input',
  template: `
    <div class="row">
      <ng-select
        class="col-lg-12 col-md-12 col-xs-12 fieldmagic-multitext"
        [(ngModel)]="values"
        [addTag]="onTagCreation"
        [isOpen]="false"
        [multiple]="true"
        (change)=onChanged($event)
        (focus)="onTouched()"
        bindLabel="0"
        (blur)="onBlur($event.target)"
        (keyup.enter)="onEnter($event.target)"
        [placeholder]="placeholder"
        [class.fieldmagic-input-has-error]="errors | filled"
        [class.fieldmagic-multitext-variant-success]="variant == 'success'"
        [class.fieldmagic-multitext-variant-error]="variant == 'error'"
      >
      </ng-select>
      <div
        fieldmagicGridColumn="12"
      >
        <fieldmagic-input-errors
          *ngIf="errors | filled"
          [errors]="errors">
        </fieldmagic-input-errors>
      </div>
    </div>
`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultitextInputComponent),
      multi: true,
    },
  ],
  styleUrls: [
    './multitext-input.component.scss',
  ],
})
export class MultitextInputComponent implements ControlValueAccessor {
  @Input() placeholder: string;

  @Input() errors: string | string[] = [];

  @Input() variant: 'error' | 'success' | 'default' = 'default';

  @Output('change') $onChanged = new EventEmitter<FieldmagicMultiTextInputOnChangedEvent>();

  values: string[] = [];

  isDisabled: boolean = false;

  // Callback when input is touched
  onTouched = () => { };

  // Callback when input value was changed
  onChanged = (values: string[]) => this.$onChanged.next(values);

  // called tag is added/created
  onTagCreation = (tag: string) => {
    if (toString(tag).trim().length < 1) {
      return;
    }

    this._pushTag(tag);

    /// _pushTag will work on the onChange and trigger the write value for it
    /// no need to tell ng-select about the tag
    return;
  };

  onBlur = (target: HTMLInputElement) => {
    if (filled(target.value)) {
      this._pushTag(target.value);
    }

    target.value = '';
  };

  onEnter = (target: HTMLInputElement) => target.value = '';

  /// calls to register onChanged handler
  registerOnChange = (fn: (values: string[]) => void) => this.onChanged = (values: string[]) => {
    values = this._parseValues(values);

    fn(values);
    this.$onChanged.next(values);
  };

  /// calls to register onTouched handler
  registerOnTouched = (fn: () => void) => this.onTouched = fn;

  setDisabledState = (state: boolean) => this.isDisabled = state;

  /// called when value is being written
  writeValue = (values: string[]) => this.values = this._parseValues(values);

  private _parseValues(values: string[]): string[] {
    const out: string[] = [];

    if (blank(values)) {
      values = [];
    }

    for (let value of values) {
      value = toString(value);

      if (trim(value).length < 1) {
        continue;
      }

      out.push(value);
    }

    return out;
  }

  private _pushTag(tag: string): void {
    const values = [... this.values, tag];

    this.writeValue(values);
    this.onChanged(values);
  }
}

export type FieldmagicMultiTextInputOnChangedEvent = string[];