import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkUpdateDialogComponent } from './bulk-update-dialog/bulk-update-dialog.component';
import { EditModule } from '../../../../modules/edit.module';
import { SharedModule } from '../../../../modules/shared.module';

@NgModule({
  declarations: [
    BulkUpdateDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EditModule,
  ],
  entryComponents: [
    BulkUpdateDialogComponent,
  ],
})
export class ListingDialogModule { }
