import { Component, OnInit, OnDestroy } from "@angular/core";
import { PaginatedResult, Pagination, PaginationStore } from '../../../../shared/store/pagination.store';
import { CustomFieldViewComponent, Form } from '../../../../base/form';
import { tap, switchMap, startWith } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import { Select } from '../../../../objects/select';
import { ApiService } from '../../../../services/api.service';
import { ViewService } from '../../../../services/view.service';
import { filled } from '../../../../shared/utils/common';
import { isNil, get, each } from 'lodash-es';

@Component({
  selector: 'fieldmagic-item-inventory-levels',
  templateUrl: './item-inventory-levels.component.html',
  providers: [
    PaginationStore,
  ],
})
export class ViewItemInventoryLevelsComponent implements OnInit, OnDestroy, CustomFieldViewComponent {
  private _subscriptions: Subscription[] = [];

  /**
   * Contains the warehouse id to be used in the filter
   *
   * @param {string|null} strWarehouseId
   */
  private strWarehouseId: string|null = null;

  get list$(): Subject<InventoryLevel[]> {
    return this._pagination.list$;
  }

  constructor(
    protected readonly _view: ViewService,
    protected readonly _api: ApiService,
    protected readonly _pagination: PaginationStore<InventoryLevel, string>
  ) { }

  ngOnInit(): void {
    this._subscriptions.push(
      this._pagination.onNextPage$.pipe(
        tap(() => this._pagination.loading()),
        switchMap((nextPage) => {
          let payload = new Map<string, any>([
            ['item_id', this._view.getRecord().record_details.id],
          ]);

          if (filled(this.strWarehouseId)) {
            payload = payload.set('warehouse_id', this.strWarehouseId);
          }

          if (!isNil(nextPage)) {
            payload = payload.set('page', nextPage);
          }

          return this._api.callPrivate$<any>({
            path: '/stock_management/stock_levels/item/statistics',
            action: 'post',
            body: payload,
            onSuccessResponse: (response) => new PaginatedResult<InventoryLevel, string>({
              pagination: new Pagination<string>({
                next_page: get(response.get('pagination'), 'next_page'),
              }),
              items: response.get('items'),
            })
          });
        }),
      ).subscribe((paginated) => {
        this._pagination.setCurrent(paginated);
      }),
    );
  }

  ngOnDestroy(): void {
    this._pagination.reset();

    each(this._subscriptions, (subscription) => subscription.unsubscribe());
  }

  setField(_: Form<any>): void { }

  /**
   * Performs actions whenever the warehouse changes.
   *
   * @param {Select|undefined} value
   *
   * @returns {void}
   */
  onWarehouseChange(value?: Select): void {
    if (filled(value)) {
      this.strWarehouseId = value.id;
    } else {
      this.strWarehouseId = null;
    }

    this._pagination.refresh();
  }
}

type InventoryLevel = {
  id: string;
  warehouse_name: string;
  min_stock_level: number;
  min_reoder_level: number;
  in_order: number;
  current_stock_level: number;
}
