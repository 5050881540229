import { Component } from '@angular/core';
import { BehaviorSubject, defer, of } from 'rxjs';
import { FieldmagicDropdownOption } from '../../../../shared/components/forms/input/dropdown/dropdown.component';
import { arr_count, arr_pop, arr_some, arr_wrap, blank, filled, generateId, observe, spf, str_len } from '../../../../shared/utils/common';
import { NotificationService } from '../../../../services/notification.service';
import { filter } from 'rxjs/operators';
import isEmail from 'validator/lib/isEmail';
import { UsersService } from '../../../../services/users.service';
import { DialogService } from '../../../../services/dialog.service';


@Component({
  selector: 'add-users-dialog',
  template: `
    <fieldmagic-dialog
      icon="users"
      title="Add User(s)"
      [instance]="self"
    >
      <ng-template
        fieldmagicDialogButtonsTmp
      >
        <fieldmagic-primary-button
          purpose="save"
          [isInProgress]="saving$ | async"
          (click)="onSave()"
        >
        </fieldmagic-primary-button>
      </ng-template>

      <ng-template
        fieldmagicDialogContentTmp
      >
        <div class="d-flex d-flex-gap-2x flex-column">
          <div class="d-flex d-flex-gap">
            <fieldmagic-checkbox-input
              [(ngModel)]="generatePassword"
              label="Automatically generate password"
            >
            </fieldmagic-checkbox-input>

            <fieldmagic-checkbox-input
              [(ngModel)]="sendWelcomeEmail"
              label="Send welcome email"
            >
            </fieldmagic-checkbox-input>

            <fieldmagic-checkbox-input
              [(ngModel)]="sendWelcomeEmailToInviter"
              label="Send welcome email to me"
            >
            </fieldmagic-checkbox-input>
          </div>

          <table class="table table-bordered">
            <thead>
              <tr>
                <th width="40%">{{ 'email_address' | translate }}</th>
                <th width="33%">{{ 'password' | translate }}</th>
                <th width="23%">{{ 'access_level' | translate }}</th>
                <th width="4%"></th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let user of users; trackBy: userByEmail; let index = index"
              >
                <td>
                  <fieldmagic-text-input
                    [(ngModel)]="user.email_address"
                    [errors]="errors | data_get: ('%s:email_address' | sprintf: [user.id])"
                  >
                  </fieldmagic-text-input>
                </td>
                <td>
                  <fieldmagic-text-input
                    [disabled]="generatePassword"
                    [(ngModel)]="user.password"
                    [errors]="errors | data_get: ('%s:password' | sprintf: [user.id])"
                  >
                  </fieldmagic-text-input>
                </td>
                <td>
                  <fieldmagic-dropdown-input
                    [(ngModel)]="user.level"
                    [options]="levels$ | async"
                    [clearable]="false"
                  >
                  </fieldmagic-dropdown-input>
                </td>
                <td>
                  <div class="d-flex d-flex-gap">
                    <fieldmagic-primary-button
                      [icon]="[{
                        icon: 'plus-circle',
                        klass: 'text-success'
                      }]"
                      purpose="icon"
                      tooltip="Click to add line"
                      (click)="add(index)"
                    >
                    </fieldmagic-primary-button>
                    <fieldmagic-primary-button
                      [icon]="[{
                        icon: 'minus-circle',
                        klass: 'text-danger'
                      }]"
                      purpose="icon"
                      tooltip="Click to remove line"
                      (click)="remove(index)"
                    >
                    </fieldmagic-primary-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </ng-template>

    </fieldmagic-dialog>
  `,
})
export class AddUsersDialogComponent {
  get self(): AddUsersDialogComponent {
    return this;
  }

  generatePassword: boolean = true;
  sendWelcomeEmail: boolean = false;
  sendWelcomeEmailToInviter: boolean = true;

  users: BasicUserDetails[] = [{
    id: generateId(),
    email_address: '',
    level: 'desktop',
  }];

  errors: Record<string, string[]> = {};

  readonly saving$ = new BehaviorSubject<boolean>(false);

  readonly levels$ = new BehaviorSubject<FieldmagicDropdownOption<string>[]>(AVAILABLE_ACCESS_LEVEL_OPTIONS);

  constructor(
    private readonly _notifications: NotificationService,
    private readonly _users: UsersService,
    private readonly _dialog: DialogService,
  ) {}

  userByEmail = (user: BasicUserDetails): string => user.id;

  add(): void {
    if (arr_count(this.users) >= MAX_ALLOWED_USERS) {
      return this._notifications.notifyError(spf('You can only add %d users at a time', {
        args: [MAX_ALLOWED_USERS],
      }));
    }

    this.users.push(this._initDetails());
  }

  remove(pos: number = 0): void {
    if (this.users.length == 1) {
      this.users = [this._initDetails()];

      return;
    }

    this.users = arr_pop(this.users, {
      pos,
    });
  }

  onSave = () => observe({
    before: () => this.saving$.next(true),
    after: () => this.saving$.next(false),
    observable: () => defer(() => {
      if (! this._isValid()) {
        return of(false);
      }

      return this._users.invite(this.users, {
        automatically_generate_password: this.generatePassword,
        send_welcome_email: this.sendWelcomeEmail,
        send_welcome_email_to_inviter: this.sendWelcomeEmailToInviter,
      });
    }),
  })
    .pipe(
      filter((successful) => successful)
    )
    .subscribe((_) => this._dialog.close({
      instance: this,
    }));

  private _initDetails = (): BasicUserDetails => ({
    id: generateId(),
    email_address: '',
    level: 'desktop',
  });

  private _isValid(): boolean {
    let errors: Record<string, string[]> = {};

    for (const user of this.users) {
      const emailAddressError = this._isEmailAddressValid(user);

      if (filled(emailAddressError)) {
        const [errorKey, errorMessage] = emailAddressError;
        errors[errorKey] = [errorMessage];
      }

      const passwordError = this._isPasswordValid(user);

      if (filled(passwordError)) {
        const [errorKey, errorMessage] = passwordError;
        errors[errorKey] = [errorMessage];
      }
    }

    this.errors = errors;

    return blank(errors);
  }

  private _isEmailAddressValid(user: BasicUserDetails): [string?, string?] {
    const errorKey = spf('%s:email_address', {
      args: arr_wrap(user.id),
    });

    if (blank(user.email_address)) {
      return [errorKey, 'This field is required'];
    }

    if (filled(user.email_address) && ! isEmail(user.email_address)) {
      return [errorKey, 'This field does not have a correct email address'];
    }

    if (filled(user.email_address)
        && arr_some(this.users, (other) => other.email_address === user.email_address && user.id !== other.id)
    ) {
      return [errorKey, 'The email address provided has a duplicate.'];
    }

    return [];
  }

  private _isPasswordValid(user: BasicUserDetails): [string?, string?] {
    if (this.generatePassword) {
      return [];
    }

    const errorKey = spf('%s:password', {
      args: arr_wrap(user.id),
    });

    if (blank(user.password)) {
      return [errorKey, 'This field is required'];
    }

    if (str_len(user.password) < MIN_PASSWORD_LENGTH) {
      return [errorKey, spf('This field must have atleast 10 characters')];
    }

    return [];
  }
}

type BasicUserDetails = {
  id: string;
  email_address: string;
  password?: string;
  level: 'admin' | 'desktop' | 'mobile-premium';
}

const AVAILABLE_ACCESS_LEVEL_OPTIONS  = [
  'admin',
  'desktop',
  'mobile-premium',
];

const MAX_ALLOWED_USERS = 10;
const MIN_PASSWORD_LENGTH = 10;