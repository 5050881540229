import {
  attempt,
  get,
  has,
  isArray,
  isBoolean,
  isEmpty,
  isError,
  isNil,
  isNull,
  isNumber,
  isPlainObject,
  isString,
  trim,
} from 'lodash-es';
import { defer, Observable, throwError } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { sprintf } from 'sprintf-js';
import moment from 'moment';
import { singular } from 'pluralize';

/// checks if a value is blank
/// a value is considered blank if it is null, undefined, empty string, empty array, empty object, or NaN
export const blank = <T = any>(value: T): boolean => {
  if (isNil(value) || isNull(value)) {
    return true;
  }

  if (isBoolean(value)) {
    return false;
  }

  if (isString(value)) {
    return trim(value).length < 1;
  }

  if (isNumber(value) && isNaN(value)) {
    return true;
  }

  if ((isArray(value) || isPlainObject(value)) && isEmpty(value)) {
    return true;
  }

  return false;
};

/// check if a value is filled
export const filled = <T = any>(value: T): boolean => !blank<T>(value);

/// retrieve a value from a given source based on the given keys
/// if one of the key has a value and exists in the source, it will return the value
/// otherwise it will return the default value if provided or null
export const either = <R = any>(source: any, keys: string[], opts: {
  default_value?: R,
} = {}): R => {
  opts = Object.assign({
    default_value: null,
  }, opts);

  for (const key of keys) {
    const value = get(source, key);

    if (filled(value)) {
      return value;
    }
  }

  return opts.default_value;
}

// retrieve a result when a condition is met. works like a ternary operator
export const when = <R = any>(condition: boolean, opts: {
  then?: () => R,
  else?: () => R,
} = {}): R => {
  opts = Object.assign({
    then: () => null,
    else: () => null,
  }, opts);

  if (condition) {
    return opts.then();
  }

  return opts.else();
}

/// retrieve a result when a value is blank. works like a ternary operator
export const whenBlank = <R = any, T = any>(value: T, opts: {
  then?: () => R,
  else?: () => R,
} = {}) => when(blank(value), opts);

/// retrieve a result when value is filled. works like a ternary operator
export const whenFilled = <R = any, T = any>(value: T, opts: {
  then?: () => R,
  else?: () => R,
} = {}) => when(filled(value), opts);

// returns the value if it is filled, otherwise it will return the fallback value
export const fallback = <R = any>(value: any, opts: {
  fallback: () => R,
}): R => whenFilled(value, {
  then: () => value,
  else: () => opts.fallback(),
});

/// transform a value based on the given transformation function
export const transform = <T = any, R = any>(value: T, opts: {
  transformer: (value: T) => R,
  default_value?: R,
}): R => {
  if (blank(value)) {
    return opts.default_value;
  }

  return opts.transformer(value);
}

/// checks if a value is in the list using a comparator function
export const isIn = <T = any>(list: T[], opts: {
  comparator: (item: T, pos: number) => boolean,
}): boolean => {
  for (const index in list) {
    if (opts.comparator(list[index], parseInt(index))) {
      return true;
    }
  }

  return false;
}

/// checks if a value is not in the list. works like the opposite of isIn
export const isNotIn = <T = any>(list: T[], opts: {
  comparator: (item: T, pos: number) => boolean,
}) => !isIn(list, opts);

/// check if a given value is a valid system id. it uses the uuid library to validate the id
export const isId = (value: any): boolean => filled(value) && _validateId(value);

// regex to validate uuid
const _validateId = (value: string): boolean => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(value);

export const data_get = <T extends object, R = any, D = any>(data: T, path: string|string[]|keyof T, opts: {
  default_value?: OptionalValue<D>,
} = {}) : OptionalValue<R> | OptionalValue<D> => {
  opts = Object.assign({
    default_value: null,
  }, opts);

  return get(data, path, opts.default_value);
}

/// a type that can be null or undefined
export type OptionalValue<T> = T | null | undefined;

/// formats a value into a given datetime format
export const format_datetime = (value: any, opts: {
  format: string,
}): string | null => {
  const parsed = moment(value);

  if (! parsed.isValid) {
    return null;
  }

  return parsed.format(opts.format);
}

export const ids_only = (values: any[], using: string = 'id'): string[] => {
  if (blank(values)) {
    return [];
  }

  const output = [];

  for (const value of values) {
    const extracted = data_get(value, using);

    if (blank(extracted) || ! isId(extracted)) {
      continue;
    }

    output.push(extracted);
  }

  return output;
}

/// generate a unique uuid used across the system
export const generateId = () => uuid();

/// pollyfil typescripts Omit Type
/// A type that constructs a new object without the given key(s)
export type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

/// observes a given observable factory which gives you the ability
/// to perform actions before/after the observable executes
export const observe = <R = any>(opts: {
  observable: () => Observable<R>,
  before?: () => void,
  after?: () => void,
  onError?: (err) => void,
}): Observable<R> => defer(() => {
  if (! isNil(opts.before)) {
    opts.before();
  }

  return opts.observable();
}).pipe(
  take(1),
  finalize(() => {
    if (isNil(opts.after)) {
      return;
    }

    return opts.after();
  }),
  catchError((err) => {
    if (! isNil(opts.onError)) {
      opts.onError(err);
    }

    return throwError(err);
  }),
);

export const filter_array = <T, R extends T>(collection: T[], predicate:  (value: T, index: number) => any): R[] =>
  collection.filter((value, index) => predicate(value, index)) as R[];

export const safely_parse_json = <R = any>(json: any, opts: {
  fallback?: () => R,
} = {}): R | null => {
  opts = Object.assign({
    fallback: () => null,
  }, opts);

  const parsed = attempt((value) => JSON.parse(value), json);

  if (! isError(parsed) && filled(parsed)) {
    return parsed;
  }

  return opts.fallback();
}

export const spf = (format: string, opts: {
  args?: any[],
} = {}): string => sprintf(format, ...opts.args);

export const safely_encode_to_json = (value: any): string | null => {
  if (isString(value)) {
    value = safely_parse_json(value);
  }

  if (blank(value)) {
    return null;
  }

  const encoded = attempt((value) => JSON.stringify(value), value);

  if (isError(encoded)) {
    return null;
  }

  return encoded;
}

export const data_has = <T = any>(value: T, path: string | string[] | keyof T): boolean => has(value, path);

export const arr_count = (value: any): number => {
  if (blank(value) || ! isArray(value)) {
    return 0;
  }

  return value.length;
}

export const str_split = (value: any, args: {
  delimiter: string,
}): string[] => {
  if (isArray(value)) {
    return value;
  }

  if (blank(value) || ! isString(value)) {
    return [];
  }

  return value.split(args.delimiter);
}

export const arr_wrap = <R = any>(value: any): R[] => {
  if (isArray(value)) {
    return value;
  }

  if (blank(value)) {
    return [];
  }

  return [value];
}

export const arr_filter = <T = any>(values: T[], args: {
  predicate: (value: T, index: number) => boolean;
}) : T[] => {
  if (! isArray(values)) {
    return [];
  }

  return values.filter((value, index) => args.predicate(value, index));
}

export const arr_pop = <T = any>(values: T[], args: {
  pos?: number
} = {}): T[] => {
  if (blank(values) || ! isArray(values)) {
    return [];
  }

  args = Object.assign({
    pos: values.length - 1
  }, args);

  if (args.pos < 0) {
    args = Object.assign(args, {
      pos: 0,
    });
  }

  if (args.pos > values.length) {
    return arr_pop(values, {
      pos: values.length - 1,
    });
  }

  /// create a surface copy of list
  const copied = [... values];

  copied.splice(args.pos, 1);

  return copied;
}

export const str_len = <T = any>(value: T): number => {
  if (blank(value) || ! isString(value)) {
    return 0;
  }

  return value.length;
}

export const arr_some = <T = any>(values: T[], predicate: (value: T, index: number) => boolean): boolean => {
  if (blank(values) || ! isArray(values)) {
    return false;
  }

  const filtered = arr_filter(values, {
    predicate: predicate,
  });

  return filled(filtered);
}

export const str_replace = <T = string>(value: T, opts: {
  pattern: string | RegExp,
  with: () => string,
}): string|null  => {
  if (blank(value) || ! isString(value)) {
    return null;
  }

  return value.replace(opts.pattern, opts.with());
}

export const str_snake_case = <T = string>(value: T, opts: {
  lowercase?: boolean,
} = {}): string | null => {
  opts = Object.assign({
    lowercase: false,
  }, opts);

  const snaked = str_replace(value, {
    pattern: /\s/g,
    with: () => '_'
  });

  if (opts.lowercase && filled(snaked)) {
    return snaked.toLowerCase();
  }

  return snaked;
}

export const arr_map = <R = any, D = any>(values: D[], opts: {
  transformer: (values: D, index: number) => R,
}) => {
  let output: R[] = [];

  for (let index = 0; index < values.length; index++) {
    output.push(opts.transformer(values[index], index));
  }

  return output;
}

export const is_url = (value): boolean => {
  if (blank(value)) {
    return false;
  }

  if (! isString(value)) {
    return false;
  }

  return /^https:\/\/[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)+[a-zA-Z0-9-_,&;=?./]+$/
    .test(value);
};

export const is_value_true = (value: any): boolean => {
  if (isBoolean(value)) {
    return value;
  }

  if (isString(value)
    && (
      value == '1'
      || value.toLocaleLowerCase() == 'true'
    )
  ) {
    return true;
  }

  if (isNumber(value) && value != 0) {
    return true;
  }

  return false;
}

export const is_value_false = (value: any): boolean => ! is_value_true(value);

export const str_singular = (value: any): OptionalValue<string> => {
  if (blank(value) || ! isString(value)) {
    return null;
  }

  return singular(value);
}