import { Pipe, PipeTransform } from '@angular/core';
import { ClientStoreService } from '../services/client-store.service';
import { Observable } from 'rxjs';
import { RoleService } from '../services/role.service';
import { map } from 'rxjs/operators';
import { blank } from '../shared/utils/common';

@Pipe({
  name: 'has_allow_permission',
  pure: false,
})
export class HasAllowPermissionPipe implements PipeTransform {
  constructor(
    private readonly _clients: ClientStoreService,
    private readonly _roles: RoleService,
  ) {}

  transform = (action: string): Observable<boolean> => this._clients.getWhenClientIsSetEvent().pipe(
    map((client) => {
      if (blank(client)) {
        return false;
      }

      return this._roles.hasPermission(action, {
        client,
      });
    })
  );
}

@Pipe({
  name: 'has_denied_permission',
  pure: false,
})
export class HasDeniedPermissionPipe implements PipeTransform {
  constructor(
    private readonly _clients: ClientStoreService,
    private readonly _roles: RoleService,
  ) {}

  transform = (action: string): Observable<boolean> => this._clients.getWhenClientIsSetEvent().pipe(
    map((client) => {
      if (blank(client)) {
        return true;
      }

      return ! this._roles.hasPermission(action, {
        client,
      });
    })
  );
}