import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { StrService } from '../../../../../services/helpers/str.service';

type OnChangeHandler = (value?: string) => void;
type OnTouchedHandler = () => void;

/**
 * @deprecated please use NewTextInputComponent
 */
@Component({
  selector: 'fc-text-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    }
  ],
  template: `
    <input
      type="text"
      class="{{ customClass }}"
      maxlength="{{ maxlength }}"
      placeholder="{{ placeholder | translate }}"
      [(ngModel)]="value"
      [ngClass]="{'is-invalid': isValid}"
      (change)="onChange($event.target.value)"
      (keypress)="onKeyPressInput($event)"
    />
  `,
  styleUrls: ['./text.component.scss']
})
export class TextInputComponent implements ControlValueAccessor {

  constructor(
    private strService: StrService
  ) { }

  /**
   * Dictates if the field should be disabled
   */
  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  /**
   * Contains the value inputted
   */
  value: string;

  /**
   * apply custom class from parent component
   */
  @Input() customClass: string;

  /**
   * determine if field is readonly
   */
  @Input() isReadonly: boolean = false;

  /**
   * determine if field is required, so we can add red border
   */
  @Input() isValid: boolean = false;

  /**
   * determine the max length
   */
  @Input() maxlength: string = null;

  /**
   * placeholder for input
   */
  @Input() placeholder: string = null;

  /**
   * Callback when input is touched
   */
  onTouched: OnTouchedHandler = () => {};

  /**
   * Callback when input value was changed
   */
  onChange: OnChangeHandler = () => {};

  /**
   * @inheritdoc
   */
  registerOnChange(fn: OnChangeHandler): void {
    this.onChange = (value?: string) => {
      fn(
        this.value = value
      );
    };
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: OnTouchedHandler): void {
    this.onTouched = fn;
  }

  /**
   * {@inheritdoc}
   */
  setDisabledState(disabled: boolean): void {
    this.isDisabled$.next(disabled);
  }

  /**
   * @inheritdoc
   */
  writeValue(value?: string): void {
    this.value = value
  }

  /**
   * prevent from typing non-alphanumeric characters from the text inputs
   */
  public onKeyPressInput(event: KeyboardEvent) {
    if (this.strService.hasInvalidCharacters(event.key)) {
      event.preventDefault();
    }
  }
}

@Component({
  selector: 'fieldmagic-text-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewTextInputComponent),
      multi: true,
    }
  ],
  template: `
    <ng-container
      *ngIf="! standalone; else input;"
    >
      <div class="form-group">
        <div class="d-flex d-flex-gap">
          <fieldmagic-text
            purpose="input"
            [content]="label"
            [withRequiredMarker]="withRequiredMarker"
          >
          </fieldmagic-text>

          <fieldmagic-icon
            *ngIf="tooltip | filled"
            icon="info-circle"
            [tooltip]="tooltip"
          >
          </fieldmagic-icon>
        </div>

        <ng-container *ngTemplateOutlet="input"></ng-container>
      </div>

    </ng-container>

    <ng-template #input>
      <div class="input-group">
        <input
          [attr.type]="inputType"
          class="form-control input-height-40 font-size-12"
          [class.fieldmagic-input-has-error]="errors | filled"
          [ngClass]="customClass"
          maxlength="{{ maxlength }}"
          placeholder="{{ placeholder | translate }}"
          [(ngModel)]="value"
          [class.isinvalid]="! isValid"
          (change)="onChange($event.target.value)"
          (keypress)="onKeyPressInput($event)"
          [disabled]="isDisabled$ | async"
        />
        <div
          *ngIf="obsecure"
          class="input-group-append"
          (click)="onShowObsecure()"
          [matTooltip]="(showObsecure$ | async) ? 'Hide' : 'Show'"
        >
          <fieldmagic-icon
            class="input-group-text"
            [icon]="(showObsecure$ | async) ? 'eye-slash' : 'eye'"
            purpose="clicking"
          >
          </fieldmagic-icon>
        </div>
      </div>

      <fieldmagic-input-errors
        *ngIf="errors | filled"
        [errors]="errors"
      >
      </fieldmagic-input-errors>
    </ng-template>
  `,
})
export class NewTextInputComponent extends TextInputComponent {
  @Input() standalone: boolean = true;

  @Input() label: string;

  @Input() withRequiredMarker: boolean = false;

  @Input() errors: string[] = [];

  @Input() obsecure: boolean = false;

  @Input() tooltip?: string;

  readonly showObsecure$ = new BehaviorSubject<boolean>(false);

  get inputType(): 'text' | 'password' {
    if (this.obsecure && ! this.showObsecure$.getValue()) {
      return 'password';
    }

    return 'text';
  }

  onShowObsecure = () => this.showObsecure$.next(
    ! this.showObsecure$.getValue()
  );
}
