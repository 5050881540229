import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ListingService } from '../../../../services/listing.service';
import { filter, finalize } from 'rxjs/operators';
import { TagDisplay, TagRecordDisplay } from '../../objects/tag-display';
import { RecordService } from '../../../../services/record.service';
import { StatusCode } from '../../../../lists/status-code';
import { NotificationService } from '../../../../services/notification.service';
import { RecordTag } from '../../objects/record-tag';

@Component({
  selector: 'tag-records',
  templateUrl: './tag-records.component.html'
})
export class TagRecordsComponent implements OnInit {

  /**
   * List of tags to display for tagging a record.
   *
   * @var {TagRecordDisplay[]}
   */
  arTags: TagRecordDisplay[] = [];

  /**
   * When a screen is loading.
   *
   * @var {booleam}
   */
  bLoading: boolean = false;

  /**
   * Current module of this tag.
   *
   * @var {string}
   */
  strModule: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<TagRecordsComponent>,
    public list: ListingService,
    public record: RecordService,
    public notify: NotificationService
  ) {
    this.strModule = this.data['module'];
  }

  ngOnInit() {
    this.bLoading = true;
    this.list.fetchData(null, 'tags', JSON.stringify({module: this.strModule}), 100)
    .pipe(
      filter(response => { return response && response['data'] }),
      finalize(() => {
        this.bLoading = false;
      })
    )
    .subscribe(response => {
      this.arTags = response['data'].map(item => {
        return new TagRecordDisplay(item);
      });
    })
  }

  /**
   * Close the dialog form.
   *
   * @returns {void}
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Clears the tags and updates it on the API.
   *
   * @returns {void}
   */
  clearTags() {
    this.notify.sendConfirmation('clear_tags_confirm').subscribe(response => {
      if (response.answer == true) {
        let arSaveData = this.data['records'].map(response => {
          return new RecordTag(response['id'], []);
        });

        this.record.saveMultipleRecord(this.data['module'], arSaveData)
        .pipe(
          filter(response => {
            return response.status === StatusCode.kResponseSuccess;
          }),
        )
        .subscribe(response => {
          this.notify.notifySuccess('success');
          this.dialogRef.close({tagged: true});
        })
      }
    });
  }

  /**
   * Add the selected tags to the given records.
   *
   *  @returns {void}
   */
  saveTags() {

    let arTagIds = this.arTags
      .filter(response => {
        return response['include'];
      })
      .map(response => {
        return new TagDisplay(response);
      });

    let arSaveData = this.data['records'].map(response => {
      return new RecordTag(response['id'], arTagIds);
    });

    this.record.saveMultipleRecord(this.data['module'], arSaveData)
    .pipe(
      filter(response => {
        return response.status === StatusCode.kResponseSuccess;
      }),
    )
    .subscribe(response => {
      this.notify.notifySuccess('success');
      this.dialogRef.close({tagged: true});
    })
  }

  /**
   * Set the tag as to be included in the record.
   *
   * @param {TagRecordDisplay} objTag
   */
  setIncluded(objTag: TagRecordDisplay) {
    objTag.include = !objTag.include;
  }

}
