import { Component, OnInit, Inject, HostListener, Input, Output, EventEmitter, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { switchMap, finalize, filter, map } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { forkJoin, iif, Observable, of, Subscription } from 'rxjs';
import { cloneDeep, get, isEmpty, map as _map, tap, omit, isEqual, set, isUndefined } from 'lodash-es';

import { Select } from '../../../../../objects/select'
import { QuoteLineItem } from '../../../../../objects/quote-line-item';
import { QuoteLineGroup } from '../../../../../objects/quote-line-group';
import { TaxCodeInterface } from '../../../../../objects/tax-code';
import { RecordService } from '../../../../../services/record.service';
import { NotificationService } from '../../../../../services/notification.service';
import { Relate } from '../../../../../objects/relate';
import { EditComponent } from '../../../editform/edit/edit.component';
import { tax, toFormattedNumber } from '../../../../utils/numbers';
import { blank, data_get, fallback, filled, is_value_true, isId, transform, when, whenFilled } from '../../../../utils/common';
import { RelatedProductsComponent } from '../../../../../features/product-folders/static-folders/related-products/related-products.component';
import { Job } from '../../../../../objects/job';
import { FormService } from '../../../../../services/form.service';
import { LooseObject } from '../../../../../objects/loose-object';
import { SetUnsavedChangesData } from '../../../../../objects/auto-save';
import { ContextMenuComponent } from '../../../context-menu/context-menu.component';
import { ContextMenuService } from '../../../../../services/context-menu.service';
import { ClientStoreService } from '../../../../../services/client-store.service';
import { EditformComponent } from '../../../editform/editform.component';
import { FormPopup } from '../../../../../objects/centralized-forms/form-popup';
import { ViewSupplierInventoryComponent } from '../../../../../admin/items/view-supplier-inventory/view-supplier-inventory.component';
import { Form, FormMode } from '../../../../../base/form';

@Component({
  selector: 'app-jobs-work-order-items',
  templateUrl: './work-order-items.component.html',
  styleUrls: ['./work-order-items.component.scss']
})
export class WorkOrderItemsComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(ContextMenuComponent) contextMenuComponent: ContextMenuComponent;
  /// used as data reference when this component is used as a child component
  @Input() initialValue: WorkOrderLineData[];

  @Input() defaultTaxCode: TaxCodeInterface;

  @Input() defaultPricebook: WorkOrderDefaultPricebook;

  private _valueFromParent: ParentValue;
  // Event to handle the value from parent
  @Input() set valueFromParent(value: ParentValue) {
    this._valueFromParent = value;
    this.doSomethingFromParent(this._valueFromParent);
  }

  get valueFromParent(): ParentValue {
    return this._valueFromParent;
  }

  @Input() strModule: string = '';
  @Output() parentEvent = new EventEmitter<WorkOrderToParentEvent>();
  @ViewChild(EditComponent, {read: false}) selectPricebook: EditComponent;

  /// emitted when a line item is updated
  @Output('updated') $onUpdate = new EventEmitter<OnWorkOrderLinesUpdate>();

  /// indicates that this component is used as a child component
  @Input() asChild: boolean = false;

  /// indicates that this component is validated via parent component
  /// only use this as a reference when parent component is the one
  /// who perform the validation
  @Input() parentWasValidated: boolean = false;

  public arOldWorkOrderItems: Array<{}> = [];
  public arLineItems: WorkOrderLine[] = [];
  public objJobRecord: object = {};

  public arDepartmentList: Array<{}> = [];
  public arTaxCodeList: Array<{}> = [];
  public bLoading: boolean = true;
  public bSubmit: boolean = false;
  public bEdited: boolean = false;
  public bErrors: boolean = false;
  public bPageLoaded: boolean = false;

  public bDepartmentTracking: boolean = false;

  public objFormGroup: FormGroup;

  public objPricebookRelateField: object = {
    key: 'pricebook_id',
    label: '',
    controlType: 'relate',
    default_value: '',
    required: false,
    module: 'pricebooks',
    validator: []
  };

  /**
   * To identify if we save the record or return it to parent component
   */
  public bSave: boolean = true;

  public isAutoSave: boolean = false;
  public isFromUnsavedChanges: boolean = false;
  public autoSaveIntervalId;
  public relateChanges: LooseObject = {};
  public relateFields: Array<string> = [];
  public selectedLineItems: Array<LooseObject> = [];

   /**
   * The parent form group where the field belongs to
   *
   * @type {FormGroup}
   */
   parentForm: FormGroup;

  lastFocusedLineItemIndex: number = -1;

  /**
   * list tax code observable for tax relate field
   */
  objTaxCodeObv: { [key: string]: Relate<Select[]> } = {};

  /**
   * Counts the number of QuoteLineItem instances
   * in the arLineItems list because the arLineItems may
   * contain a QuoteGroupItem instance.
   */
  get countLineItems(): number {
    return this.arLineItems.filter(item => (item instanceof QuoteLineItem)).length;
  }

  /**
   * Compute total amount without tax
   *
   * @returns {number}
   */
  get intAmountWithoutTax(): number {
    let numTotal = 0;
    this.arLineItems.filter(item => (! item.isGroup())).forEach(
      (objLineItem) => {
        numTotal = numTotal + toFormattedNumber(get(objLineItem, 'line_item'), {
          currency: true,
        });
      }
    );

    return toFormattedNumber(numTotal, {
      currency: true,
    });
  }

  /**
   * Compute total tax
   *
   * @returns {number}
   */
  get intTax(): number {
    let numTotal = 0;
    this.arLineItems.filter(item => (! item.isGroup())).forEach(
      (objLineItem) => {
        if (!isUndefined(objLineItem['tax_rate']) && parseFloat(objLineItem['tax_rate']) > 0) {
          numTotal += tax(get(objLineItem, 'tax_rate', 0), {
            price: get(objLineItem, 'line_item'),
          });
        }
      }
    );

    return toFormattedNumber(numTotal, {
      currency: true,
    });
  }

  /**
   * Compute total amount with tax
   *
   * @returns {number}
   */
  get intAmountWithTax(): number {
    return toFormattedNumber(this.intAmountWithoutTax + this.intTax, {
      currency: true,
    });
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  @Input() config: WorkOrderItemsComponentConfig;

  readonly _subscriptions: Subscription[] = [];
  _pricebookFieldListener: Subscription;

  private get _isDiscountedPriceEnabled(): boolean {
    return is_value_true(data_get(this._client.getActiveClient(), 'config.enable_discounts'));
  }

  constructor(
    public contextMenuService: ContextMenuService,
    private recordService: RecordService,
    private notifService: NotificationService,
    private dialogRef: MatDialogRef<WorkOrderItemsComponent>,
    private dialog: MatDialog,
    private formService: FormService,
    private readonly _client: ClientStoreService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    let dataChanges = this.formService.getUnsavedChangesData(get(this.data, ['record_details', 'id'], ''), 'work_orders', this.data['record_details'], get(this.data, ['record_details', 'id']));

    if (!isEmpty(this.data['record_details']) || dataChanges.length > 0) {
      this.initializeData();

      if (dataChanges.length > 0 && filled(this.data['auto_save'])) {
        this.notifService.sendConfirmation('do_you_want_to_apply_unsaved_changes', 'unsaved_changes')
        .subscribe((confirmation) => {
          if (confirmation.answer) {
            this.bPageLoaded = true;
            dataChanges.forEach(data => {
              let path = data['path'];
              let value = data['value1'];
              set(this.data['record_details'], path, value);
            });
            this.initializeComponent();
          } else {
            this.bPageLoaded = true;
            this.initializeComponent();
            this.formService.removeUnsavedChangesDataToLocalStorage(get(this.data, ['record_details', 'id'], ''), 'work_orders', this.objJobRecord['id']);
          }
        });
      } else if(filled(this.data['auto_save'])) {
        this.bPageLoaded = true;
        this.initializeComponent();
      }
    } else {
      this.objFormGroup = new FormGroup({
        pricebook_id: new FormControl()
      });
      this.bPageLoaded = true;
    }
  }

  /**
   * @inheritdoc
   */
  ngOnInit() {
    this.bDepartmentTracking = this._client.isDepartmentTracking();
    if (filled(this.data['auto_save'])) {
      this.triggerAutoSave();
    } else {
      this.initializeComponent();
      this.bPageLoaded = true;
    }

    if (this.objJobRecord && this.parentForm) {
      this.asChild = true;
    }

    if (blank(this._pricebookFieldListener)) {
      this._pricebookFieldListener = this.objFormGroup.controls['pricebook_id'].valueChanges.subscribe((selected) => this.$onUpdate.emit({
          pricebook_id: selected,
          work_order_items: this.arLineItems,
          work_order_item: this.arLineItems[this.arLineItems.length - 1],
          pos: this.arLineItems.length - 1,
          mode: 'update',
      }));
    }

    if (blank(this.defaultTaxCode)) {
      this._subscriptions.push(
        this.recordService.getConfiguredDefaultRelates({
          includes: [
            'default_sales_tax',
          ]
        }).subscribe((relates) => {
          this.defaultTaxCode = get(relates, 'default_sales_tax')
        }),
      );
    }
  }

  /// see: OnDestroy::ngOnDestroy
  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    if (filled( this._pricebookFieldListener)) {
      this._pricebookFieldListener.unsubscribe()
    }

    this.formService.removeAutoSaveInterval(this.autoSaveIntervalId);
  }

  /// see: OnChanges::ngOnChanges
  ngOnChanges(changes: SimpleChanges): void {
    const defaultTaxCode = get(changes, 'defaultTaxCode');

    /// patches the current default tax code to the component
    if (filled(defaultTaxCode) && ! isEqual(defaultTaxCode.currentValue, defaultTaxCode.previousValue)) {
      this.defaultTaxCode = defaultTaxCode.currentValue;
    }

    const defaultPricebook = get(changes, 'defaultPricebook');

    /// patches the current default pricebook to the component
    if (filled(defaultPricebook) && ! isEqual(defaultPricebook.currentValue, defaultPricebook.previousValue)) {
      this.defaultPricebook = defaultPricebook.currentValue;

      /// patch pricebook metadata
      this.objPricebookRelateField = {
        ... this.objPricebookRelateField,
        default_value: get(this.defaultPricebook, 'id'),
        options: transform(this.defaultPricebook, {
          transformer: (pricebook) => [new Select(pricebook.id, pricebook.text)],
          default_value: [],
        }),
      }

      this.objFormGroup.patchValue({
        pricebook_id: get(this.defaultPricebook, 'id'),
      });
    }

    const initialValue = get(changes, 'initialValue');

    /// when changes are detected in the initial value
    /// we initialize the lines again
    /// this is necessary to recapture things from the parent
    /// when this component is used as a child
    if (filled(initialValue) && ! isEqual(initialValue.currentValue, initialValue.previousValue)) {
      this._initLines();
    }
  }

  initializeData() {
    this.objJobRecord = this.data["record_details"];
    this.defaultTaxCode = this.data["related_data"] ? this.data["related_data"]["default_tax_code_sale"] : this.defaultTaxCode;
    this.arOldWorkOrderItems = cloneDeep(this.data["record_details"]["work_order_items"]);
    this.bSave = (this.data['is_save'] != undefined) ? this.data['is_save'] : this.bSave;

    if (this.objJobRecord["pricebook_id"] && this.objJobRecord["pricebook_text"]) {
      var objPricebookOption = new Select(this.objJobRecord["pricebook_id"], this.objJobRecord["pricebook_text"]);
      this.objPricebookRelateField["options"] = [ objPricebookOption ];
    }

    this.objFormGroup = new FormGroup({
      pricebook_id: new FormControl(
        whenFilled(get(this.objJobRecord, 'pricebook_id'), {
          then: () => get(this.objJobRecord, 'pricebook_id'),
        }),
      ),
    });
  }

  initializeComponent() {
    /// only perform initialization if this component is not used as a child component
    /// ngOnChanges takes care of the initialization when particular props where changed
    if (! this.asChild) {
      this._initLines();
    }

    /// listen to close event this component is not used as a sub component
    if (! this.asChild) {
      this.dialogRef.backdropClick().subscribe(_ => {
        this.cancelDialog();
      });
    }

    if (this.asChild && filled(this.defaultPricebook)) {
      this.objFormGroup.patchValue({
        pricebook_id: get(this.defaultPricebook, 'id'),
      });
    }
  }

  getPricebookText(pricebookText) {
    this.relateChanges['pricebook_id'] = pricebookText;
    this.markAsEdited();
  }


  useMarkupOrDiscount(objCurrentValue, line = null): number {
    const unitValue = get(objCurrentValue, 'pricing_method') === 'fixed_discount' || get(objCurrentValue, 'apply_to_all_items') ?
    (line['master_unit_price'] ? line['master_unit_price'] : line['discounted_price']) : line['unit_price'];
    let discount = get(objCurrentValue, 'default_discount');

    if (discount > 0) {
      const newPrice = this.getDiscountedPrice(unitValue, discount);
      let unitCost = line['labor'] ? line['hourly_cost'] : line['unit_cost'];
      return toFormattedNumber(((newPrice - unitCost) / unitCost * 100), {maxDecimalPlaces: 4});
    } else {
      return get(objCurrentValue, 'default_markup', 0);
    }
  }

  getDiscountedPrice(unitCost, markUp) {
    return toFormattedNumber(unitCost - (unitCost * (parseFloat(markUp) / 100)), {currency: true, maxDecimalPlaces: 4});
  }

  getMarkedupPrice(unitCost, markUp) {
    return toFormattedNumber(unitCost + ( unitCost * (parseFloat(markUp) / 100)), {currency: true, maxDecimalPlaces: 4});
  }

  setLineItemMarkup(objCurrentValue) {
    objCurrentValue = objCurrentValue['extras'] ? objCurrentValue['extras'] : objCurrentValue;
    if (filled(this.arLineItems)) {
    this.notifService.sendConfirmation('confirm_pricebook_pricing_to_products').filter(confirmation => confirmation.answer === true)
    .subscribe(() => {
      this.arLineItems.forEach((line_item, index) => {
        let newMarkup = this.useMarkupOrDiscount(objCurrentValue, this.arLineItems[index]);
        if (objCurrentValue['apply_to_all_items']) {
          line_item['markup'] = newMarkup;

          this._assignLinePricing(line_item as QuoteLineItem, {
            discounted_price: this.computePriceWithMarkup(this.arLineItems[index], objCurrentValue),
            original_price: fallback(data_get(line_item, 'unit_price'), {
              fallback: () => 0,
            })
          })

        } else {
          const matchingPricebookItem = objCurrentValue['pricebook_items'].find((pricebook_item) => pricebook_item.item_id === line_item['item_id']);
          line_item['markup'] = matchingPricebookItem ? newMarkup : line_item['markup'];

          this._assignLinePricing(line_item as QuoteLineItem, {
            discounted_price: fallback(data_get(matchingPricebookItem, 'pricebook_unit_price'), {
              fallback: () => fallback(data_get(line_item, 'discounted_price'), {
                fallback: () => 0,
              })
            }),
            original_price: fallback(data_get(line_item, 'unit_price'), {
              fallback: () => 0,
            })
          });
        }
        });

      });
    }
  }

  computePriceWithMarkup(line, currentValue) {
    const unitValue = get(currentValue, 'apply_to_all_items') ? (get(currentValue, 'default_markup') > 0 ?
                      line['unit_cost'] : line['master_unit_price']) :
                      (get(currentValue, 'pricing_method') != 'fixed_discount' ? line['unit_cost'] : line['unit_price']);

    let unitCost = line['labor'] ? parseFloat(line['hourly_cost']) : parseFloat(unitValue) || parseFloat(line['discounted_price']);

    if (typeof currentValue == 'string') {
      return this.getMarkedupPrice(unitCost, currentValue);
    } else {
      if (get(currentValue, 'default_discount') > 0) {
        return this.getDiscountedPrice(unitCost, get(currentValue, 'default_discount'));
      } else {
        return this.getMarkedupPrice(unitCost, get(currentValue, 'default_markup', 0));
      }
    }
  }

  /**
   * Cancel dialog
   *
   * @param {boolean}
   *
   * @returns {void}
   */
  cancelDialog(response: boolean | object = false): void {
    if (!response && this.bEdited) {
      if (this.bEdited) {
        this.notifService.sendConfirmation('confirm_cancel').filter(confirmation => confirmation.answer === true)
          .subscribe(() => {
            this.dialogRef.close(response);
          });
      } else {
        this.dialogRef.close(response);
      }
    } else {
      this.dialogRef.close(response);
    }
  }

  /**
   * initialize relate fields with typhead
   *
   * @param {QuoteLineItem}
   *
   * @returns {QuoteLineItem}
   */


  /**
   * Set the default value of the observable and
   * update the typehead to use the given API
   * to search for products.
   *
   * @param {QuoteLineItem} objWorkOrderItems
   *
   * @returns {QuoteLineItem}
   */
  initializeItemTypeheadAndList(objWorkOrderItems: QuoteLineItem): QuoteLineItem {

    let itemRelate = new Relate<Select[]>();
    let arItemDefault = [];
    if (objWorkOrderItems.item_id) {
      arItemDefault.push(new Select(objWorkOrderItems.item_id, objWorkOrderItems.item_name))
    }

    itemRelate.buildRelates(
      switchMap( strTerm => this.recordService.getProducts({
        term: strTerm,
        filter: {
          active: true,
        },
        sales_only: true,
        pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      })),
      arItemDefault,
      filled(arItemDefault)
    );

    objWorkOrderItems.obv = itemRelate.source as Observable<Select[]>;
    objWorkOrderItems.typehead = itemRelate.typehead;
    objWorkOrderItems.loader = itemRelate.loader;

    return objWorkOrderItems;
  }

  /**
   * Set the default value of the observable and
   * update the typehead to use the given API to search tax code
   *
   * @param {QuoteLineItem} objWorkOrderItems
   */
  initializeTaxCodeTypeheadAndList(objWorkOrderItems: QuoteLineItem): void {

    this.objTaxCodeObv[objWorkOrderItems.id] = new Relate<Select[]>();

    let arTaxCode = [];
    if (objWorkOrderItems.tax_code_id) {
      arTaxCode.push(new Select(objWorkOrderItems.tax_code_id, objWorkOrderItems.tax_code_name))
    }

    this.objTaxCodeObv[objWorkOrderItems.id].buildRelates(
      switchMap(strTerm => this.recordService.getRecordRelate('tax_codes', strTerm, [], false, { is_sales: true })),
      arTaxCode,
      filled(arTaxCode)
    );
  }

  onLineDescriptionChange(index: number, line: WorkOrderLine): void {
    if (index > this.arLineItems.length) {
      return;
    }

    this.arLineItems[index] = line;
    this.markAsEdited();

    this.$onUpdate.emit({
      pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      work_order_items: this.arLineItems,
      work_order_item: line,
      pos: index,
      mode: 'update',
    });
  }

  /// called when a line department is changed
  onLineDepartmentChange(index: number, id: string, line: WorkOrderLine): void {
    if (index > this.arLineItems.length || ! (line instanceof QuoteLineItem)) {
      return;
    }

    const department = this.arDepartmentList.find(department => get(department, 'id') === id);

    line.department_id = id;
    line.department_name = get(department, 'text');

    this.arLineItems[index] = line;
    this.markAsEdited();

    this.$onUpdate.emit({
      pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      work_order_items: this.arLineItems,
      work_order_item: line,
      pos: index,
      mode: 'update'
    });
  }

  onLineQuantityChange(index: number, line: WorkOrderLine): void {
    if (index > this.arLineItems.length) {
      return;
    }

    this.arLineItems[index] = line;
    this.markAsEdited();

    this.$onUpdate.emit({
      pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      work_order_items: this.arLineItems,
      work_order_item: line,
      pos: index,
      mode: 'update'
    });
  }

  onLineUnitPriceChange(index: number, line: WorkOrderLine): void {
    if (index > this.arLineItems.length || ! (line instanceof QuoteLineItem)) {
      return;
    }

    if (! this._isDiscountedPriceEnabled) {
      line.discounted_price = line.unit_price;
    }

    this.arLineItems[index] = line;
    this.markAsEdited();

    this.$onUpdate.emit({
      pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      work_order_items: this.arLineItems,
      work_order_item: line,
      pos: index,
      mode: 'update',
    });
  }

  onLineUnitCostChange(index: number, line: WorkOrderLine): void {
    if (index > this.arLineItems.length) {
      return;
    }

    this.arLineItems[index] = line;
    this.markAsEdited();
    this.onLineMarkupChange(index, line);

    this.$onUpdate.emit({
      pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      work_order_items: this.arLineItems,
      work_order_item: line,
      pos: index,
      mode: 'update',
    });
  }

  onLineMarkupChange(index: number, line: WorkOrderLine): void {
    if (index > this.arLineItems.length) {
      return;
    }

    this.arLineItems[index] = line;
    this.markAsEdited();

    let markUp = this.arLineItems[index]['markup'];
    let unitPrice = this.computePriceWithMarkup(this.arLineItems[index], markUp);

    this.arLineItems[index]['unit_price'] = toFormattedNumber(unitPrice, {currency: true, maxDecimalPlaces: 4});
    this.arLineItems[index]['markup'] = toFormattedNumber(markUp);

    this.onLineUnitPriceChange(index, line);

    this.$onUpdate.emit({
      pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      work_order_items: this.arLineItems,
      work_order_item: line,
      pos: index,
      mode: 'update',
    });
  }

  onLineTaxChange(index: number, line: WorkOrderLine, opts: {
    selected?: TaxCodeInterface,
  } = {}): void {
    if (index > this.arLineItems.length || ! (line instanceof QuoteLineItem)) {
      return;
    }

    line.updateTaxCode(opts.selected);

    this.arLineItems[index] = line;
    this.markAsEdited();

    this.$onUpdate.emit({
      pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      work_order_items: this.arLineItems,
      work_order_item: line,
      pos: index,
      mode: 'update',
    });
  }

  onLineTotalChange(index: number, line: WorkOrderLine): void {
    if (index > this.arLineItems.length) {
      return;
    }

    let costPerLineItem = this.computeCostPerLineItem(line);
    let totalPerLineItem = line['computeLineItem'];

    line['computeLineItem'] = toFormattedNumber(totalPerLineItem - costPerLineItem, {
      currency: true,
    });

    this.arLineItems[index] = line;
    this.markAsEdited();

    this.$onUpdate.emit({
      pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      work_order_items: this.arLineItems,
      work_order_item: line,
      pos: index,
      mode: 'update',
    });
  }

  /**
   * Adjusts the markup when the unit price has changed.
   *
   * @param numIndex
   */
  adjustMarkup(numIndex: number = -1) {
    if (numIndex > -1) {
      (this.arLineItems[numIndex] as QuoteLineItem).computeMarkup();
    }
  }

    /**
   * Compute cost of given line item
   *
   * @param attr
   * @returns number
   */
  computeCostPerLineItem(attr): number {
      let cost = (attr['labor']) ? attr['hourly_cost'] : attr['unit_cost'];
      let computedCost = attr['quantity'] * cost;

      return toFormattedNumber(computedCost, {
        currency: true,
      });
  }

  /// called when a line is added from browsing products
  addItem(event = null, lineItemIndex: number = -1): void {
    let objTax =  {};

    if (filled(get(event, 'default_tax_code_id'))) {
      objTax = {
        id: event['default_tax_code_id'],
        name: event['tax_code_name'],
        rate: toFormattedNumber(event['tax_rate']),
        code: event['tax_code'],
      };
    } else if (filled(get(event, 'tax_code_id'))) {
      objTax = {
        id: event['tax_code_id'],
        name: event['tax_code_name'],
        rate: toFormattedNumber(event['tax_rate']),
        code: event['tax_code'],
      };
    }

    if (blank(objTax) && filled(get(this.defaultTaxCode, 'id'))) {
      objTax = this.defaultTaxCode;
    }

    let objQuoteLineItems: QuoteLineItem = new QuoteLineItem({
      enable_discounts: this._isDiscountedPriceEnabled,
    });

    objQuoteLineItems.updateTaxCode(objTax);

    if (filled(event)) {
      objQuoteLineItems.updateProduct(event);
    }

    if (this.bDepartmentTracking) {
      objQuoteLineItems.department_id = get(event, 'department_id');
      objQuoteLineItems.department_name = get(event, 'department_name');
    }

    const line = _makeWorkOrderLineProxy(this._initializeTypeheadAndList(filled(objQuoteLineItems['id']) ? objQuoteLineItems : objQuoteLineItems.withObservablesAndId()));

    if (lineItemIndex != -1) {
      this.arLineItems.splice(lineItemIndex, 0, line);
    } else if (this.lastFocusedLineItemIndex > -1) {
      this.arLineItems.splice(this.lastFocusedLineItemIndex, 0, line);
    } else {
      this.arLineItems.push(line);
    }

    this.formService.objSaveData['work_order_items'] = this.arLineItems;
    this.markAsEdited();

    this.$onUpdate.emit({
      pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      work_order_items: this.arLineItems,
      work_order_item: line,
      pos: this.arLineItems.length - 1,
      mode: 'add'
    });
  }

  onLineRemove(index: number, line: WorkOrderLine): void {
    if (index > this.arLineItems.length) {
      return;
    }

    this.arLineItems.splice(index, 1);
    this.markAsEdited();

    this.$onUpdate.emit({
      pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      work_order_item: line,
      work_order_items: this.arLineItems,
      pos: index,
      mode: 'remove',
    });

    this.lastFocusedLineItemIndex = -1;
  }

  /**
   * Save the work order items
   *
   * @returns {void}
   */
  onSubmit(): void {
    this.validateItems();
    if (!this.bErrors) {
      this.bSubmit = true;

      let objDataToSave = this.compileSaveRecordData();

      if (this.bSave) {
        this.recordService.saveRecord('jobs', objDataToSave, this.objJobRecord["id"])
        .pipe(
          finalize(() => this.bSubmit = false)
        )
        .subscribe( response => {
          if (response.status === 200) {
            this.formService.removeUnsavedChangesDataToLocalStorage(get(this.data, ['record_details', 'id'], ''), 'work_orders', this.objJobRecord['id']);
            this.cancelDialog(true);
          } else {
            this.notifService.promptError(response.body.error);
          }
        });
      } else {
        this.cancelDialog({
          ...{
            total_tax_exc: this.intAmountWithoutTax,
            total_tax_inc: this.intAmountWithTax,
            tax: this.intTax
          },
          ...objDataToSave
        })
      }
    } else {

      this.notifService.sendNotification('not_allowed', 'required_notice', 'danger');
    }
  }

  /// called when a line has been edited and marking the component as edited
  markAsEdited(): void {
    this.bEdited = true;
  }

  /// called when a lines has been rearranged
  onLineDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arLineItems, event.previousIndex, event.currentIndex);
    this.markAsEdited();
  }

  /**
   * Validate items
   *
   * @returns {void}
   */
  validateItems(): void {
    this.bErrors = false;
    if (this.arLineItems.length > 0) {
      this.arLineItems.forEach( data => {
        if (data instanceof QuoteLineItem) {
          if (!data.tax_code_id) {
            this.bErrors = true;
          }
          if (data.quantity < 0) {
            this.bErrors = true;
          }
          if (this.bDepartmentTracking && !data.department_id) {
            this.bErrors = true;
          }
        }
      });
    }
  }

  /**
   * Expands the target element
   *
   * @param {HTMLTextAreaElement} target
   *
   * @returns {void}
   */
  expandInput(target: HTMLTextAreaElement): void {
    target.classList.add('expanded-input');
  }

  /**
   * Shrinks the target element
   *
   * @param {HTMLTextAreaElement} target
   *
   * @returns {void}
   */
  shrinkInput(target: HTMLTextAreaElement): void {
    target.classList.remove('expanded-input');
  }

  /**
   * Triggered when parent component passed
   * data in valueFromParent
   *
   * @param valueFromParent
   */
  doSomethingFromParent(valueFromParent) {
    // This logic is for recurring jobs as I reused this component in recurring job form
    if (!filled(valueFromParent)
      && (valueFromParent.module === 'recurring_jobs' || valueFromParent.module == 'job_templates')
    ) {

      if (!isEmpty(valueFromParent.data)) {
        this.objJobRecord['work_order_items'] = !isEmpty(valueFromParent.data.work_order_items) ? valueFromParent.data.work_order_items : [];
        this.objFormGroup.controls['pricebook_id'].setValue(valueFromParent.data.pricebook_id);
        this.objFormGroup.patchValue({
          pricebook_id: valueFromParent.data.pricebook_id
        });

        let objPricebookOption = new Select(valueFromParent.data.pricebook_id, valueFromParent.data.pricebook_text);

        // FC-3648: initialize selected pricebook
        if (get(valueFromParent.data, 'init_pricebook') === true) {
          this.selectPricebook.onSetRelate([objPricebookOption]);
        }

        this.objPricebookRelateField['default_value'] = valueFromParent.data.pricebook_id;
        this.objPricebookRelateField['options'] = [objPricebookOption];

        // this.formatWorkOrderItems();
      }

      this.validateItems();

      if(valueFromParent.mode !== 'save') {
        this.emitWorkOrderToParent('init?');
      } else if (valueFromParent.mode === 'save') {
        if (!this.bErrors) {
          this.emitWorkOrderToParent();
        } else {
          this.parentEvent.emit('has_error');
        }
      }
    }
  }

  /**
   * This will emit the value
   * to parent component
   */
  emitWorkOrderToParent(mode: 'init?' | 'save' = 'save') {
    var arWorkOrderItems = [];

    if (this.arLineItems.length != 0) {
      arWorkOrderItems = cloneDeep(
        this.arLineItems.map( items => {
          return (items instanceof QuoteLineItem) ? items.forSaving() : items;
        })
      );
    }

    this.parentEvent.emit({
      pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
      work_order_items: arWorkOrderItems,
      mode: mode,
    });
  }

  /// called when user attempts to add related products
  addRelated(line: Record<string, any>, lineItemIndex: number) {
    this.dialog.open(RelatedProductsComponent, {
      width: '70%',
      data: line,
    })
    .afterClosed()
    .pipe(
      filter((response) => filled(response)),
      map((response) => _map(response, (response) => get(response, 'child_item_id'))),
      filter((ids) => filled(ids)),
      switchMap((ids) => this.recordService.getProducts({
        ids,
        sales_only: false,
      })),
      filter((items) => filled(items)),
    )
    .subscribe((items) => {
      items.forEach((item) => {
        this.addItem(item, lineItemIndex + 1);
      });
    });
  }

  triggerAutoSave() {
    if (blank(this.autoSaveIntervalId)) {
      this.autoSaveIntervalId = setInterval(() => {
        if (this.bEdited) {
          this.saveWorkOrderViaAutoSave();
        }
      }, 5000);
    }
  }

  saveWorkOrderViaAutoSave() {
    let objWorkOrder = this.compileSaveRecordData();

    if (!isEmpty(this.relateChanges['pricebook_id'])) {
      objWorkOrder['pricebook_text'] = this.relateChanges['pricebook_id'];
    }

    if (!isEmpty(objWorkOrder)) {
      let unsavedChangesData: SetUnsavedChangesData = {
        record_id: get(this.data, ['record_details', 'id'], ''),
        module: 'supplier_invoices',
        data_to_save: objWorkOrder,
        parent_record_id: this.objJobRecord['id'],
        related_fields: this.relateFields,
        related_changes: this.relateChanges,
      };

      this.formService.setUnsavedChangesDataToLocalStorage(unsavedChangesData);
    }
  }

  compileSaveRecordData()
  {
    let strPricebookId = this.objFormGroup.controls['pricebook_id'].value;
    var arWorkOrderItems = [];
    if (this.arLineItems.length != 0) {
      arWorkOrderItems = cloneDeep(
        this.arLineItems.map((line) => line.encode())
      );
    }

    return {
      work_order_items: arWorkOrderItems,
      pricebook_id: (strPricebookId) ? strPricebookId : null,
    };
  }

  formatProductItemFromCopiedLine(lineitem: LooseObject) {
    lineitem['id'] = get(lineitem, 'item_id');
    lineitem['name'] = get(lineitem, 'item_name');
    lineitem['code'] = get(lineitem, 'item_code');

    return lineitem;
  }

  doSomethingFromContextMenu(event) {
    let copiedLineItemFromLocalStorage = this.contextMenuService.getCopiedLineItem();

    if (event.action == 'paste' && filled(copiedLineItemFromLocalStorage)) {
      let indexCounter = get(event, ['data', 'index']) + 1;
      copiedLineItemFromLocalStorage.forEach(lineItem => {
        lineItem = this.formatProductItemFromCopiedLine(lineItem);

        this.addItem(lineItem, indexCounter);
        indexCounter++;
      });
    } else if (event.action == 'copy') {
      let copiedLineItems = [];
      let eventDataLineItem = get(event, ['data', 'line_item'], []);

      if (this.selectedLineItems.length > 0) {
        copiedLineItems = this.selectedLineItems.map(lineItem => {
          let newLineItem = new QuoteLineItem({
            ... lineItem,
            enable_discounts: this._isDiscountedPriceEnabled,
          });
          if (!isUndefined(newLineItem['id'])) {
            delete newLineItem['id'];
          }

          return newLineItem;
        });
      } else if (filled(eventDataLineItem)) {
        let newLineItem = new QuoteLineItem({
          ... eventDataLineItem,
          enable_discounts: this._isDiscountedPriceEnabled,
        });

        if (!isUndefined(newLineItem['id'])) {
          delete newLineItem['id'];
        }

        copiedLineItems = [newLineItem];
      }

      if (filled(copiedLineItems)) {
        this.contextMenuService.setCopiedLineItem(copiedLineItems);
      }
    }
  }

  onClickedLineItem($event, attr) {
    this.selectedLineItems = this.contextMenuService.selectLineItem($event, attr, this.selectedLineItems);
  }

  /**
   * To open create item dialog form
   */
  createItem(lineItem: LooseObject): void {

    let itemsDefaultValue: LooseObject = {
      name: get(lineItem, 'description'),
      code: get(lineItem, 'description'),
      pricing_method: 'default_markup',
      unit_cost: get(lineItem, 'unit_cost'),
      unit_price: get(lineItem, 'unit_price'),
    };

    if (filled(lineItem.tax_code_id)) {
      itemsDefaultValue.default_tax_code_id = get(lineItem, 'tax_code_id');
      itemsDefaultValue.default_tax_code_text = get(lineItem, 'tax_code_name');
    }

    this.dialog.open(EditformComponent, new FormPopup('items', {}, itemsDefaultValue))
      .afterClosed()
      .subscribe((response) => {
        if (get(response, 'status') == 'save' && filled(response.data)) {
          lineItem.item = new Select(response.data.id, response.data.name);
          lineItem.item_id = response.data.id;
          lineItem.item_name = response.data.name;
          lineItem.item_code = response.data.code;
          lineItem.unit_price = response.data.unit_price;
          lineItem.unit_cost = response.data.unit_cost;
        }
      });
  }

  /**
   * Open suuplier inventory dialog.
   *
   * @param recordData
   */
  openSupplierInventoryDialog(itemId: string) {
    this.dialog.open(ViewSupplierInventoryComponent, {
      width: '80%',
      height: '98%',
      data: {
        record: {id: itemId},
      }
    });
  }

  setLastIndex = (index: number) => this.lastFocusedLineItemIndex = index + 1;

  onDescriptionFocus(target: HTMLTextAreaElement, index: number): void {
    this.expandInput(target);
    this.setLastIndex(index);
  }

  /// initializes the typeahead and list for the given work order item
  private _initializeTypeheadAndList(objWorkOrderItems: QuoteLineItem): QuoteLineItem {
    this.initializeTaxCodeTypeheadAndList(objWorkOrderItems);
    return this.initializeItemTypeheadAndList(objWorkOrderItems);
  }

  private _initLines(): void {
    const items = when(this.asChild, {
      then: () => this.initialValue,
      else: () => get(this.objJobRecord, 'work_order_items', []),
    });

    const ids = _map(items, (item) => get(item, 'item_id'))
      .filter((id) => isId(id));

    const subscription = forkJoin({
      related: iif(
        () => blank(this.arTaxCodeList) || blank(this.arDepartmentList),
        this.recordService.getMultipleModuleRelateRecord('departments|tax_codes', false, { tax_codes: {is_sales: true} }),
        of({
          departments: this.arDepartmentList,
          tax_codes: this.arTaxCodeList,
        })
      ),
      items: iif(
        () => filled(ids), this.recordService.getProducts({ ids }), of([])
      ),
    }).pipe(
      finalize(() => this.bLoading = false),
    ).subscribe((aggregate) => {
      this.arDepartmentList = get(aggregate.related, 'departments', []);
      this.arTaxCodeList = get(aggregate.related, 'tax_codes', []);

      this.arLineItems = _map(items, (item) => {
        if (get(item, 'label') || get(item, 'is_group') === true) {
          return _makeWorkOrderLineProxy(new QuoteLineGroup(item));
        }

        const lineItem = aggregate.items.find((lineItem) => lineItem.id === get(item, 'item_id'));

        if (blank(lineItem)) {
          item.item_name = null;
          item.item_code = null;
        }

        const newLineItem = this._initializeTypeheadAndList(
          new QuoteLineItem({
            ... item,
            enable_discounts: this._isDiscountedPriceEnabled,
          }).withObservablesAndId()
        );

        return _makeWorkOrderLineProxy(newLineItem);
      });

      this.$onUpdate.emit({
        pricebook_id: this.objFormGroup.controls['pricebook_id'].value,
        work_order_items: this.arLineItems,
        work_order_item: this.arLineItems[this.arLineItems.length - 1],
        pos: this.arLineItems.length - 1,
        mode: 'init',
      });
    });

    this._subscriptions.push(subscription);
  }

  setFormMode(formMode: FormMode): void { }

  /**
   * @inheritdoc
   */
  setParentForm(parentForm: FormGroup): void {
    this.parentForm = parentForm;
  }

  /**
   * @inheritdoc
   */
  setField(field: Form<any>): void { }

  /**
   * @inheritdoc
   */
  setAdditionalData(data: LooseObject): void {
    this.data['record_details'] = data['wo_job_details'] ? data['wo_job_details'] : [];
    this.initializeData()
  }

  private _assignLinePricing(line: QuoteLineItem, opts: {
    discounted_price: number;
    original_price: number;
  }): QuoteLineItem {
    if (this._isDiscountedPriceEnabled) {
      line.discounted_price = opts.discounted_price;
      line.unit_price = opts.original_price;
    } else {
      line.unit_price = opts.discounted_price;
    }

    return line;
  }
}

export interface ParentValue {
  data: any,
  module: string,
  mode: string;
}

export interface WorkOrderItemsComponentConfig {
  default_tax_code_sale: TaxCodeInterface;
}

export type WorkOrderDefaultPricebook = {
  id: string;
  text: string;
};

export type WorkOrderToParentEvent = 'has_error' | {
  pricebook_id: string;
  work_order_items: Record<string, any>[];
  mode: 'save' | 'init?';
};

export type WorkOrderLine = (QuoteLineItem | QuoteLineGroup) & {
  encode(): Record<string, any>;
  isGroup(): boolean;
};

export type OnWorkOrderLinesUpdate = {
  pricebook_id: string;
  work_order_items: WorkOrderLine[];
  work_order_item: WorkOrderLine;
  pos: number;
  mode: 'update' | 'init' | 'add' | 'remove';
}

type WorkOrderLineProduct = {
  labor: false;
  unit_cost: number;
}

type WorkOrderLineLabor = {
  labor: true;
  hourly_cost: number;
}

export type WorkOrderLineData = WorkOrderLineProduct | WorkOrderLineLabor & {
  work_oder_reference: string;
  unit_cost: number;
  unit_price: number;
  discounted_price: number;
  description: string;
  is_created: boolean;
  item_code: string;
  item_id: string;
  item_name: string;
  line_item: number;
  markup: number;
  quantity: number;
  tax_code: string;
  tax_code_id: string;
  tax_code_name: string;
  tax_rate: number;
  current_stock_level: number;
  department_id: string;
  department_name: string;
}

export type WorkOrderItemsComponentProps = {
  record_details: Job;
  related_data: {
    default_tax_code_sale: TaxCodeInterface
  };
  is_save: boolean;
}

/// creates a proxy for the given work order line item or group
const _makeWorkOrderLineProxy = (target: any): WorkOrderLine => new Proxy(target, {
  get(target, prop) {
    if (prop == 'encode') {
      return () => _encode(target);
    }

    if (prop == 'isGroup') {
      return () => target instanceof QuoteLineGroup;
    }

    return target[prop];
  }
});

/// performs encoding of the given work order line item or group for saving
const _encode = (target: QuoteLineItem | QuoteLineGroup): Record<string, any> => {
  let encoded = Object.keys(
    omit(target, ['obv', 'typehead', 'loader']),
  ).reduce((acc, key) => ({
    ... acc,
    [key]: target[key],
  }), {});

  if (blank(get(encoded, 'item_id')) || ! isId(get(encoded, 'item_id'))) {
    encoded = omit(encoded, ['item_id', 'item_name', 'item_code']);
  }

  return encoded;
}

/// checks if provided work order lines are valid
export const isValidWorkOrderLines = (lines: WorkOrderLine[], isDepartmentTracking: boolean = false): boolean => lines.every((line) => {
  if (line.isGroup()) {
    return true;
  }

  if (blank(get(line, 'tax_code_id'))) {
    return false;
  }

  if (isDepartmentTracking && blank(get(line, 'department_id'))) {
    return false;
  }

  return true;
});