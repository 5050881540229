<ng-container *ngIf="!bLoading; else loading">
  <ng-container *ngIf="dataSource.data.length > 1; else noParentNorChildAsset">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <span>
          <fa-icon
            [icon]="['fas', 'window-close']"
            class="text-secondary"
            matTooltip="{{ 'no_child_assets_found' | translate }}"></fa-icon>
        </span>

        <span>
          <fa-icon [icon]="['fal', 'briefcase']" class="ml-2"></fa-icon>
        </span>

        <span class="ml-2" [ngClass]="{'font-weight-bold': node.id === strRecordId}">{{ node.name }}</span>

        <small *ngIf="node.id === strRecordId" class="ml-2">({{ 'current_record' | translate }})</small>

        <a *ngIf="node.id !== strRecordId" [routerLink]="['/assets/' + node.id]">
          <fa-icon [icon]="['fas', 'eye']" class="ml-2 pointer text-primary" matTooltip="{{ 'view_record' | translate }}"></fa-icon>
        </a>
      </mat-tree-node>

      <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <span class="pointer" matTreeNodeToggle>
          <fa-icon
            *ngIf="treeControl.isExpanded(node)"
            [icon]="['fas', 'minus-square']"
            class="text-primary"
            matTooltip="{{ 'hide_child_assets' | translate }}">
          </fa-icon>

          <fa-icon
            *ngIf="!treeControl.isExpanded(node)"
            [icon]="['fas', 'plus-square']"
            class="text-primary"
            matTooltip="{{ 'show_child_assets' | translate }}">
          </fa-icon>
        </span>

        <span>
          <fa-icon [icon]="['fal', 'briefcase']" class="ml-2"></fa-icon>
        </span>

        <span class="ml-2" [ngClass]="{'font-weight-bold': node.id === strRecordId}">{{ node.name }}</span>

        <small *ngIf="node.id === strRecordId" class="ml-2">({{ 'current_record' | translate }})</small>

        <a *ngIf="node.id !== strRecordId" [routerLink]="['/assets/' + node.id]">
          <fa-icon [icon]="['fas', 'eye']" class="ml-2 pointer text-primary" matTooltip="{{ 'view_record' | translate }}"></fa-icon>
        </a>
      </mat-tree-node>
    </mat-tree>
  </ng-container>
</ng-container>

<ng-template #noParentNorChildAsset>
  <p data-testid="no-parent-nor-child">{{ 'no_parent_nor_child_assets' | translate }}</p>
</ng-template>

<ng-template #loading>
  <div class="text-center">
    <inline-view-loader [isLoading]="true"></inline-view-loader>
  </div>
</ng-template>