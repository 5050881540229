import { Component, OnInit, Input } from '@angular/core';
import { ArrService } from '../../../../services/helpers/arr.service';
import * as moment from 'moment';
import { ClientStoreService } from '../../../../services/client-store.service';
import { toFormattedNumber } from '../../../utils/numbers';
import { get } from 'lodash-es';
import { TimeEntryGroup } from '../../../../module/jobs/customer-invoices/edit-invoice/edit-invoice.component';
import { LooseObject } from '../../../../objects/loose-object';
import { filled } from '../../../utils/common';
import { TranslateService } from '@ngx-translate/core';
import { ViewSupplierInventoryComponent } from '../../../../admin/items/view-supplier-inventory/view-supplier-inventory.component';
import { MatDialog } from '@angular/material';
import { Form } from '../../../../base/form';
import { ViewService } from '../../../../services/view.service';

@Component({
  selector: 'app-line-items',
  templateUrl: './line-items.component.html',
  styleUrls: ['./line-items.component.scss']
})
export class LineItemsComponent implements OnInit {
  @Input() strLabel: string;
  @Input() strModule: string;
  @Input() currentFieldData: any;
  @Input() recordData: any;
  @Input() recordViewData: any;

  public strInvoicingType: string = '';
  public bHasTimeEntryLineItem: boolean = false;
  public bHasEnabledGroupBy: boolean = false;
  public bHasMaterialLineItem: boolean = false;
  public bHasInvoiceLineItem: boolean = false;
  public bHasDepartmentTracking: boolean = false;

  public arGroupTimeEntries: TimeEntryGroup[] = [];

  constructor(
    private arrService: ArrService,
    private clientStorageService: ClientStoreService,
    public translate: TranslateService,
    private dialog: MatDialog,
    private view: ViewService
  ) {
  }

  ngOnInit() {

    this.bHasDepartmentTracking = this.clientStorageService.isDepartmentTracking();

    if (this.currentFieldData != undefined && this.currentFieldData != null && this.currentFieldData != '') {
      this.currentFieldData.forEach( (data, index) => {
        if (this.recordViewData['related_data'] != undefined) {
          this.currentFieldData[index]['relate_data'] = [];
          // Set related time entries data
          if(data['material_id'] != '' && data['material_id'] != null && this.recordViewData['related_data']['materials'] != undefined) {
            let materialIndex = this.recordViewData['related_data']['materials'].findIndex(materialData => (materialData['id'] == data['material_id']));
            if (materialIndex > -1) {
              this.bHasMaterialLineItem = true;
              this.currentFieldData[index]['relate_data']['materials'] = this.recordViewData['related_data']['materials'][materialIndex];
            } else {
              this.currentFieldData[index]['relate_data']['materials'] = [];
            }
          }
          // Set related time entries data
          else if(data['time_entry_id'] != '' && data['time_entry_id'] != null && this.recordViewData['related_data']['time_entries'] != undefined) {
            this.bHasEnabledGroupBy = this.recordViewData['record_details']['enable_group_by'] || false;
            let timeEntryIndex = this.recordViewData['related_data']['time_entries'].findIndex(timeEntryData => (timeEntryData['id'] == data['time_entry_id']));
            if (timeEntryIndex > -1) {
              this.bHasTimeEntryLineItem = true;
              this.currentFieldData[index]['relate_data']['time_entries'] = this.recordViewData['related_data']['time_entries'][timeEntryIndex];
              this.groupBy();
            } else {
              this.currentFieldData[index]['relate_data']['time_entries'] = [];
            }
          }
          else if(Object.keys(data).length > 0) {
            this.bHasInvoiceLineItem = true;
          }
        }
      });
    }

    switch(this.strModule) {
      case 'customer_invoices':
        if (this.recordViewData['record_details']['job_id'] != null && this.recordViewData['record_details']['job_id'] != '') {
          this.strInvoicingType = this.recordViewData['record_details']['invoicing_type'];
        }
      break;
      case 'recurring_jobs':
        if (this.recordViewData['record_details']['billable']) {
          this.strInvoicingType = this.recordViewData['record_details']['invoicing_type'];
        }
      break;
    }
  }

  /**
   * Checker if the field has data to display.
   *
   * @param strData
   * @param strModule
   *
   * @returns {boolean}
   */
  checkRecord(objData: any, strModule: string = ''): boolean {
    let bHasData: boolean = false;
    switch (strModule) {
      case 'time_entries':
        bHasData =  false;
        let arTimeEntryData = this.arrService.keyFallsBackTo(objData['relate_data'], 'time_entries', []);
        if (Object.keys(arTimeEntryData).length > 0) {
              bHasData = true;
        }
      break;
      case 'materials':
        bHasData = false;
        let arMaterialData = this.arrService.keyFallsBackTo(objData['relate_data'], 'materials', []);
        if (Object.keys(arMaterialData).length > 0) {
              bHasData = true;
        }
      break;
      default:
        bHasData = (objData != undefined && objData != '' && objData != null) ? true : false;
      break;
    }

    return bHasData;
  }

  /**
   * Let's format the datetime value.
   * @param strDate
   * @param arCustomConfig
   * @returns
   */
  formatDate(strDate, arCustomConfig = []) {
    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('ll');
  }

  /**
   * Group the line items time materials if group by is enabled.
   *
   * @return {void}
   */
  groupBy() {
    this.arGroupTimeEntries = [];
    this.currentFieldData.forEach(item => {
      let numIndex = this.arGroupTimeEntries.findIndex(item1 => {
        if (item['relate_data'] && item['relate_data']['time_entries'] && item['relate_data']['time_entries']['user_id'] == item1.user_id) {
          return true;
        }
        return false;
      });
      if (numIndex > -1) {
        this.arGroupTimeEntries[numIndex].time_entries.push(item);
      } else {
        if (item['relate_data'] && item['relate_data']['time_entries'] && item['relate_data']['time_entries']['user_id']) {
          this.arGroupTimeEntries.push(
            new TimeEntryGroup(item['relate_data']['time_entries']['user_id'], item['relate_data']['time_entries']['user_text'], [item])
          )
        }
      }
    });
  }

  getStockReceiptToolTip(objLineItem: LooseObject, strKey: string) {
    let compiledToolTip = '';
    if (filled(objLineItem[strKey])) {
      objLineItem[strKey].forEach( objLineItem => {
        let strReference = filled(objLineItem.reference) ? objLineItem.reference : '--';
        compiledToolTip += `
        ${this.translate.instant('reference')}: ${strReference}
        ${this.translate.instant('received')}: ${objLineItem.received}
        `;
      });

    }
    return compiledToolTip;
  }

  getJobDetailsToolTip(objLineItem: LooseObject) {
    return `
    ${this.translate.instant('job_number')}:  ${objLineItem['job_number']}
    `;
    // matTooltip="{{ 'job_number' | translate }}: {{ lineItem['job_number'] }}"
  }

  /**
   * Open suuplier inventory dialog.
   *
   * @param recordData
   */
  openSupplierInventoryDialog(itemId: string) {
    this.dialog.open(ViewSupplierInventoryComponent, {
      width: '80%',
      height: '98%',
      data: {
        record: {id: itemId},
      }
    });
  }

  /**
   * Compute total amount without tax
   *
   * @returns {number}
   */
  get intAmountWithoutTax(): number {
    let numTotal = 0;

    if (this.currentFieldData) {
      this.currentFieldData.forEach(objLineItem => {
          numTotal = numTotal + objLineItem.line_item;
        }
      );
    }

    return toFormattedNumber(numTotal, {
      currency: true,
    });
  }

  /**
   * Compute total tax
   *
   * @returns {number}
   */
 get intTax(): number {
    let numTotal = 0;

    if (this.currentFieldData) {
      this.currentFieldData.forEach(objLineItem => {
          numTotal += (objLineItem.tax_rate / 100) * objLineItem.line_item;
        }
      );
    }

    return toFormattedNumber(numTotal, {
      currency: true,
    });
  }

  /**
   * Compute total amount with tax
   *
   * @returns {number}
   */
  get intAmountWithTax(): number {
    const adjustment = toFormattedNumber(get(this.recordData, 'tax_adjustment_amount'), {
      currency: true,
    });

    return toFormattedNumber(this.intAmountWithoutTax + (this.intTax + adjustment), {
      currency: true,
    });
  }

  get hasStockReceipt(): boolean {
    return this.strModule == 'purchase_orders' || this.strModule == 'supplier_invoices'
  }

  setField(field: Form<any>) {
    this.currentFieldData = field['default_value'];
    this.strLabel = field['label'];
    this.strModule = this.view.strRecordModule;
    this.recordViewData = this.view.getRecord();
    this.recordData = this.view.getViewRecord();
  }
}
