import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatChipInputEvent, MatDialogRef } from '@angular/material';
import { TagDisplay, TagDisplayInterface, TagRecordDisplay } from '../../objects/tag-display';
import { RecordService } from '../../../../services/record.service';
import { ListingService } from '../../../../services/listing.service';
import { filter, finalize, map, switchMap } from 'rxjs/operators';
import { StatusCode } from '../../../../lists/status-code';
import { isEmpty } from 'lodash-es';
import { NotificationService } from '../../../../services/notification.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { RecordTag } from '../../objects/record-tag';

@Component({
  selector: 'manage-tags',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss']
})
export class ManageTagsComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  /**
   * If we have selected records.
   *
   * @var {boolean}
   */
  bHasSelectedRecords: boolean = false;

  /**
   * The add button placeholder which is always
   * located at the right side of the tags input.
   *
   * @var {boolean}
   */
  bAddPlaceHolder: boolean = false;

  /**
   * Loading indicator, its a simple chip with a spinner
   * that should appear when anything is loading.
   *
   * @var {boolean}
   */
  bLoading: boolean = false;

  /**
   * List of tags to display.
   *
   * @var {TagRecordDisplay}
   */
  arTags: TagRecordDisplay[] = [];

  /**
   * Current module of this tag.
   *
   * @var {string}
   */
  strModule: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ManageTagsComponent>,
    public record: RecordService,
    public list: ListingService,
    public notify: NotificationService
  ) {
    this.strModule = this.data['module'];

    if (this.data['records'] && this.data['records'].length > 0) {
      this.bHasSelectedRecords = true;
    }
  }

  ngOnInit() {

    this._toggleLoading(true);

    this.list.fetchData(null, 'tags', JSON.stringify({module: this.strModule}), 100)
    .pipe(
      filter(response => { return response && response['data'] }),
      finalize(() => {
        this._toggleLoading(false);
        this.bAddPlaceHolder = true;
      })
    )
    .subscribe(response => {
      this.arTags = response['data'].map(item => {
        return new TagRecordDisplay(item);
      });
    });

  }

  /**
   * Adds tag to the event.
   *
   * @param {MatChipInputEvent} objEvent
   *
   * @return {void}
   */
  addTag(objEvent: MatChipInputEvent): void {

    if (!isEmpty(objEvent.value.trim())) {

      this._toggleLoading(true);

      let tag = new TagRecordDisplay({name: objEvent.value.trim(), module: this.strModule});

      this.record.saveRecord('tags', tag)
        .pipe(
          filter(response => {
            return response.status === StatusCode.kResponseCreated;
          }),
          map(response => {
            return new TagRecordDisplay(response['body'] as any);
          }),
          finalize(() => {
            this._toggleLoading(false);
          })
        )
        .subscribe(tag => {
          this._add(objEvent, tag);
        });

    }
  }

  /**
   * Remove tag from list
   *
   * @param {TagRecordDisplay} objFruit
   *
   * @return {void}
   */
  removeTag(objFruit: TagRecordDisplay): void {

    if (objFruit.id) {
      this._toggleLoading(true);
      this.record.deleteRecord('tags', objFruit.id)
      .pipe(
        filter(response => {
          return response.status === StatusCode.kResponseSuccess;
        }),
        finalize(() => {
          this._toggleLoading(false);
        })
      )
      .subscribe(() => {
        this._remove(objFruit);
      });
    } else {
      this._remove(objFruit);
    }

  }

  /**
   * Toggle placeholder.
   *
   * @param {bloolean} bFlag
   *
   * @return {void}
   */
  togglePlaceHolder(bFlag: boolean) {
    this.bAddPlaceHolder = !bFlag;
  }

  /**
   * Focuses on the chip input when clicked.
   *
   * @param element
   *
   * @return {void}
   */
  focusOnChipInput(element){
    element.focus();
  }

  /**
   * Save tags through the API.
   *
   * @return {void}
   */
  saveTag(objTag: TagRecordDisplay, numIndex: number) {
    this.record.saveRecord('tags', objTag, objTag.id)
    .pipe(
      filter(response => {
        return response.status === StatusCode.kResponseSuccess;
      }),
    )
    .subscribe(() => {
      this.arTags[numIndex] = new TagRecordDisplay({...objTag, ...{include: this.arTags[numIndex].include}});
      this.notify.notifySuccess('tag_updated_successfully');
    });
  }

  /**
   * Clears the tags and updates it on the API.
   *
   * @returns {void}
   */
  clearTags() {

    this.notify.sendConfirmation('clear_tags_confirm')
      .pipe(
        filter(response => response.answer == true),
        switchMap(() => {

          let arSaveData = this.data['records'].map(response => {
            return new RecordTag(response['id'], []);
          });

          return this.record.saveMultipleRecord(this.data['module'], arSaveData).pipe(
            filter(response => {
              return response.status === StatusCode.kResponseSuccess;
            }),
          );

        } )
      ).subscribe(response => {
        this.notify.notifySuccess('success');
        this.dialogRef.close({tagged: true});
      });

  }

  /**
   * Add the selected tags to the given records.
   *
   *  @returns {void}
   */
  saveTags() {

    let arTagIds = this.arTags
      .filter(response => {
        return response['include'];
      })
      .map(response => {
        return new TagDisplay(response);
      });

    let arSaveData = this.data['records'].map(response => {
      return new RecordTag(response['id'], arTagIds);
    });

    this.record.saveMultipleRecord(this.data['module'], arSaveData)
    .pipe(
      filter(response => {
        return response.status === StatusCode.kResponseSuccess;
      }),
    )
    .subscribe(response => {
      this.notify.notifySuccess('success');
      this.dialogRef.close({tagged: true});
    })
  }

  /**
   * Closes the dialog.
   *
   * @return {void}
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Remove item from the list of tags.
   *
   * @param {TagRecordDisplay} objFruit
   *
   * @return {void}
   */
  private _remove(objFruit: TagRecordDisplay) {
    const index = this.arTags.indexOf(objFruit);
    if (index >= 0) {
      this.arTags.splice(index, 1);
    }
  }

  /**
   * Add item to the list of tags.
   *
   * @param {MatChipInputEvent} objEvent
   * @param {TagRecordDisplay} objTag
   */
  private _add(objEvent: MatChipInputEvent, objTag: TagRecordDisplay) {
    if ((objEvent.value || '').trim()) {
      this.arTags.push(objTag);
    }

    if (objEvent.input) {
      objEvent.input.value = '';
    }
  }

  /**
   * Toggles the loading indicators.
   *
   * @param {boolean} bValue
   */
  private _toggleLoading(bValue: boolean) {
    this.bLoading = bValue;
  }
}
