import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';

import { PipesModule } from './pipes.module';
import { MaterialModule } from './material.module';
import { DirectivesModule } from './directives.module';
import { FormsModule as FMCFormsModule } from '../modules/forms.module';
import { RequiredTagComponent } from '../components/simple/required-tag.component';
import { InlineViewLoaderComponent } from '../../shared/components/inline-view-loader/inline-view-loader.component';
import { ButtonIconLoaderComponent } from '../../shared/components/button-icon-loader/button-icon-loader.component';
import { NoRecordFoundComponent } from '../../shared/components/no-record-found/no-record-found.component';
import { DialogHeaderComponent } from '../components/dialog/dialog-header/dialog-header.component';
import { DialogButtonComponent } from '../components/dialog/dialog-button/dialog-button.component';
import { RefreshPaginationButtonComponent } from '../components/common/buttons/pagination/refresh-pagination.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { AlertComponent } from '../alert/alert.component';
import { PaginationLinksComponent } from '../components/pagination/pagination-links.component';
import { IconComponent } from '../components/icons/icon.component';
import { ValidationMessagesComponent } from '../components/validation-messages/validation-messages.component';
import { PrimaryButtonComponent } from '../components/common/buttons/primary-button.component';
import { DialogTitleComponent } from '../components/dialog/dialog-title.component';
import { ErrorsAlertComponent } from '../alert/errors-alert.component';
import { ContextMenuComponent } from '../components/context-menu/context-menu.component';
import { ShContextMenuModule } from 'ng2-right-click-menu';
import { PageSpinnerComponent } from '../components/page-spinner/page-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material';
import { PageContentLayoutComponent } from '../layouts/page-content-layout.component';
import { TabComponent } from '../components/tab/tab.component';
import { InputErrorTextComponent, TextComponent } from '../components/text/text.component';
import { BoxComponent } from '../components/common/box/box.component';
import { LayoutGridComponent } from '../components/common/grid/grid.component';
import { SortingIconComponent } from '../components/icons/sorting-icon.component';
import { TableComponent } from '../components/table/table.component';
import { AsyncContentComponent } from '../components/utils/async-content.component';
import { PageGoBackButtonComponent } from '../components/common/buttons/page/go-back-button.component';
import { DialogCloseButtonComponent } from '../components/dialog/buttons/dialog-close-button.component';
import { HorizontalPaginationComponent } from '../components/pagination/horizontal-pagination.component';
import { PrimaryDropdownButtonComponent } from '../components/common/buttons/primary-dropdown-button.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    MaterialModule,
    DirectivesModule,
    PipesModule,
    ShContextMenuModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    InlineViewLoaderComponent,
    RequiredTagComponent,
    ButtonIconLoaderComponent,
    NoRecordFoundComponent,
    DialogHeaderComponent,
    DialogButtonComponent,
    RefreshPaginationButtonComponent,
    DialogComponent,
    AlertComponent,
    PaginationLinksComponent,
    IconComponent,
    ValidationMessagesComponent,
    PrimaryButtonComponent,
    DialogTitleComponent,
    ErrorsAlertComponent,
    ContextMenuComponent,
    PageSpinnerComponent,
    PageContentLayoutComponent,
    TabComponent,
    TextComponent,
    BoxComponent,
    LayoutGridComponent,
    SortingIconComponent,
    TableComponent,
    AsyncContentComponent,
    PageGoBackButtonComponent,
    InputErrorTextComponent,
    DialogCloseButtonComponent,
    HorizontalPaginationComponent,
    PrimaryDropdownButtonComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    MaterialModule,
    NgSelectModule,
    PipesModule,
    InlineViewLoaderComponent,
    RequiredTagComponent,
    DirectivesModule,
    FMCFormsModule,
    ButtonIconLoaderComponent,
    NoRecordFoundComponent,
    RefreshPaginationButtonComponent,
    DialogHeaderComponent,
    DialogButtonComponent,
    DialogComponent,
    AlertComponent,
    PaginationLinksComponent,
    IconComponent,
    ValidationMessagesComponent,
    PrimaryButtonComponent,
    DialogTitleComponent,
    ErrorsAlertComponent,
    ContextMenuComponent,
    PageContentLayoutComponent,
    TabComponent,
    TextComponent,
    BoxComponent,
    LayoutGridComponent,
    SortingIconComponent,
    TableComponent,
    AsyncContentComponent,
    PageGoBackButtonComponent,
    InputErrorTextComponent,
    DialogCloseButtonComponent,
    HorizontalPaginationComponent,
    PrimaryDropdownButtonComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [] // TranslateService
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
    };
  }
}
