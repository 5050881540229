
    <ng-container *ngIf="alignment === 'left'; else centerOrRight">
      <ng-container *ngTemplateOutlet="contentElem"></ng-container>
    </ng-container>
    <ng-template #centerOrRight>
      <div class="d-flex justify-content-{{ alignment }}">
        <ng-container *ngTemplateOutlet="contentElem"></ng-container>
      </div>
    </ng-template>
    <ng-template #contentElem>
      <span
        [class.fieldmagic-text-title]="purpose == 'title'"
        [class.fieldmagic-text-link]="purpose == 'link'"
        [class.fieldmagic-text-input-label]="purpose == 'input'"
        [class.fieldmagic-text-input-label-with-required-mark]="purpose == 'input' && withRequiredMarker"
        [class.fieldmagic-text-error]="purpose == 'error'"
      >
        {{ content | translate }}
      </span>
    </ng-template>
  