import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebAuth } from 'auth0-js';
import { environment } from '../../environments/environment';
import { LocalStorageService } from '../services/local-storage.service';
import { startsWith, toString, trim } from 'lodash-es';
import { ClientStoreService } from '../services/client-store.service';
import { blank } from '../shared/utils/common';
import { LayoutDashboardService } from '../shared/layouts/layout-dashboard/layout-dashboard.service';

@Injectable()
export class AuthService {

  auth0 = new WebAuth({
    clientID: 'LO-CxQTAqr4042mcRhKmArDqn7uhX5-m',
    domain: 'login.fieldmagic.co',
    responseType: 'token id_token',
    redirectUri: environment.app_url + '/#account/callback',
    scope: 'openid profile email'
  });

  // Set up the token renewal function
  tokenRenewalTimeout = null;

  intMaxTokenRenew: number = 0;

  // To store the last status code
  public error_code: string;

  constructor(
    public router: Router,
    protected localStorageService: LocalStorageService,
    readonly _clients: ClientStoreService,
    readonly _layout: LayoutDashboardService,
  ) {}

  /**
   * Triggers an authentication process
   */
  public login(): void {
    // Call the authentication handler from auth0
    this.auth0.authorize();
  }

   /**
    * Handles the callback from the auth0 authentication
    */
   public handleAuthentication(): void {
    if (this.localStorageService.getItem("access_token") === null) {
      /// store redirect_path
      this._storeRedirectPath();

      // Parse the hash in the URL
      this.auth0.parseHash((err, authResult) => {
        // Ensure that we have valid data
        if (authResult && authResult.accessToken && authResult.idToken) {
          // Remove auth variables from URL for security
          window.location.hash = '';
          // Store the session variables
          this.setSession(authResult);
        }
        // Check that we're authenticated
        if (!this.isAuthenticated()) {
          // // Make sure to remove old local storage before allow logging in.
          // this.localStorageService.clear();
          // // If not, redirect to the login page
          this.login();
        }
      });
    } else if (! this.isAuthenticated()) {
      /// store redirect path
      this._storeRedirectPath();

      this.auth0.checkSession({}, (err, result) => {
        // if we received an error most likely the session
        // is no longer valid so we need to properly reauthenticate
        // the / path is the default route that handles the authentication process
        if (err) {
          return this.router.navigate(['/']);
        }

        /// store sesssion and allow the application to continue
        this.setSession(result);

        /// if we don't have an active client we will attempt to redirect to the client selection page
        if (blank(this._clients.getActiveClient())) {
          return this._layout.switchClient();
        }

        /// we don't even need the redirect path anymore
        /// as user will be redirected to the previous page they have
        this.localStorageService.removeItem('redirect_path');
      });
    } else if (blank(this._clients.getActiveClient())) {
      /// store redirect path
      this._storeRedirectPath();
      this._layout.switchClient();
    }
  }

  /**
   * Stores key values from the login in local storage
   *
   * @param authResult Response from Auth0
   */
  private setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    // We don't need the access token returned at this point
    this.localStorageService.setItem('auth0_access_token', authResult.accessToken);
    // The JWT is returned as the id token, which we ultimately want to send as the access token
    this.localStorageService.setItem('access_token', authResult.idToken);
    // Store the token expiry
    this.localStorageService.setItem('expires_at', expiresAt);
    this.localStorageService.setItem('auth_id', authResult['idTokenPayload']['sub']);
    // schedule a token renewal
    this.scheduleRenewal();
  }

  /**
   * Clears all tokens from local storage
   */
  public logout(): void {
    // Remove everything in local storage on logout.
    this.clearStorage();
    // Logout of auth0 - doesn't quite seem to work
    this.auth0.logout({"returnTo": environment.app_url + "/#account/logout"});
  }

  /**
   * Clears all tokens from local storage
   */
  public clearStorage(): void {
    // Remove tokens and expiry time from localStorage
    this.localStorageService.removeItem('access_token');
    this.localStorageService.removeItem('old_access_token');
    this.localStorageService.removeItem('expires_at');
    //Remove everything in local storage on logout.
    this.localStorageService.clear();
  }

  /**
   * Indicates whether we currently have a valid authentication token
   */
  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(this.localStorageService.getItem('expires_at'));

    // compare the current unixtime and the stored unixtime expires at
    return (new Date()).getTime() < expiresAt;
  }

  /**
   * Calls Auth0 to renew the token
   */
  public renewToken(opts: {
    redirect?: boolean;
  } = {}) {
    opts = Object.assign({
      redirect: true,
    }, opts);

    // Get access to self
    var self = this;
    // Create the promise
    var promise = new Promise((resolve, reject) => {
      // Check the session and renew
      self.auth0.checkSession({},
        function(objError, objResult) {
          // Do we have an error?
          if (objError) {
            self.clearStorage();
            // Reject the promise
            reject(false);
          } else {
            if (self.localStorageService.getItem('image') == null || self.localStorageService.getItem('image') == '') {
              self.localStorageService.setItem('temp_image', objResult['idTokenPayload']['picture'])
            }

            // Set the result
            self.setSession(objResult);
            // Success
            resolve(true);
          }
        }
      );
    });

    promise.then( response => {
      this.intMaxTokenRenew++;

      if (! opts.redirect) {
        return response;
      }

      self._storeRedirectPath();

      if (response && this.intMaxTokenRenew <= 5) {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      } else {
        this.router.navigate(['/']);
        this.intMaxTokenRenew = 0;
      }

      return response;
    });

    // Return the generated promise
    return promise;
  }

  /**
   * Schedules a refresh to occur once the token is expired
   */
  public scheduleRenewal(): void {
    // Get access to self
    var self = this;
    // Get access to self
    var expiresAt = JSON.parse(this.localStorageService.getItem('expires_at'));
    // Determine how long to wait
    var delay = expiresAt - Date.now();
    // Do we have a valid delay?
    if (delay > 0) {
      // Set schedule for renewal
      self.tokenRenewalTimeout = setTimeout(function() {
        // Renew the token
        self.renewToken();
      }, delay);
    }
  }

  private _storeRedirectPath(): void {
    const path = window.location.hash;

    /// if path is blank or contains access_token we don't want to store it
    if (blank(path) || toString(path).includes('access_token')) {
      return;
    }

    /// make sure that path don't contain logout/login or account/selection
    const regex = /\b(login|(?:do)?logout|account\/selection)\b/i;

    if (regex.test(path)) {
      return;
    }

    this.localStorageService.setItem('redirect_path', trim(path, '#'));
  }
}
