import { SchedulerItem } from './scheduler-item';
import { filled } from '../../../shared/utils/common';
import { get, isUndefined } from 'lodash-es';
import { LooseObject } from '../../../objects/loose-object';

export class SchedulerViewSettings {

  default_module: string = 'jobs';
  default_module_filter: string = 'all_jobs_awaiting_scheduling';
  slot_height_status: 'default' | 'custom' = 'default';
  slot_width: number = 50;
  slot_height: number = 100
  number_users: number = 10;

  card_setting: {
    jobs: string,
    opportunities: string,
    tasks: string
  };

  event_setting: {
    tasks: string,
    jobs: string,
    opportunities: string
  }

  indicators: {
    tasks: {
      background: {
        field?: string
        module?: string
      }
      indicator_one: {
        field?: string
        module?: string
      }
      indicator_two: {
        field?: string
        module?: string
      }
    }
    jobs: {
      background: {
        field?: string
        module?: string
      }
      indicator_one: {
        field?: string
        module?: string
      }
      indicator_two: {
        field?: string
        module?: string
      }
    }
    opportunities: {
      background: {
        field?: string
        module?: string
      }
      indicator_one: {
        field?: string
        module?: string
      }
      indicator_two: {
        field?: string
        module?: string
      }
    }
  }

  view_settings: {
    day: {
      slot_width: number,
      slot_height: number,
      slot_status: string,
      number_users: number,
    },
    month: {
      slot_width: number,
      slot_height: number,
      slot_status: string,
      number_users: number,
    }
  }

  enable_holiday: boolean = false;
  enable_holiday_country: string[] = null;

  constructor(properties?: SchedulerViewSettings) {

    if (properties) {
      this.default_module = properties.default_module;
      this.default_module_filter = properties.default_module_filter;

      if (this.default_module == 'jobs' && isUndefined(this.default_module_filter)) {
        this.default_module_filter = 'all_jobs_awaiting_scheduling'
      }

      this.slot_width = properties.slot_width;
      this.slot_height_status = properties.slot_height_status || 'default';
      this.slot_height = properties.slot_height || 100;
      this.number_users = properties.number_users;

      if (properties.card_setting) {
        this.card_setting = properties.card_setting;
      }

      if (properties.event_setting) {
        this.event_setting = properties.event_setting;
      }

      this.indicators = properties.indicators;
    } else {
      this.card_setting = {
        jobs: jobDefaultCardSetting,
        opportunities: quoteDefaultCardSetting,
        tasks: taskDefaultCardSetting,
      };
      this.event_setting = {
        jobs: jobDefaultEventSetting,
        tasks: taskDefaultEventSetting,
        opportunities: quoteDefaultEventSetting
      };

      this.indicators = {
        tasks: {
          background: {
            field: null,
            module: null,
          },
          indicator_one: {
            field: null,
            module: null,
          },
          indicator_two: {
            field: null,
            module: null,
          }
        },
        jobs: {
          background: {
            field: null,
            module: null,
          },
          indicator_one: {
            field: null,
            module: null,
          },
          indicator_two: {
            field: null,
            module: null,
          }
        },
        opportunities: {
          background: {
            field: null,
            module: null,
          },
          indicator_one: {
            field: null,
            module: null,
          },
          indicator_two: {
            field: null,
            module: null,
          }
        }
      }
    }

    this.view_settings = {
      day: {
        slot_width: get(properties, 'view_settings.day.slot_width', 100),
        slot_height: get(properties, 'view_settings.day.slot_height', 100),
        slot_status: get(properties, 'view_settings.day.slot_status', 'default'),
        number_users: get(properties, 'view_settings.day.number_users', 10),
      },
      month: {
        slot_width: get(properties, 'view_settings.month.slot_width', 100),
        slot_height: get(properties, 'view_settings.month.slot_height', 100),
        slot_status: get(properties, 'view_settings.month.slot_status', 'default'),
        number_users: get(properties, 'view_settings.month.number_users', 10),
      }
    };

    this.enable_holiday = get(properties, 'enable_holiday', false);
    this.enable_holiday_country = get(properties, 'enable_holiday_country', null);
  }

  /**
   * Gets the display for the quote card.
   *
   * @param {SchedulerItem} task
   *
   * @returns {string}
   */
  getQuoteCardDisplay(task: SchedulerItem): string {
    return this._replaceCardDisplay(this.card_setting.opportunities, task);
  }

  /**
   * Gets the display for the job card.
   *
   * @param {SchedulerItem} task
   *
   * @returns {string}
   */
  getJobCardDisplay(task: SchedulerItem): string {
    return this._replaceCardDisplay(this.card_setting.jobs, task);
  }

  /**
   * Gets the display for the task card.
   *
   * @param {SchedulerItem} task
   *
   * @returns {string}
   */
  getTaskCardDisplay(task: SchedulerItem): string {
    return this._replaceCardDisplay(this.card_setting.tasks, task);
  }

  /**
   * Gets the display for the calendar event.
   *
   * @param {string} strDefaultDisplay
   * @param {SchedulerItem} task
   *
   * @returns {string}
   */
  getEventDisplay(strDefaultDisplay: string, task: SchedulerItem): string {
    return this._replaceCardDisplay(strDefaultDisplay, task)
  }

  /**
   * retrieve view settings
   *
   * @param strViewType
   * @returns
   */
  getViewSettings(strViewType: 'day' | 'month'): LooseObject {
    return this.view_settings[strViewType];
  }

  /**
   * Replaces the values in the default card display depending on whether the task has a customer/address/due_date etc..
   *
   * @param {string} strDefaultDisplay
   * @param {SchedulerItem} task
   *
   * @returns {string}
   */
  private _replaceCardDisplay(strDefaultDisplay: string, task: SchedulerItem): string {
    if (filled(strDefaultDisplay)) {
      if (!task.hasCustomer) {
        strDefaultDisplay = strDefaultDisplay.replace("{{ customer }}", '--');
      }

      if (!task.hasJobAddress) {
        strDefaultDisplay = strDefaultDisplay.replace("{{ job_address }}", '--');
      }

      if (!task.hasQuoteAddress) {
        strDefaultDisplay = strDefaultDisplay.replace("{{ address }}", '--');
      }

      if (!task.hasDueDate) {
        strDefaultDisplay = strDefaultDisplay.replace("{{ job_due_date }}", '--');
        strDefaultDisplay = strDefaultDisplay.replace("{{ due_date }}", '--');
      }

      if (!task.hasDepartment) {
        strDefaultDisplay = strDefaultDisplay.replace("{{ department }}", '--');
      }
    }

    return strDefaultDisplay;
  }
}

const jobDefaultEventSetting = `<span><strong>{{ customer.name }}</strong>: {{ job.due_date }}</span><br><span>{{ customer.name }} | {{ job.address }}</span>`;
const jobDefaultCardSetting = `<p><strong>{{ job.job_number }}</strong>: {{ job.due_date }}</p><p><br></p><p>{{ customer.name }} | {{ job.address }}</p>`;
const quoteDefaultEventSetting = `<p><strong>{{ opportunity.opportunity_number }}</strong>: {{ opportunity.address }}</p>`;
const quoteDefaultCardSetting = `<p><strong>{{ opportunity.opportunity_number }}</strong>: <u>{{ customer.name }}</u></p><p>{{ opportunity.address }}</p>`;
const taskDefaultEventSetting = `<p><strong>{{ job.job_number }}:</strong> {{ task.activity_name }} | {{ task.notes }}</p>`;
const taskDefaultCardSetting = `<p><strong>{{ customer.name }}</strong>: {{ job.due_date }} ({{ job.status }})</p><p><br></p><p>{{ job.address }} | {{ task.notes }}</p><p><br></p>`;