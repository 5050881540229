<ng-container>
  <ng-container
    [ngTemplateOutlet]="links"
    [ngTemplateOutletContext]="{
      content: topContent
    }"
  ></ng-container>

  <div class="w-100 mt-2">
    <ng-content></ng-content>
  </div>

  <ng-container *ngIf="! topOnly" [ngTemplateOutlet]="links"></ng-container>
</ng-container>

<ng-template
  #links
  let-content="content"
>
  <div class="row">
    <ng-container *ngIf="content | filled">
      <div
        class="d-flex justify-content-start"
        fieldmagicGridColumn="6"
      >
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </div>
    </ng-container>
    <div
      [fieldmagicGridColumn]="(content | filled) ? '6' : '12'"
      class="d-flex justify-content-end"
    >
      <ul class="pagination">
        <li
          *ngIf="! hideRefresh"
          class="page-item"
        >
          <a class="page-link" (click)="onRefresh()">
            <fa-icon [icon]="['far', 'sync-alt']" aria-hidden="true"></fa-icon>
          </a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': isFirstPage$ | async}">
          <a
            class="page-link"
            (click)="onFirstPage()"
            [class.active]="! (isFirstPage$ | async)"
          >
            <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
          </a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': ! (hasPreviousPage$ | async)}">
          <a class="page-link"
            (click)="onPreviousPage()"
            [class.active]="hasPreviousPage$ | async"
          >
            <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
          </a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': ! (hasNextPage$ | async)}">
          <a
            class="page-link"
            (click)="onNextPage()"
            [class.active]="hasNextPage$ | async"
          >
            <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
