import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RecordService } from '../../../../../services/record.service';
import { NotificationService } from '../../../../../services/notification.service';
import { get as _get, cloneDeep as _cloneDeep, isNil as _isNil, trim as _trim, add as _add, get, isNil } from 'lodash-es';
import { Relate } from '../../../../../objects/relate';
import { Warehouse } from '../../../../../objects/stock-management/warehouse';
import { map, switchMap, tap, filter } from 'rxjs/operators';
import { ListingService } from '../../../../../services/listing.service';
import { SearchService } from '../../../../../services/search.service';
import { StockReceipt, StockReceiptLineItem } from '../../../../../objects/stock-management/stock-receipt';
import { ViewService } from '../../../../../services/view.service';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { LooseObject } from '../../../../../objects/loose-object';
import { StockManagementService } from '../../../../../services/stock-management.service';
import { iif, Observable, of } from 'rxjs';
import { toFormattedNumber } from '../../../../../shared/utils/numbers';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { blank, filled } from '../../../../../shared/utils/common';

@Component({
  selector: 'dialog-stock-receipt',
  templateUrl: './dialog-stock-receipt.component.html',
  styleUrls: ['./dialog-stock-receipt.component.scss'],
  providers: [
    ListingService
  ]
})
export class DialogStockReceiptComponent implements OnInit {

  /**
   * The destination field.
   *
   * @var {Relate<Warehouse>}
   */
  public objDestinationWarehouse: Relate<Warehouse>;

  /**
   * If the mode is create receipt.
   *
   * @var {boolean}
   */
  public bCreateMode: boolean = true;

  /**
   * A simple loader if the receipt
   * is being created.
   *
   * @var {boolean}
   */
  public bCreating: boolean = false;

  /**
   * Holds the status of the dialog
   * upon closing.
   *
   * @var {string}
   */
  public strAction: string = 'close';

  /**
   * Holds the list of items in the
   * stock receipt.
   *
   * @var {StockReceiptLineItem[]}
   */
  public arLineItemQty: StockReceiptLineItem[] = [];

  public objStockReceipt: StockReceipt;

  /**
   * Holds the line item of the purchase
   * order where this receipt
   * is under.
   *
   * @var {item_id: string, quantity: number[]}
   */
  public arPurchaseOrderLineItem: {
    id: string,
    item_id: string,
    quantity: number
  }[] = [];

  public bDisabled: boolean = false;

  public bLoading: boolean = true;

  public objLineItemConfig: LooseObject = {};

  public strReference: string = null;

  public strReceiptStatus: string = 'draft';

  public objModuleId: LooseObject = {
    purchase_orders: 'purchase_order_id',
    supplier_invoices: 'supplier_invoice_id'
  };

  public objParentData: {
    module: string,
    id: string,
  };

  public bHasNoAvailableReceive: boolean = false;

  /**
   * flag to check if the purchase order line item has
   * remaining quantity that is not yet invoiced
   * to prompt the user if they wish to create supplier invoice after record is received
   *
   * @var {boolean}
   */
  protected hasPurchaseOrderQuantity: boolean = false;
  /**
   * If the current line item contains
   * any invalid quantity.
   *
   * @returns {boolean}
   */
  get bInvalidQuantity(): boolean {
    return this.arLineItemQty.findIndex(item => {
      return (item.received + item.quantity) > this.getPOItem(item.id || item.item_id).quantity;
    }) > -1;
  }

  /**
   * Checks if the receipt is not
   * the same as the purchase order's
   * line items.
   *
   * @returns {boolean}
   */
  get bReceiptsNotComplete(): boolean {
    return this.arLineItemQty.findIndex(item => {
      return (item.received + item.quantity) != this.getPOItem(item.id || item.item_id).quantity
    }) > -1;
  }

  get bCreateCredit(): boolean {
    return this.data.mode === 'create' && this.data.stock_type === 'credit';
  }

  get bCreditView(): boolean {
    return this.data.mode === 'view' && this.data.stock_type === 'credit';
  }

  get bCreateReceipt(): boolean {
    return this.data.mode === 'create' && this.data.stock_type === 'receipt';
  }

  get bReceiptView(): boolean {
    return this.data.mode === 'view' && this.data.stock_type === 'receipt';
  }


  get bCreatingNewStockReceipt(): boolean {
    return !isNil(this.data.stock_receipt.id)
      && this.data.mode === 'create'
      && this.data.stock_type === 'receipt';
  }

  get bView(): boolean {
    return this.data.mode === 'view';
  }

  get bCredit(): boolean {
    return this.data.stock_type === 'credit';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      stock_receipt: StockReceipt
      stock_type: string,
      mode: string,
    },
    private record: RecordService,
    private view: ViewService,
    private dialog: MatDialogRef<DialogStockReceiptComponent>,
    private list: ListingService,
    private notification: NotificationService,
    private search: SearchService,
    private stockManagementService: StockManagementService,
    private localStorageService: LocalStorageService
  ) {
    this.objStockReceipt = this.data.stock_receipt;
    this.objParentData = this.record.getParentData();
    this.strReference = get(this.objStockReceipt, 'reference', null);

    if (this.objStockReceipt.id) {
      this.bCreateMode = false;
      this.copyLineItems();
    }

    this.getPurchaseOrderItems();
  }

  ngOnInit() {
    this.stockManagementService.getStockReceiptConfig().subscribe(data => {
      this.objLineItemConfig = data.config
      this.checkAvailableReceive();
      this.updateLineItem();
      this.getWarehouseDefaultValue().subscribe((defaultWarehouse) => {
        this.objDestinationWarehouse = new Relate<Warehouse>().buildRelates(
          switchMap(term => this.search.getRemoteData({
            model: 'warehouses',
            label: null,
            placeholder: null,
            type: "INPUT",
            remoteModule: 'warehouses'
          }, term).map(objResults => {
            return objResults.response.map(objRecord => {
              return new Warehouse(objRecord as any);
            });
          })),
          defaultWarehouse,
          defaultWarehouse.length == 1
        );
        this.bLoading = false;
      });
    });
  }

  /**
   * Simply closes the dialog.
   * If the receipt is created but cancelled before being received,
   * still display it with draft status
   *
   * @returns {void}
   */
  cancelDialog(): void {
    if (!isEmpty(this.objStockReceipt['line_items'])) {
      this.dialog.close({
        action: 'close',
        data: this.objStockReceipt
      });
    } else {
      this.dialog.close({
        action: 'close'
      });
    }
  }

  /**
   * Creates a stock receipt record by sending
   * the line items in API.
   *
   * @returns {void}
   */
  createStockReceipts(): void {
    this.bDisabled = true;
    this.bCreating = true;
    const kWarehouseId = _get(this.objDestinationWarehouse, ['value', 'id'], null);
    let jobId = null;
    let rawLineItems = [];

    if (kWarehouseId) {
      const parentData = this.record.getParentData();
      this.record.getRecord(parentData.module, parentData.id).pipe(
        map( objModuleRecord => {
          jobId = _get(objModuleRecord, ['record_details', 'job_id'], null);

          const lineItems: any[] = _get(objModuleRecord, 'record_details.line_items', []);

          for (const line of lineItems) {
            const itemID = line['item_id'];
            const isAdhoc = _trim(itemID).length < 1;

            /// check if we already have an existing item with the same item id
            /// grouped and sum the contents
            let pos = rawLineItems.findIndex((raw) => _get(raw, 'item_id') == itemID);

            if (pos === -1 || isAdhoc) {
              rawLineItems.push({
                ...line,
                ... {
                  received: 0,
                }
              });
            } else {
              rawLineItems[pos] = {
                ...rawLineItems[pos],
                'quantity': _add(
                  parseFloat(_get(rawLineItems[pos], 'quantity', 0)),
                  parseFloat(_get(line, 'quantity', 0))
                ),
              };
            }
          }

          let stockReceiptData = {
            warehouse_id: kWarehouseId,
            line_items: JSON.stringify(rawLineItems),
            has_line_items: rawLineItems.length > 0,
            type: 'receipt',
            reference: this.strReference
          };

          if (filled(this.objModuleId[parentData.module])) {
            stockReceiptData[this.objModuleId[parentData.module]] = parentData.id;
            if (this.objParentData.module === 'supplier_invoices') {
              stockReceiptData['purchase_order_id'] = this.view.arRecord.purchase_order_id;
            }
          }

          return stockReceiptData;
        }),
        tap((data) => {
          if (data.has_line_items) {
            return;
          }

          this.notification.notifyError('no_items_to_stock');
          this.bCreateMode = true;
          this.bCreating = false;
        }),
        filter((data) => data.has_line_items),
        switchMap(objStockReceiptToCreate => {
          return this.record.saveRecord('stock_receipts', objStockReceiptToCreate, '').pipe(
            map(objStockReceiptRecord => {
              let objStockReceipt: StockReceipt = {
                id: objStockReceiptRecord['body']['id'],
                status: objStockReceiptRecord['body']['status'] || 'draft',
                warehouse_id: kWarehouseId,
                warehouse_name: this.objDestinationWarehouse.value.text,
                line_items: objStockReceiptRecord['body']['line_items'],
                purchase_order_id: null,
                type: 'receipt',
                reference: this.strReference,
                supplier_invoice_id: null,
              };

              if (filled(this.objModuleId[parentData.module])) {
                objStockReceipt[this.objModuleId[parentData.module]] = parentData.id;
              }

              return new StockReceipt(objStockReceipt);
            })
          )
        })
      ).subscribe(objStockReceiptRecord => {
        this.objStockReceipt = objStockReceiptRecord;
        this.copyLineItems();
        this.updateLineItem();
        this.bCreateMode = false;
        this.bCreating = false;
        this.strAction = 'save';
        this.bDisabled = false;
      });
    } else {
      this.notification.notifyError('warehouse_required');
      this.bDisabled = false;
      this.bCreating = false;
    }
  }

  /**
   * Updates the stock receipt with
   * what has already been received.
   *
   * @returns {void}
   */
  updateStockReceiptStatus(): void {
    if (this.arLineItemQty.length < 1) {
      this.notification.notifyError('no_stocked_item_to_received');
      return;
    }
    if (this.bInvalidQuantity) {
      this.notification.notifyError('po_stock_quantity_error');
    } else {
      if (filled(_get(this.view.arRecord, 'job_id'))) {
        this.notification
          .sendConfirmation('stock_receive_email_notification', 'email_notification', 'default')
          .subscribe( response => this.createRecord(response.answer) );
      } else {
        this.createRecord();
      }
    }

  }

  /**
   * check if the parent po has delivery location of main warehouse
   *
   * @returns
   */
  getWarehouseDefaultValue(): Observable<Warehouse[]> {
    let arUserDefaultWarehouse = this.getUserWarehouse();
    if (blank(arUserDefaultWarehouse)) {
      if (
        this.view.arRecord &&
        this.view.arRecord.warehouse_id &&
        this.view.arRecord.warehouse_text &&
        this.view.arRecord.delivery_location == 'main_warehouse') {
        return of([new Warehouse({
          id: this.view.arRecord.warehouse_id,
          name: this.view.arRecord.warehouse_text,
          text: this.view.arRecord.warehouse_text,
        } as any)]);
      } else {
        return (this.bCreateMode)
          ? this.list.fetchDataAdvanceSearch({}, 'warehouses', { status: 'active' }, {}, null)
            .map(response => { return response['data'] })
          : of([new Warehouse({
              id: this.objStockReceipt.warehouse_id,
              name: this.objStockReceipt.warehouse_name
            } as any)]);
      }
    } else {
      return of(arUserDefaultWarehouse);
    }
  }

  /**
   * disable negative values when inputing on number field
   *
   * @param event
   */
  negateNegative(event: KeyboardEvent) {
    if (event.which != 8 && event.which != 0 && event.which !== 46 && event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  }

  /**
   * when user inputed greater than the max allowed credit,
   * update the credit value based on max credit value
   *
   * @param lineItem
   */
  onChangeCreditQuantity(lineItem: StockReceiptLineItem): void {
    let intMaxCredit = this.getAvailableCredit(lineItem);
    if (lineItem.credit >= intMaxCredit) {
      lineItem.credit = toFormattedNumber(intMaxCredit, {
        maxDecimalPlaces: 3
      });
    }
  }

  /**
   * create credit
   */
  createCredit(): void {
    this.bDisabled = true
    let arLineItems = this.arLineItemQty.filter(objItem => objItem.credit && objItem.credit !== 0);
    if (arLineItems.length) {
      let objCreditPayload = {
        status: this.objStockReceipt.status,
        warehouse_id: this.objStockReceipt.warehouse_id,
        warehouse_name: this.objStockReceipt.warehouse_name,
        line_items: arLineItems,
        type: 'credit'
      };

      if (filled(this.objModuleId[this.objParentData.module])) {
        objCreditPayload[this.objModuleId[this.objParentData.module]] = this.objParentData.id;
        if (this.objParentData.module === 'supplier_invoices') {
          objCreditPayload['purchase_order_id'] = this.view.arRecord.purchase_order_id;
        }
      }

      this.record.saveRecord('stock_receipts', objCreditPayload).subscribe(() => {

        this.bDisabled = false;
        this.dialog.close({
          action: 'save',
          type: 'credit',
          data: {
            status: this.objStockReceipt.status,
            warehouse_id: this.objStockReceipt.warehouse_id,
            warehouse_name: this.objStockReceipt.warehouse_name,
            line_items: arLineItems,
            type: 'credit',
            purchase_order_id: this.objStockReceipt.purchase_order_id,
            supplier_invoice_id: this.objStockReceipt.supplier_invoice_id
          }
        });
        this.view.reloadRecordView(true);
      })
    } else {

      this.bDisabled = false;
      this.notification.notifyError('please_add_credit_quantity');
    }
  }

  /**
   * get remaining quantity
   *
   * @param lineItem
   * @returns
   */
  getAvailableCredit(lineItem: StockReceiptLineItem): number {
    let id = lineItem.item_id || lineItem.id;
    let objLineItemConfig = get(this.objLineItemConfig, id);
    return (objLineItemConfig) ? objLineItemConfig.available_credit : 0;
  }

  /**
   * get total credit
   *
   * @param lineItem
   * @returns
   */
  getTotalCredited(lineItem: StockReceiptLineItem): number {
    let id = lineItem.item_id || lineItem.id;
    let objLineItemConfig = get(this.objLineItemConfig, id);
    return (objLineItemConfig) ? objLineItemConfig.total_credit : 0;
  }

  /**
   * get total credit
   *
   * @param lineItem
   * @returns
   */
  getTotalQuantity(lineItem: StockReceiptLineItem): number {
    let strId = this.getPrimaryId(lineItem);
    let objLineItemConfig = get(this.objLineItemConfig, strId);
    return get(objLineItemConfig, 'total_quantity', 0);
  }

  /**
   * get maximum receipt allowed
   *
   * @param lineItem
   * @returns
   */
  getAvailableReceipt(lineItem: StockReceiptLineItem): number {
    let strId = this.getPrimaryId(lineItem);
    let objLineItemConfig = get(this.objLineItemConfig, strId);
    return get(objLineItemConfig, 'available_receipt', 0);
  }

  /**
   * get total received stock
   *
   * @param lineItem
   * @returns
   */
  getTotalReceived(lineItem: StockReceiptLineItem): number {
    let strId = this.getPrimaryId(lineItem);
    let objLineItemConfig = get(this.objLineItemConfig, strId);
    return get(objLineItemConfig, 'total_received', 0);
  }

  /**
   * when user inputed greater than the max allowed receipt,
   * update the receipt value based on max receipt value
   *
   * @param lineItem
   */
  onChangeRemainingQuantity(lineItem: StockReceiptLineItem): void {
    let intMaxReceipt = this.getAvailableReceipt(lineItem);
    if (lineItem.received >= intMaxReceipt) {
      lineItem.received = toFormattedNumber(intMaxReceipt, {
        maxDecimalPlaces: 3
      });
    }
  }

  /**
   * create receipt
   */
  createReceipt(): void {
    this.bDisabled = true
    let arLineItems = this.arLineItemQty.filter(objItem => objItem.received && objItem.received !== 0);
    if (arLineItems.length) {
      this.record.saveRecord('stock_receipts', {
        status: this.objStockReceipt.status,
        warehouse_id: this.objStockReceipt.warehouse_id,
        warehouse_name: this.objStockReceipt.warehouse_name,
        line_items: arLineItems,
        type: 'receipt',
        purchase_order_id: this.objStockReceipt.purchase_order_id,
        supplier_invoice_id: this.objStockReceipt.supplier_invoice_id
      }).subscribe(() => {

        this.bDisabled = false;
        this.dialog.close({ action: 'save' });
      })
      this.bDisabled = false;
    } else {

      this.bDisabled = false;
      this.notification.notifyError('please_add_receipt_quantity');
    }
  }

  /**
   * get default's user warehoise
   * @returns
   */
  getUserWarehouse(): Warehouse[] {
    let objClientDetails = this.localStorageService.getJsonItem('current_client');
    if (filled(objClientDetails.warehouse_id) && filled(objClientDetails.warehouse_text)) {
      return [new Warehouse({
        id: objClientDetails.warehouse_id,
        name: objClientDetails.warehouse_text,
        text: objClientDetails.warehouse_text,
      } as any)];
    }

    return [];
  }

  /**
   * maximum value for quantity should be the available stock to be received
   *
   * @param lineItem
   */
  onChangeQuantity(lineItem: StockReceiptLineItem): void {
    let strId = this.getPrimaryId(lineItem);
    let objLineItemConfig = get(this.objLineItemConfig, strId);
    if (filled(objLineItemConfig)) {
      if (objLineItemConfig.available_receipt > 0) {
        if (lineItem.quantity > objLineItemConfig.available_receipt) {
          lineItem.quantity = toFormattedNumber(objLineItemConfig.available_receipt, {
            maxDecimalPlaces: 3
          });
        }
      } else {
        lineItem.quantity = 0;
      }
    }
  }

  /**
   * get the primary id on liene item
   *
   * @param lineItem
   * @returns
   */
  getPrimaryId(lineItem: StockReceiptLineItem): string {

    if (filled(lineItem.item_id)) {
      return lineItem.item_id;
    }

    if (filled(lineItem.id)) {
      return lineItem.id;
    }

    if (filled(lineItem.purchase_order_line_item_id)) {
      return lineItem.purchase_order_line_item_id;
    }

    return '';
  }

  /**
   * Get the purchase order items and set
   * them in the local variable for reference
   * if the receipts are complete.
   *
   * @return {void}
   */
  private getPurchaseOrderItems(): void {
    if (this.view.arRecord) {
      const lines = _get(this.view.arRecord, 'line_items', []);
      for (const line of lines) {
        const itemID = line['item_id'];
        const isAdhoc = _trim(itemID).length < 1;

        let pos = this.arPurchaseOrderLineItem.findIndex((current) => current['item_id'] == line['item_id']);

        if (pos === -1 || isAdhoc) {
          this.arPurchaseOrderLineItem.push({
            id: this.objParentData.module == 'purchase_orders' ? get(line, 'id') : get(line, 'purchase_order_line_item_id'),
            item_id: line['item_id'],
            quantity: parseFloat(line['quantity'])
          });
        } else {
          this.arPurchaseOrderLineItem[pos] = {
            ... this.arPurchaseOrderLineItem[pos],
            quantity: _add(
              parseFloat(_get(line, 'quantity', 0)),
              this.arPurchaseOrderLineItem[pos].quantity
            ),
          };
        }
      }

      this.hasPurchaseOrderQuantity = filled(lines.filter((line) => {
        const invoiceQuantity = toFormattedNumber(get(line, 'invoiced_quantity', 0));
        const expectedQuantity = toFormattedNumber(get(line, 'quantity', 0));
        return expectedQuantity > invoiceQuantity;
      }));
    }
  }

  /**
   * Retrieves the purchase order item
   * from our existing reference variable.
   *
   * @param {string} strId
   *
   * @returns {item_id: string, quantity: number}
   */
  private getPOItem(strId: string): { item_id: string, quantity: number } {
    let objLineItem = this.arPurchaseOrderLineItem.find(lineItemId => lineItemId.id == strId);
    if (_isNil(objLineItem)) {

      return this.arPurchaseOrderLineItem.find(po_item => po_item.item_id == strId);
    }

    return objLineItem;
  }

  /**
   * Another type of close method where we pass the
   * receipt record on save.
   *
   * @param {StockReceiptLineItem[]} arLineItems
   *
   * @returns {void}
   */
  private closeOnSave(arLineItems: StockReceiptLineItem[]): void {
    this.objStockReceipt.line_items = arLineItems;
    this.dialog.close({
      action: 'save',
      data: this.objStockReceipt,
      create_supplier_invoice: this.hasPurchaseOrderQuantity
    });
  }

  /**
   * Simply copies the original stock
   * receipt line item to the
   * local variable for UI manipulation.
   *
   * @returns {void}
   */
  private copyLineItems(): void {
    this.arLineItemQty = _cloneDeep(this.objStockReceipt.line_items.filter( lineItem => lineItem.id || lineItem.purchase_order_line_item_id || lineItem.item_id ));
  }

  private createRecord(hasSendEmail: boolean = false): void {
    this.bDisabled = true;
    let arLineItems = this.arLineItemQty.map(objItem => {
      objItem.received = objItem.received + objItem.quantity;
      objItem.quantity = objItem.received;
      objItem.expected_receipt_date = moment(objItem.expected_receipt_date).format('YYYY-MM-DD 00:00:00');
      return objItem;
    });

    this.record.saveRecord(
      'stock_receipts',
      {
        status: 'received',
        line_items: arLineItems,
        send_email: hasSendEmail,
        reference: this.strReference,
        type: 'receipt',
        purchase_order_id: this.objStockReceipt.purchase_order_id,
        supplier_invoice_id: this.objStockReceipt.supplier_invoice_id
      },
      this.objStockReceipt.id
    ).subscribe(() => {
      this.bDisabled = false;
      this.objStockReceipt.status = 'received';
      this.view.reloadRecordView(true);
      this.closeOnSave(arLineItems);
    });
  }

  /**
   * update the available quantity that allowed to receive
   */
  private updateLineItem(): void {
    this.arLineItemQty.map( lineItem => {

      let strId = this.getPrimaryId(lineItem);
      let objLineItemConfig = get(this.objLineItemConfig, strId);
      if (filled(objLineItemConfig)) {

        lineItem.quantity = objLineItemConfig.available_receipt;
      }

      return lineItem;
    });
  }

  /**
   * check if there are stocks needs to be received
   */
  private checkAvailableReceive(): void {
    this.bHasNoAvailableReceive = blank(Object.values(this.objLineItemConfig).filter( lineItem => lineItem.available_receipt != 0 ));
  }
}

