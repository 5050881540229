import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ListingService } from '../../services/listing.service';
import { LooseObject } from '../../objects/loose-object';
import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { debounceTime, delay, finalize, switchMap, tap } from 'rxjs/operators';
import { SearchService } from '../../services/search.service';
import { MatDialog } from '@angular/material';
import { NotificationService } from '../../services/notification.service';
import { CalendarApiService } from '../../module/calendar/services/calendar-api.service';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment, { Moment } from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { RecordService } from '../../services/record.service';
import { CalendarService } from '../../module/calendar/services/calendar.service';
import { TaskDetailsNoActivities } from '../../module/calendar/dialogs/task-details-dialog/components/task-details-no-activities.component';
import { DialogService } from '../../services/dialog.service';
import { isEmpty, toString, omit, get, cloneDeep } from 'lodash-es';
import { NotifyViaPushService } from './components/services/notify-via-push-service';
import { AvailabilityDay, AvailabilityTime } from '../../shared/components/editform/availability/availability.component';
import { TaskMetadataC } from '../../module/calendar/full-calendar/classes/metadata/task-metadata';
import { LocalStorageService } from '../../services/local-storage.service';
import { blank, filled, isId, whenFilled } from '../../shared/utils/common';
import { sprintf } from 'sprintf-js';
import { SchedulerItem } from '../new-scheduler/objects/scheduler-item';
import { JobMetadataC } from '../../module/calendar/full-calendar/classes/metadata/job-metadata';
import { OpportunityMetadataC } from '../../module/calendar/full-calendar/classes/metadata/opportunity-metadata';
import pluralize, { singular } from 'pluralize';
import { SchedulerViewSettings } from '../new-scheduler/objects/scheduler-view-settings';
import tippy from "tippy.js";
import { Client } from '../../objects/client';
import { ActivityLogTypeMetadata } from '../../module/calendar/full-calendar/classes/metadata/activity-log-type-metadata';
import { UUID } from 'angular2-uuid';
import { Users } from '../../objects/users';
import { DropdownService } from '../../services/dropdown.service';
import { AuthorizedClient } from '../data-sharing/objects/authorized-client';

@Component({
  selector: 'task-calendar',
  templateUrl: './task-calendar.component.html',
  styleUrls: ['./task-calendar.component.scss'],
  providers: [],
})
export class TaskCalendarComponent implements OnInit, AfterViewInit {

  /**
   * The calendar in the html, we do this so we can access
   * its api.
   *
   * @var {FullCalendarComponent}
   */
  @ViewChild('fullcalendar') calendarComponent: FullCalendarComponent;

  /**
   * Emit event where we set refresh activity flag.
   *
   * @var {EventEmitter<boolean>}
   */
  @Output() onSetRefreshActivities = new EventEmitter<boolean>();

  /**
   * Emits event to close parent dialog.
   *
   * @var {EventEmitter<void>}
   */
  @Output() closeParentDialog = new EventEmitter<void>();

  /**
   * Any data we need outside of parent.
   *
   * @var {LooseObject}
   */
  @Input('data') data: LooseObject;

  /**
   * Which mode of the task scheduler.
   *
   * @var {string}
   */
  @Input('mode') mode: string;

  @Input('selected-task') objSelectedTask: LooseObject;

  /**
   * Checks if the calendar is being viewed from the new scheduler.
   *
   * @var {boolean}
   */
  @Input('from-new-scheduler') bFromNewScheduler: boolean = false;

  /**
   * Contains the saved settings for the scheduler view.
   *
   * @type {SchedulerViewSettings}
   */
  @Input('scheduler-settings') objSettings: SchedulerViewSettings;

  @Input('public-holiday') arPublicHoliday: LooseObject[] = [];

  /**
   * list of available legends
   */
  @Input('legendList') arCalendarLegends: LooseObject = {};

  /**
   * Contains the selected authorized client/contractor
   */
  @Input('child-client') childClient?: AuthorizedClient;

  /**
   * The calendar options.
   *
   * @var {CalendarOptions}
   */
  options: CalendarOptions = {
    resources: [],
    businessHours: {
      startTime: '08:00',
      endTime: '17:00',
    },

    plugins: [
      timeGridPlugin,
      dayGridPlugin,
      interactionPlugin,
      bootstrapPlugin
    ],

    titleFormat: {
      month: 'long',
      year: 'numeric',
      day: 'numeric',
      weekday: 'long'
    },

    headerToolbar: {
      end: 'dayGridMonth,timeGridWeek,timeGridDay saveButton,closeButton',
      center: 'title',
      start: 'today gotodate prev,next'
    },

    bootstrapFontAwesome: {
      prev: 'icon fc-icon fc-icon-chevron-left',
      next: 'icon fc-icon fc-icon-chevron-right',
      prevYear: 'left-double-arrow',
      nextYear: 'right-double-arrow'
    },

    schedulerLicenseKey: '0258041683-fcs-1609118915',
    lazyFetching: true,
    initialView: 'timeGridWeek',
    themeSystem: 'bootstrap4',
    editable: true,
    aspectRatio: 1.6,
    slotDuration: '00:30:00',
    defaultTimedEventDuration: '01:00:00',

    dateClick: this.handleDateClick.bind(this),
    eventDrop: this.taskChange.bind(this),
    eventResize: this.taskChange.bind(this),
    datesSet: this.changeEvents.bind(this),
    eventClick: this.onEventClick.bind(this),
    eventMouseEnter: this._eventMouseEnter.bind(this),
    eventMouseLeave: this._eventMouseLeave.bind(this),

    customButtons: {
      saveButton: {
        text: this.translate.instant('save'),
        click: this.save.bind(this)
      },
      closeButton: {
        text: this.translate.instant('close'),
        click: this.onClose.bind(this)
      },
      gotodate: {
        click: this._onClickGoToDate.bind(this)
      },
    },

    eventContent: this._eventContent.bind(this),
    eventClassNames: ['fc-event-container', 'overflow-hidden'],
    nowIndicator: true,
    loading: this._isLoading.bind(this),
    eventStartEditable: true,
    eventDurationEditable: true,
    eventResizableFromStart: true,
  };

  /**
   * Simple holder for the users paging.
   *
   * @var {object}
   */
  public objPage = {
    previous: false,
    next: false,
    current: 1
  }

  /**
   * The selected user.
   *
   * If task is already scheduled, this is the
   * user that was assigned to the task.
   *
   * If task is not yet scheduled, this is the
   * user whose calendar icon was clicked.
   *
   * @var {TaskCalendarUser}
   */
  public objSelectUser: TaskCalendarUser = null;

  /**
   * The form control for searching the user.
   *
   * @var {FormControl}
   */
  public objSearchUser = new FormControl(null);

  /**
   * List of calendar user, will only show when
   * a task is not yet scheduled.
   *
   * @var {TaskCalendarUser}
   */
  public arUsers: TaskCalendarUser[] = [];

  /**
   * If the task associated is already scheduled.
   *
   * @var {boolean}
   */
  public bIsScheduled: boolean = false;

  /**
   * The current page being viewed by the user.
   *
   * @var {string | null}
   */
  public strUserPage: string | null = null;

  /**
   * The current task's id.
   *
   * @var {string}
   */
  public strTaskId: string = null;

  /**
   * Flag once events are loaded
   *
   * @var {boolean}
   */
  public bLoadedEventsOnce: boolean = false;

  /**
  * Indicator when user search is loading.
  *
  * @var {boolean}
  */
  public bUserSearchLoader: boolean = false;

  /**
   * Collection of tasks to save.
   *
   * @var {LooseObject[]}
   */
  public arToSave: LooseObject[] = [];

  /**
   * Save loading indicator.
   *
   * @var {boolean}
   */
  public bSave: boolean = false;

  /**
   * If paging is ready.
   *
   * @var {boolean}
   */
  public bPaginationReady: boolean = false;

  /**
   * If list is subcontractors or users.
   *
   * @var {string}
   */
  public strSubcontractor: 'users' | 'subcontractors' = 'users';

  /**
   * list of event that we want to remove
   */
  public arToDelete: LooseObject[] = [];

  /**
   * tippy instance so we can immediately close the tool tip
   */
  public tippyInstance;

  private _currentClient: Client;

  private get schedulableClient(): boolean {
    if (filled(this.childClient)) {
      return this.childClient.permissions[0].action === 'scheduler:full_access';
    }

    return true;
  }

  /**
   * A flag stating that the current calendar is behalf of a contrator/client
   *
   * @return {boolean}
   */
  protected get isOnBehalfOfClient(): boolean {
    return filled(this.childClient);
  }


  constructor(
    public list: ListingService,
    public calendar: CalendarApiService,
    public translate: TranslateService,
    public search: SearchService,
    public notifyPush: NotifyViaPushService,
    private record: RecordService,
    private notif: NotificationService,
    private matDialog: MatDialog,
    private calendarService: CalendarService,
    private dialog: DialogService,
    private localStorageService: LocalStorageService,
    private dropdownService: DropdownService,
  ) {
    this._currentClient = this.localStorageService.getJsonItem('current_client');
  }

  ngAfterViewInit(): void {
    if (filled(this.data)) {
      forkJoin([
        this.dropdownService.getConfig(),
        this.calendar.getSchedulerSettings(),
      ]).subscribe(([
        objLegendResponse,
        objSchedulerSettings,
      ]) => {

        let legendList: LooseObject = [];
        Object.keys(objLegendResponse).forEach( module => {
          if (!legendList[module]) {
            legendList[module] = [];
          }
          Object.keys(objLegendResponse[module]).forEach( fieldKey => {
            objLegendResponse[module][fieldKey].config.forEach( data => {
              legendList[module].push(data);
            });
          });
        })

        this.arCalendarLegends = objLegendResponse;

        this._initSchedulerSettings(
          get(
            objSchedulerSettings,
            'scheduler_settings',
            this.calendar.defaultSettings
          )
        );
        this.calendarService.setDropdownTranslatedValues(legendList);
        this._initPublicHoliday();
        this.setAfterViewInitData();
      });
    } else {
      this.setAfterViewInitData();
    }
  }

  setAfterViewInitData(): void {

    if (this.localStorageService.getJsonItem('scheduler_settings')) {
      this.objSettings = this.localStorageService.getJsonItem('scheduler_settings');
    } else {
      this.objSettings = this.calendar.defaultSettings;
    }

    if (this.data instanceof TaskMetadataC) {
      this.data['job_text'] = this.data['job']['job_number'];
      this.data['notes'] = this.data['description'];
      this.data['task_progress'] = 'awaiting_scheduling';

      if (this.data['job']) {
        this.data['job']['address_text'] =  this.data['job'];
      }

      if (this.data['department']) {
        this.data['department_id'] = this.data['department']['id'];
        this.data['department_text'] = this.data['department']['name'];
      }

      if (this.data['customer']) {
        this.data['customer_id'] = this.data['customer']['id'];
        this.data['customer_text'] = this.data['customer']['name'];
      }

      this.objSelectedTask = new SchedulerItem(this.data, this.translate);
    }

    if (this.data instanceof JobMetadataC) {
      this.objSelectedTask = new SchedulerItem(this.data);
    }

    if (this.data && isId(this.data['user_id'])) {
      this.objSearchUser.patchValue(get(this.data, 'user_text'));
      this.calendar.objStartDate = moment(this.data['activity_date']).startOf('week');
      this.calendar.objEndDate = moment(this.data['activity_date']).endOf('week');

      this.calendarComponent.getApi().gotoDate(
        this.calendar.objStartDate.local().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
      );

      this.record.getRecord('users', this.data['user_id']).subscribe(response => {
        if (response && response['record_details']) {
          this.fetchUsers([this.data['user_id']]);
          this.selectUser(response['record_details']);
        }
      })
    } else {
      this.fetchUsers();
    }

    this.objSearchUser.valueChanges
      .pipe(
        tap(() => {
          this.bUserSearchLoader = true;
        }),
        debounceTime(400),
        switchMap(terms => {
          if (terms) {
            return this.list.fetchDataAdvanceSearch(null, 'users', {
              global_search: { op: 'eq', value: terms || '' }
            })
          } else {
            return Observable.of(null);
          }
        }),
      )
      .subscribe(result => {
        if (result != null) {
          let ids = result['data'].map(search => {
            return { id: search['id'] };
          });
          if (ids.length > 0) {
            this.fetchUsers(ids);
          } else {
            this.bUserSearchLoader = false;
            this.arUsers = [];
          }
        } else {
          this.fetchUsers();
        }
      });

    if (this.bFromNewScheduler) {
      const customButtons = this.calendarComponent.getApi().getOption('customButtons');
      const customButtonsWithoutCloseBtn = omit(customButtons, 'closeButton');
      const headerToolbar = this.calendarComponent.getApi().getOption('headerToolbar');

      this.calendarComponent.getApi().setOption('customButtons', customButtonsWithoutCloseBtn);
      this.calendarComponent.getApi().setOption('headerToolbar', {
        ...headerToolbar,
        end : 'dayGridMonth,timeGridWeek,timeGridDay saveButton'
      });
    }
  }

  ngOnInit() {

    if (this.data['task_progress'] == 'scheduled' && this.data['user_id']) {
      this.bIsScheduled = true;
      this.objSelectUser = new TaskCalendarUser(this.data);
    }

    this.strTaskId = this.data['id'];

    this.options.allDayText = this.translate.instant('all_day');

    this.options.buttonText = {
      today: this.translate.instant('today'),
      month: this.translate.instant('month'),
      day: this.translate.instant('day'),
      list: this.translate.instant('list'),
      twoWeeks: this.translate.instant('two_weeks'),
      week: this.translate.instant('week'),
    };

  }

  /**
   * Call new events when the calendar view changes.
   *
   * @param {LooseObject} objEvent
   */
  changeEvents(objEvent: LooseObject) {
    if (this.bLoadedEventsOnce) {
      this.calendar.objStartDate = moment(objEvent['startStr']);
      this.calendar.objEndDate = moment(objEvent['endStr']);
      this.getEvents();
    }
  }

  /**
   * Retrieve the events of this calendar.
   *
   * @returns {void}
   */
  getEvents() {
    this.calendar.getEvents([this.objSelectUser.id], {
      view: 'users',
      ... (this.isOnBehalfOfClient && {
        for_child_client_id: this.childClient.client_id,
      }),
    })
      .pipe(
        finalize(() => { this.bLoadedEventsOnce = true }),
        delay(100) // Delay necessary to load calendar properly.
      )
      .subscribe(response => {
        let arEventList: LooseObject[] = [];
        if (response && response['tasks']) {
          response['tasks'].forEach( objTask => {
            const prop = this._formatProp(objTask);
            if (filled(prop)) {
              let objStart = moment.utc(objTask['due_date']).local();
              let objEnd = moment.utc(objTask['due_date']).local().add(objTask['estimated_duration'], 'hours');

              arEventList.push(
                this._formatEvent(objStart, objEnd, prop)
              )
            }
          });
        }

        if (response && response['activity_logs']) {
          response['activity_logs'].forEach( objActivityLog => {
            let objStart = moment.utc(objActivityLog['start']).local();
            let objEnd = moment.utc(objActivityLog['end']).local();

            if (blank(objActivityLog.log_type.id)) {
              objActivityLog.log_type.id = objActivityLog.id;
            }

            arEventList.push(
              this._formatActivityEvent(objStart, objEnd, objActivityLog)
            );
          })
        }

        this.options.events = arEventList;
        this.updateCurrentDateDisplay();
      });
  }

  /**
   * Fetch the users.
   *
   * @param {LooseObject} objFilter
   */
  fetchUsers(arIds: string[] = [], strDirection: string = 'default'): void {
    let objFilter = {
      enable_scheduling: true,
      status: 'active',
      ...(arIds.length > 0) ? { id: arIds } : {}
    };

    if (this.strSubcontractor == 'subcontractors') {
      objFilter['level'] = 'subcontractor';
    } else {
      objFilter['level'] = 'non_subcontractor';
    }

    if ((get(this._currentClient, 'is_department_restricted')) === true) {
      objFilter['where_in'] = {
        department_id: this._getUserRestrictedDepartmentIds(),
      }
    }

    if (this.isOnBehalfOfClient) {
      objFilter['other_client_id'] = this.childClient.client_id;
    }

    let pagination = this.list.beforeFetching(strDirection);

    this.list.fetchData(JSON.stringify(pagination['objPage']), 'users', JSON.stringify(objFilter)).pipe(
      finalize(() => {
        this.bPaginationReady = true;
      })
    ).subscribe(response => {
      this.arUsers = response['data'].map(user => {
        return new TaskCalendarUser(user);
      });
      this.list.afterFetching(response, strDirection);
      this.bUserSearchLoader = false;

      setTimeout(() => {
        this.setDefaultUser();
      }, 2);
    });
  }

  /**
   * Move page of the user list.
   *
   * @param {'next'|'previous'} strDirection
   */
  movePage(strDirection: 'next' | 'prev'): void {
    this.fetchUsers([], strDirection);
  }

  /**
   * When the task has changed either by resizing or by dragging.
   *
   * @param {LooseObject} objEvent
   */
  taskChange(objEvent: LooseObject): void {
    let objStart = moment(objEvent['event'].startStr);
    let objEnd = moment(objEvent['event'].endStr);

    this.setTask(
      objStart,
      objEnd,
      objEnd.diff(objStart, 'hours'),
      objEvent['event']['extendedProps'],
    );
  }

  /**
   * Set the task in the api.
   *
   * @param {Moment} strStart
   * @param {Moment} strEnd
   * @param {number} numDuration
   */
  setTask(
    strStart: Moment,
    strEnd: Moment,
    numDuration: number = 1,
    objExtendedProps: LooseObject = {},
    bCreate: boolean = false,
  ): void {
    let arListEvents = [...(this.options.events as any)];
    let eventIndex = arListEvents.findIndex(record => record.scheduler_id == objExtendedProps.scheduler_id);
    if (eventIndex >= 0) {
      arListEvents.splice(eventIndex, 1);
    }

    arListEvents.push(
      this._eventAddBackgroundColor(objExtendedProps.module, {
        id: bCreate ? null : objExtendedProps.id,
        title: this.getEventTemplate(strStart, strEnd, objExtendedProps as any),
        start: strStart.toISOString(),
        end: strEnd.toISOString(),
        extendedProps: objExtendedProps,
        scheduler_id: objExtendedProps.scheduler_id
      })
    );
    this.options.events = arListEvents;

    let recordIndex = this.arToSave.findIndex(record => record.scheduler_id == objExtendedProps.scheduler_id);
    if (recordIndex >= 0) {
      this.arToSave.splice(recordIndex, 1);
    }

    this.arToSave.push(
      this.getAdditionalModuleId({
        id: bCreate ? null : objExtendedProps.id,
        user_id: this.objSelectUser.id,
        due_date: strStart.utc().format('YYYY-MM-DD HH:mm:ss'),
        estimated_duration: numDuration,
        scheduler_id: objExtendedProps.scheduler_id,
      }, objExtendedProps.metadata)
    );

    const duration = moment().add(24, 'hours').diff(strStart, 'hours');

    this.notifyPush.notifyViaPush = duration > 0 && duration < 24;
  }

  /**
   * Saves the changes made in the calendar.
   *
   * @returns {void}
   */
  save() {
    let recordToSave = this.getEventToSave();
    if (filled(this.arToSave) || filled(this.arToDelete)) {
      this.bSave = true;
      // If viewing from activities widget of job record, no need to display confirmation when saving
      // refer to this loom video https://www.loom.com/share/5132f2c2b872429e89e750ff981fcdda
      if (this.bFromNewScheduler) {
        // we should display the notificaiton update when there are task need to create or update
        if (filled(recordToSave.tasks)) {
          this.notif
            .sendConfirmation('do_you_want_to_update_job_task_to_schedule')
            .subscribe( (confimrationAnswer) => {
              this._scheduleMultiple(recordToSave, confimrationAnswer.answer);
            })
        } else {
          this._scheduleMultiple(recordToSave, false);
        }
      } else {
        this._scheduleMultiple(recordToSave, true);
      }
    } else {
      this.notif.notifyWarning('no_new_tasks_to_schedule');
    }
  }

  /**
   * Handles the date click, this usually sets the current
   * selected task in the calendar.
   *
   * @param {LooseObject} objEvent
   */
  handleDateClick(objEvent: LooseObject): void {
    if (blank(this.objSelectUser)) {
      return;
    }

    if (this.bFromNewScheduler && blank(this.data)) {
      this.notif.notifyWarning('select_task_to_schedule_from_above_list');
      return;
    }

    let objStart = moment(objEvent.dateStr);
    let objEnd = moment(objEvent.dateStr);
    let numEstimatedDuration: number = 1;
    if (filled(this.objSelectUser) && filled(this.data)) {

      let objDay = this.objSelectUser.getAvailabilityByIndex(objStart.day());

      if (objEvent['allDay']) {

        if (objDay.has_time) {
          objStart.set("hour", objDay.hour_start);
          objEnd.set("hour", objDay.hour_end);
          numEstimatedDuration = (objDay.hour_end - objDay.hour_start) || 1;
        } else {
          objStart.set("hour", 8);
          objEnd.set("hour", 17);
          numEstimatedDuration = 9;
        }

      } else {
        objEnd.add(1, 'hour');
      }

      this.setTask(
        objStart,
        objEnd,
        numEstimatedDuration,
        this._formatProp(this.objSelectedTask.metadata),
        true
      );
    }
  }

  /**
   * Select the user and assign it to this component.
   *
   * @param {LooseObject} objUser
   * @param {boolean} bWithFetch
   */
  selectUser(objUser: LooseObject, bWithFetch: boolean = true) {

    this.objSelectUser = new TaskCalendarUser(objUser);
    this.options.events = [];

    if (this.objSelectUser.is_subcontractor) {
      this.notifyPush.notifyViaPush = false;
    }

    if (this.objSelectUser.has_availability) {
      this.options.businessHours = Object.keys(this.objSelectUser.availability).map((schedule, index) => {
        return {
          daysOfWeek: [index + 1],
          startTime: this.objSelectUser.availability[schedule].start_time || '00:00',
          endTime: this.objSelectUser.availability[schedule].end_time || '00:00'
        };
      });
    } else {
      this.options.businessHours = {
        startTime: '08:00',
        endTime: '17:00',
      };
    }

    if (bWithFetch) {

      if (!this.calendar.objStartDate) {
        this.calendar.objStartDate = moment().startOf('week');
        this.calendar.objEndDate = moment().endOf('week');
      }

      this.getEvents();
    }

  }

  /**
   * Ignores the default sorting for keyvalue
   * iterations in html
   *
   * @returns {number}
   */
  ignoreSort(): number {
    return 0;
  }

  /**
   * When the task in the scheduler is clicked.
   *
   * @return {void}
   */
  onEventClick(objEvent) {
    let assignedUser: Users|null = null;

    if (filled(this.objSelectUser)) {
      assignedUser = new Users({
        id: this.objSelectUser.id,
        name: this.objSelectUser.text,
      });
    }

    this.matDialog.open(TaskDetailsNoActivities, {
      maxWidth: '84vw',
      width: '1280px',
      data: {
        ...objEvent.event.extendedProps,
        assigned_user: assignedUser,
        scheduled_task: this.isOnBehalfOfClient,
        view: 'users',
        metadata_type: 'task',
        ... (this.isOnBehalfOfClient && {
          for_child_client_id: this.childClient.client_id,
        }),
      }
    })
      .afterClosed()
      .subscribe(objDialogResult => {
        if (objDialogResult) {
          // Update the activities listing if we unschedule a task
          if (objDialogResult.action === 'unschedule') {
            //this.bShouldRefreshActivities = true;
            this.onSetRefreshActivities.next(true);
          }
          if (objDialogResult.action) {
            this.getEvents();
          }
        }

      });
  }

  onClose(): void {
    this.closeParentDialog.emit();
  }

  /**
   * Formats the properties for tooltip purposes.
   *
   * @param {number} numEstimatedDuration
   *
   * @returns {LooseObject}
   */
  formatExtendedProps(numEstimatedDuration: number, schedulerItem: SchedulerItem): LooseObject {

    let objProps = {
      name: this.data['activity_name'],
      job: get(this.data, 'job'),
      assigned_user: {
        id: this.objSelectUser.id
      },
      description: this.data['notes'],
      department: null,
      due_date: null,
      estimated_duration: 1,
      id: this.data['id'],
      metadata_type: 'task',
      opportunity: get(this.data, 'opportunity'),
      parent_id: null,
      priority: 'normal',
      status: '',
      viewable: true,
      item: schedulerItem,
    }

    if (this.data['department_id']) {
      objProps.department = {
        id: this.data['department_id'],
        name: this.data['department_text']
      }
    }

    if (this.data['customer_id']) {
      const strModule: 'job' | 'opportunity' = filled(objProps.job)
        ? 'job'
        : 'opportunity'

      objProps[strModule]['customer'] = {
        id: this.data['customer_id'],
        name: this.data['customer_text']
      }
    }

    if (this.data['job'] && this.data['job']['address_text']) {
      objProps.job['full_address'] = this.data['job']['address_text'];
    }

    if (filled(this.data['opportunity']) && filled(get(this.data['opportunity'], 'address_text'))) {
      objProps.opportunity['full_address'] = this.data['opportunity']['address_text'];
    }

    return objProps;
  }

  /**
   * set default user, if the current user is not on the list, use the first one
   */
  setDefaultUser(): void {
    if (blank(get(this.data, 'user_id'))) {
      let userId = this.localStorageService.getItem('user_id');
      let currentUser = this.arUsers.find(user => user.id == userId);

      this.calendar.objStartDate = whenFilled(
        this.data['activity_date'],
        {
          then: () => moment(this.data['activity_date']).startOf('week'),
          else: () => moment().startOf('week'),
        },
      );

      this.calendar.objEndDate = whenFilled(
        this.data['activity_date'],
        {
          then: () => moment(this.data['activity_date']).endOf('week'),
          else: () => moment().endOf('week'),
        }
      );

      this.calendarComponent.getApi().gotoDate(
        this.calendar.objStartDate.local().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
      );

      if (filled(currentUser)) {
        this.selectUser(currentUser);
      } else {
        this.selectUser(this.arUsers[0]);
      }
    }
  }

  getEventTemplate(startTime, endTime, objTask: SchedulerItem): string {
    let eventTemplate = '<div class="row">';

    // add event module icon
    eventTemplate += '<div class="col-12">'
    if (objTask.module == 'opportunities' || (objTask.module === 'tasks' && filled(get(objTask, 'metadata.opportunity')))) {
      eventTemplate += '<span class="bg-warning rounded-circle d-inline-block justify-content-center align-items-center task-type-chip m-1"><span class="d-flex" style="position: relative; left: 5px; width: 20px">Q</span></span>';
    } else {
      eventTemplate += '<span class="bg-success rounded-circle d-inline-block justify-content-center align-items-center task-type-chip m-1"><span class="d-flex" style="position: relative; left: 5px; width: 20px;">J</span></span>';
    }

    let strIndicatorOne = this._getIndicatorColor('indicator_one', objTask);
    if (filled(strIndicatorOne)) {
      eventTemplate += '<span class="rounded-circle d-inline-block task-type-chip m-1" style="background-color:' +strIndicatorOne+ '; width: 20px;">&nbsp;</span>';
    }

    let strIndicatorTwo = this._getIndicatorColor('indicator_two', objTask);
    if (filled(strIndicatorTwo)) {
      eventTemplate += '<span class="rounded-circle d-inline-block task-type-chip m-1" style="background-color:' +strIndicatorTwo+ '; width: 20px;">&nbsp;</span>';
    }
    if (objTask.metadata.reschedulable == true) {
      // add event delete button
      eventTemplate += '<span class="d-flex justify-content-center align-items-center float-right task-type-chip m-1 remove-event">'
        + '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'
        + '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>'
        + '<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>'
        + '</svg>'
        +'</span>';
    }

    eventTemplate += '</div>'

    // event content
    eventTemplate += '<div class="col-12"><div class="mr-1 ml-1 mb-1">';

    if (filled(this.objSettings) && filled(this.objSettings.event_setting[objTask.module])) {
      let metadata = cloneDeep(objTask.metadata);
      eventTemplate += this.calendarService.applyDataToTemplate(this.objSettings.event_setting[objTask.module], metadata);
    }
    eventTemplate += '</div></div>';

    // tool tip for event content
    let objIndicatorOne = this._getIndicatorConfig('indicator_one', objTask);
    let objIndicatorTwo = this._getIndicatorConfig('indicator_two', objTask);
    eventTemplate += '<div class="col-12"><div class="mr-1 ml-1 mb-1 d-none fc-calendar-event">';
    if (filled(objIndicatorOne.field) && filled(objIndicatorOne.value)) {
      eventTemplate += '<div>';
      eventTemplate += `<span style="font-size: 12px;">${objIndicatorOne.field}: ${objIndicatorOne.value}</span>`;
      eventTemplate += '</div>';
    }
    if (filled(objIndicatorTwo.field) && filled(objIndicatorTwo.value)) {
      eventTemplate += '<div>';
      eventTemplate += `<span style="font-size: 12px;">${objIndicatorTwo.field}: ${objIndicatorTwo.value}</span>`;
      eventTemplate += '</div>';
    }
    eventTemplate += sprintf('<div class="col-12 p-0">%s - %s</div>', startTime.format('h:mm'), endTime.format('h:mm'));
    eventTemplate += '<hr class="bg-light mt-2 mb-2"/>';

    if (filled(this.objSettings) && this.objSettings.event_setting[objTask.module]) {
      let metadata = cloneDeep(objTask.metadata);
      eventTemplate += this.calendarService.applyDataToTemplate(this.objSettings.event_setting[objTask.module], metadata);
    }
    eventTemplate += '</div></div>';


    // end event of template
    eventTemplate += '</div>';
    return eventTemplate;
  }

  getAdditionalModuleId(currentData, task: TaskMetadataC | JobMetadataC | OpportunityMetadataC): LooseObject {
    if (task.metadata_type == 'task') {
      currentData['activity_id'] = task.id;
      if (filled(task['job'])) {
        currentData['job_id'] = task['job'].id;
      }
      if (filled(task['opportunity'])) {
        currentData['opportunity_id'] = task['opportunity'].id;
      }
    } else {
      if (task.metadata_type == 'job') {
        currentData['job_id'] = task.id;
      }
      if (task.metadata_type == 'opportunity') {
        currentData['opportunity_id'] = task.id;
      }
    }
    return currentData;
  }

  ngOnChanges({ childClient }: SimpleChanges): void {
    if (childClient && ! childClient.isFirstChange() && childClient.currentValue !== childClient.previousValue) {
      this.fetchUsers();
      this.calendarComponent.getApi().setOption('eventStartEditable', this.schedulableClient);
      this.calendarComponent.getApi().setOption('eventDurationEditable', this.schedulableClient);
      this.calendarComponent.getApi().setOption('eventResizableFromStart', this.schedulableClient);
      if (this.schedulableClient) {
        this.calendarComponent.getApi().setOption('headerToolbar', {
          end: 'dayGridMonth,timeGridWeek,timeGridDay saveButton,closeButton',
          center: 'title',
          start: 'today gotodate prev,next'
        });
      } else {
        this.calendarComponent.getApi().setOption('headerToolbar', {
          end: 'dayGridMonth,timeGridWeek,timeGridDay',
          center: 'title',
          start: 'today gotodate prev,next'
        });
      }
    }
  }

  private _getIndicatorField(module: string, key: string): { field: string, module: string } {
    if (
      filled(this.objSettings) &&
      filled(this.objSettings.indicators[module])
      && filled(this.objSettings.indicators[module][key])) {

      let objIndicator = this.objSettings.indicators[module][key];
      if (filled(objIndicator) && filled(objIndicator.field)) {

        return {
          module: singular(objIndicator.module || ''),
          field: objIndicator.field,
        };
      }
    }

    return null;
  }

  private _getIndicatorColor(key: string, item: SchedulerItem): string {
    let objField = this._getIndicatorField(item.module, key);
    if (filled(objField) && filled(objField.module) && filled(objField.field)) {
      let strIndicatorValue = get(item.metadata, `${objField.module}.${objField.field}`, null);
      if (filled(objField) && filled(strIndicatorValue)) {

        return this._getDropdownColor(objField.module, objField.field, strIndicatorValue);
      }
    }

    return '';
  }

  private _getIndicatorConfig(key: string, item: SchedulerItem): LooseObject {
    let objField = this._getIndicatorField(item.module, key);
    if (filled(objField) && filled(objField.module) && filled(objField.field)) {
      let strIndicatorValue = get(item.metadata, `${objField.module}.${objField.field}`, null);
      if (filled(objField) && filled(strIndicatorValue)) {

        return {
          field: this.translate.instant(objField.field),
          value: this.calendarService.getTemplateTranslation(objField.module, objField.field, strIndicatorValue)
        };
      }
    }

    return {
      field: null,
      value: null
    };
  }

  private _getDropdownColor(module: string, field: string, value: string): string {
    // if the module is task, change it to activity
    module = (module == 'task') ? 'activity' : module;
    let strModule = pluralize(module);
    if (
      filled(this.arCalendarLegends[strModule])
      && filled(this.arCalendarLegends[strModule][field])) {

      let objField = this.arCalendarLegends[strModule][field].config;
      let objOption = objField.find(data => data.key == value);

      return filled(objOption) && filled(objOption.color) ? objOption.color : '';
    }
    return '';
  }

  private _eventAddBackgroundColor(module: string, event: LooseObject): LooseObject {
    let strBackgroundColor = '#3788d8';
    let objIndicator = this._getIndicatorField(module, 'background');
    if (filled(objIndicator) && filled(objIndicator.module) && filled(objIndicator.field)) {
      let strRecordData = get(event.extendedProps.metadata, `${objIndicator.module}.${objIndicator.field}`);
      if (filled(strRecordData)) {

        let strColor = this._getDropdownColor(objIndicator.module, objIndicator.field, strRecordData);
        if (filled(strColor)) {

          strBackgroundColor = strColor;
        }
      }
    }

    event.backgroundColor = strBackgroundColor;
    event.borderColor = strBackgroundColor;

    return event;
  }

  private _eventContent(info) {
    let parentContainer = document.createElement('div');

    parentContainer.innerHTML = info.event.title;
    if (parentContainer.querySelector('.remove-event')) {
      parentContainer.querySelector('.remove-event').addEventListener("click", (e: Event) => {

        e.stopPropagation();
        info.event.remove();

        if (filled(info.event._def.extendedProps.id)) {
          this.arToDelete.push(info.event._def.extendedProps);
        }

        let schedulerId = info.event._def.extendedProps.scheduler_id;
        if (filled(schedulerId)) {
          let taskScheduledIndex = this.arToSave.findIndex( scheduledTask => scheduledTask.scheduler_id == schedulerId);
          if (taskScheduledIndex >= 0) {
            this.arToSave.splice(taskScheduledIndex, 1)
          }
          let eventScheduledIndex = (this.options.events as LooseObject[]).findIndex( events => events.scheduler_id == schedulerId);
          if (eventScheduledIndex >= 0) {
            (this.options.events as LooseObject[]).splice(eventScheduledIndex, 1)
          }
        }
      })
    }
    return {
      domNodes:[ parentContainer ]
    };
  }

  private _formatEvent(startTime, endTime, props: SchedulerItem): LooseObject {
    return this._eventAddBackgroundColor(props.module, {
      id: props['id'],
      title: this.getEventTemplate(startTime, endTime, props),
      start: startTime.toISOString(),
      end: endTime.toISOString(),
      extendedProps: props,
      scheduler_id: props.scheduler_id
    });
  }

  private _eventMouseEnter(event): void {
    let calendarEvent = event.el.querySelector('.fc-calendar-event');
    this.tippyInstance = tippy(event.el, {
      theme: 'light',
      allowHTML: true,
      animation: 'scale-subtle',
      content: filled(calendarEvent) ? calendarEvent.innerHTML : null,
    });
  }

  private _eventMouseLeave(event): void {
    if (filled(this.tippyInstance)) {
      this.tippyInstance.destroy();
    }
  }

  private _formatProp(item: any): SchedulerItem {
    if (item instanceof TaskMetadataC || item instanceof JobMetadataC || item instanceof OpportunityMetadataC) {
      return new SchedulerItem(item, this.translate, item.id);
    }

    if (filled(item.activity_id)) {
      return new SchedulerItem(new TaskMetadataC(item), this.translate, item.id);
    } else if (filled(item.job)) {
      return new SchedulerItem(new JobMetadataC(item.job), null, item.id);
    } else if (filled(item.opportunity)) {
      return new SchedulerItem(new OpportunityMetadataC(item.opportunity), null, item.id);
    }
  }

  /**
   * When the subcontractor filter is toggled.
   *
   * @returns {void}
   */
  public onSubcontractorToggle(): void {

    if (this.strSubcontractor == 'subcontractors') {
      this.notifyPush.notifyViaPush = false;
    }

    this.fetchUsers();
  }

  /**
   * Gets the tooltip display for the task.
   *
   * @returns {string}
   */
  private _getTaskText(): string {
    if (filled(this.data['job'])) {
      return get(this.data, 'job_text');
    }

    if (filled(this.data['opportunity'])) {
      return get(this.data, 'opportunity_text');
    }

    return '';
  }

  private getEventToSave(): {
    tasks: LooseObject[],
    tasks_delete: LooseObject[],
    activities: LooseObject[],
    activities_delete: LooseObject[],
  } {
    return {
      tasks: this.arToSave.map( objTask => {
        delete objTask['metadata'];
        return objTask;
      }),
      tasks_delete: this.arToDelete.map( objDeletedTask => {
        return { id: objDeletedTask.id }
      }),
      activities: [],
      activities_delete: [],
    }
  }

  private _scheduleMultiple(objRecordToSave, bMarkAsScheduled: boolean): void {
    this.calendar.scheduleMultipleTask(
      objRecordToSave,
      {
        assigned_type: 'users',
        notify_via_push: this.notifyPush.notifyViaPush,
        notification_note: this.notifyPush._customNotificationMessage,
      },
      bMarkAsScheduled
    ).subscribe(response => {
      if (response['message'] == 'success') {
        this.bSave = false;
        this.notif.notifySuccess('success');
        this.onSetRefreshActivities.next(true);
        this.onClose();

        this.arToSave = [];
        this.arToDelete = [];
        this.calendarService.updateForSchedulingList.next({});
      } else {
        this.notif.notifyWarning(response['message']);
        this.bSave = false;
      }
    });
  }

  private _getUserRestrictedDepartmentIds(): string[] {
    const arRawDepartments = get(this._currentClient, 'department_id', []) || [];

    return arRawDepartments
      .filter(item => isId(get(item, 'id')))
      .map(item => get(item, 'id'));
  }

  /**
   * update the current date displayed in the calendar
   */
  private updateCurrentDateDisplay(): void {
    this.setCalendarBusinessHours();
    if (filled(this.calendarComponent.getApi().getDate())) {

      let schedulerSettings = this.localStorageService.getJsonItem('scheduler_settings');
      const bEnableHoliday: boolean = get(schedulerSettings, 'enable_holiday', false);
      if (bEnableHoliday && filled(this.arPublicHoliday)) {

        let currentTimeGridView = this.calendarComponent.getApi().view;

        if (currentTimeGridView.type === 'dayGridMonth') {
          this._dayGridMonthHoliday();
        }

        if (currentTimeGridView.type === 'timeGridWeek') {
          this._timeGridWeekHoliday();
        }

        if (currentTimeGridView.type === 'timeGridDay') {
          this._timeGridDayHoliday();
        }
      }
    }
  }

  /**
   * set the calendar's business hours
   */
  private setCalendarBusinessHours(): void {
    const objBusinessHours = this._getClientBusinessHours();
    if (filled(objBusinessHours)) {
      // we need to list the days of week to set the correnct index for each days of week
      // see: https://fullcalendar.io/docs/v5/businessHours
      const arDaysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      this.options.businessHours = arDaysOfWeek.map((strDaysOfWeek, index) => {
        let strStartTime = get(objBusinessHours, `${strDaysOfWeek}.start_time`);
        let strEndTime = get(objBusinessHours, `${strDaysOfWeek}.end_time`);
        return {
          daysOfWeek: [index],
          startTime: filled(strStartTime) ? strStartTime : '08:00',
          endTime: filled(strEndTime) ? strEndTime : '17:00',
        };
      });
    } else {
      this.options.businessHours = {
        startTime: '08:00',
        endTime: '17:00'
      };
    }
  }

  /**
   * retrieve business hours from scheduler settings
   */
  private _getClientBusinessHours(): LooseObject {
    const objClientConfig = cloneDeep(this.localStorageService.getJsonItem('current_client'));
    if (filled(objClientConfig) && filled(objClientConfig.config) && filled(objClientConfig.config.business_hours)) {
      return objClientConfig.config.business_hours;
    }
    return {};
  }

  private _dayGridMonthHoliday(): void {
    let strCurrentMonth = moment(this.calendarComponent.getApi().getDate()).format('YYYY-MM');
    let arHolidayThisMonth = this.arPublicHoliday.filter( objHoliday => (objHoliday.date.indexOf(strCurrentMonth) > -1) );
    if (filled(arHolidayThisMonth)) {
      let arEvents: LooseObject = this.options.events as LooseObject;
      let objHolidayGroup = arHolidayThisMonth.reduce(function (objParent, objChild) {
        objParent[objChild.date] = objParent[objChild.date] || [];
        objParent[objChild.date].push(objChild);
        return objParent;
    }, Object.create(null));
      Object.keys(objHolidayGroup).forEach( strDate => {
        arEvents.unshift({
          start: strDate,
          title: this.generateHolidayTemplate(objHolidayGroup[strDate]),
          backgroundColor: '#dc3545',
          borderColor: '#dc3545',
        });
      })
      this.options.events = arEvents;
    }
  }

  private _timeGridWeekHoliday(): void {
    let strCurrentMonth = moment(this.calendarComponent.getApi().getDate()).format('YYYY-MM');
    let arHolidayThisMonth = this.arPublicHoliday.filter( objHoliday => (objHoliday.date.indexOf(strCurrentMonth) > -1) );
    let arDaysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    // WIP
  }

  private _timeGridDayHoliday(): void {
    // WIP
  }

  private generateHolidayTemplate(objToolTipContent: LooseObject[] = []) {
    let arHolidayContent = [];
    let eventTemplate = '<div class="row">';

    eventTemplate += '<div class="col-12"><span class="pl-1">' +this.translate.instant('holiday')+ '</span></div>';

    if (filled(objToolTipContent)) {
      objToolTipContent.forEach( objHoliday => {
        arHolidayContent.push('<span>' +objHoliday.localName+ ' (' +objHoliday.countryCode+ ')</span>')
      });
    }

    eventTemplate += '<div class="col-12"><div class="mr-1 ml-1 mb-1 d-none fc-calendar-event">'
    eventTemplate += filled(arHolidayContent) ? arHolidayContent.join('<br />') : this.translate.instant('holiday');
    eventTemplate += '</div></div>';

    eventTemplate += '</div>';

    return eventTemplate;
  }

  private _getActivityLogEvent(objTask: ActivityLogTypeMetadata): string {
    let eventTemplate = '<div class="row">';

    // event content
    eventTemplate += '<div class="col-12">';
    eventTemplate += '<span class="mr-1 ml-1 mb-1 fc-calendar-event" style="line-height: 1.8">' +objTask.name+ '</span>';
    eventTemplate += '<span class="d-flex justify-content-center align-items-center float-right task-type-chip m-1 remove-event">'
      + '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'
      + '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>'
      + '<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>'
      + '</svg>'
      +'</span>';
    eventTemplate += '</div>'

    // end event of template
    eventTemplate += '</div>';

    return eventTemplate;
  }

  private _formatActivityEvent(objStart, objEnd, objActivityMetadata): LooseObject {
    return {
      id: objActivityMetadata['id'],
      resourceId: objActivityMetadata['assigned_user']['id'],
      title: this._getActivityLogEvent(new ActivityLogTypeMetadata(objActivityMetadata.log_type)),
      start: objStart.toISOString(),
      end: objEnd.toISOString(),
      extendedProps: objActivityMetadata,
      backgroundColor: 'rgb(106, 13, 173)',
      borderColor: 'rgb(106, 13, 173)',
      scheduler_id: UUID.UUID(),
      module: 'activity_logs',
      metadata: objActivityMetadata,
    };
  }

  private _isLoading(bLoading: boolean): void {
    this.calendarService.calendarAreLoading.next(bLoading);
    if (bLoading == false) {
      this._updateGotoDateButton();
    }
  }

  /**
   * update the go to date button and add icon
   */
  private _updateGotoDateButton(): void {
    let objGoToDateButton =  <HTMLElement>document.querySelector('.fc-gotodate-button');
    if (filled(objGoToDateButton)) {
      let strGoToDateIcon = '<span class="gotodate-icon" [owlDateTimeTrigger]="dateInput">'
        + '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-alt" class="svg-inline--fa fa-calendar-alt fa-w-14 fa-lg" role="img" viewBox="-50 -20 550 550">'
        + '<path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"></path>'
        + '</svg>'
        +'</span>';

      if (blank(objGoToDateButton.querySelector('.gotodate-icon'))) {
        objGoToDateButton.innerHTML = strGoToDateIcon;
      }
    }
  }

  /**
   * on click go to date button
   *
   * @param event
   */
  private _onClickGoToDate(): void {
    let objGotoDateInput = <HTMLElement>document.querySelector('.go-to-date-input');
    objGotoDateInput.click();
  }

  /**
   * on change go to date
   * @param event
   */
  _onGoToDateChange(event): void {
    let currentTimeGridView = this.calendarComponent.getApi().view;

    if (currentTimeGridView.type === 'dayGridMonth') {
      this.calendarComponent.getApi().gotoDate(moment(event.value._d).startOf('month').format("YYYY-MM-DD HH:mm:ss"));
    }

    if (currentTimeGridView.type === 'timeGridWeek') {
      this.calendarComponent.getApi().gotoDate(moment(event.value._d).isoWeekday(0).format("YYYY-MM-DD HH:mm:ss"));
    }

    if (currentTimeGridView.type === 'timeGridDay') {
      this.calendarComponent.getApi().gotoDate(moment(event.value._d).format("YYYY-MM-DD HH:mm:ss"));
    }
  }

  /**
   * store the scheduler settings in local storage
   *
   * @param objSchedulerSetting
   */
  private _initSchedulerSettings(objSchedulerSetting: LooseObject): void {
    if (filled(objSchedulerSetting)) {
      this.objSettings = new SchedulerViewSettings(objSchedulerSetting as SchedulerViewSettings);
      this.localStorageService.setJsonItem('scheduler_settings', this.objSettings);
    }
  }

  /**
   * get the public holiday when enable_holiday_country is true
   */
  private _initPublicHoliday(): void {
    if (filled(this.objSettings.enable_holiday) && filled(this.objSettings.enable_holiday_country)) {
      let arCountryList = typeof(this.objSettings.enable_holiday_country) == 'string'
        ? [this.objSettings.enable_holiday_country]
        : this.objSettings.enable_holiday_country
      forkJoin(arCountryList.map(
        strCountry => this.calendar.getPublicHoliday(strCountry)
      )).subscribe( arCountriesResponse => {
        arCountriesResponse.forEach( arHoliday => {
          this.arPublicHoliday = [ ...this.arPublicHoliday, ...arHoliday];
        });
      })
    }
  }
}

export class TaskCalendarUser {

  id: string;
  text: string;
  show_availablity: boolean;
  status: string;
  is_subcontractor: boolean = false;
  availability: AvailabilityDay;

  get has_availability() {
    let bHasAvailability: boolean = false;

    Object.keys(this.availability).forEach(schedule => {
      if (this.availability[schedule].start_time != null) {
        bHasAvailability = true;
      }
      if (this.availability[schedule].end_time != null) {
        bHasAvailability = true;
      }
    });

    return bHasAvailability;
  }

  getAvailabilityByIndex(numIndex: number): AvailabilityTime {
    return this.availability[{
      0: 'sunday',
      1: 'monday',
      2: 'tuesday',
      3: 'wednesday',
      4: 'thursday',
      5: 'friday',
      6: 'saturday',
    }[numIndex]];
  }

  constructor(properties: LooseObject) {

    this.text = properties.user_text || properties.text;
    this.id = properties.user_id || properties.id;
    this.status = properties.status;
    this.show_availablity = false;
    this.availability = new AvailabilityDay(properties.availability);

    this.is_subcontractor = properties.is_subcontractor || (properties.level && properties.level == 'subcontractor');
  }

  start_time: string;
  end_time: string;

  get has_time(): boolean {
    throw new Error('Method not implemented.');
  }
  get hour_start(): number {
    throw new Error('Method not implemented.');
  }
  get hour_end(): number {
    throw new Error('Method not implemented.');
  }
  get display_start(): string {
    throw new Error('Method not implemented.');
  }
  get display_end(): string {
    throw new Error('Method not implemented.');
  }
  get duration(): number {
    throw new Error('Method not implemented.');
  }
  getDisplay(strTime: string[]): string {
    throw new Error('Method not implemented.');
  }
}
