import { Observable } from "rxjs/Observable";
import { Select } from "./select";
import { Subject } from "rxjs/Subject";

export class AssetItems {

  /**
   * Asset type for the current
   * line item
   */
  asset_type_id: string;

  /**
   * Asset group for the current
   * line item
   */
  asset_tags_id: string[];

  /**
   * Set of id and name of the selected
   * checklist
   */
  checklist_ids: string[];

  /**
   * Department for the current
   * line item.
   */
  department_id: string;

  /**
   * Price per Asset.
   *
   */
  price_per_asset: number;

  /**
   * Time per Asset.
   */
  time_per_asset: string;

  id: string;

  constructor(properties: {
    asset_type_id?: string
    asset_tags_id?: string[]
    department_id?: string
    price_per_asset?: number
    checklist_ids?: string[]
    time_per_asset?: string
  } = {}){
    this.asset_type_id = properties.asset_type_id;
    this.asset_tags_id = properties.asset_tags_id;
    this.department_id = properties.department_id;
    this.price_per_asset = properties.price_per_asset;
    this.checklist_ids = properties.checklist_ids;
    this.time_per_asset = properties.time_per_asset;
  }
}