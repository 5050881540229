import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTreeModule } from '@angular/material/tree';
import { BarcodeOptionComponent } from './barcode/barcode.component';
import { ListComponent } from './barcode/list/list.component';
import { SharedModule } from '../../../../modules/shared.module';
import { SharedInputModule } from '../../../../modules/shared-input.module';
import { MatDialogModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatTreeModule,
    SharedInputModule,
    MatDialogModule,
    SharedModule.forRoot(),
  ],
  exports: [
    MatTreeModule,
  ],
  declarations: [
    ListComponent,
  ],
  entryComponents: [
    ListComponent,
    BarcodeOptionComponent,
  ]
})
export class OptionsModule { }
