import { Component, OnInit, HostListener, Inject, ElementRef } from '@angular/core';

import { chunk, cloneDeep } from 'lodash-es';
import { UUID } from 'angular2-uuid';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, Observable, Subject, concat, of, throwError, empty } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, concatMap, map, catchError } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

import { FormService } from '../../../../../../services/form.service';
import { ViewService } from '../../../../../../services/view.service';
import { RecordService } from '../../../../../../services/record.service';
import { SearchService } from '../../../../../../services/search.service';
import { ListingService } from '../../../../../../services/listing.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { StockTransferQuantitiesComponent } from '../stock-transfer-quantities/stock-transfer-quantities.component';

import { Select } from '../../../../../../objects/select';
import { Relate } from '../../../../../../objects/relate';
import { LooseObject } from '../../../../../../objects/loose-object';
import { GlobalRecord } from '../../../../../../objects/global-record';
import { Warehouse } from '../../../../../../objects/stock-management/warehouse';
import { StockLevel } from '../../../../../../objects/stock-management/stock-level';
import { StockTransfer } from '../../../../../../objects/stock-management/stock-transfer';
import { StockTransferQuantity } from '../../../../../../objects/stock-management/stock-transfer-quantity';
import { AdvanceSearchboxTemplate } from '../../../../../../objects/advance-searchbox';
import { Router } from '@angular/router';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [ ListingService ]
})
export class FormComponent implements OnInit {

  /**
   * store's the stock transfer metadata
   */
  objStockTransferMetadata = {
    warehouse_source_id: {
      key: 'warehouse_source_id',
      controlType: 'relate',
      default_value: null,
      deletable: false,
      label: 'warehouse_source',
      module: 'warehouses',
      readonly: false,
      required: true,
      type: 'relate',
      is_hidden: false,
      clearable: false,
    },
    warehouse_destination_id: {
      key: 'warehouse_destination_id',
      controlType: 'relate',
      default_value: null,
      deletable: false,
      label: 'warehouse_destination',
      module: 'warehouses',
      readonly: false,
      required: true,
      type: 'relate',
      is_hidden: false,
      clearable: false,
      disabled: true,
    },
  };

  /**
   * store's the current warehouses options
   */
  arWarehouseList: {
    warehouse_source: Array<WarehouseOption>
    warehouse_destination: Array<WarehouseOption>
  } = {
    warehouse_source: [],
    warehouse_destination: []
  };

  /**
   * store's the relate field for warehouses
   */
  objStockTransferRelate: {
    warehouse_source: Relate<Warehouse>
    warehouse_destination: Relate<Warehouse>
  } = {
    warehouse_source: new Relate<Warehouse>(),
    warehouse_destination: new Relate<Warehouse>()
  }

  /**
   * stock transfer form
   */
  stockTransferForm: FormGroup;

  /**
   * store the stock level per product
   */
  objStockLevelQuantity: {
    [key: string]: ItemStockTransferQuantity
  } = {};

  /**
   * store the stock transfer quantity
   */
  arStockTransferQuantityForm: Array<StockTransferQuantityConfig> = [];

  /**
   * store the stock transfer quantity
   */
  arPaginationList: Array<Array<StockTransferQuantityConfig>> = [];

  /**
   * store the stock transfer quantity
   */
  arPaginationCurrentData: Array<StockTransferQuantityConfig> = [];

  /**
   * determine the current page
   */
  intPaginationCurrentPage: number = 1;

  /**
   * determine the to number on current page
   */
  intPaginationToData: number = 10;

  /**
   * determine the from number on current page
   */
  intPaginationFromData: number = 1;

  /**
   * number of record per page
   */
  intPaginationDataPerPage: number = 10;

  /**
   * store all selected items
   */
  selectedTransferQuantityItems: LooseObject = {};

  /**
   * determine if form is submitted
   */
  bSubmitted: boolean = false;

  /**
   * determine if the stock transfer quantity forms is loading
   */
  bStockTransferQuantitiesLoading: boolean = true;

  /**
   * store the selected warehouse
   */
  objSelectedWarehouse: {
    warehouse_destination: Warehouse
    warehouse_source: Warehouse
  } = {
    warehouse_destination: null,
    warehouse_source: null
  };

  /**
   * store the current view of stock levels
   */
  objStockLevelDetails: StockLevel;

  /**
   * store the selected stock transfer
   */
  objStockTransferDetails: StockTransfer;

  /**
   * List of observable subscription that would be cleaned up after this component is unmounted/destroy
   *
   * @var {Subscription[]}
   */
  protected subscriptions: Subscription[] = [];

  /**
   * determine if we need to change the class
   */
  matDialogContentClass: string = 'overflow-visible';

  /**
   * determine if the create or edit
   */
  bUpdate: boolean = false;

  /**
   * store the stock level per product
   */
  objStockLevelSource: LooseObject = {};
  objStockLevelDestination: LooseObject = {};

  /**
   * store's stock level id
   *
   * @var {string}
   */
  private strStockLevelId: string = '';

  bLoading: boolean = true;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  get hasValidStockTransfer(): boolean {
    return this.stockTransferForm ? this.stockTransferForm.valid && !this.bStockTransferQuantitiesLoading : false;
  }

  get hasStockTransferQuantity(): boolean {
    let bValidForm = this.arStockTransferQuantityForm.find( config => config.form.valid == true) !== undefined;
    if (bValidForm) {
      this.objStockTransferMetadata.warehouse_destination_id.readonly = true;
      this.objStockTransferMetadata.warehouse_source_id.readonly = true;
    }
    return bValidForm;
  }

  /**
   * to determine if next page is enabled
   */
  get nextButton(): string {
    let intPaginationLength = this.arPaginationList.length;
    return intPaginationLength > 1 && intPaginationLength != (this.intPaginationCurrentPage) ? 'active' : 'disabled';
  }

  /**
   * to determine if previous page is enabled
   */
  get previousButton(): string {
    return this.intPaginationCurrentPage != 1 ? 'active' : 'disabled';
  }

  constructor(
    private elementRef: ElementRef,
    private formService: FormService,
    private viewService: ViewService,
    private recordService: RecordService,
    private searchService: SearchService,
    private listingService: ListingService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StockTransfer,
    private router: Router
  ) {
    this.objStockTransferDetails = this.data;
  }

  /**
   * @inheritDoc
   */
  ngOnInit() {
    this.recordService.getRecordBasedOnParent(this.getModuleUrl() == 'stock_levels').subscribe( response => {
      this.objStockLevelDetails = response['record_details'];
      this.createStockTransferForm();
      if (this.data.id) {

        this.bUpdate = true;
        this.updateStockTransferForm(this.objStockTransferDetails);
        this.getStockTransferQuantities();
        this.initStockTransferRelate('warehouse_source', [{
          id: this.objStockTransferDetails.warehouse_source_id,
          name: this.objStockTransferDetails.warehouse_source_text,
        }]);
        this.initStockTransferRelate('warehouse_destination', [{
          id: this.objStockTransferDetails.warehouse_destination_id,
          name: this.objStockTransferDetails.warehouse_destination_text,
        }]);
      } else {
        this.initStockTransferRelate('warehouse_source');
        this.initStockTransferRelate('warehouse_destination');

        this.bStockTransferQuantitiesLoading = false;
      }
      this.bLoading = false;
    });
  }

  /**
   * submit the form to create a stock transfer quantity
   *
   * @returns {void}
   */
  onSubmit(status: string): void {
    this.bSubmitted = true;
    this.stockTransferForm.controls['warehouse_source_id'].markAsDirty();
    this.stockTransferForm.controls['warehouse_destination_id'].markAsDirty();
    if (this.stockTransferForm.valid && this.validStockTransferQuantityForm()) {
      let objStockTransfer = this.stockTransferForm.getRawValue();
      objStockTransfer.status = status;
      objStockTransfer.stock_transfer_quantities = this.arStockTransferQuantityForm.map( config => {
        return config.form.getRawValue();
      });
      this.recordService.saveRecord('stock_transfers', objStockTransfer, this.objStockTransferDetails.id || null).subscribe( response => {
        if (response.status === 200 || response.status === 201) {

          this.notificationService.notifySuccess( response.status === 201 ? 'stock_transfer_created_success' : 'stock_transfer_updated_success');
          this.dialogRef.close('success');
        } else {

          this.notificationService.notifyWarning(response.body.error[0]);
          this.bSubmitted = false;
        }
      });
    } else {

      this.notificationService.notifyWarning('invalid_inputted_data');
      this.bSubmitted = false;
    }
  }

  /**
   * add stock transfer quantity
   *
   * @returns {void}
   */
  validStockTransferQuantityForm(): boolean {
    this.arStockTransferQuantityForm.map( config => {
      config.form.controls.quantity.markAsDirty();
      config.form.controls.item_id.markAsDirty();
    });
    let arValidForm = this.arStockTransferQuantityForm.map( config => config.form.valid );
    return arValidForm.length ? arValidForm.find(validate => validate == false) === undefined : false;
  }

  /**
   * create stock transfer form
   *
   * @return {void}
   */
  createStockTransferForm(): void {
    this.stockTransferForm = this.formService.toFormGroup(this.tranformFieldObject(this.objStockTransferMetadata));
  }

  /**
   * set the default value of stock transfer form
   *
   * @param objStockTransfer
   */
  updateStockTransferForm(objStockTransfer: StockTransfer): void {

    let objDefaultWarehouse: Warehouse = {
      address: null,
      address_text: null,
      id: null,
      name: null,
      qr_code: null,
      status: 'active',
      text: null,
      type: null
    }
    this.stockTransferForm.patchValue({
      warehouse_source_id: objStockTransfer.warehouse_source_id,
      warehouse_destination_id: objStockTransfer.warehouse_destination_id
    });

    let objWarehouseSource = cloneDeep(objDefaultWarehouse);
    objWarehouseSource.id = objStockTransfer.warehouse_source_id
    objWarehouseSource.name = objStockTransfer.warehouse_source_text
    this.objSelectedWarehouse.warehouse_source = objWarehouseSource;

    let objWarehouseDestination = cloneDeep(objDefaultWarehouse);
    objWarehouseDestination.id = objStockTransfer.warehouse_destination_id
    objWarehouseDestination.name = objStockTransfer.warehouse_destination_text
    this.objSelectedWarehouse.warehouse_destination = objWarehouseDestination;
  }

  /**
   * paginate stock transfer quantity items
   *
   * @param page
   */
  getPaginatioRecord(page: string = null): void {
    let paginationLength = this.arPaginationList.length;
    if (page == 'next' && paginationLength != 1) {

      this.intPaginationCurrentPage = this.intPaginationCurrentPage+1
    } else if (page == 'prev' && paginationLength > 1 && this.intPaginationCurrentPage > 1) {

      this.intPaginationCurrentPage = this.intPaginationCurrentPage-1;
    } else {

      this.intPaginationCurrentPage = 1;
    }
    this.resyncPaginationRecord();
  }

  /**
   * update the pagination list
   */
  resyncPaginationRecord(): void {
    let listIndex = this.intPaginationCurrentPage-1;

    this.arPaginationList = chunk(this.arStockTransferQuantityForm, this.intPaginationDataPerPage);
    this.arPaginationCurrentData = this.arPaginationList[listIndex];
    this.arPaginationCurrentData.forEach( config => {

      let defaultOption = this.selectedTransferQuantityItems[config.id] ? this.selectedTransferQuantityItems[config.id] : {};
      if (defaultOption.id) {

        this.createStockTransferQuantityConfig(config, defaultOption)
      }
    })

    this.intPaginationFromData = (listIndex === 0) ? 1 : this.intPaginationDataPerPage*listIndex + 1;
    this.intPaginationToData = (listIndex === 0)
      ? this.arPaginationCurrentData.length
      : (this.intPaginationDataPerPage*listIndex) + this.arPaginationCurrentData.length;
  }

  /**
   * auto focus in quantity after selecting the item
   *
   * @param formId
   * @param formGroup
   * @param event
   *
   * @returns {void}
   */
  onChangeItem(formConfig: StockTransferQuantityConfig, event = null): void {

    let strFormId = formConfig.id;
    let itemElement = this.elementRef.nativeElement.querySelector(`.item-${strFormId}`);
    let quantityElement = this.elementRef.nativeElement.querySelector(`.quantity-${strFormId}`);

    if (event) {

      this.selectedTransferQuantityItems[strFormId] = event;
      this.getWarehouseStockLevel(formConfig, 'warehouse_source');
      this.getWarehouseStockLevel(formConfig, 'warehouse_destination');
    }
    if (formConfig.form.controls.item_id.value && quantityElement) {

      quantityElement.focus();
    }

    this.updateConfig(formConfig);
  }

  /**
   * copy all the changes in current form to stock transfer quantity form
   * so we can retrieve the data when changing pages
   *
   * @param formConfig
   */
  updateConfig(formConfig: StockTransferQuantityConfig): void {

    let intFormIndex = this.arStockTransferQuantityForm.findIndex( config => config.id == formConfig.id);
    this.arStockTransferQuantityForm[intFormIndex] = formConfig;
  }

  /**
   * retrieve the warehouse quantity
   *
   * @param warehouseType
   * @param formId
   */
  getItemQuantity(warehouseType: string, formId: string): number {

    let itemData = this.selectedTransferQuantityItems[formId];
    return itemData ? itemData[warehouseType] || 0 : 0;
  }

  /**
   * cancel dialog
   *
   * @returns {void}
   */
  cancelDialog(): void {
    if (this.stockTransferForm.dirty) {
      this.notificationService.sendConfirmation('confirm_cancel')
      .filter(confirmation => confirmation.answer === true)
      .subscribe(() => { this.dialogRef.close(); });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Check if the form group is changed
   *
   * @returns {boolean}
   */
  checkFormGroupDirty(): boolean {
    return false;
  }

  /**
   * transform metadata to form control
   *
   * @param fieldMetadata
   */
  tranformFieldObject(fieldMetadata) {
    return Object.keys(fieldMetadata).map( fieldId => {

      return this.formService.tranformFieldObject(fieldMetadata[fieldId]);
    });
  }

  /**
   * add stock transfer quantity
   */
  addStockTransferQuantity(): void {
    this.createStockTransferQuantityConfig();
    this.updateMatDialogClass();
    this.resyncPaginationRecord();
  }

  /**
   * remove stock transfer quantity
   */
  removeStockTransferQuantity(form): void {
    if (this.arStockTransferQuantityForm.length > 1) {

      let intFormIndex = this.arStockTransferQuantityForm.findIndex( config => config.id == form.id);
      this.arStockTransferQuantityForm.splice(intFormIndex, 1);
      this.resyncPaginationRecord();
    }
  }

  /**
   * check if the given form is invalid
   *
   * @param form
   * @param id
   *
   * @returns {boolean}
   */
  isFormInvalid(form: FormGroup, id: string): boolean {
    return (form && form.controls[id]) ? form.controls[id].dirty && !form.controls[id].valid : true;
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * update the css to apply a scrollbar if it reaches the maximum height
   *
   * @returns {void}
   */
  updateMatDialogClass(): void {
    setTimeout(() => {
      if (this.elementRef.nativeElement.querySelector('.mat-dialog-content').offsetHeight > 750) {
        this.matDialogContentClass = 'overflow-overlay';
      }
    }, 2)
  }

  /**
   * retrieve warehouse stock level
   *
   * @param formConfig
   */
  getWarehouseStockLevel(formConfig: StockTransferQuantityConfig, strType: 'warehouse_source'|'warehouse_destination' = null): void {

    let strFormId = formConfig.id;
    let strItemId = formConfig.form.controls.item_id.value;
    let strWarehouseId = this.stockTransferForm.controls[strType+ '_id'].value;
    let strStockLevelQuantityId = strType+ '_' +strFormId;
    if (strItemId) {

      this.objStockLevelQuantity[strStockLevelQuantityId] = {
        quantity: 0,
        has_warehouse: false,
        loading: true,
      };
      let obvStockLevel = this.getStockLevelData(strWarehouseId, strItemId)
        .map(response => response['data'] ? new StockLevel(response['data'][0]) : [])
        .subscribe( (response: StockLevel) => {
          this.objStockLevelQuantity[strStockLevelQuantityId] = {
            loading: false,
            quantity: response.quantity || 0,
            has_warehouse: response.id != null
          };
        });
      this.subscriptions.push(obvStockLevel);
    }
  }

  updateStockTransferQuantityForm(objFormConfig: StockTransferQuantityConfig, strStockLevelQuantityId: string): void {
    this.objStockLevelQuantity
    let intQuantity = this.objStockLevelQuantity[strStockLevelQuantityId].quantity;
    if (intQuantity != 0) {

      objFormConfig.form.controls['quantity'].setValidators([Validators.max(intQuantity)]);
      objFormConfig.form.controls['quantity'].updateValueAndValidity;
    } else {

      objFormConfig.form.controls['quantity'].setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(intQuantity)
      ]);
      objFormConfig.form.controls['quantity'].updateValueAndValidity;
    }
  }

  /**
   * check if there is a existing product on current warehouse
   * and we need to reset the products if
   *
   * @param strType
   * @param objEvent
   *
   * @returns {void}
   */
  onChangeWarehouse(strType: 'warehouse_destination'|'warehouse_source', objEvent: Warehouse): void {
    if (!this.objSelectedWarehouse['warehouse_source'] || !this.objSelectedWarehouse['warehouse_destination'] && objEvent.id) {

      this.objSelectedWarehouse[strType] = objEvent;
      this.updateWarehouseOption(strType);
    } else {
      if (this.arStockTransferQuantityForm.length) {

        this.notificationService
          .sendConfirmation('stock_transfer_change_warehouse_confirm', 'stock_transfer_change_warehouse_header')
          .subscribe((confirmation) => {
            if (confirmation.answer) {

              this.objSelectedWarehouse[strType] = objEvent;
              this.arStockTransferQuantityForm.forEach( stockTransferQuantity => {
                this.getWarehouseStockLevel(stockTransferQuantity, strType);
              })
              this.updateWarehouseOption(strType);
            } else {

              let objPreviousValue = this.objSelectedWarehouse[strType];
              this.stockTransferForm.controls[strType+ '_id'].setValue(objPreviousValue.id);
            }
          });
      }
    }

    // if stock transfer form is valid and has no stock transfer quantity
    if (this.stockTransferForm.valid && this.arStockTransferQuantityForm.length === 0) {

      this.addStockTransferQuantity();
    }
  }

  /**
   * update's the warehouse option
   * disable the selected warehouse it is selected from another select
   *
   * @param strType
   */
  updateWarehouseOption(strType: 'warehouse_destination'|'warehouse_source'): void {

    let strWarehouseToUpdate = strType == 'warehouse_destination' ? 'warehouse_source' : 'warehouse_destination';
    let currentTypeValue = this.stockTransferForm.controls[strType+ '_id'].value;
    let arOption = this.arWarehouseList[strWarehouseToUpdate] || [];

    let newOption = arOption.map( objWarehouse => {
      objWarehouse.disabled = objWarehouse.id == currentTypeValue;
      return objWarehouse;
    });

    this.initStockTransferRelate(strWarehouseToUpdate, newOption);
  }

  /**
   * initialize stock transfer quantity config
   *
   * @returns {void}
   */
  createStockTransferQuantityConfig(
    formConfig: StockTransferQuantityConfig = null,
    defaultItemOption: StockTransferQuantityOption = null,
    defaultItemId: string = null,
    defaultQuantity: string = null,
    strRecoredId: string = null): void {

    let strFormId: string;
    let config: StockTransferQuantityConfig = {
      id: null,
      form: null,
      field: null
    };
    if (formConfig) {

      strFormId = formConfig.id;
      config = formConfig;
    } else {

      strFormId = UUID.UUID();
      // when creating a new record, use the current stock level item as default product
      if (this.objStockLevelDetails && this.objStockLevelDetails.item_id &&
          this.arStockTransferQuantityForm.length === 0 && this.bUpdate === false) {

        defaultItemId = this.objStockLevelDetails.item_id;
        defaultItemOption = {
          id: this.objStockLevelDetails.item_id,
          text: this.objStockLevelDetails.product_name,
          code: this.objStockLevelDetails.product_code
        };
      }

      config.id = strFormId;
      config.form = new FormGroup({
        'id':  new FormControl(strRecoredId),
        'item_id':  new FormControl(defaultItemId, Validators.required),
        'quantity':  new FormControl(defaultQuantity, [Validators.required, Validators.min(1)]),
      });
    }

    let defaultOption = []
    if (defaultItemOption) {

      this.selectedTransferQuantityItems[strFormId] = defaultItemOption;
      defaultOption = [defaultItemOption];
    }

    config.field =  new Relate<any>().buildRelates(
      switchMap(term => this.recordService.getProductRecordData(term).pipe(
        map((results) => results
          .map( result => {
            return {
              id: result.id,
              text: result.text,
              code: result['code'],
            };
          })
        ),
        tap( (data) => {
          if (data.length === 1) {

            this.selectedTransferQuantityItems[strFormId] = data;
            config.form.patchValue({ item_id: data['id'] });
            config.form.markAsDirty();

            this.onChangeItem(config, data);

            this.elementRef.nativeElement.querySelector(`.item-${strFormId}`).blur();
          }
          config.field.loader = false;
        }),
      )),
      defaultOption
    );

    if (config.form.controls.item_id.value) {
      if (!this.objStockLevelQuantity['warehouse_source_' +strFormId]) {

        this.getWarehouseStockLevel(config, 'warehouse_source');
      }
      if (!this.objStockLevelQuantity['warehouse_destination_' +strFormId]) {

        this.getWarehouseStockLevel(config, 'warehouse_destination');
      }
    }

    if (formConfig === null) {

      this.arStockTransferQuantityForm.push(config);
    }
  }

  /**
   * initialize stock transfer relate
   *
   * @param relateKey
   *
   * @returns {void}
   */
  initStockTransferRelate(relateKey: string, defautValue = null): void {
    this.arWarehouseList[relateKey] = defautValue ? defautValue : [];
    this.objStockTransferRelate[relateKey] = new Relate<WarehouseOption>().buildRelates(
      switchMap(term => this.getWarehouseRemoteData(term, relateKey)),
      defautValue ? defautValue : this.getWarehouseRemoteData('', relateKey)
    );
  }

  /**
   * get warehouse data data
   *
   * @param strModule
   * @param strTerm
   *
   * @returns Observable<Warehouse[]>
   */
  getWarehouseRemoteData(strTerm: string, strType: string): Observable<WarehouseOption[]>{
    return this.searchService.getRemoteData({
      model: 'warehouses',
      label: null,
      placeholder: null,
      type: "INPUT",
      remoteModule: 'warehouses'
    }, strTerm).map(objResults => {

      // get the value of other warehouse so we can disable it
      let strOtherWarehouse = null;
      if (this.stockTransferForm) {
        strOtherWarehouse = strType == 'warehouse_destination'
        ? this.stockTransferForm.controls['warehouse_source_id'].value
        : this.stockTransferForm.controls['warehouse_destination_id'].value
      }
      // reset the warehouse option list
      this.arWarehouseList[strType] = [];
      return objResults.response.map(objRecord => {

        objRecord['disabled'] = objRecord['id'] == strOtherWarehouse;
        this.arWarehouseList[strType].push(objRecord);
        return objRecord as WarehouseOption
      });
    });
  }

  getStockLevelData(strWarehouseSourceId: string = null, strItemId: string = null) {
    return this.listingService.fetchDataAdvanceSearch({}, 'stock_levels', {
      item_id: strItemId,
      warehouse_id: strWarehouseSourceId
    }, {}, null)
  }

  /**
   * get stock transfer quantities based on stock transfer id
   *
   * @returns {void}
   */
  getStockTransferQuantities(): void {

    let objDefaultFilter = { stock_transfer_id: this.objStockTransferDetails.id };
    this.recordService.getRecordRelate('stock_transfer_quantities', '', false, false, objDefaultFilter, 99).subscribe( response => {
      response.forEach( (stockTransferItems: StockTransferQuantity) => {

        let objDefaultOption = {
          id: stockTransferItems.item_id,
          text: stockTransferItems.item_text,
          code: stockTransferItems.item_code
        };
        this.createStockTransferQuantityConfig(null, objDefaultOption, stockTransferItems.item_id, stockTransferItems.quantity, stockTransferItems.id);
      });

      this.updateMatDialogClass();
      this.resyncPaginationRecord();

      this.bStockTransferQuantitiesLoading = false;
    });
  }

  /**
   * get stock level id using url
   *
   * @returns {string}
   */
  getModuleUrl(): string {
    let arUrl = this.router.url.split('/')
    arUrl.splice(0, 1);

    return arUrl[0];
  }
}

export interface StockTransferQuantityConfig1 {
  id: string,
  form: FormGroup,
  item_loader: Relate<Warehouse>,
  item_obv: Observable<Select[]>,
  item_typeahead: Subject<string>,
};

export interface StockTransferQuantityConfig {
  id: string;
  form: FormGroup;
  field: Relate<StockTransferQuantity>;
};

export interface StockTransferQuantityOption {
  id: string;
  text: string;
  code: string;
};

export interface WarehouseOption {
  id: string;
  name: string;
  disabled: boolean;
};

export interface ItemStockTransferQuantity {
  quantity: number;
  has_warehouse: boolean;
  loading: boolean;
};