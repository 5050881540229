<dialog-header [icon]="['fas', 'file-pdf']" [label]="title" [buttons]="arButtons" (onChange)="ngOnHeaderChange($event)"></dialog-header>
<hr />
<mat-dialog-content>
  <table *ngIf="! (loading$ | async) else loadingIndicator;" class="table table-striped">
    <thead>
      <tr *ngIf="options.length">
        <td><b>{{ 'name' | translate }}</b></td>
        <td align="right">
          <mat-checkbox
            [(ngModel)]="bSelectAll"
            (change)="markAsSelected($event, null, true)"
            color="primary"
            class="select-all"
            ngDefaultControl>
          </mat-checkbox>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let option of options">
        <td>{{ option.text }}</td>
        <td align="right">
          <mat-checkbox
            color="primary"
            [(ngModel)]="option.selected"
            (change)="markAsSelected($event, option)"
            class="asset-type"
            ngDefaultControl>
          </mat-checkbox>
        </td>
      </tr>
      <tr *ngIf="!options.length">
        <td>{{ 'result_empty' | translate }}</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="options.length">
    <div class="d-flex d-flex-gap">
      <mat-checkbox
        color="primary"
        [(ngModel)]="bFailedAssetsOnly"
        ngDefaultControl
      >
        <small class="text-muted">{{ 'show_only_failed_assets' | translate }}</small>
      </mat-checkbox>

      <mat-checkbox
        *ngIf="shouldShowOptionToIncludeJobChecklists"
        color="primary"
        [(ngModel)]="bIncludeJobChecklists"
        ngDefaultControl
      >
        <small class="text-muted">{{ 'include_job_checklists' | translate }}</small>
      </mat-checkbox>
    </div>
  </div>
</mat-dialog-content>

<ng-template #loadingIndicator>
  <inline-view-loader [isLoading]="true" [hasText]="false" className="text-center d-inline-block w-100"></inline-view-loader>
</ng-template>