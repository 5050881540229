<div
  *ngIf="! standalone"
  class="form-group"
>
  <fieldmagic-text
    *ngIf="bDisplayLabel"
    purpose="input"
    [content]="label"
    [withRequiredMarker]="withRequiredMarker"
  >
  </fieldmagic-text>
  <ng-container *ngTemplateOutlet="input"></ng-container>
</div>
<ng-container
  *ngIf="standalone"
  [ngTemplateOutlet]="input">
</ng-container>

<ng-template #input>
  <ng-select
    class="fieldmagic-relate"
    [items]="options$ | async"
    [typeahead]="search$"
    [loading]="isLoading"
    [clearable]="true"
    (open)="onOpen()"
    [(ngModel)]="selected"
    bindLabel="text"
    (change)="onChange($event)"
    (focus)="onTouched()"
    appendTo="body"
    placeholder="{{ placeholder | translate }}"
    [class.fieldmagic-input-has-error]="errors | filled"
  >
    <ng-template ng-option-tmp let-item="item" class="value-text">
      {{ item.text | translate }}
    </ng-template>

    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      {{ item.text | translate }}
    </ng-template>
  </ng-select>
  <fieldmagic-input-errors
    *ngIf="errors | filled"
    [errors]="errors"
  ></fieldmagic-input-errors>
</ng-template>
