import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

/**
 * a service that is responsible for connecting
 * mulitple services and react to whatever the other services triggers.
 * This is useful for cases where a specific component that reacts to a certain activity of another component
 * that is not within the same module as the current reacting module.
 * This lives in the root module in order for any service to communicate as it will a long live service
 */
@Injectable()
export class EventBusService {
  private readonly _event$ = new Subject<TriggeredEvent>();

  getEventFor = <T = any>(name: string, opts: {
    debug?: boolean;
  } = {}): Observable<T> => this._event$.pipe(
    tap((event) => {
      if (!!! opts.debug) {
        return;
      }

      console.info('Event Triggered', event.name, event.args);
    }),
    filter((event) => event.name == name),
    map((event) => event.args),
  );

  dispatch = <T = any>(event: TriggeredEvent<T>) => this._event$.next(event);
}

export type TriggeredEvent<T = any> = {
  name: string;
  args: T;
}