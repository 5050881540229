import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy, ViewChild, AfterViewChecked, DoCheck, forwardRef } from "@angular/core";
import { AsComponent } from "../as.component";
import { Renderer2, ElementRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AsConfigService } from "../asConfig.service";
import { AsInputAbstract } from "./asInput.abstract";
import { AsBoxFilterAbstract } from "../asFilter.abstract";
import { Subject } from "rxjs";
import { AsInputComponent } from "../asInput.component";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AsInputWithOperatorsComponent } from '../asInputWithOperators.component';
import { TranslateService } from '@ngx-translate/core';
import { isArray, isNil } from 'lodash-es';

@Component({
  selector: 'as-domains-input-with-operators',
  template: `
    <ng-select
        appendTo="body"
        [bindLabel]="filter.viewModel.bindLabel"
        [bindValue]="filter.viewModel.bindValue"
        (focus)="filter.focusInput$.next()"
        (keydown)="onKeydown($event)"
        #inputRef
        [placeholder]="filter.viewModel.placeholder | translate"
        [typeahead]="domainTypeahead"
        [items]="itemsDomain"
        (change)="onChange($event)"
        (clear)="onClear()"
        (remove)="onRemove($event)"
        [multiple]="true"
        [hideSelected]="true"
        [clearable]="false"
        [(ngModel)]="filter.viewModel.value.value">
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label">{{ (item.label || item.name) | translate }}</span>
            <span class="ng-value-icon right" (click)="clear(item)">×</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <span class="ng-value-label" [title]="getToolTipContent(item)">{{ (item.label || item.name) | translate }}</span>
        </ng-template>
    </ng-select>
    <input autosize #inputAutosize type="text" [(ngModel)]="filterValue" [hidden]="true" />`,
  styles: [`
        ng-select{
            height:100%;
            min-width:100px;
            max-width:500px;
        }
    `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsDomainsInputWithOperatorsComponent),
      multi: true
    }
  ]

})
export class AsDomainsInputWithOperatorsComponent extends AsInputAbstract implements AfterViewChecked, DoCheck {

  @ViewChild(NgSelectComponent) typeahead;
  @ViewChild('inputAutosize', { read: ElementRef }) inputAutosize: ElementRef;
  @ViewChild('inputRef', { read: NgControl }) ngControl: NgControl;

  private _filterValue = '';
  set filterValue(val) {
    this._filterValue = val;
  }
  get filterValue() {
    return this._filterValue;
  }

  constructor(
    public advancedSearchBox: AsComponent,
    protected _http: HttpClient,
    protected _config: AsConfigService,
    protected translateService: TranslateService,
    public _element: ElementRef,
    public filter: AsInputWithOperatorsComponent
  ) {
    super(advancedSearchBox, _http, _config, _element);
  }

  ngOnInit() {
    super.ngOnInit();

    this.ngControl.valueChanges.subscribe((res) => {
      if (res === '' || res === undefined || res === null) {
      } else {
        if (this.filter.viewModel.bindLabel) {
          this.filterValue = res[this.filter.viewModel.bindLabel];
        } else {
          this.filterValue = res;
        }
      }
    });
  }

  ngDoCheck() {
    if (this.typeahead.filterValue) {
      this.filterValue = this.typeahead.filterValue;
    }
  }

  ngAfterViewChecked() {
    this.inputElementRef.nativeElement.style.maxWidth = '1000px';
  }

  onClear() {
    this._filterValue = null;
    this.focusInput$.next(undefined);
    this.filter.remove();
  }

  onRemove(data) {
    this.focusInput$.next(undefined);
    this.inputRef.open();
    this.filter.removeEmpty([this.filter.viewModel.value]);
  }

  onChange(data) {
    // without timeout it is execute before keydown event
    setTimeout(() => {
      if (data === '' || data === undefined || data === null) {
        this.focusInput$.next(undefined);
        this.inputRef.open();
        this.filter.removeEmpty([this.filter.viewModel.value]);
      } else {
        if (this.filter.viewModel.bindLabel) {
          this._filterValue = data[this.filter.viewModel.bindLabel];
        } else {
          this._filterValue = data;
        }

        if (this.filter.viewModel.bindValue) {
          this.filter.onSelectDomains(data[this.filter.viewModel.bindValue]);
        } else {
          this.filter.onSelectDomains(data);
        }
      }
      this._onChange(data);
      this.change.emit(data);
    }, 0);
  }

  onKeydown(event) {
    if (isArray(this.filter.viewModel.value.value) && this.filter.viewModel.value.value.length === 0) {
      this.advancedSearchBox.keydown(event, this.filter.viewModel);
    }
  }

  /**
   * retrieve tool tip layout
   *
   * @param object data
   * @returns
   */
  getToolTipContent(data): string {
    if (this.filter.viewModel['remoteModule'] === 'sites') {

      let strTenant = !isNil(data['tenant']) ? data['tenant'] : '--';
      return this.translateService.instant('tenant')+ ': ' +strTenant+
        '\n' +this.translateService.instant('name')+ ': ' +data['name'];
    }

    return this.translateService.instant(data['label'] || data['name']);
  }
}