import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { FMError } from '../../../../../../objects/fm-error';
import { LooseObject } from '../../../../../../objects/loose-object';
import { ActivitiesService } from '../../../../../../services/activities.service';
import { FormService } from '../../../../../../services/form.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { RecordService } from '../../../../../../services/record.service';
import { ClientStoreService } from '../../../../../../services/client-store.service';

@Component({
  selector: 'task-upsert',
  templateUrl: './task-upsert.component.html',
  styleUrls: ['./task-upsert.component.scss']
})
export class TaskUpsertComponent implements OnInit {
  @Input() taskDetails: LooseObject;
  @Input() strTaskParentModule: 'jobs' | 'opportunities';
  @Output() taskFormSave = new EventEmitter<boolean>();

  form: FormGroup;
  bSaving = false;
  bSubmitted = false;
  strMode: 'create' | 'edit' = 'create';
  strModule = 'activities';

  recentTaskDetails: Observable<LooseObject>;

  fields: any = [
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "is_hidden": false,
      "default_value": "",
      "key": "activity_name",
      "label": "task",
      "controlType": "text",
      "space": 12,
      "max_length": 128,
      "has_primary": false
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": null,
      "key": "user_id",
      "label": "technician",
      "controlType": "relate",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "users",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": null,
      "key": "team_id",
      "label": "team",
      "controlType": "relate",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "teams",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false
    },
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": null,
      "key": "department_id",
      "label": "department",
      "controlType": "relate",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "departments",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false
    },
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "normal",
      "key": "priority",
      "label": "priority",
      "controlType": "dropdown",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "options": [
        {
          "id": "high",
          "text": "high"
        },
        {
          "id": "normal",
          "text": "normal"
        },
        {
          "id": "low",
          "text": "low"
        },
      ],
      "tag": false,
      "hideSelected": true,
      "closeOnSelect": false,
      "maxSelectedItems": 100,
      "clearable": false,
      "list": ""
    },
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "estimated_duration",
      "label": "estimated_duration",
      "controlType": "number",
      "space": 6,
      "is_hidden": false,
      "has_primary": false
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "due_date",
      "label": "due_date",
      "controlType": "datetime",
      "space": 6,
      "has_primary": false
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "notes",
      "label": "description",
      "controlType": "textarea",
      "space": 12,
      "is_hidden": false,
      "max_length": 10000,
      "has_primary": false,
      "rows": 4
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": false,
      "key": "review_complete",
      "label": "review_complete",
      "controlType": "checkbox",
      "space": 6,
      "has_primary": false
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": false,
      "key": "review_required",
      "label": "review_required",
      "controlType": "checkbox",
      "space": 6,
      "has_primary": false
    },
  ];

  constructor(
    private notifService: NotificationService,
    private formService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
    private recordService: RecordService,
    private activitiesService: ActivitiesService,
    private client: ClientStoreService
  ) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('taskDetails' in changes) {

      if (!this.client.isDepartmentTracking()) {
        this.fields = this.fields.filter(item => { return item.key !== 'department_id' });
      }

      if (!isEmpty(this.taskDetails.id)) {
        this.strMode = 'edit';
        // Get other task details
        this.recentTaskDetails = this.recordService.getRecordRelateJoined('activities', false, { 'activities.id': this.taskDetails.id });
        this.recentTaskDetails.pipe(take(1)).subscribe((recentTaskDetails) => {
          this.taskDetails = recentTaskDetails[0];

          // Prefill values on some fields where the patchValue doesn't work
          const dueDateIndex = this.fields.findIndex(field => field.key === 'due_date');
          this.fields[dueDateIndex].default_value = this.taskDetails.due_date;

          const departmentIdIndex = this.fields.findIndex(field => field.key === 'department_id');

          if (departmentIdIndex > -1) {
            this.fields[departmentIdIndex].default_text = this.taskDetails.department_name;
            this.fields[departmentIdIndex].options = [
              {
                "id": this.taskDetails.department_id,
                "text": this.taskDetails.department_name,
                "disabled": false
              }
            ];
          }

          const techinicianIdIndex = this.fields.findIndex(field => field.key === 'user_id');
          this.fields[techinicianIdIndex].default_text = this.taskDetails.user_text;
          this.fields[techinicianIdIndex].options = [
            {
              "id": this.taskDetails.user_id,
              "text": this.taskDetails.user_text,
              "disabled": false
            }
          ];

          const teamIdIndex = this.fields.findIndex(field => field.key === 'team_id');
          this.fields[teamIdIndex].default_text = this.taskDetails.team_text;
          this.fields[teamIdIndex].options = [
            {
              "id": this.taskDetails.team_id,
              "text": this.taskDetails.team_text,
              "disabled": false
            }
          ];

          this.form = this.formService.toFormGroup(this.fields);
          this.form.patchValue(this.taskDetails);
          this.changeDetectorRef.detectChanges();

        });
      } else {
        this.strMode = 'create';
        this.form = this.formService.toFormGroup(this.fields);
      }


    }
  }

  save() {
    this.form.markAsDirty();
    this.form.markAsTouched();
    this.bSubmitted = true;
    if (this.form.invalid) {
      this.notifService.notifyError('please_complete_the_form');
    } else {
      this.bSaving = true;
      let objActivityData = this.form.value;
      let activityAction$;

      if (this.strMode === 'edit') {
        objActivityData = {
          ...objActivityData,
          id: this.taskDetails.id,
          module: this.strTaskParentModule,
          record_id: this.strTaskParentModule === 'jobs' ? this.taskDetails.job_id : this.taskDetails.opportunity_id
        };
        activityAction$ = this.activitiesService.updateActivity(JSON.stringify(objActivityData));
      } else {
        objActivityData = {
          "module_id": this.strTaskParentModule === 'jobs' ? this.taskDetails.job_id : this.taskDetails.opportunity_id,
          "module_field": this.strTaskParentModule === 'jobs' ? "job_id" : 'opportunity_id',
          "task_progress": "awaiting_scheduling",
          "subject": this.form.controls.activity_name.value,
          "assigned_to": this.form.controls.user_id.value,
          "team_id": this.form.controls.team_id.value,
          "priority": this.form.controls.priority.value,
          "estimated_duration": this.form.controls.estimated_duration.value,
          "due_date": this.form.controls.due_date.value,
          "note": this.form.controls.notes.value,
          "module": this.strTaskParentModule,
          "type": "task"
        };

        if (this.form.controls.department_id) {
          objActivityData["department_id"] = this.form.controls.department_id.value;
        }

        activityAction$ = this.activitiesService.createActivity(JSON.stringify(objActivityData));
      }

      activityAction$
        .pipe(
          finalize(() => this.bSaving = false)
        ).
        subscribe(arResponse => {
          this.notifService.notifySuccess(this.strMode === 'edit' ? "record_update_success" : 'task_created');
          this.taskFormSave.emit(true);
        }, (error: HttpErrorResponse) => {
          if (error.status === 422) {
            let objError: FMError = new FMError(error.error.errors[0]);
            this.notifService.sendNotification('not_allowed', objError.detail, 'warning');
          } else {
            this.notifService.sendNotification('not_allowed', 'unable_to_update_activity', 'warning');
          }
        });
    }
  }

  cancel() {
    this.taskFormSave.emit(false);
  }

}