
    <fieldmagic-dialog
      label="{{ '%s - %s' | sprintf: [serialNumber, assetTypeName] }}"
      icon="hashtag"
      [instance]="self"
      [disableClose]="saving$ | async"
    >
      <ng-template
        fieldmagicDialogButtonsTmp
      >
        <fieldmagic-primary-button
          label="Save"
          icon="save"
          [isInProgress]="saving$ | async"
          (click)="onSave()"
        >
        </fieldmagic-primary-button>
      </ng-template>

      <ng-template
        fieldmagicDialogContentTmp
      >
        <fieldmagic-alert
          content="No available checklists found that can be used. Please fillup the form to complete the inspection."
        >
        </fieldmagic-alert>

        <ng-container [formGroup]="form">
          <div class="d-flex d-flex-gap flex-column">
            <fieldmagic-dropdown-input
              label="Status"
              [options]="['pass', 'fail', 'awaiting_completion']"
              formControlName="status"
              [withRequiredMarker]="true"
              [errors]="errors | data_get: 'status'"
              [clearable]="false"
            >
            </fieldmagic-dropdown-input>

            <fieldmagic-textarea-input
              *ngIf="(status$ | async) == 'fail'"
              label="Fault Details"
              formControlName="fault_details"
              [withRequiredMarker]="true"
              [standalone]="false"
              [errors]="errors | data_get: 'fault_details'"
            >
            </fieldmagic-textarea-input>
          </div>
        </ng-container>
      </ng-template>
    </fieldmagic-dialog>
  