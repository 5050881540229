import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChecklistsService } from '../../../../../services/checklist.service';
import { DIALOG_DATA, DialogService } from '../../../../../services/dialog.service';
import { ChecklistQuestion, ChecklistQuestionName, ChecklistResponse, ChecklistType } from '../../../shared/types';
import { BehaviorSubject, defer, Observable, of, Subscription } from 'rxjs';
import { arr_count, blank, data_get, fallback, filled, observe, OptionalValue, when } from '../../../../../shared/utils/common';
import { ChecklistResponseComponent, ChecklistResponseComponentProps } from '../../../../../shared/components/widget/checklists/checklist-response/checklist-response.component';
import { filter, switchMap } from 'rxjs/operators';
import { LinkChecklistDialogComponent, LinkChecklistDialogComponentProps, LinkChecklistDialogComponentResult } from '../link-checklist/link-checklist-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';
import { RecordService } from '../../../../../services/record.service';

@Component({
  selector: 'checklist-render-prompts-button',
  template: `
    <fieldmagic-primary-button
      purpose="icon"
      icon="tasks"
      (click)="showDialog()"
      tooltip="Add response"
      [isInProgress]="previewing$ | async"
    >
    </fieldmagic-primary-button>
  `
})
export class RenderPromptsButtonComponent {
  @Input() options: RenderPromptsButtonComponentOptions = {};

  @Output('complete') $onComplete = new EventEmitter<boolean>();

  readonly previewing$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _checklists: ChecklistsService,
    private readonly _dialog: DialogService,
  ) {}

  showDialog = () => observe({
    before: () => this.previewing$.next(true),
    after: () => this.previewing$.next(false),
    observable: () => defer(() => {
      if (filled(this.options.asset_job_id)) {
        return this._checklists.getLinked({
          asset_job_id: this.options.asset_job_id,
          job_id: this.options.job_id,
          opportunity_id: this.options.opportunity_id,
          asset_type_id: this.options.asset_type_id,
        }).pipe(
          switchMap(({ linked, links, available }) => {
            if (filled(linked)) {
              return this._displayDialog({
                id: linked.id,
                asset_id: this.options.asset_id,
                asset_job_id: this.options.asset_job_id,
                asset_type_id: this.options.asset_type_id,
              });
            }

            if (arr_count(links) > 1) {
              return this._askUserToSelectSpecificLink(links).pipe(
                filter((linkId) => filled(linkId)),
                switchMap((linkId) => this._displayDialog({
                  id: linkId,
                  asset_id: this.options.asset_id,
                  asset_job_id: this.options.asset_job_id,
                  asset_type_id: this.options.asset_type_id,
                })),
              );
            }

            if (filled(available)) {
              return this._askUserToSelectAvailableChecklists().pipe(
                filter((linkId) => filled(linkId)),
                switchMap((linkId) => this._displayDialog({
                  id: linkId,
                  asset_id: this.options.asset_id,
                  asset_job_id: this.options.asset_job_id,
                  asset_type_id: this.options.asset_type_id,
                })),
              );
            }

            return this._displayBasicInspectionChecklistDialog();
          })
        );
      }

      return this._displayDialog({
        id: this.options.checklist_response_id,
      });
    })
  }).subscribe((rendered) => {
    this.$onComplete.next(rendered);
  });

  private _displayDialog = (opts: {
    id: string;
    asset_id?: string;
    asset_job_id?: string;
    asset_type_id?: string;
  }) => new Observable<boolean>((completer) => this._dialog.show<ChecklistResponseComponent, ChecklistResponseComponentProps>({
    component: ChecklistResponseComponent,
    size: 'lg',
    data: {
      asset_id: opts.asset_id,
      id: opts.id,
      asset_job_id: opts.asset_job_id,
      asset_type_id: opts.asset_type_id,
    },
    afterClosing: (_) => {
      completer.next(true);
      completer.complete();
    },
  }));

  private _askUserToSelectSpecificLink = (links: ChecklistResponse[]): Observable<SelectSpecificChecklistDialogResult> =>
    new Observable((observer) => this._dialog.show<SelectSpecificChecklistDialogComponent, SelectSpecificChecklistDialogComponentProps>({
      component: SelectSpecificChecklistDialogComponent,
      dismissable: false,
      size: 'sm',
      data: {
        links,
        asset_job_id: this.options.asset_job_id,
        asset_type_id: this.options.asset_type_id,
      },
      afterClosing: (result) => {
        observer.next(result);
        observer.complete();
      }
    })
  );

  private _askUserToSelectAvailableChecklists = (): Observable<string> => new Observable(
    (observer) => this._dialog.show<LinkChecklistDialogComponent, LinkChecklistDialogComponentProps, LinkChecklistDialogComponentResult>({
      component: LinkChecklistDialogComponent,
      data: {
        module_name: when<LinkChecklistDialogComponentProps['module_name']>(filled(this.options.opportunity_id), {
          then: () => 'opportunities',
          else: () => 'jobs',
        }),
        module_id: fallback(this.options.opportunity_id, {
          fallback: () => this.options.job_id,
        }),
        asset_type_id: this.options.asset_type_id,
        types: ['asset'],
      },
      afterClosing: (result) => {
        if (filled(result)) {
          observer.next(result.linked.id);
        }

        observer.complete();
      },
    })
  )

  private _displayBasicInspectionChecklistDialog = () => new Observable<boolean>(
    (observer) => this._dialog.show<BasicInspectionChecklistDialogComponent, BasicInspectionChecklistDialogComponentProps>({
      component: BasicInspectionChecklistDialogComponent,
      dismissable: false,
      data: {
        asset_id: this.options.asset_id,
        asset_job_id: this.options.asset_job_id,
      },
      afterClosing: () => {
        observer.next(true);
        observer.complete();
      }
    }),
  );
}

@Component({
  selector: 'select-linked-checklist-dialog',
  template: `
    <fieldmagic-dialog
      label="Select Inspection Checklist"
      icon="link"
      [instance]="self"
    >
      <ng-template fieldmagicDialogButtonsTmp>
        <fieldmagic-primary-button
          label="Attach"
          icon="link"
          [isInProgress]="linking$ | async"
          (click)="onAttach()"
        >
        </fieldmagic-primary-button>
      </ng-template>

      <ng-template fieldmagicDialogContentTmp>
        <div class="d-flex flex-column">
          <fieldmagic-alert
            content="Multiple inspection checklist has been detected for the current asset. Please click the edit button to edit the response."
          >
          </fieldmagic-alert>

          <div class="w-100 d-flex justify-content-center">
            <div class="d-flex flex-column d-flex-gap-2x w-75">
              <div
                *ngFor="let checklist of (checklists$ | async)"
                fieldmagicGridRow
              >
                <div fieldmagicGridColumn="6">
                  <fieldmagic-text
                    [content]="checklist.name"
                  >
                  </fieldmagic-text>
                </div>
                <div
                  class="d-flex justify-content-center"
                  fieldmagicGridColumn="6"
                >
                  <fieldmagic-primary-button
                    label="Edit"
                    icon="pencil"
                    (click)="onEdit(checklist)"
                  >
                  </fieldmagic-primary-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </fieldmagic-dialog>
  `,
})
export class SelectSpecificChecklistDialogComponent {
  readonly checklists$ = new BehaviorSubject<ChecklistResponse[]>(this._props.links);

  get self(): SelectSpecificChecklistDialogComponent {
    return this;
  }

  constructor(
    @Inject(DIALOG_DATA) private readonly _props: SelectSpecificChecklistDialogComponentProps,
    private readonly _dialog: DialogService,
  ) {}

  onEdit = (link: ChecklistResponse) => this._dialog.close<this, SelectSpecificChecklistDialogResult>({
    instance: this,
    result: link.id,
  });
}

@Component({
  selector: 'basic-inspection-checklist-dialog',
  template: `
    <fieldmagic-dialog
      label="{{ '%s - %s' | sprintf: [serialNumber, assetTypeName] }}"
      icon="hashtag"
      [instance]="self"
      [disableClose]="saving$ | async"
    >
      <ng-template
        fieldmagicDialogButtonsTmp
      >
        <fieldmagic-primary-button
          label="Save"
          icon="save"
          [isInProgress]="saving$ | async"
          (click)="onSave()"
        >
        </fieldmagic-primary-button>
      </ng-template>

      <ng-template
        fieldmagicDialogContentTmp
      >
        <fieldmagic-alert
          content="No available checklists found that can be used. Please fillup the form to complete the inspection."
        >
        </fieldmagic-alert>

        <ng-container [formGroup]="form">
          <div class="d-flex d-flex-gap flex-column">
            <fieldmagic-dropdown-input
              label="Status"
              [options]="['pass', 'fail', 'awaiting_completion']"
              formControlName="status"
              [withRequiredMarker]="true"
              [errors]="errors | data_get: 'status'"
              [clearable]="false"
            >
            </fieldmagic-dropdown-input>

            <fieldmagic-textarea-input
              *ngIf="(status$ | async) == 'fail'"
              label="Fault Details"
              formControlName="fault_details"
              [withRequiredMarker]="true"
              [standalone]="false"
              [errors]="errors | data_get: 'fault_details'"
            >
            </fieldmagic-textarea-input>
          </div>
        </ng-container>
      </ng-template>
    </fieldmagic-dialog>
  `,
})
export class BasicInspectionChecklistDialogComponent implements OnInit, OnDestroy {
  serialNumber?: string;
  assetTypeName?: string;

  errors: Record<string, string[]> = {};

  readonly self = this;

  readonly form = new FormGroup({
    status: new FormControl(DEFAULT_INSPECTION_STATUS),
    fault_details: new FormControl(),
  });

  readonly saving$ = new BehaviorSubject<boolean>(false);

  readonly status$ = this.form.get('status').valueChanges;

  private _fetchingAssetRecordSubscription?: Subscription;

  constructor(
    @Inject(DIALOG_DATA) private readonly _props: BasicInspectionChecklistDialogComponentProps,
    private readonly _records: RecordService,
    private readonly _dialog: DialogService,
    private readonly _checklists: ChecklistsService,
  ) {}

  ngOnInit(): void {
    this._fetchingAssetRecordSubscription = this._records.getRecord('assets_jobs', this._props.asset_job_id, false)
      .subscribe((response) => {
        this.serialNumber = data_get(response, 'record_details.serial_number');
        this.assetTypeName = data_get(response, 'record_details.asset_type_text');

        this.form.patchValue({
          status: fallback(data_get(response, 'record_details.status'), {
            fallback: () => DEFAULT_INSPECTION_STATUS,
          }),
          fault_details: data_get(response, 'record_details.fault_details'),
        });
      });
  }

  ngOnDestroy(): void {
    if (filled(this._fetchingAssetRecordSubscription)) {
      this._fetchingAssetRecordSubscription.unsubscribe();
    }
  }

  onSave = () => observe({
    before: () => this.saving$.next(true),
    after: () => this.saving$.next(false),
    observable: () => defer(() => {
      if (! this._isValid()) {
        return of(false);
      }

      return this._checklists.saveBasicInspection({
        asset_job_id: this._props.asset_job_id,
        status: this.form.value['status'],
        fault_details: this.form.value['fault_details'],
      });
    })
  })
    .pipe(
      filter((successful) => successful)
    )
    .subscribe((_) => this._dialog.close({
      instance: this,
      result: true,
    }));

  private _isValid(): boolean {
    let errors: Record<string, string[]> = {};

    if (blank(data_get(this.form.value, 'status'))) {
      errors['status'] = ['field_is_required'];
    }

    if (blank(data_get(this.form.value, 'fault_details'))
      && data_get(this.form.value, 'status') == 'fail'
    ) {
      errors['fault_details'] = ['field_is_required'];
    }

    this.errors = errors;

    return blank(errors);
  }
}

const DEFAULT_INSPECTION_STATUS = 'awaiting_completion';

export type RenderPromptsButtonComponentOptions = {
  module_name?: 'opportunities' | 'jobs',
  questions_name?: ChecklistQuestionName,
  job_id?: string;
  opportunity_id?: string;

  /// asset related props
  asset_job_id?: string;
  asset_id?: string,
  asset_type_id?: string,
  checklist_response_id?: string;
}

export type RenderPromptsButtonChecklistLinkData = {
  id: string;
  asset_type_id?: string;
  job_id?: string;
  opportunity_id?: string;
  questions?: ChecklistQuestion[];
  response?: ChecklistQuestion[];
  fault_details?: string;
  product_id?: string[];
  is_comment_enabled?: boolean;
  type?: ChecklistType;
}

export type BasicInspectionChecklistDialogComponentProps = {
  asset_job_id: string;
  asset_id: string;
}

type SelectSpecificChecklistDialogComponentProps = {
  links: ChecklistResponse[];
  asset_job_id: string;
  asset_type_id: string;
}

type SelectSpecificChecklistDialogResult = OptionalValue<string>;
