import { PipeTransform, Pipe } from '@angular/core';
import { blank } from '../shared/utils/common';

@Pipe({
  name: 'blank',
  pure: false,
})
export class BlankPipe implements PipeTransform {
  transform = <T = any>(value: T): boolean => blank<T>(value);
}
