
    <fieldmagic-grid>
      <div fieldmagicGridColumn="12">
        <div
          *ngIf="loading$ | async; else content;"
          class="d-flex justify-content-center"
        >
          <fieldmagic-icon
            icon="spinner"
            [spin]="true"
          >
          </fieldmagic-icon>
        </div>
      </div>
    </fieldmagic-grid>
  