<header *ngIf="!bLoading">
  <div class="d-flex header-container header-label">
    <span class="title">
      <fa-icon [icon]="['fas', 'receipt']" class="pr-2"></fa-icon>
      <span translate>{{ (data.stock_type == 'credit') ? 'return_stock' : 'receive_stock' }}</span>
    </span>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li
          *ngIf="bCreateMode"
          class="list-inline-item"
        >
          <button
            class="btn btn-primary"
            (click)="createStockReceipts()"
            [disabled]="bDisabled || bHasNoAvailableReceive">
              <fa-icon [icon]="['fas', 'save']"></fa-icon>
              <span class="pl-1">{{ 'create_stock_receipt' | translate }}</span>
          </button>
        </li>
        <li
          *ngIf="!bCreateMode && objStockReceipt.status == 'draft'"
          class="list-inline-item"
        >
          <button
            class="btn btn-primary"
            (click)="updateStockReceiptStatus()"
            [disabled]="bDisabled || bHasNoAvailableReceive">
              <fa-icon [icon]="['fas', 'save']"></fa-icon>
              <span class="pl-1">{{ 'receive_stock' | translate }}</span>
          </button>
        </li>
        <li
          *ngIf="bCreateCredit"
          class="list-inline-item"
        >
          <button
            class="btn btn-primary"
            (click)="createCredit()"
            [disabled]="bDisabled">
              <fa-icon [icon]="['fas', 'save']"></fa-icon>
              <span class="pl-1">{{ 'return_stock' | translate }}</span>
          </button>
        </li>
        <li
          *ngIf="bCreatingNewStockReceipt"
          class="list-inline-item"
        >
          <button
            class="btn btn-primary"
            (click)="createReceipt()"
            [disabled]="bDisabled || bHasNoAvailableReceive">
              <fa-icon [icon]="['fas', 'save']"></fa-icon>
              <span class="pl-1">{{ 'create_receipt' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button class="btn btn-danger" (click)="cancelDialog()">
            <fa-icon [icon]="['fas', 'times']" transform="shrink-3" class="pointer"></fa-icon> {{ 'close' | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>

<mat-dialog-content>
  <ng-container *ngIf="bHasNoAvailableReceive && !bReceiptView && !bCredit; else noItemToReceive">
      <div class="col-12 text-center">
        <span>{{ 'no_items_to_receive' | translate }}</span>
      </div>
  </ng-container>
  <ng-template #noItemToReceive>
    <div *ngIf="bCreating" class="align-center">
      <inline-view-loader [isLoading]="true"></inline-view-loader>
    </div>
    <div *ngIf="!bCreating" class="row mb-3">
      <div *ngIf="objDestinationWarehouse" class="col-4">
        <label class="labels w-100">{{ 'destination_warehouse' | translate }}</label>
        <ng-select
          id="ngSelectStocktakeWarehouse"
          bindLabel="name"
          placeholder="{{ 'select_warehouse' | translate }}"
          [items]="objDestinationWarehouse.source | async"
          [typeahead]="objDestinationWarehouse.typehead"
          [loading]="objDestinationWarehouse.loader"
          [(ngModel)]="objDestinationWarehouse.model"
          [disabled]="!bCreateMode"
          [appendTo]="'body'">
        </ng-select>
      </div>
      <ng-container *ngIf="!bCreateMode && !bCredit; else emptyDiv" >
        <div class="col-4">
          <label class="labels w-100">{{ 'receipt_status' | translate }}</label>
          <span class="form-control-plaintext mt-1 border-bottom form-control-sm pl-2 record-texts long-text">
            {{ objStockReceipt['status'] | translate }}
          </span>
        </div>
        <ng-container *ngIf="bCreateReceipt || objStockReceipt['status'] === 'draft'; else viewReference" >
          <div class="col-4">
            <label class="labels w-100">{{ 'reference' | translate }}</label>
            <input [(ngModel)]="strReference" type="text" class="form-control" />
          </div>
        </ng-container>
        <ng-template #viewReference>
          <div class="col-4">
            <label class="labels w-100">{{ 'reference' | translate }}</label>
            <span class="form-control-plaintext mt-1 border-bottom form-control-sm pl-2 record-texts long-text">
              {{ objStockReceipt['reference'] | placeholdWithString }}
            </span>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <ng-container *ngIf="bCreatingNewStockReceipt; else defaultTemplate">
      <table class="table">
        <thead>
          <tr class="text-center font-weight-bold">
            <td width="23%">{{ 'product' | translate }}</td>
            <td width="10%">{{ 'ordered' | translate }}</td>
            <td width="10%">{{ 'received' | translate }}</td>
            <td width="10%">{{ 'returned' | translate }}</td>
            <td width="20%">{{ 'expected_receipt_date' | translate }}</td>
            <td width="20%">{{ 'receipt_quantity' | translate }}</td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let lineItem of arLineItemQty; index as i">
            <tr>
              <td class="text-center">{{ (lineItem.item_id) ? '[' +lineItem.item_code+ '] ' +lineItem.item_name : '--' }}</td>
              <td class="text-center">{{ getTotalQuantity(lineItem) }}</td>
              <td class="text-center">{{ getTotalReceived(lineItem) }}</td>
              <td class="text-center">{{ getTotalCredited(lineItem) }}</td>
              <td>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control fs12"
                    [(ngModel)]="lineItem.expected_receipt_date"
                    [owlDateTime]="dateTimeField"
                    [ngModelOptions]="{standalone: true}"
                    readonly
                    [owlDateTimeTrigger]="dateTimeField"
                    style="background: #fff;">
                  <button class="calendar-button" [owlDateTimeTrigger]="dateTimeField">
                    <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
                  </button>
                </div>
                <div>
                  <owl-date-time  #dateTimeField [pickerType]="'calendar'"></owl-date-time>
                </div>
              </td>
              <td class="text-center">
                <ng-container *ngIf="getAvailableReceipt(lineItem) != 0; else receiptNotAllowed">
                  <input
                    type="number"
                    [(ngModel)]="lineItem.received"
                    class="form-control fs12"
                    min="0"
                    max="{{ getAvailableCredit(lineItem) }}"
                    step="1"
                    (keyup)="onChangeRemainingQuantity(lineItem)"
                  />
                </ng-container>
                <ng-template #receiptNotAllowed>
                  {{ 'not_available' | translate }}
                </ng-template>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
    <ng-template #defaultTemplate>
      <table *ngIf="!bCreateMode" class="table">
          <thead>
            <tr class="text-center font-weight-bold">
              <td width="23%">{{ 'product' | translate }}</td>
              <ng-container *ngIf="bCreateCredit; else createReceiptView">
                <td width="10%">{{ 'ordered' | translate }}</td>
                <td width="10%">{{ 'received' | translate }}</td>
                <td width="10%">{{ 'returned' | translate }}</td>
                <td width="18%">{{ 'credit_quantity' | translate }}</td>
              </ng-container>
              <ng-template #createReceiptView>
                <td *ngIf="objStockReceipt['status'] === 'draft'" width="10%">
                  {{ 'quantity' | translate }}
                </td>
                <td *ngIf="!bCredit" width="10%">{{ 'quantity_received' | translate }}</td>
                <td width="38%">{{ 'description' | translate }}</td>
                <td *ngIf="!bCredit" width="25%">{{ 'expected_receipt_date' | translate }}</td>
                <td *ngIf="bCreditView" width="20%">{{ 'credit_quantity' | translate }}</td>
              </ng-template>
            </tr>
          </thead>
        <tbody>
          <tr *ngIf="arLineItemQty.length < 1" class="text-center">
            <td colspan="6">{{ 'no_items' | translate }}</td>
          </tr>
          <ng-container *ngIf="!bView; else isView">
            <ng-container *ngFor="let lineItem of arLineItemQty; index as i">
              <tr>
                <td class="text-center">{{ (lineItem.item_id) ? '[' +lineItem.item_code+ '] ' +lineItem.item_name : '--' }}</td>
                <ng-container *ngIf="bCreateCredit; else createReceiptView">
                  <td class="text-center">{{ getTotalQuantity(lineItem) }}</td>
                  <td class="text-center">{{ getTotalReceived(lineItem) }}</td>
                  <td class="text-center">{{ getTotalCredited(lineItem) }}</td>
                  <td *ngIf="bCredit" class="text-center">
                    <ng-container *ngIf="getAvailableCredit(lineItem) != 0; else allStockCredited">
                      <input
                        type="number"
                        [(ngModel)]="lineItem.credit"
                        class="form-control fs12"
                        min="0"
                        max="{{ getAvailableCredit(lineItem) }}"
                        step="1"
                        (keyup)="onChangeCreditQuantity(lineItem)"
                      />
                    </ng-container>
                    <ng-template #allStockCredited>
                      {{ 'not_available' | translate }}
                    </ng-template>
                  </td>
                </ng-container>
                <ng-template #createReceiptView>
                  <td *ngIf="objStockReceipt['status'] === 'draft'" class="text-center">
                    <ng-container *ngIf="lineItem.quantity != 0; else hasZeroQuantity">
                      <input
                        type="number"
                        class="form-control fs12"
                        [(ngModel)]="lineItem.quantity"
                        (keyup)="onChangeQuantity(lineItem)"
                      />
                    </ng-container>
                    <ng-template #hasZeroQuantity>--</ng-template>
                  </td>
                  <td class="text-center"><span>{{ getTotalReceived(lineItem) | number }}</span></td>
                  <td class="text-center">
                    <span *ngIf="lineItem.description" matTooltip="{{lineItem.description}}">
                      {{ lineItem.description | slice:0:75 }}
                      <span *ngIf="lineItem.description.length > 75">...</span>
                    </span>
                    <span *ngIf="!lineItem.description">--</span>
                  </td>
                  <td class="text-center">
                    <ng-container *ngIf="objStockReceipt['status'] === 'draft'; else viewExpectedDate">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control fs12"
                          [(ngModel)]="lineItem.expected_receipt_date"
                          [owlDateTime]="dateTimeField"
                          [ngModelOptions]="{standalone: true}"
                          readonly
                          [owlDateTimeTrigger]="dateTimeField"
                          style="background: #fff;">
                        <button class="calendar-button" [owlDateTimeTrigger]="dateTimeField">
                          <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
                        </button>
                      </div>
                      <div>
                        <owl-date-time  #dateTimeField [pickerType]="'calendar'"></owl-date-time>
                      </div>
                    </ng-container>
                    <ng-template #viewExpectedDate>
                      {{ lineItem.expected_receipt_date | humanReadableDate | placeholdWithString }}
                    </ng-template>
                  </td>
                </ng-template>
              </tr>
            </ng-container>
          </ng-container>
          <ng-template #isView>
            <ng-container *ngFor="let lineItem of arLineItemQty; index as i">
              <tr>
                <td class="text-center">{{ (lineItem.item_id) ? '[' +lineItem.item_code+ '] ' +lineItem.item_name : '--' }}</td>
                <td *ngIf="bReceiptView" class="text-center">
                  <span>{{ lineItem.received | number }}</span>
                </td>
                <td class="text-center">
                  <span *ngIf="lineItem.description">
                    {{ lineItem.description | slice:0:75 }}
                    <span *ngIf="lineItem.description.length > 75">...</span>
                  </span>
                  <span *ngIf="!lineItem.description">--</span>
                </td>
                <td *ngIf="bReceiptView" class="text-center">
                  {{ lineItem.expected_receipt_date | humanReadableDate | placeholdWithString }}
                </td>
                <td *ngIf="bCreditView" class="text-center">
                  <span>{{ lineItem.credit }}</span>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </tbody>
      </table>
    </ng-template>
  </ng-template>
