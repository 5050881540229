import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedAuditTrailService } from './services/shared-audit-trail.service';

@NgModule({
  providers: [
    SharedAuditTrailService,
  ],
})
export class SharedAuditTrailModule {
  static forChild(): ModuleWithProviders {
    return {
      ngModule: SharedAuditTrailModule,
      providers: [
        SharedAuditTrailService
      ],
    };
  }
}