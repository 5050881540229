<header>
    <div class="d-flex">
        <h3 class="title">
            <fa-icon [icon]="['fas', 'comments']"></fa-icon>&nbsp;
            <span>{{ objActivity.activity_name }} </span>
        </h3>
        <div class="ml-auto d-flex">
            <ul class="list-inline pull-right">
                <li class="list-inline-item">
                    <button type="button" (click)="selfDialog.close()" class="btn btn-danger">
                        <fa-icon [icon]="['fas', 'times']"></fa-icon>
                        <span class="pl-1">{{ 'close' | translate }}</span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</header>

<hr class="separator"/>

<div class="row chat-screen" #scrollMe>
    <div *ngIf="!bChatLoaded; else showChats" class="col-12 text-center text-secondary m-4">
        <fa-icon [icon]="['fas','spinner']" [spin]="true" aria-hidden="true" class="pr-1"></fa-icon>
        <small>{{ 'loading_chat_messages' | translate }}</small>
    </div>
    <ng-template #showChats>
        <div *ngIf="objNextPage" class="col-12 mb-2 text-center chat-font-size p-2">
            <span class="text-info pointer" (click)="loadPreviousMessages()">
                <fa-icon *ngIf="bPreviousLoading" [icon]="['fas','spinner']" [spin]="true"></fa-icon>
                {{ 'load_previous_messages' | translate }}
            </span>
        </div>
        <ng-container *ngFor="let chat of arChats">
            <ng-container *ngIf="chat.type === 'notification'">
                <div class="col-12 text-center">
                    <small class="text-secondary"><i>{{ chat.name }} {{ chat.message | translate }}</i></small>
                </div>
            </ng-container>
            <ng-container *ngIf="chat.type !== 'notification'">
                <div class="col-12">
                    <div *ngIf="chat.created_by === strUserId" class="chat-bubble bg-secondary text-white float-right">
                        <ng-container *ngIf="chat.type == 'text'">
                            {{ chat.message }}
                        </ng-container>
                        <ng-container *ngIf="chat.type == 'image'">
                            <a href="" [routerLink]="" (click)="previewImage(chat.message)"><img [src]="chat.message" class="img-fluid"></a>
                        </ng-container>
                        <ng-container *ngIf="chat.type == 'file'">
                            <fa-icon class="text-white" [icon]="['fas','file-alt']"></fa-icon>&nbsp;
                            <a class="text-white">{{ chat.file_name }}</a>&nbsp;
                            <ng-container *ngIf="chat.message == 'file_infected'">
                                <fa-icon [icon]="['fas', 'exclamation-circle']" matTooltip="{{ 'infected_file' | translate}}"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="chat.message !== 'file_infected'">
                                <a [href]="chat.message" target="_blank">
                                    <fa-icon [icon]="['fas', 'download']" class="file-icon-download text-white" matTooltip="{{ 'download_file' | translate}}"></fa-icon>
                                </a>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div *ngIf="chat.created_by !== strUserId" class="chat-bubble bg-primary text-white float-left">
                        <ng-container *ngIf="chat.type == 'text'">
                            {{ chat.message }}
                        </ng-container>
                        <ng-container *ngIf="chat.type == 'image'">
                            <a href="" [routerLink]=""  (click)="previewImage(chat.message)"><img [src]="chat.message" class="img-fluid"></a>
                        </ng-container>
                        <ng-container *ngIf="chat.type == 'file'">
                            <fa-icon class="text-white" [icon]="['fas','file-alt']"></fa-icon>&nbsp;
                            <a class="text-white" target="_blank">{{ chat.file_name }}</a>&nbsp;
                            <ng-container *ngIf="chat.message == 'file_infected'">
                                <fa-icon [icon]="['fas', 'exclamation-circle']" matTooltip="{{ 'infected_file' | translate}}"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="chat.message !== 'file_infected'">
                                <a [href]="chat.message">
                                    <fa-icon [icon]="['fas', 'download']" class="file-icon-download text-white" matTooltip="{{ 'download_file' | translate}}"></fa-icon>
                                </a>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="col-12 text-secondary chat-info mb-2">
                    <small [ngClass]="{'float-right': chat.created_by === strUserId}" class="chat-details">
                        <span *ngIf="chat.name && chat.created_by !== strUserId" class="font-weight-bold">
                            <fa-icon [icon]="['fas', 'circle']" [ngClass]="isOnline(chat.created_by) ? 'text-success' : 'text-secondary'" style="font-size: 10px;"></fa-icon>
                            {{ chat.name }},
                        </span>
                        <span *ngIf="!chat.name" class="font-weight-bold">{{ objActivity.activity_name }},</span>
                        {{ chat.created_at | local | format:'lll' }}
                    </small>
                </div>
            </ng-container>
        </ng-container>
    </ng-template>
</div>

<div class="row">
    <div class="col-12 mt-3">
        <div mwlTextInputHighlightContainer>
            <textarea
                mwlTextInputElement
                #textarea
                rows="2"
                [mention]="mentionsService.objUsersRelate.source | async"
                [mentionConfig]="mentionsService.objMentionSettings"
                (searchTerm)="mentionsService.searchUsers($event, strMessage.value)"
                (itemSelected)="mentionsService.addMention($event)"
                [mentionListTemplate]="mentionsTemplate"
                class="form-control chat-font-size"
                placeholder="{{ 'chat_input_placeholder' | translate }}"
                (keydown.enter)="onSubmit()"
                [formControl]="strMessage">
            </textarea>
            <mwl-text-input-highlight
                [tags]="mentionsService.arMentions"
                [tagCssClass]="'chat-mention'"
                [textInputElement]="textarea">
            </mwl-text-input-highlight>
        </div>
    </div>
    <div class="col-9">
        <button *ngFor="let action of arActions" class="btn btn-outline-secondary mt-2 mr-2" (click)="triggerAction(action)">
            <fa-icon *ngIf="action.loader" [icon]="['fas','spinner']" [spin]="true"></fa-icon>
            <fa-icon *ngIf="!action.loader" [icon]="['far', action.icon]"></fa-icon>
            {{ action.id | translate }}
        </button>
    </div>
    <div class="col-3">
        <button class="btn btn-primary float-right mt-2" (click)="onSubmit()" [disabled]="strMessage.disabled">
            <fa-icon *ngIf="strMessage.disabled" [icon]="['fas','spinner']" [spin]="true"></fa-icon>
            <fa-icon *ngIf="!strMessage.disabled" [icon]="['far', 'location-arrow']"></fa-icon>
            &nbsp;{{ 'send' | translate }}
        </button>
    </div>
</div>

<ng-template #mentionsTemplate let-item="item">
    <span>{{item.name}}</span>
</ng-template>
