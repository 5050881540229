// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  base_api_url: 'https://uatapi.fieldmagic.co',
  url: 'https://uatapi.fieldmagic.co/private',
  app_url: 'https://uat.fieldmagic.co',
  lambda_url: 'https://sls-uat.fieldmagic.co',
  search_url: 'https://uatapi.fieldmagic.co/private/search',
  version_folder: '',
  admin_email: 'support@crmonline.com.au',
  atatus_config: '2a27e202b248478dbc80025c05a1fdac',
  website: 'https://website-uat.fieldmagic.co/',
  aws_appsync_graphqlEndpoint: 'https://tt65nquagzfadl3cdzuzdutuxe.appsync-api.eu-west-1.amazonaws.com/graphql',
  chargebee: {
    site: 'fieldmagic-test',
    publishableKey: 'test_Wd0xURBTuP6PatgvFnyQSsdUCr7Icu7sB'
  },
  api_public_base_url: 'https://uatapi.fieldmagic.co/public',
  forms_generator_base_url: 'https://mbvtfwk3arkqb3cdksurqqhozm0olwnr.lambda-url.eu-west-1.on.aws',
  accounting_system_authorization_callback_origins: [
    'https://uatapi.fieldmagic.co/'
  ],
  pusher: {
    key: '2da851a3ad8ea162f5d1',
    cluster: 'ap4',
    auth_endpoint: 'https://uatapi.fieldmagic.co/pusher/auth/'
  },
  public_bucket_url: 'https://public-uat.fieldmagic.co/',
  public_bucket: 'fieldmagic-public-files-uat',
  temp_bucket: 'fieldmagic-tmp',
  client_bucket: 'fieldmagic-uat-files',
  firebase: {
    apiKey: "AIzaSyBRfKi3-A3wsLd_AUUSNQtKlmsmlucoc3s",
    authDomain: "fieldmagic-cloud-uat.firebaseapp.com",
    projectId: "fieldmagic-cloud-uat",
    storageBucket: "fieldmagic-cloud-uat.appspot.com",
    messagingSenderId: "487421712908",
    appId: "1:487421712908:web:e4d0d2311efa080396ec29",
  },
  recaptcha_site_key: '6LcTxuEkAAAAAL-btpWfIgVsUg3EHmJc00u4Do4G',
  archive_domain_name: 'archive.fieldmagic.co'
};
