import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinimumSubscriptionPlanRequiredDirective } from '../../shared/directives/MinimumSubscriptionRequired/minimum-subscription-required.directive';
import { AccessLevelRequiredDirective } from '../../shared/directives/access-level-required.directive';
import { HasPermissionDirective } from '../directives/has-permission.directive';
import { HasWidgetPermissionDirective } from '../directives/has-widget-permission.directive';
import { RowDeleteButtonDirective } from '../directives/listing/row/delete-button.directive';
import { ListRowDirective } from '../directives/listing/row.directive';
import { EchartsDirective } from '../directives/echarts.directive';
import {
  AsyncLoadedContentComponentDirective,
  BoxDirective,
  DialogButtonsTemplateDirective,
  DialogContenTemplatetDirective,
  DialogHeaderTitleTemplateDirective,
  GridColumnDirective,
  GridRowDirective,
  PaginationLinksTopContentDirective,
  PaginationListItemDirective,
  TableRowColumnDirective,
  TableRowDirective,
  TabPaneDirective,
  WYSWYGFormatDirective,
  DropdownButtonActionItemDirective,
} from '../directives/component.directive';
import { IsConfiguredDirective } from '../directives/is-configured.directive';

@NgModule({
  declarations: [
    MinimumSubscriptionPlanRequiredDirective,
    AccessLevelRequiredDirective,
    HasPermissionDirective,
    HasWidgetPermissionDirective,
    RowDeleteButtonDirective,
    ListRowDirective,
    EchartsDirective,
    TabPaneDirective,
    GridColumnDirective,
    BoxDirective,
    TableRowDirective,
    TableRowColumnDirective,
    PaginationLinksTopContentDirective,
    GridRowDirective,
    AsyncLoadedContentComponentDirective,
    DialogHeaderTitleTemplateDirective,
    DialogButtonsTemplateDirective,
    DialogContenTemplatetDirective,
    PaginationListItemDirective,
    WYSWYGFormatDirective,
    DropdownButtonActionItemDirective,
    IsConfiguredDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MinimumSubscriptionPlanRequiredDirective,
    AccessLevelRequiredDirective,
    HasPermissionDirective,
    HasWidgetPermissionDirective,
    RowDeleteButtonDirective,
    ListRowDirective,
    EchartsDirective,
    TabPaneDirective,
    GridColumnDirective,
    BoxDirective,
    TableRowDirective,
    TableRowColumnDirective,
    PaginationLinksTopContentDirective,
    GridRowDirective,
    AsyncLoadedContentComponentDirective,
    DialogHeaderTitleTemplateDirective,
    DialogButtonsTemplateDirective,
    DialogContenTemplatetDirective,
    PaginationListItemDirective,
    WYSWYGFormatDirective,
    DropdownButtonActionItemDirective,
    IsConfiguredDirective,
  ],
})
export class DirectivesModule { }
