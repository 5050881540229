<td
  *ngIf="hasParent"
  class="text-center indent-and-drag-asset"
>
  <div class="bg-indent"></div>
</td>

<!-- For REARRANGING prompts -->
<ng-content></ng-content>

<td
  class="text-center pl-0"
  [class.prompt-child-label]="hasParent"
>
  <div class="d-flex align-items-center gap-10">
    <fieldmagic-textarea-input
      class="w-100"
      id="prompt-name-{{ idSuffix }}"
      [(ngModel)]="prompt.prompt"
      (change)="onPromptLabelChange()"
      [rows]="2"
      [expandable]="true"
      [errors]="errors | data_get: ('%s:prompt' | sprintf: [prompt.id])"
    ></fieldmagic-textarea-input>
  </div>
</td>

<td
  [class.prompt-child-type]="hasParent"
>
  <div class="d-flex justify-content-center">
    <fieldmagic-dropdown-input
      class="w-100"
      id="prompt-type-{{ idSuffix }}"
      [options]="types$ | async"
      [(ngModel)]="prompt.type"
      (change)="onPromptTypeChange($event)"
      [clearable]="false"
      [errors]="errors | data_get: ('%s:type' | sprintf: [prompt.id])"
    >
    </fieldmagic-dropdown-input>
  </div>
</td>

<td
  class="text-center"
  [class.prompt-child-value]="hasParent"
>
  <ng-container [ngSwitch]="prompt.type">
    <ng-container *ngSwitchCase="'pass/fail'">
      <div class="d-flex d-flex-gap">
        <fieldmagic-multitext-input
          class="w-100"
          variant="success"
          [ngModel]="prompt | data_get: 'value.pass'"
          placeholder="Pass Values"
          (change)="onPassOptionsChanged($event)"
          [errors]="errors | data_get: ('%s:value:pass' | sprintf: [prompt.id])"
        >
        </fieldmagic-multitext-input>

        <fieldmagic-multitext-input
          class="w-100"
          variant="error"
          [ngModel]="prompt | data_get: 'value.fail'"
          placeholder="Fail Values"
          (change)="onFailOptionsChanged($event)"
          [errors]="errors | data_get: ('%s:value:fail' | sprintf: [prompt.id])"
        >
        </fieldmagic-multitext-input>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'field_entry'">
      <ng-container
        [ngTemplateOutlet]="fieldEntryInputTmp"
        [ngTemplateOutletContext]="{
          idPrefix: 'field_entry-field',
          target: 'field_entry',
          model: prompt | data_get: 'value.field_entry',
          errors: errors | data_get: ('%s:value' | sprintf: [prompt.id])
        }"
      >
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'instructions'">
      <div class="d-flex">
        <fieldmagic-textarea-input
          class="w-100"
          id="instruction-text-field-{{ idSuffix }}"
          [(ngModel)]="prompt.value.instructions_text"
          [rows]="2"
          [expandable]="true"
          [errors]="errors | data_get: ('%s:value' | sprintf: [prompt.id])"
          [wyswyg]="true"
        >
          <ng-template fieldmagicWyswygFormat>
            <div style="width: 200px">
              <ng-container
                [ngTemplateOutlet]="fieldEntryInputTmp"
                [ngTemplateOutletContext]="{
                  idPrefix: 'instruction-field-entry-field',
                  target: 'instructions',
                  model: prompt | data_get: 'value.instructions'
                }"
              >
              </ng-container>
            </div>
          </ng-template>
        </fieldmagic-textarea-input>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'dropdown'">
      <fieldmagic-multitext-input
        [ngModel]="prompt | data_get: 'value.dropdown'"
        (change)="onDropdownOptionChanged($event)"
        placeholder="Please define the options"
        [errors]="errors | data_get: ('%s:value' | sprintf: [prompt.id])"
      ></fieldmagic-multitext-input>
    </ng-container>

    <span *ngSwitchDefault>--</span>

    <ng-template
      #fieldEntryInputTmp
      let-idPrefix="idPrefix"
      let-target="target"
      let-model="model"
      let-errors="errors"
    >
      <fieldmagic-dropdown-input
        class="text-left w-100"
        id="{{ idPrefix }}-{{ idSuffix }}"
        [options]="fieldEntries$ | async"
        [grouped]="true"
        [filter]="fieldEntryFilterFactory()"
        placeholder="Please select a field"
        (change)="onFieldEntryChange($event, target)"
        [ngModel]="model"
        bindValue="id"
        [errors]="errors"
      >
      </fieldmagic-dropdown-input>
    </ng-template>
  </ng-container>
</td>

<!-- For Schedule Type (Backward Compatibility) -->
<td
  class="prompt-child-schedule_type"
  *ngIf="forType == 'asset'"
>
  <div class="d-flex flex-column">
    <ng-container *ngFor="let period of scheduleTypes">
      <fieldmagic-checkbox-input
        [useNative]="true"
        id="prompt-schedule-type-{{ period }}-{{ idSuffix }}"
        [(ngModel)]="prompt.schedule_type[period]"
        [label]="period"
      ></fieldmagic-checkbox-input>
    </ng-container>
  </div>
</td>

<!-- For REQUIRED checkbox -->
<td
  class="text-center"
  [class.prompt-child-icon]="hasParent"
>
  <div class="d-flex align-items-end justify-content-center">
    <fieldmagic-checkbox-input
      id="prompt-required-{{ idSuffix }}"
      [(ngModel)]="prompt.is_required"
      (change)="emitAsEdited()"
    >
    </fieldmagic-checkbox-input>
  </div>
</td>

<!-- For VISIBLE IN REPORTS checkbox -->
<td
  class="text-center"
  [class.prompt-child-icon]="hasParent"
>
  <div class="d-flex align-items-end justify-content-center">
    <fieldmagic-checkbox-input
      id="prompt-report-visibility-{{ idSuffix }}"
      [(ngModel)]="prompt.is_visible_in_reports"
      (change)="emitAsEdited()"
    >
    </fieldmagic-checkbox-input>
  </div>
</td>

<!-- For IMAGE IN EVERY PROMPT checkbox -->
<td
  class="text-center"
  [class.prompt-child-icon]="hasParent"
>
  <div class="d-flex align-items-end justify-content-center">
    <fieldmagic-checkbox-input
      id="prompt-enable-image-capturing-{{ idSuffix }}"
      [(ngModel)]="prompt.is_image_enabled"
      (change)="emitAsEdited()"
      [disabled]="prompt.type == 'images'"
    >
    </fieldmagic-checkbox-input>
  </div>
</td>

<!-- For REMOVAL OF PROMPT -->
<td
  class="text-center"
  [class.prompt-child-actions]="hasParent"
>
  <fieldmagic-primary-button
    id="removePrompt-{{ idSuffix }}"
    purpose="delete-icon"
    matTooltip="Delete this prompt"
    (click)="onDelete()"
  >
  </fieldmagic-primary-button>
</td>
