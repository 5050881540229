<header>
  <div class="d-flex wizard-header">
    <h3 class="dialog-title">
      <fa-icon [icon]="['fas', 'users']" transform="shrink-3" class="dialog-title"></fa-icon>
      <span>&nbsp;{{ 'create_customer' | translate }}</span>
    </h3>
    <ng-container *fcHasPermission="'customers:create'">
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <fieldmagic-primary-button
              [disabled]="bShowLoader"
              (click)="wizardService.onDialogClose.next(true)"
              variant="error"
              id="wizardCustomerCancelDialog"
              label="close"
              icon="times"
            ></fieldmagic-primary-button>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</header>

<hr>
<ng-container *fcHasPermission="'customers:create'; else createCustomersDisabled">
  <create-wizard
    [stepperSettings]="wizardStepper"
    [dialogRef]="dialogRef">

    <ng-template #step1 let-stepper="stepper">

      <customer-step
        [preselectedCustomer]="dialogData.customer_id"
        [strRecordType]="'customers'"
        [preselectedSite]="dialogData.site_id"
        [stepper]="stepper"
        [skipWhenPreselected]="false"
        (objCustomerData)="captureCustomerData($event, stepper)">
      </customer-step>

    </ng-template>

    <ng-template #step2 let-stepper="stepper">

      <contact-step
        [customerId]="strCustomerId"
        [siteId]="strSiteId"
        [stepper]="stepper"
        [preselectedContact]="dialogData.contact_id"
        (objContactData)="captureContactData($event, stepper)">
      </contact-step>

    </ng-template>

    <ng-template #step3 let-stepper="stepper">

      <div *ngIf="strExtraStep === undefined" class="container-fluid">

        <div class="row">
          <div class="col-xs-6 col-md-6 form-group">
            <strong id="contactStepTitle">{{ 'create' | translate }}</strong>
            <br />
            <small>{{ 'actions_continue' | translate }}</small>
          </div>
        </div>

        <div class="row">

          <div *ngFor="let actions of arActions" class="col-xs-3 col-md-3 mb-3">
            <div class="border border-primary wizard-card rounded d-flex align-items-center">
              <div class="d-flex flex-column w-100 mb-3">
                <div class="d-flex justify-content-center w-100 mb-2" >
                  <fa-icon class="text-primary fa-3x" [icon]="['fas', actions[1]]"></fa-icon>
                </div>
                <div class="d-flex justify-content-center w-100 mb-3 h4 text-center">
                  <span>{{ actions[0] | translate }}</span>
                </div>
                <div class="d-flex justify-content-center w-100 mt-2">
                  <button id="btnCustomerWizard{{actions[0]}}" type="button" class="btn btn-outline-primary btn-lg wizard-card-button" (click)="redirect(actions[0])"><b>{{ 'create' | translate }}</b></button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-3 col-md-3 mb-3">
            <div class="border border-warning wizard-card rounded d-flex align-items-center">
              <div class="d-flex flex-column w-100 mb-3">
                <div class="d-flex justify-content-center w-100 mb-2" >
                  <fa-icon class="text-warning fa-3x" [icon]="['fas', 'times-circle']"></fa-icon>
                </div>
                <div class="d-flex justify-content-center w-100 mb-3 h4">
                  <span>{{ 'no_action' | translate }}</span>
                </div>
                <div class="d-flex justify-content-center w-100 mt-2">
                  <button id="btnCustomerWizardno_action" type="button" class="btn btn-outline-warning btn-lg wizard-card-button" (click)="redirect('no_action')"><b>{{ 'no_action' | translate }}</b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </create-wizard>
</ng-container>

<ng-template #createCustomersDisabled>
  <span id="btnCreateCustomersDisabled" class="disabled"
    matTooltip="{{ 'forbidden_action_error' | translate }}">
    {{ 'forbidden_action_error' | translate }}
  </span>
</ng-template>