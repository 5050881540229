import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomFieldViewComponent, Form } from '../../../../../base/form';
import { AssetService } from '../../../../../services/asset.service';
import { ViewService } from '../../../../../services/view.service';
import { get } from 'lodash';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { fallback, filled, isId } from '../../../../../shared/utils/common';
import { spf } from '../../../../../shared/utils/str';

@Component({
  selector: 'fc-asset-hierarchical-view',
  templateUrl: './asset-hierarchical-view.component.html',
})

export class AssetHierarchicalViewComponent implements CustomFieldViewComponent, OnInit, OnDestroy {
  /**
   * Contains the ID of the asset record.
   *
   * @type {string}
   */
  strRecordId: string;

  /**
   * Tracks if the getHierarchicalView API call is still loading.
   *
   * @type {boolean}
   */
  bLoading: boolean = false;

  /**
   * Variable that holds the tree's controls.
   *
   * @var {FlatTreeControl<AssetHierarchyNode>}
   */
  treeControl = new FlatTreeControl<AssetHierarchyNode>(
    node => node.level,
    node => node.expandable
  );

  /**
   * Flattens the data source.
   *
   * @var {MatTreeFlattener}
   */
  treeFlattener = new MatTreeFlattener(
      (node: AssetHierarchyNode) => node,
      node => node.level,
      node => node.expandable,
      node => null
  );

  /**
   * Datasource for the tree.
   *
   * @var {MatTreeFlatDataSource}
   */
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  /**
   * Checks if the current node selected has a child.
   *
   * @param {number} level
   * @param {AssetHierarchyNode} node
   *
   * @returns {boolean}
   */
  hasChild = (level: number, node: AssetHierarchyNode) => node.expandable;

  /**
   * Contains the subscription for later cleanup.
   *
   * @type {Subscription}
   */
  private _sub: Subscription;

  constructor(
    private assetService: AssetService,
    private viewService: ViewService,
  ) { }

  ngOnInit(): void {
    this.strRecordId = get(this.viewService.getViewRecord(), 'id', '');

    if (filled(this.strRecordId) && isId(this.strRecordId)) {
      this.bLoading = true;

      this. _sub = this.assetService.getHierarchicalView(this.strRecordId).pipe(
        finalize(() => this.bLoading = false)
      ).subscribe((result: { data: AssetHierarchyItem[] }) => {
        if (result.data.length > 1) {
          const arAssetHierarchyNodes: AssetHierarchyNode[] = this._getAssetHierarchyNodes(result.data);
          this.dataSource.data = arAssetHierarchyNodes;

          this.treeControl.expandAll();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  /**
   * @inheritdoc
   */
  setField(field: Form<any>): void {
    // no need at the moment
  }

  /**
   * Converts the AssetHierarchyItem into a AssetHierarchyNode instance.
   *
   * @param {AssetHierarchyItem} item
   * @param {boolean} bExpandable = false
   * @param {number} numLevel = 0
   *
   * @returns {AssetHierarchyNode}
   */
  private _convertToHierarchyNode(
    item: AssetHierarchyItem,
    bExpandable: boolean = false,
    numLevel: number = 0
  ): AssetHierarchyNode {
    return new AssetHierarchyNode({
      ...item,
      level: numLevel,
      expandable: bExpandable
    });
  }

  /**
   * Converts the AssetHierarchyItem array data into an array containing multiple AssetHierarchyNode instances.
   *
   * @param {AssetHierarchyItem[]} arItems
   *
   * @returns {AssetHierarchyNode[]}
   */
  private _getAssetHierarchyNodes(arItems: AssetHierarchyItem[]): AssetHierarchyNode[] {
    if (arItems.length > 1) {
      const arAssetHierarchyNodes: AssetHierarchyNode[] = [this._convertToHierarchyNode(arItems[0], true)];

      for (let i = 1; i < arItems.length; i++) {
        const objCurrentAsset: AssetHierarchyItem = arItems[i];
        // we need to get the parent asset to determine the asset's depth level in the tree hierarchy
        const objParentAsset: AssetHierarchyNode = arAssetHierarchyNodes.find(item => item.id === objCurrentAsset.parent_asset_id);
        // generate node level
        const numLevel: number = filled(objParentAsset) ? objParentAsset.level + 1 : 0;
        // check if node is expandable (has child)
        const strNextItemParentAssetId: string|undefined = get(arItems, `${i + 1}.parent_asset_id`);
        const bExpandable: boolean = filled(objCurrentAsset.id) && (objCurrentAsset.id === strNextItemParentAssetId);
        // push node to array
        arAssetHierarchyNodes.push(this._convertToHierarchyNode(arItems[i], bExpandable, numLevel));
      }

      return arAssetHierarchyNodes;
    } else {
      return [];
    }
  }
}

export interface AssetHierarchyItem {
  id: string;
  parent_asset_id: string|null;
  serial_number: string;
  asset_type_text: string;
  asset_name?: string;
}

interface AssetHierarchyNodeInterface extends AssetHierarchyItem {
  level: number;
  expandable: boolean;
}

class AssetHierarchyNode implements AssetHierarchyNodeInterface {
  id: string;
  parent_asset_id: string|null;
  serial_number: string;
  asset_type_text: string;
  level: number;
  expandable: boolean;
  asset_name?: string;

  get name(): string {
    return spf('[%s] %s', {
      args: [
        this.asset_type_text,
        fallback(this.asset_name, {
          fallback: () => this.serial_number,
        }),
      ]
    });
  }

  constructor(properties: AssetHierarchyNodeInterface) {
    this.id = properties.id;
    this.parent_asset_id = properties.parent_asset_id || null;
    this.serial_number = properties.serial_number;
    this.asset_type_text = properties.asset_type_text;
    this.level = properties.level;
    this.expandable = properties.expandable;
    this.asset_name = properties.asset_name;
  }
}
