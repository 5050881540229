
  <ng-container *ngIf="!isLoading">
    <fc-common-pagination [objPagination]="listingService" (objNavigate)="getList($event)"></fc-common-pagination>
    <table class="table table-bordered">
      <thead>
        <tr>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="recordList.length > 0; else emptyRecord">
          <tr *ngFor="let history of recordList; let i = index;">
            <td class="form-group" width="25%">
              <ng-container *ngIf="history.purchase_order_id">
                <div><b>{{ 'purchase_order_number' | translate }}</b></div>
                <div>
                  <a [routerLink]="getLink('purchase_orders', history.purchase_order_id)">
                    #{{ history['purchase_order_text'] | placeholdWithString }}
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="history.job_id">
                <div><b>{{ 'job_number' | translate }}</b></div>
                <div>
                  <a [routerLink]="getLink('jobs', history.job_id)">
                    #{{ history['job_text'] | placeholdWithString }}
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="history.customer_invoice_id">
                <div><b>{{ 'invoice_number' | translate }}</b></div>
                <div>
                  <a [routerLink]="getLink('customer_invoices', history.customer_invoice_id)">
                    #{{ history['customer_invoice_text'] | placeholdWithString }}
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="history.source_warehouse_id">
                <div><b>{{ 'stock_transfer' | translate }}</b></div>
                <div>
                  <span>
                    {{ history['warehouse_source_text'] }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="history.stock_take_id">
                <div><b>{{ 'from_stocktake' | translate }}</b></div>
              </ng-container>
              <ng-container *ngIf="history.supplier_invoice_id">
                <div><b>{{ 'supplier_invoice_number' | translate }}</b></div>
                <div>
                  <a [routerLink]="getLink('supplier_invoices', history.supplier_invoice_id)">
                    #{{ history['supplier_invoice_text'] | placeholdWithString }}
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="hasNoRelateField(history) && history.quantity == 0">
                <div>
                  <span>
                    {{ 'created' | translate }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="hasNoRelateField(history) && history.quantity != 0">
                <div>
                  <span>
                    {{ 'used' | translate }}
                  </span>
                </div>
              </ng-container>
            </td>
            <td class="form-group" width="25%">
              <div><b>{{ 'warehouse' | translate }}</b></div>
              <span>
                {{ history['warehouse_destination_text'] | placeholdWithString }}
              </span>
            </td>
            <td class="form-group" width="25%">
              <div><b>{{ 'quantity' | translate }}</b></div>
              <span>
                {{ history['quantity'] }}
              </span>
            </td>
            <td class="form-group" width="25%">
              <div><b>{{ 'created_at' | translate }}</b></div>
              <span>
                {{ history['created_at'] | humanReadableDate }}
              </span>
            </td>
          </tr>
        </ng-container>
        <ng-template #emptyRecord>
          <tr>
            <td class="text-center" colspan="4">{{ "no_record_found" | translate }}</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </ng-container>
  