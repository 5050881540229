
    <fieldmagic-dialog
      title="Change Password"
      icon="key"
      [instance]="self"
    >
      <ng-template fieldmagicDialogButtonsTmp>
        <fieldmagic-primary-button
          purpose="save"
          (click)="onSave()"
          [isInProgress]="saving$ | async"
        >
        </fieldmagic-primary-button>
      </ng-template>

      <ng-template fieldmagicDialogContentTmp>
        <div class="d-flex flex-column d-flex-gap-2x">
          <fieldmagic-alert>
            <fieldmagic-text content="Secure password rules"></fieldmagic-text>
            <div class="d-flex d-flex-gap">
              <ul>
                <li><fieldmagic-text content="Password must be atleast 10 characters."></fieldmagic-text></li>
                <li><fieldmagic-text content="Password must contain atleast 1 uppercase character."></fieldmagic-text></li>
                <li><fieldmagic-text content="Password must contain atleast 1 number character."></fieldmagic-text></li>
                <li><fieldmagic-text content="Password must contain atleast 1 lowercase character."></fieldmagic-text></li>
              </ul>
            </div>
          </fieldmagic-alert>

          <div class="d-flex flex-column">
            <fieldmagic-text-input
              label="New Password"
              [(ngModel)]="newPassword"
              [standalone]="false"
              [obsecure]="true"
              [withRequiredMarker]="true"
              [errors]="errors | data_get: 'new_password'"
            >
            </fieldmagic-text-input>

            <fieldmagic-text-input
              label="Confirm Password"
              [standalone]="false"
              [(ngModel)]="confirmPassword"
              [obsecure]="true"
              [withRequiredMarker]="true"
              [errors]="errors | data_get: 'confirm_password'"
            >
            </fieldmagic-text-input>
          </div>
        </div>
      </ng-template>
    </fieldmagic-dialog>
  