import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';
import { LooseObject } from '../objects/loose-object';
import { isBoolean, isNumber, isObject, merge, round } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
import { data_get, fallback, filled } from '../shared/utils/common';
import { AdvanceReportResponse, Aggregation, AssetTypeAttributeResponse, CategoryMapping, ChartConfig, DashboardDataResponse } from '../objects/advance-reports';
import { DashboardType } from '../dashboard-new/dashboard-new.interface';
import { spf } from '../shared/utils/str';

const kBaseUrl: string = environment.url + "/advance_reports/";

@Injectable()
export class AdvanceReportService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) { }

  searchAssetTypes(searchTerm: string = '', page: number = 1): Observable<AssetTypeAttributeResponse[]> {
    let remoteUrl = `${kBaseUrl}asset_types?term=${searchTerm}&page=${page}`;
    let body = new URLSearchParams();
    return this.http.post<AssetTypeAttributeResponse[]>(remoteUrl, body.toString());
  }

  getAssetTypeAttribute(id: string): Observable<AssetTypeAttributeResponse|null> {
    if (filled(id) === false) {
      return of(null);
    }
    let remoteUrl = `${kBaseUrl}asset_type_attributes/${id}`;
    let body = new URLSearchParams();
    return this.http.post<AssetTypeAttributeResponse>(remoteUrl, body.toString());
  }

  listAdvanceReports(module: string): Observable<LooseObject[]> {
    let remoteUrl = `${kBaseUrl}list/${module}`;

    let body = new URLSearchParams();

    return this.http.post<LooseObject[]>(remoteUrl, body.toString())
      .catch(error => {
        return Observable.throw(error);
      });
  }

  getAdvanceReportData(config: LooseObject, module: string): Observable<AdvanceReportResponse> {
    let remoteUrl = `${kBaseUrl}get_report_data/${module}`;

    let body = new URLSearchParams();
    body.append('filters', JSON.stringify(config.filters));
    body.append('fields', JSON.stringify(config.fields));
    body.append('chart_config', JSON.stringify(config.chart_config));
    if (config.sort !== undefined && isObject(config.sort) && filled(config.sort)) {
      body.append('sort', JSON.stringify(config.sort));
    }
    if (config.page !== undefined && isNumber(config.page) && filled(config.page)) {
      body.append('page', config.page.toString());
    }
    if (config.page_size !== undefined && isNumber(config.page_size) && filled(config.page_size)) {
      body.append('page_size', config.page_size.toString());
    }
    if (config.remove_aggregations !== undefined && isBoolean(config.remove_aggregations)) {
      body.append('remove_aggregations', config.remove_aggregations.toString());
    }
    if (config.chart_filter !== undefined && isObject(config.chart_filter) && filled(config.chart_filter)) {
      body.append('chart_filter', JSON.stringify(config.chart_filter));
    }
    if (filled(config.asset_type_id)) {
      body.append('asset_type_id', config.asset_type_id);
    }

    return this.http.post<AdvanceReportResponse>(remoteUrl, body.toString()).catch(error => {
      return Observable.throw(error);
    });
  }

  saveAdvanceReportData(config: LooseObject, module: string, recordId: string = null): Observable<LooseObject[]> {
    let remoteUrl = `${kBaseUrl}save`;

    let body = new URLSearchParams();
    body.append('name', config.name);
    body.append('module', module);
    body.append('filters', JSON.stringify(config.filters));
    body.append('fields', JSON.stringify(config.fields));
    body.append('chart_config', JSON.stringify(config.chart_config));
    if (filled(config.asset_type_id)) {
      body.append('asset_type_id', config.asset_type_id);
    }

    let data = body.toString();

    if (recordId) {
      return this.http.put<LooseObject[]>(`${remoteUrl}/${recordId}`, data).catch(error => {
        return Observable.throw(error);
      });
    } else {
      return this.http.post<LooseObject[]>(remoteUrl, data).catch(error => {
        return Observable.throw(error);
      });
    }
  }

  aggregationResultToChart(aggregation: Aggregation, chartConfig: ChartConfig) {
    const buckets = aggregation.buckets;
    const grouping = chartConfig['grouping_config'];
    let series = chartConfig.datasets.map((dataset, index) => {
      return {
        name: dataset.name + ' '.repeat(index),
        type: dataset.chart_type || 'bar',
        data: buckets.map((bucket) => {

          if (bucket[dataset.name].aggregated_result === undefined) {
            // Drilldown chart (When using mutli bucket as dataset)
            return {
              value: bucket.doc_count,
              itemStyle: {},
              drilldownData: this.setupDrilldownData(bucket.key_as_string, dataset.actual_field, bucket[dataset.name]),
            };
          } else {
            // Regular chart
            return {
              value: bucket[dataset.name].aggregated_result.value || 0,
              itemStyle: {},
            };
          }
        }),
        color: dataset.color,
        symbol: 'circle',
        symbolSize: 15,
        yAxisIndex: index,
      }
    });
    let toolboxConfig = {
      show: true,
      feature: {
        saveAsImage: {
          show: true,
        },
        magicType: {
          show: true,
          type: ['line', 'bar'],
        },
        restore: {
          show: true,
        },
        dataView: {
          show: true,
          readOnly: true,
        },
      }
    };

    const chartOptions = {
      title: {
        text: chartConfig.name,
      },
      dataZoom: [
        {
          type: 'slider',
          labelFormatter: (value, valueStr) => this.translate.instant(valueStr),
        }
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          let tooltipContent = '<div style="font-size: 12px;">';
          tooltipContent += spf('<b>%s: %s</b>', {
            args: [
              this.translate.instant(fallback(data_get(grouping, 'label'), {
                fallback: () => data_get(grouping, 'field'),
              })),
              this.translate.instant(params[0].name),
            ],
          });
          // Iterate over the params array to construct the tooltip content
          params.forEach((item) => {
            // Construct HTML for colored legend with inline styles
            tooltipContent += '<div><span style="display: inline-block; width: 12px; height: 12px; margin-right: 5px; background-color: ' + item.color + '; vertical-align: middle;"></span>' +
              item.seriesName + ': ' + item.value + '</div>';
          });

          tooltipContent += '</div>';
          return tooltipContent;
        }
      },
      legend: {
        // Workaround since echarts will not repeat same dataset name
        data: chartConfig.datasets.map((x, index) => x.name + ' '.repeat(index)),
        textStyle: {
          color: '#333'
        },
        top: 30,
      },
      toolbox: merge(toolboxConfig, this.translate.instant("ECHARTS.toolbox")),
      yAxis: series.map((ser, index) => ({
        type: 'value',
        name: '', // Hide yAxis label
        nameTextStyle: {
          fontSize: 12,
          fontWeight: 'bold',
        },
        position: index % 2 === 0 ? 'left' : 'right',
        axisLine: {
          lineStyle: {
            color: ser.color,
            width: 2,
          }
        },
        axisLabel: {
          show: true,
          color: '#333',
          fontSize: 12,
        }
      })),
      xAxis: {
        type: 'category',
        data: buckets.map((x: LooseObject) => x.key_as_string || x.key),
        axisLabel: {
          formatter: (value: string) => this.translate.instant(value),
        }
      },
      series: series,
      // This is not a native echarts chart option and will only be used specifically for terms grouping type
      categoryMapping: buckets.map((x: LooseObject): CategoryMapping => {
        // ES Dropdown single value structure
        let dropdownValue = {
          label: x.key_as_string || x.key,
          value: x.key_as_string || x.key,
        };

        // ES Relate single value structure
        let relateValue = {
          id: x.key,
          name: x.key_as_string || x.key,
          raw: {
            id: x.key,
            name: x.key_as_string || x.key,
          }
        }

        return {
          label: x.key_as_string || x.key,
          value: filled(aggregation.is_relate) && aggregation.is_relate ? relateValue : dropdownValue,
        }
      }),
    };

    return chartOptions;
  }

  setupDrilldownData(name: string, field: string, aggregation: Aggregation) {
    let chartOption = {};
    const buckets = aggregation.buckets;
    const commonChartOption = {
      title: {
        text: this.translate.instant(name) + ' - ' + this.translate.instant(field),
        show: false,
        textStyle: {
          fontWeight: 'normal',
          fontSize: 16,
          color: '#333',
        },
        subtextStyle: {
          fontWeight: 'normal',
          fontSize: 12,
          color: '#333',
        }
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        bottom: 0,
        left: 'center',
        pageIconColor: '#000',
        pageTextStyle: {
          color: '#000'
        },
      },
      graphic: [
        {
          type: 'text',
          top: 5,
          left: 5,
          style: {
            text: '<- Back',
            fontSize: 18,
            fontWeight: 'bold',
            fill: '#007BFF',
            cursor: 'pointer'
          },
        }
      ],
    };

    const axisValue = {
      type: 'value'
    };
    const axisCategory = {
      type: 'category',
      data: [
        this.translate.instant(field)
      ],
      axisLabel: {
        formatter: (value: string) => this.translate.instant(value),
      }
    };

    chartOption = {
      ...commonChartOption,
      xAxis: axisCategory,
      yAxis: axisValue,
      series: buckets.map((item) => ({
        name: this.translate.instant(item.key_as_string || item.key),
        type: 'bar',
        data: [round(parseFloat(item.aggregated_result.value), 2)]
      })),
    }

    return chartOption;
  }

  getDashboardData(config: LooseObject, dashboard: DashboardType, module: string): Observable<DashboardDataResponse> {
    let remoteUrl = `${kBaseUrl}dashboard_data/${dashboard}/${module}`;

    let body = new URLSearchParams();
    body.append('filters', JSON.stringify(config.filters));

    return this.http.post<AdvanceReportResponse>(remoteUrl, body.toString()).catch(error => {
      return Observable.throw(error);
    });
  }
}
