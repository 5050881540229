import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LooseObject } from '../../../../../objects/loose-object';
import { FormTemplateService } from '../../../../../module/form-templates/shared/services/form-template.service';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { FileService } from '../../../../../services/file/file.service';
import { Observable } from 'rxjs';
import { Attachment } from '../../../../../objects/attachement';

@Component({
  selector: 'app-checklist-attachments',
  templateUrl: './checklist-attachments.component.html',
  styleUrls: ['./checklist-attachments.component.scss']
})
export class ChecklistAttachmentsComponent implements OnInit {

  public jobChecklistResponses: LooseObject[] = [];
  public assetTypeChecklistResponses: LooseObject[] = [];

  public isFormSubmitted: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ChecklistAttachmentsComponent>,
    protected formTemplateService: FormTemplateService,
    protected fileService: FileService
  ) { }

  ngOnInit() {
    Object.keys(this.data.checklists).map(checklistType => {
      let currentChecklist = this.data.checklists[checklistType];

      if (checklistType === 'job_checklist') {
        this.jobChecklistResponses = this.modifyResponse(currentChecklist);
      } else {
        this.assetTypeChecklistResponses = this.modifyResponse(currentChecklist);
      }
    });
  }

  /**
   * Modify responses by adding selected key with default value as true
   * and removing data with empty response
   *
   * @param {LooseObject[]} currentChecklist
   * @returns {LooseObject[]}
   */
  modifyResponse(currentChecklist: LooseObject[]) : LooseObject[] {
    if (currentChecklist) {
      return currentChecklist.filter((value) => {
        return value['response'] !== null && value['response'] != '[]';
      })
      .map((value) => {
        value['selected'] = true;
        return value;
      });
    }
    return [];
  }

  /**
   * Triggered when the select all checkbox was clicked
   *
   * @param {event}
   * @returns {void}
   */
  selectAllAttachments(event): void {
    let isChecked = event.target.checked;

    this.jobChecklistResponses.map((value) => {
      value['selected'] = isChecked;
      return value;
    });

    this.assetTypeChecklistResponses.map((value) => {
      value['selected'] = isChecked;
      return value;
    });
  }

  /**
   * Select a single checkbox from checklists
   *
   * @param event
   * @param checklistType
   * @param index
   * @returns {void}
   */
  selectChecklist(event, checklistType, index): void {
    let isChecked = event.target.checked;

    if (checklistType === 'job_checklist') {
      this.jobChecklistResponses[index]['selected'] = isChecked
    } else {
      this.assetTypeChecklistResponses[index]['selected'] = isChecked
    }
  }

  /**
   * On submit, gets and returns all selected checklists
   *
   * @returns {void}
   */
  attachSelectedChecklists(): void {

    this.isFormSubmitted = true;

    let checklistResponses = {
      job_checklist: this.jobChecklistResponses.filter((value) => value['selected'] === true),
      asset_checklist: this.assetTypeChecklistResponses.filter((value) => value['selected'] === true)
    };

    let checklistAttachments = [];
    let checklistId = [];

    Object.keys(checklistResponses).forEach(checklistData => {
      checklistResponses[checklistData].forEach(checklist => {
        checklistId.push((checklistData === 'job_checklist') ? checklist.id : checklist.checklist_id);
      });
    });

    const pdfOptions = {
      module: 'checklists',
      document_type: Object.keys(checklistResponses),
      filter: { checklist_response_id: checklistId }
    };

    this.formTemplateService.getModuleDocumentData$(this.data.job_id, pdfOptions.module, {
      document_type: pdfOptions.document_type,
      filter: pdfOptions.filter,
    }).pipe(
      switchMap((result) => this.formTemplateService.generatePDFUrl$({
        module: pdfOptions.module,
        data: result.data,
        template: result.template.history,
        custom: {
          additional_document: []
        }
      }).pipe(
        switchMap((url) => this.formTemplateService.toBlob$(url)),
        map((rawFile) => ({
          rawFile,
          result
        }))
      )),
      switchMap((value) => {
        const filename = `${value.result.config.filename.substr(0, 85)}.pdf`;
        const file = new File([value.rawFile], filename, {
          type: 'application/pdf'
        });
        return this.fileService.upload(file, undefined, filename)
        .pipe(
          map((presigned) => ({
            name: filename,
            size: file.size / 1024,
            type: 'application/pdf',
            upload_name: presigned.filename
          }))
        )
      })
    ).pipe(
      finalize(() => {
        this.isFormSubmitted = false;
        this.exitDialog(false, checklistAttachments);
      })
    )
    .subscribe(attachmentData => {
      checklistAttachments = checklistAttachments.concat(attachmentData);
    })

  }

  /**
   * Triggers when user submits or cancels attachment
   *
   * @param isCancelled
   * @param jobChecklists
   * @param assetTypeChecklists
   */
  exitDialog(isCancelled: boolean = true, checklists: Array<Attachment> = []): void {

    this.dialogRef.close({
      is_cancelled: isCancelled,
      attachments: checklists
    });
  }

}