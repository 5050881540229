
import { Pipe, PipeTransform } from '@angular/core';
import { isString, size, toString } from 'lodash-es';
import { blank } from '../shared/utils/common';

@Pipe({ name: 'ellipsis' })

export class EllipsisPipe implements PipeTransform {

  transform(value: any, config?: ellipsisConfig): string|null {
    config = Object.assign({ max: 75 }, config);

    if (blank(value)) {
      return null;
    }

    if (! isString(value)) {
      value = toString(value);
    }

    if (size(value) < config.max) {
      return value;
    }

    if (size(value) > config.max) {
      value = value.substring(0, config.max).concat('...');
    }

    return value;
  }
}
export type ellipsisConfig = {
  max?: number;
}
