import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Select } from '../../../../objects/select';
import { isNil, isString } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, startWith, finalize, map } from 'rxjs/operators';
import { ChecklistsService } from '../../../../services/checklist.service';
import { arr_wrap, data_get, filled, safely_parse_json, str_split, transform } from '../../../../shared/utils/common';
import { Checklist, ChecklistType } from '../../shared/types';

@Component({
  selector: 'select-checklist-input',
  template: `
    <div
      *ngIf="label | filled; else input;"
      class="form-group"
    >
      <fieldmagic-text
        purpose="input"
        [content]="label"
        [withRequiredMarker]="withRequiredMarker"
      >
      </fieldmagic-text>

      <ng-container *ngTemplateOutlet="input"></ng-container>
    </div>

    <ng-template #input>
      <ng-select
        [(ngModel)]="model"
        (change)="onChanged($event)"
        [loading]="fetching$ | async"
        [items]="options$ | async"
        [typeahead]="typeahead$"
        [multiple]="multiple"
        [groupBy]="makeGroupNameFactory()"
        [placeholder]="placeholder"
        appendTo="body"
      >
        <ng-template
          ng-label-tmp
          let-item="item"
          let-clear="clear"
        >
          <span class="ng-value-label">
            {{ item.text | translate }}
          </span>

          <span
            *ngIf="multiple"
            class="ng-value-icon right selected-close"
            (click)="clear(item)"
            aria-hidden="true"
          >
            ×
          </span>
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
        >
          {{ item.text | translate }}
        </ng-template>

        <ng-template
          ng-optgroup-tmp
          let-item="item"
        >
          {{ item | data_get: 'label' | translate }}
        </ng-template>
      </ng-select>
      <fieldmagic-input-errors
        *ngIf="errors | filled"
        [errors]="errors"
      >
      </fieldmagic-input-errors>
    </ng-template>
  `,
  providers: [
    ChecklistsService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChecklistInputComponent),
      multi: true,
    }
  ]
})
export class ChecklistInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() label: string;

  @Input() multiple: boolean = false;

  @Input() types: ChecklistType[] = [];

  @Input() assetTypeIds: string[] | string = [];

  @Input() grouped: boolean = false;

  @Input() placeholder?: string;

  @Input() errors: string | string[] = [];

  @Input() withRequiredMarker: boolean = false;

  @Input() size: number = 10;

  @Output('change') $onChanged = new EventEmitter<ChecklistInputChangedEvent>();

  readonly typeahead$ = new Subject<string>();
  readonly fetching$ = new BehaviorSubject<boolean>(false);

  options$: Observable<Select[]>;
  model: ChecklistInputValue;

  _onChangedFn: (event: ChecklistInputChangedEvent) => void;
  _onTouchedFn: () => void;

  constructor(
    private _checklists: ChecklistsService,
  ) {}

  ngOnInit(): void {
    if (isString(this.assetTypeIds) && filled(this.assetTypeIds)) {
      this.assetTypeIds = str_split(this.assetTypeIds, {
        delimiter: ',',
      });
    }

    this._initializeRemoteOptions();
  }

  ngOnChanges(_: SimpleChanges): void {
    this._initializeRemoteOptions();
  }

  registerOnChange(fn: any): void {
    this._onChangedFn = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedFn = fn;
  }

  writeValue(value: ChecklistInputValue): void {
    this.model = value;
  }

  onChanged(value: ChecklistInputValue): void {
    if (! isNil(this._onChangedFn)) {
      this._onChangedFn(value);
    }

    this.$onChanged.next(value);
  }

  makeGroupNameFactory() {
    if (! this.grouped) {
      return undefined;
    }

    const fn = (option: Select): string => {
      if (data_get(option, 'type') == 'asset') {
        return data_get(option, 'asset_type_text');
      }

      return data_get(option, 'type');
    };

    return fn.bind(this);
  }

  private _initializeRemoteOptions(): void {
    this.options$ = this.typeahead$.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.fetching$.next(true)),
      switchMap((term) => this._checklists.search(term, {
        types: [
          ... this.types,
          'unified',
        ],
        asset_type_ids: arr_wrap(this.assetTypeIds),
        size: this.size,
      }).pipe(
        finalize(() => this.fetching$.next(false)),
      )),
      map((records) => records.map((record) => ({
        ... record,
        type: data_get(record, 'type'),
        available_periods: transform(
          data_get(record, 'available_periods'), {
            transformer: (value: string) => safely_parse_json(value),
            default_value: [],
          },
        ),
        asset_type_id: data_get(record, 'asset_type_id'),
        asset_type_text: data_get(record, 'asset_type_text'),
      }))),
    );
  }
}

export type ChecklistInputValue = Checklist | Checklist[];
export type ChecklistInputChangedEvent = ChecklistInputValue;