import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { get } from 'lodash-es';

import { RecordService } from '../../../services/record.service';
import { ListingService } from '../../../services/listing.service';
import {ModuleStatusValueTranslations, StatusColors} from '../../../lists/listing-fields';
import { IRecordMetricEvent } from './../../events/record-metric.event';
import { DropdownService } from '../../../services/dropdown.service';
import { filled } from '../../utils/common';
import { LooseObject } from '../../../objects/loose-object';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
  providers: [
    DropdownService
  ]
})
export class InformationComponent implements OnInit, OnChanges {

  // Gets passed variables from html elements that uses Information panel
  @Input('strModule') strModule: string;
  @Input('showLegend') bShowLegend: boolean = true;
  @Input('strType') strType: string;
  // Are Analytics widgets enabled?
  @Input('isPanel1Visible') isPanel1Visible: boolean;
  @Input('isPanel2Visible') isPanel2Visible: boolean;
  @Input('isPanel3Visible') isPanel3Visible: boolean;

  /**
   * input attribute reloadRecordMetric dictates whether we will reload the current metric
   *
   * @var {boolean}
   */
  @Input('reloadRecordMetric') reloadRecordMetric: boolean = false;

  /**
   * event triggered when record metric data has been loaded
   *
   * @var {EventEmitter}
   */
  @Output('evtRecordMetricDataLoaded') evtRecordMetricDataLoaded: EventEmitter<IRecordMetricEvent> = new EventEmitter<IRecordMetricEvent>();

  public information_content : string;
  public arModuleLegend: {
    id?: string,
    text?: string,
    class?: string,
    color?: string,
    count?: string,
  }[] = [];
  public arPanel1Data:any = [];
  public arPanel2Data:any = [];
  public arPanel3Data:any = [];
  public objStatusColors = new StatusColors;
  public bLoading: boolean = true;

  protected moduleStatusValueTranslations: ModuleStatusValueTranslations = new ModuleStatusValueTranslations;
  protected objModulesLegendField = {
    leads: {
      field: 'status',
      type: 'dropdown'
    },
    customers: {
      field: 'on_hold',
      type: 'checkbox'
    },
    sites: {
      field: 'disable_maintenance',
      type: 'checkbox'
    },
    jobs: {
      field: 'status',
      type: 'dropdown'
    },
    recurring_jobs: {
      field: 'status',
      type: 'checkbox'
    },
    opportunities: {
      field: 'status',
      type: 'dropdown'
    },
    assets: {
      field: 'status',
      type: 'dropdown'
    },
    items: {
      field: 'active',
      type: 'checkbox'
    },
    purchase_orders: {
      field: 'status',
      type: 'dropdown'
    },
    customer_invoices: {
      field: 'status',
      type: 'dropdown'
    },
    supplier_invoices: {
      field: 'status',
      type: 'dropdown'
    },
    warehouses: {
      field: 'status',
      type: 'checkbox'
    },
  };

  constructor (
    private recordService: RecordService,
    private listingService: ListingService,
    private dropdownService: DropdownService,
  ) {}

  ngOnInit() {
    // Pass the content from translation to UI
    this.information_content = 'content_information.' + this.strModule;

    // Check if module has status
    if (this.bShowLegend && this.getLegend()) {
      if (filled(this.objModulesLegendField[this.strModule])) {
        this.getFieldOptions();
      } else {
        Object.keys(this.getLegend()).forEach( key => {
          let value = get(this.moduleStatusValueTranslations, `${this.strModule}.${key}`, key);
          this.arModuleLegend.push({
            id: value,
            text: value,
            class: this.getLegend()[key],
          });
        });
        this.bLoading = false;
      }
    } else {
      this.bLoading = false;
    }
  }

  /**
   * {@inheritdoc}
   *
   * @param {any} changes
   */
  ngOnChanges(changes: any): void {
    // FC-858: trigger this.getMetric when changes to this.releadRecordMetric has occured
    if (changes.reloadRecordMetric
      && changes.reloadRecordMetric.currentValue === true
      && changes.reloadRecordMetric.previousValue === false
    ) {
      this.getMetric();
    }
  }

  getLegend() {

    let arDifferentStatus = ['users', 'customers', 'sites', 'items', 'workflows'];

    // Special case. Data for statuses of these modules are true or false.
    if (arDifferentStatus.includes(this.strModule)) {
      switch(this.strModule) {
        // active, inactive
        case 'users' :
          return { 'active' : "text-success", 'inactive': "text-danger" };
        // on_hold, active
        case  'customers':
          return{ 'active' : "text-success", 'on_hold': "text-danger" };
        case  'sites':
          return{ 'enabled_maintenance' : "text-success", 'disabled_maintenance': "text-danger" };
        case 'workflows':
        case 'items':
          return{ 'active' : "text-success", 'inactive': "text-danger" };
      }

    } else {
      return (typeof (this.objStatusColors[this.strModule]) ? this.objStatusColors[this.strModule] : '')
    }

  }

  getMetric() {
      // Get record metrics
      this.recordService.getRecordMetric(this.strModule).first().subscribe(
        data => {
          this.arPanel1Data = (data['panel_1']) ? data['panel_1'] : [];
          this.arPanel2Data = (data['panel_2']) ? data['panel_2'] : [];
          this.arPanel3Data = (data['panel_3']) ? data['panel_3'] : [];

          this.evtRecordMetricDataLoaded.emit({
            isLoaded: true,
            isReloaded: this.reloadRecordMetric,
            data: data
          });
        }
      );
  }

  setFilter(arFilter) {
    // FC-872: if no filter was given pass an empty object
    if (! arFilter) {
      this.listingService.setInformationFilter([]);
    } else {
      // Send filter to listing
      this.listingService.setInformationFilter(arFilter);
    }
  }

  /**
   * retrieve the legend field
   */
  getFieldOptions(): void {
    this.dropdownService.getListingLegends(
      this.strModule, this.objModulesLegendField[this.strModule].field
    ).subscribe( response => {
      if (filled(response)) {

        this.bLoading = false;
        this.arModuleLegend = [];
        response.forEach( option => {
          this.arModuleLegend.push({
            id: option.key,
            text: option.name,
            color: option.color,
            count: get(option, 'count', 0)
          });
        });
      }
    })
  }

  /**
   * use the legend as filter in listing
   *
   * @param objFilter
   */
  onChangeFilter(objFilter: LooseObject): void {
    if (this.objModulesLegendField[this.strModule].type == 'checkbox') {
      this.listingService.setInformationFilter({
        [this.objModulesLegendField[this.strModule].field]: [{
          label: objFilter.id ? 'yes' : 'no',
          value: objFilter.id
        }]
      });
    } else if (this.objModulesLegendField[this.strModule].type == 'dropdown') {
      this.listingService.setInformationFilter({
        [this.objModulesLegendField[this.strModule].field]: [{
          op: 'eq',
          value: [{
            label: objFilter.text,
            value: objFilter.id
          }]
        }]
      });
    }
  }
}