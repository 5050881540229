import { Pipe, PipeTransform } from '@angular/core';
import { spf } from '../shared/utils/str';

@Pipe({
  name: 'sprintf',
  pure: false,
})
export class SprintfPipe implements PipeTransform {
  transform = (format: string, replacements: any[]) => spf(format, {
    args: replacements,
  });
}