import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { blank, filled, transform, when, whenBlank, whenFilled } from '../../../../../../shared/utils/common';
import { Select } from '../../../../../../objects/select';
import { FormControl, FormGroup } from '@angular/forms';
import { MaterialLineItem } from '../../material-line-item';
import { get, map as _map, toString, tap, isEqual, initial, find, last, sortBy, cloneDeep, isEmpty } from 'lodash-es';
import { Item } from '../../../../../../objects/item';
import { Relate } from '../../../../../../objects/relate';
import { RecordService } from '../../../../../../services/record.service';
import { first, map, startWith, switchMap } from 'rxjs/operators';
import { Activity } from '../../../../../../objects/activity';
import { ListingService } from '../../../../../../services/listing.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { markup, toFormattedNumber } from '../../../../../../shared/utils/numbers';
import { BehaviorSubject, defer, Subscription } from 'rxjs';
import { DialogService } from '../../../../../../services/dialog.service';
import { EditInvoiceComponent } from '../../../../customer-invoices/edit-invoice/edit-invoice.component';
import { spf } from '../../../../../../shared/utils/str';
import { LooseObject } from '../../../../../../objects/loose-object';
import { ContextMenuComponent } from '../../../../../../shared/components/context-menu/context-menu.component';
import { ContextMenuService } from '../../../../../../services/context-menu.service';
import { EditformComponent } from '../../../../../../shared/components/editform/editform.component';
import { FormPopup } from '../../../../../../objects/centralized-forms/form-popup';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'material-lines',
  templateUrl: './material-lines.component.html',
})
export class MaterialLinesComponent implements OnInit, OnChanges {
  @ViewChild(ContextMenuComponent) contextMenuComponent: ContextMenuComponent;

  @Input() defaultPricebook: DefaultMaterialPricebook;

  @Input() initialValue: MaterialLineData[] = [];

  @Input() defaultJob: DefaultMaterialJob;

  @Input() defaultTask: DefaultMaterialTask;

  @Input('invalid') invalid: boolean = false;

  @Input() fetching: boolean = false;

  @Output('updated') $onUpdate = new EventEmitter<OnMaterialsUpdate>();

  materials: MaterialLineItem[] = [];

  readonly form: FormGroup = new FormGroup({
    pricebook_id: new FormControl(),
  });

  pricebookField$ = new BehaviorSubject<Record<string, any>>({
    key: 'pricebook_id',
    label: '',
    controlType: 'relate',
    default_value: '',
    required: false,
    module: 'pricebooks',
    validator: [],
    options: [],
  });

  readonly pricebookChanges$ = defer(() => this.form.controls['pricebook_id'].valueChanges.pipe(
    startWith(get(this.defaultPricebook, 'id')),
  ));

  readonly focusedLineIndex$ = new BehaviorSubject<number>(-1);

  private _isEmpty: boolean = true;
  public selectedLineItems: Array<LooseObject> = [];

  constructor(
    public contextMenuService: ContextMenuService,
    private readonly _records: RecordService,
    private readonly _listing: ListingService,
    private readonly _dialog: DialogService,
    private readonly _matDialog: MatDialog,
  ) {}

  /// see: OnInit::ngOnInit
  ngOnInit(): void {
    if (filled(this.initialValue)) {
      this._isEmpty = false;
      this.materials = _map(this.initialValue, (value) => this._makeMaterialLineItem({
        initialValue: value,
      }))
    } else {
      this.materials = [this._makeMaterialLineItem()];
    }
  }

  /// see: OnChanges::ngOnChanges
  ngOnChanges(changes: SimpleChanges): void {
    const { initialValue, defaultPricebook } = changes;

    if (! isEqual(get(initialValue, 'currentValue'), get(initialValue, 'previousValue'))) {
      this.materials = _map(changes.initialValue.currentValue, (value) => this._makeMaterialLineItem({
        initialValue: value,
      }));

      this.$onUpdate.emit({
        materials: this.materials,
        pos: this.materials.length - 1,
        material: this.materials[this.materials.length - 1],
        mode: 'update',
      });

      this._isEmpty = blank(this.materials);
    }

    if (filled(defaultPricebook)) {
      this.form.patchValue({
        pricebook_id: get(defaultPricebook, 'currentValue.id'),
      });
    }

    if (filled(get(defaultPricebook, 'currentValue')) && get(defaultPricebook, 'isFirstChange', false)) {
      this.pricebookField$.next({
        ... this.pricebookField$.getValue(),
        default_value: get(defaultPricebook, 'currentValue.id'),
        options: [new Select(
          get(defaultPricebook, 'currentValue.id'),
          get(defaultPricebook, 'currentValue.text'),
        )],
      });
    }
  }

  /// called when a product is selected from product browsing
  onProductSelect(product): void {
    const material = this._makeMaterialLineItem({ product });

    if (this._isEmpty) {
      this.materials = [material];
      this._isEmpty = false;
    } else if (this.focusedLineIndex$.getValue() == -1) {
      this.materials.push(material);
    } else {
      this.materials.splice(this.focusedLineIndex$.getValue() + 1, 0, material);
    }

    this.$onUpdate.emit({
      material,
      materials: this.materials,
      pos: when(this.focusedLineIndex$.getValue() == -1, {
        then: () => this.focusedLineIndex$.getValue() + 1,
        else: () => this.materials.length - 1,
      }),
      mode: 'add',
    });
  }

  /// called when user re-arranges the material lines
  onMaterialLineDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.materials, event.previousIndex, event.currentIndex);

    this.$onUpdate.emit({
      materials: this.materials,
      pos: event.currentIndex,
      material: this.materials[event.currentIndex],
      mode: 'rearrange',
    });
  }

  /// called when user removes a material
  onMaterialRemove(pos: number, material: MaterialLineItem): void {
    if (pos > this.materials.length) {
      return;
    }

    if (pos === 0) {
      this.materials.shift();
    } else {
      this.materials.splice(pos , 1);
    }

    this.$onUpdate.emit({
      material,
      materials: this.materials,
      pos: pos,
      mode: 'remove',
    });
  }

  /// called when material product type was changed
  onMaterialProductTypeChange(pos: number, material: MaterialLineItem): void  {
    if (pos > this.materials.length) {
      return;
    }

    material.product.value = null;
    material.notes = null;

    this.materials[pos] = material;

    this.$onUpdate.emit({
      material,
      materials: this.materials,
      pos,
      mode: 'update',
    });
  }

  /// called when material product relate field changed
  onMaterialProductChange(pos: number, material: MaterialLineItem): void {
    if (pos > this.materials.length) {
      return;
    }

    const product = material.product.value;

    material._quantity = 1;
    material.product.value = {
      ...product,
      text: spf('[%s] %s', {
        args: [
          get(product, 'code'),
          get(product, 'name'),
        ],
      }),
    };

    material._unit_cost = toFormattedNumber(get(product, 'unit_cost', 0), {
      currency: true,
      maxDecimalPlaces: 4,
    });

    material._unit_price = toFormattedNumber(get(product, 'unit_price', 0), {
      currency: true,
      maxDecimalPlaces: 4,
    });

    material._markup = when(get(product, 'markup') && ! isEqual(get(product, 'markup'), 0), {
      then: () => toFormattedNumber(get(product, 'markup')),
      else: () => material.markUpComputation(),
    });

    material.notes = get(product, 'description');

    this.materials[pos] = material;

    this.$onUpdate.emit({
      material,
      materials: this.materials,
      pos,
      mode: 'update',
    });
  }

  onMaterialDescriptionChange(pos: number, material: MaterialLineItem): void {
    if (pos > this.materials.length) {
      return;
    }

    this.materials[pos] = material;

    this.$onUpdate.emit({
      material,
      pos,
      materials: this.materials,
      mode: 'update',
    });
  }

  /// called when the material quantity changed
  onMaterialQuantityChange(pos: number, material: MaterialLineItem) {
    if (pos > this.materials.length) {
      return;
    }

    this.materials[pos] = material;

    this.$onUpdate.emit({
      material,
      materials: this.materials,
      pos,
      mode: 'update',
    });
  }

  /// called when material task changed
  onMaterialTaskChange(pos: number, material: MaterialLineItem) {
    if (pos > this.materials.length) {
      return;
    }

    this.materials[pos] = material;

    this.$onUpdate.emit({
      material,
      materials: this.materials,
      pos,
      mode: 'update',
    });
  }

  /// called when material unit cost changed
  onMaterialUnitCostChange(pos: number, material: MaterialLineItem) {
    this._recalculateMarkup(pos, material);
  }

  /// called when material unit price changed
  onMaterialUnitPriceChange(pos: number, material: MaterialLineItem) {
    this._recalculateMarkup(pos, material);
  }

  /// called when material markup was changed
  onMaterialMarkupChange(pos: number, material: MaterialLineItem) {
    if (pos > this.materials.length) {
      return;
    }

    this.materials[pos] = tap(material, (material) => material.recalculateUnitPrice());

    this.$onUpdate.emit({
      material,
      materials: this.materials,
      pos,
      mode: 'update',
    });
  }

  /// called when user wants to edit customer invoice
  onCustomerInvoiceEdit(material: MaterialLineItem): void {
    const invoiceId = get(material, 'customer_invoice_id');
    const jobId = get(this.defaultJob, 'id');

    if (blank(invoiceId) || blank(jobId)) {
      return;
    }

    this._dialog.show({
      component: EditInvoiceComponent,
      size: 'full',
      data: {
        record_id: invoiceId,
        invoice: [],
        module: 'customer_invoices',
        view_type: 'edit',
        customer_invoice_id: invoiceId,
      },
      afterClosing: (result: Record<string, any>) => {
        if (blank(result) || get(result, 'action') != 'save') {
          return;
        }

        this._records.getRecord('jobs', jobId)
          .pipe(first())
          .subscribe((response) => {
          const materials = get(response, 'related_data.materials', []);

          if (blank(materials)) {
            return;
          }

          this.materials = _map(this.materials, (material) => {
            const matched = find(materials, (existing) => material.id == get(existing, 'id'));

            if (blank(matched)) {
              return material;
            }

            material.customer_invoice_id = get(matched, 'customer_invoice_id');
            material.customer_invoice_number = get(matched, 'invoice_number');

            return material;
          });

          this.$onUpdate.emit({
            materials: this.materials,
            material: last(this.materials),
            pos: this.materials.length - 1,
            mode: 'refreshed',
          });
        });
      }
    });
  }

  onLineFocus(pos: number): void {
    this.focusedLineIndex$.next(pos);
  }

  sortMaterialsByItemCode(): void {
    this.materials = sortBy(this.materials, (material) => get(material, 'product.value.code'));

    this.$onUpdate.emit({
      materials: this.materials,
      pos: this.materials.length - 1,
      material: this.materials[this.materials.length - 1],
      mode: 'rearrange',
    });
  }

  doSomethingFromContextMenu(event) {
    let copiedLineItemFromLocalStorage = this.contextMenuService.getCopiedLineItem();

    if (event.action == 'paste' && filled(copiedLineItemFromLocalStorage)) {
      let hasNonLaborItem = copiedLineItemFromLocalStorage.filter(lineItem => !lineItem['labor']);

      if (this._isEmpty && !isEmpty(hasNonLaborItem)) {
        this.materials = [];
      }

      let indexCounter = get(event, ['data', 'index']) + 1;
      copiedLineItemFromLocalStorage.forEach(material => {
        if (!get(material, 'labor')) {
          let currentLineItem = this._makeMaterialLineItem({initialValue: material});

          this.materials.splice(indexCounter, 0, currentLineItem);
          this.$onUpdate.emit({
            material,
            materials: this.materials,
            pos: when(indexCounter == -1, {
              then: () => indexCounter,
              else: () => this.materials.length - 1,
            }),
            mode: 'add',
          });
          indexCounter++;
        }
      });
      this._isEmpty = false;
    } else if (event.action == 'copy') {
      let copiedLineItems = [];
      let eventDataLineItem = get(event, ['data', 'line_item'], []);

      if (this.selectedLineItems.length > 0) {
        copiedLineItems = this.selectedLineItems.map(lineItem => {
          let newLineItem = this.formatCopiedLineItem(cloneDeep(lineItem));

          return newLineItem;
        });

      } else if (filled(eventDataLineItem)) {
        let newLineItem = this.formatCopiedLineItem(cloneDeep(eventDataLineItem));

        copiedLineItems = [newLineItem];
      }

      if (filled(copiedLineItems)) {
        this.contextMenuService.setCopiedLineItem(copiedLineItems);
      }
    }
  }

  formatCopiedLineItem(lineItem: LooseObject) {
    let currentLineItem = cloneDeep(lineItem);
    currentLineItem['item_id'] = get(currentLineItem, ['product', 'value', 'id']);
    currentLineItem['item_name'] = get(currentLineItem, ['product', 'value', 'name']);
    currentLineItem['item_code'] = get(currentLineItem, ['product', 'value', 'code']);
    currentLineItem['description'] = get(currentLineItem, ['description'], get(currentLineItem, ['notes']));
    currentLineItem['related_products'] = get(currentLineItem, ['related_products'], get(currentLineItem, ['product', 'value', 'related_products'], []));
    currentLineItem['supplier_pricing'] = get(currentLineItem, ['supplier_pricing'], get(currentLineItem, ['product', 'value', 'supplier_pricing'], []));
    currentLineItem['labor'] = get(currentLineItem, ['labor'], get(currentLineItem, ['product', 'value', 'labor']));
    currentLineItem['task_id'] = get(currentLineItem, ['task', 'value', 'id']);
    currentLineItem['task_name'] = get(currentLineItem, ['task', 'value', 'activity_name']);
    // We should set this to null as it will be a new line item on paste
    currentLineItem['customer_invoice_id'] = null;
    currentLineItem['customer_invoice_number'] = null;
    currentLineItem['id'] = null;

    delete currentLineItem['task'];
    delete currentLineItem['product'];

   return currentLineItem;
  }

  onClickedLineItem($event, attr) {
    this.selectedLineItems = this.contextMenuService.selectLineItem($event, attr, this.selectedLineItems);
  }

  /**
   * To open create item dialog form
   */
  createItem(pos: number, material: MaterialLineItem): void {
    this._matDialog.open(EditformComponent, new FormPopup('items', {}, {
      name: material.notes,
      code: material.notes,
      markup: material.markup,
      pricing_method: 'default_markup',
      unit_cost: material.unit_cost,
      unit_price: material.unit_price,
    }))
      .afterClosed()
      .subscribe((response) => {

        if (get(response, 'status') == 'save') {
          const product = response.data;

          material.type = 'product_catalog';
          material.product.value = {
            ...product,
            text: spf('[%s] %s', {
              args: [
                get(product, 'code'),
                get(product, 'name'),
              ],
            }),
          };
          material._unit_cost = toFormattedNumber(get(product, 'unit_cost', 0), {
            currency: true,
            maxDecimalPlaces: 4,
          });
          material._unit_price = toFormattedNumber(get(product, 'unit_price', 0), {
            currency: true,
            maxDecimalPlaces: 4,
          });
          material._markup = when(get(product, 'markup') && ! isEqual(get(product, 'markup'), 0), {
            then: () => toFormattedNumber(get(product, 'markup')),
            else: () => material.markUpComputation(),
          });

          material.notes = get(product, 'description');

          this.materials[pos] = material;
          this.$onUpdate.emit({
            material,
            materials: this.materials,
            pos,
            mode: 'update',
          });
        }
      });
  }

  private _makeMaterialLineItem(opts: {
    product?: Record<string, any>
    initialValue?: MaterialLineData,
  } = {}): MaterialLineItem {
    const product = opts.product;
    const initialValue = opts.initialValue;

    const tasks = [];

    if (filled(get(initialValue, 'task_id'))) {
      tasks.push({
        id: get(initialValue, 'task_id'),
        activity_name: get(initialValue, 'task_name'),
      });
    } else if (filled(this.defaultTask)) {
      tasks.push({
        id: this.defaultTask.id,
        activity_name: this.defaultTask.name,
      });
    }

    const products = [];

    if (filled(get(initialValue, 'item_id'))) {
      products.push({
        id: get(initialValue, 'item_id'),
        text: spf('[%s] %s', {
          args: [
            get(initialValue, 'item_code'),
            get(initialValue, 'item_name'),
          ],
        }),
        name: get(initialValue, 'item_name'),
        code: get(initialValue, 'item_code'),
      });
    } else if (filled(product) && filled(get(product, 'id'))) {
      products.push({
        ... product,
        text: spf('[%s] %s', {
          args: [
            get(product, 'code'),
            get(product, 'name')
          ]
        }),
      });
    }

    const fieldsToCheck = ['notes', 'once_off_product_name', 'description'];
    const notesValue = fieldsToCheck
      .map(field => get(initialValue, field))
      .find(filled) || get(product, 'description');

    return new MaterialLineItem({
      id: get(initialValue, 'id'),
      product: transform(new Relate<Item|string>().buildRelates(
        switchMap((term) => {
          const filter = {
            active: true,
            labor: false,
            is_searchable: true,
          };

          return this._records.getProducts({
            term,
            filter,
            pricebook_id: get(this.form.value, 'pricebook_id'),
          });
        }),
        products,
        true,
      ), {
        transformer: (relate) => {
          if (filled(get(product, 'id')) || filled(get(initialValue, 'item_id'))) {
            return relate;
          } else if (filled(product)) {
            relate.value = get(product, 'description');
          } else if (filled(initialValue)) {
            relate.value = get(initialValue, 'once_off_product_name', get(initialValue, 'product'));
          }

          return relate;
        },
      }),
      task: new Relate<Activity>().buildRelates(
        switchMap((term) =>
          this._listing.fetchDataAdvanceSearch({}, 'activities', {
            ... transform(this.defaultJob, {
              transformer: (job) => ({
                job_id: [{
                  id: job.id,
                }]
              })
            }),
            activity_type: [{
              op: 'eq',
              value: [{
                value: 'task',
              }]
            }],
            activity_name: {
              op: 'eq',
              value: term,
            },
          }).pipe(
            map((response) => _map(get(response, 'data', []), (data) => new Activity(data))),
          )
        ),
        tasks,
        true,
      ),
      type: when(
        filled(get(initialValue, 'item_id'))
        || filled(get(product, 'id'))
        || (blank(product) && blank(initialValue)),
        {
          then: () => 'product_catalog',
          else: () => 'once_off_purchase',
        }
      ),
      quantity: whenFilled(get(initialValue, 'quantity'), {
        then: () => get(initialValue, 'quantity'),
        else: () => 1,
      }),
      unit_cost: whenFilled(get(initialValue, 'unit_cost'), {
        then: () => get(initialValue, 'unit_cost', 0),
        else: () => get(product, 'unit_cost', 0)
      }),
      unit_price: whenFilled(get(initialValue, 'unit_price'), {
        then: () => get(initialValue, 'unit_price', 0),
        else: () => get(product, 'unit_price', 0),
      }),
      markup: this._computeMarkup(whenFilled(initialValue, {
        then: () => initialValue,
        else: () => product,
      })),
      notes: notesValue,
      work_order_reference: get(initialValue, 'work_order_reference'),
      sort_seq: get(initialValue, 'pos'),
      from_work_order: get(initialValue, 'from_work_order'),
      customer_invoice_id: get(initialValue, 'customer_invoice_id'),
      customer_invoice_number: get(initialValue, 'customer_invoice_number'),
      related_products: filled(get(initialValue, 'related_products')) ? get(initialValue, 'related_products') : get(product, 'related_products', []),
      supplier_pricing: filled(get(initialValue, 'supplier_pricing')) ? get(initialValue, 'supplier_pricing') : get(product, 'supplier_pricing', []),
      description: filled(get(initialValue, 'description')) ? get(initialValue, 'description') : get(product, 'description'),
      labor: get(initialValue, 'labor', get(product, 'labor'))
    });
  }

  private _recalculateMarkup(pos: number, material: MaterialLineItem) {
    if (pos > this.materials.length) {
      return;
    }

    this.materials[pos] = transform(material, {
      transformer: (material) => {
        material.markup = toString(toFormattedNumber(material.markUpComputation()));

        return material;
      }
    });

    this.$onUpdate.emit({
      material,
      materials: this.materials,
      pos,
      mode: 'update',
    });
  }

  private _computeMarkup(product: Record<string, any>): number {
    if (blank(get(product, 'markup')) || get(product, 'markup') == 0) {
      return markup({
        unit_price: get(product, 'unit_price', 0),
        unit_cost: get(product, 'unit_cost', 0),
      });
    }

    return get(product, 'markup', 0);
  }
}

export type DefaultMaterialPricebook = {
  id: string;
  text: string;
}

export type DefaultMaterialJob = {
  id: string;
}

export type DefaultMaterialTask = {
  id: string;
  name: string;
}

export type OnMaterialsUpdate = {
  material: MaterialLineItem;
  pos: number;
  materials: MaterialLineItem[];
  mode: 'add' | 'remove' | 'update' | 'rearrange' | 'refreshed';
}

export type MaterialLineData = {
  quantity: number;
  id?: string;
  task_id?: string;
  task_name?: string;
  unit_cost?: number;
  unit_price?: number;
  item_id?: string;
  item_name?: string;
  item_code?: string;
  description?: string;
  once_off_product_name?: string;
  work_order_reference?: string;
  pos?: number;
  from_work_order?: boolean;
  markup?: number;
  customer_invoice_id?: string;
  customer_invoice_number?: string;
  notes?: string | Item;
  supplier_pricing?: LooseObject[];
  related_products?: LooseObject[];
}