import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { Client } from '../../../../objects/client';
import { ChargebeeService } from '../../../../services/chargebee/chargebee.service';
import { currency_codes } from "../../../../../assets/api/currency_codes.json";
import { unix } from 'moment';

@Component({
  selector: 'billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss']
})
export class BillingHistoryComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public client: Client,
    public objDialogRef: MatDialogRef<BillingHistoryComponent>,
    protected chargebeeService: ChargebeeService
  ) { }

  /**
   * indicator that tells if request is still loading
   *
   * @var {boolean}
   */
  public bLoading: boolean = true;

  /**
   * indicator that tells if request is still loading
   *
   * @var {boolean}
   */
  public objDownload: {} = {};

  /**
   * billing history list
   *
   * @var {string}
   */
  public arBillingHistoryList: {}[] = [];

  ngOnInit() {
    this.getBillingHistory();
  }

  /**
   * retrieve billing history
   *
   * @returns void
   */
  getBillingHistory(): void {
    this.chargebeeService.billingHistory(this.client.client_id).pipe(
      finalize(() => this.bLoading = false),
    )
    .subscribe( response => {
      this.bLoading = false;
      response.forEach( item => {
        item.currency = this.getCurrencySymbol(item.currency_code);
        item.description = item.line_items[0].description;
        item.total = (item.total/100).toFixed(2).toString(),
        item.paid_at = unix(item.paid_at).format('LL')
        this.arBillingHistoryList.push(item);
      });
    });
  }

  /**
   * get invoice download url
   *
   * @returns void
   */
  downloadInvoice(strInvoiceId: string): void {
    this.objDownload[strInvoiceId] = true
    this.chargebeeService.downloadInvoice(this.client.client_id, strInvoiceId).pipe(
      finalize(() => this.objDownload[strInvoiceId] = false),
    )
    .subscribe( response => {
      window.open(response.download_url, '_blank');
    });
  }

  /**
   * get currency symbol
   *
   * @param currency
   */
  private getCurrencySymbol(currency: string): string {
    let currencyConfig = currency_codes.find(({ code }) => code === currency);

    return currencyConfig ? currencyConfig.symbol : '$'
  }
}
