import { MapsAPILoader } from '@agm/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { get } from 'lodash-es';
import { ClientStoreService } from '../../../../../../services/client-store.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {

  lat: number = null;
  lng: number = null;
  zoom: number = 17;
  address: string = null;
  markerLatitude: number = null;
  markerLongitude: number = null;

  constructor(
    public matdialogRef: MatDialogRef<GoogleMapComponent>,
    private mapsAPILoader: MapsAPILoader,
    private clientStoreService: ClientStoreService,
    @Inject(MAT_DIALOG_DATA) public dialogData,
  ) { }

  ngOnInit() {
    this.lat = this.convertToProperGeolocation(get(this.dialogData, 'latitude', null));
    this.lng = this.convertToProperGeolocation(get(this.dialogData, 'longitude', null));
    this.markerLatitude = this.convertToProperGeolocation(get(this.dialogData, 'latitude', null));
    this.markerLongitude = this.convertToProperGeolocation(get(this.dialogData, 'longitude', null));

    this.loadGoogleMapsPlacesAutocomplete();
    this.setLocationByCurrentPosition();
    this.getAddressFromLatLng(this.lat, this.lng);
  }

  /**
   * Load auto complete of the address
   */
  loadGoogleMapsPlacesAutocomplete() {
    this.mapsAPILoader.load().then(() => {

      const autocomplete = new google.maps.places.Autocomplete(
        document.getElementById('searchAddressInput') as HTMLInputElement
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        if (place.geometry) {
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.markerLatitude = place.geometry.location.lat();
          this.markerLongitude = place.geometry.location.lng();
          this.getAddressFromLatLng(this.lat, this.lng);
        }
      });
    });
  }

  /**
   * When you clicked in the map
   * this will be triggered
   * @param event
   */
  onMapClick(event: any) {
    this.markerLatitude = event.coords.lat;
    this.markerLongitude = event.coords.lng;
    this.getAddressFromLatLng(this.markerLatitude, this.markerLongitude);
  }

  /**
   * When marker dragend this
   * will be triggered
   * @param event
   */
  onMarkerDragEnd(event: any) {
    this.markerLatitude = event.coords.lat;
    this.markerLongitude = event.coords.lng;
    this.getAddressFromLatLng(this.markerLatitude, this.markerLongitude);
  }

  /**
   * Convert the latitude and longitude to readable address
   * @param latitude
   * @param longitude
   */
  getAddressFromLatLng(latitude: number, longitude: number) {
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat: latitude, lng: longitude };

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            this.address = results[0].formatted_address;
          } else {
            this.address = 'Address not found';
          }
        } else {
          this.address = 'Geocoder failed due to: ' + status;
        }
      });
    });
  }

  /**
   * This will set the current position
   * if has empty latitude and longitude
   */
  setLocationByCurrentPosition() {
    if (!this.lat && !this.lng) {
      let activeClient = this.clientStoreService.getActiveClient();

      if (activeClient.address['latitude'] && activeClient.address['longitude']) {
        this.lat = +activeClient.address['latitude'];
        this.lng = +activeClient.address['longitude'];

        this.markerLatitude = +activeClient.address['latitude'];
        this.markerLongitude = +activeClient.address['longitude'];
      } else if (navigator) {
        navigator.geolocation.getCurrentPosition( position => {
          if (position !== undefined) {
            this.lat = position.coords.latitude;
            this.markerLatitude = position.coords.latitude;

            this.lng = position.coords.longitude;
            this.markerLongitude = position.coords.longitude;
          }
        });
      }
    }
  }

  geolocationChange(event, geocodeType) {
    if (geocodeType === 'lat') {
      this.lat = this.convertToProperGeolocation(event);
      this.markerLatitude = this.convertToProperGeolocation(event);
    }

    if (geocodeType === 'lng') {
      this.lng = this.convertToProperGeolocation(event);
      this.markerLongitude = this.convertToProperGeolocation(event);
    }

    this.getAddressFromLatLng(this.markerLatitude, this.markerLongitude);
  }

  /**
   * Save data of the selected location from map
   */
  saveLocation() {
    this.closeDialog({
      'address_name': this.address,
      'latitude': this.markerLatitude,
      'longitude': this.markerLongitude,
    });
  }

  convertToProperGeolocation(value) {
    return isNaN(parseFloat(value)) ? null : parseFloat(value);
  }

  /**
   * Closing the dialog
   * @param data
   */
  closeDialog(data = null) {
    this.matdialogRef.close(data);
  }
}
