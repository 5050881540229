
    <div class="row">
      <ng-select
        class="col-lg-12 col-md-12 col-xs-12 fieldmagic-multitext"
        [(ngModel)]="values"
        [addTag]="onTagCreation"
        [isOpen]="false"
        [multiple]="true"
        (change)=onChanged($event)
        (focus)="onTouched()"
        bindLabel="0"
        (blur)="onBlur($event.target)"
        (keyup.enter)="onEnter($event.target)"
        [placeholder]="placeholder"
        [class.fieldmagic-input-has-error]="errors | filled"
        [class.fieldmagic-multitext-variant-success]="variant == 'success'"
        [class.fieldmagic-multitext-variant-error]="variant == 'error'"
      >
      </ng-select>
      <div
        fieldmagicGridColumn="12"
      >
        <fieldmagic-input-errors
          *ngIf="errors | filled"
          [errors]="errors">
        </fieldmagic-input-errors>
      </div>
    </div>
