<form [formGroup]="form">

  <div class="container-fluid">

    <div class="row">
      <div *ngIf="!bIsOrganization && !bNoCustomer" class="col-xs-6 col-md-6 form-group">
        <strong id="customerStepTitle">{{ 'create_or_search' | translate }} {{ 'customer' |
          translate }}</strong>
        <br />
        <small>{{ 'fill_in_customer_details' | translate }}</small>
      </div>

      <div *ngIf="bIsOrganization && !bNoCustomer" class="col-xs-6 col-md-6 form-group">
        <strong>{{ 'create_or_search' | translate }} {{ 'organization'
          | translate }}</strong>
        <br />
        <small>{{ 'fill_in_organization_details' | translate }}</small>
      </div>

      <div *ngIf="bNoCustomer" class="col-xs-6 col-md-6 form-group">
        <strong>{{ 'set_location' | translate }}</strong>
        <br />
        <small>{{ 'set_job_location' | translate }}</small>
      </div>

      <div class="col-xs-6 col-md-6 form-group">
        <a *ngIf="strRecordType === 'jobs' && bNoCustomer" href="javascript:void(0)" class="text-primary float-right" (click)="skip()">
          <fa-icon [icon]="['fas', 'forward']"></fa-icon>&nbsp;{{ 'skip' | translate }}
        </a>
        <a
          *ngIf="form.controls.customer_id.value != null"
          href="javascript:void(0)"
          [ngClass]="{'mr-3': bNoCustomer}"
          class="text-danger float-right" (click)="clear()">
            <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>&nbsp;{{ 'reset' | translate }}
        </a>
        <br *ngIf="bNoCustomer || form.controls.customer_id.value"/>
        <mat-checkbox
          *ngIf="strRecordType === 'jobs'"
          [(ngModel)]="bNoCustomer"
          [ngModelOptions]="{standalone: true}"
          (change)="toggleShowCustomer($event)"
          class="float-right labels">
            {{ 'no_customer' | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div *ngIf="!bNoCustomer" class="row">
      <div class="col-xs-12 col-md-12 text-center">
        <div class="form-check form-check-inline border rounded p-3 mr-5"
          [ngClass]="{'border-primary font-weight-bolder': !bIsOrganization}">
          <input id="customerStepTypeIndividual" class="form-check-input" formControlName='type' type="radio"
            name="type" id="individual" value="individual">
          <label class="form-check-label" for="individual">{{ 'individual' | translate }}</label>
        </div>
        <div class="form-check form-check-inline border rounded p-3"
          [ngClass]="{'border-primary font-weight-bolder': bIsOrganization}">
          <input id="customerStepTypeOrganization" class="form-check-input" formControlName='type' type="radio"
            name="type" id="organization" value="organization">
          <label class="form-check-label" for="organization">{{ 'organization' | translate }}</label>
        </div>
      </div>
    </div>

    <div *ngIf="form.controls['type'].value == 'organization' && !bNoCustomer" class="row mt-3">
      <div class="col-12 mb-3">
        <div>
          <label class="labels" for="name" translate>
            {{ 'name' }}
            <required-tag [hasSpace]="false"></required-tag>
          </label>
        </div>
        <div class="customer-input">
          <!-- We only have placeholder="&nbsp;" to override Google Places Autocomplete's placeholder. -->
          <input id="customerStepOrganizationName" #orgName
            formControlName='name'
            type="text"
            placeholder="&nbsp;"
            class="form-control font-size-11 input-height-40 input-padding"
            [ngClass]="{'is-invalid': form.controls['name'].invalid && form.dirty }"
            [matAutocomplete]="nameSearch"
            [matAutocompleteDisabled]="!nameSearch"
            (focusout)="showAddressFieldForNewCustomer()"/>
          <fa-icon [icon]="['fal', 'search']" class="customer-search-icon"></fa-icon>
          <fa-icon *ngIf="!objCustomerSearch.loader && bHasSelectedExistingCustomer" [icon]="['fas', 'check-circle']" class="text-success customer-input-icon"></fa-icon>
          <fa-icon *ngIf="objCustomerSearch.loader" [icon]="['fal', 'spinner-third']" [spin]="true" class="customer-input-icon"></fa-icon>
        </div>

        <mat-autocomplete #nameSearch="matAutocomplete">
          <ng-container *ngIf="(objCustomerSearch.source | async) as searchResults">
            <mat-option *ngFor="let results of searchResults" class="search-class" [value]="results.name"
              (onSelectionChange)="updateCustomer(results)">
              <div class="row">
                <div class="col-12 result-name">{{
                  results.name }}</div>
                <div class="col-6 result-module">{{ results.module | translate }}</div>
                <div class="col-6 result-module"><span class="float-right">{{ 'match_score' | translate }}: {{
                    results.match_percentage | number:'1.0-0' }}%</span></div>
              </div>
            </mat-option>
            <mat-option
              *ngIf="searchResults.length == 0 && !nameValueEmpty && !bShowAddressField"
              class="search-class"
              [value]="form.controls.name.value"
              (onSelectionChange)="showAddressFieldForNewCustomer()">

              <div class="row">
                <div class="col-12 result-module">{{ 'add_new_customer' | translate }}</div>
              </div>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </div>
    </div>

    <div *ngIf="form.controls['type'].value == 'individual' && !bNoCustomer" class="row mt-3">
      <div class="col-6 mb-3">
        <div>
          <label class="labels" for="first_name" translate>
            {{ 'first_name' }}
            <required-tag [hasSpace]="false"></required-tag>
          </label>
        </div>
        <div class="customer-input">
          <input id="customerStepFirstName" #firstName
            formControlName='first_name'
            type="text"
            class="form-control font-size-11 input-height-40 input-padding"
            [ngClass]="{'is-invalid': form.controls['first_name'].invalid && form.dirty}"
            [matAutocomplete]="firstNameSearch"
            [matAutocompleteDisabled]="!firstNameSearch"/>
          <fa-icon [icon]="['fal', 'search']" class="customer-search-icon"></fa-icon>
          <fa-icon *ngIf="objCustomerSearch.loader" [icon]="['fal', 'spinner-third']" [spin]="true" class="customer-input-icon"></fa-icon>
        </div>
        <mat-autocomplete #firstNameSearch="matAutocomplete">
          <ng-container *ngIf="(objCustomerSearch.source | async) as searchResults">
            <mat-option *ngFor="let results of searchResults" class="search-class" [value]="results.name"
              (onSelectionChange)="updateCustomer(results)">
              <div class="row">
                <div class="col-12 result-name">{{
                  results.name }}</div>
                <div class="col-6 result-module">{{ results.module | translate }}</div>
                <div class="col-6 result-module"><span class="float-right">{{ 'match_score' | translate }}: {{
                    results.match_percentage | number:'1.0-0' }}%</span></div>
              </div>
            </mat-option>
            <mat-option
              *ngIf="searchResults.length == 0 && !firstNameValueEmpty && !bShowAddressField"
               class="search-class"
               [value]="form.controls.first_name.value"
               (onSelectionChange)="showAddressFieldForNewCustomer()">

              <div class="row">
                <div class="col-12 result-module">{{ 'add_new_customer' | translate }}</div>
              </div>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </div>
      <div class="col-6 mb-3">
        <div>
          <label class="labels" for="last_name" translate>
            {{ 'last_name' }}
            <required-tag [hasSpace]="false"></required-tag>
          </label>
        </div>
        <div class="customer-input">
          <input id="customerStepLastName" #lastName
            formControlName='last_name'
            type="text"
            class="form-control font-size-11 input-height-40 input-padding"
            [ngClass]="{'is-invalid': form.controls['last_name'].invalid && form.dirty}"
            [matAutocomplete]="lastNameSearch"
            [matAutocompleteDisabled]="!lastNameSearch"
            (focusout)="showAddressFieldForNewCustomer()"/>
          <fa-icon [icon]="['fal', 'search']" class="customer-search-icon"></fa-icon>
          <fa-icon *ngIf="objCustomerSearch.loader" [icon]="['fal', 'spinner-third']" [spin]="true" class="customer-input-icon"></fa-icon>
        </div>
        <mat-autocomplete #lastNameSearch="matAutocomplete">
            <mat-option
              *ngIf="!lastNameValueEmpty && !bShowAddressField"
               class="search-class"
               [value]="form.controls.last_name.value"
               (onSelectionChange)="showAddressFieldForNewCustomer()">

              <div class="row">
                <div class="col-12 result-module">{{ 'add_new_customer' | translate }}</div>
              </div>
            </mat-option>
        </mat-autocomplete>
      </div>
    </div>

    <div class="row" [ngClass]="{'d-none': !bShowAddressField}">
      <div class="col-12 mb-3">
        <div>
          <label class="labels" for="address" translate>
            {{ 'address' }}
            <required-tag [hasSpace]="false"></required-tag>
          </label>

          <mat-checkbox
            *ngIf="strRecordType === 'jobs'"
            [(ngModel)]="bIsCustomLocation"
            [ngModelOptions]="{standalone: true}"
            (change)="toggleCustomLocation($event)"
            class="float-right labels">
              {{ 'is_custom_location' | translate }}
          </mat-checkbox>
        </div>
        <app-address></app-address>
      </div>
    </div>

    <div *ngIf="showExistingSites" class="row mb-4">
      <div class="col-xs-12 col-md-12 d-block">
        <div class="pt-3 border">
          <div class="row pl-3 pr-3">
            <div class="col-xs-8 col-md-8">
              <p class="d-flex align-items-center gap-5">
                <fa-icon [icon]="['fas', 'info-circle']" class="mr-2 text-warning fa-lg"></fa-icon>
                <span class="fs-13 font-italic">
                  {{ 'wizard_has_existing_site_preselect' | translate }}
                </span>
              </p>
            </div>
            <div class="col-xs-4 col-md-4">
              <button id="customerStepForceNew" type="button" class="font-size-12 d-flex justify-content-center gap-10 btn btn-outline-primary btn-block btn-lg p-2"
                (click)="createNewSiteForExistingCustomer()">
                <strong>{{ 'create_new_site' | translate }}</strong>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-12">
              <div>
                <ng-container>
                  <mat-radio-group formControlName='site_id' ngDefaultControl>
                    <table class="existing_sites w-100" aria-describedby="existingSites">
                      <thead>
                        <tr>
                          <th class="fs-13 pl-3 linked-data-table-header" id="existingSites">
                            <span *ngIf="bHasExistingSite">{{ 'existing_site_numbers' | translate }}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf='(objSiteSearch | async) as siteResult; else siteLoading'>
                          <tr *ngFor="let siteData of siteResult.data; trackBy: trackBySiteId; index as i">
                            <td class="font-size-12 pl-2 pr-2 pb-1" [ngClass]="i === 0 ? 'pt-3' : 'pt-1'">
                              <div class="d-flex align-items-center">
                                <mat-radio-button
                                  class="ml-1 w-100"
                                  [value]="siteData.id"
                                  [checked]="siteResult.data.length === 1"
                                  (click)="setSiteData(siteData)">

                                  <span class="text-wrap">
                                    <b>{{siteData.site_number}} - {{ siteData.name }}</b>
                                  </span>
                                </mat-radio-button>

                                <fa-icon
                                  matTooltip="{{ 'edit_site' | translate }}"
                                  class="text-success pointer pl-2 font-size-12"
                                  [icon]="['fas', 'pencil']"
                                  (click)="editSite(siteData)">
                                </fa-icon>
                              </div>
                            </td>
                          </tr>

                          <tr *ngIf="siteResult.total_records == 0">
                            <td class="font-size-12 pl-3 pr-2 pb-1 pt-3">
                              <span>{{ 'customer_no_site' | translate }}</span>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-template #siteLoading>
                          <tr>
                            <td class="text-center">
                              <inline-view-loader [isLoading]="true"></inline-view-loader>
                            </td>
                          </tr>
                        </ng-template>
                      </tbody>
                    </table>
                  </mat-radio-group>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row pl-3 pr-3 pb-1">
            <div *ngIf="(objSiteSearch | async) as siteResult;"  class="col d-flex justify-content-between">
              <span>
                <small *ngIf="siteResult.from_records" class="small-font">
                  {{ 'list_view_summary' | translate:{fromRecords: siteResult.from_records, toRecords:
                  siteResult.to_records, totalRecords: siteResult.total_records} }}
                </small>
              </span>
              <div *ngIf="siteResult.from_records" class="pagination-section">
                <ul class="pagination float-right">
                  <li class="page-item" [ngClass]="siteResult.current_page === 1 ? 'disabled' : ''">
                    <a id="wizardCustomerStepExistingSitesPageOne" class="page-link" aria-label="first" (click)="getSites(1)">
                      <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <li class="page-item" [ngClass]="siteResult.current_page === 1 ? 'disabled' : ''">
                    <a id="wizardCustomerStepExistingSitesPreviousPage" class="page-link" aria-label="previous" (click)="getSites(siteResult.current_page - 1)">
                      <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <li class="page-item" [ngClass]="siteResult.current_page === siteResult.last_page ? 'disabled' : ''">
                    <a id="wizardCustomerStepExistingSitesNextPage" class="page-link" aria-label="next" (click)="getSites(siteResult.current_page + 1)">
                      <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <li class="page-item" [ngClass]="siteResult.current_page === siteResult.last_page ? 'disabled' : ''">
                    <a id="wizardCustomerStepExistingSitesLastPage" class="page-link" aria-label="last" (click)="getSites(siteResult.last_page)">
                      <fa-icon [icon]="['far', 'chevron-double-right']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-12">
        <button
          *ngIf="(objSiteSearch | async); else loading"
          id="customerStepNext"
          type="button"
          class="btn btn-primary btn-block btn-lg p-2"
          (click)="next()"
          [disabled]="nextButtonDisabled">
          <strong>{{ 'next' | translate }}</strong>
        </button>
        <ng-template #loading>
          <button
            id="customerStepNextDisabled"
            type="button"
            class="btn btn-primary btn-block btn-lg p-2"
            [disabled]="true">
            <span *ngIf="!bHasExistingSite">
              <inline-view-loader [isLoading]="true" [hasText]="false"></inline-view-loader>
              {{'checking_for_existing_site' | translate }}
            </span>
            <strong *ngIf="bHasExistingSite">{{ 'next' | translate }}</strong>
          </button>
        </ng-template>
      </div>
    </div>

  </div>
</form>