import { Component, OnInit, Inject, Input, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '../../../../services/notification.service';
import { RecordService } from '../../../../services/record.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StockManagementService } from '../../../../services/stock-management.service';
import { cloneDeep } from 'lodash-es';
import { Relate } from '../../../../objects/relate';
import { Select } from '../../../../objects/select';
import { switchMap } from 'rxjs/operators';
import { LooseObject } from '../../../../objects/loose-object';

@Component({
  selector: 'app-update-stock-allocation',
  templateUrl: './update-stock-allocation.component.html',
  styleUrls: ['./update-stock-allocation.component.scss']
})
export class UpdateStockAllocation implements OnInit {

  public bShowLoader: boolean = false;
  public bSubmitted: boolean = false;

  strStatus: string = '';
  arStatus: Select[] = [
    { id: 'pending', text: 'pending' },
    { id: 'allocated', text: 'allocated' },
    { id: 'used', text: 'used' },
  ]

  objWarehouseRelate = new Relate<Select[]>();

  constructor(
    public dialogRef: MatDialogRef<UpdateStockAllocation>,
    @Inject(MAT_DIALOG_DATA) public data: { materials: LooseObject },
    private notifService: NotificationService,
    private recordService: RecordService,
    private stockManagementService: StockManagementService
    ) {
  }

  ngOnInit() {
    this.objWarehouseRelate.buildRelates(
      switchMap(strTerm => this.recordService.getRecordRelate('warehouses', strTerm, false)), null, false
    );
  }

  /**
   * Close the current dialog.
   */
  cancelDialog() {
    this.dialogRef.close();
  }

  /**
   * save all selected materials
   */
  saveMaterial(): void {
    if (this.strStatus) {
      if (this.strStatus == 'pending' || this.strStatus != 'pending' && this.objWarehouseRelate.value) {
        this.bSubmitted = true;
        let arMaterials = cloneDeep(this.data.materials).map( material => {
          material['status'] = this.strStatus;
          material['warehouse_id'] = (this.objWarehouseRelate.value) ? this.objWarehouseRelate.value['id'] : '';
          material['warehouse_name'] = (this.objWarehouseRelate.value) ? this.objWarehouseRelate.value['text'] : '';
          return material;
        });
        this.stockManagementService.allocateStock(arMaterials).subscribe(() => {
          this.dialogRef.close('saved');
        }, err => {
          this.notifService.notifyError('negative_stock_error');
          this.bSubmitted = false;
        });
      }
    }
  }
}
