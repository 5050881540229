import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { cloneDeep, findIndex, get, isEmpty } from 'lodash-es';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, Subscription, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { EnumeratedTypeFields } from '../../../../../lists/field-type';
import { PromptTypeIcons } from '../../../../../lists/listing-fields';
import { ChecklistResponsePrompt } from '../../../../../objects/checklis-response';
import { Select } from '../../../../../objects/select';
import { ChecklistsService, GetLinkDetailsData } from '../../../../../services/checklist.service';
import { FileService } from '../../../../../services/file/file.service';
import { NotificationService } from '../../../../../services/notification.service';
import { RecordService } from '../../../../../services/record.service';
import { blank, data_get, fallback, filled, generateId, observe, transform, when } from '../../../../utils/common';
import { AnswerableChecklistPrompt, ChecklistPrompt, ChecklistPromptGroup, ChecklistQuestion } from '../../../../../module/checklists/shared/types';
import { DIALOG_DATA } from '../../../../../services/dialog.service';
import { ClientStoreService } from '../../../../../services/client-store.service';

@Component({
  selector: 'app-checklist-response',
  templateUrl: './checklist-response.component.html',
  styleUrls: ['./checklist-response.component.scss'],
  providers: [ChecklistsService]
})
export class ChecklistResponseComponent implements OnInit, OnDestroy {

    public arPrompts: any = [];
    public arChecklist: any = [];
    public arRelatedRecords : any = [];
    public arMetadata : any = [];
    public isPassed : any = "default";
    public strResponseType : string = "main_prompts";
    public arAvailablePeriod : any = [];
    public bHasFailedPrompts: boolean = false;
    public arProducts : any = [];
    public strReviewStatus : string = 'not_yet_reviewed';
    public strFaultDetails : string = '';
    public strJobOrOpportunityNumber : string = '';
    public intMaxCharacter : number = 2056;
    public arNumberOfErroneousText : string[] = [];
    public intDefaultTextAreaMaxLength : number = 10000;
    public intDefaultTextMaxLength : number = 2056;

    public objEnumFields = new EnumeratedTypeFields;
    public arValidModules = [ "jobs", "opportunities", "sites", "assets", "custom_asset_types"];

    public objProducts = {
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'select_product',
        name: 'name',
        module: 'items',
        value: 'arProducts',
        readonly: false
    }

    // Fields that are ready only thus cannot be edited
    public arExcludedFields = [
        "amount_to_invoice",
        "attributes",
        "billing_type",
        "created_at",
        "created_by",
        "invoicing_type",
        "job_number",
        "modified_by",
        "phone",
        "updated_at",
        "updated_by"
    ];

    public objCompletedBy: object = {
        id: '',
        text: '',
    };
    public bRelateLoading: boolean = false;
    public arRelateValuesInput$ = new Subject<string>();
    public arInitialCompletedRelate: Array<object> = [];

    public bFormDirty: boolean = false;
    public shouldShowDateCompleted: boolean = false;
    public strDateCompleted: string = null;
    public bIsCommentEnabled: boolean = false;

    @HostListener('window:keyup.esc') onKeyUp() {
      this.cancelDialog();
    }

    readonly fetching$ = new BehaviorSubject<boolean>(false);

    readonly saving$ = new BehaviorSubject<boolean>(false);

    private _fetchingDetailsSubscription?: Subscription;

    constructor (
        public dialogRef: MatDialogRef<ChecklistResponseComponent>,
        private notifService: NotificationService,
        private fileService: FileService,
        private recordService: RecordService,
        private readonly _checklists: ChecklistsService,
        @Inject(DIALOG_DATA) private readonly _props: ChecklistResponseComponentProps,
        private readonly _clients: ClientStoreService,
    ) {}

    ngOnInit() {
        this.dialogRef.backdropClick().subscribe(_ => {
            this.cancelDialog();
        });

        this._fetchingDetailsSubscription = observe({
            before: () => this.fetching$.next(true),
            after: () => this.fetching$.next(false),
            observable: () => this._checklists.getLinkDetails({
                id: this._props.id,
                asset_type_id: this._props.asset_type_id,
                asset_job_id: this._props.asset_job_id,
                asset_id: this._props.asset_id,
            })
        }).subscribe((data) => this._initialize(data));
    }

    ngOnDestroy(): void {
        if (filled(this._fetchingDetailsSubscription)) {
            this._fetchingDetailsSubscription.unsubscribe();
        }
    }

    /**
     * Initialize products.
     * @param arInitialList
     */
    initializeProducts(arInitialList = []) {
        this.objProducts['obv'] = new Observable<Select[]>();

        // Set the input observable to trigger this API call.
        this.objProducts['obv'] = concat(
            of(arInitialList),
            this.objProducts['typehead'].pipe(
                // Trigger only ever 400 milisecond.
                debounceTime(400),
                // Trigger only when the value is different from the previous.
                distinctUntilChanged(),
                // Show the loader.
                tap(() => {
                    this.objProducts['loader'] = true;
                }),
                // Get the response from the API.
                switchMap(term => this.recordService.getRecordRelate('items', term, '', false, { 'active' : true }).pipe(
                    // Hide the loader once done.
                    tap(() => {
                    this.objProducts['loader'] = false;
                    })
                ))
            )
        );
    }

    /*
    *  Gets data gathered and saves in checklists response table
    */
    onSubmit() {
        let isAssetChecklistParent = (this.arChecklist['type'] === 'asset' && this.strResponseType === 'main_prompts');
        let hasAnsweredPrompts = Array.isArray(this.arPrompts) && this.arPrompts.length !== 0;

        if (isAssetChecklistParent && blank(this.objCompletedBy)) {
            return this.notifService.notifyError('Please select the person who completed this checklist.');
        }

        if (hasAnsweredPrompts || isAssetChecklistParent) {
            // Check if there's a failed pass or fail prompt and require fault details textarea
            if (this.bHasFailedPrompts && this.strFaultDetails == "") {
                this.notifService.notifyWarning('fault_details_required');
            } else if (this.arNumberOfErroneousText.length > 0) {
                this.notifService.notifyWarning('text_max_limit_error');
            } else {
                observe({
                    before: () => this.saving$.next(true),
                    after: () => this.saving$.next(false),
                    observable: () => this._checklists.saveResponse({
                        checklist_response_id: this._props.id,
                        asset_id: this._props.asset_id,
                        questions: this.arPrompts,
                        completed_by: data_get(this.objCompletedBy, 'id'),
                        fault_details: this.strFaultDetails,
                        faulty_product_ids: this.arProducts,
                        review_status: this.strReviewStatus,
                        date_completed: transform(this.strDateCompleted, {
                            transformer: () => moment(this.strDateCompleted).format('YYYY-MM-DD\THH:mm:ss'),
                        }),
                    }),
                }).pipe(
                    filter((succeeded) => succeeded),
                ).subscribe((_) => this.dialogRef.close({
                    status: 'save'
                }));
            }
        } else {
            this.notifService.notifyWarning('no_prompts');
        }
    }

    /**
     * Closes the dialog
     */
    cancelDialog() {
        if (this.checkFormGroupDirty()) {
          // Pop-up modal for confirmation
          this.notifService.sendConfirmation('confirm_cancel')
            .filter(confirmation => confirmation.answer === true)
            .subscribe(() => {
                this.dialogRef.close({ status: 'cancel' });
            });
        } else {
            this.dialogRef.close({status : 'cancel'});
        }
    }

    // Change response type icon
    getTypeIcon(type) {
        return PromptTypeIcons[type];
    }

    // Add leading zeros
    pad(number, size) {
        var strNewNumber = number + "";
        while (strNewNumber.length < size) strNewNumber = "0" + strNewNumber;
        return strNewNumber;
    }

    /**
     * When the ng-select dropdown is clicked/opened, we fake a user input
     * a user input with no characters.
     * @param typehead - the ng-select typehead observable.
     */
    public triggerSubject(typehead: Subject<string>) {
        // We trigger the typehead to execute a search.
        typehead.next("");
    }

    // Returns formatted date from default value of custom field
    public convertCustomDateValues(strDatePeriod) {
        switch(strDatePeriod) {
            case 'today':
                return moment().format('LL');
            case 'seven_days':
                return moment().add(7, 'days').format('LL');
            case 'fourteen_days':
                return moment().add(14, 'days').format('LL');
            case 'thirty_days':
                return moment().add(30, 'days').format('LL');
            case 'last_day_of_this_month':
                return moment().endOf('month').format('LL');
            case 'first_day_of_next_month':
                return moment().add(1, 'month').date(1).format('LL');
            case 'monthly':
                return moment().add(1, 'month').format('LL');
            case 'quarterly':
                return moment().add(3, 'month').format('LL');
            case 'semi_annual':
                return moment().add(6, 'month').format('LL');
            case 'annual':
                return moment().add(1, 'year').format('LL');
            case 'every_2_years':
                return moment().add(2, 'year').format('LL');
            case 'every_3_years':
                return moment().add(3, 'year').format('LL');
            case 'every_4_years':
                return moment().add(4, 'year').format('LL');
            case 'every_5_years':
                return moment().add(5, 'year').format('LL');
            default:
                return moment().format('LL');
        }
    }

    /**
     * Changes an saves review status to review_complete
     *
     * @return void
     */
    changeReviewStatusToComplete() {
        this.strReviewStatus = 'review_complete';
        this.onSubmit();
    }


    /**
     * Check if the form group is changed
     *
     * @returns {boolean}
     */
    checkFormGroupDirty(): boolean {
        return this.bFormDirty;
    }

    /**
     * Mark as dirty
     *
     * @returns {void}
     */
    markAsDirty(bIsDirty: boolean = false): void {
      this.bFormDirty = bIsDirty;
    }


    /**
     * Get the index of the current selected option and negate it's current value
     * (eg. Add checked status if not yet selected)
     *
     * @param {string} selectedId
     * @param {boolean} isChecked
     * @param {number} indexPromptTypes
     * @param {number} indexPrompt
     * @returns {void}
     */
    changeSelection(selectedId: string, isChecked: boolean, options, indexPromptTypes: number, indexPrompt: number): void {

        let selectedIndex = options.findIndex( option => selectedId === option['id']);

        options[selectedIndex]['checked'] = ! isChecked;

        this.arPrompts[indexPromptTypes]['prompts'][indexPrompt]['value']['options'] = options;
    }

    /**
     * Get the set max length of field
     * @param arMetada
     * @returns
     */
    getFieldMaxLength(arMetada: []): number {
        if (arMetada['max_length'] !== undefined) {
            return arMetada['max_length'];
        }

        return this.intMaxCharacter;
    }

    /**
     * Checks if there are any failed 'pass/fail' type prompts, also resets the
     * arProducts and strFaultDetails variables if there are no failed prompts.
     * Prompt changes color if passed (Green) and failed (red).
     *
     * @returns {void}
     */
    checkForFailedPassFailPrompts(): void {
        // Gets all 'pass/fail' type prompts
        let arPassFailPrompts: ChecklistResponsePrompt[] = this.arPrompts.reduce((arFinalRes, promptCategory) => {
            let arCategoryPassFailPrompts = promptCategory['prompts'].reduce((arResult, prompt) => {
                if (prompt['type'] === 'pass/fail') {
                    arResult.push(prompt);
                }

                if (prompt['is_group']) {
                    let arPassFailSubprompts = prompt['prompts'].filter(subprompt => subprompt['type'] === 'pass/fail');
                    arResult = arResult.concat(arPassFailSubprompts);
                }

                return arResult;
            }, []);

            return arFinalRes.concat(arCategoryPassFailPrompts);
        }, []);

        // Checks if at least one 'pass/fail' type prompt has failed
        this.bHasFailedPrompts = arPassFailPrompts.some(
            prompt => (data_get(prompt, 'value.is_passed') === false && filled(data_get(prompt, 'value.pass_fail_value')))
        );

        // If there's no failed prompt reset values for products and fault details
        if (!this.bHasFailedPrompts) {
            this.arProducts = [];
            this.strFaultDetails = "";
        }
    }

    /**
     * Removes the repeating prompts group.
     *
     * @param {number} numIndex
     *
     * @returns {void}
     */
    removeGroup(numIndex: number): void {
        let numCurrPromptIdx: number = this.arPrompts.findIndex(question => this.isQuestionVisible(question));

        this.arPrompts[numCurrPromptIdx]['prompts'].splice(numIndex, 1);
        this.checkForFailedPassFailPrompts();
    }

    /**
     * Checks if there are similar repeating groups.
     *
     * @param {ChecklistPromptGroup} objPromptGroup
     * @param {number} numIndex
     *
     * @returns {boolean}
     */
    hasRepeatingGroup(objPromptGroup: ChecklistPromptGroup, numIndex: number): boolean {
        let numCurrPromptIdx: number = this.arPrompts.findIndex(question => this.isQuestionVisible(question));
        let arRepeatingGroups: ChecklistPromptGroup[] = this.arPrompts[numCurrPromptIdx]['prompts']
            .filter((item, idx) => item['is_group'] && item['group_name'] === objPromptGroup.group_name && idx !== numIndex)
            .filter(item => item['prompts'].every((prompt, idx) => get(prompt, 'prompt') === get(objPromptGroup.prompts[idx], 'prompt')));

        return arRepeatingGroups.length > 0;
    }

    /**
     * Repeats the selected prompt group.
     *
     * @param {ChecklistPrompt<ChecklistPromptGroup>} objGroup
     *
     * @returns {void}
     */
    repeatGroup(objGroup: ChecklistPrompt<ChecklistPromptGroup>, numIndex: number): void {
        let objBlankGroup = this.getBlankPromptGroup(objGroup);
        let numCurrPromptIdx: number = this.arPrompts.findIndex(question => this.isQuestionVisible(question));

        this.arPrompts[numCurrPromptIdx]['prompts'].splice(numIndex + 1, 0, objBlankGroup);
        this.checkForFailedPassFailPrompts();
    }

    /**
     * Creates a blank copy of the selected prompt group.
     *
     * @param {ChecklistPrompt<ChecklistPromptGroup>} objGroup
     *
     * @returns {ChecklistPrompt<ChecklistPromptGroup>}
     */
    getBlankPromptGroup(objGroup: ChecklistPrompt<ChecklistPromptGroup>): ChecklistPrompt<ChecklistPromptGroup> {
        let objClonedGroup = cloneDeep(objGroup);

        objClonedGroup.id = generateId();
        objClonedGroup.parent_id = fallback(data_get(objGroup, 'parent_id'), {
            fallback: () => objGroup.id,
        });
        objClonedGroup.prompts = objClonedGroup.prompts.map((objPrompt: ChecklistPrompt<AnswerableChecklistPrompt>) => {
            objPrompt.id = generateId();

            switch (objPrompt.type) {
                case "pass/fail":
                    objPrompt.value = {
                        pass: objPrompt.value.pass,
                        fail: objPrompt.value.fail,
                        pass_and_fail: objPrompt.value.pass_and_fail
                    };
                    break;
                case "field_entry":
                    objPrompt.value = {
                        default_value: objPrompt.value.default_value,
                        module: objPrompt.value.module,
                        field: objPrompt.value.field,
                        field_entry: objPrompt.value.field_entry,
                        label: get(objPrompt, 'value.label', ''),
                        key: get(objPrompt, 'value.key', ''),
                        required: get(objPrompt, 'value.required', false),
                        type: get(objPrompt, 'value.type', 'text')
                    };
                    break;
                case "dropdown":
                    objPrompt.value = { dropdown: objPrompt.value.dropdown };
                    break;
                case 'images':
                    objPrompt.value = {
                        'images': [],
                    };
                    break;
                case "signature":
                case "text":
                case "number":
                case "date":
                    objPrompt.value = { [objPrompt.type]: '' };
                    break;
            }

            return objPrompt;
        });

        return objClonedGroup;
    }

    /**
     * Perform actions on the prompt based on the prompt type.
     *
     * @param {ChecklistPrompt<AnswerableChecklistPrompt>} objPrompt
     *
     * @returns {ChecklistPrompt<AnswerableChecklistPrompt>}
     */
    initPrompt(objPrompt: ChecklistPrompt<AnswerableChecklistPrompt>): ChecklistPrompt<AnswerableChecklistPrompt> {
        /// per prompt image
        if (filled(data_get(objPrompt, 'image'))) {
            const perPromptImages = data_get(objPrompt, 'image', {
                default_value: [],
            });

            for (const perPromptImage of perPromptImages) {
                if (filled(data_get(perPromptImage, 'upload_name'))) {
                    this.fileService.getObjectSignedUrl(perPromptImage['upload_name'], environment.client_bucket).subscribe(object => {
                        perPromptImage['image_path'] = object['url'];
                    });
                }
            }
        }

        switch(objPrompt['type']) {
            case 'signature' :
                if (objPrompt['value']['signature'] != "") {
                    this.fileService.getObjectSignedUrl(objPrompt['value']['signature'], environment.client_bucket).subscribe(object => {
                        objPrompt['value']['image_path'] = object['url'];
                    });
                }
            break;
            // Combine Pass and Fail Values into an array
            case 'pass/fail' :
                // Check if there are failed values
                if (filled(data_get(objPrompt, 'value.pass_fail_value')) && data_get(objPrompt, 'value.is_passed') === false) {
                    this.bHasFailedPrompts = true;
                }

                objPrompt['value']['pass_and_fail'] = objPrompt['value']['pass'].concat(objPrompt['value']['fail']);
            break;

            // Get the corresponding value of the field based on the module indicated
            case 'field_entry' :

                // Temporarily store field and module (WILL REMOVE IN SAVING)
                let arFieldEntryValue = objPrompt['value']['field_entry'].split("-");

                objPrompt['value']['module'] = arFieldEntryValue[0]; // Get the module
                objPrompt['value']['field'] = arFieldEntryValue[1]; // Get the field

                // Checks if module is supported or valid
                if (this.arValidModules.includes(objPrompt['value']['module'])) {

                    if (objPrompt['value']['module'] != 'custom_asset_types') {
                        let strFieldEntryValue = "";
                        const objCurrentFieldMetadata = get(this.arMetadata[objPrompt['value']['module']], (objPrompt['value']['module'], objPrompt['value']['field']), null);

                        if (filled(data_get(this.arRelatedRecords, [objPrompt['value']['module'], [objPrompt['value']['field']]]))) {
                            strFieldEntryValue = this.arRelatedRecords[objPrompt['value']['module']][objPrompt['value']['field']];
                        } else {
                            strFieldEntryValue = objCurrentFieldMetadata && this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['default_value'] != undefined
                                ? this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['default_value'] : "";
                        }

                        // Get the dropdown option of custom fields in metadata
                        if (objPrompt['value']['field'].includes('dropdown')) {
                            objPrompt['value']['dropdown'] = objCurrentFieldMetadata && this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['options'] != undefined
                                ? this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['options'] : [];
                        } else if (objPrompt['value']['field'].includes('date')) {
                            // Formats date from default value of custom field  (eg. today, seven_days etc)
                            strFieldEntryValue = this.convertCustomDateValues(strFieldEntryValue);
                        } else if (objPrompt['value']['field'].includes('multiselect')) {

                            if (objPrompt['value']['options'] === undefined) {

                                let arCurrentMetadata = this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']];

                                let arCurrentOptions = objCurrentFieldMetadata && arCurrentMetadata['options'] !== undefined
                                    ? arCurrentMetadata['options'] : [];

                                let arDefaultValues = arCurrentMetadata['default_value'];

                                arCurrentOptions.some(option => {
                                    option['checked'] = arDefaultValues.includes(option['id']);
                                });

                                objPrompt['value']['options'] = arCurrentOptions;
                            }
                        } else if (objPrompt['value']['field'].includes('textarea') || objPrompt['value']['field'].includes('text')) {
                            const fieldEntryValue = get(objPrompt, ['value', 'field_entry_value'], []);
                            const intMaxLength = get(this.arMetadata, [objPrompt['value']['module'], objPrompt['value']['field'], 'max_length']);

                            if (fieldEntryValue.length > intMaxLength) {
                                this.arNumberOfErroneousText.push(objPrompt['id']);
                            }
                        }

                        if (objCurrentFieldMetadata['type'] === 'checkbox') {
                            objPrompt['value']['field_entry_value'] = isEmpty(objPrompt['value']['field_entry_value']) ?
                                strFieldEntryValue : objPrompt['value']['field_entry_value'];
                        }

                        objPrompt['value']['default_value'] = strFieldEntryValue;

                    } else {
                        if (filled(data_get(this.arRelatedRecords, 'assets.asset_type_attributes'))) {
                            const attributesMetadata = fallback(data_get(this.arRelatedRecords, 'assets.asset_type_attributes'), {
                                fallback: () => [],
                            });

                            let intIndexOfAttribute = findIndex(
                                attributesMetadata,
                                (item) => (
                                    get(item, 'key') == objPrompt['value']['field']
                                )
                            );

                            if (intIndexOfAttribute !== -1 && filled(attributesMetadata[intIndexOfAttribute])) {
                                objPrompt['value']['key'] = attributesMetadata[intIndexOfAttribute]['key'];
                                objPrompt['value']['label'] = attributesMetadata[intIndexOfAttribute]['label'];
                                objPrompt['value']['default_value'] = when(filled(data_get(objPrompt, 'value.default_value')), {
                                    then: () => data_get(objPrompt, 'value.default_value'),
                                    else: () => data_get(this.arRelatedRecords, ['assets', 'attributes', data_get(objPrompt, 'value.field')])
                                });
                                objPrompt['value']['type'] = attributesMetadata[intIndexOfAttribute]['type'];
                                objPrompt['value']['required'] = attributesMetadata[intIndexOfAttribute]['required'];

                                if (filled(data_get(objPrompt, 'value.field_entry_value'))) {
                                    objPrompt['value']['default_value'] = objPrompt['value']['field_entry_value'];
                                }

                                if (objPrompt['value']['type'] === 'dropdown') {
                                    objPrompt['value']['option'] = attributesMetadata[intIndexOfAttribute]['option'] != "" ?
                                        attributesMetadata[intIndexOfAttribute]['option'] : [];
                                }
                            }
                        }

                        /// if we cannot find the corresponding asset attribute for this prompt. we will fallback to text
                        /// this is to prevent the prompt from being empty
                        /// this happens when attribute is deleted from the asset type
                        if (blank(get(objPrompt, 'value.type'))) {
                            objPrompt['value']['type'] = 'text';
                            objPrompt['value']['key'] = objPrompt['value']['field'];
                            objPrompt['value']['label'] = objPrompt['value']['field'];
                            objPrompt['value']['required'] = false;
                        }
                    }

                }
            break;

            // Get the corresponding value of the field based on the module indicated
            case 'image':
            case 'images':
                if (isEmpty(objPrompt['value'][objPrompt.type])) {
                    objPrompt['value'][objPrompt.type] = [];
                }

                if (objPrompt['value'][objPrompt.type].length > 0) {

                    let arTempImages = [];
                    objPrompt['value'][objPrompt.type].forEach(image => {

                        // If image type is not defined
                        image['type'] = image['type'] == undefined ? 'image/jpeg' : image['type'];

                        // If uploaded name is not undefined
                        if (image['upload_name'] != undefined) {
                            this.fileService.getObjectSignedUrl(image['upload_name'], environment.client_bucket).subscribe(object => {
                                // Override image path to new presigned path
                                image['image_path'] = object['url']
                                arTempImages.push(image);
                            });
                        }
                    });

                    objPrompt['value'][objPrompt.type] = arTempImages

                }
            break;


            // Get the corresponding value of the field inserted (IF THERE'S ANY) based on the module indicated
            // Then parse the value back to the whole instructions text
            case 'instructions' :
                let arVariableData = [];
                let strFieldInstructionValue = objPrompt['value']['instructions_text'];

                // Find all occurence of possible variables with given format eg. ({$sites-name}, {$jobs-po_number}, {$quotes-po_number})
                let arVariableMatches = strFieldInstructionValue.match(/[{$](.*?)[}]/g);
                // Check if Matches are already loaded (if there's any) and is not null
                if (arVariableMatches != null) {
                    // loop through all found variables and check if a valid variable
                    arVariableMatches.forEach( strVariable => {

                        // Extract the module and field type from the current variable format
                        let intLastIndex = strVariable.length - 1;
                        let arInstructionValue = strVariable.substring(2, intLastIndex).split("-");

                        objPrompt['value']['module'] = arInstructionValue[0]; // Get the module
                        objPrompt['value']['field'] = arInstructionValue[1] === 'address' ? 'address_text' : arInstructionValue[1] ; // Get the field (If address however, get the text value)

                        // Checks if module is supported or valid
                        if (this.arValidModules.includes(objPrompt['value']['module'])) {
                            if (objPrompt['value']['module'] != 'custom_asset_types') {

                                if (filled(data_get(this.arRelatedRecords, [objPrompt['value']['module'], [objPrompt['value']['field']]]))) {
                                    arVariableData.push(this.arRelatedRecords[objPrompt['value']['module']][objPrompt['value']['field']]);
                                } else {
                                    if (
                                        this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']] !== undefined &&
                                        this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['default_value']
                                    ) {
                                        arVariableData.push(this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['default_value']);
                                    }
                                }
                            } else {
                                if (filled(data_get(this.arRelatedRecords, 'assets.asset_type_attributes'))) {
                                    const attributesMetadata = fallback(data_get(this.arRelatedRecords, 'assets.asset_type_attributes'), {
                                        fallback: () => [],
                                    });

                                    // get index of the attribute
                                    let intIndexOfAttribute = attributesMetadata.findIndex(
                                        item => (
                                            item.key == objPrompt['value']['field']
                                        )
                                    );
                                    if (intIndexOfAttribute >= 0 && filled(attributesMetadata[intIndexOfAttribute])) {
                                        arVariableData.push(
                                            data_get(this.arRelatedRecords, ['assets', 'attributes', data_get(objPrompt, 'value.field')]),
                                        );
                                    }
                                }
                            }
                        } else {
                            // If module invalid, remove it from the list
                            const index = arVariableMatches.indexOf(strVariable, 0);
                            if (index > -1) {
                                arVariableMatches.splice(index, 1);
                            }
                        }
                    });

                    // Replace all the variable values in instruction with actual values from the record
                    for ( let intVariableNumber = 0; arVariableMatches.length > intVariableNumber; intVariableNumber++ ) {
                        strFieldInstructionValue = strFieldInstructionValue.replace(arVariableMatches[intVariableNumber], fallback(arVariableData[intVariableNumber], {
                            fallback: () => '',
                        }));
                    }

                    objPrompt['value']['instructions_text'] = strFieldInstructionValue;
                }
            break;
        }

        return objPrompt;
    }

    isQuestionVisible(question: ChecklistQuestion): boolean {
        if (question.name == 'main_prompts' && data_get(this.arChecklist, 'type') != 'asset') {
            return true;
        }

        return question.name == 'per_asset_prompts' &&
            data_get(this.arChecklist, 'type') == 'asset';
    }

    private _initialize(response: GetLinkDetailsData): void {
        this.arChecklist = response.data;
        this.strResponseType = (response.data.type != 'asset') ? 'main_prompts' : 'per_asset_prompts';
        this.strReviewStatus = response.data.review_status;

        /// backward compatibility for asset checklist rendering from linked checklists widget
        /// should display a completing form instead
        if (response.data.type == 'asset' && blank(this._props.asset_job_id)) {
            this.strResponseType = 'main_prompts';
        }

        this.strJobOrOpportunityNumber = fallback(data_get(response.related_data, 'jobs.job_number'), {
            fallback: () => data_get(response.related_data, 'opportunities.opportunity_number'),
        });

        this.bIsCommentEnabled = response.data.is_comment_enabled;

        this._initializeCompletionDetails(response);
        this._initializeFaultDetails(response);

        this.arRelatedRecords = response.related_data;
        this.arMetadata = response.modules_metadatum;

        /// initial prompts
        const questions = fallback(response.data.response, {
            fallback: () => response.data.questions
        });

        for (let question of questions) {
            question.prompts = question.prompts
                .map((prompt) => {
                    if (prompt.type == 'group') {
                       prompt.prompts = prompt.prompts.map((subPrompt) => this.initPrompt(subPrompt));

                       return prompt;
                    }

                    return this.initPrompt(prompt);
                });
        }

        this.arPrompts = questions;
    }

    private _initializeCompletionDetails(response: GetLinkDetailsData): void {
        const client = this._clients.getActiveClient();

        if (data_get(client, 'level') == 'admin' && filled(data_get(response.data, 'date_completed'))) {
            this.shouldShowDateCompleted = true;
            this.strDateCompleted = data_get(response.data, 'date_completed');
        }

        if (filled(data_get(response.data, 'completed_by'))
            && filled(data_get(response.data, 'completed_by_name'))
        ) {
            this.objCompletedBy = new Select(response.data.completed_by, response.data.completed_by_name);
        }
    }

    private _initializeFaultDetails(response: GetLinkDetailsData): void {
        const faultDetails = data_get(response.data, 'fault_details');
        const faultyProducts = fallback(data_get(response.related_data, 'items'), {
            fallback: () => [],
        });

        this.initializeProducts(faultyProducts);

        this.arProducts = data_get(response.data, 'product_id');
        this.strFaultDetails = faultDetails;
    }
}

export type ChecklistResponseComponentProps = {
    id: string;
    asset_id?: string;
    asset_type_id?: string;
    asset_job_id?: string;
}