import { Config  } from 'protractor';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import {
  map,
  catchError,
  timeout,
  switchMap,
  tap,
  filter
} from 'rxjs/operators';
import { DriverInterface, IntegratedDriverInterface } from '../entities/driver';
import { environment } from '../../../../../environments/environment';
import { LooseObject } from '../../../../objects/loose-object';
import { Select } from '../../../../objects/select';
import * as moment from 'moment';
import { LocalStorageService } from '../../../../services/local-storage.service';
import {
  get,
  trim,
  trimEnd,
  isEmpty,
  tap as lodashTap
} from 'lodash-es';
import { blank, filled } from '../../../../shared/utils/common';

@Injectable({
  providedIn: 'root'
})
export class AccountingSystemService {
  /**
   * Progress event observable instance
   *
   * @var {Subject<ProgressEvent>}
   */
  protected progress$: Subject<ProgressEvent> = new Subject;

  /**
   * @param {HttpClient} http
   * @param {LocalStorageService} storage
   */
  constructor(
    private http: HttpClient,
    private storage: LocalStorageService
  ) {}

  /**
   * retrieves list of available drivers from the API
   *
   * @returns {Observable<DriverInterface[]>}
   */
  getDrivers$(): Observable<DriverInterface[]> {
    return this.callApi$('post', '/').pipe(
      map(({body}) => {
        return body.drivers || [];
      }),
      catchError(() => of([]))
    );
  }

  /**
   * retrieves the authorization url for the given driver
   *
   * @param   {DriverInterface} driver
   *
   * @returns {Observable<string>}
   */
  getAuthorizationUrl$(driver: DriverInterface): Observable<string> {
    let body = new URLSearchParams;

    body.append('driver_id', driver.id);

    return this.callApi$('post', '/get_authorization_url', body.toString())
      .pipe(
        map((response: HttpResponse<Config>) => {
          let url: string = response.body.url;
          return url;
        }),
        catchError(() => {
          return of(undefined);
        })
      );
  }

  /**
   * opens the authorization url that is assigned in the driver
   *
   * @param   {string} url
   *
   * @returns {Observable<{ isSuccessful: boolean, message: string }>}
   */
  authorize$(url: string): Observable<{ isSuccessful: boolean, message: string }> {
    let subject = new Subject<{ isSuccessful: boolean, message: string }>();
    let authorizationOrigins = (environment.accounting_system_authorization_callback_origins || []).map(
      (origin) => trimEnd(origin, '/')
    );

    window.addEventListener('message', (event: MessageEvent) => {
      let message: string = 'an_error_occured';
      let isSuccessful: boolean = false;

      if (! event.isTrusted || ! authorizationOrigins.includes(event.origin)) {
        message = 'unknown_authorization_origin';
      } else if (event.data.isSuccess) {
        isSuccessful = true;
        message = 'authorization_successful';
      } else if (event.data.message) {
        message = event.data.message;
      }

      subject.next({ isSuccessful, message });
      subject.complete();
    }, false);

    setTimeout(() => {
      let authWindow = window.open(url);

      let checkWindowClose = setInterval((() => {
        if (authWindow !== null && authWindow.closed) {
          clearInterval(checkWindowClose);
          let closed = { isSuccessful: false, message: "authorization_closed" };
          subject.next(closed);
          subject.complete();
        }
      }), 500);

    }, 1000);

    return subject.asObservable();
  }

  /**
   * call the api for importing tax codes
   *
   * @param  {ImportOption} options
   *
   * @returns {Observable<ImportResult>}
   */
  importTaxCodes$(options: ImportOption = undefined): Observable<ImportResult> {
    return this.doImport$('/import_tax_codes', options).pipe(
      map((response: HttpResponse<Config>) => {
        let isSuccessful: boolean = false;

        if (response.status === 201) isSuccessful = true;

        return {
          isSuccessful,
          errors: [],
          batch: response.body.batch_option
        };
      }),
      catchError((error) => {
        if (error.name === 'HttpErrorResponse') {
          const response = error;

          return of({
            isSuccessful: false,
            errors: [ response.error.message || 'an_error_occured' ],
            batch: undefined,
          });
        } else {
          return of({
            isSuccessful: false,
            errors: ['an_error_occured'],
            batch: undefined,
          });
        }
      })
    );
  }

  /**
   * call the api for importing account codes
   *
   * @param {ImportOption} options
   *
   * @returns {Observable<ImportResult>}
   */
  importAccountCodes$(options: ImportOption = undefined): Observable<ImportResult> {
    return this.doImport$('/import_account_codes', options).pipe(
      map((response: HttpResponse<Config>) => {
        let isSuccessful: boolean = false;

        if (response.status === 201) isSuccessful = true;

        return {
          isSuccessful,
          errors: [],
          batch: response.body.batch_option
        };
      }),
      catchError((error) => {
        if (error.name === 'HttpErrorResponse') {
          const response = error;

          return of({
            isSuccessful: false,
            errors: [ response.error.message || 'an_error_occured' ],
            batch: undefined,
          });
        } else {
          return of({
            isSuccessful: false,
            errors: ['an_error_occured'],
            batch: undefined,
          });
        }
      })
    );
  }

  /**
   * request an import of items to the API service
   *
   * @param   {ImportOption} options
   *
   * @returns {Observable<ImportResult>}
   */
  importItems$(options: ImportOption = undefined): Observable<ImportResult> {
    return this.doImport$('/import_items', options).pipe(
      map((response: HttpResponse<Config>) => {
        let isSuccessful: boolean = false;
        let { status } = response;

        if (status === 201) isSuccessful = true;

        return {
          isSuccessful,
          errors: [],
          shouldSkip: status === 202,
          batch: response.body.batch_option
        };
      }),
      catchError((error) => {
        if (error.name === 'HttpErrorResponse') {
          const response = error;

          return of({
            isSuccessful: false,
            errors: [ response.error.message || 'an_error_occured' ],
            batch: undefined,
          });
        } else {
          return of({
            isSuccessful: false,
            errors: ['an_error_occured'],
            batch: undefined,
          });
        }
      })
    );
  }

  /**
   * schedules an import procedure of customers and suppliers for the configured driver
   *
   * @param   {boolean} shouldNotifyImmediately
   * @param   {string}  modifiedSince
   *
   * @returns {Observable<{isScheduledSuccessfully: boolean, errors: string[]}>}
   */
  scheduleImportCustomersAndSuppliers$(shouldNotifyImmediately: boolean = false, modifiedSince: string = undefined): Observable<{isScheduledSuccessfully: boolean, errors: string[]}> {
    let payload = new URLSearchParams;

    payload.append('notify_immediately', (shouldNotifyImmediately) ? '1': '0');

    if (modifiedSince !== undefined) payload.append('modified_since', moment(modifiedSince, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));

    return this.callApi$('post', '/schedule_import/customers', payload.toString())
      .pipe(
        map(() => {
          return {
            isScheduledSuccessfully: true,
            errors: []
          };
        }),
        catchError((response: HttpErrorResponse) => {
          return of({
            isScheduledSuccessfully: false,
            errors: [ response.error.message || 'an_error_occured' ]
          });
        })
      );
  }

  /**
   * schedules an import procedure of contacts for the configured driver
   *
   * @param   {boolean} shouldNotifyImmediately
   * @param   {string}  modifiedSince
   *
   * @returns {Observable<{isScheduledSuccessfully: boolean, errors: string[]}>}
   */
  scheduleImportContacts$(shouldNotifyImmediately: boolean = false, modifiedSince: string = undefined): Observable<{isScheduledSuccessfully: boolean, errors: string[]}> {
    let payload = new URLSearchParams;

    payload.append('notify_immediately', (shouldNotifyImmediately) ? '1': '0');

    if (modifiedSince !== undefined) payload.append('modified_since', moment(modifiedSince, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));

    return this.callApi$('post', '/schedule_import/contacts',  payload.toString())
      .pipe(
        map(() => {
          return {
            isScheduledSuccessfully: true,
            errors: []
          };
        }),
        catchError((response: HttpErrorResponse) => {
          return of({
            isScheduledSuccessfully: false,
            errors: [ response.error.message || 'an_error_occured' ]
          });
        })
      );
  }

  /**
   * retrieves the current integrated driver information that the client was connected to
   *
   * @param   {boolean} shouldDisconnect
   *
   * @returns {Observable<IntegratedDriverInterface|undefined>}
   */
  getIntegratedDriver$(shouldDisconnect: boolean = false): Observable<IntegratedDriverInterface|undefined> {
    let body = new URLSearchParams;

    if (shouldDisconnect) {
      body.append('should_disconnect', '1');
    }

    return this.callApi$('post', '/get_configured_driver', body.toString())
      .pipe(
        map(({status, body}) => {
          if (status === 204) return undefined;
          return body as IntegratedDriverInterface;
        }),
        tap((driver) => this.storeAccountingDriverIdentity(driver))
      );
  }

  /**
   * If client uses Xero accounting, gets the organization short code.
   *
   * @returns {Observable<string|null>}
   */
  getXeroShortCode$(): Observable<string|null> {
    const strShortCode: string = this.storage.getItem('xero_short_code');

    if (blank(strShortCode)) {
      return this.getIntegratedDriver$().pipe(
        tap(driver => this.storage.setItem('xero_short_code', get(driver, 'shortCode', ''))),
        switchMap(driver => of(get(driver, 'shortCode')))
      );
    }

    return of(strShortCode);
  }

  /**
   * disconnects the current integrated driver that is configured to our system
   *
   * @returns {Observable<{isSuccessful: boolean, errors: string[]}>}
   */
  disconnect$(): Observable<{isSuccessful: boolean, errors: string[]}> {
    return this.callApi$('post', '/disconnect')
      .pipe(
        map((response: HttpResponse<Config>) => {
          let errors = [];
          let isSuccessful = false;

          if (response.status === 204) isSuccessful = true;

          return { isSuccessful, errors };
        }),
        catchError((response: HttpErrorResponse) => {
          if (response.status === 400) {
            return of({ isSuccessful: false, errors: [ response.error.message ]});
          }
        }),
        tap(({ isSuccessful }) => isSuccessful && this.storeAccountingDriverIdentity(undefined))
      );
  }

  /**
   * schedules a sync to accounting system integrated for the given module
   *
   * @param   {string} strModuleId
   * @param   {string} strModuleName
   *
   * @returns {Observable<ScheduleSyncResult>}
   */
  scheduleSync$(strModuleId: string, strModuleName: string): Observable<ScheduleSyncResult> {
    let objPayload = lodashTap(new URLSearchParams, (objParams) => {
      objParams.append('id', strModuleId);
    });

    return this.callApi$('post', `/sync/${strModuleName}`, objPayload)
      .pipe(
        map((objResponse: HttpResponse<AccountingSyncResponse>) => ({
          isSuccessful: objResponse.status === 200,
          status: objResponse.status,
        })),
        catchError((objErrorResponse: HttpErrorResponse) => {
          if (objErrorResponse.status === 404 || objErrorResponse.status === 400) {
            return of({
              isSuccessful: false,
              errors:  (objErrorResponse.status === 400)
                ? [objErrorResponse.error['message']] : ['module_not_found'],
            });
          }

          return throwError(objErrorResponse);
        })
      );
  }

  /**
   * checks if the current client has an integrated driver installed
   *
   * @returns {Observable<boolean>}
   */
  hasIntegratedDriver$(): Observable<boolean> {
    return Observable.defer(() => {
      let installedAccountingId = this.storage.getItem('accounting_system_id');

      // if we have a cached accounting id lets check wether the value is equal to `not_set`
      if (! isEmpty(installedAccountingId)) {
        return Observable.of(installedAccountingId !== 'not_set');
      }

      return this.getIntegratedDriver$().pipe(
        map((driver) => !!driver),
      );
    });
  }

  /**
   * updates the client's driver metadata
   *
   * @param   {LooseObject} metadata
   * @param   {string}      type
   *
   * @returns {Observable<{isSuccessful: boolean, errors: FieldValidationError}>}
   */
  updateMetadata$(metadata: LooseObject, options: {type: string, driverId: string}): Observable<{isSuccessful: boolean, errors: FieldValidationError}> {
    let payload = new URLSearchParams;

    payload.append('data', JSON.stringify(metadata));
    payload.append('type', options.type);
    payload.append('driver_id', options.driverId);

    return this.callApi$('post', '/update_metadata', payload.toString())
      .pipe(
        map(() => ({
          isSuccessful: true,
          errors: undefined,
        })),
        catchError(({status, error}) => {
          if (status === 422) return of({isSuccessful: false, errors: error});
        })
      )
  }

  /**
   * retrieves the dynamic options of the given metadata field
   *
   * @param   {string} id
   * @param   {string} driverId
   *
   * @returns {Observable<Select[]>}
   */
  getMetadataFieldOptions$(id: string, driverId: string): Observable<Select[]> {
    let payload = new URLSearchParams;

    payload.append('id', id);
    payload.append('driver_id', driverId);

    return this.callApi$('post', '/get_dropdown_options', payload.toString())
      .pipe(
        map(({body}) => body as Select[]),
        catchError(() => of([])),
      );
  }

  /**
   * update/save the current process step
   *
   * @param {string} type
   * @param {string} currentStep
   *
   * @returns {Observable<boolean>}
   */
  updateStep$(type: string, currentStep: string): Observable<boolean> {
    let payload = new URLSearchParams;

    payload.append('type', type);
    payload.append('current_step', currentStep);

    return this.callApi$('post', '/update_steps', payload.toString())
      .pipe(
        map(() => true)
      );
  }

  /**
   *
   * @param {string} type
   *
   * @returns {string|undefined}
   */
  getCurrentStep$(type: string): Observable<string|undefined> {
    return this.callApi$('post', '/get_steps')
      .pipe(
        map(({body}) => {
          return get(body, type);
        })
      );
  }

  /**
   * calls a given api endpoint
   *
   * @param {string} method
   * @param {string} uri
   * @param {any}    body
   *
   * @returns {Observable<HttpResponse<Config>>}
   */
  private callApi$(method: string, uri: string, body?: any): Observable<HttpResponse<Config>> {
    var baseUrl = trimEnd(environment.url, '/');
    var uri = trim(uri, '/');
    var method = method || 'get';

    return this.http[method](trim(`${baseUrl}/integrations/accounting_systems/${uri}`, '/'), body, { observe: 'response' }).pipe(timeout(60000));
  }

  /**
   * Update client system defaults
   *
   * @param {[key: string]: any} fields
   *
   * @returns {Observable<boolean>}
   */
  updateSystemDefaults$(fields: {[key: string]: any}): Observable<boolean> {
    let body = new URLSearchParams();

    body.append('data', JSON.stringify(fields));

    return this.callApi$('post', 'setup_default_configuration', body.toString())
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  /**
   * Sends an enquiry for new accounting integrations
   *
   * @param {string} message
   *
   * @returns {Observable<boolean>}
   */
  sendEnquiry$(message: string): Observable<boolean> {
    let body = new URLSearchParams;

    body.append('message', message);

    return this.callApi$('post', '/send_suggestion', body.toString())
      .pipe(
        map(({status}) => status === 200),
        catchError((err) => {
          if (err.name === 'HttpErrorResponse'  && err.status >= 400 && err.status <= 499) return of(false);
        })
      );
  }

  /**
   * Retrieved the current progress event
   *
   * @returns {Observable<ProgressEvent>}
   */
  getProgressEvent$(): Observable<ProgressEvent> {
    return this.progress$.asObservable();
  }

  toggleInvoicesAccountingFlag(moduleName: string, opts: {
    enabled?: boolean,
    selected?: string[],
    all?: boolean,
    excluded?: string[],
  } = {}): Observable<boolean> {
    opts = Object.assign({
      enabled: false,
      all: false,
    }, opts);

    const payload = new URLSearchParams();

    payload.append('module_name', moduleName);
    payload.append('enabled', opts.enabled ? '1' : '0');

    if (opts.all) {
      payload.append('all', '1');
    }

    if (opts.selected) {
      payload.append('ids', JSON.stringify(opts.selected));
    }

    if (opts.excluded && filled(opts.excluded)) {
      payload.append('excluded', JSON.stringify(opts.excluded));
    }

    return this.callApi$('post', '/toggle_accounting_flag', payload).pipe(
      map((_) => true),
    );
  }

  /**
   * Emits and creates progress event
   *
   * Note: Currently can only handle one progress at a time, Parallel progress is not yet supported
   *
   * @param {number} progress
   * @param {number} total
   * @param {any}    id
   *
   * @returns {void}
   */
  protected emitProgressEvent(progress: number, total: number | null, id: any = undefined): void {
    this.progress$.next({
      id,
      progress,
      total,
    });
  }

  /**
   * Request an import to the given uri and automatically handles batch import
   *
   * @param {string} uri
   * @param {ImportOption} options
   *
   * @returns {Observable<T>}
   */
  protected doImport$<T>(uri: string, options: ImportOption = undefined): Observable<T> {
    let body = new URLSearchParams;

    // merge defaults
    options = Object.assign({
      cursor: null
    }, options);

    if (options.cursor) body.append('cursor', options.cursor);

    return this.callApi$('post', uri, body.toString()).pipe(
      switchMap((response) => {
        const { batch_option } = response.body;

        if (response.status === 201 && batch_option) {
          // emit a progress event
          this.emitProgressEvent(batch_option.cursor, batch_option.total, options.progressId);

          return this.doImport$(uri, Object.assign(options, {
            cursor: batch_option.cursor,
          }));
        }

        return of(response);
      })
    ) as Observable<T>;
  }

  /**
   * Flags the system that there were no imported items
   *
   * @returns {void}
   */
  flagNoImportedItems(): void {
    this.storage.setItem('accounting_has_no_imported_items', true);
  }

  /**
   * unsets the flag for no imported items
   *
   * @returns {void}
   */
  unsetFlagForNoImportedItems(): void {
    this.storage.removeItem('accounting_has_no_imported_items');
  }

  /**
   * Flag if system has no imported items
   *
   * @returns {boolean}
   */
  get noImportedItems(): boolean {
    return this.storage.hasItem('accounting_has_no_imported_items') === true;
  }

  /**
   * Store accounting driver identity if provided
   *
   * @param   {Pick<DriverInterface, "id">} driver
   *
   * @returns {void}
   */
  storeAccountingDriverIdentity(driver?: Pick<DriverInterface, "id">): void {
    let identity = (driver) ? driver.id : 'not_set';

    this.storage.setItem('accounting_system_id', identity);
  }

  /**
   * Clears stored accounting system identifier
   *
   * @return {void}
   */
  clearStoredAccountingSystemIdentity(): void {
    this.storage.removeItem('accounting_system_id');
  }

  /**
   * Updates current amount due from the accounting
   *
   * @param {string} id
   * @param {string} moduleName
   *
   * @returns {Observable<boolean>}
   */
  updateAmountDue$(id: string, moduleName: string): Observable<boolean> {
    const body = new URLSearchParams;

    body.append('id', id);

    return this.callApi$('post', `/sync/${moduleName}/amount_due`, body.toString())
      .pipe(
        filter((response) => response.status === 200),
        map(() => true)
      )
  }

  /**
   * Do a sync with debug enable returning the request logs
   *
   * @param  {string} strModuleId
   * @param  {string} strModuleName
   *
   * @returns {Observable<DebugSyncResult>}
   */
  syncDebug$(strModuleId: string, strModuleName: string): Observable<DebugSyncResult> {
    let objPayload = lodashTap(new URLSearchParams, (objParams) => {
      objParams.append('id', strModuleId);
    });

    return this.callApi$('post', `/sync_debug/${strModuleName}`, objPayload)
      .pipe(
        filter((objResponse) => objResponse.status === 200),
        map((objResponse: HttpResponse<AccountingDebugSyncResponse>) => ({
          isSuccessful: true,
          requestLogId: objResponse.body.request_log_id,
        })),
        catchError((objErrorResponse: HttpErrorResponse) => {
          if (objErrorResponse.status === 404 || objErrorResponse.status === 400) {
            return of({
              isSuccessful: false,
              errors:  (objErrorResponse.status === 400)
                ? [objErrorResponse.error['message']] : ['module_not_found'],
              requestLogId: objErrorResponse.error['request_log_id'],
            });
          }

          return throwError(objErrorResponse);
        })
      )
  }

  /**
   * Retrieved request log contents
   *
   * @param {string} strAuditLogId
   * @param {string} strNextToken
   *
   * @returns {Observable<AccountingDebugLogResult>}
   */
  getRequestLogs$(strAuditLogId: string, strNextToken: string = undefined): Observable<AccountingDebugLogResult> {
    let objPayload = lodashTap(new URLSearchParams, (objParams) => {
      objParams.append('log_id', strAuditLogId);

      if (strNextToken !== undefined) {
        objParams.append('next_token', strNextToken);
      }
    });

    return this.callApi$('post', '/get_debug_logs', objPayload)
      .pipe(
        filter((objResponse) => objResponse.status === 200),
        map((objResponse: HttpResponse<AccountingDebugLogsResponse>) => ({
          contents: objResponse.body.contents.map((objContent) => ({
            method: objContent.method,
            url: objContent.url,
            requestHeaders: objContent.request_headers,
            requestBody: objContent.request_body,
            status: objContent.status,
            responseHeaders: objContent.response_headers,
            responseBody: objContent.response_body,
            timestamp: objContent.timestamp,
          })),
          nextToken: objResponse.body.next_token || undefined,
        }))
      );
  }

  syncExistingUnsyncedInvoices(arIds: string[], strModule: string, bSelectAllChecked: boolean) {
    return this.callApi$('post', 'sync/existing_unsynced_invoices', new URLSearchParams({
      ids: JSON.stringify(arIds),
      select_all_checked: bSelectAllChecked ? '1' : '0',
      module: strModule
    }));
  }
}

export interface FieldValidationError {
  /**
   * indexed by the field name and its corresponding errors as string
   *
   * @var {string[]}
   */
  [key: string]: string[];
}

export interface ProgressEvent {
  /**
   * Unique progress identifier that is given by the caller
   *
   * @var {any}
   */
  id: any;

  /**
   * Current progress
   *
   * @var {number}
   */
  progress: number;

  /**
   * Current progress total
   *
   * @var {null | number}
   */
  total: null | number;
}

export interface ImportResult {
  /**
   * Current import result flag if successful or not
   *
   * @var {boolean}
   */
  isSuccessful: boolean;

  /**
   * Error information when import error occured
   *
   * @var {string[]}
   */
  errors: string[];

  /**
   * Batch option information response
   *
   * @var {object}
   */
  batch: any;

  /**
   * Flag if the current import should skipped
   *
   * @var {boolean}
   */
  shouldSkip?: boolean;
}

export interface ImportOption {
  /**
   * The current progress cursor value
   *
   * @var {any}
   */
  cursor?: any;

  /**
   * A unique identifier that can be used to determine whether the current import progress belongs to the current caller
   *
   * @var {any}
   */
  progressId?: any;
}

interface AccountingSyncResponse {
  /**
   * Sync message response
   *
   * @type {string}
   */
  message: string;
}

export interface ScheduleSyncResult {
  /**
   * A flag that identifies whether the sync was successful or not
   *
   * @type {boolean}
   */
  isSuccessful: boolean;
  /**
   * List of errors received
   *
   * @type {string[]}
   */
  errors?: string[];
}


type AccountingDebugSyncResponse = AccountingSyncResponse & {
  /**
   * Audit log identity
   *
   * @type {string}
   */
  request_log_id: string;
};

export type DebugSyncResult = ScheduleSyncResult & {
  /**
   * @see AccountingSyncResponse['request_log_id']
   */
  requestLogId: string;
};

type AccountingDebugLogContentResponse = {
  /**
   * Request method
   *
   * @type {string}
   */
  method: string;
  /**
   * Request url
   *
   * @type {string}
   */
  url: string;
  /**
   * Request headers
   *
   * @type {string}
   */
  request_headers: string;
  /**
   * Request body
   *
   * @type {string}
   */
  request_body: string;
  /**
   * Response status with phrase and status code
   *
   * @type {string}
   */
  status: string;
  /**
   * Response headers
   *
   * @type {string}
   */
  response_headers: string;
  /**
   * Response body
   *
   * @type {string}
   */
  response_body: string;
  /**
   * Log timestamp
   *
   * @type {string}
   */
  timestamp: string;
}

type AccountingDebugLogsResponse = {
  /**
   * Log contents
   *
   * @type {AccountingDebugLogContentResponse[]}
   */
  contents: AccountingDebugLogContentResponse[];

  /**
   * Next token identifier for the next set of logs
   *
   * @type {string|undefined}
   */
  next_token?: string;
}

export type AccountingDebugLogContent = {
  /**
  * @see AccountingDebugLogContentResponse['method']
  */
  method: string;
  /**
  * @see AccountingDebugLogContentResponse['url']
  */
  url: string;
  /**
  * @see AccountingDebugLogContentResponse['request_headers']
  */
  requestHeaders: string;
  /**
  * @see AccountingDebugLogContentResponse['request_body']
  */
  requestBody: string;
  /**
  * @see AccountingDebugLogContentResponse['status']
  */
  status: string;
  /**
  * @see AccountingDebugLogContentResponse['response_headers']
  */
  responseHeaders: string;
  /**
  * @see AccountingDebugLogContentResponse['response_body']
  */
  responseBody: string;
  /**
   * @see AccountingDebugLogContentResponse['timestamp']
   */
  timestamp: string;
}

export type AccountingDebugLogResult = {
  /**
   * @type {AccountingDebugLogContent[]}
   */
  contents: AccountingDebugLogContent[];
  /**
   * @see AccountingDebugLogsResponse['next_token']
   */
  nextToken?: string;
}