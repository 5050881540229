
  <header>
    <div class="d-flex">
      <h3 class="title">
          <fa-icon [icon]="['fas', 'object-ungroup']" ></fa-icon>&nbsp;
          <span>{{ 'merge_record'| translate }} </span>
      </h3>
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <button id="btnMerge" (click)="mergeRecord()" class="btn btn-primary" [disabled]="bSubmitted">
              <fa-icon *ngIf="!bSubmitted" [icon]="['fas', 'save']"></fa-icon>
              <fa-icon *ngIf="bSubmitted" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              <span class="pl-1">{{ 'merge' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <fieldmagic-primary-button
              (click)="cancelDialog()"
              icon="times"
              variant="error"
              label="close">
            </fieldmagic-primary-button>
          </li>
        </ul>
      </div>
    </div>
</header>

<hr class="separator"/>

<mat-dialog-content class="mobile-view-dialog">
  <div *ngIf="fieldForm.length != 0" class="mt-3">
    <div class="tab-content row">
      <fieldmagic-alert *ngIf="error$ | async" variant="error" class="w-100 col-lg-12 col-sm-12 col-md-12">
        <strong>{{ "We weren't able to complete that action. The details below may help identify what went wrong." | translate }}</strong>
        <ul class="m-0"><li>{{ error$ | async }}</li></ul>
      </fieldmagic-alert>
      <div [ngClass]="{'col-6': fieldForm.length === 2, 'col-4': fieldForm.length === 3}" *ngFor="let item of fieldForm; let i = index">

        <div class="row primary-btn-margin">
          <button type="button" (click)="setPrimaryRecord(i)" [ngClass]="{'btn-primary': item['is_primary'], 'btn-secondary': !item['is_primary']}" class="btnSetPrimary row btn col-12 mb-3">
            <span class="pl-1">{{ 'set_as_primary_record' | translate }}</span>
          </button>
        </div>

        <form [formGroup]="item.groups">
              <ng-container *ngFor="let data of item.fields; let idx_field = index">
                <div class="row mb-3">

                  <div class="radio-per-field col-1">
                      <mat-radio-button *ngIf="data['key'] !== 'attributes-'+i" class="btnCopy" [checked]="item['radio_model'][data['key']]" [name]="removeAppendedIndex(data['key'])" (change)="copyToPrimary(item.groups, data['key'], data['controlType'], i)">
                      </mat-radio-button>
                  </div>
                  <div class="field-margin col-11">
                    <!--This is for normal field-->
                    <ng-container>
                      <app-edit-field *ngIf="data['controlType'] !== 'relate' && (data['key'] !== 'attributes-' + i) && !isAddressField(data['key'])" [item]="data" [form]="item.groups" [strMode]="'edit'" [strModule]="module" [strRecordId]="" [autoSelectResult]="true" [valueFromParent]="parentValue"></app-edit-field>
                    </ng-container>

                    <ng-container *ngIf="isAddressField(data['key'])">
                      <label translate>{{data['label']}}</label>
                      <app-address #address [addressForm]="item.address_group[data['key']]"></app-address>
                    </ng-container>
                    <!--This is for relate field-->
                    <ng-container *ngIf="data['controlType'] === 'relate' && relateFields">
                      <label> {{ relateFields[data['key']]['label'] | translate }}</label>
                      <ng-select
                        [items]="relateFields[data['key']]['obv'] | async"
                        [typeahead]="relateFields[data['key']]['typehead']"
                        [loading]="relateFields[data['key']]['loader']"
                        [clearable]="true"
                        [ngModelOptions]="{standalone: true}"
                        bindLabel="text"
                        [bindValue]
                        (open)="triggerSubject(relateFields[data['key']]['typehead'])"
                        [(ngModel)]="relateFields[data['key']]['value']"
                        (ngModelChange)="relateFieldModelChange($event, data['key'], i)"
                        [id]="relateFields[data['key']]['name']"
                        [name]="relateFields[data['key']]['name']"
                        class="selectRelate font-size-14">
                          <!-- DISPLAY IN SELECTED -->
                          <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-label">{{ item.text | translate }}</span>
                          </ng-template>
                          <!-- DISPLAY IN OPTION -->
                          <ng-template  ng-option-tmp let-item="item">
                            <span class="value-text"> {{ item.text | translate }}</span>
                          </ng-template>
                      </ng-select>
                    </ng-container>

                    <!--This is for attribute field of assets-->
                    <ng-container *ngIf="data['key'] === 'attributes-'+i && data['controlType'] === 'json' && strModule === 'assets'">
                      <label> {{ data['label'] | translate }}</label>

                      <div class="row">
                          <ng-container>
                            <div *ngFor="let assetValue of item.groups.controls[data['key']].value;let attrIndex = index" class="col-md-6 col-xs-6 mb-3">
                                <mat-radio-button class="btnCopy" [name]="assetValue.key"  (change)="copyToPrimary(item.groups, data['key'], data['controlType'], i, attrIndex)">
                                </mat-radio-button>

                                <label *ngIf="assetValue.label" class="labels" translate>{{ assetValue.label }}</label>

                                <input *ngIf="assetValue.type == 'text'"
                                  type="text"
                                  class="form-control font-size-11"
                                  [(ngModel)]="assetValue.default_value"
                                  [ngModelOptions]="{standalone: true}"
                                  [required]="assetValue.required"
                                  [ngClass]="{'is-invalid': assetValue.required && assetValue.default_value == '' && isTouched}"
                                >

                                <input *ngIf="assetValue.type == 'number'"
                                  type="number"
                                  class="form-control font-size-11"
                                  onPaste="return false"
                                  [(ngModel)]="assetValue.default_value"
                                  (keypress)="validateInputNumber($event)"
                                  [ngModelOptions]="{standalone: true}"
                                  [required]="assetValue.required"
                                  [ngClass]="{'is-invalid': assetValue.required && assetValue.default_value == '' && isTouched}"
                                >

                                <ng-select *ngIf="assetValue.type == 'dropdown'"
                                  [items]="assetValue.option"
                                  [ngModelOptions]="{standalone: true}"
                                  [(ngModel)]="assetValue.default_value"
                                  [clearable]="true"
                                  [required]="assetValue.required"
                                  bindLabel="text"
                                  bindValue="id"
                                  [ngClass]="{'is-invalid': assetValue.required && assetValue.default_value == '' && isTouched}"
                                  class="asset_dropdown"
                                >
                                  <ng-template ng-option-tmp let-item="item" class="font-size-11">
                                    {{ item.text | translate }}
                                  </ng-template>

                                  <ng-template ng-label-tmp let-item="item" class="font-size-11">
                                    {{ item.text | translate }}
                                  </ng-template>
                                </ng-select>

                                <div *ngIf="assetValue.type == 'date'">
                                    <div class="input-group">
                                      <input
                                        [(ngModel)]="assetValue.default_value"
                                        [owlDateTime]="dateTimeField"
                                        [ngModelOptions]="{standalone: true}"
                                        type="text"
                                        class="form-control date-input"
                                        [required]="assetValue.required"
                                        [ngClass]="{'is-invalid': assetValue.required && assetValue.default_value == '' && isTouched}"
                                        >
                                      <button class="calendar-button" [owlDateTimeTrigger]="dateTimeField">
                                        <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
                                      </button>
                                    </div>

                                    <div>
                                      <owl-date-time  #dateTimeField [pickerType]="'calendar'"></owl-date-time>
                                    </div>
                                </div>
                            </div>
                          </ng-container>


                          <div class="font-size-11 col-md-12 col-xs-12" *ngIf="item.groups.controls[data['key']].value.length < 1 && !data['is_loading']">
                            <div> {{ 'no_available_attributes' | translate}}</div>
                          </div>
                          <div *ngIf="data['is_loading']" class="col-md-12 col-xs-12">
                            <div> <fa-icon [icon]="['fas', 'spinner']" class="spinner" [spin]="true"></fa-icon></div>
                          </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
        </form>
      </div>
    </div>
  </div>
  <div align="center">
    <fa-icon *ngIf="fieldForm.length == 0" [icon]="['fas', 'spinner']" [spin]="true" class="loading-spinner" aria-hidden="true"></fa-icon>
  </div>
</mat-dialog-content>
