<ng-container *fcHasPermission="'contacts:create'; else createContactDisabled">
  <span *ngIf="bRelatedContactsLoading" class="d-flex justify-content-center pb-3">
    <inline-view-loader [isLoading]="true" [hasText]="false"></inline-view-loader>
  </span>

  <div *ngIf="displayRelatedContacts && !bRelatedContactsLoading" class="container-fluid">
    <div class="row mb-4">
      <div class="col-12 d-block">
        <div class="pt-3 border">
          <div class="row pl-3 pr-3">
            <div class="col-8">
              <p class="d-flex align-items-center">
                <fa-icon [icon]="['fas', 'info-circle']" class="mr-2 text-primary fa-lg"></fa-icon>
                <span class="fs-13 font-italic">
                  {{ 'wizard_customer_has_related_contacts' | translate }}
                </span>
              </p>
            </div>

            <div class="col-4">
              <button id="addNewContactBtn" type="button" class="font-size-12 d-flex justify-content-center gap-10 btn btn-outline-primary btn-block btn-lg p-2"
                (click)="loadContactForm()">
                <strong>{{ 'add_new_contact' | translate }}</strong>
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div>
                <table class="table border-bottom">
                  <thead>
                    <tr>
                      <th width="33%" class="font-size-12 text-center">{{ 'contact_text' | translate }}</th>
                      <th width="33%" class="font-size-12 text-center">{{ 'customer_roles' | translate }}</th>
                      <th width="33%" class="font-size-12 text-center">{{ 'site_roles' | translate }}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <ng-container *ngIf='!bRelatedContactsLoading; else relatedContactsLoading'>
                      <tr *ngFor="let contact of arRelatedContactsData['data']">
                        <td>{{ contact['text'] }}</td>

                        <td>
                          <ng-container *ngIf="contact['customer_roles'].length > 0; else noAssignedRole">
                            <div *ngFor="let item of contact['customer_roles'];">
                              <span *ngFor="let role of item['role']; index as i">
                                <fa-icon
                                  *ngIf="role['primary']"
                                  [icon]="['fas', 'check-circle']"
                                  class="text-success pointer"
                                  matTooltip="{{ 'primary_role' | translate }}">
                                </fa-icon>
                                {{ role['text'] | translate }}{{ (i !== item['role'].length - 1) ? ',' : ''}}
                              </span>
                            </div>
                          </ng-container>
                        </td>

                        <td>
                          <ng-container *ngIf="contact['site_roles'].length > 0; else noAssignedRole">
                            <div *ngFor="let item of contact['site_roles'];">
                              <span *ngFor="let role of item['role']; index as i">
                                <fa-icon
                                  *ngIf="role['primary']"
                                  [icon]="['fas', 'check-circle']"
                                  class="text-success pointer"
                                  matTooltip="{{ 'primary_role' | translate }}">
                                </fa-icon>
                                {{ role['text'] | translate }}{{ (i !== item['role'].length - 1) ? ',' : ''}}
                              </span>
                            </div>
                          </ng-container>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row pl-3 pr-3">
            <div *ngIf="arRelatedContactsData['data'].length > 0"  class="col d-flex justify-content-between">
              <span>
                <small *ngIf="!bRelatedContactsLoading" class="small-font">
                  {{ 'list_view_summary' | translate:{fromRecords: arRelatedContactsData.from, toRecords:
                  arRelatedContactsData.to, totalRecords: arRelatedContactsData.total} }}
                </small>
              </span>

              <div class="pagination-section">
                <ul class="pagination float-right">
                  <li class="page-item" [ngClass]="arRelatedContactsData['current_page'] === 1 || bRelatedContactsLoading ? 'disabled' : ''">
                    <a id="wizardCustomerStepExistingSitesPageOne" class="page-link" aria-label="first" (click)="loadRelatedContacts(1)">
                      <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <li class="page-item" [ngClass]="arRelatedContactsData['current_page'] === 1 || bRelatedContactsLoading ? 'disabled' : ''">
                    <a id="wizardCustomerStepExistingSitesPreviousPage" class="page-link" aria-label="previous" (click)="loadRelatedContacts(arRelatedContactsData['current_page'] - 1)">
                      <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <li class="page-item" [ngClass]="(arRelatedContactsData['current_page'] === arRelatedContactsData['last_page']) || bRelatedContactsLoading ? 'disabled' : ''">
                    <a id="wizardCustomerStepExistingSitesNextPage" class="page-link" aria-label="next" (click)="loadRelatedContacts(arRelatedContactsData['current_page'] + 1)">
                      <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                  <li class="page-item" [ngClass]="(arRelatedContactsData['current_page'] === arRelatedContactsData['last_page']) || bRelatedContactsLoading ? 'disabled' : ''">
                    <a id="wizardCustomerStepExistingSitesLastPage" class="page-link" aria-label="last" (click)="loadRelatedContacts(arRelatedContactsData['last_page'])">
                      <fa-icon [icon]="['far', 'chevron-double-right']" aria-hidden="true"></fa-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form *ngIf="displayContactForm && !bRelatedContactsLoading" [formGroup]="form">
    <div class="container-fluid">

      <div class="row">
        <div class="col-xs-6 col-md-6 form-group">
          <strong id="contactStepTitle">{{ 'create_or_search' | translate }} {{ 'contact' | translate }}</strong>
          <br />
          <small>{{ 'fill_in_contact_details' | translate }}</small>
        </div>
        <div class="col-xs-6 col-md-6 form-group">
          <a href="javascript:void(0)" id="btnSkipContact" class="text-primary float-right" (click)="skip()">
            <fa-icon [icon]="['fas', 'forward']"></fa-icon>&nbsp;{{ 'skip' | translate }}
          </a>
          <a *ngIf="objSelectedContact" id="btnResetContact" href="javascript:void(0)" class="text-danger float-right mr-3" (click)="clearForm()">
            <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>&nbsp;{{ 'reset' | translate }}
          </a>
        </div>
      </div>

      <div class="row">

        <div class="col-6 mb-3">
          <div>
            <label class="labels" for="first_name"> {{ 'first_name' | translate }}
              <required-tag [hasSpace]="false"></required-tag>
            </label>
          </div>

          <div class="contact-input">
            <input id="contactStepFirstName"
              formControlName="first_name"
              type="text"
              class="form-control font-size-11 input-height-40 input-padding"
              [ngClass]="{'is-invalid': form.controls['first_name'].invalid && form.dirty}"
              [matAutocomplete]="firstNameSearch"
              [matAutocompleteDisabled]="!firstNameSearch"/>
            <fa-icon [icon]="['fal', 'search']" class="contact-search-icon"></fa-icon>
            <fa-icon *ngIf="objContactSearch.loader"
              [icon]="['fal', 'spinner-third']"
              [spin]="true"
              class="contact-input-icon">
            </fa-icon>
          </div>
          <mat-autocomplete (onScrollEndEvent)="onEndScroll()" #firstNameSearch="matAutocomplete">
            <ng-container *ngIf="(objContactSearch.source | async) as searchResults">
              <mat-option *ngFor="let results of searchResults" class="search-class" [value]="results.name"
                (onSelectionChange)="prefillContactForm(results)">
                <div class="row">
                  <div class="col-12 result-name">{{
                    results.name }}</div>
                  <div class="col-6 result-module">{{ results.module | translate }}</div>
                  <div class="col-6 result-module"><span class="float-right">{{ 'match_score' | translate }}: {{
                      results.match_percentage | number:'1.0-0' }}%</span></div>
                </div>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>

        <div class="col-6 mb-3">
          <div>
            <label class="labels" for="last_name"> {{ 'last_name' | translate }}
              <required-tag [hasSpace]="false"></required-tag>
            </label>
          </div>
          <div class="contact-input">
            <input id="contactStepLastName"
              formControlName="last_name"
              type="text"
              class="form-control font-size-11 input-height-40"
              [ngClass]="{'is-invalid': form.controls['last_name'].invalid && form.dirty}" />
          </div>
        </div>

        <div *ngFor="let data of arFields" class="col-{{data.space}} mb-3">
          <ng-container
            *ngIf="(
              data.label != 'created_by' &&
              data.label != 'modified_by' &&
              data.label != 'updated_at' &&
              data.label != 'created_at'
            )">
            <app-edit-field [item]="data" [form]="form" [bSubmitted]="bSubmitted" [strMode]="strMode"
              [strModule]="strModule"></app-edit-field>
          </ng-container>
        </div>

        <!-- Special container for phone -->
        <div class="col-{{objPhoneField.space}} mb-3">
          <ng-container>
            <app-phone [form]="form" [value]="objPhoneFieldValue" [key]="objPhoneField.key" [label]="objPhoneField.label">
            </app-phone>
          </ng-container>
        </div>

      </div>

      <!-- Linked Customers -->
      <div class="row mt-2 mb-4" *ngIf="form.valid">
        <div class="col-xs-12 col-md-12  d-block">
          <div class="border">
            <div *ngIf="bIsLinked" class="row p-3">
              <div class="col-xs-12 col-md-12">
                <small>
                  <fa-icon [icon]="['fas', 'check-circle']" class="text-success mr-2"></fa-icon>
                  <i>{{ 'contact_linked' | translate }}</i>
                </small>
              </div>
            </div>
            <div *ngIf="!bIsLinked" class="row p-3">
              <div class="col-xs-12 col-md-12 mb-3">
                <small>
                  <fa-icon [icon]="['fas', 'times-circle']" class="text-danger mr-2"></fa-icon>
                  <i>{{ 'contact_link_to_customer' | translate }}</i>
                </small>
              </div>
              <div class="col-xs-12 col-md-12">
                <div class="row">
                  <div class="col-xs-12 col-md-12">
                    <ng-select [items]="arRoles"
                      formControlName='roles'
                      placeholder="{{ 'select_roles' | translate }}"
                      bindLabel="text"
                      [multiple]="true"
                      appendTo="body"
                      (change)="onRoleChange($event)">

                      <ng-template ng-label-tmp let-item="item" let-clear="clear" class="fs-12">
                        <span id="setAsPrimaryRole_{{ item.id }}{{ i }}" class="pl-1" [ngClass]="{'pointer': item.primary != true}" (click)="setPrimaryRole(item.id, objForm)" aria-hidden="true">{{ item.text | translate }}</span>
                        <span *ngIf="item.primary == true" id="primaryRole{{ i }}" class="ml-1 badge badge-success">{{ 'primary' | translate }}</span>
                        <span id="removeRole_{{ item.id }}{{ i }}" class="pl-1 pr-1 pointer" (click)="clear(item)" aria-hidden="true">×</span>
                      </ng-template>

                      <ng-template ng-option-tmp let-item="item" class="pointer">{{ item.text | translate }}</ng-template>

                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>

  <!-- Stepper Next -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-md-12">
        <button id="contactStepNext" type="button" class="btn btn-primary btn-block btn-lg p-2" (click)="next()"
          [disabled]="!form.valid && !displayRelatedContacts">
          <strong>{{ 'next' | translate }}</strong>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #createContactDisabled>
  <span id="widgetContactsCreateButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
  </span>
</ng-template>

<ng-template #relatedContactsLoading>
  <tr>
    <td colspan="3" class="text-center">
      <inline-view-loader [isLoading]="true"></inline-view-loader>
    </td>
  </tr>
</ng-template>

<ng-template #noAssignedRole>
  <span>{{ 'no_role_assigned' | translate }}</span>
</ng-template>