import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup } from '@angular/forms';
import * as _moment from 'moment';

import { CalendarService } from '../../services/calendar.service';
import { CalendarApiService } from '../../services/calendar-api.service';

import { NotificationService } from '../../../../services/notification.service';
import { FormService } from '../../../../services/form.service';
import { finalize } from 'rxjs/operators';
import { first, isEmpty } from 'lodash-es';
import { TaskMetadata } from '../../full-calendar/classes/metadata/task-metadata';
import { LooseObject } from '../../../../objects/loose-object';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-task-schedule-dialog',
  templateUrl: './task-schedule-dialog.component.html',
  styleUrls: ['./task-schedule-dialog.component.scss'],
  providers: [
    CalendarApiService
  ]
})
export class TaskScheduleDialogComponent implements OnInit {
  /**
   * Store's the task details.
   *
   * @type {LooseObject}
   */
  public taskMetadata: LooseObject = {};

  /**
   * Stores the record details.
   *
   * @type {LooseObject}
   */
  public recordDetails: LooseObject;

  /**
   * Stores the record's tasks.
   *
   * @type {LooseObject}
   */
  public tasks: LooseObject;

  /**
   * Task schedule form group.
   *
   * @type {FormGroup}
   */
  public taskScheduleForm: FormGroup;

  /**
   * Loading flag.
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * The name of the selected technician.
   *
   * @type {string}
   */
  private strTechnicianName: string;

  public strMode: string;
  bSubmitted = false;
  bSaving = false;

  fields: any[] = [
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "estimated_duration",
      "label": "estimated_duration",
      "controlType": "number",
      "space": 6,
      "min_length": 0,
      "is_hidden": false,
      "has_primary": false
    },
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "due_date",
      "label": "due_date",
      "controlType": "datetime",
      "space": 12,
      "has_primary": false
    },
  ];

  arAssignFields = {
    users: {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "user_id",
      "key_text": "user_text",
      "label": "technician",
      "controlType": "relate",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "users",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false,
      "assigned_type": "user"
    },
    teams: {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "team_id",
      "key_text": "team_text",
      "label": "team",
      "controlType": "relate",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "teams",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false,
      "assigned_type": "team"
    },
  }

  /**
   * Contains the client id of the authorized client
   */
  readonly onBehalfOfClientId: string = this.data.on_behalf_of_client;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    protected calendarService: CalendarService,
    protected calendarServiceApi: CalendarApiService,
    protected notificationService: NotificationService,
    protected formService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
    protected dialogRef: MatDialogRef<TaskScheduleDialogComponent>
  ) {
    this.taskMetadata = this.calendarService.getTaskMetadata(this.data.fcEvent);
    this.recordDetails = this.data.fcEvent.record_details;
    this.tasks = this.data.fcEvent.tasks;
    this.strTechnicianName = this.taskMetadata.user_text;
  }

  ngOnInit() {
    if (this.taskMetadata['task_progress'] === 'awaiting_scheduling' || this.taskMetadata['task_progress'] === 'cancelled') {
      this.strMode = 'schedule';
    } else {
      this.strMode = 'reschedule';
    }

    this.fields.splice(1, 0, this.arAssignFields[this.data.view]);

    const estimatedDurationIndex = this.fields.findIndex(field => field.key === 'estimated_duration');
    this.fields[estimatedDurationIndex].default_value = this.taskMetadata['estimated_duration'];

    const dueDateIndex = this.fields.findIndex(field => field.key === 'due_date');
    this.fields[dueDateIndex].default_value = this.taskMetadata['due_date'];

    const techinicianIdIndex = this.fields.findIndex(field => field.key === this.arAssignFields[this.data.view].key);
    this.fields[techinicianIdIndex].options = [
      {
        "id": this.taskMetadata[this.arAssignFields[this.data.view].key],
        "text": this.taskMetadata[this.arAssignFields[this.data.view].key_text],
        "disabled": false
      }
    ];

    this.taskScheduleForm = this.formService.toFormGroup(this.fields);
    this.taskScheduleForm.patchValue(this.taskMetadata);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Schedule the task
   *
   * @returns {void}
   */
  scheduleTask(): void {
    this.taskScheduleForm.markAsDirty();
    this.taskScheduleForm.markAsTouched();
    this.bSubmitted = true;
    let schedule$;

    if (this.taskScheduleForm.invalid) {
      this.notificationService.notifyError('please_complete_the_form');
    } else {
      this.dialogRef.disableClose = true;
      this.bSaving = true;
      let objScheduleData = this.taskScheduleForm.value;

      objScheduleData.due_date = moment.utc(objScheduleData.due_date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
      objScheduleData.estimated_duration = (objScheduleData.estimated_duration == 0) ? 1 : objScheduleData.estimated_duration;

      schedule$ = this.calendarServiceApi.scheduleTask(
        [
          {
            id: this.taskMetadata['id'],
            parent_id: this.taskMetadata['job_id'],
          },
        ],
        {
          ... (this.onBehalfOfClientId && {
            for_child_client_id: this.onBehalfOfClientId,
          }),
          assigned_id: objScheduleData[this.arAssignFields[this.data.view].key],
          assigned_type: this.arAssignFields[this.data.view].assigned_type,
          due_date: objScheduleData.due_date,
          duration: objScheduleData.estimated_duration,
          module: 'task',
        }
      );

      schedule$
        .pipe(
          finalize(() => this.bSaving = false)
        ).
        subscribe(tasks => {
          // if we have this task scheduled to other client
          // the scheduleTask method returns the list of the tasks that was scheduled
          // for each tasks it contains the necessary metadata as what we have in the scheduled
          // calendar events. since this dialog is meant to schedule as single task
          // we would extract the first value in the list
          const task = first(tasks);

          // if the given task replaces the original according to the api
          // we would use that replaced task metadata as a data to be
          // provided to the dialog event after closed
          const data = (! task['was_replaced'])
            ? this.getReturnData(objScheduleData.due_date, objScheduleData.estimated_duration)
            : task;

          this.notificationService.notifySuccess(this.strMode === 'reschedule' ? "task_rescheduled" : 'task_scheduled');
          this.closeDialog({
            action: this.strMode,
            data,
            user: { id: objScheduleData[this.arAssignFields[this.data.view].key] }
          });
        }, (error: HttpErrorResponse) => {
          this.notificationService.notifyWarning('task_schedule_error');
        });
    }
  }

  /**
   * Sets the name of the selected technician.
   *
   * @param {string} strName
   *
   * @returns {void}
   */
  setTechnicianText(strName: string): void {
    this.strTechnicianName = strName;
  }

  /**
   * Returns a formatted object of the scheduled task for updating
   * of the calendar and the tasks for scheduling list.
   *
   * @param strDueDate
   * @param numEstimatedDuration
   *
   * @returns {TaskMetadataC}
   */
  getReturnData(strDueDate: string, numEstimatedDuration: number): TaskMetadata {
    return {
      id: this.taskMetadata['id'],
      name: this.taskMetadata['activity_name'],
      status: "scheduled",
      due_date: strDueDate,
      priority: this.taskMetadata['priority'],
      estimated_duration: numEstimatedDuration,
      description: this.taskMetadata['notes'],
      job: this.data.fcEvent.module === 'jobs'
        ? this.calendarService.getTaskJobDetails(this.taskMetadata, this.recordDetails, this.data.fcEvent.tasks)
        : null,
      opportunity: this.data.fcEvent.module === 'opportunities'
        ? this.calendarService.getTaskOpportunityDetails(this.taskMetadata, this.recordDetails, this.data.fcEvent.tasks)
        : null,
      department: {
        id: this.taskMetadata['department_id'],
        name: this.taskMetadata['department_name'],
      },
      assigned_user: {
        id: this.taskScheduleForm.value[this.arAssignFields[this.data.view].key],
        name: this.strTechnicianName
      },
      viewable: true,
      reschedulable: true,
      parent_id: this.taskMetadata['parent_id'],
      activity_date: strDueDate,
    }
  }

  /**
   * Close the dialog
   *
   * @returns {void}
   */
  closeDialog(task = null): void {
    this.dialogRef.close(task);
  }
}
