<app-error-handler onlyFor="assets:list" alignment="center" displayAs="widget">
    <div class="d-flex justify-content-around activities-option tabs-content">
    <div *ngIf="strModule == 'jobs'" [ngClass]="{ 'col-sm-4' : strModule == 'jobs' }" class="text-center">
      <span id="widgetAssetsSelectButton" class="pointer" (click)="selectAssets()" class="span-links">
        <fa-icon [icon]="['fas', 'wrench']" class="text-success"></fa-icon>
        {{ strCreateLabel | translate }}
      </span>
      <ng-template #createAssetDisabled>
        <span id="widgetAssetsSelectButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
            <fa-icon [icon]="['fas', 'wrench']" class="text-default"></fa-icon>
            {{ strCreateLabel | translate }}
        </span>
      </ng-template>
    </div>
    <div [ngClass]="strModule == 'sites' ? 'col-sm-12' : 'col-sm-4'" class="text-center">
      <ng-container *fcHasPermission="'assets:create'; else createAssetAndLinkDisabled">
        <span id="widgetAssetsCreateLinkButton" class="pointer" (click)="createAndLinkAsset()" class="span-links">
          <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
          {{ 'create_and_link_asset' | translate }}
        </span>
      </ng-container>
      <ng-template #createAssetAndLinkDisabled>
        <span id="widgetAssetsCreateLinkButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-default"></fa-icon>
            {{ 'create_and_link_asset' | translate }}
        </span>
      </ng-template>
    </div>
    <div *ngIf="strModule == 'jobs'" [ngClass]="{ 'col-sm-4' : strModule == 'jobs' }" class="text-center">
      <span id="widgetAssetsCreateQuoteVersionButton" class="pointer" (click)="(bProcessed) ? convertToQuote() : ''" class="span-links">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        {{ 'create_opportunity' | translate }}
      </span>
    </div>
  </div>

    <div class="assets-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pt-4 pb-4">
          <div class="form-inline">
            <div class="form-group flex-nowrap w-100">
              <div class="col-lg-4">
                <label class="jc-l">{{ strFilterLabel | translate }}</label>
              </div>
              <div *ngIf="objAssetForm" class="col-lg-8">
                <form [formGroup]="objAssetForm" (change)="getRecord()">
                  <app-edit-field [item]="objAssetField[strFilterId]" [form]="objAssetForm" [strMode]="add" [bSubmitted]="false" [strCustomClass]="'w-100'"
                    id="widgetAssetsSerialNumberFilter"></app-edit-field>
                </form>
              </div>
            </div>
          </div>

          <div *ngIf="objOption['status']" class="form-inline mt-12">
            <div class="form-group flex-nowrap w-100">
              <div class="col-lg-4">
                <label class="jc-l">{{ 'status' | translate }}</label>
              </div>
              <div class="col-lg-8">
                <ng-select
                [items]="objOption.status.config"
                class="value-text"
                (change)="getRecord()"
                [(ngModel)]="strFilterStatus"
                id="widgetAssetsStatusFilter">
                  <ng-template ng-label-tmp let-item="item">{{ item.text | translate }}</ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <span id="widgetAssetsStatusFilter{{ item.id }}">{{ item.text | translate }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>

          <div class="form-inline mt-12">
            <div class="form-group flex-nowrap w-100">
                <div class="col-lg-4">
                  <label class="jc-l">{{ 'sort_assets' | translate }}</label>
                </div>
                <div class="col-lg-8">
                  <ng-select
                    *ngIf="objSortField"
                    class="value-text"
                    (change)="getRecord()"
                    [(ngModel)]="strSortAsset">
                    <ng-option *ngFor="let field of objSortField | keyvalue" [value]="field.key">
                      {{ field.key | translate }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div class="row border-top pt-2 pb-2">
        <div class="col-sm-6 pt-1">
          <span class="module-title">
            <fa-icon [icon]="['fa', 'wrench']" aria-hidden="true"></fa-icon>
            &nbsp;
            {{ 'linked_assets' | translate }}
          </span>
        </div>
        <div class="col-sm-6 pr-2">
          <nav class="pagination-section">
            <ul class="pagination">
              <refresh-pagination-btn id="widgetAssetsRefreshButton" (refresh)="onRefresh()" [isDisabled]="! bLoader"></refresh-pagination-btn>
              <li [ngClass]="(listingService.strFirstValue != listingService.strCurrentValue && listingService.strCurrentValue != '') ? 'active pointer' : 'disabled'" class="page-item">
              <a id="widgetAssetsPreviousButton" class="page-link" aria-label="prev" (click)="getRecord('prev')">
                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
              </a>
              </li>
              <li [ngClass]="(listingService.strNextValue != '') ? 'active pointer' : 'disabled'" class="page-item">
              <a id="widgetAssetsNextButton" class="page-link"  aria-label="next" (click)="getRecord('next')">
                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
              </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div *ngIf="arAssetList.length < 1">
        <div class="row border-top" *ngIf="bLoader">
          <span class="no-content">
            {{ 'no_assets_found' | translate }}
          </span>
        </div>
        <div class="row border-top" *ngIf="!bLoader">
          <span class="no-content">
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            {{ 'loading' | translate }}
          </span>
        </div>
      </div>

      <div class="row border-top pt-2 pb-2 pr-2"  *ngFor="let asset of arAssetList" [id]="'widgetAssetsRecord' + asset.id">

        <div class="col-sm-1 circle-size">
          <fa-icon matTooltip="{{ asset.status | translate }}" [icon]="['fas', 'circle']" [ngClass]="[(objStatusClass[asset.status]) ? objStatusClass[asset.status] : '']"></fa-icon>
        </div>

        <div class="col-sm-4">
          <ng-template #assetLabel>
            <ng-container *ngIf="(asset.name | filled) && (asset.serial_number | filled) && asset.name != asset.serial_number">
                {{ asset.name }} (#{{ asset.serial_number }})
            </ng-container>
            <ng-container *ngIf="((asset.name | blank) && (asset.serial_number | filled)) || asset.name == asset.serial_number">
              #{{ asset.serial_number }}
            </ng-container>
            <ng-container *ngIf="(asset.name | blank) && (asset.serial_number | blank)">
              --
            </ng-container>
          </ng-template>
          <ng-container *ngIf="asset.is_owned_by_current_client; else noLinkAssetsLabel">
            <a id="widgetAssetsLabel" *ngIf="strModule == 'jobs'" class='word-wrap' class="pointer" [routerLink]="['/assets/' + asset.asset_id]">
              <ng-container *ngTemplateOutlet="assetLabel"></ng-container>
            </a>
            <a id="widgetAssetsLabel" *ngIf="strModule == 'sites'" class='word-wrap' class="pointer" [routerLink]="['/assets/' + asset.id]">
              <ng-container *ngTemplateOutlet="assetLabel"></ng-container>
            </a>
            <br/>
          </ng-container>
          <ng-template #noLinkAssetsLabel>
            <span class="word-wrap" id="widgetAssetsLabel">
              {{ (asset.serial_number) ? '#' + asset.serial_number : '--' }}
            </span>
            <br/>
          </ng-template>

          <span class='word-wrap'>{{ (asset.asset_type_text) ? asset.asset_type_text : '--' }}</span> <br/>
          <span class='word-wrap' matTooltip="{{ 'walk_order' | translate }}">{{ (asset.walk_order) ? (asset.walk_order | format_number) : '--' }}</span>
        </div>

        <div class="col-sm-6 truncate" matTooltip="{{ (asset.notes && asset.notes.length >= 85) ? asset.notes : '' }}">
          {{ (asset.notes) ? asset.notes : '--' }}
        </div>

        <div class="col-sm-1 d-flex flex-column align-items-end pl-0">
          <div
            *ngIf="asset.editable || asset.deletable"
            fieldmagicGridRow
            class="d-flex"
          >
            <div
              *ngIf="asset.editable"
              fieldmagicGridColumn="6"
              class="p-0"
            >
              <fieldmagic-primary-button
                [denied]="'assets:edit' | has_denied_permission | async"
                purpose="icon"
                [icon]="[{
                  icon: 'pencil',
                  klass: 'text-success'
                }]"
                (click)="(asset.asset_id == undefined) ? saveAsset(asset.id) : saveAsset(asset.asset_id)"
              ></fieldmagic-primary-button>
            </div>

            <div
              *ngIf="asset.deletable"
              fieldmagicGridColumn="6"
              class="p-0"
            >
              <fieldmagic-primary-button
                [denied]="'assets:delete' | has_denied_permission | async"
                purpose="delete-icon"
                (click)="deleteRecord(asset.id)"
              ></fieldmagic-primary-button>
            </div>
          </div>

          <div
            *ngIf="strModule === 'jobs'"
            fieldmagicGridRow
            class="d-flex"
          >
            <div
              fieldmagicGridColumn="6"
              class="p-0"
            >
              <checklist-render-prompts-button
                [options]="{
                  job_id: asset.job_id,
                  asset_id: asset.asset_id,
                  asset_type_id: asset.asset_type_id,
                  module_name: 'jobs',
                  questions_name: 'per_asset_prompts',
                  checklist_response_id: asset.checklist_response_id,
                  asset_job_id: asset.id
                }"
                (complete)="onPromptsComplete()"
              ></checklist-render-prompts-button>
            </div>

            <div
              fieldmagicGridColumn="6"
              class="p-0"
            >
              <fieldmagic-primary-button
                purpose="icon"
                tooltip="View Inspection Photos"
                [icon]="[{
                  icon: 'file-image',
                  klass: 'text-danger'
                }]"
                (click)="openAssetJobPhotosDialog(asset)"
              ></fieldmagic-primary-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-error-handler>