import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper, MatCheckboxChange } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { delay, shareReplay, switchMap, take, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Address } from '../../../../../objects/address';
import { ListingService } from '../../../../../services/listing.service';
import { NotificationService } from '../../../../../services/notification.service';
import { WizardCustomerData } from '../../../objects/wizard';
import { Relate } from '../../../../../objects/relate';
import { SearchService } from '../../../../../services/search.service';
import { RecordService } from '../../../../../services/record.service';
import { GlobalRecord } from '../../../../../objects/global-record';
import { PlacesService } from '../../../../../shared/components/address/places.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { Client } from '../../../../../objects/client';
import { AddressComponent } from '../../../../../shared/components/address/address.component';
import { SharedService } from '../../../services/shared.service';
import { LooseObject } from '../../../../../objects/loose-object';
import { get,  } from 'lodash-es';
import { AddressService } from '../../../../../services/address.service';
import { filled, blank, isId } from '../../../../../shared/utils/common';
import { Country } from '../../../../../lists/country';

@Component({
  selector: 'customer-step',
  templateUrl: './customer.component.html',
  styleUrls: [
    './customer.component.scss',
    '../../../styles/shared_wizard_styles.scss',
  ],
  providers: [PlacesService]
})
export class CustomerComponent implements OnInit, OnDestroy {

  /**
   * The element of the org name to be used for Google places.
   *
   * @var {ElementRef}
   */
  @ViewChild('orgName') orgNameElementRef: ElementRef;

  /**
   * The stepper this component is under.
   *
   * @var {MatStepper}
   */
  @Input() stepper: MatStepper;

  /**
   * The ID of the preselected customer.
   *
   * @var {string}
   */
  @Input() preselectedCustomer: string;

  /**
   * The ID of the preselected site.
   *
   * @var {string}
   */
  @Input() preselectedSite: string;

  /**
   * The type of the record being created.
   *
   * @var {string}
   */
  @Input() strRecordType: string;

  /**
   * Flag that shows if the wizard was opened from the mega menu.
   *
   * @var {boolean}
   */
  @Input() bOpenedFromMegaMenu: boolean = false;

  /**
   * Flag to identify if we will skip this step when we have a preselected
   * customer and site.
   *
   * @var {boolean}
   */
  @Input() skipWhenPreselected: boolean;

  /**
   * The data of the customer to be sent to the parent.
   *
   * @var {EventEmitter<WizardCustomerData>}
   */
  @Output() objCustomerData = new EventEmitter<WizardCustomerData>();

  /**
   * Only visible in create job wizard. Emits the latest value of whether the job has a customer or not.
   *
   * @var {EventEmitter<boolean>}
   */
  @Output() changeStepToLocation = new EventEmitter<boolean>();

  /**
   * Only visible in create job wizard. Emits the latest value of whether the job location is a custom address.
   *
   * @var {EventEmitter<boolean>}
   */
  @Output() customLocationEmitter = new EventEmitter<boolean>();

  /**
   * Emits true if step is skipped.
   *
   * @var {EventEmitter<boolean>}
   */
  @Output() skipStepEmitter = new EventEmitter<boolean>();

  /**
   * The child address component.
   *
   * @var {AddressComponent}
   */
  @ViewChild(AddressComponent) private addressComponent: AddressComponent;

  /**
   * The form group of the customer.
   *
   * @var {FormGroup}
   */
  form: FormGroup = new FormGroup({
    'customer_id': new FormControl(null),
    'first_name': new FormControl(null, Validators.required),
    'last_name': new FormControl(null, Validators.required),
    'name': new FormControl({value: null, disabled: true}, Validators.required),
    'type': new FormControl('individual', Validators.required),
    'site_id': new FormControl(null),
    'address': new FormGroup({}),
    'existing_site_address_text': new FormControl(null)
  });

  /**
   * If the form has been submitted.
   *
   * @var {boolean}
   */
  bSubmitted: boolean = false;

  /**
   * If the current customer's address is already
   * an existing site.
   *
   * @var {boolean}
   */
  bHasExistingSite: boolean = false;

  /**
   * If the current customer is an organization.
   *
   * @var {boolean}
   */
  bIsOrganization: boolean = false;
  /**
   * If the site selected is disabled
   *
   * @var {boolean}
   */
  bIsDisabledMaintenance: boolean = false;
  /**
   * Site search observable.
   *
   * @var {Relate<any>}
   */
  objSiteSearch: Observable<any> = Observable.of([]);

  /**
   * Customer search observable.
   *
   * @var {Relate<any>}
   */
  objCustomerSearch: Relate<any> = new Relate<any>();

  /**
   * List of subscriptions to destroy.
   *
   * @var {Subscription}
   */
  arSubscriptions: Subscription[] = [];

  /**
   * The address object of the preselected site.
   *
   * @type {Address}
   */
  objPreselectedSiteAddress: Address;

  /**
   * Changes the step from customer to location if no customer is selected.
   *
   * @var {boolean}
   */
  bNoCustomer: boolean = false;

  /**
   * Flag that checks if the job location is a custom address.
   *
   * @var {boolean}
   */
  bIsCustomLocation: boolean = false;

  /**
   * Variable to save the previous value, so we only
   * trigger when they are different.
   *
   * @var {Address}
   */
  bSitePrevious: Address = null;

  /**
   * Checks if we already skipped the form once.
   *
   * @var {boolean}
   */
  bSkipped: boolean = false;

  /**
   * Checks if an existing customer was selected through the name autocomplete.
   *
   * @var {boolean}
   */
  bHasSelectedExistingCustomer = false;

  bCalledUpdateCustomer = false;

  /**
   * If we are creating a new site from an existing customer.
   *
   * @var {boolean}
   */
  bCreatingNewSiteFromExistingCustomer = false;

  /**
   * Checks if the address field should be shown.
   *
   * @var {boolean}
   */
  bShowAddressField: boolean = false;

  bEditingSite: boolean = false;

  bHasOnlyOneSelectedSite: boolean = false;

  bAllowCustomerSearching = true;

  get showExistingSites(): boolean {
    if (filled(this.sharedService.getSite()) && this.bShowAddressField) {
      return false;
    }

    return (
      (this.form.dirty && this.form.controls.address.valid && this.bHasExistingSite && !this.bIsCustomLocation) ||
      this.bHasSelectedExistingCustomer
    ) && !this.bCreatingNewSiteFromExistingCustomer
      && !this.bNoCustomer;
  }

  get nameValueEmpty(): boolean {
    return blank(get(this.form, 'controls.name.value')) && !this.placesService.bIsPlaceShown;
  }

  get firstNameValueEmpty(): boolean {
    return blank(get(this.form, 'controls.first_name.value')) && !this.placesService.bIsPlaceShown;
  }

  get lastNameValueEmpty(): boolean {
    return blank(get(this.form, 'controls.last_name.value')) && !this.placesService.bIsPlaceShown;
  }

  get nextButtonDisabled(): boolean {
    return (
      this.bHasExistingSite && !this.form.controls.site_id.value && !this.bIsCustomLocation && !this.bEditingSite && !this.bHasOnlyOneSelectedSite
    ) || (
      !this.bCreatingNewSiteFromExistingCustomer &&
      !this.bHasExistingSite &&
      !this.bEditingSite &&
      !this.bShowAddressField
    )
  }

  constructor(
    private placesService: PlacesService,
    private notifService: NotificationService,
    private recordService: RecordService,
    private listService: ListingService,
    private searchService: SearchService,
    private localStorage: LocalStorageService,
    private sharedService: SharedService,
    private addressService: AddressService,
  ) {}

  ngOnInit() {
    this.placesService.bIsPlaceShown = false;
    this.sharedService.clearData();

    if (!this.preselectedCustomer && !this.bOpenedFromMegaMenu && this.strRecordType === 'jobs') {
      this.bNoCustomer = true;
      this.toggleCustomerFieldsStatus(false);
    }

    this.setCustomerSearch();
    this.setOnOrganizationChange();
    this.form.patchValue({type: 'organization'})
  }

  ngAfterViewInit() {
    this.initializeAddressForm();

    if (this.preselectedCustomer) {
      this.updateCustomer(new GlobalRecord({
        id: this.preselectedCustomer,
        match_percentage: 100,
        module: 'customers'
      }));
    }

    if (this.preselectedSite) {
      this.updateSite(new GlobalRecord({
        id: this.preselectedSite,
        match_percentage: 100,
        module: 'sites'
      }));
    }

    if (blank(this.preselectedCustomer) && blank(this.preselectedSite) && !this.bOpenedFromMegaMenu) {
      setTimeout(() => {
        this.skipStepEmitter.emit(true);
      }, 500)
    }
  }

  ngOnDestroy(): void {
    this.placesService.showPlaces();
    this.clear();
    this.bSkipped = false;
    this.arSubscriptions.forEach(subsribes => {
      subsribes.unsubscribe();
    })
  }

  /**
   * When the user clicks next.
   *
   * @returns {void}
   */
  next(): void {
    if (this.bCreatingNewSiteFromExistingCustomer) {
      this.form.patchValue({
        address: get(this.addressComponent, 'addressForm.value', {}) || {}
      });
    }

    if (this.bIsCustomLocation) {
      this.form.patchValue({
        site_id: null,
        existing_site_address_text: null
      });
    }

    this.addressComponent.parentHasSubmitted = true;
    this.form.markAsDirty();
    this.form.markAsTouched();
    this.bSubmitted = true;

    if (this.bNoCustomer) {
      this.form.patchValue({ customer_id: null });
      this.toggleCustomerFieldsStatus(false);
    }

    if (filled(this.sharedService.getSite())) {
      const params = {
        site_id: this.sharedService.getSite().site_id,
      };

      if (this.bEditingSite) {
        params['existing_site_address_text'] = null,
        params['address'] = get(this.addressComponent, 'addressForm.value', {}) || {}
      }

      this.form.patchValue(params);
      this._proceedToNextStep();

      return;
    }

    if (this.form.invalid) {
      this.notifService.notifyError('please_complete_the_form');
    } else if (this.bIsDisabledMaintenance) {
      // FC-3606: Message prompt when creating Job if the Site selected is "Disabled Maintenance"
      this.notifService.notifyError("unable_to_create_disabled_maintenance");
    } else {
      this._proceedToNextStep();
    }
  }

  /**
   * Proceeds to the next step
   *
   * @returns {void}
   */
  _proceedToNextStep(): void {
    const objFormValue: WizardCustomerData = {
      ...this.form.value,
      editing_site: this.bEditingSite
    };

    this.objCustomerData.emit(objFormValue);
    this.sharedService.customerClickedNext.next(isId(this.sharedService.customerId));
  }

  /**
   * Mark field as dirty
   *
   * @returns {void}
   */
  markFormControlDirty(): void {
    this.form.controls['address'].markAsDirty();
  }

  /**
   * Gets the filter for the sites search
   *
   * @returns {LooseObject}
   */
  private getSitesFilter(): LooseObject {
    if (this.preselectedSite) {
      return { id: this.preselectedSite };
    }

    const strCustomerId = get(this.form, 'value.customer_id');

    if (strCustomerId) {
      return { customer_id: strCustomerId };
    }

    const objAddress = this.form.controls.address.value;

    return {
      "address": {
        "street": this.setFilters(objAddress.street),
        "country": this.setFilters(objAddress.country),
        "state": this.setFilters(objAddress.state),
        "city": this.setFilters(objAddress.city),
        "zip": this.setFilters(objAddress.zip)
      }
    }
  }

  /**
   * Gets the sites list
   *
   * @param {number} intPageNum
   *
   * @returns {void}
   */
  getSites(intPageNum: number = 1): void {
    this.bHasOnlyOneSelectedSite = false;
    this.form.controls.site_id.setValue(null);

    if (!this.preselectedSite && !this.bEditingSite) {
      this.sharedService.setSite(null);
    }

    this.objSiteSearch = this.getSitesObservable(intPageNum).pipe(
      tap(result => {
        this.bIsDisabledMaintenance = result["data"].findIndex((item) => item.disable_maintenance) > -1;
        this.bAllowCustomerSearching = true;
        const preselectedSiteData: LooseObject = result['data'].find(item => item.id == this.preselectedSite);

        // if there is only one existing site, automatically select that one
        if (get(result, 'data', []).length === 1) {
          this.bHasOnlyOneSelectedSite = true;
          this.setSiteData(result['data'][0]);
        }

        if (this.preselectedSite && filled(preselectedSiteData)) {
          this.form.patchValue({
            site_id: this.preselectedSite,
            existing_site_address_text: get(preselectedSiteData, 'address_text')
          });

          if (this.preselectedSite && this.skipWhenPreselected === true && !this.bSkipped) {
            this.bSkipped = true;

            // FC-4005: if form is valid, we should automatically next the form
            if (this.form.valid || this.bNoCustomer) {
              this.next();
            }
          };

        } else {
          this.form.controls.site_id.setValue(null);
        }

        this.form.markAsDirty();
        this.bHasExistingSite = result['data'].length > 0;
      }),
      shareReplay()
    )
  }

  /**
   * Gets the sites search observable
   *
   * @param {number} intPageNum
   * @param {LooseObject} objFilter
   *
   * @returns {Observable<any>}
   */
  getSitesObservable(intPageNum: number = 1): Observable<any> {
    return this.listService.fetchDataAdvanceSearch(
      { pageNum: intPageNum },
      'sites',
      this.getSitesFilter(),
      { "id": "updated_at", "sort": "desc" },
      null,
      4
    ).pipe(shareReplay(1));
  }

  /**
   * Update the current customer.
   *
   * @param {GlobalRecord} objGlobalRecord
   *
   * @returns {void}
   */
  updateCustomer(objGlobalRecord: GlobalRecord): void {
    this.recordService.getRecord(
      'customers',
      objGlobalRecord.id,
      true
    ).subscribe(data => {
      if (data['record_details']) {
        this.bShowAddressField = false;
        this.bAllowCustomerSearching = false;

        let strType = data['record_details']['type'];

        this.bCreatingNewSiteFromExistingCustomer = false;
        this.form.controls.type.setValue(strType);
        this.form.controls.customer_id.setValue(objGlobalRecord.id);

        if (strType == 'individual') {
          this.form.controls.first_name.setValue(data['record_details']['first_name']);
          this.form.controls.last_name.setValue(data['record_details']['last_name']);
        }

        if (strType == 'organization') {
          this.form.controls.name.setValue(data['record_details']['name']);
        }

        this.sharedService.customerId = get(data, 'record_details.id', null);
        this.sharedService.customerText = get(data, 'record_details.text', null);

        // FC-3982: if we have preselected site, don't apply customer's address
        if (!this.preselectedSite) {
          let objAddress = new Address().setPropertiesToNull(data['record_details']['address']);
          let newAddress = this.addressService.setMissingStateAndCountryByClientDefault(objAddress);
          // when the country has invalid code, remov the state and country value
          if (!(new Country()).hasValidCode(newAddress.country)) {
            newAddress.state = null;
            newAddress.country = null;
          }
          this.form.controls.address.patchValue(newAddress);

          this.addressComponent.setGeolocationComponentLatLng({
            latitude: objAddress.latitude,
            longitude: objAddress.longitude,
          });
        }

        this.form.markAsDirty();
        this.bCalledUpdateCustomer = true;
        this.bHasSelectedExistingCustomer = true;
      }
    });
  }

  /**
   * Update the current customer.
   *
   * @param {GlobalRecord} objGlobalRecord
   *
   * @returns {void}
   */
  updateSite(objGlobalRecord: GlobalRecord): void {
    this.recordService.getRecord(
      'sites',
      objGlobalRecord.id,
      true
    ).subscribe(data => {
      if (data['record_details']) {
        let objAddress = new Address().setPropertiesToNull(data['record_details']['address']);
        this.form.controls.address.patchValue(objAddress);
        this.sharedService.setSite({
          id: get(data, 'record_details.id'),
          primary_contact_id: get(data, 'record_details.primary_contact_id'),
          address_text: get(data, 'record_details.text')
        });
        this.form.markAsDirty();
      }
    });
  }

  /**
   * Clear the form and setting it to the
   * initial values.
   *
   * @returns {void}
   */
  clear(): void {
    this.form.reset();
    this.form.markAsPristine();
    this.form.controls.type.setValue('individual');
    this.preselectedCustomer = null;
    this.preselectedSite = null;
    this.objPreselectedSiteAddress = null;
    this.bNoCustomer = false;
    this.bIsCustomLocation = false;
    this.bHasExistingSite = false;
    this.objCustomerData.emit(null);
    this.sharedService.clearData();
  }

  /**
   * Only visible in job wizard. Toggles the show customer flag.
   *
   * @returns {void}
   */
  toggleShowCustomer(change: MatCheckboxChange): void {
    this.bNoCustomer = change.checked;

    if (this.bNoCustomer) {
      this.toggleCustomerFieldsStatus(false);
      this.bCreatingNewSiteFromExistingCustomer = false;
      this.bHasExistingSite = false;
      this.sharedService.clearData();
      this.bShowAddressField = true;
    } else {
      this.toggleCustomerFieldsStatus(true);
    }

    this.changeStepToLocation.emit(this.bNoCustomer);

    this.form.markAsDirty();
  }

  /**
   * Only visible in job wizard. Toggles the show customer flag.
   *
   * @returns {void}
   */
  toggleCustomLocation(change: MatCheckboxChange): void {
    this.bIsCustomLocation = change.checked;
    this.customLocationEmitter.emit(this.bIsCustomLocation);
  }

  /**
   * Only visible in job wizard. Step is skippable if there is no customer.
   *
   * @returns {void}
   */
  skip(): void {
    this.notifService.sendConfirmation('skip_customer_confirm').subscribe(response => {
      if (response.answer === true) {
        this._skipStepActions();
      }
    })
  }

  /**
   * Sets the site data for prefil on the next component.
   *
   * @param {LooseObject} objSite
   */
  setSiteData(objSite: LooseObject): void {
    this.sharedService.setSite(objSite);
    this.form.patchValue({
      site_id: this.bIsCustomLocation ? null : objSite.id,
      existing_site_address_text: this.bIsCustomLocation ? null : objSite.address_text
    })
  }

  /**
   * Shows the address form to allow editing of existing site address.
   *
   * @param {LooseObject} objSite
   */
  editSite(objSite: LooseObject): void {
    if (isId(objSite.id)) {
      this.bEditingSite = true;
      this.setSiteData(objSite);
      this.updateSite(new GlobalRecord({
        id: objSite.id
      }));
      this.bShowAddressField = true;
    }
  }

  createNewSiteForExistingCustomer(): void {
    this.bShowAddressField = true;
    this.bCreatingNewSiteFromExistingCustomer = true;
    this.bHasExistingSite = false;
    this.sharedService.setSite(null);

    this.buildAddressForm(new Address());

    this.form.patchValue({
      site_id: null,
      existing_site_address_text: null
    });
  }

  showAddressFieldForNewCustomer(): void {
    if (!this.bHasSelectedExistingCustomer && (!this.nameValueEmpty || !this.lastNameValueEmpty)) {
      this.bShowAddressField = true;
    }
  }

  trackBySiteId(index: number, site: any): string {
    return site.id;
  }

  /**
   * Set the logic when an organization is changed.
   *
   * @returns {void}
   */
  private setOnOrganizationChange(): void {
    this.arSubscriptions.push(
      this.form.controls.type.valueChanges.subscribe(change => {
        this.form.markAsPristine();
        let isOrganization: boolean = this.bIsOrganization = (change === 'organization');

        if (isOrganization) {
          this.form.controls.first_name.reset();
          this.form.controls.last_name.reset();
          this.form.controls.first_name.disable();
          this.form.controls.last_name.disable();
          this.form.controls.name.enable();

          if (blank(this.form.value['name'])) {
            this._clearCustomerAndSite();
          }
        } else {
          this.form.controls.name.reset();
          this.form.controls.name.disable();
          this.form.controls.first_name.enable();
          this.form.controls.last_name.enable();

          if (blank(this.form.value['first_name']) && blank(this.form.value['last_name'])) {
            this._clearCustomerAndSite();
          }
        }
      }
    ));

    this.form.controls.type.valueChanges.pipe(
      take(1),
      // We have debounce so the dom has enough time to
      // refresh and we can get the now showing orgNameElementRef.
      delay(1),
    ).subscribe(() => {
        let objClient: Client = this.localStorage.getJsonItem('current_client');
        if (objClient && this.orgNameElementRef) {
          // FC-4207: focus on name field when opening wizard
          setTimeout(() => {
            if (this.orgNameElementRef) {
              this.orgNameElementRef.nativeElement.focus();
            }
          }, 100);

          this.placesService.initializePlaces(this.orgNameElementRef, objClient.country || 'AU').subscribe(autocomplete => {
            autocomplete.addListener("place_changed", () => {
              this.placesService.ngZone.run(() => {
                let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                if (place === undefined || place.geometry === undefined || place.geometry === null) {
                  return;
                }
                this.form.controls.name.setValue(place.name);
                this.addressComponent.setFromAutoComplete(place);
                this.bShowAddressField = true;
                this.placesService.bIsPlaceShown = false;
              })
            });
          });
        }
    })
  }

  private toggleCustomerFieldsStatus(bEnable: boolean): void {
    if (!bEnable) {
      this.form.get('type').disable();
      this.form.get('first_name').disable();
      this.form.get('last_name').disable();
      this.form.get('name').disable();
    } else {
      this.form.get('type').enable();
      this.form.get('first_name').enable();
      this.form.get('last_name').enable();
      this.form.get('name').enable();
    }
  }

  /**
   * Set the customer search observables and loaders.
   *
   * @returns {void}
   */
  private setCustomerSearch(): void {
    this.objCustomerSearch.buildRelates(
      switchMap(term => {
        return this.searchService.global(term, 'customers').pipe(
          tap((results) => {
            if (results.length == 0) {
              this._clearCustomerAndSite();
              this.placesService.showPlaces();

              if (filled(term)) {
                this.placesService.bIsPlaceShown = true;
              }
            } else {
              this.placesService.hidePlaces();
              this.placesService.bIsPlaceShown = false;
            }
          })
        );
      }),
    );

    this.arSubscriptions.push(
      Observable.merge(
        this.form.controls.first_name.valueChanges,
        this.form.controls.last_name.valueChanges,
        this.form.controls.name.valueChanges
      ).pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe(strTerm => {
        if (this.bAllowCustomerSearching) {
          this.objCustomerSearch.typehead.next(strTerm);
        }
      })
    );
  }

  /**
   * Initializes the address forms.
   *
   * @returns {void}
   */
  private initializeAddressForm(): void {
    this.buildAddressForm(new Address());
    this.form.controls.address = this.addressComponent.addressForm;
    this.arSubscriptions.push(
      this.form.controls.address.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe(() => {
        if (
          ((this.form.controls.address.valid && this.form.controls.address.value != this.bSitePrevious) || this.bHasSelectedExistingCustomer) &&
          !this.bCreatingNewSiteFromExistingCustomer
        ) {
          this.bSitePrevious = this.form.controls.address.value;
          this.bHasExistingSite = false;
          this.getSites();
        }
      })
    );
  }

  /**
   * Builds the address form inside the address
   * component.
   *
   * @param {Address} objAddress
   */
  private buildAddressForm(objAddress: Address): void {
    this.addressComponent.buildForm(objAddress, 'add', 'recordview');
  }

  /**
   * Set the filters for sites ES.
   *
   * @param {any} value
   *
   * @returns {op: "eq", value: any}
   */
  private setFilters(value: any): {op: "eq", value: any} {
    return { op: "eq", value };
  }

  private _clearCustomerAndSite(): void {
    this.bHasSelectedExistingCustomer = false;
    this.bHasExistingSite = false;
    this.sharedService.clearData();
  }

  /**
   * Emits the values when this step is skipped.
   *
   * @returns {void}
   */
  private _skipStepActions(): void {
    this.bIsCustomLocation = false;
    this.objCustomerData.emit(null);
    this.customLocationEmitter.emit(false);
    this.skipStepEmitter.emit(true);
  }
}
