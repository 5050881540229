import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { AccountService } from '../../services/account.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { ClientStoreService } from '../../services/client-store.service';
import { NotificationService } from '../../services/notification.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-resenddialog',
  templateUrl: './resenddialog.component.html',
  styleUrls: ['./resenddialog.component.scss']
})
export class ResenddialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public arInvitationList: any,
    public dialogRef: MatDialogRef<ResenddialogComponent>,
    public account: AccountService,
    public clientStoreService: ClientStoreService,
    public notifService: NotificationService,
    protected localStorageService: LocalStorageService
  ) {}

  ngOnInit() {}

  /**
   * Resends multiple pending invitation
   *
   * @returns void
   */
  resendAllInvitations(): void {
    this.account.resendUserInvitations(this.arInvitationList).subscribe(
      data => {
        this.notifService.notifySuccess('successful_resent_invite');
      }
    );
    this.dialogClose();
  }

  /**
   * Resends one pending user invitation
   *
   * @param invitation
   * @param currentInvitationData
  */
  resendInvitation(indexInvitation) {
    let currentEmailInvite = this.arInvitationList.splice(indexInvitation, 1);

    this.account.resendUserInvitations(currentEmailInvite)
      .pipe(catchError(errorResponse => {
          if (errorResponse.status === 422) {
            this.notifService.sendNotification(errorResponse.error.title, errorResponse.error.details, 'warning');
          }
          return throwError(errorResponse);
        })
      ).subscribe(
        data => {
          this.notifService.notifySuccess('successful_resent_invite');
        }
      );
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
