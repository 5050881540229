import * as _moment from 'moment';
import { iif, Observable, of } from 'rxjs';
import { cloneDeep, forEach, get, isEmpty, isNil, uniq } from 'lodash-es';
import { switchMap, map, first } from 'rxjs/operators';

import { MatDialog,  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, HostListener, ViewChild } from '@angular/core';

import { Select } from '../../../../objects/select';
import { Relate } from '../../../../objects/relate';
import { LooseObject } from '../../../../objects/loose-object';
import { RecordService } from '../../../../services/record.service';
import { NotificationService } from '../../../../services/notification.service';
import { WizardService } from '../../../../features/wizard/services/wizard.service';
import { CustomTranslateService } from '../../../../services/custom-translate.service';
import { RelatedProductsComponent } from '../../../../features/product-folders/static-folders/related-products/related-products.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ActivatedRoute } from '@angular/router';
import { ContextMenuComponent } from '../../../../shared/components/context-menu/context-menu.component';
import { ContextMenuService } from '../../../../services/context-menu.service';
import { fallback, filled, isId } from '../../../../shared/utils/common';
import { UUID } from 'angular2-uuid';
import { PoWizardLine } from '../../../../objects/line-items/po-wizard-line';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-wizard-purchase-order',
  templateUrl: './wizard-purchase-order.component.html',
  styleUrls: ['./wizard-purchase-order.component.scss'],
  providers: [ CustomTranslateService ]
})
export class WizardPurchaseOrderComponent implements OnInit {
  @ViewChild(ContextMenuComponent) contextMenuComponent: ContextMenuComponent;
  @ViewChild('ngSelectModule') ngSelectComponent: NgSelectComponent;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  public arItems: LineItem[] = [];

  public bLoading: boolean = true;

  public bSubmitted: boolean = false;

  public bSaving: boolean = false;

  private strRecordModule: string = null;

  private objJobRecord: LooseObject = {};

  private strPODeliverTo: string = null;
  private strDeliveryNotes: string = null;

  public objDeliveryConfig = {
    main_warehouse: {
      label: 'warehouse',
      module: 'warehouses',
      module_id: 'warehouse_id'
    },
    site: {
      label: 'site',
      module: 'sites',
      module_id: 'site_id'
    }
  };
  private objModuleRelate: Relate<string>;

  public selectedLineItems: Array<LooseObject> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public contextMenuService: ContextMenuService,
    private wizardService: WizardService,
    private recordService: RecordService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<WizardPurchaseOrderComponent>,
    private dialog: MatDialog,
  ) {
    this.strRecordModule = !isNil(this.data.module) ? this.data.module : null;
  }

  ngOnInit() {
    iif(
      () => this.strRecordModule == 'jobs' && isId(get(this.data, 'record_id')),
      this.recordService.getRecord('jobs', get(this.data, 'record_id')),
      of({ record_details: {} }),
    ).subscribe((response) => {
      this.objJobRecord = response['record_details']
      this.getPurchaseOrderConfig();
    });
  }

  /**
   * retrieve purchase order config
   */
  getPurchaseOrderConfig(): void {
    if (filled(this.objJobRecord.work_order_items)) {

      let arItemIds: string[] = this.objJobRecord.work_order_items
        .filter(objLineItem => objLineItem.item_id)
        .map(objLineItem => objLineItem.item_id);
      iif( () => !isEmpty(arItemIds),
        this.recordService.getProducts({
          ids: arItemIds,
        }),
        of([])
      ).subscribe( response => {
        if (response && response.length > 0) {
          let arProductList = response;
          forEach(this.objJobRecord.work_order_items, (objLineItem)  => {
            if (objLineItem.item_id) {

              let objProduct = arProductList.find( objItem => objItem.id == objLineItem.item_id);
              objProduct['quantity'] = objLineItem.quantity;
              objProduct['unit_cost'] = objLineItem.unit_cost;
              objProduct['related_products'] = fallback(get(objProduct, 'related_products'), {
                fallback: () => [],
              });

              objProduct['tax_code_id'] = fallback(get(objLineItem, 'tax_code_id'), {
                fallback: () => get(objProduct, 'tax_code_id'),
              });

              objProduct['tax_code_name'] = fallback(get(objLineItem, 'tax_code_name'), {
                fallback: () => get(objProduct, 'tax_code_name'),
              });

              this.addItem(objProduct);
            }
          });
        }
        this.bLoading = false;
      });
    } else {
      this.bLoading = false;
    }
  }
4

  /**
   * add new line item
   */
  addItem(objItem: LooseObject = null): void {
    let objProductFilter = {
      labor: false, active: true, is_searchable: true
    };
    let itemId = get(objItem, 'item_id', get(objItem, 'id'));
    let itemName = get(objItem, 'item_name', get(objItem, 'name'));
    let itemCode = get(objItem, 'item_code', get(objItem, 'code'));

    let objLineItem = new LineItem(
      new Relate<any>().buildRelates(
        switchMap(strTerm => this.recordService.getProductRecordData(strTerm, objProductFilter, '', true, null, [], true)),
        (objItem && itemId) ? [{ id: itemId, name: itemName, labor: objItem.labor, code: itemCode }] : null,
        true
      ),
      new Relate<any>().buildRelates(
        switchMap(strTerm => this.recordService.getRecordRelate('customers', strTerm, false, false, { is_supplier: true }))
      ),
      objItem
    );

    if (objItem) {

      objLineItem.quantity = objItem.quantity || 1;
      objLineItem.unit_cost = objItem.unit_cost || 0;
      objLineItem.labor = objItem.labor || false;
      objLineItem.item_code = itemCode;
      objLineItem.item_name = itemName;
      objLineItem.description = objItem.description;
      objLineItem.related_products = fallback(objItem.related_products, {
        fallback: () => [],
      });
      objLineItem.related_products = objItem.related_products;
      objLineItem.is_supplier_new_pricing = objItem.is_supplier_new_pricing || false;

      if (!isEmpty(objItem.preferred_supplier) || !isEmpty(objItem.supplier_pricing)) {
        this.updateCustomerRelate(objLineItem, objItem);
      }
    }

    this.arItems.push(objLineItem);
  }

  /**
   * we should update the amount when updating item
   *
   * @param objLineItem
   */
  onChangeItem(objLineItem: LineItem): void {

    objLineItem.description = null;
    objLineItem.unit_cost = 0.00;
    objLineItem.quantity = 1;
    objLineItem.is_unit_cost_changed = false;
    objLineItem.is_supplier_new_pricing = false;
    objLineItem.supplier_pricing_list = [];
    objLineItem.customer_relate.destroyTypehead();

    let objItem = objLineItem.item_relate.value;

    objLineItem.related_products = objItem.related_products;

    objLineItem.description = objItem.description;
    objLineItem.unit_cost = objItem.unit_cost;

    this.updateCustomerRelate(objLineItem, objItem)
  }

  /**
   * adding default value or default option on customer relate field
   *
   * @param objLineItem
   * @param objItem
   */
  updateCustomerRelate(objLineItem: LineItem, objItem: LooseObject = null): void {
    let objDefaultSupplier = objItem.preferred_supplier && objItem.preferred_supplier_text
      ? [ new Select(objItem.preferred_supplier, objItem.preferred_supplier_text, 'primary') ]
      : [];

    objLineItem.supplier_pricing_list = cloneDeep(objDefaultSupplier);
    objItem.supplier_pricing.forEach( supplierPricing => {
      objLineItem.supplier_pricing_list.push(
        new Select(
          get(supplierPricing, 'customer_id', get(supplierPricing, 'id')),
          get(supplierPricing, 'customer_text', get(supplierPricing, 'text')),
          '',
          get(supplierPricing, 'extras', supplierPricing)
        )
      )
    });

    if (isEmpty(objDefaultSupplier)) {
      if (objItem.supplier_pricing && objItem.supplier_pricing.length == 1) {
        let objSupplierPricing = objItem.supplier_pricing[0];
        objDefaultSupplier = [
          new Select(
            get(objSupplierPricing, 'customer_id', get(objSupplierPricing, 'id')),
            get(objSupplierPricing, 'customer_text', get(objSupplierPricing, 'text')),
            '',
            objSupplierPricing
          )
        ]
      }
    }

    objLineItem.customer_relate.destroyTypehead();
    objLineItem.customer_relate = new Relate<any>().buildRelates(
      switchMap(strTerm => this.customerBuildRelate(objLineItem, strTerm)),
      objLineItem.supplier_pricing_list,
      !isEmpty(objDefaultSupplier)
    );

    if (objDefaultSupplier) {
      this.onChangeCustomer(objLineItem);
    }
  }

  /**
   * initialize relate request for customer relate field
   *
   * @param objLineItem
   * @param strTerm
   */
  customerBuildRelate(objLineItem: LineItem, strTerm: string): Observable<Select[]>{
    return this.recordService.getRecordRelate('customers', strTerm, false, false, { is_supplier: true })
      .pipe(
        map( response => {
          if (isEmpty(strTerm) && !isEmpty(objLineItem.supplier_pricing_list)) {
            return objLineItem.supplier_pricing_list;
          }
          return response;
        })
      );
  }

  /**
   * trigger's when customer relate is changed
   *
   * @param objLineItem
   */
  onChangeCustomer(objLineItem: LineItem): void {
    if (objLineItem.customer_relate.value && objLineItem.customer_relate.value.extras) {

      // when extras has value in customer relate, this means that it is from supplier pricing
      let objSupplierPricing = objLineItem.customer_relate.value.extras;
      objLineItem.unit_cost = parseFloat(objSupplierPricing.unit_cost);
    }
  }

  /**
   * trigger's changing from supplier reorder cost to default item's cost
   */
  onChangeUpdatePricing(objLineItem: LineItem, event): void {
    objLineItem.is_supplier_new_pricing = event.checked;
  }

  /**
   * trigger's when unit cost is changed
   *
   * @param objLineItem
   */
  onChangeUnitCost(objLineItem: LineItem): void {
    objLineItem.is_unit_cost_changed = true;
  }

  /**
   * remove and reindex Items
   *
   * @param index
   */
  removeItem(index: number): void {
    this.arItems.splice(index, 1);
    this.arItems.filter( objItems => objItems);
  }

  /**
   * Save Purchase Order as draft
   */
  savePurchaseOrder(): void {
    this.bSubmitted = true;
    this.bSaving = true;
    if (this.hasValidLineItems()) {
      let objPayload = this.getPayload();
      let customerId = uniq(objPayload.line_items.map( lineItem => lineItem.customer_id ));
      this.wizardService.savePurchaseOrderDetails(objPayload).subscribe( response => {
        this.notificationService.notifySuccess('multiple_po_created');
        this.dialogRef.close({
          status: 'success',
          redirect: customerId.length == 1
        });
      })
    } else {

      this.bSaving = false;
      this.notificationService.notifyWarning('fill_out_fields');
    }
  }

  /**
   * create a payload for saving purchase order
   */
  getPayload(): LooseObject {

    let arItems = this.arItems.map( objItems => {
      return {
        customer_id: objItems.customer_relate.value.id,
        item_id: (objItems.item_relate.value) ? objItems.item_relate.value.id : null,
        is_supplier_new_pricing: objItems.is_supplier_new_pricing,
        quantity: objItems.quantity,
        unit_cost: objItems.unit_cost,
        description: objItems.description,
        tax_code_id: objItems.tax_code_id,
        tax_code_name: objItems.tax_code_name,
      }
    });

    return {
      delivery_location: this.strPODeliverTo,
      delivery_notes: this.strDeliveryNotes,
      site_id: this.strPODeliverTo == 'site' ? this.objModuleRelate.value : null,
      warehouse_id: this.strPODeliverTo == 'main_warehouse' ? this.objModuleRelate.value : null,
      job_id: this.objJobRecord.id,
      line_items: arItems
    }
  }

  /**
   * validate all the form line items
   *
   * @returns boolean
   */
  hasValidLineItems(): boolean {
    return isEmpty(this.arItems.filter( objLineItem => {
      let objLineItemForm = new FormGroup({
        customer_relate: new FormControl(null, [Validators.required]),
        item_relate: new FormControl(null, [Validators.required]),
        quantity: new FormControl(null, [Validators.required]),
        unit_cost: new FormControl(null, [Validators.required]),
      });

      objLineItemForm.patchValue({
        customer_relate: objLineItem.customer_relate.value,
        item_relate: objLineItem.item_relate.value,
        quantity: objLineItem.quantity,
        unit_cost: objLineItem.unit_cost,
      });

      return !objLineItemForm.valid;
    }));
  }

  /**
   * Close the current dialog.
   */
  cancelDialog(): void {
    if (this.arItems.length) {
      // Pop-up modal for confirmation
      this.notificationService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close({
            status: 'cancel'
          });
        });
    } else {
      this.dialogRef.close({
        status: 'cancel'
      });
    }
  }

  /**
   * Add the related product to the list of line items.
   *
   * @param {LooseObject} objRecord
   */
  public addRelated(objRecord: LooseObject) {

    this.dialog.open(RelatedProductsComponent, {
      width: '70%',
      data: objRecord
    }).afterClosed().subscribe(response => {
      if (response && response.length > 0) {

        let strIds: string[] = [];

        response.forEach(item => {
          strIds.push(item['child_item_id'])
        });

        this.recordService.getProductRecordData(null, null, null, false, null, strIds).subscribe(response => {
          if (response && response.length > 0) {
            response.forEach(item => {
              this.addItem(item);
            });
          }
        });
      }
    });

  }

  /**
   * When the deliver to is changed.
   *
   * @param {string} strDeliverTo
   */
  public onDeliverToChange(strDeliverTo: string) {

    this.strDeliveryNotes = null;

    if (this.ngSelectComponent) {
      this.ngSelectComponent.handleClearClick();
    }

    if (this.objModuleRelate) {
      this.objModuleRelate.$source.unsubscribe();
      this.objModuleRelate = null;
    }

    if (strDeliverTo == 'site') {
      this.objModuleRelate = new Relate<any>().buildRelates(
        switchMap(strTerm => this.recordService.getRecordRelate(this.objDeliveryConfig[strDeliverTo].module, strTerm, '', false)),
        this.objJobRecord.site_id ? [{id: this.objJobRecord.site_id, text: this.objJobRecord.site_text}] : null,
        true
      );
    }

    if (strDeliverTo == 'main_warehouse') {
      this.objModuleRelate = new Relate<string>().buildRelates(
        switchMap(strTerm => this.recordService.getRecordRelate(this.objDeliveryConfig[strDeliverTo].module, strTerm, '', false))
      );
    }
  }

  doSomethingFromContextMenu(event) {
    let copiedLineItemFromLocalStorage = this.contextMenuService.getCopiedLineItem();

    if (event.action == 'paste' && filled(copiedLineItemFromLocalStorage)) {
      let indexCounter = get(event, ['data', 'index']) + 1;
      copiedLineItemFromLocalStorage.forEach(lineItem => {
        if (lineItem['labor'] == false && !isEmpty(lineItem['item_id'])) {
          this.addItem(lineItem);
          indexCounter++;
        }
      });
    } else if (event.action == 'copy') {
      let copiedLineItems = [];
      let eventDataLineItem = get(event, ['data', 'line_item'], []);

      if (this.selectedLineItems.length > 0) {
        copiedLineItems = this.selectedLineItems.map(lineItem => {
          let newLineItem = this.formatCopiedLineItem(lineItem);

          return new PoWizardLine(newLineItem);
        });
      } else if (eventDataLineItem) {
        let newLineItem = this.formatCopiedLineItem(eventDataLineItem);

        copiedLineItems = [new PoWizardLine(newLineItem)];
      }

      if (filled(copiedLineItems)) {
        this.contextMenuService.setCopiedLineItem(copiedLineItems);
      }
    }
  }

  formatCopiedLineItem(lineItem: LooseObject) {
    let currentLineItem = cloneDeep(lineItem);
    currentLineItem['item_id'] = get(currentLineItem, ['item_relate', 'value', 'id']);
    currentLineItem['item_code'] = get(currentLineItem, ['item_relate', 'value', 'code']);
    currentLineItem['item_name'] = get(currentLineItem, ['item_relate', 'value', 'text'], get(currentLineItem, ['item_relate', 'value', 'name']));
    currentLineItem['labor'] = get(currentLineItem, ['item_relate', 'value', 'labor']);
    currentLineItem['description'] = get(currentLineItem, ['description']);
    currentLineItem['supplier_pricing'] = get(currentLineItem, ['supplier_pricing_list']);
    currentLineItem['preferred_supplier'] = get(currentLineItem, ['customer_relate', 'value', 'id']);
    currentLineItem['preferred_supplier_text'] = get(currentLineItem, ['customer_relate', 'value', 'text'], get(currentLineItem, ['customer_relate', 'value', 'name']));

    return currentLineItem;
  }

  onClickedLineItem($event, attr) {
    this.selectedLineItems = this.contextMenuService.selectLineItem($event, attr, this.selectedLineItems);
  }

  /**
   * When the module relate field changes.
   *
   * @param {LooseObject} objSelectedItem
   */
  public onModuleChange(objSelectedItem: LooseObject) {
    if (this.strPODeliverTo == 'main_warehouse' && objSelectedItem) {
      this.strDeliveryNotes = objSelectedItem['delivery_instructions'];
    }
  }
}

export class LineItem {

  customer_relate: Relate<any>;
  item_relate: Relate<any>;
  description?: string;
  quantity: number;
  unit_cost: number;
  department_relate: Relate<any>;
  tax_code_relate: Relate<any>;
  account_code_relate: Relate<any>;
  amount: number;
  supplier_pricing_list: LooseObject[];
  is_unit_cost_changed: boolean;
  is_supplier_new_pricing: boolean;
  use_supplier_pricing: boolean;
  related_products: LooseObject[];
  tax_code_id?: string;
  tax_code_name?: string;
  select_line_id?: string;
  labor?: boolean;
  item_code?: string;
  item_name?: string;

  constructor(
    itemRelate: Relate<any>,
    customerRelate: Relate<any>,
    lineItem: LooseObject = {}
  ) {
    this.customer_relate = customerRelate;
    this.item_relate = itemRelate;
    this.description = get(lineItem, 'description', null);
    this.quantity = 1;
    this.unit_cost = 0.00;
    this.amount = 0.00;
    this.is_unit_cost_changed = false;
    this.is_supplier_new_pricing = false;
    this.use_supplier_pricing = false;
    this.supplier_pricing_list = [];
    this.related_products = [];
    this.tax_code_id = get(lineItem, 'tax_code_id', null);
    this.tax_code_name = get(lineItem, 'tax_code_name', null);
    this.select_line_id = UUID.UUID();
    this.labor = get(lineItem, 'labor');
    this.item_code = get(lineItem, 'item_code');
    this.item_name = get(lineItem, 'item_name');
    this.description = get(lineItem, 'description');
  }

}