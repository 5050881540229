import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { isNil } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fieldmagic-sorting-icon',
  template: `
    <fieldmagic-icon
      *ngIf="(target$ | async) != field"
      icon="sort"
      class="pointer"
      (click)="onClick()"
    ></fieldmagic-icon>
    <fieldmagic-icon
      *ngIf="(target$ | async) == field && (direction$ | async) == 'asc'"
      icon="sort-up"
      class="pointer"
      (click)="onClick()"
    ></fieldmagic-icon>
    <fieldmagic-icon
      *ngIf="(target$ | async) == field && (direction$ | async) == 'desc'"
      icon="sort-down"
      class="pointer"
      (click)="onClick()"
    ></fieldmagic-icon>
  `,
})
export class SortingIconComponent implements OnChanges {
  @Input()
  current: CurrentStoringConfig;

  @Input()
  field: string;

  @Output('onSort')
  $onSort = new EventEmitter<OnSortingIconSortEvent>();

  readonly direction$ = new BehaviorSubject<'sort-up' | 'sort-down'>(null);

  readonly target$ = new BehaviorSubject<string>(null);

  onClick(): void {
    this.$onSort.emit({
      id: this.field,
      sort: this.current.id == this.field ? (this.current.sort == 'asc' ? 'desc' : 'asc') : 'asc',
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (! isNil(changes.current)) {
      this.target$.next(changes.current.currentValue.id);
      this.direction$.next(changes.current.currentValue.sort);
    }
  }
}

export type CurrentStoringConfig = {
  id: string;
  sort: 'asc' | 'desc';
}

export type OnSortingIconSortEvent = {
  id: string;
  sort: 'asc' | 'desc';
}