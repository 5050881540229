import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, shareReplay, switchMap, tap, filter, finalize } from 'rxjs/operators';
import { PaginatedData } from '../../../../../module/acs-support/acs-support';
import { AcsSupportService } from '../../../../../module/acs-support/acs-support.service';
import { GlobalRecord } from '../../../../../objects/global-record';
import { Phone } from '../../../../../objects/phone';
import { Select } from '../../../../../objects/select';
import { FormService } from '../../../../../services/form.service';
import { NotificationService } from '../../../../../services/notification.service';
import { RecordService } from '../../../../../services/record.service';
import { SearchService } from '../../../../../services/search.service';
import { WizardContactData } from '../../../objects/wizard';
import { Relate } from '../../../../../objects/relate';
import { ListingService } from '../../../../../services/listing.service';
import { isEmpty, isNil, trimEnd, get } from 'lodash-es';
import { SharedService } from '../../../services/shared.service';
import { LooseObject } from '../../../../../objects/loose-object';
import { filled, isId, blank } from '../../../../../shared/utils/common';

@Component({
  selector: 'contact-step',
  templateUrl: './contact.component.html',
  styleUrls: [
    './contact.component.scss',
    '../../../styles/shared_wizard_styles.scss',
  ]
})
export class ContactComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  /**
   * Stepper html and api.
   *
   * @var {MatStepper}
   */
  @Input() stepper: MatStepper;

  /**
   * The customer id from the parent.
   *
   * @var {string}
   */
  @Input() customerId: string;

  /**
   * The site id from the customer forms.
   *
   * @var {string}
   */
  @Input() siteId: string;

  /**
   * The preselected contact id.
   *
   * @var {string}
   */
  @Input() preselectedContact: string;

  /**
   * Checks if the customer step is finished.
   *
   * @var {boolean}
   */
  @Input() bCustomerStepFinished: boolean = false;

  /**
   * Flag that shows if the wizard was opened from the mega menu.
   *
   * @var {boolean}
   */
  @Input() bOpenedFromMegaMenu: boolean = false;

  /**
   * Emit the contact data to the parent.
   *
   * @var {EventEmitter<WizardContactData>()}
   */
  @Output() objContactData = new EventEmitter<WizardContactData>();

  /**
   * Emits true if step is skipped.
   *
   * @var {EventEmitter<boolean>}
   */
  @Output() skipStepEmitter = new EventEmitter<boolean>();

  /**
   * Form group for the contact.
   *
   * @var {FormGroup}
   */
  form: FormGroup;

  /**
   * If the contact is linked to the previous customer.
   *
   * @var {boolean}
   */
  bIsLinked: boolean = false;

  /**
   * If the contact form was submitted.
   *
   * @var {boolean}
   */
  bSubmitted: boolean = false;

  /**
   * The selected contact when searching from the first name.
   *
   * @var {GlobalRecord}
   */
  objSelectedContact: GlobalRecord;

  /**
   * List of phone values.
   *
   * @var {Phone[]}
   */
  objPhoneFieldValue: Phone[] | string = "";

  /**
   * The pagination for the contact roles.
   *
   * @var {Observable<PaginatedData>}
   */
  objContactRoles: Observable<PaginatedData>;

  /**
   * Customer search observable.
   *
   * @var {Relate<any>}
   */
  objContactSearch: Relate<any> = new Relate<any>();

  /**
   * The list of roles to choose from.
   *
   * @var {Select[]}
   */
  arRoles: Select[] = [];

  /**
   * List of fields to generate a form from.
   *
   * @var {any}
   */
  arFields: any = [
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "email_address",
      "label": "email_address",
      "controlType": "multiselect",
      "space": 12,
      "has_primary": false,
      "options": [],
      "tag": true,
      "multiple": true,
      "hideSelected": true,
      "closeOnSelect": true,
      "clearable": false,
      "maxSelectedItems": 100,
      "module": "",
    },
  ];

  /**
   * The elements and properties to be set
   * in a phone field form.
   *
   * @var {any}
   */
  objPhoneField = {
    "required": false,
    "readonly": false,
    "is_admin": false,
    "default_value": [],
    "key": "phone",
    "label": "phone",
    "controlType": "dialog",
    "space": 12,
    "has_primary": false,
  };

  /**
   * Page configs for the prepopulated contact options
   * from the selected exiting customer or site.
   *
   * @var {bIsCustomersEnd: boolean, bIsSitesEnd: boolean}
   */
  objPageConfig: {
    bIsCustomersEnd: boolean,
    bIsSitesEnd: boolean
  } = {
    bIsCustomersEnd: true,
    bIsSitesEnd: true
  }

  /**
   * List of contacts roles that are pre-loaded.
   *
   * @var {GlobalRecord[]}
   */
  arRolesList: GlobalRecord[] = [];

  arRelatedContactsData: PaginatedData;

  arCustomerAndSiteRelatedContacts: LooseObject[] = [];

  /**
   * Flags whenever the related contacts are loading.
   *
   * @type {boolean}
   */
  bRelatedContactsLoading: boolean = false;

  bContactPageLoading: boolean = false;

  /**
   * Internal Use: contains list of subscription that is used throughout the component that would be
   * cleaned up when this component is destroyed.
   *
   * @type {Subscription[]}
   */
  protected arSubscriptions: Subscription[] = [];

  /**
   * Checks if an existing contact was selected through the name autocomplete.
   *
   * @var {boolean}
   */
  bHasSelectedExistingContact = false;

  get displayRelatedContacts(): boolean {
    return filled(this.arRelatedContactsData) && filled(this.arRelatedContactsData.data);
  }

  get displayContactForm(): boolean {
    return !this.displayRelatedContacts;
  }

  constructor(
    private notifService: NotificationService,
    private formService: FormService,
    private searchService: SearchService,
    private recordService: RecordService,
    private acsSupportService: AcsSupportService,
    private listingService: ListingService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    const sub = this.sharedService.customerDataUpdate$.pipe(
      filter((bCallApi: boolean) => bCallApi && isId(this.sharedService.customerId)),
      tap(() => this.bRelatedContactsLoading = true),
      switchMap((bCallApi: boolean) =>
        this.acsSupportService.getContactCustomerAndSiteRolesForWizard(
          this.sharedService.customerId,
          get(this.sharedService.getSite(), 'site_id', null),
        ).pipe(
          finalize(() => this.bRelatedContactsLoading = false),
        )
      ),
    ).subscribe(
      (res: PaginatedData) => this.arRelatedContactsData = res,
      error => this.notifService.notifyError(get(error, 'error', 'error_occurred') || 'error_occurred')
    );

    this.form = this.formService.toFormGroup([...this.arFields, this.objPhoneField]);
    this.form.addControl('first_name', new FormControl('', Validators.required));
    this.form.addControl('last_name', new FormControl('', Validators.required));
    this.form.addControl('selected_customer', new FormControl(''));
    this.form.addControl('roles', new FormControl([]));

    this.setRoleDropdowns();
    this.setOnSearchContact();

    let formStatusSub: Subscription = this.form.statusChanges
      .pipe(distinctUntilChanged())
      .subscribe(res => {
        if (res === 'VALID' && this.customerId && this.objSelectedContact) {
          this.getContactRolesAsCustomer();
        }
      });

    this.arSubscriptions.push(formStatusSub);
    this.arSubscriptions.push(sub);
  }

  ngAfterViewInit() {
    if (this.preselectedContact) {
      this.prefillContactForm(new GlobalRecord({
        id: this.preselectedContact,
        match_percentage: 100,
        module: 'contacts'
      }));
    }
  }

  ngOnChanges(objChanges: SimpleChanges) {
    if (objChanges.customerId && !isEmpty(objChanges.customerId.currentValue)) {
      this.getRecord("default", "customers", this.customerId);
    }

    if (objChanges.siteId && !isEmpty(objChanges.siteId.currentValue)) {
      let objSite = this.sharedService.getSite();

      if (objSite != null && objChanges.siteId.currentValue == objSite.site_id) {
        this.prefillContactForm(new GlobalRecord({
          id: objSite.primary_contact_id,
          match_percentage: 100,
          module: 'contacts'
        }));
      }
    }

    if (filled(objChanges['bCustomerStepFinished']) && objChanges.bCustomerStepFinished.currentValue === true && !this.bOpenedFromMegaMenu) {
      if (blank(this.preselectedContact)) {
        this._skipStepActions();
      } else {
        this.next();
      }
    }
  }

  ngOnDestroy(): void {
    this.arSubscriptions.forEach(sub => sub.unsubscribe());
  }

  /**
   * When the autocomplete dropdown list reaches
   * the end.
   *
   * @returns {void}
   */
  onEndScroll(): void{
    if (this.objPageConfig.bIsCustomersEnd) {
      this.getRecord("next", "customers", this.customerId)
    }

    if (!this.objPageConfig.bIsCustomersEnd && this.objPageConfig.bIsSitesEnd) {
      this.getRecord("next", "sites", this.siteId)
    }
  }

  /**
   * If we have 11 or more contacts roles connected to a customer,
   * we need to paginate it, and this method does that work for us.
   *
   * @param {string} strPage
   * @param {string} strModule
   * @param {string} strModuleId
   *
   * @returns {void}
   */
  getRecord(
    strPage: 'next' | 'prev' | 'default' | 'reload' = null,
    strModule: string,
    strModuleId: string
  ): void {
    if (
      (strModule == 'sites' && !isEmpty(this.siteId)) ||
      (strModule == 'customers' && !isEmpty(this.customerId))
    ) {

      let strConfig = `${module}_roles`;
      this.listingService.setPaginationConfig(strConfig);

      let pagination = this.listingService.beforeFetching(strPage);

      this.listingService.fetchData(
        JSON.stringify(
          pagination['objPage']),
          'contact_roles',
          JSON.stringify({module: strModule, module_id: strModuleId}
        )
      ).subscribe(response => {

          this.listingService.afterFetching(response, strPage);
          this.listingService.updatePaginationConfig(strConfig);

          this.arRolesList = [...this.arRolesList, ...response['data'].map(item => {
            return {
              id: item['contact_id'],
              name: item['contact_text'],
              match_percentage: "100",
              module: "contacts"
            }
          })];

          let arRoles = this.arRolesList.filter((objRole, numIndex) => {
            return this.arRolesList.indexOf(objRole) === numIndex;
          });

          this.objContactSearch.$source.next(arRoles);

          if (strModule == 'customers') {
            this.objPageConfig.bIsCustomersEnd = response['hasNextToken'];
          }

          if (strModule == 'sites') {
            this.objPageConfig.bIsSitesEnd = response['hasNextToken'];
          }
      });
    }
  }

  /**
   * When the role value is changed.
   *
   * @param {any[]} arRoles
   *
   * @returns {void}
   */
  onRoleChange(arRoles: any[]): void {
    if (arRoles.length == 1) {
      arRoles[0]['primary'] = true;
      this.form.controls.roles.setValue([arRoles[0]]);
    }
  }

  /**
   * When the next button is clicked.
   *
   * @param {boolean} bAsNewCustomer
   *
   * @returns {void}
   */
  next(bAsNewCustomer: boolean = false): void {
    if (this.displayRelatedContacts) {
      this._skipStepActions();

      return;
    }

    if (bAsNewCustomer) {
      this.form.controls.selected_customer.setValue(null);
    }

    this.form.markAsDirty();
    this.form.markAsTouched();
    this.bSubmitted = true;

    if (!this.bHasSelectedExistingContact) {
      this.checkPhoneFieldsValidity();
    }

    if (this.form.invalid) {
      this.notifService.notifyError('please_complete_the_form');
    } else {
      let objFormValue = this.form.value;
      if (this.objSelectedContact) {
        objFormValue = { ...objFormValue, contact_id: this.objSelectedContact.id };
      }
      const objRelatedCustomer = this.form.controls.selected_customer.value;
      if (objRelatedCustomer) {
        objFormValue = {
          ...objFormValue,
          linked_customer: {
            first_name: objRelatedCustomer.first_name,
            last_name: objRelatedCustomer.last_name,
            name: objRelatedCustomer.name,
            type: objRelatedCustomer.type,
            customer_id: objRelatedCustomer.id,
            address: objFormValue.selected_customer.address,
          }
        };
      }

      objFormValue.email_address = this.form.controls.email_address.value.map((objEmailAddress: Select) => {
        return {
          email: objEmailAddress.text,
          primary: objEmailAddress.primary ? objEmailAddress.primary : '0',
        };
      });

      this.objContactData.emit(objFormValue);
    }
  }

  /**
   * Checks if the phone fields are valid, and sets the form as invalid if they are not.
   *
   * @returns {void}
   */
  private checkPhoneFieldsValidity(): void {
    this.form.controls['phone'].markAsTouched();
    this.form.controls['phone'].markAsDirty();

    const arPhones: Phone[] = get(this.form, 'value.phone', []);

    const bHasInvalidPhoneField = arPhones.some(phone => {
      return isEmpty(phone.type) || isEmpty(phone.code) || isEmpty(phone.number);
    });

    if (bHasInvalidPhoneField) {
      this.form.controls['phone'].setErrors({'incorrect': true});
    }
  }

  /**
   * Clears the form to initial values.
   *
   * @returns {void}
   */
  clearForm() {
    this.form.reset({
      first_name: '',
      last_name: '',
      email_address: '',
      phone: [],
      roles: []
    })
    this.objPhoneFieldValue = [];
    this.objSelectedContact = null;
    this.bSubmitted = false;
    this.bIsLinked = false;
  }

  /**
   * Responsible for filling the form once
   * a contact that was searched is selected.
   *
   * @param {GlobalRecord} objGlobalRecord
   *
   * @returns {void}
   */
  prefillContactForm(objGlobalRecord: GlobalRecord): void {

    if (isNil(objGlobalRecord.id) || trimEnd(objGlobalRecord.id).length < 1) {
      return;
    }

    this.recordService.getRecord(
      'contacts',
      objGlobalRecord.id,
      true
    ).subscribe(data => {
      this.bHasSelectedExistingContact = true;
      const contactData = data['record_details'];
      // Prefill the current record
      this.form.controls.first_name.setValue(contactData.first_name);
      this.form.controls.last_name.setValue(contactData.last_name);
      this.form.controls.email_address.setValue(contactData.email_address.map(x => {
        return new Select(x['email'], x['email'], x['primary']);
      }));
      this.form.controls.phone.setValue(contactData.phone.map(x => {
        return new Phone(x);
      }));
      this.objPhoneFieldValue = this.form.controls.phone.value;

      // Get form values snapshot. Later used for submitting the form
      this.objSelectedContact = objGlobalRecord;

      // Get Related Customers
      this.getContactRolesAsCustomer();
    });
  }

  /**
   * Get contact related customers
   *
   * @param {number} intPageNum
   *
   * @returns {void}
   */
  getContactRolesAsCustomer(intPageNum = 1): void{
    this.bIsLinked = false;
    this.objContactRoles = this.acsSupportService.getRoles('contacts', this.objSelectedContact.id, intPageNum, 20, 'customers').pipe(
      tap(response => {
        if (response['data']) {
          response['data'].forEach(item => {
            if (item['module_id'] == this.customerId) {
              this.bIsLinked = true;
            }
          })
        }
      }),
      shareReplay()
    );

    this.arSubscriptions.push(this.objContactRoles.subscribe());
  }

  /**
   * Since FC-3897, contact form is optional
   * so we allow them to skip this step.
   *
   * @returns {void}
   */
  skip(): void {
    this.notifService.sendConfirmation('skip_contact_confirm').subscribe(response => {
      if (response.answer === true) {
        this._skipStepActions();
      }
    })
  }

  /**
   * mark the option as primary
   *
   * @param {string} strId
   *
   * @returns {void}
   */
  setPrimaryRole(strId: string): void {
    this.form.controls.roles.value.map( option => {
      option.primary = (option.id == strId) ? true : false;
      return option;
    });
  }

  loadRelatedContacts(numPage: number = 1): void {
    this.bRelatedContactsLoading = true;

    const sub: Subscription = this.acsSupportService.getContactCustomerAndSiteRolesForWizard(
      this.sharedService.customerId,
      get(this.sharedService.getSite(), 'site_id', null),
      numPage
    ).pipe(
      finalize(() => this.bRelatedContactsLoading = false),
    ).subscribe(
      (res: PaginatedData) => this.arRelatedContactsData = res,
      error => this.notifService.notifyError(get(error, 'error', 'error_occurred') || 'error_occurred')
    );

    this.arSubscriptions.push(sub);
  }

  loadContactForm(): void {
    this.arRelatedContactsData = null;
  }

  /**
   * Set the role dropdown options from the API.
   *
   * @returns {void}
   */
  private setRoleDropdowns(): void {
    this.recordService.getDropdownRecord('contacts').subscribe(response => {
      if (response.body['customer_roles']['config']) {
        this.arRoles = response.body['customer_roles']['config'];
      }
    })
  }

  /**
   * Set the search contact typeheads and sources.
   *
   * @returns {void/kl}
   */
  private setOnSearchContact(): void{
    this.form.controls.first_name.valueChanges.subscribe(strTerm => {
      this.objContactSearch.typehead.next(strTerm);
    });

    this.objContactSearch.buildRelates(
      switchMap(term => {
        return this.searchService.global(term, 'contacts');
      }),
    );
  }

  /**
   * Emits the values when this step is skipped.
   *
   * @returns {void}
   */
  private _skipStepActions(): void {
    this.objContactData.emit(null);
    this.skipStepEmitter.emit(true);
  }
}