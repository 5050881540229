import { Injectable, Input } from '@angular/core';
import { LooseObject } from '../../../objects/loose-object';
import { Observable, Subject } from 'rxjs';
import { filled } from '../../../shared/utils/common';

@Injectable()
export class SharedService {

  bDisplayRelatedContacts: boolean = false;

  customerClickedNext: Subject<boolean> = new Subject<boolean>();

  customerDataUpdate$: Observable<boolean> = this.customerClickedNext.asObservable();

  /**
   * The site data to share in between the wizard components.
   *
   * @var {WizardSharedSite}
   */
  private objSiteData: WizardSharedSite|null = null;

  private _strCustomerId: string|null = null;

  private _strCustomerText: string|null = null;

  @Input()
  set customerId(id: string|null) {
    this._strCustomerId = id;
  }

  get customerId(): string|null {
    return this._strCustomerId;
  }

  @Input()
  set customerText(text: string|null) {
    this._strCustomerText = text;
  }

  get customerText(): string|null {
    return this._strCustomerText;
  }

  /**
   * Assign the site in the service.
   *
   * @param {LooseObject|null} objSite
   */
  setSite(objSite: LooseObject|null): void {
    if (filled(objSite)) {
      this.objSiteData = new WizardSharedSite({
        site_id: objSite.id,
        primary_contact_id: objSite.primary_contact_id,
        address_text: objSite.address_text
      });
    } else {
      this.objSiteData = null;
    }
  }

  /**
   * Retrieve the site data in the service.
   *
   * @returns {WizardSharedSite | null}
   */
  getSite(): WizardSharedSite | null {
    return this.objSiteData || null;
  }

  clearData(): void {
    this.customerId = null;
    this.customerText = null;
    this.setSite(null);
  }
}

export class WizardSharedSite {
  site_id: string;
  primary_contact_id: string;
  address_text: string;

  constructor(objWizardSharedSite: WizardSharedSite) {
    this.site_id = objWizardSharedSite.site_id;
    this.primary_contact_id = objWizardSharedSite.primary_contact_id;
    this.address_text = objWizardSharedSite.address_text
  }
}