import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef } from '@angular/core';
import { DropdownButtonActionItemDirective } from '../../../directives/component.directive';

@Component({
  selector: 'fieldmagic-primary-dropdown-button',
  template: `
    <fieldmagic-primary-button
      [isInProgress]="isInProgress"
      [disabled]="disabled"
      [icon]="icon"
      [klass]="klass"
      [variant]="variant"
      (click)="onOpen()"
      data-toggle="dropdown"
    >
      <span class="dropdown-toggle">{{ label | translate }}</span>
    </fieldmagic-primary-button>
    <div class="dropdown-menu dropdown-content fieldmagic-dropdown-button-content">
      <ng-container *ngFor="let actionItemTmp of actionItemTmps; let index = index;">
        <div
          class="dropdown-item fieldmagic-dropdown-button-item"
          (click)="onActionItemClick(index)"
        >
          <ng-container *ngTemplateOutlet="actionItemTmp"></ng-container>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `.fieldmagic-dropdown-button-item {
      cursor: pointer;
      font-size: 14px;
      padding: 4px 16px 4px 16px;
    }`,
    `.fieldmagic-dropdown-button-content {
      top: 5px !important;
    }`,
  ],
})
export class PrimaryDropdownButtonComponent {
  @Input() isInProgress: boolean = false;

  @Input() disabled: boolean = false;

  @Input() icon: string;

  @Input() klass: string | {
    [className: string]: boolean;
  };

  @Input() label: string;

  @Input() variant: 'error' | 'primary' | 'secondary' | 'success' | 'warning' = 'primary';

  @Output('opened') $opened = new EventEmitter<void>();

  /// Note: as of this writing, i cannot thing any possible way to
  /// named an action from consumer perspective. Index of the action is currently
  /// the way to solve the issue to trigger click event where the user clicks on the option
  /// adding the click even on the child will cause the other part of the option blind of the event
  @Output('selected') $selected = new EventEmitter<number>();

  @ContentChildren(
    DropdownButtonActionItemDirective, {
      read: TemplateRef
    }
  )
  actionItemTmps: QueryList<TemplateRef<any>>;

  onOpen = () => this.$opened.emit();

  onActionItemClick = (idx: number) => this.$selected.next(idx);
}