import { LooseObject } from './loose-object';

export interface AdvanceSearchboxTemplate {
    model: string;
    label: string;
    placeholder: string;
    type: 'INPUT' | 'OPERATORS';
    multiple?: boolean | number | '*';
    operators?: Array<'eq' | 'lt' | 'le' | 'ne' | 'gt' | 'ge' | 'startsWith' | 'endsWith' | 'contains'>;
    mask?: MaskInterface;
    filterFieldType?: 'normal' | 'custom' | 'special';
    /**
     * Set this value to remote for remote data or StaticEnum for static dropdown
     */
    domains?: 'remote' | StaticEnum[];
    /**
     * If domains has a value. This will bind a single propery to the label
     */
    bindLabel?: string;
    /**
     * If domains has a value. This will bind a single propery to the value.
     */
    bindValue?: string;
    /**
     * For remote fields. Set where to get the remote module will be fetched.
     */
    remoteModule?: string;
    /**
     * Data Field Type. Defined on DB
     */
    dataType?: string;
    /**
     * FC-3652: to determine which module requested
     */
    moduleOrigin?: string;
    /**
     * FC-3928: default value for remote data
     */
    option?: LooseObject;
    /**
     * FC-4276: additional default filter
     */
    additional_filter?: LooseObject;
    translated_label?: string;
    /**
     * Additional label fo the original field label
     */
    extraLabel?: string;
};

export interface StaticEnum {
    label: string;
    value: number | string | boolean;
}

interface MaskInterface {
    mask: any;
    specialCharacters?: any;
    patterns?: any;
    dropSpecialCharacters?: boolean;
    clearIfNotMatch?: boolean;
}
