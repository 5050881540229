<span class="dropdown nowrap filter-button">

  <span class="pr-3 span-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <fa-icon [icon]="['fas', 'filter']" class="pr-1"></fa-icon>
    {{ 'filter' | translate }}
  </span>

  <div class="dropdown-menu center-align filter-dropdown">
    <ng-container *ngIf="arSavedFilters">

      <div class="table mb-0 w-100">
        <div class="row m-0">
          <div class="cell">
            <span class="filter-labels">{{ 'default_filters' | translate }}</span>
          </div>
        </div>
        <div *ngFor="let filter of arSavedFilters.default | keyvalue" class="row m-0 mt-1">
          <div class="col-xs-12 col-md-12 filter-items">
            <a id="filter_{{filter.key}}"
              (click)="setFilter(filter.key, 'saved_filter', true)"
              matTooltip="{{ filter.key | translate }}"
              class="pointer">
              {{ filter.key | translate }}
            </a>
            <fa-icon *ngIf="arModel['filter_name'] == filter.key"
              class="text-success ml-2"
              [icon]="['fas', 'check']">
            </fa-icon>
          </div>
        </div>
      </div>

      <div *ngIf="(arSavedFilters.saved | json) != '[]'">
        <hr />
        <div class="table mb-0 w-100">
          <div class="row m-0">
            <div class="cell">
              <span class="filter-labels">{{ 'saved_filters' | translate }}</span>
            </div>
          </div>
          <div *ngFor="let filter of arSavedFilters.saved | keyvalue" class="row m-0 mt-1">
            <div class="col-xs-12 col-md-12 filter-items">
              <a id="saved_filter_{{filter.key}}"
                (click)="setFilter(filter.key, 'saved_filter', true)"
                matTooltip="{{ filter.key | translate }}"
                class="pointer">
                {{ filter.key | translate }}
                  <span *ngIf="filter.value['is_shared'] && !filter.value['is_removable']">
                    <b>({{ 'shared_filter' | translate }})</b>
                  </span>
              </a>
              <fa-icon *ngIf="arModel['filter_name'] == filter.key"
                class="text-success ml-2"
                [icon]="['fas', 'check']">
              </fa-icon>
              <fa-icon *ngIf="filter.value['is_removable']"
                class="text-danger ml-2 pointer"
                (click)="deleteFilter(filter.value)"
                [icon]="['fas', 'times']">
              </fa-icon>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="(arSavedFilters.saved | json) == '[]' || (arSavedFilters.saved | json) == '{}'">
        <hr />
        <div class="table mb-0 w-100">
          <div class="row m-0">
            <div class="cell">
              <span class="filter-labels">{{ 'saved_filters' | translate }}</span>
            </div>
          </div>
          <div class="row m-0 mt-1">
            <div class="col-xs-12 col-md-12 filter-items">
              <span class="dropdown-item-filter">{{ 'no_available_filters' | translate }}</span>
            </div>
          </div>
        </div>
      </div>

    </ng-container>
  </div>
</span>