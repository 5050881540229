import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LooseObject } from '../../objects/loose-object';
import { environment } from '../../../environments/environment';
import { AcsConfig, ContactRoleFilter, PaginatedData } from './acs-support';
import { GlobalRecord } from '../..//objects/global-record';
import { map } from 'rxjs/operators/map';
import { isId } from '../../shared/utils/common';

@Injectable()

export class AcsSupportService {

  strSupportBaseUrl = environment.url + '/record/support';

  pingAcs: BehaviorSubject<string>;

  constructor(
    private http: HttpClient,
  ) {
    this.pingAcs = new BehaviorSubject<string>('');
  }

  getConfig(): Observable<AcsConfig> {
    const strUrl = `${this.strSupportBaseUrl}/config`;
    return this.http.post<AcsConfig>(strUrl, {});
  }

  updateConfig(objConfig: AcsConfig) {
    const strUrl = `${this.strSupportBaseUrl}/update_config`;
    return this.http.post<AcsConfig>(strUrl, {
      acs: JSON.stringify(objConfig),
    });
  }

  getDetails(strRecordId: string, strModule: string): Observable<LooseObject> {
    const strUrl = `${this.strSupportBaseUrl}/details/${strModule}`;
    return this.http.post<LooseObject>(strUrl, {
      record_id: strRecordId,
    });
  }

  getRelatedRecords(strRelatedModule: 'activities', strParentRecordId: string, strParentRecordModule: string, intPage: number = 1): Observable<PaginatedData> {
    const strUrl = `${this.strSupportBaseUrl}/related/${strRelatedModule}`;
    return this.http.post<PaginatedData>(strUrl, {
      parent_id: strParentRecordId,
      parent_module: strParentRecordModule,
      page: intPage,
    });
  }

  getRoles(strModule: string, strRecordId: string, intPage: number = 1, intPageSize: number = 5, strRoleAs = 'customers_sites'): Observable<PaginatedData> {
    const strUrl = `${this.strSupportBaseUrl}/related/roles/${strModule}?page_size=${intPageSize}&page=${intPage}`;
    return this.http.post<PaginatedData>(strUrl, {
      record_id: strRecordId,
      role_as: strRoleAs,
    }).pipe(
      map(paginatedData => {
        const updatedData = paginatedData.data.map((v) => {
          // For forward compatiblity with multiple role on the UAT
          if (!Array.isArray(v.role)) {
            v.role = [
              {id: v.role, text: v.role, primary: true},
            ];
          }
          return v;
        });
        Object.assign(paginatedData.data, updatedData);
        return paginatedData;
      })
    );
  }

  getOpportunities(objContactRoleFilter: ContactRoleFilter, intPage: number = 1): Observable<PaginatedData> {
    const strUrl = `${this.strSupportBaseUrl}/related/opportunities`;
    let objFilter = Object.assign(objContactRoleFilter, {page: intPage});
    return this.http.post<PaginatedData>(strUrl, objFilter);
  }

  getJobs(objContactRoleFilter: ContactRoleFilter, intPage: number = 1): Observable<PaginatedData> {
    const strUrl = `${this.strSupportBaseUrl}/related/jobs`;
    let objFilter = Object.assign(objContactRoleFilter, {page: intPage});
    return this.http.post<PaginatedData>(strUrl, objFilter);
  }

  getContactCustomerAndSiteRolesForWizard(strExistingCustomerId: string, strExistingSiteId: string|null, numPage: number = 1): Observable<PaginatedData> {
    return this.http.post<PaginatedData>(
      `${this.strSupportBaseUrl}/related/roles/wizard/contacts?page=${numPage}`,
      new URLSearchParams({
        customer_id: strExistingCustomerId,
        ...(isId(strExistingSiteId) && {
          site_id: strExistingSiteId
        })
      })
    );
  }

  triggerPingAcs(updateList: string): void {
    this.pingAcs.next(updateList);
  }
}
