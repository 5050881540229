import { LooseObject } from '../../../../../objects/loose-object';
import { RelateData } from './relate-data';
import { TaskMetadata } from './task-metadata';
import { Users } from '../../../../../objects/users';

export interface OpportunityMetadata {

  assigned_user: RelateData | null;

  department: RelateData | null;

  customer: LooseObject | null;

  site?: LooseObject | null;

  forecast_close_date: string

  full_address: string;

  id: string;

  opportunity_number: string;

  tasks: Array<TaskMetadata> | null;

  is_deleted: boolean;

  schedule_assigned_user?: Users|null;

  reschedulable?: boolean;
}

export class OpportunityMetadataC {

  assigned_user: RelateData | null;

  department: RelateData | null;

  customer: LooseObject | null;

  site?: LooseObject | null;

  forecast_close_date: string

  full_address: string;

  id: string;

  opportunity_number: string;

  tasks: Array<TaskMetadata> | null;

  metadata_type: string = 'opportunity';

  opportunity?: LooseObject = {};

  is_deleted: boolean;

  schedule_assigned_user: Users|null;

  reschedulable?: boolean;

  constructor(properties: OpportunityMetadata) {
    this.id = properties.id;
    this.opportunity_number = properties.opportunity_number;
    this.full_address = properties.full_address;
    this.forecast_close_date = properties.forecast_close_date;

    this.assigned_user = properties.assigned_user || null;
    this.department = properties.department || null;
    this.customer = properties.customer || null;
    this.site = properties.site || null;
    this.tasks = properties.tasks || null;
    this.is_deleted = properties.is_deleted || false;
    this.schedule_assigned_user = properties.schedule_assigned_user || null;
    this.reschedulable = properties.reschedulable

    this.opportunity = JSON.parse(JSON.stringify(properties));
  }
}