import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { isNull, isUndefined } from 'lodash-es';
import { take } from 'rxjs/operators';
import { OptionalValue } from '../shared/utils/common';

export { MAT_DIALOG_DATA as DIALOG_DATA } from '@angular/material';

@Injectable()
export class DialogService {
  constructor(
    private readonly builder: MatDialog,
  ) { }

  show<C, D = any, R = any>(props: ShowDialogProps<C, D, R>): void {
    props = Object.assign({
      dismissable: true,
    }, props);

    const dialog = this.builder.open<C, D, R>(
      props.component,
      {
        data: props.data,
        panelClass: this._computeDialogSize(props.size),
        disableClose: ! props.dismissable,
        closeOnNavigation: ! props.dismissable,
        minWidth: this._computeWidth(props.size),
        maxWidth: this._computeWidth(props.size),
      },
    );

    if (!isUndefined(props.beforeClosing)) {
      dialog.beforeClosed()
        .pipe(take(1))
        .subscribe(() => props.beforeClosing());
    }

    if (!isUndefined(props.afterClosing)) {
      dialog.afterClosed()
        .pipe(take(1))
        .subscribe((result) => props.afterClosing(result));
    }
  }

  close<I, R = any>(props: CloseDialogProps<I, R>): void {
    const matches = this.builder.openDialogs.filter((ref) => ref.componentInstance === props.instance);

    if (matches.length < 1) {
      return;
    }

    const dialog = matches[0];

    if (isUndefined(dialog) || isNull(dialog)) {
      return;
    }

    dialog.close(props.result);
  }

  private _computeDialogSize(size: DialogSize = 'md'): string {
    switch (size) {
      case 'full':
        return 'w-100';
      case 'lg':
        return 'w-75';
      case 'sm':
        return 'w-30';
      case 'xs':
        return 'w-20';
      case 'xxs':
        return 'w-10';
      case 'md':
      default:
        return 'w-50';
    }
  }

  private _computeWidth(size: DialogSize = 'md'): string {
    switch (size) {
      case 'full':
        return '100%';
      case 'lg':
        return '75%';
      case 'sm':
        return '30%';
      case 'xs':
        return '20%';
      case 'xxs':
        return '10%';
      case 'md':
      default:
        return '50%';
    }
  }
}

type IDialogComponent<C> = ComponentType<C> | TemplateRef<C>;

type ShowDialogProps<C, D = any, R = any> = {
  /**
   * The dialog component that will be shown to the user.
   */
  component: IDialogComponent<C>;
  /**
   * The data that will be injected to the component when rendering.
   * Useful to pass data to the rendering component.
   */
  data?: D
  /**
   * The size of the dialog to be displayed. Defaults to `md`
   */
  size?: DialogSize;
  /**
   * A flag that dictates that the rendered dialog can be closed using escape or clicking
   * outside of the dialog. Default: true,
   */
  dismissable?: boolean;
  /**
   * A hook that will be called before a dialog is closed
   */
  beforeClosing?: () => void;
  /**
   * A hook that will be called when dialog is closed. If a result is provided
   * from the closing dialog it will be passed down to this hook.
   */
  afterClosing?: (result: OptionalValue<R>) => void;
}

type CloseDialogProps<I, R = any> = {
  /**
   * The instance of the dialog to be closed
   */
  instance: I;
  /**
   * The result to be emitted for listeners of the dialog when closed
   */
  result?: R;
}

type DialogSize = 'full' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
