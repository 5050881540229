import { filled } from '../../../shared/utils/common';
import { LooseObject } from '../../../objects/loose-object';

interface SchedulerTaskTabbedDataInterface {
  id: string,
  activity_id: string|null,
  estimated_duration: number,
  due_date: string,
  user_id: string,
  user_text: string,
  activity_log_type_id: string|null,
  job_id: string|null,
  opportunity_id: string|null
  task: LooseObject|null;
  job: LooseObject|null;
  opportunity: LooseObject|null;
}

export class SchedulerTaskTabbedData implements SchedulerTaskTabbedDataInterface {

  id: string;
  activity_id: string|null;
  estimated_duration: number;
  due_date: string;
  user_id: string;
  user_text: string;
  activity_log_type_id: string|null;
  job_id: string|null;
  opportunity_id: string|null;
  task: LooseObject|null;
  job: LooseObject|null;
  opportunity: LooseObject|null;
  is_primary: boolean;

  constructor(properties: LooseObject) {
    this.id = properties.id;
    this.activity_id = properties.activity_id;
    this.estimated_duration = properties.estimated_duration;
    this.due_date = properties.due_date;
    this.user_id = properties.user_id;
    this.user_text = properties.user_text;
    this.activity_log_type_id = properties.activity_log_type_id;
    this.job_id = properties.job_id;
    this.opportunity_id = properties.opportunity_id;
    this.task = filled(properties.task)
      ? properties.task
      : null;
    this.job = filled(properties.job)
      ? properties.job
      : null;
    this.opportunity = filled(properties.opportunity)
      ? properties.opportunity
      : null;
    this.is_primary = properties.is_primary || false;
  }
}