import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { UsersComponent } from './users.component';
import { ViewComponent } from './view/view.component';
import { InvitesComponent } from './invites/invites.component';


const routes: Routes = [
    { path: 'invites', component: InvitesComponent, pathMatch: 'full' },
    { path: '', component: UsersComponent },
    { path: ':user', component: ViewComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class UsersRoutingModule { }
