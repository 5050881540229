import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SchedulerTaskTabbedData } from '../../objects/scheduler-task-tabbed-data';
import { RecordService } from '../../../../services/record.service';
import { FormService } from '../../../../services/form.service';
import { NotificationService } from '../../../../services/notification.service';
import { filled, fallback } from '../../../../shared/utils/common';
import { get } from 'lodash-es';
import { LooseObject } from '../../../../objects/loose-object';
import { Users } from '../../../../objects/users';
import * as moment from 'moment';

@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.scss'],
})
export class ScheduleFormComponent implements OnChanges {

  @Input('schedule') schedule: SchedulerTaskTabbedData|null = null;

  @Input('activity-id') strActivityId: string|null = null;

  @Input('job-id') strJobId: string|null = null;

  @Input('opportunity-id') strOpportunityId: string|null = null;

  /**
   * The default assigned user when creating a schedule. Only has value in create mode.
   *
   * @type {ScheduleDefaultAssignedUser}
   */
  @Input('default-user-on-create') defaultUser: Users;

  @Output() scheduleFormSave = new EventEmitter<boolean>();

  form: FormGroup;

  fields: any[] = [
    {
      required: true,
      readonly: false,
      is_admin: false,
      default_value: '',
      key: 'due_date',
      label: 'due_date',
      controlType: 'datetime',
      space: 12,
      has_primary: false
    },
    {
      required: true,
      readonly: false,
      is_admin: false,
      default_value: 1,
      key: 'estimated_duration',
      label: 'estimated_duration',
      controlType: 'number',
      space: 6,
      is_hidden: false,
      has_primary: false
    },
    {
      required: true,
      readonly: false,
      is_admin: false,
      default_value: null,
      key: 'user_id',
      label: 'technician',
      controlType: 'relate',
      space: 6,
      is_hidden: false,
      has_primary: false,
      note: '',
      maxSelectedItems: 100,
      option_count: 0,
      default_text: '',
      module: 'users',
      multiple: false,
      options: [],
      add_tag: false,
      filter: {
        has_subcontractor: true
      }
    }
  ];

  strMode: 'create' | 'edit' = 'create';

  bSubmitted = false;

  bSaving = false;

  get headerText(): string {
    return `${this.strMode}_schedule`;
  }

  constructor(
    private recordService: RecordService,
    private formService: FormService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if ('schedule' in changes) {
      const numTechnicianIndex: number = this.fields.findIndex(field => field.key === 'user_id');
      const numDueDateIndex: number = this.fields.findIndex(field => field.key === 'due_date');

      if (filled(get(changes, 'schedule.currentValue'))) {
        this.strMode = 'edit';

        this.fields[numDueDateIndex].default_value = changes.schedule.currentValue.due_date;

        this.fields[numTechnicianIndex].default_text = changes.schedule.currentValue.user_text;
        this.fields[numTechnicianIndex].options = [
          {
            id: changes.schedule.currentValue.user_id,
            text: changes.schedule.currentValue.user_text,
            disabled: false
          }
        ];

        this.form = this.formService.toFormGroup(this.fields);
        this.form.patchValue(changes.schedule.currentValue);
        this.cdr.detectChanges();

      } else {
        this.strMode = 'create';

        if (filled(this.defaultUser)) {
          this.fields[numTechnicianIndex].default_text = this.defaultUser.name;
          this.fields[numTechnicianIndex].options = [
            {
              id: this.defaultUser.id,
              text: this.defaultUser.name,
              disabled: false
            }
          ];
        }

        const currentMoment = moment().utc();
        // Round up to the next whole hour
        const roundedDate = currentMoment.minute(0).second(0).millisecond(0);
        roundedDate.add(1, 'hour');

        const strRoundedDateString = roundedDate.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

        this.fields[numDueDateIndex].default_value = strRoundedDateString;

        this.form = this.formService.toFormGroup(this.fields);

        if (filled(this.defaultUser)) {
          this.form.patchValue({
            user_id: this.defaultUser.id,
            due_date: strRoundedDateString,
            estimated_duration: 1
          });

          this.cdr.detectChanges();
        }
      }
    }
  }

  cancel(): void {
    this.scheduleFormSave.emit(false);
  }

  save(): void {
    this.form.markAsDirty();
    this.form.markAsTouched();

    this.bSubmitted = true;

    if (this.form.invalid) {
      this.notificationService.notifyError('please_complete_the_form');
    } else {
      this.bSaving = true;

      const objFormValue: LooseObject = this.form.value;
      let data: LooseObject = {};

      const strTaskId: string|null = fallback(
        this.strActivityId,
        {
          fallback: () => get(this.schedule, 'activity_id', null) || null,
        }
      )

      if (this.strMode === 'create') {
        data = {
          ...objFormValue,
          activity_id: strTaskId,
          job_id: this.strJobId,
          opportunity_id: this.strOpportunityId
        }
      } else {
        data = {
          ...this.schedule,
          ...objFormValue,
          activity_id: strTaskId,
          job_id: this.strJobId,
          opportunity_id: this.strOpportunityId
        };
      }

      this.recordService.saveRecord('scheduler_tasks', data, get(this.schedule, 'id', null)).subscribe(
        res => {
          const strNotifKey: 'create' | 'update' = this.strMode === 'create'
            ? 'create'
            : 'update';

          this.notificationService.notifySuccess(`schedule_${strNotifKey}_success`);
          this.scheduleFormSave.emit(true);
        },
        err => {
          this.notificationService.notifyError('header_notification.generic_fail');
        }
      )
    }
  }
}