import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { join, trimEnd, trim, isEmpty, isNil, isString, entries, isMap, isObject, toString, isArray, isBoolean } from 'lodash-es';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { blank, data_get, data_has, filled } from '../shared/utils/common';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _notifications: NotificationService,
  ) { }

  callPrivate$<R = unknown>(props: ApiCallProps<R>): Observable<R | null> {
    const endpoint = join([
      trimEnd(environment.base_api_url, '/'),
      'private',
      trim(props.path, '/'),
    ], '/');

    return this._http.request<Record<string, any>>(props.action, endpoint, {
      ... (!isEmpty(props.queryParams) && {
        params: this._transformQuery(props.queryParams),
      }),
      ... (!isEmpty(props.body) && {
        body: this._transformBody(props.body),
      }),
      observe: 'response',
    }).pipe(
      tap({
        error: (response) => {
          const callback = props.onErrorResponse;

          if (response instanceof HttpErrorResponse) {
            let error = response.error;

            if (
              props.notifyOnFailure
              && (response.status == 422 || response.status == 400)
              && props.expectsNewFormat
              && filled(data_get(error, 'message'))
            ) {
              this._notifications.notifyError(data_get(error, 'message'));
            }

            if (response.status == 403) {
              this._notifications.notifyNotAllowed();
            }
          }

          if (response instanceof HttpErrorResponse && !isNil(callback)) {
            const data = new Map<string, any>()

            let error = response.error;

            if (response.status == 403) {
              this._notifications.notifyNotAllowed();
            }

            if (isString(error)) {
              error = {
                message: error,
              };
            }

            for (const [key, value] of Object.entries(response.error)) {
              data.set(key, value);
            }

            return callback(response.status, data);
          }
        }
      }),
      map((response) => {
        if (response.status >= 200 && response.status <= 299) {
          const data = new Map<string, any>();

          let content;

          if (props.expectsNewFormat && data_has(response.body, 'item')) {
            content = data_get(response.body, 'item');
          } else {
            content = response.body;
          }

          if (blank(content)) {
            return null;
          }

          for (const [key, value] of Object.entries(content)) {
            data.set(key, value);
          }

          return props.onSuccessResponse(data);
        }

        return null;
      }),
    );
  }

  _transformQuery(query: ApiQueryParams): ApiQueryParams {
    let params = {};

    for (let [key, value] of entries(query)) {
      if (isNil(value)) {
        value = '';
      }

      params[key] = value;
    }

    return params;
  }

  _transformBody(body: Map<string, any> | Record<string, any>): URLSearchParams {
    let params = new URLSearchParams();

    for (let [key, value] of entries(body)) {
      if (isMap(value) || isObject(value)) {
        value = JSON.stringify(value);
      } else if (isBoolean(value)) {
        value = value ? '1' : '0';
      } else if (!isString(value)) {
        value = toString(value);
      }

      params.set(key, value);
    }

    return params;
  }
}

type ApiCallProps<R> = {
  path: string;
  action: 'post' | 'get' | 'delete' | 'patch' | 'put' | 'head';
  queryParams?: ApiQueryParams;
  body?: Map<string, any> | Record<string, any>;
  headers?: {
    [key: string]: string[],
  };
  onSuccessResponse: (response: Map<string, any>) => R | null;
  expectsNewFormat?: boolean;
  onErrorResponse?: (status: number, data: Map<string, any>) => void;
  /// automatically notifies when api responded with 400 or 422
  /// but only works when expectsNewFormat is true
  notifyOnFailure?: boolean;
}

type ApiQueryParams = Record<string, string | string[]>;
