import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsideComponent } from './components/aside/aside.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { CollapseModule } from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '../../../modules/font-awesome.module';
import { NgxPopperModule } from 'ngx-popper';
import { ActivityModule } from '../../../modules/activity.module';
import { ChecklistsModule } from '../../../../admin/checklists/checklists.module';
import { StockLevelModule } from '../../../../module/stock-level/stock-level.module';
import { ClipboardModule } from 'ngx-clipboard';
import { ImportModule } from '../../../../admin/import/import.module';
import { SharedModule } from '../../../modules/shared.module';
import { SyncInvoiceToAccountingComponent } from '../../../../module/jobs/sync-invoice-to-accounting/sync-invoice-to-accounting.component';
import { UsersModule } from '../../../../admin/users/users.module';

@NgModule({
  declarations: [
    AsideComponent,
    NavigationComponent,
    SyncInvoiceToAccountingComponent,
  ],
  imports: [
    NgxPopperModule.forRoot({
      placement: 'right',
      disableAnimation: true
    }),
    CommonModule,
    CollapseModule.forRoot(),
    FontAwesomeModule,
    TranslateModule,
    RouterModule,
    ActivityModule,
    ChecklistsModule,
    StockLevelModule,
    RouterModule,
    ImportModule,
    ClipboardModule,
    SharedModule.forRoot(),
    UsersModule,
  ],
  exports: [
    AsideComponent,
    NavigationComponent,
  ],
  entryComponents: [
    SyncInvoiceToAccountingComponent,
  ]
})
export class DashboardModule { }
