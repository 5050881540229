import { ErrorHandler, Injector } from '@angular/core';
import { config, notify } from 'atatus-spa'
import { environment } from '../../environments/environment';
import { LocalStorageService } from '../services/local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';

config(environment.atatus_config, {
  hashRoutes: true,
}).install();

export class AtatusErrorHandler implements ErrorHandler {

  static localStorageService: LocalStorageService;

  handleError(error:any) : void {
    if (atatus) {
      // Don't send errors to Atatus if 401.
      if (error.status != undefined && error.status == 401) {
        console.log("Unauthorized");
      }  else if (error.status != undefined && error.status == 403) {
      // Don't send if 403 as this means they're just not allowed to access something.
        console.log("Forbidden");
      } else if (error.status != undefined && error.status == 404) {
        console.log("Not Found");
      } else if (error.status == 422) {
        console.log('Validation error. Please see response or handle the response');
      } else {
        if (error.message != undefined && ! error.message.toLowerCase().includes("loading chunk")) {

          const injector: any = Injector.create([{ provide: LocalStorageService, useClass: LocalStorageService, deps: [] }]);
          AtatusErrorHandler.localStorageService = injector.get(LocalStorageService);

          const strDefaultValue = 'Unknown';

          let strUserName = AtatusErrorHandler.localStorageService.getItem('user_name') != null ?
            AtatusErrorHandler.localStorageService.getItem('user_name') : strDefaultValue;
          let strUserEmail = AtatusErrorHandler.localStorageService.getItem('user_email') != null ?
            AtatusErrorHandler.localStorageService.getItem('user_email') : strDefaultValue;
          let strCurrentClient = AtatusErrorHandler.localStorageService.getItem('current_client');

          let objCustomData = { user_name: strUserName, email:  strUserEmail };

          if (strCurrentClient != null) {

            let objCurrentClient = JSON.parse(strCurrentClient);

            let objClientData = {
              name: objCurrentClient.name !== undefined ? objCurrentClient.name : strDefaultValue,
              client_id: objCurrentClient.client_id !== undefined ? objCurrentClient.client_id : strDefaultValue,
              user_type: objCurrentClient.level !== undefined ? objCurrentClient.level : strDefaultValue
            };

            objCustomData['client_data'] = objClientData;

            if (error instanceof HttpErrorResponse) {
              objCustomData['response'] = JSON.stringify({
                status: error.status,
                body: error.error,
              });
            }
          }

          // Send the error to Atatus with custom data (email, user name and client data)
          notify(error.originalError || error, objCustomData);

        }
        console.error(error);
      }
    }
  }
}