import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {

  objTableHeaderData: object[];

  constructor() {

    /**
     * First value should be a translatable label.
     * the following values should be the field's key.
     */
    this.objTableHeaderData = [
      {
        id: 'level',
        label: 'user_type',
        relate_display: 'level',
        searchable: false,
        sortable: false,
        option: 'level'
      },
      {
        id: 'department_id',
        relate_display: 'department_name',
        label: 'department',
        searchable: false,
        sortable: false,
        relate: 'departments',
        option: ''
      },
      {
        id: 'name',
        label: 'name',
        searchable: true,
        sortable: true,
        option: ''
      },
      {
        id: 'created_at',
        label: 'date_created',
        searchable: true,
        sortable: true,
        option: 'history_date'
      },
      {
        id: 'updated_at',
        label: 'date_modified',
        searchable: true,
        sortable: true,
        option: 'history_date'
      }
    ];
  }

  ngOnInit() {}

}