import { Component, Input } from '@angular/core';
import { isNil, isEmpty, get } from 'lodash-es';
import { Select } from '../../../../../../objects/select';
import { Summary } from '../../../../objects/wizard';
import { AssetData } from '../../../../objects/asset-data';
import { ClientStoreService } from '../../../../../../services/client-store.service';
import { filled, blank, isId } from '../../../../../../shared/utils/common';

@Component({
  selector: 'app-job-summary',
  templateUrl: './job-summary.component.html',
  styleUrls: [
    './job-summary.component.scss',
    '../../../../styles/shared_wizard_styles.scss',
  ]
})
export class JobSummaryComponent {
  @Input() bHasNoCustomer: boolean = false;

  /**
   * Values of the forms to make
   * a summarization.
   *
   * @var {Summary}
   */
  @Input() summary: Summary;

  /**
   * Contains asset data when creating job from asset.
   *
   * @var {AssetData}
   */
  @Input() objAssetData: AssetData;

  /**
   * If department tracking enabled.
   *
   * @var {boolean}
   */
  bDepartmentTracking: boolean = false;

  get jobAssets(): Select[] {
    const arJobAssets: Select[] = get(this.summary, 'job.assets', []) || [];

    const existingAssetInJobAssets: Select|null = arJobAssets
      .find((item: Select) => item.id === get(this.objAssetData, 'asset_id')) || null;

    if (blank(existingAssetInJobAssets) && isId(get(this.objAssetData, 'asset_id'))) {
      arJobAssets.push(new Select(
        get(this.objAssetData, 'asset_id'),
        `[${get(this.objAssetData, 'asset_type_text', '')}] ${get(this.objAssetData, 'serial_number', '')}`
      ));
    }

    return arJobAssets;
  }

  /**
   * Generates a string summary of the linked checklists
   *
   * @returns {string}
   */
  get checklistsSummaryText(): string {
    let arValue: Select[] = get(this.summary, 'job.checklists');

    if (isNil(arValue) || isEmpty(arValue)) {
      return '--';
    }

    let strSummary: string = '';

    arValue.forEach((item, index) => {
      if (index === arValue.length - 1) {
        strSummary += `[${item.text}]`;
      } else {
        strSummary += `[${item.text}] `;
      }
    });

    return strSummary;
  }

  get customerTypeSummaryText(): string {
    return get(this.summary, 'customer.type');
  }

  get customerNameSummaryText(): string {
    if (get(this.summary, 'customer.type') === 'organization') {
      return get(this.summary, 'customer.name');
    } else {
      let strFname: string = get(this.summary, 'customer.first_name') || '';
      let strLname: string = get(this.summary, 'customer.last_name') || '';

      return `${strFname} ${strLname}`;
    }
  }

  constructor(
    private client: ClientStoreService
    ) {
    this.bDepartmentTracking = client.isDepartmentTracking();
  }

}
