<ng-container *fcHasPermission="'stock_management'; else notPermitted">
<div class="pagination-body">
  <div class="container">
    <div class="row pt-2 pb-2">
      <div class="col-sm-6 pt-1">
        <span class="module-title">
          <fa-icon [icon]="['fa', 'sliders-h']" aria-hidden="true" class="pr-2"></fa-icon>{{ 'stock_adjustments' | translate }}
        </span>
      </div>
      <div class="col-sm-6 pr-2">
        <fc-common-pagination [objPagination]="list" (objNavigate)="getList($event)"></fc-common-pagination>
      </div>
    </div>

    <div *ngIf="recordList == 0" class="row border-top p-3">
      <div class="col-12 text-center">
        {{ 'no_results' | translate }}
      </div>
    </div>

    <div class="row border-top p-2" *ngFor="let objStockAdjustment of recordList">
      <div class="col-12 pl-1 pr-1">
        <div class="row">
          <div class="col-2 pr-0 pl-2 text-center">
            <div *ngIf="objStockAdjustment.destination_warehouse_id && !objStockAdjustment.source_warehouse_id">
              <span *ngIf="objStockAdjustment['quantity'] > 0" class="text-label text-success">
                <fa-icon [icon]="['fa', 'plus']" aria-hidden="true"></fa-icon>
                {{ objStockAdjustment['quantity'] }}
              </span>
              <span *ngIf="objStockAdjustment['quantity'] < 0" class="text-label text-danger">
                <fa-icon [icon]="['fa', 'minus']" aria-hidden="true"></fa-icon>
                {{ objStockAdjustment['quantity'] | abs }}
              </span>
            </div>
            <div *ngIf="!objStockAdjustment.destination_warehouse_id && objStockAdjustment.source_warehouse_id">
              <span class="text-label text-danger">
                <fa-icon [icon]="['fa', 'minus']" aria-hidden="true"></fa-icon>
                {{ objStockAdjustment['quantity'] }}
              </span>
            </div>
            <div *ngIf="objStockAdjustment.destination_warehouse_id && objStockAdjustment.source_warehouse_id">
              <span class="text-label text-primary">
                <fa-icon [icon]="['fa', 'exchange-alt']" aria-hidden="true"></fa-icon>
                {{ objStockAdjustment['quantity'] }}
              </span>
            </div>
          </div>
          <div class="col-3 pr-0 pl-0 text-center">
            <span *ngIf="hasNoRelateField(objStockAdjustment) && objStockAdjustment.quantity == 0">
              {{ 'created' | translate }}
            </span>
            <span *ngIf="hasNoRelateField(objStockAdjustment) && objStockAdjustment.quantity != 0">
              {{ 'used' | translate }}
            </span>
            <span *ngIf="objStockAdjustment.purchase_order_id">
              <a *ngIf="objStockAdjustment.purchase_order_text" [routerLink]="getLink('purchase_orders', objStockAdjustment.purchase_order_id)">
                #{{ objStockAdjustment.purchase_order_text }}
              </a>
              <ng-container *ngIf="!objStockAdjustment.purchase_order_text">
                <span>--</span>
              </ng-container><br />
            </span>
            <span *ngIf="objStockAdjustment.supplier_invoice_id">
              <a *ngIf="objStockAdjustment.supplier_invoice_text" [routerLink]="getLink('supplier_invoices', objStockAdjustment.supplier_invoice_id)">
                #{{ objStockAdjustment.supplier_invoice_text }}
              </a>
              <ng-container *ngIf="!objStockAdjustment.supplier_invoice_text">
                <span>--</span>
              </ng-container><br />
            </span>
            <span *ngIf="objStockAdjustment.job_id">
              <a *ngIf="objStockAdjustment.job_text" [routerLink]="getLink('jobs', objStockAdjustment.job_id)">
                #{{ objStockAdjustment.job_text }}
              </a>
              <ng-container *ngIf="!objStockAdjustment.job_text">
                <span>--</span>
              </ng-container><br />
            </span>
            <span *ngIf="objStockAdjustment.customer_invoice_id">
              <a *ngIf="objStockAdjustment.customer_invoice_text" [routerLink]="getLink('customer_invoices', objStockAdjustment.customer_invoice_id)">
                #{{ objStockAdjustment.customer_invoice_text }}
              </a>
              <ng-container *ngIf="!objStockAdjustment.customer_invoice_text">
                <span>--</span>
              </ng-container>
            </span>
            <span *ngIf="objStockAdjustment.stock_take_id">{{ 'from_stocktake' | translate }}</span>
            <span *ngIf="objStockAdjustment.destination_warehouse_id && objStockAdjustment.source_warehouse_id">{{ 'stock_transfer' | translate }}</span>
          </div>
          <div class="col-3 pr-0 pl-0 text-center">
            <div *ngIf="objStockAdjustment.warehouse_source_text != ''" class="truncate">
              <span matTooltip="{{ 'warehouse_source' | translate }}: {{ objStockAdjustment.warehouse_source_text }}" class="pointer">
                <fa-icon [icon]="['far', 'arrow-left']" class="text-danger" aria-hidden="true"></fa-icon>
                {{ objStockAdjustment.warehouse_source_text }}
              </span>
            </div>
            <div *ngIf="objStockAdjustment.warehouse_destination_text != ''" class="truncate">
              <span matTooltip="{{ 'warehouse_destination' | translate }}: {{ objStockAdjustment.warehouse_destination_text }}" class="pointer">
                <fa-icon [icon]="['far', 'arrow-right']" class="text-success" aria-hidden="true"></fa-icon>
                {{ objStockAdjustment.warehouse_destination_text }}
              </span>
            </div>
          </div>
          <div class="col-4 pr-0 pl-0 text-center">
            <span>{{ objStockAdjustment.created_at | local | format:'lll' }}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</ng-container>

<ng-template #notPermitted>
  <span>{{ 'forbidden_error_stockmanagement' | translate }}</span>
</ng-template>