<app-error-handler onlyFor="supplier_invoices:list" alignment="center" displayAs="widget">
<div *ngIf="bDisplayAddBtn" class="d-flex justify-content-around contacts-option tabs-content">
    <ng-container *fcHasPermission="'supplier_invoices:create'; else createSupplierInvoiceDisabled">
        <span id="widgetSupplierInvoicesCreateButton" (click)="(bPageLoaded && bDialogLoaded) ? createSupplierInvoice() : ''" class="span-links">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
            {{ 'create_supplier_invoice' | translate }}
        </span>
    </ng-container>
    <ng-template #createSupplierInvoiceDisabled>
        <span id="widgetSupplierInvoicesCreateButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-default"></fa-icon>
            {{ 'create_supplier_invoice' | translate }}
        </span>
    </ng-template>
</div>

<div class="contact-roles-body">
    <div class="container">
        <div class="row pt-2 pb-2">
            <div class="col-sm-6 pt-1">
                <span class="module-title">
                    <fa-icon [icon]="['fa', 'file-alt']" aria-hidden="true"></fa-icon>
                    &nbsp;
                    {{ 'supplier_invoices' | translate }}
                </span>
            </div>
            <div class="col-sm-6 pr-2">
                <nav class="pagination-section">
                    <ul class="pagination">
                        <refresh-pagination-btn id="widgetSupplierInvoicesRefreshButton" (refresh)="onRefresh()" [isDisabled]="! bPageLoaded"></refresh-pagination-btn>
                        <li [ngClass]="(listService.strFirstValue != listService.strCurrentValue && listService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetSupplierInvoicesPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev')">
                                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                        <li [ngClass]="(listService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetSupplierInvoicesNextButton" class="page-link" aria-label="first" (click)="fetchList('next')">
                                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div *ngIf="arSupplierInvoices.length < 1">
            <div class="row border-top" *ngIf="bPageLoaded">
                <span class="no-content">
                    {{ 'no_invoices' | translate }}
                </span>
            </div>
            <div class="row border-top" *ngIf="!bPageLoaded">
                <span class="no-content">
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                    {{ 'loading' | translate }}
                </span>
            </div>
        </div>

        <div class="row border-top pt-2 pb-2 pr-2"  *ngFor="let supplier_invoice of arSupplierInvoices" [id]="'widgetSupplierInvoicesRecord' + supplier_invoice.id">
            <div class="col-sm-3">
                <div class="row" matTooltip="{{ 'supplier_invoice_number' | translate }}: {{ supplier_invoice.text }}">
                    <div class="truncate col-12">
                        <a id="widgetSupplierInvoicesLabel" [routerLink]="['/supplier_invoices/'+supplier_invoice.id]">
                            <span> #{{ supplier_invoice.text }}</span>
                        </a>
                    </div>
                </div>
                <div *ngIf="strModule !== 'purchase_orders'" class="row">
                    <div class="truncate col-12">
                        <a *ngIf="supplier_invoice.purchase_order_id; else noPurchaseOrder"
                            id="widgetSupplierInvoicesPurchaseOrderLabel"
                            [routerLink]="['/purchase_orders/'+supplier_invoice.purchase_order_id]"
                            matTooltip="{{ 'po_number' | translate }}: {{ supplier_invoice.purchase_order_text }}">
                            <span> #{{ supplier_invoice.purchase_order_text | placeholdWithString }}</span>
                        </a>
                        <ng-template #noPurchaseOrder>--</ng-template>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="row" matTooltip="{{ 'invoice_date' | translate }}: {{ supplier_invoice.invoice_date ? formatDate(supplier_invoice.invoice_date) : '--' }}">
                    <div class="truncate col-12">
                        <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                        {{ supplier_invoice.invoice_date ? formatDate(supplier_invoice.invoice_date) : '--' }}
                    </div>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="row" matTooltip="{{ 'amount_tax_ex' | translate }}: {{ supplier_invoice.amount | currency }}">
                    <div class="truncate col-12">
                      {{ supplier_invoice.amount | currency: {decimal_places: 2} }}
                    </div>
                </div>
                <div class="row" matTooltip="{{ 'amount_tax_inc' | translate }}: {{ supplier_invoice.amount_inc_tax | currency }}">
                    <div class="truncate col-12">
                      {{ supplier_invoice.amount_inc_tax | currency: {decimal_places: 2} }}
                    </div>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="row" matTooltip="{{ 'amount_due' | translate }}: {{ supplier_invoice.amount_due | currency }}">
                    <div class="truncate col-12">
                      {{ supplier_invoice.amount_due | currency: {decimal_places: 2} }}
                    </div>
                </div>
            </div>
            <div class="col-sm-1 d-table text-role less-padding">
                <div class="row">
                    <div class="col-12">
                        <form-templates-preview-default-template-btn
                            [as-span]="true"
                            icon-class="text-danger"
                            btn-class=""
                            [hide-text]="true"
                            class="pointer float-right ml-2"
                            [module-id]="supplier_invoice.id"
                            document-type="supplier_invoice_report"
                            module-name="supplier_invoices"
                            id="widgetSupplierInvoicesPreviewButton" >
                        </form-templates-preview-default-template-btn>
                        <ng-container *fcHasPermission="'supplier_invoices:edit'; else disabledEditButton">
                            <fa-icon id="widgetSupplierInvoicesEditButton" class="text-success pointer float-right" [icon]="['fas', 'pencil']" (click)="(bDialogLoaded) ? openSupplierInvoice(supplier_invoice) : ''"></fa-icon>
                        </ng-container>
                        <ng-template #disabledEditButton>
                            <fa-icon id="widgetSupplierInvoicesEditButton" class="disabled float-right" [icon]="['fas', 'pencil']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                        </ng-template>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <ng-container *fcHasPermission="'supplier_invoices:delete'; else disabledDeleteButton">
                            <fa-icon id="widgetSupplierInvoicesDeleteButton" class="text-warning pointer float-right" [icon]="['fas', 'trash-alt']" (click)="deleteSupplierInvoice(supplier_invoice.id)"></fa-icon>
                        </ng-container>
                        <ng-template #disabledDeleteButton>
                            <fa-icon id="widgetSupplierInvoicesDeleteButton" class="disabled float-right" [icon]="['fas', 'trash-alt']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</app-error-handler>
