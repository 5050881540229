<div *ngFor="let objDataDisplay of arDataDisplay" [ngClass]="{
    'right-align-colum': hasDateColumn,
    'breakword-container': hasWordBreakableColumn,
    'breakword-container-last': isWordStillBreakable,
    'default-display': (!hasWordBreakableColumn && !isWordStillBreakable)
    }">

    <ng-container *ngIf="isObject(objDataDisplay)">
        <div [innerHTML]="objDataDisplay[2]"></div>

        <!-- ICON FOR STATUS -->
        <div class="row col-xs-12 col-md-12 col-lg-12 icon-container"
            id="recordStatusIcon"
            *ngIf ="objDataDisplay[0] == moduleStatus() &&
                    (objDataDisplay[1]) !== null && (objDataDisplay[1]) !== ''">
            <fa-icon [icon]="['fas', 'circle']" [ngClass]="'status-field'" [ngStyle]="{'color': statusRepresentation(objDataDisplay[1]) }"
                matTooltip="{{ isStatusBoolean(objDataDisplay[1]) | translate}}" class="icon-style">
            </fa-icon>

        </div>

        <!-- ICON FOR JOB CHECKLIST STATUS COLUMN -->
        <fa-icon *ngIf ="strCurrentModule === 'jobs' && objDataDisplay[0] === 'job_checklist_status'"
            [icon]="['fas', 'info-circle']" class="checklist-status-field {{ JobChecklistStatusRepresentation(objDataDisplay[1]) }}"
            matTooltip="{{ 'job_checklist_status' | translate }} : {{ objDataDisplay[1] | translate }}" class="icon-style">
        </fa-icon>

        <!-- ICON FOR QUOTE CHECKLIST STATUS COLUMN -->
        <fa-icon *ngIf ="strCurrentModule === 'opportunities' && objDataDisplay[0] === 'opportunity_checklist_status'"
            [icon]="['fas', 'info-circle']" class="checklist-status-field {{ JobChecklistStatusRepresentation(objDataDisplay[1]) }}"
            matTooltip="{{ 'opportunity_checklist_status' | translate }} : {{ objDataDisplay[1] | translate }}" class="icon-style">
        </fa-icon>

        <!-- ICON FOR ASSET STATUS COLUMN OF JOB TABLE -->
        <fa-icon *ngIf ="strCurrentModule === 'jobs' && objDataDisplay[0] === 'asset_status'"
            [icon]="['fal', 'bell']" class="asset-status-field {{ AssetStatusRepresentation(objDataDisplay[1]) }}"
            matTooltip="{{ objDataDisplay[1] | translate}}" class="icon-style">
        </fa-icon>

        <app-list-view-profit-analysis *ngIf="objDataDisplay[0] === 'profit_analysis'" [record]="arModuleData"></app-list-view-profit-analysis>

        <div class="row col-xs-12 col-md-12 col-lg-12" *ngIf ="objDataDisplay[0] !== 'asset_status' && objDataDisplay[0] !== 'job_checklist_status' && objDataDisplay[0] !== 'opportunity_checklist_status' && objDataDisplay[0] !== 'profit_analysis'">
            <!-- PRIMARY FIELDS -->
            <div class="truncate primary-field pointer"
               *ngIf="isAPrimaryField(objDataDisplay[0]) &&
                      defaultDisplay(objDataDisplay[0], objDataDisplay[1]) != ''; else emptyPrimaryField"
                matTooltip="{{ changeFieldName(objDataDisplay[0]) | translate }}: {{ isNumber(defaultDisplay(objDataDisplay[0], objDataDisplay[1])) === false ? defaultDisplay(objDataDisplay[0], objDataDisplay[1]) : defaultDisplay(objDataDisplay[0], objDataDisplay[1]) | translate }}" translate>
                <!-- SHOW ICON IF FIELD HAS ICON AVAILABLE AND NOT PRIMARY -->
                <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
                <span *ngIf="strRecordUrl === ''" id="record_{{objDataDisplay[0]}}">
                    {{ defaultDisplay(objDataDisplay[0], objDataDisplay[1]) | translate | placeholdWithString }}
                </span>

                <a *ngIf="strRecordUrl !== ''" id="recordLink" href="/#{{strRecordUrl}}" (click)="$event.stopPropagation()">
                    <span id="record_{{objDataDisplay[0]}}">
                        {{ defaultDisplay(objDataDisplay[0], objDataDisplay[1]) | translate | placeholdWithString }}
                    </span>
                </a>
            </div>

            <ng-template #emptyPrimaryField>
                <ng-container *ngIf="isAPrimaryField(objDataDisplay[0])">
                    <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
                    <span matTooltip="{{ objDataDisplay[0] | translate | placeholdWithString }}" class="regular-field"></span>
                </ng-container>
            </ng-template>

           <!-- REGULAR FIELDS & THEIR SPECIAL CASES-->
           <div class="truncate regular-field"
               *ngIf ="!isAPrimaryField(objDataDisplay[0]) &&
                       objDataDisplay[0] != moduleStatus() &&
                       !isInObjectFields(objDataDisplay[0]) &&
                       !isDate(objDataDisplay[0]) &&
                       !hasBeenDeleted(objDataDisplay[0]) &&
                       objDataDisplay[1] != '' &&
                       !isDecimal(objDataDisplay[0])
                       && shouldDisplay(objDataDisplay[0],  objDataDisplay[1])"
                matTooltip="{{ getRegularFieldHoverTooltip(objDataDisplay) }}" matTooltipDisabled="{{ changeRegularFieldName(objDataDisplay[0]) ? false : true }}"
                [ngClass]="{'secondary-field': isASecondaryField(objDataDisplay[0]), 'regular-field-custom': objDataDisplay[0] == 'special_notes' }" translate>
                <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
                <span id="record_{{objDataDisplay[0]}}">
                    {{ defaultDisplay(objDataDisplay[0], objDataDisplay[1]) | translate | placeholdWithString }}
                </span>
            </div>

        </div>

        <!-- Icon Template-->
        <ng-template #iconTemplate>
            <fa-icon *ngIf="objDataDisplay[0] != moduleStatus() &&
                hasIcon(objDataDisplay[0]) &&
                !isInObjectFields(objDataDisplay[0]) &&
                !arListingTypes.currencies.includes(objDataDisplay[0]) &&
                !isDate(objDataDisplay[0]) && !hasBeenDeleted(objDataDisplay[0]); else displayLabel"
                [icon]="['far', getIcon(objDataDisplay[0], objDataDisplay[1])]"
                [ngClass]="isAPrimaryField(objDataDisplay[0]) ? 'text-primary' : 'icon-style'">
            </fa-icon>
            <ng-template #displayLabel>
                <span *ngIf="shouldDisplayLabel(objDataDisplay[0], objDataDisplay[1])" class="noWrap">{{ objDataDisplay[0] | translate }}: </span>
            </ng-template>
        </ng-template>

        <!-- DATE FIELDS -->
        <div class="row col-xs-12 col-md-12 col-lg-12"
            *ngIf ="isDate(objDataDisplay[0])">
            <span class="d-block truncate regular-field" matTooltip="{{ objDataDisplay[0] | translate}}: {{ formatDatetime(objDataDisplay[1], objDataDisplay[0]) }}">
                <span class="noWrap">{{ objDataDisplay[0] | translate }}: </span>
                <span id="record_{{objDataDisplay[0]}}"
                    class="noWrap">
                    {{ formatDatetime(objDataDisplay[1], objDataDisplay[0]) }}
                </span>
            </span>
        </div>

        <!-- Decimal -->
        <div
            class="row col-xs-12 col-md-12 col-lg-12"
            *ngIf="isDecimal(objDataDisplay[0])"
        >
            <span class="d-block truncate regular-field" matTooltip="{{ objDataDisplay[0] | translate}}: {{ objDataDisplay[1] | format_number }}">
                <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
                <span id="record_{{objDataDisplay[0]}}">
                    {{ objDataDisplay[1] | format_number }}
                </span>
            </span>
        </div>

        <!-- DELETED BY FIELD IN RECYCLE BIN -->
        <div class="row col-xs-12 col-md-12 col-lg-12"
            *ngIf ="hasBeenDeleted(objDataDisplay[0])">
            <span class="d-block truncate regular-field" matTooltip="{{ 'deleted_by' | translate}}: {{ objDataDisplay[1] }}">
                <span class="noWrap">{{ 'deleted_by' | translate }}: </span>
                <span id="record_{{objDataDisplay[0]}}"
                    class="noWrap">
                    {{ objDataDisplay[1] }}
                </span>
            </span>
        </div>

        <!-- OBJECT FIELDS SUCH AS PHONE, ATTRIBUTES, EMAIL ETC-->
        <ng-container
            *ngIf ="!isAPrimaryField(objDataDisplay[0]) &&
                    objDataDisplay[0] != moduleStatus() &&
                    isInObjectFields(objDataDisplay[0])">

            <div *ngIf="objDataDisplay[1] == undefined || objDataDisplay[1] == '' || objDataDisplay == null || objDataDisplay[1].length < 1">
                <span matTooltip="{{objDataDisplay[0] | translate | placeholdWithString}}"></span>
            </div>
            <!-- if field value  returns an object -->
            <ng-container *ngFor="let item of objDataDisplay[1]">
                <!-- string of objects -->
                <div *ngIf="isString(item)" class="regular-field row" matTooltip="{{objDataDisplay[0] | translate}}: {{ item }}" translate>
                    <div class="icon-container col-xs-12 col-md-12 col-lg-12 truncate">
                        <fa-icon [icon]="['far', getIcon(objDataDisplay[0])]" class="icon-style"></fa-icon>
                        {{ item }}
                    </div>
                </div>

                <!-- array of objects for phone -->
                <div *ngIf="!isString(item) && objDataDisplay[0] == 'phone'"
                    class="regular-field row"
                    matTooltip="{{objDataDisplay[0] | translate}}: {{ item.code }} {{ (item.number) ? item.number : '' }}">
                    <div class="icon-container col-xs-12 col-md-12 col-lg-12 truncate">
                        <fa-icon [icon]="['far', item.getPhoneIcon]" class="icon-style"></fa-icon>
                        <ng-container *ngIf="item.number; else illegalNumber">
                                {{ item.code }} {{ (item.number) ? (item.number | phone) : '' }}
                                <ng-template #illegalNumber><span>{{ 'fix_number' | translate }}</span></ng-template>
                        </ng-container>
                    </div>
                </div>

                <!-- array of objects except for phone -->
                <div *ngIf="!isString(item) && objDataDisplay[0] != 'phone'"
                    class="regular-field row"
                    matTooltip="{{objDataDisplay[0] | translate}}: {{ formatObject(item, objDataDisplay[0]) }}">
                    <div class="icon-container col-xs-12 col-md-12 col-lg-12 truncate">
                        <div *ngIf="objDataDisplay[0] === 'total_profit'; else defaultField"
                            [ngClass]="{'text-success': totalProfitValue > 0, 'text-danger': totalProfitValue < 0, 'text-muted': totalProfitValue === 0}">
                            <div class="row total-profit-margin">
                                <div class="col-xs-10" [innerHTML]="totalProfitText"></div>
                                <div class="col-xs-2">
                                    <fa-icon *ngIf="totalProfitValue !== 0" [icon]="['fas', 'arrow-alt-' + (totalProfitValue > 0 ? 'up' : 'down')]" class="fa-2x ml-2"></fa-icon>
                                </div>
                            </div>
                        </div>
                        <ng-template #defaultField>
                            <fa-icon [icon]="['far', getIcon(objDataDisplay[0], item)]" class="icon-style"></fa-icon>
                            {{ formatObject(item, objDataDisplay[0]) | translate | placeholdWithString }}
                        </ng-template>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
