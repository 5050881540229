import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { FormService } from '../../../../services/form.service';
import { RecordService } from '../../../../services/record.service'
import { cloneDeep, first, get } from 'lodash-es';
import { NotificationService } from '../../../../services/notification.service';
import { AssetService } from '../../../../services/asset.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { isEmpty } from 'lodash-es';
import { ConvertOpportunityFilesComponent } from '../convert-opportunity-files/convert-opportunity-files.component';
import { mergeMap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-convert-opportunity',
  templateUrl: './convert-opportunity.component.html',
  styleUrls: ['./convert-opportunity.component.scss']
})

export class ConvertOpportunityComponent implements OnInit {

  items = [true, 'Two', 3];
  public selectedSimpleItem;
  public quoteForm: FormGroup;
  public arFields = [];
  public bLoading: boolean = true;
  public bSubmitted: boolean = false;
  public bDisabled: boolean = false;
  public bDisabledMaintenance: boolean = false;
  public objSelectedQuote: any = false;
  public objSelectedTemplate: any = false;
  public arDetails = {};
  public strQuoteSummary: string;

  /**
	 * Fields that use centralize edit.
	 */
	public objQuotesField = {
    configure_inclusion: {
			key: 'configure_inclusion',
			type: 'dropdown',
			label: 'configure_inclusion',
      required: true,
      options: [
        {id: "po_only", text: "po_only"},
        {id: "wo_only", text: "wo_only"},
        {id: "wo_and_po", text: "wo_and_po"},
        {id: "no_wo_and_po", text: "no_wo_and_po"}
      ],
    },
    po_number: {
      key: 'po_number',
			type: 'input',
			label: 'po_number',
      required: true,
      readonly: false,
      default_value: '',
    },
    work_order_number: {
			key: 'work_order_number',
			type: 'input',
			label: 'wo_number',
      required: true,
      readonly: false,
      default_value: '',
    },
    type: {
			key: 'type',
			type: 'dropdown',
			label: 'job_type',
      required: true,
      options: [],
      default_value: 'simple_job',
    },
    project_template: {
      note: 'project_template_info',
			key: 'project_template',
      type: 'relate',
      module: 'project_templates',
			label: 'project_template',
      multiple: false,
      required: false,
      readonly: false,
    },
    invoicing_type: {
			key: 'invoicing_type',
			type: 'dropdown',
      label: 'invoicing_type',
      default_value: 'flexible_invoicing',
      required: true,
      options: [],
    },
    automatically_create_task_from_labor: {
      key: 'automatically_create_task_from_labor',
      type: 'checkbox',
      label: 'automatically_create_task_from_labor',
      required: false,
      default_value: false
    },
    automatically_create_job_materials: {
      key: 'automatically_create_job_materials',
      type: 'checkbox',
      label: 'automatically_create_job_materials',
      required: false,
      default_value: false
    },
    created_from_quote: {
      key: 'created_from_quote',
      text: 'quote_with_summary_text',
      label: 'quote',
      type: 'relate',
      formControlName: 'relate',
      module: 'quotes',
      filter: {
        is_template: false
      },
      multiple: false,
      required: true,
      readonly: false,
      default_value: ''
    },
    job_summary: {
			key: 'job_summary',
			type: 'textarea',
			label: 'job_summary',
      required: true,
      max_length: 10000
    },
    user_id: {
      key: 'user_id',
      label: 'assigned_user',
      type: 'relate',
      formControlName: 'relate',
      module: 'users',
      multiple: false,
      required: false,
      readonly: false,
      default_value: ''
    },
    pricebook_id: {
      key: 'pricebook_id',
      label: 'assigned_pricebook',
      type: 'relate',
      formControlName: 'relate',
      module: 'pricebooks',
      multiple: false,
      readonly: false,
      default_value: ''
    }
  }

  constructor(
    public formService: FormService,
    public recordService: RecordService,
    public assetService: AssetService,
    public notifService: NotificationService,
    public localStorageService: LocalStorageService,
    public dialog: MatDialogRef<ConvertOpportunityComponent>,
    public dialogPreferredSupplier : MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Update the created from quote (which is a quote relate field)
    // and add a filter, only fetch quotes with the given opportunity id.
    this.objQuotesField['created_from_quote']['filter']['opportunity_id'] = data['recordDetails']['id'];
    this.objQuotesField['user_id']['default_value'] = this.localStorageService.getItem('user_id');
    // Set the name from the local storage.
    this.arDetails = {
      'user_text': this.localStorageService.getItem('user_name')
    };
    this.strQuoteSummary = data['recordDetails']['summary'];

    this.bDisabledMaintenance = get(first(get(data, 'relatedData.sites')), 'disable_maintenance', false);

    this.recordService.getRecordRelate('quotes', null, [], false, {
      'opportunity_id': data['recordDetails']['id'],
      'is_primary': true
    }).pipe(
      switchMap(response => {
        if (Array.isArray(response) && response.length > 0) {
          this.objQuotesField['created_from_quote']['default_value'] = response[0]['id'];
          this.objQuotesField['job_summary']['default_value'] = response[0]['quote_summary'];
          this.objSelectedQuote = response[0];
          this.arDetails['created_from_quote_text'] = response[0]['text'];
          this.objQuotesField['pricebook_id']['default_value'] = response[0]['id'];
          this.arDetails['pricebook_text'] = response[0]['pricebook_name']
        }
        return this.recordService.getDropdownRecord('jobs');
      })
    ).subscribe(data => {
      this.initializeDialog(data.body);
      // Hide loader icon.
      this.bLoading = false;
    })
  }

  ngOnInit() {}

  /**
   * Close the dialog.
   */
  public cancelDialog() {
    this.dialog.close();
  }

  /**
   * Wait for the request to know if we have quotes.
   * @param event
   */
  public checkQuoteIfEmpty(event) {
    if (event !== undefined && event['convert_quote_count'] == 0) {
      // Remove from form object and form control.
      delete this.objQuotesField['created_from_quote'];

      this.quoteForm.removeControl('created_from_quote');

      // Disable and set these to false as we don't have a quote.
      this.quoteForm.patchValue({
        automatically_create_task_from_labor: false,
        automatically_create_job_materials: false,
      });

      // FC-3512: Quote summary is copied as job summary when converting a quote
      // into a job (without quote versions)
      this.quoteForm.patchValue({ job_summary: this.strQuoteSummary });

      this.quoteForm.controls['automatically_create_task_from_labor'].disable();
      this.quoteForm.controls['automatically_create_job_materials'].disable();

      this.quoteForm.updateValueAndValidity();
    }
  }

  /**
   * Creates the convert form from the objQuotesField.
   * @param options
   */
  public initializeDialog(options) {

    // Set the dropdown options of the dropdown fields.
    this.objQuotesField['type']['options'] = options['type']['config'];
    this.objQuotesField['invoicing_type']['options'] = options['invoicing_type']['config'];

    // Insantiate arFormControls as object to avoid errors in console.
    let arFormControls = {};

    // Loop each relate fields
    Object.keys(this.objQuotesField).forEach( item => {
      // Transform field to object
      if (this.objQuotesField[item]['key'] != undefined) {
        this.objQuotesField[item] = cloneDeep(this.formService.tranformFieldObject(this.objQuotesField[item], this.arDetails));
        // Create form control for each field.
        arFormControls[item] = new FormControl(this.objQuotesField[item].default_value || null);
      }
      // Place fields in an array.
      this.arFields.push(item);
    });

    // Create the form group object. (A compilation of form control objects.)
    this.quoteForm = new FormGroup(arFormControls);

    // If the quote relate has changed.
    this.quoteForm.controls['project_template'].valueChanges.subscribe(item => {

      // Find the quote from the quote relate dropdown options.
      this.objSelectedTemplate = this.objQuotesField['project_template']['options'].find(template => (template['id'] == item));

      // Once quote is found
      if (this.objSelectedTemplate) {
        this.objSelectedTemplate = JSON.parse(this.objSelectedTemplate['project_contents']);
      }

      if (this.quoteForm.controls['project_template'].value == null) {
        // Return to enable when they don't select a project anymore.
        this.quoteForm.controls['automatically_create_task_from_labor'].enable();
        this.quoteForm.controls['automatically_create_job_materials'].enable();

      } else {
        // Disable these options when its a project.
        this.quoteForm.patchValue({
          automatically_create_task_from_labor: false,
          automatically_create_job_materials: false,
        });

        this.quoteForm.controls['automatically_create_task_from_labor'].disable();
        this.quoteForm.controls['automatically_create_job_materials'].disable();
      }

    })

    // If the quote relate has changed.
    this.quoteForm.controls['created_from_quote'].valueChanges.subscribe(item => {

      // Find the quote from the quote relate dropdown options.
      this.objSelectedQuote = this.objQuotesField['created_from_quote']['options'].find(quote => (quote['id'] == item));

      // Once quote is found
      if (this.objSelectedQuote) {
        // Set the job summary of the job to the selected quote's summary.
        this.quoteForm.patchValue({
          job_summary: this.objSelectedQuote['quote_summary'] || this.objSelectedQuote.extras.quote_summary,
        })
      }
    })

    // If the job type changes.
    this.quoteForm.controls['type'].valueChanges.subscribe(item => {

      // If the selected type is a simple job, but the invoicing type already selected milestone billing
      // remove the milestone billing as the selected invoicing type.
      if (item == 'simple_job' && this.quoteForm.controls['invoicing_type'].value == 'milestone_invoicing') {
        this.quoteForm.patchValue({
          invoicing_type: ""
        });
      }

      //Make sure these options are enabled if changing to simple job.
      if (item == 'simple_job') {
        this.quoteForm.controls['automatically_create_task_from_labor'].enable();
        this.quoteForm.controls['automatically_create_job_materials'].enable();
      }

      if (item == 'project') {
        if (this.quoteForm.controls['project_template'].value != null) {
          // Disable these options when its a project.
          this.quoteForm.patchValue({
            automatically_create_task_from_labor: false,
            automatically_create_job_materials: false,
          });

          this.quoteForm.controls['automatically_create_task_from_labor'].disable();
          this.quoteForm.controls['automatically_create_job_materials'].disable();
        }
      }

      // Get the old dropdown options of the invoicing type.
      // We use cloneDeep to generate a new instance of the object
      // instead of referencing the original object.
      let oldOptions = cloneDeep(this.objQuotesField['invoicing_type']['options']);



      // If the selected type is a project.
      if (item == 'project') {
        // Add milestone_invoicing only if it does not exist.
        if (oldOptions.findIndex(items => items.id == 'milestone_invoicing') == -1) {
          // Add milestone billing option to the invoicing type dropdown list.
          oldOptions.push({id: "milestone_invoicing", text: "milestone_invoicing"});
        }

      } else {
        // Remove milestone_invoicing only if it exists.
        if (oldOptions.findIndex(items => items.id == 'milestone_invoicing') != -1) {
          // Remove milestone billing option from the invoicing type dropdown list.
          oldOptions.splice(3, 1);
        }

      }

      // Place the new dropdown list in the invoicing type dropdown.
      this.objQuotesField['invoicing_type']['options'] = oldOptions;

    });
  }



  /**
   * Skip conversion.
   */
  public skipConversion() {
    this.dialog.close('success');
  }

  /**
   * Creates a job based from the oppotunity and selected quote.
   */
  public convertJob() {

    // An identifier that the Convert Job button has been clicked once.
    // We need this to make the required input fields red if they are not yet filled up.
    this.bSubmitted = true;

    // Check if the form is valid.
    if (this.quoteForm.valid && !this.bDisabledMaintenance) {
      // Get all the forms values and set a new instance of the object
      // in a new object.
      let objResult = {...this.quoteForm.getRawValue()}

      // Remove the configure fields as it is not needed in saving.
      delete objResult['configure_inclusion'];

      // Check if selected invoicing type is fixed price then include the amount_to_invoice data with the value of opportunity amount
      if (objResult['invoicing_type'] == 'fixed_price_invoices') {
        objResult['amount_to_invoice'] = (this.data['recordDetails']['amount'] != undefined && this.data['recordDetails']['amount'] != null && this.data['recordDetails']['amount'] != '') ? this.data['recordDetails']['amount'] : '0.00';
      }
      // Default data when converting job
      objResult['billable'] = true;
      // Set the customer, sites, oppotunity id, is_custom_location, address as well as the status.
      objResult['customer_id'] = this.data['recordDetails']['customer_id'];
      objResult['site_id'] = this.data['recordDetails']['site_id'];
      objResult['status'] = 'awaiting_scheduling';
      objResult['billable'] = true;
      objResult['opportunity_id'] = this.data['recordDetails']['id'];
      objResult['is_custom_location'] = this.data['recordDetails']['is_custom_location'];
      objResult['address'] = this.data['recordDetails']['address'];
      objResult['department_id'] = this.data['recordDetails']['department_id'];
      // FC-2020: Add work_order_items in jobs using quote line items
      objResult['work_order_items'] = this.objSelectedQuote.line_items;
      objResult['pricebook_id'] = this.objSelectedQuote.pricebook_id;

      // Disable save button to avoid spam.
      this.bDisabled = true;

      // If there is a selected project template.
      if (objResult['project_template'] != null) {
        // Still allow to choose a project template even if there is no quote.
        if (!this.objSelectedQuote) {
          this.objSelectedQuote = {};
        }

        this.objSelectedQuote['tasks'] = this.objSelectedTemplate['tasks'];
        delete this.objSelectedQuote['line_items'];
      }

      // Delete the project template.
      delete objResult['project_template'];

      // Build the object needed by the API to create a job.
      let objConvert = {
        config: [
          {
            module: "jobs",
            data: objResult,
          },
          {
            module: "activities",
            automatically_create_task_from_labor: objResult.automatically_create_task_from_labor || false,
            automatically_create_job_materials: objResult.automatically_create_job_materials || false,
            data: this.objSelectedQuote,
          }
        ],
        record: this.data['recordDetails']
      }

      // Send data to API.
        this.recordService.convertRecord('opportunities', objConvert).subscribe(convertResult => {
        // If the creation was a success, send a notification and close the dialog
        if (convertResult.status == 200 || convertResult.status == 201) {

          if (objResult['opportunity_id'] != undefined && this.data['recordDetails']['from_job_id'] != "") {

            // Get all asset_job record with opportunity id given
            this.assetService.getOpportunityAssets(convertResult['body']['job']['id'], this.data['recordDetails']['from_job_id'], objResult['opportunity_id']).subscribe(convertResult => {});
          }

          // If there are files in opportunity record, have the option for the users
          // to choose files they want to link to their newly converted job record
          if (! isEmpty(convertResult['body']['opportunity_files'])) {

            this.dialogPreferredSupplier.open(ConvertOpportunityFilesComponent, {
              width: '800px',
              height: 'auto',
              data: {
                opportunity_record: objResult,
                opportunity_files: convertResult['body']['opportunity_files'],
                job_id: convertResult['body']['job']['id']
              }
            });
          }

          this.notifService.sendNotification('header_notification.success_added', 'convert_success', 'success');
          this.dialog.close({message: 'success', job_id: convertResult['body']['job']['id']});

        } else if (convertResult.body.errors !== undefined) {
          // Enable button again and send notification if conversion throws error.
          this.bDisabled = false;
          this.notifService.sendNotification('warning', convertResult.body.errors, 'danger');
        } else {
          // Enable button again and send notification if conversion throws error.
          this.bDisabled = false;
          this.notifService.sendNotification('oops', 'record_create_failed', 'danger');
        }

      });

    } else {
      if(this.quoteForm.invalid){
      // Notify the user that he/she is still missing some requried fields.
      this.notifService.sendNotification('required', 'required_notice', 'warning');

    }
      if (this.bDisabledMaintenance) {
        // FC-3606: Message prompt when creating Job if the Site selected is "Disabled Maintenance"
        this.notifService.notifyError("unable_to_convert_disabled_maintenance");
      }
    }
  }

}
