export class ListingElementsEvent {

  type: string;
  data?: any;
  page?: number;

  constructor(properties: ListingElementsEvent) {
    this.type = properties.type;
    this.data = properties.data;
    this.page = properties.page;
  }

}