
    <fieldmagic-dialog>
      <div class="dialog-header-content">
        <fieldmagic-dialog-title
          label="choose_checklist"
          icon="link"
        >
        </fieldmagic-dialog-title>
      </div>

      <div class="dialog-header-buttons">
        <fieldmagic-primary-button
          label="link"
          icon="link"
          (click)="onLink()"
          [isInProgress]="linking$ | async"
        >
        </fieldmagic-primary-button>

        <fieldmagic-dialog-close-button
          (click)="onClose()"
          [disabled]="linking$ | async"
        ></fieldmagic-dialog-close-button>
      </div>

      <div class="dialog-content">
        <div
          [formGroup]="form"
          fieldmagicGridRow
        >
          <div
            fieldmagicGridColumn="12"
          >
            <select-checklist-input
              formControlName="checklist"
              [types]="availableChecklistTypes$ | async"
              label="Available Checklists"
              placeholder="Please select a checklist"
              [withRequiredMarker]="true"
              [errors]="errors | data_get: 'checklist'"
              [assetTypeIds]="assetTypeIds"
            >
            </select-checklist-input>
          </div>

          <div
            *ngIf="isAvailablePeriodSelectable$ | async"
            fieldmagicGridColumn="12"
          >
            <fieldmagic-dropdown-input
              formControlName="available_periods"
              [multi]="true"
              label="Available Inspection Period/s"
              placeholder="Please inspection select period/s"
              [options]="availablePeriodOptions$ | async"
              [withRequiredMarker]="isAvailablePeriodRequired$ | async"
              [errors]="errors | data_get: 'available_periods'"
            >
            </fieldmagic-dropdown-input>
          </div>
        </div>
      </div>
    </fieldmagic-dialog>
  