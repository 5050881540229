<header class="dialog-header mb-1 pl-4 pr-4">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-xs-12 dialog-title">
      <div class="row">
        <ng-content
          *ngIf="(title | blank) && (titleTmp | blank)"
          select=".dialog-header-content > *"
        >
        </ng-content>

        <div
          *ngIf="(title | filled) && (titleTmp | blank)"
          class="dialog-header-content"
        >
          <fieldmagic-dialog-title
            [label]="title"
            [icon]="icon"
          >
          </fieldmagic-dialog-title>
        </div>

        <div
          *ngIf="(title | blank) && (titleTmp | filled)"
          class="dialog-header-content"
        >
          <ng-container *ngTemplateOutlet="titleTmp"></ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-xs-12 pt-1">
      <div class="row">
        <ng-content
          *ngIf="buttonsTmp | blank"
          select=".dialog-header-buttons"
        >
        </ng-content>

        <div
          *ngIf="buttonsTmp | filled"
          class="dialog-header-buttons"
        >
          <ng-container *ngTemplateOutlet="buttonsTmp"></ng-container>

          <fieldmagic-dialog-close-button
            *ngIf="instance | filled"
            [instance]="instance"
            [disabled]="disableClose"
          ></fieldmagic-dialog-close-button>
        </div>
      </div>
    </div>
  </div>
</header>

<hr />

<mat-dialog-content>
  <ng-content
    *ngIf="contentTmp | blank"
    select=".dialog-content"
  >
  </ng-content>

  <div
    *ngIf="contentTmp | filled"
    class="dialog-content"
  >
    <ng-container *ngTemplateOutlet="contentTmp"></ng-container>
  </div>
</mat-dialog-content>
