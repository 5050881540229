import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrService } from '../../../../../services/helpers/arr.service';
import { RecordService } from '../../../../../services/record.service';
import { get as _get, isEmpty as _isEmpty} from 'lodash-es';
import { StockLevel, StockLevelInterface } from '../../../../../objects/stock-management/stock-level';
import validator from 'validator';
import { MatDialog } from '@angular/material';
import { UpdateStockLevelComponent } from './update-stock-level/update-stock-level.component';
import { NotificationService } from '../../../../../services/notification.service';

@Component({
  selector: 'warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.scss']
})
export class WarehouseListComponent implements OnInit {

  /**
   * List of stock levels.
   *
   * @var {StockLevelInterface[]}
   */
  public arStockLevels: StockLevelInterface[] = [];

  /**
   * The item id where all the warehouse
   * to be displayed are linked to.
   *
   * @var {string}
   */
  private strItemId: string = '';

  constructor(
    private record: RecordService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    public arrService: ArrService) {
      this.route.queryParams.subscribe(parameter => {
        this.strItemId = _get(parameter, 'item_id', null);
        if (this.strItemId && validator.isUUID(this.strItemId)) {
          this.fetchList();
        } else {
          this.navigateToInvalidPage();
        }
      });
    }

  ngOnInit() {}

  /**
   * Retrieves list of warehouse
   * linked to the item.
   *
   * @returns {void}
   */
  fetchList(): void {
    this.record.getMultipleModuleRelateRecord('stock_levels', false, {stock_levels: {item_id: this.strItemId}}, false)
      .subscribe(response => {
        this.arStockLevels = response['stock_levels'];
      });
  }

  /**
   * Redirect the page to an
   * 404 warning.
   *
   * @returns {void}
   */
  navigateToInvalidPage(): void {
    this.router.navigate(['error/404/page']);
  }

  /**
   * Handle refresh action
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.fetchList();
  }

  /**
   * Opens a form which lets the user update the location of the selected stock in a warehouse
   *
   * @param stockLevel
   * @returns {void}
   */
  openUpdateLocationDialog(stockLevel: StockLevel) {
    this.dialog.open(UpdateStockLevelComponent, {
      panelClass: ['w-50'],
      disableClose: true,
      data:  {
        id: stockLevel.id,
        warehouse_name: stockLevel.warehouse_name,
        location: stockLevel.location
      }
    })
    .afterClosed()
    .subscribe((isDialogCancelled) => {
      if (! isDialogCancelled) {
        this.notificationService.notifySuccess('location_update_success')
        this.fetchList();
      }
    });
  }
}