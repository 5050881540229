<ng-container *ngIf="bLoaded">
  <form [formGroup]="form">
    <div class="container-fluid">
      <div class="row" >
        <div class="col-xs-6 col-md-6 form-group">
          <strong id="jobStepTitle">{{ 'create_job' | translate }}</strong>
          <br />
          <small>{{ 'fill_in_details' | translate }}</small>
        </div>
      </div>

      <div class="row">
        <div *ngFor="let data of fields" class="col-{{data.space}} mb-3">
          <ng-container
            *ngIf="(( data.label != 'created_by' && data.label != 'modified_by' && data.label != 'updated_at' && data.label != 'created_at') && strMode == 'add') || strMode == 'edit'">
            <app-edit-field [item]="data" [form]="form" [bSubmitted]="bSubmitted" [strMode]="strMode"
              [strModule]="strModule"></app-edit-field>
          </ng-container>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-3">
          <strong>{{ 'additional_options' | translate }}</strong>
        </div>
      </div>

      <div class="row">

        <div class="col-6 mb-3">
          <app-edit-field
            [item]="additionalOptionsFields['assets']"
            [form]="form"
            [bSubmitted]="bSubmitted"
            [strMode]="strMode"
            [strModule]="strModule">
          </app-edit-field>

          <div class="d-flex align-items-center gap-15 pt-2">
            <span class="span-links pointer d-flex align-items-center gap-2" (click)="openSearchAssetsDialog()">
              <fa-icon [icon]="['fas', 'search']" class="text-success"></fa-icon>
              <small>{{ 'asset_search' | translate }}</small>
            </span>

            <span class="span-links pointer d-flex align-items-center gap-2" (click)="createAndLinkAsset()">
              <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
              <small>{{ 'create_and_link_asset' | translate }}</small>
            </span>
          </div>
        </div>

        <div class="col-6 mb-3">
          <div class="form-group">
            <select-checklist-input
              label="checklists"
              formControlName="checklists"
              [types]="['asset', 'job']"
              [multiple]="true"
              [assetTypeIds]="assetTypeIds$ | async"
              [grouped]="true"
              [size]="100"
            ></select-checklist-input>
          </div>
        </div>
      </div>

      <!-- Stepper Next -->
      <div class="row">
        <div class="col-xs-12 col-md-12">
          <button id="jobStepNext" type="button" class="btn btn-primary btn-block btn-lg p-2" (click)="goForward()">
            <strong>{{ 'next' | translate }}</strong>
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-container>
