import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActivitiesService } from '../../../../../services/activities.service';
import { data_get, observe } from '../../../../utils/common';
import { EventBusService } from '../../../../../services/event-bus.service';
import { NoteActivity } from '../../../../../objects/activity';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'activities-sticky-notes',
  template: `
    <fieldmagic-async-content
      [loading]="fetching$ | async"
    >
      <ng-template fieldmagicAsyncLoadedContent>
        <div
           *ngIf="notes$ | async | filled"
          class="w-100 d-flex flex-row sticky-notes"
        >
          <div
            *ngFor="let note of notes$ | async"
            class="alert alert-warning sticky-note pointer"
            (click)="onEditNote(note)"
          >
            <div class="w-100 d-flex justify-content-between">
              <small>
                <fieldmagic-icon
                  customClass="sticky-note-icon"
                  icon="thumbtack"
                >
                </fieldmagic-icon>
              </small>

              <p style="font-size:0.7rem">{{ note.created_at | format: 'll' }}</p>
            </div>
            <div class="w-100 d-flex">
              <p>{{ note.notes | truncate: 25 }}</p>
            </div>
          </div>
        </div>
      </ng-template>
    </fieldmagic-async-content>
  `,
  styleUrls: ['./sticky-notes.component.scss'],
})
export class StickyNotesComponent implements OnDestroy, AfterViewInit, OnChanges {
  @Input() moduleName: string;

  @Input() moduleId: string;

  readonly notes$ = new BehaviorSubject<NoteActivity[]>([]);

  readonly fetching$ = new BehaviorSubject<boolean>(true);

  private _onNextPageSubscriotion?: Subscription;
  private _onNoteCreateSubscription?: Subscription;
  private _onNoteEditSubscription?: Subscription;

  constructor(
    private readonly _activities: ActivitiesService,
    private readonly _events: EventBusService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (data_get(changes, 'moduleId.currentValue') != data_get(changes, 'moduleId.previousValue')) {
      this._loadList();
    }
  }

  ngOnDestroy(): void {
    if (this._onNoteCreateSubscription) {
      this._onNoteCreateSubscription.unsubscribe();
    }

    if (this._onNoteEditSubscription) {
      this._onNoteEditSubscription.unsubscribe();
    }

    if (this._onNextPageSubscriotion) {
      this._onNextPageSubscriotion.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    if (this._onNoteCreateSubscription) {
      this._onNoteCreateSubscription.unsubscribe();
    }

    if (this._onNoteEditSubscription) {
      this._onNoteEditSubscription.unsubscribe();
    }

    this._onNoteCreateSubscription = this._events.getEventFor('activities:created:note').pipe(
      filter((data) => data_get(data, 'is_sticky_note'))
    )
      .subscribe((_) => this._loadList());

    this._onNoteEditSubscription = this._events.getEventFor('activities:edited:note').pipe(
      filter((snapshot) => data_get(snapshot, 'previous.is_sticky_note') || data_get(snapshot, 'updates.is_sticky_note'))
    )
      .subscribe((_) => this._loadList());
  }

  onEditNote = (note: NoteActivity) => this._events.dispatch({
    name: 'activities:edit',
    args: note,
  });

  private _loadList = () => observe({
    before: () => this.fetching$.next(true),
    after: () => this.fetching$.next(false),
    observable: () => this._activities.getActivities(JSON.stringify({
      'module': this.moduleName,
      'record_id': this.moduleId,
      'widget': 'activities',
      'filter_clause': {
        'activity_type': 'note',
        'is_sticky_note': true,
      },
    })),
  }).subscribe((result) => this.notes$.next(result.data.map((activity) => ({
    ... activity,
    activity_type: 'note',
    notes: data_get(activity, 'notes'),
  }))));
}