import { ListingService } from '../../../../../../services/listing.service';
import { Component, Input } from '@angular/core';
import { filled } from '../../../../../utils/common';
import { NotificationService } from '../../../../../../services/notification.service';
import { LooseObject } from '../../../../../../objects/loose-object';
import { MatDialog } from '@angular/material';
import { ListComponent } from './list/list.component';
import { BarcodeService } from '../../../../../../services/barcode.service';
import { RecordService } from '../../../../../../services/record.service';

@Component({
  selector: 'fieldmagic-barcode-option',
  template: `
    <div class="dropdown-submenu">
      <div class="dropdown-item">
        <fieldmagic-icon
          id='btnRebuildSearchDatabase'
          (click)='viewBarcode()'
          icon='barcode'
          class="pointer"
        >
          <span>{{ 'barcode' | translate }}</span>
        </fieldmagic-icon>
      </div>
      <ul class="dropdown-menu">
        <div class="dropdown-item">
          <fieldmagic-icon
            id='btnRebuildSearchDatabase'
            (click)='viewBarcode()'
            icon='barcode'
            class="pointer"
          >
            <span>{{ 'view_barcode_list' | translate }}</span>
          </fieldmagic-icon>
        </div>

        <div class="dropdown-item">
          <fieldmagic-icon
            id='btnRebuildSearchDatabase'
            (click)='addBarcode()'
            icon='plus'
            class="pointer"
          >
            <span>{{ 'add_to_barcode_list' | translate }}</span>
          </fieldmagic-icon>
        </div>
        <div class="dropdown-item">
          <fieldmagic-icon
            id='btnRebuildSearchDatabase'
            (click)='clearBarcode()'
            icon='times'
          >
            <span>{{ 'clear_barcode_list' | translate }}</span>
          </fieldmagic-icon>
        </div>
      </ul>
    </div>
  `,
  styleUrls: ['./barcode.component.scss']
})
export class BarcodeOptionComponent {

  @Input() selectedRecords: LooseObject[];

  recordsToPrint: LooseObject[] = [];

  get hasRecordToPrint(): boolean {
    return this.recordsToPrint.length != 0;
  }

  constructor(
    private readonly _listing: ListingService,
    private notificationService: NotificationService,
    private matDialog: MatDialog,
    private barcodeService: BarcodeService,
    private recordService: RecordService,
  ) {
  }

  /**
   * view barcode list
   */
  viewBarcode(): void {
    if (this.hasRecordToPrint) {
      this.matDialog.open(ListComponent, {
        panelClass: ['w-50'],
        disableClose: true,
        data: this.recordsToPrint
      })
      .afterClosed()
      .subscribe( (response: { status: string, message: string }) => {
        if (response.status == 'success') {
          this.notificationService.notifySuccess(response.message)
        } else if (response.status == 'error') {
          this.notificationService.notifyError(response.message)
        }
      });
    } else {
      this.notificationService.notifyWarning('no_barcode_added');
    }
  }

  /**
   * add the selected item
   */
  addBarcode(): void {
    if (this.selectedRecords.length) {
      this.selectedRecords.forEach( selectedRecord => {
        if (!this.existInList(selectedRecord)) {
          this.recordsToPrint.push(selectedRecord)
        }
      });
      this.notificationService.notifySuccess('barcode_added');
    } else {
      this.notificationService.notifyWarning('no_barcode_selected');
    }
  }

  /**
   * clear the recordsToPrint
   */
  clearBarcode(): void {
    this.recordsToPrint = [];
    this.notificationService.notifySuccess('barcode_clear_succesfully');
  }

  /**
   * determine if the record exist in recordsToPrint
   *
   * @param record
   * @returns
   */
  private existInList(record: LooseObject): boolean {
    return filled(
      this.recordsToPrint.find(
        currentRecord => currentRecord.id == record.id
      )
    )
  }
}