import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LooseObject } from '../../../../../../../objects/loose-object';
import { cloneDeep, get, keys } from 'lodash-es';
import { filled } from '../../../../../../utils/common';
import { RecordService } from '../../../../../../../services/record.service';
import { BarcodeService } from '../../../../../../../services/barcode.service';
import { NotificationService } from '../../../../../../../services/notification.service';

@Component({
  selector: 'fieldmagic-barcode-option-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  selectedRecord: LooseObject[] = [];

  loadingButton = {
    csv: false,
    print: false
  };

  barcodeForm: {
    item: LooseObject
    copy: number
  }[] = [];

  public barcodeMetadata: LooseObject =  {
    item_id: {
      controlType: 'relate',
      key: 'item_id',
      label: 'item_id',
      required: true,
      options: [],
      module: 'items',
    },
    copy: {
      controlType: 'number',
      key: 'copy',
      label: 'copy',
      required: true,
      default_value: 1,
      min_length: 0,
      validator: []
    },
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LooseObject[],
    private dialogRef: MatDialogRef<ListComponent>,
    private recordService: RecordService,
    private barcodeService: BarcodeService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.selectedRecord = this.data;
    this.initializeForm();
  }

  /**
   * initialize the form
   */
  initializeForm(): void {
    this.data.forEach( data => {

      let formGroup = cloneDeep(this.barcodeMetadata);
      formGroup.item_id = data.id;
      this.barcodeForm.push({
        item: data,
        copy: 1
      });
    });
  }

  /**
   * close dialog
   */
  dialogClose(status: string = 'close', message: string = ''): void {
    this.dialogRef.close({
      status: status,
      message: message
    });
  }

  /**
   * print barcode
   */
  printBarcode(): void {
    this.loadingButton.print = true;
    if (this.barcodeService.hasBarcodeConfig()) {

      let recordIds: string[] = this.barcodeForm.map(data => data.item.id);
      this.recordService.getRecordRelate('items', '', recordIds, false, false, recordIds.length).subscribe( response => {

        let barcodeFields = this.barcodeService.generateMultipleBarcodePayload(this.barcodeForm, response);
        if (filled(barcodeFields)) {
          this.barcodeService.generateMultipleItemImage(barcodeFields).subscribe(
            () => this.dialogClose('success', 'barcode_generated_success'),
            error => this.dialogClose('error', get(error, 'error.error', 'content_report_error'))
          );
        } else {
          this.notificationService.notifyError('content_report_error')
        }
      });
    } else {
      this.notificationService.notifyWarning('no_default_barcode');
    }
  }

  /**
   * compiled record
   *
   * @param config
   * @param record
   * @returns
   */
  compileRecord(config: LooseObject, record: LooseObject[], withHeaders: boolean = false): LooseObject[] {

    let compiledRecord: LooseObject[] = [];
    let fieldKeys: string[] = ['id'].concat(keys(config.used_fields));
    if (withHeaders) {

      let header: LooseObject = {};
      fieldKeys.forEach(key => {
        header[key] = key.toUpperCase();
      });
      compiledRecord.push(header);
    }

    record.forEach( data => {
      let row: LooseObject = {};
      fieldKeys.forEach(key => {
        row[key] = data[key] || null;
      });
      compiledRecord.push(row);
    });

    return compiledRecord;
  }

  /**
   * remove item from selected record
   *
   * @param data
   */
  onRemoveItem(data: LooseObject): void {
    let selectedIndex: number = this.selectedRecord.findIndex( items => (items.id == data.item.id));
    if (selectedIndex > -1) {

      this.selectedRecord.splice(selectedIndex, 1);

      this.barcodeForm = [];
      this.initializeForm();
    }
  }
}
