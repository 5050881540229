import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalPipe } from '../../pipes/moment/local/local.pipe';
import { FormatPipe } from '../../pipes/moment/format/format.pipe';
import { TranslateArrayPipe } from '../../pipes/translate-array/translate-array.pipe';
import { HumanReadableDatePipe } from '../../pipes/human-readable-date/human-readable-date.pipe';
import { PlaceholdWithStringPipe } from '../../pipes/placehold-with-string/placehold-with-string.pipe';
import { ChargebeeHumanizedTrialDuration } from '../../pipes/chargebee-humanized-trial-duration/chargebee-humanized-trial-duration.pipe';
import { CustomCurrencyPipe } from '../../pipes/custom-currency-pipe';
import { LineItemsAmountPipe } from '../../pipes/line-items-amount-pipe';
import { PhonePipe } from '../../pipes/phone-pipe';
import { ReadableAddressPipe } from '../../pipes/readable-address.pipe';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { SafePipe } from '../../pipes/safe-pipe';
import { AbsPipe } from '../../pipes/abs-pipe';
import { NewLineToBreakPipe } from '../../pipes/newline-to-break.pipe';
import { ItemNamePipe } from '../../pipes/item-name/item-name.pipe';
import { NormalizeValuePipe } from '../../pipes/normalize-value/normalize-value.pipe';
import { DotNotationPipe } from '../../pipes/dot-notation/dot-notation.pipe';
import { BlankPipe } from '../../pipes/blank.pipe';
import { FilledPipe } from '../../pipes/filled.pipe';
import { DataGetPipe } from '../../pipes/data-get.pipe';
import { EllipsisPipe } from '../../pipes/ellipsis';
import { SafeHtmlPipe } from '../../pipes/safe-html-pipe';
import { ParseSettingsPipe } from '../../features/new-scheduler/dialogs/settings/parse-settings-pipe';
import { SnakeCasePipe } from '../../pipes/snake-case.pipe';
import { DataValuesPipe } from '../../pipes/data-values.pipe';
import { ToIterableDataPipe } from '../../pipes/to-iterable-data.pipe';
import { FormatNumberPipe } from '../../pipes/format_number.pipe';
import { DataKeys } from '../../pipes/data-keys.pipe';
import { SprintfPipe } from '../../pipes/sprintf.pipe';
import { HasAllowPermissionPipe, HasDeniedPermissionPipe } from '../../pipes/has-permission.pipe';
import { WhenPipe } from '../../pipes/conditional.pipe';

@NgModule({
  declarations: [
    PlaceholdWithStringPipe,
    LocalPipe,
    ChargebeeHumanizedTrialDuration,
    FormatPipe,
    HumanReadableDatePipe,
    CustomCurrencyPipe,
    LineItemsAmountPipe,
    TranslateArrayPipe,
    PhonePipe,
    ReadableAddressPipe,
    TruncatePipe,
    SafePipe,
    AbsPipe,
    NewLineToBreakPipe,
    ItemNamePipe,
    NormalizeValuePipe,
    DotNotationPipe,
    BlankPipe,
    FilledPipe,
    DataGetPipe,
    EllipsisPipe,
    SafeHtmlPipe,
    ParseSettingsPipe,
    SnakeCasePipe,
    DataValuesPipe,
    ToIterableDataPipe,
    FormatNumberPipe,
    DataKeys,
    SprintfPipe,
    HasAllowPermissionPipe,
    HasDeniedPermissionPipe,
    WhenPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomCurrencyPipe,
    PlaceholdWithStringPipe,
    LocalPipe,
    ChargebeeHumanizedTrialDuration,
    FormatPipe,
    HumanReadableDatePipe,
    LineItemsAmountPipe,
    TranslateArrayPipe,
    PhonePipe,
    ReadableAddressPipe,
    TruncatePipe,
    SafePipe,
    AbsPipe,
    NewLineToBreakPipe,
    ItemNamePipe,
    NormalizeValuePipe,
    DotNotationPipe,
    BlankPipe,
    FilledPipe,
    DataGetPipe,
    EllipsisPipe,
    SafeHtmlPipe,
    ParseSettingsPipe,
    SnakeCasePipe,
    DataValuesPipe,
    ToIterableDataPipe,
    FormatNumberPipe,
    DataKeys,
    SprintfPipe,
    HasAllowPermissionPipe,
    HasDeniedPermissionPipe,
    WhenPipe,
  ]
})
export class PipesModule { }

