import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { get } from 'lodash-es';
import { CustomEditFieldComponent, Form, FormMode } from '../../../../base/form';
import { CUSTOM_SELECTORS } from './config';
import { LooseObject } from '../../../../objects/loose-object';

@Component({
  selector: 'fc-custom-editform-field',
  template: `
    <ng-container #container></ng-container>
  `
})
export class CustomEditFormFieldComponent implements OnInit {
  /**
   * This dictates whether the form is being edited or for new record
   */
  @Input('formMode') formMode: FormMode;

  /**
   * Contains the parent form instance
   */
  @Input('parentForm') parentForm: FormGroup;

  /**
   * Contains the current field information for this custom field
   */
  @Input('field') field: Form<unknown>;

  /**
   * Contains the additional data you may want to pass to your custom field.
   *
   * @type {LooseObject}
   */
  @Input('additionalData') additionalData: LooseObject

  /**
   * Contains the view reference for the #container view
   */
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  /**
   * Create instance of the component. This component is used to wrap the custom
   * field rendering which allows developers to create module specific field without altering
   * the original parent edit form component
   */
  constructor(
    private readonly cfr: ComponentFactoryResolver
  ) {}

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    const ref = this.container.createComponent<CustomEditFieldComponent>(this.cfr.resolveComponentFactory(
      get(CUSTOM_SELECTORS, this.field.custom_form_options.component_selector)
    ));

    ref.instance.setParentForm(this.parentForm);
    ref.instance.setField(this.field);
    ref.instance.setFormMode(this.formMode);

    if ('setAdditionalData' in ref.instance) {
      ref.instance.setAdditionalData(this.additionalData);
    }
  }
}