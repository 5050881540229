import { BehaviorSubject } from 'rxjs';
import { ListingService } from '../../../../services/listing.service';
import { Component, Input } from '@angular/core';
import { blank, filled } from '../../../utils/common';
import { NotificationService } from '../../../../services/notification.service';
import { filter, finalize, first, switchMap, tap } from 'rxjs/operators';
import { uniqueId } from 'lodash-es';

@Component({
  selector: 'fieldmagic-rebuild-search-database-option',
  template: `
    <fieldmagic-icon
      id='btnRebuildSearchDatabase'
      (click)='onClick()'
      icon='sync-alt'
      [spin]='isInProgress$ | async'
    >
      <span>Rebuild Search Database</span>
    </fieldmagic-icon>
  `,
})
export class RebuildSearchDatabaseOptionComponent {
  @Input() moduleName: string;

  readonly isInProgress$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _listing: ListingService,
    private readonly _notifications: NotificationService
  ) {}

  onClick(): void {
    if (blank(this.moduleName) || this.isInProgress$.getValue()) {
      return;
    }

    const progressNotificationId = `${uniqueId()}_progress-rebuild-notification`

    this._notifications
      .sendConfirmation(
        'Are you sure you want to rebuild the search database for this module? This may take a while to complete.'
      )
      .pipe(
        first(),
        filter((confirmation) => confirmation.answer),
        tap(() => this._notifications.notifyInfo('Scheduling rebuild...', {
          id: progressNotificationId
        })),
        tap(() => this.isInProgress$.next(true)),
        switchMap(() =>
          this._listing.scheduledSearchDatabaseRebuild(this.moduleName)
        ),
        finalize(() => this.isInProgress$.next(false)),
        tap(() => this._notifications.dismissNotification(progressNotificationId))
      )
      .subscribe((result) => {
        if (result.success) {
          return this._notifications.notifySuccess(
            'Search database rebuild scheduled. We will notify you once the rebuild is complete.'
          );
        }

        if (filled(result.error_description)) {
          return this._notifications.notifyError(result.error_description);
        }
      });
  }
}
