<header>
  <div class="d-flex wizard-header">
    <h3 class="dialog-title">
      <fa-icon [icon]="['fas', 'list-ul']" transform="shrink-3" class="dialog-title"></fa-icon>
      <span>&nbsp;{{ 'create_opportunities' | translate }}</span>
    </h3>
    <ng-container *fcHasPermission="'opportunities:create'">
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <fieldmagic-primary-button
              variant="error"
              (click)="wizardService.onDialogClose.next(true)"
              icon="times"
              label="close"
              id="wizardCustomerCancelDialog"
            ></fieldmagic-primary-button>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</header>

<hr>
<ng-container *fcHasPermission="'opportunities:create'; else createOpportunitiesDisabled">
  <create-wizard
    [stepperSettings]="wizardStepper"
    [dialogRef]="dialogRef">

    <ng-template #step1 let-stepper="stepper">

      <customer-step
        [stepper]="stepper"
        [skipWhenPreselected]="true"
        [preselectedCustomer]="dialogData.customer_id"
        [preselectedSite]="dialogData.site_id"
        [bOpenedFromMegaMenu]="dialogData.opened_from_mega_menu"
        (skipStepEmitter)="skipCustomerStep($event, stepper)"
        (objCustomerData)="captureCustomerData($event, stepper)">
      </customer-step>

    </ng-template>

    <ng-template #step2 let-stepper="stepper">

      <contact-step
        [stepper]="stepper"
        [customerId]="strCustomerId"
        [siteId]="strSiteId"
        [bOpenedFromMegaMenu]="dialogData.opened_from_mega_menu"
        [bCustomerStepFinished]="bCustomerStepFinished"
        (skipStepEmitter)="skipContactStep($event, stepper)"
        (objContactData)="captureContactData($event, stepper)">
      </contact-step>

    </ng-template>

    <ng-template #step3 let-stepper="stepper">

      <quote-step
        [stepper]="stepper"
        [jobId]="dialogData.job_id"
        (objQuoteData)="captureQuoteData($event, stepper)">
      </quote-step>

    </ng-template>

    <ng-template #step4 let-stepper="stepper">

      <summary-step [stepper]="stepper" [summary]="objWizard">
        <ng-template #next>
          <button *ngIf="!bLoading; else jobLoading" id="wizardSchedule" type="button" class="btn btn-primary btn-block btn-lg p-2" (click)="create()">
            <strong>{{ 'create_opportunity' | translate }}</strong>
          </button>
          <ng-template #jobLoading>
            <button id="wizardScheduleScheduling" type="button" class="btn btn-primary btn-block btn-lg p-2" [disabled]="true">
              <inline-view-loader [isLoading]="true" [hasText]="false"></inline-view-loader> {{ 'creating' | translate }}...
            </button>
          </ng-template>
        </ng-template>
      </summary-step>

    </ng-template>

  </create-wizard>
</ng-container>

<ng-template #createOpportunitiesDisabled>
  <span id="btnCreateOpportunitiesDisabled" class="disabled"
    matTooltip="{{ 'forbidden_action_error' | translate }}">
    {{ 'forbidden_action_error' | translate }}
  </span>
</ng-template>