import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { MODULES_NO_ACTION, MODULES_NO_ADD, MODULES_NO_DELETE, MODULES_NO_RECORD_VIEW, MODULES_WITHOUT_MERGE, MODULES_WITH_DOWNLOAD, SORT_TYPES, STATUS_FIELDS, SHORT_DISPLAY_FIELDS, LONG_DISPLAY_FIELDS, MODULES_WITH_BARCODE } from './listing-new-config';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ListingService } from '../../../services/listing.service';
import { SearchAfter } from '../../../objects/elasticsearch';
import { ListingElementsEvent } from './objects/listing-elements-event';
import { Common } from '../../../objects/common';
import { ListingFilter } from './objects/listing-filter';
import { ListingHeaders } from './objects/listing-headers';
import { ModuleLogo } from '../../../lists/module-logo';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { AdvanceSearchboxTemplate } from '../../../objects/advance-searchbox';
import { Location } from '@angular/common';
import { debounceTime, filter, first } from 'rxjs/operators';
import { SearchService } from '../../../services/search.service';
import { AsComponent, AsConfigService } from '../../external-libraries/ngx-advanced-searchbox/src/public_api';
import { AddComponent } from './components/add/add.component';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ListingPagination } from './objects/listing-pagination';
import { merge, cloneDeep, isEmpty, get, reduce, toNumber, isNil } from 'lodash-es';
import { SavefilterdialogComponent } from '../listing/savefilterdialog/savefilterdialog.component';
import { MatDialog } from '@angular/material';
import { NotificationService } from '../../../services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LooseObject } from '../../../objects/loose-object';
import { ViewSupplierInventoryComponent } from '../../../admin/items/view-supplier-inventory/view-supplier-inventory.component';
import { blank, data_get, fallback, filled } from '../../utils/common';
import { TagConfig } from '../../../features/tags/objects/tag-config';
import { CustomCreate } from './objects/custom-create';
import { ActionsComponent } from './components/actions/actions.component';
import { ArrService } from '../../../services/helpers/arr.service';
import { MergeRecordComponent } from '../listing/merge-dialog/merge-record/merge-record.component';
import { FilterAction } from './components/filter/filter.component';
import { TranslateService } from '@ngx-translate/core';
import { RecordService } from '../../../services/record.service';
import { BulkUpdateDialogComponent } from './components/dialog/bulk-update-dialog/bulk-update-dialog.component';
import moment from 'moment';

@Component({
  selector: 'fm-listing',
  templateUrl: './listing-new.component.html',
  styleUrls: ['./listing-new.component.scss']
})
export class ListingNewComponent {

  /**
   * Component to use as a custom list instead of the default one.
   *
   * @var {TemplateRef<any>}
   */
  @ContentChild('customList') customListTemplate: TemplateRef<any>;

  /**
   * Custom list of action buttons, aside from the default "Add, Sort, Filter."
   *
   * @var {TemplateRef<any>}
   */
  @ContentChild('customListAction') customListActionTemplate: TemplateRef<any>;

  /**
   * Custom list of extra actions aside from the filter Search, Save, Clear.
   *
   * @var {TemplateRef<any>}
   */
  @ContentChild('customListExtraAction') customListExtraActionTemplate: TemplateRef<any>;

  /**
   * Additional list of actions under the dropdown in the extra actions.
   *
   * @var {TemplateRef<any>}
   */
  @ContentChild('customListExtraActionDropdown') customListExtraActionDropdownTemplate: TemplateRef<any>;

  /**
   * Additional list of actions per record aside from the Edit and Delete buttons.
   *
   * @var {TemplateRef<any>}
   */
  @ContentChild('customListItemAction') customListItemActionTemplate: TemplateRef<any>;

  /**
   * Retrieve the add component as we need to manipulate or
   * reuse some of its methods.
   *
   * @var {AddComponent}
   */
  @ViewChild(AddComponent) addComponent: AddComponent;

  /**
   * Retrieve the actions component and trigger any child component.
   *
   * @var {ActionsComponent}
   */
  @ViewChild(ActionsComponent) actionComponent: ActionsComponent;

  /**
   * AdvancedSearchbox component
   *
   * @var {AsComponent}
   */
  @ViewChild(AsComponent) advancedSearchboxComponent: AsComponent;

  /**
   * Flag to show/hide the custom list.
   *
   * @var {boolean}
   */
  @Input('bShowCustomList') bShowCustomList: boolean = false;

  /**
   * Flag to show/hide the sort.
   *
   * @var {boolean}
   */
  @Input('bShowSort') bShowSort: boolean = true;

  /**
   * Flag to show/hide the filter.
   *
   * @var {boolean}
   */
  @Input('bShowFilter') bShowFilter: boolean = true;

  /**
   * Flag to show/hide the add.
   *
   * @var {boolean}
   */
  @Input('bShowAdd') bShowAdd: boolean = true;

  /**
   * Flag to show/hide the filter.
   *
   * @var {boolean}
   */
  @Input('bAllowCheckboxSelection') bAllowCheckboxSelection: boolean = false;

  /**
   * The module for the add button, sometimes, the module differs when using
   * a custom list.
   *
   * @var {string}
   */
  @Input('strAddModule') strAddModule: string;

  /**
   * The module where this is displayed.
   *
   * @var {string}
   */
  @Input('strModule') strModule: string;

  /**
   * The label for the add button.
   *
   * @var {string}
   */
  @Input('strAddLabel') strAddLabel: string = 'add';

  /**
   * TODO: The header data for the listing. It looks to be not used
   * anymore but only on certain buttons. Will have to re evaluate.
   *
   * @var {objHeaderData}
   */
  @Input('objHeaderData') objHeaderData: ListingHeaders[] = [];

  /**
   * Events happening within this component that needs to
   * be informed to the parent/s.
   *
   * @var {EventEmitter<ListingElementsEvent>}
   */
  @Output('events') listEvents = new EventEmitter<ListingElementsEvent>();

  /**
   * List of subscriptions that must be destroyed after use.
   *
   * @var {Subscription[]}
   */
  public arSubscriptions: Subscription[] = [];

  /**
   * Used for the advanced searchbox.
   *
   * @var {SearchAfter[]}
   */
  public arSearchAfters: SearchAfter[] = [];

  /**
   * List of sort options for this module.
   *
   * @var {Common[]}
   */
  public arSortOption: Common[] = [];

  /**
   * The filter model for this list.
   *
   * @var {ListingFilter}
   */
  public arModel: ListingFilter = new ListingFilter;

  /**
   * TODO: Should be in a separate component.
   * List of saved filters.
   *
   * TODO: Avoid using any, add an interface.
   * @var {any}
   */
  public arSavedFilters: any;

  /**
   * The selected filter to apply to the current list.
   *
   * TODO: Avoid using any, add an interface.
   * @var {any}
   */
  public arSelectedFilter: any;

  /**
   * The rows to display in the listing component.
   *
   * TODO: Avoid using any, add an interface.
   * @var {any}
   */
  public arRowDisplay: any;

  /**
   * The entire list to display.
   *
   * TODO: Avoid using any, add an interface.
   * @var {any}
   */
  public arContent: any;

  /**
   * The metadata of the list to display.
   *
   * TODO: Avoid using any, add an interface.
   *
   * @var {any}
   */
  public arContentType: any;

  /**
   * The selected filter to apply to the current list.
   *
   * @var {string}
   */
  public strTooltipDisabled: string;

  /**
   * If the view is already loaded.
   *
   * @var {boolean}
   */
  public bViewLoaded: boolean = false;

  /**
   * If the list returns no result.
   *
   * @var {boolean}
   */
  public bNoResult: boolean = false;

  /**
   * If the list is still loading.
   *
   * @var {boolean}
   */
  public bLoading: boolean = false;

  /**
   * Contains the pagination data of this listing.
   *
   * @var {ListingPagination}
   */
  public objPagination: ListingPagination = new ListingPagination();

  /**
   * Tag config, will be used to check for whether to show
   * any tag related element.
   *
   * @var {TagConfig}
   */
  public objTagConfig = new TagConfig();

  /**
   * The selected records whose checkboxes are true.
   *
   * @var {Array<object>}
   */
  public arSelectedRecords: Array<object> = [];
  public isSelectAll: boolean = false;
  public isTotalRecordSelected: boolean = false;

  /**
   * Contains data needed for the advanced searchbox.
   *
   * @var {model?: object, template?: Array<object>, form?: FormGroup}
   */
  public objAdvancedSearchbox: {
    model?: object
    template?: Array<object>
    form?: FormGroup
  } = {};

  /**
   * TODO: Move everything to separate component
   * it should have nothing to do with the listing.
   * But will maintain it here to avoid breaking a lot
   * of existing logic.
   */
  public arDropdownModels: any = {};
  public arDropdownOptions: any = {};
  public objTableHeaderOption: any = {};

  public isFilterChanged: boolean = false;
  public lastPage: number;

  public arRecordPerPageOption: number[] = [
    10, 15, 20, 25
  ];

  public excludedBulkUpdateModule = [
    'users',
    'roles',
    'teams',
    'workflows',
    'checklists',
    'pricebooks',
    'warehouses',
    'stock_levels',
    'item_folders',
    'credit_notes',
    'sms_templates',
    'job_templates',
    'subcontractors',
    'quote_templates',
    'email_templates',
    'project_templates',
  ];

  public excludedBulkDeleteModule = [
    'users',
    'roles',
    'item_folders',
    'quote_templates',
  ];

  /**
   * Inform events outside the component.
   *
   * @var {EventEmitter<ListingElementsEvent>}
   */
  public eventPageConfig = new EventEmitter<{
    current_page: number,
    last_page: number,
    pages: number[]
  }>();

  objLegends: LooseObject = {};

  /**
   * Check if this module has a download action.
   *
   * @returns {boolean}
   */
  get hasDownloadAction(): boolean {
    return (MODULES_WITH_DOWNLOAD.indexOf(this.strModule) > -1);
  }

  /**
   * Check if this module has a download action.
   *
   * @returns {boolean}
   */
  get bAllowedDelete() {
    return !(MODULES_NO_DELETE.includes(this.strModule));
  }

  /**
   * Check if this module has a download action.
   *
   * @returns {boolean}
   */
  get bAdd() {
    return !(MODULES_NO_ADD.indexOf(this.strModule) > -1);
  }

  /**
   * Check if this module has a download action.
   *
   * @returns {boolean}
   */
  get bNoAction() {
    return (MODULES_NO_ACTION.indexOf(this.strModule) > -1);
  }

  /**
   * Check if this module has a download action.
   *
   * @returns {boolean}
   */
  get bViewLink() {
    return (MODULES_NO_RECORD_VIEW.indexOf(this.strModule) > -1);
  }

  /**
   * Check if this module has a download action.
   *
   * @returns {boolean}
   */
  get bAllowedMerge() {
    return !(MODULES_WITHOUT_MERGE.includes(this.strModule));
  }

  /**
   * Check if this module has a download action.
   *
   * @returns {boolean}
   */
  get arIcon() {
    return ModuleLogo[this.strModule];
  }

  /**
   * get the current page stored in listing service
   */
  get currentPage(): number {
    return this.listService.getCurrentPageNum();
  }

  /**
   * get the cached listing record per page config
   */
  get recordsPerPage(): number {
    const client = this.localStorageService.getJsonItem('current_client');

    if (blank(client)) {
      return 25;
    }

    return data_get(client, 'config.listing_records_per_page', {
      default_value: 25,
    });
  }

  /**
   * Leeads and customers have 5 columns unlike the other modules
   * who have 4. Have a special width for them so they fit the screen.
   *
   */
  get useFieldWidth() {
    return (this.strModule == 'leads' || this.strModule == 'customers' || this.strModule == 'items')  ? 'other-field-style': 'default-field-style';
  }

  /**
   * Check if this module has a barcode option.
   *
   * @returns {boolean}
   */
  get bOptionBarcode(): boolean {
    return MODULES_WITH_BARCODE.includes(this.strModule);
  }

  /**
   * Event to inform consumers config is done loading.
   *
   * @var {Subject}
   */
  private bConfigLoaded = new Subject<any>();

  /**
   * Config event observable.
   *
   * @var {Observable}
   */
  bConfigLoaded$ = this.bConfigLoaded.asObservable();

  /**
   * Flag to see if we'll wait for config or just run
   * necessary methods.
   *
   * @var {boolean}
   */
  bWaitFilter: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public listService: ListingService,
    private searchService: SearchService,
    private notifService: NotificationService,
    private advSearchConfigService: AsConfigService,
    private localStorageService: LocalStorageService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private arrayService: ArrService,
    private translateService: TranslateService,
    private recordService: RecordService,
  ) {}

  ngOnDestroy(): void {
    this.arSubscriptions.forEach(item => {
      item.unsubscribe();
    });

    // Get stored filter
    let arListingStorage = (this.localStorageService.getItem('listing')) ? JSON.parse(this.localStorageService.getItem('listing')) : {};
    let hasGlobalSearch = !isEmpty(arListingStorage) && filled(get(arListingStorage, [this.strModule, 'filter','global_search']));

    if (hasGlobalSearch) {
      delete arListingStorage[this.strModule]['filter']['global_search'];
    }

    this.localStorageService.setJsonItem('listing', arListingStorage);
  }

  ngOnChanges(objEvent: SimpleChange): void {

    if (
      objEvent['bShowCustomList'] &&
      objEvent['bShowCustomList']['currentValue'] === false
    ) {

      this.arContent = [];

      this.listService.setTableHeaderFields(this.objHeaderData);
      this.listService.setModule(this.strModule);

      this.generateModel();
      this.objAdvancedSearchbox.model = {};

      this.arSubscriptions.push(this.listService.obvInformationFilter$.subscribe( objFilter => {
        if (typeof objFilter === 'object') {
          this.objAdvancedSearchbox.model = objFilter;
          this.filterRecord('go', null, true);
        } else if (typeof objFilter === 'string') {
          this.setFilter(objFilter, 'saved_filter', true);
        }
      }));

      this.objAdvancedSearchbox.form = new FormGroup({});

      this.listService.getConfig(this.strModule).subscribe(({template, filters, metadata}) => {

        this.getSortOptions(template);

        this.arSavedFilters = filters;
        this.setupAdvanceSearchbox(template);
        this.arContentType = metadata;

        this.arSubscriptions.push(this.route.queryParams.subscribe( params => {

          if (params['add_form'] == 'open') {
            this.addComponent.objCustomCreate.params = params;
            this.addComponent.create();
          }

          if (params['filter'])  {
            this.objAdvancedSearchbox.model = JSON.parse(params['filter']);
          }

          if (params['module_search']) {
            let searchValue = Array.isArray(params['module_search']) ? params['module_search'][0] : params['module_search'];
            this.objAdvancedSearchbox.model['global_search'] = {
              'op': 'eq',
              'value': searchValue,
            }

            if (!isEmpty(this.advancedSearchboxComponent)) {
              this.advancedSearchboxComponent.searchBoxControl.setValue(
                {
                  'op': 'eq',
                  'label': searchValue,
                  'value': searchValue,
                }
              );
            }
          }

          if (params['filter'] || params['module_search']) {
            this.getData('default');
            return;
          }

          if (params.trigger !== undefined) {
            return;
          }

          const pageNum = Math.abs(parseInt(params.page));
          if (Number.isNaN(pageNum) === false) {
            this.listService.setCurrentPageNum(pageNum);
          }

          const arListingStorage = (this.localStorageService.getItem('listing')) ? JSON.parse(this.localStorageService.getItem('listing')) : {};

          if (arListingStorage[this.strModule] && arListingStorage[this.strModule]['sort'] !== undefined) {
            this.listService.setOrderBy(arListingStorage[this.strModule]['sort']);
            this.arModel['order_by'] = arListingStorage[this.strModule]['sort'];
          }

          if (arListingStorage[this.strModule] && arListingStorage[this.strModule]['filter'] !== undefined) {
            const filterName = arListingStorage[this.strModule]['filter_name'];
            if (filterName !== undefined && filterName !== null && filterName !== "") {
              this.setFilter(filterName, 'saved_filter', false);
            } else {
              this.objAdvancedSearchbox.model = arListingStorage[this.strModule]['filter'];
              this.changeDetectorRef.detectChanges();
              this.getData(Number.isNaN(pageNum) ? 'default' : 'reload', null);
            }
          } else {
            this.getData(Number.isNaN(pageNum) ? 'default' : 'reload', null);
          }
        }));
      });
      this.bViewLoaded = true;
    }

  }

  /**
   * This method forces a change detect. This is useful
   * so we can re-use the proceess triggered on
   * change detect.
   *
   * @returns {void}
   */
  forceComponentChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * This method is triggered by the child components which in
   * turn does something based on the event.
   *
   * @param {ListingElementsEvent} objEvent
   */
  childEvent(objEvent: ListingElementsEvent): void {

    if (objEvent.type === 'create_closed') {
      setTimeout(() => {
        this.filterRecord('default');
      }, 2000);
    }

    if (objEvent.type == 'redirect_view') {
      this.redirectToRecordView(objEvent.data);
    }

    if (objEvent.type == 'paginate') {
      let pageNum = 1;

      if (objEvent.data == 'next') {
        pageNum = this.listService.getCurrentPageNum();
      } else if (objEvent.data == 'prev') {
        pageNum = this.listService.getCurrentPageNum();
      } else if (! isNil(get(objEvent, 'page'))) {
        pageNum = toNumber(objEvent.page);
      }

      if (pageNum < 1 || isNaN(pageNum)) {
        pageNum = 1;
      }

      this.listService.setCurrentPageNum(pageNum);
      this.filterRecord(objEvent.data)
    }

    if (objEvent.type == 'sort') {
      this.filterRecord('sort', objEvent.data, true)
    }

    if (objEvent.type == 'add') {
      this.addComponent.create(objEvent.data);
    }

    this.listEvents.emit(objEvent);

  }

  /**
   * Redirects to the record view.
   *
   * @param {string} strId
   * @param {number} numIndex
   *
   * @returns {void}
   */
  redirectToRecordView(
    strId: string,
    numIndex: number = -1
  ): void {

    if (!MODULES_NO_RECORD_VIEW.includes(this.strModule)) {
      if (numIndex > -1) {
        this.router.navigate([strId], {
          state: {
            fromListView: true,
            page: this.listService.getCurrentPageNum(),
            searchAfter: this.arSearchAfters[numIndex],
          },
          relativeTo: this.route
        });
      } else {
        this.router.navigate([strId], {
          state: {
            fromListView: false
          },
          relativeTo: this.route
        });
      }
    }

  }

  /**
   * Get all the sort options.
   *
   * @param {AdvanceSearchboxTemplate[]} arTemplate
   *
   * @return {void}
   */
  getSortOptions(arTemplate: AdvanceSearchboxTemplate[]): void {
    this.arSortOption = [];
    arTemplate.forEach( field => {
      if (SORT_TYPES.includes(field.dataType)) {
        this.arSortOption.push(new Common(field.label, field.model, ''));
      }
    });
  }

  /**
   * Generate the models needed for sort, filters, and so on.
   *
   * @param {string | null} strType
   *
   * @returns {void}
   */
  generateModel(strType: string | null = null): void {

    if (strType != 'default_filter' && strType != 'saved_filter') {
      this.arModel['order_by'] = this.listService.defaultOrderBy as any || {
        id: 'updated_at',
        sort: 'desc'
      };
    }

    if (this.arModel['share'] != undefined) {
      this.arModel['share'] = false;
    }

    this.arModel['default_filter'] = null;
    this.arModel['has_filter'] = false;
    this.arModel['filter_name'] = null;

  }

  /**
   * Filter record list
   *
   * @param {string} strAction
   * @param {string} objFilter
   * @param {boolean} bReset
   *
   * @return {void} Refresh the list view
   */
  filterRecord(
    strAction: string,
    objFilter = null,
    bReset: boolean = false
  ): void {

    let numPage: number = 1;

    switch (strAction) {
      case 'prev':
        numPage = this.listService.getCurrentPageNum() - 1;
        break;
      case 'next':
        numPage = this.listService.getCurrentPageNum() + 1;
        break;
      case 'with_filter':
        numPage = this.listService.getCurrentPageNum();
        break;
      default:
        numPage = 1;
        break;
    }

    if (bReset) {
      numPage = 1;
    }

    const navExtras: NavigationExtras = {
      queryParams: { page: numPage },
      replaceUrl: true,
    }

    /// retreieved the filter information from the saved filters
    const filters: Record<string, any> = reduce(this.arSavedFilters, (acc, filter) => ({
      ... acc,
      ... filter,
    }), {});

    this.arSelectedFilter = filters[objFilter];

    if (['with_filter', 'sort', 'go'].includes(strAction)) {

      this.location.replaceState(
        this.router.serializeUrl(
          this.router.createUrlTree([], navExtras)
        )
      );

      if (strAction === 'go') {
        this.getData('default');
      } else {
        this.getData(bReset ? 'default' : 'reload', objFilter);
      }

    } else {

      this.router.navigate([], {
        queryParams: {trigger: false}
      }).then(() => {
        this.router.navigate([], navExtras);
      });

    }
  }

  /**
   * This sets up the advanced searchbox.
   *
   * @param {object[]} template
   *
   * @returns {void}
   */
  async setupAdvanceSearchbox(template: object[]): Promise<void> {

    this.objAdvancedSearchbox.model = {};
    this.objAdvancedSearchbox.template = template;

    this.objAdvancedSearchbox.template.forEach((asTemplate: AdvanceSearchboxTemplate) => {
      if (asTemplate.domains && asTemplate.domains === 'remote') {
        this.advSearchConfigService.customDomainsAsyncFn[asTemplate.model] = (observable, viewModel, model) => {
          return observable.pipe(
            debounceTime(400),
          ).switchMap((term) => {
            return this.searchService.getRemoteData(asTemplate, term);
          });
        };
      }
    });

  }

  /**
   * Retrieves the data from api depending on the
   * page number and render it to the listing page
   *
   * @param {string} numPage
   * @param {object} objFilter
   *
   * @returns {Promise<void>}
   */
  async getData(strPage: string, objFilter: object = null): Promise<void> {

    await new Promise(resolve => setTimeout(resolve, 100));

    const objPagination = this.listService.beforeFetching(strPage);
    if (objFilter != null && Object.keys(objFilter).length) {
      objPagination['objFilters'] = objFilter;
    }

    const sortParams = this.listService.getOrderBy();

    const filterParams = this.objAdvancedSearchbox.model;

    if (!isEmpty(this.advancedSearchboxComponent) && !isEmpty(this.advancedSearchboxComponent.searchBoxValue)) {
      filterParams['global_search'] = {
        op: 'eq',
        value: get(this.advancedSearchboxComponent.searchBoxValue, 'value', this.advancedSearchboxComponent.searchBoxValue)
      }
    }

    const predefinedFilterName = objFilter === null ?
      null :
      typeof(objPagination['objFilters']) === 'object' ?
      (objPagination['objFilters']['filter_name'] !== undefined ? objPagination['objFilters']['filter_name'] : null) :
      objPagination['objFilters'] as string;

    this.listService.fetchDataAdvanceSearch(
      objPagination['objPage'],
      this.strModule,
      filterParams,
      sortParams,
      predefinedFilterName,
      this.recordsPerPage
    ).pipe(
      debounceTime(100)
    ).subscribe( data => {

      this.arRowDisplay = data['row_display'];
      this.arContent = data['data'];
      this.arSearchAfters = data['search_after'];
      this.objLegends = data['legends'];

      this.setDisabledTooltip();

      this.listService.afterFetching(data, strPage);

      this.bNoResult = this.arContent.length > 0 ? false : true;
      this.arModel.order_by = data['order_by'];
      this.bLoading = false;

      if (Object.keys(this.arDropdownModels).length === 0) {
        this.generateModelOptions();
      }

      this.storeModuleFilter({
        page: objPagination['objPage'],
        filter: filterParams,
        sort: sortParams,
        filter_name: predefinedFilterName
      });

      this.objPagination.next_page = data['hasNextToken'];
      this.objPagination.previous_page = data['hasPreviousToken'];
      this.objPagination.from_records = data['from_records'];
      this.objPagination.to_records = data['to_records'];
      this.objPagination.total_records = data['total_records'];

      this.isAllRecordSelected();
      this.initializePages(data['last_page']);

      if (this.isTotalRecordSelected) {
        this.isTotalRecordSelected = false;
        this.isSelectAll = false;
        this.arSelectedRecords = [];
      }

    });
  }

  /**
   * Set the tooltip depending on the module.
   *
   * @returns {void}
   */
  setDisabledTooltip(): void {
    if (this.strModule == 'leads') {
      this.strTooltipDisabled = 'disabled_edit_leads';
    }
  }

  /**
   * Trigger the filter event.
   *
   * @returns {void}
   */
  triggerFilter(): void{
    setTimeout(() => {
      this.isFilterChanged = true;
      this.filterRecord('go');
    }, 20);
  }

  /**
   * Save the last filter that user created or selected
   *
   * @param {LooseObject} objFilterData
   *
   * @returns {void}
   */
  storeModuleFilter(objFilterData: LooseObject): void {
    const arListingStorage = (this.localStorageService.getItem('listing')) ? JSON.parse(this.localStorageService.getItem('listing')) : {};
    arListingStorage[this.strModule] = objFilterData;
    this.localStorageService.setJsonItem('listing', arListingStorage);
  }

  /**
   * Check field for minimize the width.
   *
   * @param {string[]} arField
   *
   * @returns {void}
   */
  checkStatusField(arField: string[] = []): boolean {
    return (typeof arField[0] != 'undefined' && STATUS_FIELDS.includes(arField[0]));
  }

  /**
   * For fields that need short display (width: 11%).
   * Feel free to modify as more fields may require short display fields.
   *
   * @param {string[]} arField
   *
   * @returns {boolean}
   */
  checkShortDisplayField(arField: string[] = []): boolean {
    return arField.length > 0 &&
      filled(arField[0]) &&
      get(SHORT_DISPLAY_FIELDS, this.strModule, []).includes(arField[0]);
  }

  /**
   * For fields that need long display (width: 25%).
   * Feel free to modify as more fields may require long display fields.
   *
   * @param {string[]} arField
   *
   * @returns {boolean}
   */
  checkLongDisplayField(arField: string[] = []): boolean {
    return arField.length > 0 &&
      filled(arField[0]) &&
      get(LONG_DISPLAY_FIELDS, this.strModule, []).includes(arField[0]);
  }

  /**
   * Saves the current filter.
   *
   * @returns {void}
   */
  saveCurrentFilter(): void {
    const arSelectedFilter = fallback(this.arSelectedFilter, {
      fallback: () => ({}),
    });

    if (arSelectedFilter['is_removable'] === false) {
      arSelectedFilter['id'] = null;
    }

    this.arModel['default_filter'] = '';
    let tabDialogRef = this.dialog.open(SavefilterdialogComponent, {
      width: '500px',
      height: 'auto',
      data: {
        "objFilterBody": {...this.arModel},
        "arLabels": this.objHeaderData,
        "arRelate": [],
        "arFilterOption": this.objTableHeaderOption,
        "arSelectedFilter": arSelectedFilter
      }
    });

    tabDialogRef.afterClosed().subscribe(
      saveFilterResult => {

        if (saveFilterResult) {
          if (saveFilterResult && saveFilterResult['filter_body']['filter_name'] != undefined) {
            saveFilterResult['filter_body']['filter_name'] = saveFilterResult['filter_name'];
          }

          if (this.arSelectedFilter) {
            saveFilterResult['id'] = this.arSelectedFilter['id'];
          }

          saveFilterResult['filter_body']['filter_structure'] = this.objAdvancedSearchbox.model;
          this.listService.saveCreatedFilter(this.strModule, JSON.stringify(saveFilterResult)).subscribe(
            data => {
              this.arSavedFilters = data['filters']
              this.arModel['filter_name'] = data['filter_name'];
              this.listService.setFilter(data['filter_name']);
              this.setFilter(data['filter_name'], 'saved_filter', true);
              this.notifService.notifySuccess("filter_successfully_saved");
          }, (err: HttpErrorResponse) => {
            this.notifService.notifyError(err.error.message);
          });
        }
      }
    );

  }

  /**
   * Get table headers
   *
   * @param {string} strOptionId
   *
   * @returns {Common[]}
   */
  getTableHeaderOption(strOptionId: string): Common[] {
    return (this.objTableHeaderOption[strOptionId] != undefined) ? this.objTableHeaderOption[strOptionId] : [new Common('', 'no_available_filter', '')];
  }

  /**
   * Generate the model options for this.
   *
   * @returns {void}
   */
  generateModelOptions(): void {
    this.objHeaderData.forEach( objFieldData => {
      if (objFieldData['option'] != "") {

        let strId: string = objFieldData.id as any;

        this.arDropdownModels[strId] = {};

        this.arDropdownOptions[strId] = this.getTableHeaderOption(strId).map(
          item => {

            this.arDropdownModels[strId][item.id] = false;
            return item.id;
          }
        );

      }
    });
  }

  /**
   * Set the filter based on the filter type
   *
   * @param {string | []} strFilter
   * @param {string} strType
   * @param {boolean} bReset
   * @param {boolean} bResetQuickFilter
   *
   * @returns void
   */
  setFilter(
    strFilter: string | [],
    strType: string,
    bReset: boolean = false,
    bResetQuickFilter: boolean = false
  ): void {
    let currentFilter: any = '';

    if (strFilter) {

      switch(strType) {
        case 'default_filter':
          if (strType == 'default_filter' && Object.keys(this.route.snapshot.queryParamMap['params']).length > 0) {


            let objFilterFromLink = {...this.route.snapshot.queryParamMap['params']};
            let strLabel = objFilterFromLink['label'];
            delete objFilterFromLink['label'];

            this.arModel['default_filter'] = '';
            this.arModel['filter_name'] = strLabel;

            currentFilter = objFilterFromLink;
          } else {
            this.arModel['default_filter'] = strFilter as any;
            this.arModel['filter_name'] = '';
            currentFilter = this.arModel;
          }
          break;
        case 'saved_filter':
          currentFilter = strFilter;
          this.arModel['filter_name'] = strFilter as any;
          if (bReset) {
            this.listService.setOrderBy({});
          }

          let filterData = null;
          if (this.arSavedFilters.default[currentFilter] !== undefined) {
            filterData = this.arSavedFilters.default[currentFilter];
          } else if (this.arSavedFilters.saved[currentFilter] !== undefined) {
            filterData = this.arSavedFilters.saved[currentFilter];
          }

          if (filterData !== null) {
            const objFilterStructure = this._formatFilter(filterData.config.filter_structure);

            this.objAdvancedSearchbox.model = objFilterStructure
          }
          break;

        case 'set_filter':

          Object.keys(strFilter).forEach( strFilterKey => {
            if (strFilter[strFilterKey]) {
              this.arModel[strFilterKey] = strFilter[strFilterKey];
            }
          });

          if (typeof(strFilter) != 'object') {
            this.arModel['default_filter'] = '';
          }

          if (this.arSelectedFilter['config'] != undefined) {
            Object.keys(this.arSelectedFilter['config']).forEach( index => {
              if (index != 'order_by') {
                this.arModel[index] = this.arSelectedFilter['config'][index];
              }
            });
          }

          currentFilter = this.arModel;
        break;

        default:
          this.arModel['filter_name'] = '';
          currentFilter = strFilter;
        break;
      }
    }

    Object.keys(strFilter).forEach( strFilterKey => {
      if (strFilter[strFilterKey] && strFilterKey != 'order_by') {
        this.arModel['hasFilter'] = true;
      }
    });

    this.listService.setFilter(currentFilter);
    this.filterRecord('with_filter', strFilter, bReset);
  }

  /**
   * Clears all filters.
   *
   * @returns {void}
   */
  clearAllFilters(): void {

    this.objAdvancedSearchbox.model = {};

    this.listService.setFilter('clear');
    this.generateModel();

    if (this.arModel['order_by']) {
      this.arModel['order_by'] = {
        id: 'updated_at',
        sort: 'desc'
      };
      this.listService.setOrderBy(this.arModel['order_by']);
    }

    this.filterRecord('go');

  }

  /**
   * Creates a URL that goes from list view to record view
   *
   * @param   {string}  strRecordId     The record id of the current item
   * @param   {number}  numRecordIndex  The index of the current item
   *
   * @return  {string}
   */
  generateRecordUrl(strRecordId: string, numRecordIndex: number = -1): string {
    return this.listService.getListRecordURL(
      this.strModule,
      strRecordId,
      numRecordIndex,
      this.listService.getCurrentPageNum(),
      [],
      cloneDeep(this.arSearchAfters),
      this.route
    );
  }

  /**
   * generate a set of array that will be showed in listing pagination
   *
   * @param totalPage
   */
  initializePages(totalPage: number): void {
    let maxTotalPage = 10000/this.recordsPerPage <= totalPage
      ? 10000/this.recordsPerPage
      : totalPage;
    this.lastPage = maxTotalPage;
    let pageList: number[] = [];
    let numberOfPage = 5
    for (let startPage = 1; startPage <= maxTotalPage; startPage++) {
      pageList.push(startPage);
    }

    let pageStart: number = (this.currentPage-3 > 0 && maxTotalPage > 5) ? this.currentPage-3 : 0;
    let pageEnd: number =  (this.currentPage+2 > numberOfPage) ? this.currentPage+2 : numberOfPage;
    if (this.lastPage - pageEnd < 0  && maxTotalPage > numberOfPage) {
      pageStart = pageStart + maxTotalPage - pageEnd;
    }

    if (this.isFilterChanged && 10000/this.recordsPerPage <= totalPage) {
      this.notifService.notifyWarning('exceed_max_number_of_displayed_record')
      this.isFilterChanged = false;
    }

    this.eventPageConfig.emit({
      current_page: this.currentPage,
      last_page: this.lastPage,
      pages: pageList.slice(pageStart, pageEnd)
    });

    // if the last page of page list is equivalent to the last page
    // we should disable the next page
    if (
      this.objPagination.next_page
      && this.lastPage == pageList.slice(pageStart, pageEnd).pop()) {
      this.objPagination.next_page = false;
    }
  }

  /**
   * If the record is selected.
   *
   * @param arRecordData
   * @returns
   */
  isRecordSelected(arRecordData) {
    return this.arSelectedRecords.findIndex(selectedRecord => selectedRecord['id'] === arRecordData['id']) > -1;
  }

  /**
   * This will add and remove the selected record data
   * @param event
   * @param recordData
   *
   */
  selectRecordChange(event, recordData) {
    if (event.target.checked) {
      this.arSelectedRecords.push(recordData);
    } else {
      this.arSelectedRecords.splice(this.arSelectedRecords.findIndex(record => record['id'] === recordData['id']), 1);
    }
  }

  /**
   * set current page number
   *
   * @param page
   */
  setPageNumber(page: number): void {
    this.listService.setCurrentPageNum(page);
    this.getData('reload');
  }

  /**
   * Trigger the edit in the child.
   *
   * @param {string} strId
   * @param {any} arData
   */
  goToEdit(strId: string, arData = []): void {
    this.actionComponent.goToEdit(strId, arData);
  }

  /**
   * It will open the merge dialog.
   *
   * @returns {void}
   */
  openMergeDialog() {

    let bHasConvertedRecord = false;
    let bHasInvoice = false;
    let arInvoicingType = this.arrayService.getUniqueValues(this.arSelectedRecords, 'invoicing_type');

    this.arSelectedRecords.forEach( arRecord => {
      if (this.strModule === 'leads' && (arRecord['status'] === 'converted' || arRecord['status'] === 'disqualified')) {
        bHasConvertedRecord = true;
      }

      if (this.strModule === 'jobs' && (arRecord['has_invoice'])) {
        bHasInvoice = true;
      }
    });

    if (this.arSelectedRecords.length < 2 || this.arSelectedRecords.length > 3) {
      this.notifService.notifyWarning('merge_selected_record_warning');
    } else if(MODULES_WITHOUT_MERGE.includes(this.strModule)) {
      this.notifService.notifyWarning('merging_record_not_allowed');
    } else if (bHasConvertedRecord) {
      this.notifService.notifyWarning('merging_lead_record_not_allowed');
    } else if(arInvoicingType.length > 1 && bHasInvoice) {
      this.notifService.notifyWarning('merging_job_record_not_allowed');
    } else {
      let popupConfig = {
        data: {
          records: this.arSelectedRecords,
          module: this.strModule,
        },
        disableClose: true,
        maxWidth: '100%',
        width: '99%',
        height: '99%',
        padding: '1%',
      };

      let dialogRef = this.dialog.open(MergeRecordComponent, popupConfig);
      dialogRef.afterClosed().pipe(
      first(),
      filter((response) => response.action === 'success')
      ).subscribe(() => {
          this.arSelectedRecords = [];
          this.notifService.notifySuccess('merge_success');
          this.filterRecord('go')
      });
    }
  }

  /**
   * Listen to any events from filter and do an action from it.
   *
   * @param {FilterAction} objFilterEvent
   *
   * @returns {void}
   */
  filterEvents(objFilterEvent: FilterAction) {
    if (objFilterEvent.action == 'clear_filter') {
      if (this.arModel['filter_name'] === objFilterEvent.key) {
        this.clearAllFilters();
      }
      if (this.arSavedFilters['saved'][objFilterEvent.key]) {
        delete this.arSavedFilters['saved'][objFilterEvent.key];
      }
    } else {
      this.setFilter(objFilterEvent.key, objFilterEvent.action, objFilterEvent.reset);
    }
  }
  /**
   * mark the checkbox
   *
   * @param event
   * @param data
   * @param mark_all
   */
  ngOnChangeCheckbox(event: Event, data: object, mark_all: boolean = false): void {
    if (mark_all) {
      if (this.arContent.length) {
        this.arContent.forEach( data => {
          let selectedIndex = this.arSelectedRecords.findIndex(record => record['id'] === data['id']);
          if (this.isSelectAll && selectedIndex != -1) {
            this.arSelectedRecords.splice(selectedIndex, 1);
          } else if(!this.isSelectAll && selectedIndex < 0) {
            this.arSelectedRecords.push(data);
          }
        });

        this.isSelectAll = (this.isSelectAll) ? false : true;
        this.isTotalRecordSelected = this.isTotalRecordSelected && !this.isSelectAll ? false : this.isTotalRecordSelected;
      }
    } else {
      var record_id = (data['id']) ? data['id'] : null;
      if (record_id && typeof event.currentTarget['checked'] === 'boolean') {
        if (event.currentTarget['checked']) {
          this.arSelectedRecords.push(data);
        } else {
          this.arSelectedRecords.splice(this.arSelectedRecords.findIndex(record => record['id'] === data['id']), 1);
        }
      }

      this.isAllRecordSelected();
      this.isTotalRecordSelected = this.isTotalRecordSelected && !this.isSelectAll ? false : this.isTotalRecordSelected;
    }
  }

  processDeleteMultipleRecord(): void {
    var records = [];
    this.arSelectedRecords.forEach( record => {
      records.push({id: record['id']});
    });

    let data = {
      record_ids: records,
      additional_data: {
        is_total_selected: this.isTotalRecordSelected,
        filter: this.objAdvancedSearchbox.model,
      }
    }

    this.listService.bLoading = true;

    this.recordService.deleteMultipleRecord(this.strModule, data).subscribe(async response => {
      if (response.status === 200) {
        await this.filterRecord('default');

        this.isSelectAll = false;
        this.arSelectedRecords = [];
        var totalLength = this.isTotalRecordSelected ? this.objPagination.total_records : records.length;
        var notificationMessage = this.translateService.instant('bulk_delete_success') +
          ` ${response.body.success} ` + this.translateService.instant('of') +
          ` ${totalLength} `;

        this.notifService.sendNotification('success', notificationMessage, 'success', 20000);
      }
      this.listService.bLoading = false;
    }, error => {
      if (error.status == 422) {
        var notificationMessage = this.translateService.instant('bulk_delete_failed') + "\n";

        if (filled(error.error.error)) {
          notificationMessage += this.translateService.instant(error.error.error);
        }

        this.notifService.sendNotification('warning', notificationMessage, 'warning', 20000);
      }
      this.listService.bLoading = false;
    });
  }

  /**
   * make api call to delete the selected record
   */
  deleteMultipleRecord(): void {
    if (this.isTotalRecordSelected == true && this.objPagination.total_records > 10000) {
      this.notifService.sendConfirmation('maximum_record_selected', 'warning', 'default')
      .filter(confirmation => confirmation.answer === true)
      .subscribe(() => {
        this.processDeleteMultipleRecord();
      });
    } else {
      if (this.arSelectedRecords.length) {
        this.notifService.sendConfirmation('confirm_multiple_delete', 'warning', 'default')
          .filter(confirmation => confirmation.answer === true)
          .subscribe(() => {
            this.processDeleteMultipleRecord();
          });
      }
    }
  }

  /**
   * Open dialog to set field and value to update
   */
  openUpdateDialog(): void {
    if (this.isTotalRecordSelected == true && this.objPagination.total_records > 10000) {
      this.notifService.sendConfirmation('maximum_record_selected', 'warning', 'default')
      .filter(confirmation => confirmation.answer === true)
      .subscribe(() => {
        this.updateDialog();
      });
    } else {
      if (this.arSelectedRecords.length) {
          this.updateDialog();
      }
    }
  }

  updateDialog(): void {
    if (Object.keys(this.arSelectedRecords).length) {

      let updateDialog = this.dialog.open(BulkUpdateDialogComponent, {
        data: {
          module: this.strModule,
          records: this.arSelectedRecords,
          is_total_selected: this.isTotalRecordSelected,
          current_filter: this.objAdvancedSearchbox.model,
          total_records: this.objPagination.total_records,
        },
        disableClose: true,
        maxHeight: '80vh',
        width: '70%',
        height: 'auto',
      });
      updateDialog.afterClosed().subscribe( response => {
        if (response && response['module']) {
          this.isSelectAll = false;
          this.arSelectedRecords = [];
          this.strModule = response['module'];
          this.filterRecord('default');
        }
      });
    }
  }

  triggerSelectTotalRecords(event, isTotalRecordSelected) {
    if (this.isTotalRecordSelected) {
      this.isSelectAll = false;
      this.arSelectedRecords = [];
    }

    this.isTotalRecordSelected = isTotalRecordSelected ? false : true;
  }

  isAllRecordSelected() {
    if (this.arContent.length) {
      let stopLoop = false;
      this.arContent.forEach( data => {
        if (stopLoop) return;

        let selectedIndex = this.arSelectedRecords.findIndex(record => record['id'] === data['id']);

        if (selectedIndex < 0) {
          this.isSelectAll = false;
          stopLoop = true;
        } else {
          this.isSelectAll = true;
        }
      });
    }
  }

  bulkUpdateEvent(event) {
    if (event == 'success') {
      this.isSelectAll = false;
      this.arSelectedRecords = [];
      this.filterRecord('default');
    }
  }

  isModuleAllowedBulk(module: string, excludedBulkModule: Array<string>) {
    return !excludedBulkModule.includes(module);
  }

  isDisabled(data) {
    // This will be comment for now as we need
    // to discuss for this
    // if (this.strModule == 'users') {
    //   return data['level'] == 'subcontractor';
    // }

    return false;
  }

  private _formatFilter(filterStructure: any): LooseObject {
    const formattedFilter: LooseObject = {};

    if (Array.isArray(filterStructure) && filterStructure.length > 0) {
      filterStructure.forEach(data => {
        formattedFilter[data.model] = {
          op: data.value.operator,
          value: data.value.value
        }
      });
    } else {
       Object.keys(filterStructure).forEach(strField => {
        filterStructure[strField].forEach(data => {
          if (get(data, 'is_custom_date_filter') === true) {
            formattedFilter[strField] = this._formatCustomDateFilters(data);
          } else {
            formattedFilter[strField] = data;
          }
        });
       });
    }

    return formattedFilter
  }

  private _formatCustomDateFilters(filterData: LooseObject): LooseObject[] {
    if (filterData.timespan === 'last') {
      const start = moment().subtract(
        filterData.number,
        filterData.period
      ).format('MM/DD/YYYY');

      return [{
        op: filterData.op,
        value: start
      }];
    } else {
      const start = moment().format('MM/DD/YYYY');
      const end = moment().add(
        filterData.number,
        filterData.period
      ).format('MM/DD/YYYY');

      const startFilter = {
        op: 'ge',
        value: start
      };

      const endFilter = {
        op: 'le',
        value: end
      };

      return [startFilter, endFilter];
    }
  }
}
