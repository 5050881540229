
    <div
      class="alert"
      [class.alert-danger]="variant == 'error'"
      [class.alert-warning]="variant == 'warning'"
      [class.alert-success]="variant == 'success'"
      [class.alert-info]="variant == 'info'"
    >
      <small>
          <fa-icon [icon]="['fas', iconName]" class="mr-2"></fa-icon>

          <fieldmagic-text
            *ngIf="content | filled"
            purpose="body"
            [content]="content"
          >
          </fieldmagic-text>

          <ng-content
            *ngIf="content | blank"
          >
          </ng-content>
      </small>
    </div>
  