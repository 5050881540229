import { Component, OnInit, OnDestroy } from '@angular/core';
import { RecordService } from '../../../services/record.service';
import { ViewService } from '../../../services/view.service';
import { ArrService } from '../../../services/helpers/arr.service';
import { ActivatedRoute } from '@angular/router';
import { get as _get, isEmpty as _isEmpty} from 'lodash-es';
import { WidgetTab } from '../../../objects/widget-tab';
import { STARTER_PLAN } from '../../../objects/subscription-plans';
import { StockLevel } from '../../../objects/stock-management/stock-level';
import { FileService } from '../../../services/file/file.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'stock-level-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {

  /**
   * Loading flag for the stock level view.
   *
   * @var {boolean}
   */
  public bLoading: boolean = true;
  public strImageUrl: string = null;

  /**
   * Contains the stock level.
   *
   * @var {StockLevel}
   */
  public objStockLevel: StockLevel;

  public arAvailableTabs: WidgetTab[] = [
    {type: 'warehouses', singular: 'warehouse', icon: 'warehouse-alt', default_active: 'active', minimum_subscription_required: STARTER_PLAN},
    {type: 'stock-transfers', singular: 'stock-transfer', icon: 'exchange-alt', default_active: '', minimum_subscription_required: STARTER_PLAN},
    {type: 'stock-adjustments', singular: 'stock-adjustment', icon: 'sliders-h', default_active: '', minimum_subscription_required: STARTER_PLAN},
  ];

  constructor(
    private route: ActivatedRoute,
    private recordService: RecordService,
    private viewService: ViewService,
    private fileService: FileService,
    public arrService: ArrService
  ) {
    this.route.params.subscribe( params => {
      this.fetchRecord(params['id']);
    });
  }

  ngOnInit() {}

  /**
   * {@inheritdoc}
   */
  ngOnDestroy() {}

  /**
   * Retrieve the stock level record.
   *
   * @param {string} strRecordId
   */
  fetchRecord(strRecordId: string): void {

    this.bLoading = true;

    this.recordService.getRecord('stock_levels', strRecordId, true, {}, 'stock_levels:view', 'get/stock_levels')
      .filter(objResponse => !_isEmpty(objResponse['record_details']))
      .map(objResponse => { return new StockLevel(objResponse['record_details']) })
      .finally(() => { this.bLoading = false; })
      .subscribe(objResponse => {

        this.objStockLevel = objResponse;

        let strImageUploadName = _get(this.objStockLevel, 'image', null);
        strImageUploadName = strImageUploadName ? JSON.parse(strImageUploadName) : null;

        if (strImageUploadName) {
          this.fileService.getObjectSignedUrl(strImageUploadName['upload_name'], environment.client_bucket)
          .subscribe(signedUrlResponse => {
            this.strImageUrl = signedUrlResponse['url'];
          });
        }

        this.viewService.setViewRecord(this.objStockLevel);
        this.objStockLevel.preferred_supplier = this.objStockLevel.customer_name ?
          this.objStockLevel.customer_name : (this.objStockLevel.customer_first_name?
          (this.objStockLevel.customer_first_name + ' ' + this.objStockLevel.customer_last_name) : '--');

      });

  }
}
