import { Component, Input, OnInit } from '@angular/core';
import { Select } from '../../../../objects/select';
import { FormControl } from '@angular/forms';
import { LooseObject } from '../../../../objects/loose-object';

@Component({
  selector: 'fc-user-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {

  /**
   * The form passed from parent.
   *
   * @var {FormControl}
   */
  @Input('form') form: FormControl;

  /**
   * The field, usually the value of the form control
   * but with modification passed from parent.
   *
   * @var {object}
   */
  @Input('field') field: {
    key: string,
    value: {
      start_time: string,
      end_time: string
    }
  };

  /**
   * The current time selections available.
   *
   * @var {object}
   */
  timeSelection: {
    to: Select[],
    from: Select[]
  } = {
    to: [],
    from: []
  };

  constructor() { }

  ngOnInit() {
    for (let index = 0; index < 24; index++) {
      ['00', '30'].forEach(item => {
        this.timeSelection.to.push(
          this.createTime(index, item)
        );
        this.timeSelection.from.push(
          this.createTime(index, item)
        );
      });
    }
  }

  /**
   * Creates a list of available time.
   *
   * @param {number} numTime
   * @param {string} numMins
   *
   * @returns {Select}
   */
  createTime(numTime: number, numMins: string): Select {
    return new Select(
      numTime.toString().padStart(2, '0') + `:${(numMins)}:00`,
      ((numTime > 11) ? ((numTime - 12) || 12).toString().padStart(2, '0') : (numTime || 12).toString().padStart(2, '0')) + `:${(numMins)} ${(numTime > 11) ? 'PM' : 'AM'}`,
      null, (numMins == '00') ? numTime : numTime + .5
    );
  }

  /**
   * Copy all the time from Monday to the rest of the
   * days of the week.
   *
   * @returns {void}
   */
  copyAll(): void {
    let objNewFields = {};
    Object.keys(this.form.value).forEach(test => {
      objNewFields[test] = {...this.form.value[this.field.key]};
    });
    this.form.setValue(objNewFields);
  }

  /**
   * Disabled the dropdown values
   * to make sure we dont have end times
   * that are lesser than the start times.
   *
   * @param {LooseObject} objEvent
   *
   * @returns {void}
   */
  disableFields(objEvent: LooseObject): void {
    if (objEvent) {
      this.timeSelection['to'] = this.timeSelection['to'].map(response => {
        if (objEvent['extras'] >= response['extras']) {
          response['disabled'] = true;
        } else {
          response['disabled'] = false;
        }
        return response;
      });
    }
  }

}

export class AvailabilityTime {

  start_time: string;
  end_time: string;

  get has_time() {
    return this.start_time != null && this.end_time != null;
  }

  get hour_start() {
    return this.start_time != null ? parseInt(this.start_time.split(':')[0]) : 8;
  }

  get hour_end() {
    return this.end_time != null ? parseInt(this.end_time.split(':')[0]) : 8;
  }

  get display_start() {
    if (this.start_time) {
      return this.getDisplay(this.start_time.split(':'));
    }
  }

  get display_end() {
    if (this.end_time) {
      return this.getDisplay(this.end_time.split(':'));
    }
  }

  get duration() {
    if (this.start_time && this.end_time) {
      let start: string[] = this.start_time.split(':');
      let end: string[] = this.end_time.split(':');
      return parseInt(end[0]) - parseInt(start[0]);
    } else {
      return 0;
    }
  }

  constructor(properties: LooseObject) {
    this.start_time = properties.start_time || null;
    this.end_time = properties.end_time || null;
  }

  /**
   * Get display of the time.
   *
   * @param {string[]} strTime
   *
   * @returns {string}
   */
  getDisplay(strTime: string[]): string {
    let num = parseInt(strTime[0]);
    let mins = strTime[1];
    return ((num > 11) ? ((num - 12) || 12).toString().padStart(2, '0') :
      (num || 12).toString().padStart(2, '0')) + `:${(mins)} ${(num > 11) ?
        'PM' : 'AM'}`
  }

}

export class AvailabilityDay {

  monday: AvailabilityTime;
  tuesday: AvailabilityTime;
  wednesday: AvailabilityTime;
  thursday: AvailabilityTime;
  friday: AvailabilityTime;
  saturday: AvailabilityTime;
  sunday: AvailabilityTime;

  constructor(props?: AvailabilityDay) {
    if (props) {
      this.monday = new AvailabilityTime(props['monday']);
      this.tuesday = new AvailabilityTime(props['tuesday']);
      this.wednesday = new AvailabilityTime(props['wednesday']);
      this.thursday = new AvailabilityTime(props['thursday']);
      this.friday = new AvailabilityTime(props['friday']);
      this.saturday = new AvailabilityTime(props['saturday']);
      this.sunday = new AvailabilityTime(props['sunday']);
    } else {
      this.monday = new AvailabilityTime({});
      this.tuesday = new AvailabilityTime({});
      this.wednesday = new AvailabilityTime({});
      this.thursday = new AvailabilityTime({});
      this.friday = new AvailabilityTime({});
      this.saturday = new AvailabilityTime({});
      this.sunday = new AvailabilityTime({});
    }
  }
}
