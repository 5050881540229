import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivityType } from '../../../../../lists/activity';
import { RelateIds } from '../../../../../lists/relate-ids';
import { NotificationService } from '../../../../../services/notification.service';
import { ActivitiesService } from '../../../../../services/activities.service';
import { RecordService } from '../../../../../services/record.service';
import { data_get } from '../../../../utils/common';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit {

  public strModule: string;
  public bSubmitted: boolean = false;
  public strRelatedId: any = RelateIds;
  public notesForm: FormGroup;
  public bShowLoader: boolean = false;
  public bPageLoaded: boolean = false;
  public strViewType: string = this.data.view_type;
  public objEditDetails = {};
  public objInitialRawData = {};
  public arRecordDetails = {};

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  constructor( public dialogRef: MatDialogRef<NoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activitiesService: ActivitiesService,
    private notifService: NotificationService,
    private recordService: RecordService
  ) {
    this.arRecordDetails = data;
    this.strModule = data.module;
    if (this.strViewType == 'edit') {
      this.recordService.getRecordRelateJoined('activities', false, { 'activities.id': this.data['activity_id'] }).subscribe( response => {
        // Get updated record data of currently updating.
        this.objEditDetails = (response != null && response.length > 0) ? response[0] : [];
        this.initForm();
        this.notesForm.patchValue({
          'note': this.objEditDetails['notes'],
          'include_in_report': data_get(this.objEditDetails, 'include_in_report', {
            default_value: false,
          }),
          'share_in_mobile': data_get(this.objEditDetails, 'share_in_mobile', {
            default_value: false,
          }),
          'is_sticky_note': data_get(this.objEditDetails, 'is_sticky_note', {
            default_value: false,
          }),
        });
        this.objInitialRawData = {...this.notesForm.getRawValue()};
        this.bPageLoaded = true;
      });
    } else {
      this.initForm();
      this.bPageLoaded = true;
    }

    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog()
    });
  }

  ngOnInit() {}
  /**
   * Initialize Form
   */
  initForm() {
    // Create form group
    this.notesForm = new FormGroup({
      note:  new FormControl('', Validators.required),
      include_in_report: new FormControl(false),
      share_in_mobile: new FormControl(false),
      is_sticky_note: new FormControl(false),
    });
  }

  /**
   * For Cancel button
   */
  cancelDialog(): void {
    if (this.notesForm.dirty) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close('cancel');
        });
    } else {
      this.dialogRef.close('cancel');
    }
  }

  /*
   * Create note in activities table
   */
  onSubmit() {

    // Set submitted true, to trigger all required field
    this.bSubmitted = true;
    // Do we have valid form?
    if (this.notesForm.valid) {

      if (this.strViewType == 'edit') {

        let arProperKeys = {
          'note': 'notes',
          'include_in_report': 'include_in_report',
          'share_in_mobile': 'share_in_mobile',
          'is_sticky_note': 'is_sticky_note',
        }

        let objDiffTemp = {
          id: this.arRecordDetails['activity_id'],
          record_id: this.arRecordDetails['record_id'],
          module: this.arRecordDetails['module']
        };

        Object.keys(this.objInitialRawData).forEach(key => {
          if (this.objInitialRawData[key] != this.notesForm.getRawValue()[key]) {
            let strKey = arProperKeys[key] != undefined ? arProperKeys[key] : key;
            objDiffTemp[strKey] = this.notesForm.getRawValue()[key];
          }
        });

        if (Object.keys(objDiffTemp).length > 3) {
          this.dialogRef.close({'mode': 'edit', 'data': objDiffTemp});
        } else {
          this.notifService.sendNotification('oops', 'no_changes_made', 'warning');
        }

      } else {
        // Set subject to the first 20 characters of the note
        var strSubject = (this.notesForm.controls['note'].value.length > 20) ?
        this.notesForm.controls['note'].value.substring(0, 20) : this.notesForm.controls['note'].value;

        // Prepare request body
        var arRequestData = {
          module_id : this.data.record_id,
          module_field : this.strRelatedId[this.data.module],
          subject : strSubject,
          note : this.notesForm.controls['note'].value,
          module : this.data.module,
          type : ActivityType['note'],
          include_in_report: this.notesForm.controls['include_in_report'].value,
          share_in_mobile: this.notesForm.controls['share_in_mobile'].value,
          is_sticky_note: this.notesForm.controls['is_sticky_note'].value,
        }

        this.bSubmitted = false;
        this.dialogRef.close({'mode': 'create', 'data':  arRequestData});
      }
    }
  }

}
