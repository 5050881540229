import { isNull, isUndefined } from 'lodash-es';

export class PaginatedResult<T extends Object, P = string> {
  get items(): T[] {
    return this._props.items;
  }

  get pagination(): Pagination<P> {
    return this._props.pagination;
  }

  constructor(
    private readonly _props: PaginatedResultProps<T, P>,
  ) { }
}

type PaginatedResultProps<T extends Object, P extends Object> = {
  items: T[];
  pagination: Pagination<P>;
}

export class Pagination<T> {
  get nextPage(): T | null {
    return this._props.next_page;
  }

  get hasNextPage(): boolean {
    return !(isUndefined(this.nextPage) || isNull(this.nextPage));
  }

  constructor(
    private readonly _props: PaginationProps<T>,
  ) { }
}

type PaginationProps<T extends Object> = {
  next_page?: T | null;
  total?: number;
}
