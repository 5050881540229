
    <!-- Non-Standalone -->
    <div
      *ngIf="! standalone"
      class="form-group"
    >
      <div class="d-flex d-flex-gap">
        <fieldmagic-text
          *ngIf="displayLabel || (label | filled)"
          purpose="input"
          [content]="label"
          [withRequiredMarker]="withRequiredMarker"
        >
        </fieldmagic-text>
      </div>
      <ng-container *ngTemplateOutlet="input"></ng-container>
    </div>

    <!-- Standalone -->
    <ng-container
      *ngIf="standalone"
      [ngTemplateOutlet]="input"
    >
    </ng-container>

    <ng-template #input>
      <div class="w-100 d-flex flex-column d-flex-gap">
        <ng-container
          *ngIf="wyswyg; else textarea;"
          [ngTemplateOutlet]="quill"
        >
        </ng-container>
        <div
          class="d-flex w-100"
          [class.justify-content-between]="errors | filled"
          [class.justify-content-end]="errors | blank"
        >
          <fieldmagic-input-errors
            *ngIf="errors | filled"
            [errors]="errors"
          >
          </fieldmagic-input-errors>

          <small *ngIf="max != undefined" class="text-help">{{ remaining$ | async }}/{{max}}</small>
        </div>
      </div>
    </ng-template>

    <ng-template #textarea>
      <textarea
        class="form-control font-size-11 fieldmagic-textarea-input"
        [class.input-height-40]="expandable"
        [disabled]="isDisabled$ | async"
        (change)="onChange($event.target.value)"
        (keyup)="onKeyUp($event.target.value)"
        (blur)="onTouched()"
        [attr.maxLength]="max"
        [attr.rows]="rows"
        [value]="value"
        (focus)="onFocusIn($event.target)"
        (focusout)="onFocusOut($event.target)"
        [class.fieldmagic-input-has-error]="errors | filled"
      ></textarea>
    </ng-template>

    <ng-template #quill>
      <quill-editor
        #quill
        [ngModel]="value"
        [disabled]="isDisabled$ | async"
        [class.fieldmagic-input-has-error]="errors | filled"
        (onContentChanged)="onChange($event.html)"
        (blur)="onTouched()"
        (onFocus)="onFocusIn(quill.elementRef.nativeElement)"
        (focusout)="onFocusOut(quill.elementRef.nativeElement)"
        class="fieldmagic-wyswig-input"
        [class.hidden-toolbar]="isDisabled$ | async"
      >
        <div quill-editor-toolbar>
          <span class="ql-formats">
            <select class="ql-font">
              <option selected></option>
              <option value="serif"></option>
              <option value="monospace"></option>
            </select>
            <select class="ql-size">
              <option value="small"></option>
              <option selected></option>
              <option value="large"></option>
              <option value="huge"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>
          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <select class="ql-align">
              <option selected></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-link"></button>
          </span>

          <span
            *ngFor="let wyswygFormatTmp of wyswygFormatTmps"
            class="ql-formats"
          >
            <ng-container *ngTemplateOutlet="wyswygFormatTmp"></ng-container>
          </span>
        </div>
      </quill-editor>
    </ng-template>
  