import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagListComponent } from './tag-list.component';
import { MatChipsModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material';

@NgModule({
  declarations: [
    TagListComponent
  ],
  exports: [
    TagListComponent
  ],
  entryComponents: [
    TagListComponent
  ],
  imports: [
    CommonModule,
    MatChipsModule,
    MatTooltipModule,
  ]
})
export class TagListModule { }
