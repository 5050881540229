
    <fieldmagic-dialog>
      <div class="dialog-header-content">
        <fieldmagic-dialog-title
          icon="history"
          label="Change History"
        >
        </fieldmagic-dialog-title>
      </div>

      <div class="dialog-header-buttons">
        <fieldmagic-dialog-close-button
          (click)="onDismiss()"
        ></fieldmagic-dialog-close-button>
      </div>

      <div class="dialog-content">
        <fieldmagic-pagination-links>
          <ng-template fieldmagicPaginationLinksTopContent>
            <div class="d-flex w-100 d-flex-gap align-items-center">
              <fieldmagic-text
                content="{{ 'Range' | translate }}"
              >
              </fieldmagic-text>

              <input-date
                class="w-100"
                [objForm]="form"
                [objItem]="inputDateField"
                [strMode]="add"
                strModule="audit"
                (sendToParent)="onFilter()"
              >
              </input-date>
            </div>
          </ng-template>

          <fieldmagic-table
            [headers]="headers$ | async"
            [loading]="fetching$ | async"
            [data]="items$ | async"
          >
            <ng-template
              fieldmagicTableRow
              let-item="item"
            >
              <tr>
                <td
                  fieldmagicTableRowColumn
                >
                  <fieldmagic-text
                    alignment="center"
                    *ngIf="item.modified_by | filled"
                    content="{{ 'Update' | translate }}"
                  >
                  </fieldmagic-text>
                  <fieldmagic-text
                    alignment="center"
                    *ngIf="(item.created_by | filled) && (item.modified_by | blank)"
                    content="{{ 'Create' | translate }}"
                  >
                  </fieldmagic-text>
                </td>
                <td
                  fieldmagicTableRowColumn
                >
                  <fieldmagic-text
                    alignment="center"
                    content="{{ item.logged_at | format: 'lll' }}"
                  >
                  </fieldmagic-text>
                </td>
                <td
                  fieldmagicTableRowColumn
                >
                  <fieldmagic-text
                    *ngIf="item.author_name | filled; else unknownUser"
                    alignment="center"
                    content="{{ item.author_name }}"
                  >
                  </fieldmagic-text>
                  <ng-template #unknownUser>
                   <fieldmagic-text
                      alignment="center"
                      content="{{ 'Unknown Author' | translate }}"
                      matTooltip="{{ 'Author might have been removed from the system' | translate }}"
                    >
                    </fieldmagic-text>
                  </ng-template>
                </td>
                <td
                  fieldmagicTableRowColumn
                >
                  <fieldmagic-icon
                    icon="eye"
                    position="center"
                    purpose="clicking"
                    matTooltip="View"
                    (click)="onPreview(item)"
                  >
                  </fieldmagic-icon>
                </td>
              </tr>
            </ng-template>
          </fieldmagic-table>
        </fieldmagic-pagination-links>
      </div>
    </fieldmagic-dialog>
  