import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LooseObject } from '../objects/loose-object';

const kBaseUrl: string = `${environment.url}/items`;

@Injectable({
  providedIn: 'root'
})

export class ItemsService {

  constructor (private http: HttpClient) {}

  upsertProductAndSupplierPricing(strCustomerId: string, arLineItems: LooseObject[]): Observable<any> {
    let body = new URLSearchParams();
    body.append('customer_id', strCustomerId);
    body.append('line_items', JSON.stringify(arLineItems));

    return this.http.post<Response>(`${kBaseUrl}/upsert_unit_cost_supplier_pricing`, body);
  }
}

