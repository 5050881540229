import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TagDisplay, TagRecordDisplay } from '../objects/tag-display';
import { clone } from 'lodash-es';
import { LooseObject } from '../../../objects/loose-object';

@Component({
  selector: 'fm-dynamic-chip',
  template: `<mat-chip
                #fmTagPopover="ngbPopover"
                [ngbPopover]="popContent"
                [autoClose]="'outside'"
                container="body"
                [style.background]="tag.color"
                [style.color]="tag.textColor()"
                [removable]="removable"
                (removed)="remove(tag)">
                <input *ngIf="selectable" type="checkbox" class="mr-2" [(ngModel)]="tag.include" (click)="$event.stopPropagation()">
                {{ tag.name }}
                <fa-icon *ngIf="removable"
                  [style.margin-top]="'3px'"
                  [style.color]="tag.textColor()"
                  [icon]="['fas', 'times-circle']"
                  matChipRemove
                  (click)="$event.stopPropagation()">
                </fa-icon>
             </mat-chip>
             <ng-template #popContent>
              <input [(ngModel)]="objTag.name" class="form-control mb-1" [ngClass]="{'is-invalid': bMarkAsRequired}"/>
              <small *ngIf="bMarkAsRequired" class="text-danger">{{ 'required' | translate }}</small>
              <span
                cpAlphaChannel="disabled"
                [style.background]="tag.color"
                [cpToggle]="true"
                [cpDialogDisplay]="'inline'"
                [(colorPicker)]="objTag.color">
              </span>
              <button class="btn btn-primary btn-block mt-2" (click)="select(fmTagPopover)">{{ 'save' | translate }}</button>
             </ng-template>
             `
})
export class FmDynamicChip implements OnInit {

  /**
   * The tag data to display.
   *
   * @var {TagRecordDisplay}
   */
  @Input('tag') tag: TagRecordDisplay;

  /**
   * If the tag is removable, hides the x icon
   * if false.
   *
   * @var {boolean}
   */
  @Input('removable') removable: boolean;

  /**
   * If the tag is selectable, shows the checkbox.
   *
   * @var {boolean}
   */
  @Input('selectable') selectable: boolean;

  /**
   * Informs parent component whenever a tag is removed.
   *
   * @var {EventEmitter<TagRecordDisplay>}
   */
  @Output() removeEvent = new EventEmitter<TagRecordDisplay>();

  /**
   * Informs parent component whenever a tag is removed.
   *
   * @var {EventEmitter<TagRecordDisplay>}
   */
  @Output() saveEvent = new EventEmitter<TagRecordDisplay>();

  /**
   * Indicator to show the field is requited.
   *
   * @var {boolean}
   */
  bMarkAsRequired: boolean = false;

  /**
   * The tag data in the popup.
   *
   * @var {TagRecordDisplay}
   */
  objTag: TagRecordDisplay;

  ngOnInit(): void {
    this.objTag = clone(this.tag);
  }

  /**
   * Method that triggers the removeEvent
   *
   * @param {TagRecordDisplay} tag
   */
  remove(tag: TagRecordDisplay) {
    this.removeEvent.next(tag);
  }

  /**
   * Method that triggers the saveEvent
   *
   * @param {LooseObject} objEvent
   */
  select(objEvent: LooseObject) {
    if (this.tag.name.length > 0) {
      this.bMarkAsRequired = false;
      this.saveEvent.next(this.objTag);
      objEvent.close();
    } else {
      this.bMarkAsRequired = true;
    }
  }

}
