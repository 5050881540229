import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AccountService } from '../../../services/account.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ClientStoreService } from '../../../services/client-store.service';
import { NotificationService } from '../../../services/notification.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ResenddialogComponent } from '../../resenddialog/resenddialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LooseObject } from '../../../objects/loose-object';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-invites',
  templateUrl: './invites.component.html',
  styleUrls: ['./invites.component.scss', '../../../app.component.scss']
})

export class InvitesComponent implements OnInit {

  public userInvitationForm: FormGroup;
  public arAvailableLocale = [ 'en', 'es', 'fil' ];
  private strCurrentUserLocale = this.localStorageService.getItem('user_locale');
  public objUserInvitationData = {
    'admin' : {
      'label' : 'administrators',
      'email_addresses' : [],
      'locale' : this.strCurrentUserLocale
    },
    'desktop' : {
      'label' : 'office_users',
      'email_addresses' : [],
      'locale' : this.strCurrentUserLocale
    },
    'mobile-premium': {
      'label' : 'mobile_users',
      'email_addresses' : [],
      'locale' : this.strCurrentUserLocale
    }
  }
  public bIsInvitationSent: boolean = false;
  public arInvitations : object = null;
  private static readonly CLIENT_HAS_PENDING_INVITATION = 'client_has_pending_invitations';

  constructor(
    public ClientStoreService: ClientStoreService,
    protected localStorageService: LocalStorageService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private notifService: NotificationService
  ) { }

  ngOnInit() {

    // Gets the list of pending invitations sent by the user admin
    this.accountService.checkInvivationReminder().subscribe(response => {
        if (! isEmpty(response['pending_invitations_list'])) {
          this.arInvitations = response['pending_invitations_list'];
        }
    });
  }

  /**
   * Sends user invitations to different user types
   *
   * @returns void
   */
  sendUserInvitations(): void {
    this.bIsInvitationSent = true;
    if (this.isValidUserInvitationRequest()) {

      this.accountService.sendUserInvitations(JSON.stringify(this.objUserInvitationData))
      .pipe(catchError(errorResponse => {
          if (errorResponse.status === 422) {
            this.notifService.sendNotification(errorResponse.error.title, errorResponse.error.details, 'warning');
          }
          this.bIsInvitationSent = false;
          return throwError(errorResponse);
        })
      )
      .subscribe(
        data => {
          this.notifService.notifySuccess('successful_email_invite');
          this.objUserInvitationData['admin']['email_addresses'] = [];
          this.objUserInvitationData['desktop']['email_addresses'] = [];
          this.objUserInvitationData['mobile-premium']['email_addresses'] = [];
          this.bIsInvitationSent = false;
        }
      );
    } else {
      this.bIsInvitationSent = false;
    }

  }

  /**
   * Adds email address to the designated user type array
   *
   * @param emailAddress - list of email addresses in ng select
   * @param invitationType - user type (Eg. admin, mobile-user, premium)
   */
  addEmailChip(emailAddress, invitationType): void {
    let arLowerCaseEmailAddressList = this.getLowerCaseEmailAddressList(emailAddress);
    this.objUserInvitationData[invitationType]['email_addresses'] = arLowerCaseEmailAddressList;
  }

  /**
   * Removes email address to the designated user type array
   *
   * @param string invitationType - user type (Eg. admin, mobile-user, premium)
   * @param int indexToRemove - the index of the email address to be removed
   */
  removeEmailChip(invitationType, indexToRemove): void {
    this.objUserInvitationData[invitationType]['email_addresses'].splice(indexToRemove, 1);
  }

  /**
   * Evaluates the user pasted string and splits into array using comma (,) as delimeter
   *
   * @param event
   * @param string invitationType - user type (Eg. admin, mobile-user, premium)
   */
  onPaste(event, invitationType): void {
    event.stopPropagation();
    event.preventDefault();

    let items = this.getLowerCaseEmailAddressList(event.clipboardData.getData('Text').split(','));
    this.objUserInvitationData[invitationType]['email_addresses'] = this.objUserInvitationData[invitationType]['email_addresses'].concat(items);
  }

  /*
  * Checks if the request for user invitation is valid
  *
  * @return boolean - returns true if there's no duplicate email and if there's even one email address in the request
  */
 isValidUserInvitationRequest(): boolean {

    let isValidRequest = true;
    let arMergedEmails = [];

    Object.keys(this.objUserInvitationData).forEach( strKey => {
      arMergedEmails = [...arMergedEmails, ...this.objUserInvitationData[strKey]['email_addresses']];
    });

    if (arMergedEmails.length === 0) {

      this.notifService.sendNotification('send_invites_error', 'send_invites_error_message', 'warning');
      isValidRequest = false;

    } else if (new Set(arMergedEmails).size !== arMergedEmails.length) {

      this.notifService.sendNotification('header_warning', 'invites_repeated_email', 'warning');
      isValidRequest = false;

    }

    return isValidRequest;
  }

  // Built-in fuction of ng-select. Used when adding a custom option in it's dropdown selection
  addTag(email) {
    return email;
  }

  /**
   * Converts all email addresses in the list to lowercase and removes all spaces
   *
   * @param emailAddressList
   */
  getLowerCaseEmailAddressList(emailAddressList) {
    return emailAddressList.map(email_address => {
      return email_address.toLowerCase().replace(/\s/g,'');
    });
  }


  /**
   * Displays dialog which contains pending user invitations
   *
   * @returns void
   */
  showResendInvitationDialog(): void {

    this.dialog.open(ResenddialogComponent, {
      width: '760px',
      height: 'auto',
      data: this.arInvitations
    });

  }

  /**
   * Triggers when user loses focus on an email input field
   *
   * @param event
   * @param invitationType
   * @returns {void}
   */
  onBlur(event: LooseObject, invitationType: string): void {
    if (! isEmpty(event.target.value)) {
      let items = this.getLowerCaseEmailAddressList(event.target.value.split(','));

      this.objUserInvitationData[invitationType]['email_addresses'] = this.objUserInvitationData[invitationType]['email_addresses'].concat(items);
      event.target.value = null;
    }
  }

}
