<header>
  <div class="d-flex wizard-header">
    <h3 class="dialog-title">
      <fa-icon [icon]="['fas', 'wrench']" transform="shrink-3" class="dialog-title"></fa-icon>
      <span>&nbsp;{{ 'create_job' | translate }}</span>
    </h3>
    <ng-container *fcHasPermission="'jobs:create'">
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <fieldmagic-primary-button
              variant="error"
              (click)="wizardService.onDialogClose.next(true)"
              icon="times"
              label="close"
              id="wizardCustomerCancelDialog"
            >
            ></fieldmagic-primary-button>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</header>

<hr>

<ng-container *fcHasPermission="'jobs:create'; else createJobsDisabled">
  <create-wizard
    [stepperSettings]="wizardStepper"
    [dialogRef]="dialogRef">

    <ng-template #step1 let-stepper="stepper">

      <customer-step
        [stepper]="stepper"
        [bOpenedFromMegaMenu]="dialogData.opened_from_mega_menu"
        [strRecordType]="dialogData.record_type"
        [preselectedCustomer]="dialogData.customer_id"
        [preselectedSite]="dialogData.site_id"
        (changeStepToLocation)="changeCustomerStepName($event)"
        (customLocationEmitter)="bIsCustomLocation = $event"
        [skipWhenPreselected]="true"
        (skipStepEmitter)="skipCustomerStep($event, stepper)"
        (objCustomerData)="captureCustomerData($event, stepper)">
      </customer-step>

    </ng-template>

    <ng-template #step2 let-stepper="stepper">

      <contact-step
        [stepper]="stepper"
        [customerId]="strCustomerId"
        [siteId]="strSiteId"
        [bOpenedFromMegaMenu]="dialogData.opened_from_mega_menu"
        [preselectedContact]="dialogData.contact_id"
        [bCustomerStepFinished]="bCustomerStepFinished"
        (skipStepEmitter)="skipContactStep($event, stepper)"
        (objContactData)="captureContactData($event, stepper)">
      </contact-step>

    </ng-template>

    <ng-template #step3 let-stepper="stepper">

      <job-step
        [stepper]="stepper"
        [bHasNoCustomer]="bHasNoCustomer"
        [bIsCustomLocation]="bIsCustomLocation"
        [opportunityId]="dialogData.opportunity_id"
        [objAssetData]="dialogData.asset_data"
        (objJobData)="captureJobData($event, stepper)">
      </job-step>

    </ng-template>

    <ng-template #step4 let-stepper="stepper">

      <summary-step [stepper]="stepper" [bHasNoCustomer]="bHasNoCustomer" [objAssetData]="dialogData.asset_data" [strRecordType]="'jobs'" [summary]="objWizard">

        <ng-template #next>

          <div class="row">
            <div class="col-4">
              <button id="wizardScheduleCreate" type="button" class="btn btn-success btn-block btn-lg p-2" [disabled]="bLoading" (click)="strRedirect = 'create' ;save()">
                <span *ngIf="bLoading && strRedirect == 'create'; else createDefault" ><inline-view-loader [isLoading]="true" [hasText]="false"></inline-view-loader> {{ 'creating' | translate }}...</span>
                <ng-template #createDefault><strong>{{ 'create' | translate }}</strong></ng-template>
              </button>
            </div>
            <div class="col-4">
              <button id="wizardSchedule" type="button" class="btn btn-primary btn-block btn-lg p-2" [disabled]="bLoading" (click)="strRedirect = 'schedule'; save()">
                <span *ngIf="bLoading && strRedirect == 'schedule'; else createSchedule" ><inline-view-loader [isLoading]="true" [hasText]="false"></inline-view-loader> {{ 'scheduling' | translate }}...</span>
                <ng-template #createSchedule><strong>{{ 'schedule' | translate }}</strong></ng-template>
              </button>
            </div>
            <div class="col-4">
              <button id="wizardScheduleCreateAndView" type="button" class="btn btn-warning btn-block btn-lg p-2" [disabled]="bLoading" (click)="strRedirect = 'work_order'; save()">
                <span *ngIf="bLoading && strRedirect == 'work_order'; else createWorkOrder" ><inline-view-loader [isLoading]="true" [hasText]="false"></inline-view-loader> {{ 'creating' | translate }}...</span>
                <ng-template #createWorkOrder><strong>{{ 'create_and_view_work_order' | translate }}</strong></ng-template>
              </button>
            </div>
          </div>

        </ng-template>
      </summary-step>


    </ng-template>

  </create-wizard>
</ng-container>

<ng-template #createJobsDisabled>
  <span id="btnCreateJobsDisabled" class="disabled"
    matTooltip="{{ 'forbidden_action_error' | translate }}">
    {{ 'forbidden_action_error' | translate }}
  </span>
</ng-template>