import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { entries, keys, reduce, split, trim } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
import { AvailablePeriodOption, ChecklistPrompt, ChecklistPromptGroup, ChecklistType, PromptFieldEntry, PromptType } from '../../../../module/checklists/shared/types';
import { BehaviorSubject } from 'rxjs';
import { FieldmagicDropdownOption, FieldmagicDropdownOptionFilter } from '../../../../shared/components/forms/input/dropdown/dropdown.component';
import { blank, data_get, fallback, filled, spf } from '../../../../shared/utils/common';
import { FieldmagicMultiTextInputOnChangedEvent } from '../../../../shared/components/forms/input/multitext/multitext-input.component';
import { AssetType, AssetTypeAttribute } from '../../../asset-types/shared/types';
import { format_module_field_entries, identify_module_group_name } from '../../../../module/checklists/shared/utils';
import { Field } from '../../../../services/form.service';

const AVAILABLE_CHECKLIST_PROMPT_TYPES = [
  'pass/fail',
  'field_entry',
  'signature',
  'instructions',
  'dropdown',
  'text',
  'number',
  'date',
  'textarea',
  'images',
];

@Component({
  selector: 'tr[app-checklist-prompt]',
  templateUrl: './checklist-prompt.component.html',
  styleUrls: [
    './checklist-prompt.component.scss',
  ],
})
export class ChecklistPromptComponent implements OnChanges {
  @Input() forType: ChecklistType = 'unified';

  @Input() index: number;

  @Input() parentIndex: number = 0;

  @Input() prompt: ChecklistPrompt;

  @Input()
  set fieldEntries(value: Record<string, PromptFieldEntry[]>) {
    this.fieldEntries$.next(entries(value).reduce((options, [_, fields]) => ([
      ... options,
      ... fields.map<FieldmagicDropdownOption<PromptFieldEntry>>((field) => ({
        ... field,
        text: field.name,
        groupName: field.group_name,
      })),
    ]), []));
  }

  @Input() parent?: ChecklistPromptGroup;

  @Input() assetType?: AssetType;

  @Input() errors?: Record<string, string[]>;

  @Input() availableOnPeriod?: AvailablePeriodOption;

  // deprecated prop for asset checklist
  @Input() scheduleTypes: AvailablePeriodOption[] = [];

  @Output('removed') $onDelete = new EventEmitter<number>();

  @Output('edited') $onEdit = new EventEmitter<ChecklistPrompt>();

  get hasParent() {
    return filled(this.parent);
  }

  get idSuffix() {
    return spf('%s%s', {
      args: [this.parentIndex, this.index],
    });
  }

  readonly types$ = new BehaviorSubject(AVAILABLE_CHECKLIST_PROMPT_TYPES);

  readonly fieldEntries$ = new BehaviorSubject<FieldmagicDropdownOption<PromptFieldEntry>[]>([]);

  constructor(
    private _translations: TranslateService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (filled(data_get(changes, 'assetType'))) {
      this._rebuildFieldEntriesOptions();
      this._updateScheduleType();
    }

    if (filled(data_get(changes, 'availableOnPeriod'))) {
      this._updateScheduleType();
    }
  }

  emitAsEdited = (): void => this._triggerEditEvent();

  onScheduleTypeChange(value: boolean, prompt: ChecklistPrompt, period: string): void {
    if (value && period == 'always') {
      prompt.schedule_type = reduce(keys(prompt.schedule_type), (acc, current) => ({
        ... acc,
        [current]: true,
      }), {});
    }

    this.emitAsEdited();
  }

  onPromptLabelChange = (): void => this._triggerEditEvent();

  onPassOptionsChanged = (event: FieldmagicMultiTextInputOnChangedEvent): void => this._updateOptions(event, 'pass');

  onFailOptionsChanged = (event: FieldmagicMultiTextInputOnChangedEvent): void => this._updateOptions(event, 'fail');

  onDropdownOptionChanged = (event: FieldmagicMultiTextInputOnChangedEvent): void => this._updateOptions(event, 'dropdown');

  onPromptTypeChange(selected: PromptType): void {
    this.prompt.value = {};

    /// resets the prompt value
    if (selected == 'pass/fail') {
      this.prompt.value = {
        fail: [this._translations.instant('fail')],
        pass: [this._translations.instant('pass')],
      };
    } else if (selected == 'dropdown' || selected == 'image') {
      this.prompt.value[selected] = [];
    } else {
      this.prompt.value[selected] = '';
    }

    /// sets required flag
    if (selected == 'instructions') {
      this.prompt.is_required = false;
    } else {
      this.prompt.is_required = true;
    }

    this._triggerEditEvent();
  }

  onFieldEntryChange(selected: FieldmagicDropdownOption<PromptFieldEntry>, forInput: 'instructions' | 'field_entry'): void {
    const fieldId = data_get((selected as PromptFieldEntry), 'id');

    if (forInput == 'instructions') {
      this.prompt.value['instructions'] = fieldId;
      this._onAddInstructionFieldEntry();
    } else {
      this.prompt.value['field_entry'] = fieldId;
      this._triggerEditEvent();
    }
  }

  onDelete = (): void => this.$onDelete.emit(this.index);

  computeFieldEntrySelectedDropdownValue(forInput: 'instructions' | 'field_entry'): FieldmagicDropdownOption<PromptFieldEntry> {
    const id = data_get(this.prompt, ['value', forInput]);
    const parts: string[] = split(id, '-');

    return {
      id,
      name: parts[1],
      text: parts[1],
      groupName: identify_module_group_name(parts[0]),
      group_name: identify_module_group_name(parts[0]),
    };
  }

  fieldEntryFilterFactory = (): FieldmagicDropdownOptionFilter<FieldmagicDropdownOption<PromptFieldEntry>> => (
    (option) => {
      const groupName = (option as PromptFieldEntry).group_name;

      if (groupName == 'ungrouped') {
        return true;
      }

      if (this.forType == 'asset'
        && (groupName == 'asset_fields' || groupName == 'custom_asset_fields')
      ) {
        return true;
      }

      if ((this.forType == 'job' || this.forType == 'opportunity')  && groupName == 'site_fields') {
        return true;
      }

      if (this.forType == 'job' && groupName == 'job_fields') {
        return true;
      }

      if (this.forType == 'opportunity' && groupName == 'opportunity_fields') {
        return true;
      }

      if (this.forType == 'unified'
        && (groupName == 'custom_asset_fields' || groupName == 'asset_fields')
        && filled(this.assetType)
      ) {
        return true;
      }

      if (this.forType == 'unified'
        && groupName != 'custom_asset_fields'
        && groupName != 'asset_fields'
        && blank(this.assetType)
      ) {
        return true;
      }

      return false;
    }
  ).bind(this);

  private _onAddInstructionFieldEntry(): void {
    const selected = data_get(this.prompt, 'value.instructions');

    if (blank(selected)) {
      return;
    }

    const current: string = fallback(data_get(this.prompt, 'value.instructions_text'), {
      fallback: () => '',
    });

    this.prompt.value['instructions_text'] = trim(spf('%s {$%s}', {
      args: [current, selected],
    }));

    this._triggerEditEvent();
  }

  private _updateOptions(event: FieldmagicMultiTextInputOnChangedEvent, type: string): void {
    this.prompt.value[type] = event;
    this._triggerEditEvent();
  }

  private _triggerEditEvent = (): void => this.$onEdit.emit(this.prompt);

  private _rebuildFieldEntriesOptions(): void {
    const assetTypeAttributes = fallback(data_get(this.assetType, 'attributes'), {
      fallback: () => [],
    });

    /// filter field entries that are not asset type attributes
    /// as they being rebuild depending on the selected asset type
    const fieldEntries = this.fieldEntries$.getValue()
      .filter((fieldEntry) => (fieldEntry as PromptFieldEntry).group_name != 'custom_asset_fields');

    this.fieldEntries$.next([
      ... fieldEntries,
      ... format_module_field_entries(
        reduce<AssetTypeAttribute, Record<string, Field>>(assetTypeAttributes, (acc, attribute) => ({
          ... acc,
          ... {
            [attribute.key]: {
              key: attribute.key,
              type: attribute.type,
              label: attribute.label,
              default_value: attribute.default_value,
            }
          }
          }), {}
        ),
        'custom_asset_types',
      ).map((field) => ({
        ... field,
        text: field.name,
        groupName: field.group_name,
      })),
    ])
  }

  private _updateScheduleType(): void {
    if (this.forType != 'unified') {
      return;
    }

    const selectedPeriod = this.availableOnPeriod;

    if (blank(selectedPeriod) && blank(this.assetType)) {
      this.prompt.schedule_type = {
        'always': true,
      };
    } else if (
      (blank(selectedPeriod) && filled(this.assetType))
        || (blank(this.assetType) && filled(this.parent) && blank(selectedPeriod))
    ) {
      this.prompt.schedule_type = {};
    } else if (filled(selectedPeriod)) {
      this.prompt.schedule_type = {
        [selectedPeriod]: true,
      };
    }
  }
}
