import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { tap, delay } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../../environments/environment';
import { Config } from 'protractor';
import { Select } from '../../objects/select';
import { LooseObject } from '../../objects/loose-object';
import { NotificationService } from '../notification.service';
import { ErrorEventType, ErrorPublishingService } from '../error-publishing.service';
import { filled } from '../../shared/utils/common';

const kBaseUrl: string = environment.url + "/data-sharing/";

@Injectable()
export class DataSharingService {

  constructor (
    private http: HttpClient,
    protected notifications: NotificationService,
    protected errors: ErrorPublishingService
  ) {}

  /**
   * API for fetching master list
   * @param token
   * @returns
   */
  getMasterList(token: string = null): Observable<ResponseData[]> {
    let params = new URLSearchParams({
      ... (filled(token) && { token }),
    });

    return this.http.post<ResponseData[]>(kBaseUrl + 'admin/clients/master', params.toString()).pipe(
      tap({
        error: (objErr: HttpErrorResponse) => {
          if (objErr.status === 403) {
            this.errors.publish({
              type: ErrorEventType.HTTP_ERROR,
              status: 403,
              for: '',
            });
          }
        }
      })
    );
  }

  /**
   * API for fetching child list
   * @param token
   * @returns
   */
  getChildList(token: string = null): Observable<ResponseData[]> {
    let params = new URLSearchParams({
      ... (filled(token) && { token }),
    });

    return this.http.post<ResponseData[]>(kBaseUrl + 'admin/clients/children', params.toString()).pipe(
      tap({
        error: (objErr: HttpErrorResponse) => {
          if (objErr.status === 403) {
            this.errors.publish({
              type: ErrorEventType.HTTP_ERROR,
              status: 403,
              for: '',
            });
          }
        }
      })
    );
  }

  /**
   * API for creating master client
   *
   * @param masterClientId
   * @param permissions
   * @returns
   */
  createMaster(masterClientId: string, permissions: Array<PermissionsPayload>): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('master_client_id', masterClientId);
    body.append('permissions', JSON.stringify(permissions));

    // Post request
    return this.http.post<Response>(kBaseUrl+ 'admin/clients/master/create', body.toString(), { observe: 'response' }).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.notifications.notifyNotAllowed();
          } else if (err.status === 422) {
            this.notifications.notifyWarning(this.formatErrorMessage(err.error))
          }
        }
      })
    );
  }

  /**
   * API for updating master client permissions
   * @param id
   * @param permissions
   * @returns
   */
  updateMaster(id: string, permissions: Array<PermissionsPayload>): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('permissions', JSON.stringify(permissions));
    // Post request
    return this.http.patch(kBaseUrl+ 'admin/clients/master/'+id, body.toString(), { observe: 'response' }).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.notifications.notifyNotAllowed();
          } else if (err.status === 422) {
            this.notifications.notifyWarning(this.formatErrorMessage(err.error))
          }
        }
      })
    );
  }

  /**
   * API for updating child permissions
   * @param id
   * @param permissions
   * @returns
   */
  updateChild(id: string, permissions: Array<PermissionsPayload>): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams();
    // Append post parameter
    body.append('permissions', JSON.stringify(permissions));

    // Post request
    return this.http.patch(kBaseUrl+ 'admin/clients/children/'+id, body.toString(), { observe: 'response' }).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.notifications.notifyNotAllowed();
          } else if (err.status === 422) {
            this.notifications.notifyWarning(this.formatErrorMessage(err.error))
          }
        }
      })
    );
  }

  /**
   * API for deleting record
   * @param id
   * @returns
   */
  deleteAuthClient(id): Observable<Object> {
    // Post request
    return this.http.delete(kBaseUrl+ 'admin/clients/'+id);
  }

  /**
   * Copy the customer record
   *
   * @param id
   * @param masterClientId
   * @returns
   */
  copyMasterCustomer(id: string, masterClientId: string): Observable<HttpResponse<Config>> {
    // Create request parameters
    let body = new URLSearchParams({
      other_client_id: masterClientId
    });

    // Post request
    return this.http.put<Response>(kBaseUrl+ 'customers/'+id+'/copy', body.toString(), {observe: 'response'}).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.notifications.notifyNotAllowed();
          } else if (err.status === 422) {
            this.notifications.notifyWarning(this.formatErrorMessage(err.error))
          }
        }
      })
    );
  }

  /**
   * Formatting error message
   * @param error
   * @returns
   */
  protected formatErrorMessage(error) {
    if (error['master_client_id'] && error['master_client_id'].length > 0) {
      return error['master_client_id'][0];
    }

    if (error['permissions'] && error['permissions'].length > 0) {
      return error['permissions'][0];
    }
  }
}

export class ResponseData {
  constructor(
    public name?: string,
    public permissions?: Array<[]>,
    public created_at?: string,
    public updated_at?: string,
  ) { }
}

export interface PermissionsPayload {
  action: string;
  scope: string;
};