import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RecordService } from '../../../../../services/record.service';
import { ViewService } from '../../../../../services/view.service';
import { LooseObject } from '../../../../../objects/loose-object';
import { get, isEmpty, isNil, toString } from 'lodash-es';
import { NotificationService } from '../../../../../services/notification.service';
import { MaterialLineItem } from '../../../../../objects/material-line-item';
import { toFormattedNumber } from '../../../../../shared/utils/numbers';
import { filled } from '../../../../../shared/utils/common';

@Component({
  selector: 'app-add-materials-to-job',
  templateUrl: './add-materials-to-job.component.html',
  styleUrls: ['./add-materials-to-job.component.scss']
})
export class AddMaterialsToJobComponent implements OnInit {

  public isFromJob: boolean = false;
  public isSelectAll: boolean = false;
  public lineItems: LooseObject;
  public selectedLineItems: Array<LooseObject> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private record : RecordService,
    private view: ViewService,
    private notification: NotificationService,
    private dialog: MatDialogRef<AddMaterialsToJobComponent>,
  ) { }

  ngOnInit() {
    this.isFromJob = !isEmpty(get(this.dialogData, 'job_id', ''));
    let lineItems = get(this.dialogData, 'po_line_items', []);
    this.lineItems = lineItems
    .filter(lineItem => !isEmpty(lineItem.job_id))
    .map(lineItem => {
      lineItem['quantity_used'] = lineItem['material_id'] ? lineItem['material_quantity'] : lineItem['quantity'];

      return lineItem
    });
  }

  /**
   * Simply closes the dialog.
   *
   * @returns {void}
   */
  cancelDialog(): void {

    this.dialog.close({
      action: 'close'
    });
  }

  /**
   * Adds all the line items of the purchase order to job's materials
   *
   * @returns {void}
   */
  addMaterialsToJob(): void {
    let lineItems = this.selectedLineItems;
    let stockReceiptData = get(this.dialogData, 'stock_receipt_data', []);

    let arMaterialsToSave = lineItems.map(item => {
      item['unit_price'] = item['item_unit_price'];

      let materialLineItem = new MaterialLineItem(item);

      return {...materialLineItem,
              type: isNil(materialLineItem.item_id) ? 'once_off_purchase' : 'product_catalog',
              job_id: item['job_id'],
              purchase_order_id: item['purchase_order_id'],
              warehouse_id: stockReceiptData ? stockReceiptData.warehouse_id : item['warehouse_id'],
              status: 'allocated',
              purchase_order_line_item_id: item['id'],
              note: item['description'],
              product: isNil(materialLineItem.item_id) ? item['description'] : '',
              markup: materialLineItem.markUpComputation(),
              quantity: item['quantity_used'],
            };
    });

    this.record.saveMultipleRecord('materials', arMaterialsToSave).subscribe(() => {
      this.notification.notifySuccess('success_materials_to_job');
      this.dialog.close('materials_created');
    });
  }

  onChangeCheckbox(event, lineItem = [], isAll) {
    if (isAll && event.currentTarget['checked'] == true) {
      this.selectedLineItems = [];

      this.lineItems.forEach(item => {
        if (!item.material_id) {
          this.selectedLineItems.push(item);
        }
      });
      this.isSelectAll = true;
    } else if (isAll && event.currentTarget['checked'] == false) {
      this.selectedLineItems = [];
      this.isSelectAll = false;
    } else {
      if (event.currentTarget['checked'] == true) {
        this.selectedLineItems.push(lineItem);
      } else {
        let findIndex = this.selectedLineItems.findIndex(item => item['id'] == lineItem['id']);
        this.selectedLineItems.splice(findIndex, 1);
        this.isSelectAll = false;
      }
    }
  }

  isLineItemSelected(currentLineItem) {
    return this.selectedLineItems.findIndex(item => item['id'] == currentLineItem['id']) >= 0;
  }

  formatNumber(value) {
    return toString(toFormattedNumber(value));
  }

  hasMaterialRecord(lineItem) {
    return !isEmpty(lineItem['material_id']);
  }

  usedQuantityChanged(event, currentLineItem, lineItemIndex) {
    if (parseFloat(currentLineItem['quantity']) < parseFloat(event.target.value)) {
      this.lineItems[lineItemIndex]['quantity_used'] = parseFloat(currentLineItem['quantity']);
    }
  }

  /**
   * get display text
   *
   * @param lineItem
   * @returns
   */
  getProductDisplayText(lineItem): string {
    if (filled(lineItem.item_code) && filled(lineItem.item_name)) {
      return `[${lineItem.item_code}] ${lineItem.item_name}`;
    } else {
      return lineItem.description;
    }
  }
}
