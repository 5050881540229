import { Pipe, PipeTransform } from '@angular/core';
import { blank, OptionalValue } from '../shared/utils/common';
import { toFormattedNumber } from '../shared/utils/numbers';

@Pipe({
  name: 'format_number',
  pure: false,
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: any, opts: {
    max_decimals?: number;
    is_currency?: boolean;
  } = {}): OptionalValue<string> {
    opts = Object.assign({
      max_decimals: 3,
      is_currency: false,
    }, opts);

    if (blank(value)) {
      return null;
    }

    const formatted = toFormattedNumber(value, {
      maxDecimalPlaces: opts.max_decimals,
      currency: opts.is_currency,
    });

    return formatted.toString();
  }
}