<section>
	<div class="row">
		<div class="col-sm-12">
			<app-information strModule="users"
				[isPanel1Visible]= "true"
				[isPanel2Visible]= "true"
				[isPanel3Visible]= "true" >
			</app-information>
		</div>
		<div class="col-sm-12">
			<fm-listing
				strModule="users"
				[bShowCustomList]="false"
				[objHeaderData]="objTableHeaderData"
				[bAllowCheckboxSelection]="true">
			</fm-listing>
		</div>
	</div>
</section>