import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LooseObject } from '../../../../../objects/loose-object';
import { ActivatedRoute, Router } from '@angular/router';
import { ListingElementsEvent } from '../../objects/listing-elements-event';
import { MatDialog } from '@angular/material';
import { EditInvoiceComponent } from '../../../../../module/jobs/customer-invoices/edit-invoice/edit-invoice.component';
import { ProjectTemplatesService } from '../../../../../services/project-templates.service';
import { NotificationService } from '../../../../../services/notification.service';
import { EditPurchaseOrderComponent } from '../../../../../module/jobs/purchase-orders/edit-purchase-order/edit-purchase-order.component';
import { EditSupplierInvoiceComponent } from '../../../../../module/jobs/supplier-invoices/edit-supplier-invoice/edit-supplier-invoice.component';
import { SaveComponent } from '../../../../../admin/sms-templates/save/save.component';
import { EditRecurringInvoiceFormComponent } from '../../../widget/recurring_invoices/form/edit-recurring_invoice-form.component';
import { EditRecurringJobsComponent } from '../../../widget/recurring-jobs/edit-recurring-jobs/edit-recurring-jobs.component';
import { EditComponent as EditJobTemplatesComponent } from '../../../../../admin/job-templates/edit/edit.component';
import { EditComponent as EditEmailTemplate } from "../../../../../admin/email-template/edit/edit.component";
import { filter, finalize, first, switchMap, tap } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { EditformComponent } from '../../../editform/editform.component';
import { FormPopup } from '../../../../../objects/centralized-forms/form-popup';
import { RecordService } from '../../../../../services/record.service';
import { ListingService } from '../../../../../services/listing.service';
import { StatusCode } from '../../../../../lists/status-code';
import { Observable } from 'rxjs';
import { PusherService } from '../../../../../services/pusher.service';
import { TranslateService } from '@ngx-translate/core';
import { NO_EDIT_MODAL_MODULES } from '../../listing-new-config';

@Component({
  selector: 'list-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  /**
   * Row data from the main list component.
   *
   * TODO: Create an variabled interface.
   *
   * @var {LooseObject}
   */
  @Input('rowData') rowData: LooseObject;

  /**
   * Which module uses this component.
   *
   * @var {string}
   */
  @Input('strModule') strModule: string;

  /**
   * Flag if allowed to delete, supplied by parent.
   *
   * @var {boolean}
   */
  @Input('bAllowedDelete') bAllowedDelete: boolean = false;

  /**
   * If the edit button should use logo. (Without screen resize)
   *
   * @var {boolean}
   */
  @Input('bLogoEditOnly') bLogoEditOnly: boolean = false;

  /**
   * If we want to show the view logo.
   *
   * @var {boolean}
   */
  @Input('bShowView') bShowView: boolean = false;

  /**
   * Any events dont here to be informed to the parent.
   *
   * @var {EventEmitter}
   */
  @Output() actionsEvent = new EventEmitter<ListingElementsEvent>();

  loadingRecord = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public projectTemplateService: ProjectTemplatesService,
    public notifService: NotificationService,
    public recordService: RecordService,
    public listService: ListingService,
    private pusherService: PusherService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {}

  /**
   * Get the dialog config data before opening
   * the dialog.
   *
   * @param {object} objData
   *
   * @returns {[k: string]: any}
   */
  getDialogConfig(objData: object = {}): {[k: string]: any} {

    let popupConfig : {[k: string]: any} = {
      disableClose: true,
      data: objData
    };

    if (window.innerWidth <= 800 && window.innerHeight <= 1024) {
      popupConfig.width = '100%';
      popupConfig.height = '100%';
      popupConfig.maxHeight = '100vh';
      popupConfig.maxWidth = '100vw';
    } else {
      popupConfig.width = '40%';
      popupConfig.height = 'auto';
    }

    return popupConfig;
  }

  /**
   * Refresh the list by informing the parent.
   *
   * @returns {void}
   */
  refreshList(): void {
    this.actionsEvent.emit({
      type: 'paginate',
      data: 'go'
    });
  }

  /**
   * Get the record.
   *
   * @param {string} strId
   */
  getRecord(strId: string): void {
    this.recordService.getRecord(this.strModule, strId, true, {}, 'list').subscribe( response => {
      if (response['record_details']) {
        this.listService.setUpdatedRecord(response['record_details']);
        // TODO: Not removing this while recode in progress. Will remove once confirmed to QA this works.
        // this.updateListingData(response['record_details']);
      }
    });
  }

  /**
   * Opens the record dialog the refreshes the list on successful update.
   *
   * @param {string} strId
   * @param {object} arData
   */
  recordDialog(strId: string , objData = {}): void {
    let updateDialog = this.dialog.open(
      EditformComponent,
      new FormPopup(this.strModule, {}, objData, strId, 'edit')
    );

    updateDialog.afterClosed().first().subscribe(data => {
      if (data['status'] == 'save' && data['data'] == 'record_update_success') {
        this.actionsEvent.emit({
          type: 'paginate',
          data: 'go'
        });
      }
    });

  }

  /**
   * Triggers when delete button is clicked
   *
   * @param strItemId
   *
   * @returns {void}
   */
  deleteLink(strItemId: string): void {
    this.notifService.sendConfirmation("confirm_delete").subscribe(
      confirmation => {
        if (confirmation.answer) {
          if (this.strModule === 'warehouses') {
            this.listService.fetchData(null, 'stock_levels', JSON.stringify({ warehouse_id: strItemId }))
              .subscribe(response => {
                if (response['data'].length > 0) {
                  this.notifService.notifyError('cannot_delete_warehouse');
                } else {
                  this.deleteRecord(strItemId);
                }
            });
          } else {
            this.deleteRecord(strItemId);
          }
        }
      }
    );
  }

  /**
   * When deleting record in items module, also
   * deletes related record in stock levels module.
   *
   * @param {string} strItemId
   *
   * @returns {void}
   */
  deleteItemInStockLevels(strItemId: string): void {
    this.recordService.getRecordRelateJoined('stock_levels', false, { 'items.id': strItemId })
    .pipe(
      switchMap(objStockLevelData => {
        const objStockLevelItemToBeDeleted = objStockLevelData[0];

        if (objStockLevelItemToBeDeleted) {
          return this.recordService.deleteRecord('stock_levels', objStockLevelItemToBeDeleted.id);
        } else {
          return Observable.of(false);
        }
      })
    )
    .subscribe(() => {
      this.continueRecordDeletion(strItemId);
    }, error => {
      if (error.error.id) {
        this.notifService.sendNotification('not_allowed', error.error.id[0], 'warning');
      }
    });
  }

  /**
   * Calls api which deletes record
   *
   * @param {string} strItemId
   *
   * @returns {void}
   */
  continueRecordDeletion(strItemId: string): void {
    this.recordService.deleteRecord(this.strModule, strItemId).first().subscribe(
      data => {
        // Store response body
        let arResponse = data.body;
        // Check if status is 200
        if (data.status == StatusCode.kResponseSuccess) {
          // Show notification update success
          this.notifService.sendNotification('deleted_successful', arResponse.toString(), 'success');
        } else {
          var warning_message = (typeof arResponse === 'object' && arResponse['message']) ? arResponse['message'] : arResponse.toString();
          //Notify user if the update is a success.
          this.notifService.sendNotification('warning', warning_message, 'warning');
        }
        this.refreshList();
      },
      error => {
        if (error.error.id) {
          this.notifService.sendNotification('not_allowed', error.error.id[0], 'warning');
        }
      }
    );
  }

  /**
   * Prepares deletion of record from current module.
   *
   * If current module is items, deletes related data
   * in stock levels first before proceeding to deletion.
   *
   * @param {string} strItemId
   *
   * @returns {void}
   */
  deleteRecord(strItemId: string): void {
    if (this.strModule === 'items') {
      this.deleteItemInStockLevels(strItemId);
    } else {
      this.continueRecordDeletion(strItemId);
    }
  }

  /**
   * Trigger the edit in the child.
   *
   * @param {string} strId
   * @param {[]} arData
   */
  goToEdit(strId: string, arData = []): void {
    if (NO_EDIT_MODAL_MODULES.includes(this.strModule) === false) {

      this.loadingRecord = true;
      this.pusherService.getRecordEditors(this.strModule, strId).pipe(
        finalize(() => this.loadingRecord = false)
      ).subscribe((recordEditors) => {
        if (recordEditors.length > 0) {
          this.notifService.notifyError(
            this.translate.instant('record_presence.editing_message', {editor_name: recordEditors[0].name})
          );
        } else {
          this.triggerEdit(strId, arData);
          this.dialog.afterAllClosed.subscribe(() => {
            this.pusherService.unsubscribePresenceChannel(this.pusherService.recordEditPresence, true);
          });
        }
      });
    } else {
      this.triggerEdit(strId, arData);
    }
  }

  /**
   * To tell if edit will use dialog or link.
   *
   * @param {string} strId
   * @param {array} arData
   *
   * @returns {void}
   */
  triggerEdit(strId: string, arData = []): void {

      if (this.strModule == 'pricebooks') {
        this.router.navigate(['admin/pricebooks/edit/' +strId]);
      } else if (this.strModule == 'teams') {
        this.router.navigate(['admin/teams/' + strId]);
      } else if (this.strModule == 'project_templates') {
        this.projectTemplateService.setMode('update');
        this.projectTemplateService.setProjectTemplate(arData);
        // If module is project template, open a new page for editing a job template
        this.router.navigate(['admin/project_templates/edit']);
      } else if (this.strModule == 'checklists') {
        this.router.navigate(['checklists/edit/' + strId]);
      } else if(this.strModule == 'customer_invoices') {
        // Append record details to module config to get the latest data
        // Create the object to be passed inside the dialog.
        let objData = {
          maxWidth: '100%',
          width: '100%',
          height: 'auto',
          padding: '1%',
          disableClose: true,
          // The id of the jobs, the invoice's "parent".
          data: {
            record_id : '',
            invoice: [],
            customer_invoice_id: arData['id'],
            view_type : 'edit'
          }
        };
        // Initialize the dialog.
        let dialogRef = this.dialog.open(EditInvoiceComponent, objData);
        dialogRef.afterClosed().first().subscribe(saveRecordData => {
          if (saveRecordData != undefined && saveRecordData.action == 'save') {
            this.notifService.notifySuccess('header_notification.success_update');
            if (arData['id']) {
              this.getRecord(arData['id']);
            } else {
              this.refreshList();
            }
          }
        });
      } else if(this.strModule == 'purchase_orders') {
        // Create the object to be passed inside the dialog.
        let objData = {
          maxWidth: '100%',
          width: '100%',
          height: 'auto',
          padding: '1%',
          disableClose: true,
          // The id of the jobs, the invoice's "parent".
          data: {
            record_id : '',
            purchase_order: [],
            purchase_order_id: arData['id'],
            view_type : 'edit'
          }
        };
        // Initialize the dialog.
        let dialogRef = this.dialog.open(EditPurchaseOrderComponent, objData);
        dialogRef.afterClosed().first().subscribe(saveRecordData => {
          if (saveRecordData && saveRecordData != 'fail') {
            this.notifService.notifySuccess('header_notification.success_update');
            if (arData['id']) {
              this.getRecord(arData['id']);
            } else {
              this.refreshList();
            }
          }
        });
      } else if(this.strModule == 'supplier_invoices') {
            // Create the object to be passed inside the dialog.
            let objData = {
              maxWidth: '100%',
              width: '100%',
              height: 'auto',
              padding: '1%',
              disableClose: true,
              // The id of the jobs, the invoice's "parent".
              data: {
                record_id : '',
                supplier_invoice: [],
                supplier_invoice_id: arData['id'],
                view_type : 'edit'
              }
            };
            // Initialize the dialog.
            let dialogRef = this.dialog.open(EditSupplierInvoiceComponent, objData);
            dialogRef.afterClosed().first().subscribe(saveRecordData => {
              if (saveRecordData.message == 'save') {
                this.notifService.notifySuccess('header_notification.success_update');
                if (arData['id']) {
                  this.getRecord(arData['id']);
                } else {
                  this.refreshList();
                }
              }
            });
      } else if (this.strModule == "email_templates") {

        let popupConfig : {[k: string]: any} = this.getDialogConfig(arData);

        let dialogRef = this.dialog.open(EditEmailTemplate, popupConfig);
        dialogRef.afterClosed().first().subscribe( response => {
          if (response) {
            this.notifService.notifySuccess('header_notification.success_update');
            this.refreshList();
          }
        });
      } else if (this.strModule == 'sms_templates') {
        let dialogRef = this.dialog.open(SaveComponent, {
          width: '600px',
          disableClose: true,
          data: arData
        });

        dialogRef.afterClosed().first().subscribe(saveRecordData => {
          if (saveRecordData === 'save') {
            this.refreshList();
          }
        });
      } else if (this.strModule === 'recurring_invoices') {
        this
          .dialog
          .open(EditRecurringInvoiceFormComponent, {
            maxWidth: '100%',
            width: '100%',
            height: 'auto',
            disableClose: true,
            data: {
              isNew: false,
              moduleID: arData['id'],
              moduleName: this.strModule,
              recordID: arData['id'],
            },
          })
          .afterClosed()
          .subscribe();
      } else if (this.strModule === 'recurring_jobs') {
        this
          .dialog
          .open(EditRecurringJobsComponent, {
            maxWidth: '100%',
            width: '80%',
            height: 'auto',
            disableClose: true,
            data: {
              record_id: null,
              module: null,
              recurring_job: arData,
              recurring_job_id: arData['id'],
              view_type: 'edit',
            },
          })
          .afterClosed()
          .pipe(
            first(),
            filter(response => !isNil(response) && response !== false),
            tap(() => this.refreshList()),
            tap(() => this.notifService.notifySuccess('header_notification.success_update')),
          )
          .subscribe();

      } else if (this.strModule === 'job_templates') {
        this
          .dialog
          .open(EditJobTemplatesComponent, {
            panelClass: 'height-auto-mat-dialog',
            maxWidth: '98%',
            maxHeight: '98%',
            width: '98%',
            height: 'auto',
            disableClose: true,
            data: {
              record_id: null,
              module: null,
              job_template: arData,
              job_template_id: arData['id'],
              view_type: 'edit',
            },
          })
          .afterClosed()
          .pipe(
            first(),
            filter(response => response !== undefined),
            tap(() => this.refreshList()),
            tap(() => this.notifService.notifySuccess('header_notification.success_update')),
          )
          .subscribe();

      } else if (this.strModule === 'stock_levels') {
        this.router.navigate(['stock_levels/' + strId], {queryParams: {item_id:arData['item_id']}});
      } else if (this.strModule === 'roles') {
        this.router.navigate(['admin/roles/', strId]);
      } else if (this.strModule === 'workflows') {
        this.router.navigate(['admin/workflow/form/' + strId]);
      } else {
        this.recordDialog(strId, arData);
      }
  }

  /**
   * Go to the record view.
   *
   * @param {string} strId
   *
   * @returns {void}
   */
  viewData(strId: string): void {
    this.router.navigate([strId], {
      state: {
        fromListView: false
      },
      relativeTo: this.route
    });
  }

  /**
   * Compiled all related data to be shown in tooltip
   *
   * @param relatedData - related data to be shown
   */
  compiledRelatedData(relatedData) {
    let invoiceNumber = [];
    relatedData.forEach( data => {
      invoiceNumber.push(data['text']);
    });

    return invoiceNumber.join(", ");
  }

}
