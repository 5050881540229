<header >
  <div class="d-flex dialog-header">
      <div class="dialog-title">
          <span *ngIf="isCreate"> {{ 'create_sms_template' | translate }} </span>
          <span *ngIf="! isCreate"> {{ 'update_sms_template' | translate }} </span>
      </div>
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <button id="btnSubmit" (click)="onSubmit()" class="btn btn-primary">
              <fa-icon [icon]="['fas', 'save']"></fa-icon>
              <span class="pl-1">{{ 'save' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <fieldmagic-primary-button
              (click)="closeDialog()"
              id="btnCancel"
              icon="times"
              label="close"
              variant="error"
            ></fieldmagic-primary-button>
          </li>
        </ul>
      </div>
  </div>
  <hr />
</header>

<div class="row mb06">
  <div class="col-12 rows">
      <span class="labels">{{ 'name' | translate }}</span>
      <fieldmagic-text-input [(ngModel)]="strName" id="checklistName" [customClass]="'form-control default-value'">
      </fieldmagic-text-input>
  </div>
</div>

<div class="row mb06">
  <div class="col-12 rows">
      <span class="labels">{{ 'module' | translate }}</span>
      <ng-select
        *ngIf="objAvailableFields"
        [(ngModel)]="strModule">
        <ng-option *ngFor="let module of objAvailableFields | keyvalue" [value]="module.key">
          {{ module.key | translate }}
        </ng-option>
      </ng-select>
  </div>
</div>

<div class="row mb06">
  <div class="col-12 rows">
      <span class="labels">{{ 'field' | translate }}</span>
      <div class="inline-elements">
        <ng-select
          *ngIf="objAvailableFields"
          class="field"
          [(ngModel)]="strField"
          appendTo="body">
          <ng-option *ngFor="let field of objAvailableFields[strModule]" [value]="field.field">
            {{ field.label | translate }}
          </ng-option>
        </ng-select>

        <fa-icon
          matTooltip="{{ 'append_field' | translate }}"
          class="add-button"
          (click)="appendField()"
          [icon]="['fas', 'plus-circle']">
        </fa-icon>
      </div>
  </div>
</div>

<div class="row mb06">
  <div class="col-12">
    <span class="labels">{{ 'message' | translate }}</span>
    <textarea [(ngModel)]="strMessage" class="form-control default-value message" ></textarea>
  </div>
</div>
