import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, tap, filter } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { JsonService } from '../helpers/json/json.service';
import { ImportRecord } from '../../objects/import-record';
import { environment } from '../../../environments/environment';
import { Specifications } from '../../contracts/importer/specifications';
import { NotificationService } from '../../services/notification.service';
import { FormattedErrorObject, BasicValidationErrorObject } from '../../objects/request-errors';
import { LooseObject } from '../../objects/loose-object';
import { parse as jsonParse } from '../../shared/utils/json';
import { get } from 'lodash-es';
import { CustomTranslateService } from '../custom-translate.service';
import { filled } from '../../shared/utils/common';


@Injectable({
  providedIn: 'root'
})
export class ImporterService {

  constructor(
    protected http: HttpClient,
    protected notificationService: NotificationService,
    protected jsonService: JsonService,
    public customTranslate: CustomTranslateService
  ) { }

  /**
   * Makes a request to the template download API then returns the file
   * to the user for download.
   *
   * @param {string} module_name
   *
   * @returns {Observable<HttpResponse<Blob>>}
   */
  downloadImportFileTemplate(module_name: string, additionalFilter: LooseObject = {}): Observable<HttpResponse<Blob>> {
    let objFilter = {};
    if (additionalFilter) {
      objFilter = {
        filter: JSON.stringify(additionalFilter)
      };
    }
    return this.http.post<Blob>(
      this.importApi(`${module_name}/download-template`),
      objFilter,
      { headers: new HttpHeaders({ Accept: 'text/csv' }), responseType: 'blob' as 'json', observe: 'response' }
    )
    .pipe(
      map(response => response),
      catchError(error => of(error).pipe(
        tap(error => {
          this.notificationService.notifyError('import_template_download_error');
        })
      )),
      filter(response => response.error === undefined)
    );
  }

  /**
   * Uploads the given file so that it can be queued for importing
   *
   * @param {string} moduleName
   * @param {string} tempS3Filename
   * @param {string} specifications
   *
   * @returns {Observable<ImportRecord>}
   */
  import(
    moduleName: string,
    tempS3Filename: string,
    specifications: Specifications,
    recordId: string | null = null
  ){
    let body = new URLSearchParams();

    body.append('module', moduleName);
    body.append('temp_s3_filename', tempS3Filename);
    body.append('specifications', this.jsonService.stringify(specifications));
    body.append('record_id', recordId);
    return this
      .http
      .post(this.importApi(`${moduleName}/import`), body.toString())
      .pipe(
        catchError((error) => {
          let errorMessage = error.error;

          if (get(errorMessage, 'missing_required_fields', null) !== null) {
            const requiredFields = get(errorMessage, 'missing_required_fields', [])
              .map((field) => {
                console.log(this.customTranslate.getTranslation(field))
                return this.customTranslate.getTranslation(field)});
            errorMessage = 'Required field/s missing: ' + requiredFields.join(', ');
          }

          this.notificationService.notifyError(errorMessage);
          return throwError(errorMessage);
        }),
        map(response => response['data']),
      );
  }

  /**
   * Returns the records that were imported under the given
   * import id. The HttpResponse type has been set to "any"
   * due to the polymorphic nature of the imported records.
   *
   * @param import_id
   * @param page_num
   *
   * @returns {Observable<any[]>}
   */
  getImportedRecords(import_id: string, page_num: number = 1): Observable<any[]> {
    return this.http.post(this.importApi(`${import_id}/imported-records?page=${page_num}`), {})
      .pipe(
        catchError((error) => {
          this.notifyError(error);
          return of(error);
        }),
        map(response => response),
      );
  }

  /**`
   * Makes a request to the resultset download API then returns the file
   * to the user for download.
   *
   * @param {string} module_name
   * @param {string} kind
   *
   * @returns {Observable<HttpResponse<Blob>>}
   */
  downloadResultSet(import_id: string, kind: 'success' | 'errors' | 'import'): Observable<HttpResponse<Blob>> {
    return this.http.post<Blob>(
      this.importApi(`${import_id}/download-resultset/${kind}`),
      {},
      { headers: new HttpHeaders({ Accept: 'text/csv' }), responseType: 'blob' as 'json', observe: 'response' }
    )
      .pipe(
        map(response => response),
        catchError(error => of(error).pipe(
          tap(error => {
            this.notificationService.notifyError('resultset_download_error');
          })
        )),
        filter(response => response.error === undefined)
      );
  }

  /**
   * Using the given temporary s3 filename, this will call the field-mapping API
   * which determines the initial mapped data (header and value) in the CSV file
   * The API also gets the metadata of every importable fields.
   *
   * @param {string} moduleName
   * @param {string} tempS3Filename
   *
   * @returns {Observable<FieldMappings>}
   */
  extractFieldMappings(
    moduleName: string,
    tempS3Filename: string,
    opts: {
      asset_type_id?: string,
      additional_mappings?: Record<string, any>,
    } = {}
  ): Observable<FieldMappings> {
    opts = Object.assign({
      asset_type_id: null,
      additional_mappings: {},
    }, opts);

    let formdata = new FormData();

    formdata.set('temp_s3_filename', tempS3Filename);

    if (filled(opts.asset_type_id)) {
      formdata.set('asset_type_id', opts.asset_type_id);
    }

    if (filled(opts.additional_mappings)) {
      formdata.set('additional_mappings', JSON.stringify(opts.additional_mappings));
    }

    return this.http.post<FieldMappings>(this.importApi(`${moduleName}/field-mappings/extract`), formdata)
      .pipe(
        tap({
          error: (error) => {
            this.notifyError(get(error, ['error', 'error_message'], 'an_error_occured'));
          }
        }),
        map(response => response)
      );
  }

  getTemplate(moduleName: string) {
    let formdata = new FormData();
    formdata.set('module', moduleName);
    return this.http.post(this.importApi(`get-templates`), formdata)
    .pipe(
      catchError((error) => {
        this.notifyError(error);
        return of(error);
      }),
      map(response => response),
    );
  }

  /**
   * Calls API to save a template
   *
   * @param moduleName
   * @param templateName
   * @param csvPath
   * @param metadata
   * @param pairedData
   * @param additionalData
   * @param strId
   * @returns
   */
  saveTemplate(
    moduleName: string,
    templateName: string,
    csvPath: string,
    metadata,
    pairedData,
    additionalData,
    strId: string | null = null
  ): Observable<FieldMappings & { id : string }> {
    let formdata = new FormData();

    formdata.set('name', templateName);

    // If template update there's no need to resave module, path and metadata
    if (! strId) {
      formdata.set('module', moduleName);
      formdata.set('csv_path', csvPath);
      formdata.set('metadata', JSON.stringify(metadata));
    }
    // If Update append the ID
    if (strId) {
      formdata.set('id', strId);
    }
    if (pairedData.length != 0) {
      formdata.set('paired_data', JSON.stringify(pairedData));
    }
    if (additionalData.length != 0) {
      formdata.set('additional_data', JSON.stringify(additionalData));
    }
    return this.http.post<FieldMappings>(this.importApi(`save-template`), formdata)
      .pipe(
        tap({
          error: (error) => {
            this.notifyError(error.error);
          }
        }),
        map(response => response['item']),
      );
  }


  /**
   * Saves the given field mapping, with a specific name.
   *
   * @param moduleName
   * @param name
   * @param fieldMap
   *
   * @returns {Observable<FieldMappingRecord>}
   */
  saveModuleFieldMapping(
    moduleName: string,
    name: string,
    fieldMap
  ) {
    let formdata = new FormData();
    formdata.set('name', name);
    formdata.set('field_mappings', this.jsonService.stringify(fieldMap));

    return this
      .http
      .post(this.importApi(`${moduleName}/field-mappings/store`), formdata)
      .pipe(
        tap({
          error: (error) => {
            this.notifyError(error.error);
          }
        }),
        map(response => response['data']),
        map(response => {
          response.field_mappings = jsonParse(response.field_mappings, {});

          return response;
        }),
      );
  }

  /**
   * Saves the given field mapping, with a specific name.
   *
   * @param moduleName
   * @param name
   * @param fieldMap
   *
   * @returns {Observable<FieldMappingRecord>}
   */
  updateModuleFieldMapping(
    moduleId: string,
    moduleName: string,
    name: string,
    fieldMap
  ){
    let formdata = new FormData();
    formdata.set('id', moduleId);
    formdata.set('name', name);
    formdata.set('field_mappings', this.jsonService.stringify(fieldMap));

    return this
      .http
      .post(this.importApi(`${moduleName}/field-mappings/update`), formdata)
      .pipe(
        tap({
          error: (error) => {
            this.notifyError(error.error);
          }
        }),
        map(response => response['data']),
        map(response => {
          response.field_mappings = jsonParse(response.field_mappings, []);

          return response;
        }),
      );
  }

  /**
   * Similar to ImporterService::presentArrayAsErrorMessages but reads
   * the error messages in a different way, due to how BLOB errors
   * are returned by the server.
   *
   * @see {@link https://stackoverflow.com/a/48502027/8449155} For why we're parsing the error
   * messages in a different way.
   *
   * @param header
   * @param error
   *
   * @returns {void}
   */
  protected presentBlobErrorMessages(header: string, error: HttpErrorResponse): void {
    const reader = new FileReader();
    reader.addEventListener('loadend', (e) => {
      this.notifyError(JSON.parse(e.srcElement['result']));
    });
    reader.readAsText(error.error);
  }

  /**
   * Extracts the error messages from the HttpErrorResponse object.
   *
   * @param {HttpErrorResponse} error
   *
   * @returns {void}
   */
  protected notifyError(response: FormattedErrorObject | BasicValidationErrorObject): void {
    let errorMessage = 'unknown_error';

    if (response.errors !== undefined) {
      let errors = <FormattedErrorObject> response;
      errorMessage = (errors.errors.map(_error => _error.detail)).join('\n');
    }

    this.notificationService.notifyError(errorMessage);
  }

  /**
   * Appends the importer API url to the given endpoint suffix.
   *
   * @param suffix
   *
   * @returns {string}
   */
  protected importApi(suffix: string): string {
    return `${environment.url}/importer/${suffix}`;
  }
}

export type FieldMetadata = {
  'first_row_value': string,
  'import_header': string,
  'label': string,
  'max_length': number,
  'type': string,
  'key': string
 };

export type FieldMappings = {
  extracted_data:  {
    not_paired_data: FilteredMetadata[],
    paired_data: FilteredMetadata[]
  } ;
  metadata: FilteredMetadata[]
};

export type FilteredMetadata = {
  default_value?: string,
  first_row_value?: string,
  import_header?: string,
  label: string,
  max_length?: number,
  required?: boolean,
  type?: string,
  unique?: boolean
}

export type Template = {
  id?: string,
  name?: string,
  csv_path?: string,
  paired_fields?: object,
  module?: string,
  metadata?: object,
  additional_paired_fields?: object,
  unpaired_fields?: object
}
