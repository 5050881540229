import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Notification } from '../../../../../objects/notification';
import { NotificationService } from '../../../../../services/notification.service';
import { data_get } from '../../../../utils/common';

@Component({
  selector: 'app-asset-status',
  templateUrl: './asset-status.component.html',
  styleUrls: ['./asset-status.component.scss']
})
export class AssetStatusComponent implements OnInit {

  public arAssetData : any = [];
  public strStatus = '';
  public arStatus = [
    'awaiting_completion',
    'pass',
    'fail'
  ];

  faultDetails?: string;

  constructor(
    private notifService: NotificationService,
    private dialogRef: MatDialogRef<AssetStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.arAssetData = this.data['asset_data'];
    this.strStatus = this.arAssetData['status']
    this.faultDetails = data_get(this.arAssetData, 'fault_details');
  }

  // To close the current dialog
  closeDialog(isCancelled = true, strStatus = null): void {

    let objReturn = { status: 'cancel' };
    if (!isCancelled && strStatus != null) {
      objReturn['status'] = 'save';
      objReturn['new_status'] = strStatus;
      objReturn['fault_details'] = this.faultDetails;
    }

    this.dialogRef.close(objReturn);
  }

  // Calls close dialog and pass the new status (If there is a change in status)
  saveStatus() {
    this.closeDialog(false, this.strStatus)
  }

}
