import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EditModule } from './edit.module';
import { SharedModule } from './shared.module';
import { SchedulesTabComponent } from '../../features/new-scheduler/schedules-tab/schedules-tab.component';
import { ScheduleFormComponent } from '../../features/new-scheduler/schedules-tab/components/schedule-form.component';

@NgModule({
  declarations: [
    SchedulesTabComponent,
    ScheduleFormComponent
  ],
  entryComponents: [
    SchedulesTabComponent,
    ScheduleFormComponent
  ],
  exports: [
    SchedulesTabComponent,
    ScheduleFormComponent
  ],
  imports: [
    TranslateModule,
    EditModule,
    FontAwesomeModule,
    SharedModule.forRoot(),
  ]
})
export class SharedCalendarModule { }
