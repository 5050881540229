<div *ngIf="arRecordHeader">
  <ng-container *ngIf="! (objRecordDetails | data_get: 'data_only')">
  <div class="container-fluid">
    <div class="row">
      <div [ngClass]="strModule === 'sites' ? 'col-xs-6 col-md-5' : 'col-xs-3 col-md-3'">
        <span class="record-title" id="viewHeaderTitle">
          <span class="icon-figure-primary">
            <fa-icon [icon]="['far', arIcon]"></fa-icon>
          </span> &nbsp;
          {{ arRecordHeader['label'] | translate}}
          <span *ngIf="objBadgeModules[strModule] && arRecordDetails[objBadgeModules[strModule]]"
            class="badge badge-success" matTooltip="{{'badge.' + strModule | translate}}">
            {{ arRecordDetails[objBadgeModules[strModule]] }}
          </span>
        </span>
      </div>
      <div [ngClass]="strModule === 'sites' ? 'col-xs-6 col-md-7' : 'col-xs-9 col-md-9'">
        <div class="buttons-mobile-view">
          <ul *ngIf="strPreviousData || strNextData" class="list-inline float-right ml-2 mt-1">
            <li class="list-inline-item ml-1">
              <a *ngIf="strPreviousData" href="" [routerLink]="[strUrl + strPreviousData.id]" [state]="{searchAfter: strPreviousData.search_after}"
                id="navigatePrevious">
                <fa-icon [icon]="['far', 'chevron-left']"></fa-icon>
              </a>
              <fa-icon *ngIf="!strPreviousData" id="navigatePreviousDisabled" [icon]="['far', 'chevron-left']"></fa-icon>
            </li>
            <li class="list-inline-item ml-3">
              <a *ngIf="strNextData" href="" [routerLink]="[strUrl + strNextData.id]" [state]="{searchAfter: strNextData.search_after}"
                id="navigateNext">
                <fa-icon [icon]="['far', 'chevron-right']"></fa-icon>
              </a>
              <fa-icon *ngIf="!strNextData" id="navigateNextDisabled" [icon]="['far', 'chevron-right']"></fa-icon>
            </li>
          </ul>

          <ng-container *ngIf="arRecordDetails | filled">
            <ul
              class="list-inline float-right"
              *isAuditSupported="strModule"
            >
              <li class="list-inline-item">
                <audit-trail-record-history-button
                  [moduleName]="strModule"
                  [moduleId]="arRecordDetails | data_get: 'id'"
                ></audit-trail-record-history-button>
              </li>
            </ul>
          </ng-container>

          <ng-container>
            <ul class="list-inline float-right" *ngIf="formTemplateData && formTemplateLabel">
              <li class="list-inline-item">
                <form-templates-generator [module-name]="strModule"></form-templates-generator>
              </li>
            </ul>
          </ng-container>

          <ng-container>
            <ul class="list-inline float-right" *ngIf="arRecordViewList.length > 0 && strModule === 'jobs'">
              <li class="list-inline-item">
                <profitability-analysis-button
                  (updatedAmountToInvoiceEmitter)="updateAmountToInvoiceField($event)"
                  [job-record]="arRecordDetails"
                  id="btnProfitabilityAnalysis">
                </profitability-analysis-button>
              </li>
            </ul>
          </ng-container>

          <ng-container>
              <import-record-button [record]="arRecordDetails"></import-record-button>
          </ng-container>

          <ng-container>
            <ul class="list-inline float-right" *ngFor="let objButtons of arRecordHeader['button']">
              <li class="list-inline-item">
                <ng-container *ngIf="objButtons">
                  <div ngSwitch [ngSwitch]="objButtons.type">
                    <div *ngSwitchCase="'dialog_edit'">
                      <ng-container *fcHasPermission="strModule + ':edit'; else disableEditButton">
                        <button id="editRecordButton" *ngIf="bEditable && strModule != 'leads'" (click)="(bEditButton) ? goToEdit() : ''"
                          class="btn mr-2" [ngClass]="objButtons.class" [disabled]="openingEditModal">
                          <fa-icon *ngIf="objButtons.icon" [spin]="openingEditModal" [icon]="['fas', openingEditModal ? 'spinner' : objButtons.icon]" class="mr-1"></fa-icon>{{
                          objButtons.label | translate }}
                        </button>
                        <!-- This containter button is only applicable on leads-->
                        <ng-container *ngIf="bEditable && strModule == 'leads'">
                          <div *ngIf="!bConvertButton" matTooltip="{{'converted_data_tooltip' | translate}}">
                            <button id="editRecordButton" (click)="(bEditButton) ? goToEdit() : ''" class="btn mr-2" [ngClass]="objButtons.class"
                              disabled>
                              <fa-icon *ngIf="objButtons.icon" class="mr-1"></fa-icon>{{
                              objButtons.label | translate }}
                            </button>
                          </div>
                          <div *ngIf="bConvertButton">
                            <button id="editRecordButton" (click)="(bEditButton) ? goToEdit() : ''" class="btn mr-2" [ngClass]="objButtons.class" [disabled]="openingEditModal">
                              <fa-icon *ngIf="objButtons.icon" [spin]="openingEditModal" [icon]="['fas', openingEditModal ? 'spinner' : objButtons.icon]" class="mr-1"></fa-icon>{{
                              objButtons.label | translate }}
                            </button>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-template #disableEditButton>
                        <button class="btn" [ngClass]="objButtons.class" disabled>
                          <fa-icon *ngIf="objButtons.icon" [icon]="['fas', objButtons.icon]" class="mr-1"></fa-icon>
                          <span matTooltip="{{ 'forbidden_action_error' | translate }}">{{ objButtons.label | translate }}</span>
                        </button>
                      </ng-template>
                    </div>

                    <div *ngSwitchCase="'dialog_convert'">
                      <button id="convertRecordButton" *ngIf="bConvertButton" (click)="convertDialog('button')" class="btn"
                        [ngClass]="objButtons.class" [disabled]="bConverting">
                        <inline-view-loader [isLoading]="bConverting" [hasText]="false"></inline-view-loader>
                        <fa-icon *ngIf="objButtons.icon && !bConverting" [icon]="['far', objButtons.icon]" class="mr-1">
                        </fa-icon> {{ objButtons.label | translate }}
                      </button>
                      <button id="convertedLinkButton" *ngIf="!bConvertButton && strModule == 'leads'" (click)="convertedLinkDialog()" class="btn"
                        [ngClass]="objButtons.class">
                        <fa-icon *ngIf="objButtons.icon" [icon]="['far', objButtons.icon]" class="mr-1"></fa-icon>{{
                        'view_converted' | translate }}
                      </button>
                    </div>

                    <div *ngSwitchCase="'sync_to_accounting'">
                      <integrations-accounting-system-sync-module
                        [module-name]="objButtons.moduleName"
                        [module-id]="objButtons.moduleId"
                        (onSuccessfulAction)="handleAccountingSyncSuccessfulAction($event)"
                        id="syncToAccountingButton"
                      ></integrations-accounting-system-sync-module>
                    </div>

                    <div *ngSwitchCase="'button'">
                      <button [id]="objButtons.label + 'Button'" type="{{ objButtons.type }}" [routerLink]="[objButtons.link]"
                        [queryParams]="objButtons.queryParams" class="btn" [ngClass]="objButtons.class">
                        <fa-icon *ngIf="objButtons.icon" [icon]="['fas', objButtons.icon]" class="mr-1"></fa-icon> {{
                        objButtons.label | translate }}
                      </button>
                    </div>

                    <div *ngSwitchCase="'custom_action'">
                      <button [id]="objButtons.label + 'Button'" type="{{ objButtons.type }}"
                        class="btn" [ngClass]="objButtons.class" (click)="objButtons.custom_action()">
                        <fa-icon *ngIf="objButtons.icon" [icon]="['fas', objButtons.icon]" class="mr-1"></fa-icon> {{
                        objButtons.label | translate }}
                      </button>
                    </div>

                    <div *ngSwitchCase="'back'">
                      <back-to-list [module]="strModule" [label]="objButtons.label" [link]="objButtons.link"
                        [icon]="objButtons.icon" [cstmClass]="objButtons.class">
                      </back-to-list>
                    </div>

                    <div *ngSwitchCase="'link'">
                      <button [id]="objButtons.label + 'Button'" type="{{ objButtons.type }}"
                        [routerLink]="[objButtons.link+ '/' +arRecordDetails[objButtons.id]] " class="btn"
                        [ngClass]="objButtons.class">
                        <fa-icon *ngIf="objButtons.icon" [icon]="['fas', objButtons.icon]" class="mr-1"></fa-icon> {{
                        objButtons.label | translate }}
                      </button>
                    </div>

                    <div *ngSwitchCase="'job_work_order_items'">
                      <button id="workOrderButton" type="button" (click)="jobWorkOrderItemsDialog()" class="btn" [ngClass]="objButtons.class">
                        <fa-icon *ngIf="objButtons.icon" [icon]="['fas', objButtons.icon]" class="mr-1"></fa-icon> {{
                        objButtons.label | translate }}
                      </button>
                    </div>

                    <ng-container *ngSwitchCase="'form_templates'">
                      <form-templates-generator [module-name]="strModule" [module-id]="arRecordDetails['id']">
                      </form-templates-generator>
                    </ng-container>

                    <div *ngSwitchCase="'import'">
                      <button
                        *ngIf="arRecordDetails['is_supplier'] === true"
                        id="btnImportSupplierPricing"
                        type="button"
                        (click)="importSupplierPricing()"
                        [ngClass]="objButtons.class">
                        <fa-icon [icon]="['fas', 'file-plus']" class="mr-1"></fa-icon>
                        {{ objButtons.label | translate }}
                      </button>
                    </div>

                    <div *ngSwitchCase="'delete'">
                      <ng-container *fcHasPermission="strModule + ':delete'; else disabledDeleteButton">
                        <button *ngIf="deletable" id="deleteRecordButton" type="button" (click)="deleteRecord()" class="btn" [ngClass]="objButtons.class">
                          <fa-icon *ngIf="objButtons.icon" [icon]="['fas', objButtons.icon]" class="mr-1"></fa-icon> {{ objButtons.label | translate }}
                        </button>
                      </ng-container>
                      <ng-template #disabledDeleteButton>
                        <button id="deleteRecordButton" type="button" class="btn" [ngClass]="objButtons.class" disabled>
                          <fa-icon *ngIf="objButtons.icon" [icon]="['fas', objButtons.icon]" class="mr-1"></fa-icon>
                          <span matTooltip="{{ 'forbidden_action_error' | translate }}">{{ objButtons.label | translate }}</span>
                        </button>
                      </ng-template>
                    </div>

                    <div *ngSwitchCase="'copy'">
                      <ng-container *ngIf="arRecordDetails.can_copy">
                        <button id="copyRecordButton" (click)="copyRecord()"
                          class="btn" [ngClass]="objButtons.class">
                          <fa-icon *ngIf="objButtons.icon" [icon]="['fas', objButtons.icon]" class="mr-1"></fa-icon>{{
                          objButtons.label | translate }}
                        </button>
                      </ng-container>
                    </div>

                    <ng-container *ngSwitchCase="'actions'">
                      <app-view-actions-button [module]="strModule" [actions]="objButtons.actions" [record]="arRecordDetails"></app-view-actions-button>
                    </ng-container>

                    <div *ngSwitchDefault>
                    </div>
                  </div>
                </ng-container>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="currentRecordViewers?.length > 0" class="row">
      <div class="col-md-12">
        <ng-container>
          <span class="float-right">
            <div class="presence-list" id="presenceList">
              <div *ngFor="let recordViewer of currentRecordViewers" class="user-avatar" [style.backgroundColor]="recordViewer.color" matTooltip="{{recordViewer.name}}">
                {{ recordViewer.initials }}
                <fa-icon [icon]="['fas', recordViewer.icon]" class="eye-icon"></fa-icon>
              </div>
            </div>
          </span>
        </ng-container>
      </div>
    </div>
  </div>

  <hr />

  <integrations-accounting-systems-notification-failed-sync></integrations-accounting-systems-notification-failed-sync>
  </ng-container>

  <activities-sticky-notes
    *ngIf="(arRecordDetails | data_get: 'id' | filled) && doesModuleSupportActivities"
    [moduleName]="strModule"
    [moduleId]="arRecordDetails | data_get: 'id'"
  ></activities-sticky-notes>

  <nav class="pb-3">
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <a *ngFor="let tab of arRecordView" [ngClass]="{'active': tab.label == strCurrentLabel}"
        class="nav-item nav-link noradius text-primary pointer record-labels" (click)="switchTabView(tab.label)"
        [id]="'viewRecordTab' + tab.label">
        {{ tab.label | translate }}
      </a>
    </div>
  </nav>

  <div *ngFor="let rowFields of arRecordViewList" class="row record-container" style="clear:both;">
    <div *ngFor="let eachField of rowFields" [ngClass]="getCustomClass(rowFields)">
      <div *ngIf="eachField['is_hidden'] != undefined && !eachField['is_hidden'] || eachField['is_hidden'] == undefined"
        [ngSwitch]="eachField['type']" class="form-group">
        <label
          *ngIf="! eachField['hide_label']"
          class="record-labels"
          for="{{ eachField }}">
          {{ eachField['label'] | translate }}

          <ng-container *fcMinSubscriptionPlanRequired="'fieldmagic-enterprise'">
            <fa-icon
              *ngIf="(eachField.label === 'create_job_email_address' || eachField.label === 'create_supplier_invoice_email_address')  && strModule === 'customers'"
              ngxClipboard
              [cbContent]="getFieldValue(eachField)"
              (cbOnSuccess)="notifyCopy()"
              [icon]="['fal', 'copy']"
              class="pull-right ml-2 pointer text-primary"
              matTooltip="{{ 'copy_to_clipboard' | translate }}">
            </fa-icon>
          </ng-container>

          <ng-container *fcMinSubscriptionPlanRequired="'fieldmagic-enterprise'">
            <fa-icon
              *ngIf="(eachField.label === 'create_job_email_address' || eachField.label === 'create_supplier_invoice_email_address') && strModule === 'customers'"
              [icon]="['fas', 'info-circle']"
              class="ml-1 pointer text-primary"
              matTooltip="{{ (eachField.label === 'create_job_email_address' ? 'create_job_by_email_info' : 'create_supplier_invoice_by_email_info' ) | translate }}">
            </fa-icon>
          </ng-container>

          <fa-icon
            *ngIf="(eachField.label === 'create_job_email_address' || eachField.label === 'create_supplier_invoice_email_address') && strModule === 'customers' && !isActiveClientOnEnterprisePlan"
            [icon]="['fas', 'info-circle']"
            class="ml-1 pointer text-primary"
            matTooltip="{{ 'click_for_more_info' | translate }}"
            (click)="displayUpgradeInfoBoxForNonEnterpriseClients()">
          </fa-icon>
        </label>

        <!-- url -->
        <div *ngSwitchCase="'url'">
          <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 url"
            (click)="openExternalLink(eachField['value'])">
            {{ eachField['value'] }}
          </span>
        </div>

        <!-- number -->
        <div *ngSwitchCase="'number'">
          <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
            {{ eachField['value'] | placeholdWithString }}
          </span>
        </div>

        <div *ngSwitchCase="'increment'">
          <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
            {{ formatNumbers(eachField['value']) | placeholdWithString }}
          </span>
        </div>

        <div *ngSwitchCase="'currency'">
          <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
            {{ eachField['value'] | currency: {decimal_places: eachField['displayed_places']} | placeholdWithString }} &nbsp;
          </span>
        </div>

        <!-- checkbox -->
        <div *ngSwitchCase="'checkbox'">
          <mat-slide-toggle [id]="'viewField' + eachField.label" class="ml-1" [checked]="(eachField['value'] === true) ? true : false" [disabled]="true">
          </mat-slide-toggle>
        </div>

        <!-- date -->
        <div *ngSwitchCase="'date'">
          <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
            {{ formatDate(eachField['value'], false) | placeholdWithString }} &nbsp;
          </span>
        </div>

        <!-- date -->
        <div *ngSwitchCase="'datetime'">
          <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
            {{ formatDate(eachField['value'], true) | placeholdWithString }} &nbsp;
          </span>
        </div>

        <!-- dialog -->
        <div *ngSwitchCase="'dialog'" class="record-texts">

          <div [id]="'viewField' + eachField.label" *ngIf="eachField['label'] == 'phone'">
            <div *ngFor="let item of eachField['value']">
              <div class="icon-container">
                <fa-icon [icon]="['far', item.getPhoneIcon]" class="icon-style"></fa-icon>
              </div>
              <span *ngIf="item.number; else illegalNumber">{{ item.code }} {{ (item.number) ? (item.number | phone) :
                '' }}</span>
              <ng-template #illegalNumber><span>{{ 'fix_number' | translate }}</span></ng-template>
            </div>
          </div>

          <span [id]="'viewField' + eachField.label" *ngIf="eachField['value'] == ''"
            class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text">
            <span [id]="'viewField' + eachField.label" *ngIf="eachField['value'] != undefined && eachField['value'].length == 0">--</span>
          </span>
        </div>

        <!-- colour picker -->
        <div *ngSwitchCase="'picker'">
          <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
            <fa-icon *ngIf="eachField['value']" [icon]="['fas', 'circle']" [ngStyle]="{'color': eachField['value']}">
            </fa-icon>
            {{ eachField['value'] | placeholdWithString }}
          </span>
        </div>

        <!-- colour picker -->
        <div *ngSwitchCase="'textarea'">
          <span [id]="'viewField' + eachField.label" class="form-control-plaintext form-control-sm pl-2 record-texts border-bottom long-text"
            [innerHTML]="eachField['value'] | placeholdWithString"></span>
        </div>

        <!-- multiselect -->
        <div *ngSwitchCase="'multiselect'" class="record-texts">
          <!-- if field is email -->
          <div [id]="'viewField' + eachField.label" *ngIf="eachField['label'] == 'email_address'">
            <div *ngFor="let item of eachField['default_value']">
              <div class="icon-container">
                <fa-icon [icon]="['far', 'envelope']" class="icon-style" [ngStyle]="{'color': eachField['value']}">
                </fa-icon>
              </div>
              {{ item['email'] }} <span *ngIf="item['primary'] == '1'" class="badge badge-success">Primary</span>
            </div>

            <span [id]="'viewField' + eachField.label" *ngIf="! eachField['value']"
              class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text">--</span>
          </div>

          <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text"
            *ngIf="eachField['label'] != 'email_address'">
            {{ formatMultiselectValues(eachField['default_value'], eachField['options']) | placeholdWithString }}
          </span>
        </div>

        <!-- relate -->
        <div *ngSwitchCase="'relate'">
          <div [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text">
            <div *ngIf="eachField['value'] && eachField['default_value']; else empty_relate" class="d-flex align-items-center justify-content-between site-field">
              <span
                *ngIf="(strModule !== 'jobs' && strModule !== 'items')
                || (strModule === 'jobs' && eachField['module'] !== 'customers')
                || (strModule === 'jobs' && eachField['module'] === 'customers' && arRecordDetails['customer_is_viewable'])
                else noLinkRelate"
                [ngClass]="{'pointer': arNoRelateModule.indexOf(eachField['module']) == -1 }">

                <a *ngIf="!arNoRelateModule.includes(eachField['module'])"
                  [routerLink]="getRelateLink(eachField['module'], eachField['default_value'])"
                  [queryParams]="getQueryParams(eachField['module'])">
                  {{ formatNumbers(eachField['value']) }}
                </a>

                <span *ngIf="arNoRelateModule.includes(eachField['module'])">
                  {{ formatNumbers(eachField['value']) }}
                </span>
              </span>

              <a *ngIf="eachField.module === 'sites'" href="{{ eachField.google_maps_url }}" target="_blank" class="pull-right text-primary pointer">
                <fa-icon
                  [icon]="['fas', 'map-marker-alt']"
                  class="fa-lg"
                  matTooltip="{{ 'go_to_google_maps' | translate }}">
                </fa-icon>
              </a>
            </div>
            <ng-template #empty_relate>--</ng-template>
            <ng-template #noLinkRelate>
              <span>{{ formatNumbers(eachField['value']) }}</span>
            </ng-template>
          </div>
        </div>

        <!-- image -->
        <div *ngSwitchCase="'image'">
          <ng-container *ngIf="eachField['loading']; else showImage">
            <small class="text-secondary">
              <fa-icon [icon]="['fas','spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              {{ 'loading' | translate }}
            </small>
          </ng-container>

          <ng-template #showImage>
            <ng-container *ngIf="eachField['value']">
              <img [id]="'viewField' + eachField.label" class="img-fluid rounded mx-auto d-block" src="{{eachField['value']}}" />
            </ng-container>
            <ng-container *ngIf="!eachField['value'] || eachField['value'] == '' && !eachField['loading']">
              <img [id]="'viewField' + eachField.label" width="250" height="250" class="img-fluid rounded mx-auto d-block" src="../assets/img/noimage.png" />
            </ng-container>
          </ng-template>
        </div>

        <!-- json -->
        <div *ngSwitchCase="'json'">

          <div *ngIf="strModule == 'users'"
            class="form-control-plaintext form-control-sm pl-2 record-texts d-inline-block">

            <div class="row">
              <div *ngFor="let schedule of eachField['value'] | keyvalue : ignoreSort" class="col-6">
                <span class="text-secondary">{{ schedule.key | translate }}:&nbsp;</span>
                <span class="text-secondary">{{ formatTimeAvailability(schedule.value) | translate }}</span>
              </div>
            </div>

          </div>

          <div *ngIf="strModule == 'asset_types'"
            class="form-control-plaintext form-control-sm pl-2 record-texts d-inline-block">
            <div [id]="'viewField' + eachField.label" *ngIf="eachField['label'] == 'attributes'">
              <div [id]="'viewField' + eachField.label + item.key" *ngFor="let item of eachField['value']">
                {{ item.type | translate }} : {{ item.label | translate }}
              </div>
            </div>
          </div>

          <div *ngIf="strModule == 'assets'">
            <div *ngIf="eachField['value'].length > 0" class="row">
              <div class="col-xs-6 col-md-6 mb-3" *ngFor="let item of eachField['value']">
                <label class="record-labels" for="item.label">{{ item.label | translate }}</label>
                <span *ngIf="item.type == 'text' || item.type == 'dropdown'"
                  class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts"
                  [id]="'viewField' + item.label"
                  >{{ item.default_value | translate }}</span>
                <span *ngIf="item.type == 'number'"
                  class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts"
                  [id]="'viewField' + item.label"
                  >{{ item.default_value }}</span>
                <span *ngIf="item.type == 'date'"
                  class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts"
                  [id]="'viewField' + item.label"
                  >{{ formatDate(item.default_value, false) }}</span>
              </div>
            </div>
            <div *ngIf="eachField['value'].length < 1" class="row">
              <div class="col-md-12 col-xs-12 record-texts">
                <span id="viewFieldno_attributes">{{ 'no_available_attributes' | translate}}</span>
              </div>
            </div>
          </div>

            <ng-container *ngIf="strModule === 'recurring_jobs'">
              <div *ngIf="eachField['label'] === 'required_skills'">
                <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text">{{ recurringJobsRequiredSkills }}</span>
              </div>
              <div *ngIf="eachField['label'] === 'checklists'">
                <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text">{{ recurringJobsChecklists }}</span>
              </div>
              <div *ngIf="eachField['label'] == 'assets_items' || eachField['label'] == 'work_order' || eachField['label'] == 'material'">
                <app-line-items [recordData]="arRecordDetails" [recordViewData]="arRecordViewData" [currentFieldData]="eachField['value']" [strModule]="strModule" [strLabel]="eachField['label']"></app-line-items>
              </div>
            </ng-container>

          <!-- Layout for Line items-->
          <div *ngIf="eachField['label'] == 'assets_items' || eachField['label'] == 'line_items'">
            <app-line-items [recordData]="arRecordDetails" [recordViewData]="arRecordViewData"
              [currentFieldData]="eachField['value']" [strModule]="strModule"></app-line-items>
          </div>

          <ng-container *ngIf="strModule === 'stocktakes'">
            <table class="table table-bordered main_content" width="100%">
              <thead>
                <tr>
                  <th class="text-center" style="font-size: 12px; width:30%;">{{ 'name' | translate }}</th>
                  <th class="text-center" style="font-size: 12px; width:30%;">{{ 'code' | translate }}</th>
                  <th class="text-center" style="font-size: 12px; width:30%;">{{ 'location' | translate }}</th>
                  <th class="text-center" style="font-size: 12px; width:10%;">{{ 'quantity' | translate }}</th>
                </tr>
              </thead>
              <tr *ngFor="let item of eachField['default_value']">
                <td width="30%">
                  <span matTooltip="{{ item.name }}">
                    <a [routerLink]="['/items/' + item.item_id]">{{ item.name }}</a>
                  </span>
                </td>
                <td width="30%">
                  <span matTooltip="{{ item.code }}"> {{ item.code }}</span>
                </td>
                <td width="30%">
                  <span matTooltip="{{ item.location }}"> {{ item.location }}</span>
                </td>
                <td width="10%">
                  <span matTooltip="{{ item.quantity }}"> {{ item.quantity }}</span>
                </td>
              </tr>
            </table>
          </ng-container>

          <ng-container *ngIf="eachField['label'] == 'tags'">
            <tag-list [record]="{tags: eachField['value']}" [view]="false"></tag-list>
          </ng-container>
        </div>

        <ng-container *ngSwitchCase="'custom_form_component'">
          <fc-custom-view-field
            [field]="eachField"
            [additionalData]="additionalCustomFormComponent">
          </fc-custom-view-field>
        </ng-container>

        <div *ngSwitchCase="'decimal'">
          <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
            {{ eachField['value'] | format_number | placeholdWithString }} &nbsp;
          </span>
        </div>

        <!-- default -->
        <div *ngSwitchDefault>
          <!-- Normal fields with no relations -->
          <span [id]="'viewField' + eachField.label" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text pre-wrap-text">
            {{ getFieldValue(eachField) | translate | placeholdWithString }}
            <span *ngIf="eachField.label !== 'create_job_email_address' && eachField.label !== 'create_supplier_invoice_email_address'">&nbsp;</span>

            <a class="pull-right" href="" *ngIf="fieldLinkData && fieldLinkData[eachField.label] !== undefined && fieldLinkData[eachField.label]['extraLink']" [routerLink]="fieldLinkData[eachField.label]['url']"
            [queryParams]="fieldLinkData[eachField.label]['data']"
            >
              {{ fieldLinkData[eachField.label]['label'] | translate }}
            </a>

            <a *ngIf="eachField.type === 'address'" href="{{ eachField.google_maps_url }}" target="_blank" class="pull-right text-primary pointer">
              <fa-icon
                [icon]="['fas', 'map-marker-alt']"
                class="fa-lg"
                matTooltip="{{ 'go_to_google_maps' | translate }}">
              </fa-icon>
            </a>
          </span>
        </div>

      </div>
    </div>
  </div>
</div>