<div>
    <div class="d-flex justify-content-around tabs-content align-items-center">
        <link-checklist-button
          [moduleName]="strModule"
          [moduleId]="strRecordId"
          (completed)="onLinkComplete()"
        ></link-checklist-button>

        <form-templates-preview-default-template-btn
          [is-disabled]="arLinkedChecklist.length < 1"
          [as-span]="true"
          icon-class="text-success"
          btn-class="span-links"
          [document-type]="viewService.strRecordModule === 'opportunities' ? 'opportunity_checklist' : ['asset_checklist', 'job_checklist']"
          [module-id]="strRecordId"
          module-name="checklists"
          *ngIf="isAdminUser"
          class="checklist-preview-btn"
          [allow-custom-template-generate]="true"
          preview-header-label="checklist"
          id="widgetChecklistsViewAllButton">
        </form-templates-preview-default-template-btn>
  </div>
    <div class="assets-body">
    <div class="container">


      <div class="row border-top pt-2 pb-2">
        <div class="col-sm-6 pt-1">
          <span class="module-title">
            <fa-icon [icon]="['fa', 'link']" aria-hidden="true"></fa-icon>
            &nbsp;
            {{ 'linked_checklists' | translate }}
          </span>
        </div>
        <div class="col-sm-6 pr-2">
          <nav class="pagination-section">
            <ul class="pagination">
              <refresh-pagination-btn id="widgetChecklistsRefreshButton" (refresh)="onRefresh()" [isDisabled]="! bLoader"></refresh-pagination-btn>
              <li [ngClass]="(listingService.strFirstValue != listingService.strCurrentValue && listingService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                <a id="widgetChecklistsPreviousButton" class="page-link" aria-label="first" (click)="fetchData('prev')">
                  <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                </a>
              </li>
              <li [ngClass]="(listingService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                <a id="widgetChecklistsNextButton" class="page-link" aria-label="first" (click)="fetchData('next')">
                  <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div *ngIf="arLinkedChecklist.length < 1">
        <div class="row border-top" *ngIf="bLoader">
          <span class="no-content">
            {{ 'no_linked_checklist' | translate }}
          </span>
        </div>
        <div class="row border-top" *ngIf="!bLoader">
          <span class="no-content">
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            {{ 'loading' | translate }}
          </span>
        </div>
      </div>

      <div class="row border-top pt-2 pb-2 pr-2"  *ngFor="let checklist of arLinkedChecklist" [id]="'widgetChecklistsRecord' + checklist.id">

				<div class="col-sm-1 circle-size">
                    <fa-icon matTooltip="{{ checklist.status | translate }}" class="icon-size float-left {{objColors['checklist_response'][checklist.status]}}" [icon]="['fas', 'circle']"></fa-icon>
				</div>

        <div class="col-sm-5">
          {{ checklist.name | translate }}
          <div class="checklist-type">
              <ng-container *ngIf="checklist.type == 'asset'">
                {{ '(%s - %s)' | sprintf: [convertChecklistType(checklist.type) | translate, checklist.asset_type_text] }}
              </ng-container>

              <ng-container *ngIf="checklist.type != 'asset'">
                ({{ convertChecklistType(checklist.type) | translate }})
              </ng-container>
          </div>
        </div>

        <div class="col-sm-5">
          {{ checklist.updated_at | local | format: 'lll' }}
                    <div class="checklist-type" *ngIf="checklist.period !== '' && checklist.period !== '[]'" matTooltip="{{ 'inspection_period' | translate }}">
                        {{ convertPeriod(checklist.period) | translate }}
                    </div>
        </div>

        <div class="col-sm-1 d-table text-role less-padding">
          <div class="row">
            <div class="col-12 d-flex justify-content-end align-items-center d-gap">
              <checklist-render-prompts-button
                *ngIf="checklist.editable"
                [options]="{
                  module_name: strModule,
                  questions_name: 'main_prompts',
                  checklist_response_id: checklist.id
                }"
                (complete)="onResponseComplete()"
              ></checklist-render-prompts-button>

              <form-templates-preview-default-template-btn
                [as-span]="true"
                icon-class="text-danger"
                btn-class=""
                [hide-text]="true"
                class="pointer checklist-preview-btn"
                [document-type]="checklist.type + '_checklist'"
                [module-id]="strRecordId"
                module-name="checklists"
                [filter]="{checklist_response_id: checklist.id}"
                *ngIf="isAdminUser"
                [allow-custom-template-generate]="true"
                [preview-header-label]="checklist.name"
                id="widgetChecklistsPreviewButton">
              </form-templates-preview-default-template-btn>
            </div>
          </div>
          <div *ngIf="isAdminUser && checklist.deletable" class="row">
            <div class="col-12">
              <fa-icon id="widgetChecklistsDeleteButton" matTooltip="{{ 'delete' | translate }}" class="text-warning pointer float-right" [icon]="['fas', 'trash-alt']" (click)="deleteRecord(checklist.id)"></fa-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
