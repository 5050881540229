import { Component, DoCheck, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Select } from '../../../../objects/select';
import { MatDialog } from '@angular/material';
import { Checklist } from '../../../../objects/checklist';
import { EditdialogComponent } from '../editdialog/editdialog.component';
import { Observable, Subject, throwError } from 'rxjs';
import { FormService } from '../../../../services/form.service';
import { NotificationService } from '../../../../services/notification.service';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map, concatMap } from 'rxjs/operators';
import { AddressComponent } from '../../address/address.component';
import { Address } from '../../../../objects/address';
import { concat } from 'rxjs/observable/concat';
import { of } from 'rxjs/observable/of';
import { RecordService } from '../../../../services/record.service';
import * as _moment from 'moment';
import { EditformComponent } from '../../editform/editform.component';
import { FileService } from '../../../../services/file/file.service';
import { ViewService } from '../../../../services/view.service'
import { environment } from '../../../../../environments/environment';
import { RATINGS_OPTIONS } from '../../../../module/safety-management/shared/constants';
import { ClientStoreService } from '../../../../services/client-store.service';
import { SubscriptionRestrictionService } from '../../../../services/subscription-restriction/subscription-restriction.service';
import { ENTERPRISE_PLAN } from '../../../../objects/subscription-plans';
import { ArrService } from '../../../../services/helpers/arr.service';
import { sprintf } from 'sprintf-js';
import { DateSelectList } from '../../../../lists/date-select-list';
import { StrService } from '../../../../services/helpers/str.service';
import { GeolocationComponent } from '../../forms/input/geolocation/geolocation.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { get, isEmpty, isEqual, isString, last, toString } from 'lodash-es';
import { toFormattedNumber } from '../../../utils/numbers';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { isId, filled, data_get, either } from '../../../utils/common';
import { LooseObject } from '../../../../objects/loose-object';
import { TranslateService } from '@ngx-translate/core';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-edit-field',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditComponent implements OnInit, DoCheck {
  private _valueFromParent: ParentValue;
  // Event to handle the value from parent
  @Input() set valueFromParent(value: ParentValue) {
    this._valueFromParent = value;
    this.doSomethingFromParent(this._valueFromParent);
  }

  get valueFromParent(): ParentValue {
    return this._valueFromParent;
  }

  @Input() item: any;
  @Input() form: FormGroup;
  @Input() bSubmitted: boolean;
  @Input() autoSelectResult: boolean = false;
  @Input() bStudio: boolean = false;
  @Input() strMode: string = '';
  @Input() strModule: string = '';
  @Input() strCustomClass: any = '';
  @Output() parentEvent = new EventEmitter<any>();
  @Input() strIndex: any;
  @Input() strView: any;
  @Output() setFormIndex = new EventEmitter<string>();
  @Output() roleChange = new EventEmitter<string>();
  @Output() getDateDiff = new EventEmitter<string>();
  @Output() getCurrencyChange = new EventEmitter<string>();
  @Input() strRecordId: string = '';
  @Output() strCurrentValue = new EventEmitter<string>();
  @Input() strUpdateValue: any = '';
  @Input() bIsRoleForm: boolean = false;
  @Input() bFilter: boolean = false;
  @Input() strParentModule: string = '';
  @Output() objCurrentValue = new EventEmitter<object>();
  @Output() patchDataToForm = new EventEmitter<any>();

  /**
   * Contains the client id of the client that the current
   * authenticated client wished to work on behalf with
   */
  @Input('behalf-of-client-id') onBehalfOfClientId: string;
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @ViewChild(GeolocationComponent)
  private geolocationComponent: GeolocationComponent;
  @ViewChild(AddressComponent)
  private addressComponent: AddressComponent;
  public arRelateValues$: Observable<Select[]>;
  public arRelateValuesInput$ = new Subject<string>();
  public bRelateLoading: boolean = false;
  public bIsCalendarShown = false;
  public strItemKey = '';
  public isFileDropped: boolean = false;
  public isFileUploaded: boolean = false;
  public arFiles : any = [];
  public relateAddTag: any = [];

  public arMeridianList: string[] = ["am", "pm"];
  public arMinList: string[] = ["00", "15", "30", "45"];
  public arHourList: string[] = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  public loading: boolean = false;

  public strDate = new FormControl('');
  public strHour = new FormControl('');
  public strMin = new FormControl('');
  public strMeridiem = new FormControl('');
  public strColor;
  public strCustomerNotes;

  public strDateDisplay: any = '';
  public strImageUrl: string = null;
  // Flag that checks if the relate field has value
  public bSearch: boolean = false;
  // List of relate fields that are allowed to have 'create new' option
  public arSearchRelateFields: string [] = ['customers', 'sites', 'contacts'];
  // List of Modules that are allowed to create new in relate fields
  public arSearchModuleList: string [] = ['jobs', 'opportunities', 'contacts'];
  public objOldValues: {} = {};
  // We set this as type any as we dont know
  // what will be the value is given
  public oldValue: any = null;
  public currentValue: any = null;
  public currentAppendedIndex: string = null;
  public arNoLabel: Array<string> = [
    'work_order',
    'job_task',
    'material',
  ];

   public geolocationData: GeolocationData = {
    'address_name': null,
    'latitude': null,
    'longitude': null,
   };


  /**
   * List of dates.
   *
   * @var {DateSelectList}
   */
  public objDateList = new DateSelectList();

  /**
   * Check if the field is valid
   */
  get isValid(): boolean {
    return (this.form.controls[this.item.key].disabled) ? true : this.form.controls[this.item.key].valid;
  }

  /**
   * Checks if the form control will use appendTo="body"
   *
   * @returns {string|null}
   */
  get elemToAppend(): string|null {
    // FC-3636: only used in workflows module for now
    return (this.strModule === 'workflows' || this.strModule === 'imports') ? 'body' : null;
  }

  /**
   * Gets the ID for the checkbox element
   *
   * @returns {string}
   */
  get checkboxId(): string {
    return (this.strModule === 'workflows') ? this.item['checkbox_id'] : this.item['key'];
  }

  /**
   * Check if the field was touched
   */
  get isTouched(): boolean {
    return this.form.controls[this.item.key].touched;
  }

  /**
   * Check if field is customer notes or purchasing notes
   */
  get isCustomerOrPurchasing(): boolean {
    const specialNotesField = ['customer_notes', 'purchasing_notes'];
    return !specialNotesField.includes(this.item.label);
  }

  /**
   * Check if we should display the label or a child will handle it?
   */
  get hasLabel(): boolean {
    return this.item.key
      && this.item.label
      && this.item.controlType != 'dialog'
      && this.item.label != 'phone'
      && ! this.arNoLabel.includes(this.item.label)
      && ! this.item.hide_label
      && this.item.label != 'customer_notes'
      && this.item.label != 'purchasing_notes';
  }

  /**
   * Checks if the form control has
   * a required validator.
   *
   * This also caters the custom required validator for invoices.
   */
  get hasRequiredValidator(): boolean {

    const validator = this.form.controls[this.item.key].validator ? this.form.controls[this.item.key].validator({} as AbstractControl) : '';

    if (
      validator && validator.required ||
      this.form.controls[this.item.key].errors !== null && this.form.controls[this.item.key].errors['incorrect'] === true
    ) {
      return true;
    }
    return false;
  }

  /**
   * disable the email_address input if subcontractor form is edit
   *
   * @return {boolean}
   */
  get isReadOnlyEmailAddress(): boolean {
    return this.strModule == 'subcontractors' && this.strMode == 'edit';
  }

  /**
   * FC-3930: check if active client is on an enterprise plan.
   * This is needed to display the info icon on the 'is_customer' and 'is_supplier' fields in the customers module.
   *
   * @return {boolean}
   */
  get isActiveClientOnEnterprisePlan(): boolean {
    return this.subscriptionRestrictionService.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ENTERPRISE_PLAN)
  }

  /**
   * get the cached default markup from config
   */
  get getDefaultMarkupFromConfig(): number {
    let currentClient = this.localStorageService.getJsonItem('current_client');
    return currentClient.config.default_markup || 0;
  }

  /**
   * Used when controlType is 'custom_form_component'. Put here all additional data you want to send to the component.
   *
   * @return {boolean}
   */
  get additionalCustomFormComponentData(): LooseObject {
    const data: LooseObject = {};

    if (
      this.strMode === 'edit' &&
      this.item['key'] === 'warehouse_stock_levels' &&
      get(this.valueFromParent, 'field_name') === 'warehouse_id'
    ) {
      data['warehouse_id'] = get(this.valueFromParent, 'value');
    }

    if (this.item['key'] === 'work_order_items' &&
      get(this.valueFromParent, 'field_name') === 'wo_job_details') {
      data['wo_job_details'] = get(this.valueFromParent, 'value')
    }
    return data;
  }

  constructor(
    private dialog: MatDialog,
    private formService: FormService,
    private notifService: NotificationService,
    private recordService: RecordService,
    private changeDetectorRef: ChangeDetectorRef,
    private fileService: FileService,
    private clientStoreService: ClientStoreService,
    private arrService: ArrService,
    private viewService: ViewService,
    private strService: StrService,
    private localStorageService: LocalStorageService,
    public subscriptionRestrictionService: SubscriptionRestrictionService,
    private translateService: TranslateService,
  ) { }

  /**
   * After initializing of the component.
   * We need to call markForCheck to
   * be able to reflect all changes.
   */
  ngDoCheck() {
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    //If the submit button has been triggered, show error validators.
    if (this.bSubmitted) {
      this.form.controls[this.item.key].markAsTouched();
    }
    // For annual condition report in sites
    if (this.strModule == 'sites_annual_condition_report') {
      // Update the strDateDisplay
      this.strDateDisplay = this.strUpdateValue;
    }

    const item = get(changes, 'item');

    /// rebuild relate field options if the metadata has been changed
    if (filled(item) && ! isEqual(item.currentValue, item.previousValue) && get(item.currentValue, 'controlType') == 'relate') {
      this.onSetRelate(get(item.currentValue, 'options', []));
    }
  }

  ngOnInit() {
    // Store current field id
    this.strItemKey = this.hasAppendedIndex(this.item.key) ? this.item.key.replace(/-\d/, '') : this.item.key;
    this.currentAppendedIndex = this.getAppendedIndex(this.item.key);
    // Oninit of the form we need to set the current value
    // to this objOldValues so we can use it later.

    this.objOldValues[this.item.key] = this.form.controls[this.item.key].value;

    // If the data type is dropdown
    if (this.item.controlType == 'dropdown') {
      // Disable converted status in option
      if (this.strModule == 'leads') {
        let convertedOptionIndex = this.item.options.findIndex(data => data['id'] == 'converted');
        if (convertedOptionIndex > -1 && !this.bFilter) {
          this.item.options[convertedOptionIndex]['disabled'] = true;
        }
      }
      this.item.options.forEach((item, index) => {
        if (typeof item['id'] == 'boolean') {
          this.item.options[index]['id'] = item['id'].toString();
        }

        // dropdown text should be translated as some of the
        // text value is different from translation value
        this.item.options[index]['text'] = this.translateService.instant(item['text']);
      });

      if (typeof this.form.controls[this.item.key].value == 'boolean') {
        this.form.patchValue({
          [this.item.key]: this.form.controls[this.item.key].value.toString()
        });
      }

      // Get field option
      let arFieldOption = this.item.options;

      // Get selected option
      let arFieldSelected = arFieldOption.find(x => x.id == this.item.default_value);

      // Trigger this onchange to apply the functionality
      this.onChange(this.item.key, arFieldSelected);
    }
    //If the data type is relate.
    if (this.item.controlType == 'relate') {

      let arInitial = [];
      // Do we have multiple relate field? and options has value?
      if (this.item.options && this.item.options.length != 0) {
        // Add options as default option in field
        this.item.options.forEach( element => {
          arInitial.push(new Select(element['id'], element['text']));
        });
      } else {
        // Do we have default value in form?
        if (this.form.value[this.item.key]) {
          // If this field is multipe
          if (this.item.multiple != undefined && this.item.multiple === true) {
            // Check if there's a default text
            if (this.item.default_text !== null && this.item.default_text !== '') {
              let arDefaultText = this.item.default_text.split(', ');
              // If has default text loop each text then check it to default value if exists
              if (arDefaultText) {
                arDefaultText.forEach( (strValue, strIndex) => {
                  let strDefaultValue = (this.item.default_value[strIndex] !== undefined) ? this.item.default_value[strIndex] : {};
                  // FC-2916: Fix Cannot read property 'id' of undefined
                  if (strDefaultValue.id && strDefaultValue.primary) {
                    // Create a new select object then push to arInitial
                    arInitial.push(new Select(strDefaultValue.id || '', strValue, strDefaultValue.primary || '0'))
                  }
                });
              }
            }
          } else {
            // Add current value as default option in field
            arInitial = [new Select(this.item['default_value'], this.item['default_text'])];
          }
        }
      }

      // If item is add tag and not multiple get the first data if option has value.
      if (this.item.add_tag && this.item.multiple == false) {
        this.relateAddTag = (arInitial.length > 0) ? arInitial[0]: [];
      }

      this.onSetRelate(arInitial);
    }

    //If the data type is currency. We want to make sure that the display on form has decimal
    if (this.item.controlType == 'currency') {
      let currentValue = this.form.controls[this.item['key']].value;
      this.form.patchValue({
        [this.item['key']]: toFormattedNumber(currentValue, {
          maxDecimalPlaces: this.item.decimal_places,
          currency: true,
        }),
      });
    }

    //If a field is a color picker, initialize the color.
    if (this.item.controlType == 'picker') {
      this.strColor = this.item.default_value;
    }

    switch (this.strItemKey) {
      //If a field is a job_id, customer id, site_id or invoicing type, disabled if readonly.
      case 'job_id':
      case 'customer_id':
      case 'site_id':
      case 'invoicing_type':
          if(this.item.readonly) {
            this.form.controls[this.item.key].disable();
          } else {
            this.form.controls[this.item.key].enable();
          }
      break;
      // disabled default as readonly in client side.
      case 'accounting_sync_detail':
        this.item.readonly = true;
      break;
      //If a field is a amount to invoice patch the default value.
      case 'amount_to_invoice':
        this.form.patchValue({
          [this.item['key']]: this.item.default_value
        });
      break;
      case 'walk_order':
        this.item.min_length = 0;
      break;
      case 'created_at':
      case 'updated_at':
        this.item.controlType = 'text';
        let strFormatedDate = this.formatDate(this.form['controls'][this.item.key]['value']);
        this.form.patchValue({
          [this.item['key']]: (strFormatedDate != 'Invalid date') ? strFormatedDate : ''
        });
      break;
      case 'accounting_id':
      case 'customer_accounting_id':
      case 'supplier_accounting_id':
        if(this.clientStoreService.getActiveClient().level !== 'admin')
        {
          this.item.readonly = true;
        }
      break;
      case 'current_stock_level':
        if (this.form.controls['is_inventoried'].value) {
          this.form.controls['current_stock_level'].disable();
        } else {
          this.form.controls['current_stock_level'].enable();
        }
      break;
      case 'recurring_job_id':
        this.form.controls[this.item.key].disable();
      break;
      case 'opportunity_id':
        // FC-3427: Auto-populate quote field in create form
        // when creating a job inside the quote.
        if (this.viewService.strRecordModule === 'opportunities') {
          if (!this.form.controls['opportunity_id'].value) {
            const objViewData = this.viewService.getViewRecord();
            let objDefaultOpportunityOption = this.convertDataToOption([objViewData]);
            this.updateRelateOption(objDefaultOpportunityOption, []);
            this.form.patchValue({
              opportunity_id: objViewData.id
            });
            this.form.controls['opportunity_id'].disable();
            // We need to call markForCheck. To be able to reflect the changes made.
            this.changeDetectorRef.markForCheck();
          }
        }
      break;
      case 'geolocation':
        if (this.strMode === 'edit') {
          let geoData = {
            'address_name' : get(this.form.controls['geolocation'], 'value.address_name'),
            'latitude' : get(this.form.controls['geolocation'], 'value.latitude'),
            'longitude' : get(this.form.controls['geolocation'], 'value.longitude'),
          };

          this.geolocationData = geoData;
        }
      break;
      case 'pricing_method':
        if (this.strModule != 'items') {
          break;
        }

        let pricingMethodValue = this.form.controls['pricing_method'].value;
        if (pricingMethodValue == 'default_markup') {

          const buyPriceField = get(this.form.get('labor'), 'value') ? 'hourly_cost' : 'unit_cost';
          const unitCost = parseFloat(this.form.get(buyPriceField) ? this.form.get(buyPriceField).value : 0);
          this.form.controls['unit_price'].disable();
          this.form.controls['markup'].disable();
          // Automatically apply the default mark up value for selected default_markup
          this.form.patchValue({
            'markup': toString(toFormattedNumber(this.getDefaultMarkupFromConfig)),
            'unit_price': toString(
              toFormattedNumber(this.computeUnitPrice(this.getDefaultMarkupFromConfig, unitCost), {
                currency: true,
              })
            ),
          });
        } else if (pricingMethodValue == 'fixed_markup') {
          this.form.controls['unit_price'].disable();
        } else if (pricingMethodValue == 'fixed_sell_price') {
          this.form.controls['markup'].disable();
        }
      break;
    }

    //If the type is a datetime AND is used by studio, we properly edit the values.
    if (this.item.controlType == 'datetime' && this.bStudio) {
      this.strDate.setValue(this.item['date']);
      this.strHour.setValue(this.item['hour']);
      this.strMin.setValue(this.item['minute']);
      this.strMeridiem.setValue(this.item['meridiem']);
    }

    //If the type is a checkbox and the field is labor, we hide some parts of the form.
    if (this.item.controlType == 'checkbox') {
      if (this.item.key.includes('labor') ||
          this.item.key.includes('is_custom_location') ||
          this.item.key.includes('is_department_restricted')) {
        this.form.get(this.item.key).valueChanges.subscribe(formResponse => {
          this.parentEvent.next({type: this.item.key, data: formResponse, mode: this.strMode});
          // We need to call markForCheck. To be able to reflect the changes made.
          this.changeDetectorRef.markForCheck();
        });
      }

      if (this.strModule == 'customers') {
        let customerNumberKey = this.setFieldKey('customer_number');
        if (this.item.key.includes('is_customer') && this.form.controls[customerNumberKey]) {
          if (this.item.default_value == true) {
            this.form.controls[customerNumberKey].enable();
          } else {
            this.form.controls[customerNumberKey].disable();
          }
        }

        let supplierNumberKey = this.setFieldKey('supplier_number');
        if (this.item.key.includes('is_supplier') && this.form.controls[supplierNumberKey]) {
          if (this.item.default_value == true) {
            this.form.controls[supplierNumberKey].enable();
          } else {
            this.form.controls[supplierNumberKey].disable();
          }
        }
      }
    }

    // Set the image preview if there is one.
    if (this.item.controlType == 'image') {
      if (this.item.default_value != undefined &&
          this.item.default_value != '' &&
          this.item.default_value['upload_name'] != undefined &&
          this.item.default_value['upload_name'] != '') {

            this.arFiles = {
              'name': this.item.default_value['name'],
              'size': this.item.default_value['size'],
              'type': this.item.default_value['type'],
              'upload_name': this.item.default_value['upload_name']
            };
            this.fileService.getObjectSignedUrl(this.item.default_value['upload_name'], environment.client_bucket).subscribe (
              object => {
                this.strImageUrl = object['url'];
                // We need to call markForCheck. To be able to reflect the changes made.
                this.changeDetectorRef.markForCheck();
            });
      }
    }

    //Merge data for saving.
    Observable.merge(
      this.strDate.valueChanges,
      this.strHour.valueChanges,
      this.strMin.valueChanges,
      this.strMeridiem.valueChanges)
      .subscribe(data => {
        this.form.patchValue({
          [this.item['key']]: {
            'date': this.strDate.value,
            'hour': this.strHour.value,
            'minute': this.strMin.value,
            'meridiem': this.strMeridiem.value,
          }
        });
        // We need to call markForCheck. To be able to reflect the changes made.
        this.changeDetectorRef.markForCheck();
      }
    );

    // If this flag is true, trigger the relate on creation of the component.
    if (this.autoSelectResult) {
      this.initRelate(this.item['module'], this.item['key'], this.item['multiple']);
    }
    // FC-1879: Update date current value, patchValue will not work because this field is standalone
    if (this.item.controlType === 'date' && this.strModule == 'quotes') {
      this.form.controls[this.item.key].valueChanges.subscribe( value => {
        this.strDateDisplay = value;
      })
    }
  }

  onFileChange(objData) {
    let reader = new FileReader();

    // If file is valid
    if(objData.target.files && objData.target.files.length) {
      const [file] = objData.target.files;
      reader.readAsDataURL(file);
      if (file.type.indexOf('image') > -1) {
        if(file.size/1024/1024 < 30) {
          this.isFileDropped = true;
          reader.onload = () => {

            this.fileService.upload(file).pipe(
              concatMap(tempfile => {
                if (tempfile.url === null || tempfile.filename === null) {
                  return throwError(
                    'An error occured while uploading the file. Please try again'
                  );
                }

                let objFile = this.fileService.objFile;
                this.arFiles = {
                  'name': objFile.name,
                  'size': objFile.size / 1024,
                  'type': objFile.type,
                  'upload_name' : tempfile['filename']
                };

                return this.fileService.getObjectSignedUrl(tempfile.filename);
              })
            )
            .subscribe(response => {
                this.strImageUrl = response['url'];
                this.isFileDropped = false;
                this.isFileUploaded = true;
                this.form.patchValue({
                  [this.item.key]: this.arFiles
                });
                this.changeDetectorRef.markForCheck();
            });
          };
        }
      } else {
        this.notifService.sendNotification('not_allowed', 'images_only', 'warning');
      }
    }
  }

  onChange(strFieldId, event) {
    if (event !== undefined) {
      // Check module
      switch (this.strModule) {
        case 'opportunities':
          // Check triggered field
          if (strFieldId == 'stage') {
            if (event != undefined && event['id'] != undefined) {
              this.parentEvent.emit({
                'type': strFieldId,
                'data': event['id'],
                'mode': this.strMode
              });
            }
          }
        break;
        case "recurring_jobs":
        case "jobs":
          if (strFieldId == "invoicing_type") {
            if (event && event["id"] == "fixed_price_invoices") {
              let intAmountToInvoiceValue = (this.formService.arOldRawData["jobs"] && this.formService.arOldRawData["jobs"]["amount_to_invoice"]) ? this.formService.arOldRawData["jobs"]["amount_to_invoice"] : 0.000;
              this.form.patchValue({
                amount_to_invoice: intAmountToInvoiceValue
              });
            }
          }
          this.parentEvent.emit(event);
        break;
        case 'timesheets':
        case 'workflows':
          this.parentEvent.emit(event);
        break;
        case 'email-templates':
        case 'items':
          this.parentEvent.emit({
            field: strFieldId,
            value: event.id,
          });
        break;

      }

      // Special case get the form index when form is changed
      if (this.strView == 'roles') {
        event['form_index'] = this.strIndex;
        this.setFormIndex.emit(this.strIndex);
        this.roleChange.emit(event);
      }
    }
  }

  /**
   * We update the datetime value.
   * @param event
   */
  updateAssetDateTime(event, strKey) {
      let strFormValue = moment(event['value']).isValid() ? moment(event['value']).format('YYYY-MM-DD') : null;
      let arAttributeValue = this.form['controls'][this.item['key']].value;
      let newArAttribute = [];

      if (arAttributeValue) {
        arAttributeValue.forEach(data => {
            if (data['key'] == strKey) {
              data['default_value'] = strFormValue;
              newArAttribute.push(data);
            } else {
              newArAttribute.push(data);
            }
        });
      }
      this.form.patchValue({
        [this.item['key']]: newArAttribute
      });
  }

  /**
   * This triggers once the view is all loaded and ready.
   * Similar to document.ready in javascript.
   */
  ngAfterViewInit() {
    if (this.item.controlType === 'address') {
      // Set default value to null because values like '[]' cause errors in addressComponent.buildForm
      if (isEmpty(this.item.default_value)) {
        this.item.default_value = null;
      }
      let objAddress: Address = (!this.item['default_value']) ? new Address() : this.item['default_value'];
      let strMode: string = (!this.item['default_value']) ? 'add' : 'edit';
      this.addressComponent.buildForm(objAddress, strMode, 'recordview');
    }

    if (this.item.controlType == 'json') {
      /*
      let objAddress: Address = (!this.item['default_value']) ? new Address() : this.item['default_value'];
      let strMode: string = (!this.item['default_value']) ? 'add' : 'edit';
      this.addressComponent.buildForm(objAddress, strMode, 'recordview');
      */
    }
  }

  /**
   * Update address everytime the form changes.
   * @param event
   */
  updateAddress(event) {
    this.form.patchValue({
      [this.item['key']]: event
    });
  }

  /**
   * Update json everytime the form changes.
   * @param event
   */
  updateJson(event) {
    this.form.patchValue({
      [this.item['key']]: event
    });
    this.markFormControlDirty();
  }

  /**
   * If a new tag was created.
   * @param name
   */
  addTag(name) {
    if (this.strItemKey === 'email_address') {
      // Create regex object. This checks the format of the string.
      let emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      // Use the regex and test the string.
      if (!emailRegex.test(name)) {
        this.notifService.sendNotification('oops', 'email_format_incorrect_added', 'warning');
        return null;
      }
    }
    return new Select(name, name);
  }


  addTagToOptions(objTag){
    if (!this.item['options'].includes(objTag) && (this.item['tag'] || this.item['add_tag'])) {
      this.item['options'].push(objTag);
    }
  }

  /**
   * If the tag added a new value.
   * @param event
   */
  onMultiSelectChange(event) {
    if (event.length != 0 && this.item['module'] && event[event.length - 1]['id'] == event[event.length - 1]['text']) {
      //Ask first the user if he/she wants to add a new data to the tag.
      this.notifService.sendConfirmation("add_new_item")
        .subscribe(
          confirmation => {
            if (confirmation.answer) {
              //First we properly map the tag fields.
              let strNew = event[event.length - 1]['id'];

              event.splice(event.length - 1, 1);

              let arItemList: any = [];

              if (event.length > 0) {
                event.forEach(item => {
                  arItemList.push(item['id']);
                });
              }

              this.formService.addItem(strNew, this.item['module'], arItemList);
              // We need to call markForCheck. To be able to reflect the changes made.
              this.changeDetectorRef.markForCheck();
            } else {
              //If user denied confirmation, remove the added tag.
              let newValue = this.form.get(this.item.key).value;
              newValue.splice(-1, 1);
              this.form.patchValue({
                [this.item.key]: newValue
              });
              // We need to call markForCheck. To be able to reflect the changes made.
              this.changeDetectorRef.markForCheck();
            }
          }
        );
    }
  }

  /**
   * Triggers when the color is changed.
   */
  colorPicker(){
    //We update the form value since we are cannot use form control.
    this.form.patchValue({
      [this.item.key]: this.strColor
    });
  }

  /**
   * If the field type is a dialog, we open the dialog.
   */
  openDialog(){

    let arKeyValues: Select[] = [];
    // Set dropdown values if phone
    if (this.item.key.includes('phone')) {
      arKeyValues = [
        new Select('home', 'lists.home'),
        new Select('work', 'lists.work'),
        new Select('mobile', 'lists.mobile'),
        new Select('others', 'lists.others'),
      ];
    }
    //Set the dialog size and input the necessary data.
    let editFormDialog = this.dialog.open(EditdialogComponent, {
      width: '700px',
      height: 'auto',
      data: {
        'strKey': this.item.key,
        'dialogValues': this.item.default_value,
        'keyValues': arKeyValues
      }
    });
    //Once the dialog closes, apply the changes from the dialog to the edit form component.
    editFormDialog.afterClosed().subscribe(
      dialogResult => {
        if (dialogResult) {
          //Update the display.
          this.item.default_value = dialogResult['newData'];
          //Update the real value.
          this.form.patchValue({
            [this.item.key]: dialogResult['newData']
          });
          // We need to call markForCheck. To be able to reflect the changes made.
          this.changeDetectorRef.markForCheck();
        }
      }
    );
  }

  /**
   * When lose focus on currency type, add decimal
   *
   * @param event
   *
   * @returns void
   */
  formatCurrency(event): void {
    // To manipulate onchange of currency field
    this.getCurrencyChange.emit(event);

    this.form.patchValue({
      [this.item.key]: toFormattedNumber(event.target.value, {
        maxDecimalPlaces: this.item.decimal_places,
        currency: true,
      }),
    });
  }

  fieldChanged(event, fieldName) {
    // This is the main condition as we can use this for other fields

    if ((this.strModule == 'items' || this.strModule == 'materials')
      && ['unit_price', 'unit_cost', 'markup', 'pricing_method', 'hourly_cost', 'labor'].includes(this.item.key)
    ) {
      let isLabor = get(this.form.get('labor'), 'value');
      let buyerPriceField = isLabor ? 'hourly_cost' : 'unit_cost';
      let unitPrice = parseFloat(this.form.get('unit_price') ? this.form.get('unit_price').value : 0);
      let unitCost = parseFloat(this.form.get(buyerPriceField) ? this.form.get(buyerPriceField).value : 0);
      let markUp = parseFloat(this.form.get('markup') ? this.form.get('markup').value : 0);
      let currentFieldValue = get(event, ['target', 'value']) ? get(event, ['target', 'value']) : 0;
      currentFieldValue = isString(currentFieldValue) ? parseFloat(currentFieldValue) : 0;

      if (fieldName == 'markup') {
        let computedUnitPrice = this.computeUnitPrice(currentFieldValue, unitCost);
        this.form.patchValue({
          'unit_price': toString(
            toFormattedNumber(computedUnitPrice, {
              currency: true,
            })
          )
        });
      }

      if (fieldName == 'unit_price') {
        let computedMarkup = this.computeMarkup(currentFieldValue, unitCost);
        this.form.patchValue({
          'markup': toString(toFormattedNumber(computedMarkup))
        });
      }

      if (fieldName == 'unit_cost' || fieldName == 'hourly_cost') {
        let computedUnitPrice = this.computeUnitPrice(markUp, currentFieldValue);
        this.form.patchValue({
          'unit_price': toString(
            toFormattedNumber(computedUnitPrice, {
              currency: true,
            })
          )
        });
      }

      if (fieldName == 'pricing_method') {
        // I override currentFieldValue as i'm expecting the event
        // is an id and text object due to dropdown field
        currentFieldValue = get(event, ['id']) ? get(event, ['id']) : '';

        if (currentFieldValue == 'fixed_markup') {
          if (get(this.form.controls, 'unit_price')) {
            this.form.controls['unit_price'].disable();
          }

          if (get(this.form.controls, 'markup')) {
            this.form.controls['markup'].enable();
          }

          let computedUnitPrice = this.computeUnitPrice(markUp, unitCost);
          this.form.patchValue({
            'unit_price': toString(
              toFormattedNumber(computedUnitPrice, {
                currency: true,
              })
            )
          });
        }

        if (currentFieldValue == 'fixed_sell_price') {
          if (get(this.form.controls, 'unit_price')) {
            this.form.controls['unit_price'].enable();
          }

          if (get(this.form.controls, 'markup')) {
            this.form.controls['markup'].disable();
          }

          let computedMarkup = this.computeMarkup(unitPrice, unitCost);
          this.form.patchValue({
            'markup': toString(toFormattedNumber(computedMarkup))
          });
        }

        if (currentFieldValue == 'default_markup') {
          let defaultMarkup = this.getDefaultMarkupFromConfig;

          if (get(this.form.controls, 'unit_price')) {
            this.form.controls['unit_price'].disable();
          }

          if (get(this.form.controls, 'markup')) {
            this.form.controls['markup'].disable();
          }

          let computedUnitPrice = this.computeUnitPrice(defaultMarkup, unitCost);

          this.form.patchValue({
            'markup': toString(toFormattedNumber(defaultMarkup)),
            'unit_price': toString(
              toFormattedNumber(computedUnitPrice, {
                currency: true,
              })
            ),
          });
        }
      }
    }
  }

  /**
   * When lose focus on decimal type, add decimal
   *
   * @param event
   *
   * @returns void
   */
  formatDecimal(event): void {
    this.form.patchValue({
      [this.item.key]: toFormattedNumber(event.target.value, {
        maxDecimalPlaces: last(this.item.precision),
      }),
    });
  }

  /**
   * When lose focus on number type
   * If we have precision config in metadata add as toFixed
   *
   * @param event
   *
   * @returns void
   */
  formatNumber(event): void {
    this.form.patchValue({
      [this.item.key]: toFormattedNumber(event.target.value, {
        maxDecimalPlaces: last(this.item.precision),
      }),
    });
  }

  onChangeRelate(strModule, event, bAddTag) {
    // If record view module is jobs, customer or customer invoices and the field is job. Send the value to parent component
    // Note: We need to pass undefined value if the field is clear so the parent can be cleared.
    let arModulesToEmitEvent = ['jobs', 'customers', 'customer_invoices', 'purchase_orders', 'supplier_invoices'];
    if (arModulesToEmitEvent.includes(this.strModule) && (strModule == 'jobs')) {
      this.parentEvent.emit({
        'field': this.item['key'],
        'value': event
      });
    }

    if ((this.strModule === 'stock_levels' || this.strModule === 'customer_invoices') && strModule === 'warehouses') {
      this.parentEvent.emit({
        'field': this.item['key'],
        'value': event
      });
    }

    if (this.strModule === 'workflows') {
      this.parentEvent.emit({
        'field': this.item['key'],
        'value': event
      });
    }

    if (this.strModule === 'timesheets') {
      this.parentEvent.emit(event);
    }

    if (this.strModule === 'purchase_orders' || strModule === 'users') {
      this.parentEvent.emit({
        'field': this.item['key'],
        'value': event
      });
    }

    if (event != undefined) {
      // If the module is jobs, and the relate field that changed is either sites of customers.
      if ((this.strModule == 'jobs' || this.strModule == 'opportunities' || this.strModule == 'recurring_jobs') && (strModule == 'sites' || strModule == 'customers')) {
        // Make sure their options are not empty.
        if (this.item['options'] != undefined && this.item['options'].length != 0 && event['id'] != undefined) {
          // Find the value selected in the options.
          let objAddress = this.item['options'].findIndex(element => (element['id'] == event['id']));

          // If the module is jobs, and the relate field that changed is either sites of customers.
          if (strModule == 'sites' || strModule == 'customers') {
            // If the value was found...
            if (objAddress > -1) {
              // Send the value to parent component along with some necessary values.
              this.parentEvent.emit({
                'type': this.item['key'],
                'data': this.item['options'][objAddress],
                'mode': this.strMode
              });
            }
          }
        }
      }

      // If addTag is true change the value by id or text
      if (bAddTag) {
        let strValue = '';
        if (event != null && event != '') {
          if (event['id'] != undefined) {
            strValue = event['id'];
          } else if (event['text'] != undefined) {
            strValue = event['text'];
          }
        }
        this.form.patchValue({
          [this.item.key]: strValue
        });
        this.markFormControlDirty();
      }
      if (strModule && event['id'] != undefined && event['id'] == 'create') {
        this.recordDialog(strModule);
      }

      // FC-3606: Message prompt when creating Job if the Site selected is "Disabled Maintenance"
      if (['jobs', 'quotes', 'recurring_jobs'].indexOf(this.strModule) > -1){
        let selectedSite = this.item['options'].find(option => option.id == event.id);
        if (selectedSite.disable_maintenance) {
          this.notifService.notifyError("unable_to_create_disabled_maintenance");
        }
      }

      Object.keys(this.item['options']).forEach(option => {
        if (event['id'] != undefined && this.item['options'][option]['id'] == event['id']) {
          this.strCurrentValue.emit(this.item['options'][option]['text']);
          this.objCurrentValue.emit(this.item['options'][option]);
        }
      });
    }

    // Special case get the form index when form is changed
    if (this.strView == 'roles') {
      this.setFormIndex.emit(this.strIndex);
    }

    // FC-2593: if the current module is delete, we need to emit the data
    // to lessen the request in api
    if (this.strModule === 'delete') {
        this.parentEvent.emit({
          'field': this.item['key'],
          'value': event
        });
      }
  }

  initRelate(strModule, strId, bIsMultiple) {
    // Check if relate field is empty then initialize relate values.
    if ((
        (typeof(this.form.controls[strId].value) == 'object' && bIsMultiple == false) ||
        this.form.controls[strId].value == '' ||
        this.form.controls[strId].value == null ||
        get(this.item, 'always_init_relate') === true
      )
      && this.item.controlType == 'relate'
    ) {
      this.bRelateLoading = true;

      // Initialize a variable for filters.
      let arFilter: any = false;

      // If the field has filter, place them in the arFilter.
      if (this.item['filter'] != undefined) {
        arFilter = this.item['filter'];
      }

      if (this.strModule == 'credit_notes') {
        // create filter based on credit notes type
        if (strId == 'customer_id' && filled(this.form.getRawValue().type)) {
          let filterKey = this.form.getRawValue().type == 'customer' ? 'is_customer' : 'is_supplier';
          this.item['filter'] = { [filterKey]: true };
          arFilter = this.item['filter'];
        }
      }

      if (this.item['readonly'] === true) {
        this.form.controls[this.item['key']].disable();
      }

      this.beforeGetRecordRelate(strModule, '', '', false, this.item['filter']).subscribe( arRelateInitialValue => {
        // FC-3636: add text for recurring jobs relate
        if (strModule === 'recurring_jobs' && this.strModule === 'workflows') {
          arRelateInitialValue.forEach(obj => {
            obj.text = `${obj['period_text']} - ${obj['customer_text']}`;
          })
        }

        //Set the values of the relate's dropdown.
        this.bRelateLoading = false
        this.item['options'] = arRelateInitialValue;

        if (this.item['key'] == 'created_from_quote') {
          this.parentEvent.emit({'convert_quote_count': arRelateInitialValue.length});
        }

        // If this flag is true, and the results only have 1 record, set that record as default.
        if (this.autoSelectResult && arRelateInitialValue !=  undefined && arRelateInitialValue.length == 1) {
          this.form.patchValue({
            [this.item['key']]: arRelateInitialValue[0]['id']
          });
          this.onChangeRelate(this.item.module, arRelateInitialValue[0], false);
        }
        // Set relate record
        let option = this.convertDataToOption(arRelateInitialValue);

        let updatedOption = this.convertDataToOption(option);
        if (strModule === 'checklists') {
          updatedOption.forEach(op => {
            let initialOption: Checklist = arRelateInitialValue.find(initOp => initOp.id === op.id);
            if (initialOption) {
              op.extras = {
                ...op.extras,
                checklist_type: initialOption.type,
                available_periods: initialOption.available_periods ? JSON.parse(initialOption.available_periods) : []
              }
            }
          });
        }
        this.updateRelateOption(updatedOption, arFilter);
        // We need to call markForCheck. To be able to reflect the changes made.
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  /**
   * Initiate or update the relate field
   */
  onSetRelate(arInitial) {

    if (this.item.controlType == 'relate') {
      // Update the value of bSearch to false
      this.bSearch = false;
      this.item['options'] = arInitial;

      // Initialize a variable for filters.
      let arFilter: any = false;
      // If the field has filter, place them in the arFilter.
      if (this.item['filter'] != undefined) {
        arFilter = this.item['filter'];
      }

      // if we are in the customers module, only show the payment terms based on customer and supplier
      if (this.strModule === 'customers' && this.item.key == 'customer_invoice_terms') {
        arFilter['is_customer'] = true;
      } else if (this.strModule === 'customers' && this.item.key == 'supplier_invoice_terms') {
        arFilter['is_supplier'] = true;
      }

      // If we are in the asset_types module add these filter
      if (this.strModule == 'asset_types' && this.item.key.includes('asset_type_id')) {
        arFilter['asset_types.asset_type_id'] = (this.strRecordId) ? this.strRecordId : null;
      }
      // If the module is users and field is department id
      if (this.strModule == 'users' && this.item.key.includes('department_id')) {
        this.form.patchValue({
          department_id: this.item.default_value
        });
      }
      if (this.strModule == 'warehouses') {
        arFilter.status = 'active';
      }

      if (this.item['readonly'] === true) {
        this.form.controls[this.item['key']].disable();
      }

      // if relate is user and is in jobs activities we should include subcontractor
      if (this.strParentModule === 'jobs' && this.strModule === 'activities' && this.item.key === 'assign_user') {
        arFilter = { has_subcontractor: true };
      }
      this.arRelateValues$ = concat(
        of(arInitial), // We set the initial values to the ng-select.
        this.arRelateValuesInput$.pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this.bRelateLoading = true),
          //We search the inserted text in the ng-select, this time by name.
          switchMap(term => {
            return this.recordService.getRecordRelate(
              this.item['module'],
              term,
              '',
              false,
              arFilter,
              10,
              true,
              {
                ... (this.onBehalfOfClientId && {
                  on_behalf_of_client: this.onBehalfOfClientId,
                }),
              }
            ).pipe(
                catchError(() => of([])), // Empty the list of dropdown on error.
                tap((data) => {
                  let intFieldModule = this.arSearchRelateFields.indexOf(this.item.module);
                  let intModule = this.arSearchModuleList.indexOf(this.strModule);
                  this.bSearch = (term && intFieldModule > -1 && intModule > -1) ? true : false;
                  //Removed the loading icon in relate.
                  this.bRelateLoading = false;
                  //Set the values of the relate's dropdown.
                  this.item.options = this.convertDataToOption(data);
                }),
                map(data => this.convertDataToOption(data))
              )
          })
        ),
      );
    }
  }

  /**
   * Open record dialog
   */
  recordDialog(strModule) {

    let dialogConfig : {[k: string]: any} = {
      data: {
        "strModule": strModule,
        "strMode": 'add',
        'bIsRoleForm': this.bIsRoleForm
      },
      disableClose: true
    };

    // IF MOBILE
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      // display as pop up if not mobile
      dialogConfig.width = '80%';
      dialogConfig.height = 'auto';
    }

    let recordDialog = this.dialog.open(EditformComponent, dialogConfig);
    recordDialog.afterClosed().first().subscribe( result => {

      // Get field key
      let strField = this.item.key;
      this.bSearch = false;
      // Do we have 'save' status?
      if (result.status == 'save') {

        // Store data
        let arAddedRecord = result.data;

        // Send the created data to the parent form.
        this.parentEvent.emit({
          'type': this.item['key'],
          'data': arAddedRecord,
          'mode': this.strMode
        });

        let strTextId = strField.replace(/_id/g, '_text');
        let arSelected = [new Select(arAddedRecord['id'], arAddedRecord[strTextId])];
        // Set new record id
        this.form.controls[strField].setValue(arAddedRecord['id']);
        this.form.controls[strField].markAsTouched;
        // Update relate dropdown
        this.onSetRelate(arSelected);
      } else {
        // Set value to empty if cancelled
        this.form.controls[strField].setValue('');
      }
      // We need to call markForCheck. To be able to reflect the changes made.
      this.changeDetectorRef.markForCheck();
    });
  }

  setRelateCounter(arRelateRecord) {
    this.item.option_count = (arRelateRecord) ? arRelateRecord.length : 0;
    return arRelateRecord;
  }

  /**
   * Set primary email address
   *
   * @param id
   */
  setPrimaryEmail(id) {
    // We make sure that this method is for multiselect email address
    if (this.item.key.includes('email_address') && this.item.controlType == 'multiselect') {
      // Loop each email options
      this.item.options.forEach( arEmail => {
        // Loop all email and set the selected email primary
        arEmail.primary = (arEmail.id == id) ? '1' : '0';
      });
      this.form.controls[this.item.key].markAsTouched();
      this.form.controls[this.item.key].setValue(this.item.options);
    }
  }

  /**
   * Set primary for department in users module
   *
   * @param id
   *
   * @return void
   */
  setPrimaryDepartment(id): void {
    // We make sure that this method is for users module and multiselect department_id
    if (this.strModule == 'users' && this.item.key.includes('department_id') && this.item.multiple === true) {

      this.item.options.forEach( department => {
        department.primary = (department.id == id) ? '1' : '0';
      })

      this.form.controls.department_id.value.forEach( department => {
        department.primary = (department.id == id) ? '1' : '0';
      });

      this.form.controls.department_id.markAsTouched();
      // Set the new values for relate option
      this.updateRelateOption(this.item.options);
    }
  }

  /**
   * Emit the current field value so that we can retrieve before the data
   *
   * @return void
   */
  emitValue(): void {
    let objCurrentField = this.form.controls[this.item.key];
    let arAccountingFields = ['accounting_id', 'customer_accounting_id', 'supplier_accounting_id'];
    this.oldValue = this.arrService.keyFallsBackTo(this.objOldValues, this.item.key);
    this.currentValue = objCurrentField.value;

    // We need to update the old value from new value
    if (this.oldValue !== this.currentValue) {
      this.objOldValues[this.item.key] = this.currentValue;
    }

    if (arAccountingFields.includes(this.item.key)) {
      this.notifService.sendConfirmation('warning_accounting_id_change')
      .subscribe(confirmation => {
        if (confirmation.answer) {
          this.strCurrentValue.emit(this.currentValue);
        } else {
          this.form.patchValue({
            [this.item.key]: this.oldValue
          });
          // If we cancel the changes we need to set again
          // the old value
          this.objOldValues[this.item.key] = this.oldValue;
          this.strCurrentValue.emit(this.oldValue);
        }
      });
    } else {
      this.strCurrentValue.emit(this.currentValue);
      this.objCurrentValue.emit(this.currentValue);
    }
  }

  validateInputNumber(event) {
    if (event.which != 8 && event.which != 0 && event.which !== 46 && event.which < 48 || event.which > 57) {
        event.preventDefault();
    }
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate) {
    // Convert datetime to utc
    let utcTime = moment(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('lll');
  }

  /**
   * Convert the data to select option format
   *
   * @param data Array<object>
   *
   * @returns Select[]
   */
  convertDataToOption(data: Array<object>): Select[] {

    let newOption: Select[] = [];
    let modulesWithNotes = ['jobs', 'opportunities', 'customer_invoices', 'purchase_orders'];

    // Compare current option and new option
    data.forEach( option => {
      if (option['id'] !== undefined && option['text'] !== undefined) {
        var findCurrentOption = this.item.options.find( id => id.id == option['id']);
        var itemPrimary = findCurrentOption !== undefined ? findCurrentOption.primary : '0';
        const supplierPaymentTerm = {
          supplier_payment_term_type: option['supplier_payment_term_type'] || null,
          supplier_payment_term_days: option['supplier_payment_term_days'] || '0',
          purchasing_notes: option['purchasing_notes'] || ''
        }
        // Create options for select field
        if (this.item.key.includes('created_from_quote')) {
          newOption.push(new Select(option['id'], option['text'], itemPrimary || '0', {
            quote_summary: option['quote_summary'],
          }));
        } else if (this.item.key.includes('warehouse_id')) {
          newOption.push(new Select(option['id'], option['text'], itemPrimary || '0', option || null));
        } else if (this.item.key.includes('job_template_id')) {
          newOption.push(new Select(option['id'], option['text'], '0', option || null));
        } else if ((this.item.key.includes('customer_id') || this.item.key.includes('purchase_order_id')) && this.strModule == 'supplier_invoices') {
          newOption.push(new Select(option['id'], option['text'], itemPrimary || '0', supplierPaymentTerm));
        } else if (this.item.key.includes('pricebook_id')) {
          newOption.push(new Select(option['id'], option['text'], itemPrimary || '0', option || null));
        } else if (this.item.key == 'assets') {
          newOption.push(new Select(option['id'], option['text'], itemPrimary || '0', {
            asset_type_id: either(option, ['asset_type_id', 'extras.asset_type_id']),
          }));
        } else if (this.item.key.includes('customer_id') && modulesWithNotes.includes(this.strModule)) {
          newOption.push(new Select(option['id'], option['text'], '0', {
           customer_notes: option['customer_notes']
          }));
        } else {
          newOption.push(new Select(option['id'], option['text'], itemPrimary || '0', option['address'] || null));
        }
      }
    });

    // Add an option if the record does not exists.
    if (newOption.length < 1 && this.bSearch) {
      newOption.push(new Select('create', 'create_new_' + this.item.module));
    }
    return newOption;
  }

  /**
   * Updates the value of the field to Observable select
   *
   * @param arRelateInitialValue
   * @param arFilter
   *
   * @returns void
   */
  updateRelateOption(arRelateInitialValue, arFilter = []): void {
    this.arRelateValues$ = concat(
      of(arRelateInitialValue), // We set the initial values to the ng-select.
      this.arRelateValuesInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.bRelateLoading = true),
        switchMap(term => this.beforeGetRecordRelate(this.item['module'], term, '', false, (this.item['filter_tap'] != undefined && this.item['filter_tap'] == false) ? false : arFilter).pipe(
          tap((data) => {
            let intFieldModule = this.arSearchRelateFields.indexOf(this.item.module);
            let intModule = this.arSearchModuleList.indexOf(this.strModule);
            this.bSearch = (term && intFieldModule > -1 && intModule > -1) ? true : false;
            //Removed the loading icon in relate.
            this.bRelateLoading = false;
            //Set the values of the relate's dropdown.
            this.item.options = this.convertDataToOption(data);

            if (this.item['module'] === 'checklists') {
              this.item.options.forEach(op => {
                let initialOption: Checklist = data.find(initOp => initOp.id === op.id);
                if (initialOption) {
                  op.extras = {
                    ...op.extras,
                    checklist_type: initialOption.type,
                    available_periods: initialOption.available_periods ? JSON.parse(initialOption.available_periods) : []
                  }
                }
              });
              this.updateRelateOption(this.item.options, arFilter);
            }
          }),
          map(data => this.convertDataToOption(data))
        ))
      ),
    );
  }

  /**
   * Here we can manipulate the relate filter before requesting it to api
   *
   * @param relate_module
   * @param term
   * @param id
   * @param email
   * @param filter
   *
   * @retuns Observable<Select[]>
   */
  beforeGetRecordRelate(relate_module, term, id, email, filter): Observable<Select[]> {
    filter = (filter) ? filter : {};
    let bIncludeAdditionalFilter = true;
    // FC-2068: Added filter in sites relate field if the module is opportunities, job, customer_invoices or recurring_jobs.
    // This filter will only apply if there is no terms included and customer_id have value
    let siteFilter = [
      'opportunities',
      'jobs',
      'customer_invoices',
      'recurring_jobs'
    ];
    let customerIdKey = this.setFieldKey('customer_id');
    if (
      siteFilter.find( moduleList => moduleList == this.strModule ) !== undefined &&
      (term === null || term === '' ) &&
      this.item.module === 'sites' &&
      this.form.controls[customerIdKey] !== undefined &&
      this.form.controls[customerIdKey].value !== ''
    ) {
      filter['customer_id'] = this.form.controls[customerIdKey].value;
    } else {
      delete filter['customer_id'];
    }

    let modulesExcludeAdditionalFilter: Array<string> = ['assets', 'document_library'];

    if (modulesExcludeAdditionalFilter.includes(this.strModule)) {
      bIncludeAdditionalFilter = false;
    }

    // if relate is user and is in jobs activities we should include subcontractor
    if (this.strParentModule === 'jobs' && this.strModule === 'activities' && this.item.key === 'assign_user') {
      filter['has_subcontractor'] = true;
    }

    // If module is assets and searching for parent asset, filter out the current record and all its descendant assets.
    if (
      this.strMode === 'edit' &&
      get(this.item, 'key') === 'parent_asset_id' &&
      get(this.item, 'module') === 'assets'
    ) {
      const arDescendantIds: string[] = get(this.valueFromParent, 'value', []);

      if (isId(this.strRecordId)) {
        arDescendantIds.push(this.strRecordId);
      }

      filter['where_not_in'] = {
        id: arDescendantIds
      };
    }

    return this.recordService.getRecordRelate(relate_module, term, id, email, filter, 10, bIncludeAdditionalFilter, {
      ... (this.onBehalfOfClientId && {
        on_behalf_of_client: this.onBehalfOfClientId,
      }),
    })
  }

  /**
   * Mark field as dirty
   *
   * @returns {void}
   */
  markFormControlDirty(): void {
    this.form.controls[this.item['key']].markAsDirty();
  }

  /**
   * Detect if the checkbox is triggered
   * If the is_customer and is_supplier is being
   *
   * @param {object} event
   *
   * @returns {void}
   */
  onChangeCheckbox(event): void {
    if (this.strModule == 'customers') {
      let customerNumberKey = this.setFieldKey('customer_number');
      let customerInvoiceTermsKey = this.setFieldKey('customer_invoice_terms');
      if (this.item.key.includes('is_customer') && this.form.controls[customerNumberKey] && this.form.controls[customerInvoiceTermsKey]) {
        if (event.checked) {
          this.form.controls[customerNumberKey].enable();
          this.form.controls[customerInvoiceTermsKey].enable();
        } else {
          this.form.controls[customerNumberKey].disable();
          this.form.controls[customerInvoiceTermsKey].disable();
        }

        this.parentEvent.emit({
          'type': 'is_customer',
          'key': 'create_job_email_address',
          'is_hidden': !event.checked
        });
      }

      let supplierNumberKey = this.setFieldKey('supplier_number');
      let supplierInvoiceTermsKey = this.setFieldKey('supplier_invoice_terms');
      if (this.item.key.includes('is_supplier') && this.form.controls[supplierNumberKey] && this.form.controls[supplierInvoiceTermsKey]) {
        if (event.checked) {
          this.form.controls[supplierNumberKey].enable();
          this.form.controls[supplierInvoiceTermsKey].enable();
        } else {
          this.form.controls[supplierNumberKey].disable();
          this.form.controls[supplierInvoiceTermsKey].disable();
        }

        this.parentEvent.emit({
          'type': 'is_supplier',
          'key': 'create_supplier_invoice_email_address',
          'is_hidden': !event.checked
        });
      }
    }

    if (this.strModule == 'items') {
      if (this.item.key == 'is_inventoried') {
        if (event.checked) {
          this.form.controls['current_stock_level'].disable();
        } else {
          this.form.controls['current_stock_level'].enable();
        }
      }
    }
  }

  /**
   * FC-3930: Displays an information box telling the user that
   * to avail the feature of creating jobs or supplier invoices by email,
   * they have to upgrade to the enterprise plan.
   *
   * Only accessible to non-enterprise clients.
   *
   * @returns {void}
   */
  displayUpgradeInfoBoxForNonEnterpriseClients(): void {
    if (!this.isActiveClientOnEnterprisePlan && this.strModule === 'customers') {
      this.notifService.sendConfirmation(
        'create_job_or_supplier_invoice_from_email_upgrade',
        'additional_features',
        'default',
        { trueVal: 'upgrade', falseVal: 'decline' }
      ).filter(confirmation => confirmation.answer === true)
       .subscribe(() => {
        this.parentEvent.emit({ 'type': 'redirect_to_manage_subscription' });
      });
    }
  }

  /**
   * Displays a notification showing user has copied text to clipboard
   *
   * @returns {void}
   */
  notifyCopy(): void {
    this.notifService.notifyInfo('copied_to_clipboard');
  }

  getRiskRatingOptions(index: number) {
    return RATINGS_OPTIONS[index] || [];
  }

  /**
   * Check if the given field has appended index
   * This will set a new field name
   * FYI: this is for multiple form with the same field
   * we need to append the index of the form so we can
   * identify the parent form of the field
   * @param string
   * @returns
   */
  hasAppendedIndex(string: string) {
    return /-\d/.test(string);
  }

  /**
   * Get the value of the appended index
   * This will set a new field name
   * FYI: this is for multiple form with the same field
   * we need to append the index of the form so we can
   * identify the parent form of the field
   *
   * @param   {string|undefined} strSubject
   *
   * @returns {string}
   */
  getAppendedIndex(strSubject?: string): string {
    return strSubject !== undefined ? strSubject.replace(/(.*)-/,"") : '';
  }

  /**
   * This will set a new field name
   * FYI: this is for multiple form with the same field
   * we need to append the index of the form so we can
   * identify the parent form of the field
   * @param fieldName
   * @returns
   */
  setFieldKey(fieldName: string): string {
    return (this.hasAppendedIndex(this.item.key)) ? sprintf('%s-%s', fieldName, this.getAppendedIndex(this.item.key)) : fieldName;
  }

  /**
   * This will handle when there's a value
   * changed from the parent component
   * @param valueFromParent
   */
  doSomethingFromParent(valueFromParent) {
    if (this.item && valueFromParent['field_name'] === this.item.key) {
      if (valueFromParent['control_type'] === 'date' || valueFromParent['control_type'] === 'datetime') {
        let strFormValue = '';
        let toNotUtc = '';
        let dateValue = valueFromParent['value'];

        if (valueFromParent['control_type'] == 'date') {
          strFormValue = moment(dateValue).format('YYYY-MM-DD');
          toNotUtc = moment(dateValue).format('YYYY-MM-DD');
        } else {
          let toUtc = moment.utc(dateValue);
          toNotUtc = moment(dateValue).format('YYYY-MM-DD HH:mm:ss');
          let toUtcString = toUtc._d;
          strFormValue = moment(toUtcString).format('YYYY-MM-DD HH:mm:ss');
        }

        this.strDateDisplay = strFormValue;
      }

      if (valueFromParent['control_type'] === 'relate') {
        this.item.options = [...this.item.options, ...valueFromParent['value']];
        this.updateRelateOption(this.getUniqueElementsByKey(this.item.options, 'id'), []);
      }

      if (valueFromParent['control_type'] === 'geolocation') {
        let geoData = {
          'address_name' : valueFromParent['value']['address_name'],
          'latitude' : valueFromParent['value']['latitude'],
          'longitude' : valueFromParent['value']['longitude']
        };

        this.geolocationData = geoData;
        this.form.controls['geolocation'].setValue(geoData);
        this.geolocationComponent.latitude = valueFromParent['value']['latitude'];
        this.geolocationComponent.longitude = valueFromParent['value']['longitude'];
      }
    }
  }

  /**
   * If you want to do something in this component
   * which is triggered by the child component, please
   * use this method. Avoid adding another method that
   * the child calls.
   *
   * @var {string}
   */
  childEmitEvent(objChildEvent: ChildEventValue) {
    if (objChildEvent.field_type && ['date', 'datetime', 'daterange', 'datetimerange'].includes(objChildEvent.field_type)) {
      this.emitValue();
    }

    if (objChildEvent.date_difference) {
      this.parentEvent.emit(objChildEvent.date_difference);
    }
  }

  setAsPrimary(data): void {
    if (this.item.has_primary) {
      this.item.options.map( option => {
        option.primary = (option.id == data.id) ? true : false;
        return option;
      });
    }
  }

  validateCurrency(event) {
    let arFieldsThatNeedsToValidate = [
      'unit_cost',
      'unit_price'
    ];

    if (arFieldsThatNeedsToValidate.includes(this.item.key)
      && event.which != 8
      && event.which != 0
      && event.which !== 46
      && event.which < 48
      || event.which > 57) {
        event.preventDefault();
    }
  }

  /**
   * prevent from typing non-alphanumeric characters from the text inputs
   */
  validateInputText(event: KeyboardEvent): void {
    if (this.strService.hasInvalidCharacters(event.key)) {
      event.preventDefault();
    }
  }

  /**
   * This event emit from the geolocationComponent
   * @param event
   */
  geolocationComponentEmitter(event): void {
    this.form.controls['geolocation'].setValue(event);
  }

  /**
   * This will get the unique element in an array
   * @param arr
   * @param key
   * @returns
   */
  getUniqueElementsByKey(arr: [], key: string): Array<{}> {
    const seen = new Set();
    return arr.filter(item => {
      const itemValue = item[key];
      return seen.has(itemValue) ? false : seen.add(itemValue);
    });
  }

  protected computeMarkup(unitPrice: number, unitCost: number)
  {
    let markup = ((unitPrice - unitCost) / unitCost) * 100;

    return !isNaN(markup) && isFinite(markup) ? markup : 0;
  }

  protected computeUnitPrice(markup: number, unitCost: number)
  {
    let unitPrice = unitCost + ((markup / 100) * unitCost);

    return !isNaN(unitPrice) && isFinite(unitPrice) ? unitPrice : 0;
  }

  /**
   * Ignores the default sorting for keyvalue
   * iterations in html
   *
   * @returns {number}
   */
  ignoreSort(): number {
    return 0;
  }

  /**
   * Clears the ng-select component if it exists
   *
   * @returns {void}
   */
  clearSelect(): void {
    if (this.ngSelectComponent) {
      this.ngSelectComponent.handleClearClick();
    }
  }

  updateFormValue(event: LooseObject): void {
    this.patchDataToForm.emit(event);
  }
}

export interface ChildEventValue {

  date_difference: string | {
    years: any,
    months: any,
    days: any,
    hours: any,
    minutes: any,
    seconds: any
  } | undefined;

  field_type: string;
  field_value: string;
}

export interface ParentValue {
  value: any,
  control_type: string,
  field_name: string,
}

export interface GeolocationData {
  address_name: string,
  latitude: number,
  longitude: number,
}