import { Pipe, PipeTransform } from '@angular/core'
import { when } from '../shared/utils/common'

@Pipe({
  name: 'when'
})
export class WhenPipe implements PipeTransform {
  transform = <T = any>(then: T, condition: boolean, opts: {
    otherwise?: T,
  } = {}): T | undefined => when(condition, {
    then: () => then,
    else: () => opts.otherwise,
  })
}